import {HotkeyNavListController} from '../../../shared/controllers/hotkey.nav.list.controller';

export default {
    template: require('./devices.list.html'),
    controller: HotkeyNavListController,
    controllerAs: 'controller',
    bindings: {
        downKeyDescriptionText: '<',
        items: '<',
        onItemSelected: '&',
        selectedItem: '<',
        upKeyDescriptionText: '<'
    }
};