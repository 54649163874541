import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {stateGo} from 'redux-ui-router';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import DateRangeShortcutHelper from 'invision-core/src/components/helpers/daterange.shortcut.helper';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';

import {
    ALLOW_CREDIT_DEBIT_NOTE,
    ONE_TIME_MAKE_PAYMENT,
    PAYMENT_ADJUSTMENT_ACCESS,
    VIEW_FUTURE_INVOICES_ACCESS
} from '../../../security.attributes';
import {
    ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS,
    ADJUSTMENT_CONFIG_ENABLED,
    ADJUSTMENTS,
    APPLY_TAX_CERTIFICATE_ID,
    BILLING_PAYMENTS_SEARCH_PLACEHOLDER,
    billingLedgerTabList,
    CREATE_NOTE_ID,
    CREDIT_NOTES,
    DEBIT_NOTES,
    DEPOSITS,
    ENABLE_CREATE_NOTE,
    FUTURE_INVOICE_URL,
    INVOICES,
    MISC_ADJUSTMENT_ID,
    MISC,
    OVERALL,
    PAYMENTS
} from '../billing.payments.constants';
import {
    CanUserPerformMiscellaneousAndItemAdjustmentSelector,
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerConvergentBillerIdSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerSubscriberSummaryPostPaidAccountSelector,
    IsARCSubscriptionMgmtFeatureToggleSelector,
    IsCurrentAccountPostpaidSelector
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    AdjustmentConfigSelector,
    BillingLedgerCurrentTabSelector,
    BillingLedgerTableDataSelector,
    DateShortcutSelector,
    EndDateSelector,
    InvoiceFilterSelector,
    InvoiceListForDropDownSelector,
    IsFetchingBillingPaymentsSelector,
    PageCountOfLedgerSelector,
    PageNumberSelector,
    PageSizeOfLedgerSelector,
    RecordCountOfLedgerSelector,
    StartDateSelector,
    TypeSelector
} from '../../../reducers/selectors/customer.billing.selectors';
import {
    retrieveLedgerSummary,
    setCurrentTabBillingLedger,
    setPageSizePreference,
    updateDateRange,
    updateInvoiceFilter,
    updatePageNumber,
    updateTypeFilter
} from '../../../reducers/actions/customer.billing.actions';
import {fetchInvoices} from '../../../reducers/actions/customer.cases.actions';
import LocaleKeys from '../../../locales/keys';
import MakePaymentConstants from '../../customer/makePayment/make.payment.constants';
import {MISC_ADJUSTMENT} from '../../adjustment/adjustment.modal.constants';
import {retrieveConvergentBillerSubscriberSummary} from '../../../reducers/actions/customer.convergent.biller.actions';
import {CurrentCustomerNodeSelector} from '../../../reducers/selectors/customer.account.hierarchy.selectors';
import {ACCOUNTING_METHODS} from '../../createCustomer/billingInformationSection/billing.information.section.constants';
import {
    DEFAULT_PAGE_NUMBER,
    DEFAULT_PAGE_SIZE
} from '../../shared/constants/customer.constants';
import {REPORTING_LEVELS} from '../../../components/customer/accountHierarchy/account.hierarchy.constants';

const ALL_PAYMENT_TYPES = OVERALL;
class BillingPaymentsController {
    constructor($ngRedux, $scope, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $scope,
            $timeout,
            ACCOUNTING_METHODS,
            billingLedgerTabList: billingLedgerTabList(),
            cellTypes: {
                ADJUSTMENT: i18n.translate(LocaleKeys.ADJUSTMENT),
                CREDIT_NOTE: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.CREDIT_NOTE),
                DEBIT_NOTE: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.DEBIT_NOTE),
                INVOICE_SUMMARY: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_SUMMARY),
                REFUND: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.REFUND),
                PAYMENT: i18n.translate(LocaleKeys.PAYMENT),
                STATEMENT: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.STATEMENT),
                DEPOSITS: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DEPOSIT)
            },
            checkIfUserHasAccessToViewFutureInvoices: this.checkIfUserHasAccessToViewFutureInvoices.bind(this),
            CoreLocaleKeys,
            filter: ALL_PAYMENT_TYPES,
            handleMoreMenuOptions: this.handleMoreMenuOptions.bind(this),
            localeKeys: LocaleKeys,
            isStatement: this.isStatement.bind(this),
            onSearchCleared: this.onSearchCleared.bind(this),
            onQuickInfoModalClose: this.onQuickInfoModalClose.bind(this),
            pageSizeSelected: this.pageSizeSelected.bind(this),
            redirectToSelectedState: this.redirectToSelectedState.bind(this),
            searchInvoiceNumber: this.searchInvoiceNumber.bind(this),
            searchPlaceholder: BILLING_PAYMENTS_SEARCH_PLACEHOLDER(),
            selectedShortcut: null,
            selectTab: this.selectTab.bind(this),
            shortcuts: DateRangeShortcutHelper.getShortListShortcuts(),
            stateOrName: 'index.customercare.customer.billingpayments',
            tabs: [{
                active: true,
                name: i18n.translate(LocaleKeys.LEDGER)
            }],
            uiNotificationService,
            updatePageNumber: this.updatePageNumber.bind(this)
        });

        this.setOptions();

        this.BILLING_AND_PAYMENTS = i18n.translate(LocaleKeys.BILLING_AND_PAYMENTS);
        this.LOADING = i18n.translate(LocaleKeys.LOADING);
        this.MISC_ADJUSTMENT = i18n.translate(LocaleKeys.MISC_ADJUSTMENT);
        this.TYPE = i18n.translate(LocaleKeys.TYPE);
        this.VIEW_DETAILS = i18n.translate(LocaleKeys.VIEW_DETAILS);
        this.ADJUSTMENT_TYPE = MISC;
        this.BILLING_PAYMENTS_TYPE = MakePaymentConstants.MAKE_PAYMENT_TYPES.BILLING_PAYMENTS;

        this.navigateToView = (dgRowEntity) => {
            this.actions.stateGo(dgRowEntity.sref, {
                invoiceId: dgRowEntity.invoiceId
            }, {
                location: true,
                notify: true
            });
        };
        this.navigateToCreditNoteDetails = (rowEntity) => {
            this.actions.stateGo(rowEntity.sref, {
                creditNoteId: rowEntity.type === this.cellTypes.CREDIT_NOTE ? rowEntity.creditNoteId :
                    rowEntity.type === this.cellTypes.DEBIT_NOTE? rowEntity.debitNoteId : rowEntity.adjustmentId
            }, {
                location: true,
                notify: true
            });
        };
        $scope.datatable = {
            listComponentScope: this,
            adjustmentType : this.ADJUSTMENT_TYPE,
            cellTypes: this.cellTypes,
            creditNoteWithTaxCertificateTooltipTemplate: require('../cellTemplates/creditnote.tax.certificate.tooltip.template.html'),
            getAccountBalanceColumnHeader: this.getAccountBalanceColumnHeader,
            handleItemsMoreOptionsMenuSelected: this.handleItemsMoreOptionsMenuSelected,
            invoiceIdTooltipLabel: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_NUMBER),
            invoiceDisputeTooltip: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_HAS_DISPUTE),
            partialAdjustmentTooltip: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PARTIAL_ADJUSTMENT_TOOLTIP),
            partialPaymentTooltip: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PARTIAL_PAYMENT_TOOLTIP),
            paymentIdTooltipLabel: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.PAYMENT_ID),
            onRowEntitySelected: this.navigateToView,
            openCreditDebitNoteDetails: this.navigateToCreditNoteDetails,
            openQuickInfoModal: this.openQuickInfoModal.bind(this),
            reasonCodeTooltipLabel: i18n.translate(LocaleKeys.REASON_CODE),
            remainingBalanceTooltipTemplate: require('../cellTemplates/remaining.balance.tooltip.template.html'),
            remainingBalanceTooltipLabel: i18n.translate(LocaleKeys.REMAINING_BALANCE),
            singleLabelValueTooltipTemplate: require('../cellTemplates/single.label.value.tooltip.template.html'),
            tabList: this.billingLedgerTabList,
            transactionIdTooltipLabel: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.TRANSACTION_ID),
            viewDetails: this.VIEW_DETAILS,
            whtCertDateTooltipLabel: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.WITHHELD_TAX_CERTIFICATE_DATE),
            whtCertIdTooltipLabel: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.WITHHELD_TAX_CERTIFICATE_ID)
        };
        this.adjustmentModalConfig = {
            onRegisterApi: ({api}) => {
                this.adjustmentModalApi = api;
            }
        };
        this.applyCreditNoteModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditNoteModalApi = api;
            }
        };
        this.applyTaxCertificateModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyTaxCertificateModalApi = api;
            }
        };
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentConfig: AdjustmentConfigSelector(store),
                adjustmentReasonCodes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AdjustmentReason, store),
                adjustmentTypeCodes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AdjustmentType, store),
                billingLedgerCurrentTab: BillingLedgerCurrentTabSelector(store),
                billingLedgerTableData: BillingLedgerTableDataSelector(store),
                canUserPerformMiscellaneousAndItemAdjustment: CanUserPerformMiscellaneousAndItemAdjustmentSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store),
                customerConvergentBillerId: CurrentCustomerConvergentBillerIdSelector(store),
                dateShortcut: DateShortcutSelector(store),
                endDate: EndDateSelector(store),
                invoiceFilter: InvoiceFilterSelector(store),
                invoiceListForFilter: InvoiceListForDropDownSelector(store),
                isARCSubscriptionMgmtFeatureLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.MicroserviceConfiguration, store),
                isARCSubscriptionMgmtFeatureToggle:IsARCSubscriptionMgmtFeatureToggleSelector(store),
                isAdjustmentConfigLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdjustmentConfig, store),
                isCurrentAccountPostpaid: IsCurrentAccountPostpaidSelector(store),
                isDbss: IsDbss(store),
                isFetchingBillingPayments: IsFetchingBillingPaymentsSelector(store),
                isPosPaymentTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PointOfSalePaymentType, store),
                pageCountOfLedger: PageCountOfLedgerSelector(store),
                pageNumber: PageNumberSelector(store),
                pageSizeOfLedger: PageSizeOfLedgerSelector(store),
                paymentInstrumentTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PaymentInstrumentType, store),
                paymentInstrumentTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaymentInstrumentType, store),
                postPaidAccount: ConvergentBillerSubscriberSummaryPostPaidAccountSelector(store),
                recordCountOfLedger: RecordCountOfLedgerSelector(store),
                startDate: StartDateSelector(store),
                typeFilter: TypeSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            fetchInvoices,
            retrieveConvergentBillerSubscriberSummary,
            retrieveLedgerSummary,
            setCurrentTabBillingLedger,
            setPageSizePreference,
            stateGo: stateGo,
            updateDateRange,
            updateInvoiceFilter,
            updatePageNumber,
            updateTypeFilter
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isAdjustmentConfigLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentConfig);
        }

        if (!this.state.adjustmentReasonCodes || this.state.adjustmentReasonCodes.length < 1) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentReason);
        }

        if (!this.state.adjustmentTypeCodes || this.state.adjustmentTypeCodes.length < 1) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentType);
        }

        if (!this.state.isARCSubscriptionMgmtFeatureLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.MicroserviceConfiguration);
        }

        if (!this.state.paymentInstrumentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentType);
        }

        if (this.state.customerConvergentBillerId) {
            this.actions.fetchInvoices(this.state.currentCustomerId);
        }

        if (!this.state.isPosPaymentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PointOfSalePaymentType);
        }

        if (!pathOr(0, ['length'], this.state.postPaidAccount)) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId);
        }
        this.initDateRange();
        this.retrieveConvergentBiller();

        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this.checkIfUserHasAccessToViewFutureInvoices();
        this.hasCreateNoteAccess();
        this.createNoteModalConfig = {
            onRegisterApi: ({api}) => {
                this.createNoteModalApi = api;
            }
        };
        this.moreMenuOptions = [{
            title: i18n.translate(this.localeKeys.MISC_ADJUSTMENT),
            id: 0
        },
        {
            title: i18n.translate(this.localeKeys.CREDIT_DEBIT_NOTE.CREATE_NOTE),
            id: 1
        }];

        if (this.state.isDbss) {
            this.moreMenuOptions.push({
                title: i18n.translate(this.localeKeys.APPLY_TAX_CERTIFICATE.APPLY_TAX_CERTIFICATE),
                id: 2
            });
        };

        if (this.ACCOUNTING_METHODS[1].value === this.state.currentCustomerAccountingMethod) {
            delete this.billingLedgerTabList.OPEN_INVOICES;
            this.selectTab(this.billingLedgerTabList.ACCOUNT_SUMMARY);
        } else {
            this.selectTab(this.billingLedgerTabList.OPEN_INVOICES);
        }
    }

    onOptionSelected(item) {
        this.typeOptions.forEach((item) => {
            item.selected = false;
        });

        item.selected = true;
        switch (item.text) {
            case i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.ADJUSTMENTS):
                this.filter = ADJUSTMENTS;
                break;
            case i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.CREDIT_NOTES):
                this.filter = CREDIT_NOTES;
                break;
            case i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.DEBIT_NOTES):
                this.filter = DEBIT_NOTES;
                break;
            case i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.INVOICES):
                this.filter = INVOICES;
                break;
            case i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.PAYMENTS):
                this.filter = PAYMENTS;
                break;
            default:
                this.filter = ALL_PAYMENT_TYPES;
                break;
        }
        this.actions.updateTypeFilter(this.filter);
        this.retrieveConvergentBiller();
    }

    setOptions() {

        const {typeFilter = ALL_PAYMENT_TYPES} = this.state || {};
        this.typeOptions = [
            {
                selected: typeFilter === ALL_PAYMENT_TYPES,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.ALL)
            },
            {
                selected: typeFilter === ADJUSTMENTS,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.ADJUSTMENTS)
            },
            {
                selected: typeFilter === CREDIT_NOTES,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.CREDIT_NOTES)
            },
            {
                selected: typeFilter === DEBIT_NOTES,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.DEBIT_NOTES)
            },
            {
                selected: typeFilter === INVOICES,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.INVOICES)
            },
            {
                selected: typeFilter === PAYMENTS,
                text: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_TYPES.PAYMENTS)
            }
        ];
    }

    initDateRange() {
        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
        this.shortcuts =  [entireHistoryDateShortcut, ...DateRangeShortcutHelper.getShortListShortcuts()];
        this.selectedShortcut = find(propEq(this.state.dateShortcut, 'urlId'), this.shortcuts);
        if (this.selectedShortcut) {
            this.actions.updateDateRange(this.selectedShortcut.start, this.selectedShortcut.end, this.selectedShortcut.urlId);
        } else {
            this.selectedShortcut = entireHistoryDateShortcut;
        }
    }

    updateRangeEndForShortcuts() {
        if (this.state.dateShortcut) {
            this.initDateRange();
        }
    }

    handleItemsMoreOptionsMenuSelected(rowEntity, {item}) {
        switch (item.id) {
            case ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS.APPLY_CREDIT_NOTE:
                this.creditNoteData = rowEntity;
                this.openApplyCreditNoteModal();
                break;
            case ACCOUNT_SUMMARY_ITEM_LEVEL_MENU_OPTIONS.VIEW_TRANSACTION_DETAILS:
                this.actions.stateGo('index.customercare.customer.transactions.details',
                    {
                        transactionId: rowEntity.transactionId || rowEntity.paymentTransactionId
                    }, {
                        location: true,
                        notify: true
                    });
                break;
            default:
                break;
        }
    }

    getAccountBalanceColumnHeader() {
        return this.tabList.DEPOSITS.active ?
            i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DEPOSIT_BALANCE) :
            i18n.translate(
                LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_BALANCE
            );
    }

    retrieveConvergentBiller() {
        if (this.state.customerConvergentBillerId) {
            const request = this.buildBillingAndPaymentsRequest();
            request.request.PageSize = this.state.pageSizeOfLedger;
            request.request.PageNumber = this.state.pageNumber;
            this.actions.retrieveLedgerSummary(request)
                .then(() => {
                    this.setOptions();
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    onDateRangeChanged(startDate, endDate, selectedShortcut) {
        this.actions.updateDateRange(startDate, endDate, selectedShortcut && selectedShortcut.urlId ? selectedShortcut.urlId : null);
        this.selectedShortcut = find(propEq(this.state.dateShortcut, 'urlId'), this.shortcuts);
        this.retrieveConvergentBiller();
    }

    buildBillingAndPaymentsRequest() {
        const request = {
            EndDate: this.state.endDate || undefined,
            Filter:  this.billingLedgerTabList.DEPOSITS.active ? DEPOSITS : this.state.typeFilter,
            StartDate: this.state.startDate || undefined,
        };
        request.InvoiceNumber = this.state.invoiceFilter || undefined;
        return {
            customerId: this.state.currentCustomerId,
            request
        };
    }

    onPaymentSuccess() {
        this.updateRangeEndForShortcuts();
        this.retrieveConvergentBiller();
    }

    openAdjustmentModal() {
        this.adjustmentType = MISC_ADJUSTMENT;
        this.showAdjustmentModal = true;
        this.$timeout(() => {
            this.adjustmentModalApi.open();
        });
    }

    onAdjustmentClose() {
        this.updateRangeEndForShortcuts();
        this.retrieveConvergentBiller();
        this.adjustmentModalApi.close();
        this.showAdjustmentModal = false;
    }

    hasMakePaymentAccess() {
        return (this.state.isCurrentAccountPostpaid || this.state.isARCSubscriptionMgmtFeatureToggle) &&
            !this.state.currentCustomerIsExternallyManagedAR &&
            hasAccess(this.state.userSecurityAttributes, ONE_TIME_MAKE_PAYMENT) &&
            this.state.currentCustomer?.HierarchyReportingLevel !== REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT;
    }

    hasMiscAdjustmentAccess() {
        return this.state.canUserPerformMiscellaneousAndItemAdjustment && this.state.postPaidAccount.length && this.state.postPaidAccount[0].AccountNumber &&
            (!this.state.currentCustomerIsExternallyManagedAR || this.state.currentCustomerIsExternallyManagedAR) && hasAccess(this.state.userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS);
    }

    hasCreateNoteAccess() {
        return (this.state.adjustmentConfig[ENABLE_CREATE_NOTE] === ADJUSTMENT_CONFIG_ENABLED) && hasAccess(this.state.userSecurityAttributes, ALLOW_CREDIT_DEBIT_NOTE);
    }

    showMoreMenu() {
        return this.hasMiscAdjustmentAccess() && this.hasCreateNoteAccess();
    }

    redirectToSelectedState() {
        this.actions.stateGo(FUTURE_INVOICE_URL);
    }

    isStatement() {
        return this.state.currentCustomer?.HierarchyReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT;
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    checkIfUserHasAccessToViewFutureInvoices() {
        if (this.state.currentCustomer.HierarchyId) {
            this.isBillCycleChangeAllowed = !(this.state.currentCustomer && this.state.currentCustomer.HierarchyParentSubscriberId);
            this.hasFutureSatetmentsViewAccess = (hasAccess(this.state.userSecurityAttributes, VIEW_FUTURE_INVOICES_ACCESS) && !this.state.currentCustomer.HierarchyParentSubscriberId);
        } else {
            this.hasFutureSatetmentsViewAccess = hasAccess(this.state.userSecurityAttributes, VIEW_FUTURE_INVOICES_ACCESS);
        }
    }

    searchInvoiceNumber(invoiceNumber) {
        if (invoiceNumber) {
            this.actions.updateInvoiceFilter(invoiceNumber.trim());
            this.retrieveConvergentBiller();
        }

    }

    handleMoreMenuOptions(option) {
        if (option.item.id === MISC_ADJUSTMENT_ID) {
            this.openAdjustmentModal();
        }
        if (option.item.id === CREATE_NOTE_ID) {
            this.openCreateNoteModal();
        }
        if (option.item.id === APPLY_TAX_CERTIFICATE_ID) {
            this.openApplyTaxCertificateModal();
        }
    }

    openCreateNoteModal() {
        this.showCreateNoteModal = true;
        this.$timeout(() => {
            this.createNoteModalApi.open();
        });
    }

    closeCreateNoteModal() {
        this.retrieveConvergentBiller();
        this.createNoteModalApi.close();
        this.showCreateNoteModal = false;
    }

    selectTab(selectedTab) {
        this.selectedTab = selectedTab;
        for (const tab in this.billingLedgerTabList) {
            this.billingLedgerTabList[tab].active = (tab === selectedTab.key);
        }
        if (this.billingLedgerTabList.ACCOUNT_SUMMARY.active) {
            this.resetFilters();
            this.retrieveConvergentBiller();
        }
        if (this.billingLedgerTabList.DEPOSITS.active) {
            this.resetFilters();
            this.retrieveConvergentBiller();
        }
        this.actions.setCurrentTabBillingLedger(selectedTab);
        return this.billingLedgerTabList;
    }

    pageSizeSelected(pageSize) {
        this.actions.updatePageNumber(DEFAULT_PAGE_NUMBER);
        this.actions.setPageSizePreference(pageSize);
        this.retrieveConvergentBiller();
    }

    onSearchCleared() {
        this.actions.updateInvoiceFilter(null);
        this.retrieveConvergentBiller();
    }

    resetFilters() {
        this.actions.updateTypeFilter(ALL_PAYMENT_TYPES);
        this.actions.updateInvoiceFilter(null);
        this.actions.updatePageNumber(DEFAULT_PAGE_NUMBER);
        this.actions.setPageSizePreference(DEFAULT_PAGE_SIZE);
        this.actions.updateDateRange(null, null, null);
        this.selectedShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
    }

    openQuickInfoModal(entity) {
        this.quickInfoData = entity;
        this.showQuickInfoModal = true;
    }

    onQuickInfoModalClose() {
        this.showQuickInfoModal = false;
    }

    openApplyCreditNoteModal() {
        this.showApplyCreditNoteModal = true;
        this.$timeout(() => {
            this.applyCreditNoteModalApi.open();
        });
    }

    onApplyCreditNoteModalClose() {
        this.retrieveConvergentBiller();
        this.applyCreditNoteModalApi.close();
        this.showApplyCreditNoteModal = false;
    }

    openApplyTaxCertificateModal() {
        this.showApplyTaxCertificateModal = true;
        this.$timeout(() => {
            this.applyTaxCertificateModalApi.open();
        });
    }

    onApplyTaxCertificateModalClose() {
        this.retrieveConvergentBiller();
        this.applyTaxCertificateModalApi.close();
        this.showApplyTaxCertificateModal = false;
    }

    updatePageNumber(pageNumber) {
        this.actions.updatePageNumber(pageNumber);
        this.retrieveConvergentBiller();
    }

}

export default {
    controller: BillingPaymentsController,
    controllerAs: 'billingPaymentsList',
    template: require('./billing.payments.list.page.html')
};
