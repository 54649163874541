import {ADDRESS_STATUS_CONSTANTS} from '../../../customer/addresses/addresses.constants';

export const isAddressValidFromAddressResponse = (addressResponse) => {
    if (addressResponse.AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.INVALID || addressResponse.AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.MDU_VALIDATION_ERROR) {
        return false;
    }
    return addressResponse && addressResponse.ValidatedAddress;
};

export const formatValidatedAddress = (validatedAddress = {}) => {
    return {
        addressLine1: validatedAddress.LineOne,
        addressLine2: validatedAddress.LineTwo,
        city: validatedAddress.City,
        country: validatedAddress.Country,
        postalCode: validatedAddress.PostalCode,
        stateRegionProvince: validatedAddress.State
    };
};
