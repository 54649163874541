import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';

import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {
    CurrentBusinessUnitSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {
    CUSTOM_SECURITY_QUESTION_KEY,
    CODES
} from 'invision-core/src/components/metadata/codes/codes.constants';
import {REPORTING_LEVELS} from '../../customer/accountHierarchy/account.hierarchy.constants';
import {AdditionalPropertyStrings} from 'invision-core/src/components/metadata/codes/codes.additional.properties.constants';

import CustomerCareLocaleKeys from '../../../locales/keys';
import {INVOICE_DETAIL_LEVEL, REGEXES} from '../../../customercare.constants';
import {
    EMAIL_MAX_LENGTH,
    TERMS_AND_CONDITIONS_TYPES
} from '../create.customer.constants';
import {
    CurrentCustomerSelector,
    CustomerPinRegexSelector,
    DefaultPasswordChallengeOptionsWithCustomValidation,
    EnableProspectLiteSelector,
    SelectedCustomerCategorySelector,
    SelectedCustomerIsDriversLicenseRequired,
    SelectedCustomerIsSsnRequired,
    SelectedCustomerRequirements
} from '../../../reducers/selectors/customer.selectors';
import {CreditCheckConfigurationSelector} from '../../../reducers/selectors/credit.check.selectors';
import {mapAdditionalPropertyValue} from '../../../reducers/helpers/customer.helper';
import {CUSTOMER_PROFILE_STATE} from '../../customer/profile/profile.config';

export class AccountInformationSectionController {
    constructor($ngRedux, $state) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.emailRegex = REGEXES.EMAIL;
        this.ssnRegex = REGEXES.NUMBERS_ONLY;
        this.maskedSsnRegex = REGEXES.NUMBERS_OR_SSN_ASTERISK;
        this.EMAIL_MAX_LENGTH = EMAIL_MAX_LENGTH;
        this.securityQuestionCustom = CUSTOM_SECURITY_QUESTION_KEY;
        this.requirements = {};
        this.reportingLevels = REPORTING_LEVELS;
        this.mappedPasswordChallengesAdditionalMetadata = null;
        this.securityAnswerRegex = null;
        this.invoiceDetailLevels = [{
            Name: i18n.translate(CoreLocaleKeys.SELECT),
            Value: undefined
        }, {
            Name: i18n.translate(this.CustomerCareLocaleKeys.INVOICE_DETAIL_LEVEL_OPTIONS.DETAIL),
            Value: INVOICE_DETAIL_LEVEL.DETAIL
        }, {
            Name: i18n.translate(this.CustomerCareLocaleKeys.INVOICE_DETAIL_LEVEL_OPTIONS.SUMMARY),
            Value: INVOICE_DETAIL_LEVEL.SUMMARY
        }];
        this.selectedInvoiceDetail = {
            Name: i18n.translate(CoreLocaleKeys.SELECT),
            Value: undefined
        };
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addressCountries: MetadataOptionsForCodeValuesSelector(CODES.AddressCountry, store),
                creditCheckConfiguration: CreditCheckConfigurationSelector(store),
                currentBusinessUnit: CurrentBusinessUnitSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                customerCategory: SelectedCustomerCategorySelector(store),
                defaultPasswordChallenges: DefaultPasswordChallengeOptionsWithCustomValidation(store),
                enableProspectLite: EnableProspectLiteSelector(store),
                homeCountries: MetadataOptionsForCodeValuesSelector(CODES.AddressCountry, store),
                isDbss: IsDbss(store),
                isDriversLicenseRequired: SelectedCustomerIsDriversLicenseRequired(store),
                isRegexCodesLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                isSsnRequired: SelectedCustomerIsSsnRequired(store),
                passwordChallenges: MetadataCodeTypeSelector(CODES.DefaultPasswordChallenge, store),
                pinRegex: CustomerPinRegexSelector(store),
                regexCodes: MetadataCodeTypeSelector(CODES.RegularExpression, store),
                selectedCustomerRequirements: SelectedCustomerRequirements(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                termsAndConditionsConfiguration: MetadataCodeTypeSelector(CODES.TermsAndConditionsConfiguration, store)
            };
        };
        const controllerActions = {
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.loadMetadata();

        this.requirements = this.state.selectedCustomerRequirements;
        this.isEditingCustomerFormProfilePage = this.$state.is(CUSTOMER_PROFILE_STATE);
        this.handleFormChanged();
    }

    loadMetadata() {
        if (!this.state.isRegexCodesLoaded) {
            this.actions.fetchCodeTypes(CODES.RegularExpression);
        }
    }

    $onChanges() {
        if (this.state && this.state.creditCheckConfiguration && this.state.creditCheckConfiguration.creditCheckModalStatus === false) {
            this.handleSsnValue();
        }
        if (this.customerModel.InvoiceConfiguration && this.selectedInvoiceDetail) {
            this.selectedInvoiceDetail.Value = this.customerModel.InvoiceConfiguration.InvoiceDetailLevel;
        }
    }

    get canDisplaySsn() {
        return this.requirements.display_ssn;
    }

    get canDisplayDriversLicense() {
        return this.requirements.display_drivers_license_number;
    }


    handleSsnValue(onSsnBlur) {
        if (this.markMandatoryCreditCheckFieldsDisabled !== undefined && this.markMandatoryCreditCheckFieldsDisabled !== null) {
            if (onSsnBlur) {
                this.customerModel.Ssn = this.maskedSsn;
            }
            if (this.customerModel.Ssn && this.customerModel.Ssn.length > 4) {
                const lastFourDigits = this.customerModel.Ssn.substring(this.customerModel.Ssn.length - 4);
                this.maskedSsn = `*****${lastFourDigits}`;
            }
        }
    }

    handleInvoiceDetailChanged() {
        this.customerModel.InvoiceConfiguration = {
            InvoiceDetailLevel:  this.selectedInvoiceDetail.Value
        };
        this.handleFormChanged();
    }

    handleFormChanged(onSsnBlur) {
        if (onSsnBlur && this.markMandatoryCreditCheckFieldsDisabled !== undefined) {
            this.handleSsnValue(onSsnBlur);
        }
        if (this.requirements.capture_email_as_login) {
            this.customerModel.Login = this.customerModel.Email;
        }
        this.getRegexForSecurityQuestion();
        this.onFormChanged()(this.customerModel);
    }

    isError(elementName, fields = []) {
        const formElement = this.parentForm[elementName];
        let isError = false;
        if (formElement && (formElement.$touched || this.isFormSubmitted)) {
            if (fields.length === 0 && Object.keys(formElement.$error).length > 0) {
                isError = true;
            }

            fields.forEach(field => {
                if (formElement.$error[field]) {
                    isError = true;
                }
            });
        }
        return isError;
    }

    shouldShowDefaultPasswordChallenge() {
        return this.isNewCustomer && this.state.defaultPasswordChallenges.length;
    }

    shouldShowInvoiceDisplayOptions() {
        return !this.isNewCustomer &&
            this.invoiceDisplayOptions &&
            this.invoiceDisplayOptions.length > 1;
    }

    shouldShowAcceptedTermsAndConditionsDate() {
        return this.state.termsAndConditionsConfiguration
            && this.state.termsAndConditionsConfiguration.length
            && (this.isAcceptedTermsAndConditionsSubscriberType(this.state.termsAndConditionsConfiguration));
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    isAcceptedTermsAndConditionsSubscriberType(object) {
        return object.find((currentObj) => {
            return find(propEq('terms_and_conditions_type', 'Key'))(currentObj.AdditionalProperties).Value === TERMS_AND_CONDITIONS_TYPES.SUBSCRIBER;
        });
    }

    getRegexForSecurityQuestion() {
        this.securityAnswerRegex = this.customerModel.SecurityQuestion && this.customerModel.SecurityQuestion !== CUSTOM_SECURITY_QUESTION_KEY
            ? new RegExp(this.findRegex(this.customerModel.SecurityQuestion)) : null;
    }

    get isRequired() {
        return this.removeMultiOfferValidations !== undefined ? !this.removeMultiOfferValidations : !this.overrideValidations;
    }

    findRegex(securityQuestion) {
        //Map additional properties for password challenges if not mapped so we can find the regex code id
        if (!this.mappedPasswordChallengesAdditionalMetadata) {
            this.mappedPasswordChallengesAdditionalMetadata = this.state.passwordChallenges.map((passChallenge) => {
                return {
                    value: passChallenge.Name,
                    additionalProperties: mapAdditionalPropertyValue(passChallenge.AdditionalProperties)
                };
            });
        }

        // Find selected value on the code table list of challenges
        const passChallengeFound = this.mappedPasswordChallengesAdditionalMetadata.find((sc) => {
            return sc.value === securityQuestion;
        });
        // Once the challenge is found on the raw list we need to find the regex_code additional property
        const regexIdFound = passChallengeFound ? passChallengeFound.additionalProperties.find((additionalProperty) => {
            return additionalProperty.Id = AdditionalPropertyStrings.REGEX_CODE;
        }) : '';
        // Once the value for the code challenge we need to find it on the list of regex codes
        const regexCodeFound = regexIdFound ? this.state.regexCodes.find((regex) => {
            return regex.Value === regexIdFound.Value;
        }) : '';
        // Once the ID is found on the list then we can return the value for the regex
        return regexCodeFound ? mapAdditionalPropertyValue(regexCodeFound.AdditionalProperties).find((additionalProperty) => {
            return additionalProperty.Id = AdditionalPropertyStrings.REGEX;
        }).Value : '';
    }

    pinRequired() {
        return (!this.overrideValidations && this.state.selectedCustomerRequirements.capture_subscriber_pin)
                || (!this.state.enableProspectLite && this.state.selectedCustomerRequirements.capture_subscriber_pin);
    }
}

export default {
    template: require('./account.information.section.html'),
    bindings: {
        codeTypes: '<',
        customerModel: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?',
        driversLicenseStateOptions: '<',
        invoiceDisplayOptions: '<',
        isLiteCustomerEnabledHousehold: '<',
        isNewCustomer: '<',
        errorLookup: '<',
        onFormChanged: '&',
        isFormSubmitted: '<',
        overrideValidations: '<?',
        removeMultiOfferValidations: '<?'
    },
    require: {
        parentForm: '^form'
    },
    controller: AccountInformationSectionController,
    controllerAs: 'AccountInformationSection'
};
