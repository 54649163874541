import {SERVICES_USAGE_STATE_NAME} from './../services.and.usage.config';

export const USAGE_CAPS_URL =  `${SERVICES_USAGE_STATE_NAME}.usageCaps`;
export const USAGE_CAPS_THRESHOLD_TYPES = {
    'Currency': 1
};
export const USAGE_CAPS_THRESHOLD_EXTRA_PROPS = ['selected', 'enabled', 'DisplayName'];
export const USAGE_CAPS_INDICATOR_NORMAL_ICON = 'dashboard';
export const USAGE_CAPS_INDICATOR_BREACHED_ICON = 'exclamation-circle';
export const USAGE_CAPS_ELECTION_LEVEL = {
    CUSTOMER_LEVEL: 2,
    SERVICE_LEVEL: 1
};
export const USAGE_CONTROL_TABS = {
    SERVICE_LEVEL: 0,
    ACCOUNT_LEVEL: 1
};
export const USAGE_NOTIFICATION_METHOD = {
    BY_INCREMENTAL_VALUE: 1,
    PERCENTAGE: 2
};
export const TEST_DATA = {
    AllServicesUsageCaps: [
        {
            Id: 11,
            Name: 'Data Used',
            Description: 'Data Used description',
            ServiceTypes:[
                {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            ],
            ThresholdType: {
                Code: 2,
                Name: 'GB'
            },
            Thresholds: [
                {
                    Id: 111,
                    MaximumAmount: 50,
                    Default: true
                },
                {
                    Id: 112,
                    MaximumAmount: 100,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 111
                }
            ],
            TermsAndConditions: {
                Name: 'T&C #1',
                TermsAndConditionsContent: 'I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can\'t do it. I just can\'t do it. It\'s inappropriate. It\'s not nice." Lorem Ipsum best not make any more threats to your website. It will be met with fire and fury like the world has never seen.'
            }
        },
        {
            Id: 22,
            Name: 'Money Spent',
            Description: 'Money Spent Description',
            ServiceTypes:[
                {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            ],
            ThresholdType: {
                Code: 1,
                Name: 'Currency'
            },
            Thresholds: [
                {
                    Id: 221,
                    MaximumAmount: 100,
                    Default: true
                },
                {
                    Id: 222,
                    MaximumAmount: 300,
                    Default: false
                },
                {
                    Id: 223,
                    MaximumAmount: 500,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 221
                }
            ],
            TermsAndConditions: {
                Name: 'T&C #2',
                TermsAndConditionsContent: 'I will write some great placeholder text – and nobody writes better placeholder text than me, believe me – and I’ll write it very inexpensively. I will write some great, great text on your website’s Southern border, and I will make Google pay for that text. Mark my words.'
            }
        },
        {
            Id: 33,
            Name: 'Data Used (MB)',
            Description: 'Data Used description',
            ServiceTypes:[
                {
                    Id: 2,
                    Name: 'ServiceType 2'
                }
            ],
            ThresholdType: {
                Code: 3,
                Name: 'MB'
            },
            Thresholds: [
                {
                    Id: 331,
                    MaximumAmount: 500,
                    Default: true
                },
                {
                    Id: 332,
                    MaximumAmount: 1000,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 331
                }
            ],
            TermsAndConditions: {
                Name: 'T&C #3',
                TermsAndConditionsContent: 'I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can\'t do it. I just can\'t do it. It\'s inappropriate. It\'s not nice." Lorem Ipsum best not make any more threats to your website. It will be met with fire and fury like the world has never seen.'
            }
        }

    ],

    UsageCapElections:  [
        {
            Id: 11,
            Name: 'Data Used',
            Description: 'Data Used description',
            ServiceTypes:[
                {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            ],
            ThresholdType: {
                Code: 2,
                Name: 'GB'
            },
            Thresholds: [
                {
                    Id: 111,
                    MaximumAmount: 50,
                    Default: true
                },
                {
                    Id: 112,
                    MaximumAmount: 100,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 111
                }
            ],
            ServiceIdentifier: {
                Value: '1234567890',
                ServiceType: {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            },
            Elected: true,
            ElectedThreshold: {
                Id: 111,
                MaximumAmount: 50,
                Default: false
            },
            TermsAndConditions: {
                Name: 'T&C #1',
                TermsAndConditionsContent: 'I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can\'t do it. I just can\'t do it. It\'s inappropriate. It\'s not nice." Lorem Ipsum best not make any more threats to your website. It will be met with fire and fury like the world has never seen.'
            }
        },
        {
            Id: 22,
            Name: 'Money Spent',
            Description: 'Money Spent Description',
            ServiceTypes:[
                {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            ],
            ThresholdType: {
                Code: 1,
                Name: 'Currency'
            },
            Thresholds: [
                {
                    Id: 221,
                    MaximumAmount: 100,
                    Default: true
                },
                {
                    Id: 222,
                    MaximumAmount: 300,
                    Default: false
                },
                {
                    Id: 223,
                    MaximumAmount: 500,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 221
                }
            ],
            ServiceIdentifier: {
                Value: '1234567890',
                ServiceType: {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            },
            Elected: true,
            ElectedThreshold: {
                Id: 223,
                MaximumAmount: 500,
                Default: false
            },
            TermsAndConditions: {
                Name: 'T&C #2',
                TermsAndConditionsContent: 'I will write some great placeholder text – and nobody writes better placeholder text than me, believe me – and I’ll write it very inexpensively. I will write some great, great text on your website’s Southern border, and I will make Google pay for that text. Mark my words.'
            }
        },
        {
            Id: 11,
            Name: 'Data Used',
            Description: 'Data Used description',
            ServiceTypes:[
                {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            ],
            ThresholdType: {
                Code: 2,
                Name: 'GB'
            },
            Thresholds: [
                {
                    Id: 111,
                    MaximumAmount: 50,
                    Default: true
                },
                {
                    Id: 112,
                    MaximumAmount: 100,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 111
                }
            ],
            ServiceIdentifier: {
                Value: '3128441871',
                ServiceType: {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            },
            Elected: true,
            ElectedThreshold: {
                Id: 112,
                MaximumAmount: 100,
                Default: false
            },
            TermsAndConditions: {
                Name: 'T&C #1',
                TermsAndConditionsContent: 'I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can\'t do it. I just can\'t do it. It\'s inappropriate. It\'s not nice." Lorem Ipsum best not make any more threats to your website. It will be met with fire and fury like the world has never seen.'
            }
        },
        {
            Id: 22,
            Name: 'Money Spent',
            Description: 'Money Spent Description',
            ServiceTypes:[{
                Id: 1,
                Name: 'ServiceType 1'
            }
            ],
            ThresholdType: {
                Code: 1,
                Name: 'Currency'
            },
            Thresholds: [
                {
                    Id: 221,
                    MaximumAmount: 100,
                    Default: true
                },
                {
                    Id: 222,
                    MaximumAmount: 300,
                    Default: false
                },
                {
                    Id: 223,
                    MaximumAmount: 500,
                    Default: false
                },
            ],
            NotificationThresholds: [
                {
                    Id: 221
                }
            ],
            ServiceIdentifier: {
                Value: '3128441871',
                ServiceType: {
                    Id: 1,
                    Name: 'ServiceType 1'
                }
            },
            Elected: true,
            ElectedThreshold: {
                Id: 223,
                MaximumAmount: 500,
                Default: false
            },
            TermsAndConditions: {
                Name: 'T&C #2',
                TermsAndConditionsContent: 'I will write some great placeholder text – and nobody writes better placeholder text than me, believe me – and I’ll write it very inexpensively. I will write some great, great text on your website’s Southern border, and I will make Google pay for that text. Mark my words.'
            }
        },
        {
            Id: 33,
            Name: 'Data Used (MB)',
            Description: 'Data Used description',
            ServiceTypes:[
                {
                    Id: 2,
                    Name: 'ServiceType 2'
                }
            ],
            ThresholdType: {
                Code: 3,
                Name: 'MB'
            },
            Thresholds: [
                {
                    Id: 331,
                    MaximumAmount: 500,
                    Default: true
                },
                {
                    Id: 332,
                    MaximumAmount: 1000,
                    Default: false
                }
            ],
            NotificationThresholds: [
                {
                    Id: 331
                }
            ],
            ServiceIdentifier: {
                Value: '8477211355',
                ServiceType: {
                    Id: 2,
                    Name: 'ServiceType 2'
                }
            },
            Elected: true,
            ElectedThreshold: {
                Id: 332,
                MaximumAmount: 1000,
                Default: false
            },
            TermsAndConditions: {
                Name: 'T&C #3',
                TermsAndConditionsContent: 'I was going to say something extremely rough to Lorem Ipsum, to its family, and I said to myself, "I can\'t do it. I just can\'t do it. It\'s inappropriate. It\'s not nice." Lorem Ipsum best not make any more threats to your website. It will be met with fire and fury like the world has never seen.'
            }
        }
    ]
};

export const LOCATION_OVERRIDES_DISPLAY_MAX = 3;

export const USAGE_CAP_BEHAVIOR_TYPES = {
    RestrictUsage : 1,
    DiscountToZero : 2
};