import {CurrentCustomerSelector} from '../../../reducers/selectors/customer.selectors';
import {SessionSelectors} from 'invision-core';
import {PageDataSelector} from '../../../reducers/selectors/custom.page.selectors';
import {
    MetadataCodeLoadedSelector,
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

class CustomPageContextualController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                currentUserContext: SessionSelectors.UserSecurityContextSelector(store),
                pageData: PageDataSelector(store),
                templateLoaded: MetadataCodeLoadedSelector(CODES.BusinessUnitTemplate, store)
            };
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });
    }

    $onDestroy() {
        this.disconnect();
    }
}

export default {
    template: require('./custom.page.contextual.html'),
    controller: CustomPageContextualController
};
