import {values} from 'ramda';
import {ThunkHelper} from 'invision-core';

export const SET_REVOKE_GIFT_ORDER_MODEL = 'SET_REVOKE_GIFT_ORDER_MODEL';
export const setRevokeGiftOrderModel = (model) => {
    return {
        type: SET_REVOKE_GIFT_ORDER_MODEL,
        payload: {
            model
        }
    };
};

export const REVOKE_GIFT_ORDER_CONSTANTS = {
    BEGIN: 'REVOKE_GIFT_ORDER_BEGIN',
    SUCCESS: 'REVOKE_GIFT_ORDER_SUCCESS',
    FAILURE: 'REVOKE_GIFT_ORDER_FAILURE'
};
const REVOKE_GIFT_ORDER_EVENTS = values(REVOKE_GIFT_ORDER_CONSTANTS);
const revokeGiftOrderPromise = (dispatch, reasonCode, orderId) => {
    return ThunkHelper(dispatch, REVOKE_GIFT_ORDER_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/RevokeGiftOrder',
        data: {
            ReasonCode: reasonCode,
            Id: orderId
        }
    });
};
export const revokeGiftOrder = (model, orderId) => {
    return (dispatch) => {
        return revokeGiftOrderPromise(dispatch, model.reasonCode, orderId);
    };
};
