import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import LocaleKeys from '../../../../../../../../locales/keys';

class ProductDecisionsController {
    constructor() {
        Object.assign(this, {
            defaultDescriptionLengthLimit: 100,
            invoiceNameLengthLimit: 200,
            LocaleKeys,
            productClassifications: PRODUCT_CLASSIFICATIONS,
        });
    }
}

export default {
    template: require('./product.decisions.html'),
    bindings: {
        descriptionLengthLimit: '<?',
        hasError: '<',
        maxQuantity: '<',
        minQuantity: '<',
        monetaryLocale: '<',
        onQuantityChanged: '&',
        pricingPlan: '<',
        product: '<',
        productHasOverrides: '<'
    },
    controller: ProductDecisionsController,
};
