import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from '../../../../servicesAndUsage/usageDetails/usage.details.config';
import {
    SERVICES_AND_SHARED_ENTITLEMENTS,
    SERVICES_AND_SHARED_ENTITLEMENTS_TAB
} from '../../../../servicesAndUsage/services.and.usage.config';
import {setSelectedSharedEntitlementIndex} from '../../../../../../reducers/actions/shared.entitlements.actions';
import {ENTITLEMENT_SHARE_STATUS} from '../../../../../../customercare.constants';
import {updateDashboardEntitlementShareStatus} from '../../../../../../reducers/actions/customer.convergent.biller.actions';
import {CurrentCustomerIdSelector} from '../../../../../../reducers/selectors/customer.selectors';
import {NUMBER_OF_ENTITLEMENTS_TO_DISPLAY} from '../services.and.offers.contants';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {ENTITLEMENT_BALANCE_UPDATE_ACCESS} from '../../../../../../security.attributes';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {USAGE_DETAILS_NON_TELCO_SERVICES_REDIRECT} from '../../../../../../reactRoutes';

class EntitlementsGridController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            ENTITLEMENT_SHARE_STATUS,
            handleMoreOptionsSelected: this.handleMoreOptionsSelected.bind(this),
            handleOnClick: this.handleOnClick.bind(this),
            uiNotificationService,
            updateEntitlementShareStatus: this.updateEntitlementShareStatus.bind(this)
        });
    }

    $onInit() {
        const controllerActions = {
            setSelectedSharedEntitlementIndex,
            stateGo,
            updateDashboardEntitlementShareStatus
        };
        const mapStateToProps = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        this.moreMenuItems = [{
            id: CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_ENTITLEMENT_MODAL.TITLE,
            title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_ENTITLEMENT_MODAL.TITLE)
        }];
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.editEntitlementModalConfig = {
            onRegisterApi: (event) => {
                this.handleEditEntitlementModalConfig = event.api;
            }
        };

        this.showMoreItems = convertStringToNumber(this.entitlementsCount) > NUMBER_OF_ENTITLEMENTS_TO_DISPLAY;
    }

    handleOnClick(entitlementIndex, entitlementId, balanceIterationId) {
        if (this.isShared) {
            this.actions.setSelectedSharedEntitlementIndex(entitlementIndex || 0);
            this.actions.stateGo(SERVICES_AND_SHARED_ENTITLEMENTS, {
                tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SHARED_ENTITLEMENTS
            });
        } else {
            if (this.isNonTelcoUsageServicePageEnabled) {
                this.actions.stateGo(USAGE_DETAILS_NON_TELCO_SERVICES_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    serviceIdentifierValue: this.serviceIdentifier.Value
                });
            } else {
                this.actions.stateGo(SERVICE_USAGE_DETAILS_STATE_NAME, {
                    entitlementId,
                    entitlementIndex,
                    balanceIterationId,
                    serviceIdentifierValue: this.serviceIdentifier.Value,
                    serviceIdentifierName: this.serviceIdentifier.ServiceIdentifierName,
                    serviceAttributeId: this.serviceIdentifier.ServiceAttributeId,
                    serviceIdentifierFriendlyName: this.serviceIdentifier.FriendlyName
                });
            }
        }
    }

    hasEntitlementEditAccess() {
        return hasAccess(this.state.userSecurityAttributes, ENTITLEMENT_BALANCE_UPDATE_ACCESS);
    }

    updateEntitlementShareStatus(entitlement) {
        const newStatus = entitlement.SharingStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
            ? ENTITLEMENT_SHARE_STATUS.DISABLED
            : ENTITLEMENT_SHARE_STATUS.ENABLED;
        this.actions.updateDashboardEntitlementShareStatus(this.state.currentCustomerId, entitlement.EntitlementIdentifier, newStatus).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(
                newStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
                    ? CustomerLocaleKeys.SHARING_ACTIONS.ENABLE_SUCCESS
                    : CustomerLocaleKeys.SHARING_ACTIONS.DISABLE_SUCCESS));
            this.refreshServiceDetails && this.refreshServiceDetails();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    handleMoreOptionsSelected({item}) {
        switch (item.id) {
            case CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_ENTITLEMENT_MODAL.TITLE:
                this.openEditEntitlementModal();
                break;
            default:
        }
    }

    openEditEntitlementModal() {
        this.openEditEntitlementModalOpened = true;
        this.$timeout(() => {
            this.handleEditEntitlementModalConfig.open();
        });
    }

    closeEditEntitlementModal() {
        this.openEditEntitlementModalOpened = false;
        this.handleEditEntitlementModalConfig.close();
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        entitlements: '<',
        entitlementsCount: '<',
        isNonTelcoUsageServicePageEnabled: '<',
        isShared: '<?',
        refreshServiceDetails: '&?',
        serviceIdentifier: '<'
    },
    controller: EntitlementsGridController,
    template: require('./entitlements.grid.template.html')
};
