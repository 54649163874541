import {CustomerCareProfileWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
export const CUSTOMER_PROFILE_STATE = 'index.customercare.customer.profile';

export function routing($stateProvider) {
    $stateProvider.state(CUSTOMER_PROFILE_STATE, {
        url: '/profile',
        params: {
            openEditCustomerModal: null
        },
        template: '<customer-profile></customer-profile>',
        data: {
            windowTitle: CustomerCareProfileWindowTitleSelector,
        }
    });
}
