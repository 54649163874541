import LocaleKeys from '../../../locales/keys';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import {
    BILLER_RULE_INSTANCE_TYPE
} from '../../../customercare.constants';


export const discountTypes = {
    amountOff: {
        text: 'AmountOff',
        value: '3',
        max: 2147483647
    },
    percentageOff: {
        text: 'PercentageOff',
        value: '1',
        max: 100
    }
};

export function createDiscretionaryGridColumnDefs() {
    return [

        {
            field: 'isSelected',
            name: 'isSelected',
            displayName: '',
            minWidth: '42',
            width: '42',
            cellTemplate: require('./cellTemplates/discretionary.checkbox.select.template.html'),
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'name',
            name: 'name',
            displayName: i18n.translate(CoreLocaleKeys.NAME),
            cellTemplate: require('./cellTemplates/name.template.html'),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'amount',
            name: 'amount',
            cellTemplate: require('./cellTemplates/amount.template.html'),
            displayName:  i18n.translate(LocaleKeys.CART.DISCOUNT_MODAL.DISCOUNT_AMOUNT),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'savings',
            name: 'savings',
            cellTemplate: require('./cellTemplates/savings.template.html'),
            displayName:  i18n.translate(LocaleKeys.CART.DISCOUNT_MODAL.DISCOUNT_SAVINGS),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }
    ];
}

export function createRegularGridColumnDefs() {
    return [

        {
            field: 'isSelected',
            name: 'isSelected',
            displayName: '',
            minWidth: '42',
            width: '42',
            cellTemplate: require('./cellTemplates/regular.checkbox.select.template.html'),
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'name',
            name: 'name',
            displayName: i18n.translate(CoreLocaleKeys.NAME),
            cellTemplate: require('./cellTemplates/name.template.html'),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'amount',
            name: 'amount',
            cellTemplate: require('./cellTemplates/amount.template.html'),
            displayName:  i18n.translate(LocaleKeys.CART.DISCOUNT_MODAL.DISCOUNT_AMOUNT),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        },
        {
            field: 'savings',
            name: 'savings',
            cellTemplate: require('./cellTemplates/savings.template.html'),
            displayName:  i18n.translate(LocaleKeys.CART.DISCOUNT_MODAL.DISCOUNT_SAVINGS),
            width: '*',
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }
    ];
}

export const getTypeTranslation = (type) => {
    switch (type) {
        case BILLER_RULE_INSTANCE_TYPE.CUSTOM:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.CUSTOM);
        case BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.ENTITLEMENT);
        case BILLER_RULE_INSTANCE_TYPE.FINANCE:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.FINANCE);
        case BILLER_RULE_INSTANCE_TYPE.ONETIME:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.ONETIME);
        case BILLER_RULE_INSTANCE_TYPE.RECURRING:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.RECURRING);
        case BILLER_RULE_INSTANCE_TYPE.USAGE:
            return i18n.translate(LocaleKeys.BILLER_RULE_INSTANCE_TYPES.USAGE);
        default:
            return '';
    }
};

export function mapStatusIdToStatusName(statusCode, statuses) {
    const status = find(propEq(String(statusCode), 'Value'), statuses);
    return status ? status.Name : '';
}
