import __ from 'ramda/src/__';
import CustomerCareKeys from '../../../../../locales/keys';
import {CurrentBusinessUnitTimeZoneOffsetSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {
    find,
    last,
    propEq,
    remove
} from 'ramda';
import {
    CoreLocaleKeys,
    i18n,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    ScheduleTypes
} from '../decisions.constants';
import {BulkChargeTypes} from '../../../newConnectWizard/new.connect.wizard.constants';
import {PRICE_OVERRIDE_ACCESS} from '../../../../../security.attributes';
import {OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import moment from 'moment';


function EditDecisionOptionController($ngRedux, $timeout) {
    let disconnectRedux;
    const mapStateToTarget = (store) => {
        return {
            currentBUTimeZoneOffset: CurrentBusinessUnitTimeZoneOffsetSelector(store),
            userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
            pricingPlanMetadata: OfferingMetadataPricingPlansSelector(store),
        };
    };
    const controllerActions = {};

    let popupApi = null;
    this.$onInit = () => {
        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.coreLocaleKeys = CoreLocaleKeys;
        this.customerCareKeys = CustomerCareKeys;
        this.showForm = false;

        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        this.resetForm();
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };

        this.scheduleTypes = ScheduleTypes;
        this.hasPriceOverrideAccess = PermissionService.hasAccess(this.state.userSecurityAttributes, PRICE_OVERRIDE_ACCESS);
    };

    this.restoreDefaultPricing = () => {
        this.restoreOptionDefaultPricing()(this.editOption.Id);
    };

    this.getName = (option, brit) => {
        return this.state.pricingPlanMetadata[option.PricingPlanId].BillerRuleInstanceThumbnails.find((MetaDataBrit) => {
            return MetaDataBrit.Id === brit.Id;
        }).Name;
    };

    this.allowPlanOrServiceSwap = () => {
        if (this.editOption) {
            return this.isModify &&
                this.editOption.Bulk &&
                this.editOption.HasServiceIdentifier;
        }
    };

    this.disableUpdatePricingInvalid = () => {
        let disableForm = false;

        this.editOption && this.editOption.BillerRuleInstanceThumbnails.forEach((brit) => {
            if (brit.scheduleType === this.scheduleTypes.CUSTOM) {
                disableForm = this.disableInvalidMinMaxDates(brit);
            }

            if (last(brit.pricingPeriods).EndDate) {
                disableForm = true;
            }
        });

        if (this.formApi.$invalid) {
            disableForm = this.formApi.$invalid;
        }

        return disableForm;
    };

    this.disableInvalidMinMaxDates = (brit) => {
        brit.pricingPeriods.forEach((period, index) => {
            period.disabled = false;

            const startDate = period.StartDate ? new Date(period.StartDate).getTime() : null;
            const endDate = period.EndDate ? new Date(period.EndDate).getTime() : null;
            const startMinDate = this.getStartDateMinDate(brit, index) ? new Date(this.getStartDateMinDate(brit, index)).getTime() : null;
            const startMaxDate = this.getStartDateMaxDate(brit, index) ? new Date(this.getStartDateMaxDate(brit, index)).getTime() : null;
            const endMinDate = this.getEndDateMinDate(brit, index) ? new Date(this.getEndDateMinDate(brit, index)).getTime() : null;
            const endMaxDate = this.getEndDateMaxDate(brit, index) ? new Date(this.getEndDateMaxDate(brit, index)).getTime() : null;

            if (startDate && startDate < startMinDate) {
                period.disabled = true;
            }

            if (startMaxDate && startDate > startMaxDate) {
                period.disabled = true;
            }

            // the last row's end date will have a minimum but must be empty
            if (index === brit.pricingPeriods.length - 1) {
                if (endDate) {
                    period.disabled = true;
                }
            } else {
                if (endDate && endDate < endMinDate) {
                    period.disabled = true;
                }

                if (endMaxDate && endDate > endMaxDate) {
                    period.disabled = true;
                }
            }

            if (!period.StartDate && !period.EndDate) {
                period.disabled = true;
            }
        });

        if (brit.pricingPeriods.some((period) => {
            return period.disabled;
        })) {
            return true;
        }
        return false;
    };

    this.savePricing = () => {
        if (this.isModify) {
            this.editOption = Object.assign({}, this.editOption, {
                isPlanOrServiceSwap: this.isPlanOrServiceChange
            });
            this.saveOptionEdit()(this.editOption);
            this.setEditOption()(this.editOption);
        }
        this.saveBulkPricing()(this.editOption);
        this.updateCart()(() => {
            this.closePopup();
        });
    };

    this.cancelEditPricing = () => {
        this.closePopup();
    };

    this.onPriceChange = () => {
        const params = [];
        if (this.editOption && this.editOption.BillerRuleInstanceThumbnails.length) {
            this.editOption.BillerRuleInstanceThumbnails.forEach((brit) => {
                const britObj = {
                    optionId: this.editOption.Id,
                    brcId : brit.BillerRuleConfigurationId,
                    pricingPeriods: brit.pricingPeriods,
                    scheduleType: brit.scheduleType,
                };
                params.push(britObj);
            });
            this.updateEditOptionPrice()(params);
        }
    };

    this.changeScheduleType = (brit) => {
        if (brit.scheduleType === this.scheduleTypes.NONE) {
            brit.pricingPeriods = [this.buildDefaultPricingPeriod(brit)];
        }

        this.onPriceChange();
    };

    this.addPricingPeriod = (brit) => {
        const newPricingPeriod = this.buildDefaultPricingPeriod(brit);
        newPricingPeriod.StartDate = last(brit.pricingPeriods).EndDate;

        brit.pricingPeriods.push(newPricingPeriod);
    };

    this.buildDefaultPricingPeriod = (brit) => {
        return {
            Amount: brit.Amount,
            ChargeAmount: brit.Amount,
            EndDate: null,
            Quantity: this.editOption.Quantity,
            StartDate: null
        };
    };

    this.removePricingPeriod = (brit, index) => {
        brit.pricingPeriods = remove(index, 1, brit.pricingPeriods);
    };

    this.formatTimeWithBuTimeOffset = (date) => {
        const browserTimeZoneOffsetMinutes = new Date().getTimezoneOffset();
        const buTimezoneOffsetMinutes = moment.duration(this.state.currentBUTimeZoneOffset).asMinutes();
        return moment(date).utc().utcOffset(browserTimeZoneOffsetMinutes + Number(buTimezoneOffsetMinutes), true).toISOString();
    };

    this.onStartDateChanged = (brit, index, date) => {
        brit.pricingPeriods[index].StartDate = this.formatTimeWithBuTimeOffset(date);
        this.onPriceChange();
    };

    this.onEndDateChanged = (brit, index, date) => {
        brit.pricingPeriods[index].EndDate = this.formatTimeWithBuTimeOffset(date);
        this.onPriceChange();
    };

    this.getStartDateMinDate = (brit, index) => {
        if (index > 0) {
            return brit.pricingPeriods[index-1].EndDate || brit.pricingPeriods[index-1].StartDate;
        }
        return moment().startOf('day').toISOString();
    };

    this.getStartDateMaxDate = (brit, index) => {
        if (index > 0 && (brit.pricingPeriods[index-1].EndDate || brit.pricingPeriods[index-1].StartDate)) {
            return brit.pricingPeriods[index-1].EndDate || brit.pricingPeriods[index-1].StartDate;
        }
        if (brit.pricingPeriods[index].EndDate) {
            return brit.pricingPeriods[index].EndDate;
        }
        if (brit.pricingPeriods[+1+index]) {
            return brit.pricingPeriods[+1+index].StartDate || brit.pricingPeriods[+1+index].EndDate;
        }
        return null;
    };

    this.getEndDateMinDate = (brit, index) => {
        if (index > 0 && (brit.pricingPeriods[index-1].EndDate || brit.pricingPeriods[index-1].StartDate)) {
            return brit.pricingPeriods[index-1].EndDate || brit.pricingPeriods[index-1].StartDate;
        }
        if (brit.pricingPeriods[+1+index] && brit.pricingPeriods[+1+index].StartDate) {
            return brit.pricingPeriods[+1+index].StartDate;
        }
        if (brit.pricingPeriods[index].StartDate) {
            return brit.pricingPeriods[index].StartDate;
        }
        if (brit.pricingPeriods[+1+index] && brit.pricingPeriods[+1+index].EndDate) {
            return brit.pricingPeriods[+1+index].EndDate;
        }
        return moment().startOf('day').toISOString();
    };

    this.getEndDateMaxDate = (brit, index) => {
        if (brit.pricingPeriods[+1+index]) {
            return brit.pricingPeriods[+1+index].StartDate || brit.pricingPeriods[+1+index].EndDate;
        }
        return null;
    };

    this.closePopup = () => {
        popupApi.close();
        this.showForm = false;
    };

    this.restoreDefaults = (e) => {
        e.preventDefault();

        this.editOption.BillerRuleInstanceThumbnails.forEach((brit) => {
            brit.pricingPeriods = [this.buildDefaultPricingPeriod(brit)];
            brit.scheduleType = this.scheduleTypes.NONE;
        });
    };

    this.resetForm = () => {
        this.popupTitle = `${i18n.translate(this.customerCareKeys.EDIT_BULK_PLAN.EDIT_BULK_PRICING)} ${this.editOption.Name}`;

        this.editOption.BillerRuleInstanceThumbnails.forEach((brit) => {
            brit.scheduleType = brit.scheduleType || this.scheduleTypes.NONE;
        });
        this.isPlanOrServiceChange = this.editOption.isPlanOrServiceSwap;
    };

    this.getDefaultAmount = (brcId) => {
        const brit = find(propEq(brcId, 'BillerRuleConfigurationId'))(this.editOption.defaultAmounts);
        return brit.CatalogAmount;
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.getIconClass = (billerRuleInstanceThumbnail) => {
        return billerRuleInstanceThumbnail.BulkChargeTypeCode === BulkChargeTypes.FLAT ?
            'filled-circle-f' :
            'filled-circle-u';
    };

    this.showAmountField = (briScheduleType) => {
        return briScheduleType === this.scheduleTypes.NONE;
    };

    this.showSchedulePeriods = (briScheduleType) => {
        return briScheduleType === this.scheduleTypes.CUSTOM;
    };

    this.showBriAmount = (brit) => {
        if (this.editOption.Bulk) {
            return true;
        }

        const filteredBris = this.editOption.PricingPlanBillerRuleInstances ? [
            ...this.editOption.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
            ...this.editOption.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances
        ] : [];

        return filteredBris.find((bri) => {
            return bri.BillerRuleConfigurationId === brit.BillerRuleConfigurationId && bri.AllowChargeOverride;
        });
    };
}

export default {
    template: require('./edit.decision.option.popup.html'),
    bindings: {
        config: '<',
        editOption: '<',
        isModify: '<',
        restoreOptionDefaultPricing: '&',
        saveOptionEdit: '&',
        saveBulkPricing: '&',
        setEditOption: '&',
        updateCart: '&',
        updateEditOptionPrice: '&'
    },
    controllerAs: 'EditDecisionOptionController',
    controller: EditDecisionOptionController
};
