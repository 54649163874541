export default () => {
    return {
        link: (scope, element) => {
            element.one('load', () => {
                element[0].contentWindow.postMessage(scope.sendWidgetData, element[0].src);
                scope.onLoadCallback();
            });
            element.one('fail', () => {
                scope.onFailCallback();
            });
        },
        restrict: 'A',
        scope: {
            onFailCallback: '&',
            onLoadCallback: '&',
            sendWidgetData: '<'
        }
    };
};
