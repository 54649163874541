import {i18n} from 'invision-core';
import CustomerCareLocaleKeys from '../locales/keys';
import transaction from './templates/transaction.pug';

export const transactionDetails = (data) => {
    const labels = i18n.translateObject(CustomerCareLocaleKeys.PRINT_VIEWS.TRANSACTION_DETAILS);
    return transaction(Object.assign({
        data,
        labels
    }));
};
