import {CART_ITEM_TYPE, DEFAULT_CART_ITEM_VIEW_MODEL} from '../../../reducers/constants/products.wizard.constants';

const createCartItem = (item, index = 0) => {
    const grossAmount = item.GrossRenewAmount ? item.GrossRenewAmount : item.PricingPlan.RenewalChargeAmount;
    const totalAmount = item.RenewAmount ? item.RenewAmount : item.PricingPlan.RenewalChargeAmount;
    const currencyCode = item.Currency ? item.Currency : item.PricingPlan.Currency;
    const brandableCurrencyName = item.BrandableCurrencyName ? item.BrandableCurrencyName : item.PricingPlan.BrandableCurrencyName;

    return Object.assign({}, DEFAULT_CART_ITEM_VIEW_MODEL, {
        childItems: item.Children && item.Children.length > 0 ? item.Children.map(createCartItem) : [],
        discountAmount: grossAmount - totalAmount,
        grossAmount: grossAmount,
        totalAmount: totalAmount,
        name: item.Product.Name,
        pricingPlanName: item.PricingPlan.Name,
        pricingPlanId: item.PricingPlan.Id,
        productId: item.Product.Id,
        instanceProperties: item.InstanceProperties,
        quantity: 1,
        id: item.Id,
        index: index,
        isEditable: false,
        isRemovable: false,
        itemType: CART_ITEM_TYPE.REMOVAL,
        currencyCode,
        brandableCurrencyName
    });
};

export const createSubscriptionCartItem = (fullSubscription, editingSubscriptionItemId) => {
    let subscriptionItem = null;

    if (fullSubscription) {
        if (fullSubscription.Items.length > 1 && editingSubscriptionItemId) {
            subscriptionItem = fullSubscription.Items.find((subItem) => {
                return subItem.Id === editingSubscriptionItemId;
            });
        }

        return createCartItem(subscriptionItem || fullSubscription.Items[0]);
    } else {
        return {};
    }
};
