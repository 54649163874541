import {
    FormHelper,
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    updateSubscriberTreatment
} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    UpdateSubscriberTreatmentErrorSelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';

import moment from 'moment';

const ESCALATE = '1';
const DEESCALATE = '2';

class ChangeStepPopupController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.uiNotificationService = uiNotificationService;
        this.ESCALATE = ESCALATE;
        this.DEESCALATE = DEESCALATE;
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                lastAttemptError: UpdateSubscriberTreatmentErrorSelector(store)
            };
        };
        const controllerActions = {
            updateSubscriberTreatment
        };
        this.resetForm();
        this.showForm = false;
        this.defaultDate = moment().add(1, 'days').startOf('day').toDate();
        this.todaysDate = new Date();

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.changeStepCallback = this.onChangeStep() || angular.noop;

        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            this.popupApi = Object.assign({}, api, {
                open: () => {
                    this.resetForm();
                    this.showForm = true;
                    this.$timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };

        this.fieldErrorLookup = {
            TreatmentChangeStepDeescalationDate: {
                'invalidDate': i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.START_DATE_INVALID),
                'minDate': i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CREATE_PROMISE_TO_PAY.ERRORS.START_DATE_IN_PAST)
            }
        };
    }
    $onDestroy() {
        this.disconnectRedux();
    }
    closePopup() {
        this.popupApi.close();
        this.showForm = false;
    }
    handleSubmitForm() {
        if (!this.checkForErrors()) {
            this.actions.updateSubscriberTreatment(this.customerId, this.treatmentChangeStepEscalate, this.treatmentChangeStepDeescalationDate).then(() => {
                this.changeStepCallback(this.treatmentChangeStepEscalate === ESCALATE);
                this.uiNotificationService.success(
                    i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.CHANGE_STEP.SUCCESS_UPDATE),
                    null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                this.closePopup();
            }, () => {
                if (this.state.lastAttemptError && this.state.lastAttemptError.message) {
                    this.serviceError = this.state.lastAttemptError.message;
                }
            });
        }
    }

    checkForErrors() {
        this.serviceError = null;
        this.formErrors = FormHelper.formHasErrors(this.formApi.$error, this.fieldErrorLookup);
        return this.formErrors.length > 0;
    }
    resetForm() {
        // set the default value for escalate.
        this.setDefaultEscalation();
        this.setDeescalationValue();
        this.onChangeEscalation();
        this.serviceError = null;
        this.formErrors = [];
    }
    onChangeEscalation() {
        this.isEscalateSelected = this.treatmentChangeStepEscalate === ESCALATE;
    }
    setDeescalationValue() {
        this.treatmentChangeStepDeescalationDate = this.defaultDate;
    }
    setDefaultEscalation() {
        this.treatmentChangeStepEscalate = this.treatmentNextStepNumber ? ESCALATE : DEESCALATE;
    }
}

export default {
    template: require('./change.step.popup.html'),
    bindings: {
        config: '<',
        treatmentNextStepName: '<',
        treatmentNextStepNumber: '<',
        customerId: '<',
        treatmentEntryDate: '<',
        treatmentNextStepDate: '<',
        onChangeStep: '&'
    },
    controllerAs: 'ChangeStepPopup',
    controller: ChangeStepPopupController
};