import CustomerLocaleKeys from '../../../../../../locales/keys';
import {NUMBER_OF_CAROUSEL_ITEMS_TO_DISPLAY} from '../services.and.offers.contants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
class OfferingThumbnailController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            NUMBER_OF_CAROUSEL_ITEMS_TO_DISPLAY,
            uiNotificationService
        });
    }

    get statusTitle() {
        if (this.offering && this.offering.offerDetails.Status === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE) {
            return i18n.translate(CustomerLocaleKeys.OFFER_STATUS.PENDING_ACTIVE);
        }
        return null;
    }
}

export default {
    bindings: {
        currency: '<',
        offering: '<'
    },
    template: require('./offering.thumbnail.html'),
    controller: OfferingThumbnailController
};
