import {pathOr, values} from 'ramda';
import {i18n} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    OPT_OUT_PROCESS_CREDIT_PAYMENT_SELECTION,
    DIRECT_DEBIT,
    DD_SUB_TYPE_NETHERLANDS,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from './ewallet.constants';

export const getFormattedExpirationForCard = (card = {}) => {
    const month = pathOr('', ['ExpirationMonth'], card).toString();
    const year = pathOr('', ['ExpirationYear'], card).toString().slice(-2);
    const separator = month.length > 0 && year.length > 0 ? '/' : '';

    return `${month}${separator}${year}`;
};

export const isPaymentSelectable = (paymentInstrument) => {
    let isPaymentSelectable = true;
    if (paymentInstrument.Type === parseInt(DIRECT_DEBIT)) {
        if (paymentInstrument.DirectDebit && paymentInstrument.DirectDebit.Type === parseInt(DD_SUB_TYPE_NETHERLANDS)) {
            isPaymentSelectable = false;
        }
    } else {
        return !values(OPT_OUT_PROCESS_CREDIT_PAYMENT_SELECTION).includes(String(paymentInstrument.Type));
    }
    return isPaymentSelectable;
};

export const paymentTypeNameIdentifier = (paymentInstrument) => {
    if ((paymentInstrument.Type === parseInt(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STORED_VALUE_ACCOUNT))
        && paymentInstrument.StoredValueAccount.LoyaltyCard) {
        return i18n.translate(LocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.LOYALTY_CARD);
    }

    return paymentInstrument.TypeName;
};