import propEq from 'ramda/src/propEq';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {ActiveSubtenantsSelector} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {
    fetchCodeTypes,
    fetchCodeTypesThunk
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    LanguagesForCurrentBusinessUnitSelector,
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {setCustomerCategory} from '../../../../reducers/actions/customer.actions';
import {
    clearQuoteAndSetSelectedOffer,
    updateCustomerInfoEditCopy
} from '../../../../reducers/actions/new.connect.wizard.actions';
import {
    CustomerInfoSelector,
    CreateCountryCurrencyValueOptions,
    DefaultCountryCurrencySelector,
    EditCustomerInfoSelector
} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {CustomerTypeFilteredSelector} from '../../../createCustomerPopup/customer.form.dropdown.selectors';
import {SelectedContractSelector} from '../../../../reducers/selectors/contract.selectors';
import {CurrentBusinessUnitHasSubtenants} from '../../../../reducers/selectors/subtenant.selectors';
import {StateRegionProvinceValueOptions} from '../../newConnectWizard/new.connect.wizard.selectors';
import {IsFetchingSelector} from '../../../../reducers/selectors/offering.order.selectors';
import {loadSubscriberRequirements} from '../../../../reducers/helpers/subscriber.requirements.actions.helper';
import {PostalCodeSelector} from '../../../../reducers/selectors/customer.addresses.selectors';
import {setAddressData} from '../../../../reducers/actions/customer.addresses.actions';
import {
    resetSearchCriteria,
    setSearchCriteriaAndSearchContracts
} from '../../../../reducers/actions/contracts.list.actions';
import {
    CUSTOMER_CATEGORY,
    CustomerCategories
} from '../../../createCustomerPopup/create.customer.popup.constants';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../../../customer/addresses/addresses.constants';
import {setSelectedContract} from '../../../../reducers/actions/contract.details.actions';
import {
    IsAddressMandatoryInCreditCheckSelector,
    MarkMandatoryCreditCheckFieldsDisabledSelector
} from '../../../../reducers/selectors/credit.check.selectors';

class ServiceabilityController {
    constructor($ngRedux, uiNotificationService, $timeout, $q) {
        Object.assign(this, {
            addressFormModel: {},
            addressPayload: {},
            $ngRedux,
            $q,
            $timeout,
            uiNotificationService,
            onAddressFormUpdate: this.onAddressFormUpdate.bind(this)
        });
    }

    isResidentialSelected() {
        return this.category.id === CUSTOMER_CATEGORY.RESIDENTIAL;
    }

    mapStateToTarget(store) {
        return {
            addressCountries: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.AddressCountry, store),
            addressStates: StateRegionProvinceValueOptions(store),
            addressStateTypesLoaded: MetadataCodeLoadedSelector(CODES.AddressStateProvinceRegion, store),
            countryCurrencies: CreateCountryCurrencyValueOptions(store),
            currentBusinessUnit: CurrentBusinessUnitSelector(store),
            currentBusinessUnitHasSubtenants: CurrentBusinessUnitHasSubtenants(store),
            currentSubtenants: ActiveSubtenantsSelector(store),
            customerInfo: CustomerInfoSelector(store),
            customerTypeFilteredOptions: CustomerTypeFilteredSelector(store),
            defaultCountryCurrency: DefaultCountryCurrencySelector(store),
            editCustomerInfo: EditCustomerInfoSelector(store),
            isAddressCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
            isAddressMandatoryInCreditCheck: IsAddressMandatoryInCreditCheckSelector(store),
            isCountryCurrenciesLoaded: MetadataCodeLoadedSelector(CODES.CountryCurrency, store),
            isFetchingAvailableOffers: IsFetchingSelector(store),
            languages: LanguagesForCurrentBusinessUnitSelector(store),
            markMandatoryCreditCheckFieldsDisabled: MarkMandatoryCreditCheckFieldsDisabledSelector(store),
            personTitleOptions: MetadataOptionsForCodeValuesSelector(CODES.PersonTitle, store),
            postalCode: PostalCodeSelector(store),
            selectedContract: SelectedContractSelector(store),
            subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store)
        };
    }

    openContractPopup() {
        this.contractDialogOpened = true;
        this.$timeout(() => {
            this.contractPopupApi.open();
        });
    }

    closeContractPopup() {
        this.contractPopupApi.close();
        this.contractDialogOpened = false;
    }

    successContractPopup(selectedContract) {
        this.contractPopupApi.close();
        this.contractDialogOpened = false;
        this.actions.setSelectedContract(selectedContract);
        this.selectedContractId = selectedContract.Id;
    }

    clearSelectedContract() {
        this.selectedContractId = null;
        this.actions.setSelectedContract(null);
    }

    setRequirements() {
        this.requirements = this.isResidentialSelected() ? this.state.subscriberRequirements : this.state.subscriberCategoryRequirements;
    }

    onAddressFormUpdate(addressFormModel) {
        this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
        this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
        this.addressFormModel.city = addressFormModel.city;
        this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;

        if (addressFormModel.country && (addressFormModel.country !== this.addressFormModel.country)) {
            this.addressFormModel.country = addressFormModel.country;
            this.handleChangeFormCountry();
        } else {
            this.updateCustomerInfoEditCopy();
        }
    }

    $onInit() {
        const controllerActions = {
            clearQuoteAndSetSelectedOffer,
            fetchCodeTypes,
            fetchCodeTypesThunk,
            loadSubscriberRequirements,
            resetSearchCriteria,
            setAddressData,
            setCustomerCategory,
            setSearchCriteriaAndSearchContracts,
            setSelectedContract,
            updateCustomerInfoEditCopy
        };
        this.contractPopupApi = null;
        this.contractPopupConfig = {
            onRegisterApi: (event) => {
                this.contractPopupApi = event.api;
            }
        };

        this.contractTermsConditionsPopupConfig = {
            onRegisterApi: (event) => {
                this.contractTermsConditionsPopupApi = event.api;
            }
        };

        this.openContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupOpened = true;
            this.$timeout(() => {
                this.contractTermsConditionsPopupApi.open();
            });
        };

        this.handleCloseContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupApi.close();
            this.contractTermsConditionsPopupOpened = false;
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.actions.resetSearchCriteria();

        this.isLoaded = false;
        this.requirements = {};

        this.selectedContractId = null;

        this.actions.loadSubscriberRequirements().then(() => {
            this.setRequirements();
            this.isLoaded = true;
        });

        this.formMaxLengthValidation = ADDRESS_FORM_FIELD_MAX_LENGTH;

        this.customerCategories = CustomerCategories();
        this.category = this.state.customerInfo.Category === undefined ? this.customerCategories[0] : this.customerCategories.find(propEq(this.state.customerInfo.Category, 'id'));
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.CoreLocaleKeys = CoreLocaleKeys;
        // this is not best practice, but due to the BU reloading on security invalidation we needed to stop the languages options from empting out.
        this.languageOptions = this.state.languages.slice();
        this.actions.setCustomerCategory(this.category.id);
        this.actions.updateCustomerInfoEditCopy(this.state.customerInfo);

        this.updateCustomerInfoEditCopy = () => {
            let addressUpdated = false;
            if (this.addressFormModel.country !== this.addressPayload.Country) {
                addressUpdated = Object.values(this.addressPayload).length;
                if (this.addressFormModel.country) {
                    this.addressPayload.Country = this.addressFormModel.country;
                }
                this.actions.setAddressData(this.addressPayload);
            }
            this.setRequirements();
            this.actions.updateCustomerInfoEditCopy({
                addressLine1: this.addressFormModel.addressLine1,
                addressLine2: this.addressFormModel.addressLine2,
                Category: this.category.id,
                city: this.addressFormModel.city,
                CompanyName: this.category.id === CUSTOMER_CATEGORY.COMMERCIAL ? this.CompanyName : '',
                country: this.addressFormModel.country,
                FirstName: this.category.id === CUSTOMER_CATEGORY.RESIDENTIAL ? this.FirstName : '',
                HomeCountry: this.addressFormModel.country,
                Language: this.language,
                LastName: this.category.id === CUSTOMER_CATEGORY.RESIDENTIAL ? this.LastName : '',
                postalCode: this.addressFormModel.postalCode,
                PreferredName: this.preferredName,
                PreferredTaxReportingCurrency: addressUpdated ? null : this.state.editCustomerInfo.PreferredTaxReportingCurrency,
                stateRegionProvince: this.addressFormModel.stateRegionProvince,
                SubscriberCurrency: this.currency,
                SubscriberTypeCode: this.type ? this.type.value : '',
                SubtenantId: this.subtenant ? this.subtenant.Id : null,
                Title: this.title
            });
        };

        this.handleChangeFormCountry = () => {
            // This function sets the HomeCountry in the REDUX store which correctly populates *this.state.countryCurrencies*
            // Hence, we end up making 2 *this.updateCustomerInfoEditCopy()* calls when the Country is changed.
            this.updateCustomerInfoEditCopy();
            if (this.state.isCountryCurrenciesLoaded && this.state.countryCurrencies) {
                this.updateCountryCurrencyField();
            } else {
                this.uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.CURRENCY_NOT_FOUND), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }
        };

        this.handleChangeFormCurrency = () => {
            this.actions.clearQuoteAndSetSelectedOffer();
            this.updateCustomerInfoEditCopy();
        };

        this.updateCountryCurrencyField = () => {
            if (this.state.countryCurrencies.find((currencyOption) => {
                return currencyOption.value === this.state.editCustomerInfo.SubscriberCurrency;
            })) {
                this.currency = this.state.editCustomerInfo.SubscriberCurrency;
            } else {
                this.currency = this.state.defaultCountryCurrency ? this.state.defaultCountryCurrency.Value : this.state.countryCurrencies[0].value;
            }

            if (this.currency && this.state.customerInfo.SubscriberCurrency !== this.currency) {
                this.handleChangeFormCurrency();
            } else {
                this.updateCustomerInfoEditCopy();
            }
        };

        this.persistData();
        if (!this.type && this.customerTypeOptions && this.customerTypeOptions[this.category.id] &&
            this.customerTypeOptions[this.category.id].length) {
            this.cloneCustomerTypes();
        }

        this.onUpdateSubcriberCategoryModel = () => {
            this.subtenant = null;
            this.actions.setCustomerCategory(this.category.id);
            this.cloneCustomerTypes();
            this.updateCustomerInfoEditCopy();
        };

        this.onUpdateSubcriberTypeModel = () => {
            if (this.type) {
                this.updateCustomerInfoEditCopy();
            }
        };


        this.onlyOneCountryAndOneCurrency = () => {
            return (this.state.addressCountries.length === 1 && this.state.countryCurrencies.length === 1);
        };

        //Object assign below is if coming from previous step
        Object.assign(this.addressFormModel, this.state.customerInfo);
        this.addressFormModel.country = this.state.customerInfo.HomeCountry ? this.state.customerInfo.HomeCountry : this.state.currentBusinessUnit.defaultCountry;
        this.language = this.state.customerInfo.Language ? this.state.customerInfo.Language : this.state.currentBusinessUnit.defaultLanguage;

        if (this.state.isCountryCurrenciesLoaded && this.state.isAddressCountriesLoaded) {
            this.handleChangeFormCountry();
        } else {
            const promises = [];
            //Added below back in due to country-currency dependency specifically oneCountryOneCurrency
            if (!this.state.isAddressCountriesLoaded) {
                promises.push(this.actions.fetchCodeTypes(CODES.AddressCountry));
            }
            if (!this.state.isCountryCurrenciesLoaded) {
                promises.push(this.actions.fetchCodeTypesThunk(CODES.CountryCurrency));
            }
            this.$q.all(promises).then(() => {
                this.handleChangeFormCountry();
            });
        }

        if (!this.state.addressStateTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressStateProvinceRegion);
        }
    }

    $onChanges(changeObj) {
        if (this.state && changeObj.updateStateValues
            && changeObj.updateStateValues.previousValue !== changeObj.updateStateValues.currentValue) {
            this.persistData();
        }

        if ( changeObj.customerTypeOptions && !changeObj.customerTypeOptions.isFirstChange()
            && changeObj.customerTypeOptions.currentValue !== changeObj.customerTypeOptions.previousValue) {
            this.cloneCustomerTypes();
        }
    }

    cloneCustomerTypes() {
        this.customerTypes = this.customerTypeOptions[this.category.id];
        if (this.customerTypes) {
            const selectedCustomerType = this.customerTypes.find(propEq(this.state.customerInfo.SubscriberTypeCode, 'value'));
            if (this.state.customerInfo.SubscriberTypeCode && selectedCustomerType) {
                this.type = selectedCustomerType;
            } else {
                this.type = this.customerTypes.find(propEq(true, 'default')) || this.customerTypes[0];
            }
        }
    }

    isDisabled() {
        return this.state.isAddressMandatoryInCreditCheck && this.state.markMandatoryCreditCheckFieldsDisabled;
    }

    persistData() {
        //This will get triggered after creditCheckModal is successful
        this.FirstName = this.state.customerInfo.FirstName;
        this.LastName = this.state.customerInfo.LastName;
        this.CompanyName = this.state.customerInfo.CompanyName;
        this.currency = this.state.customerInfo.SubscriberCurrency;
        this.language = this.state.customerInfo.Language;
        this.preferredName = this.state.customerInfo.PreferredName;
        this.subtenant = this.getSubtenant();
        this.selectedContractId = this.state.selectedContract ? this.state.selectedContract.Id : null;
        this.title = this.state.customerInfo.Title;
        this.onAddressFormUpdate({
            addressLine1: this.state.customerInfo.addressLine1,
            addressLine2: this.state.customerInfo.addressLine2,
            city: this.state.customerInfo.city,
            country: this.state.customerInfo.country,
            stateRegionProvince: this.state.customerInfo.stateRegionProvince,
            postalCode: this.state.customerInfo.postalCode,
        });
    }

    getSubtenant() {
        return this.state.customerInfo.SubtenantId ?
            this.state.currentSubtenants.find((subtenant) => {
                return subtenant.Id.Value === this.state.customerInfo.SubtenantId.Value;
            })
            : null;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./serviceability.html'),
    controller: ServiceabilityController,
    controllerAs: 'serviceabilityController',
    require: {
        wizardForm: '^form'
    },
    bindings: {
        contracts: '<',
        updateStateValues: '<',
        customerTypeOptions: '<',
        isNewConnect: '<'
    }
};
