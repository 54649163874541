import clone from 'ramda/src/clone';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import CareLocaleKeys from '../../../../locales/keys';
import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import {
    TAX_LOCATION_CUSTOM_OPTIONS,
    TAX_LOCATION_CUSTOM_OPTIONS_HASH,
    TAX_RULE_OPTIONS,
    TAX_SERVICE_LOCATION_REFERENCES
} from '../../../../customercare.constants';
import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';
class ServiceTaxCustomizationController {
    constructor( $ngRedux) {
        Object.assign(this, {
            $ngRedux,
            AddressHelper,
            CareLocaleKeys,
            CoreLocaleKeys,
            billToLocationToolTipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.BILL_TO_LOCATION_TOOLTIP_CONTENT),
            getOriginatingPercentageSplit: this.getOriginatingPercentageSplit.bind(this),
            handleAddressChange: this.handleAddressChange.bind(this),
            isCustomTaxRule: TAX_RULE_OPTIONS.DEFAULT,
            lastStoredTaxDetails: null,
            originatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.ORIGINATING_LOCATION_TOOLTIP_CONTENT),
            TAX_LOCATION_CUSTOM_OPTIONS_HASH,
            TAX_LOCATION_CUSTOM_OPTIONS,
            taxBillToLocationOptionValue: '',
            taxOriginatingLocationOptionValue: '',
            taxTerminatingLocationOptionValue: '',
            terminatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.TERMINATING_LOCATION_TOOLTIP_CONTENT),
            TAX_RULE_OPTIONS,
            TAX_SERVICE_LOCATION_REFERENCES,
            updateBillToLocation: this.updateBillToLocation.bind(this),
            updateCustomOptionValue: this.updateCustomOptionValue.bind(this),
            updateOriginatingLocation: this.updateOriginatingLocation.bind(this),
            updateTerminatingLocation: this.updateTerminatingLocation.bind(this),
            validateNumbers
        });
        this.initTaxLocationOptions();
    }

    $onChanges(changesObj) {
        if (changesObj.taxLocationOptionsByPricingPlan) {
            this.taxLocationOptionsByPricingPlanViewModel = clone(changesObj.taxLocationOptionsByPricingPlan.currentValue);
        }
        if (changesObj.pricingPlanId || changesObj.instanceNumber) {
            if (this.taxLocationOptionsByPricingPlanViewModel &&
                this.taxLocationOptionsByPricingPlanViewModel[this.pricingPlanId]) {
                if (this.pricingPlanId && this.instanceNumber) {
                    this.setCustomTaxLocationRules(this.taxLocationOptionsByPricingPlanViewModel[this.pricingPlanId][this.instanceNumber].selectedTaxOption ||
                    this.taxLocationOptionsByPricingPlanViewModel[this.pricingPlanId][this.instanceNumber].taxOption, true);
                    this.initCustomTaxLocation();
                    this.getOriginatingPercentageSplit(true);
                }

            }
        }

        if (changesObj.addresses && changesObj.addresses.currentValue) {
            this.taxLocationCustomOptions.splice(1, 0, {
                id: TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE,
                label: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.ADDRESS_ON_FILE)
            });
            this.selectedOriginatingTaxLocationAddress = this.addresses[0].Id;
            this.selectedTerminatingTaxLocationAddress = this.addresses[0].Id;
            this.selectedBillToTaxLocationAddress = this.addresses[0].Id;
            this.initCustomTaxLocation();
        }

        if (changesObj.disableDefaultLocation) {
            this.initTaxLocationOptions();
            this.initCustomTaxLocation();
        }
    }

    $onInit() {
        if (this.isFromPopUp) {
            this.isCustomTaxRule = this.TAX_RULE_OPTIONS.CUSTOM;
            this.lastStoredTaxDetails = this.taxLocationOptionsByPricingPlan;
            this.initCustomTaxLocation();
            this.getOriginatingPercentageSplit(true);
        }
    }

    initTaxLocationOptions() {
        this.taxLocationCustomOptions = [...(this.disableDefaultLocation ? [] : [{
            id: TAX_LOCATION_CUSTOM_OPTIONS.DEFAULT,
            label: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.DEFAULT_TAX_LOGIC)
        }]),
        {
            id: TAX_LOCATION_CUSTOM_OPTIONS.NPANXX,
            label: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.NPA_NXX)
        }];
    }

    initCustomTaxLocation() {
        if (this.isFromPopUp || this.taxLocationOptionsByPricingPlan[this.pricingPlanId]) {
            const selectedTaxLocationModel = !this.isFromPopUp ?
                this.taxLocationOptionsByPricingPlanViewModel[this.pricingPlanId][this.instanceNumber] :
                this.taxLocationOptionsByPricingPlanViewModel;
            //originatingLocation tax initialization
            this.taxOriginatingLocationCustomOption = selectedTaxLocationModel.ServiceTaxCustomization.OriginatingTaxServiceLocation ?
                selectedTaxLocationModel.ServiceTaxCustomization.OriginatingTaxServiceLocation.TaxLocationType :
                this.taxLocationCustomOptions[0].id;
            if (this.taxOriginatingLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX) {
                this.taxOriginatingLocationOptionValue = selectedTaxLocationModel.ServiceTaxCustomization.OriginatingTaxServiceLocation?.[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxOriginatingLocationCustomOption]] || '';
            } else if (this.taxOriginatingLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE) {
                this.selectedOriginatingTaxLocationAddress = selectedTaxLocationModel.ServiceTaxCustomization.OriginatingTaxServiceLocation[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxOriginatingLocationCustomOption]];
            }
            //billToLocation tax initialization
            this.taxBillToLocationCustomOption = selectedTaxLocationModel.ServiceTaxCustomization.BillToLocation ?
                selectedTaxLocationModel.ServiceTaxCustomization.BillToLocation.TaxLocationType :
                this.taxLocationCustomOptions[0].id;
            if (this.taxBillToLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX) {
                this.taxBillToLocationOptionValue = selectedTaxLocationModel.ServiceTaxCustomization.BillToLocation?.[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxBillToLocationCustomOption]] || '';
            } else if (this.taxBillToLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE) {
                this.selectedBillToTaxLocationAddress = selectedTaxLocationModel.ServiceTaxCustomization.BillToLocation[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxBillToLocationCustomOption]];
            }
            //terminatingLocation tax initialization
            this.taxTerminatingLocationCustomOption = selectedTaxLocationModel.ServiceTaxCustomization.TerminatingTaxServiceLocation ?
                selectedTaxLocationModel.ServiceTaxCustomization.TerminatingTaxServiceLocation.TaxLocationType :
                this.taxLocationCustomOptions[0].id;
            if (this.taxTerminatingLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX) {
                this.taxTerminatingLocationOptionValue = selectedTaxLocationModel.ServiceTaxCustomization.TerminatingTaxServiceLocation?.[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxTerminatingLocationCustomOption]] || '';
            } else if (this.taxTerminatingLocationCustomOption === this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE) {
                this.selectedTerminatingTaxLocationAddress = selectedTaxLocationModel.ServiceTaxCustomization.TerminatingTaxServiceLocation[TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.taxTerminatingLocationCustomOption]];
            }
        }
    }

    getOriginatingPercentageSplit(donNotTriggerUpdate) {
        this.originatingPercentage = this.isFromPopUp ? this.taxLocationOptionsByPricingPlan.ServiceTaxCustomization.OriginatingPercentage || this.defaultTaxSplitPercentage :
            this.taxLocationOptionsByPricingPlan[this.pricingPlanId] && this.taxLocationOptionsByPricingPlan[this.pricingPlanId][this.instanceNumber].ServiceTaxCustomization.OriginatingPercentage || this.defaultTaxSplitPercentage;
        if (this.originatingPercentage) {
            this.originatingPercentage = parseInt(this.originatingPercentage * 100);
            this.calculatePercentageSplit(donNotTriggerUpdate);
        }
    }

    updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocationOption, taxServiceLocationOptionType, taxServiceLocationOptionValue) {
        if (taxServiceLocationOptionValue) {
            cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocationOption][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[taxServiceLocationOptionType]] = taxServiceLocationOptionValue;
        }
        cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocationOption].TaxLocationType = taxServiceLocationOptionType;
    }

    setUnsetCustomTaxOptions(cloneTaxLocationItems, taxServiceLocation) {
        if (!cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation]) {
            cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation] = {};
        }
        if (this.TAX_SERVICE_LOCATION_REFERENCES.ORIGINATING_TAX_SERVICE_LOCATION === taxServiceLocation) {
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.DEFAULT === this.taxOriginatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxOriginatingLocationCustomOption);
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX === this.taxOriginatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxOriginatingLocationCustomOption, this.taxOriginatingLocationOptionValue);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX]];
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE === this.taxOriginatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxOriginatingLocationCustomOption, this.selectedOriginatingTaxLocationAddress);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE]];
            }

        } else if (this.TAX_SERVICE_LOCATION_REFERENCES.TERMINATING_TAX_SERVICE_LOCATION === taxServiceLocation) {
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.DEFAULT === this.taxTerminatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxTerminatingLocationCustomOption);
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX === this.taxTerminatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxTerminatingLocationCustomOption, this.taxTerminatingLocationOptionValue);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX]];
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE === this.taxTerminatingLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxTerminatingLocationCustomOption, this.selectedTerminatingTaxLocationAddress);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE]];
            }
        } else if (this.TAX_SERVICE_LOCATION_REFERENCES.BILL_TO_LOCATION === taxServiceLocation) {
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.DEFAULT === this.taxBillToLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxBillToLocationCustomOption);
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX === this.taxBillToLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxBillToLocationCustomOption, this.taxBillToLocationOptionValue);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.NPANXX]];
            }
            if (this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE === this.taxBillToLocationCustomOption) {
                this.updateTaxCustomLocationValues(cloneTaxLocationItems, taxServiceLocation, this.taxBillToLocationCustomOption, this.selectedBillToTaxLocationAddress);
            } else {
                delete  cloneTaxLocationItems.ServiceTaxCustomization[taxServiceLocation][this.TAX_LOCATION_CUSTOM_OPTIONS_HASH[this.TAX_LOCATION_CUSTOM_OPTIONS.ADDRESS_ON_FILE]];
            }

        }
        if (!this.isFromPopUp) {
            cloneTaxLocationItems.taxOption = TAX_RULE_OPTIONS.CUSTOM;
        }

    }
    updateOriginatingLocation() {
        this.taxOriginatingLocationOptionValue = '';
        const cloneTaxLocationItems = clone(this.taxLocationOptionsByPricingPlan);
        this.setUnsetCustomTaxOptions(this.isFromPopUp ?
            cloneTaxLocationItems :
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber], this.TAX_SERVICE_LOCATION_REFERENCES.ORIGINATING_TAX_SERVICE_LOCATION);
        this.lastStoredTaxDetails = cloneTaxLocationItems;
        this.onUpdate()(cloneTaxLocationItems);
    }

    updateTerminatingLocation() {
        this.taxTerminatingLocationOptionValue = '';
        const cloneTaxLocationItems = clone(this.taxLocationOptionsByPricingPlan);
        this.setUnsetCustomTaxOptions(this.isFromPopUp ?
            cloneTaxLocationItems :
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber], this.TAX_SERVICE_LOCATION_REFERENCES.TERMINATING_TAX_SERVICE_LOCATION);
        this.lastStoredTaxDetails = cloneTaxLocationItems;
        this.onUpdate()(cloneTaxLocationItems);
    }

    updateBillToLocation() {
        this.taxBillToLocationOptionValue = '';
        const cloneTaxLocationItems = clone(this.taxLocationOptionsByPricingPlan);
        this.setUnsetCustomTaxOptions(this.isFromPopUp ?
            cloneTaxLocationItems :
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber], this.TAX_SERVICE_LOCATION_REFERENCES.BILL_TO_LOCATION);
        this.lastStoredTaxDetails = cloneTaxLocationItems;
        this.onUpdate()(cloneTaxLocationItems);
    }

    updateCustomOptionValue(taxServiceLocation) {
        const cloneTaxLocationItems = clone(this.taxLocationOptionsByPricingPlan);
        this.setUnsetCustomTaxOptions(this.isFromPopUp ?
            cloneTaxLocationItems :
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber], taxServiceLocation);
        this.lastStoredTaxDetails = cloneTaxLocationItems;
        this.onUpdate()(cloneTaxLocationItems);
    }

    handleAddressChange(taxServiceLocation) {
        this.updateCustomOptionValue(taxServiceLocation);
    }

    calculatePercentageSplit(donNotTriggerUpdate) {
        if (this.originatingPercentage > 100) {
            this.originatingPercentage = 100;
        }
        this.remainingPercentageSplit = (100 - this.originatingPercentage);
        const cloneTaxLocationItems = clone(this.taxLocationOptionsByPricingPlan);
        if (!this.isFromPopUp) {
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber].ServiceTaxCustomization.OriginatingPercentage = this.originatingPercentage / 100;
            cloneTaxLocationItems[this.pricingPlanId][this.instanceNumber].taxOption = TAX_RULE_OPTIONS.CUSTOM;
        } else {
            cloneTaxLocationItems.ServiceTaxCustomization.OriginatingPercentage = this.originatingPercentage / 100;
        }
        if (!donNotTriggerUpdate) {
            this.lastStoredTaxDetails = cloneTaxLocationItems;
            this.onUpdate()(cloneTaxLocationItems);
        }
    }

    restoreTaxLocationInitialValues() {
        const itemToModify = clone(this.taxLocationOptionsByPricingPlan);
        const ServiceTaxCustomizedDefault = {
            BillToLocation: {
                TaxLocationType: 0
            },
            OriginatingTaxServiceLocation: {
                TaxLocationType: 0
            },
            TerminatingTaxServiceLocation: {
                TaxLocationType: 0
            }
        };
        if (!this.isFromPopUp) {
            itemToModify[this.pricingPlanId][this.instanceNumber].ServiceTaxCustomization = ServiceTaxCustomizedDefault;
            itemToModify[this.pricingPlanId][this.instanceNumber].taxOption = this.TAX_RULE_OPTIONS.DEFAULT;
        } else {
            ServiceTaxCustomizedDefault.SubscriberProductId = this.taxLocationOptionsByPricingPlan.ServiceTaxCustomization.SubscriberProductId;
            itemToModify.ServiceTaxCustomization = ServiceTaxCustomizedDefault;
        }
        this.onUpdate()(itemToModify);
    }

    setCustomTaxLocationRules(val, doNotTriggerUpdate) {
        if (this.pricingPlanId && this.instanceNumber) {
            this.taxLocationOptionsByPricingPlanViewModel[this.pricingPlanId][this.instanceNumber].taxOption = val;
            this.isCustomTaxRule = val;
        }
        this.isCustomTaxRule = val;
        if (val === this.TAX_RULE_OPTIONS.DEFAULT && !doNotTriggerUpdate) {
            this.restoreTaxLocationInitialValues();
        }

        if (this.isFromPopUp && val === this.TAX_RULE_OPTIONS.CUSTOM) {
            this.onUpdate()(this.lastStoredTaxDetails);
        }
    }
}

export default {
    bindings: {
        addresses: '<?',
        defaultTaxSplitPercentage: '<?',
        disableDefaultLocation: '<?',
        hideBillingLocation: '<?',
        instanceNumber: '<?',
        isFromPopUp: '<?',
        onUpdate: '&',
        pricingPlanId: '<?',
        showError: '<?',
        taxLocationOptionsByPricingPlan: '<'
    },
    template: require('./service.tax.customization.template.html'),
    controller: ServiceTaxCustomizationController,
    controllerAs: 'ServiceTaxCustomizationController'
};