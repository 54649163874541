import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {CustomerEnabledWrapUpCodesSelector} from '../../../../../reducers/selectors/customer.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';

class CustomerWrapupModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareLocaleKeys,
            onModelChange: this.onModelChange.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                customerWrapUpCodes: CustomerEnabledWrapUpCodesSelector(store),
                isCustomerWrapUpLoaded: MetadataCodeLoadedSelector(CODES.CustomerWrapUp, store)
            };
        };

        const controllerActions = {
            fetchCodeTypesThunk
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isCustomerWrapUpLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.CustomerWrapUp);
        }

    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onModelChange(customerWrapUpModel) {
        this.onWrapupFormChange()(customerWrapUpModel);
    }
}

export default {
    bindings: {
        onWrapupFormChange: '&'
    },
    template: require('./customer.wrapup.html'),
    controller: CustomerWrapupModalController
};
