import {AngularFormUtilities} from 'invision-core';
import LocaleKeys from '../../../../../locales/keys';
import {updateServiceFriendlyName} from '../dbss.dashboard.actions';

class UpdateServiceFriendlyNameModalController {
    constructor($ngRedux, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout
        });
    }

    $onInit() {
        this.localeKeys = LocaleKeys;
        this.MAX_NAME_LENGTH = 200;
        this.newFriendlyName = this.serviceIdentifier.FriendlyName;

        const mapStateToTarget = () => {
            return {};
        };
        const controllerActions = {
            updateServiceFriendlyName
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }
    handleClose() {
        this.onClose()();
    }

    validateForm() {
        this.$timeout(() => {
            this.formErrors = AngularFormUtilities.constraintErrorMessages(undefined, this.friendlyNameForm, {
                friendlyName: LocaleKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.LABEL
            }, {
                maxlength: {
                    friendlyName: this.MAX_NAME_LENGTH
                }
            });
        });
    }

    submitForm() {
        if (this.friendlyNameForm.$valid) {
            this.isUpdating = true;
            this.actions.updateServiceFriendlyName(this.customerId, this.serviceIdentifier.Value, this.newFriendlyName).then(
                () => {
                    this.onSubmit()();
                    this.isUpdating = false;
                },
                (error) => {
                    this.formErrors = [error.translatedMessage];
                    this.isUpdating = false;
                }
            );
        } else {
            this.validateForm();
        }
    }
}

export default {
    template: require('./update.friendly.name.modal.html'),
    controller: UpdateServiceFriendlyNameModalController,
    bindings: {
        onClose: '&',
        onSubmit: '&',
        customerId: '<',
        serviceIdentifier: '<'
    }
};
