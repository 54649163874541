import {DiscretionaryDiscountsByCurrencySelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';
import {
    cloneDeep
} from 'lodash';
import * as DiscountActions from '../../../reducers/actions/discount.actions';
import LocaleKeys from '../../../locales/keys';
import {DiscountFormSelector} from '../../../reducers/selectors/discount.selectors';

class DiscountDialogController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            formLookup: {
                discount: LocaleKeys.DISCOUNT_DIALOG.DISCRETIONARY_DISCOUNT,
                reason: LocaleKeys.DISCOUNT_DIALOG.REASON
            },
            LocaleKeys,
            onReasonSelected: this.onReasonSelected.bind(this)
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                discountForm: DiscountFormSelector(store),
                discretionaryDiscountsByCurrency: DiscretionaryDiscountsByCurrencySelector(store)
            };
        };

        const controllerActions = {
            clearDiscountForm: DiscountActions.clearDiscountForm,
            setDiscountForm: DiscountActions.setDiscountForm
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.selectedDiscounts = cloneDeep(this.state.discountForm.selectedDiscounts) || {};
    }

    get discretionaryDiscounts() {
        return this.state.discretionaryDiscountsByCurrency[this.currency] || [];
    }

    onDiscountSelected() {
        this.actions.setDiscountForm(Object.assign({}, this.state.discountForm, {
            selectedDiscounts: this.selectedDiscounts
        }));
    }

    onReasonSelected(item) {
        this.actions.setDiscountForm(Object.assign({}, this.state.discountForm, {
            discountReason: item
        }));
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleClose() {
        this._resetForm();
        this.onClose()();
    }

    handleKeypressForm(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmitForm();
        }
    }

    handleSubmitForm() {
        this.discountDialogForm.$submitted = true;
        if (this.discountDialogForm.$valid) {
            this.onSubmit()(Object.assign({}, this.state.discountForm, {
                selectedDiscounts: this.selectedDiscounts
            }));
            this._resetForm();
        }
    }

    _resetForm() {
        this.selectedDiscounts = {};
        this.actions.clearDiscountForm();
    }

    $onChanges() {
        if (this.state && this.state.discountOptions) {
            this.selectedDiscountId = this.state.discountOptions[0].value;
        }
    }
}

export default {
    bindings: {
        applyToRenewal: '<?',
        formErrors: '<',
        isLoading: '<',
        currency: '<',
        onClose: '&',
        onSubmit: '&'
    },
    template: require('./discount.dialog.html'),
    controller: DiscountDialogController
};
