import {PORTAL_ACCESS} from '../../security.attributes';
import {ApplicationStateController, MetadataActions, MetadataConstants, MetadataSelectors} from 'invision-core';

export function routing($stateProvider, $urlRouterProvider) {
    $stateProvider.state('index', {
        url: '',
        abstract: true,
        template: '<main class="u-sizeFull"></main>',
        unauthenticated: true,
        controller: function(SessionService, $log) {
            SessionService.load();
            $log.info('Invision application initialized successfully.');
        }
    });

    $stateProvider.state('index.customercare', {
        url: '/app',
        template: '<customer-care-page></customer-care-page>',
        params: {
            securityAttributeId: PORTAL_ACCESS.id
        },
        securityAttributes: [PORTAL_ACCESS],
        resolve: {
            codeTablesReady: ($q, $ngRedux) => {
                if (!MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ConvergentBillerConfigurationDetail, $ngRedux.getState())) {
                    return MetadataActions.codes.fetchConvergentBillerConfigurationDetailTypesPromise($ngRedux.dispatch);
                } else {
                    return $q.when();
                }
            }
        },
        controller: ApplicationStateController
    });

    $urlRouterProvider.otherwise(function($injector, $location) {
        $injector.invoke(['$state', function($state) {
            const requestedPath = $location.url();

            if (requestedPath && '/' !== requestedPath && !requestedPath.startsWith('/app/')) {
                $state.go('index.internalRedirect', {
                    path: requestedPath
                }, {
                    location: false,
                    notify: true
                });
            } else {
                $state.go('index.login');
            }
        }]);
    });
}
