export const getFormattedName = (member) => {
    member = member || {};
    const nameArray = [];
    if (member.FirstName) {
        nameArray.push(member.FirstName);
    }
    if (member.LastName) {
        nameArray.push(member.LastName);
    }

    return nameArray.join(' ');
};

export const getMemberBySubscriberId = (householdMembers, subscriberId) => {
    householdMembers = householdMembers || [];
    return householdMembers.find((member) => {
        return member.SubscriberId === subscriberId;
    });
};

export const sortMemberListByAlpheticalOrder = (householdMembers, headOfHousehold, includeHoH) => {
    let HoH;
    let newList;

    if (headOfHousehold && householdMembers) {

        if (includeHoH) {
            HoH = householdMembers.find((item) => {
                return item.SubscriberId === headOfHousehold.SubscriberId;
            });

            householdMembers = householdMembers
                .filter((item) => {
                    return item.SubscriberId !== HoH.SubscriberId;
                });
        }

        newList = householdMembers.sort((a, b) => {
            a = a.formattedName.toUpperCase();
            b = b.formattedName.toUpperCase();

            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        });

        return includeHoH ? Array(HoH).concat(newList) : newList;
    }
};

//This will take a group of ratings by category and find the
//value in the ratingPrivileges to display the highest access level for that category
export const findLastSelectedRating = (ratings, memberRatings) => {
    if (ratings && memberRatings) {
        const categoryValues = ratings.map(({Value}) => {
            return parseInt(Value);
        });
        const applicableMemberRatings = memberRatings.filter((item) => {
            return categoryValues.includes(item);
        });

        const ratingValue = applicableMemberRatings.reduce((prev, next) => {
            return prev > next ? prev : next;
        }, null);

        return ratings.find((item) => {
            return parseInt(item.Value) === ratingValue;
        });
    } else {
        return null;
    }
};
