import LocaleKeys from '../../../../../locales/keys';

class InstancePropertiesTabController {
    constructor() {
        this.localeKeys = LocaleKeys;
    }
}

export default {
    controller: InstancePropertiesTabController,
    template: require('./instance.properties.tab.html'),
    bindings: {
        properties: '<'
    }
};