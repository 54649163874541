import moment from 'moment';
import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    GetMaxDateForFutureDatedOrder,
    IsFutureDatedOrderAllowedInBunt
} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    CandidateBillCyclesSelector,
    IsEvaluatingBillCyclesSelector
} from '../../../../../reducers/selectors/customer.billcycle.selectors';
import {DASHBOARD_ROUTE} from '../../../../../reducers/constants/dashboard.constants';
import {retrieveCandidateBillCycles} from '../../../../../reducers/actions/customer.billcycle.actions';
import {retrieveConvergentBillerAccountDetails} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {
    asyncRepeatInterval,
    getRetrieveOrderQuoteCountdown
} from '../../../../../reducers/helpers/async.helper';
import {
    clearDisconnect,
    disconnectSubscriber,
    disconnectSubscriberQuote,
    restoreDefaultEtf,
    retrieveOfferingRemovalContext,
    retrieveOrderContext,
    setDefaultEtfAmount,
    setWaiveAndReason,
    submitChangeOfServiceRemoval,
    toggleEtfWaive
} from '../../../../../reducers/actions/offering.disconnect.actions';
import LocaleKeys from '../../../../../locales/keys';
import {
    BillingEffectiveDateOptionsSelector,
    BillingEffectiveDateSettingsSelector,
    ContractIdRouteParamSelector,
    DefaultEtfAmount,
    DisconnectAdhocQuoteItemsSelector,
    DisconnectItemsSelector,
    DisconnectOfferingServiceIdentifiersSelector,
    DisconnectOfferServiceFeaturesSelector,
    DisconnectOrderContractInstanceSelector,
    DisconnectQuoteSelector,
    EtfWaiveReason,
    HasDisconnectAdhocsSelector,
    HasFinancedDevicesSelector,
    HasProvidesPlansSelector,
    IsCalculatingQuoteSelector,
    IsDisconnectQuoteBypassedSelector,
    IsEtfWaived,
    IsFullDisconnect,
    IsRetrievingContextSelector,
    IsSubmittingOrderSelector,
    OfferGroupedQuoteItems,
    QuoteHasInvoiceItemsSelector,
    RemoveOfferItemsIdsSelector
} from '../../../../../reducers/selectors/offering.disconnect.selectors';
import {
    FullCartOfferingOrderQuoteTotalSelector,
    FullCartOfferingOrderQuotesSelector,
    FullCartOfferingOrderFeeQuoteSelector,
    FullCartOfferingOrderTaxQuoteSelector,
    ShowHideFullCartColumnsSelector
} from '../../../../../reducers/selectors/full.cart.selectors';
import {EARLY_TERMINATION_FEE_OVERRIDE_ACCESS} from '../../../../../security.attributes';
import {setReloadSubscriberStateFlag} from '../../../../../reducers/actions/customer.actions';
import {
    calculateChangeOfServiceOfferingOrderQuote,
    retrieveOfferingOrderQuote
} from '../../../../../reducers/actions/offering.order.actions';
import {stateGo} from 'redux-ui-router';
import {DisconnectAndRemoveOfferCustomerReasonCodesSelector} from '../../../../../reducers/selectors/services.selectors';
import {
    REASON_CODES,
    RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION
} from '../../../../../customercare.constants';
import {AMOUNT_TYPES, getSummaryTotalsLabel} from '../../../../../reducers/helpers/summary.tab.helper';
import {
    IsAsyncCompleteSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    OfferingOrderAsyncIdSelector
} from '../../../../../reducers/selectors/offering.order.selectors';
import {retrieveContractInstance} from '../../../../../reducers/actions/contract.details.actions';

class DisconnectOffersController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            AMOUNT_TYPES,
            CoreLocaleKeys,
            closeViewFullCartPopupDialog: this.closeViewFullCartPopupDialog.bind(this),
            disconnectReasons: REASON_CODES,
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            errorsExistOnForm: true,
            executionOptionsMinDate: moment(this.getTomorrow()).startOf('day').utc().toISOString(),
            executionOptionsModel: {},
            formFieldConstraintParameters: {
                maxlength: {
                    earlyTerminationFeeOverrideReason: 500
                }
            },
            getSummaryTotalsLabel,
            getRetrieveOrderQuoteCountdown,
            handleOpenEditEtfPopup: this.handleOpenEditEtfPopup.bind(this),
            isDisconnecting: false,
            isLoading: false,
            LocaleKeys,
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            onOverrideCalculateQuote: this.onOverrideCalculateQuote.bind(this),
            openContractTermsConditionsPopup: this.openContractTermsConditionsPopup.bind(this),
            onRefreshQuote: this.onRefreshQuote.bind(this),
            overrideBypassQuoteConfiguration: false,
            calculateBypassedQuoteTotals: this.calculateBypassedQuoteTotals.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.FutureDatedOrderConfiguration,
            CODES.ReasonCodes,
            CODES.RegularExpression,
            CODES.ServiceAttribute
        ];

        const mapStateToTarget = (store) => {
            return {
                asyncId: OfferingOrderAsyncIdSelector(store),
                billingEffectiveDateOptions: BillingEffectiveDateOptionsSelector(store),
                billingEffectiveDateSettings: BillingEffectiveDateSettingsSelector(store),
                candidateBillCycles: CandidateBillCyclesSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                currentContractId: ContractIdRouteParamSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                defaultEtfAmount: DefaultEtfAmount(store),
                disconnectAdhocQuoteItemsSelector: DisconnectAdhocQuoteItemsSelector(store),
                disconnectItems: DisconnectItemsSelector(store),
                // TODO Customer Order shouldn't SF item also be coming back from quote call, was informed that Full Disconnect not supported yet for SF
                disconnectOfferServiceFeatures: DisconnectOfferServiceFeaturesSelector(store),
                disconnectAndRemoveOfferCustomerReasons: DisconnectAndRemoveOfferCustomerReasonCodesSelector(store),
                etfWaive: IsEtfWaived(store),
                etfWaiveReason: EtfWaiveReason(store),
                fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                fdoConfiguration: MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoMaxDate: GetMaxDateForFutureDatedOrder(store),
                fullCartOfferingOrderQuotes: FullCartOfferingOrderQuotesSelector(store),
                fullCartOfferingOrderQuoteTotal: FullCartOfferingOrderQuoteTotalSelector(store),
                fullCartOfferingOrderFeeQuote: FullCartOfferingOrderFeeQuoteSelector(store),
                fullCartOfferingOrderTaxQuote: FullCartOfferingOrderTaxQuoteSelector(store),
                hasDisconnectAdhocsSelector: HasDisconnectAdhocsSelector(store),
                hasFinancedDevices: HasFinancedDevicesSelector(store),
                hasInvoiceItems: QuoteHasInvoiceItemsSelector(store),
                hasProvidesPlans: HasProvidesPlansSelector(store),
                isAsyncComplete: IsAsyncCompleteSelector(store),
                isCalculatingQuote: IsCalculatingQuoteSelector(store),
                isCalculatingCOSOfferingOrderQuote: IsQuotingOfferSelector(store),
                isDisconnectQuoteBypassed: IsDisconnectQuoteBypassedSelector(store),
                isEvaluatingBillCycles: IsEvaluatingBillCyclesSelector(store),
                isFullDisconnect: IsFullDisconnect(store),
                isRetrievingContext: IsRetrievingContextSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                isRemoveOfferQuoteBypassed: IsQuoteBypassedSelector(store),
                offerGroupedQuoteItems: OfferGroupedQuoteItems(store),
                orderContractInstance: DisconnectOrderContractInstanceSelector(store),
                quoteOffer: DisconnectQuoteSelector(store),
                removeOfferItemsIds: RemoveOfferItemsIdsSelector(store),
                routeParams: RouteParams(store),
                serviceIdentifiers: DisconnectOfferingServiceIdentifiersSelector(store),
                showHideFullCartColumns: ShowHideFullCartColumnsSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            calculateChangeOfServiceOfferingOrderQuote,
            clearDisconnect,
            disconnectSubscriber,
            disconnectSubscriberQuote,
            fetchCodeTypes,
            restoreDefaultEtf,
            retrieveCandidateBillCycles,
            retrieveContractInstance,
            retrieveConvergentBillerAccountDetails,
            retrieveOfferingOrderQuote,
            retrieveOfferingRemovalContext,
            retrieveOfferingsMetadata,
            retrieveOrderContext,
            setDefaultEtfAmount,
            setReloadSubscriberStateFlag,
            setWaiveAndReason,
            stateGo,
            submitChangeOfServiceRemoval,
            toggleEtfWaive
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            Object.assign(this, {
                state,
                actions
            });
        });

        if (!this.state.isFullDisconnect) {
            this.actions.retrieveOfferingsMetadata([this.state.routeParams.offeringId]).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }

        this.actions.retrieveOrderContext(this.state.currentCustomerId).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        this.retrieveBillCycleDetails();
        this.state.isFullDisconnect ? this.disconnectQuote() : this.retrieveOfferingRemovalDetails();

        this.disconnectOfferConfirmationPopupConfig = {
            onRegisterApi: ({api}) => {
                this.disconnectOfferConfirmationPopupApi = api;
            }
        };

        this.editEtfPopupConfig = {
            onRegisterApi: ({api}) => {
                this.editEtfPopupApi = api;
            }
        };

        this.viewFullCartPopupConfig = {
            onRegisterApi: (event) => {
                this.viewFullCartPopupApi = event.api;
            }
        };

        this.contractTermsConditionsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.contractTermsConditionsPopupApi = api;
            }
        };

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });
    }

    onOverrideCalculateQuote() {
        this.overrideBypassQuoteConfiguration = true;
        this.actions.calculateChangeOfServiceOfferingOrderQuote(
            this.state.currentCustomer,
            undefined,
            undefined,
            this.getDisconnectItemsWithBedAndFinanceDetails(),
            undefined,
            this.executionOptionsModel,
            undefined,
            undefined,
            undefined,
            this.overrideBypassQuoteConfiguration
        );
    }

    onViewFullCartRequest() {
        Promise.resolve().then(() => {
            if (!Object.keys(this.state.quoteOffer).length) {
                this.isLoading = true;
                return this.actions.calculateChangeOfServiceOfferingOrderQuote(
                    this.state.currentCustomer,
                    null,
                    null,
                    this.getDisconnectItemsWithBedAndFinanceDetails(),
                    undefined,
                    this.executionOptionsModel,
                    undefined,
                    undefined,
                    undefined,
                    this.overrideBypassQuoteConfiguration
                );
            }
        }).then(() => {
            this.isLoading = false;
            this.$timeout(this.openViewFullCartPopupDialog.bind(this));
        }).catch((error) => {
            this.isLoading = false;
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    gotoDecisionsPage() {
        this.closeViewFullCartPopupDialog();
        this.actions.goToStep(LocaleKeys.WIZARD.STEPS.DECISIONS);
    }

    openViewFullCartPopupDialog() {
        this.showViewFullCartPopup = true;
        this.$timeout(this.viewFullCartPopupApi.open);
    }

    closeViewFullCartPopupDialog() {
        this.showViewFullCartPopup = false;
        this.viewFullCartPopupApi.close();
    }

    handleOpenEditEtfPopup() {
        this.overrideEtfModel = clone(this.state.orderContractInstance);
        this.editEtfPopupApi.open();
    }

    handleSubmitButton() {
        this.disconnectOfferConfirmationPopupApi.open();
    }

    handleCancelRoute() {
        this.$state.go(DASHBOARD_ROUTE);
    }

    handleCloseDisconnectOfferConfirmationPopupCancel() {
        this.disconnectOfferConfirmationPopupApi.close();
    }

    handleCloseEditEtfPopupCancel() {
        this.editEtfPopupApi.close();
    }

    openContractTermsConditionsPopup() {
        this.contractTermsConditionsPopupOpened = true;
        this.$timeout(() => {
            this.contractTermsConditionsPopupApi.open();
        });
    }

    handleCloseContractTermsConditionsPopup() {
        this.contractTermsConditionsPopupApi.close();
        this.contractTermsConditionsPopupOpened = false;
    }

    hasEarlyTerminationFeeOverrideAccess() {
        return hasAccess(this.state.userSecurityAttributes, EARLY_TERMINATION_FEE_OVERRIDE_ACCESS);
    }

    //Calls retrieveOrderQuote the first time and then calls it every 30 seconds that the call hasnt returned a response
    repeatRetrieveQuote() {
        asyncRepeatInterval(
            this.onRefreshQuote,
            () => {
                return !this.state.isAsyncComplete;
            }
        );
    }

    onRefreshQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.currentCustomerId, this.state.asyncId)
            .then((response) => {
                this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    restoreEarlyTerminationFee() {
        this.actions.restoreDefaultEtf();
        if (this.state.isFullDisconnect) {
            this.actions.disconnectSubscriberQuote(this.state.currentCustomerId, undefined, undefined, undefined, this.overrideBypassQuoteConfiguration)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        } else {
            this.actions.calculateChangeOfServiceOfferingOrderQuote(this.state.currentCustomer, undefined, undefined, this.getDisconnectItemsWithBedAndFinanceDetails(),
                undefined, this.executionOptionsModel, false, undefined, undefined, this.overrideBypassQuoteConfiguration)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
        this.editEtfPopupApi.close();
    }

    updateEarlyTerminationFee() {
        this.actions.setWaiveAndReason(this.overrideEtfModel.earlyTerminationFeeOverrideReason);

        this.state.isFullDisconnect ?
            this.actions.disconnectSubscriberQuote(this.state.currentCustomerId, this.state.etfWaive, this.overrideEtfModel.earlyTerminationFeeOverrideReason, undefined, this.overrideBypassQuoteConfiguration)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }) :
            this.actions.calculateChangeOfServiceOfferingOrderQuote(this.state.currentCustomer, undefined, undefined, this.getDisconnectItemsWithBedAndFinanceDetails(),
                undefined, this.executionOptionsModel, false, undefined, undefined, this.overrideBypassQuoteConfiguration).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });

        this.editEtfPopupApi.close();
    }

    retrieveBillCycleDetails() {
        this.actions.retrieveCandidateBillCycles(
            this.state.currentCustomerId,
            subscriberOrderTypeEnum.postpaid.value
        ).then(() => {
            this.selectedBillCycle = this.state.candidateBillCycles && this.state.candidateBillCycles.length ? this.state.candidateBillCycles.find((billCycle) => {
                return billCycle.id === this.state.currentCustomer.SubscriberTypeDetails.BillCycle;
            }) : null;
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    retrieveOfferingRemovalDetails() {
        this.actions.retrieveOfferingRemovalContext(
            this.state.removeOfferItemsIds,
            this.state.currentCustomerId,
            this.state.currentContractId
        ).then(() => {
            this.actions.calculateChangeOfServiceOfferingOrderQuote(this.state.currentCustomer, undefined, undefined, this.getDisconnectItemsWithBedAndFinanceDetails(),
                undefined, this.executionOptionsModel, false, undefined, undefined, this.overrideBypassQuoteConfiguration)
                .then(() => {
                    this.actions.setDefaultEtfAmount(this.state.quoteOffer.EarlyTerminationFeeAmount);
                    if (this.state.asyncId) {
                        this.repeatRetrieveQuote();
                    }
                    if (this.state.orderContractInstance && this.state.orderContractInstance.orderContractInstanceId && this.state.orderContractInstance.orderContractInstanceId !== '0') {
                        return this.actions.retrieveContractInstance(+this.state.orderContractInstance.orderContractInstanceId, this.state.currentCustomer.Id);
                    }
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }


    disconnectQuote() {
        this.actions.disconnectSubscriberQuote(this.state.currentCustomerId, false, null, this.executionOptionsModel.disconnectReason, this.overrideBypassQuoteConfiguration)
            .then((response) => {
                if (response.Quote) {
                    this.actions.setDefaultEtfAmount(response.Quote.Totals.EarlyTerminationFeeAmount);
                }
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    calculateBypassedQuoteTotals() {
        this.overrideBypassQuoteConfiguration = true;
        this.disconnectQuote();
    }

    handleResultDisconnectOfferConfirmationPopup(confirmed) {
        if (confirmed && !this.isDisconnecting && !this.errorsExistOnForm) {
            this.state.isFullDisconnect ? this.fullDisconnect() : this.disconnectSingleOffer();
        }

        this.handleCloseDisconnectOfferConfirmationPopupCancel();
    }

    getDisconnectItemsWithBedAndFinanceDetails() {
        return (this.state.disconnectItems || []).map((itemToRemove) => {
            return Object.assign({}, itemToRemove, {
                ...(this.state.hasFinancedDevices && this.executionOptionsModel.applyBalanceToInvoice !== null && {
                    FinanceDetails: itemToRemove.FinanceDetails ? Object.assign({}, itemToRemove.FinanceDetails, {
                        ApplyBalanceToInvoice: this.executionOptionsModel.applyBalanceToInvoice
                    }) : undefined
                }),
                ...(this.executionOptionsModel?.billingEffectiveIntention && {
                    BillingEffectiveDateIntention: this.executionOptionsModel.billingEffectiveIntention
                }),
                ...(this.executionOptionsModel.billingEffectiveDate && {
                    BillingEffectiveDate: this.executionOptionsModel.billingEffectiveDate
                })
            });
        });
    }

    disconnectSingleOffer() {
        this.isDisconnecting = true;
        const disconnectItems = this.getDisconnectItemsWithBedAndFinanceDetails();

        this.actions.submitChangeOfServiceRemoval(
            disconnectItems,
            this.state.currentCustomerId,
            this.executionOptionsModel
        ).then(() => {
            /*
            * TODO:
            * PD-411357
            * Need to identify when the subscriber has no more offers left to trigger (Switches to disconnect state) below action
            */
            this.actions.setReloadSubscriberStateFlag(true);
            this.uiNotificationService.transientSuccess(i18n.translate(this.LocaleKeys.DISCONNECT_OFFERS.DISCONNECT_SUCCESSFUL));
            this.transitionToDashboard();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.isDisconnecting = false;
        });
    }

    fullDisconnect() {
        this.isDisconnecting = true;
        this.actions.disconnectSubscriber(
            this.state.currentCustomerId,
            this.executionOptionsModel,
            this.state.etfWaive,
            this.state.etfWaiveReason
        ).then(() => {
            this.actions.setReloadSubscriberStateFlag(true);
            this.uiNotificationService.transientSuccess(i18n.translate(this.LocaleKeys.DISCONNECT_OFFERS.DISCONNECT_SUCCESSFUL));
            this.transitionToDashboard();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.isDisconnecting = false;
        });
    }

    transitionToDashboard() {
        const subscriberId = this.state.currentCustomerId;
        const params = {
            customerId: subscriberId
        };
        const options = {
            reload: true
        };
        this.actions.stateGo(DASHBOARD_ROUTE, params, options);
    }

    $onDestroy() {
        this.actions.clearDisconnect();
        this.disconnectRedux();
    }

    getTomorrow() {
        const today = moment();
        return moment(today).add(1, 'days');
    }

    onExecutionOptionChange(model, isCreditCheckFinancingChange, isReasonChange) {
        this.executionOptionsModel = model;

        if (isCreditCheckFinancingChange || isReasonChange) {
            if (this.state.isFullDisconnect) {
                this.disconnectQuote();
            } else {
                const disconnectItems = this.state.hasFinancedDevices && this.executionOptionsModel.applyBalanceToInvoice !== null ?
                    this.state.disconnectItems.map((itemToRemove) => {
                        return Object.assign({}, itemToRemove, {
                            FinanceDetails: itemToRemove.FinanceDetails ? Object.assign({}, itemToRemove.FinanceDetails, {
                                ApplyBalanceToInvoice: this.executionOptionsModel.applyBalanceToInvoice
                            }) : undefined,
                            ...(this.executionOptionsModel?.billingEffectiveIntention && {
                                BillingEffectiveDateIntention: this.executionOptionsModel.billingEffectiveIntention
                            }),
                            ...(this.executionOptionsModel.billingEffectiveDate && {
                                BillingEffectiveDate: this.executionOptionsModel.billingEffectiveDate
                            })
                        });
                    }) : this.getDisconnectItemsWithBedAndFinanceDetails();

                this.actions.calculateChangeOfServiceOfferingOrderQuote(this.state.currentCustomer, undefined, undefined, disconnectItems,
                    undefined, this.executionOptionsModel, false, undefined, undefined, this.overrideBypassQuoteConfiguration).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        }
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
    }

    headingText() {
        return i18n.translate(this.state.isFullDisconnect
            ? this.LocaleKeys.DISCONNECT_OFFERS.DISCONNECT_CUSTOMER
            : this.LocaleKeys.DISCONNECT_OFFERS.REMOVE_OFFER);
    }
}

export default {
    template: require('./disconnect.offers.dbss.html'),
    bindings: {},
    controller: DisconnectOffersController,
    controllerAs: 'DisconnectOffers'
};
