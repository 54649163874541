import {createSelector} from 'reselect';

const RevokeGiftOrderStoreSelector = (store) => {
    return store.customercare.recoverableUiState.revokeGiftOrder;
};

export const RevokeGiftOrderModelSelector = createSelector(
    [RevokeGiftOrderStoreSelector],
    (revokeGiftOrderStore) => {
        return revokeGiftOrderStore.model;
    }
);

export const IsRevokingGiftOrderSelector = createSelector(
    [RevokeGiftOrderStoreSelector],
    (revokeGiftOrderStore) => {
        return revokeGiftOrderStore.isRevokingGiftOrder;
    }
);

export const RevokeGiftOrderErrorMessagesSelector = createSelector(
    [RevokeGiftOrderStoreSelector],
    (revokeGiftOrderStore) => {
        return revokeGiftOrderStore.revokeGiftOrderError && [revokeGiftOrderStore.revokeGiftOrderError.translatedMessage];
    }
);
