import {i18n} from 'invision-core';
import {setCheckoutDetailsValidation} from '../../../../../reducers/actions/offering.order.actions';
import {SelectedShippingAddressAndMethodSelector} from '../../../../../reducers/selectors/offering.order.selectors';
import CustomerCareLocaleKeys from '../../../../../locales/keys';

export class EditCheckoutPopupController {
    constructor($ngRedux, $filter) {
        Object.assign(this, {
            $ngRedux,
            $filter,
            customerCareKeys: CustomerCareLocaleKeys,
            getEditModalTitle: this.getEditModalTitle.bind(this),
            handleDownPaymentChange: this.handleDownPaymentChange.bind(this),
            isFdoFormValid: this.isFdoFormValid.bind(this),
            getDeliveryMethodWithCurrency: this.getDeliveryMethodWithCurrency.bind(this),
            onEditExecutionOptionChange: this.onEditExecutionOptionChange.bind(this),
            onSelectEditOption: this.onSelectEditOption.bind(this),
            showErrorSummary: false,
            SIDE_NAV_FORMS: {
                BILLING_AND_SCHEDULING: 'editModalBillingAndSchedulingForm',
                DEVICE_PAYMENT: 'editModalDevicePaymentForm',
                SHIPPING: 'editModalShippingForm'
            },
            updateDeliveryMethod: this.updateDeliveryMethod.bind(this),
            updateProductIds: this.updateProductIds.bind(this)
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                selectedShippingAddressAndMethod: SelectedShippingAddressAndMethodSelector(store)
            };
        };

        const controllerActions = {
            setCheckoutDetailsValidation
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.getEditModalNavList();
        if (this.editModalSideNavList.length) {
            this.editModalSideNavList.forEach((form) => {
                this.updateSelectedFormItems({
                    formName: form.formName,
                    isValid: null,
                    glyph: 'empty',
                    glyphClass: 'c-navGroup-emptyRouteIcon'
                });
            });
        }
        if (this.selectedItemFormName) {
            this.updateSelectedFormItems({
                formName: this.selectedItemFormName,
                isActive: true
            });
        }
        this.selectedDeliveryMethod = this.state.selectedShippingAddressAndMethod ? this.state.selectedShippingAddressAndMethod.methodId : undefined;
    }

    getEditModalTitle() {
        switch (this.selectedItemFormName) {
            case this.SIDE_NAV_FORMS.DEVICE_PAYMENT:
                return i18n.translate(this.customerCareKeys.CHECKOUT.DEVICE_PAYMENTS);
            case this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING:
                return i18n.translate(this.customerCareKeys.CHECKOUT.BILLING_SCHEDULING);
            case this.SIDE_NAV_FORMS.SHIPPING:
                return i18n.translate(this.customerCareKeys.CHECKOUT.SHIPPING);
            default:
                return '';
        }
    }

    getEditModalNavList() {
        const editModalSideNavList = [];
        const shippingNavObj = {
            formName: this.SIDE_NAV_FORMS.SHIPPING,
            glyph: 'empty',
            glyphClass: 'c-navGroup-emptyRouteIcon',
            id: this.SIDE_NAV_FORMS.SHIPPING,
            isActive: false,
            isDisabled: false,
            isHidden: false,
            isValid: null,
            label: i18n.translate(this.customerCareKeys.CHECKOUT.SHIPPING),
            tooltipMessage: null
        };
        const devicePaymentNavObj = {
            formName: this.SIDE_NAV_FORMS.DEVICE_PAYMENT,
            glyph: 'empty',
            glyphClass: 'c-navGroup-emptyRouteIcon',
            id: this.SIDE_NAV_FORMS.DEVICE_PAYMENT,
            isActive: false,
            isDisabled: this.isDevicePaymentsDisabled,
            isHidden: false,
            isValid: null,
            label: i18n.translate(this.customerCareKeys.CHECKOUT.DEVICE_PAYMENTS),
            tooltipMessage: i18n.translate(this.customerCareKeys.CHECKOUT.EDITABLE_AFTER_TOTALS_CALCULATED) // 'Editable after totals have been calculated'
        };
        const billingNavObj = {
            formName: this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING,
            glyph: 'empty',
            glyphClass: 'c-navGroup-emptyRouteIcon',
            id: this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING,
            isActive: false,
            isDisabled: false,
            isHidden: false,
            isValid: null,
            label: i18n.translate(this.customerCareKeys.CHECKOUT.BILLING_SCHEDULING),
            tooltipMessage: null
        };
        if (this.showDownPaymentsSchedulingAndBilling && this.financedItems.length && this.displaySummaryTab && !this.isDevicePaymentsHidden && this.isShipping) {
            editModalSideNavList.push(shippingNavObj);
            editModalSideNavList.push(devicePaymentNavObj);
            editModalSideNavList.push(billingNavObj);
        } else if (this.showDownPaymentsSchedulingAndBilling && this.financedItems.length && this.displaySummaryTab && !this.isDevicePaymentsHidden) {
            editModalSideNavList.push(devicePaymentNavObj);
            editModalSideNavList.push(billingNavObj);
        } else if (this.financedItems.length && this.displaySummaryTab && !this.isDevicePaymentsHidden && this.isShipping) {
            editModalSideNavList.push(devicePaymentNavObj);
            editModalSideNavList.push(shippingNavObj);
        } else if (this.showDownPaymentsSchedulingAndBilling && this.isShipping) {
            editModalSideNavList.push(shippingNavObj);
            editModalSideNavList.push(billingNavObj);
        }
        this.actions.setCheckoutDetailsValidation(editModalSideNavList);
    }

    onSelectEditOption(item) {
        this.itemSelected = item;
        this.selectedItemFormName = item.formName;

        if (item.formName === this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING) {
            this.editModalDevicePaymentForm.$setSubmitted(true);
            const isValid = !!this.editModalDevicePaymentForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.DEVICE_PAYMENT, isValid);
            this.updateSelectedFormItems({
                formName: this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING,
                isActive: true
            });
        } else if (item.formName === this.SIDE_NAV_FORMS.DEVICE_PAYMENT) {
            this.editModalBillingAndSchedulingForm.$setSubmitted(true);
            const isValid = !!this.editModalBillingAndSchedulingForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING, isValid);
            this.updateSelectedFormItems({
                formName: this.SIDE_NAV_FORMS.DEVICE_PAYMENT,
                isActive: true
            });
        } else if (item.formName === this.SIDE_NAV_FORMS.SHIPPING) {
            this.editModalShippingForm.$setSubmitted(true);
            const isValid = !!this.editModalShippingForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.SHIPPING, isValid);
            this.updateSelectedFormItems({
                formName: this.SIDE_NAV_FORMS.SHIPPING,
                isActive: true
            });
        }
    }

    updateEditFormValidationProperites(formName, isValid) {
        this.updateSelectedFormItems({
            formName: formName,
            isValid: isValid,
            glyph: isValid ? 'status-complete-filled' : 'status-pending-filled',
            glyphClass: isValid ? 'is-active' : 'is-removed'
        });
    }

    updateSelectedFormItems(itemObj) {
        this.actions.setCheckoutDetailsValidation(itemObj);
    }

    updateProductIds(pricingPlanId, pricingPlanProductId, offeringInstanceId, options) {
        this.updateSubscriberProductIds()(pricingPlanId, pricingPlanProductId, offeringInstanceId, options);
    }

    updateDeliveryMethod() {
        if (this.selectedItemFormName === this.SIDE_NAV_FORMS.SHIPPING) {
            const isValid = !!this.editModalShippingForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.SHIPPING, isValid);
        }
    }

    onEditExecutionOptionChange(executionOptionsModel) {
        this.executionOptionsModel = executionOptionsModel;
        this.onExecutionOptionChange()(this.executionOptionsModel);
        if (this.selectedItemFormName === this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING) {
            const isValid = !!this.editModalBillingAndSchedulingForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING, isValid);
        }
    }

    isFdoFormValid(isFormValid) {
        this.errorsExistOnForm = isFormValid;
        return isFormValid;
    }

    handleDownPaymentChange(itemIndex, item) {
        this.handleItemDownPaymentChanged()(itemIndex, item);
        if (this.selectedItemFormName === this.SIDE_NAV_FORMS.DEVICE_PAYMENT) {
            const isValid = !!this.editModalDevicePaymentForm.$valid;
            this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.DEVICE_PAYMENT, isValid);
        }
    }

    getDeliveryMethodWithCurrency(option) {
        return option.label.concat(' ').concat(this.$filter('invCurrency')(option.cost, option.currency));
    }

    onSubmitEditCheckoutModal() {
        this.showErrorSummary = true;
        this.editModalBillingAndSchedulingForm.$setSubmitted(true);
        this.editModalDevicePaymentForm.$setSubmitted(true);
        this.editModalShippingForm.$setSubmitted(true);
        if (this.editModalDevicePaymentForm.$valid &&
            this.editModalBillingAndSchedulingForm.$valid &&
            (this.shippingAddress ? this.editModalShippingForm.$valid : true )) {
            if (this.selectedDeliveryMethod) {
                this.updateSelectedDeliveryMethod()(this.selectedDeliveryMethod);
            }
            this.onSubmitEditModal()(this.executionOptionsModel);
        } else {
            if (this.editModalBillingAndSchedulingForm.$valid) {
                const isValid = !!this.editModalBillingAndSchedulingForm.$valid;
                this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.BILLING_AND_SCHEDULING, isValid);
            }
            if (!this.editModalDevicePaymentForm.$valid) {
                const isValid = !!this.editModalDevicePaymentForm.$valid;
                this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.DEVICE_PAYMENT, isValid);
            }
            if (!this.editModalShippingForm.$valid) {
                const isValid = !!this.editModalShippingForm.$valid;
                this.updateEditFormValidationProperites(this.SIDE_NAV_FORMS.SHIPPING, isValid);
            }
            this.updateSelectedFormItems({
                formName: this.selectedItemFormName,
                isActive: true
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

}

export default {
    template: require('./edit.checkout.popup.html'),
    bindings: {
        billCycleOptions: '<',
        billCycleSelectOptionTemplate: '<',
        billingEffectiveDateOptions: '<',
        billingEffectiveDateSettings: '<',
        canShowFdoAndBed: '<',
        cartHasOfferWithBillerRuleCycleLevelOtherThanItem: '<?',
        closeEditCheckoutPageInformationPopup: '&',
        currentCustomerId: '<',
        displaySummaryTab: '<',
        edit: '<',
        editModalSideNavList: '<',
        executionOptions: '<',
        executionOptionsMinDate: '<',
        fdoMaxDate: '<',
        financedItems: '<',
        handleItemDownPaymentChanged: '&',
        isAlignRenewals: '<',
        isBillCycleEditDisabled: '<?',
        isBillingEffectiveDateShown: '&',
        isDbss: '<?',
        isDevicePaymentsHidden: '<?',
        isDevicePaymentsDisabled: '<?',
        isFirstOrder: '<?',
        isOrderPostPaid: '<?',
        isShipping: '<?',
        shipTo: '<?',
        shippingAddress: '<?',
        deliveryMethodOptions: '<?',
        formatedCityStateForShippingAddress: '<?',
        offCycleAlignmentOptions: '<',
        onBillCycleSelectOptionSelected: '&?',
        onExecutionOptionChange: '&',
        onFdoFormValidityChange: '&',
        onSubmitEditModal: '&',
        renewalData: '<?',
        selectedBillCycle: '<?',
        selectedItemFormName: '<?',
        showAlignRenewals: '<?',
        showBillCycleOption: '<',
        showDownPaymentsSchedulingAndBilling: '<',
        showSideNavEditModal: '<',
        transferFromSubscriberId: '<?',
        updateSubscriberProductIds: '&?',
        updateSelectedDeliveryMethod: '&?'
    },
    controller: EditCheckoutPopupController
};
