import {
    DEVICE_ACCESS
} from '../../../security.attributes';
import {
    clearDeviceFilters
} from '../../../reducers/actions/customer.devices.actions';
import {DEVICE_DETAIL_STATE_OR_NAME as CONTENT_ACTIVITY_STATE} from '../contentActivity/content.activity.config';

export default ($stateProvider) => {
    $stateProvider.state('index.customercare.customer.devices', {
        url: '/devices',
        template: '<customer-devices></customer-devices>',
        securityAttributes: [
            DEVICE_ACCESS
        ],
        onExit: ($ngRedux, $state) => {
            const NEXT_ROUTE = $state.next.name;
            if (CONTENT_ACTIVITY_STATE !== NEXT_ROUTE) {
                $ngRedux.dispatch(clearDeviceFilters());
            }
        }
    });
};
