import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchAdjustmentReasonTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import LocaleKeys from '../../../locales/keys';
import {
    retrieveAdjustmentDetails,
    retrieveAndOpenBillAdjustmentStatement
} from '../../../reducers/actions/customer.billing.actions';
import {
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerIdSelector,
    RouteParams
} from '../../../reducers/selectors/customer.selectors';
import {
    AdjustmentItemsTableDataSelector,
    AdjustmentOrCreditNoteDetailsSelector,
    AdjustmentReasonsCodeSelector,
    IsFetchingAdjustmentDetailsSelector
} from '../../../reducers/selectors/customer.billing.selectors';
import {getAdjustmentCodeNames} from '../../../reducers/selectors/customer.billing.selectors.helper';
import {ACCOUNTING_METHODS_NAMES} from '../../../reducers/constants/billing.payments.constants';
import {
    BILL_ADJUSTMENT,
    CONVERSION_CREDIT_ADJUSTMENT,
    CONVERSION_DEBIT_ADJUSTMENT,
    CREDIT_NOTE_ADJUSTMENT,
    DEBIT_NOTE_ADJUSTMENT,
    MISC_ADJUSTMENT
} from '../../billingPayments/billing.payments.constants';

class CreditNoteDetailsController {
    constructor($filter, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            $timeout,
            ACCOUNTING_METHODS_NAMES,
            BILL_ADJUSTMENT,
            CONVERSION_CREDIT_ADJUSTMENT,
            CONVERSION_DEBIT_ADJUSTMENT,
            CREDIT_NOTE_ADJUSTMENT,
            DEBIT_NOTE_ADJUSTMENT,
            LocaleKeys,
            MISC_ADJUSTMENT,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentReasonCodes: AdjustmentReasonsCodeSelector(store),
                adjustmentReasonCodesLoaded: MetadataCodeLoadedSelector(CODES.ADJUSTMENT_REASON_TYPE_CODE, store),
                creditNoteDetails: AdjustmentOrCreditNoteDetailsSelector(store),
                currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                isFetchingAdjustmentDetails: IsFetchingAdjustmentDetailsSelector(store),
                routeParams: RouteParams(store),
                tableData: AdjustmentItemsTableDataSelector(store)
            };
        };
        const controllerActions = {
            fetchAdjustmentReasonTypes: fetchAdjustmentReasonTypes,
            retrieveBillAdjustmentAndOpenInNewTab: retrieveAndOpenBillAdjustmentStatement,
            retrieveAdjustmentDetails
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.adjustmentReasonCodesLoaded) {
            this.actions.fetchAdjustmentReasonTypes();
        }

        this.columnDefs = this.getTableHeaders();
        this.applyCreditNoteModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditNoteModalApi = api;
            }
        };
        this.retrieveCreditNoteDetails();
    }

    showApplyNoteButton() {
        return this.state.currentCustomerAccountingMethod === this.ACCOUNTING_METHODS_NAMES.OPEN_INVOICE && this.creditNoteSummary && this.creditNoteSummary.RemainingBalance !== 0 && convertStringToNumber(this.creditNoteSummary.AdjustmentTypeCode) === this.CREDIT_NOTE_ADJUSTMENT;
    }

    getTableHeaders() {
        return [
            {
                field: 'id',
                displayName: i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_DEBIT_NOTE_ID),
                enableSorting: false
            }, {
                field: 'date',
                displayName: i18n.translate(LocaleKeys.DATE),
                enableSorting: false
            }, {
                field: 'amount',
                displayName: i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_PAID),
                cellTemplate: require('./cellTemplates/amount.template.html'),
                enableSorting: false
            }, {
                field: 'balance',
                displayName: i18n.translate(LocaleKeys.BALANCE),
                cellTemplate: require('./cellTemplates/balance.template.html'),
                enableSorting: false
            }
        ];
    }

    retrieveCreditNoteDetails() {
        this.creditNoteDetailsRequest = {
            customerId: this.state.currentCustomerId,
            request: {
                AdjustmentId: this.state.routeParams.creditNoteId
            }
        };
        this.actions.retrieveAdjustmentDetails(this.creditNoteDetailsRequest)
            .then(() => {
                this.creditNoteSummary = this.state.creditNoteDetails.AdjustmentSummary;
                this.adjustmentType = this.creditNoteSummary && convertStringToNumber(this.creditNoteSummary.AdjustmentTypeCode);
                this.reasonCodeName = this.creditNoteSummary && getAdjustmentCodeNames(this.creditNoteSummary.AdjustmentReasonCode, (this.state.adjustmentReasonCodes ? this.state.adjustmentReasonCodes.items: []));
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    openApplyCreditNoteModal() {
        this.creditNoteData = {
            adjustmentCode: this.reasonCodeName,
            amount: this.creditNoteSummary.OriginalBalance,
            creditNoteId: this.creditNoteSummary.AdjustmentId,
            currencyCode: this.creditNoteSummary.CurrencyCode,
            referenceId: this.creditNoteSummary.AdjustmentNumber,
            remainingBalance: this.creditNoteSummary.RemainingBalance
        };
        this.showApplyCreditNoteModal = true;
        this.$timeout(() => {
            this.applyCreditNoteModalApi.open();
        });
    }

    onApplyCreditNoteModalClose() {
        this.retrieveCreditNoteDetails();
        this.applyCreditNoteModalApi.close();
        this.showApplyCreditNoteModal = false;
    }

    buildBillAdjustmentRequest() {
        return {
            CustomerId: this.state.routeParams.customerId,
            AdjustmentId: this.creditNoteSummary.AdjustmentId,
            request: {
                AccountNumber: this.creditNoteSummary.AccountNumber,
                StatementDate: this.creditNoteSummary.DateCreated,
                StatementNumber: this.creditNoteSummary.AdjustmentNumber,
                StatementType: 1
            }
        };
    }

    generateStatementFlag() {
        return this.creditNoteSummary.hasOwnProperty('StatementEnabled');
    }

    retrieveAdjustmentContent() {
        this.actions.retrieveBillAdjustmentAndOpenInNewTab(this.buildBillAdjustmentRequest());
    }

    $onDestroy() {
        this.disconnectRedux();
    }

}

export default {
    template: require('./credit.note.details.template.html'),
    controller: CreditNoteDetailsController,
    controllerAs: 'CreditNoteDetailsController'
};
