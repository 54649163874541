import {
    MetadataActions,
    MetadataSelectors,
    MetadataConstants,
    MetadataHelpers
} from 'invision-core';
import localeKeys from '../../../../locales/keys';
import {IssueWriteOff} from '../../../../reducers/actions/issue.write.off.actions';
import {TransactionErrorSelector,
    IsFetchingDataSelector} from '../../../../reducers/selectors/customer.transactions.selectors';
import {clearTransactionsError} from '../../../../reducers/actions/customer.transactions.actions';
import {FLOAT} from '../../../shared/constants/regex.constants';

class WriteOffPopupController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            localeKeys,
            currencyRegex: FLOAT
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                hasLoadedWriteOffReasons: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.WriteOffReason)(store),
                isLoadingData: IsFetchingDataSelector(store),
                reasons: MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.WriteOffReason)(store),
                transactionError: TransactionErrorSelector(store)
            };
        };
        const controllerActions = {
            clearTransactionsError,
            issueWriteOff: IssueWriteOff,
            fetchCodeTypesThunk: MetadataActions.codes.fetchCodeTypesThunk
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.hasLoadedWriteOffReasons) {
            this.actions.fetchCodeTypesThunk(MetadataConstants.codes.WriteOffReason)
                .then(() => {
                    this.reasonCodes = MetadataHelpers.codes.selectOptionsForCodeValues(this.state.reasons.items);
                });
        } else {
            this.reasonCodes = MetadataHelpers.codes.selectOptionsForCodeValues(this.state.reasons.items);
        }
        this.reason = null;
    }

    $onDestroy() {
        this.actions.clearTransactionsError();
        this.disconnectRedux();
    }

    onSubmit() {
        if (this.form.$valid && this.isAmountValid) {
            this.actions.issueWriteOff({
                Amount: this.amount,
                Description: this.description,
                PurchaseOrderId: this.purchaseOrder.Id,
                ReasonCode: this.reason
            })
                .then(() => {
                    this.onSubmitSuccess()();
                });
        }
    }

    get isAmountValid() {
        const value = parseFloat(this.amount);
        return value > 0 && value <= this.purchaseOrder.Totals.TotalAmount;
    }

    get amountNgClass() {
        return {
            'is-error': (this.form.amount.$touched || this.form.$submitted)
                        && (this.form.amount.$invalid || !this.isAmountValid)
        };
    }
}

export default {
    template: require('./write.off.popup.html'),
    controller: WriteOffPopupController,
    controllerAs: 'WriteOff',
    bindings: {
        onClose: '&',
        onSubmitSuccess: '&',
        purchaseOrder: '<'
    }
};
