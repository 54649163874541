import angular from 'angular';

import 'invision-core';

import CreateAddressPopupComponent from './create.address.popup.component';

export default angular.module('invision.customercare.customer.addresses.createAddressPopup', [
    'invision.core.components'
])
    .component('createAddressPopup', CreateAddressPopupComponent)
    .name;
