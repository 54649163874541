import moment from 'moment';
import pick from 'ramda/src/pick';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {getQService} from 'invision-core/src/components/injectables/injector.helper';
import {
    getContentTypeFromFileType,
    getFileTypeFromContentType
} from 'invision-core/src/components/upload/upload.helper';
import UploadHelperConstants from 'invision-core/src/components/upload/upload.helper.constants';

import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {
    addImportBulkServiceAttributesJobPendingFile,
    clearImportBulkServiceAttributesJobPendingFiles,
    removeImportBulkServiceAttributesJobPendingFile,
    retrieveAwsS3UploadConfiguration,
    retrieveImportBulkServiceAttributesJobDetails,
    sendBulkServiceAttributesUploadNotification,
    setIsImportingBulkServiceAttributes,
    updateImportBulkServiceAttributesJobPendingFileStatus,
    updateImportBulkServiceAttributesJobsPendingUpdatedStatus
} from '../../../../../reducers/actions/order.details.actions';
import {
    retrieveConvergentBillerOrderDetails,
} from '../../../../../reducers/actions/customer.convergent.biller.actions';

import {
    IMPORT_BULK_SERVICE_ATTRIBUTES_ALLOWED_FILE_TYPE_NAME,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES,
    IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES,
    MAX_NUM_IMPORT_UPLOADABLE_FILES
} from '../order.details.constants';

import {
    OrderDetailsImportServiceAttributesFormEditingIsDisabledSelector,
    OrderDetailsImportServiceAttributesFormSubmissionIsDisabledSelector,
    OrderDetailsImportServiceAttributesJobDetailsViewModelSelector,
    OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector,
    OrderDetailsBulkServiceAttributesJobIsImportingSelector,
    OrderDetailsBulkServiceAttributesJobIsProcessingSelector,
    OrderDetailsImportServiceAttributesUploadConfigurationSelector
} from '../../../../../reducers/selectors/order.details.selectors';

import {
    CurrentOrderItemDisplayItemsSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';

import {
    generateFormattedFileResult
} from './import.bulk.attributes.modal.helper';

class ImportBulkAttributesModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $q: getQService(),
            coreLocaleKeys: CoreLocaleKeys,
            i18n,
            localeKeys: CustomerCareLocaleKeys,
            uiNotificationService,
            viewModel: {
                isLoading: true,
                orderDetails: {
                    files: [],
                    invalidFiles: [],
                    offeringId: null
                }
            }
        });
    }

    $onInit() {
        const ALLOWED_FILE_TYPE = UploadHelperConstants.ALLOWED_FILE_TYPES_MAPPING[IMPORT_BULK_SERVICE_ATTRIBUTES_ALLOWED_FILE_TYPE_NAME];

        const controllerActions = {
            addImportBulkServiceAttributesJobPendingFile,
            clearImportBulkServiceAttributesJobPendingFiles,
            removeImportBulkServiceAttributesJobPendingFile,
            retrieveAwsS3UploadConfiguration,
            retrieveConvergentBillerOrderDetails,
            retrieveImportBulkServiceAttributesJobDetails,
            sendBulkServiceAttributesUploadNotification,
            setIsImportingBulkServiceAttributes,
            updateImportBulkServiceAttributesJobPendingFileStatus,
            updateImportBulkServiceAttributesJobsPendingUpdatedStatus
        };
        const mapStateToTarget = (store) => {
            return {
                bulkServiceAttributesJobsPendingUpdatedStatus: OrderDetailsImportServiceAttributesJobsPendingUpdatedStatusSelector(store),
                currentOrderDisplayItems: CurrentOrderItemDisplayItemsSelector(store),
                isFormEditingDisabled: OrderDetailsImportServiceAttributesFormEditingIsDisabledSelector(store),
                isFormSubmissionDisabled: OrderDetailsImportServiceAttributesFormSubmissionIsDisabledSelector(store),
                isImportingServiceAttributes: OrderDetailsBulkServiceAttributesJobIsImportingSelector(store),
                isProcessingServiceAttributes: OrderDetailsBulkServiceAttributesJobIsProcessingSelector(store),
                jobDetailsViewModel: OrderDetailsImportServiceAttributesJobDetailsViewModelSelector(store),
                uploadConfig: OrderDetailsImportServiceAttributesUploadConfigurationSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.uploadErrorCallback = (response) => {
            const translatedErrorMessage = this.i18n.translate(this.coreLocaleKeys.UPLOAD.AWS_ERRORS.DEFAULT);

            this.actions.updateImportBulkServiceAttributesJobPendingFileStatus(
                response.fileName,
                generateFormattedFileResult(
                    IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.FAILURE,
                    translatedErrorMessage
                )
            );
        };

        this.uploadSuccessCallback = (response) => {
            const fileDetails = {
                name: response.fileName,
                path: response.filePath
            };
            const orderDetails = pick(['jobId', 'orderId', 'pricingPlanId', 'subscriberId'], this.state.jobDetailsViewModel);
            orderDetails.offeringId = this.viewModel.orderDetails.offeringId;

            this.sendBulkServiceAttributesUploadNotification(orderDetails, fileDetails);
        };

        // NOTE: Standardize the file type since OS/browsers aren't consistent with content-types (e.g. a CSV may be "application/vnd.ms-excel"
        // if associated with Excel instead of "text/csv")
        this.validateFile = (selectedFile) => {
            const selectedFileType = getFileTypeFromContentType(selectedFile.type);

            return !!(selectedFileType && ALLOWED_FILE_TYPE.type === selectedFileType);
        };

        this.customErrorMessageLocaleKeysMapping = {
            maxFiles: this.i18n.translate(this.coreLocaleKeys.UPLOAD.MAX_NUM_FILES, {
                numFiles: MAX_NUM_IMPORT_UPLOADABLE_FILES
            }),
            maxSize: this.i18n.translate(this.coreLocaleKeys.UPLOAD.MAX_FILE_SIZE),
            validateFn: this.i18n.translate(this.localeKeys.ORDER_DETAILS.IMPORT_BULK_SERVICE_ATTRIBUTES.FILE_TYPE_ERROR, {
                fileType: `.${ALLOWED_FILE_TYPE.extension}`
            }),
        };
        this.fileUploadConfig = {
            allowedFileTypeExtension: `.${ALLOWED_FILE_TYPE.extension}`,
            allowedFileTypeId: ALLOWED_FILE_TYPE.typeId,
            allowInvalidFilesInModel: false,
            maxNumFiles: MAX_NUM_IMPORT_UPLOADABLE_FILES
        };

        this.modalTitle = `${this.i18n.translate(this.localeKeys.ORDER_DETAILS.IMPORT_SERVICE_ATTRIBUTES)}: ${this.orderDetails.pricingPlanName}`;

        this.actions.retrieveImportBulkServiceAttributesJobDetails(
            this.orderDetails
        ).then(() => {
            const pendingJobDetails = this.state.bulkServiceAttributesJobsPendingUpdatedStatus[this.orderDetails.pricingPlanId];

            this.viewModel.isLoading = false;
            this.viewModel.orderDetails.offeringId = this.orderDetails.offeringId;

            if (pendingJobDetails &&
                this.state.jobDetailsViewModel.importHistorySummary.dateLastModified >
                pendingJobDetails.dateLastModified) {
                this.actions.updateImportBulkServiceAttributesJobsPendingUpdatedStatus([{
                    pricingPlanId: this.orderDetails.pricingPlanId
                }]);
            }
        });
    }

    $onDestroy() {
        this.actions.clearImportBulkServiceAttributesJobPendingFiles();

        this.disconnectRedux();
    }

    close() {
        if (!this.state.isImportingServiceAttributes) {
            this.resetFormState(true);

            this.onClose()();
        }
    }

    displayOverrideMessage() {
        return !this.state.jobDetailsViewModel.pendingUpdatedStatus &&
            !this.state.isProcessingServiceAttributes &&
            this.state.jobDetailsViewModel.statusId !== IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS;
    }

    displayServiceAttributesJobCompleteMessage() {
        return this.state.isFormEditingDisabled &&
            this.state.isFormSubmissionDisabled &&
            this.state.jobDetailsViewModel.statusId === IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS;
    }

    extendFileModel(files, file) {
        if (file) {
            if (!file.importResults) {
                file.importResults = [];
            }

            this.actions.addImportBulkServiceAttributesJobPendingFile(file);
        }
    }

    uploadFileToAwsS3Bucket(utcDateAndTime, file) {

        const fileName = `${utcDateAndTime}-${file.name}`;

        this.actions.retrieveAwsS3UploadConfiguration(
            fileName,
            this.fileUploadConfig.allowedFileTypeId,
            this.orderDetails.orderId,
            this.orderDetails.pricingPlanId,
            this.orderDetails.customerId
        ).then(() => {

            const fileType = getFileTypeFromContentType(file.type);
            const fileContentType =  getContentTypeFromFileType(fileType);

            fetch(this.state.uploadConfig.SignedUrl, {
                body: file,
                method: 'PUT',
                headers: {
                    'Content-Type': fileContentType
                }

            }).then(() => {
                this.uploadSuccessCallback({
                    fileName: file.name,
                    filePath: this.state.uploadConfig.DestinationFilePath
                });
            }).catch(() => {
                this.uploadErrorCallback({
                    fileName: file.name
                });
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    handleRemoveFile(file) {
        if (!this.state.isFormSubmissionDisabled) {
            this.actions.removeImportBulkServiceAttributesJobPendingFile(file.name);

            if (!this.state.jobDetailsViewModel.pendingFiles.length) {
                this.resetFormState(true);
            }
        }
    }

    handleUploadNotificationResponse(orderDetails, fileDetails, error) {
        let formattedResult;

        if (!error) {
            formattedResult = generateFormattedFileResult(
                IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.SUCCESS,
                i18n.translate(this.localeKeys.ORDER_DETAILS.IMPORT_BULK_SERVICE_ATTRIBUTES.FILE_IMPORTED_SUCCESSFULLY)
            );
        } else {
            formattedResult = generateFormattedFileResult(
                IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.FAILURE,
                error.translatedMessage
            );
        }

        this.actions.updateImportBulkServiceAttributesJobPendingFileStatus(
            fileDetails.name,
            formattedResult
        );

        this.actions.setIsImportingBulkServiceAttributes(false);

        if (!error) {
            this.resetFormState();
        }

        this.actions.retrieveConvergentBillerOrderDetails(
            orderDetails.subscriberId,
            orderDetails.orderId
        );
    }

    resetFormState(includeSelectedFiles = false) {
        if (includeSelectedFiles) {
            this.viewModel.orderDetails.files = [];
            this.viewModel.orderDetails.invalidFiles = [];
        }

        this.importBulkServiceAttributesForm.$setPristine();
        this.importBulkServiceAttributesForm.$setUntouched();
    }

    sendBulkServiceAttributesUploadNotification(orderDetails, fileDetails) {
        this.actions.sendBulkServiceAttributesUploadNotification(
            orderDetails, fileDetails)
            .then(
                () => {
                    this.handleUploadNotificationResponse(orderDetails, fileDetails);
                },
                (error) => {
                    this.handleUploadNotificationResponse(orderDetails, fileDetails, error);
                });
    }

    // NOTE: `this.viewModel.orderDetails.files` is used instead of `this.state.jobDetailsViewModel.pendingFiles`
    // below since the File object properties are stripped by Immutable when adding to the store. Otherwise they should
    // be in sync
    submit() {
        if (this.importBulkServiceAttributesForm.$valid &&
            this.viewModel.orderDetails.files.length) {
            this.actions.setIsImportingBulkServiceAttributes(true);

            const utcDateAndTime = moment().utc().format(moment.defaultFormatUtc);

            this.viewModel.orderDetails.files.forEach((file) => {
                this.uploadFileToAwsS3Bucket(utcDateAndTime, file);
            });
        }
    }
}

export default {
    bindings: {
        onClose: '&',
        orderDetails: '<'
    },
    controller: ImportBulkAttributesModalController,
    controllerAs: 'ctrl',
    template: require('./import.bulk.attributes.modal.html')
};
