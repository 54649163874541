import __ from 'ramda/src/__';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import CustomerCareKeys from '../../../../locales/keys';
import {
    BILLER_RULE_CYCLE_LEVEL,
    BILLER_RULE_INSTANCE_TYPE,
    INVOICE_TIMING_CONSTANTS,
    ORDER_SCENARIOS
} from '../../../../customercare.constants';
import {CHARGE_TIMING} from '../../../../reducers/constants/wizard.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {MetadataCodeLoadedSelector, MetadataCodeTypeSansGlobalSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {OfferingMetadataByIdSelector, OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {
    AMOUNT_TYPES,
    getSummaryTotalsLabel
} from '../../../../reducers/helpers/summary.tab.helper';
import {
    MINI_CART_MORE_ACTIONS,
    moreActionMenuItems
} from './summary.tab.helper';
import {getRetrieveOrderQuoteCountdown} from '../../../../reducers/helpers/async.helper';
class SummaryTabController {
    constructor($ngRedux, $scope, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $scope,
            $timeout,
            AMOUNT_TYPES,
            CHARGE_TIMING,
            customerCareKeys: CustomerCareKeys,
            disconnectRedux: null,
            editMenuItems: moreActionMenuItems([MINI_CART_MORE_ACTIONS.CHANGE, MINI_CART_MORE_ACTIONS.EDIT, MINI_CART_MORE_ACTIONS.REMOVE]),
            editMenuItemsWithoutChange: moreActionMenuItems([MINI_CART_MORE_ACTIONS.EDIT, MINI_CART_MORE_ACTIONS.REMOVE]),
            getBriAmountByType: this.getBriAmountByType.bind(this),
            getBriDiscountByType: this.getBriDiscountByType.bind(this),
            getBriType: this.getBriType.bind(this),
            getRetrieveOrderQuoteCountdown,
            getSummaryTotalsLabel,
            handleEditItemAction: this.handleEditItemAction.bind(this),
            handleMoreActionSelected: this.handleMoreActionSelected.bind(this),
            handleRemoveItemAction: this.handleRemoveItemAction.bind(this),
            removeMenuItems: moreActionMenuItems([MINI_CART_MORE_ACTIONS.REMOVE]),
            showRefundMessage: this.showRefundMessage.bind(this),
            uiNotificationService,
        });
    }

    $onInit() {
        const codeTables = [
            CODES.RegularExpression,
            CODES.ServiceAttribute,
            CODES.OffCycleConfig
        ];
        this.connectRedux();
        this.shouldShowCaveat = () => {
            if (!this.isCheckoutStep || !this.caveatMessages) {
                return false;
            } else if (this.viewModel.dueTotals && this.viewModel.dueTotals.orderQuoteTotals) {
                return this.viewModel.dueTotals.orderQuoteTotals.some((item) => {
                    return item.chargeTiming === CHARGE_TIMING.BILL_CYCLE;
                });
            } else {
                return this.isCheckoutStep;
            }
        };

        this.anyIconsShown = (products = []) => {
            return products.some((product) => {
                return product.isAdded || product.isRemoved || product.isSwapped;
            });
        };

        this.isInvoiceImmediately = (billerRule) => {
            return pathOr(0, ['InvoiceTiming'], billerRule) === INVOICE_TIMING_CONSTANTS.INVOICE_IMMEDIATELY;
        };

        this.getChargeToolTipIcon = (billerRule) => {
            const invoiceTiming = pathOr(null, ['InvoiceTiming'], billerRule);
            switch (invoiceTiming) {
                case INVOICE_TIMING_CONSTANTS.INVOICE_IMMEDIATELY:
                    return 'exclamation-circle';
                case INVOICE_TIMING_CONSTANTS.INVOICE_ON_CYCLE:
                    return 'exclamation-circle';
                default:
                    return null;
            }
        };

        this.getTermLength = (product) => {
            return product.termLength || product.billerRule.TermLength || product.billerRule.DefaultTermLength;
        };

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.getChargeToolTipText = (billerRule) => {
            const invoiceTiming = pathOr(null, ['InvoiceTiming'], billerRule);
            switch (invoiceTiming) {
                case INVOICE_TIMING_CONSTANTS.INVOICE_IMMEDIATELY:
                    return i18n.translate(this.customerCareKeys.CART.INVOICE_IMMEDIATELY);
                case INVOICE_TIMING_CONSTANTS.INVOICE_ON_CYCLE:
                    return i18n.translate(this.customerCareKeys.CART.INVOICE_ON_CYCLE);
                default:
                    return null;
            }
        };

        this.contractTermsConditionsPopupConfig = {
            onRegisterApi: (event) => {
                this.contractTermsConditionsPopupApi = event.api;
            }
        };

        this.openContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupOpened = true;
            this.$timeout(() => {
                this.contractTermsConditionsPopupApi.open();
            });
        };

        this.handleCloseContractTermsConditionsPopup = () => {
            this.contractTermsConditionsPopupApi.close();
            this.contractTermsConditionsPopupOpened = false;
        };

        this.hasNegativeDueToday = () => {
            return this.viewModel?.dueTotals?.orderQuoteTotals?.some((item) => {
                return item.chargeTiming === CHARGE_TIMING.DUE_TODAY && item.totalAmount < 0;
            });
        };

        this.hasOffCycle = () => {
            return Object.values(this.state.offeringMetadata).some(
                (offeringMetaData) => {
                    return (
                        offeringMetaData.BillerRuleCycleLevel ===
                        BILLER_RULE_CYCLE_LEVEL.ITEM
                    );
                }
            );
        };
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    connectRedux() {
        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                isDbss: IsDbss(store),
                offCycleConfiguration: MetadataCodeTypeSansGlobalSelector(
                    CODES.OffCycleConfig,
                    store
                ),
                offeringMetadataPricingPlans: OfferingMetadataPricingPlansSelector(
                    store
                ),
                offeringMetadata: OfferingMetadataByIdSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeTypes
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    handleEditItemAction(action, offeringInstanceId, offeringId) {
        switch (action) {
            case MINI_CART_MORE_ACTIONS.CHANGE:
                this.onChangeOffer()(offeringInstanceId, offeringId);
                break;
            case MINI_CART_MORE_ACTIONS.EDIT:
                this.onEditOffer()(offeringInstanceId);
                break;
            case MINI_CART_MORE_ACTIONS.REMOVE:
                this.onRemoveOffer()(offeringInstanceId);
                break;
            default:
                break;
        }
    }

    getChargeTimingTitle(chargeTime) {
        switch (convertStringToNumber(chargeTime)) {
            case CHARGE_TIMING.BILL_CYCLE:
                return i18n.translate(CustomerCareKeys.CHARGE_TIMING.ADDED_NEXT_INVOICE);
            case CHARGE_TIMING.DUE_ON_ACTIVATION:
                return i18n.translate(CustomerCareKeys.CHARGE_TIMING.DUE_ON_ACTIVATION);
            case CHARGE_TIMING.DUE_ON_FIRST_USE:
                return i18n.translate(CustomerCareKeys.CHARGE_TIMING.DUE_ON_FIRST_USE);
            case CHARGE_TIMING.DUE_TODAY:
                return i18n.translate(CustomerCareKeys.CHARGE_TIMING.DUE_TODAY);
            default:
                break;
        }
    }

    getSubtotalAmount(chargeTime) {
        switch (convertStringToNumber(chargeTime)) {
            case CHARGE_TIMING.BILL_CYCLE:
                return this.viewModel.dueSubTotals.dueNextInvoice.subTotal;
            case CHARGE_TIMING.DUE_ON_ACTIVATION:
                return this.viewModel.dueSubTotals.dueOnActivation.subTotal;
            case CHARGE_TIMING.DUE_ON_FIRST_USE:
                return this.viewModel.dueSubTotals.dueOnFirstUse.subTotal;
            case CHARGE_TIMING.DUE_TODAY:
                return this.viewModel.dueSubTotals.dueToday.subTotal;
            default:
                break;
        }
    }

    handleRemoveItemAction(offeringInstanceId) {
        this.onRemoveOffer()(offeringInstanceId);
    }

    getBriType(charge) {
        switch (charge.type) {
            case BILLER_RULE_INSTANCE_TYPE.RECURRING:
                return i18n.translate(this.customerCareKeys.CART_SUMMARY.RECURRING);
            case BILLER_RULE_INSTANCE_TYPE.ONETIME:
                return i18n.translate(this.customerCareKeys.CART_SUMMARY.ONE_TIME);
            default:
                break;
        }
    }

    getBriAmountByType(charge, product) {
        if (product.hasOverriddenPrice) {
            const overriddenCharge = (product.billerRuleDetails || []).find((billerRuleDetails) => {
                //there can be multiple recurring charges so need to compare by id as well as type in case of recurring charge
                return (charge.type === BILLER_RULE_INSTANCE_TYPE.RECURRING) || (charge.type === BILLER_RULE_INSTANCE_TYPE.ONETIME) ?
                    charge.billerRuleConfigurationId === billerRuleDetails.billerRuleConfigurationId :
                    billerRuleDetails.type === charge.type;
            });
            return overriddenCharge && typeof overriddenCharge.amount === 'number' ? overriddenCharge.amount : charge.amount;
        } else {
            switch (charge.type) {
                case BILLER_RULE_INSTANCE_TYPE.RECURRING:
                    return charge.amount;
                case BILLER_RULE_INSTANCE_TYPE.ONETIME:
                    return charge.amount;
                default:
                    break;
            }
        }
    }

    getBriDiscountByType(charge) {
        switch (charge.type) {
            case BILLER_RULE_INSTANCE_TYPE.RECURRING:
                return charge.discount;
            case BILLER_RULE_INSTANCE_TYPE.ONETIME:
                return charge.discount;
            case BILLER_RULE_INSTANCE_TYPE.FINANCE:
                return charge.discount;
            default:
                break;
        }
    }


    handleMoreActionSelected(action, offeringInstanceId, offeringId) {
        if (this.onMoreActionSelected()) {
            this.onMoreActionSelected()(action, offeringInstanceId, offeringId);
        }
    }

    showRefundMessage() {
        const isAutomaticProratedCreditDisabled = this.state.offCycleConfiguration.some((offCycleConfig) => {
            return (offCycleConfig.AdditionalProperties.find(propEq('disable_automatic_prorated_credit', 'Key')).Value === 'True');
        });
        return (
            (this.orderScenario === ORDER_SCENARIOS.CHANGE_ORDER ||
                this.orderScenario === ORDER_SCENARIOS.TRANSITION_OFFER) &&
            isAutomaticProratedCreditDisabled &&
            this.hasOffCycle() &&
            this.hasNegativeDueToday()
        );
    }

    isBriOptionBulk(pricingPlanId, billerRule) {
        if (!Array.isArray(billerRule)) {
            return this.state &&
                this.state.offeringMetadataPricingPlans[pricingPlanId] &&
                this.state.offeringMetadataPricingPlans[pricingPlanId].BillerRuleInstanceThumbnails.some((briThumbnail) => {
                    return briThumbnail.Bulk && briThumbnail.BillerRuleConfigurationId === billerRule.BillerRuleConfigurationId;
                });
        } else {
            return billerRule.some((bri) => {
                return this.state &&
                    this.state.offeringMetadataPricingPlans[pricingPlanId] &&
                    this.state.offeringMetadataPricingPlans[pricingPlanId].BillerRuleInstanceThumbnails.some((briThumbnail) => {
                        return briThumbnail.Bulk && briThumbnail.BillerRuleConfigurationId === bri.BillerRuleConfigurationId;
                    });
            });
        }
    }
}

export default {
    template: require('./summary.tab.html'),
    bindings: {
        caveatMessages: '<?',
        displayEditableItemsOnly: '<?',
        displayRemoveItems: '<?',
        displaySummaryTab: '<?',
        editableItems: '<?',
        hasSubscriptionQuote: '<?',
        isCheckoutStep: '<?',
        orderScenario: '<?',
        isDiscountHidden: '<?',
        isMultiOffer: '<?',
        isQuoteBypassed: '<?',
        moreActionMenuItems: '<',
        onChangeOffer: '&?',
        onEditOffer: '&?',
        onMoreActionSelected: '&?',
        onPreviewRenewalStatements: '&?',
        onRefreshQuote: '&?',
        onRemoveOffer: '&?',
        removableItems: '<?',
        retrieveOrderQuoteTimestamp: '<?',
        selectedOfferingServiceIdentifiers: '<?',
        viewModel: '<' // See SummaryTabViewModel in new.connect.wizard.selectors.js,
    },
    controller: SummaryTabController,
    controllerAs: 'summaryTabController'
};
