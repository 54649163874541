import {
    emptyContentActivityList,
    removeContentActivityFilter
} from '../../../reducers/actions/customer.content.activity.actions';

const template = '<content-activity></content-activity>';
const params = {
    deviceName: null,
    previousRoute: null
};

export const DETAIL_STATE_OR_NAME = 'index.customercare.customer.contentActivity.product';
export const DEVICE_DETAIL_STATE_OR_NAME = 'index.customercare.customer.contentActivity.device';

export default ($stateProvider) => {
    $stateProvider.state('index.customercare.customer.contentActivity', {
        template,
        url: '/contentActivity',
        params,
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(removeContentActivityFilter());
            $ngRedux.dispatch(emptyContentActivityList());
        }
    }).state(DETAIL_STATE_OR_NAME, {
        template,
        url: '/product/:productId',
        params
    }).state(DEVICE_DETAIL_STATE_OR_NAME, {
        template,
        url: '/device/:deviceId',
        params
    });
};
