import {
    clone
} from 'ramda';
import {CASE_CATEGORIES, CASE_STATUS} from '../cases/list/case.constants';

export function transformCaseForService(editMode, editCase, categoryDetails) {
    const clonedCase = clone(editCase);
    if (editCase.Category === CASE_CATEGORIES.TROUBLE_CALL || editCase.CaseTemplateId === CASE_CATEGORIES.TROUBLE_CALL ||
        editCase.Category === CASE_CATEGORIES.INVOICE_DISPUTE || editCase.CaseTemplateId === CASE_CATEGORIES.INVOICE_DISPUTE) {
        clonedCase.Details = getCaseDetails(editMode, clonedCase, categoryDetails);
    }
    return clonedCase;
}

export function shouldHaveResolutionText({Status}={}) {
    return Status === CASE_STATUS.CLOSED || Status === CASE_STATUS.CANCELED;
}

function getCaseDetails(editMode, editCase, categoryDetails={}) {
    const details = {};

    if (editCase.Category === CASE_CATEGORIES.TROUBLE_CALL || editCase.CaseTemplateId === CASE_CATEGORIES.TROUBLE_CALL) {
        details.TroubleCallDetails = categoryDetails;
    } else if (editCase.Category === CASE_CATEGORIES.INVOICE_DISPUTE || editCase.CaseTemplateId === CASE_CATEGORIES.INVOICE_DISPUTE) {
        details.InvoiceDisputeDetails = editCase.Details.InvoiceDisputeDetails || {};
        details.InvoiceDisputeDetails.ApprovedAmount = details.InvoiceDisputeDetails.ApprovedAmount || 0;
    }
    return details;
}
