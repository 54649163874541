import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../../../../../locales/keys';
import {CHARGE_TIMING} from '../../../../../../reducers/constants/wizard.constants';
import {getRetrieveOrderQuoteCountdown} from '../../../../../../reducers/helpers/async.helper';
import {determineClassForComponent} from '../cart.helper';
class FullCartController {
    constructor() {
        Object.assign(this, {
            assignedClass: null,
            CHARGE_TIMING,
            getRetrieveOrderQuoteCountdown,
            hideItem: [],
            isExpanded: {
                offeringOrderQuotes: [],
                shippingQuotes: false,
                taxQuotes: false,
                renewalSchedulesQuotes: []
            },
            isHeaderExpanded: true,
            LocaleKeys,
            onOffcycleOptionSelect: this.onOffcycleOptionSelect.bind(this),
            updateExpandProperty: this.updateExpandProperty.bind(this),
            ORDER_TOTALS: i18n.translate(LocaleKeys.OFFCYCLE_CART.ORDER_TOTALS),
            RENEWAL_SCHEDULE: i18n.translate(LocaleKeys.OFFCYCLE_CART.RENEWAL_SCHEDULE)
        });
    }

    $onInit() {
        if (this.hasOffcycleOrders) {
            this.offcyleDropdownOptions = [
                {
                    text: this.ORDER_TOTALS,
                    selected: true
                },
                {
                    text: this.RENEWAL_SCHEDULE,
                    selected: false
                }
            ];
        }
        this.assignedClass = determineClassForComponent(this.showHideFullCartColumns);

    }

    onOffcycleOptionSelect(item) {
        this.offcyleDropdownOptions.forEach((option) => {
            option.selected = option.text === item.text;
        });
        this.selectedOffcycleDropdown = item.text;
        if (item.text === this.RENEWAL_SCHEDULE) {
            this.assignedClass = determineClassForComponent();
            this.isExpanded.renewalSchedulesQuotes[0] = true;
        } else {
            this.assignedClass = determineClassForComponent(this.showHideFullCartColumns);
            this.collapseAll();
        }
    }

    expandAll() {
        for (const i in this.isExpanded) {
            if (Array.isArray(this.isExpanded[i])) {
                this.updateExpandProperty(i, true);
            } else {
                this.isExpanded[i] = true;
            }
        }
    }

    collapseAll() {
        for (const i in this.isExpanded) {
            if (Array.isArray(this.isExpanded[i])) {
                this.updateExpandProperty(i, false);
            } else {
                this.isExpanded[i] = false;
            }
        }
    }

    updateExpandProperty(index, value) {
        for (let item = 0; item <= (index === 'renewalSchedulesQuotes' ? this.renewalSchedules.length : this.offeringOrderQuotes.length); item++) {
            this.isExpanded[index][item] = value;
            if (this.offeringOrderQuotes[item] && this.offeringOrderQuotes[item].isPlaceHolderForExistingItems) {
                this.expandExistingHeaderItems(item, !value);
            }
        }
    }

    onExpandToggle(quoteItem, index) {
        if (quoteItem === 'offeringOrderQuotes' || quoteItem === 'renewalSchedulesQuotes') {
            this.isExpanded[quoteItem][index] = !this.isExpanded[quoteItem][index];
        } else {
            this.isExpanded[quoteItem] = !this.isExpanded[quoteItem];
        }
    }

    expandExistingHeaderItems(index, value) {
        for (let i = 0; i <= this.offeringOrderQuotes.length; i++) {
            if (i > index ) {
                this.hideItem[i] = value || !this.hideItem[i];
            }
        }
    }
}

export default {
    bindings: {
        currency: '<',
        displayAsyncQuoteMessage: '<?',
        fees: '<',
        isChange: '<?',
        isEdit: '<?',
        isNewConnect: '<?',
        handleEdit: '&',
        handleRemove: '&',
        renewalSchedules: '<',
        hasOffcycleOrders: '<',
        gotoDecisionsPage: '&?',
        offeringOrderQuotes: '<',
        onClose: '&',
        onRefreshQuote: '&?',
        retrieveOfferingOrderQuoteTimestamp: '<?',
        selectedOfferingInstanceId: '<',
        shipping: '<?',
        showHideFullCartColumns: '<',
        subtotal: '<',
        taxes: '<'
    },
    template: require('./full.cart.html'),
    controller: FullCartController
};
