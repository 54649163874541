import CustomerCareLocaleKeys from '../../../locales/keys';
import {ACCOUNTING_METHOD_TYPES} from '../../../customercare.constants';
export const DEFAULT_ACCOUTNING_METHOD_CODE = 'default_accounting_method_code';
export const ACCOUNTING_METHODS = {
    1: {
        id: CustomerCareLocaleKeys.PROFILE_PAGE.ACCOUNTING_METHODS.BALANCE_FORWARD,
        value: ACCOUNTING_METHOD_TYPES.BALANCE_FORWARD
    },
    2: {
        id: CustomerCareLocaleKeys.PROFILE_PAGE.ACCOUNTING_METHODS.OPEN_INVOICE,
        value: ACCOUNTING_METHOD_TYPES.OPEN_INVOICE
    },
};