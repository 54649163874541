import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';
import {
    ADJUSTMENT_CATEGORIES,
    INVOICE,
    INVOICE_START_DATE_DURATION,
    MAX_DESCR_LENGTH,
    MIN_NOTE_AMOUNT,
    NOTE_TYPES
} from './applyTaxCertificate.modal.constants';
import {constraintErrorMessages} from 'invision-core/src/utilities/angular.form.utilities';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {CurrentBusinessUnitCurrencyCodeSelector} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    createConvergentBillerAdjustment,
    retrieveInvoicesForDropdown
} from '../../../reducers/actions/customer.billing.actions';
import * as BillingPaymentsConstants from '../billing.payments.constants';
import {
    AdjustmentConfigSelector,
    AdjustmentReasonCodeForTaxSelectionOptions,
    HierarchyInvoiceNodeSubscriberIdSelector,
    InvoiceListWithTaxForDropDownSelector,
    InvoiceSummariesSelector,
    IsFetchingInvoiceListSelector,
} from '../../../reducers/selectors/customer.billing.selectors';
import {IsCreatingConvergentBillerAdjustmentSelector} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {CurrentCustomerSelector} from '../../../reducers/selectors/customer.selectors';
import LocaleKeys from '../../../locales/keys';
import moment from 'moment';
import {REPORTING_LEVELS} from '../../../components/customer/accountHierarchy/account.hierarchy.constants';

class ApplyTaxCertificateModalController {
    constructor($ngRedux, $filter, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            isStatement : this.isStatement.bind(this),
            MAX_DESCR_LENGTH,
            MIN_NOTE_AMOUNT,
            NOTE_TYPES,
            onTaxCertificateDateChanged: this.onTaxCertificateDateChanged.bind(this),
            onInvoiceSelected: this.onInvoiceSelected.bind(this),
            selectedInvoiceNumber: null,
            selectedReasonCode: null,
            taxCertificateDate: moment(),
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentConfig: AdjustmentConfigSelector(store),
                adjustmentReasonCodes: AdjustmentReasonCodeForTaxSelectionOptions(store),
                adjustmentReasonCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdjustmentReason, store),
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                hierarchyInvoiceNodeSubscriberId: HierarchyInvoiceNodeSubscriberIdSelector(store),
                invoiceList: InvoiceListWithTaxForDropDownSelector(store),
                invoiceSummaries: InvoiceSummariesSelector(store),
                isCreatingConvergentBillerAdjustment: IsCreatingConvergentBillerAdjustmentSelector(store),
                isFetchingInvoiceList: IsFetchingInvoiceListSelector(store)
            };
        };
        const controllerActions = {
            createConvergentBillerAdjustment,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveInvoicesForDropdown
        };

        this.localeKeys = LocaleKeys;
        this.noteType = NOTE_TYPES.CREDIT;
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.adjustmentReasonCodesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentReason);
        }

        if (this.state.invoiceSummaries.length) {
            this.currencyCode = this.state.invoiceSummaries[0].CurrencyCode;
        } else {
            this.currencyCode = this.state.currentBusinessUnitCurrencyCode;
        }

        this.enableGenerateStatements = this.state.adjustmentConfig[BillingPaymentsConstants.ENABLE_GENERATE_STATEMENT] === BillingPaymentsConstants.ADJUSTMENT_CONFIG_ENABLED ? true : false;
        this.getInvoiceAndDebitNotesList();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getInvoiceAndDebitNotesList() {
        const getInvoiceAndDebitNotesListRequest = {
            customerId: this.state.currentCustomer.Id,
            request: {
                Filter: BillingPaymentsConstants.INVOICES
            }
        };
        this.actions.retrieveInvoicesForDropdown(getInvoiceAndDebitNotesListRequest)
            .then(() => {
                if (this.invoiceId) {
                    this.selectedInvoiceNumber = this.state.invoiceList.find((invoice) => {
                        return invoice.id === this.invoiceId;
                    });
                    this.onInvoiceSelected();
                }
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    get adjustmentReasonCodes() {
        const adjustmentReasonCodes = pathOr([], ['adjustmentReasonCodes'], this.state);
        return adjustmentReasonCodes.filter((reasonCode) => {
            return (
                (
                    reasonCode.adjustment_category === ADJUSTMENT_CATEGORIES.OTHER ||
                    isEmpty(reasonCode.adjustment_category)
                ) && (
                    this.noteType === reasonCode.adjustment_payment_type ||
                    isEmpty(reasonCode.adjustment_payment_type)
                )
            );
        });
    }

    closeModal() {
        this.onClose();
    }

    validateForm() {
        this.formErrorMessages = constraintErrorMessages(undefined, this.applyTaxCertificateForm, {
            taxAmount: LocaleKeys.APPLY_TAX_CERTIFICATE.TAX_AMOUNT,
            taxCertificateDate: LocaleKeys.APPLY_TAX_CERTIFICATE.TAX_CERTIFICATE_DATE,
            taxCertificateNumber: LocaleKeys.APPLY_TAX_CERTIFICATE.WITHHELD_TAX_CERTIFICATE_ID,
            noteDescription: LocaleKeys.ADJUSTMENTS.DESCRIPTIONS,
            reasonCode: LocaleKeys.ADJUSTMENTS.REASON_CODE
        });
    }

    onTaxCertificateDateChanged(taxCertificateDate) {
        this.taxCertificateDate = taxCertificateDate;
        this.validateForm();
    }

    onInvoiceSelected() {
        if (this.selectedInvoiceNumber) {
            this.taxAmount = this.selectedInvoiceNumber.taxDueAmount;
            this.validateForm();
        }
    }

    isStatement() {
        return this.state.currentCustomer?.HierarchyReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT;
    }


    applyTaxCertificate() {
        if (this.applyTaxCertificateForm.$valid) {
            const request = {
                customerId: this.state.currentCustomer.Id,
                request: {
                    AccountNumber: this.accountNumber,
                    AdjustmentTypeCode: NOTE_TYPES.CREDIT,
                    Amount: parseFloat(this.taxAmount).toFixed(2),
                    Description: this.noteDescription,
                    GenerateStatement: this.enableGenerateStatements,
                    DebitNoteId: null,
                    InvoiceId: this.selectedInvoiceNumber && this.selectedInvoiceNumber.id || null,
                    PostDate: new Date(),
                    ReasonCode: this.selectedReasonCode,
                    TaxCertificateDate: this.taxCertificateDate,
                    TaxCertificateId: this.taxCertificateNumber
                }
            };
            this.actions.createConvergentBillerAdjustment(request).then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(LocaleKeys.APPLY_TAX_CERTIFICATE.APPLY_TAX_CERTIFICATE_SUCCESS));
                this.onSuccess();
            }).catch((error) => {
                if (this.formErrorMessages) {
                    this.formErrorMessages.length = 0;
                }
                this.formErrorMessages.push(error.translatedMessage);
            });
        } else {
            this.validateForm();
        }
    }
}

export default {
    controller: ApplyTaxCertificateModalController,
    controllerAs: 'ApplyTaxCertificateModalController',
    template: require('./applyTaxCertificate.modal.html'),
    bindings: {
        accountNumber: '<?',
        invoiceNumber: '<?',
        invoiceId: '<?',
        config: '<',
        onClose: '&',
        onSuccess: '&'
    }
};
