import angular from 'angular';
import 'angular-hotkeys';
import 'invision-core';

import AssociateServices from './associateServices/associate.services.module';
import CreateMemberPopupModule from './createMemberPopup/create.member.popup.module';
import EditHouseholdPopupModule from './editHouseholdPopup/edit.household.popup.module';
import MemberDetails from './memberDetails/member.details.module';
import MemberList from './memberList/member.list.module';
import Overview from './overview/overview.module';
import OverviewAndMemberDetails from './details/details.module';
import MemberPrivileges from './memberPrivileges/member.privileges.module';

import CustomerHousehold from './household.component';

import {routing} from './household.config';

export default angular.module('invision.customercare.customer.household', [
    'cfp.hotkeys',
    'invision.core.components',
    'invision.ui.components.openNewWindow',
    AssociateServices,
    CreateMemberPopupModule,
    EditHouseholdPopupModule,
    MemberDetails,
    MemberList,
    Overview,
    OverviewAndMemberDetails,
    MemberPrivileges
])
    .config(routing)
    .component('customerHousehold', CustomerHousehold)
    .name;
