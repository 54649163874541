import clone from 'ramda/src/clone';
import omit from 'ramda/src/omit';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import CustomerCareKeys from '../../../../locales/keys';
import {getColumnDefinitions} from './product.service.relationship.modal.helper';


class ProductServiceRelationshipModalComponent {

    constructor() {
        Object.assign(this, {
            columnDefs: getColumnDefinitions(),
            CustomerCareKeys,
            defaultSelectOption: {
                label: i18n.translate(CoreLocaleKeys.SELECT),
                value: undefined
            },
            productServiceRelationships: []
        });
    }

    $onChanges({consumeServices}) {
        if (consumeServices && consumeServices.currentValue.length) {
            this.productServiceRelationships = clone(consumeServices.currentValue).map(consumeService => {
                const selectedProvidesPlan = consumeService.ProviderPlanDetail ? {
                    label: consumeService.ProviderPlanDetail.PricingPlanName,
                    value: consumeService.ProviderPlanDetail.PricingPlanId.Value
                } : this.defaultSelectOption;

                return {
                    ...consumeService,
                    isDisabled: !(consumeService.LinkableProviderPlans|| []).length,
                    providesPlans: [this.defaultSelectOption].concat((consumeService.LinkableProviderPlans|| []).map((providesPlan) => {
                        return {
                            label: providesPlan.PricingPlanName,
                            value: providesPlan.PricingPlanId.Value
                        };
                    })),
                    selectedProvidesPlan
                };
            });
        }
    }

    onSave() {
        const updatedProductServiceRelationships = this.productServiceRelationships.map(consumeService => {
            return omit(['providesPlans', 'selectedProvidesPlan', 'isDisabled', 'serviceAttributeName'], {
                ...consumeService,
                ProviderPlanDetail: consumeService.LinkableProviderPlans.find(providesPlan => {
                    return providesPlan.PricingPlanId.Value === consumeService.selectedProvidesPlan.value;
                })
            });
        });

        this.onSubmit()(updatedProductServiceRelationships);
    }
}

export default {
    bindings: {
        consumeServices: '<',
        onClose: '&',
        onSubmit: '&'
    },
    controller: ProductServiceRelationshipModalComponent,
    template: require('./product.service.relationship.modal.template.html')
};