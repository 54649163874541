import {isNil} from 'ramda';
import CustomerCareKeys from '../../../../locales/keys';
import {performCreditCheck} from '../../../../reducers/actions/credit.check.actions';
import {
    IsCreditCheckEnabledSelector,
    IsMatchSubscriberCreditCheckDetailsSelector,
    SubscriberCreditCheckDetailsSelector,
    SubscriberCreditCheckStatusSelector
} from '../../../../reducers/selectors/credit.check.selectors';
import {CurrentCustomerSelector} from '../../../../reducers/selectors/customer.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

class StatusButtonController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareKeys,
            uiNotificationService
        });
    }

    $onInit() {
        this.connectRedux();
        if (!this.state.subscriberCreditCheckDetails.isFetchingData && !this.state.isMatchSubscriberCreditCheckDetails) {
            this.performCreditCheck();
        }
    }

    $onChanges(changesObj) {
        this.connectRedux();
        if (changesObj.subscriberId && !isNil(changesObj.subscriberId.currentValue) && changesObj.subscriberId.currentValue !== changesObj.subscriberId.previousValue) {
            if (!this.state.subscriberCreditCheckDetails.isLoaded && !this.state.subscriberCreditCheckDetails.isFetchingData) {
                this.performCreditCheck();
            }
        }
        if (changesObj.subscriberCategory && !isNil(changesObj.subscriberCategory.currentValue) && changesObj.subscriberCategory.currentValue !== changesObj.subscriberCategory.previousValue) {
            if (!this.state.subscriberCreditCheckDetails.isFetchingData) {
                this.performCreditCheck();
            }
        }
        if (changesObj.subscriberType && changesObj.subscriberType.currentValue) {
            if (!this.state.subscriberCreditCheckDetails.isFetchingData) {
                this.performCreditCheck();
            }
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    buttonClicked() {
        if (this.onClick()) {
            this.onClick()();
        }
    }

    connectRedux() {
        if  (!this.disconnectRedux) {
            const controllerActions = {
                performCreditCheck
            };
            const mapStateToTarget = (store) => {
                return {
                    currentCustomer: CurrentCustomerSelector(store),
                    isCreditCheckEnabled: IsCreditCheckEnabledSelector(store),
                    isMatchSubscriberCreditCheckDetails: IsMatchSubscriberCreditCheckDetailsSelector(store),
                    subscriberCreditCheckDetails: SubscriberCreditCheckDetailsSelector(store) || {},
                    subscriberCreditCheckStatus: SubscriberCreditCheckStatusSelector(store)
                };
            };

            this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
                this.state = state;
                this.actions = actions;
            });
        }
    }

    performCreditCheck() {
        this.actions.performCreditCheck(this.subscriberCategory, this.subscriberId, convertStringToNumber(this.subscriberType)).catch((err) => {
            this.uiNotificationService.transientError(err.translatedMessage);
        });
    }
}

export default {
    template: require('./status.button.html'),
    controller: StatusButtonController,
    bindings: {
        onClick: '&?',
        subscriberCategory: '<', // Subscriber Category or ID is required
        subscriberId: '<',
        subscriberType: '<'
    },
    controllerAs: 'StatusButton'
};
