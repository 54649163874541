// import {
//     CasesMountPoint,
//     CasesRouteConstants,
//     CasesListPage,
//     CasesDetailsPage
// } from '@invision/customercare2/src/customer/cases';
// import React from 'react';
// import { UIView } from '@uirouter/react';

export const CASES_LIST_STATE_REDIRECT =
    'index.customercare.customer.casesRedirect';
export const CASES_DETAILS_STATE_REDIRECT =
    'index.customercare.customer.cases.detailsRedirect';

// export default function routing($stateProvider) {
//     $stateProvider.state(CASES_LIST_STATE_REDIRECT, {
//         url: CasesRouteConstants.CASES_PAGE.pathSegment,
//         redirectTo: CasesRouteConstants.CASES_LIST_PAGE.name
//     });

//     $stateProvider.state(CASES_DETAILS_STATE_REDIRECT, {
//         url: CasesRouteConstants.CASES_DETAILS_PAGE.pathSegment,
//         redirectTo: CasesRouteConstants.CASES_DETAILS_PAGE.name
//     });

//     $stateProvider.state({
//         abstract: true,
//         url: CasesRouteConstants.CASES_PAGE.pathSegment,
//         name: CasesRouteConstants.CASES_PAGE.name,
//         component: ({ transition }) => {
//             const { customerId } = transition.params();
//             return (
//                 <div className="u-sizeFull">
//                     <CasesMountPoint routeParams={{ customerId }}>
//                         <UIView />
//                     </CasesMountPoint>
//                 </div>
//             );
//         }
//     });

//     $stateProvider.state({
//         url: CasesRouteConstants.CASES_LIST_PAGE.pathSegment,
//         name: CasesRouteConstants.CASES_LIST_PAGE.name,
//         component: ({ transition }) => {
//             const { customerId } = transition.params();
//             return <CasesListPage customerId={customerId} />;
//         }
//     });

//     $stateProvider.state({
//         url: CasesRouteConstants.CASES_DETAILS_PAGE.pathSegment,
//         name: CasesRouteConstants.CASES_DETAILS_PAGE.name,
//         component: ({ transition }) => {
//             const { customerId, caseId } = transition.params();
//             return <CasesDetailsPage customerId={customerId} caseId={caseId} />;
//         }
//     });
// }
