import angular from 'angular';

import createProductsOrderWizardConfig from './create.products.order.wizard.config';
import ApplyChangesFieldComponent from './steps/checkout/apply.changes.field.component';
import CheckoutComponent from './steps/checkout/products.checkout.component';
import ProductsWizardComponent from './create.products.order.wizard.component';
import ProductsComponent from './steps/products/products.component';
import ProductDialogComponent from './steps/products/productDialog/product.dialog.component';
import ProductDecisionsComponent from './steps/products/productDecisions/product.decisions.component';
import AdvancedSearchFilterBarComponent from './steps/products/advancedSearchFilterBar/advanced.search.filter.bar.component';
import IncludedItemComponent from './steps/products/product.included.item.component';
import SelectableItemsComponent from './steps/products/product.selectable.items.component';
import EditInstancePropertiesComponent from './steps/products/edit.instance.properties.component';
import CartComponent from './cart/create.products.order.cart.component';
import CartItemComponent from './cart/create.products.order.cart.item.component';
import DiscountsTooltipComponent from './tooltips/discounts.tooltip.component';
import TaxesTooltipComponent from './tooltips/taxes.tooltip.component';
import ShippingTooltipComponent from './tooltips/shipping.tooltip.component';

export default angular.module('index.customercare.customer.orders.createProductsOrder', ['invision.core.services'])
    .component('applyChangesField', ApplyChangesFieldComponent)
    .component('ottCheckoutStep', CheckoutComponent)
    .component('ottProductCartItem', CartItemComponent)
    .component('ottProductCart', CartComponent)
    .component('productsWizard', ProductsWizardComponent)
    .component('productsStep', ProductsComponent)
    .component('productDialog', ProductDialogComponent)
    .component('productDecisions', ProductDecisionsComponent)
    .component('advancedSearchFilterBar', AdvancedSearchFilterBarComponent)
    .component('includedItem', IncludedItemComponent)
    .component('selectableItems', SelectableItemsComponent)
    .component('editInstanceProperties', EditInstancePropertiesComponent)
    .component('discountsTooltip', DiscountsTooltipComponent)
    .component('taxesTooltip', TaxesTooltipComponent)
    .component('shippingTooltip', ShippingTooltipComponent)
    .config(createProductsOrderWizardConfig)
    .name;
