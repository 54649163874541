import clone from 'ramda/src/clone';
import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import CustomerLocaleKeys from '../../../../../../../locales/keys';
import {UNIT_OF_MEASURES} from '../../../../../../../customercare.constants';
import {
    setSelectedPolicyCounter,
    updatePolicyCounterBalance
} from '../..../../../../../../../../reducers/actions/edit.policy.counter.modal.actions';
import {ConvergentBillerCurrencyCodeSelector} from '../../../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveServiceDetails} from '../../../../../../../reducers/actions/services.actions';
import {
    IsLoadingSelector,
    PolicyCountersSelector,
    UnitOfMeasuresSelector
} from '../../../../../../../reducers/selectors/edit.policy.counter.modal.selectors';

export class EditPolicyCounterModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            uiNotificationService
        });
    }

    $onInit() {
        this.disableConfirm = true;
        this.policyCountersToUpdate = [];
        this.selectedPolicyCounter = null;
        this.adjustRemainingBalanceBy = [];

        const controllerActions = {
            retrieveServiceDetails,
            setSelectedPolicyCounter,
            updatePolicyCounterBalance
        };

        const mapStateToProps = (store) => {
            return {
                currencyCode: ConvergentBillerCurrencyCodeSelector(store),
                isLoading: IsLoadingSelector(store),
                policyCounters: PolicyCountersSelector(store),
                unitOfMeasures: UnitOfMeasuresSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.dropdownPolicyCounterItems = this.state.policyCounters.filter((policyCounter) => {
            if (policyCounter.OwningSubscriberId) {
                return policyCounter.BalanceUnitCode !== UNIT_OF_MEASURES.CURRENCY && policyCounter.OwningSubscriberId === this.currentCustomerId;
            } else {
                return policyCounter.BalanceUnitCode !== UNIT_OF_MEASURES.CURRENCY;
            }
        });
    }

    updatePolicyCounter() {
        const updatePolicyCounterBalanceRequest = {
            AdjustmentAmount: +this.adjustRemainingBalanceBy[0].value,
            BalanceUnitCode:  this.adjustRemainingBalanceBy[0].type,
            EntitlementId: +this.selectedPolicyCounter.EntitlementId,
            Reason: this.reasonDescription
        };
        this.actions.updatePolicyCounterBalance(updatePolicyCounterBalanceRequest, this.currentCustomerId)
            .then(() => {
                const serviceIdentifiers = [{
                    Value: this.serviceIdentifier.Value
                }];
                this.uiNotificationService.success(
                    i18n.translate(this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_POLICY_COUNTER_MODAL.POLICY_COUNTER_BALANCE_UPDATE_SUCCESS));
                this.actions.retrieveServiceDetails(this.currentCustomerId, serviceIdentifiers);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.cancelEditPolicyCounterModal();
            });
    }

    handleFormChanged() {
        this.disableConfirm = !(this.adjustRemainingBalanceBy && this.reasonDescription);
    }

    cancelEditPolicyCounterModal() {
        this.actions.setSelectedPolicyCounter({});
        this.onClose();
    }

    onPolicyCounterChange() {
        this.editPolicyCounterForm.$setPristine();
        if (this.selectedPolicyCounter) {
            this.adjustRemainingBalanceBy = [{
                type: +this.selectedPolicyCounter.BalanceUnitCode,
                value: null
            }];
            this.reasonDescription = '';
            this.disableConfirm = true;
        }
        this.actions.setSelectedPolicyCounter(this.selectedPolicyCounter);
        this.uomDropdownOptions = clone(this.state.unitOfMeasures);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: EditPolicyCounterModalController,
    controllerAs: 'EditPolicyCounterModalController',
    template: require('./edit.policy.counter.html'),
    bindings: {
        currentCustomerId: '<',
        onClose: '&',
        serviceIdentifier: '<'
    }
};