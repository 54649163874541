import {i18n} from 'invision-core';
import moment from 'moment';
import CustomerLocaleKeys from './../../../../../locales/keys';
import {CurrentCustomerIdSelector,
    CurrentCustomerSelector} from '../../../../../reducers/selectors/customer.selectors';
import {CurrentLockerItemIdSelector} from '../../../../../reducers/selectors/customer.product.detail.selectors';
import {ActiveProductSelector} from '../../../../../reducers/selectors/customer.products.selectors';
import {grantAdditionalRights} from '../../../../../reducers/actions/customer.product.detail.actions';

const MAX_NUMBER_OF_OPTIONS = 7;

class GrantAccessRightsController {
    constructor($ngRedux) {
        Object.assign(this, {
            CustomerLocaleKeys,
            $ngRedux
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                activeProduct: ActiveProductSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentLockerItemId: CurrentLockerItemIdSelector(store)
            };
        };

        const controllerActions = {
            grantAdditionalRights
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.options = Array.from(new Array(MAX_NUMBER_OF_OPTIONS), (i, index) => {
            return index + 1;
        });

        if (this.policyCollection) {
            this.policyCollection = this.policyCollection.filter((item) => {
                return item.AccessRequestsRemaining || item.LicenseRequestsRemaining || item.ExpirationDate;
            }).asMutable({
                deep:true
            });
        }

        this.hoursPattern = /^\d+$/;
        this.model = {
            Id: this.state.currentLockerItemId || this.state.activeProduct.Id,
            ActionCode: null,
            GrantAccessCount: null,
            GrantLicenseCount: null,
            GrantTimeRemaining: null
        };
    }

    handleClose() {
        this.onClose()();
    }

    onSubmitForm() {
        this.model.ActionCode = this.policy.ActionCode;

        if (this.GrantAccessForm.$valid) {
            return this.actions.grantAdditionalRights(this.state.currentCustomerId, this.model)
                .then(() => {
                    this.onSuccess()();
                });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getSelectDisplayText(value) {
        return value ? i18n.translate(this.CustomerLocaleKeys.SELECT): i18n.translate(this.CustomerLocaleKeys.GRANT_ACCESS.NOT_AVAILABLE);
    }

    getDialogSize() {
        return this.policyCollection ? 'medium' : 'small';
    }

    displayZeroWhenEmpty(property) {
        return property || i18n.translate(this.CustomerLocaleKeys.PRODUCTS_PAGE.ZERO);
    }

    calculateTimeRemaining(expirationDate) {
        if (expirationDate) {
            this.isExpired = moment(expirationDate).isBefore(moment());
            const difference = moment(expirationDate).diff(moment());

            const duration = difference > 0 ? moment.duration(difference) : moment.duration(moment());

            return i18n.translate(this.CustomerLocaleKeys.PRODUCTS_PAGE.TIME_REMAINING_DISPLAY, {
                Day: duration.days(),
                Hour: duration.hours(),
                Minute: duration.minutes()
            });
        } else {
            return null;
        }
    }
}

export default {
    template: require('./grant.access.rights.popup.html'),
    controller: GrantAccessRightsController,
    controllerAs: 'GrantAccessRights',
    bindings: {
        policy: '<',
        policyCollection: '<',
        onClose: '&',
        onSuccess: '&'
    }
};
