import pathOr from 'ramda/src/pathOr';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {
    CanUserPerformFinanceAdjustmentSelector,
    CurrentCustomerIdSelector
} from '../../../../../../reducers/selectors/customer.selectors';
import {CanReturnDeviceSelector} from '../../../../../../reducers/selectors/apply.credit.modal.selectors';
import {
    DeviceFinancingNameSelector,
    IsFetchingDeviceReturnDetailsSelector,
    SubscriberDeviceReturnDetailsSelector
} from '../../../../../../reducers/selectors/customer.devices.selectors';
import {
    createDeviceAdjustment,
    retrieveDeviceRefundAmount
} from '../../../../../../reducers/actions/customer.devices.actions';
import {DEPOSIT_ADJUSTMENT} from '../../../../../adjustment/adjustment.modal.constants';
import {DOWN_PAYMENT_TYPE} from '../../../../../../customercare.constants';
import {MAKE_PAYMENT_ROUTE} from '../../../../makePayment/make.payment.constants';
import {ONE_TIME_MAKE_PAYMENT} from '../../../../../../security.attributes';
import {INVENTORY_DETAILS_STATE_REDIRECT} from '../../../../../../reactRoutes';
class DeviceFinanceDetailsController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            DEPOSIT_ADJUSTMENT,
            DOWN_PAYMENT_TYPE,
            thumbnailHeight: 95,
            thumbnailWidth: 65,
            uiNotificationService,
            onCloseAdjustmentModal: this.onCloseAdjustmentModal.bind(this),
            onSubmitAdjustmentModal: this.onSubmitAdjustmentModal.bind(this),
            onCloseReturnDeviceModal: this.onCloseReturnDeviceModal.bind(this),
            onSubmitReturnDeviceModal: this.onSubmitReturnDeviceModal.bind(this),
        });
    }

    $onInit() {
        const controllerActions = {
            createDeviceAdjustment,
            retrieveDeviceRefundAmount,
            stateGo
        };

        const mapStateToProps = (store) => {
            return {
                adjustmentReasonsLoaded: MetadataCodeLoadedSelector(CODES.BalanceAdjustmentReason, store),
                adjustmentReasonOptions: MetadataOptionsForCodeValuesSelector(CODES.BalanceAdjustmentReason, store),
                canReturnDevice: CanReturnDeviceSelector(store),
                canUserPerformFinanceAdjustment: CanUserPerformFinanceAdjustmentSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                deviceFinancingName: DeviceFinancingNameSelector(store),
                deviceReturnDetails: SubscriberDeviceReturnDetailsSelector(store),
                isFetchingDeviceReturnDetails: IsFetchingDeviceReturnDetailsSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.adjustmentModalConfig = {
            onRegisterApi: ({api}) => {
                this.adjustmentModalApi = api;
            }
        };

        this.returnDeviceModalConfig = {
            onRegisterApi: ({api}) => {
                this.returnDeviceModalConfigApi = api;
            }
        };

        if (this.deviceFinanceDetails) {
            this.moreMenuItems = this.getMoreMenuItems();
            this.deviceAttributes = this.getAttributes();
        }
        this.discountToolTip = require('../../../../../shared/tooltipTemplates/discount.tooltip.html');
    }

    getAttributes() {
        const inventoryIdentifierValue = pathOr(null, ['SubscriberInventory', 'InventoryIdentifierValue'], this.deviceFinanceDetails);

        return pathOr([], ['SubscriberInventory', 'Attributes'], this.deviceFinanceDetails).reduce((attrs, attribute) => {
            attrs.push(attribute.Value);
            return attrs;
        }, inventoryIdentifierValue ? [inventoryIdentifierValue] : []);
    }

    getMoreMenuItems() {
        const moreMenuItems = [{
            id: this.CustomerLocaleKeys.VIEW_DETAILS,
            title: i18n.translate(this.CustomerLocaleKeys.VIEW_DETAILS),
            action: () => {
                this.actions.stateGo(INVENTORY_DETAILS_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    inventoryId: pathOr(null, ['SubscriberInventory', 'Id'], this.deviceFinanceDetails)
                });
            }
        }];

        if (this.deviceFinanceDetails.DeviceFinanceAccountNumber && this.state.canUserPerformFinanceAdjustment) {
            moreMenuItems.push({
                id: this.CustomerLocaleKeys.ADJUSTMENTS.APPLY_ADJUSTMENT,
                title: i18n.translate(this.CustomerLocaleKeys.ADJUSTMENTS.APPLY_ADJUSTMENT),
                action: () => {
                    this.showAdjustmentModal = true;
                    this.$timeout(() => {
                        this.adjustmentModalApi.open();
                    });
                }
            });
        }
        if (hasAccess(this.state.userSecurityAttributes, ONE_TIME_MAKE_PAYMENT)) {
            moreMenuItems.push({
                id: this.CustomerLocaleKeys.MAKE_A_PAYMENT,
                title: i18n.translate(this.CustomerLocaleKeys.MAKE_A_PAYMENT),
                action: () => {
                    this.actions.stateGo(MAKE_PAYMENT_ROUTE, {
                        account: this.deviceFinanceDetails.DeviceFinanceAccountNumber
                    });
                }
            });
        }


        if (this.state.canReturnDevice) {
            moreMenuItems.push({
                id: this.CustomerLocaleKeys.INVENTORY.RETURN_DEVICE,
                title: i18n.translate(this.CustomerLocaleKeys.INVENTORY.RETURN_DEVICE),
                action: () => {
                    this.actions.retrieveDeviceRefundAmount(this.deviceFinanceDetails.SubscriberInventory && this.deviceFinanceDetails.SubscriberInventory.SubscriberProductId, this.state.currentCustomerId)
                        .then(() => {
                            this.showReturnDeviceModal = true;
                            this.$timeout(() => {
                                this.returnDeviceModalConfigApi.open();
                            });
                        })
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
            });
        }
        return moreMenuItems;
    }

    onMoreMenuItemSelected({item: itemProps}) {
        itemProps.action(itemProps);
    }

    onCloseAdjustmentModal() {
        this.adjustmentModalApi.close();
        this.showAdjustmentModal = false;
    }

    onSubmitAdjustmentModal() {
        this.refreshServiceDetails && this.refreshServiceDetails();
        this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
        this.onCloseAdjustmentModal();
    }

    onCloseReturnDeviceModal() {
        this.showReturnDeviceModal = false;
        this.returnDeviceModalConfigApi.close();
    }

    onSubmitReturnDeviceModal(deviceDetails) {
        this.actions.createDeviceAdjustment(deviceDetails, this.state.currentCustomerId).then(() => {
            this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.DEVICE_RETURN_SUCCESS));
            this.refreshServiceDetails && this.refreshServiceDetails();
            this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.onCloseReturnDeviceModal();
        });
    }

    getDeviceFinancingMarketingNameForHeading() {
        return i18n.translate(this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING_FOR, {
            deviceFinancingName: this.state.deviceFinancingName
        });
    }

    discountTooltipText() {
        if (this.deviceFinanceDiscounts.length > 0) {
            return `${this.deviceFinanceDiscounts.length} ${i18n.translate(this.deviceFinanceDiscounts.length === 1
                ? CustomerLocaleKeys.DISCOUNT : CustomerLocaleKeys.DISCOUNTS_LABEL)}`;
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        deviceFinanceDetails: '<',
        deviceFinanceDiscounts: '<',
        refreshServiceDetails: '&?',
        refreshConvergentBillerDetails: '&?'
    },
    template: require('./device.financing.html'),
    controller: DeviceFinanceDetailsController
};
