import CustomerLocaleKeys from '../../../../locales/keys';
import {i18n} from 'invision-core';
import {
    defaultToZero,
    yesOrNo
} from '../../../../reducers/selectors/customer.account.status.dashboard.selectors';
import {
    getContentType,
    getFriendlyDisplayName
} from '../content.activity.helpers';

class CustomerContentActivityDetailController {
    constructor() {
        this.localeKeys = CustomerLocaleKeys;

        this.terminateStreamPopup = {
            onRegisterApi: (event) => {
                this.terminateStreamPopupApi = event.api;
            }
        };

        this.removeDownloadPopup = {
            onRegisterApi: (event) => {
                this.removeDownloadPopupApi = event.api;
            }
        };
    }

    $onChanges(changObj) {
        if (changObj.contentActivity) {
            this.setupAdvertisementGrid();
            this.getContentType();
        }
    }

    closeRemoveDownloadPopup() {
        this.removeDownloadPopupApi.close();
    }

    removeDownloadPopupResult(isCancelDownload) {
        if (isCancelDownload) {
            this.cancelStream()(this.contentActivity);
        }
        this.closeRemoveDownloadPopup();
    }

    closeTerminateStreamPopup() {
        this.terminateStreamPopupApi.close();
    }

    terminateStreamPopupResult(isCancelStream) {
        if (isCancelStream) {
            this.cancelStream()(this.contentActivity);
        }
        this.closeTerminateStreamPopup();
    }

    openDialog() {
        this.contentActivity.Download ? this.removeDownloadPopupApi.open() : this.terminateStreamPopupApi.open();
    }

    // eslint-disable-next-line getter-return
    get displayTerminateText() {
        if (this.isActiveContent) {
            const key = !this.contentActivity.Download ?
                this.localeKeys.CONTENT_ACTIVITY.CANCEL_STREAM.DISPLAY_TEXT :
                this.localeKeys.CONTENT_ACTIVITY.CANCEL_DOWNLOAD.DISPLAY_TEXT;

            return i18n.translate(key);
        }
    }

    getContentType() {
        this.contentIdentifier = getContentType(this.contentActivity);
        this.friendlyName = getFriendlyDisplayName(this.contentActivity);
    }

    setupAdvertisementGrid() {
        this.advertisementGrid =  [
            {
                name: i18n.translate(this.localeKeys.ADVERTISEMENTS.ADS_INITIATED),
                value: defaultToZero(this.contentActivity.InitiatedAdsCount)
            },
            {
                name: i18n.translate(this.localeKeys.ADVERTISEMENTS.ADS_COMPLETED),
                value: defaultToZero(this.contentActivity.CompletedAdsCount)
            },
            {
                name: i18n.translate(this.localeKeys.ADVERTISEMENTS.QUIT_DURING_AD),
                value: yesOrNo(this.contentActivity.StreamClosedDuringAd)
            }
        ];
    }
}

export default {
    bindings: {
        cancelStream: '&',
        contentActivity: '<',
        isActiveContent: '<',
        isLoadingDetails: '<'
    },
    template: require('./detail.html'),
    controller: CustomerContentActivityDetailController
};
