import CustomerCareKeys from '../../../../locales/keys';
import {MetadataConstants,
    MetadataSelectors} from 'invision-core';
import {setCurrentStepIsInvalid,
    updateCustomerInfoEditCopy,
    updateCustomerInfoEditCopyPromise} from '../../../../reducers/actions/new.connect.wizard.actions';
import {
    CreatedCustomerSelector,
    CustomerInfoSelector,
    EditCustomerInfoSelector
} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {DriversLicenseStateRegionProvinceValueOptions,
    StateRegionProvinceValueOptions} from '../../newConnectWizard/new.connect.wizard.selectors';
import {IsQuotingOfferSelector} from '../../../../reducers/selectors/offering.order.selectors';
import {MarkMandatoryCreditCheckFieldsDisabledSelector} from '../../../../reducers/selectors/credit.check.selectors';
import {FormattedNewCustomerSubscriberIdentitiesSelector} from '../../../../reducers/selectors/customer.information.selectors';

function CustomerController($scope, $ngRedux) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            addressCountries: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.AddressCountry, store),
            addressStates: StateRegionProvinceValueOptions(store),
            driversLicenseStateOptions: DriversLicenseStateRegionProvinceValueOptions(store),
            createdCustomer: CreatedCustomerSelector(store),
            customerInfo: CustomerInfoSelector(store),
            editCustomerInfo: EditCustomerInfoSelector(store),
            formattedNewCustomerSubscriberIdentities: FormattedNewCustomerSubscriberIdentitiesSelector(store).asMutable(
                {
                    deep: true
                }
            ),
            isQuotingOrder: IsQuotingOfferSelector(store),
            markMandatoryCreditCheckFieldsDisabled: MarkMandatoryCreditCheckFieldsDisabledSelector(store)
        };
    };

    this.$onInit = () => {
        const controllerActions = {
            setCurrentStepIsInvalid,
            updateCustomerInfoEditCopy,
            updateCustomerInfoEditCopyPromise
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareKeys = CustomerCareKeys;
        this.actions.updateCustomerInfoEditCopy(this.state.customerInfo);

        this.actions.setCurrentStepIsInvalid(true);
        // Wait for the form to be rendered so it can tell us if it's valid
        $scope.$evalAsync(() => {
            this.actions.setCurrentStepIsInvalid(this.customerForm.$invalid);
            this.onPropertyChange();
        });

        this.handleFormChanged = (customerModel) => {
            this.actions.updateCustomerInfoEditCopyPromise(customerModel).then(() => {
                const apErrors = this.customerForm.createCustomerForm.addlApi ? this.customerForm.createCustomerForm.addlApi.$error : null;
                const hasError = apFormHasErrors(apErrors);
                this.actions.setCurrentStepIsInvalid(hasError || this.customerForm.$invalid);
            });
        };
        this.onPropertyChange = () => {
            const apErrors = this.customerForm.createCustomerForm.addlApi ? this.customerForm.createCustomerForm.addlApi.$error : null;
            const hasError = apFormHasErrors(apErrors);
            this.actions.setCurrentStepIsInvalid(hasError || this.customerForm.$invalid);
        };
    };

    function apFormHasErrors(ngFormError) {
        let hasError = false;
        if (ngFormError) {
            const errorTypes = Object.keys(ngFormError);
            hasError = errorTypes.some((errorType) => {
                return (errorType === 'required' || errorType === 'pattern' || errorType === 'undefined');
            });
        }
        return hasError;
    }

    this.$onDestroy = () => {
        disconnectRedux();
    };
}

export default {
    template: require('./customer.html'),
    controller: CustomerController,
    controllerAs: 'customerController',
    require: {
        wizardForm: '^form'
    },
    bindings: {
        formErrors: '<',
        removeMultiOfferValidations: '<?'
    }
};
