import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerLocaleKeys from '../../../../../locales/keys';

export function createTableServicesListHeaders() {
    return [{
        cellTemplate: require('./cellTemplates/service.id.template.html'),
        field: 'ServiceIdentifier.FormattedValue',
        displayName: i18n.translate(CustomerLocaleKeys.SERVICE),
        cellTooltip: true,
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        field: 'ServiceName',
        displayName: i18n.translate(CustomerLocaleKeys.FRIENDLY_NAME),
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        field: 'NetworkIdentifier',
        displayName: i18n.translate(CustomerLocaleKeys.NETWORK_IDENTIFIER),
        cellTooltip: true,
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        cellTemplate: require('./cellTemplates/status.template.html'),
        field: 'ServiceStatus',
        displayName: i18n.translate(CustomerLocaleKeys.STATUS),
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        cellTemplate: require('./cellTemplates/usage.caps.template.html'),
        field: 'UsageControls',
        displayName: i18n.translate(CustomerLocaleKeys.USAGE_CAPS.USAGE_CONTROLS_LABEL),
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        cellTemplate: require('./cellTemplates/type.of.service.template.html'),
        field: 'TypeOfService',
        displayName: i18n.translate(CustomerLocaleKeys.SERVICES_LIST.TYPE_OF_SERVICE),
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }, {
        cellTemplate: require('./cellTemplates/ellipsis_actions.html'),
        headerCellTemplate: '<div></div>',
        field: 'ServiceStatus',
        width: 75,
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }];
}

export const TYPE_OF_SERVICE = {
    BULK: 1,
    NON_BULK: 2
};

export const NON_BULK_SERVICE_CATEGORIZATION = {
    ACTIVE_NON_BULK: 2,
    ACTIVE_NON_BULK_WITH_MANAGED_FEATURES: 7,
    ACTIVE_NON_BULK_SUSPEND_DISABLED: 5,
    ACTIVE_NON_BULK_SUSPEND_DISABLED_WITH_MANAGED_FEATURES: 8,
    LOCKED_SUSPENDED_NON_BULK :4,
    LOCKED_SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES :9,
    LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED: 6,
    LOCKED_SUSPENDED_NON_BULK_RESUME_DISABLED_WITH_MANAGED_FEATURES: 10,
    REMOVED: 12,
    SUSPENDED_NON_BULK: 3,
    SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES: 11
};

export const DEFAULT_PAGE_NUMBER = 1;
