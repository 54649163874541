import localeKeys from '../../../../locales/keys';
import {
    REGEXES
} from '../../../../customercare.constants';
import {
    CurrentTransactionIdSelector,
    IsSendingEmailSelector
} from '../../../../reducers/selectors/customer.transactions.selectors';
import {
    CurrentCustomerIdSelector
} from './../../../../reducers/selectors/customer.selectors';
import {
    clearTransactionsError,
    emailTransaction
} from '../../../../reducers/actions/customer.transactions.actions';

class EmailPopupController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            emailRegEx: REGEXES.EMAIL,
            errorMessages: [],
            localeKeys,
            model: {
                email: null
            },
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isSendingEmail: IsSendingEmailSelector(store),
                transactionId: CurrentTransactionIdSelector(store)
            };
        };

        const controllerActions = {
            clearTransactionsError,
            emailTransaction
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.actions.clearTransactionsError();
        this.disconnectRedux();
    }

    onSubmit() {
        if (this.form.$valid) {
            const requestData = {
                Email: this.model.email,
                Id: this.state.transactionId
            };

            this.errorMessages = [];

            this.actions.emailTransaction(requestData, this.state.currentCustomerId)
                .then(() => {
                    this.onSuccess()();
                }, ({translatedMessage}) => {
                    this.errorMessages.push(translatedMessage);
                });
        }
    }
}

export default {
    template: require('./email.popup.html'),
    controller: EmailPopupController,
    bindings: {
        onClose: '&',
        onSuccess: '&',
    }
};
