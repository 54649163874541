import {
    AngularFormControllerErrorAdapter,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors,
    i18n
} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import moment from 'moment';

import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {HOUSEHOLD_STATE_NAME} from '../household.constants';
import {
    CurrentCustomerIdSelector,
    RouteParams
} from '../../../../reducers/selectors/customer.selectors';
import {
    IsRetrievingEwalletDataSelector,
    ShareablePaymentInstrumentsWithTypeNamesViewModel
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {
    CurrentHouseholdSelector,
    CurrentMemberIsHeadOfHouseholdSelector,
    EnableHouseholdFullAccessControlSelector,
    EnableHouseholdAccessPrivilegesSelector,
    EnableHouseholdServiceLevelManagementSelector,
    EnableHouseholdUsagePrivilegesSelector,
    HouseholdErrorSelector,
    IsUpdatingHouseholdSelector,
    RecoverablePaymentInstrumentsSelector,
    RecoverablePrivilegesSelector,
    RecoverableSelectedHoursSelector,
    SelectableRatingsByCategory,
    SelectedHouseholdMemberSelector,
    SelectedMemberPrivilegesAPIRequestSelector,
    SelectedMemberPrivilegesSelector
} from '../../../../reducers/selectors/customer.household.selectors';
import {retrieveWallet} from '../../../../reducers/actions/customer.ewallet.actions';
import {
    resetUiState,
    retrieveCustomerHouseholds,
    setAvailablePaymentInstruments,
    setMemberPrivilege,
    setSelectedHouseholdMember,
    setSelectedHours,
    setSelectedHoursForHouseholdMember,
    updateHouseholdMember
} from '../../../../reducers/actions/customer.household.actions';
import {
    getMemberBySubscriberId
} from '../household.helpers';

import localeKeys from '../../../../locales/keys';

const MIN_CURRENCY = 0.01;
const NIGHT_HOUR_START = 17;
const NIGHT_HOUR_END = 20;
const WEEKEND_HOUR_START = 8;
const WEEKEND_HOUR_END = 20;
class MemberPrivilegesController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            uiNotificationService,
            HOUSEHOLD_STATE_NAME,
            minCurrency: MIN_CURRENCY,
            vm: {
                categories: [],
                privileges: {},
                paymentInstruments: [],
                recoverableSelectedHours: []
            }
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentHeadOfHousehold: CurrentMemberIsHeadOfHouseholdSelector(store),
                currentHousehold: CurrentHouseholdSelector(store),
                enableHouseholdFullAccessControl: EnableHouseholdFullAccessControlSelector(store),
                enableHouseholdAccessPrivileges: EnableHouseholdAccessPrivilegesSelector(store),
                enableHouseholdServiceLevelManagement: EnableHouseholdServiceLevelManagementSelector(store),
                enableHouseholdUsagePrivileges: EnableHouseholdUsagePrivilegesSelector(store),
                hasLoadedHouseholdPrivileges: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.HouseholdPrivileges, store),
                hasLoadedRatingCategories: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RatingCategory, store),
                hasLoadedRatings: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.Rating, store),
                hasLoadedTimezones: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TimeZone, store),
                householdError: HouseholdErrorSelector(store),
                isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                isUpdatingHouseholdData: IsUpdatingHouseholdSelector(store),
                language: SessionSelectors.LanguageSelector(store),
                paymentInstruments: ShareablePaymentInstrumentsWithTypeNamesViewModel(store),
                privilegeRequest: SelectedMemberPrivilegesAPIRequestSelector(store),
                privileges: SelectedMemberPrivilegesSelector(store),
                ratingsOptions: SelectableRatingsByCategory(store),
                recoverablePaymentInstruments: RecoverablePaymentInstrumentsSelector(store),
                recoverablePrivileges: RecoverablePrivilegesSelector(store),
                recoverableSelectedHours: RecoverableSelectedHoursSelector(store),
                routeParams: RouteParams(store),
                selectableRatingsByCategory: SelectableRatingsByCategory(store),
                selectedHouseholdMember: SelectedHouseholdMemberSelector(store),
                timezoneOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.TimeZone, store)
            };
        };

        const controllerActions = {
            fetchPrivileges: () => {
                return MetadataActions.codes.fetchCodeTypes(MetadataConstants.codes.HouseholdPrivileges);
            },
            fetchRatings: () => {
                return MetadataActions.codes.fetchCodeTypes(MetadataConstants.codes.Rating);
            },
            fetchRatingsCategory: () => {
                return MetadataActions.codes.fetchCodeTypes(MetadataConstants.codes.RatingCategory);
            },
            fetchTimezones: () => {
                return MetadataActions.codes.fetchCodeTypes(MetadataConstants.codes.TimeZone);
            },
            resetUiState,
            retrieveWallet,
            retrieveCustomerHouseholds,
            setAvailablePaymentInstruments,
            setMemberPrivilege,
            setSelectedHours,
            setSelectedHouseholdMember,
            setSelectedHoursForHouseholdMember,
            stateGo,
            updateHouseholdMember
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.currentDateTime = new Date();
        this.LocaleKeys = localeKeys;
        this.stateOrName = 'index.customercare.customer.memberprivileges';
        this.formErrors = [];
        this.formFieldLocaleKeyMapping = {
            accumulateDate: this.LocaleKeys.HOUSEHOLD.PRIVILEGES.ACCUMULATE_DATE,
            spendingLimit: this.LocaleKeys.HOUSEHOLD.PRIVILEGES.SPENDING_LIMIT,
            daysForLimit: this.LocaleKeys.HOUSEHOLD.PRIVILEGES.DAYS_FOR_SPENDING_LIMIT,
            selectedPaymentInstrument: this.LocaleKeys.HOUSEHOLD.PRIVILEGES.SHARE_PAYMENT_METHODS
        };

        this.retrieveData();

        this.dayHeaders = Array.from(new Array(7), (i, index) => {
            return moment().weekday(index).format('dd');
        });
    }

    $onDestroy() {
        this.actions.resetUiState();
        this.disconnect();
    }

    toggleEnforcePaymentPrivileges() {
        this.setMemberPrivilege({
            EnforcePaymentPrivileges: this.vm.privileges.EnforcePaymentPrivileges
        });

        if (!this.vm.privileges.EnforcePaymentPrivileges) {
            this.setMemberPrivilege({
                CreatePaymentInstrumentEnabled: false,
                LimitSpending: false
            });
            this.vm.paymentInstruments.forEach((p) => {
                p.selected = false;
            });
            this.updateSelectedPaymentInstruments(this.vm.paymentInstruments);
        }
    }

    selectTimezone() {
        this.setMemberPrivilege({
            Timezone: this.vm.privileges.Timezone
        });
    }

    toggleEnforceAccessPrivileges() {
        this.setMemberPrivilege({
            EnforceAccessPrivileges: this.vm.privileges.EnforceAccessPrivileges
        });
    }

    toggleEnforceUsagePrivileges() {
        this.setMemberPrivilege({
            EnforceUsagePrivileges: this.vm.privileges.EnforceUsagePrivileges
        });
    }

    toggleCreatePaymentMethod() {
        this.setMemberPrivilege({
            CreatePaymentInstrumentEnabled: this.vm.privileges.CreatePaymentInstrumentEnabled
        });

        this.validateForm();
    }

    toggleLimitSpending() {
        this.setMemberPrivilege({
            LimitSpending: this.vm.privileges.LimitSpending
        });
        this.validateForm();
    }

    toggleFullAccessPrivileges() {
        this.setMemberPrivilege({
            MemberManagementEnabled: this.vm.privileges.MemberManagementEnabled
        });
    }

    toggleManageServicePrivileges() {
        this.setMemberPrivilege({
            ManageServicesEnabled: this.vm.privileges.ManageServicesEnabled
        });
    }

    updateSpendingLimit() {
        this.setMemberPrivilege({
            SpendingLimitAmount: this.vm.privileges.SpendingLimitAmount
        });

        this.validateForm();
    }

    updateSpendingLimitPeriodDays() {
        this.setMemberPrivilege({
            SpendingLimitPeriodDays: this.vm.privileges.SpendingLimitPeriodDays
        });

        this.validateForm();
    }

    updateNextSpendingDeposit() {
        this.setMemberPrivilege({
            NextSpendingDeposit: this.vm.privileges.NextSpendingDeposit
        });

        this.validateForm();
    }

    setMemberPrivilege(payload=this.vm.privileges) {
        return this.actions.setMemberPrivilege(payload)
            .then(() => {
                this.vm.privileges = this.state.recoverablePrivileges.asMutable({
                    deep: true
                });
            });
    }

    setAvailablePaymentInstruments(toUpdate) {
        this.actions.setAvailablePaymentInstruments(toUpdate)
            .then(() => {
                this.vm.paymentInstruments = this.state.recoverablePaymentInstruments.asMutable({
                    deep: true
                });
            });
    }

    selectRating() {
        const selectedRatings = this.vm.categories.filter(({selected}) => {
            return selected;
        });

        this.setMemberPrivilege({
            RatingPrivileges: selectedRatings.reduce((selectedItems, category) => {
                return category.selected ? selectedItems.concat(category.selected) : selectedItems;
            }, [])
        });
    }

    buildSelectedMemberDto() {
        return {
            Privileges: this.state.privilegeRequest,
            SubscriberId: parseInt(this.state.routeParams.subscriberId)
        };
    }


    retrieveData() {
        const promises = [];

        if (!this.state.hasLoadedRatings) {
            promises.push(this.actions.fetchRatings());
        }

        if (!this.state.hasLoadedRatingCategories) {
            promises.push(this.actions.fetchRatingsCategory());
        }

        if (!this.state.hasLoadedTimezones) {
            promises.push(this.actions.fetchTimezones());
        }

        if (!this.state.hasLoadedHouseholdPrivileges) {
            this.actions.fetchPrivileges();
        }

        if (this.state.currentHousehold) {
            promises.push(this.getPaymentInstruments());
        } else {
            promises.push(this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false)
                .then(() => {
                    this.getPaymentInstruments();

                    if (!this.state.currentHeadOfHousehold) {
                        this.actions.updateHouseholdMember(this.state.routeParams.customerId, this.state.currentHousehold.Id, this.buildSelectedMemberDto());
                    }
                }));
        }

        return Promise.all(promises).then(() => {
            this.selectMember();
            this.setMemberPrivilege(this.state.privileges);
            this.vm.categories = this.state.ratingsOptions;
            this.vm.selectedHours = this.state.recoverableSelectedHours.asMutable({
                deep: true
            });
            this.checkWeekends();
            this.checkNights();
        });
    }

    getPaymentInstruments() {
        return this.actions.retrieveWallet({
            customerId: this.state.currentHousehold.HeadOfHouseholdSubscriberId
        })
            .then(() => {
                this.selectPaymentInstruments();
            });
    }

    selectMember() {
        const memberId = parseInt(this.state.routeParams.subscriberId, 10);
        const member = getMemberBySubscriberId(this.state.currentHousehold.Members, memberId);
        this.actions.setSelectedHouseholdMember(member.SubscriberId);
        this.actions.setSelectedHoursForHouseholdMember(member);
    }

    updateSelectedPaymentInstruments(toUpdate=this.vm.paymentInstruments) {
        this.setAvailablePaymentInstruments(toUpdate);
        this.setMemberPrivilege({
            PaymentInstrumentPrivileges: toUpdate
                .filter(({selected}) => {
                    return selected;
                })
                .map(({Id}) => {
                    return Id;
                })
        });
    }

    selectPaymentInstruments() {
        const toUpdate = this.state.paymentInstruments
            .map((paymentInstrument) => {
                return Object.assign({
                    selected: (this.state.privileges.PaymentInstrumentPrivileges || []).includes(paymentInstrument.Id)
                }, paymentInstrument);
            });
        this.updateSelectedPaymentInstruments(toUpdate);
    }

    updateUsagePrivileges(toUpdate=this.vm.selectedHours) {
        this.checkNights();
        this.checkWeekends();

        this.actions.setSelectedHours(toUpdate, this.state.selectedHouseholdMemberId)
            .then(() => {
                this.vm.selectedHours = this.state.recoverableSelectedHours.asMutable({
                    deep: true
                });
            });
    }

    checkNights() {
        let allSelected = true;

        this.vm.selectedHours.forEach((hour) => {
            if (this.isNightHour(hour)) {
                hour.days.forEach((day) => {
                    if (!day.selected) {
                        this.vm.isNights = false;
                        allSelected = false;
                    }
                });
            }
        });

        this.vm.isNights = allSelected;
    }

    checkWeekends() {
        let allSelected = true;

        this.vm.selectedHours.forEach((hour) => {
            if (this.isWeekendHour(hour)) {
                hour.days.forEach((day) => {
                    if (this.isWeekend(hour, day) && !day.selected) {
                        this.vm.isWeekends = false;
                        allSelected = false;
                    }
                });
            }
        });

        this.vm.isWeekends = allSelected;
    }

    selectNights() {
        this.vm.selectedHours.forEach((hour) => {
            if (this.isNightHour(hour)) {
                hour.days.forEach((day) => {
                    if (this.vm.isWeekends && this.isWeekend(hour, day)) {
                        day.selected = this.vm.isWeekends;
                    } else {
                        day.selected = this.vm.isNights;
                    }
                });
            }
        });

        this.updateUsagePrivileges();
    }

    selectWeekends() {
        this.vm.selectedHours.forEach((hour) => {
            if (this.isWeekendHour(hour)) {
                hour.days.forEach((day) => {
                    if (this.vm.isNights && this.isNightHour(hour)) {
                        day.selected = this.vm.isNights;
                    } else if (this.isWeekend(hour, day)) {
                        day.selected = this.vm.isWeekends;
                    }
                });
            }
        });

        this.updateUsagePrivileges();
    }

    isWeekendHour(hour) {
        return hour.hour >= WEEKEND_HOUR_START && hour.hour <= WEEKEND_HOUR_END;
    }

    isWeekend(hour, day) {
        // weekends are 8-9 Saturday, Sunday and 5-9pm Friday with this logic
        // a refactor may be needed if we support different 'weekends'
        return (day.day === 5 && this.isNightHour(hour)) || day.day === 0 || day.day === 6;
    }

    isNightHour(hour) {
        return hour.hour >= NIGHT_HOUR_START && hour.hour <= NIGHT_HOUR_END;
    }

    savePrivileges() {
        if (this.formApi.$valid) {
            this.actions.updateHouseholdMember(this.state.currentHousehold.HeadOfHouseholdSubscriberId, this.state.currentHousehold.Id, this.buildSelectedMemberDto())
                .then(({UpdatedHouseholdMembers}) => {
                    this.formApi.$setPristine();
                    this.actions.stateGo(HOUSEHOLD_STATE_NAME);
                    this.uiNotificationService.success(i18n.translate(this.LocaleKeys.HOUSEHOLD.MEMBER_UPDATE_SUCCESS), null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });

                    this.setMemberPrivilege(UpdatedHouseholdMembers[0].Privileges);
                })
                .catch(() => {
                    this.uiNotificationService.error(this.state.householdError.message, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                });
        } else {
            this.validateForm();
        }
    }

    validateForm() {
        this.$timeout(() => {
            this.formErrors = AngularFormControllerErrorAdapter(this.formApi, this.formFieldLocaleKeyMapping);

            this.formErrorMessages = this.formErrors.filter((error) => {
                return error;
            });

            if (this.vm.privileges.LimitSpending) {
                if (this.formApi.accumulateDate.$error['minDate']) {
                    this.formErrorMessages.push(i18n.translate(this.LocaleKeys.HOUSEHOLD.ERRORS.ACCUMULATE_MIN_DATE));
                } else if (this.formApi.accumulateDate.$error['invalidDate']) {
                    this.formErrorMessages.push(i18n.translate(this.LocaleKeys.HOUSEHOLD.ERRORS.ACCUMULATE_DATE_INVALID));
                }
            }
        });

        if (this.vm.privileges.SpendingLimitAmount === 'null') {
            this.setMemberPrivileges({
                SpendingLimitAmount: undefined
            });
        }

        this.setMemberPrivilege();
    }

    isLoading() {
        return this.state.isFetchingEwalletData || this.state.isUpdatingHouseholdData;
    }

    showFormErrors(fieldName) {
        return this.formApi[fieldName].$invalid &&
            (this.formApi.$submitted || this.formApi[fieldName].$touched);
    }

    showThreeDateFormErrors() {
        return this.formApi.accumulateDate.$error['invalidDate']
        || this.formApi.accumulateDate.$error['required']
        || this.formApi.accumulateDate.$error['minDate'];
    }

    clearForm() {
        this.formApi.$setPristine();
        this.actions.stateGo(this.HOUSEHOLD_STATE_NAME);
    }

    areInstrumentsRequired() {
        const limitSpending = !!this.vm.privileges.LimitSpending;
        return limitSpending && !this.vm.paymentInstruments.find((instrument) => {
            return instrument.selected;
        });
    }

    getHourOfDay(hour) {
        return moment().hour(hour).minute(0).format('hh:mm a');
    }
}

export default {
    template: require('./member.privileges.template.html'),
    bindings: {},
    controllerAs: 'MemberPrivilegesController',
    controller: MemberPrivilegesController
};
