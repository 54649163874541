// Setting minimum to 0 because minimum validation is handled through the error selector
// cardinality validation isn't at a single quantity decision level but rather as a collection
// hence we don't need to set minimum on the stepper
export const MINIMUM_DECISIONS = 0;

export const DecisionTypes = {
    GROUP: 1,
    QUANTITY: 2,
    SERVICE_ATTRIBUTE: 3,
    PHYSICAL_INVENTORY: 4,
    STANDALONE: 5
};

// TODO: make sure this matches what we actually expect from the API
export const ScheduleTypes = {
    NONE:1,
    CUSTOM:2
};

export const QuantityPricingTypeCodes = {
    NONE: 1,
    TIERED: 2,
    TAPERED: 3
};

export const EditDecisionOptionQualificationTypes = {
    NONE: 0,
    UPGRADE: 1,
    DOWNGRADE: 2,
    SIDEGRADE: 3
};

export const OfferTypes = {
    DEVICE: 1,
    STANDARD: 0
};

export const BRI_STATUS = {
    ACTIVE: 1,
    INACTIVE: 2,
    PAID: 3
};

export const DISALLOW_EDIT_REASONS = {
    SUBSCRIBER_PRODUCT_SUSPENDED: 1,
    TRANSFER_SUBSCRIBER_PRODUCT_SUSPENDED: 2,
    SUBSCRIBER_PRODUCT_PENDING_OFFERING_SEPARATION: 3,
    SUBSCRIBER_PRODUCT_PENDING_FUTURE_DATED_ORDER: 5
};

export const DEVICE_SERVICE_FEATURE_ATTRIBUTE_ID = 999999;