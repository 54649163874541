import thunkMiddleware from 'redux-thunk';
import {combineReducers} from 'redux';

import {
    getLogger
} from 'invision-core/src/components/helpers/redux.logger.helper';
import reducers from './reducers/reducers';

export const initializeRedux = ($ngReduxProvider) => {
    //LOCALE_HASH, REDUX_LOGGER, REDUX_DEV_TOOL and REDUX_DEV_TOOL_ALLOWED_HOSTS are defined in webpack.config.js
    const middleware = [thunkMiddleware, 'ngUiRouterMiddleware'];
    if (['normal', 'clean'].indexOf(REDUX_LOGGER) >= 0) {
        middleware.push(getLogger(REDUX_LOGGER));
    }

    const enhancers = [];
    // Attention developers, if you would like to take advantage of Redux Devtools to speed up your development,
    // install this Chrome extension at:
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
    //
    // To learn more about Redux Devtools and it may benefit your development, check out:
    // https://github.com/gaearon/redux-devtools
    if (REDUX_DEV_TOOL && window.__REDUX_DEVTOOLS_EXTENSION__) {
        const devHosts = REDUX_DEV_TOOL_ALLOWED_HOSTS;
        const isDev = devHosts.some((host) => {
            return window.location.hostname.indexOf(host) >= 0;
        });

        if (isDev) {
            enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
        }
    }

    $ngReduxProvider.createStoreWith(combineReducers(reducers), middleware, enhancers);
};