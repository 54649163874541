import {CustomerCareUsageDetailsWindowTitleSelector} from '../../../../reducers/selectors/customer.selectors';
import {SERVICES_USAGE_STATE_NAME} from './../services.and.usage.config';

export const SERVICE_USAGE_DETAILS_STATE_NAME = `${SERVICES_USAGE_STATE_NAME}.usageDetails`;

export default function Routing($stateProvider) {
    $stateProvider.state(SERVICE_USAGE_DETAILS_STATE_NAME, {
        url: '/usagedetails/list?serviceIdentifierName&serviceAttributeId&serviceIdentifierValue&serviceIdentifierFriendlyName&entitlementIndex&entitlementId&isShared&groupCode&balanceIterationId',
        template: '<usage-details from-state-object="$resolve.fromStateObject"></usage-details>',
        params: {
            balanceIterationId: null,
            entitlementId: null,
            entitlementIndex: null,
            fromStateObject: null,
            groupCode: null,
            isShared: null,
            serviceAttributeId: null,
            serviceIdentifierFriendlyName: null,
            serviceIdentifierName: null,
            serviceIdentifierValue: null,
        },
        resolve: {
            fromStateObject: ($stateParams) => {
                return $stateParams.fromStateObject;
            }
        },
        data: {
            windowTitle: CustomerCareUsageDetailsWindowTitleSelector
        }
    });
}
