import CustomerCareKeys from '../../../../../locales/keys';

class CustomerAdditionalPropertiesController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys
        });
    }
}

export default {
    template: require('./customer.additional.properties.html'),
    controller: CustomerAdditionalPropertiesController,
    bindings: {
        additionalProperties: '<'
    }
};
