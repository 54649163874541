import CustomerCareKeys from '../../../../../locales/keys';
import {CUSTOMER_CATEGORY} from '../../../../createCustomerPopup/create.customer.popup.constants';

class CustomerInfoController {

    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            requirements: {}
        });
    }

    $onInit() {
        this.requirements = this.viewModel.categoryCode === CUSTOMER_CATEGORY.RESIDENTIAL ? this.residentialRequirements : this.commercialRequirements;
    }
}

export default {
    template: require('./customer.info.html'),
    bindings: {
        isDbss: '<',
        commercialRequirements: '<',
        residentialRequirements: '<',
        viewModel: '<'
    },
    controller: CustomerInfoController
};
