import CustomerCareLocaleKeys from './../../../../../locales/keys';

const EXTENSION_STATUS_TYPE = {
    ACTIVE: 1,
    UPCOMING: 2
};

class SubscriptionItemController {
    $onInit() {
        this.localeKeys = CustomerCareLocaleKeys;
        this.extensionStatusType = EXTENSION_STATUS_TYPE;
    }
}

export default {
    controller: SubscriptionItemController,
    template: require('./subscription.extension.html'),
    bindings: {
        item: '<',
        subscription: '<'
    }
};
