import {
    CoreLocaleKeys
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {retrieveOfferingContextQuickView} from '../../../../../reducers/actions/offering.order.actions';
import {
    IsRetrievingOfferingContextQuickView,
    OfferingQuickViewSelector,
    QuickViewSummaryTabViewModel,
    RetrieveOfferingContextQuickViewErrorSelector
} from '../../../../../reducers/selectors/offering.order.selectors';
const mapStateToTarget = (store) => {
    return {
        isRetrievingOfferingContextQuickView: IsRetrievingOfferingContextQuickView(store),
        offeringContext: OfferingQuickViewSelector(store),
        retrieveOfferingContextQuickViewError: RetrieveOfferingContextQuickViewErrorSelector(store),
        summaryTabViewModel: QuickViewSummaryTabViewModel(store)
    };
};

const controllerActions = {
    retrieveOfferingContextQuickView
};

const TRUNCATION_PREFIX = '...';
const DESCRIPTION_LENGTH_LIMIT = 175;

class QuickViewPopupController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.disconnectRedux = null;
        this.truncationPrefix = TRUNCATION_PREFIX;
    }
    $onInit() {
        this.showDescriptionOverflow = false;
        this.descriptionLengthLimit = DESCRIPTION_LENGTH_LIMIT;
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.coreLocaleKeys = CoreLocaleKeys;
        this.handleClose = () => {
            this.onClose()();
        };
        this.onSelect = () => {
            this.onSelectOffer()(this.offer.Id);
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.$onDestroy = () => {
            this.disconnectRedux();
        };
        this.actions.retrieveOfferingContextQuickView(this.offer.Id);
        this.toggleDescriptionOverflow = () => {
            this.showDescriptionOverflow = !this.showDescriptionOverflow;
            this._updateDescriptionLengthLimit();
        };
        this._updateDescriptionLengthLimit = () => {
            this.descriptionLengthLimit = this.showDescriptionOverflow ? this.offer.ShortDescription.length : DESCRIPTION_LENGTH_LIMIT;
        };
    }
}

export default {
    template: require('./quickView.popup.html'),
    controller: QuickViewPopupController,
    controllerAs: 'quickViewPopupController',
    bindings: {
        offer: '<',
        onClose: '&',
        onSelectOffer: '&'
    }
};
