import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import Configurable from 'invision-core/src/components/configurable/configurable';
import {UserSecurityContextSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerLocaleKeys from '../../../../../locales/keys';
import {stateGo} from 'redux-ui-router';

import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    CustomerAccountStatusViewHasTiles,
    CustomerAccountStatusViewIsExpandedSelector,
    CustomerAccountStatusViewModelSelector,
    CustomerAccountStatusViewNameSelector
} from '../../../../../reducers/selectors/customer.account.status.dashboard.selectors';

import {updateOrderStatusFilter} from '../../../../../reducers/actions/order.history.filter.actions';
import {setCaseStatusFilter} from '../../../../../reducers/actions/customer.cases.actions';
import {setTransactionResultFilter} from '../../../../../reducers/actions/customer.transactions.actions';

import {STATE_OR_NAME as ORDER_HISTORY_STATE} from '../../../orderHistory/order.history.config';
import {
    CASES_LIST_STATE_REDIRECT,
    E_WALLET_STATE_REDIRECT
} from '../../../../../reactRoutes';
import {STATE_OR_NAME as TRANSACTIONS_STATE} from '../../../transactions/transactions.config';
import {customerResetRevokedPassword} from '../../../../../reducers/actions/customer.actions';
import {retrieveCustomerAccountStatus} from '../../../../../reducers/actions/customer.account.status.actions';
import {retrieveCustomTileValue} from '../../../../../reducers/actions/custom.tiles.actions';

class AccountStatusController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                caseStatus: MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.CaseStatus, store),
                caseStatusLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CaseStatus, store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerAccountStatus: CustomerAccountStatusViewModelSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentUserContext: UserSecurityContextSelector(store),
                hasTilesConfigured: CustomerAccountStatusViewHasTiles(store),
                isExpanded: CustomerAccountStatusViewIsExpandedSelector(store),
                name: CustomerAccountStatusViewNameSelector(store),
                orderStatus: MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.OrderStatus, store),
                orderStatusLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.OrderStatus, store),
                transactionResults: MetadataSelectors.codes.MetadataCodeSelector(MetadataConstants.codes.TransactionResult, store),
                transactionResultLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TransactionResult, store)
            };
        };

        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            stateGo,
            setCaseStatusFilter,
            setTransactionResultFilter,
            updateStatusFilter: updateOrderStatusFilter,
            resetRevokedPassword : customerResetRevokedPassword,
            retrieveCustomerAccountStatus,
            retrieveCustomTileValue
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.orderStatusLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.OrderStatus);
        }

        if (!this.state.caseStatusLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.CaseStatus);
        }

        if (!this.state.transactionResultLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.TransactionResult);
        }

        this.actions.retrieveCustomerAccountStatus(this.state.currentCustomerId);

        if (this.state.currentCustomerAccountStatus) {
            this.state.currentCustomerAccountStatus.forEach((tile) => {
                if (tile.isCustom) {
                    this.actions.retrieveCustomTileValue(tile, this.state.currentCustomer, this.state.currentUserContext);
                }
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleExpansion() {
        this.state.isExpanded = !this.state.isExpanded;
    }

    onActionClick(status) {
        const OPEN_CASE_STATUS= '1';
        const NEW_CASE_STATUS= '2';
        const OPEN_ORDER_STATUS = '1';
        const PENDING_ORDER_STATUS = '0';

        switch (status.id) {
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.DEVICE_SESSION_PIN_BOOL:
                this.customerCtrl.openResetDevicePinDialog();
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.LOGIN_REVOKED_BOOL:
                this.customerCtrl.openResetRevokedPasswordDialog();
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.OPEN_ORDERS_COUNT:
                this.actions.updateStatusFilter(
                    this.setupFilter(this.state.orderStatus.items, OPEN_ORDER_STATUS, PENDING_ORDER_STATUS)
                );
                this.actions.stateGo(ORDER_HISTORY_STATE);
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.OPEN_CASES_COUNT:
                this.actions.setCaseStatusFilter(
                    this.setupFilter(this.state.caseStatus.items, OPEN_CASE_STATUS, NEW_CASE_STATUS)
                );
                this.actions.stateGo(CASES_LIST_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId
                });
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.EXPIRED_PI_COUNT:
                this.actions.stateGo(E_WALLET_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId
                });
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.PAYMENT_FAILURES_COUNT:
                this.actions.setTransactionResultFilter(this.getTransactionFilter());
                this.actions.stateGo(TRANSACTIONS_STATE);
                break;
            case Configurable.CustomerCare.CUSTOMER_ACCOUNT_STATUS_CONSTANTS_IDS.DISCRETIONARY_DISCOUNTS_COUNT:
                this.customerCtrl.openDiscretionaryDiscountsPopup();
                break;
            default:
                break;
        }
    }

    setupFilter(items, firstValue, secondValue) {
        const filters = items.filter((item) => {
            return item.Value === firstValue || item.Value === secondValue;
        });

        filters.forEach((status) => {
            status.id = status.Value;
        });

        return filters;
    }

    getTransactionFilter() {
        const SUCCESSFUL_TRANSACTION = '0';

        const filters = this.state.transactionResults.items.filter(({Value}) => {
            return Value !== SUCCESSFUL_TRANSACTION;
        });

        return filters;
    }
}

export default {
    template: require('./account.status.html'),
    require: {
        customerCtrl: '^customer'
    },
    controller: AccountStatusController,
    controllerAs: 'AccountStatus'
};
