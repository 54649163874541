import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import localeKeys from '../../../../locales/keys';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {OFFERING_OPTION_STATUSES} from '../../constants/offering.option.status.constants';
import {
    OFFERING_METADATA_OFFER_TYPE,
    PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE
} from '../../../../customercare.constants';
import {OfferingOptionBulkTypes} from '../../../../reducers/constants/convergent.biller.constants';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {DBSS_PRODUCT_DETAILS_PAGE_REDIRECT} from '../../../../reactRoutes';

class SubscriberOfferDetails {
    constructor($ngRedux, $filter, uiNotificationService) {
        Object.assign(this, {
            $ngRedux: $ngRedux,
            $filter,
            filterPricingPlansFromMetaData: this.filterPricingPlansFromMetaData.bind(this),
            getSuspensionDateToolTip: this.getSuspensionDateToolTip.bind(this),
            isCancelEarlyRenewalPaymentModalOpen: false,
            localeKeys,
            OFFER_STATUS_INDICATOR_STATUS,
            OFFERING_METADATA_OFFER_TYPE,
            OFFERING_OPTION_STATUSES,
            onCancelEarlyRenewalPaymentModalClose: this.onCancelEarlyRenewalPaymentModalClose.bind(this),
            onCancelEarlyRenewalPaymentModalSubmit: this.onCancelEarlyRenewalPaymentModalSubmit.bind(this),
            openCancelEarlyRenewalPaymentModal: this.openCancelEarlyRenewalPaymentModal.bind(this),
            openProductDetailsPage: this.openProductDetailsPage.bind(this),
            PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE,
            thumbnailWidth: 65,
            uiNotificationService
        });
    }

    $onChanges(changeObj) {
        if (changeObj.offer && changeObj.offer.currentValue) {
            if (changeObj.offer.currentValue.OfferType === OFFERING_METADATA_OFFER_TYPE.STANDARD && !this.showOfferImage) {
                this.pricingPlansInOffer = changeObj.offer.currentValue.Options.filter((option) => {
                    return this.serviceIdentifier.find((service) => {
                        return option.ServiceIdentifierValue === service;
                    });
                });
            } else {
                this.pricingPlansInOffer = changeObj.offer.currentValue.Options;
            }

            this.setIsPaymentFailedShown();
        }
    }

    $onInit() {

        const mapStateToProps = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isDbss: IsDbss(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, {
            stateGo
        })((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.discountToolTip = require('../../../../components/shared/tooltipTemplates/discount.tooltip.html');

        if (this.offerType === OFFERING_METADATA_OFFER_TYPE.STANDARD && !this.showOfferImage) {
            this.pricingPlansInOffer = this.offer.Options.filter((option) => {
                return this.serviceIdentifier.find((service) => {
                    return option.ServiceIdentifierValue === service;
                });
            });
        } else {
            this.pricingPlansInOffer = this.offer.Options;
        }
        this.setIsPaymentFailedShown();
    }

    openProductDetailsPage(subscriberProductId) {
        this.actions.stateGo(DBSS_PRODUCT_DETAILS_PAGE_REDIRECT, {
            customerId: this.state.currentCustomerId,
            subscriberProductId: subscriberProductId
        });
    }

    setIsPaymentFailedShown() {
        this.isPaymentFailedShown = this.OFFER_STATUS_INDICATOR_STATUS.ACTIVE === this.offer.Status && this.offer.Options && this.offer.Options.some((option) => {
            return this.isOffCyclePaymentFailed(option);
        });
    }

    discountTooltipText(discounts, billerRuleConfigurationIdOfBri) {
        if (discounts.length > 0) {
            let occurrences = 0;
            discounts.forEach((discount) => {
                if (discount.billerConfigurationId === billerRuleConfigurationIdOfBri) {
                    occurrences++;
                }
            });
            return `${occurrences} ${i18n.translate(occurrences === 1 ? localeKeys.DISCOUNT : localeKeys.DISCOUNTS_LABEL)}`;
        }
    }

    isOffCyclePaymentFailed(option) {
        return option.OffCycleDetail && option.OffCycleDetail.GracePeriodExpiryDate &&
        option.OffCycleDetail.CurrentRenewalFailedAttempts;
    }

    filterPricingPlansFromMetaData(offerPricingPlanId) {
        return this.metaDataPricingPlans && this.metaDataPricingPlans[offerPricingPlanId]? this.metaDataPricingPlans[offerPricingPlanId].Name: undefined;
    }

    getItemNameBulk(bri, pricingPlanId) {
        const pricingPlanDetails = this.metaDataPricingPlans[pricingPlanId];
        const selectedBriThumbnail = (pricingPlanDetails && pricingPlanDetails.BillerRuleInstanceThumbnails) ? pricingPlanDetails.BillerRuleInstanceThumbnails.find((item) => {
            return item.BillerRuleConfigurationId === bri.BillerRuleConfigurationId;
        }) : null;
        return selectedBriThumbnail?.Name;
    }

    getBulkQty(pricingPlanId) {
        const pricingPlanDetails = this.pricingPlansInOffer?.find((item) => {
            return item.PricingPlanId === pricingPlanId;
        });
        return pricingPlanDetails?.BillerRulePrices?.[0]?.Quantity;
    }

    getBulkTooltipContent(bri) {
        if (bri.BulkType === OfferingOptionBulkTypes.BULK_FLAT) {
            return i18n.translate(localeKeys.DECISIONS.FLAT_PRICING);
        } else if (bri.BulkType === OfferingOptionBulkTypes.BULK_UNIT) {
            return i18n.translate(localeKeys.DECISIONS.UNIT_PRICING);
        }
    }

    getBulkIcon(bri) {
        if (bri.BulkType === OfferingOptionBulkTypes.BULK_FLAT) {
            return 'filled-circle-f';
        } else if (bri.BulkType === OfferingOptionBulkTypes.BULK_UNIT) {
            return 'filled-circle-u';
        }
    }

    getSuspensionDateToolTip(date) {
        return i18n.translate(localeKeys.CUSTOMER_DASHBOARD.SUSPENSION_DATE, {
            date: this.$filter('localShortDate')(date)
        });
    }

    openCancelEarlyRenewalPaymentModal(pricingPlan) {
        this.transactionId = pricingPlan.OffCycleDetail.LastPaymentTransactionId;
        this.isCancelEarlyRenewalPaymentModalOpen = true;
    }

    onCancelEarlyRenewalPaymentModalClose() {
        this.isCancelEarlyRenewalPaymentModalOpen = false;
    }

    onCancelEarlyRenewalPaymentModalSubmit(isSuccess, message) {
        if (isSuccess) {
            this.isCancelEarlyRenewalPaymentModalOpen = false;
            this.refreshOfferDetails && this.refreshOfferDetails();
            this.uiNotificationService.transientSuccess(message);
        } else {
            this.uiNotificationService.transientError(message);
        }
    }

}

export default {
    bindings: {
        currency: '<',
        hasAdminAccess: '<?',
        isDashboardOfferingsWidgetUIFeatureEnabled: '<?',
        metaDataPricingPlans: '<?',
        offer: '<',
        offerType: '<',
        onMoreItemSelected: '&?',
        openLifeCycleDetailsPopup: '&?',
        navigateToPricingPlanEarlyRenewal: '&?',
        refreshOfferDetails: '&?',
        showOfferImage: '<?',
        serviceIdentifier: '<?'
    },
    template: require('./subscriber.offer.details.html'),
    controller: SubscriberOfferDetails
};
