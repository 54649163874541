import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import LocaleKeys from '../../../../locales/keys';
import {MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import moment from 'moment';
import {updateServiceLifeCycleStatus} from '../../../../reducers/actions/update.service.life.cycle.status.actions';

class UpdateServiceLifeCycleStatusController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            LocaleKeys,
            CoreLocaleKeys,
            uiNotificationService
        });
    }

    $onInit() {

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isServiceLifeCycleStatusLoaded: MetadataCodeLoadedSelector(CODES.ServiceLifeCycleStatus, store),
                serviceLifeCycleStatus: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.ServiceLifeCycleStatus, store),
            };
        };
        const controllerActions = {
            fetchCodeTypes,
            updateServiceLifeCycleStatus
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isServiceLifeCycleStatusLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceLifeCycleStatus);
        }
    }

    handleClose() {
        this.onClose()();
    }

    submitForm() {
        if (this.UpdateServiceLifeCycleStatus.$valid) {
            this.actions.updateServiceLifeCycleStatus(this.state.currentCustomerId, moment().toISOString(), this.lifecycleStatusSelected, this.reasonDescription, this.serviceIdentifier).then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.UPDATE_SERVICE_LIFE_CYCLE_STATUS.SUCCESS));
                this.handleClose();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
                this.handleClose();
            });
        }
    }

}

export default {
    template: require('./update.service.life.cycle.status.modal.html'),
    controller: UpdateServiceLifeCycleStatusController,
    bindings: {
        onClose: '&',
        onSubmit: '&',
        serviceIdentifier: '<'
    }
};
