import clone from 'ramda/src/clone';
import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../../locales/keys';

class ServiceFeaturesDialogController {
    constructor($anchorScroll, $location) {
        Object.assign(this, {
            $anchorScroll,
            $location,
            CustomerCareKeys,
            errors: [],
            MAXIMUM_PRICING_PLAN: 1,
            MINIMUM_PRICING_PLAN: 0,
            stateOfCollapsible: {},
            countOfSelectedPricingPlans: {},
            onPricingPlanSelection: this.onPricingPlanSelection.bind(this),
            productsOverMax: {},
            selectedServiceFeatures: []
        });
    }

    $onInit() {
        this.newconnect = 'newconnect';
        const featuresVisited = [];
        this.selectedServiceFeatures = [];
        this.serviceFeatures.forEach((serviceFeature) => {
            const index = featuresVisited.indexOf(serviceFeature.pricingPlanId);
            if (index > -1) {
                this.selectedServiceFeatures[index].quantity++;
            } else {
                serviceFeature.quantity = 1;
                featuresVisited.push(serviceFeature.pricingPlanId);
                this.selectedServiceFeatures.push({
                    ...serviceFeature
                });
            }
        });
        if (this.selectedServiceFeatures.length) {
            this.countOfSelectedPricingPlans = this.countSelectedServiceFeaturePricingPlan();
        }
    }

    onCollapse(product) {
        return (isCollapsed) => {
            this.stateOfCollapsible[product.id] = !isCollapsed;
            if (!isCollapsed && !product.pricingPlans.length) {
                this.onProductPricingPlansRequested()(product.id);
            }
        };
    }

    onPricingPlanSelection(product, productName) {
        return (quantity, pricingPlanId, pricingPlanName, shoppingCartItemBillerRuleDetails) => {
            const serviceFeatureToModify = this.selectedServiceFeatures.find((serviceFeature) => {
                return serviceFeature.productId === product.id &&
                    serviceFeature.pricingPlanId === pricingPlanId;
            });

            if (serviceFeatureToModify) {
                serviceFeatureToModify.quantity = quantity;
                serviceFeatureToModify.billerRuleDetails = shoppingCartItemBillerRuleDetails;
            } else {
                if (product.maximumQuantity === 1) {
                    this.selectedServiceFeatures = (this.selectedServiceFeatures || []).filter((service) => {
                        return service.productId !== product.id;
                    });
                }
                this.selectedServiceFeatures.push({
                    pricingPlanId,
                    pricingPlanName,
                    productId: product.id,
                    productName,
                    quantity,
                    billerRuleDetails: shoppingCartItemBillerRuleDetails
                });
            }

            const productFilteredServiceFeatures = this.selectedServiceFeatures.filter((serviceFeature) => {
                return serviceFeature.quantity !== 0 && product.id === serviceFeature.productId;
            });
            const totalQuantity = productFilteredServiceFeatures.reduce((totalQuantity, selectedServiceFeature) => {
                return totalQuantity + selectedServiceFeature.quantity;
            }, 0);
            if (productFilteredServiceFeatures.length > product.max ||  totalQuantity > product.maximumQuantity) {
                this.productsOverMax[product.id] = i18n.translate(
                    this.CustomerCareKeys.ATTRIBUTES.SERVICE_FEATURES.PRODUCT_OVER_MAXIMUM, {
                        productName: product.name
                    }
                );
            } else {
                delete this.productsOverMax[product.id];
            }
            this.countOfSelectedPricingPlans = this.countSelectedServiceFeaturePricingPlan();
            this.errors = Object.values(this.productsOverMax);
        };
    }

    getServiceFeatureValue(productId, pricingPlanId) {
        const selectedServiceFeature = this.selectedServiceFeatures.find((serviceFeature) => {
            return serviceFeature.productId === productId &&
                serviceFeature.pricingPlanId === pricingPlanId;
        });
        return selectedServiceFeature ? pathOr(1, ['quantity'], selectedServiceFeature) : 0;
    }

    getFilteredServiceFeatures() {
        return this.selectedServiceFeatures.filter((serviceFeature) => {
            return serviceFeature.quantity !== 0;
        });
    }

    onAdd() {
        if (!this.errors.length) {
            this.onSelection()(this.getFilteredServiceFeatures());
        } else {
            const old = this.$location.hash();
            this.$location.hash('serviceFeaturesTop');
            this.$anchorScroll();
            this.$location.hash(old);
        }
    }

    countSelectedServiceFeaturePricingPlan() {
        return this.selectedServiceFeatures
            .reduce((countPrim, selectedPricingPlan) => {
                if (countPrim[selectedPricingPlan.pricingPlanId]) {
                    countPrim[selectedPricingPlan.pricingPlanId] += countPrim[selectedPricingPlan.pricingPlanId];
                } else {
                    if (selectedPricingPlan.quantity === 0) {
                        countPrim[selectedPricingPlan.pricingPlanId] = 0;
                    } else {
                        countPrim[selectedPricingPlan.pricingPlanId] =  selectedPricingPlan.quantity || 1;
                    }
                }
                return countPrim;
            }, {});
    }
}

export default {
    template: require('./service.features.dialog.html'),
    bindings: {
        onClose: '&',
        onProductPricingPlansRequested: '&',
        onSelection: '&',
        products: '<',
        serviceFeatures: '<',
        hasOverrideAccess: '<'
    },
    controller: ServiceFeaturesDialogController
};
