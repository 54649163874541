import localeKeys from '../../../../../../locales/keys';

class TaxesTooltipController {
    constructor() {
        this.localeKeys = localeKeys;
        this.tooltipContext = {};
    }

    $onInit() {
        this.tooltipTemplate = require('./prices.tooltip.template.html');
    }

    $onChanges(changeObj) {
        if (changeObj.taxes) {
            this.tooltipContext.prices = changeObj.taxes.currentValue;
        }
        if (changeObj.currencyCode) {
            this.tooltipContext.currencyCode = changeObj.currencyCode.currentValue;
        }
        if (changeObj.brandableCurrencyName) {
            this.tooltipContext.brandableCurrencyName = changeObj.brandableCurrencyName.currentValue;
        }
    }
}

export default {
    bindings: {
        brandableCurrencyName: '<',
        currencyCode: '<',
        taxes: '<', // See CartTab in products.order.selector.helper.js
    },
    controller: TaxesTooltipController,
    template: require('./taxes.tooltip.html')
};
