import angular from 'angular';

import RoutingConfig from './ewallet.config';
import EwalletComponent from './ewallet.component';
import EwalletListComponent from './list/ewallet.list.component';
import EwalletListItemComponent from './list/ewallet.list.item.component';
import EwalletDetailsComponent from './detail/ewallet.detail.component';
import {AcpDetailComponent} from './detail/paymentInstruments/acp.detail.component';
import {DirectDebitDetailComponent} from './detail/paymentInstruments/direct.debit.detail.component';
import {BraintreeDetailComponent} from './detail/paymentInstruments/braintree.detail.component';
import {ECheckDetailComponent} from './detail/paymentInstruments/echeck.detail.component';
import {ExternalBillDetailComponent} from './detail/paymentInstruments/external.bill.detail.component';
import {RokuDetailComponent} from './detail/paymentInstruments/roku.detail.component';
import {StoredValueAccountDetailComponent} from './detail/paymentInstruments/stored.value.account.detail.component';

import BlocklistAllowlistModalComponent from './modals/blocklist.allowlist.modal.component';

export default angular.module('invision.customercare.customer.ewallet', [])
    .config(RoutingConfig)
    .component('customerEwallet', EwalletComponent)
    .component('customerEwalletList', EwalletListComponent)
    .component('customerEwalletListItem', EwalletListItemComponent)
    .component('customerEwalletDetail', EwalletDetailsComponent)
    .component('customerEWalletAcpDetail', AcpDetailComponent)
    .component('customerEWalletDirectDebitDetail', DirectDebitDetailComponent)
    .component('customerEWalletECheckDetail', ECheckDetailComponent)
    .component('customerEWalletExternalBillDetail', ExternalBillDetailComponent)
    .component('customerEWalletBraintreeDetail', BraintreeDetailComponent)
    .component('customerEWalletRokuDetail', RokuDetailComponent)
    .component('customerEWalletStoredValueAccountDetail', StoredValueAccountDetailComponent)
    .component('bwModal', BlocklistAllowlistModalComponent)
    .name;
