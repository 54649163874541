import angular from 'angular';

import CouponAssociationCardComponent from './associationCard/coupon.association.card.component';
import CustomerCouponsComponent from './customer.coupons.component';
import GrantCouponsComponent from './grantCoupons/grant.coupons.component';
import GrantCouponPopup from './grantCoupons/grantCouponPopup/grant.coupon.popup.component';
import ResendRedemptionCodePopupComponent from './resendRedemptionCodePopup/resend.redemption.code.popup.component';

import {routing as RoutingConfig} from './customer.coupons.config';

export default angular.module('invision.customercare.customer.coupons', [])
    .config(RoutingConfig)
    .component('couponAssociationCard', CouponAssociationCardComponent)
    .component('customerCoupons', CustomerCouponsComponent)
    .component('grantCoupons', GrantCouponsComponent)
    .component('grantCouponPopup', GrantCouponPopup)
    .component('resendRedemptionCodePopup', ResendRedemptionCodePopupComponent)
    .name;
