import OfferingOptionCtrl from './offering.option.controller';

export default {
    bindings: {
        currencyCode: '@',
        enableQuickEdit: '&',
        hasAdminAccess: '<',
        isOneTimeOption: '<',
        isUsageOrEntitlement: '<',
        launchQuickEdit: '&',
        launchQuickRemove: '&?',
        offerHasPendingChange: '<',
        option: '<',
        paymentInstrumentFlagged: '<'
    },
    template: require('./offering.option.html'),
    controller: OfferingOptionCtrl,
    controllerAs: 'OfferingOption'
};
