import localeKeys from '../../../../../locales/keys';
import {find, propEq} from 'ramda';

class TransactionDetailsPaypalController {
    constructor() {
        this.localeKeys = localeKeys;
    }

    get paymentTypeTranslated() {
        const paymentType = find(propEq(this.details.PaymentType.toString(), 'Value'), this.paypalPaymentTypes);

        return paymentType ? paymentType.Name : undefined;
    }
}

export default {
    template: require('./transaction.details.paypal.html'),
    bindings: {
        details: '<',
        paypalPaymentTypes: '<'
    },
    controller: TransactionDetailsPaypalController
};
