import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../../locales/keys';

export const DEFAULT_MAXIMUM_SLOTS = 5;
export const DEFAULT_PAGE_NUMBER = 1;

export const SEARCH_BY_TYPES =  {
    OFFERING_NAME: 1,
    OFFERING_INSTANCE_ID: 2
};

export const offeringSearchByFilters = (selectedType) => {
    return [{
        id: SEARCH_BY_TYPES.OFFERING_NAME,
        text: i18n.translate(LocaleKeys.OFFER_NAME),
        selected: selectedType === SEARCH_BY_TYPES.OFFERING_NAME
    }, {
        id: SEARCH_BY_TYPES.OFFERING_INSTANCE_ID,
        text: i18n.translate(LocaleKeys.OFFERINGS_PAGE.OFFERING_INSTANCE_ID),
        selected: selectedType === SEARCH_BY_TYPES.OFFERING_INSTANCE_ID
    }];
};

export const OFFERING_STATUS_TYPES = {
    ACTIVE: 1,
    REMOVED: 2,
    PENDING_ACTIVE: 3,
    PENDING_REMOVED: 4,
    SUSPENDED: 6,
    SUSPENSION_SCHEDULED: 8
};

export const offeringStatusFilters = () => {
    return [{
        id: OFFERING_STATUS_TYPES.ACTIVE,
        text: i18n.translate(LocaleKeys.ACTIVE),
        selected: true
    }, {
        id: OFFERING_STATUS_TYPES.REMOVED,
        text: i18n.translate(LocaleKeys.REMOVED)
    }, {
        id: OFFERING_STATUS_TYPES.PENDING_ACTIVE,
        text: i18n.translate(LocaleKeys.PENDING_ACTIVE)
    }, {
        id: OFFERING_STATUS_TYPES.PENDING_REMOVED,
        text: i18n.translate(LocaleKeys.PENDING_REMOVED)
    }, {
        id: OFFERING_STATUS_TYPES.SUSPENDED,
        text: i18n.translate(LocaleKeys.SUSPENDED)
    }, {
        id: OFFERING_STATUS_TYPES.SUSPENSION_SCHEDULED,
        text: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.SUSPENSION_SCHEDULED)
    }];
};