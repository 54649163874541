import {
    head,
    indexOf,
    last,
    pathEq
} from 'ramda';
import CustomerCareLocaleKeys from '../../../locales/keys';

const HOTKEY_COMBO_UP = 'up';
const HOTKEY_COMBO_DOWN = 'down';

// NOTE: Accompanying bindings should be:
//      downKeyDescriptionText: '<'
//      items: '<',
//      onItemSelected: '&',
//      selectedItem: '<',
//      upKeyDescriptionText: '<'
export class HotkeyNavListController {
    constructor(hotkeys, $popupManager) {
        Object.assign(this, {
            hotkeys,
            localeKeys: CustomerCareLocaleKeys,
            $popupManager
        });
    }

    $onInit() {
        this.hotkeys.add({
            combo: HOTKEY_COMBO_UP,
            description: this.upKeyDescriptionText,
            callback: (event) => {
                if (this.$popupManager.numberOfOpenPopups() === 0) {
                    event.preventDefault();

                    const selectedItemIndex = indexOf(this.selectedItem, this.items);
                    switch (selectedItemIndex) {
                        case -1:
                        case 0:
                            this.setSelectedItem(last(this.items));
                            break;
                        default:
                            this.setSelectedItem(this.items[selectedItemIndex - 1]);
                            break;
                    }
                }
            }
        });

        this.hotkeys.add({
            combo: HOTKEY_COMBO_DOWN,
            description: this.downKeyDescriptionText,
            callback: (event) => {
                if (this.$popupManager.numberOfOpenPopups() === 0) {
                    event.preventDefault();
                    const selectedItemIndex = indexOf(this.selectedItem, this.items);
                    switch (selectedItemIndex) {
                        case -1:
                        case this.items.length - 1:
                            this.setSelectedItem(head(this.items));
                            break;
                        default:
                            this.setSelectedItem(this.items[selectedItemIndex + 1]);
                            break;
                    }
                }
            }
        });

        this.handleItemSelected = (item) => {
            this.setSelectedItem(item);
        };
    }

    $onChanges(changesObj) {
        if (changesObj.items &&
            changesObj.items.currentValue &&
            changesObj.items.previousValue) {
            if (this.onItemAdded && changesObj.items.currentValue.length > changesObj.items.previousValue.length) {
                this.onItemAdded()();
            } else if (this.onItemRemoved && changesObj.items.currentValue.length < changesObj.items.previousValue.length) {
                this.onItemRemoved()();
            }
        }
    }

    get hasItems() {
        return this.items && this.items.length > 0;
    }

    isItemsEqual(selectedItem) {
        return selectedItem && pathEq(this.selectedItem.Id, ['Id'])(selectedItem);
    }

    $onDestroy() {
        this.hotkeys.del(HOTKEY_COMBO_UP);
        this.hotkeys.del(HOTKEY_COMBO_DOWN);
    }

    setSelectedItem(item) {
        this.onItemSelected()(item);
    }
}
