import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerLocaleKeys from '../../../../locales/keys';
import {ConvergentBillerCurrencyCodeSelector} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {NOT_SHARED} from './policy.counter.thumbnail.constants';

class PolicyCounterThumbnailController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            NOT_SHARED
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currencyCode: ConvergentBillerCurrencyCodeSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });
        this.infoTooltipTemplate = require('./templates/info.tooltip.template.html');

        this.viewPolicyDetailsColumnDefs = [{
            field: 'Status',
            displayName: i18n.translate(CustomerLocaleKeys.POLICY_COUNTERS.POLICY_STATUS),
            cellTemplate: require('./cellTemplates/status.template.html'),
            cellClass: 'u-p0',
        },
        {
            field: 'Description',
            displayName: i18n.translate(CustomerLocaleKeys.POLICY_COUNTERS.DESCRIPTION)
        },
        {
            field: 'Threshold',
            displayName: i18n.translate(CustomerLocaleKeys.POLICY_COUNTERS.ALLOCATION),
            cellTemplate: require('./cellTemplates/threshold.template.html')
        }];

        this.viewPolicyDetailsPopUp = {
            onRegisterApi: ({api}) => {
                this.viewPolicyDetailsPopUpApi = api;
            }
        };

        this.openViewPolicyDetailsPopup = (selectedPolicyCounter) => {
            this.selectedPolicyCounter = selectedPolicyCounter;
            this.viewPolicyDetailsPopUpApi.open();
        };
        this.closeViewPolicyDetailsPopup = () => {
            this.selectedPolicyCounter = {};
            this.viewPolicyDetailsPopUpApi.close();
        };
    }
}

export default {
    bindings: {
        policyCounterDetails: '<?'
    },
    controller: PolicyCounterThumbnailController,
    controllerAs: 'PolicyCounterThumbnailController',
    template: require('./policy.counter.thumbnail.template.html')
};
