import cloneDeep from 'lodash/cloneDeep';
import pathOr from 'ramda/src/pathOr';
import isNil from 'ramda/src/isNil';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {
    getCodeTablePropertyValueByName,
    getDependentAttributes,
    getIsRequired,
    getPossibleValues,
    getRegexValidation,
    showHideAttributes
} from './attribute.modal.helper';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {
    SERVICE_ATTRIBUTE_DISPLAY_TYPES,
    NOTIFICATION_TIME_LENGTH
} from '../../../../../customercare.constants';
import {
    CurrentOrderServiceIdentifiersSelector,
    IsUpdatingOrderServiceDetailsSelector,
    UpdateOrderServiceDetailsErrorSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    IsRetrievingInventory,
    RetrieveInventoryError
} from '../../../../../reducers/selectors/order.history.selectors';
import {
    retrieveOrderServiceDetails,
    searchInventoryForUpdatingAttributes,
    updateOrderServiceDetails
} from '../../../../../reducers/actions/order.actions';

class AttributeModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareLocaleKeys,
            uiNotificationService,
            SERVICE_ATTRIBUTE_DISPLAY_TYPES
        });

        this.formOptions = {
            debounce: 300
        };
        this.formFieldLocaleKeyMapping = {};
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentOrderServiceIdentifiers: CurrentOrderServiceIdentifiersSelector(store),
                isUpdatingOrderServiceDetails: IsUpdatingOrderServiceDetailsSelector(store),
                isRetrievingInventory: IsRetrievingInventory(store),
                retrieveInventoryError: RetrieveInventoryError(store),
                updateOrderServiceDetailsError: UpdateOrderServiceDetailsErrorSelector(store)
            };
        };
        const controllerActions = {
            retrieveOrderServiceDetails,
            searchInventoryForUpdatingAttributes,
            updateOrderServiceDetails
        };
        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.duplicateServiceIdentifiers = [];
        this.attributes = this.editAttributes.asMutable({
            deep: true
        }).concat(this.availableAttributes.asMutable({
            deep: true
        }));
        this.buildPossibleValues();
        this.setAttributeVisibility();
    }
    $onDestroy() {
        this.disconnect();
    }
    buildPossibleValues() {
        this.attributes.forEach((attribute) => {
            attribute.isRequired = getIsRequired(attribute, this.attributeConfiguration);
            attribute.PreviousValue = cloneDeep(attribute.Value);
            attribute.possibleValues = getPossibleValues(attribute, this.attributeConfiguration);
            attribute.regularExpression = getRegexValidation(attribute, this.attributeConfiguration, this.regexCodes);
            attribute.serviceAttributeDisplayType = getCodeTablePropertyValueByName(attribute, this.attributeConfiguration, 'service_attribute_display_type_code');
            attribute.validationError = i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
                field_name: attribute.ServiceAttributeName
            });
            this.formFieldLocaleKeyMapping[attribute.ServiceAttributeId] = attribute.validationError;
        });
    }
    close() {
        this.onClose()();
    }
    hideForm() {
        return this.attributes.every((attr) => {
            return !attr.Editable;
        });
    }
    update() {
        this.duplicateServiceIdentifiers = [];

        const orderItems = [{
            AddedServiceAttributesValues: [],
            ModifiedServiceAttributeValues: [],
            OrderItemId: this.orderItemId
        }];

        this.attributes.forEach((attribute) => {
            const duplicatedServiceIdentifier = this.state.currentOrderServiceIdentifiers.find((attr) => {
                return attribute.IsServiceIdentifier &&
                    attr.Value === attribute.Value &&
                    attr.ServiceAttributeId !== attribute.ServiceAttributeId;
            });

            if (duplicatedServiceIdentifier) {
                this.duplicateServiceIdentifiers.push(attribute.ServiceAttributeId);
            } else {
                if (attribute.Editable) {
                    const editAttribute = this.editAttributes.find((attr) => {
                        return attr.ServiceAttributeId === attribute.ServiceAttributeId;
                    });
                    if (!!editAttribute && attribute.PreviousValue !== attribute.Value) {
                        orderItems[0].ModifiedServiceAttributeValues.push(attribute);
                    } else if (!editAttribute && !isNil(attribute.Value)) {
                        orderItems[0].AddedServiceAttributesValues.push(attribute);
                    }
                }
            }
        });
        if (!this.duplicateServiceIdentifiers.length) {
            this.actions.updateOrderServiceDetails(this.orderId, orderItems)
                .then(() => {
                    this.actions.retrieveOrderServiceDetails(this.orderId, this.customerId);

                    this.uiNotificationService.success(
                        i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.SERVICE_ATTRIBUTES.UPDATE_SUCCESS), null, {
                            timeOut: NOTIFICATION_TIME_LENGTH
                        });

                    this.onClose()();
                });
        }
    }
    setAttributeVisibility() {
        const dependentAttributes = getDependentAttributes(this.attributes, this.attributeConfiguration);
        if (dependentAttributes.length) {
            showHideAttributes(dependentAttributes, this.attributes, this.attributeConfiguration);
        }
    }
    getMoreOptions(attr) {
        this.hasSearchedForInventory = true;
        this.actions.searchInventoryForUpdatingAttributes(attr.InventoryTypeId).then((response) => {
            attr.inventoryOptions = response.InventoryItems;
        })
            .catch(() => {});
    }
    setRadioValue(attr, inventoryOption) {
        attr.Value = inventoryOption.SerialNumber;
    }
    attributesDuplicated() {
        return this.duplicateServiceIdentifiers.length ?
            this.duplicateServiceIdentifiers :
            pathOr([], ['faultData'], this.state.updateOrderServiceDetailsError);
    }
    getServiceIdentifierDuplicatedErrorMessage() {
        return i18n.translate(CustomerCareLocaleKeys.SERVICE_IDENTIFIER_DUPLICATES_FOUND, {
            duplicates: this.attributesDuplicated().length
        });
    }
    isAttributeDuplicated(id) {
        if (this.attributesDuplicated().includes(id)) {
            return (this.editAttributesForm.$submitted);
        }
    }
    getAttributeDuplicatedErrorMessage() {
        return i18n.translate(this.duplicateServiceIdentifiers.length ?
            CustomerCareLocaleKeys.SERVICE_IDENTIFIER_UNIQUE_ERROR :
            CustomerCareLocaleKeys.SERVICE_IDENTIFIER_ALREADY_EXISTS);
    }
    showUpdateOrderServiceDetailsError() {
        return pathOr('', ['message'], this.state.updateOrderServiceDetailsError) &&
            !this.attributesDuplicated().length &&
            this.editAttributesForm.$submitted;
    }
    setServiceAttributeDisplayTypeVisibility(attr) {
        if (this.isOpenOrder && attr.Editable) {
            return this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.EDITABLE;
        } else if (this.isOpenOrder && !attr.Editable) {
            return this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.VIEWABLE;
        } else if (!this.isOpenOrder && attr.Editable && attr.serviceAttributeDisplayType === this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.EDITABLE) {
            return  this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.VIEWABLE;
        } else if (!this.isOpenOrder && ( !attr.Editable || attr.serviceAttributeDisplayType === this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.VIEWABLE)) {
            return this.SERVICE_ATTRIBUTE_DISPLAY_TYPES.VIEWABLE;
        }
    }
}

export default {
    controller: AttributeModalController,
    controllerAs: 'AttributeModal',
    template: require('./attribute.modal.html'),
    bindings: {
        attributeConfiguration: '<',
        availableAttributes: '<',
        contentTitle: '<',
        customerId: '<',
        dialogTitle: '<',
        editAttributes: '<',
        onClose: '&',
        orderId: '<',
        orderItemId: '<',
        regexCodes: '<',
        isOpenOrder: '<'
    }
};
