import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {i18n} from 'invision-core';

const DEFAULT_MAX_VISIBLE_CHARACTERS = 500;

class RemarkController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.disconnectRedux = null;
    }
    $onInit() {
        this.maxVisibleCharacters = this.maxVisibleCharacters || DEFAULT_MAX_VISIBLE_CHARACTERS;
        this.displayMore = true;
        this.actions = [{
            title: i18n.translate(CustomerCareLocaleKeys.REMARK.EDIT),
            action: () => {
                return this.editRemark();
            }
        }];
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
    }
    $onChanges() {
        this.fullyVisible = this.remark.Body.length < this.maxVisibleCharacters;
        this.displayMore = !this.fullyVisible;
        this.setVisibilityOfFullMessage(this.displayMore);
        this.hasBeenModified = this.remark.Modified !== this.remark.Created;
    }
    handleActionSelection({item: {action}}) {
        action();
    }
    toggleDisplayMore(displayMore = this.displayMore) {
        this.displayMore = !displayMore;
        this.setVisibilityOfFullMessage(this.displayMore);
    }
    setVisibilityOfFullMessage(displayMore) {
        this.visibleMessage = (!displayMore || this.fullyVisible) ? this.remark.Body : `${this.remark.Body.substr(0, this.maxVisibleCharacters)}...`;
    }
    editRemark() {
        this.onEditRemark({
            remark : this.remark
        });
    }
    get dateLabelKey() {
        return this.hasBeenModified ? this.CustomerCareLocaleKeys.REMARK.MODIFIED : this.CustomerCareLocaleKeys.REMARK.CREATED;
    }
    get moreOrLessKey() {
        return this.displayMore ? CustomerCareLocaleKeys.MORE : CustomerCareLocaleKeys.LESS;
    }
}

export default {
    bindings: {
        hasAdminAccess: '<',
        remark: '<',
        maxVisibleCharacters: '<',
        onEditRemark: '&',
    },
    template: require('./remark.html'),
    controller: RemarkController,
    controllerAs: 'Remark'
};
