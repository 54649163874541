import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import DateRangeShortcutHelper from 'invision-core/src/components/helpers/daterange.shortcut.helper';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {DateTimeHelper} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import LocaleKeys from '../../../../../locales/keys';
import {
    BILL_ADJUSTMENT,
    BILLING_INVOICE_DETAILS_URL,
    BILLING_PAYMENTS_SEARCH_PLACEHOLDER,
    CONVERT_TO_CREDIT,
    CREDIT_NOTE_TYPE,
    OPEN_INVOICES,
    OPEN_INVOICES_COL_DEFS
} from '../../../billing.payments.constants';
import {
    IsFetchingOpenInvoicesSelector,
    OpenInvoicesCurrentPageSelector,
    OpenInvoicesEndDateSelector,
    OpenInvoicesListSelector,
    OpenInvoicesPageCountSelector,
    OpenInvoicesPageSizeSelector,
    OpenInvoicesRecordCountSelector,
    OpenInvoicesSearchTermSelector,
    OpenInvoicesStartDateSelector
} from '../../../../../reducers/selectors/customer.billing.selectors';
import {
    createConvergentBillerAdjustment,
    retrieveLedgerSummary,
    updateInvoiceNumberSearchTerm,
    updateOpenInvoicesDateRange,
    updateOpenInvoicesPageNumber,
    updateOpenInvoicesPageSize
} from '../../../../../reducers/actions/customer.billing.actions';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {MISC_ADJUSTMENT} from '../../../../adjustment/adjustment.modal.constants';
import {PAYMENT_ADJUSTMENT_ACCESS} from '../../../../../security.attributes';
import clone from 'ramda/src/clone';
import {CurrentBusinessUnitTimeZoneSelector} from 'invision-core/src/components/session/businessunit.selectors';

class OpenInvoicesController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CoreLocaleKeys,
            LocaleKeys,
            OPEN_INVOICES_COL_DEFS,
            uiNotificationService,
            balanceToolTipTemplate: require('../../../../shared/tooltipTemplates/open.invoices.balance.tooltip.template.html'),
            goToInvoiceDetails: this.goToInvoiceDetails.bind(this),
            handleConvertToCreditModalClose: this.handleConvertToCreditModalClose.bind(this),
            onConvertToCreditFormSubmit: this.onConvertToCreditFormSubmit.bind(this),
            invoiceTypeLabel: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.INVOICE_TYPE),
            isConvertToCreditModalOpen: false,
            newBalanceLabel: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_BALANCE),
            onOpenInvoiceDateRangeChanged: this.onOpenInvoiceDateRangeChanged.bind(this),
            onSearchCleared: this.onSearchCleared.bind(this),
            openDialog: this.openDialog.bind(this),
            pageSizeSelected: this.pageSizeSelected.bind(this),
            priorBalanceLabel: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.PRIOR_BALANCE),
            searchInvoiceNumber: this.searchInvoiceNumber.bind(this),
            searchPlaceholder: BILLING_PAYMENTS_SEARCH_PLACEHOLDER(),
            totalOnDemandInvoicesLabel: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.TOTAL_ON_DEMAND_INVOICES),
            updateOpenInvoicesPageNumber: this.updateOpenInvoicesPageNumber.bind(this)
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentBUTimeZone: CurrentBusinessUnitTimeZoneSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                isFetchingOpenInvoices: IsFetchingOpenInvoicesSelector(store),
                openInvoicesStartDate: OpenInvoicesStartDateSelector(store),
                openInvoicesCurrentPage: OpenInvoicesCurrentPageSelector(store),
                openInvoicesEndDate: OpenInvoicesEndDateSelector(store),
                openInvoicesList: clone(OpenInvoicesListSelector(store)),
                openInvoicesPageCount: OpenInvoicesPageCountSelector(store),
                openInvoicesPageSize: OpenInvoicesPageSizeSelector(store),
                openInvoicesRecordCount: OpenInvoicesRecordCountSelector(store),
                openInvoicesSearchTerm: OpenInvoicesSearchTermSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            createConvergentBillerAdjustment,
            retrieveLedgerSummary,
            stateGo,
            updateInvoiceNumberSearchTerm,
            updateOpenInvoicesDateRange,
            updateOpenInvoicesPageNumber,
            updateOpenInvoicesPageSize
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.initDateRange();
        this.fetchOpenInvoicesLedger();
        if (hasAccess(this.state.userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS)) {
            const isMoreOptionsPresent = OPEN_INVOICES_COL_DEFS.some((item) => {
                return item.field === 'moreOptions';
            });

            if (!isMoreOptionsPresent) {
                OPEN_INVOICES_COL_DEFS.push({
                    field: 'moreOptions',
                    displayName: '',
                    cellTemplate: require('../cellTemplates/openInvoice.apply.moreOptionsMenu.template.html'),
                    width: '*',
                    maxWidth: 70,
                    enableColumnMoving: false
                });
            }
        }
        this.adjustmentModalConfig = {
            onRegisterApi: ({api}) => {
                this.adjustmentModalApi = api;
            }
        };
    }

    onAdjustmentClose() {
        this.adjustmentModalApi.close();
        this.showAdjustmentModal = false;
        this.fetchOpenInvoicesLedger();
    }

    fetchOpenInvoicesLedger() {
        const request = {
            request: {
                Filter: OPEN_INVOICES,
                InvoiceNumber: this.state.openInvoicesSearchTerm || undefined,
                StartDate: this.state.openInvoicesStartDate || undefined,
                EndDate: this.state.openInvoicesEndDate || undefined,
                PageSize: this.state.openInvoicesPageSize,
                PageNumber: this.state.openInvoicesCurrentPage
            },
            customerId: this.state.currentCustomerId
        };
        this.actions.retrieveLedgerSummary(request, true)
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    handleMoreOptionsMenuSelected(rowEntity, item) {
        switch (item.item.id) {
            case CONVERT_TO_CREDIT:
                this.convertToCreditInitialValues = {
                    AccountNumber: rowEntity.accountNumber,
                    noteType: CREDIT_NOTE_TYPE,
                    InvoiceId: rowEntity.invoiceId,
                    InvoiceNumber: rowEntity.invoiceNumber,
                    Amount: Math.abs(rowEntity.currentDue),
                    GenerateStatement: true
                };
                this.isConvertToCreditModalOpen = true;
                break;
            case BILL_ADJUSTMENT:
                this.openDialog(rowEntity.accountNumber, rowEntity.invoiceId, rowEntity.invoiceNumber);
                break;
            default:
                break;
        }
    }

    onConvertToCreditFormSubmit(formValues) {
        const {noteType, InvoiceNumber, ...convertToCreditFormValues} = formValues;
        const currentDateInBillingTimezone = DateTimeHelper.offsetTimezone(new Date(), this.state.currentBUTimeZone);

        const request = {
            customerId: this.state.currentCustomerId,
            request: {
                AdjustmentTypeCode: CONVERT_TO_CREDIT,
                PostDate: currentDateInBillingTimezone,
                ...convertToCreditFormValues
            }
        };

        this.actions.createConvergentBillerAdjustment(request)
            .then(() => {
                this.isConvertToCreditModalOpen = false;
                this.fetchOpenInvoicesLedger();
                this.uiNotificationService.transientSuccess(i18n.translate(this.LocaleKeys.CONVERT_TO_CREDIT_SUCCESS));
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    handleConvertToCreditModalClose() {
        this.isConvertToCreditModalOpen = false;
    }

    openDialog(accountNumber, invoiceId, invoiceNumber) {
        this.adjustmentType = MISC_ADJUSTMENT;
        this.adjustmentModal = {
            accountNumber: accountNumber,
            invoiceId:  invoiceId,
            invoiceNumber: invoiceNumber
        };
        this.showAdjustmentModal = true;
        this.$timeout(() => {
            this.adjustmentModalApi.open();
        });
    }

    searchInvoiceNumber(invoiceNumber) {
        if (invoiceNumber) {
            this.actions.updateInvoiceNumberSearchTerm(invoiceNumber.trim());
            this.fetchOpenInvoicesLedger();
        }
    }

    initDateRange() {
        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(this.LocaleKeys.ALL_TIME));
        this.shortcuts = [[entireHistoryDateShortcut, ...DateRangeShortcutHelper.getShortListShortcuts()], [...DateRangeShortcutHelper.getRelativeShortListShortcuts()]];
        this.selectedShortcut = !(this.state.openInvoicesStartDate && this.state.openInvoicesEndDate) ? entireHistoryDateShortcut : null;
    }

    updateOpenInvoicesPageNumber(pageNumber) {
        this.actions.updateOpenInvoicesPageNumber(pageNumber);
        this.fetchOpenInvoicesLedger();
    }

    onOpenInvoiceDateRangeChanged(startDate, endDate, selectedShortcut) {
        this.actions.updateOpenInvoicesDateRange(startDate, endDate, selectedShortcut && selectedShortcut.urlId ? selectedShortcut.urlId : null);
        this.fetchOpenInvoicesLedger();
    }

    onSearchCleared() {
        this.actions.updateInvoiceNumberSearchTerm(null);
        this.fetchOpenInvoicesLedger();
    }

    goToInvoiceDetails(invoiceId) {
        this.actions.stateGo(BILLING_INVOICE_DETAILS_URL, {
            invoiceId: invoiceId
        });
    }

    pageSizeSelected(pageSize) {
        this.actions.updateOpenInvoicesPageSize(pageSize);
        this.fetchOpenInvoicesLedger();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

}

export default {
    controller: OpenInvoicesController,
    controllerAs: 'OpenInvoicesController',
    template: require('./open.invoices.template.html')
};
