import localeKeys from '../../../../../locales/keys';

class TransactionDetailsGooglePlayController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.google.play.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsGooglePlayController
};
