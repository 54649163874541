import moment from 'moment';
import clone from 'ramda/src/clone';
import propEq from 'ramda/src/propEq';

import {MetadataCodeTypeIntegerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {getNameForCode} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {AdditionalPropertyValueType} from 'invision-core/src/components/metadata/codes/codes.additional.properties.constants';
import {saveInventoryStorePickupDetails} from '../../../../../reducers/actions/offering.order.actions';

import {
    InventoryStorePickupDetailsSelector,
    IsSendingInventoryToStoreSelector
} from '../../../../../reducers/selectors/offering.order.selectors';

import CustomerCareLocaleKeys from '../../../../../locales/keys';

class SendToStorePopupController {
    constructor($ngRedux, $anchorScroll, $location) {
        Object.assign(this, {
            $anchorScroll,
            $location,
            $ngRedux,
            CustomerCareLocaleKeys,
            onListUpdated: this.onListUpdated.bind(this),
            onPropertyChanged: this.onPropertyChanged.bind(this)
        });
    }

    $onInit() {
        const controllerActions = {
            saveInventoryStorePickupDetails
        };
        const mapStateToTarget = (store) => {
            return {
                additionalPropValueSelector: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
                inventoryStorePickupDetails: InventoryStorePickupDetailsSelector(store),
                isSendingInventoryToStore: IsSendingInventoryToStoreSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.pickupDetailsModel = clone(
            (this.pickupDetails && (this.pickupDetails.id || this.pickupDetails.comments || this.pickupDetails.date))  ?
                this.pickupDetails :
                this.state.inventoryStorePickupDetails
        );

        this.cartAdditionalProperties = Array.isArray(this.additionalProperties) ? clone(this.additionalProperties) : [];
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    submitForm() {
        if (this.sendToStoreForm.$valid) {
            this.actions.saveInventoryStorePickupDetails(Object.assign(
                {},
                this.pickupDetailsModel, {
                    date: moment.isMoment(this.pickupDetailsModel.date) ?
                        this.pickupDetailsModel.date.toDate() :
                        this.pickupDetailsModel.date
                })
            );

            this.onSubmit()();
        } else {
            const old = this.$location.hash();
            this.$location.hash('additionalPropertiesErrorField');
            this.$anchorScroll();
            this.$location.hash(old);
        }
    }

    onListUpdated() {
        this.onAdditionalPropertyListUpdated()(this.cartAdditionalProperties);
    }

    onPropertyChanged(id, newValue) {
        const findType = this.cartAdditionalProperties.find(propEq(id, 'id'));
        switch (findType.type) {
            case AdditionalPropertyValueType.SINGLE_TEXT:
                newValue = [newValue];
                break;
            case AdditionalPropertyValueType.MULTIPLE_TEXT:
                break;
            case AdditionalPropertyValueType.SELECT:
            case AdditionalPropertyValueType.BOOLEAN:
                newValue = newValue ? [getNameForCode(this.state.additionalPropValueSelector, newValue)] : [];
                break;
            default:
                break;
        }

        this.onAdditionalPropertyChanged()(id, newValue);
    }
}

export default {
    bindings: {
        additionalProperties: '<?',
        isLoading: '<?',
        onAdditionalPropertyChanged: '&',
        onAdditionalPropertyListUpdated: '&',
        onClose: '&',
        onSubmit: '&',
        pickupDetails: '<?',
        pickupDetailsConfig: '<'
    },
    controller: SendToStorePopupController,
    template: require('./send.to.store.popup.template.html')
};
