import __ from 'ramda/src/__';
import findIndex from 'ramda/src/findIndex';
import pathOr from 'ramda/src/pathOr';
import moment from 'moment';
import clone from 'ramda/src/clone';

import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    fetchCodeTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {DASHBOARD_ROUTE} from '../../../../../reducers/constants/dashboard.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {IsConvergentBillerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {LastRouteSelector} from 'invision-core/src/components/session/session.selectors';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {CUSTOMER_URL} from '../../../../customer/customer.config';
import {ShowCustomerAutoPayBannerSelector} from '../../../../../reducers/selectors/customer.account.status.selectors';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {loadCodeTables} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {getShippingMethodsByCountryCode} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.actions';

import {retrieveConvergentBillerAccountDetails} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {
    FullCartOfferingOrderQuotesSelector,
    FullCartOfferingOrderQuoteTotalSelector,
    FullCartOfferingOrderFeeQuoteSelector,
    FullCartOfferingOrderTaxQuoteSelector,
    FullCartOfferingOrderShippingQuoteSelector,
    ShowHideFullCartColumnsSelector
} from '../../../../../reducers/selectors/full.cart.selectors';
import {
    AllAttributesAreValidSelector,
    AttributeServiceSelector,
    BackButtonTextSelector,
    CurrentActiveAttributeNameSelector,
    CustomerInfoSelector,
    FilteredFormAttributeGroups,
    FilteredPhysicalAttributeGroups,
    FinancedProductGroupInCartSelector,
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    ForwardButtonTextSelector,
    FormattedNameSelector,
    GoToPreviousStepIsValidSelector,
    HasRequiredAndUnavailableInventorySelector,
    IsFetchingAnyDataSelector,
    ShowNextButtonSelector,
    ShowPreviousButtonSelector,
    StepProgressBarViewModelSelector,
    SummaryTabViewModel,
    UnavailablePhysicalInventorySelector
} from './edit.offer.wizard.selectors';
import {
    beginEditOfferOrder,
    cancelOrder,
    editOfferSubmitCosOrder,
    fetchAddresses,
    fetchAttributes,
    fetchAttributesQuote,
    fetchPreviousAttributesAndNavigate,
    fetchPreviousDecisionsAndNavigate,
    fetchWallet,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    resumeStoreOrder,
    saveOptionEdit,
    saveBulkPricing,
    searchAttributeInventory,
    sendPortInOrItemReservation,
    setCurrentStepIsInvalid,
    setDecisionsRequiringAffectedServicesResolution,
    setEditAttributeGroups,
    setEditOption,
    setEditCartAdditionalProperty,
    setEditCartAdditionalPropertyOnChange,
    setEditOrderAdditionalProperty,
    setEditOrderAdditionalPropertyOnChange,
    setEditPhysicalAttributeGroups,
    setIsResumingOrder,
    setIsResumingStoreOrderForEditOffer,
    setPaymentInfo,
    setSelectedTab,
    updateAttribute,
    updateBillCycle,
    updateCart,
    updateEditOptionPrice,
    updateInventorySelection,
    updateOfferPhysicalInventoryMakeAndModel,
    updateOfferPhysicalInventoryTypeAttribute,
    updatePaymentInfo,
    updatePortInRequest,
    updateSelectedServiceFeatures,
    updateServiceTaxLocationDecisionEditOffer
} from '../../../../../reducers/actions/edit.offer.wizard.actions';
import {
    AvailableCreditFromQuoteSelector,
    AvailableFinanceCreditFromQuoteSelector,
    BillingAddressStateRegionProvinceValueOptionsSelector,
    CartAdditionalPropertiesSelector,
    CartAdditionalPropertyListMapSelector,
    CompletedStepsSelector,
    CurrentRecurringSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    CurrentStepIndexSelector,
    DeliveryOptionsByPricingPlanSelector,
    EditOptionSelector,
    EditOptionsSelector,
    EditOfferDeliveryMethodOptionsSelector,
    EditOfferingIdSelector,
    EditOfferingInstanceIdSelector,
    FormattedAttributesSelector,
    FormattedDeliveryDecisionsSelector,
    FormattedPhysicalAttributesSelector,
    FormattedCityStateSelector,
    InventoryAvailabilityRequestsSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    IsCreditLimitBreachedFromQuoteSelector,
    IsDecisionsStepSelector,
    IsInsufficientChargeOverrideAccessWarningSelector,
    IsResumingStoreOrderSelector,
    IsSendToStoreButtonEnabledSelector,
    IsSubmittingOrderSelector,
    OrderAdditionalPropertiesSelector,
    OrderAdditionalPropertyListMapSelector,
    PaymentInfoEditSelector,
    ServiceTaxDecisionsSelector,
    IsSubscriberProductIdUndefinedSelector,
    IsShippingRequiredSelector,
    QuoteHasInvoiceItemsSelector,
    ShippingAddressSelector,
    ShippingAddressDropdownOptionsSelector,
    SelectedOfferIdSelector,
    SelectedTabIndexSelector,
    ServiceAddressSelector,
    TabPanelViewModelSelector,
    TaxLocationDecisionsByPricingPlanEditOfferSelector
} from '../../../../../reducers/selectors/edit.offer.wizard.selectors';
import {
    CurrentCustomerCreditLimitSelector,
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    SelectedCustomerHomeCountrySelector
} from '../../../../../reducers/selectors/customer.selectors';
import {STATE_OR_NAME} from '../../../../../reducers/constants/edit.offer.wizard.constants';
import {
    DELIVERY_OPTIONS,
    EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE,
    INVENTORY_ITEM_RESERVATION_FAULT_CODE,
    ORDER_SCENARIOS,
    RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION
} from '../../../../../customercare.constants';
import {
    calculateChangeOfServiceOfferingOrderQuote,
    decisionOptionQuantityChanged,
    decisionOptionServiceIdsForRemovalSelected,
    resetAvailableInventoryInformation,
    retrieveOffCycleNextRenewalSchedules,
    retrieveOfferingOrderQuote,
    retrieveOrderQuote,
    searchAvailableOfferings,
    searchInventoriesAvailability,
    sendInventoryToStore,
    setActiveAttributeFormName,
    setAttributeFormSubmitted,
    setDevicePaymentOptions,
    setInventoryRegionFilter,
    setSelectedInventoryStores
} from '../../../../../reducers/actions/offering.order.actions';
import {
    DbssShippingEnabledRegionIdSelector,
    FullCartAsyncStatusSelector,
    InventoryStorePickupDetailsSelector,
    InventoryStoresTableDataSelector,
    IsAsyncCompleteSelector,
    IsFetchingSelector,
    IsFullCartAsyncCompleteSelector,
    IsInventoryStoreConfiguredSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    OffcycleRenewalScheduleSelector,
    OfferingOrderAsyncIdSelector,
    OfferingOrderQuoteSelector,
    QuoteCalculatedSelector,
    SelectedDeliveryDecisionSelector,
    SelectedInventoryRegionSelector,
    SelectedInventoryStoresDetailsSelector,
    SelectedOrderDetailsSelector,
    SelectedOrderExecutionOptionsSelector,
    SelectedShippingAddressAndMethodSelector,
    SubscriptionOrderQuoteSelector
} from '../../../../../reducers/selectors/offering.order.selectors';
import {
    AddItemsSelector,
    AllPhysicalInventoryItemsFromSelectedOffering,
    AreAnyInventoryUnavailableSelector,
    AttributeFormSubmittedSelector,
    AttributeGroupsSelector,
    CartContainsOffcycleOfferSelector,
    CompletedDecisionsSelector,
    DecisionsGroupEmptySelector,
    DecisionsHasErrorSelector,
    DecisionsRequireAffectedServicesResolutionSelector,
    DecisionsRequiringAffectedServicesResolutionSelector,
    FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
    HasBulkShoppingCartItem,
    HasOffCycleChargeRenewalSelector,
    IsAllSelectedPhysicalInventoryPresentAtSelectedStore,
    InventoryAvailabilitySelector,
    IsUpdatingCartSelector,
    ModifyItemsSelector,
    PagesWithDecisionsSelector,
    PaymentInstrumentRequiredSelector,
    PhysicalAttributeGroupsSelector,
    RemoveItemsSelector,
    SelectedOfferChangesEffectiveSelector,
    SelectedOfferingPhysicalInventoryAvailabilitySelector,
    SelectedOfferingPickupIdSelector,
    SelectedOfferingServiceIdentifiersSelector,
    SelectedOfferingStoreAvailabilitySelector,
    SelectedOfferPaymentInstrumentSelector,
    ServicesBeingRemovedHaveServiceFeaturesSelector,
    ShoppingCartIsPostpaidSelector,
    ShoppingCartSelector,
    SubmittedOrderCurrencyCodeSelector,
    SubmittedOrderErrorSelector,
    SubmittedOrderSelector
} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    EwalletErrorSelector,
    SelectedPaymentAutoPayEnable,
    SelectedPaymentForSubmitAtCheckoutSelector,
    SelectedPaymentMethodSelector,
    RecordPaymentInformationSelector,
    SupplyPaymentInstrumentSelector
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    duplicatedServiceIdentifierAttributes,
    markDecisionsRequiringAffectedServicesResolutionAsResolved
} from '../../../../../reducers/helpers/offer.ordering.wizard.helper';
import {setSelectedContract} from '../../../../../reducers/actions/contract.details.actions';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    cancelStoreOrder,
    setCustomerCategory
} from '../../../../../reducers/actions/customer.actions';
import {resetMobileNumberPortabilityModels} from '../../../../../reducers/actions/mobile.number.portability.actions';
import {setCustomerAutoPayBannerFlag} from '../../../../../reducers/actions/customer.account.status.actions';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../../reducers/actions/address.component.actions';
import {
    ValidatedAddressesSelector,
    UnvalidatedAddressesSelector
} from '../../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../../shared/contextualComponents/address/address.validator.helper';
import {OfferWizard} from '../../offer.wizard.common';
import {ContextOfferingAction} from '../../../../../reducers/constants/wizard.constants';
import {
    MINI_CART_MORE_ACTIONS,
    moreActionMenuItems
} from '../../../../wizards/tabs/summaryTab/summary.tab.helper';
import {asyncRepeatInterval} from '../../../../../reducers/helpers/async.helper';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../../../../reducers/selectors/customer.information.selectors';
import {CurrentCustomerNodeSelector} from '../../../../../reducers/selectors/customer.account.hierarchy.selectors';
import {REPORTING_LEVELS} from '../../../accountHierarchy/account.hierarchy.constants';
import {CUSTOMER_CATEGORY} from '../../../../createCustomerPopup/create.customer.popup.constants';
class EditOfferWizardController extends OfferWizard {
    constructor($ngRedux, $log, $filter, $state, $timeout, $interval, $element, uiNotificationService, unsavedChangesPrompt) {
        super();
        Object.assign(this, {
            $element,
            $filter,
            $interval,
            $log,
            $ngRedux,
            $state,
            $timeout,
            _previewRenewalStatementsPopupApi: null,
            cartMessages: [CustomerCareKeys.CART_SUMMARY.QUOTE_SUMMARY],
            CustomerCareKeys,
            customerCategory: CUSTOMER_CATEGORY,
            DELIVERY_OPTIONS,
            initialServiceFeatures: {},
            intendedDecisionsPage: null,
            intendedDecisionsPageWhenDecisionAffectedServicesResolved: null,
            isLoadingPage: true,
            miniCartMoreActionMenuItems: moreActionMenuItems([MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER]),
            ORDER_SCENARIOS,
            overrideBypassQuoteConfiguration: false,
            proceedToAttributesStepWhenDecisionAffectedServicesResolved: false,
            retrieveOfferingOrderQuoteTimestamp: null,
            retrieveOrderQuoteTimestamp: null,
            REPORTING_LEVELS,
            showSendToStorePopup: false,
            showPreviewRenewalStatementsDialog: false,
            showSelectAffectedServicesPopup: false,
            STATE_OR_NAME,
            uiNotificationService,
            unsavedChangesPrompt,
            isReconnectOffer: $state.params.reconnectOffer,
            applyDecisionAffectedServicesResolutionsCallback: this.applyDecisionAffectedServicesResolutionsCallback.bind(this),
            attemptNextStep: this.attemptNextStep.bind(this),
            beginResolveDecisionAffectedServicesCallback: this.beginResolveDecisionAffectedServicesCallback.bind(this),
            cancelResolveDecisionAffectedServicesCallback: this.cancelResolveDecisionAffectedServicesCallback.bind(this),
            closeSearchInventoryPopupDialog: this.closeSearchInventoryPopupDialog.bind(this),
            closeSelectStorePopupDialog: this.closeSelectStorePopupDialog.bind(this),
            closeSendToStorePopup: this.closeSendToStorePopup.bind(this),
            closeViewFullCartPopupDialog: this.closeViewFullCartPopupDialog.bind(this),
            handleClosePreviewRenewalStatementsDialog: this.handleClosePreviewRenewalStatementsDialog.bind(this),
            handleCloseSubmitOrderErrorPopup: this.handleCloseSubmitOrderErrorPopup.bind(this),
            handleInventoryAvailabilityContinue: this.handleInventoryAvailabilityContinue.bind(this),
            handlePreviewRenewalStatements: this.handlePreviewRenewalStatements.bind(this),
            handleResultUnsavedChangesPromptPopup: this.handleResultUnsavedChangesPromptPopup.bind(this),
            handleSubmitForPickupCancel: this.handleSubmitForPickupCancel.bind(this),
            handleSubmitForPickupErrorPopup: this.handleSubmitForPickupErrorPopup.bind(this),
            onCartAdditionalPropertyChanged: this.onCartAdditionalPropertyChanged.bind(this),
            onCartAdditionalPropertyListUpdated: this.onCartAdditionalPropertyListUpdated.bind(this),
            onInventoryRegionFilterChanged: this.onInventoryRegionFilterChanged.bind(this),
            onInventoryRegionFilterChangedForStoreSearch: this.onInventoryRegionFilterChangedForStoreSearch.bind(this),
            onMoreActionSelected: this.onMoreActionSelected.bind(this),
            onOrderAdditionalPropertyChanged: this.onOrderAdditionalPropertyChanged.bind(this),
            onOrderAdditionalPropertyListUpdated: this.onOrderAdditionalPropertyListUpdated.bind(this),
            onOverrideCalculateQuote: this.onOverrideCalculateQuote.bind(this),
            onRefreshOfferingQuote: this.onRefreshOfferingQuote.bind(this),
            onRefreshQuote: this.onRefreshQuote.bind(this),
            onSelectInventoryStore: this.onSelectInventoryStore.bind(this),
            onUpdateSelectedServiceFeatures: this.onUpdateSelectedServiceFeatures.bind(this),
            openStoreSearch: this.openStoreSearch.bind(this),
            recalculateOrderQuote: this.recalculateOrderQuote.bind(this),
            recallOfferingContext: this.recallOfferingContext.bind(this),
            repeatRetrieveOfferingQuote: this.repeatRetrieveOfferingQuote.bind(this),
            repeatRetrieveQuote: this.repeatRetrieveQuote.bind(this),
            sendToStore: this.sendToStore.bind(this),
            setSelectedStoreForSearchModal: this.setSelectedStoreForSearchModal.bind(this),
            updateCart: this.updateCart.bind(this)
        });
        Promise.all(loadCodeTables(this.$ngRedux.getState(), this.$ngRedux.dispatch, [
            CODES.FinanceBillerRuleConfiguration,
            CODES.InventoryRegion,
            CODES.OneTimeBillerRuleConfiguration,
            CODES.PeriodType,
            CODES.ReasonCodes,
            CODES.RecurringBillerRuleConfiguration,
            CODES.Stores
        ])).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addItems: AddItemsSelector(store),
                additionalPropValueOptions: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
                addressStates: BillingAddressStateRegionProvinceValueOptionsSelector(store),
                allAttributesAreValid: AllAttributesAreValidSelector(store),
                areAnyInventoryUnavailable: AreAnyInventoryUnavailableSelector(store),
                attributeFormSubmitted: AttributeFormSubmittedSelector(store),
                attributeGroups: AttributeGroupsSelector(store),
                availableCreditFromQuote: AvailableCreditFromQuoteSelector(store),
                availableFinanceCreditFromQuote: AvailableFinanceCreditFromQuoteSelector(store),
                availableInventoryInformation: InventoryAvailabilitySelector(store),
                backButtonText: BackButtonTextSelector(store),
                cartAdditionalProperties: CartAdditionalPropertiesSelector(store),
                cartAdditionalPropertyListMap: CartAdditionalPropertyListMapSelector(store),
                cartContainsOffcycleOffer: CartContainsOffcycleOfferSelector(store),
                changesEffective: SelectedOfferChangesEffectiveSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                completedDecisions: CompletedDecisionsSelector(store),
                completedSteps: CompletedStepsSelector(store),
                createdOrder: SubmittedOrderSelector(store),
                createOrderError: SubmittedOrderErrorSelector(store),
                currencyCode: SubmittedOrderCurrencyCodeSelector(store),
                currentAttributeTitle: CurrentActiveAttributeNameSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerCreditLimit: CurrentCustomerCreditLimitSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store),
                currentRecurring: CurrentRecurringSelector(store),
                currentStep: CurrentStepSelector(store),
                currentStepIndex: CurrentStepIndexSelector(store),
                currentStepIsInvalid: CurrentStepIsInvalidSelector(store),
                customerId: CurrentCustomerIdSelector(store),
                customerInfo: CustomerInfoSelector(store),
                dbssShippingEnabledRegionId: DbssShippingEnabledRegionIdSelector(store),
                decisionsHasValidationError: DecisionsHasErrorSelector(store),
                decisionsRequireAffectedServicesResolution: DecisionsRequireAffectedServicesResolutionSelector(store),
                decisionsRequiringAffectedServicesResolution: DecisionsRequiringAffectedServicesResolutionSelector(store),
                deliveryMethodOptions: EditOfferDeliveryMethodOptionsSelector(store),
                deliveryOptionsByPricingPlan: DeliveryOptionsByPricingPlanSelector(store),
                editOption: EditOptionSelector(store),
                editOptions: EditOptionsSelector(store),
                editOfferingId: EditOfferingIdSelector(store),
                editOfferingInstanceId: EditOfferingInstanceIdSelector(store),
                emptyDecisionGroups: DecisionsGroupEmptySelector(store),
                executionOptions: SelectedOrderExecutionOptionsSelector(store),
                filteredFormAttributeGroups: FilteredFormAttributeGroups(store),
                filteredPhysicalAttributeGroups: FilteredPhysicalAttributeGroups(store),
                filteredStoresOnSelectedRegionForStoresSearchModal: FilteredStoresOnSelectedRegionForStoresSearchModalSelector(store),
                financedProductGroupInCart: FinancedProductGroupInCartSelector(store),
                formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
                formattedAttributes: FormattedAttributesSelector(store),
                formattedCityState: FormattedCityStateSelector(store),
                formattedDeliveryDecisions: FormattedDeliveryDecisionsSelector(store),
                formattedName: FormattedNameSelector(store),
                formattedPhysicalAttributes: FormattedPhysicalAttributesSelector(store),
                formattedPhysicalInventories: SelectedOfferingPhysicalInventoryAvailabilitySelector(store),
                formAttributeGroups: FormAttributeGroups(store),
                formPhysicalAttributeGroups: FormPhysicalAttributeGroups(store),
                forwardButtonText: ForwardButtonTextSelector(store),
                fullCartAsyncStatus: FullCartAsyncStatusSelector(store),
                fullCartOfferingOrderQuotes: FullCartOfferingOrderQuotesSelector(store),
                fullCartOfferingOrderQuoteTotal: FullCartOfferingOrderQuoteTotalSelector(store),
                fullCartOfferingOrderFeeQuote: FullCartOfferingOrderFeeQuoteSelector(store),
                fullCartOfferingOrderTaxQuote: FullCartOfferingOrderTaxQuoteSelector(store),
                fullCartOfferingOrderShippingQuote: FullCartOfferingOrderShippingQuoteSelector(store),
                goToPreviousStepIsValid: GoToPreviousStepIsValidSelector(store),
                hasBulkShoppingCartItem: HasBulkShoppingCartItem(store),
                hasInvoiceItems: QuoteHasInvoiceItemsSelector(store),
                hasOffCycleChargeRenewal: HasOffCycleChargeRenewalSelector(store),
                hasRequiredAndUnavailableInventory: HasRequiredAndUnavailableInventorySelector(store),
                inventoryAvailabilityRequests: InventoryAvailabilityRequestsSelector(store),
                inventoryRegions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.InventoryRegion, store),
                inventoryStorePickupDetails: InventoryStorePickupDetailsSelector(store),
                isAllSelectedPhysicalInventoryAvailable: IsAllSelectedPhysicalInventoryPresentAtSelectedStore(store),
                isAttributesStep: IsAttributesStepSelector(store),
                isCheckoutStep: IsCheckoutStepSelector(store),
                isConvergentBiller: IsConvergentBillerSelector(store),
                isCreditLimitBreached:IsCreditLimitBreachedFromQuoteSelector(store),
                isDbss: IsDbss(store),
                isDecisionsStep: IsDecisionsStepSelector(store),
                isFetchingOfferOrder: IsFetchingSelector(store),
                isFetchingAnyData: IsFetchingAnyDataSelector(store),
                isFullCartAsyncComplete: IsFullCartAsyncCompleteSelector(store),
                isInsufficientChargeOverrideAccessWarning: IsInsufficientChargeOverrideAccessWarningSelector(store),
                isInventoryStoreConfigured: IsInventoryStoreConfiguredSelector(store),
                isPaymentInstrumentRequired: PaymentInstrumentRequiredSelector(store),
                isQuoteBypassed: IsQuoteBypassedSelector(store),
                isQuoteCalculated: QuoteCalculatedSelector(store),
                isAsyncComplete: IsAsyncCompleteSelector(store),
                isQuotingOffer: IsQuotingOfferSelector(store),
                //TO-DO verify below doesn't break on customer step
                isResumingStoreOrder: IsResumingStoreOrderSelector(store),
                isSendToStoreButtonEnabled: IsSendToStoreButtonEnabledSelector(store),
                isShippingRequired: IsShippingRequiredSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                isUpdatingCart: IsUpdatingCartSelector(store),
                lastAttemptError: SubmittedOrderErrorSelector(store),
                lastRoute: LastRouteSelector(store),
                modifyItems: ModifyItemsSelector(store),
                orderDetails: SelectedOrderDetailsSelector(store),
                offcycleRenewalSchedule: OffcycleRenewalScheduleSelector(store),
                offeringOrderAsyncId: OfferingOrderAsyncIdSelector(store),
                offeringOrderQuote: OfferingOrderQuoteSelector(store),
                orderAdditionalProperties: OrderAdditionalPropertiesSelector(store),
                orderAdditionalPropertyListMap: OrderAdditionalPropertyListMapSelector(store),
                pagesWithDecisions: PagesWithDecisionsSelector(store),
                paymentInfo: SelectedOfferPaymentInstrumentSelector(store),
                paymentInfoEdit: PaymentInfoEditSelector(store),
                paymentInstrumentRequired: PaymentInstrumentRequiredSelector(store),
                periodTypes: MetadataCodeTypeSelector(CODES.PeriodType, store),
                physicalAttributeGroups: PhysicalAttributeGroupsSelector(store),
                serviceTaxDecisions: ServiceTaxDecisionsSelector(store),
                isSubscriberProductIdUndefined: IsSubscriberProductIdUndefinedSelector(store),
                physicalInventories: AllPhysicalInventoryItemsFromSelectedOffering(store),
                recordPaymentInfo: RecordPaymentInformationSelector(store),
                removeItems: RemoveItemsSelector(store),
                retrieveEwalletError: EwalletErrorSelector(store),
                shippingAddress: ShippingAddressSelector(store),
                selectedCustomerHomeCountry: SelectedCustomerHomeCountrySelector(store),
                selectedDeliveryDecision: SelectedDeliveryDecisionSelector(store),
                selectedInventoryRegion: SelectedInventoryRegionSelector(store),
                selectedInventoryStores: SelectedInventoryStoresDetailsSelector(store),
                selectedInventoryStoresItemAvailability: SelectedOfferingStoreAvailabilitySelector(store),
                selectedOfferId: SelectedOfferIdSelector(store),
                selectedOfferingServiceIdentifiers: SelectedOfferingServiceIdentifiersSelector(store),
                selectedPayment: SelectedPaymentMethodSelector(store),
                selectedPaymentClean: SelectedPaymentForSubmitAtCheckoutSelector(store),
                selectedPaymentMethodAutoPay: SelectedPaymentAutoPayEnable(store),
                selectedOfferingPickupId: SelectedOfferingPickupIdSelector(store),
                selectedShippingAddressAndMethod: SelectedShippingAddressAndMethodSelector(store),
                selectedTabIndex: SelectedTabIndexSelector(store),
                serviceAddress: ServiceAddressSelector(store),
                services: AttributeServiceSelector(store),
                servicesBeingRemovedHaveServiceFeatures: ServicesBeingRemovedHaveServiceFeaturesSelector(store),
                shippingAddressDropdownOptions: ShippingAddressDropdownOptionsSelector(store),
                shoppingCart: ShoppingCartSelector(store),
                shoppingCartIsPostpaid: ShoppingCartIsPostpaidSelector(store),
                shouldShowNextButton: ShowNextButtonSelector(store),
                shouldShowPreviousButton: ShowPreviousButtonSelector(store),
                showCustomerAutoPayBanner: ShowCustomerAutoPayBannerSelector(store),
                showHideFullCartColumns: ShowHideFullCartColumnsSelector(store),
                stepsProgressBarViewModel: StepProgressBarViewModelSelector(store),
                storesTableData: InventoryStoresTableDataSelector(store),
                subscriptionOrderQuotes: SubscriptionOrderQuoteSelector(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                summaryTabViewModel: SummaryTabViewModel(store),
                supplyPaymentInstrument: SupplyPaymentInstrumentSelector(store),
                tabs: TabPanelViewModelSelector(store),
                taxLocationDecisionsByPricingPlanEditOffer: TaxLocationDecisionsByPricingPlanEditOfferSelector(store),
                unavailablePhysicalInventory: UnavailablePhysicalInventorySelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
            };
        };

        const controllerActions = {
            beginEditOfferOrder,
            calculateChangeOfServiceOfferingOrderQuote,
            cancelOrder,
            cancelStoreOrder,
            decisionOptionQuantityChanged,
            decisionOptionServiceIdsForRemovalSelected,
            editOfferSubmitCosOrder,
            fetchAddresses,
            fetchAttributes,
            fetchAttributesQuote,
            fetchCodeTypes,
            fetchPreviousAttributesAndNavigate,
            fetchPreviousDecisionsAndNavigate,
            fetchWallet,
            getShippingMethodsByCountryCode,
            goToNextStep,
            goToPreviousStep,
            goToStep,
            registerUnsavedChanges,
            resetAvailableInventoryInformation,
            resetMobileNumberPortabilityModels,
            resumeStoreOrder,
            retrieveConvergentBillerAccountDetails,
            retrieveOffCycleNextRenewalSchedules,
            retrieveOfferingOrderQuote,
            retrieveOfferingsMetadata,
            retrieveOrderQuote,
            saveOptionEdit,
            saveBulkPricing,
            searchAttributeInventory,
            searchAvailableOfferings,
            searchInventoriesAvailability,
            sendInventoryToStore,
            sendPortInOrItemReservation,
            setActiveAttributeFormName,
            setAttributeFormSubmitted,
            setCurrentStepIsInvalid,
            setCustomerAutoPayBannerFlag,
            setCustomerCategory,
            setDecisionsRequiringAffectedServicesResolution,
            setDevicePaymentOptions,
            setEditAttributeGroups,
            setEditOption,
            setEditCartAdditionalProperty,
            setEditCartAdditionalPropertyOnChange,
            setEditOrderAdditionalProperty,
            setEditOrderAdditionalPropertyOnChange,
            setEditPhysicalAttributeGroups,
            setForceShowEditFormAddress,
            setInventoryRegionFilter,
            setIsResumingOrder,
            setIsResumingStoreOrderForEditOffer,
            setPaymentInfo,
            setSelectedContract,
            setSelectedInventoryStores,
            setSelectedTab,
            unregisterUnsavedChanges,
            updateAttribute,
            updateBillCycle,
            updateCart,
            updateEditOptionPrice,
            updateInventorySelection,
            updateOfferPhysicalInventoryMakeAndModel,
            updateOfferPhysicalInventoryTypeAttribute,
            updatePaymentInfo,
            updatePortInRequest,
            updateSelectedServiceFeatures,
            updateServiceTaxLocationDecisionEditOffer,
            validateAddress
        };

        this.disconnectRedux = this.connectToStore(mapStateToTarget, controllerActions);

        const promises = [];

        this.actions.beginEditOfferOrder();
        if (this.state.customerInfo.ConvergentBillerId) {
            promises.push(this.actions.retrieveConvergentBillerAccountDetails(this.state.customerId));
        }
        promises.push(this.actions.fetchPreviousDecisionsAndNavigate(
            this.state.customerId,
            this.state.editOfferingId,
            this.state.editOfferingInstanceId,
            null,
            this.state.isConvergentBiller,
            this.isReconnectOffer
        ).then(() => {
            if (pathOr(false, ['Items', '0', 'OrderContractInstance'], this.state.shoppingCart)) {
                this.actions.setSelectedContract(this.state.shoppingCart.Items[0].OrderContractInstance);
            }
            if (this.state.isInsufficientChargeOverrideAccessWarning && this.isReconnectOffer) {
                this.openInsufficientPermissionsWarningPopup();
            }
            this.actions.retrieveOfferingsMetadata([this.state.selectedOfferId]);
            return !this.state.isResumingStoreOrder ? this.searchAvailableOfferings() : this.actions.resumeStoreOrder(this.state.customerInfo).then(() => {
                return this.actions.calculateChangeOfServiceOfferingOrderQuote(
                    this.state.customerInfo,
                    this.state.editOfferingId,
                    this.state.shoppingCart.AddItems,
                    this.state.shoppingCart.RemoveItems,
                    this.state.shoppingCart.ModifyItems,
                    this.state.executionOptions,
                    this.isReconnectOffer,
                    this.state.selectedShippingAddressAndMethod,
                    !!this.state.shippingAddress,
                    this.overrideBypassQuoteConfiguration
                )
                    .then(() => {
                        this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
                    });
            });
        }).catch(() => {
            this.state.lastRoute.name ? this.$state.go(this.state.lastRoute.name, this.state.lastRoute.params) : this.redirectToCustomer();
        }));

        promises.push(this.actions.fetchAddresses(this.state.customerInfo.Id));
        this.actions.setCustomerCategory(this.state.customerInfo.Category);

        promises.push(this.actions.fetchWallet(this.state.customerId).catch(() => {
            if (this.state.retrieveEwalletError) {
                this.uiNotificationService.error(this.state.retrieveEwalletError.message);
            }
        }));

        if (this.state.isDbss) {
            promises.push(this.actions.getShippingMethodsByCountryCode(this.state.selectedCustomerHomeCountry).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }));
        }

        Promise.all(promises).finally(() => {
            this.$timeout(() => {
                this.isLoadingPage = false;
            });
        });

        this.insufficientPermissionsWarningPopup = {
            onRegisterApi: (event) => {
                this.insufficientPermissionsWarningPopup = event.api;
            }
        };

        this.openInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.open();
        };

        this.closeInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.close();
            this.goBackToDashboard();
        };

        this.goBackToDashboard = () => {
            const route = DASHBOARD_ROUTE;
            const params = this.state.lastRoute.params || {};
            this.$state.go(route, params);
        };

        //hook the popupApi
        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api}) => {
                this.unsavedChangesPromptPopupApi = api;
            }
        };

        // hook the order confirmation popupApi
        this.orderConfirmationPopup = {
            onRegisterApi: ({api}) => {
                this.orderConfirmationPopup = api;
            }
        };

        // hook the submit order error popupApi
        this.submitOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitOrderErrorPopupApi = api;
            }
        };

        // hook the submit order error popupApi
        this.submitForPickupErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitForPickupErrorPopupApi = api;
            }
        };

        this.creditCheckPopupConfig = {
            onRegisterApi: ({api}) => {
                this.creditCheckPopupApi = api;
            }
        };

        this.viewFullCartPopupConfig = {
            onRegisterApi: (event) => {
                this.viewFullCartPopupApi = event.api;
            }
        };

        this.previewRenewalStatementsPopupConfig = {
            onRegisterApi: (event) => {
                this._previewRenewalStatementsPopupApi = event.api;
            }
        };

        this.selectStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectStorePopupApi = api;
            }
        };

        this.storeSearchInventoryConfig = {
            onRegisterApi: ({api}) => {
                this.storeSearchInventoryApi = api;
            }
        };

        this.openCreditCheckModal = () => {
            this.creditCheckPopupApi.open();
        };

        this.sendToStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.sendToStorePopupApi = api;
            }
        };
    }

    handleResultUnsavedChangesPromptPopup(confirmed) {
        if (confirmed) {
            this.handleCancel();
        }
        this.unsavedChangesPromptPopupApi.close();
    }

    openViewFullCartPopupDialog() {
        this.showViewFullCartPopup = true;
        this.$timeout(this.viewFullCartPopupApi.open);
    }

    recallOfferingContext() {
        this.actions.fetchAttributes({
            completedAttributes: this.state.formattedAttributes,
            completedDecisions: this.getCompletedDecisions(),
            completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
            isConvergentBiller: this.state.isConvergentBiller,
            isEditOffer: true,
            isReconnectAction: this.isReconnectOffer,
            offeringId: this.state.selectedOfferId,
            offeringInstanceId: this.state.editOfferingInstanceId
        }).catch((err) => {
            this.uiNotificationService.transientError(err.translatedMessage);
        });
    }

    onOverrideCalculateQuote() {
        this.overrideBypassQuoteConfiguration = true;
        this.recalculateOrderQuote();
    }

    onViewFullCartRequest() {
        if (this.state.offeringOrderAsyncId) {
            this.repeatRetrieveOfferingQuote();
            this.openViewFullCartPopupDialog();
        } else {
            if (!Object.keys(this.state.offeringOrderQuote).length) {
                this.actions.calculateChangeOfServiceOfferingOrderQuote(
                    this.state.customerInfo,
                    this.state.editOfferingId,
                    this.state.addItems,
                    this.state.removeItems,
                    this.state.modifyItems,
                    this.state.executionOptions,
                    this.isReconnectOffer,
                    this.state.selectedShippingAddressAndMethod,
                    undefined,
                    this.overrideBypassQuoteConfiguration
                ).then(() => {
                    if (this.state.cartContainsOffcycleOffer) {
                        this.actions.retrieveOffCycleNextRenewalSchedules(
                            this.state.customerInfo.Id,
                            this.state.addItems,
                            this.state.modifyItems,
                            this.state.removeItems
                        )
                            .catch((error) => {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            });
                    }
                    this.openViewFullCartPopupDialog();
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            } else {
                if (this.state.cartContainsOffcycleOffer) {
                    this.actions.retrieveOffCycleNextRenewalSchedules(
                        this.state.customerInfo.Id,
                        this.state.addItems,
                        this.state.modifyItems,
                        this.state.removeItems
                    )
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
                this.openViewFullCartPopupDialog();
            }
        }
    }

    repeatRetrieveQuote() {
        asyncRepeatInterval(
            this.onRefreshQuote,
            () => {
                return !this.state.isAsyncComplete && this.state.isCheckoutStep;
            }
        );
    }

    onRefreshQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    repeatRetrieveOfferingQuote() {
        if (this.state.offeringOrderQuote.OfferingQuotes.length > 0 && this.state.isFullCartAsyncComplete) {
            this.openViewFullCartPopupDialog();
        } else {
            asyncRepeatInterval(
                this.onRefreshOfferingQuote,
                () => {
                    return !this.state.isFullCartAsyncComplete && this.state.isCheckoutStep;
                }
            );
        }
    }

    onRefreshOfferingQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOfferingOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    handleCloseUnsavedChangesPromptPopup() {
        this.unsavedChangesPromptPopupApi.close();
    }

    openOrderConfirmationPopup() {
        this.orderConfirmationPopup.open();
    }

    handleCloseSubmitOrderErrorPopup() {
        this.submitOrderErrorPopupApi.close();
    }

    gotoDecisionsPage() {
        this.closeViewFullCartPopupDialog();
        this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS);
    }

    handleCancel() {
        this.actions.cancelOrder();
        this.deregisterUnsavedChanges();

        if (this.state.lastRoute.name) {
            this.$state.go(this.state.lastRoute.name, this.state.lastRoute.params);
        } else {
            this.redirectToCustomer();
        }
    }

    handleSubmitForPickupCancel() {
        this.isCancellingSendToStoreCart= true;
        this.submitForPickupErrorPopupApi.close();
        this.actions.cancelStoreOrder(this.state.customerInfo.Id)
            .then(() => {
                this.handleSendToStore();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.isCancellingSendToStoreCart = false;
            });
    }

    handleSubmitForPickupErrorPopup() {
        this.submitForPickupErrorPopupApi.close();
    }

    onCartAdditionalPropertyListUpdated() {
        this.actions.setEditCartAdditionalProperty(this.state.cartAdditionalPropertyListMap);
    }

    onCartAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditCartAdditionalPropertyOnChange(id, newValue);
    }

    onOrderAdditionalPropertyListUpdated() {
        this.actions.setEditOrderAdditionalProperty(this.state.orderAdditionalPropertyListMap);
    }

    onOrderAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditOrderAdditionalPropertyOnChange(id, newValue);
    }

    beginResolveDecisionAffectedServicesCallback(intendedPage) {
        this.intendedDecisionsPage = intendedPage || null;
        this.showSelectAffectedServicesPopup = true;
    }

    closeViewFullCartPopupDialog() {
        this.showViewFullCartPopup = false;
        this.viewFullCartPopupApi.close();
    }

    cancelResolveDecisionAffectedServicesCallback(hideSelectAffectedServicesPopupOnly = false) {
        if (!hideSelectAffectedServicesPopupOnly) {
            const canceledDecisions = markDecisionsRequiringAffectedServicesResolutionAsResolved(this.state.decisionsRequiringAffectedServicesResolution);

            this.actions.setDecisionsRequiringAffectedServicesResolution(canceledDecisions);

            canceledDecisions.forEach((decision) => {
                const originalQuantity = decision.updatedQuantity + decision.numRequiredSelections;

                this.actions.decisionOptionServiceIdsForRemovalSelected(decision.decisionId, decision.optionId, undefined);
                this.actions.decisionOptionQuantityChanged(originalQuantity, decision.decisionId, decision.optionId, false);
            });

            if (!this.state.decisionsHasValidationError) {
                this.actions.setCurrentStepIsInvalid(false);
            }
        }

        this.intendedDecisionsPage = null;
        this.intendedDecisionsPageWhenDecisionAffectedServicesResolved = null;
        this.showSelectAffectedServicesPopup = false;
    }

    applyDecisionAffectedServicesResolutionsCallback() {
        if (!this.state.decisionsHasValidationError) {
            this.actions.setCurrentStepIsInvalid(false);
        }

        this.showSelectAffectedServicesPopup = false;

        if (this.intendedDecisionsPage) {
            this.intendedDecisionsPageWhenDecisionAffectedServicesResolved = this.intendedDecisionsPage;

            this.intendedDecisionsPage = null;
        } else if (this.proceedToAttributesStepWhenDecisionAffectedServicesResolved) {
            this.proceedToAttributesStepWhenDecisionAffectedServicesResolved = false;

            this.attemptNextStep();
        }
    }

    deregisterUnsavedChanges() {
        this.actions.unregisterUnsavedChanges('editOfferWizard');
        this.actions.resetMobileNumberPortabilityModels();
    }

    goToPreviousStep() {
        if (this.state.isCheckoutStep) {
            this.actions.setPaymentInfo(this.state.paymentInfoEdit);
        }

        if (this.state.isAttributesStep &&
            this.isAttributesFormInvalid()) {
            this.wizardForm.$setSubmitted();
            this.actions.setAttributeFormSubmitted(true);
            return;
        }

        this.actions.goToPreviousStep();

        if (this.state.isDecisionsStep) {
            this.actions.setAttributeFormSubmitted(false);
            this.actions.setActiveAttributeFormName(null);
        }
    }

    isAttributesFormInvalid() {
        return (this.wizardForm['attributesController.attributesForm'] &&
            this.wizardForm['attributesController.attributesForm'].$invalid) || !this.state.allAttributesAreValid;
    }

    isCheckoutFormInvalid() {
        return this.wizardForm['$ctrl.paymentInstrumentForm'] &&
            this.wizardForm['$ctrl.paymentInstrumentForm'].$invalid;
    }

    onSubmit() {
        if (!this.state.isSubmittingOrder) {
            this.$element.find('button[type="submit"]')[0].focus();
            this.$timeout(this.attemptNextStep);
        }
    }

    handleInventoryAvailabilityContinue(isSendToStore) {
        return this.actions.fetchAttributesQuote(
            this.state.customerId,
            this.state.editOfferingId,
            this.state.editOfferingInstanceId,
            this.getCompletedDecisions(),
            this.state.formattedAttributes,
            this.state.formattedPhysicalAttributes,
            this.state.isConvergentBiller,
            this.isReconnectOffer
        ).then(() => {
            this.actions.calculateChangeOfServiceOfferingOrderQuote(
                this.state.customerInfo,
                this.state.editOfferingId,
                this.state.addItems,
                this.state.removeItems,
                this.state.modifyItems,
                this.state.executionOptions,
                this.isReconnectOffer,
                undefined,
                this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED,
                this.overrideBypassQuoteConfiguration
            ).then(() => {
                if (this.state.offeringOrderAsyncId) {
                    this.repeatRetrieveQuote();
                }
            });
        })
            .then(() => {
                if (isSendToStore) {
                    this.openSendToStorePopup();
                } else {
                    if (this.state.offeringOrderAsyncId) {
                        this.repeatRetrieveQuote();
                    }
                    this.actions.goToNextStep();
                }
            }).then(() => {
                this.wizardForm.$setPristine();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    getCompletedDecisions() {
        return [...this.state.serviceTaxDecisions, ...this.state.formattedDeliveryDecisions, ...this.state.completedDecisions];
    }

    attemptNextStep(isSendToStore = false) {
        let dirty = true;
        // NOTE: this may register non-existant (extraneous) "unsaved
        // changes" in the case that the user tries to proceed to the next
        // step, but has not dirtied the form
        this.actions.registerUnsavedChanges('editOfferWizard', {
            isDirty: () => {
                return dirty;
            },
            setPristine: () => {
                dirty = false;
            }
        });

        if (this.state.decisionsRequireAffectedServicesResolution) {
            if (this.state.isDecisionsStep) {
                this.proceedToAttributesStepWhenDecisionAffectedServicesResolved = true;
            }
            this.beginResolveDecisionAffectedServicesCallback();
        } else if (this.state.isAttributesStep && !this.state.hasRequiredAndUnavailableInventory) {
            this.actions.setAttributeFormSubmitted(true);
            if (!this.isAttributesFormInvalid() && !duplicatedServiceIdentifierAttributes(this.state.formAttributeGroups).length) {
                if (this.state.formattedPhysicalAttributes.length) {
                    const transitionOutcomes = null;
                    this.actions.resetAvailableInventoryInformation();
                    this.actions.fetchAttributes({
                        completedAttributes: this.state.formattedAttributes,
                        completedDecisions: this.getCompletedDecisions(),
                        completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
                        isConvergentBiller: this.state.isConvergentBiller,
                        isEditOffer: true,
                        isReconnectAction: this.isReconnectOffer,
                        offeringId: this.state.selectedOfferId,
                        offeringInstanceId: this.state.editOfferingInstanceId,
                        transitionOutcomes: transitionOutcomes
                    }).then(() => {
                        if (this.isReconnectOffer) {
                            this.handleInventoryAvailabilityContinue();
                        } else {
                            if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                this.actions.setInventoryRegionFilter(this.state.dbssShippingEnabledRegionId);
                            }
                            if (this.state.selectedInventoryStores.length < 1) {
                                if (isSendToStore || this.state.isSubscriberProductIdUndefined) {
                                    this.openStoreSearch(isSendToStore);
                                } else {
                                    this.handleInventoryAvailabilityContinue();
                                }
                            } else if (isSendToStore || this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories).then(() => {
                                    if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
                                        if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                            this.uiNotificationService.transientError(i18n.translate(CustomerCareKeys.ATTRIBUTES.UNAVAILABLE_SHIPPING_INVENTORY));
                                        } else if (isSendToStore) {
                                            this.openStoreSearch();
                                        }
                                    } else if (isSendToStore) {
                                        this.openSendToStorePopup();
                                    } else {
                                        this.handleInventoryAvailabilityContinue();
                                    }
                                }).catch((err) => {
                                    if (isSendToStore) {
                                        this.showStoreInventoryPopup = true;
                                        this.$timeout(() => {
                                            this.storeSearchInventoryApi.open();
                                        });
                                    }
                                    this.onSearchInventoryAvailabilityFailure(err);
                                });
                            } else {
                                this.handleInventoryAvailabilityContinue();
                            }
                        }
                    });
                } else if (isSendToStore) {
                    this.openSendToStorePopup();
                } else {
                    this.handleInventoryAvailabilityContinue();
                }
            }
        } else if (this.state.isCheckoutStep) {
            if (isSendToStore) {
                if (this.state.selectedInventoryStores.length < 1) {
                    this.openStoreSearch();
                } else {
                    this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories).then(() => {
                        if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
                            this.openStoreSearch();
                        } else if (isSendToStore) {
                            this.openSendToStorePopup();
                        }
                    });
                }
            } else if (!this.isCheckoutFormInvalid()) {
                this.validateAddressWithAPI()
                    .then(() => {
                        const addressKey = this.getAddressKey(),
                            validatedAddress = this.state.validatedAddresses[addressKey];

                        if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                            let paymentInfo;
                            // if statement handled this way to make sure it has an Id, otherwise it returns a function if null
                            if (pathOr(false, ['Id'], this.state.selectedPayment)) {
                                paymentInfo = (this.state.supplyPaymentInstrument || this.state.paymentInstrumentRequired)
                                    ? this.state.selectedPaymentClean : null;

                            } else {
                                this.actions.setPaymentInfo(this.state.paymentInfoEdit);
                                paymentInfo = this.state.paymentInfoEdit;
                                if (this.state.paymentInfoEdit && this.state.paymentInfoEdit.BillingAddressIsServiceAddress) {
                                    paymentInfo = Object.assign({}, paymentInfo, {
                                        BillingAddressId: this.state.serviceAddress.Id
                                    });
                                }
                                if (this.state.paymentInfoEdit && !this.state.paymentInfoEdit.BillingAddressIsServiceAddress) {
                                    const billingAddress = Object.assign({}, paymentInfo.BillingAddress, {
                                        Name: this.state.paymentInfoEdit.BillingAddress.LineOne // Name is required by the API and needs to be unique.
                                    });
                                    paymentInfo = Object.assign({}, paymentInfo, {
                                        BillingAddress: billingAddress
                                    });
                                }
                            }

                            this.actions.setCustomerAutoPayBannerFlag(this.state.selectedPaymentMethodAutoPay);
                            if (this.state.currentCustomerCreditLimit && !this.isReconnectOffer) {
                                this.checkCreditandFinanceLimitAndSubmitOrder(paymentInfo);
                            } else {
                                this.editOfferSubmitCOSOrder(paymentInfo);
                            }
                        } else {
                            this.actions.setForceShowEditFormAddress(false);
                            this.uiNotificationService.transientError(i18n.translate(this.customerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                        }
                    }).catch((err) => {
                        this.uiNotificationService.transientError(err.translatedMessage);
                    });
            }
        } else if (this.state.isDecisionsStep && !this.state.decisionsHasValidationError) {

            /*
                For the resumeStoreOrder scenario in edit or change offer, when we hit
                the `sendToStore` API unlike `addOffer` we do not send along a saved shopping cart.
                Therefore we do not need to update the shopping cart like in add since we do not get back
                a shopping cart from the API.
            */
            if (this.state.isResumingStoreOrder) {
                if (this.state.formattedPhysicalAttributes.length === 0) {
                    //We are setting this to populate the decition type 4 selectors
                    this.actions.setEditPhysicalAttributeGroups({
                        physicalAttributeGroups: this.state.physicalAttributeGroups,
                        formPhysicalAttributeGroups: this.state.formPhysicalAttributeGroups
                    });
                }
                if (!this.state.filteredFormAttributeGroups || this.state.filteredFormAttributeGroups.length === 0) {
                    this.actions.setEditAttributeGroups(this.state.attributeGroups);
                }
            }

            this.actions.fetchPreviousAttributesAndNavigate(
                this.state.customerInfo,
                this.state.editOfferingId,
                this.state.editOfferingInstanceId,
                this.state.isResumingStoreOrder ? [...this.getCompletedDecisions(), ...this.state.formattedAttributes, ...this.state.formattedPhysicalAttributes] : [...this.getCompletedDecisions(), ...this.state.formattedAttributes],
                this.state.isConvergentBiller,
                this.isReconnectOffer,
                this.overrideBypassQuoteConfiguration
            )
                .then(() => {
                    if (this.state.isResumingStoreOrder) {
                        this.actions.setIsResumingStoreOrderForEditOffer(false);
                    }
                    this.wizardForm.$setPristine();
                });
        } else if (!this.state.currentStepIsInvalid) {
            this.redirectToCustomer();
        } else {
            this.$log.warn('The current step has errors');
        }
    }


    checkCreditandFinanceLimitAndSubmitOrder(paymentInfo) {
        const isFinanceCreditLimitBreached = this.validateFinanceCreditLimitFromQuote();
        if (!this.state.isCreditLimitBreached && !isFinanceCreditLimitBreached) {
            this.editOfferSubmitCOSOrder(paymentInfo);
        }
    }

    validateFinanceCreditLimitFromQuote() {
        return this.state.availableFinanceCreditFromQuote > 0;
    }

    editOfferSubmitCOSOrder(paymentInfo) {
        this.actions.editOfferSubmitCosOrder(
            this.state.customerId,
            this.state.editOfferingId,
            this.state.editOfferingInstanceId,
            this.state.addItems,
            this.state.modifyItems,
            this.state.removeItems,
            paymentInfo,
            this.state.changesEffective,
            this.state.executionOptions,
            this.state.recordPaymentInfo,
            this.isReconnectOffer,
            pathOr(null, ['selectedOfferingPickupId', 'Id'], this.state),
            this.state.orderAdditionalProperties,
            this.state.selectedShippingAddressAndMethod,
            this.state.orderDetails
        ).then(() => {
            //Below might not be necessary
            if (this.state.isResumingOrder) {
                this.actions.setIsResumingStoreOrderForEditOffer(false);
            }
            this.orderConfirmationPopup.open();
            this.actions.resetMobileNumberPortabilityModels();
        }).catch((error) => {
            this.submitCoSError = error.translatedMessage;
            this.submitOrderErrorPopupApi.open();
        });
    }

    recalculateOrderQuote() {
        this.actions.calculateChangeOfServiceOfferingOrderQuote(
            this.state.customerInfo,
            this.state.editOfferingId,
            this.state.shoppingCart.AddItems,
            this.state.shoppingCart.RemoveItems,
            this.state.shoppingCart.ModifyItems,
            this.state.executionOptions,
            this.isReconnectOffer,
            this.state.selectedShippingAddressAndMethod,
            !!this.state.shippingAddress,
            this.overrideBypassQuoteConfiguration
        ).then(() => {
            if (this.state.offeringOrderAsyncId) {
                this.repeatRetrieveQuote();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    redirectToCustomer() {
        this.deregisterUnsavedChanges();
        this.$state.go(CUSTOMER_URL, {
            'customerId': this.state.customerInfo.Id,
            'showAutoPayBanner': {
                enabled: this.state.showCustomerAutoPayBanner,
                customerId: this.state.customerInfo.Id
            }
        }, {
            reload: true
        });
    }

    tabSelected(selectedTab) {
        const selectedIndex = findIndex((tab) => {
            return tab === selectedTab;
        })(this.state.tabs);

        this.actions.setSelectedTab(selectedIndex);
    }

    updateCart(offerId, offeringInstanceId, completedDecisions, isModify, customerId, callback, contextOfferingAction,
        transitionContext, isConvergentBiller, customerLanguage) {
        this.actions.setDevicePaymentOptions([]);
        this.actions.updateCart(
            offerId,
            offeringInstanceId,
            completedDecisions,
            isModify,
            customerId,
            callback,
            this.isReconnectOffer ? ContextOfferingAction.RECONNECT : contextOfferingAction,
            transitionContext,
            isConvergentBiller,
            customerLanguage
        );
    }

    cancelClick() {
        if (this.unsavedChangesPrompt.hasUnsavedChanges()) {
            this.unsavedChangesPromptPopupApi.open();
        } else {
            this.handleCancel();
        }
    }

    handlePreviewRenewalStatements() {
        this._openPreviewRenewalStatementsPopup();
    }

    handleClosePreviewRenewalStatementsDialog() {
        this._closePreviewRenewalStatementsPopup();
    }

    _openPreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = true;
        this.$timeout(this._previewRenewalStatementsPopupApi.open);
    }

    _closePreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = false;
        this._previewRenewalStatementsPopupApi.close();
    }

    onSelectInventoryStore() {
        this.showSelectStorePopup = true;

        if (this.state.selectedInventoryRegion) {
            this.actions.setInventoryRegionFilter(this.state.selectedInventoryRegion);
        }

        this.$timeout(() => {
            this.selectStorePopupApi.open();
        });
    }

    closeSelectStorePopupDialog(applyChanges, selectedStore) {
        this.showSelectStorePopup = false;
        if (applyChanges) {
            this.actions.setSelectedInventoryStores([selectedStore]);
        }
        this.actions.setInventoryRegionFilter(null);
        this.selectStorePopupApi.close();
    }

    onInventoryRegionFilterChanged(region) {
        this.actions.setInventoryRegionFilter(region);
    }

    onInventoryRegionFilterChangedForStoreSearch(region) {
        this.actions.setInventoryRegionFilter(region);
        this.actions.resetAvailableInventoryInformation();
        const formattedInventories = this.state.physicalInventories,
            formattedPayload = !this.state.formattedInventories ? {
                InventoryTypes: formattedInventories,
                RegionId: region
            } : this.state.formattedPhysicalInventories;

        this.actions.searchInventoriesAvailability(formattedPayload)
            .catch(this.onSearchInventoryAvailabilityFailure);
    }

    closeSearchInventoryPopupDialog() {
        this.showStoreInventoryPopup = false;
        this.actions.setInventoryRegionFilter(null);
        this.storeSearchInventoryApi.close();
    }

    setSelectedStoreForSearchModal(selectedStore) {
        this.actions.setSelectedInventoryStores([selectedStore]);
        this.showStoreInventoryPopup = false;
        this.storeSearchInventoryApi.close(); //need to fix this?
        this.handleInventoryAvailabilityContinue(this.isSubmitForPickUp || !this.state.isSubscriberProductIdUndefined);
    }

    openStoreSearch(isSendToStore = false) {
        this.isSubmitForPickUp = isSendToStore;
        this.showStoreInventoryPopup= true;
        this.$timeout(() => {
            this.storeSearchInventoryApi.open();
        });
    }

    openSendToStorePopup() {
        this.isLoading = false;
        this.sendToStorePickupDetailsConfig = {
            maxDate: undefined,
            minDate: Date.now()
        };
        this.showSendToStorePopup = true;

        this.$timeout(() => {
            this.sendToStorePopupApi.open();
        });
    }

    closeSendToStorePopup() {
        this.showSendToStorePopup = false;

        this.sendToStorePopupApi.close();
    }

    sendToStore() {
        this.handleSendToStore();
    }

    handleSendToStore() {
        const offeringId = this.state.editOfferingId,
            offeringInstanceId = this.state.editOfferingInstanceId;

        const offering = {
            Action: this.isReconnectOffer ? ContextOfferingAction.RECONNECT : ContextOfferingAction.MODIFY,
            OfferingId: offeringId,
            OfferingInstanceId: offeringInstanceId
        };
        this.actions.sendInventoryToStore(
            this.state.customerInfo.Id,
            this.state.cartAdditionalProperties.length ? {
                AdditionalProperties: this.state.cartAdditionalProperties
            } : undefined,
            this.state.inventoryStorePickupDetails,
            offering,
            this.getCompletedDecisions().concat(this.state.formattedAttributes).concat(this.state.formattedPhysicalAttributes)
        )
            .then(() => {
                this.actions.setIsResumingStoreOrderForEditOffer(false);
                this.actions.unregisterUnsavedChanges('$ctrl.sendToStoreForm');
                this.closeSendToStorePopup();
                this.onOrderActionSuccess(CustomerCareKeys.SEND_TO_STORE.SEND_SUCCESS);
            }).catch((error) => {
                if (error.Code === INVENTORY_ITEM_RESERVATION_FAULT_CODE) {
                    this.closeSendToStorePopup();
                    this.isInventoryItemReservationFault = true;
                    this.openStoreSearch();
                } else if (error.Code === EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE) {
                    this.closeSendToStorePopup();
                    this.submitForPickupErrorPopupApi.open();

                } else {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            });
    }

    onUpdateSelectedServiceFeatures(attributeId, selectedServiceFeatures, billerRuleInstanceDetails) {
        const removedItems = clone(this.state.removeItems);
        if (!this.initialServiceFeatures[attributeId]) {
            this.state.formAttributeGroups.forEach((attributeGroup) => {
                return attributeGroup.attributes.find((attribute) => {
                    if (attribute.id === attributeId) {
                        return this.initialServiceFeatures[attributeId] = attribute.serviceFeatures;
                    }
                });
            });
        }

        selectedServiceFeatures.forEach((serviceFeature) => {
            if (!serviceFeature.SubscriberProductId) {
                if (this.initialServiceFeatures[attributeId] && this.initialServiceFeatures[attributeId].find((initialServiceFeature) => {
                    return initialServiceFeature.ProductId === serviceFeature.ProductId &&
                        initialServiceFeature.PricingPlanId === serviceFeature.PricingPlanId;
                })) {
                    const restorableIndex = removedItems.findIndex((item) => {
                        return item.ProductId === serviceFeature.ProductId &&
                        item.PricingPlanId === serviceFeature.PricingPlanId;
                    });
                    if (restorableIndex > -1) {
                        serviceFeature.SubscriberProductId = removedItems[restorableIndex].LockerItemId;
                    }
                }


            }

        });

        this.actions.updateSelectedServiceFeatures(attributeId, selectedServiceFeatures, billerRuleInstanceDetails);
    }

    $onDestroy() {
        this.cancelResolveDecisionAffectedServicesCallback();
        this.disconnectRedux();
    }

    getAddressKey() {
        return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        /*
            This will only get called if the payment instrument component appears which should
            only occure if they have no defaultBilling Payment method
        */
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes =  {
                DefaultBilling: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    searchAvailableOfferings() {
        const data = {
            OfferingIds: [this.state.selectedOfferId]
        };

        return this.actions.searchAvailableOfferings(data,
            {
                pageNumber: 1
            },
            undefined,
            this.state.customerInfo.Id
        );
    }

    onOrderActionSuccess(messageKey) {
        this.uiNotificationService.transientSuccess(i18n.translate(messageKey));
        this.redirectToCustomer();
    }

    onMoreActionSelected(action) {
        if (action === MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER) {
            this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS);
        }
    }
}

export default {
    template: require('./edit.offer.wizard.html'),
    controller: EditOfferWizardController,
    controllerAs: 'editOfferController'
};
