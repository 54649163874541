import {isAlwaysAbleToBeSetAsDefaultType} from '../../../../reducers/helpers/ewallet.reducer.helper';

export const showAddToBlocklist = (paymentMethod) => {
    return Boolean(!paymentMethod.Blocklisted && !paymentMethod.Allowlisted && paymentMethod.actionables.canBlocklist && !paymentMethod.Locked);
};

export const showRemoveFromBlocklist = (paymentMethod) => {
    return Boolean(paymentMethod.Blocklisted && paymentMethod.actionables.canBlocklist && !paymentMethod.Locked);
};

export const showAddToAllowlist = (paymentMethod) => {
    return Boolean(!paymentMethod.Allowlisted && !paymentMethod.Blocklisted && paymentMethod.actionables.canBlocklist && !paymentMethod.Locked);
};

export const showRemoveFromAllowlist = (paymentMethod) => {
    return Boolean(paymentMethod.Allowlisted && paymentMethod.actionables.canBlocklist && !paymentMethod.Locked);
};

export const paymentInstrumentCanBeSetAsDefault = (paymentMethod) => {
    return Boolean(paymentMethod
        && (isAlwaysAbleToBeSetAsDefaultType(paymentMethod.Type) || paymentMethod.actionables.canOrder));
};
