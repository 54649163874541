// These values need to match the response from https://qa8.metadata.ascendon.tv/Codes/Type/390/Language/en-US/SystemId/85788485-e411-48a9-b478-610c1285dc1a
export const BILL_ADJUSTMENT = '1001';
export const MISC_ADJUSTMENT = '1002';
export const DEPOSIT_ADJUSTMENT = '1003';
export const WRITEOFF_ADJUSTMENT = '5';
export const WRITEOFF_REVERSAL_ADJUSTMENT = '1007';
export const AR_TRANSFER_ADJUSTMENT_EXTERNAL_REFERENCE = 'AR_TRANSFER';

export const DEPOSIT_TRANSFER = '1001';
export const DEPOSIT_UPDATED ='1019';

export const ADJUSTMENT_CATEGORIES = {
    FINANCE: 'FINANCE',
    OTHER: 'OTHER'
};

export const ADJUSTMENT_PAYMENT_TYPES = {
    CREDIT: '0',
    DEBIT: '1'
};