import LocaleKeys from '../../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {CART_ITEM_TYPE} from '../../../../../../reducers/constants/products.wizard.constants';

const CART_ITEM_MORE_MENU_ITEMS = {
    REMOVE: 'remove',
    EDIT: 'edit',
    TERMS_CONDITIONS: 'terms_conditions'
};

class CartController {
    constructor($ngRedux, $timeout, $window) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            $window,
            localeKeys: LocaleKeys,
            PRODUCT_CLASSIFICATIONS
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                isDbss: IsDbss(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });
        this.DEFAULT_MENU_ITEMS = [];

        this.cartItemClasses = {
            [CART_ITEM_TYPE.ADDITION]: 'is-addition',
            [CART_ITEM_TYPE.REMOVAL]: 'is-removal'
        };

        this.handleMoreOptionsMenuSelected = (item) => {
            switch (item.item.id) {
                case CART_ITEM_MORE_MENU_ITEMS.EDIT:
                    this.onEditOptionSelect();
                    break;
                case CART_ITEM_MORE_MENU_ITEMS.REMOVE :
                    if (this.onRemove()) {
                        this.onRemove()(this.cartItem);
                    }
                    break;
                case CART_ITEM_MORE_MENU_ITEMS.TERMS_CONDITIONS :
                    this.onTermsAndConditionsOptionSelect();
                    break;
                default:
                    break;
            }
        };

        /*** Select Options Modal for editing a cartItem ***/
        this.removeCartItem = (() => {
            this.onRemove()(this.cartItem);
        }).bind(this);

        this.onEditOptionSelect = () => {
            this.onEdit()(this.cartItem);
        };

        this.termsAndConditionsPopupApi = null;
        this.termsAndConditionsPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.termsAndConditionsPopupApi = api;
            }
        };
        this.closeTermsDialog = this.closeTermsDialog.bind(this);

        this.hasChildrenToShow = () => {
            return this.cartItem.childItems && this.cartItem.childItems.length > 0;
        };

        this.toggleShowDetails = () => {
            this.showShowDetails = !this.showShowDetails;
            this.showHideDetailsLabel = this.showShowDetails ? this.translatedShowDetailsLabel : this.translatedHideDetailsLabel;
        };

        this.translatedShowDetailsLabel = i18n.translate(this.localeKeys.ORDER_DETAILS.SHOW_DETAILS);
        this.translatedHideDetailsLabel = i18n.translate(this.localeKeys.ORDER_DETAILS.HIDE_DETAILS);

        this.showShowDetails = true;
        this.showHideDetailsLabel = this.translatedShowDetailsLabel;

        this.updateMenuItems();
    }

    $onChanges(changesObj) {
        if (changesObj.cartItemRemovable && this.DEFAULT_MENU_ITEMS) {
            this.updateMenuItems();
        }
    }

    getCartItemClass() {
        return this.cartItemClasses[this.cartItem.itemType];
    }

    updateMenuItems() {
        this.moreOptionsMenuItems = this.DEFAULT_MENU_ITEMS.concat([]);

        if (this.cartItem.isEditable) {
            this.moreOptionsMenuItems.push({
                id: CART_ITEM_MORE_MENU_ITEMS.EDIT,
                isDisabled: false,
                title: i18n.translate(LocaleKeys.EDIT)
            });
        }

        if (this.cartItem.isRemovable) {
            this.moreOptionsMenuItems.push({
                id: CART_ITEM_MORE_MENU_ITEMS.REMOVE,
                isDisabled: false,
                title: i18n.translate(this.cartItem.classification === this.PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE &&
                    this.cartItem.itemType === CART_ITEM_TYPE.REMOVAL ?
                    this.localeKeys.ADD_TO_CART : this.localeKeys.REMOVE
                )
            });
        }

        if (this.cartItem.pricingPlanTermsAndConditions) {
            this.moreOptionsMenuItems.push({
                id: CART_ITEM_MORE_MENU_ITEMS.TERMS_CONDITIONS,
                isDisabled: false,
                title: i18n.translate(this.localeKeys.TERMS_AND_CONDITIONS)
            });
        }
    }

    onTermsAndConditionsOptionSelect() {
        if (this.cartItem.pricingPlanTermsAndConditionsIsUrl) {
            this.$window.open(this.cartItem.pricingPlanTermsAndConditions, '_blank');
        } else {
            this.showTermsAndConditionsModalPopup = true;
            this.$timeout(() => {
                this.termsAndConditionsPopupApi.open();
            });
        }
    }

    closeTermsDialog() {
        this.showTermsAndConditionsModalPopup = false;
        this.termsAndConditionsPopupApi.close();
    }
}

export default {
    bindings: {
        brandableCurrencyName: '<',
        cartItem: '<',
        currencyCode: '<',
        onEdit: '&',
        onEditSuccess: '&',
        onRemove: '&'
    },
    controller: CartController,
    template: require('./create.products.order.cart.item.html')
};
