import {i18n} from 'invision-core';
import localeKeys from '../../../../../locales/keys';
import {OrderDetailsNotificationHistorySelector} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';

class GiftNotificationHistoryController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            localeKeys
        });

        this.grid = {
            columns: [
                {
                    field: 'Notified',
                    displayName: i18n.translate(localeKeys.ORDER_DETAILS.NOTIFICATION_DATE),
                    width: 150,
                    cellFilter: 'date: \'shortDate\''
                }, {
                    field: 'RecipientName',
                    displayName: i18n.translate(localeKeys.ORDER_DETAILS.RECIPIENT_NAME),
                    minWidth: 250
                }, {
                    field: 'RecipientEmail',
                    displayName: i18n.translate(localeKeys.EMAIL),
                    minWidth: 250
                }, {
                    field: 'SenderMessage',
                    displayName: i18n.translate(localeKeys.ORDER_DETAILS.MESSAGE),
                    cellTemplate: '<div class="ui-grid-cell-contents" ng-bind="row.entity.SenderMessage"></div>',
                    minWidth: 250
                }, {
                    field: ' ',
                    cellTemplate: `<div class="c-datatable-drillableCell"
                        ng-click="grid.appScope.giftNotificationHistoryController.onNotificationClick(row.entity)"
                        ng-bind="::grid.appScope.giftNotificationHistoryController.localeKeys.ORDER_DETAILS.READ | i18n">
                    </div>`,
                    width: 150
                }
            ],
            onInit: () => {}
        };

        this.messagePopup = {
            onRegisterApi: ({api}) => {
                Object.assign(this.messagePopup, api);
            }
        };
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                orderNotificationHistory: OrderDetailsNotificationHistorySelector(store)
            };
        };
        const controllerActions = {};

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onNotificationClick(notification) {
        this.selectedNotification = notification;
        this.messagePopup.open();
    }
}

export default {
    template: require('./gift.notification.history.html'),
    controller: GiftNotificationHistoryController,
    controllerAs: 'giftNotificationHistoryController',
    bindings: {}
};