// General Status from metadata code 67
export const GENERAL_STATUSES = {
    ACTIVE: 1,
    PENDING_ACTIVATION: 5,
    PENDING_APPROVAL: 4,
    PENDING_REMOVAL: 6,
    REMOVED: 2,
    SUSPENDED: 3
};

export const ALLOWABLE_ACTIONS = {
    ALLOW_ADD: 1,
    ALLOW_REMOVE: 2,
    ALLOW_EDIT: 3,
};
