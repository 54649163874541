import {
    find,
    pickBy,
    pipe,
    pluck,
    prop,
    propEq
} from 'ramda';
import cloneDeep from 'lodash/cloneDeep';
import LocaleKeys from '../../locales/keys';
import {i18n} from 'invision-core';
import {findCardType} from '../../utilities/credit.card.helper';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../customer/ewallet/ewallet.constants';

const objectAttributeMap = new Map([
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, 'CreditCard'],
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD, 'ExternalGiftCard'],
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK, 'ECheck'],
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL, 'ExternalBill']
]);
const paymentInstrumentAttributes = [
    'BillingAddress',
    'BillingAddressId',
    'Default',
    'Id',
    'Name',
    'Type'
];

let _instrumentAttributesToKeep = [];
const isInstrumentAttributeToKeep = (val, key) => {
    return _instrumentAttributesToKeep.includes(key);
};

const scrubPaymentInstrumentObject = (paymentInstrumentObject, instrumentNameToKeep) => {
    _instrumentAttributesToKeep = Array.from(paymentInstrumentAttributes);
    if (instrumentNameToKeep) {
        _instrumentAttributesToKeep.push(instrumentNameToKeep);
    }

    return pickBy(isInstrumentAttributeToKeep, paymentInstrumentObject);
};

export const buildPaymentInstrumentRequest = (isCreate, requestData, recordPaymentInstrument = true) => {
    const paymentInstrument = scrubPaymentInstrumentObject(cloneDeep(requestData.editPaymentInstrument),
        objectAttributeMap.get(requestData.editPaymentInstrument.Type));

    if (objectAttributeMap.get(requestData.editPaymentInstrument.Type)) {
        const paymentInstrumentObject =
            requestData.editPaymentInstrument[objectAttributeMap.get(requestData.editPaymentInstrument.Type)];

        if (!paymentInstrument.Name) {
            //Types (specifically external gift cards) may not be available on edit.  Don't try to overwrite the name
            paymentInstrument.Name = createCardShortName(paymentInstrumentObject,
                getCardName(paymentInstrumentObject.Type, requestData.editPaymentInstrument,
                    requestData.creditCardTypes, requestData.externalGiftCardTypes,
                    requestData.paymentInstrumentTypes));
        }
    }

    if (isCreate) {
        paymentInstrument.Locked = !recordPaymentInstrument;
        paymentInstrument.recordPayment = recordPaymentInstrument;
        if (recordPaymentInstrument) {
            delete paymentInstrument.Id;
        } else {
            paymentInstrument.Id = -1;
        }
    }

    const requestObj = {
        customerId: requestData.currentCustomerId,
        request: {
            PaymentInstrument: paymentInstrument
        }
    };

    if (requestData.paymentTypeRequiresAddress) {
        requestObj.request.PaymentInstrument.BillingAddress =
            buildAddress(paymentInstrument.BillingAddress, requestData.paymentTypeRequiresAddress,
                requestData.editPaymentInstrument, requestData.makePaymentAddress);
    } else {
        delete(requestObj.request.PaymentInstrument.BillingAddress);
    }
    return requestObj;
};

export const buildAddress = (address, paymentTypeRequiresAddress, editPaymentInstrument, makePaymentAddress) => {
    if (!paymentTypeRequiresAddress && !editPaymentInstrument.BillingAddress) {
        return;
    }

    const addressObj = cloneDeep(address);
    addressObj.Name = address.Name || address.LineOne;
    if (!makePaymentAddress.Id) {
        delete(addressObj.Id);
    }
    return addressObj;
};

export const createCardShortName = (card, cardName) => {
    const substitutions = {
        cardType: cardName,
        last4Digits: card.AccountNumber ? card.AccountNumber.slice(-4) : ''
    };
    return i18n.translate(LocaleKeys.MAKE_PAYMENT.CARD_TYPE_AND_LAST_FOUR, substitutions);
};

export const getCardName = (cardType, editPaymentInstrument, creditCardTypes, externalGiftCardTypes, paymentInstrumentTypes) => {
    if (editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD) {
        return findCardType(creditCardTypes, cardType);
    }
    if (editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD) {
        return findCardType(externalGiftCardTypes, cardType);
    }
    return pipe(
        pluck('paymentInstrumentType'),
        find(propEq(editPaymentInstrument.Type, 'Value')),
        prop('Name')
    )(paymentInstrumentTypes);
};
