import {MetadataConstants, MetadataSelectors} from 'invision-core';
import {SelectedShippingAddressFromDropDownSelector, UpdatedShippingAddressSelector} from '../../../../../reducers/selectors/offering.order.selectors';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {setSelectedShippingAddress, setUpdatedShippingAddress} from '../../../../../reducers/actions/offering.order.actions';
import cloneDeep from 'lodash/cloneDeep';
import {stateRequiredHelper} from '../../../../customer/addresses/address.helper';
import {addressStateRegionProvinceValueOptionsForCountry} from '../../../../../reducers/helpers/customer.selectors.helpers';
import {setAddressData} from '../../../../../reducers/actions/customer.addresses.actions';

function ChangeShippingAddressPopupController($ngRedux) {
    this.$onInit = () => {
        const mapStateToTarget = (store) => {
            return {
                addressCountriesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
                addressStatesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressRequirements: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressRequirements, store),
                addressRequirementsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressRequirements, store),
                selectedShippingAddress: SelectedShippingAddressFromDropDownSelector(store),
                updatedShippingAddress: UpdatedShippingAddressSelector(store),
            };
        };
        const controllerActions = {
            setSelectedShippingAddress,
            setUpdatedShippingAddress,
            setAddressData
        };
        this.disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressCountry);
        }

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressStateProvinceRegion);
        }

        if (!this.state.addressRequirementsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressRequirements);
        }

        this.addressFormModel = {};
        this.editShippingAddress = {
            Id: 0,
            Country: null,
            ShipToName: null,
            LineOne: null,
            LineTwo: null,
            State: null,
            City: null,
            PostalCode: null
        };
        this.customerCareKeys = CustomerCareLocaleKeys;
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleChangeDropDownAddress = this.handleChangeDropDownAddress.bind(this);
        this.onAddressFormChanged = this.onAddressFormChanged.bind(this);
        this.onSubmitChangeShippingAddressModal = this.onSubmitChangeShippingAddressModal.bind(this);
        this.showErrorSummary = false;

        this.editShippingAddress = cloneDeep(this.state.updatedShippingAddress);
        this.updateAddressFormModel();
    };

    this.setAddressData = () => {
        const payload = {};
        if (this.editShippingAddress.Country) {
            payload.Country = this.editShippingAddress.Country;
        }
        this.actions.setAddressData(payload);
    };

    this.updateStateField = () => {
        // this.actions.setAddressData(this.editPaymentInstrument.BillingAddress);
        this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.addressStatesOptions);

        // if Country has states, reset State if it's not one of them
        if (this.addressStatesOptions && !this.addressStatesOptions.find((stateOption) => {
            return stateOption.value === this.editShippingAddress.State;
        })) {
            this.editShippingAddress.State = null;
            this.addressFormModel.stateRegionProvince = this.editShippingAddress.State;
        }
    };

    this.handleChangeFormCountry = () => {
        this.setAddressData();
        this.updateStateField();
    };

    this.onAddressFormChanged = (addressFormModel) => {
        this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
        this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
        this.addressFormModel.city = addressFormModel.city;
        this.addressFormModel.country = addressFormModel.country;
        this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
        this.addressFormModel.shipToName = addressFormModel.shipToName;
        this.editShippingAddress = {};
        this.editShippingAddress.ShipToName= this.addressFormModel.shipToName;
        this.editShippingAddress.LineOne= this.addressFormModel.addressLine1;
        this.editShippingAddress.LineTwo= this.addressFormModel.addressLine2;
        this.editShippingAddress.City= this.addressFormModel.city;
        this.editShippingAddress.State= this.addressFormModel.stateRegionProvince;
        this.editShippingAddress.PostalCode= this.addressFormModel.postalCode ? this.addressFormModel.postalCode : null;
        this.editShippingAddress.Id= undefined;

        if (addressFormModel.country !== this.editShippingAddress.Country) {
            this.editShippingAddress.Country = addressFormModel.country;
            this.handleChangeFormCountry();
        }
    };

    this.handleChangeDropDownAddress = () => {
        this.actions.setSelectedShippingAddress(this.state.selectedShippingAddress);
        this.editShippingAddress = this.state.selectedShippingAddress;
        this.updateStateField();
        this.updateAddressFormModel();
    };

    this.updateAddressFormModel = () => {
        if (!this.editShippingAddress) {
            return;
        }
        const selectedAddress = this.editShippingAddress;
        this.addressFormModel = Object.assign({}, selectedAddress, {
            addressLine1: selectedAddress.LineOne,
            addressLine2: selectedAddress.LineTwo,
            city: selectedAddress.City,
            country: selectedAddress.Country,
            postalCode: selectedAddress.PostalCode,
            shipToName: selectedAddress.ShipToName,
            stateRegionProvince: selectedAddress.State
        });
    };

    this.onSubmitChangeShippingAddressModal = () => {
        this.actions.setUpdatedShippingAddress(this.editShippingAddress);
        this.changeShippingAddressForm.$setSubmitted(true);
        this.showErrorSummary = true;
        if (this.changeShippingAddressForm.$valid) {
            this.updateSelectedShippingAddress()();
        }
    };

    this.onCloseModal = () => {
        this.actions.setSelectedShippingAddress(this.state.updatedShippingAddress);
        this.closeChangeShippingAddressPopup()();
    };

    this.$onDestroy = () => {
        this.disconnectRedux();
    };

}

export default {
    template: require('./change.shipping.address.popup.html'),
    bindings: {
        addressStatesOptions: '<',
        closeChangeShippingAddressPopup: '&',
        postalCode: '<',
        shippingAddressDropdownOptions: '<?',
        shippingAddress: '<?',
        updateSelectedShippingAddress: '&'
    },
    controller: ChangeShippingAddressPopupController
};

