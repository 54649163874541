import localeKeys from '../../../../../locales/keys';

class TransactionDetailsBraintreeMethodController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.braintree.method.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsBraintreeMethodController
};
