import {GIFT_CARD_ACCESS} from '../../security.attributes';

export const GIFT_CARD_DETAILS = 'index.customercare.giftcard.details';
export function routing($stateProvider) {
    $stateProvider
        .state('index.customercare.giftcard', {
            url: '/giftcard',
            abstract: true,
            template: '<ui-view></ui-view>'
        })
        .state(GIFT_CARD_DETAILS, {
            url: '/details/:giftCardId',
            securityAttributes: [GIFT_CARD_ACCESS],
            resolve: {
                giftCardIdIsValid: ($q, $stateParams) => {
                    const giftCardId = $stateParams.giftCardId;

                    if (giftCardId !== undefined && giftCardId.trim().length && !isNaN(giftCardId)) {
                        return $q.resolve();
                    } else {
                        return $q.reject({
                            redirectTo: 'index.customercare'
                        });
                    }
                }
            },
            template: '<gift-card class="u-sizeFull"></gift-card>'
        });
}

