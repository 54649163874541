import clone from 'ramda/src/clone';
import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import CustomerCareLocaleKeys from '../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {NUMBERS_ONLY_OR_BLANK} from 'invision-core/src/constants/validation.constants';
class TaxExemptionDetailsController {
    constructor($scope) {
        Object.assign(this, {
            $scope,
            CustomerCareLocaleKeys,
            NUMBERS_ONLY_OR_BLANK,
            emptyRow: {
                taxLevelCode: null,
                taxExemptionType: null,
                taxAssociationCode: null,
                addressStateProvinceRegionCode: null,
                overrideValue: null
            },
            validateNumbers
        });
    }

    $onInit() {
        this.onSelectionChangedHandler = this.onSelectionChanged() || angular.noop;
        this.$scope.$watch(() => {
            return this.ngModel.$modelValue;
        }, () => {
            this.initializeFieldModel();
        });

        this.initializeValidator();
    }

    initializeFieldModel() {
        if (this.ngModel.$modelValue) {
            const viewValue = this.ngModel.$modelValue.length !== 0 ?
                this.ngModel.$modelValue : [clone(this.emptyRow)];
            this.ngModel.$setViewValue(viewValue);
        }
    }

    initializeValidator() {
        this.ngModel.$validators.multiDropdownValid = (modelValue) => {
            return this.validateUnfinishedRows(modelValue) &&
                this.validateDuplicateRows(modelValue);
        };
    }


    validateUnfinishedRows(modelValue) {
        for (const item of modelValue) {
            if (this.rowNotComplete(item)) {
                return false;
            }
        }
        return true;
    }

    validateDuplicateRows(modelValue) {
        for (const item of modelValue) {
            if (this.rowNotEmpty(item) && this.duplicateRowExists(item)) {
                return false;
            }
        }
        return true;
    }

    rowNotComplete(dropdownOption) {
        if (!dropdownOption.taxLevelCode && !dropdownOption.taxExemptionType) {
            return false;
        } else {
            return !dropdownOption.taxLevelCode || !dropdownOption.taxExemptionType;
        }
    }
    rowNotEmpty(row) {
        return row.taxLevelCode !== null && row.taxExemptionType !== null;
    }
    taxTypeAndTaxLevelBothHaveValue(dropdownOption) {
        return dropdownOption.taxLevelCode && dropdownOption.taxExemptionType;
    }

    taxTypeAndTaxLevelCodeHaveNoValue(dropdownOption) {
        return !dropdownOption.taxLevelCode && !dropdownOption.taxExemptionType;
    }

    rowNotCompleteForTaxExemption(dropdownOption) {
        if (this.formCtrl.$submitted) {
            if (this.taxTypeAndTaxLevelCodeHaveNoValue(dropdownOption)) {
                return false;
            } else {
                return !dropdownOption.taxExemptionType;
            }
        } else {
            return false;
        }
    }

    rowNotCompleteForTaxLevel(dropdownOption) {
        if (this.formCtrl.$submitted) {
            if (this.taxTypeAndTaxLevelCodeHaveNoValue(dropdownOption)) {
                return false;
            } else {
                return !dropdownOption.taxLevelCode;
            }
        } else {
            return false;
        }
    }

    duplicateRowExists(dropdownOption) {
        const duplicateRowCount = this.ngModel.$viewValue.filter((row) => {
            if (this.taxTypeAndTaxLevelBothHaveValue(row) && this.taxTypeAndTaxLevelBothHaveValue(dropdownOption)) {
                return row.taxLevelCode.Id === dropdownOption.taxLevelCode.Id &&
                    row.taxExemptionType.Id === dropdownOption.taxExemptionType.Id &&
                    (row.taxAssociationCode && row.taxAssociationCode.Id) === (dropdownOption.taxAssociationCode && dropdownOption.taxAssociationCode.Id) &&
                    (row.addressStateProvinceRegionCode && row.addressStateProvinceRegionCode.Id) === (dropdownOption.addressStateProvinceRegionCode && dropdownOption.addressStateProvinceRegionCode.Id);
            }
            return false;
        });
        if (duplicateRowCount.length > 1) {
            this.addFlagForDuplicateCheck(duplicateRowCount);
        }
        return  duplicateRowCount.length > 1;
    }

    addFlagForDuplicateCheck(duplicateRows) {
        duplicateRows.forEach((row, index) => {
            if (index === 0) {
                row.firstDuplicateRow = true;
            } else {
                row.firstDuplicateRow = false;
            }
        });
    }

    validateModel(dropdownOption) {
        this.ngModel.$validate();
        this.onSelectionChangedHandler();
        if (dropdownOption && !this.taxTypeAndTaxLevelBothHaveValue(dropdownOption)) {
            dropdownOption.addressStateProvinceRegionCode = null;
            dropdownOption.overrideValue = null;
            dropdownOption.taxAssociationCode = null;
        }
    }

    getErrorMessage(dropdownOption) {
        if (this.shouldShowDuplicateError(dropdownOption)) {
            return i18n.translate(this.CustomerCareLocaleKeys.TAX_LABELS.DROPDOWN_SELECT_VALIDATIONS.ROW_DUPLICATE);
        }
        return '';
    }

    addAdditionalItem() {
        const ngModelValue = this.ngModel.$viewValue;
        ngModelValue.push(clone(this.emptyRow));
        this.ngModel.$setViewValue(ngModelValue);
        this.validateModel();
    }

    onDeleteClick(index) {
        const ngModelValue = this.ngModel.$viewValue;
        ngModelValue.splice(index, 1);
        this.ngModel.$setViewValue(ngModelValue);
        this.validateModel();
    }

    shouldHideDeleteButton(index) {
        return index === this.ngModel.$viewValue.length;
    }

    shouldShowDuplicateError(dropdownOption) {
        if (this.formCtrl.$submitted) {
            return this.duplicateRowExists(dropdownOption);
        }
    }

    removeIdentityVerification(index) {
        this.ngModel.$viewValue.splice(index, 1);
        this.validateModel();
    }
    fieldName(index, id) {
        return `${id}-${index}`;
    }
}
export default {
    bindings: {
        additionalItemPrompt: '<',
        addressStateProvinceRegionCodes: '<',
        onSelectionChanged: '&',
        taxAssociationCodes: '<',
        taxExemptionTypes: '<',
        taxLevelCodes: '<'
    },
    controller: TaxExemptionDetailsController,
    controllerAs: 'taxExemptionDetailsController',
    require: {
        formCtrl: '^form',
        ngModel: 'ngModel'
    },
    template: require('./tax.exemption.information.section.html')
};
