export const SEARCH_DROPDOWN_CONSTANTS = {
    INDIVIDUAL: 1,
    ADDRESS: 2,
    BUSINESS: 3,
    TRANSACTION: 4,
    GIFT_CARD: 5,
    COUPON: 6,
    COMPANY: 7,
    ADDITIONAL_PROPERTIES: 8
};

export const SEARCH_PAYMENT_INSTRUMENT_TYPES = {
    CREDIT_CARD: 0,
    ECHECK: 1,
    EXTERNAL_BILLER: 4,
    ITUNES: 6,
    GOOGLE_PLAY: 12,
    PAYPAL: 5,
    POINT_FOR_SALE: 20,
    UWP: 16
};

export const getSearchFieldsTemplate = () => {
    return {
        addressLineOne: undefined,
        companyName: undefined,
        deviceId: undefined,
        deviceSerialNumber: undefined,
        emailAddress: undefined,
        externalId: undefined,
        filterPaymentInstrumentType: undefined,
        firstName: undefined,
        hierarchyName: undefined,
        lastName: undefined,
        orderId: undefined,
        orderNumber: undefined,
        paymentAccount: undefined,
        paymentFirstFourDigits: undefined,
        paymentInstrumentSubType: undefined,
        paymentInstrumentSubTypeCreditCard: undefined,
        paymentInstrumentSubTypeExternalBiller: undefined,
        paymentLastFourDigits: undefined,
        paymentReconciliationId: undefined,
        paymentReferenceId: undefined,
        paymentTransactionDate: undefined,
        purchaseReceiptReference: undefined,
        phoneNumber: undefined,
        postalCode: undefined,
        subscriberAdditionalProperties: [],
        subscriberId: undefined,
        subscriberIdentification: undefined,
        title: undefined,
        returnUniqueSubscribersByTransaction: undefined
    };
};

export const TRANSACTION_RECEIPT_TAX_BREAKOUT_PROPERTY = 'transaction_receipt_tax_breakout';
export const FILTER_PAYMENT_INSTRUMENT_TYPE = 'filterPaymentInstrumentType';