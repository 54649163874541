import angular from 'angular';

import Routing from './services.and.usage.config';
import ServicesAndUsageComponent from './servicesAndSharedEntitlements/services.and.shared.entitlements.component';
import ServicesListComponent from './servicesAndSharedEntitlements/services/services.list.component';
import ServiceDetailsComponent from './serviceDetails/service.details.component';
import SharedEntitlementsComponent from './servicesAndSharedEntitlements/sharedEntitlements/shared.entitlements.component';

export default angular.module('invision.customercare.customer.servicesAndUsage', [])
    .config(Routing)
    .component('bulkServicesList', ServicesListComponent)
    .component('servicesAndUsage', ServicesAndUsageComponent)
    .component('serviceDetailsPage', ServiceDetailsComponent)
    .component('sharedEntitlements', SharedEntitlementsComponent)
    .name;
