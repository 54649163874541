import angular from 'angular';

import {briNameAppend} from '../steps/decisions/decisions.filters';
import {CheckoutComponent} from '../steps/checkout/checkout.component';
import {routing} from './new.connect.wizard.config';
import AttributesComponent from '../steps/attributes/attributes.component';
import CompareStepComponent from '../steps/compare/compare.component';
import CustomerComponent from '../steps/customer/customer.component';
import CustomerTabComponent from '../tabs/customerTab/customer.tab.component';
import DecisionsComponent from '../steps/decisions/decisions.component';
import EditDecisionOptionPopupComponent from '../steps/decisions/EditDecisionOptionPopup/edit.decision.option.popup.component';
import OverrideFinanceBriPopupComponent from '../steps/decisions/OverrideFinanceBriPopup/override.finance.bri.popup.component';
import EditCheckoutPopup from '../steps/checkout/editCheckoutPopup/edit.checkout.popup.component';
import ChangeShippingAddressPopup from '../steps/checkout/changeShippingAddressPopup/change.shipping.address.popup.component';
import MobileNumberPortabilityModule from '../steps/attributes/mobileNumberPortability/mobile.number.portability.module';
import NewConnectWizardComponent from './new.connect.wizard.component';
import OfferingQuickViewPopup from '../steps/offerings/quickView/quickView.popup.component';
import OfferingsComponent from '../steps/offerings/offerings.component';
import SaveOrderPopoutComponent from './saveOrderPopup/save.order.popup.component';
import SelectContractPopupComponent from '../steps/serviceability/selectContractPopup/select.contract.popup.component';
import ServiceabilityComponent from '../steps/serviceability/serviceability.component';
import SummaryTabComponent from '../tabs/summaryTab/summary.tab.component';

export default angular.module('invision.customercare.newconnect', [
    MobileNumberPortabilityModule
])
    .component('attributesStep', AttributesComponent)
    .component('checkoutStep', CheckoutComponent)
    .component('compareStep', CompareStepComponent)
    .component('customerStep', CustomerComponent)
    .component('decisionsStep', DecisionsComponent)
    .component('editDecisionOptionPopup', EditDecisionOptionPopupComponent)
    .component('editCheckoutPopup', EditCheckoutPopup)
    .component('changeShippingAddressPopup', ChangeShippingAddressPopup)
    .component('newConnect', NewConnectWizardComponent)
    .component('offeringQuickViewPopup', OfferingQuickViewPopup)
    .component('offeringsStep', OfferingsComponent)
    .component('orderCustomerTab', CustomerTabComponent)
    .component('orderSummaryTab', SummaryTabComponent)
    .component('overrideFinanceBriPopup', OverrideFinanceBriPopupComponent)
    .component('saveOrderPopup', SaveOrderPopoutComponent)
    .component('selectContractPopup', SelectContractPopupComponent)
    .component('serviceabilityStep', ServiceabilityComponent)
    .filter('briNameAppend', briNameAppend)
    .config(routing)
    .name;
