import {HOUSEHOLD_STATE_NAME} from '../household.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {CurrentSharedEntitlementBalancesSelector,
    IsFetchingSubscriberSummarySelector} from '../../../../reducers/selectors/customer.convergent.biller.selectors';

import {CurrentHouseholdSelector,
    CurrentHouseholdMemberSelector,
    HeadOfHouseholdSelector,
    HouseholdChildMembersSelector,
    HousholdMembersSorted} from '../../../../reducers/selectors/customer.household.selectors';
import {NA} from '../../../../reducers/constants/billing.payments.constants';
import {retrieveSharedEntitlements} from '../../../../reducers/actions/shared.entitlements.actions';

class OverviewController {
    constructor($ngRedux, $state) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
        this.NA = NA;
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                childMembers: HouseholdChildMembersSelector(store),
                currentHouseholdMembers: HousholdMembersSorted(store),
                currentMember: CurrentHouseholdMemberSelector(store),
                headOfHousehold: HeadOfHouseholdSelector(store),
                household: CurrentHouseholdSelector(store),
                isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
                isDbss: IsDbss(store),
                sharedEntitlementBalances: CurrentSharedEntitlementBalancesSelector(store),
                unitsOfMeasure: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.UnitsOfMeasure, store),
                unitsOfMeasureLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.UnitsOfMeasure, store)
            };
        };

        const controllerActions = {
            fetchUnitOfMeasureTypes: MetadataActions.codes.fetchUnitOfMeasureTypes,
            retrieveSharedEntitlements: retrieveSharedEntitlements
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareKeys = CustomerCareLocaleKeys;
        this.stateOrName = HOUSEHOLD_STATE_NAME;

        if (!this.state.sharedEntitlementBalances && this.state.headOfHousehold && this.state.isDbss) {
            this.actions.retrieveSharedEntitlements(this.state.headOfHousehold.SubscriberId);
        }

        if (!this.state.unitsOfMeasureLoaded) {
            this.actions.fetchUnitOfMeasureTypes();
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    isHeadOfHousehold(subscriberId) {
        return this.state.headOfHousehold.SubscriberId === subscriberId;
    }

    getMemberPrivilegesInfo(member) {
        return member.Privileges.MemberManagementEnabled ?
            `${i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.FULL_ACCESS_PRIVILEGES)})` :
            (member.Privileges.ManageServicesEnabled  ?
                `${i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.SERVICE_LEVEL_PRIVILEGES)})` :
                i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER));
    }

    formatTotal(initial, measure) {
        return i18n.translate(this.customerCareKeys.USAGE_DETAILS.TOTAL, {
            initialBalance: initial,
            unitOfMeasure: measure
        });
    }

    formatRemaining(remaining, measure) {
        return i18n.translate(this.customerCareKeys.USAGE_DETAILS.REMAINING, {
            BalanceRemaining: remaining,
            unitOfMeasure: measure
        });
    }

    determineConsumedValue(data) {
        if (data.InitialBalance && data.BalanceRemaining) {
            return Math.max(data.InitialBalance - data.BalanceRemaining, 0).toFixed(2);
        } else {
            return data.BalanceConsumed;
        }
    }

}

export default {
    template: require('./overview.html'),
    controllerAs: 'Overview',
    controller: OverviewController
};
