import __ from 'ramda/src/__';
import compose from 'ramda/src/compose';
import reduce from 'ramda/src/reduce';
import {
    handleResetWarnings,
    handleWarnings
} from 'invision-core/src/reducers/actions/warnings/warnings.actions';

export const listOfApisWithWarnings = [
    'SubscriberManagement/RetrieveConvergentBillerAccountDetails',
    'SubscriberManagement/RetrieveConvergentBillerAccountUsageDetails',
    'SubscriberManagement/RetrieveConvergentBillerServiceDetails',
    'SubscriberManagement/RetrieveConvergentBillerSubscriberAccounts',
    'SubscriberManagement/RetrieveConvergentBillerSubscriberDeposits',
    'SubscriberManagement/RetrieveConvergentBillerSubscriberSummary',
    'SubscriberManagement/RetrieveConvergentBillerSubscriberTreatmentDetails',
    'SubscriberManagement/RetrieveOfferingContext',
    'SubscriberManagement/RetrieveSubscriberFinanceDetails',
    'SubscriberManagement/RetrieveSubscriberOfferingDetail',
    'SubscriberManagement/SearchSubscriberOfferings'
];

export const registerWarningHandlers = reduce((warningObj, warningApis) => {
    warningObj[warningApis] = {
        warningHandler: handleWarnings,
        resetWarningHandler: handleResetWarnings,
        config: {
            apiListedForWarning: true
        }
    };
    return warningObj;
});

const warningHandlers = registerWarningHandlers(__, listOfApisWithWarnings);

export default compose(warningHandlers)({});
