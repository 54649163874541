import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../locales/keys';
import {findByValue} from 'invision-core/src/utilities/ramda.helper';
import {SCHEDULED_ORDER_OPTIONS} from '../../customercare.constants';

export const getNameByCountryCode = (languages, countryCode) => {
    if (languages && languages.length && countryCode) {
        const result = findByValue(countryCode, languages);
        return result ? result.Name : countryCode;
    }
};

export const getNavigableRoute = (pages) => {
    return pages[0].state || pages[0].subNavigation[0].state;
};

export function getExecutionOptionsErrorMessage(date, minDate, maxDate, specifiedDateFromPicker, modelOption) {
    if (!specifiedDateIsInvalid(date, minDate, maxDate, specifiedDateFromPicker, modelOption)) {
        return null;
    }
    return i18n.translate(
        date !== null && date < minDate ?
            CustomerCareKeys.SCHEDULING_OPTIONS.SPECIFIED_DATE_MIN_NEW_ORDER :
            date !== null && date > maxDate ?
                CustomerCareKeys.SCHEDULING_OPTIONS.SPECIFIED_DATE_MAX_NEW_ORDER :
                CustomerCareKeys.SCHEDULING_OPTIONS.SPECIFIED_DATE_EMPTY
    );
}

export function specifiedDateIsInvalid(date, minDate, maxDate, specifiedDateFromPicker, modelOption) {
    return modelOption === `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}` &&
        (date < minDate ||
        date > maxDate ||
        specifiedDateFromPicker === null);
}
