import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {USAGE_CAPS_URL} from '../../../../servicesAndUsage/usageCaps/usage.caps.constants';

class UsageChargesAndCapsController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            moreMenuItems: [{
                title: i18n.translate(CustomerLocaleKeys.USAGE_CAP_SETTINGS),
                action: () => {
                    return this.actions.stateGo(USAGE_CAPS_URL, {
                        serviceId: this.serviceValue
                    });
                }
            }]
        });
    }

    $onInit() {
        const controllerActions = {
            stateGo
        };
        const mapStateToProps = () => {
            return {};
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    onMoreMenuItemSelected({item: itemProps}) {
        itemProps.action(itemProps);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        currencyCode: '<',
        serviceValue: '<',
        totalCharge: '<',
        usageCaps: '<'
    },
    controller: UsageChargesAndCapsController,
    template: require('./usage.charges.and.caps.template.html')
};