import CustomerCareLocaleKeys from '../../locales/keys';
import {
    BusinessUnitSelectors,
    FormHelper
} from 'invision-core';
import pathOr from 'ramda/src/pathOr';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {
    CreateCustomerStateRegionProvinceValueOptions,
    CreateEditCustomerSelector,
    EnableProspectLiteSelector,
    IsCreatingCustomerSelector
} from '../../reducers/selectors/customer.selectors';
import {InvoiceDisplaySelector} from './customer.form.dropdown.selectors';
import {CUSTOMER_CATEGORY} from './create.customer.popup.constants';
import {PROSPECT_LITE_CUSTOMER} from '../shared/constants/customer.constants';
import {
    clearCreateEditCustomerForm,
    createCustomer,
    createEditCustomerFormChanged,
    setCustomerCategory,
    setEditAdditionalProperty,
    updateSubscriber
} from '../../reducers/actions/customer.actions';
import {updateOverrideFlag} from '../../reducers/actions/customer.addresses.actions';
import {
    getErrorLookupObject,
    getErrorIdentityVerifications
} from '../createCustomer/create.customer.constants';
import {apFormHasErrors} from '../main/customercare.page.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../reducers/actions/address.component.actions';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../shared/contextualComponents/address/address.validator.helper';
import {SubscriberAdditionalPropertiesSelector} from '../createCustomer/additionalPropertiesSection/additional.properties.filtered.selector';
import {PreventOverrideFlagSelector} from '../../reducers/selectors/customer.addresses.selectors';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../reducers/selectors/customer.information.selectors';

const mapStateToTarget = (store) => {
    return {
        createCustomer: CreateEditCustomerSelector(store),
        createCustomerDriversLicenseStateOptions: CreateCustomerStateRegionProvinceValueOptions(store),
        enableProspectLite: EnableProspectLiteSelector(store),
        formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
        invoiceDisplayOptions: InvoiceDisplaySelector(store),
        isCreatingCustomer: IsCreatingCustomerSelector(store),
        isDbss: BusinessUnitSelectors.IsDbss(store),
        preventOverrideFlag: PreventOverrideFlagSelector(store),
        subscriberAdditionalPropData: SubscriberAdditionalPropertiesSelector(store),
        unvalidatedAddresses: UnvalidatedAddressesSelector(store),
        validatedAddresses: ValidatedAddressesSelector(store)

    };
};

const controllerActions = {
    clearCreateEditCustomerForm,
    createCustomer,
    createEditCustomerFormChanged,
    setCustomerCategory,
    setEditAdditionalProperty,
    setForceShowEditFormAddress,
    updateOverrideFlag,
    updateSubscriber,
    validateAddress
};

class CreateCustomerPopupController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            apErrors: [],
            createCustomerFormErrors: [],
            disconnectRedux: null,
            previousAdditionalProperties: null
        });
    }

    $onInit() {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.subscriberAdditionalPropData && this.state.subscriberAdditionalPropData.length > 0) {
            this.previousAdditionalProperties = this.state.subscriberAdditionalPropData;
        }

        // Initialize the createCustomer store
        this.actions.setCustomerCategory(this.customerCategory);
        this.actions.createEditCustomerFormChanged(this.customer);

        this.handleClose = () => {
            if (this.onClose()) {
                this.onClose()();
            }
        };

        this.onChangedCreateCustomerForm = (customerModel) => {
            if (this.isLiteCustomer) {
                this.createCustomerFormWrapper.createCustomerForm.$submitted = true;
            }
            this.actions.createEditCustomerFormChanged(customerModel);
        };

        this.onSubmitCreateCustomerForm = () => {
            this.handleApErrors();
            this.createCustomerFormWrapper.createCustomerForm.$submitted = true;
            const ngFormErrors = this.createCustomerFormWrapper.createCustomerForm.$error;
            let createCustomerFormNonApErrors = FormHelper.formHasErrors(ngFormErrors, getErrorLookupObject());

            if (getErrorIdentityVerifications(this.createCustomerFormWrapper.createCustomerForm)) {
                createCustomerFormNonApErrors = createCustomerFormNonApErrors.concat([{
                    description: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SUBSCRIBER_VERIFICATION_INVALID)
                }]);
            }

            this.createCustomerFormErrors = createCustomerFormNonApErrors.concat(this.apErrors).filter(Boolean);

            if (!this.createCustomerFormErrors.length && !this.isSubmitDisabled()) {
                //TO-DO verify below works
                this.validateAddressWithAPI()
                    .then(() => {
                        const addressKey = this.getAddressKey();
                        const validatedAddress = this.state.validatedAddresses[addressKey];
                        if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                            this.state.createCustomer.Category = this.customerCategory;

                            const formAction = this.isCreatingCustomer() ? this.actions.createCustomer : this.actions.updateSubscriber;

                            return formAction(this.state.createCustomer, this.state.preventOverrideFlag)
                                .then((response) => {
                                    if (this.isLiteCustomer) {
                                        this.recalculateQuote && this.recalculateQuote()(this.state.createCustomer);
                                    }
                                    this.actions.updateOverrideFlag(null);
                                    this.onSuccess()(response);
                                }).catch((error) => {
                                    // Catch in the component due to needing to auto scroll the form to the top when api or ui errors
                                    this.createCustomerFormErrors.push(error.translatedMessage);

                                    this.createCustomerFormWrapper.highlightErrorMessages();
                                });
                        } else {
                            this.actions.setForceShowEditFormAddress(false);
                            this.createCustomerFormErrors.push({
                                translatedMessage: i18n.translate(this.CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                            });
                            this.createCustomerFormWrapper.highlightErrorMessages();
                        }
                    })
                    .catch((err) => {
                        this.createCustomerFormErrors.push(err.translatedMessage);
                        this.createCustomerFormWrapper.highlightErrorMessages();
                    });
            }
        };

        this.handleApErrors = () => {
            const apFormErrors = this.createCustomerFormWrapper.createCustomerForm.addlApi ? this.createCustomerFormWrapper.createCustomerForm.addlApi.$error : null;
            this.apErrors = apFormHasErrors(apFormErrors);
        };

        this.isCreatingCustomer = () => {
            return this.state.createCustomer.Id === undefined;
        };
    }

    getDialogTitle() {
        if (!this.isCreatingCustomer()) {
            return this.customerCareLocaleKeys.EDIT_CUSTOMER;
        } else if (this.customerCategory === CUSTOMER_CATEGORY.COMMERCIAL) {
            return this.customerCareLocaleKeys.CREATE_NEW_COMMERCIAL_CUSTOMER;
        } else if (this.customerCategory === CUSTOMER_CATEGORY.RESIDENTIAL) {
            return this.customerCareLocaleKeys.CREATE_NEW_RESIDENTIAL_CUSTOMER;
        } else {
            return this.customerCareLocaleKeys.CREATE_NEW_CUSTOMER;
        }
    }

    isSubmitDisabled() {
        return this.state.isCreatingCustomer || (this.state.isDbss && !this.state.createCustomer.SubscriberCurrency);
    }

    getAddressKey() {
        return pathOr(undefined, ['createCustomerFormWrapper', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['createCustomerFormWrapper', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    get overrideValidations() {
        return this.isLiteCustomer ? false : (this.customerState !== undefined ?
            convertStringToNumber(this.customerState) === PROSPECT_LITE_CUSTOMER : this.state.enableProspectLite);
    }

    validateAddressWithAPI() {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = addressKey && this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    $onDestroy() {
        if (this.previousAdditionalProperties && this.previousAdditionalProperties.length > 0) {
            this.actions.setEditAdditionalProperty(this.previousAdditionalProperties);
        } else {
            // clear out the last batch of data so we start with a fresh slate
            this.actions.clearCreateEditCustomerForm();
        }
        this.disconnectRedux();
    }
}

export default {
    template: require('./create.customer.popup.html'),
    controller: CreateCustomerPopupController,
    controllerAs: 'CreateCustomerPopup',
    bindings: {
        config: '<',
        customer: '<',
        isCheckoutStep: '<?',
        isCustomerTypeReadOnly: '<?',
        customerCategory: '<',
        customerState: '<?',
        editCustomerMessage: '<?',
        recalculateQuote: '&?',
        invoiceDisplayOptions: '<',
        isLiteCustomer:'<?',
        onClose: '&',
        onSuccess: '&'
    }
};
