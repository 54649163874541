import i18n from 'invision-core/src/components/i18n/i18n';
import localeKeys from '../../../../locales/keys';
import {
    resetGroupedEntitlementsModalStore,
    retrieveEntitlementBalances,
    setGroupedEntitlementsSelectedTab
} from '../../../../reducers/actions/grouped.entitlements.modal.actions';
import {
    GroupedEntitlementsSelectedTabSelector,
    GroupEntitlementsWithFormattedServiceAttributeSelector,
    IsLoadingGroupedEntitlementsSelector
} from '../../../../reducers/selectors/grouped.entitlements.modal.selectors';
import {
    ENTITLEMENT_SHARE_STATUS,
    UNIT_OF_MEASURES
} from '../../../../customercare.constants';
import {GROUPED_ENTITLEMENTS_MODAL_TABS} from '../../../../reducers/grouped.entitlements.modal.reducer';
import {updateEntitlementShareStatus} from '../../../../reducers/actions/shared.entitlements.actions';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';

class GroupedEntitlementsModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            ENTITLEMENT_SHARE_STATUS,
            GROUPED_ENTITLEMENTS_MODAL_TABS,
            localeKeys,
            onSharedActionClicked: this.onSharedActionClicked.bind(this),
            onTabSelected: this.onTabSelected.bind(this),
            selectedTab: null,
            tabs: [{
                id: GROUPED_ENTITLEMENTS_MODAL_TABS.AVAILABLE_FOR_PAYMENT,
                name: i18n.translate(localeKeys.AVAILABLE_FOR_PAYMENT),
                active: true
            }, {
                id: GROUPED_ENTITLEMENTS_MODAL_TABS.AVAILABLE_FOR_USAGE,
                name: i18n.translate(localeKeys.AVAILABLE_FOR_USAGE),
                active: false
            }],
            uiNotificationService,
            UNIT_OF_MEASURES
        });
    }

    $onInit() {
        this.selectedTab = this.tabs[0];
        const controllerActions = {
            resetGroupedEntitlementsModalStore,
            retrieveEntitlementBalances,
            setGroupedEntitlementsSelectedTab,
            updateEntitlementShareStatus
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isLoading: IsLoadingGroupedEntitlementsSelector(store),
                entitlements: GroupEntitlementsWithFormattedServiceAttributeSelector(store),
                selectedTab: GroupedEntitlementsSelectedTabSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.retrieveEntitlementBalances();
    }

    retrieveEntitlementBalances() {
        this.actions.retrieveEntitlementBalances(
            this.entitlementGroup.EntitlementIdentifier.GroupCode,
            this.serviceIdentifier
        ).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onTabSelected(selectedTab) {
        this.actions.setGroupedEntitlementsSelectedTab(selectedTab.id);
        this.tabs = this.tabs.map((tab) => {
            tab.active = selectedTab === tab;
            return tab;
        });
    }

    onSharedActionClicked(entitlement) {
        const newStatus = entitlement.SharingStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
            ? ENTITLEMENT_SHARE_STATUS.DISABLED
            : ENTITLEMENT_SHARE_STATUS.ENABLED;
        this.actions.updateEntitlementShareStatus(this.state.currentCustomerId, entitlement.EntitlementIdentifier, newStatus).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(
                newStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
                    ? localeKeys.SHARING_ACTIONS.ENABLE_SUCCESS
                    : localeKeys.SHARING_ACTIONS.DISABLE_SUCCESS));
            this.retrieveEntitlementBalances();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onDestroy() {
        this.actions.resetGroupedEntitlementsModalStore();
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        onClose: '&',
        entitlementGroup: '<',
        currencyCode: '<',
        serviceIdentifier: '<?',
        showTotalUsed: '<?'
    },
    controller: GroupedEntitlementsModalController,
    template: require('./grouped.entitlements.modal.template.html')
};