import {
    clone,
    equals
} from 'ramda';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    ACCEPTING_FOR,
    CONSENT_TYPES
} from '../../createCustomerPopup/create.customer.popup.constants';
import {NOTIFICATION_TIME_LENGTH, REGEXES} from '../../../customercare.constants';
import {
    EMAIL_MAX_LENGTH
} from '../create.customer.constants';
import {
    sendConsentTerms,
    setIsConsentTermsSent
} from '../../../reducers/actions/customer.actions';
import {SelectedCustomerIsConsentTermsSentSelector} from '../../../reducers/selectors/customer.selectors';

const controllerActions = {
    sendConsentTerms,
    setIsConsentTermsSent
};

class ConsentInformationSectionController {
    constructor($ngRedux, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.AcceptingFor = ACCEPTING_FOR;
        this.ConsentTypes = CONSENT_TYPES;
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.data = {
            sendCopyOfTermsEmailChanged: false,
            sendCopyOfTermsEmailRequired: false
        };
        this.EMAIL_MAX_LENGTH = EMAIL_MAX_LENGTH;
        this.EMAIL_REGEX = REGEXES.EMAIL;
        this.isExpanded = false;
    }

    $onChanges(changesObj) {
        if (changesObj.consents) {
            if (!equals(changesObj.consents.currentValue, changesObj.consents.previousValue)) {
                this.data.consents = clone(changesObj.consents.currentValue);
            }
        }
    }

    $onDestroy() {
        if (this.state.isConsentTermsSent) {
            this.actions.setIsConsentTermsSent(null);
        }
        this.disconnectRedux();
    }

    $onInit() {
        this.displayConsentType = this.displayConsentType || this.ConsentTypes.MANDATORY;

        const mapStateToTarget = (store) => {
            return {
                isConsentTermsSent: SelectedCustomerIsConsentTermsSentSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    handleFormChanged() {
        this.onFormChanged()(this.customerModel);
    }

    handleMandatoryConsentAcceptance() {
        this.customerModel.ConsentAcceptingMandatoryTerms.Terms = this.data.consents;
        this.handleFormChanged();
    }

    handleOptionalConsentAcceptance(consent) {
        consent.acceptRejectDate = new Date();
        const acceptedRejectedConsents = this.data.consents.filter(c => {
            return (c.isAccepted === true || c.isAccepted === false);
        });
        this.customerModel.ConsentAcceptingOptionalTerms = acceptedRejectedConsents;
        this.handleFormChanged();
    }

    handleSendCopyOfTermsChanged() {
        this.data.sendCopyOfTermsEmailChanged = true;
        this.data.sendCopyOfTermsEmailRequired = false;
    }

    hasError(fieldName) {
        if ('sendCopyOfTerms' === fieldName) {
            return this.data.sendCopyOfTermsEmailRequired && !this.customerModel.ConsentSendCopyOfTerms;
        }
        return false;
    }

    get isLegalGuardianEmailRequired() {
        return (this.customerModel.ConsentAcceptingFor === this.AcceptingFor.CHILD);
    }

    sendCopyOfTerms() {
        if (this.customerModel.ConsentSendCopyOfTerms) {
            this.data.sendCopyOfTermsEmailRequired = false;
            this.actions.sendConsentTerms(this.customerModel.ConsentSendCopyOfTerms, this.customerModel.HomeCountry).catch(err => {
                this.uiNotificationService.error(err.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
            this.data.sendCopyOfTermsEmailChanged = false;
        } else if (this.customerModel.ConsentSendCopyOfTerms !== undefined) {
            this.data.sendCopyOfTermsEmailRequired = true;
        }
    }

    toggleConsents() {
        this.isExpanded = !this.isExpanded;
    }
}

export default {
    template: require('./consent.information.section.html'),
    bindings: {
        customerModel:'<',
        consents: '<',
        displayConsentType: '<',
        isFormSubmitted: '<',
        onFormChanged: '&'
    },
    controller: ConsentInformationSectionController,
    controllerAs: 'ConsentInformationSection'
};
