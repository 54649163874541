import clone from 'ramda/src/clone';
import CareLocaleKeys from '../../../../../../locales/keys';
import {TAX_LOCATION_CUSTOM_OPTIONS} from '../../../../../../customercare.constants';
import {CurrentAddressesSelector} from '../../../../../../reducers/selectors/customer.addresses.selectors';
import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';
import {updateCustomizedServiceTax} from '../../../../../../reducers/actions/services.actions';
import {CurrentCustomerIdSelector} from '../../../../../../reducers/selectors/customer.selectors';

class ServiceTaxDetailsController {
    constructor($ngRedux,) {
        Object.assign(this, {
            $ngRedux,
            CareLocaleKeys,
            getFormattedAddress: this.getFormattedAddress.bind(this),
            TAX_LOCATION_CUSTOM_OPTIONS
        });
    }

    $onInit() {
        const controllerActions = {
            updateCustomizedServiceTax
        };
        const mapStateToProps = (state) => {
            return {
                currentAddresses: CurrentAddressesSelector(state),
                currentCustomerId: CurrentCustomerIdSelector(state)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onChanges(changesObj) {
        if (changesObj.serviceTaxDetails.currentValue) {
            this.originatingPercentage = this.serviceTaxDetails.OriginatingPercentage && this.serviceTaxDetails.OriginatingPercentage < 1 ?
                parseInt(this.serviceTaxDetails.OriginatingPercentage * 100) :
                null;
            this.remainingPercentage = this.originatingPercentage ? 100 - this.originatingPercentage : 100;
        }
    }

    getFormattedAddress(addressId) {
        return this.state.currentAddresses.length ? AddressHelper.createBillingAddressString(
            clone(this.state.currentAddresses
                .find((thisAddress) => {
                    return thisAddress.Id === addressId;
                })
            )
        ) : '';
    }
}

export default {
    bindings: {
        serviceTaxDetails: '<'
    },
    template: require('./service.tax.details.template.html'),
    controller: ServiceTaxDetailsController,
    controllerAs: 'ServiceTaxDetailsController'
};