import {
    SERVICE_FEATURE_ORDER_WIZARD_MODE,
    PRODUCT_ORDER_WIZARD_MODE,
    CREATE_PRODUCTS_ORDER_STATE_NAME,
    CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME,
    MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME
} from '../../../../../reducers/constants/products.wizard.constants';
import {ORDERING_ACCESS} from './../../../../../security.attributes';
import LocaleKeys from '../../../../../locales/keys';

export default function CreateProductsOrderWizardConfig($stateProvider, $provide) {
    $stateProvider
        .state(CREATE_PRODUCTS_ORDER_STATE_NAME, {
            url: '/createProductsOrder',
            ignoreBack: true,
            template: '<products-wizard></products-wizard>',
            params: {
                mode: PRODUCT_ORDER_WIZARD_MODE.CREATE,
                productsOnly: true,
                subscriptionsOnly: false
            },
            adminSecurityAttributes: [ORDERING_ACCESS]
        })
        .state(MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME, {
            url: '/serviceInstanceId/:serviceInstanceId/service/:serviceTypeId/serviceId/:serviceId/inventoryCategoryCode/:inventoryCategoryCode?{inventoryName}',
            ignoreBack: true,
            template: '<products-wizard></products-wizard>',
            params: {
                mode: SERVICE_FEATURE_ORDER_WIZARD_MODE.MANAGE,
                productsOnly: true,
                subscriptionsOnly: false,
                service: null
            },
            adminSecurityAttributes: [ORDERING_ACCESS]
        })
        .state(CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME, {
            url: '/createSubscriptionsOrder',
            ignoreBack: true,
            template: '<products-wizard></products-wizard>',
            params: {
                mode: PRODUCT_ORDER_WIZARD_MODE.CREATE,
                productsOnly: false,
                subscriptionsOnly: true
            },
            adminSecurityAttributes: [ORDERING_ACCESS]
        });


    $provide.decorator('formErrors', ($delegate) => {
        $delegate.addErrorMessageLocaleKey('invalidSelectableItems', LocaleKeys.SELECTABLE_ITEMS.INVALID);
        $delegate.addErrorMessageLocaleKey('doPaymentInstrumentsCoverOrderTotal', LocaleKeys.WIZARD.VALIDATIONS.DO_PAYMENT_INSTRUMENTS_COVER_ORDER_TOTAL);
        $delegate.addErrorMessageLocaleKey('isPaymentInstrumentSelected', LocaleKeys.WIZARD.VALIDATIONS.IS_PAYMENT_INSTRUMENT_SELECTED);
        $delegate.addErrorMessageLocaleKey('isPaymentInstrumentExpirationValid', LocaleKeys.WIZARD.VALIDATIONS.IS_PAYMENT_INSTRUMENT_EXPIRED);
        $delegate.addErrorMessageLocaleKey('isPaymentInstrumentInactive', LocaleKeys.WIZARD.VALIDATIONS.IS_PAYMENT_INSTRUMENT_INACTIVE);
        $delegate.addErrorMessageLocaleKey('isShippingAddressSelected', LocaleKeys.WIZARD.VALIDATIONS.IS_SHIPPING_ADDRESS_SELECTED);
        $delegate.addErrorMessageLocaleKey('invalidOrderScheduledForDate', LocaleKeys.SCHEDULING_OPTIONS.SPECIFIED_DATE_EMPTY);
        $delegate.addErrorMessageLocaleKey('invalidBillingEffectiveDate', LocaleKeys.SCHEDULING_OPTIONS.BILLING_EFFECTIVE_DATE.BILLING_EFFECTIVE_DATE_EMPTY);
        return $delegate;
    });
}
