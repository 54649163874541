import localeKeys from '../../../../../locales/keys';
class DisconnectContractItemController {
    constructor() {
        Object.assign(this, {
            localeKeys
        });
    }
}

export default {
    template: require('./disconnect.contract.item.html'),
    bindings: {
        contractName: '<',
        currencyCode: '<',
        editEtf: '&',
        effectiveEndDate: '<',
        etfAmount: '<',
        hadEtf: '<',
        hasEtfOverrideAccess: '<',
        minimumSpendAmount: '<',
        termAndCondition: '&'
    },
    controller: DisconnectContractItemController,
    controllerAs: 'DisconnectContractItemController'
};
