import {
    clone,
    isNil,
    pathOr,
    propEq
} from 'ramda';

import {
    PermissionService,
    SessionSelectors
} from 'invision-core';

import {clearEWalletError} from '../../reducers/actions/customer.ewallet.actions';
import {
    SelectedPaymentMethodSelector,
    PaymentInstrumentsWithTypeNamesViewModel
} from '../../reducers/selectors/customer.ewallet.selectors';
import {
    isEditablePaymentInstrumentType,
    isLockedPaymentInstrument
} from '../../reducers/helpers/ewallet.reducer.helper';
import {
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../customer/ewallet/ewallet.constants';

import {BLANK_PAYMENT_INSTRUMENT, PAYMENT_METHOD_STATUSES} from './payment.method.switcher.constants';

import LocaleKeys from '../../locales/keys';

import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';

import {
    E_WALLET_ADMIN_ACCESS,
    PAYMENT_INSTRUMENT_ADMIN_ACCESS
} from '../../security.attributes';
import {
    CurrentHouseholdSelector,
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector,
    CurrentMemberPrivilegesSelector
} from '../../reducers/selectors/customer.household.selectors';
import {IsPIIDataAccessEnabledSelector} from '../../reducers/selectors/customer.selectors';

class PaymentMethodSwitcherController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            currentInstrumentForEditing: null,
            filterAutopay: false,
            isEditingPaymentInstrument: false,
            LocaleKeys,
            showPaymentInstrumentForm: false,
            showSavePaymentInstrument: false,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentHousehold: CurrentHouseholdSelector(store),
                currentMemberHasCreatePaymentInstrumentPermissions: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currentMemberPrivileges: CurrentMemberPrivilegesSelector(store),
                selectedPaymentMethod: SelectedPaymentMethodSelector(store),
                unfilteredPaymentInstruments: PaymentInstrumentsWithTypeNamesViewModel(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store)
            };
        };

        const controllerActions = {
            clearEWalletError,
            fetchCodeTypes
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.LocaleKeys = LocaleKeys;

        this.changePaymentPopupConfig = {
            onRegisterApi: (event) => {
                this.changePaymentPopupApi = event.api;
            }
        };

        this.createOrEditPaymentMethodPopupConfig = {
            onRegisterApi: (event) => {
                this.createOrEditPaymentMethodPopupApi = event.api;
            }
        };

        this.closeChangePaymentMethodPopup = () => {
            if (this.changePaymentPopupApi.close) {
                this.changePaymentPopupApi.close();
            }

            this.actions.clearEWalletError();
            this.showChangePaymentPopup = false;
        };

        this.closeCreateOrEditPaymentMethodPopup = () => {
            if (this.createOrEditPaymentMethodPopupApi.close) {
                this.createOrEditPaymentMethodPopupApi.close();
            }

            this.actions.clearEWalletError();
            this.isEditingPaymentInstrument = false;
            this.showPaymentInstrumentForm = false;
        };

        this.onChangePaymentMethodComplete = (paymentInstrument) => {
            this.closeChangePaymentMethodPopup();


            this.onChange()(paymentInstrument);
        };

        this.onCreateOrEditPaymentMethodComplete = (paymentInstrument) => {
            if (this.isEditingPaymentInstrument) {
                this.onEdit()(paymentInstrument);
            } else {
                this.onNew()(paymentInstrument);
            }

            this.closeCreateOrEditPaymentMethodPopup();
        };
    }

    $onDestroy() {
        this.closeChangePaymentMethodPopup();
        this.closeCreateOrEditPaymentMethodPopup();

        this.disconnectRedux();
    }

    hasChangePaymentInstrument() {
        return typeof(this.canChangePaymentInstrument) !== 'undefined' ? this.canChangePaymentInstrument : true;
    }

    hasPaymentInstrumentAdminAccess() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, PAYMENT_INSTRUMENT_ADMIN_ACCESS);
    }

    hasEwalletAdminAccess() {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, E_WALLET_ADMIN_ACCESS);
    }

    getCurrentPaymentInstrument() {
        if (this.state.unfilteredPaymentInstruments && this.currentInstrumentId) {
            const match = this.state.unfilteredPaymentInstruments.find(propEq(this.currentInstrumentId, 'Id'));
            return match ? match : this.state.selectedPaymentMethod;
        }
        return null;
    }

    isCurrentPaymentInFilteredList() {
        if (this.currentInstrumentId) {
            return this.paymentInstruments.find(propEq(this.currentInstrumentId, 'Id'));
        }
        return false;
    }

    hasMultiplePaymentInstruments() {
        return pathOr(0, ['length'], this.paymentInstruments) > 1 ||
               (pathOr(0, ['length'], this.paymentInstruments) > 0 && !this.isCurrentPaymentInFilteredList());
    }

    hasAvailablePaymentInstrument() {
        return pathOr(0, ['length'], this.paymentInstruments) > 0 && !this.getCurrentPaymentInstrument();
    }

    currentInstrumentIsLocked() {
        return isLockedPaymentInstrument(this.getCurrentPaymentInstrument());
    }

    showEdit() {
        const currentPaymentInstrument = this.getCurrentPaymentInstrument();
        const isRemoved = currentPaymentInstrument && (currentPaymentInstrument.Status === PAYMENT_METHOD_STATUSES.REMOVED);
        return !this.isReadOnly &&
            !isRemoved &&
            this.showNew() &&                     // Edit follows same permission rules as New,
            currentPaymentInstrument && // plus need to account for missing PI,
            !this.currentInstrumentIsLocked() &&  // and needs to account for locked PI,
            isEditablePaymentInstrumentType(currentPaymentInstrument.Type) && // and whether the PI type is editable,
        // including EGCs which are only editable if they have an address
            (String(currentPaymentInstrument.Type) !== SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD || currentPaymentInstrument.BillingAddress) &&
            (this.state.isPIIDataAccessEnabled);
    }

    showNew() {
        return !this.isReadOnly &&
            this.hasEwalletAdminAccess() &&
            this.hasPaymentInstrumentAdminAccess() &&
            this.canCreatePaymentInstruments() &&
            (this.state.isPIIDataAccessEnabled);
    }

    showLocked() {
        return this.currentInstrumentIsLocked();
    }

    showChange() {
        return !this.isReadOnly && this.hasMultiplePaymentInstruments() && this.hasChangePaymentInstrument();
    }

    showFirstDiv() {
        return (this.showEdit() || this.showLocked()) && (this.showChange() || this.showNew());
    }

    showSecondDiv() {
        return this.showChange() && this.showNew();
    }

    openCreatePaymentMethodPopup() {
        this.setupEditPaymentInstrument(BLANK_PAYMENT_INSTRUMENT);

        this.showPaymentInstrumentForm = true;

        this.$timeout(() => {
            this.createOrEditPaymentMethodPopupApi.open();
        });
    }

    openEditPaymentMethodPopup() {
        this.setupEditPaymentInstrument(this.getCurrentPaymentInstrument());

        this.showPaymentInstrumentForm = true;
        this.isEditingPaymentInstrument = true;

        this.$timeout(() => {
            this.createOrEditPaymentMethodPopupApi.open();
        });
    }

    openChangePaymentMethodPopup() {
        this.showChangePaymentPopup = true;

        this.$timeout(() => {
            this.changePaymentPopupApi.open();
        });
    }

    setupEditPaymentInstrument(paymentInstrument) {
        this.currentInstrumentForEditing = clone(paymentInstrument);

        if (this.currentInstrumentForEditing.CreditCard && !isNil(this.currentInstrumentForEditing.CreditCard.Type)) {
            this.currentInstrumentForEditing.CreditCard.Type = Number.parseInt(this.currentInstrumentForEditing.CreditCard.Type, 10);
        }
    }

    /*
        This function was debated over a few times as to whether it should be included in the component, or
        in the consumers of the component. From a hipchat conversation with Kelly, she said it was safe to assume
        that everywhere that is using the switcher will have to take into account households.

        The decision was made that this is an ok place for the logic to exist right now.

        At the same time, it was realized that it might need to be changed if future scenarios discover a
        case where household needs to not be included.
    */
    canCreatePaymentInstruments() {
        if (this.state.currentHousehold) {
            return this.state.currentMemberHasCreatePaymentInstrumentPermissions;
        } else {
            return true;
        }
    }
}

export default {
    template: require('./payment.method.switcher.html'),
    bindings: {
        canChangePaymentInstrument: '<',
        currentInstrumentId: '<',
        filterAutopay: '<?',
        hasAdditionalInstruments: '<?', //This binding supports the one-off instances where the switcher needs to display additional messaging if other payment instruments exist but have all been used,
        isReadOnly: '<',
        onChange: '&',
        onEdit: '&',
        onNew: '&',
        paymentInstruments: '<',
        showSavePaymentInstrument: '<?'
    },
    controllerAs: 'PaymentMethodSwitcherController',
    controller: PaymentMethodSwitcherController
};
