import {
    captureResponseRequest,
    executeWebContainerRequest
} from '../../../../reducers/actions/next.best.action.actions';
import {
    SessionSelectors
} from 'invision-core';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';

// NOTE: This is code for a DEMO!  It should not be used as an example for other development work.
class NBAWidgetController {
    constructor($state, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $state,
            $ngRedux,
            $timeout,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (state) => {
            return {
                user: SessionSelectors.UserSelector(state)
            };
        };

        const controllerActions = {
            getNextBestAction: executeWebContainerRequest,
            captureResponseRequest: captureResponseRequest
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.isExpanded = false;
        this.onOffersPage = false;
        this.isFetchingOffers = false;
        this.pageSwitch = () => {
            if (!this.onOffersPage) {
                this.isFetchingOffers = true;
                this.offerOptions.length = 0;
                this.actions.getNextBestAction(this.externalReference, this.intent, this.downgradeSelections).then((data) => {
                    Array.prototype.push.apply(this.offerOptions, data);
                    this.isFetchingOffers = false;
                });
            }
            this.onOffersPage = !this.onOffersPage;
        };
        this.clearForm = () => {
            this.intent = 'up';
            this.downgradeSelections = [];
        };
        this.onSelect = (offer) => {
            offer.Outcome = 'Accepted';
            this.actions.captureResponseRequest(this.externalReference, this.state.user.login, this.offerOptions).then( () => {
                this.uiNotificationService.success(`${offer.OfferName} offer has been selected!`, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
            this.clearForm();
            this.onOffersPage = !this.onOffersPage;
            this.isExpanded = false;
        };
        this.behaviourOptions =['Positive', 'Neutral', 'Negative'];
        this.offerOptions = [];
        this.tableData = {
            columnDefs: [{
                field: 'OfferName',
                displayName: 'Offer Name',
                width: 220,
                resizable: false,
                enableSorting: true,
                enableColumnMoving: false
            }, {
                field: 'Group',
                displayName: 'Group',
                width: 105,
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false
            }, {
                field: 'ShortDescription',
                displayName: 'Short Description',
                width: 255,
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false
            }, {
                cellTemplate: require('./behaviour.cell.template.html'),
                field: 'Behaviour',
                displayName: 'Behaviour',
                minWidth: 150,
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false
            }, {
                cellTemplate: require('./outcome.cell.template.html'),
                field: 'Outcome',
                displayName: 'Outcome',
                minWidth: 150,
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false
            }],
            data : this.offerOptions
        };
        this.intent = 'up';
        this.isUpgrade = () => {
            return this.intent === 'up';
        };
        this.isDowngrade = () => {
            return this.intent === 'down';
        };
        this.isMoving = () => {
            return this.intent === 'move';
        };
        this.downgradeSelections = [];
        this.downgradeReasons = ['Sports', 'HD', 'HBO'];
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    allowCreate(searchValue) {
        return !this.downgradeReasons.find((option) => {
            return option === searchValue;
        });
    }
}

export default {
    template: require('./next.best.action.html'),
    bindings: {
        externalReference: '<'
    },
    controller: NBAWidgetController,
    controllerAs: 'NBAWidget'
};