import angular from 'angular';

import routing from './transfer.wizard.config';
import DestinationCustomerInfoComponent from '../../../wizards/steps/transferConfiguration/destinationCustomerInfo/destination.customer.info.component';
import SelectDestinationCustomerDialogComponent from './selectDestinationCustomer/select.destination.customer.dialog.component';
import TransferConfigurationStepComponent from '../../../wizards/steps/transferConfiguration/transfer.configuration.component';
import TransferWizardComponent from './transfer.wizard.component';

export default angular.module('index.customercare.customer.orders.transfer', [])
    .component('destinationCustomerInfo', DestinationCustomerInfoComponent)
    .component('selectDestinationCustomerDialog', SelectDestinationCustomerDialogComponent)
    .component('transfer', TransferWizardComponent)
    .component('transferConfigStep', TransferConfigurationStepComponent)
    .config(routing)
    .name;
