import angular from 'angular';
import 'invision-core';

import MemberDetailsComponent from './member.details.component';

export default angular.module('invision.customercare.customer.household.memberDetails', [
    'invision.ui.components.openNewWindow'
])
    .component('memberDetails', MemberDetailsComponent)
    .name;
