import CustomerCareKeys from '../../../../../../locales/keys';

class DevicePaymentItemLabelController {
    constructor() {
        Object.assign(this, {
            customerCareKeys: CustomerCareKeys
        });
    }
}

export default {
    template: require('./device.payment.item.label.html'),
    controller: DevicePaymentItemLabelController,
    bindings: {
        item: '<'
    }
};
