class RecentActivityController {}

export default {
    template: require('./recent.activity.html'),
    bindings: {
        activity: '<'
    },
    controller: RecentActivityController,
    controllerAs: 'RecentActivity'
};
