import customerCareKeys from '../../../../locales/keys';
import {ENTITLEMENT_CONTEXT_TYPES} from '../../../../customercare.constants';
class EntitlementUsageChartController {
    constructor($timeout) {
        Object.assign(this, {
            $timeout,
            customerCareKeys,
            ENTITLEMENT_CONTEXT_TYPES,
            isGroupModalOpen: false
        });
    }
    $onInit() {
        this.groupedEntitlementsPopupConfig = {
            onRegisterApi: (event) => {
                this.groupedEntitlementsPopupApi = event.api;
            }
        };
    }
    openEntitlementsModal(event) {
        event.stopPropagation();
        this.isGroupModalOpen = true;
        this.$timeout(() => {
            this.groupedEntitlementsPopupApi.open();
        });
    }
    closeGroupEntitlementModal() {
        this.groupedEntitlementsPopupApi.close();
        this.isGroupModalOpen = false;
    }
}

export default {
    bindings: {
        currencyCode: '<?',
        entitlement: '<',
        hideChartLegend: '<?',
        serviceIdentifier: '<?',
        showExpirationDate: '<?',
        showTotalUsed: '<?'
    },
    controller: EntitlementUsageChartController,
    template: require('./entitlement.usage.chart.template.html')
};