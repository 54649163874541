import {RouteParams} from '../../reducers/selectors/customer.selectors';
import {DASHBOARD_ROUTE} from '../../reducers/constants/dashboard.constants';
import {retrieveCustomerByExternalReference} from './acsrCustomerSwitch.actions';
import {ROOT_CARE_MODULE_ROUTE} from '../../customercare.constants';

export class AcsrCustomerSwitchController {
    constructor($state, $ngRedux, uiNotificationService) {
        Object.assign(this, {
            $state,
            $ngRedux,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                routeParams: RouteParams(store),
            };
        };

        const controllerActions = {
            retrieveCustomerByExternalReference
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.loadAcsrCustomerAndRedirect();
    }

    loadAcsrCustomerAndRedirect() {
        this.actions.retrieveCustomerByExternalReference(this.state.routeParams.externalReference).then((response) => {
            this.$state.go(DASHBOARD_ROUTE, {
                customerId: response.Subscriber.Id
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
            this.$state.go(ROOT_CARE_MODULE_ROUTE);
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: AcsrCustomerSwitchController,
    template: require('./acsrCustomerSwitch.html')
};
