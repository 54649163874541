class SubscriptionBundleController {
}

export default {
    controller: SubscriptionBundleController,
    template: require('./subscription.bundle.html'),
    bindings: {
        item: '<',
        moreOptions: '<',
        userCanRemove: '<',

        onMoreOptionClicked: '&',
        onRemoveDiscount: '&',
        subscription: '<',
    }
};
