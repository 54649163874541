import {
    clamp,
    find,
    groupBy,
    pathOr,
    pluck,
    propEq,
    propOr
} from 'ramda';

import {i18n} from 'invision-core';

import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {CompletedDecisionsSelector,
    DecisionsRequiringAffectedServicesResolutionMappedForSelectedPageSelector,
    PagesIsFetchingDecisionOptionServiceIdsSelector} from '../../../../../reducers/selectors/selected.offering.order.selectors';

import {retrieveDecisionOptionServiceIds} from '../../../../../reducers/actions/edit.offer.wizard.actions';

import CustomerCareLocaleKeys from '../../../../../locales/keys';

class SelectAffectedServicesController {
    constructor($ngRedux, hotkeys) {
        Object.assign(this, {
            $ngRedux,
            hotkeys
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                completedDecisions: CompletedDecisionsSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                decisionsRequiringAffectedServicesResolution: DecisionsRequiringAffectedServicesResolutionMappedForSelectedPageSelector(store),
                isLoading: PagesIsFetchingDecisionOptionServiceIdsSelector(store)
            };
        };

        const controllerActions = {
            retrieveDecisionOptionServiceIds
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;

        const formDynamicErrorTranslations = {
            additionalSelections: i18n.translate(this.CustomerCareLocaleKeys.DECISIONS.ADDITIONAL_SELECTIONS),
            requires: i18n.translate(this.CustomerCareLocaleKeys.DECISIONS.REQUIRES)
        };

        this.decisionOptionIds = [];
        this.formDynamicErrors = [];

        this.decisionsRequiringAffectedServicesResolutionForEdit = [];

        this.state.decisionsRequiringAffectedServicesResolution.forEach((decision) => {
            this.decisionOptionIds = this.decisionOptionIds.concat(pluck('Id', decision.Options));
        });

        this.actions.retrieveDecisionOptionServiceIds(this.state.currentCustomerId, this.decisionOptionIds, this.offeringInstanceId)
            .then((response) => {
                const serviceIdsGroupedByDecisionOption = groupBy(
                    (decisionOptionServiceId) => {
                        return decisionOptionServiceId.DecisionOptionId;
                    },
                    response.DecisionServiceIdentifiers);
                const updatedDecisionsRequiringAffectedServicesResolutionForEdit = [];

                this.state.decisionsRequiringAffectedServicesResolution.forEach((decision) => {
                    const decisionWithOptionServiceIds = Object.assign({}, decision, {
                        Options: decision.Options.map((option) => {
                            const decisionOptionPreviouslyCompletedDecision = this.state.completedDecisions.find((completedDecision) => {
                                return completedDecision.SelectedValue === option.Id;
                            });
                            const decisionOptionServicesSelectedForRemoval = pathOr([], ['SelectedServicesForRemoval'], decisionOptionPreviouslyCompletedDecision);

                            const decisionOptionServiceIds = serviceIdsGroupedByDecisionOption[option.Id].reduce(
                                (accumulatedServiceIds, decisionOptionServiceId) => {
                                    const serviceIds = decisionOptionServiceId.ServiceIdentifiers.map((serviceId) => {
                                        let serviceIdIsSelected = false;

                                        if (decisionOptionServicesSelectedForRemoval.length < option.numRequiredSelections) {
                                            serviceIdIsSelected = decisionOptionServicesSelectedForRemoval.some((serviceSelectedForRemoval) => {
                                                return serviceSelectedForRemoval.ServiceAttributeId === serviceId.ServiceAttributeId &&
                                                    serviceSelectedForRemoval.Value === serviceId.Value;
                                            });
                                        }

                                        return {
                                            attributeId: serviceId.ServiceAttributeId,
                                            disabled: false,
                                            Id: serviceId.Value,
                                            Name: serviceId.FriendlyName || serviceId.Value,
                                            selected: serviceIdIsSelected
                                        };
                                    });

                                    return accumulatedServiceIds.concat(serviceIds);
                                },
                                []);

                            return Object.assign({}, option, {
                                serviceIdentifiers: decisionOptionServiceIds
                            });
                        })
                    });

                    updatedDecisionsRequiringAffectedServicesResolutionForEdit.push(decisionWithOptionServiceIds);
                });

                this.decisionsRequiringAffectedServicesResolutionForEdit = updatedDecisionsRequiringAffectedServicesResolutionForEdit;
            });

        this.hotkeys
            .add({
                callback: (event) => {
                    event.preventDefault();

                    this.handleFormSubmit();
                },
                combo: 'enter'
            });

        this.hotkeys
            .add({
                callback: (event) => {
                    event.preventDefault();

                    this.handleClose();
                },
                combo: 'esc'
            });

        this.handleClose = () => {
            this.onClose()();
        };

        this.handleFormSubmit = () => {
            const selectedDecisionOptionServiceIds = [];

            this.formDynamicErrors = [];

            this.decisionsRequiringAffectedServicesResolutionForEdit.forEach((decision) => {
                decision.Options.forEach((option) => {
                    const numOptionRemainingSelections = this.getNumDecisionOptionRemainingSelections(option);

                    if (0 !== numOptionRemainingSelections) {
                        this.formDynamicErrors.push(`"${option.Name}" ${formDynamicErrorTranslations.requires} ${numOptionRemainingSelections} ${formDynamicErrorTranslations.additionalSelections}`);
                    } else {
                        selectedDecisionOptionServiceIds.push({
                            decisionId: decision.Id,
                            optionId: option.Id,
                            serviceIdentifiers: this.getSelections(option.serviceIdentifiers),
                            updatedQuantity: option.updatedQuantity
                        });
                    }
                });
            });

            if (0 === this.formDynamicErrors.length) {
                this.onSuccess()(selectedDecisionOptionServiceIds);
            }
        };
    }

    $onDestroy() {
        this.hotkeys.del('enter');
        this.hotkeys.del('esc');

        this.disconnectRedux();
    }

    getNumDecisionOptionCurrentSelections(option) {
        return this.getNumSelections(option.serviceIdentifiers);
    }

    getNumDecisionOptionRemainingSelections(option) {
        return this.getNumDecisionOptionRequiredSelections(option) - this.getNumDecisionOptionCurrentSelections(option);
    }

    getNumDecisionOptionRequiredSelections(option) {
        return option.numRequiredSelections;
    }

    getNumSelections(options = []) {
        return (this.getSelections(options)).length;
    }

    getSelections(options = []) {
        return options.filter((option) => {
            return option.selected;
        });
    }

    toggleSelection(decisionId, optionId, serviceIdentifierId) {
        this.decisionsRequiringAffectedServicesResolutionForEdit.forEach((decision) => {
            if (decisionId === decision.Id) {
                decision.Options.forEach((option) => {
                    if (optionId === option.Id) {
                        const serviceIdentifierIsCurrentlySelected = propOr(false, 'selected', find(propEq(serviceIdentifierId, 'Id'), option.serviceIdentifiers));
                        const numOptionRemainingSelections = this.getNumDecisionOptionRemainingSelections(option);
                        const numOptionRemainingSelectionsAfterToggle = clamp(0, option.numRequiredSelections, serviceIdentifierIsCurrentlySelected ? numOptionRemainingSelections + 1 : numOptionRemainingSelections - 1);

                        if (serviceIdentifierIsCurrentlySelected || numOptionRemainingSelections > 0) {
                            option.serviceIdentifiers.forEach((serviceIdentifier) => {
                                if (serviceIdentifierId === serviceIdentifier.Id) {
                                    serviceIdentifier.disabled = false;
                                    serviceIdentifier.selected = !serviceIdentifierIsCurrentlySelected;

                                } else {
                                    serviceIdentifier.disabled = !serviceIdentifier.selected && 0 === numOptionRemainingSelectionsAfterToggle;
                                }
                            });
                        }
                    }
                });
            }
        });

        return false;
    }
}

export default {
    template: require('./select.affected.services.popup.html'),
    bindings: {
        config: '<',
        offeringInstanceId: '<',
        onClose: '&',
        onSuccess: '&'
    },
    controllerAs: 'selectAffectedServicesController',
    controller: SelectAffectedServicesController
};
