import {CustomerCareActionsWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
import {CONVERGENT_BILLER_DEMO_ACCESS} from '../../../security.attributes';

export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.demoActions', {
        url: '/demoActions',
        template: '<demo-actions></demo-actions>',
        data: {
            windowTitle: CustomerCareActionsWindowTitleSelector,
        },
        securityAttributes: [CONVERGENT_BILLER_DEMO_ACCESS]
    });
}
