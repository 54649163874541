import LocaleKeys from '../../locales/keys';

class DefaultLandingPageController {
    constructor() {
        Object.assign(this, {
            LocaleKeys
        });
    }
}

export default {
    template: require('./default.landing.page.html'),
    controller: DefaultLandingPageController
};