import {createSelector} from 'reselect';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsNotDbssWithOffersEnabled} from 'invision-core/src/components/session/businessunit.selectors';
import {
    CODES,
    REGEX_CONSTANTS
} from 'invision-core/src/components/metadata/codes/codes.constants';
import {getRegexForCode} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {MetadataCodeTypeIntegerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CurrentCustomerNameSelector,
    CurrentCustomerSelector,
    RouteParams,
    RoutePreviousState
} from '../../../../../reducers/selectors/customer.selectors';
import {
    CartTab,
    QuoteTab
} from '../../../../../reducers/helpers/products.order.selector.helpers';
import {
    DiscretionaryDiscountsSelector,
    ModifiedSubscriptionItemSelector,
    OrderQuoteSelector,
    ReplaceCartItemSelector,
    ShoppingCartMetadataSelector,
    ShoppingCartSelector,
    SubmitChangeOfServiceForServiceFeatureRequestSelector
} from '../../../../../reducers/selectors/products.order.selectors';
import {
    CompletedStepsSelector,
    CurrentStepIndexSelector,
    ProductOrderShippingMethodIdSelector,
    SkippedStepSelector
} from '../../../../../reducers/selectors/products.wizard.selectors';
import {
    getNextStepIndex,
    getPreviousStepIndex
} from '../../../../../reducers/helpers/wizard.helper';
import {
    ALL_STEPS_KEYS,
    SERVICE_FEATURE_ORDER_WIZARD_MODE,
    PRODUCT_ORDER_WIZARD_MODE
} from '../../../../../reducers/constants/products.wizard.constants';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    setFormattedName,
    setFormattedCityState
} from '../../../../../reducers/helpers/customer.helper';
import {
    SUBSCRIPTION_DETAILS_ROUTE
} from '../../../subscriptions/subscriptions.config';
import {isServiceFeatureOrder} from './steps/products/products.wizard.helper';

const DEFAULT_CUSTOMER = {};
export const CustomerInfoSelector = createSelector(
    [
        CurrentCustomerSelector
    ],
    (customer) => {
        if (customer) {
            return customer;
        } else {
            return DEFAULT_CUSTOMER;
        }
    }
);

export const FormattedNameSelector = createSelector(
    [CustomerInfoSelector],
    (customerInfo) => {
        return setFormattedName(customerInfo);
    }
);

export const FormattedCityStateSelector = createSelector(
    [CustomerInfoSelector],
    (customerInfo) => {
        return setFormattedCityState(customerInfo);
    }
);

export const BackButtonTextSelector = createSelector(
    [
        CurrentStepIndexSelector,
        CompletedStepsSelector,
        RouteParams
    ],
    (currentStepIndex, completedSteps, routeParams) => {
        const previousStepIndex = getPreviousStepIndex(ALL_STEPS_KEYS, completedSteps, currentStepIndex);
        const previousStepKey = ALL_STEPS_KEYS[previousStepIndex];

        return previousStepKey ? i18n.translate(CustomerCareKeys.BACK_TO_STEP, {
            step: isServiceFeatureOrder(routeParams) &&
                ALL_STEPS_KEYS[0] === ALL_STEPS_KEYS[previousStepIndex] ?
                i18n.translate(CustomerCareKeys.SERVICE_FEATURES) : i18n.translate(previousStepKey)
        }) : '';
    }
);

export const ForwardButtonTextSelector = createSelector(
    [
        CurrentStepIndexSelector,
        SkippedStepSelector
    ],
    (currentStepIndex, skippedSteps) => {
        const nextStepIndex = getNextStepIndex(ALL_STEPS_KEYS, skippedSteps, currentStepIndex);
        const nextStepKey = ALL_STEPS_KEYS[nextStepIndex];

        return nextStepKey ? i18n.translate(CustomerCareKeys.CONTINUE_TO_STEP, {
            step: i18n.translate(nextStepKey)
        }) : i18n.translate(CustomerCareKeys.COMPLETE_ORDER);
    }
);

const ProductWizardMode = createSelector(
    [RouteParams],
    (data) => {
        return data.mode;
    }
);

export const PreviousRouteSelector = createSelector(
    [ProductWizardMode],
    (wizardMode) => {
        switch (wizardMode) {
            case PRODUCT_ORDER_WIZARD_MODE.REPLACE:
            case PRODUCT_ORDER_WIZARD_MODE.RESTORE:
                return SUBSCRIPTION_DETAILS_ROUTE;
            default:
                return 'index.customercare.customer.dashboard';
        }
    }
);

export const IsPreviousRouteDashboardSelector = createSelector(
    [RoutePreviousState],
    (route) => {
        return route.name === 'index.customercare.customer.dashboard';
    }
);

export const IsCreatingModeSelector = createSelector(
    [ProductWizardMode],
    (wizardMode) => {
        return wizardMode === PRODUCT_ORDER_WIZARD_MODE.CREATE;
    }
);

export const IsModifyingModeSelector = createSelector(
    [ProductWizardMode],
    (wizardMode) => {
        return wizardMode === PRODUCT_ORDER_WIZARD_MODE.MODIFY;
    }
);

export const IsReplacingModeSelector = createSelector(
    [ProductWizardMode],
    (wizardMode) => {
        return wizardMode === PRODUCT_ORDER_WIZARD_MODE.REPLACE;
    }
);

export const IsRestoringModeSelector = createSelector(
    [ProductWizardMode],
    (wizardMode) => {
        return wizardMode === PRODUCT_ORDER_WIZARD_MODE.RESTORE;
    }
);

export const SubscriptionsOnlySelector = createSelector(
    [RouteParams],
    (parameters) => {
        return parameters.subscriptionsOnly;
    }
);

export const ProductsOnlySelector = createSelector(
    [RouteParams],
    (parameters) => {
        return parameters.productsOnly;
    }
);

export const ServiceFeatureProductsSelector = createSelector(
    [RouteParams],
    (parameters) => {
        return parameters.service ? parameters.service : parameters.serviceId;
    }
);

//This option in the API call is a tri-state. If null, returns both products and subscriptions
//If true, returns only subscriptions. If false, returns only products
export const CatalogSubscriptionsFilterSelector = createSelector(
    [IsReplacingModeSelector, SubscriptionsOnlySelector, ProductsOnlySelector, IsNotDbssWithOffersEnabled],
    (isReplacingMode, subscriptionsOnly, productsOnly, subscriptionsAsOffersBU) => {
        const SUBSCRIPTIONS_ONLY_CASES = {
            SUBSCRIPTIONS_ONLY: true,
            PRODUCTS_ONLY: false,
            BOTH: null
        };

        if (subscriptionsAsOffersBU) {
            return SUBSCRIPTIONS_ONLY_CASES.PRODUCTS_ONLY;
        } else if ((isReplacingMode || subscriptionsOnly) && !productsOnly) {
            return SUBSCRIPTIONS_ONLY_CASES.SUBSCRIPTIONS_ONLY;
        } else if (productsOnly && !subscriptionsOnly && !isReplacingMode) {
            return SUBSCRIPTIONS_ONLY_CASES.PRODUCTS_ONLY;
        } else {
            return SUBSCRIPTIONS_ONLY_CASES.BOTH;
        }
    }
);

export const WizardTitleLocaleSelector = createSelector(
    [ProductWizardMode, SubscriptionsOnlySelector],
    (wizardMode, subscriptionsOnly) => {
        switch (wizardMode) {
            case SERVICE_FEATURE_ORDER_WIZARD_MODE.MANAGE:
                return CustomerCareKeys.MANAGE_ADD_ONS;
            case PRODUCT_ORDER_WIZARD_MODE.MODIFY:
                return CustomerCareKeys.MODIFY_PRODUCT_ORDER;
            case PRODUCT_ORDER_WIZARD_MODE.REPLACE:
                return CustomerCareKeys.REPLACE_PRODUCT_ORDER;
            case PRODUCT_ORDER_WIZARD_MODE.RESTORE:
                return CustomerCareKeys.RESTORE_PRODUCT_ORDER;
            default:
                return subscriptionsOnly ?
                    CustomerCareKeys.NEW_SUBSCRIPTION_ORDER :
                    CustomerCareKeys.NEW_PRODUCT_ORDER;
        }
    }
);

export const WizardTitleCustomerOrServiceSelector = createSelector(
    [ProductWizardMode, ServiceFeatureProductsSelector, CurrentCustomerNameSelector],
    (wizardMode, service, customerName) => {
        switch (wizardMode) {
            case SERVICE_FEATURE_ORDER_WIZARD_MODE.MANAGE:
                return service.Value ? service.FriendlyName || service.FormattedValue : service;
            default:
                return customerName;
        }
    }
);

const UrlRegexSelector = createSelector(
    [MetadataCodeTypeIntegerSelector(CODES.RegularExpression)],
    (codes) => {
        return codes && codes.length ? getRegexForCode(codes, REGEX_CONSTANTS.UrlFormat) : undefined;
    }
);

export const CartTabViewModel = CartTab([
    ShoppingCartMetadataSelector, ShoppingCartSelector, OrderQuoteSelector, ProductOrderShippingMethodIdSelector, ModifiedSubscriptionItemSelector, ReplaceCartItemSelector, DiscretionaryDiscountsSelector,
    IsReplacingModeSelector, IsModifyingModeSelector, IsRestoringModeSelector, UrlRegexSelector
]);

export const QuoteTabViewModel = QuoteTab([
    ShoppingCartMetadataSelector, ShoppingCartSelector, OrderQuoteSelector, ProductOrderShippingMethodIdSelector, ModifiedSubscriptionItemSelector, ReplaceCartItemSelector, DiscretionaryDiscountsSelector,
    IsReplacingModeSelector, IsModifyingModeSelector, IsRestoringModeSelector, UrlRegexSelector, SubmitChangeOfServiceForServiceFeatureRequestSelector
]);

export const CartHasItemsSelector = createSelector(
    [IsModifyingModeSelector, CartTabViewModel, ShoppingCartSelector, SubmitChangeOfServiceForServiceFeatureRequestSelector, RouteParams],
    (isModifyingMode, cartTab, shoppingCart, changeOfServiceRequest, routeParams) => {
        const isServiceFeatureOrderAndHasItems = !!(changeOfServiceRequest && (
            changeOfServiceRequest.AddItems && changeOfServiceRequest.AddItems.length ||
            changeOfServiceRequest.RemoveItems && changeOfServiceRequest.RemoveItems.length
        ));
        return !!((isModifyingMode && cartTab && cartTab.modifyItem) ||
        isServiceFeatureOrderAndHasItems
        || (!isServiceFeatureOrder(routeParams) && shoppingCart.Items && shoppingCart.Items.length));
    }
);

export const HasCustomerInfoSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return !!(currentCustomer.FirstName && currentCustomer.FirstName !== '');
    }
);
