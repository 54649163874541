import _ from 'lodash';

export const deeply = (mapped, fn) => {
    return (target) => {
        if (_.isArray(target)) {
            return target.map((value) => {
                return deeply(mapped, fn)(value);
            });
        }

        if (_.isPlainObject(target)) {
            return mapped(_.mapValues(target, (value) => {
                return deeply(mapped, fn)(value);
            }), fn);
        }

        return target;
    };
};

export const camelCaseKeys = (object) => {
    return deeply(_.mapKeys, (value, key) => {
        return _.camelCase(key);
    })(object);
};

export const pascalCaseKeys = (object) => {
    return deeply(_.mapKeys, (value, key) => {
        return key.charAt(0).toUpperCase() + _.camelCase(key).slice(1);
    })(object);
};