import {DestinationCustomerSearchResultsViewModelSelector} from '../../../../../reducers/selectors/transfer.wizard.selectors';
import CustomerCareKeys from '../../../../../locales/keys';

class SelectDestinationCustomerDialogController {

    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareKeys
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                destinationCustomerSearchResultsViewModel: DestinationCustomerSearchResultsViewModelSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./select.destination.customer.dialog.html'),
    controller: SelectDestinationCustomerDialogController,
    bindings: {
        additionalText: '<?',
        dialogTitle: '<',
        isLoading: '<',
        onClose: '&',
        onCustomerSelected: '&'
    }
};
