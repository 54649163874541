import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CustomerActionsTemplateSelector} from '../../reducers/selectors/customer.actions.template.selectors';
import {CurrentCustomerNameSelector} from '../../reducers/selectors/customer.selectors';
import {retrieveCustomerPromise} from '../../reducers/actions/customer.actions';
import {retrieveCustomerAccountStatusPromise} from '../../reducers/actions/customer.account.status.actions';
import {SUBSCRIBER_ACCESS} from '../../security.attributes';
import {getNavigableRoute} from './customer.helper';
import {TRANSFER_STATE_NAME} from './orders/transfer/transfer.wizard.config';

export const CUSTOMER_URL = 'index.customercare.customer';
export function routing($stateProvider) {
    $stateProvider.state(CUSTOMER_URL, {
        url: '/customer/:customerId',
        template: '<customer></customer>',
        data: {
            windowTitle: CurrentCustomerNameSelector,
        },
        params: {
            showAutoPayBanner: {
                enabled: false,
                customerId: undefined
            }
        },
        securityAttributes: [SUBSCRIBER_ACCESS],
        resolve: {
            customerIdIsValid: ($q, $stateParams) => {
                const customerId = $stateParams.customerId;

                if (customerId !== undefined && customerId.trim().length && !isNaN(customerId)) {
                    return $q.resolve();
                } else {
                    return $q.reject({
                        redirectTo: 'index.customercare'
                    });
                }
            },
            customer: ($ngRedux, $stateParams, customerIdIsValid, uiNotificationService) => { // eslint-disable-line no-unused-vars
                return retrieveCustomerPromise($ngRedux.dispatch, $stateParams.customerId).then(() => {

                    // This call is tied to a requirement for us to potentially limit the number of subscriber pages a CSR can limit based upon a new BU level configuration.  This check is tied to
                    // the RetrieveSubscriberStatistics call that this promise represents.  Please do not move/remove this without discussing with Kelly Lein or Geoff Preston.
                    return retrieveCustomerAccountStatusPromise($ngRedux.dispatch, $stateParams.customerId);
                }).catch((error) => {
                    uiNotificationService.transientError(error.translatedMessage);
                });
            },
            autoPayBanner: ($stateParams, $state, $q) => {
                if ($stateParams.showAutoPayBanner.customerId &&
                    $stateParams.showAutoPayBanner.customerId !== convertStringToNumber($stateParams.customerId) &&
                    $state.current.name !== TRANSFER_STATE_NAME
                ) {
                    return $q.reject({
                        redirectTo: CUSTOMER_URL,
                        toParams: {
                            customerId: $stateParams.customerId,
                            showAutoPayBanner: {
                                enabled: false,
                                customerId: undefined
                            }
                        }
                    });
                } else {
                    return $q.resolve();
                }
            }
        },
        controller: ($ngRedux, $state, $stateParams, customer) => { // eslint-disable-line no-unused-vars
            if ($state.is(CUSTOMER_URL)) {
                const template = CustomerActionsTemplateSelector($ngRedux.getState());
                const targetState = template && template.pages && template.pages.length > 0 ? getNavigableRoute(template.pages) : '';
                $state.go(targetState, $stateParams);
            }
        }
    });
}
