export const DISCONNECT_OFFERS_DBSS_ROUTE =
    'index.customercare.customer.disconnectoffersdbss';
export const DISCONNECT_CUSTOMER_DBSS_ROUTE =
    'index.customercare.customer.disconnectdbss';

export function routing($stateProvider) {
    $stateProvider
        .state(DISCONNECT_OFFERS_DBSS_ROUTE, {
            url:
                '/disconnect/offering/:offeringId/offeringInstance/:offeringInstanceId',
            template:
                '<disconnect-offers-dbss class="u-flexKeepHeight"></disconnect-offers-dbss>'
        })
        .state(DISCONNECT_CUSTOMER_DBSS_ROUTE, {
            url: '/disconnect',
            template:
                '<disconnect-offers-dbss class="u-flexKeepHeight"></disconnect-offers-dbss>'
        });
}
