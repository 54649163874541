import {STATE_OR_NAME} from '../../../../reducers/constants/add.services.wizard.constants';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_OR_NAME, {
            url: '/createServicesOrder',
            ignoreBack: true,
            template: '<add-services class="u-sizeFull u-flexKeepHeight"></add-services>'
        });
}
