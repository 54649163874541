import * as R from 'ramda';
import {RamdaHelper} from 'invision-core';

const CONTACT_EVENT_TYPE = '52';

const ContactMethodToSubscriberEnum = {
    'Email': 'Email',
    'Home Phone': 'HomePhone',
    'Business Phone': 'BusinessPhone',
    'Mobile Phone': 'MobilePhone'
};

export function createPreferredContactFromContactPreferences(subscriber, metadataContactTypes) {
    if (subscriber && metadataContactTypes.length > 0) {
        return R.pipe(
            R.filter((contactPreference) => {
                return contactPreference.ContactEventType === CONTACT_EVENT_TYPE;
            }),
            R.map((contactPreference) => {
                const metadataContactType = RamdaHelper.findByValue(parseInt(contactPreference.ContactMethod, 10), metadataContactTypes);
                return {
                    title: metadataContactType.Name,
                    value: subscriber.data[ContactMethodToSubscriberEnum[metadataContactType.Name]],
                    type: metadataContactType.Name === 'Email' ? metadataContactType.Name : 'Phone'
                };
            })
        )(subscriber.data.ContactPreferences);
    }
    return [];
}
