import angular from 'angular';

import AddressWrapupComponent from './presentationalComponents/address/wrapUp/address.wrapup.form.component';
import AddressWrapupContextualComponent from './contextualComponents/address/wrapUp/address.wrapup.component';
import AutoRenewLinkContextualComponent from './contextualComponents/autoRenewLink/auto.renew.link.component';
import ApplyCreditRefundModalComponent from './contextualComponents/transactions/applyCreditRefund/apply.credit.refund.modal.component';
import CartItemWithActionsController from './presentationalComponents/orders/cart/cartItemWithActions/cart.item.with.actions.component';
import ContractTermsConditionsDialogComponent from './contractTermsConditionsDialog/contract.terms.conditions.dialog.component';
import CustomerAccountInfoComponent from './presentationalComponents/customer/accountInfo/customer.account.info.component';
import CustomerAdditionalPropertiesComponent from './presentationalComponents/customer/additionalProperties/customer.additional.properties.component';
import customerBillingCycleUpdateModalComponent from './presentationalComponents/customer/customerBillingCycleUpdate/customer.billing.cycle.update.modal.component';
import CustomerContactInfoComponent from './presentationalComponents/customer/contactInfo/customer.contact.info.component';
import CustomerInfoComponent from './presentationalComponents/customer/info/customer.info.component';
import CustomerPinModalComponent from './presentationalComponents/customer/customerPin/customer.pin.modal.component';
import CustomerWrapupComponent from './presentationalComponents/customer/wrapUp/customer.wrapup.form.component';
import CustomerWrapupContextualComponent from './contextualComponents/customer/wrapUp/customer.wrapup.component';
import DecisionOverrideDiscountController from './decisionOverrideDiscountDialog/decision.override.discount.dialog.component';
import DevicePaymentItemComponent from './presentationalComponents/orders/checkout/devicePayment/device.payment.item.component';
import DevicePaymentItemLabelComponent from './presentationalComponents/orders/checkout/devicePayment/device.payment.item.label.component';
import DevicePaymentItemsComponent from './presentationalComponents/orders/checkout/devicePayment/device.payment.items.component';
import DiscountDialogComponent from './dialog/discount.dialog.component';
import DiscretionaryDiscountsComponent from './presentationalComponents/orders/checkout/discretionaryDiscounts/discretionary.discounts.component';
import EntitlementBalanceComponent from './contextualComponents/entitlementBalance/entitlement.balance.component';
import EntitlementThumbnailComponent from './presentationalComponents/entitlementThumbnail/entitlement.thumbnail.component';
import EntitlementUsageChartComponent from './presentationalComponents/entilementUsageChart/entitlement.usage.chart.component';
import FullCartComponent from './presentationalComponents/orders/cart/fullCart/full.cart.component';
import FullCartChargeComponent from './presentationalComponents/orders/cart/fullCartCharge/full.cart.charge.component';
import FullCartOfferComponent from './presentationalComponents/orders/cart/fullCartOffer/full.cart.offer.component';
import FullCartRenewalScheduleComponent from './presentationalComponents/orders/cart/fullCartRenewalSchedule/full.cart.renewal.schedule.component';
import FutureDatedOrdersComponent from './presentationalComponents/orders/checkout/futureDatedOrders/future.dated.orders.component';
import GroupedEntitlementsModalComponent from './contextualComponents/groupedEntitlementsModal/grouped.entitlements.modal.component';
import InventoryStoreFinderComponent from './contextualComponents/inventoryStoreFinder/inventory.store.finder.component';
import InventoryStoreHeaderComponent from './presentationalComponents/inventoryStoreHeader/inventory.store.header.component';
import LifeCycleStepsDialogComponent from './contextualComponents/lifeCycleStepsDialog/life.cycle.steps.dialog';
import MoveHierarchyNodeComponent from './presentationalComponents/accountHierarchy/move.hierarchy.node.component';
import ReturnDeviceModalComponent from './presentationalComponents/inventory/returnDevice/return.device.modal.component';
import PolicyCounterThumbnailComponent from './presentationalComponents/policyCounterThumbnail/policy.counter.thumbnail.component';
import PricingPlanComponent from './presentationalComponents/orders/pricingPlan/pricing.plan.component';
import PricingPlanRenewalPeriodsTooltipComponent from './contextualComponents/pricingPlanRenewalPeriodsTooltip/pricingplan.renewal.periods.component';
import ProductServiceRelationshipsModalComponent from './presentationalComponents/productServiceRelationshipModal/product.service.relationship.modal.component';
import SearchOtherCustomerDialogComponent from './contextualComponents/customer/search/search.other.customer.dialog.component';
import SelectedInventoryStoreComponent from './presentationalComponents/selectedInventoryStore/selected.inventory.store.component';
import SendToStorePopupContextualComponent from './contextualComponents/orders/sendToStorePopup/send.to.store.popup.component';
import ServiceFeaturesDialogComponent from './contextualComponents/serviceFeaturesDialog/service.features.dialog.component';
import UpdateServiceLifeCycleStatusModalComponent from './contextualComponents/UpdateServiceLifeCycleStatus/update.service.life.cycle.status.modal.component';
import ServicesSuspendResumeModalComponent from './contextualComponents/services/servicesSuspendResumeModal/services.suspend.resume.modal.component';
import ServiceTaxCustomizationComponent from './presentationalComponents/serviceTaxCustomization/service.tax.customization.component';
import StoreInventorySearchModalComponent from './contextualComponents/storeInventorySearchModal/store.inventory.search.component';
import ShippingMethodsComponent from './presentationalComponents/orders/checkout/shippingMethods/shipping.methods.component';
import SubscriberOfferDetails from './presentationalComponents/subscriberOfferDetails/subscriber.offer.details.component';
import SubscriberServiceDetails from './presentationalComponents/subscriberServiceDetails/subscriber.service.details.component';
import SubscriberStateController from './presentationalComponents/subscriberState/subscriber.state.component';
import SubscriptionFeesComponent from './presentationalComponents/orders/checkout/subscriptionFees/subscription.fees.component';
import TaxAndGeneralLedgerController from './presentationalComponents/orders/checkout/taxAndGlLocation/tax.and.general.ledger.location.component';
import TelephoneSelectionComponent from './presentationalComponents/orders/attributes/telephoneSelection/telephone.selection.component';
import UsageChartsModule from './presentationalComponents/usageChart/usage.chart.module';
import ValidatePINModalComponent from './contextualComponents/validatePINModal/validate.pin.modal.component';

export default angular.module('invision.customercare.shared', [UsageChartsModule])
    .component('addressWrapup', AddressWrapupContextualComponent)
    .component('addressWrapupForm', AddressWrapupComponent)
    .component('applyCreditRefundModal', ApplyCreditRefundModalComponent)
    .component('autoRenewLink', AutoRenewLinkContextualComponent)
    .component('cartItemWithActions', CartItemWithActionsController)
    .component('contractTermsConditionsDialog', ContractTermsConditionsDialogComponent)
    .component('customerAccountInfo', CustomerAccountInfoComponent)
    .component('customerAdditionalProperties', CustomerAdditionalPropertiesComponent)
    .component('customerBillingCycleUpdate', customerBillingCycleUpdateModalComponent)
    .component('customerContactInfo', CustomerContactInfoComponent)
    .component('customerInfo', CustomerInfoComponent)
    .component('customerPin', CustomerPinModalComponent)
    .component('customerWrapup', CustomerWrapupContextualComponent)
    .component('customerWrapupForm', CustomerWrapupComponent)
    .component('decisionOverrideDiscount', DecisionOverrideDiscountController)
    .component('devicePaymentItem', DevicePaymentItemComponent)
    .component('devicePaymentItemLabel', DevicePaymentItemLabelComponent)
    .component('devicePaymentItems', DevicePaymentItemsComponent)
    .component('discountDialog', DiscountDialogComponent)
    .component('discretionaryDiscounts', DiscretionaryDiscountsComponent)
    .component('entitlementBalance', EntitlementBalanceComponent)
    .component('entitlementThumbnail', EntitlementThumbnailComponent)
    .component('entitlementUsageChart', EntitlementUsageChartComponent)
    .component('fullCart', FullCartComponent)
    .component('fullCartOffer', FullCartOfferComponent)
    .component('fullCartRenewalSchedule', FullCartRenewalScheduleComponent)
    .component('fullCartCharge', FullCartChargeComponent)
    .component('futureDatedOrders', FutureDatedOrdersComponent)
    .component('groupedEntitlementsModal', GroupedEntitlementsModalComponent)
    .component('inventoryStoreFinder', InventoryStoreFinderComponent)
    .component('inventoryStoreHeader', InventoryStoreHeaderComponent)
    .component('lifeCycleStepsDialog', LifeCycleStepsDialogComponent)
    .component('moveHierarchyNode', MoveHierarchyNodeComponent)
    .component('returnDeviceModal', ReturnDeviceModalComponent)
    .component('policyCounterThumbnail', PolicyCounterThumbnailComponent)
    .component('pricingPlan', PricingPlanComponent)
    .component('pricingPlanRenewalPeriodsTooltip', PricingPlanRenewalPeriodsTooltipComponent)
    .component('productServiceRelationshipsModal', ProductServiceRelationshipsModalComponent)
    .component('searchOtherCustomerDialog', SearchOtherCustomerDialogComponent)
    .component('selectedInventoryStore', SelectedInventoryStoreComponent)
    .component('sendToStorePopup', SendToStorePopupContextualComponent)
    .component('serviceFeaturesDialog', ServiceFeaturesDialogComponent)
    .component('serviceTaxCustomization', ServiceTaxCustomizationComponent)
    .component('updateServiceLifeCycleStatusModal', UpdateServiceLifeCycleStatusModalComponent)
    .component('servicesSuspendResumeModal', ServicesSuspendResumeModalComponent)
    .component('shippingMethods', ShippingMethodsComponent)
    .component('storeSearchInventoryPopup', StoreInventorySearchModalComponent)
    .component('subscriberOfferDetails', SubscriberOfferDetails)
    .component('subscriberServiceDetails', SubscriberServiceDetails)
    .component('subscriberState', SubscriberStateController)
    .component('subscriptionFees', SubscriptionFeesComponent)
    .component('taxAndGeneralLedger', TaxAndGeneralLedgerController)
    .component('telephoneSelection', TelephoneSelectionComponent)
    .component('validatePinModal', ValidatePINModalComponent)
    .name;
