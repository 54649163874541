import angular from 'angular';

import 'invision-core';

import UpdateAddressPopupComponent from './update.address.popup.component';

export default angular.module('invision.customercare.customer.addresses.updateAddressPopup', [
    'invision.core.components'
])
    .component('updateAddressPopup', UpdateAddressPopupComponent)
    .name;
