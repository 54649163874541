export default {
    ACCESS_DENIED_EXPIRED_TEMPORARY_PASSWORD: '117-11717',
    ACCESS_DENIED_INSUFFICIENT_SUBSCRIBER_ACCESS: '117-11710',
    ACCOUNT_ITEM_LIMIT_REACHED: '1069',
    ADD_HIERARCHY_NODE_FAILURE: '1206',
    ADDRESS_VALIDATION_FAILED: '1101',
    ADDRESS_VALIDATOR_NOT_ACCESSIBLE: '1700',
    ADJUSTMENT_INVALID_OVER_CHARGE: '989',
    ADJUSTMENT_LIMIT_PER_DAY_VIOLATED: '11004-1100402',
    ADJUSTMENT_LIMIT_PER_INSTANCE_VIOLATED: '11004-1100401',
    ADJUSTMENT_NOT_ALLOWED: '11012',
    AUTOPAY_NOT_SUPPORTED: '11092',
    BULK_SERVICE_ATTRIBUTES_JOB_EXTRACT_FILE_ALREADY_PROCESSED: '1255',
    BULK_SERVICE_ATTRIBUTES_JOB_MISSING_EXTRACT_FILE: '1254',
    BULK_SERVICE_ATTRIBUTES_JOB_MISSING_PRICING_PLAN: '1250',
    BULK_SERVICE_ATTRIBUTES_JOB_NOT_ASSOCIATED_WITH_ORDER: '1249',
    BULK_SERVICE_ATTRIBUTES_JOB_NOT_FOUND: '1248',
    CASE_ACTIVITY_NOT_FOUND: '1401',
    CASE_NOT_FOUND: '1400',
    CASE_UPDATE_NOT_ALLOWED: '1402',
    CONVERGENT_BILLER_ISSUE: '4',
    CREDIT_CARD_TYPE: '617-6170114',
    CREDIT_CHECK_CONFIGURATION_NEEDED: '234',
    CREDIT_DEBIT_ADJUSTMENT_REASON_CODE_NOT_CONFIGURED: '50217',
    CREDIT_NOTE_LIMIT_PER_DAY_VIOLATED: '11168-1116802',
    CREDIT_NOTE_LIMIT_PER_INSTANCE_VIOLATED: '11168-1116801',
    DATA_EXTRACT_NOT_FOUND: '1504',
    DEPENDENT_PRICING_PLAN: '1019-101914',
    DEVICE_NOT_FOUND: '806',
    DUPLICATE_SERVICE_IDENTIFIER: '1068',
    DUPLICATE_SERVICE_IDENTIFIER_IN_ARM: '1280-128001',
    EZTAX_ADDRESS_NOT_FOUND: '1244-124411',
    EZTAX_AUTHORIZATION_FAILED: '1244-124413',
    EZTAX_BILL_TO_LOCATION_NOT_SPECIFIED: '1244-124405',
    EZTAX_BUSINESS_CLASS_NOT_VALID: '1244-124404',
    EZTAX_COMMIT_TRANSACTION_ALREADY_EXECUTED: '1244-124410',
    EZTAX_COUNTRY_ISO_NOT_VALID: '1244-124402',
    EZTAX_COUNTY_STATE_ZIP_NOT_FOUND: '1244-124401',
    EZTAX_MISSING_INVALID_REQUEST_BODY: '1244-124406',
    EZTAX_TABLE_INDICES_NOT_MATCHING: '1244-124408',
    EZTAX_TAX_TRANSACTIONS_ALREADY_COMMITTED: '1244-124409',
    EZTAX_TRANSACTION_SERVICE_PAIR_NOT_VALID: '1244-124403',
    EZTAX_TRANSACTIONS_FOR_DOCUMENT_CODE_NOT_FOUND: '1244-124407',
    EZTAX_UNABLE_TO_REACH_CONFIGURED_URL: '1244-124414',
    EZTAX_UNSUPPORTED_TAX_CODE: '1244-124412',
    FDO_DISABLED_ON_BUNT: '1085-108501',
    FDO_MAX_DATE_SURPASSED: '1085-108502',
    FDO_ORDER_CONTAINS_DEPOSITS: '1087-108702',
    FDO_ORDER_CONTAINS_INVALID_PRODUCTS: '1087-108705',
    FDO_ORDER_CONTAINS_LOGICAL_INVENTORY: '1087-108704',
    FDO_ORDER_CONTAINS_PHYSICAL_INVENTORY: '1087-108703',
    FDO_PREPAID_ORDERS_NOT_ALLOWED: '1087-108701',
    FDO_PRICING_PLAN_OVERLAP: '1086-108601',
    FULL_SUITE_ADDRESS_VALIDATION_FAILED: '1193',
    FULL_SUITE_OTHER_ADDRESS_VALIDATION_FAILED: '1194',
    GIFT_CARD_ACTIVATION_FAILURE: '602',
    HIERARCHY_NOT_FOUND: '1204',
    HOUSEHOLD_ACCESS_DENIED_INSUFFICIENT_SUBSCRIBER_ACCESS: '117-11705',
    HOUSEHOLD_NOT_FOUND: '1105',
    INTERACTION_NOT_FOUND: '106',
    INVALID_CHARGE_REQUEST: '604',
    INVALID_FDO_IN_PROGRESS: '1084-108402',
    INVALID_FDO_NOT_FDO: '1084-108401',
    INVALID_PAYMENT_INSTRUMENT: '606',
    INVALID_PAYMENT_REQUEST: '607',
    INVALID_PORT_IN_REQUEST_LAMBDA_ERROR: '1088-108805',
    INVENTORY_INVALID_URL: '1640',
    INVENTORY_ITEM_NO_LONGER_AVAILABLE: '1610',
    INVENTORY_MULTIPLE_PROVIDER: '1638',
    INVENTORY_PROVIDER_PROCESSING_ERROR_CREATE_UPDATE: '1630',
    INVENTORY_PROVIDER_UNAVAILABLE_ERROR_CREATE_UPDATE: '1631',
    INVENTORY_QUANTITY_SHOULD_BE_GREATER_THAN_ZERO: '1620',
    INVENTORY_SKU: '1055',
    INVENTORY_STORE_REGION_ERROR: '1636',
    INVENTORY_TYPE_ATTRIBUTE_NOT_MATCHED: '1619',
    INVENTORY_TYPE_MAKE_NOT_MATCHED: '1615',
    INVENTORY_TYPE_NOT_FOUND: '1604',
    INVENTORY_TYPE_PROVIDER_COMMUNICATION_NOT_FOUND: '1609',
    INVENTORY_TYPE_PROVIDER_NOT_FOUND: '1608',
    INVENTORY_TYPE_PROVIDER_NOT_VALID: '1600',
    INVOICE_AND_DEBIT_NOTE_ID_NOT_ALLOWED: '50218',
    LITE_CUSTOMER_SUBMISSION_ERROR_CODE: '11058',
    MISSING_OR_INVALID_VALUES: '118',
    NO_ATTRIBUTES_FOR_PRICING_PLAN: '1253',
    NO_BILL_CYCLES_AVAILABLE: '1246',
    NO_INVENTORY_AVAILABLE: '1605',
    OFFERING_NOT_FOUND: '1149',
    ORDER_COULD_NOT_BE_CANCELED: '502',
    ORDER_SUBMITTED_CREDIT_CHECK_ERROR: '1296',
    OVERLAPPING_OR_INVALID_INDEX: '1257',
    PAYMENT_INSTRUMENT_AUTHORIZATION_FAILED: '1112',
    PAYMENT_INSTRUMENT_NOT_FOUND: '611',
    PAYMENT_INSTRUMENT_TYPE_NOT_SUPPORTED: '612',
    PAYMENT_SOURCE_NOT_VALID: '1115',
    PAYPAL_PROCESSING_FAILURE: '613',
    PHYSICAL_INVENTORY_TYPE_MODEL: '1617',
    PRICING_PLAN_NOT_AVAILABLE_FOR_TOP_UP: '9003',
    PRICING_PLAN_NOT_FOUND: '916',
    PROCESS_PAYMENT_FAILED: '1201',
    PRODUCT_BUNDLE_NOT_AVAILABLE: '920-92010',
    PRODUCT_DOES_NOT_EXIST: '920-92023',
    PRODUCT_PRICING_PLAN_NOT_FOUND: '920-92018',
    PROMISE_TO_PAY_ALREADY_EXISTS: '1226',
    REORDER_WHILE_PENDING_ERROR: '1268',
    REVENUE_CONFIGURATION_NOT_FOUND: '615',
    REVENUE_MANAGEMENT_PAYMENT_INSTRUMENT_CONVERSION_FAILURE: '1200',
    REVENUE_TAX_FAILURE: '616',
    SERVICE_ATTRIBUTE_VALUE_MISSING_ERROR: '11024',
    SERVICE_FEATURE_CANNOT_BE_ASSOCIATED: '11022-902905',
    SERVICE_IDENTIFIER_NOT_FOUND: '1183',
    SHOPPING_CART_AD_HOC_EMPTY_OVERRIDE: '1019-101912',
    SHOPPING_CART_AD_HOC_OVERRIDE_DETAIL_NOT_ALLOWED: '1019-101910',
    SHOPPING_CART_AD_HOC_OVERRIDE_INVALID_AMOUNT: '1019-101911',
    SHOPPING_CART_EXCEEDED: '1065',
    SHOPPING_CART_HAS_NO_ITEMS: '1019-101905',
    SHOPPING_CART_INVALID_AD_HOC_OVERRIDE_DETAIL: '1019-101909',
    SHOPPING_CART_INVALID_AD_HOC_PRODUCTS_IN_CART: '1019-101908',
    SITE_ADDRESS_NOT_SPECIFIED: '1245',
    SPENDING_LIMIT_VIOLATION: '623',
    SUBSCRIBER_ANONYMIZATION_FAILED: '1140',
    SUBSCRIBER_IN_CONVERGENT_BILLER_NOT_FOUND: '52',
    SUBSCRIBER_NOT_FOUND: '1120',
    SUBSCRIBER_OFFER_LIMIT_VIOLATION: '1091',
    SUBSCRIBER_PRODUCT_NOT_FOUND: '1032',
    SUBSCRIBER_REQUIRED: '1203',
    SUBSCRIPTION_NOT_FOUND: '1033',
    TEXT_SCRUBBER_FAILED: '2',
    TRANSACTION_FAILURE: '617',
    USAGE_CAPS_INVALID_ELECTION: '50312',
    USER_INVENTORY_CHANGES_NOT_ALLOWED: '11049'
};
