import angular from 'angular';
import CustomerProductsListComponent from './list/products.list.component';
import ProductsDetailComponent from './detail/products.detail.component';

import GrantAccessRightsPopupComponent from './detail/grantAccessRightsPopup/grant.access.rights.popup.component';
import RemoveProductsPopupComponent from './removeProductsPopup/remove.products.popup.component';

import ProductsConfig from './products.config';

export default angular.module('inv.customercare.customer.customerProducts', [])
    .component('customerProductsList', CustomerProductsListComponent)
    .component('productsDetail', ProductsDetailComponent)
    .component('grantAccessRights', GrantAccessRightsPopupComponent)
    .component('removeProducts', RemoveProductsPopupComponent)
    .config(ProductsConfig)
    .name;
