import isEmpty from 'ramda/src/isEmpty';
import pick from 'ramda/src/pick';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {PostalCodeSelector} from '../../../reducers/selectors/customer.addresses.selectors';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../../customer/addresses/addresses.constants';
import {IsAddressMandatoryInCreditCheckSelector} from '../../../reducers/selectors/credit.check.selectors';

class CreditCheckAddressSectionController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CoreLocaleKeys,
            creditCheckAddressModal: {},
            CustomerCareLocaleKeys,
            formMaxLengthValidation: ADDRESS_FORM_FIELD_MAX_LENGTH,
            isCountryDisabled: this.isCountryDisabled || false,
            onAddressFormUpdate: this.onAddressFormUpdate.bind(this),
            pickAddressFormDataFrom: this.pickAddressFormDataFrom.bind(this),
            toggleCreditCheckAddressSection: this.toggleCreditCheckAddressSection.bind(this)
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addressCountries: MetadataOptionsForCodeValuesSelector(CODES.AddressCountry, store),
                addressCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
                isAddressMandatoryInCreditCheck: IsAddressMandatoryInCreditCheckSelector(store),
                postalCode: PostalCodeSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeTypes
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressCountry);
        }

        Object.assign(this.creditCheckAddressModal, this.customerModel.AddressForCreditCheck ? this.pickAddressFormDataFrom(this.customerModel.AddressForCreditCheck) : null);
        this.handleFormChanged();
    }

    $onChanges(changesObj) {
        if (!isEmpty(this.creditCheckAddressModal) && (changesObj.customerModel && changesObj.customerModel.currentValue)) {
            Object.assign(this.creditCheckAddressModal, this.pickAddressFormDataFrom(changesObj.customerModel.currentValue.AddressForCreditCheck));
        }
    }

    onAddressFormUpdate(creditCheckAddressModal) {
        if (this.customerModel.AddressForCreditCheck) {
            Object.assign(this.customerModel.AddressForCreditCheck, creditCheckAddressModal);
        }
        Object.assign(this.creditCheckAddressModal, creditCheckAddressModal);
        this.onFormChanged()(this.customerModel);
    }

    pickAddressFormDataFrom(model) {
        return pick(['addressLine1', 'addressLine2', 'city', 'country', 'stateRegionProvince', 'postalCode'], model);
    }

    handleFormChanged() {
        this.onFormChanged()(this.customerModel);
    }

    isError(formElement) {
        return (formElement.$error.pattern || formElement.$error.required || formElement.$error.maxlength) && (formElement.$touched || this.isFormSubmitted);
    }

    isDisabled() {
        return this.state.isAddressMandatoryInCreditCheck && this.markMandatoryCreditCheckFieldsDisabled;
    }

    toggleCreditCheckAddressSection() {
        this.customerModel.showCreditCheckAddressSection = this.showCreditCheckAddressSection;
        this.onFormChanged()(this.customerModel);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./credit.check.address.section.html'),
    bindings: {
        addressStateOptions: '<',
        customerModel: '<',
        isCountryDisabled: '<',
        errorLookup: '<',
        onFormChanged: '&',
        isFormSubmitted: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?',
    },
    controller: CreditCheckAddressSectionController,
    controllerAs: 'CreditCheckAddressSection'
};
