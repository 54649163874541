import {
    pathOr
} from 'ramda';
import {
    CoreLocaleKeys,
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../locales/keys';
import {OPT_OUT_OPTIONS} from '../createCustomerPopup/create.customer.popup.constants';

export const CONSENT_MAX_LENGTH = 150;
export const EMAIL_MAX_LENGTH = 100;

export const TERMS_AND_CONDITIONS_TYPES = {
    SUBSCRIBER: '1'
};

export const SUBSCRIBER_IDENTIFICATIONS_FIELD_NAME = 'subIdentification';

export const getErrorLookupObject = (options) => {
    return {
        acceptingFor: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.ACCEPTING_FOR_REQUIRED)
        },
        acceptingTerms: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.ACCEPTING_TERMS_REQUIRED)
        },
        'addressWrapupController.singleAddressFormFormApi': {
            required: i18n.translate(CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
        },
        addressLine1: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.ADDRESS_LINE_1),
                maxlength: '80'
            }),
            required: i18n.translate(CoreLocaleKeys.ADDRESS_LINE_1_REQUIRED)
        },
        addressLine2: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.ADDRESS_LINE_2),
                maxlength: '50'
            })
        },
        birthDate: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_REQUIRED),
            date: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_INVALID),
            outOfRange: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_OUT_OF_RANGE, pathOr({}, ['birthDate', 'outOfRange'], options)),
            maxDate: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_RANGE_ERROR),
            minDate: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_RANGE_ERROR),
            invalidDate: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BIRTH_DATE_INVALID)
        },
        businessPhone: {
            pattern: i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
                field_name: i18n.translate(CustomerCareLocaleKeys.BUSINESS_PHONE)
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.BUSINESS_PHONE_REQUIRED)
        },
        city: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.CITY),
                maxlength: '40'
            }),
            required: i18n.translate(CoreLocaleKeys.CITY_REQUIRED)
        },
        companyName: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.COMPANY_NAME),
                maxlength: '100'
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.COMPANY_NAME_REQUIRED)
        },
        country: {
            required: i18n.translate(CoreLocaleKeys.COUNTRY_REQUIRED)
        },
        currency: {
            required: i18n.translate(CoreLocaleKeys.CURRENCY_REQUIRED)
        },
        customerType: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.CUSTOMER_TYPE_REQUIRED)
        },
        driversLicenseNumber: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.DRIVERS_LICENSE_NUM_REQUIRED)
        },
        driversLicenseState: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.DRIVERS_LICENSE_STATE_REQUIRED)
        },
        email: {
            maxlength: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.EMAIL_INVALID_LENGTH, {
                limit: EMAIL_MAX_LENGTH
            }),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.EMAIL_INVALID_PATTERN),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.EMAIL_REQUIRED)
        },
        externalId: {
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.EXTERNAL_ID_INVALID_PATTERN),
            emptyId: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.EXTERNAL_ID_EMPTY)
        },
        firstName: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.FIRST_NAME),
                maxlength: '100'
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.FIRST_NAME_REQUIRED)
        },
        gender: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.GENDER_REQUIRED)
        },
        homePhone: {
            pattern: i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
                field_name: i18n.translate(CustomerCareLocaleKeys.HOME_PHONE)
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.HOME_PHONE_REQUIRED)
        },
        incomeLevelType: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.INCOME_LEVEL_TYPE_REQUIRED)
        },
        language: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LANGUAGE_REQUIRED)
        },
        lastName: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.LAST_NAME),
                maxlength: '100'
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LAST_NAME_REQUIRED)
        },
        leadSourceType: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LEAD_SOURCE_TYPE_REQUIRED)
        },
        legalGuardianEmail: {
            maxlength: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LEGAL_GUARDIAN_EMAIL_INVALID_LENGTH, {
                limit: EMAIL_MAX_LENGTH
            }),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LEGAL_GUARDIAN_EMAIL_INVALID_PATTERN),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LEGAL_GUARDIAN_EMAIL_REQUIRED)
        },
        login: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.LOGIN_REQUIRED)
        },
        mobilePhone: {
            pattern: i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
                field_name: i18n.translate(CustomerCareLocaleKeys.MOBILE_PHONE)
            }),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.MOBILE_PHONE_REQUIRED)
        },
        preferredName: {
            maxlength: i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_LONG, {
                field_name: i18n.translate(CustomerCareLocaleKeys.PREFERRED_NAME),
                maxlength: '100'
            })
        },
        subscriberPin: {
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.PIN_INVALID_PATTERN),
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.PIN_REQUIRED)
        },
        subscriberSubtenants: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SUBSCRIBER_SUBTENANT_REQUIRED)
        },
        taxTypeLevel : {
            multiDropdownValid: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.TAX_TYPE_LEVEL_INVALID)
        },
        postalCode: {
            required: i18n.translate(CoreLocaleKeys.POSTAL_CODE_REQUIRED)
        },
        race: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.RACE_REQUIRED)
        },
        securityAnswer: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SECURITY_ANSWER_REQUIRED),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SECURITY_ANSWER_PATTERN)
        },
        securityQuestion: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SECURITY_QUESTION_REQUIRED)
        },
        sendCopyOfTerms: {
            maxlength: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SEND_COPY_OF_TERMS_INVALID_LENGTH, {
                limit: EMAIL_MAX_LENGTH
            }),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SEND_COPY_OF_TERMS_INVALID_PATTERN)
        },
        ssn:{
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SSN_REQUIRED),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SSN_INVALID),
        },
        state: {
            required: i18n.translate(CustomerCareLocaleKeys.STATE_REGION_PROVINCE_REQUIRED)
        },
        subscriberVerification: {
            required: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SUBSCRIBER_VERIFICATION_REQUIRED),
            pattern: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.SUBSCRIBER_VERIFICATION_VALUE_INVALID)
        }
    };
};

export const getErrorIdentityVerifications = (form) => {
    const requiredErrors = form.$error.required ? form.$error.required.some((requiredError) => {
        return requiredError.$name.includes(SUBSCRIBER_IDENTIFICATIONS_FIELD_NAME);
    }) : false;

    const patternErrors = form.$error.pattern ? form.$error.pattern.some((requiredError) => {
        return requiredError.$name.includes(SUBSCRIBER_IDENTIFICATIONS_FIELD_NAME);
    }) : false;

    return requiredErrors || patternErrors;
};

export const ADDITIONAL_PROPERTIES_SORT_PROP_DATA = [{
    propId: 1099
}];

export const getOptOutOptions = () => {
    return [
        {
            label: i18n.translate(CustomerCareLocaleKeys.NOTIFICATION_PREFERENCES_TYPE.OPT_OUT_EMAIL),
            value: OPT_OUT_OPTIONS.OPT_OUT_EMAIL,
        },
        {
            label: i18n.translate(CustomerCareLocaleKeys.NOTIFICATION_PREFERENCES_TYPE.OPT_OUT_SMS),
            value: OPT_OUT_OPTIONS.OPT_OUT_SMS,
        }
    ];
};
