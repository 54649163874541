import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
import findIndex from 'ramda/src/findIndex';
import pathOr from 'ramda/src/pathOr';
import moment from 'moment';

import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {loadCodeTables} from 'invision-core/src/components/metadata/codes/codes.helpers';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CUSTOMER_URL} from '../../../../customer/customer.config';
import {DASHBOARD_ROUTE} from '../../../../../reducers/constants/dashboard.constants';
import {ShowCustomerAutoPayBannerSelector} from '../../../../../reducers/selectors/customer.account.status.selectors';
import {
    LastRouteSelector,
    PageSizePreferenceSelector,
    UserSecurityAttributesSelector
} from 'invision-core/src/components/session/session.selectors';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector,
    MetadataCodeTypeSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {getShippingMethodsByCountryCode} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.actions';

import {
    AllAttributesAreValidSelector,
    AttributeServiceSelector,
    BackButtonTextSelector,
    CurrentActiveAttributeNameSelector,
    CustomerInfoSelector,
    FilteredFormAttributeGroups,
    FilteredPhysicalAttributeGroups,
    FormattedNameSelector,
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    ForwardButtonTextSelector,
    GroupedCheckboxFilterOptionsSelector,
    HasRequiredAndUnavailableInventorySelector,
    IsContinueDisabledSelector,
    IsContinueShoppingShownSelector,
    IsFetchingAnyDataSelector,
    IsSaveButtonShownSelector,
    IsSendToStoreButtonEnabledSelector,
    OfferOptionViewModelSelector,
    ShowNextButtonSelector,
    ShowPreviousButtonSelector,
    StepProgressBarViewModelSelector,
    SummaryTabViewModel,
    UnavailablePhysicalInventorySelector
} from './add.offer.wizard.selectors';
import {
    beginAddOfferOrder,
    calculateAddOfferOrderQuoteWithTaxes,
    cancelOrder,
    clearQuoteAndSetSelectedOffer,
    fetchAddOfferAttributesAndNavigate,
    fetchAddOfferDecisionsAndNavigate,
    fetchAddresses,
    fetchAttributes,
    fetchWallet,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    initializeAsMultiOfferWizard,
    multiOfferSetCompletedSteps,
    resetForContinueShopping,
    resetOfferAdditionalProperties,
    resetMultiOffers,
    resetPickupId,
    restoreOptionDefaultPricing,
    resumeOrder,
    resumeOrderAttributes,
    resumeStoreOrder,
    retrieveShoppingCartOfferings,
    saveBulkPricing,
    searchAttributeInventory,
    sendPortInOrItemReservation,
    setChangeOfferingId,
    setChangeOfferingInstanceId,
    setCurrentStepIsInvalid,
    setDecisionsTouched,
    setDownPaymentOnMultiOfferShoppingCart,
    setEditAttributeGroups,
    setEditCartAdditionalProperty,
    setEditCartAdditionalPropertyOnChange,
    setEditOption,
    setEditOrderAdditionalProperty,
    setEditOrderAdditionalPropertyOnChange,
    setEditOfferAdditionalProperty,
    setEditOfferAdditionalPropertyOnChange,
    setEditPhysicalAttributeGroups,
    setIsResumingOrder,
    setIsResumingStoreOrder,
    setMultiOfferShoppingCart,
    setPaymentInfo,
    setRemoveOfferInstanceId,
    setSelectedFacetsEditCopy,
    setSelectedOfferEditCopy,
    setSelectedOfferingChargeTypesEditCopy,
    setSelectedOfferingInstanceId,
    setSelectedTab,
    setTransferFromName,
    setTransferFromSubscriberId,
    submitAddOrder,
    updateAttribute,
    updateBillCycle,
    updateCart,
    updateEditOptionPrice,
    updateInventorySelection,
    updateOfferPhysicalInventoryMakeAndModel,
    updateOfferPhysicalInventoryTypeAttribute,
    updatePaymentInfo,
    updatePortInRequest,
    updateSelectedServiceFeatures,
    updateShoppingCart
} from '../../../../../reducers/actions/add.offer.wizard.actions';
import {
    calculateOfferingOrderQuote,
    clearOfferingOrderQuote,
    clearQuote,
    replaceMultiOfferShoppingCart,
    resetAvailableInventoryInformation,
    resetInventoryStorePickupDetails,
    retrieveOffCycleNextRenewalSchedules,
    retrieveOfferingOrderQuote,
    retrieveOrderQuote,
    saveInventoryStorePickupDetails,
    searchAvailableOfferings,
    searchInventoriesAvailability,
    sendInventoryToStore,
    setActiveAttributeFormName,
    setAttributeFormSubmitted,
    setCheckoutDetailsValidation,
    setDecisionsSelectedPageId,
    setDeliveryDecision,
    setDevicePaymentOptions,
    setInventoryRegionFilter,
    setInventoryStoreChangedFlag,
    setSelectedInventoryStores,
    updateMultiOfferShoppingCart,
    updateShippingDetails,
    updateServiceTaxLocationDecision
} from '../../../../../reducers/actions/offering.order.actions';
import {
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWalletAndSelectDefaultPaymentMethod
} from '../../../../../reducers/actions/customer.ewallet.actions';
import {
    AddedOffersSelector,
    AddOfferAsyncIdSelector,
    AddOfferDeliveryMethodOptionsSelector,
    AddOfferShoppingCartPhysicalInventoriesSelector,
    AddOfferStoreAvailabilitySelector,
    AddOfferSubscriberInventoryAvailabilityInventorySelector,
    AllPhysicalInventoryItemsFromAddOfferSelector,
    AvailableCreditFromQuoteBreachedSelector,
    AvailableFinanceCreditFromQuoteSelector,
    BillCycleSelector,
    BillingAddressStateRegionProvinceValueOptionsSelector,
    CartAdditionalPropertiesSelector,
    CartAdditionalPropertyListMapSelector,
    ChangeOfferingInstanceIdSelector,
    CompletedStepsSelector,
    CurrentStepIndexSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    DeliveryOptionsByPricingPlanSelector,
    EditedMultiOfferShoppingCartSelector,
    EditOptionSelector,
    EditOptionsSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    FormattedAttributesSelector,
    FormattedCityStateSelector,
    FormattedDeliveryDecisionsSelector,
    FormattedPhysicalAttributesSelector,
    HasOffCycleChargeRenewalAddOfferSelector,
    InitializeAsMultiOfferWizardSelector,
    InventoryAvailabilityRequestsSelector,
    IsAddOfferPaymentInstrumentRequiredSelector,
    IsAsyncCompleteSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    IsCreditLimitBreachedFromQuoteSelector,
    IsDecisionsStepSelector,
    IsDecisionsTouchedSelector,
    IsFullCartAsyncCompleteSelector,
    IsMultiOfferEditingSelector,
    IsMultiOfferSelector,
    IsMultiOfferShoppingCartPrepaidSelector,
    IsOffersStepSelector,
    IsRemovingOfferSavedToMultiOfferCartSelector,
    IsResumingOrderSelector,
    IsResumingStoreOrderSelector,
    IsRetrievingMultiOfferCartSelector,
    IsSavingOrderSelector,
    IsShippingRequiredSelector,
    IsTransferWizardSelector,
    MultiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItemSelector,
    MultiOfferFinancedItemsForDownpaymentSelector,
    MultiOfferIdsSelector,
    MultiOfferShoppingCartSelector,
    OfferAdditionalPropertiesSelector,
    OfferAdditionalPropertyListMapSelector,
    OffersCountSelector,
    OrderAdditionalPropertiesSelector,
    OrderAdditionalPropertyListMapSelector,
    PaymentInfoEditSelector,
    PaymentInfoSelector,
    PickupIdSelector,
    QuoteCalculatedSelector,
    QuoteHasInvoiceItemsSelector,
    RemoveOfferInstanceIdSelector,
    SelectedOfferIdSelector,
    SelectedOfferIndexSelector,
    SelectedOfferingInstanceIdSelector,
    SelectedTabIndexSelector,
    ServiceAddressSelector,
    ServiceTaxDecisionsSelector,
    ShippingAddressDropdownOptionsSelector,
    ShippingAddressSelector,
    ShoppingCartWithoutRemovedOfferSelector,
    SubscriberPhysicalInventoryPickupDetailSelector,
    SubscriptionDataForPreview,
    TabPanelViewModelSelector,
    TaxLocationDecisionsByPricingPlanSelector,
    TransferFromNameSelector,
    TransferFromSubscriberIdSelector,
    ValidPaymentInstrument
} from '../../../../../reducers/selectors/add.offer.wizard.selectors';
import {
    CurrentCustomerBillCycleSelector,
    CurrentCustomerCreditLimitSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerNameSelector,
    CurrentCustomerSelector,
    IsCreatingCustomerSelector,
    IsExistingCartSelector,
    IsTransferCartFromCurrentCustomerSelector,
    SelectedCustomerHomeCountrySelector,
    ShoppingCartErrorSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    AreAnyInventoryUnavailableSelector,
    AttributeFormSubmittedSelector,
    CartContainsOffcycleOfferSelector,
    CompletedDecisionsSelector,
    CurrentRemoveOfferSelector,
    DecisionsHasErrorSelector,
    FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
    InventoryAvailabilitySelector,
    IsAllSelectedPhysicalInventoryPresentAtSelectedStore,
    IsSaveOrderButtonDisabledSelector,
    IsSelectedOfferingChangeable,
    IsUpdatingCartSelector,
    PaymentInstrumentRequiredSelector,
    SaveOrderButtonTooltipTextSelector,
    SelectedOfferingServiceIdentifiersSelector,
    ShoppingCartHaveOnlyStandaloneItemsSelector,
    ShoppingCartSelector,
    SubmittedOrderErrorSelector,
    SelectedDeliveryDecisionFromRocSelector
} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    duplicatedServiceIdentifierAttributes,
    getShoppingCartWithUpdatedStoreIdForAPIRequest,
    getMultiOfferShoppingCartWithUpdatedDeliveryDecision
} from '../../../../../reducers/helpers/offer.ordering.wizard.helper';
import {STATE_OR_NAME} from '../../../../../reducers/constants/add.offer.wizard.constants';
import {
    ContextOfferingAction,
    OFFERINGS_SEARCH_CONSTANTS
} from '../../../../../reducers/constants/wizard.constants';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    EwalletErrorSelector,
    SelectedPaymentAutoPayEnable,
    SelectedPaymentForSubmitAtCheckoutSelector,
    SelectedPaymentMethodSelector,
    RecordPaymentInformationSelector,
    SupplyPaymentInstrumentSelector
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    DELIVERY_OPTIONS,
    DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE,
    EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE,
    INVENTORY_ITEM_RESERVATION_FAULT_CODE,
    ORDER_SCENARIOS,
    RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE,
    RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION
} from '../../../../../customercare.constants';
import FaultCodeKeys from '../../../../../../src/api/fault.code.keys';
import {
    AsyncStatusSelector,
    CreatedOrderCurrencyCodeSelector,
    CreatedOrderSelector,
    DbssShippingEnabledRegionIdSelector,
    FullCartAsyncStatusSelector,
    InventoryStorePickupDetailsSelector,
    InventoryStoresTableDataSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    IsInventoryStoreChangedSelector,
    IsInventoryStoreConfiguredSelector,
    IsSendingInventoryToStoreSelector,
    IsSubmittingOrderSelector,
    OffcycleRenewalScheduleSelector,
    OfferingOrderCheckoutPageEditFormSelector,
    OfferingOrderQuoteSelector,
    SelectedDeliveryDecisionSelector,
    SelectedInventoryRegionSelector,
    InventoryRegionWithoutWarehouseSelector,
    SelectedOrderDetailsSelector,
    SelectedInventoryStoresDetailsSelector,
    SelectedOrderExecutionOptionsSelector,
    SelectedShippingAddressAndMethodSelector,
    ViewLastAttemptErrorSelector
} from '../../../../../reducers/selectors/offering.order.selectors';
import {SelectedContractSelector} from '../../../../../reducers/selectors/contract.selectors';
import {
    cancelStoreOrder,
    setCustomerCategory,
    setEditAdditionalProperty
} from '../../../../../reducers/actions/customer.actions';
import {searchContracts} from '../../../../../reducers/actions/contracts.list.actions';
import {ContractsSearchResultsSelector} from '../../../../../reducers/selectors/contracts.list.selectors';
import {resetMobileNumberPortabilityModels} from '../../../../../reducers/actions/mobile.number.portability.actions';
import {setCustomerAutoPayBannerFlag} from '../../../../../reducers/actions/customer.account.status.actions';
import {setSelectedContract} from '../../../../../reducers/actions/contract.details.actions';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../../reducers/actions/address.component.actions';
import {
    ValidatedAddressesSelector,
    UnvalidatedAddressesSelector
} from '../../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../../shared/contextualComponents/address/address.validator.helper';
import {OfferWizard} from '../../offer.wizard.common';
import {SUBSCRIBER_TYPE_EDIT_ACCESS} from '../../../../../security.attributes';
import {
    FullCartOfferingOrderQuotesSelector,
    FullCartOfferingOrderQuoteTotalSelector,
    FullCartOfferingOrderFeeQuoteSelector,
    FullCartOfferingOrderTaxQuoteSelector,
    FullCartOfferingOrderShippingQuoteSelector,
    ShowHideFullCartColumnsSelector
} from '../../../../../reducers/selectors/full.cart.selectors';
import {asyncRepeatInterval} from '../../../../../reducers/helpers/async.helper';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../../../../reducers/selectors/customer.information.selectors';
import {CurrentCustomerNodeSelector} from '../../../../../reducers/selectors/customer.account.hierarchy.selectors';
import {REPORTING_LEVELS} from '../../../accountHierarchy/account.hierarchy.constants';
import {CUSTOMER_CATEGORY} from '../../../../createCustomerPopup/create.customer.popup.constants';
class AddOfferWizardController extends OfferWizard {
    constructor($q, $scope, $ngRedux, $filter, $interval, $log, $state, $location, $anchorScroll, $timeout, $element, uiNotificationService, unsavedChangesPrompt) {
        super();
        Object.assign(this, {
            $q,
            $anchorScroll,
            $element,
            $filter,
            $interval,
            $location,
            $log,
            $ngRedux,
            $scope,
            $state,
            $timeout,

            autoPayBannerFlag: false,
            contextOfferingAction: ContextOfferingAction.ADD,
            customerCareKeys: CustomerCareKeys,
            customerCategory: CUSTOMER_CATEGORY,
            DELIVERY_OPTIONS,
            fullCartEditOfferingInstanceId: null,
            isCartExisting: false,
            isContinueShopping: false,
            isInventoryItemReservationFault: false,
            isLoading: false,
            isRemovingOffer: false,
            isResumingCancelOrderButton: false,
            orderScenario: ORDER_SCENARIOS.ADD_OFFER,
            overrideBypassQuoteConfiguration: false,
            resumeOrderWarning: RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE,
            REPORTING_LEVELS,
            retrieveOfferingOrderQuoteTimestamp: null,
            retrieveOrderQuoteTimestamp: null,
            saveOrderErrorMessage: '',
            showPreviewRenewalStatementsDialog: true,
            showSendToStorePopup: false,
            showViewFullCartPopup: false,
            stateOrName: STATE_OR_NAME,
            uiNotificationService,
            unsavedChangesPrompt,

            attemptNextStep: this.attemptNextStep.bind(this),
            cancelAddingOrEditingOffer: this.cancelAddingOrEditingOffer.bind(this),
            changeOfferClick: this.changeOfferClick.bind(this),
            closeRemoveOfferConfirmation: this.closeRemoveOfferConfirmation.bind(this),
            closeResumeOrderErrorPopup: this.closeResumeOrderErrorPopup.bind(this),
            closeSearchInventoryPopupDialog: this.closeSearchInventoryPopupDialog.bind(this),
            closeSelectStorePopupDialog: this.closeSelectStorePopupDialog.bind(this),
            closeSendToStorePopup: this.closeSendToStorePopup.bind(this),
            closeViewFullCartPopupDialog: this.closeViewFullCartPopupDialog.bind(this),
            editOfferClick: this.editOfferClick.bind(this),
            fetchOrderQuote: this.fetchOrderQuote.bind(this),
            finishAttributes: this.finishAttributes.bind(this),
            handleClosePreviewRenewalStatementsDialog: this.handleClosePreviewRenewalStatementsDialog.bind(this),
            handleCloseSaveOrderErrorPopup: this.handleCloseSaveOrderErrorPopup.bind(this),
            handleCloseSubmitOrderErrorPopup: this.handleCloseSubmitOrderErrorPopup.bind(this),
            handleCloseSubmitTransferOrderPopup: this.handleCloseSubmitTransferOrderPopup.bind(this),
            handlePreviewRenewalStatements: this.handlePreviewRenewalStatements.bind(this),
            handleRemoveOffer: this.handleRemoveOffer.bind(this),
            handleResultExistingCartPromptPopup: this.handleResultExistingCartPromptPopup.bind(this),
            handleResultSaveOrderPromptPopup: this.handleResultSaveOrderPromptPopup.bind(this),
            handleResultTransferOrderPopup: this.handleResultTransferOrderPopup.bind(this),
            handleResultUnsavedChangesPromptPopup: this.handleResultUnsavedChangesPromptPopup.bind(this),
            handleSingleOfferChange: this.handleSingleOfferChange.bind(this),
            handleSubmitForPickupCancel: this.handleSubmitForPickupCancel.bind(this),
            handleSubmitForPickupErrorPopup: this.handleSubmitForPickupErrorPopup.bind(this),
            onCartAdditionalPropertyChanged: this.onCartAdditionalPropertyChanged.bind(this),
            onCartAdditionalPropertyListUpdated: this.onCartAdditionalPropertyListUpdated.bind(this),
            onInventoryRegionFilterChanged: this.onInventoryRegionFilterChanged.bind(this),
            onInventoryRegionFilterChangedForStoreSearch: this.onInventoryRegionFilterChangedForStoreSearch.bind(this),
            onItemDownpaymentChanged: this.onItemDownpaymentChanged.bind(this),
            onAdditionalPropertyChanged: this.onAdditionalPropertyChanged.bind(this),
            onAdditionalPropertyListUpdated: this.onAdditionalPropertyListUpdated.bind(this),
            onOverrideCalculateQuote: this.onOverrideCalculateQuote.bind(this),
            onRefreshOfferingQuote: this.onRefreshOfferingQuote.bind(this),
            onRefreshQuote: this.onRefreshQuote.bind(this),
            onSelectInventoryStore: this.onSelectInventoryStore.bind(this),
            onStepSubmit: this.onStepSubmit.bind(this),
            openStoreSearch: this.openStoreSearch.bind(this),
            repeatRetrieveOfferingQuote: this.repeatRetrieveOfferingQuote.bind(this),
            recalculateOrderQuote: this.recalculateOrderQuote.bind(this),
            recallOfferingContext: this.recallOfferingContext.bind(this),
            repeatRetrieveQuote: this.repeatRetrieveQuote.bind(this),
            removeOffer: this.removeOffer.bind(this),
            sendToStore: this.sendToStore.bind(this),
            setSelectedStoreForSearchModal: this.setSelectedStoreForSearchModal.bind(this),
            updateCart: this.updateCart.bind(this)
        });
        Promise.all(loadCodeTables(this.$ngRedux.getState(), this.$ngRedux.dispatch, [
            CODES.FinanceBillerRuleConfiguration,
            CODES.InventoryRegion,
            CODES.OneTimeBillerRuleConfiguration,
            CODES.PeriodType,
            CODES.ReasonCodes,
            CODES.RecurringBillerRuleConfiguration,
            CODES.Stores,
            CODES.SubscriptionBillingCycle
        ])).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addedOffers: AddedOffersSelector(store),
                additionalPropValueOptions: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
                addOfferShoppingCartPhysicalInventoryItems: AddOfferShoppingCartPhysicalInventoriesSelector(store),
                addressStates: BillingAddressStateRegionProvinceValueOptionsSelector(store),
                allAttributesAreValid: AllAttributesAreValidSelector(store),
                areAnyInventoryUnavailable: AreAnyInventoryUnavailableSelector(store),
                asyncId: AddOfferAsyncIdSelector(store),
                asyncStatus: AsyncStatusSelector(store),
                attributeFormSubmitted: AttributeFormSubmittedSelector(store),
                availableCreditFromQuote: AvailableCreditFromQuoteBreachedSelector(store),
                availableFinanceCreditFromQuote: AvailableFinanceCreditFromQuoteSelector(store),
                availableInventoryInformation: InventoryAvailabilitySelector(store),
                backButtonText: BackButtonTextSelector(store),
                billCycle: BillCycleSelector(store),
                cartAdditionalProperties: CartAdditionalPropertiesSelector(store),
                cartAdditionalPropertyListMap: CartAdditionalPropertyListMapSelector(store),
                cartContainsOffcycleOffer: CartContainsOffcycleOfferSelector(store),
                changeOfferingInstanceId: ChangeOfferingInstanceIdSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                completedDecisions: CompletedDecisionsSelector(store),
                completedSteps: CompletedStepsSelector(store),
                contracts: ContractsSearchResultsSelector(store),
                createdOrder: CreatedOrderSelector(store),
                createOrderError: ViewLastAttemptErrorSelector(store),
                currencyCode: CreatedOrderCurrencyCodeSelector(store),
                currentAttributeTitle: CurrentActiveAttributeNameSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerBillCycle: CurrentCustomerBillCycleSelector(store),
                currentCustomerCreditLimit: CurrentCustomerCreditLimitSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                currentCustomerName: CurrentCustomerNameSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store),
                currentRemoveOffer: CurrentRemoveOfferSelector(store),
                currentStep: CurrentStepSelector(store),
                currentStepIndex: CurrentStepIndexSelector(store),
                currentStepIsInvalid: CurrentStepIsInvalidSelector(store),
                customerId: CurrentCustomerIdSelector(store),
                customerInfo: CustomerInfoSelector(store),
                dbssShippingEnabledRegionId: DbssShippingEnabledRegionIdSelector(store),
                deliveryMethodOptions: AddOfferDeliveryMethodOptionsSelector(store),
                deliveryOptionsByPricingPlan: DeliveryOptionsByPricingPlanSelector(store),
                editOption: EditOptionSelector(store),
                editOptions: EditOptionsSelector(store),
                editedMultiOfferShoppingCart: EditedMultiOfferShoppingCartSelector(store),
                editModalSideNavList: OfferingOrderCheckoutPageEditFormSelector(store),
                formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
                executionOptions: SelectedOrderExecutionOptionsSelector(store),
                filteredFormAttributeGroups: FilteredFormAttributeGroups(store),
                filteredPhysicalAttributeGroups: FilteredPhysicalAttributeGroups(store),
                filteredStoresOnSelectedRegionForStoresSearchModal: FilteredStoresOnSelectedRegionForStoresSearchModalSelector(store),
                formattedAttributes: FormattedAttributesSelector(store),
                formattedCityState: FormattedCityStateSelector(store),
                formattedDeliveryDecisions: FormattedDeliveryDecisionsSelector(store),
                formattedName: FormattedNameSelector(store),
                formattedPhysicalAttributes: FormattedPhysicalAttributesSelector(store),
                formattedPhysicalInventories: AddOfferSubscriberInventoryAvailabilityInventorySelector(store),
                formAttributeGroups: FormAttributeGroups(store),
                formPhysicalAttributeGroups: FormPhysicalAttributeGroups(store),
                forwardButtonText: ForwardButtonTextSelector(store),
                fullCartAsyncStatus: FullCartAsyncStatusSelector(store),
                fullCartOfferingOrderQuotes: FullCartOfferingOrderQuotesSelector(store),
                fullCartOfferingOrderQuoteTotal: FullCartOfferingOrderQuoteTotalSelector(store),
                fullCartOfferingOrderShippingQuote: FullCartOfferingOrderShippingQuoteSelector(store),
                fullCartOfferingOrderFeeQuote: FullCartOfferingOrderFeeQuoteSelector(store),
                fullCartOfferingOrderTaxQuote: FullCartOfferingOrderTaxQuoteSelector(store),
                groupedCheckboxFilterOptions: GroupedCheckboxFilterOptionsSelector(store),
                hasDecisionValidationError: DecisionsHasErrorSelector(store),
                hasInvoiceItems: QuoteHasInvoiceItemsSelector(store),
                hasOffCycleChargeRenewal: HasOffCycleChargeRenewalAddOfferSelector(store),
                hasRequiredAndUnavailableInventory: HasRequiredAndUnavailableInventorySelector(store),
                initializeAsMultiOfferWizard: InitializeAsMultiOfferWizardSelector(store),
                inventoryAvailabilityRequests: InventoryAvailabilityRequestsSelector(store),
                inventoryRegions: InventoryRegionWithoutWarehouseSelector(store),
                inventoryStorePickupDetails: InventoryStorePickupDetailsSelector(store),
                isAllSelectedPhysicalInventoryAvailable: IsAllSelectedPhysicalInventoryPresentAtSelectedStore(store),
                isAttributesStep: IsAttributesStepSelector(store),
                isCalculatingQuote: IsQuotingOfferSelector(store),
                isCheckoutStep: IsCheckoutStepSelector(store),
                isContinueDisabled: IsContinueDisabledSelector(store),
                isContinueShoppingShown: IsContinueShoppingShownSelector(store),
                isConvergentBiller: IsDbss(store),
                isCreatingCustomer: IsCreatingCustomerSelector(store),
                isCreditLimitBreached: IsCreditLimitBreachedFromQuoteSelector(store),
                isDbss: IsDbss(store),
                isDecisionsStep: IsDecisionsStepSelector(store),
                isDecisionsTouched: IsDecisionsTouchedSelector(store),
                isExistingCart: IsExistingCartSelector(store),
                isFetchingAnyData: IsFetchingAnyDataSelector(store),
                isFullCartAsyncComplete: IsFullCartAsyncCompleteSelector(store),
                isInventoryStoreChanged: IsInventoryStoreChangedSelector(store),
                isInventoryStoreConfigured: IsInventoryStoreConfiguredSelector(store),
                isMultiOffer: IsMultiOfferSelector(store),
                isMultiOfferCartPrepaid: IsMultiOfferShoppingCartPrepaidSelector(store),
                isMultiOfferEditing: IsMultiOfferEditingSelector(store),
                isOffersStep: IsOffersStepSelector(store),
                isPaymentInstrumentRequired: IsAddOfferPaymentInstrumentRequiredSelector(store),
                isQuoteBypassed: IsQuoteBypassedSelector(store),
                isQuoteCalculated: QuoteCalculatedSelector(store),
                isAsyncComplete: IsAsyncCompleteSelector(store),
                isRemovingOfferSavedToMultiOfferCart: IsRemovingOfferSavedToMultiOfferCartSelector(store),
                isResumingOrder: IsResumingOrderSelector(store),
                isResumingStoreOrder: IsResumingStoreOrderSelector(store),
                isRetrievingMultiOfferCart: IsRetrievingMultiOfferCartSelector(store),
                isSaveButtonShown: IsSaveButtonShownSelector(store),
                isSaveOrderButtonDisabled: IsSaveOrderButtonDisabledSelector(store),
                isSavingOrder: IsSavingOrderSelector(store),
                isSelectedOfferingChangeable: IsSelectedOfferingChangeable(store),
                isSendingInventoryToStore: IsSendingInventoryToStoreSelector(store),
                isSendToStoreButtonEnabled: IsSendToStoreButtonEnabledSelector(store),
                isShippingRequired: IsShippingRequiredSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                isTransferCart: IsTransferCartFromCurrentCustomerSelector(store),
                isTransferWizard: IsTransferWizardSelector(store),
                isUpdatingCart: IsUpdatingCartSelector(store),
                lastAttemptError: SubmittedOrderErrorSelector(store),
                lastRoute: LastRouteSelector(store),
                multiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItem: MultiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItemSelector(store),
                multiOfferFinancedItems: MultiOfferFinancedItemsForDownpaymentSelector(store),
                multiOfferShoppingCart: MultiOfferShoppingCartSelector(store),
                offcycleRenewalSchedule: OffcycleRenewalScheduleSelector(store),
                offerIds: MultiOfferIdsSelector(store),
                offeringOrderQuote: OfferingOrderQuoteSelector(store),
                offerAdditionalProperties: OfferAdditionalPropertiesSelector(store),
                offerAdditionalPropertyListMap: OfferAdditionalPropertyListMapSelector(store),
                offerOptionViewModel: OfferOptionViewModelSelector(store),
                offersCount: OffersCountSelector(store),
                orderAdditionalProperties: OrderAdditionalPropertiesSelector(store),
                orderAdditionalPropertyListMap: OrderAdditionalPropertyListMapSelector(store),
                orderDetails: SelectedOrderDetailsSelector(store),
                pageSize: PageSizePreferenceSelector(store),
                paymentInfo: PaymentInfoSelector(store),
                paymentInfoEdit: PaymentInfoEditSelector(store),
                paymentInstrumentRequired: PaymentInstrumentRequiredSelector(store),
                periodTypes: MetadataCodeTypeSelector(CODES.PeriodType, store),
                physicalInventories: AllPhysicalInventoryItemsFromAddOfferSelector(store),
                pickupId: PickupIdSelector(store),
                recordPaymentInfo: RecordPaymentInformationSelector(store),
                removeOfferInstanceId: RemoveOfferInstanceIdSelector(store),
                retrieveEwalletError: EwalletErrorSelector(store),
                routeParams: RouteParams(store),
                saveOrderButtonTooltipText: SaveOrderButtonTooltipTextSelector(store),
                selectedContract: SelectedContractSelector(store),
                selectedCustomerHomeCountry: SelectedCustomerHomeCountrySelector(store),
                selectedDeliveryDecision: SelectedDeliveryDecisionSelector(store),
                selectedDeliveryDecisionFromRoc: SelectedDeliveryDecisionFromRocSelector(store),
                selectedInventoryRegion: SelectedInventoryRegionSelector(store),
                selectedInventoryStores: SelectedInventoryStoresDetailsSelector(store),
                selectedInventoryStoresItemAvailability: AddOfferStoreAvailabilitySelector(store),
                selectedOfferId: SelectedOfferIdSelector(store),
                selectedOfferIndexSelector: SelectedOfferIndexSelector(store),
                selectedOfferingInstanceId: SelectedOfferingInstanceIdSelector(store), // Applicable for Multi-Offer flow.
                selectedOfferingServiceIdentifiers: SelectedOfferingServiceIdentifiersSelector(store),
                selectedPayment: SelectedPaymentMethodSelector(store),
                selectedPaymentClean: SelectedPaymentForSubmitAtCheckoutSelector(store),
                selectedPaymentMethodAutoPay: SelectedPaymentAutoPayEnable(store),
                selectedShippingAddressAndMethod: SelectedShippingAddressAndMethodSelector(store),
                selectedTabIndex: SelectedTabIndexSelector(store),
                serviceAddress: ServiceAddressSelector(store),
                services: AttributeServiceSelector(store),
                serviceTaxDecisions: ServiceTaxDecisionsSelector(store),
                shippingAddress: ShippingAddressSelector(store),
                shippingAddressDropdownOptions: ShippingAddressDropdownOptionsSelector(store),
                shoppingCart: ShoppingCartSelector(store),
                shoppingCartError: ShoppingCartErrorSelector(store),
                shoppingCartHaveOnlyStandaloneItems: ShoppingCartHaveOnlyStandaloneItemsSelector(store),
                shoppingCartIsPrepaid: IsMultiOfferShoppingCartPrepaidSelector(store),
                shoppingCartWithoutRemovedOffer: ShoppingCartWithoutRemovedOfferSelector(store),
                shouldShowNextButton: ShowNextButtonSelector(store),
                shouldShowPreviousButton: ShowPreviousButtonSelector(store),
                showCustomerAutoPayBanner: ShowCustomerAutoPayBannerSelector(store),
                showHideFullCartColumns: ShowHideFullCartColumnsSelector(store),
                stepsProgressBarViewModel: StepProgressBarViewModelSelector(store),
                storesTableData: InventoryStoresTableDataSelector(store),
                subscriberPhysicalInventoryPickupDetail: SubscriberPhysicalInventoryPickupDetailSelector(store),
                subscriptionBillingCycles: MetadataCodeTypeSelector(CODES.SubscriptionBillingCycle, store),
                subscriptionDataForPreview: SubscriptionDataForPreview(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                summaryTabViewModel: SummaryTabViewModel(store),
                supplyPaymentInstrument: SupplyPaymentInstrumentSelector(store),
                tabs: TabPanelViewModelSelector(store),
                transferFromName: TransferFromNameSelector(store),
                taxLocationDecisionsByPricingPlan: TaxLocationDecisionsByPricingPlanSelector(store),
                transferFromSubscriberId: TransferFromSubscriberIdSelector(store),
                unavailablePhysicalInventory: UnavailablePhysicalInventorySelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
                validPaymentInfo: ValidPaymentInstrument(store)
            };
        };
        const controllerActions = {
            beginAddOfferOrder,
            calculateAddOfferOrderQuoteWithTaxes,
            calculateOfferingOrderQuote,
            cancelOrder,
            cancelStoreOrder,
            clearOfferingOrderQuote,
            clearQuote,
            clearQuoteAndSetSelectedOffer,
            fetchAddresses,
            fetchAttributes,
            fetchAttributesAndNavigate: fetchAddOfferAttributesAndNavigate,
            fetchCodeTypes,
            fetchDecisionsAndNavigate: fetchAddOfferDecisionsAndNavigate,
            fetchWallet,
            getShippingMethodsByCountryCode,
            goToNextStep,
            goToPreviousStep,
            goToStep,
            initializeAsMultiOfferWizard,
            multiOfferSetCompletedSteps,
            registerUnsavedChanges,
            replaceMultiOfferShoppingCart,
            resetAvailableInventoryInformation,
            resetForContinueShopping,
            resetInventoryStorePickupDetails,
            resetOfferAdditionalProperties,
            resetMobileNumberPortabilityModels,
            resetMultiOffers,
            resetPickupId,
            restoreOptionDefaultPricing,
            resumeOrder,
            resumeOrderAttributes,
            resumeStoreOrder,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveOffCycleNextRenewalSchedules,
            retrieveOfferingOrderQuote,
            retrieveOfferingsMetadata,
            retrieveOrderQuote,
            retrieveShoppingCartOfferings,
            retrieveWalletAndSelectDefaultPaymentMethod,
            saveBulkPricing,
            saveInventoryStorePickupDetails,
            searchAttributeInventory,
            searchAvailableOfferings,
            searchContracts,
            searchInventoriesAvailability,
            sendInventoryToStore,
            sendPortInOrItemReservation,
            setActiveAttributeFormName,
            setAttributeFormSubmitted,
            setChangeOfferingId,
            setChangeOfferingInstanceId,
            setCheckoutDetailsValidation,
            setCurrentStepIsInvalid,
            setCustomerAutoPayBannerFlag,
            setCustomerCategory,
            setDecisionsSelectedPageId,
            setDecisionsTouched,
            setDeliveryDecision,
            setDevicePaymentOptions,
            setDownPaymentOnMultiOfferShoppingCart,
            setEditAdditionalProperty,
            setEditAttributeGroups,
            setEditCartAdditionalProperty,
            setEditCartAdditionalPropertyOnChange,
            setEditOfferAdditionalProperty,
            setEditOfferAdditionalPropertyOnChange,
            setEditOption,
            setEditOrderAdditionalProperty,
            setEditOrderAdditionalPropertyOnChange,
            setEditPhysicalAttributeGroups,
            setForceShowEditFormAddress,
            setInventoryRegionFilter,
            setInventoryStoreChangedFlag,
            setIsResumingOrder,
            setIsResumingStoreOrder,
            setMultiOfferShoppingCart,
            setPaymentInfo,
            setRemoveOfferInstanceId,
            setSelectedContract,
            setSelectedFacetsEditCopy,
            setSelectedInventoryStores,
            setSelectedOfferEditCopy,
            setSelectedOfferingChargeTypesEditCopy,
            setSelectedOfferingInstanceId,
            setSelectedTab,
            setTransferFromName,
            setTransferFromSubscriberId,
            submitAddOrder,
            unregisterUnsavedChanges: unregisterUnsavedChanges,
            updateAttribute,
            updateBillCycle,
            updateCart,
            updateEditOptionPrice,
            updateInventorySelection,
            updateMultiOfferShoppingCart,
            updateOfferPhysicalInventoryMakeAndModel,
            updateOfferPhysicalInventoryTypeAttribute,
            updatePaymentInfo,
            updatePortInRequest,
            updateSelectedServiceFeatures,
            updateServiceTaxLocationDecision,
            updateShippingDetails,
            updateShoppingCart,
            validateAddress
        };

        this.disconnectRedux = this.connectToStore(mapStateToTarget, controllerActions);

        this.checkoutStepSubmitRegistration = function(handleCheckoutStepSubmit) {
            this.checkoutStepSubmitHandler = handleCheckoutStepSubmit;
        };

        this.EditSelectedFacetIdsSelector = EditSelectedFacetIdsSelector;
        this.EditSelectedOfferingChargeTypeIdSelector = EditSelectedOfferingChargeTypeIdSelector;
        this.subscriberSubtenant = (!this.state.routeParams.customerId) ? [this.state.customerInfo.SubtenantId] : null;
        this.cartMessages = [
            this.customerCareKeys.CART_SUMMARY.QUOTE_SUMMARY
        ];

        this.actions.beginAddOfferOrder(this.state.initializeAsMultiOfferWizard);

        // Used to see if Contracts exist if changed, also change in NewConnectWizard
        this.actions.searchContracts({
            PageNumber: 1,
            PageSize: 1
        });

        this.actions.fetchWallet(this.state.customerInfo.Id).catch(() => {
            if (this.state.retrieveEwalletError) {
                this.uiNotificationService.error(this.state.retrieveEwalletError.message);
            }
        });

        this.actions.fetchAddresses(this.state.customerInfo.Id);
        this.actions.setCustomerCategory(this.state.customerInfo.Category);

        this.setCustomerTypeChangeReadOnly();

        this.insufficientPermissionsWarningPopup = {
            onRegisterApi: (event) => {
                this.insufficientPermissionsWarningPopup = event.api;
            }
        };

        this.openInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.open();
        };

        this.closeInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.close();
            this.goBackToDashboard();
        };

        this.goBackToDashboard = () => {
            const route = DASHBOARD_ROUTE;
            const params = this.state.lastRoute.params || {};
            this.$state.go(route, params);
        };

        if (this.state.isResumingOrder) {
            this.isResumingCancelOrderButton = true;
        }

        if ((this.state.isResumingOrder && !this.state.shoppingCartError) || this.state.isTransferWizard || this.state.changeOfferingInstanceId) {
            this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                .then((response) => {
                    this.state.offerIds.length && this.actions.retrieveOfferingsMetadata(this.state.offerIds);
                    if (this.state.isTransferWizard) {
                        const transferFromSubscriberId = pathOr(null, ['ShoppingCart', 'TransferFromSubscriberId'], response);
                        if (transferFromSubscriberId) {
                            this.actions.setTransferFromSubscriberId(transferFromSubscriberId);
                            this.actions.setIsResumingOrder(true);
                        }
                    }

                    if (this.state.isResumingOrder && response.Warnings && !!(response.Warnings.find((warning) => {
                        return warning.Code === this.resumeOrderWarning;
                    }))) {
                        this.openInsufficientPermissionsWarningPopup();
                        return Promise.resolve();
                    }

                    if (pathOr(0, ['ShoppingCart', 'Items', 'length'], response)) {
                        const physicalInventoryItemWithStore = response.ShoppingCart.Items.find((item) => {
                            return pathOr(null, ['PhysicalInventories', 0, 'StoreId'], item);
                        });

                        if (physicalInventoryItemWithStore) {
                            this.actions.setSelectedInventoryStores(
                                [physicalInventoryItemWithStore.PhysicalInventories[0].StoreId.toString()]
                            );
                        }
                    }
                    const shoppingCartItemsForDeliveryDecisons = pathOr([], ['ShoppingCart', 'Items'], response);
                    const cartContainsShippableItem = shoppingCartItemsForDeliveryDecisons.some((item) => {
                        return item.DeliveryType === DELIVERY_OPTIONS.SHIPPED;
                    });
                    this.actions.setDeliveryDecision(cartContainsShippableItem ? DELIVERY_OPTIONS.SHIPPED : DELIVERY_OPTIONS.STORE_PICKUP);
                    this.actions.calculateOfferingOrderQuote(
                        this.state.customerInfo,
                        this.state.multiOfferShoppingCart,
                        !this.state.currentCustomerBillCycle ? this.state.billCycle : null,
                        this.state.executionOptions,
                        undefined,
                        cartContainsShippableItem,
                        this.overrideBypassQuoteConfiguration
                    ).then(() => {
                        if (this.state.asyncId) {
                            this.repeatRetrieveQuote();
                        }
                        if (!this.state.changeOfferingInstanceId) {
                            this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
                            this.actions.multiOfferSetCompletedSteps();
                        }
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });

                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }

        if (this.state.isResumingStoreOrder) {
            this.actions.resumeStoreOrder(this.state.customerInfo, false).then((response) => {
                if (response.Warnings && !!(response.Warnings.find((warning) => {
                    return warning.Code === this.resumeOrderWarning;
                }))) {
                    this.openInsufficientPermissionsWarningPopup();
                    return Promise.resolve();
                }
            });
            this.actions.setIsResumingOrder(true);
        }

        this.additionalPropertiesPopupConfig = {
            onRegisterApi: ({api}) => {
                this.additionalPropertiesPopupApi = api;
            }
        };

        this.sendToStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.sendToStorePopupApi = api;
            }
        };

        // hook the popupApis
        this.existingCartPromptConfig = {
            onRegisterApi: ({api}) => {
                this.existingCartPromptApi = api;
                if (this.state.isExistingCart &&
                    !this.state.isResumingOrder &&
                    !this.state.initializeAsMultiOfferWizard &&
                    !this.state.isResumingStoreOrder &&
                    !this.state.isTransferWizard &&
                    !this.state.isTransferCart &&
                    !this.state.changeOfferingInstanceId) {
                    this.$scope.$applyAsync(api.open);
                }
            }
        };

        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api}) => {
                this.unsavedChangesPromptPopupApi = api;
            }
        };

        this.saveOrderPromptPopupConfig = {
            onRegisterApi: ({api}) => {
                this.saveOrderPromptPopupApi = api;
            }
        };

        this.saveOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.saveOrderErrorPopupApi = api;
            }
        };

        this.$scope.orderConfirmationPopup = {
            onRegisterApi: (evt) => {
                this.$scope.orderConfirmationPopup = evt.api;
            }
        };

        this.submitOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitOrderErrorPopupApi = api;
            }
        };

        this.submitTransferOrderPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitTransferOrderPopupApi = api;
            }
        };

        this.creditCheckPopupConfig = {
            onRegisterApi: ({api}) => {
                this.creditCheckPopupApi = api;
            }
        };

        this.submitForPickupErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitForPickupErrorPopupApi = api;
            }
        };

        this._previewRenewalStatementsPopupApi = null;
        this.previewRenewalStatementsPopupConfig = {
            onRegisterApi: (event) => {
                this._previewRenewalStatementsPopupApi = event.api;
            }
        };

        this._resumeOrderErrorPopupApi = null;
        this.resumeOrderErrorPopupConfig = {
            onRegisterApi: (event) => {
                this._resumeOrderErrorPopupApi = event.api;
            }
        };

        this.viewFullCartPopupConfig = {
            onRegisterApi: (event) => {
                this.viewFullCartPopupApi = event.api;
            }
        };

        this.openCreditCheckModal = () => {
            this.creditCheckPopupApi.open();
        };

        this.removeOfferConfirmationPopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeOfferConfirmationPopupApi = api;
            }
        };

        this.selectStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectStorePopupApi = api;
            }
        };

        this.storeSearchInventoryConfig = {
            onRegisterApi: ({api}) => {
                this.storeSearchInventoryApi = api;
            }
        };

        this.editProfileInformationPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.editProfileInformationPopupApi = api;
            }
        };

        if (this.state.initializeAsMultiOfferWizard) {
            // This is intentionally defined after setting up all of the popup modals, so Initialize is still true.
            this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                .then(() => {
                    const contractId = (this.state.multiOfferShoppingCart.Items.find((item) => {
                        return item.OrderContractId;
                    }) || {}).OrderContractId;

                    if (contractId) {
                        const selectedContract = this.state.contracts.find((contract) => {
                            return contract.Id === contractId;
                        });
                        this.actions.setSelectedContract(selectedContract);
                    }
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error);
                })
                .finally(() => {
                    this.actions.initializeAsMultiOfferWizard(false);
                    this.actions.setSelectedOfferingChargeTypesEditCopy(this.state.isMultiOfferCartPrepaid ?
                        CustomerCareKeys.OFFERING_CHARGE_TYPES.PREPAID : CustomerCareKeys.OFFERING_CHARGE_TYPES.POSTPAID, true);
                });
        }
        if (this.state.isDbss) {
            this.actions.getShippingMethodsByCountryCode(this.state.selectedCustomerHomeCountry).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    openSendToStorePopup() {
        this.isLoading = false;
        this.isSendToStoreLoading = false;
        this.sendToStorePickupDetailsConfig = {
            maxDate: undefined,
            minDate: Date.now()
        };
        this.showSendToStorePopup = true;

        this.$timeout(() => {
            this.sendToStorePopupApi.open();
        });
    }

    //Calls retrieveOrderQuote the first time and then calls it every 30 seconds that the call hasnt returned a response
    repeatRetrieveQuote() {
        asyncRepeatInterval(
            this.onRefreshQuote,
            () => {
                return !this.state.isAsyncComplete && this.state.isCheckoutStep;
            }
        );
    }

    onRefreshQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.asyncId)
            .then((response) => {
                this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    onOverrideCalculateQuote() {
        this.overrideBypassQuoteConfiguration = true;
        this._updateShoppingCart();
    }

    repeatRetrieveOfferingQuote() {
        if (this.state.offeringOrderQuote.OfferingQuotes.length > 0 && this.state.isFullCartAsyncComplete) {
            this.openViewFullCartPopupDialog();
        } else {
            asyncRepeatInterval(
                this.onRefreshOfferingQuote,
                () => {
                    return !this.state.isFullCartAsyncComplete;
                }
            );
        }
    }

    onRefreshOfferingQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.customerId, this.state.asyncId)
            .then((response) => {
                this.retrieveOfferingOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    fetchOrderQuote() {
        this.actions.calculateOfferingOrderQuote(
            this.state.customerInfo,
            this.state.multiOfferShoppingCart,
            !this.state.currentCustomerBillCycle ? this.state.billCycle : null,
            this.state.executionOptions,
            this.state.selectedShippingAddressAndMethod,
            undefined,
            this.overrideBypassQuoteConfiguration
        ).then(() => {
            if (this.state.asyncId) {
                this.repeatRetrieveQuote();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    sendToStore() {
        if (this.state.isResumingStoreOrder) {
            this.isSendToStoreLoading = true;
            this.actions.cancelStoreOrder(this.state.customerInfo.Id)
                .then(() => {
                    this.handleSendToStore();
                    this.isSendToStoreLoading = false;
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                    this.isSendToStoreLoading = false;
                });
        } else {
            this.handleSendToStore();
        }
    }

    handleSendToStore(isResumeChangeOffer = false, multiOfferingInstanceId, multiOfferingId, isConfirmation) {
        this.isSendToStoreLoading = true;
        this._updateShoppingCart(false, true, isConfirmation)
            .then(() => {
                let shoppingCart = clone(Object.keys(this.state.shoppingCart).length ? this.state.shoppingCart : this.state.multiOfferShoppingCart);

                if (this.state.cartAdditionalProperties.length) {
                    shoppingCart = Object.assign({}, shoppingCart, {
                        AdditionalProperties: this.state.cartAdditionalProperties
                    });
                }

                return this.actions.sendInventoryToStore(
                    this.state.customerInfo.Id,
                    shoppingCart,
                    isResumeChangeOffer ?
                        this.state.subscriberPhysicalInventoryPickupDetail :
                        this.state.inventoryStorePickupDetails
                ).then(() => {
                    if (isResumeChangeOffer) {
                        return this.actions.resumeStoreOrder(this.state.customerInfo, isResumeChangeOffer)
                            .then((response) => {
                                if (response.Warnings && !!(response.Warnings.find((warning) => {
                                    return warning.Code === this.resumeOrderWarning;
                                }))) {
                                    this.openInsufficientPermissionsWarningPopup();
                                    return Promise.resolve();
                                }
                                const offer = pathOr({}, ['ShoppingCart', 'Items', '0'], response);

                                this.changeOfferClick(multiOfferingInstanceId || offer.OfferingInstanceId, multiOfferingId || offer.OfferingId);
                                this.actions.clearQuoteAndSetSelectedOffer(null);
                                this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.OFFERS);
                            });
                    } else {
                        this.actions.setIsResumingOrder(false);
                        this.actions.unregisterUnsavedChanges('$ctrl.sendToStoreForm');
                        this.onOrderActionSuccess(CustomerCareKeys.SEND_TO_STORE.SEND_SUCCESS);
                    }
                }).catch((error) => {
                    if (error.Code === INVENTORY_ITEM_RESERVATION_FAULT_CODE) {
                        this.closeSendToStorePopup();
                        this.isInventoryItemReservationFault = true;
                        this.openStoreSearch(true);
                    } else if (error.Code === EXISTING_SAVED_CART_FOR_SEND_TO_STORE_FAULT_CODE) {
                        this.closeSendToStorePopup();
                        this.submitForPickupErrorPopupApi.open();
                    } else {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    }
                });
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.isSendToStoreLoading = false;
            });
    }

    handleSubmitForPickupCancel() {
        this.isSendToStoreLoading= true;
        this.handleSubmitForPickupErrorPopup();
        this.actions.cancelStoreOrder(this.state.customerInfo.Id)
            .then(() => {
                this.handleSendToStore(false, undefined, undefined, true);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.isSendToStoreLoading = false;
            });
    }

    handleSubmitForPickupErrorPopup() {
        this.submitForPickupErrorPopupApi.close();
    }

    openSaveOrderPopup() {
        if (this.state.cartAdditionalPropertyListMap.length) {
            this.cartAdditionalPropertyFileds = clone(this.state.cartAdditionalPropertyListMap);
            this.showAdditionalPropertiesPopup = true;
            this.$timeout(() => {
                this.additionalPropertiesPopupApi.open();
            });
        } else {
            this.saveOrderPromptPopupApi.open();
        }
    }

    closeAdditionalPropertiesPopup() {
        this.showAdditionalPropertiesPopup = false;
        this.additionalPropertiesPopupApi.close();
    }

    saveAdditionalProperties() {
        if (this.additionalPropertiesPopupForm.$valid) {
            this.showAdditionalPropertiesPopup = false;
            this.additionalPropertiesPopupApi.close();

            this.saveOrderPromptPopupApi.open();
        } else {
            const old = this.$location.hash();
            this.$location.hash('additionalPropertiesErrorField');
            this.$anchorScroll();
            this.$location.hash(old);
        }
    }

    onCartAdditionalPropertyListUpdated() {
        this.actions.setEditCartAdditionalProperty(this.state.cartAdditionalPropertyListMap);
    }

    onCartAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditCartAdditionalPropertyOnChange(id, newValue);
    }

    onAdditionalPropertyListUpdated(isOfferAdditionalProperty) {
        if (isOfferAdditionalProperty) {
            this.actions.setEditOfferAdditionalProperty(this.state.offerAdditionalPropertyListMap);
        } else {
            this.actions.setEditOrderAdditionalProperty(this.state.orderAdditionalPropertyListMap);
        }
    }

    onAdditionalPropertyChanged(id, newValue, isOfferAdditionalProperty) {
        if (isOfferAdditionalProperty) {
            this.actions.setEditOfferAdditionalPropertyOnChange(id, newValue);
        } else {
            this.actions.setEditOrderAdditionalPropertyOnChange(id, newValue);
        }
    }

    openRemoveOfferConfirmation() {
        this.removeOfferConfirmationPopupApi.open();
    }

    closeRemoveOfferConfirmation() {
        this.removeOfferConfirmationPopupApi.close();
    }

    resumeOrderErrorCallback() {
        this._resumeOrderErrorPopupApi.open();
    }

    closeResumeOrderErrorPopup() {
        this._resumeOrderErrorPopupApi.close();
    }

    cancelButtonText() {
        return (this.isResumingCancelOrderButton && !this.state.shoppingCartError) ? i18n.translate(CustomerCareKeys.CANCEL_RESUME) :
            i18n.translate(CustomerCareKeys.CANCEL_ORDER);
    }

    handleCloseSaveOrderErrorPopup() {
        this.saveOrderErrorPopupApi.close();
    }

    handleResultExistingCartPromptPopup(confirmed) {
        if (confirmed) {
            this.isCartExisting = true;
            this.actions.setIsResumingOrder(true);
            this.$state.reload();
        }

        this.existingCartPromptApi.close();
    }

    handleCloseExistingCartPromptPopup() {
        this.existingCartPromptApi.close();
    }

    handleResultUnsavedChangesPromptPopup(confirmed) {
        if (confirmed) {
            this.fullCartEditOfferingInstanceId ?
                this.handleEditOffer(this.fullCartEditOfferingInstanceId) :
                this.handleCancel();
        } else {
            this.fullCartEditOfferingInstanceId = null;
        }

        this.unsavedChangesPromptPopupApi.close();
    }

    openViewFullCartPopupDialog() {
        this.showViewFullCartPopup = true;
        this.$timeout(this.viewFullCartPopupApi.open);
    }

    onViewFullCartRequest() {
        if (this.state.asyncId && this.state.isCheckoutStep) {
            this.repeatRetrieveOfferingQuote();
            this.openViewFullCartPopupDialog();
        } else {
            if (!Object.keys(this.state.offeringOrderQuote).length) {
                this.actions.calculateOfferingOrderQuote(
                    this.state.customerInfo,
                    this.state.multiOfferShoppingCart,
                    !this.state.currentCustomerBillCycle ? this.state.billCycle : null,
                    this.state.executionOptions,
                    this.state.selectedShippingAddressAndMethod
                ).then(() => {
                    if (this.state.cartContainsOffcycleOffer || this.state.multiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItem) {
                        this.actions.retrieveOffCycleNextRenewalSchedules(this.state.customerInfo.Id, this.state.multiOfferShoppingCart.Items)
                            .catch((error) => {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            });
                    }
                    if (this.state.asyncId) {
                        this.repeatRetrieveOfferingQuote();
                    }
                    this.openViewFullCartPopupDialog();
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            } else {
                if (this.state.cartContainsOffcycleOffer || this.state.multiOfferCartContainsOfferWithAtleastOneBillerRuleCycleLevelAsItem) {
                    this.actions.retrieveOffCycleNextRenewalSchedules(this.state.customerInfo.Id, this.state.multiOfferShoppingCart.Items)
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
                if (this.state.asyncId) {
                    this.repeatRetrieveOfferingQuote();
                }
                this.openViewFullCartPopupDialog();
            }
        }
    }

    closeSendToStorePopup() {
        this.showSendToStorePopup = false;

        this.sendToStorePopupApi.close();
    }

    closeViewFullCartPopupDialog() {
        this.showViewFullCartPopup = false;
        this.viewFullCartPopupApi.close();
    }

    handleCloseUnsavedChangesPromptPopup() {
        this.unsavedChangesPromptPopupApi.close();
    }

    handleResultSaveOrderPromptPopup(confirmed) {
        this.saveOrderPromptPopupApi.close();
        if (confirmed) {
            if (this.state.isAttributesStep) {
                this.finishAttributes(false, true);
            } else if (this.state.isCheckoutStep) {
                if (this.state.cartAdditionalProperties.length) {
                    const shoppingCart = Object.assign({}, this.state.multiOfferShoppingCart, {
                        AdditionalProperties: this.state.cartAdditionalProperties
                    });

                    this.actions.updateShoppingCart(this.state.customerInfo.Id, shoppingCart).then(() => {
                        this.onOrderActionSuccess(CustomerCareKeys.SAVE_ORDER_SUCCESS);
                    }).catch((error) => {
                        this.saveOrderErrorMessage = error.translatedMessage;
                        this.saveOrderErrorPopupApi.open();
                    });
                } else {
                    this.actions.updateShoppingCart(this.state.customerInfo.Id, this.state.multiOfferShoppingCart).then(() => {
                        this.onOrderActionSuccess(CustomerCareKeys.SAVE_ORDER_SUCCESS);
                    }).catch((error) => {
                        this.saveOrderErrorMessage = error.translatedMessage;
                        this.saveOrderErrorPopupApi.open();
                    });
                }
            }
        }

        this.saveOrderPromptPopupApi.close();
    }

    handleCloseSaveOrderPromptPopup() {
        this.saveOrderPromptPopupApi.close();
    }

    openOrderConfirmationPopup() {
        this.$scope.orderConfirmationPopup.open();
    }

    handleCloseSubmitOrderErrorPopup() {
        this.submitOrderErrorPopupApi.close();
    }

    handleResultTransferOrderPopup() {
        this.$element.find('button[type="submit"]')[0].focus();
        this.$timeout(this.attemptNextStep);
        this.handleCloseSubmitTransferOrderPopup();
    }

    handleCloseSubmitTransferOrderPopup() {
        this.submitTransferOrderPopupApi.close();
    }

    handleCancel() {
        this.isLoading = true;
        this.actions.cancelOrder();
        if (!this.isResumingCancelOrderButton && !this.state.isResumingStoreOrder) {
            this.isRemovingOffer = true;
            this.actions.updateShoppingCart(this.state.customerId, {})
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                })
                .finally(() => {
                    this.isRemovingOffer = false;
                });
        }
        this.actions.setIsResumingOrder(false);
        this.actions.setIsResumingStoreOrder(false);
        this.deregisterUnsavedChanges();

        if (this.state.lastRoute.name && this.state.lastRoute.name !== STATE_OR_NAME &&
            this.state.lastRoute.params.customerId === this.state.customerId) {
            this.$state.go(this.state.lastRoute.name, this.state.lastRoute.params);
        } else {
            this.redirectToCustomer();
        }
    }

    onOrderActionSuccess(messageKey) {
        this.uiNotificationService.transientSuccess(i18n.translate(messageKey));
        this.redirectToCustomer();
    }

    deregisterUnsavedChanges() {
        this.actions.unregisterUnsavedChanges('addOfferWizard');
        this.actions.resetMobileNumberPortabilityModels();
    }

    searchAvailableOfferings() {
        const data = {
            ContractId: this.state.selectedContract ? this.state.selectedContract.Id : undefined,
            CurrencyCode: this.state.customerInfo.SubscriberCurrency,
            Facets: [],
            OfferingChargeType: null,
            OrderScenario: ORDER_SCENARIOS.ADD_OFFER,
            PageNumber: 1,
            PageSize: this.state.pageSize,
            SearchString: '',
            SortBy: OFFERINGS_SEARCH_CONSTANTS.SORT_BY.RELEVANCE,
            SortDirection: OFFERINGS_SEARCH_CONSTANTS.SORT_DIRECTION.DESCENDING
        };
        if (this.subscriberSubtenant) {
            data['Subtenants'] = this.subscriberSubtenant;
        }
        return this.actions.searchAvailableOfferings(data,
            {
                pageNumber: 1
            },
            undefined,
            this.state.routeParams.customerId
        );
    }

    onItemDownpaymentChanged(item) {
        this.actions.setDownPaymentOnMultiOfferShoppingCart(item);
    }

    onOfferSelected(offerId) {
        if (!this.state.changeOfferingInstanceId) {
            if (this.state.isResumingOrder) {
                this.actions.setIsResumingOrder(false);
            }
            if (this.state.isResumingStoreOrder) {
                this.actions.setIsResumingStoreOrder(false);
            }
        }

        this.actions.fetchDecisionsAndNavigate({
            offerId,
            customer: this.state.customerInfo,
            isConvergentBiller: this.state.isConvergentBiller,
            segmentationContext: [],
            shouldCalculateTaxes: !this.state.isDbss && !!this.state.validPaymentInfo,
            paymentInstrument: [this.state.validPaymentInfo],
            isMultiOfferEditing: this.state.isMultiOfferEditing,
            multiOfferShoppingCart: this.state.multiOfferShoppingCart,
            isDbss: this.state.isDbss,
            changeOfferingInstanceId: this.state.changeOfferingInstanceId,
            pickupId: this.state.pickupId
        }).then(() => {
            this.actions.retrieveOfferingsMetadata([offerId])
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            this.wizardForm.$setPristine();
        });
    }

    onOfferingDeselected() {
        if (this.state.isResumingOrder) {
            this.actions.setIsResumingOrder(false);
        }
    }

    goToPreviousStep() {
        if (this.state.isCheckoutStep) {
            this.actions.setPaymentInfo(this.state.paymentInfoEdit);
        }

        if (this.state.isAttributesStep &&
            this.isAttributesFormInvalid()) {
            this.wizardForm.$setSubmitted();
            this.actions.setAttributeFormSubmitted(true);
            return;
        }

        this.actions.goToPreviousStep();
        if (this.state.isDecisionsStep) {
            this.actions.setAttributeFormSubmitted(false);
            this.actions.setActiveAttributeFormName(null);
        }

        if (this.state.isOffersStep) {
            this.actions.setSelectedOfferingChargeTypesEditCopy(this.state.isMultiOfferCartPrepaid ?
                CustomerCareKeys.OFFERING_CHARGE_TYPES.PREPAID :
                CustomerCareKeys.OFFERING_CHARGE_TYPES.POSTPAID,
            true);
        }
    }

    cancelClick() {
        if (this.unsavedChangesPrompt.hasUnsavedChanges()) {
            this.unsavedChangesPromptPopupApi.open();
        } else {
            this.handleCancel();
        }
    }

    isAttributesFormInvalid() {
        return (this.wizardForm['attributesController.attributesForm'] &&
            this.wizardForm['attributesController.attributesForm'].$invalid) || !this.state.allAttributesAreValid;
    }

    isCheckoutFormInvalid() {
        return this.wizardForm['$ctrl.paymentInstrumentForm'] &&
            this.wizardForm['$ctrl.paymentInstrumentForm'].$invalid;
    }

    onStepSubmit() {
        if (this.state.isCheckoutStep) {
            this.checkoutStepSubmitHandler();
        }
    }

    onSubmit() {
        if (!this.state.isSubmittingOrder) {
            if (this.state.isTransferWizard && this.state.isCheckoutStep) {
                this.submitTransferOrderPopupApi.open();
            } else {
                this.$element.find('button[type="submit"]')[0].focus();
                this.$timeout(this.attemptNextStep);
            }
        }
    }

    handleSearchInventoryAvabilityResolve(isContinueShopping, isSaveOrder, isSendToStore) {
        if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
            if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                this.uiNotificationService.transientError(i18n.translate(this.customerCareKeys.ATTRIBUTES.UNAVAILABLE_SHIPPING_INVENTORY));
            } else {
                this.openStoreSearch();
            }
        } else if (isSaveOrder) {
            this.openSaveOrderPopup();
        } else if (isSendToStore) {
            this.openSendToStorePopup();
        } else {
            this.finishAttributes(isContinueShopping);
        }
        this.wizardForm.$setPristine();
    }

    getCompletedDecisions() {
        return [...this.state.serviceTaxDecisions, ...this.state.formattedDeliveryDecisions, ...this.state.completedDecisions];
    }

    handleAttributeValidations(isContinueShopping = false, isSaveOrder = false, isSendToStore = false, isSearchInventoryAvabilityComplete = false) {
        this.actions.setAttributeFormSubmitted(true);
        if (!this.isAttributesFormInvalid() && !duplicatedServiceIdentifierAttributes(this.state.formAttributeGroups).length) {
            this.actions.fetchAttributes({
                offeringId: this.state.selectedOfferId,
                offeringInstanceId: this.state.selectedOfferingInstanceId,
                completedDecisions: this.getCompletedDecisions(),
                completedAttributes: this.state.formattedAttributes,
                completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
                isConvergentBiller: true,
                isReconnectAction: false,
                changeOfferingInstanceId: this.state.changeOfferingInstanceId,
                useSavedShoppingCart: this.state.isResumingOrder,
                pickupId: this.state.pickupId
            }).then(() => {
                if (this.state.formattedPhysicalAttributes.length) {
                    //we are not using customerInfoEdit since we do not need to worry about editing customer information since this is not new-connect flow
                    this.actions.resetAvailableInventoryInformation();
                    this.actions.fetchAttributes({
                        offeringId: this.state.selectedOfferId,
                        offeringInstanceId: this.state.selectedOfferingInstanceId,
                        completedDecisions: this.getCompletedDecisions(),
                        completedAttributes: this.state.formattedAttributes,
                        completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
                        isConvergentBiller: this.state.isConvergentBiller,
                        isReconnectAction: false,
                        changeOfferingInstanceId: this.state.changeOfferingInstanceId,
                        useSavedShoppingCart: this.state.isResumingOrder,
                        pickupId: this.state.pickupId
                    }).then(() => {
                        this.storeSearchSettings = {
                            isContinueShopping,
                            isSaveOrder,
                            isSendToStore
                        };
                        if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                            this.actions.setInventoryRegionFilter(this.state.dbssShippingEnabledRegionId);
                        }
                        if (this.state.selectedInventoryStores.length < 1 && !this.state.isTransferWizard) {
                            this.openStoreSearch();
                            this.wizardForm.$setPristine();
                            this.isLoading = false;
                        } else if (!this.state.isTransferWizard) {
                            if (isSearchInventoryAvabilityComplete)  {
                                this.handleSearchInventoryAvabilityResolve(isContinueShopping, isSaveOrder, isSendToStore);
                            } else {
                                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
                                    .then(() => {
                                        this.handleSearchInventoryAvabilityResolve(isContinueShopping, isSaveOrder, isSendToStore);
                                    }).catch((err) => {
                                        this.showStoreInventoryPopup= true;
                                        this.$timeout(() => {
                                            this.storeSearchInventoryApi.open();
                                            this.onSearchInventoryAvailabilityFailure(err);
                                        });
                                    });
                            }
                        } else {
                            this.finishAttributes(isContinueShopping);
                        }
                    });
                } else if (isSaveOrder) {
                    this.openSaveOrderPopup();
                } else if (isSendToStore) {
                    this.openSendToStorePopup();
                } else {
                    this.finishAttributes(isContinueShopping);
                }
            }).catch((error) => {
                if (error.Code !== DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE) {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            });
        }
    }

    finishAttributes(isContinueShopping = false, isSaveOrder = false) {
        if (isContinueShopping) {
            if (this.state.changeOfferingInstanceId) {
                this.resetChange();
            }
            this.addAnotherOffer();
        } else {
            this._updateShoppingCart(isSaveOrder).then((reduxActionsStatuses) => {
                if (reduxActionsStatuses.isMultiOfferCartUpdated) {
                    if (isSaveOrder) {
                        this.onOrderActionSuccess(CustomerCareKeys.SAVE_ORDER_SUCCESS);
                    }
                }
                if (reduxActionsStatuses.isQuoteRefreshed) {
                    this.actions.setSelectedOfferingInstanceId(null);
                    this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
                }
            });
        }
        this.wizardForm.$setPristine();
    }

    updateCart(offerId,
        offeringInstanceId,
        completedDecisions,
        isModify,
        customerId,
        callback,
        contextOfferingAction,
        transitionContext,
        isConvergentBiller,
        customerLanguage) {
        this.actions.setDevicePaymentOptions([]);
        this.actions.setDecisionsTouched(true);
        this.actions.updateCart(
            offerId,
            offeringInstanceId,
            completedDecisions,
            isModify,
            customerId,
            callback,
            contextOfferingAction,
            transitionContext,
            isConvergentBiller,
            customerLanguage,
            this.state.pickupId,
            this.state.changeOfferingInstanceId,
            this.state.isResumingOrder);
    }

    addAnotherOffer() {
        this.actions.setIsResumingOrder(false);
        if (this.state.isCheckoutStep && this.state.isMultiOffer) {
            this.actions.resetForContinueShopping();
            if (this.state.isMultiOfferEditing) {
                this.resetEditingMultiOffer();
            }
            this.actions.clearQuote();
            this.actions.setSelectedOfferingChargeTypesEditCopy(this.state.isMultiOfferCartPrepaid ?
                CustomerCareKeys.OFFERING_CHARGE_TYPES.PREPAID : CustomerCareKeys.OFFERING_CHARGE_TYPES.POSTPAID, true);
        } else {
            const shoppingCart = this.state.isMultiOfferEditing || this.state.changeOfferingInstanceId ?
                this.state.editedMultiOfferShoppingCart :
                this.state.isCheckoutStep ?
                    this.state.multiOfferShoppingCart :
                    this.state.shoppingCart;
            return this.actions.updateMultiOfferShoppingCart(
                this.state.customerId,
                shoppingCart,
                (this.state.isMultiOffer && !(this.state.isMultiOfferEditing || this.state.changeOfferingInstanceId)))
                .then(() => {
                    this.actions.clearOfferingOrderQuote();
                    this.actions.retrieveShoppingCartOfferings(this.state.customerId).then(() => {
                        this.deregisterUnsavedChanges();
                        this.actions.resetForContinueShopping();
                        if (this.state.changeOfferingInstanceId) {
                            this.resetChange();
                        }
                        if (this.state.isMultiOfferEditing) {
                            this.resetEditingMultiOffer();
                        }
                        this.actions.clearQuote();
                        this.actions.setSelectedOfferingChargeTypesEditCopy(this.state.isMultiOfferCartPrepaid ?
                            CustomerCareKeys.OFFERING_CHARGE_TYPES.PREPAID : CustomerCareKeys.OFFERING_CHARGE_TYPES.POSTPAID, true);
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                }).catch((error) => {
                    this.saveOrderErrorPopupApi.open(error);
                });
        }
    }

    resetEditingMultiOffer() {
        this.actions.setSelectedOfferingInstanceId(null);
        this.actions.setDecisionsSelectedPageId(null);
    }

    changeOfferClick(offeringInstanceId, offeringId) {
        if (this.state.isMultiOffer) {
            if (this.state.isResumingStoreOrder) {
                this.handleResumeSendToStoreChangeOfferClick(offeringInstanceId, offeringId);
            } else {
                this.actions.resetForContinueShopping();
            }
            this.actions.clearQuote();
            this.actions.setChangeOfferingId(offeringId);
            this.actions.setChangeOfferingInstanceId(offeringInstanceId);
        } else {
            this.isLoading = true;
            this.actions.updateMultiOfferShoppingCart(this.state.customerInfo.Id, this.state.multiOfferShoppingCart, false)
                .then((response) => {
                    return this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                        .then(() => {
                            return this.changeOfferClick(response.ShoppingCart.Items[0].OfferingInstanceId, offeringId);
                        });
                }).catch((error) => {
                    return this.uiNotificationService.transientError(error.translatedMessage);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
        if (this.state.selectedShippingAddressAndMethod) {
            this.actions.updateShippingDetails({});
        }
    }

    handleSingleOfferChange() {
        if (this.state.isResumingStoreOrder) {
            this.handleResumeSendToStoreChangeOfferClick();
        } else {
            this.actions.updateShoppingCart(this.state.customerInfo.Id, this.state.shoppingCart).then((response) => {
                const offer = pathOr(null, ['ShoppingCart', 'Items', '0'], response);
                if (offer) {
                    this.changeOfferClick(offer.OfferingInstanceId, offer.OfferingId);
                    this.actions.clearQuoteAndSetSelectedOffer(null);
                    this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.OFFERS);
                }
            });
        }
    }

    handleResumeSendToStoreChangeOfferClick(offeringInstanceId, offeringId) {
        this.actions.cancelStoreOrder(this.state.customerInfo.Id)
            .then(() => {
                this.handleSendToStore(true, offeringInstanceId, offeringId);
                this.actions.resetForContinueShopping();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    editOfferClick(offeringInstanceId) {
        if (this.state.isMultiOffer) {
            this.fullCartEditOfferingInstanceId = offeringInstanceId;
            if (this.unsavedChangesPromptPopupApi && !this.state.isCheckoutStep) {
                // TODO: CustomerOrder - should update this to be handled more intelligently (if something is modified)
                // which means unsavedChanges will have to be reworked for the add offer flow.
                this.unsavedChangesPromptPopupApi.open();
            } else {
                this.handleEditOffer();
            }
        } else {
            this.isLoading = true;
            this.actions.updateMultiOfferShoppingCart(this.state.customerInfo.Id, this.state.multiOfferShoppingCart, false)
                .then((response) => {
                    return this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                        .then(() => {
                            return this.editOfferClick(response.ShoppingCart.Items[0].OfferingInstanceId);
                        });
                }).catch((error) => {
                    return this.uiNotificationService.transientError(error.translatedMessage);
                }).finally(() => {
                    this.isLoading = false;
                });
        }
        if (this.state.editModalSideNavList) {
            this.actions.setCheckoutDetailsValidation([]);
        }
        if (this.state.selectedShippingAddressAndMethod) {
            this.actions.updateShippingDetails({});
        }
    }

    handleEditOffer() {
        if (this.showViewFullCartPopup) {
            this.closeViewFullCartPopupDialog();
        }
        this.actions.resetForContinueShopping();
        this.actions.clearQuote();
        this.actions.setIsResumingOrder(true);
        this.actions.setSelectedOfferingInstanceId(this.fullCartEditOfferingInstanceId);
        this.actions.setDecisionsSelectedPageId(null);
        this.actions.resumeOrder(
            this.state.currentCustomer,
            this.resumeOrderErrorCallback,
            this.state.isConvergentBiller,
            !this.state.isDbss && !!this.state.validPaymentInfo,
            [this.state.validPaymentInfo],
            this.fullCartEditOfferingInstanceId,
            this.state.isMultiOfferEditing,
            this.state.pickupId
        ).finally(() => {
            this.fullCartEditOfferingInstanceId = null;
        });
    }

    handleRemoveOffer(offeringInstanceId) {
        this.actions.setRemoveOfferInstanceId(offeringInstanceId);
        // From checkout step we know this is remove offer that has been saved into shopping cart with an offer instance id
        if (this.state.isCheckoutStep) {
            if (this.state.offersCount > 1) {
                this.openRemoveOfferConfirmation();
            } else {
                this.unsavedChangesPromptPopupApi.open(); //In the future we shouldn't use unsavedChangesPromptPopup, create a different popup with a different message .
            }
        } else if (this.state.isMultiOfferEditing) {
            // If we are editing an offer we also know that it has been saved into shopping cart with an offer instance id
            this.state.offersCount > 1 ? this.openRemoveOfferConfirmation() : this.unsavedChangesPromptPopupApi.open(); //In the future we shouldn't use unsavedChangesPromptPopup, create a different popup with a different message .
        } else {
            //You are in the middle of adding an unsaved offer but you are removing a previous offer
            this.openRemoveOfferConfirmation();
        }
    }

    removeOffer(confirm) {
        if (confirm) {
            if (this.showViewFullCartPopup) {
                this.closeViewFullCartPopupDialog();
            }
            this.isRemovingOffer = true;
            if (!this.state.isCheckoutStep && !this.state.isRemovingOfferSavedToMultiOfferCart) {
                this.cancelAddingOrEditingOffer();
            } else {
                this.actions.updateShoppingCart(this.state.customerId, this.state.shoppingCartWithoutRemovedOffer)
                    .then(() => {
                        this.actions.setIsResumingOrder(false); // Needed to prevent the handle cancel from thinking its still in resume
                        this.actions.clearOfferingOrderQuote();
                        return this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                            .then(() => {
                                if (this.state.isCheckoutStep) {
                                    return this.actions.calculateOfferingOrderQuote(
                                        this.state.customerInfo,
                                        this.state.multiOfferShoppingCart,
                                        undefined,
                                        undefined,
                                        undefined,
                                        undefined,
                                        this.overrideBypassQuoteConfiguration
                                    ).then(() => {
                                        if (this.state.asyncId) {
                                            this.repeatRetrieveQuote();
                                        }
                                    }).catch((error) => {
                                        this.uiNotificationService.transientError(error.translatedMessage);
                                    });
                                }
                            })
                            .catch((error) => {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            });
                    })
                    .catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    })
                    .finally(() => {
                        this.actions.setRemoveOfferInstanceId(null);
                        this.isRemovingOffer = false;
                    });
            }
        } else {
            this.actions.setRemoveOfferInstanceId(null);
        }
        this.closeRemoveOfferConfirmation();
    }

    cancelAddingOrEditingOffer() {
        // Go to checkout step and set the step progress bar with previous steps completed with no skipped steps
        this.actions.setSelectedOfferingInstanceId(null);
        this.actions.setDecisionsSelectedPageId(null);
        if (this.state.changeOfferingInstanceId) {
            this.resetChange();
        }

        if (this.state.pickupId) {
            this.actions.resumeStoreOrder(this.state.customerInfo, false, this.overrideBypassQuoteConfiguration).then((response) => {
                if (response.Warnings && !!(response.Warnings.find((warning) => {
                    return warning.Code === this.resumeOrderWarning;
                }))) {
                    this.openInsufficientPermissionsWarningPopup();
                    return Promise.resolve();
                }
            });
        } else {
            this.actions.retrieveShoppingCartOfferings(this.state.customerInfo.Id)
                .then((response) => {
                    this.actions.clearQuote();
                    return this.actions.calculateOfferingOrderQuote(
                        this.state.customerInfo,
                        response.ShoppingCart,
                        undefined,
                        undefined,
                        undefined,
                        undefined,
                        this.overrideBypassQuoteConfiguration
                    ).then(() => {
                        if (this.state.asyncId) {
                            this.repeatRetrieveQuote();
                        }
                        this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
                        this.actions.multiOfferSetCompletedSteps();
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    attemptNextStep(isContinueShopping = false, isSendToStore = false) {
        if (this.state.isSavingOrder ||
            this.state.isCalculatingQuote ||
            this.state.isSubmittingOrder ||
            this.state.isRetrievingMultiOfferCart ||
            this.state.isSendingInventoryToStore ||
            this.isLoading ||
            this.state.isFetchingAnyData) {
            return;
        }
        // isContinueShopping uses the logic that already exists in step navigation and validations.
        let dirty = true;
        // NOTE: this may register non-existant (extraneous) "unsaved
        // changes" in the case that the user tries to proceed to the next
        // step, but has not dirtied the form
        this.actions.registerUnsavedChanges('addOfferWizard', {
            isDirty: () => {
                return dirty;
            },
            setPristine: () => {
                dirty = false;
            }
        });

        if (this.state.isOffersStep) {
            if (!this.state.currentStepIsInvalid) {
                this.actions.goToNextStep();
                this.wizardForm.$setPristine();
            }
        } else if (this.state.isAttributesStep && !this.state.hasRequiredAndUnavailableInventory) {
            this.handleAttributeValidations(isContinueShopping, false, isSendToStore);
        } else if (this.state.isCheckoutStep) {
            if (isContinueShopping) {
                // This will need to be changed as ASCFTR-2830 progresses.
                this.addAnotherOffer();
                this.actions.resetOfferAdditionalProperties();
            } else if (isSendToStore) {
                this.openSendToStorePopup();
            } else {
                if (!this.isCheckoutFormInvalid()) {
                    this.validateAddressWithAPI()
                        .then(() => {
                            const addressKey = this.getAddressKey();
                            const validatedAddress = this.state.validatedAddresses[addressKey];
                            if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                                let paymentInfo;
                                // if statement handled this way to make sure it has an Id, otherwise it returns a function if null
                                if (pathOr(false, ['Id'], this.state.selectedPayment)) {
                                    paymentInfo = (this.state.supplyPaymentInstrument || this.state.paymentInstrumentRequired)
                                        ? this.state.selectedPaymentClean : null;
                                } else {
                                    this.actions.setPaymentInfo(this.state.paymentInfoEdit);
                                    paymentInfo = this.state.paymentInfo;
                                    if (this.state.paymentInfo && this.state.paymentInfo.BillingAddressIsServiceAddress) {
                                        paymentInfo = Object.assign({}, paymentInfo, {
                                            BillingAddressId: this.state.serviceAddress.Id
                                        });
                                    }
                                    if (this.state.paymentInfo && !this.state.paymentInfo.BillingAddressIsServiceAddress) {
                                        const billingAddress = Object.assign({}, paymentInfo.BillingAddress, {
                                            Name: this.state.paymentInfo.BillingAddress.LineOne // Name is required by the API and needs to be unique.
                                        });
                                        paymentInfo = Object.assign({}, paymentInfo, {
                                            BillingAddress: billingAddress
                                        });
                                    }
                                }
                                const billCycle = !this.state.currentCustomerBillCycle ? this.state.billCycle : null;
                                this.actions.setCustomerAutoPayBannerFlag(this.state.selectedPaymentMethodAutoPay);
                                if (this.state.currentCustomerCreditLimit) {
                                    this.checkCreditAndFinanceLimitAndSubmitOrder(billCycle, this.state.multiOfferShoppingCart, paymentInfo);
                                } else {
                                    this.addOfferSubmitOrder(billCycle, this.state.multiOfferShoppingCart, paymentInfo);
                                }
                            } else {
                                this.actions.setForceShowEditFormAddress(false);
                                this.uiNotificationService.transientError(i18n.translate(this.customerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                            }
                        }).catch((err) => {
                            this.uiNotificationService.transientError(err.translatedMessage);
                        });
                }
            }
        } else if (!this.state.currentStepIsInvalid) {
            if (this.state.isDecisionsStep && !this.state.hasDecisionValidationError) {
                if (((this.state.isResumingOrder && !this.state.shoppingCartError) || this.state.isResumingStoreOrder) &&
                    (!this.state.changeOfferingInstanceId && !this.state.isMultiOfferEditing)) {
                    this.actions.resumeOrderAttributes(
                        this.state.customerId,
                        this.state.isConvergentBiller,
                        false,
                        this.state.selectedOfferingInstanceId,
                        this.state.pickupId,
                        this.state.completedDecisions,
                        this.state.selectedOfferId
                    ).then(() => {
                        return this.actions.updateCart(
                            this.state.selectedOfferId,
                            this.state.selectedOfferingInstanceId,
                            this.state.completedDecisions,
                            false,
                            this.state.customerId,
                            undefined,
                            undefined,
                            undefined,
                            true,
                            this.state.currentCustomer.Language,
                            this.state.pickupId,
                            undefined,
                            this.state.isResumingOrder
                        );
                    }).then(() => {
                        if (this.state.selectedDeliveryDecision) {
                            this.handleDeliveryDecision();
                        }
                        this.wizardForm.$setPristine();
                    });
                } else {
                    this.actions.fetchAttributesAndNavigate({
                        offerId: this.state.selectedOfferId,
                        offeringInstanceId: this.state.selectedOfferingInstanceId,
                        completedDecisions: this.state.completedDecisions,
                        customer: this.state.customerInfo,
                        completedAttributes: this.state.formattedAttributes,
                        completedPhysicalAttributes: [],
                        shouldCalculateTaxes: !!this.state.validPaymentInfo,
                        paymentInstrument: [this.state.validPaymentInfo],
                        isMultiOfferEditing: this.state.isMultiOfferEditing,
                        isDbss: this.state.isDbss,
                        changeOfferingInstanceId: this.state.changeOfferingInstanceId,
                        pickupId: this.state.pickupId,
                        useSavedShoppingCart: this.state.isResumingOrder,
                        isMultiOffer: this.state.isMultiOffer
                    }).then(() => {
                        if (this.state.selectedDeliveryDecision) {
                            this.handleDeliveryDecision();
                        }
                        this.wizardForm.$setPristine();
                    });
                }
            }
        } else {
            this.$log.warn('The current step has errors');
        }
    }

    checkCreditAndFinanceLimitAndSubmitOrder(billCycle, shoppingCart, paymentInfo) {
        const isFinanceCreditLimitBreached = this.validateFinanceCreditLimitFromQuote();
        if (!this.state.isCreditLimitBreached && !isFinanceCreditLimitBreached) {
            this.addOfferSubmitOrder(billCycle, shoppingCart, paymentInfo);
        }
    }

    validateFinanceCreditLimitFromQuote() {
        return this.state.availableFinanceCreditFromQuote > 0;
    }

    handleDeliveryDecision() {
        if (this.state.selectedDeliveryDecision !== this.state.selectedDeliveryDecisionFromRoc) {
            this.actions.setDeliveryDecision(this.state.selectedDeliveryDecisionFromRoc);
            this.actions.setSelectedInventoryStores([]);
        }
    }

    addOfferSubmitOrder(billCycle, shoppingCart, paymentInfo) {

        const transferFromSubscriberId = this.state.isTransferWizard ? Object.assign({}, {
            TransferFromSubscriberId: this.state.transferFromSubscriberId
        }) : null;
        const physicalInventoryDetails = this.state.subscriberPhysicalInventoryPickupDetail.id ? Object.assign({}, {
            SubscriberPhysicalInventoryPickupDetail: {
                Id: this.state.subscriberPhysicalInventoryPickupDetail.id,
                Comments: this.state.subscriberPhysicalInventoryPickupDetail.comments,
                PickupDate: this.state.subscriberPhysicalInventoryPickupDetail.date
            }
        }) : null;
        return this.actions.submitAddOrder(
            this.state.customerInfo,
            Object.assign({}, shoppingCart, transferFromSubscriberId, physicalInventoryDetails),
            paymentInfo,
            billCycle,
            this.state.executionOptions,
            this.state.recordPaymentInfo,
            this.state.isTransferWizard, // Uses the same actions because they have the same schema / requirements
            this.state.orderAdditionalProperties,
            this.state.selectedShippingAddressAndMethod,
            this.state.orderDetails,
            this.state.isMultiOffer ? undefined :  this.state.offerAdditionalProperties
        ).then(() => {
            if (this.state.isResumingStoreOrder) {
                this.actions.setIsResumingStoreOrder(false);
            }
            this.deregisterUnsavedChanges();
            this.$scope.orderConfirmationPopup.open();
        }).catch((error) => {
            error.Code === convertStringToNumber(FaultCodeKeys.LITE_CUSTOMER_SUBMISSION_ERROR_CODE) ?
                this.openEditProfileInformationPopup() : this.submitOrderErrorPopupApi.open(error);
        });
    }

    recalculateOrderQuote(updatedPaymentInfo) {
        const nextTaxCountry = pathOr(null, ['BillingAddress', 'Country'], updatedPaymentInfo);
        const nextTaxState = pathOr(null, ['BillingAddress', 'State'], updatedPaymentInfo);

        if ((!this.currentTaxCountry || this.currentTaxCountry !== nextTaxCountry) ||
            (!this.currentTaxState || this.currentTaxState !== nextTaxState)) {
            this.actions.calculateAddOfferOrderQuoteWithTaxes(
                this.state.customerInfo,
                this.state.multiOfferShoppingCart,
                updatedPaymentInfo ? [updatedPaymentInfo] : [this.state.paymentInfoEdit],
                this.state.isDbss,
                this.state.executionOptions,
                this.state.billCycle,
                this.state.shippingAddress ? true: false
            ).then(() => {
                if (this.state.asyncId) {
                    this.repeatRetrieveQuote();
                }
                this.currentTaxCountry = nextTaxCountry;
                this.currentTaxState = nextTaxState;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    recallOfferingContext() {
        this.actions.fetchAttributes({
            offeringId: this.state.selectedOfferId,
            offeringInstanceId: this.state.selectedOfferingInstanceId,
            completedDecisions: this.getCompletedDecisions(),
            completedAttributes: this.state.formattedAttributes,
            completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
            isConvergentBiller: true,
            isReconnectAction: false,
            changeOfferingInstanceId: this.state.changeOfferingInstanceId,
            useSavedShoppingCart: this.state.isResumingOrder,
            pickupId: this.state.pickupId
        })
            .catch((error) => {
                if (error) {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            });
    }

    tabSelected(selectedTab) {
        const selectedIndex = findIndex((tab) => {
            return tab === selectedTab;
        })(this.state.tabs);

        this.actions.setSelectedTab(selectedIndex);
    }

    redirectToCustomer() {
        this.$scope.orderConfirmationPopup.close();
        this.deregisterUnsavedChanges();
        this.$state.go(CUSTOMER_URL, {
            'customerId': this.state.isTransferWizard ? this.state.transferFromSubscriberId : this.state.customerInfo.Id,
            'showAutoPayBanner': {
                enabled: this.state.showCustomerAutoPayBanner,
                customerId: this.state.customerInfo.Id
            }
        }, {
            reload: true
        });
    }

    launchSaveOrder() {
        if (this.state.isAttributesStep) {
            this.handleAttributeValidations(false, true);
        } else if (this.state.isCheckoutStep) {
            this.openSaveOrderPopup();
        }
    }

    handlePreviewRenewalStatements() {
        this._openPreviewRenewalStatementsPopup();
    }

    handleClosePreviewRenewalStatementsDialog() {
        this._closePreviewRenewalStatementsPopup();
    }

    _openPreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = true;
        this.$timeout(this._previewRenewalStatementsPopupApi.open);
    }

    _closePreviewRenewalStatementsPopup() {
        this.showPreviewRenewalStatementsDialog = false;
        this._previewRenewalStatementsPopupApi.close();
    }

    // Handler to open the modal dialog that allows the CSR to select a Store.
    onSelectInventoryStore() {
        if (this.state.isCheckoutStep) {
            this.openStoreSearch(true);
        } else {
            if (this.state.selectedInventoryRegion) {
                this.actions.setInventoryRegionFilter(this.state.selectedInventoryRegion);
            }
            this.showSelectStorePopup = true;
            this.$timeout(() => {
                this.selectStorePopupApi.open();
            });
        }
    }

    /**
     * Updates Shopping Cart for Multi-Offer & Refreshes Shopping Cart for Single offer; on Attributes & Checkout Step.
     * Ensures the correct Store ID is configured for all items in the Shopping Cart (both Single & Multi-offer).
     */
    _updateShoppingCart(isSaveOrder = false, isSendToStore = false, isConfirmation = false) {
        const reduxActionsStatuses = {
            isMultiOfferCartUpdated: false,
            isQuoteRefreshed: false, // both Single & Multi-Offer
            isSingleOfferValidated: false
        };
        if (!isConfirmation && (this.state.isAttributesStep || this.state.isCheckoutStep)) {
            if (this.state.isMultiOffer || isSaveOrder) {
                let modifiedShoppingCartForAPIRequest;
                const shoppingCartToUpdateStoreIdOn = this.state.isAttributesStep ?
                    (this.state.isMultiOfferEditing || isSendToStore || this.state.changeOfferingInstanceId || this.state.isMultiOffer ? this.state.editedMultiOfferShoppingCart : this.state.shoppingCart) :
                    this.state.multiOfferShoppingCart;
                modifiedShoppingCartForAPIRequest = (this.state.isInventoryStoreChanged && this.state.selectedInventoryStores && this.state.selectedInventoryStores.length) ?
                    getShoppingCartWithUpdatedStoreIdForAPIRequest(shoppingCartToUpdateStoreIdOn, this.state.selectedInventoryStores[0].Value) : shoppingCartToUpdateStoreIdOn;
                const isAppendItemsToCart = this.state.isAttributesStep ? this.state.isMultiOffer && !(this.state.isMultiOfferEditing || this.state.changeOfferingInstanceId) : false;
                let cartItemsObj;
                if (this.state.isMultiOffer) {
                    cartItemsObj = getMultiOfferShoppingCartWithUpdatedDeliveryDecision(modifiedShoppingCartForAPIRequest, this.state.selectedDeliveryDecision);
                    modifiedShoppingCartForAPIRequest = cartItemsObj.cartItems;
                }

                // Invoking update shopping cart also validates the items in the cart.
                const customerId = this.state.customerInfo.Id;
                const updateShoppingCartPromise = this.state.isCheckoutStep ?
                    this.actions.replaceMultiOfferShoppingCart(customerId, modifiedShoppingCartForAPIRequest) :
                    this.actions.updateMultiOfferShoppingCart(customerId, this.state.cartAdditionalProperties.length ? Object.assign({}, modifiedShoppingCartForAPIRequest, {
                        AdditionalProperties: this.state.cartAdditionalProperties
                    }) : modifiedShoppingCartForAPIRequest, cartItemsObj ? cartItemsObj.isAppend : isAppendItemsToCart);

                return updateShoppingCartPromise.then(() => {
                    if (this.state.changeOfferingInstanceId) {
                        this.resetChange();
                    }

                    if (this.state.isInventoryStoreChanged && isAppendItemsToCart && !this.state.isMultiOfferEditing) {
                        // This happens on Attributes Step when adding a new Offer on the multi-offer flow.
                        // And the CSR changes the Store. Need to update Store ID for the previously added offers.
                        const modifiedAllItemsInShoppingCartForAPIRequest = getShoppingCartWithUpdatedStoreIdForAPIRequest(
                            this.state.multiOfferShoppingCart,
                            this.state.selectedInventoryStores[0].Value
                        );
                        return this.actions.replaceMultiOfferShoppingCart(customerId, modifiedAllItemsInShoppingCartForAPIRequest);
                    }
                }).then(() => {
                    this.actions.clearOfferingOrderQuote();
                    reduxActionsStatuses.isMultiOfferCartUpdated = true;
                    // You can reset the Inventory Store Change flag, because all Physical Inventory Items in the Shopping Cart now have the same Store ID.
                    this.actions.setInventoryStoreChangedFlag(false);
                    if (!isSaveOrder) {
                        return this.actions.retrieveShoppingCartOfferings(customerId);
                    } else {
                        return null;
                    }
                }).then((retrieveShoppingCartOfferingsResponse) => {
                    if (retrieveShoppingCartOfferingsResponse !== null && !isSaveOrder) {
                        this.actions.clearQuote();
                        if (isSendToStore) {
                            return Promise.resolve(reduxActionsStatuses);
                        } else {
                            return this.actions.calculateOfferingOrderQuote(
                                this.state.customerInfo,
                                retrieveShoppingCartOfferingsResponse.ShoppingCart,
                                this.state.billCycle,
                                this.state.executionOptions,
                                undefined,
                                this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED,
                                this.overrideBypassQuoteConfiguration
                            ).catch((error) => {
                                return Promise.reject(error);
                            });
                        }
                    } else {
                        return null;
                    }
                }).then(() => {
                    if (this.state.asyncId) {
                        this.repeatRetrieveQuote();
                    }

                    reduxActionsStatuses.isQuoteRefreshed = !isSaveOrder;

                    return reduxActionsStatuses;
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);

                    return Promise.reject(reduxActionsStatuses);
                });
            } else {
                if (!this.state.isCheckoutStep) {
                    this.actions.setMultiOfferShoppingCart(this.state.shoppingCart);
                }

                if (isSendToStore) {
                    return Promise.resolve(reduxActionsStatuses);
                } else {
                    this.actions.clearQuote();

                    const shoppingCartWithUpdateStoreId = (this.state.isInventoryStoreChanged && this.state.selectedInventoryStores && this.state.selectedInventoryStores.length) ?
                        getShoppingCartWithUpdatedStoreIdForAPIRequest(this.state.multiOfferShoppingCart, this.state.selectedInventoryStores[0].Value) : this.state.multiOfferShoppingCart;

                    return this.actions.calculateOfferingOrderQuote(
                        this.state.customerInfo,
                        shoppingCartWithUpdateStoreId,
                        this.state.billCycle,
                        this.state.executionOptions,
                        undefined,
                        this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED,
                        this.overrideBypassQuoteConfiguration
                    ).then(() => {
                        if (this.state.asyncId) {
                            this.repeatRetrieveQuote();
                        }

                        reduxActionsStatuses.isQuoteRefreshed = true;
                        return reduxActionsStatuses;
                    }).catch((error) => {
                        return Promise.reject(error);
                    });
                }
            }
        } else {
            return Promise.resolve(reduxActionsStatuses);
        }
    }

    closeSelectStorePopupDialog(isApplyChanges, selectedStore) {
        this.showSelectStorePopup = false;
        if (isApplyChanges) {
            this.actions.setSelectedInventoryStores([selectedStore]);
        }
        this.actions.setInventoryRegionFilter(null);
        this.selectStorePopupApi.close();
    }

    onInventoryRegionFilterChanged(region) {
        this.actions.setInventoryRegionFilter(region);
    }

    onInventoryRegionFilterChangedForStoreSearch(region) {
        this.actions.setInventoryRegionFilter(region);
        this.actions.resetAvailableInventoryInformation();
        const formattedInventories = this.state.physicalInventories,
            formattedPayload = !this.state.formattedInventories ? {
                InventoryTypes: formattedInventories,
                RegionId: region
            } : this.state.formattedPhysicalInventories;

        this.actions.searchInventoriesAvailability(formattedPayload)
            .catch(this.onSearchInventoryAvailabilityFailure);
    }

    closeSearchInventoryPopupDialog() {
        this.showStoreInventoryPopup = false;
        this.actions.setInventoryRegionFilter(null);
        this.storeSearchInventoryApi.close();
        if (this.isInventoryItemReservationFault) {
            this.openSendToStorePopup();
        }
    }

    setSelectedStoreForSearchModal(selectedStore) {
        this.actions.setSelectedInventoryStores([selectedStore]);
        this.showStoreInventoryPopup = false;
        this.storeSearchInventoryApi.close();
        if (this.state.isAttributesStep) {
            this.handleAttributeValidations(
                this.storeSearchSettings.isContinueShopping,
                this.storeSearchSettings.isSaveOrder,
                this.storeSearchSettings.isSendToStore,
                true
            );
        } else {
            this._updateShoppingCart();
        }
    }

    openStoreSearch(isSearchInventoriesAvailability = false) {
        this.showStoreInventoryPopup= true;
        this.$timeout(() => {
            this.storeSearchInventoryApi.open();
            if (isSearchInventoriesAvailability) {
                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories).then(() => {
                    if (this.isInventoryItemReservationFault) {
                        this.isInventoryItemReservationFault = false;
                    }
                }).catch(this.onSearchInventoryAvailabilityFailure);
            }
        });
    }

    getAddressKey() {
        return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        /*
            This will only get called if the payment instrument component appears which should
            only occure if they have no defaultBilling Payment method
        */
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes =  {
                DefaultBilling: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    setCustomerTypeChangeReadOnly() {
        const alreadyHasCreditCheckClassification = !!this.state.currentCustomer.CreditCheckClassification;
        const hasEditTypeAccess = hasAccess(this.state.userSecurityAttributes, SUBSCRIBER_TYPE_EDIT_ACCESS);
        this.isCustomerTypeChangeReadOnly = alreadyHasCreditCheckClassification && !hasEditTypeAccess && !this.state.isCreatingCustomer;
    }

    openEditProfileInformationPopup() {
        this.showCustomerPopup = true;
        this.$timeout(() => {
            this.actions.setEditAdditionalProperty(this.state.currentCustomer.AdditionalProperties);
            this.editProfileInformationPopupApi.open();
        });
    }

    closeEditProfileInformationPopup() {
        this.showCustomerPopup = false;
        this.editProfileInformationPopupApi.close();
    }

    onSubmitEditCustomerForm() {
        this.showLoadingIndicator = true;
        this.closeEditProfileInformationPopup();
        this.actions.retrieveAvailablePaymentInstrumentTypes();
        this.actions.retrieveWalletAndSelectDefaultPaymentMethod({
            customerId: this.state.customerId
        });
        this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareKeys.PROFILE_PAGE.UPDATE_SUCCESS_MESSAGE));
    }

    resetChange() {
        this.actions.setChangeOfferingId(null);
        this.actions.setChangeOfferingInstanceId(null);
    }

    $onDestroy() {
        if (!this.isCartExisting) {
            this.actions.setIsResumingOrder(false);
        }
        this.resetChange();
        this.actions.setTransferFromName('');
        this.disconnectRedux();
    }
}

export default {
    template: require('./add.offer.wizard.html'),
    controller: AddOfferWizardController,
    controllerAs: 'addOfferController'
};
