import moment from 'moment';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    CurrentBusinessUnitCurrencyCodeSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {retrieveProductsMetadata} from 'invision-core/src/components/metadata/products/products.actions';
import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';
import without from 'ramda/src/without';
import CustomerCareKeys from '../../../../../../../locales/keys';
import {
    clearCartSummaryForWaiveCharge,
    retrieveOrderContext,
    setPreviouslySelectedPaymentInstrumentIds,
    setProductOrderCouponCodes,
    setProductOrderDiscretionaryDiscounts,
    updateSearchCatalogParams
} from '../../../../../../../reducers/actions/products.order.actions';
import {
    CurrencySelector,
    CurrentSubscriptionHasLateFeeSelector,
    CurrentSubscriptionSelector,
    ShippingAddressSelector
} from './../../../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    IsModifyingModeSelector,
    IsReplacingModeSelector,
    IsRestoringModeSelector,
    SubscriptionsOnlySelector
} from '../../create.products.order.wizard.selectors';
import {
    BillingEffectiveDateOptionsSelector,
    BillingEffectiveDateSettingsSelector,
    CartContainsProductWithBillerRuleCycleLevelOtherThanItemSelector,
    DiscretionaryDiscountsIdSelector,
    DiscretionaryDiscountsReasonSelector,
    DiscretionaryDiscountsSelector,
    DoPaymentInstrumentsCoverOrderTotalSelector,
    HasPrepaidPricingPlanSelector,
    InvalidCouponRedemptionViewModelSelector,
    IsDbssShoppingCart,
    IsSelectedProductAdHoc,
    IsSelectedProductDbss,
    IsServiceFeatureShoppingCart,
    IsValidatingCoupon,
    ModifiedSubscriptionItemSelector,
    OrderQuoteIsValidSelector,
    OrderRequiresShippingSelector,
    PreviouslySelectedPaymentInstrumentIdsSelector,
    SearchCatalogParamsSelector,
    SelectablePaymentInstrumentForServiceFeaturesSelector,
    SelectablePaymentInstrumentSelector,
    ShippingMethodsViewModel,
    ShoppingCartItemDetailsLocationCodesSelector,
    ShoppingCartSelector,
    UnfilteredCouponCodesSelector,
    UngiftableShoppingCartItemSelector,
} from '../../../../../../../reducers/selectors/products.order.selectors';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIdSelector,
    RouteParams
} from '../../../../../../../reducers/selectors/customer.selectors';
import {
    PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector,
    PaymentInstrumentsWithoutBillToInvoiceSelector,
    PaymentIsFetchingDataSelector,
    RecordPaymentInformationSelector,
    SelectedPaymentMethodSelector
} from '../../../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    CurrentHouseholdSelector,
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector
} from '../../../../../../../reducers/selectors/customer.household.selectors';
import {setDiscountForm} from '../../../../../../../reducers/actions/discount.actions';
import {
    ChangeImmediateSelector,
    IsProductWizardRestoreOrderWaivingFeeSelector,
    IsProductWizardRestoreOrderWaivingLateFeeSelector,
    IsShippingAddressSameAsBillingSelector,
    IsShippingMethodsAvailableSelector,
    OttExecutionOptionsSelector,
    PaymentInstrumentsSelector,
    PaymentInstrumentIdsSelector,
    PaymentInstrumentIdsValidInQuoteSelector,
    ProductOrderShippingAddressIdSelector,
    ProductWizardGiftOrderInfoSelector,
    ProductWizardIsGiftOrderSelector,
    ProductWizardRestoreOrderWaiveFeeReasonSelector,
    ProductWizardRestoreOrderWaiveLateFeeReasonSelector,
    ProductWizardTemporaryShipToNameSelector
} from '../../../../../../../reducers/selectors/products.wizard.selectors';
import {retrieveCustomerAddresses} from '../../../../../../../reducers/actions/customer.addresses.actions';
import {
    setChangeImmediately,
    setGiftOrderInfo,
    setIsGiftOrder,
    setIsShippingAddressSameAsBilling,
    setOrderDetails,
    setOttExecutionOptions,
    setPaymentInstruments,
    setPaymentInstrumentIds,
    setProductOrderShippingAddressId,
    setRestoreOrderWaiveFee,
    setRestoreOrderWaiveFeeReason,
    setRestoreOrderWaiveLateFee,
    setRestoreOrderWaiveLateFeeReason,
    setSelectedShippingMethodId,
    setTemporaryShipToName
} from '../../../../../../../reducers/actions/products.wizard.actions';
import {
    addUnsavedPaymentMethod,
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWallet,
    setRecordPaymentInstrument
} from '../../../../../../../reducers/actions/customer.ewallet.actions';
import {retrieveCustomerHouseholds} from '../../../../../../../reducers/actions/customer.household.actions';
import {getDefaultPaymentMethodFromPaymentInstruments} from '../../../../../../../reducers/helpers/ewallet.reducer.helper';
import {
    ORDER_TYPE_CODE,
    REGEXES
} from '../../../../../../../customercare.constants';
import {
    CurrentActiveAddressesSelector,
    CurrentAddressesSelector,
    DefaultShippingAddressSelector,
} from '../../../../../../../reducers/selectors/customer.addresses.selectors';
import {GENERAL_STATUSES} from '../../../../../../shared/constants/general.status.constants';
import {SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES} from '../../../../../ewallet/ewallet.constants';
import {CandidateBillCyclesSelector} from '../../../../../../../reducers/selectors/customer.billcycle.selectors';
import {retrieveCandidateBillCycles} from '../../../../../../../reducers/actions/customer.billcycle.actions';
import {
    GetMaxDateForFutureDatedOrder,
    IsFutureDatedOrderAllowedInBunt
} from '../../../../../../../reducers/selectors/selected.offering.order.selectors';
import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';


const CONVERGENT_BILLER_INVOICE = Number(SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES.CONVERGENT_BILLER_INVOICE);

class CheckoutController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            addressSwitcherAddress: null,
            applyDiscount: this.applyDiscount.bind(this),
            canCreatePaymentInstruments: this.canCreatePaymentInstruments.bind(this),
            closeDiscountDialog: this.closeDiscountDialog.bind(this),
            currentDateTime: new Date(),
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            emailPattern: REGEXES.EMAIL,
            executionOptionsMinDate: moment(this.getTomorrow()).startOf('day').utc().toISOString(),
            formFieldLocaleKeyMapping: {
                addressShipToName: CustomerCareKeys.ADDRESSES.SHIP_TO_NAME,
                waiveFeeReason: CustomerCareKeys.SUBSCRIPTIONS.WAIVE_CHARGE_REASON,
                SpecifiedDate: 'test',
            },
            giftOrderInfo: {
                currentDateTime: new Date(),
                giftMessage: null,
                notificationDate: new Date(),
                recipientName: null
            },
            initializedHouseholdData: false,
            initializedWallet: false,
            isLoadingData: this.isLoadingData.bind(this),
            isShippingAddressSameAsBilling: true,
            localeKeys: CustomerCareKeys,
            MAXIMUM_COMMENT_LENGTH: 500,
            onAddAnotherPaymentClicked: this.onAddAnotherPaymentClicked.bind(this),
            onAddPaymentInstrumentCallback: this.onAddPaymentInstrumentCallback.bind(this),
            onAddressSwitcherChangeCallback: this.onAddressSwitcherChangeCallback.bind(this),
            onAddressSwitcherCreateOrEditCallback: this.onAddressSwitcherCreateOrEditCallback.bind(this),
            onChangeImmediatelyCallback: this.onChangeImmediatelyCallback.bind(this),
            onChangePaymentInstrumentCallback: this.onChangePaymentInstrumentCallback.bind(this),
            onChangeTemporaryShipToName: this.onChangeTemporaryShipToName.bind(this),
            onEditDiscountClick: this.onEditDiscountClick.bind(this),
            onEditPaymentInstrumentCallback: this.onEditPaymentInstrumentCallback.bind(this),
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            onFdoFormValidityChange: this.onFdoFormValidityChange.bind(this),
            onListUpdated: this.onListUpdated.bind(this),
            onPropertyChanged: this.onPropertyChanged.bind(this),
            onRemoveDiscountClick: this.onRemoveDiscountClick.bind(this),
            onShippingAddressSameAsBillingChanged: this.onShippingAddressSameAsBillingChanged.bind(this),
            onWaiveFeeReasonSelected: this.onWaiveFeeReasonSelected.bind(this),
            onWaiveFeeSelected: this.onWaiveFeeSelected.bind(this),
            onWaiveLateFeeReasonSelected: this.onWaiveLateFeeReasonSelected.bind(this),
            onWaiveLateFeeSelected: this.onWaiveLateFeeSelected.bind(this),
            openDiscountDialog: this.openDiscountDialog.bind(this),
            ORDER_COMMENT_MAX_LENGTH: 500,
            orderDetails: {},
            paymentInstrument: {},
            readOnlyInstruments: [],
            reasonCodes: [],
            removePaymentInstrument: this.removePaymentInstrument.bind(this),
            selectablePaymentInstruments: [],
            setOrderDetails: this.setOrderDetails.bind(this),
            setProductOrderDiscretionaryDiscounts: this.setProductOrderDiscretionaryDiscounts.bind(this),
            shippingMethodSelected: this.shippingMethodSelected.bind(this),
            shouldHideSameAsBillingCheckbox: this.shouldHideSameAsBillingCheckbox.bind(this),
            shouldShowAdditionalPaymentMethods: this.shouldShowAdditionalPaymentMethods.bind(this),
            showDiscountForm: false,
            uiNotificationService,
            validations: {
                DEFAULT_MAX_LENGTH: 100,
                GIFT_MESSAGE_MAX_LENGTH: 500,
                SHIP_TO_NAME_MAX_LENGTH: 200
            }
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                applyDiscountDialogApi: null,
                billingEffectiveDateOptions: BillingEffectiveDateOptionsSelector(store),
                billingEffectiveDateSettings: BillingEffectiveDateSettingsSelector(store),
                candidateBillCycles: CandidateBillCyclesSelector(store),
                cartContainsProductWithBillerRuleCycleLevelOtherThanItem: CartContainsProductWithBillerRuleCycleLevelOtherThanItemSelector(store),
                changeImmediately: ChangeImmediateSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                couponCodes: UnfilteredCouponCodesSelector(store),
                currency: CurrencySelector(store),
                currentActiveAddresses: CurrentActiveAddressesSelector(store),
                currentAddresses: CurrentAddressesSelector(store),
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentHousehold: CurrentHouseholdSelector(store),
                currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                currentSubscriptionShippingAddress: ShippingAddressSelector(store),
                defaultShippingAddress: DefaultShippingAddressSelector(store),
                discretionaryDiscounts: DiscretionaryDiscountsSelector(store),
                discretionaryDiscountsId: DiscretionaryDiscountsIdSelector(store),
                discretionaryDiscountsReason: DiscretionaryDiscountsReasonSelector(store),
                doPaymentInstrumentsCoverOrderTotal: DoPaymentInstrumentsCoverOrderTotalSelector(store),
                executionOptions: OttExecutionOptionsSelector(store),
                fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                fdoConfiguration: MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoConfigurationLoaded: MetadataCodeLoadedSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoMaxDate: GetMaxDateForFutureDatedOrder(store),
                giftOrderInfo: ProductWizardGiftOrderInfoSelector(store),
                hasPrepaid: HasPrepaidPricingPlanSelector(store),
                invalidCouponRedemptions: InvalidCouponRedemptionViewModelSelector(store),
                isDbss: IsDbss(store),
                isDbssShoppingCart: IsDbssShoppingCart(store),
                isFetchingWalletData: PaymentIsFetchingDataSelector(store),
                isGiftOrder: ProductWizardIsGiftOrderSelector(store),
                isModifyingMode: IsModifyingModeSelector(store),
                isReplacingMode: IsReplacingModeSelector(store),
                isRestoringMode: IsRestoringModeSelector(store),
                isSelectedProductAdHoc: IsSelectedProductAdHoc(store),
                isSelectedProductDbss: IsSelectedProductDbss(store),
                isServiceFeatureShoppingCart: IsServiceFeatureShoppingCart(store),
                isShippingAddressSameAsBilling: IsShippingAddressSameAsBillingSelector(store),
                isShippingMethodsAvailable: IsShippingMethodsAvailableSelector(store),
                isValidatingCoupon: IsValidatingCoupon(store),
                locationCodes: ShoppingCartItemDetailsLocationCodesSelector(store),
                modifiedSubscriptionItem: ModifiedSubscriptionItemSelector(store),
                paymentInstrumentFunds: {
                    isOrderTotalCovered: DoPaymentInstrumentsCoverOrderTotalSelector(store),
                    paymentInstrumentsNotRecorded: PaymentInstrumentsSelector(store),
                    recordPaymentInfo: RecordPaymentInformationSelector(store),
                    selectedPaymentIds: PaymentInstrumentIdsSelector(store)
                },
                paymentInstrumentsNotRecorded: PaymentInstrumentsSelector(store),
                paymentInstrumentsWithBalance: this.determinePaymentInstrumentsWithBalanceSelector(store),
                previouslySelectedPaymentInstrumentIds: PreviouslySelectedPaymentInstrumentIdsSelector(store),
                quoteIsValid: OrderQuoteIsValidSelector(store),
                reasonCodes: MetadataCodeTypeDictionarySelector(CODES.ReasonCodes, store),
                reasonCodesLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                recordPaymentInfo: RecordPaymentInformationSelector(store),
                routeParams: RouteParams(store),
                searchCatalogParams: SearchCatalogParamsSelector(store),
                selectablePaymentInstruments: this.determineSelectablePaymentInstrumentsSelector(store),
                selectedPaymentMethod: SelectedPaymentMethodSelector(store),
                selectedPaymentInstrumentIds: PaymentInstrumentIdsValidInQuoteSelector(store),
                selectedShippingAddressId: ProductOrderShippingAddressIdSelector(store),
                shippingMethods: ShippingMethodsViewModel(store),
                shippingRequired: OrderRequiresShippingSelector(store),
                shoppingCart: ShoppingCartSelector(store),
                subscriptionHasLateFee: CurrentSubscriptionHasLateFeeSelector(store),
                subscriptionOnly: SubscriptionsOnlySelector(store),
                temporaryShipToName: ProductWizardTemporaryShipToNameSelector(store),
                timeZonesLoaded: MetadataCodeLoadedSelector(CODES.TimeZone, store),
                ungiftableProducts: UngiftableShoppingCartItemSelector(store),
                waiveFee: IsProductWizardRestoreOrderWaivingFeeSelector(store),
                waiveFeeReason: ProductWizardRestoreOrderWaiveFeeReasonSelector(store),
                waiveFeeReasonOptions: MetadataOptionsForCodeValuesSelector(CODES.WaiveFeeReason, store),
                waiveLateFee: IsProductWizardRestoreOrderWaivingLateFeeSelector(store),
                waiveLateFeeReason: ProductWizardRestoreOrderWaiveLateFeeReasonSelector(store),
            };
        };

        const controllerActions = {
            addUnsavedPaymentMethod,
            clearCartSummaryForWaiveCharge,
            fetchCodeTypes,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveCandidateBillCycles,
            retrieveCustomerAddresses,
            retrieveCustomerHouseholds,
            retrieveOrderContext,
            retrieveProductsMetadata,
            retrieveWallet,
            setChangeImmediately,
            setDiscountForm,
            setGiftOrderInfo,
            setIsGiftOrder,
            setIsShippingAddressSameAsBilling,
            setOrderDetails,
            setOttExecutionOptions,
            setPaymentInstruments,
            setPaymentInstrumentIds,
            setPreviouslySelectedPaymentInstrumentIds,
            setProductOrderCouponCodes,
            setProductOrderDiscretionaryDiscounts,
            setProductOrderShippingAddressId,
            setRecordPaymentInstrument,
            setRestoreOrderWaiveFee,
            setRestoreOrderWaiveFeeReason,
            setRestoreOrderWaiveLateFee,
            setRestoreOrderWaiveLateFeeReason,
            setSelectedShippingMethodId,
            setTemporaryShipToName,
            updateSearchCatalogParams
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
            this.setSelectedCurrentPaymentInstrument();
        });

        const productIds = pluck('ProductId', pathOr([], ['Items'], this.state.shoppingCart));
        this.actions.retrieveProductsMetadata(productIds).then(() => {
            if (this.state.isDbss && this.state.cartContainsProductWithBillerRuleCycleLevelOtherThanItem) {
                this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, subscriberOrderTypeEnum.postpaid.value).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.applyDiscountDialogConfig = {
            onRegisterApi: ({api}) => {
                this.applyDiscountDialogApi = api;
            }
        };

        if (!this.state.fdoConfigurationLoaded) {
            this.actions.fetchCodeTypes(CODES.FutureDatedOrderConfiguration);
        }

        if (this.state.isRestoringMode && !this.state.codeTypeLoaded(CODES.WaiveFeeReason)) {
            this.actions.fetchCodeTypes(CODES.WaiveFeeReason);
        }

        if (!this.state.timeZonesLoaded) {
            this.actions.fetchCodeTypes(CODES.TimeZone);
        }

        if (!this.state.reasonCodesLoaded) {
            this.actions.fetchCodeTypes(CODES.ReasonCodes);
        }

        this.additionalPropertyFileds = clone(this.additionalPropertiesCollection);

        // TODO Customer Order skip all these calls on checkout for DBSS we don't need for submitting an order
        // Coupons, Discretionary, & Discounts & Gifting do not apply for DBSS?
        if (!this.state.isDbssShoppingCart) {
            this.loadCustomerAddresses();
            this.loadHouseholdData();
            this.initGiftOrder();
            this.retrieveWalletAndSetInitialPaymentInstrument();
        } else {
            // TODO (CustomerOrder): When Checkout is refactored, make this a helpe that is called when nedessary so
            // it will have more explicit control.
            this.actions.retrieveOrderContext(this.state.currentCustomerId).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
            this.onChange()();  // Used to initiate calculate order quote on the parent component.
        }

        if (!this.shouldShowApplyChanges()) {
            this.actions.setChangeImmediately(true);
        } else if (this.state.isReplacingMode && this.shouldShowApplyChanges()) {
            this.actions.setChangeImmediately(false);
        }

        this.setDefaultBillCycle();

        this.actions.retrieveAvailablePaymentInstrumentTypes({
            Currency: this.state.currentCustomerCurrencyCode || this.state.currentBusinessUnitCurrencyCode,
            ShoppingCart: {
                Items: this.state.shoppingCart
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        this.reasonCodes = this.getFilteredReasonCodes();

    }

    $doCheck() {
        if (this.formController.selectedShippingAddress) {
            if (!this.state.selectedShippingAddressId) {
                this.formController.selectedShippingAddress.$setValidity('isShippingAddressSelected', false);
            } else {
                this.formController.selectedShippingAddress.$setValidity('isShippingAddressSelected', true);
            }
        }
    }

    $postLink() {
        this.$timeout(() => {
            if (!this.state.isDbssShoppingCart) {
                // *true* indicates validation succeeded
                if (this.formController.paymentInstrumentsFunds) {
                    this.formController.paymentInstrumentsFunds.$validators.doPaymentInstrumentsCoverOrderTotal = (modelValue) => {
                        // Marking validation as *true* is Expired/Inactive Payment Instrument is selected. Else it shows multiple errors when you switch from External Gift Card with insufficient balance to Expired/Inactive Credit Card.
                        return (this._containsExpiredPaymentInstrument(modelValue.selectedPaymentIds) ||
                            this.isPaymentInstrumentRemoved() ||
                            this._containsInvoicePaymentInstrument(modelValue.selectedPaymentIds) || this.state.isServiceFeatureShoppingCart) ?
                            true :
                            modelValue.isOrderTotalCovered;
                    };
                    this.formController.paymentInstrumentsFunds.$validators.isPaymentInstrumentSelected = (modelValue) => {
                        return this.state.isServiceFeatureShoppingCart ?
                            (!this.state.hasPrepaid || !!modelValue.selectedPaymentIds.length || (!!modelValue.paymentInstrumentsNotRecorded.length && !modelValue.recordPaymentInfo)) :
                            ((!!modelValue.selectedPaymentIds.length || (!!modelValue.paymentInstrumentsNotRecorded.length && !modelValue.recordPaymentInfo)) || !(pathOr(true, ['state', 'shoppingCart', 'PaymentInstrumentRequired'], this)));
                    };
                    this.formController.paymentInstrumentsFunds.$validators.isPaymentInstrumentExpirationValid = (modelValue) => {
                        return this._containsInvoicePaymentInstrument(modelValue.selectedPaymentIds) ?
                            true :
                            !this._containsExpiredPaymentInstrument(modelValue.selectedPaymentIds);
                    };
                    this.formController.paymentInstrumentsFunds.$validators.isPaymentInstrumentInactive = () => {
                        return !this.isPaymentInstrumentRemoved();
                    };
                }
            }
        });
    }

    determinePaymentInstrumentsWithBalanceSelector(store) {
        return this.state && this.state.isServiceFeatureShoppingCart ? PaymentInstrumentsWithoutBillToInvoiceSelector(store) : PaymentInstrumentsCanBeUsedForOrderingContainingBalanceSelector(store);
    }

    determineSelectablePaymentInstrumentsSelector(store) {
        return this.state && this.state.isServiceFeatureShoppingCart ? SelectablePaymentInstrumentForServiceFeaturesSelector(store) : SelectablePaymentInstrumentSelector(store);
    }

    loadCustomerAddresses() {
        if (!this.state.currentAddresses || !this.state.currentAddresses.length) {
            this.actions.retrieveCustomerAddresses(this.state.currentCustomerId).then(() => {
                const previouslySelectedAddressSwitcherAddress = this.state.currentActiveAddresses.find(propEq(this.state.selectedShippingAddressId, 'Id'));
                this.addressSwitcherAddress = previouslySelectedAddressSwitcherAddress || this.state.defaultShippingAddress;
                if (!this.addressSwitcherAddress) {
                    this.actions.setIsShippingAddressSameAsBilling(true);
                }
            });
        } else {
            const previouslySelectedAddressSwitcherAddress = this.state.currentActiveAddresses.find(propEq(this.state.selectedShippingAddressId, 'Id'));
            this.addressSwitcherAddress = previouslySelectedAddressSwitcherAddress || this.state.defaultShippingAddress;
            if (!this.addressSwitcherAddress) {
                this.actions.setIsShippingAddressSameAsBilling(true);
            }
        }
    }

    hasPaymentInstruments() {
        return !!((this.state.selectablePaymentInstruments || []).length || (this.state.paymentInstrumentsWithBalance || []).length);
    }

    initGiftOrder() {
        this.isGiftOrder = this.state.isGiftOrder;
        if (!isEmpty(this.state.giftOrderInfo)) {
            this.giftOrderInfo = clone(this.state.giftOrderInfo);
        }
    }

    handleApplyCouponCode(couponCode) {
        if (!this.state.couponCodes.find(propEq(couponCode, 'name'))) {
            // only apply the coupon if it's unique (not already applied)
            this.actions.setProductOrderCouponCodes([{
                name: couponCode,
                description: null
            }].concat(this.state.couponCodes));
        }
        this.onChange()();
    }

    handleRemoveCouponCode(couponCode) {
        this.actions.setProductOrderCouponCodes(reject(propEq(couponCode.name, 'name'), this.state.couponCodes));
        if (couponCode.name === this.state.searchCatalogParams.ProductFilter.CouponCode) {
            const searchParameters = clone(this.state.searchCatalogParams);
            searchParameters.ProductFilter.CouponCode = undefined;
            this.actions.updateSearchCatalogParams(searchParameters);
        }
        this.onChange()();
    }

    onChangeTemporaryShipToName() {
        this.actions.setTemporaryShipToName(this.temporaryShipToName);
    }

    _getPaymentInstrument(id) {
        return this.state.selectablePaymentInstruments.find(propEq(id, 'Id'));
    }

    _containsExpiredPaymentInstrument(paymentInstrumentIds) {
        const expiredCreditCards = paymentInstrumentIds.filter(pid => {
            const pi = this._getPaymentInstrument(pid);
            return pi && pi.CreditCard ? this._isCreditCardExpired(pi.CreditCard) : false;
        });
        return expiredCreditCards.length;
    }

    _containsInvoicePaymentInstrument(paymentInstrumentIds) {
        return paymentInstrumentIds.some((id) => {
            const paymentInstrument = this._getPaymentInstrument(id);
            return paymentInstrument && paymentInstrument.Type === CONVERGENT_BILLER_INVOICE;
        });
    }

    _isCreditCardExpired(creditCardDetails) {
        const formattedCreditCardExpiration = `${creditCardDetails.ExpirationMonth}/${creditCardDetails.ExpirationYear}`;
        return moment(formattedCreditCardExpiration, 'MM/YYYY').isBefore(moment(), 'month');
    }

    shouldShowAdditionalPaymentMethods() {
        return this.state.selectablePaymentInstruments.length && !this.state.doPaymentInstrumentsCoverOrderTotal &&
            this.state.paymentInstrumentsWithBalance.length >= this.state.selectedPaymentInstrumentIds.length && this.paymentInstrument &&
            !(this.paymentInstrument.CreditCard && this._isCreditCardExpired(this.paymentInstrument.CreditCard) || this.isPaymentInstrumentRemoved());
    }

    onAddAnotherPaymentClicked() {
        this.actions.setPreviouslySelectedPaymentInstrumentIds(this.previouslySelectedPaymentInstrumentIds.concat(this.paymentInstrument.Id));
        this.paymentInstrument = getDefaultPaymentMethodFromPaymentInstruments(this.state.selectablePaymentInstruments);
        this.addPaymentInstrument(this.paymentInstrument);
    }

    removePaymentInstrument(paymentInstrumentId) {
        //Remove the trailing read only instruments
        const updatedPreviouslySelectedPaymentInstrumentIds = this.previouslySelectedPaymentInstrumentIds.filter((previouslySelectedPaymentInstrumentId) => {
            return previouslySelectedPaymentInstrumentId !== paymentInstrumentId;
        });

        if (!this.paymentInstrument) { // previously all PIs in the list were used
            if (updatedPreviouslySelectedPaymentInstrumentIds.length === 0 && this.previouslySelectedPaymentInstrumentIds.length === 1) {
                updatedPreviouslySelectedPaymentInstrumentIds.push(paymentInstrumentId);
            }

            // need to set this.paymentInstrument to the last in the list
            const lastPaymentInstrumentId = updatedPreviouslySelectedPaymentInstrumentIds.pop();
            this.paymentInstrument = this.state.paymentInstrumentsWithBalance.find((pi) => {
                return pi.Id === lastPaymentInstrumentId;
            });
        }

        this.actions.setPreviouslySelectedPaymentInstrumentIds(updatedPreviouslySelectedPaymentInstrumentIds);
        this.actions.setPaymentInstrumentIds(updatedPreviouslySelectedPaymentInstrumentIds.concat([this.paymentInstrument.Id]));

        this.onChange()();
    }

    loadHouseholdData() {
        if (!this.state.currentHousehold) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false).finally(() => {
                this.initializedHouseholdData = true;
            });
        } else {
            this.initializedHouseholdData = true;
        }
    }

    retrieveWalletAndSetInitialPaymentInstrument() {
        this.actions.retrieveWallet({
            customerId: this.state.currentCustomerId
        }, true).then(() => {
            if (this.state.paymentInstrumentsWithBalance.length) {
                const paymentInstruments = this.selectedPaymentIdsToInstruments(this.state.selectedPaymentInstrumentIds, this.state.paymentInstrumentsWithBalance);
                this.selectablePaymentInstruments = this.state.paymentInstrumentsWithBalance;

                if (this.state.isModifyingMode || this.state.isRestoringMode || this.state.isReplacingMode) {
                    this.paymentInstrument = this.state.currentSubscription.PaymentInstrument;
                } else if (paymentInstruments && paymentInstruments.length) {
                    while (paymentInstruments.length && !this.paymentInstrument.Id) {
                        this.paymentInstrument = paymentInstruments.asMutable().pop();
                        if (this.state.previouslySelectedPaymentInstrumentIds.includes(this.paymentInstrument.Id)) {
                            this.paymentInstrument = undefined;
                        }
                    }
                    this.readOnlyInstruments = paymentInstruments;
                    this.selectablePaymentInstruments = without(this.readOnlyInstruments, this.selectablePaymentInstruments);
                } else {
                    this.paymentInstrument = getDefaultPaymentMethodFromPaymentInstruments(this.state.paymentInstrumentsWithBalance);
                }

                this.isShippingAddressSameAsBilling = this.state.isShippingAddressSameAsBilling;
                const billingAddress = pathOr(null, ['BillingAddress', 'Id'], this.paymentInstrument);
                if (this.isShippingAddressSameAsBilling && billingAddress) {
                    this.actions.setProductOrderShippingAddressId(billingAddress);
                } else {
                    this.isShippingAddressSameAsBilling = false;
                    this.actions.setIsShippingAddressSameAsBilling(this.isShippingAddressSameAsBilling);

                    this.addressSwitcherAddress = this.state.currentSubscriptionShippingAddress || this.state.defaultShippingAddress || this.state.currentActiveAddresses[0];
                    this.actions.setProductOrderShippingAddressId(this.getAddressSwitcherAddressId());
                }

                this.onChangePaymentInstrumentCallback(this.paymentInstrument);
            } else {
                this.onChangePaymentInstrumentCallback();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.initializedWallet = true;
        });
    }

    setSelectedCurrentPaymentInstrument() {
        this.previouslySelectedPaymentInstrumentIds = this.state.previouslySelectedPaymentInstrumentIds;

        if (this.paymentInstrument && !(this.state.selectedPaymentInstrumentIds.includes(this.paymentInstrument.Id)) && this.previouslySelectedPaymentInstrumentIds.length) {
            this.actions.setPaymentInstrumentIds(this.previouslySelectedPaymentInstrumentIds.concat([this.paymentInstrument.Id]));
        }
    }

    onChangeImmediatelyCallback(changeImmediately) {
        this.actions.setChangeImmediately(changeImmediately);
        this.onChange()();
    }

    onAddPaymentInstrumentCallback(paymentInstrument) {
        this.selectablePaymentInstruments = without(this.readOnlyInstruments, [paymentInstrument].concat(this.state.paymentInstrumentsWithBalance));
        if (!this.addressSwitcherAddress) {
            this.addressSwitcherAddress = paymentInstrument.BillingAddress;
        }
        this.onChangePaymentInstrumentCallback(paymentInstrument);
    }

    onChangePaymentInstrumentCallback(paymentInstrument) {
        this.temporaryShipToName = null;

        this.onEditPaymentInstrumentCallback(paymentInstrument);
    }

    onEditPaymentInstrumentCallback(paymentInstrument) {
        //Set the current active payment instrument
        this.paymentInstrument = paymentInstrument;
        if (paymentInstrument && paymentInstrument.Id > 0) {
            this.actions.setRecordPaymentInstrument(true);
            this.actions.setPaymentInstruments([]);
            if (this.paymentInstrument.BillingAddress && this.addressSwitcherAddress && this.addressSwitcherAddress.Id === this.paymentInstrument.BillingAddress.Id) {
                //leveraging the address switcher change callback to ensure shipping methods are updated
                this.onAddressSwitcherChangeCallback(paymentInstrument.BillingAddress, false);
            }

            //Remove the latest item from the list
            const selectedPaymentInstrumentIds = this.state.selectedPaymentInstrumentIds.asMutable();
            if (selectedPaymentInstrumentIds.length) {
                selectedPaymentInstrumentIds.splice(-1, 1);
            }
            //Add the changed method as the latest item
            selectedPaymentInstrumentIds.push(paymentInstrument.Id);
            this.actions.setPaymentInstrumentIds(selectedPaymentInstrumentIds);
        } else {
            if (paymentInstrument) {
                this.actions.setPaymentInstrumentIds([]);
                this.actions.addUnsavedPaymentMethod({
                    PaymentInstrument: paymentInstrument
                });
                this.actions.setPaymentInstruments([paymentInstrument]);
            }
        }
        this.shippingAddressChanged();
        this.handleSameAsBillingCheckboxOnPaymentMethodChange(paymentInstrument);
        this.onChange()();
    }

    addPaymentInstrument(paymentInstrument) {
        const selectedPaymentInstrumentIds = this.state.selectedPaymentInstrumentIds.asMutable();
        if (paymentInstrument && paymentInstrument.Id) {
            selectedPaymentInstrumentIds.push(paymentInstrument.Id);
        }
        this.actions.setPaymentInstrumentIds(selectedPaymentInstrumentIds);

        this.handleSameAsBillingCheckboxOnPaymentMethodChange(paymentInstrument);
        this.onChange()();
    }

    onAddressSwitcherChangeCallback(address, triggerChangeCallback = true) {
        this.addressSwitcherAddress = address;
        this.temporaryShipToName = null;
        this.shippingAddressChanged();
        triggerChangeCallback && this.onChange()();
    }

    onAddressSwitcherCreateOrEditCallback(address) {
        this.onAddressSwitcherChangeCallback(address);

        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    isLoadingData() {
        return this.state.isDbssShoppingCart ? false : !this.initializedWallet || !this.initializedHouseholdData || this.state.isFetchingWalletData;
    }

    canCreatePaymentInstruments() {
        return this.state.currentMemberCanCreatePaymentInstrument;
    }

    shouldHideSameAsBillingCheckbox() {
        const currentPaymentInstrumentHasBillingAddressId = pathOr(false, ['BillingAddress', 'Id'], this.paymentInstrument);

        return this.state.shippingRequired &&
            this.state.selectedPaymentInstrumentIds.length !== 1 ||
            !currentPaymentInstrumentHasBillingAddressId;
    }

    getAddressSwitcherAddressId() {
        return pathOr(null, ['Id'], this.addressSwitcherAddress);
    }

    getPaymentInstrumentAddressId() {
        return pathOr(null, ['BillingAddress', 'Id'], this.paymentInstrument);
    }

    handleSameAsBillingCheckboxOnPaymentMethodChange(updatedPaymentInstrument) {
        if (this.shouldHideSameAsBillingCheckbox()) {
            this.isShippingAddressSameAsBilling = false;
            this.actions.setIsShippingAddressSameAsBilling(this.isShippingAddressSameAsBilling);
            this.actions.setProductOrderShippingAddressId(this.getAddressSwitcherAddressId());
            this.actions.setSelectedShippingMethodId(null);
        }

        if (this.isShippingAddressSameAsBilling) {
            const currentPaymentInstrumentBillingAddressId = pathOr(null, ['BillingAddress', 'Id'], updatedPaymentInstrument);

            if (currentPaymentInstrumentBillingAddressId) {
                if (currentPaymentInstrumentBillingAddressId !== this.state.selectedShippingAddressId) {
                    this.actions.setProductOrderShippingAddressId(currentPaymentInstrumentBillingAddressId);
                    this.actions.setSelectedShippingMethodId(null);
                }
            } else {
                this.isShippingAddressSameAsBilling = false;
                this.actions.setIsShippingAddressSameAsBilling(this.isShippingAddressSameAsBilling);
                this.actions.setProductOrderShippingAddressId(this.getAddressSwitcherAddressId());
                this.actions.setSelectedShippingMethodId(null);
            }
        }
    }

    shippingAddressChanged() {
        const newShippingAddressId = this.isShippingAddressSameAsBilling ? this.getPaymentInstrumentAddressId() : this.getAddressSwitcherAddressId();
        this.handleTemporaryShipToNameUpdate();
        this.actions.setIsShippingAddressSameAsBilling(this.isShippingAddressSameAsBilling);
        this.actions.setProductOrderShippingAddressId(newShippingAddressId);
        this.actions.setSelectedShippingMethodId(null);
    }

    onShippingAddressSameAsBillingChanged() {
        this.shippingAddressChanged();
        this.onChange()();
    }

    handleTemporaryShipToNameUpdate() {
        const paymentInstrumentShipToName = pathOr(this.temporaryShipToName, ['BillingAddress', 'ShipToName'], this.paymentInstrument);
        const addressSwitcherAddressShipToName = pathOr(this.temporaryShipToName, ['addressSwitcherAddress', 'ShipToName'], this);
        this.temporaryShipToName = this.isShippingAddressSameAsBilling ? paymentInstrumentShipToName : addressSwitcherAddressShipToName;
        this.onChangeTemporaryShipToName();
    }

    selectedPaymentIdsToInstruments(paymentInstrumentIds, paymentInstruments) {
        return paymentInstrumentIds.map((paymentInstrumentId) => {
            return this.selectedPaymentIdToInstrument(paymentInstrumentId, paymentInstruments);
        });
    }

    selectedPaymentIdToInstrument(paymentInstrumentId, paymentInstruments) {
        return paymentInstruments.find(propEq(paymentInstrumentId, 'Id'));
    }

    setIsGiftOrder() {
        this.defaultInvalidNotificationDate();
        this.actions.setIsGiftOrder(this.isGiftOrder);
    }

    setGiftOrderInfo() {
        this.actions.setGiftOrderInfo(this.giftOrderInfo);
    }

    defaultInvalidNotificationDate() {
        // Check for Invalid Date
        // This corrects a edge case where unchecking 'Send as Gift' when the three-field-date-input
        // places a value of Invalid Date into the state, causing re-selecting it to have
        // and empty value in the notificationDate. The same behavior will occur when navigating
        // back to products with and invalid date. This will also prevent the store from updating
        // the giftOrderInfo object just for enabling sendAsGift
        if (isNaN(pathOr(new Date(), ['notificationDate'], this.giftOrderInfo).valueOf())) {
            this.giftOrderInfo.notificationDate = this.currentDateTime;
            this.actions.setGiftOrderInfo(this.giftOrderInfo);
        }
    }

    shouldShowApplyChanges() {
        let currentPlanId = null;
        let originalPlanId = null;

        if (this.state.modifiedSubscriptionItem && this.state.modifiedSubscriptionItem.PricingPlan) {
            currentPlanId = this.state.modifiedSubscriptionItem.PricingPlan.Id;
        }

        if (pathOr(0, ['Items', 'length'], this.state.currentSubscription)) {
            originalPlanId = pathOr(null, ['PricingPlan', 'Id'], this.state.currentSubscription.Items.find((currentItem) => {
                return currentItem.Id === this.state.modifiedSubscriptionItem.Id;
            }));
        }

        return (this.state.isModifyingMode && currentPlanId !== originalPlanId) && !this.state.isReplacingMode;
    }

    isPaymentInstrumentRemoved() {
        return this.paymentInstrument && (this.paymentInstrument.Status === GENERAL_STATUSES.REMOVED);
    }

    // Discretionary Discounts
    onEditDiscountClick() {
        // must re-create the discount dialog data format where
        // selectedDiscounts is a map of Id to bool
        const discountForm = {
            selectedDiscounts: {},
            discountReason: this.state.discretionaryDiscountsReason
        };
        (this.state.discretionaryDiscountsId || []).forEach((discountId) => {
            discountForm.selectedDiscounts[discountId] = true;
        });

        this.actions.setDiscountForm(discountForm);
        this.openDiscountDialog();
    }

    onRemoveDiscountClick(discount) {
        // must re-create the discount dialog data format where
        // selectedDiscounts is a map of Id to bool
        const discountData = {
            selectedDiscounts: {},
            discountReason: null
        };
        if (discount) {
            (this.state.discretionaryDiscountsId || []).forEach((discountId) => {
                discountData.selectedDiscounts[discountId] = true;
            });
            discountData.selectedDiscounts[discount.Id] = false;
            discountData.discountReason = this.state.discretionaryDiscountsReason;
        }

        this.setProductOrderDiscretionaryDiscounts(discountData);
        this.onChange()();
    }

    setProductOrderDiscretionaryDiscounts(discountData) {
        this.actions.setProductOrderDiscretionaryDiscounts({
            selectedIds: Object.keys(discountData.selectedDiscounts).filter((discountMapId) => {
                return discountData.selectedDiscounts[discountMapId];
            }),
            reason: discountData.discountReason
        });
    }

    applyDiscount(discountData) {
        this.closeDiscountDialog();
        this.setProductOrderDiscretionaryDiscounts(discountData);
        this.onChange()();
    }

    closeDiscountDialog() {
        this.showDiscountForm = false;

        this.applyDiscountDialogApi.close();
    }

    openDiscountDialog() {
        this.showDiscountForm = true;

        this.$timeout(() => {
            this.applyDiscountDialogApi.open();
        });
    }

    // Shipping Methods
    shippingMethodSelected(shippingMethodId) {
        this.actions.setSelectedShippingMethodId(shippingMethodId);
        this.onChange()();
    }

    // Subscription Fees
    onWaiveFeeSelected(waiveFee) {
        this.actions.setRestoreOrderWaiveFee(waiveFee);

        if (waiveFee) {
            this.actions.setRestoreOrderWaiveLateFee(false);
            this.actions.setRestoreOrderWaiveLateFeeReason(null);
            this.actions.clearCartSummaryForWaiveCharge();
        } else {
            this.actions.setRestoreOrderWaiveFeeReason(null);
            this.onChange()();
        }
    }

    onWaiveFeeReasonSelected(waiveFeeReason) {
        this.actions.setRestoreOrderWaiveFeeReason(waiveFeeReason);
    }

    onWaiveLateFeeSelected(waiveLateFee) {
        this.actions.setRestoreOrderWaiveLateFee(waiveLateFee);

        if (!waiveLateFee) {
            this.actions.setRestoreOrderWaiveLateFeeReason(null);
            this.onChange()();
        }
    }

    onWaiveLateFeeReasonSelected(waiveLateFeeReason) {
        this.actions.setRestoreOrderWaiveLateFeeReason(waiveLateFeeReason);
        this.onChange()();
    }

    setDefaultBillCycle() {
        this.selectedBillCycle = this.state.candidateBillCycles[0];
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
    }

    onExecutionOptionChange(executionOptionsModel) {
        this.actions.setOttExecutionOptions(executionOptionsModel);
    }

    onFdoFormValidityChange(message) {
        this.fdoErrorMessageFromComponent = message;
        return message;
    }

    getTomorrow() {
        const today = moment();
        return moment(today).add(1, 'days');
    }

    shouldShowFdoExecutionOptionsError() {
        return this.errorsExistOnForm && this.formController.$submitted;
    }

    onListUpdated() {
        this.onAdditionalPropertyListUpdated()();
    }

    onPropertyChanged(id, newValue) {
        this.onAdditionalPropertyChanged()(id, newValue);
    }

    setOrderDetails() {
        this.actions.setOrderDetails(this.orderDetails);
    }

    getFilteredReasonCodes() {
        return (Object.values(this.state.reasonCodes) || []).filter((code) => {
            return code?.AdditionalProperties?.order_type_code === +ORDER_TYPE_CODE.NEW;
        });;
    }

    $onDestroy() {
        if (this.uiNotificationService.numActiveToasts()) {
            this.uiNotificationService.clear();
        }
        this.defaultInvalidNotificationDate();
        this.disconnectRedux();
    }
}

export default {
    template: require('./products.checkout.html'),
    controller: CheckoutController,
    require: {
        formController: '^^form'
    },
    bindings: {
        additionalPropertiesCollection: '<',
        additionalPropertyValueOptions: '<',
        isCalculatingQuote: '<',
        isNewOrder: '<',
        onAdditionalPropertyListUpdated: '&',
        onAdditionalPropertyChanged: '&',
        onChange: '&',
        shippingMethodId: '<'
    }
};
