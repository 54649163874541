import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import without from 'ramda/src/without';
import {getReplaceItems} from './subscription.order.helper';

export default class {
    constructor(state, stripInvalidCoupons) {
        this.state = state;
        this.stripInvalidCoupons = !!stripInvalidCoupons;
    }

    createOrder(additionalProperties) {
        let state;

        if (this.state.isCreatingMode) {
            state = createNewOrder(this.state, this.stripInvalidCoupons);
        } else if (this.state.isReplacingMode) {
            state = createReplaceSubscriptionOrder(this.state, this.stripInvalidCoupons);
        } else if (this.state.isModifyingMode) {
            state = createChangeOrder(this.state, this.stripInvalidCoupons);
        } else if (this.state.isRestoringMode) {
            state = createRestoreOrder(this.state, this.stripInvalidCoupons);
        }

        return Object.assign(state, additionalProperties);
    }
}

const createBaseOrder = (state, stripInvalidCoupons) => {
    let couponCodes = pluck('name', state.couponCodes);
    if (stripInvalidCoupons && state.invalidCouponRedemptions) {
        const invalidCoupons = pluck('RedemptionCode', state.invalidCouponRedemptions);
        couponCodes = without(invalidCoupons, couponCodes);
    }

    if (state.searchCouponCode) {
        couponCodes.push(state.searchCouponCode);
    }

    const isPaymentInstrumentRequired = pathOr(true, ['shoppingCart', 'PaymentInstrumentRequired'], state);
    // default is true because we want to only *not* send the PaymentInstrumentIds when they are explicitly *not* required for the cart
    // This needed to be changes due tu PD-380701 Payment Instrument Not Associated to $0 Order/Subscription

    const returnObject = {
        customerId: state.currentCustomer.Id,
        discretionaryDiscountReasonCode: pathOr(null, ['discretionaryDiscountReason', 'value'], state),
        discretionaryDiscounts: state.discretionaryDiscountIds,
        redemptionCodes: couponCodes
    };
    returnObject.recordPaymentInfo = pathOr(true, ['recordPaymentInfo'], state);
    if (!returnObject.recordPaymentInfo) {
        returnObject.useDefaults = false;
        returnObject.paymentInstruments = state.paymentInstrumentsClean;
    } else {
        returnObject.useDefaults = isPaymentInstrumentRequired;
        returnObject.paymentInstrumentIds = state.paymentInstrumentIds;
    }

    return returnObject;
};

const createNewOrder = (state, stripInvalidCoupons) => {
    const order = createBaseOrder(state, stripInvalidCoupons);
    order.shoppingCart = state.shoppingCart;
    order.shippingAddressId = state.orderRequiresShipping ? state.shippingAddressId : null;
    order.shippingMethodId = state.orderRequiresShipping ? state.shippingMethodId : null;
    order.shipToName = state.orderRequiresShipping ? state.shipToName: null;
    order.orderDetails = state.orderDetails || null;
    return order;
};

const createReplaceSubscriptionOrder = (state, stripInvalidCoupons) => {
    const order = createBaseOrder(state, stripInvalidCoupons);

    const subscriptionItemToReplaceId = pathOr(null, ['replaceItem', 'id'], state);
    const replacementItem = state.replacementItem ? state.replacementItem : state.shoppingCart.Items[0];

    order.modificationObject = {
        ChangeImmediately: state.changeImmediately,
        ReplaceItems: getReplaceItems(replacementItem, subscriptionItemToReplaceId),
        SubscriptionId: state.currentSubscription.Id
    };
    order.shippingAddressId = state.orderRequiresShipping ? state.shippingAddressId : null;
    order.shippingMethodId = state.orderRequiresShipping ? state.shippingMethodId : null;
    order.shipToName = state.orderRequiresShipping ? state.shipToName: null;

    return order;
};

const createChangeOrder = (state, stripInvalidCoupons) => {
    const order = createBaseOrder(state, stripInvalidCoupons);
    order.modificationObject = {
        ChangeImmediately: state.changeImmediately,
        SubscriptionId: state.currentSubscription.Id,

        AddItems: state.modifyItemRequestData.AddItems,
        ChangeItems: state.modifyItemRequestData.ChangeItems,
        RemoveItems: state.modifyItemRequestData.RemoveItems
    };

    if (state.orderRequiresShipping) {
        order.shippingAddressId = state.shippingAddressId;
        order.shippingMethodId = state.shippingMethodId;
        order.shipToName = state.shipToName;
    }

    return order;
};

const createRestoreOrder = (state, stripInvalidCoupons) => {
    const order = createBaseOrder(state, stripInvalidCoupons);
    order.subscriptionId = state.currentSubscription.Id;

    if (state.currentSubscription.Items[0].Shipped) {
        order.shippingAddressId = state.shippingAddressId;
        order.shippingMethodId = state.shippingMethodId;
        order.shipToName = state.shipToName;
    }

    order.waiveFee = state.waiveFee;
    order.waiveFeeReason = state.waiveFeeReason;
    order.waiveLateFee = state.waiveLateFee;
    order.waiveLateFeeReason = state.waiveLateFeeReason;
    return order;
};
