import clone from 'ramda/src/clone';
import LocaleKeys from '../../../../../../locales/keys';

class ServiceTaxCustomizeDialogController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            LocaleKeys,
            handleSubmitForm: this.handleSubmitForm.bind(this),
            handleCloseDialog: this.handleCloseDialog.bind(this),
            updateServiceTaxValue: this.updateServiceTaxValue.bind(this),
            updatedCustomizedServiceTaxValue: {
                ServiceTaxCustomization: {}
            }
        });
    }

    $onChanges(changesObj) {
        if (changesObj.currentAddresses && changesObj.currentAddresses.currentValue) {
            this.clonedAddresses = clone(this.currentAddresses);
        }
        if (changesObj.serviceTaxModel && changesObj.serviceTaxModel.currentValue) {
            this.updatedCustomizedServiceTaxValue.ServiceTaxCustomization = clone(this.serviceTaxModel);
        }
    }

    handleSubmitForm() {
        !this.serviceTaxCustomizationForm.$valid ? this.serviceTaxCustomizationForm.$setSubmitted() : this.onSave()(this.updatedCustomizedServiceTaxValue);
    }

    handleCloseDialog() {
        this.closeModal()();
    }

    updateServiceTaxValue(changedServiceTax) {
        this.updatedCustomizedServiceTaxValue = changedServiceTax;
    }
};

export default {
    bindings: {
        closeModal: '&',
        currentAddresses: '<',
        isUpdatingServiceTax: '<',
        onSave: '&',
        serviceTaxModel: '<?'
    },
    controller: ServiceTaxCustomizeDialogController,
    controllerAs: 'ServiceTaxCustomizeDialogController',
    template: require('./service.tax.customize.dialog.template.html'),
};