import LocaleKeys from '../../../locales/keys';

class RedemptionCodeDetailsAssociationsController {
    constructor($filter) {
        this.$filter = $filter;
        this.localeKeys = LocaleKeys;
    }

    $onInit() {
        this.getFilteredAssociations();
    }

    getFilteredAssociations() {
        this.filteredAssociations = this.$filter('filter')(this.selectedCouponAssociationCardsData, this.searchString);
    }
}

export default {
    bindings: {
        selectedCouponAssociationCardsData: '<'
    },
    template: require('./redemption.code.details.associations.html'),
    controller: RedemptionCodeDetailsAssociationsController,
};
