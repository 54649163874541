import angular from 'angular';

import 'invision-core';

import ChangePaymentPopup from './change.payment.popup.component';

export default angular.module('invision.paymentMethodSwitcher.changePaymentPopup', [
    'invision.core.components'
])
    .component('changePaymentPopup', ChangePaymentPopup)
    .name;
