import localeKeys from '../../../../../locales/keys';

class TransactionDetailsAcpController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.acp.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsAcpController
};
