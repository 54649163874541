import {stateGo} from 'redux-ui-router';
import CustomerLocaleKeys from '../../../../../../locales/keys';
import {setSelectedSharedEntitlementIndex} from '../../../../../../reducers/actions/shared.entitlements.actions';
import {NUMBER_OF_ENTITLEMENTS_TO_DISPLAY} from '../services.and.offers.contants';
import {
    SERVICES_AND_SHARED_ENTITLEMENTS_TAB,
    SERVICES_AND_SHARED_ENTITLEMENTS
} from '../../../../servicesAndUsage/services.and.usage.config';

class SharedUsageTabController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            handleSharedUsageOnClick: this.handleSharedUsageOnClick.bind(this),
            NUMBER_OF_ENTITLEMENTS_TO_DISPLAY,
            SERVICES_AND_SHARED_ENTITLEMENTS_TAB,
            SERVICES_AND_SHARED_ENTITLEMENTS
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {};
        };
        const controllerActions = {
            setSelectedSharedEntitlementIndex,
            stateGo
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleSharedUsageOnClick(entitlementIndex) {
        this.actions.setSelectedSharedEntitlementIndex(entitlementIndex || 0);
        this.actions.stateGo(SERVICES_AND_SHARED_ENTITLEMENTS, {
            tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SHARED_ENTITLEMENTS
        });
    }
}

export default {
    template: require('./shared.usage.tab.html'),
    controller: SharedUsageTabController,
    controllerAs: 'SharedUsageTabController',
    bindings: {
        sharedEntitlements: '<'
    }
};