import CustomerCareLocaleKeys from '../../../locales/keys';
import {i18n} from 'invision-core';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerSubscriberSummaryServicesSelector,
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    IsExecutingBillRunSelector,
} from '../../../reducers/selectors/demoActions.selectors';

import {
    executeBillRun,
} from '../../../reducers/actions/demoActions.actions';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';

class DemoActionsController {

    constructor($ngRedux, uiNotificationService) {
        this.disconnectRedux = null;
        this.mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                isExecutingBillRun: IsExecutingBillRunSelector(store)
            };
        };
        this.controllerActions = {
            executeBillRun
        };

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.uiNotificationService = uiNotificationService;
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
    //need
    shouldShowLoading() {
        return this.state.isExecutingBillRun;
    }

    executeBillRunOnClick() {
        this.actions.executeBillRun(this.state.currentCustomerId)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.DEMO_ACTIONS.EXECUTE_BILL_RUN_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            })
            .catch(() => {
                this.uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.DEMO_ACTIONS.EXECUTE_BILL_RUN_FAILURE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }


}

export default {
    template: require('./demoActions.html'),
    controller: DemoActionsController,
    controllerAs: 'DemoActionsController'
};
