import {GENERAL_STATUSES} from '../../shared/constants/general.status.constants';

export const REMOVE_ADDRESS_OPTION_ID = 'REMOVE_ADDRESS_OPTION_ID';
export const REACTIVATE_ADDRESS_OPTION_ID = 'REACTIVATE_ADDRESS_OPTION_ID';

export const ADDRESS_STATUSES = GENERAL_STATUSES;

export const ADDRESS_FORM_FIELD_MAX_LENGTH = {
    addressLineOne: 80,
    addressLineTwo: 50,
    city: 40,
    companyName: 100,
    firstName: 100,
    lastName: 100,
    shipToName: 200
};

export const ADDRESS_STATUS_CONSTANTS = {
    INVALID: 4,
    MDU_VALIDATION_ERROR: 7
};