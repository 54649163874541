import angular from 'angular';

import {Search} from './search.component';
import {SearchFields} from './searchFields/search.fields.component';
import {SearchCustomerResults} from './searchResults/search.customer.results.component';
import {SearchGiftCardResults} from './searchResults/search.giftcard.results.component';
import SearchTypeTabs from './searchTypeTabs/search.type.tabs.component';

export default angular.module('invision.customercare.search', [])
    .component('search', Search)
    .component('searchFields', SearchFields)
    .component('searchCustomerResults', SearchCustomerResults)
    .component('searchGiftCardResults', SearchGiftCardResults)
    .component('searchTypeTabs', SearchTypeTabs)
    .name;
