import angular from 'angular';

import addressSwitcher from './address.switcher.component';
import changeAddressModal from './changeAddressModal/change.address.modal.component';
import createAddressPopupModule from '../customer/addresses/createAddressPopup/create.address.popup.module';
import updateAddressPopupModule from '../customer/addresses/updateAddressPopup/update.address.popup.module';

export default angular.module('invision.addressSwitcher', [createAddressPopupModule, updateAddressPopupModule])
    .component('changeAddressModal', changeAddressModal)
    .component('addressSwitcher', addressSwitcher)
    .name;
