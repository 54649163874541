import {PaymentInstrumentTypeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import pathOr from 'ramda/src/pathOr';
import {DECIMAL_NUMBERS} from 'invision-core/src/constants/validation.constants';
import {
    hasAccess,
    getUpperLimitAccess
} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {unregisterUnsavedChanges} from 'invision-core/src/components/unsavedChangesPrompt/actions';

import clone from 'ramda/src/clone';

import CustomerCareKeys from '../../../../../locales/keys';
import {
    ApplyCreditErrorSelector,
    IsApplyingCreditSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    NEW_PAYMENT_INSTRUMENT,
    ORIGINAL_PAYMENT,
    REFUND_BY_CHECK
} from '../../../../customer/applyCreditModal/apply.credit.modal.constants';
import {
    ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT,
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT
} from '../../../../../security.attributes';
import {isPaymentSelectable} from '../../../../customer/ewallet/ewallet.helpers';
import {
    CanChangePaymentInstrument,
    CurrentPaymentInstrumentsTypeSelector,
    MutablePaymentInstrumentsTypeFilterOptionsSelector,
    ApplyCreditFromOverviewPaymentOptionsSelector
} from '../../../../../reducers/selectors/apply.credit.modal.selectors';
import {IsEligibleForPartialRefundsSelector} from '../../../../../reducers/selectors/customer.transactions.selectors';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {createPaymentInstrument,
    redeemGiftCard,
    retrieveWallet} from '../../../../../reducers/actions/customer.ewallet.actions';
import {applyOrderCredit} from '../../../../../reducers/actions/order.actions';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../../../customer/ewallet/ewallet.constants';
import {NOTIFICATION_TIME_LENGTH} from '../../../../../customercare.constants';
import {
    EwalletErrorSelector,
    IsRetrievingEwalletDataSelector
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../../reducers/actions/address.component.actions';



class ApplyCreditRefundModalComponent {
    constructor($filter, $ngRedux, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            AMOUNT_TYPE: {
                MAX: 'max',
                CUSTOM: 'custom'
            },
            availableCards: [],
            creditLocaleKeys: CustomerCareKeys.ORDER_DETAILS.CREDIT,
            CustomerCareKeys,
            extraErrors: [],
            isEditingPaymentInstrument: false,
            model : {
                Amount: null,
                AmountType: 'max',
                Currency: null,
                CustomAmount: '',
                Description: null,
                SendNotification: false,
                ReasonCode: null,
            },
            MAX_DESCR_LENGTH: 100,
            NEW_PAYMENT_INSTRUMENT,
            REFUND_BY_CHECK,
            onSubmitForm: this.onSubmitForm.bind(this),
            ORIGINAL_PAYMENT,
            paymentInstrumentRequestObject: {},
            regex: DECIMAL_NUMBERS,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                applyCreditError: ApplyCreditErrorSelector(store),
                canChangePaymentInstrument: CanChangePaymentInstrument(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPaymentInstrumentTypes: CurrentPaymentInstrumentsTypeSelector(store),
                eWalletError: EwalletErrorSelector(store),
                isApplyingCredit: IsApplyingCreditSelector(store),
                isDbss: IsDbss(store),
                isEligibleForPartialRefunds: IsEligibleForPartialRefundsSelector(store),
                isFetchingWallet: IsRetrievingEwalletDataSelector(store),
                paymentInstrumentsTypeFilterOptions: MutablePaymentInstrumentsTypeFilterOptionsSelector(store),
                paymentInstrumentTypeLoaded: PaymentInstrumentTypeLoadedSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
                walletPaymentInstrumentsTypeFilterOptions: ApplyCreditFromOverviewPaymentOptionsSelector(store)
            };
        };

        const controllerActions = {
            applyOrderCredit,
            createPaymentInstrument,
            fetchCodeTypes,
            redeemGiftCard,
            retrieveWallet,
            setForceShowEditFormAddress,
            unregisterUnsavedChanges,
            validateAddress
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.canChangePaymentInstrument) {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            }).then(() => {
                this.updateAvailablePaymentInstruments();
            });
        }
        this.model.Currency = this.currencyCode;
        this.dashboardAmount = this.amountToCredit;
        this.amountToCredit =  this.amountToCredit > 0 ? this.amountToCredit : 0;
        this.model.Amount = this.amountToCredit;
        this.model.CustomAmount = this.amountToCredit;
        this.updateAvailablePaymentInstruments();
        if (!this.state.paymentInstrumentTypeLoaded) {
            this.actions.fetchCodeTypes(CODES.PaymentInstrumentType);
        }
        this.invalidAmountErrorMessage = !this.state.isEligibleForPartialRefunds ? this.creditLocaleKeys.AMOUNT_VALUE_INVALID_DBSS : this.creditLocaleKeys.AMOUNT_VALUE_INVALID;
        this.actions.fetchCodeTypes(CODES.FeatureToggleConfig);
    }

    get canChangePaymentInstrument() {
        return hasAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT);
    }

    get isOriginalPaymentOnly() {
        return this.selectedCard.value === this.ORIGINAL_PAYMENT
            && this.availableCards.length === 1
            && this.availableCards[0].value === this.ORIGINAL_PAYMENT;
    }

    updateAvailablePaymentInstruments() {
        if (this.state && (this.state.canChangePaymentInstrument || this.isDashboard)) {
            this.availableCards = clone(this.isDashboard ?
                this.state.walletPaymentInstrumentsTypeFilterOptions :
                this.state.paymentInstrumentsTypeFilterOptions);
        } else {
            this.availableCards = [];
        }

        if (!this.isDashboard) {
            this.availableCards.unshift({
                value: this.ORIGINAL_PAYMENT,
                text: i18n.translate(this.creditLocaleKeys.ORIGINAL_PAYMENT_METHOD)
            });
        }

        this.selectedCard = this.availableCards[0];
        this.allowPaymentSelection = true;
        this.updateAllowablePaymentSelection();
    }

    updateAllowablePaymentSelection() {
        this.paymentInstruments.forEach((paymentInstrument) => {
            const shouldAllow = isPaymentSelectable(paymentInstrument);
            if (!shouldAllow) {
                this.allowPaymentSelection = false;
            }
        });
    }

    applyOrderCredit(customerId, details) {
        this.actions.applyOrderCredit(customerId, details)
            .then(() => {
                this.actions.unregisterUnsavedChanges('paymentBillingAddressForm.paymentInstrumentForm');
                this.onSubmit()();
            });
    }

    get isAmountValid() {
        const value = parseFloat(this.model.Amount);
        return value > 0 && (!this.state.isEligibleForPartialRefunds ? true : (value <= this.maxCreditAmount));
    }

    customValidations() {
        this.extraErrors = this.isAmountValid ? [] : [
            i18n.translate(this.invalidAmountErrorMessage, {
                minAmount: this.$filter('invCurrency')(0, this.currencyCode),
                maxAmount: this.$filter('invCurrency')(this.maxCreditAmount, this.currencyCode)
            })];
    }

    onSubmitForm() {
        this.customValidations();
        this.ApplyCreditRefundForm.$submitted = true;
        if (this.ApplyCreditRefundForm.$valid && this.isAmountValid) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    const {Amount, Currency, Description, ReasonCode, SendNotification} = this.model;
                    const creditDetails = Object.assign({
                        Amount,
                        Currency,
                        Description,
                        ReasonCode,
                        SendNotification
                    }, this.additionalApiData);

                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        if (this.allowPaymentSelection)  {
                            if (this.selectedCard.value === this.NEW_PAYMENT_INSTRUMENT) {
                                if (this.paymentInstrumentRequestObject.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD) {
                                    this.actions.redeemGiftCard(this.paymentInstrumentRequestObject.Pin, this.paymentInstrumentRequestObject.CustomerId).then((redeemedGiftCard) => {
                                        this.uiNotificationService.success(i18n.translate(CustomerCareKeys.E_WALLET.REDEEM_GIFT_CARD_SUCCESS), null, {
                                            timeOut: NOTIFICATION_TIME_LENGTH
                                        });
                                        creditDetails.PaymentInstrument = {
                                            Id: redeemedGiftCard.Id
                                        };
                                        if (this.state.isDbss && this.isBalanceAdjustment) {
                                            this.updateModelForReasonCode(creditDetails);
                                        }
                                        this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                                    });
                                } else {
                                    this.actions.createPaymentInstrument(this.paymentInstrumentRequestObject).then((createdPaymentInstrument) => {
                                        this.uiNotificationService.success(i18n.translate(CustomerCareKeys.MAKE_PAYMENT.CREATE_INSTRUMENT_SUCCESS), null, {
                                            timeOut: NOTIFICATION_TIME_LENGTH
                                        });
                                        creditDetails.PaymentInstrument = {
                                            Id: createdPaymentInstrument.PaymentInstrument.Id
                                        };
                                        if (this.state.isDbss && this.isBalanceAdjustment) {
                                            this.updateModelForReasonCode(creditDetails);
                                        }
                                        this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                                    });
                                }
                            } else if (this.selectedCard.value !== this.REFUND_BY_CHECK && this.selectedCard.value !== this.ORIGINAL_PAYMENT) {
                                creditDetails.PaymentInstrument = {
                                    Id: this.selectedCard.value
                                };
                            }
                        }

                        if (this.state.isDbss && this.isDashboard && this.selectedCard.value === this.REFUND_BY_CHECK) {
                            creditDetails.RefundByCheck = true;
                            if (this.isBalanceAdjustment) {
                                this.updateModelForReasonCode(creditDetails);
                            }
                            this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                        }

                        if (creditDetails.PaymentInstrument && creditDetails.PaymentInstrument.Id ||
                        this.selectedCard.value === this.ORIGINAL_PAYMENT) {
                            if (this.state.isDbss && this.isBalanceAdjustment) {
                                this.updateModelForReasonCode(creditDetails);
                            }
                            this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                        }
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        this.uiNotificationService.transientError(i18n.translate(CustomerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                    }
                }).catch((err) => {
                    this.uiNotificationService.transientError(err.translatedMessage);
                });
        }
    }

    updateModelForReasonCode(creditDetails) {
        delete creditDetails.ReasonCode;
        creditDetails.BalanceAdjustmentReasonCode = this.model.ReasonCode;
        return creditDetails;
    }
    handleSetAmountType() {
        if (this.model.AmountType === this.AMOUNT_TYPE.MAX) {
            this.model.CustomAmount = undefined;
            this.model.Amount = this.maxCreditAmount;
        } else if (this.model.AmountType === this.AMOUNT_TYPE.CUSTOM) {
            this.model.Amount = this.model.CustomAmount || 0;
            this.ApplyCreditRefundForm.applyCreditAmount.$setUntouched();
        }
        this.customValidations();
    }

    get maxCreditAmount() {
        return Math.min(this.amountToCredit, getUpperLimitAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT.id));
    }

    updateCustomAmount() {
        if (this.model.AmountType === this.AMOUNT_TYPE.CUSTOM) {
            this.model.Amount = this.model.CustomAmount;
        }
        this.customValidations();
    }

    updateCreditAmount() {
        this.model.AmountType = this.AMOUNT_TYPE.CUSTOM;
        this.model.Amount = this.model.CustomAmount;
    }

    $onDestroy() {
        this.disconnect();
    }

    //check if this works
    getAddressKey() {
        return pathOr(undefined, ['ApplyCreditRefundForm', 'paymentBillingAddressForm.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['ApplyCreditRefundForm', 'paymentBillingAddressForm.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        //TO-DO verify the appropriate address type below
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }
}

export default {
    bindings: {
        additionalApiData: '<?',
        amountToCredit: '<',
        brandableCurrencyName: '<?',
        creditReasons: '<',
        currencyCode: '<',
        isDashboard: '<?',
        isBalanceAdjustment: '<?',
        onClose: '&',
        onSubmit: '&',
        paymentInstruments: '<',
        title: '@'
    },
    template: require('./apply.credit.refund.modal.html'),
    controller: ApplyCreditRefundModalComponent,
    controllerAs: 'ApplyCreditRefund'
};
