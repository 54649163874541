import LocaleKeys from '../../../../locales/keys';
import {
    i18n,
    LogHelper,
} from 'invision-core';
import {
    CurrentRedemptionDetailsSelector,
    SelectedReasonIdSelector
} from '../../../../reducers/selectors/coupon.redemption.code.selectors';
import {
    removeCouponRedemptionCode,
    setSelectedReasonId
} from '../../../../reducers/actions/coupon.redemption.code.actions';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';

class RemoveCouponRedemptionCodeModalController {

    constructor($ngRedux, $timeout, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.uiNotificationService = uiNotificationService;
        this.localeKeys = LocaleKeys;
    }

    $onInit() {
        const controllerActions = {
            removeCouponRedemptionCode,
            setSelectedReasonId
        };

        const mapStateToTarget = (store) => {
            return {
                selectedCouponRedemptionCode: CurrentRedemptionDetailsSelector(store),
                selectedReasonId: SelectedReasonIdSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.formFieldLocaleKeyMapping = {
            reason: LocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.MODALS.ERRORS.REMOVE_REASON_REQUIRED
        };
    }

    onReasonSelected(selectedReason) {
        this.actions.setSelectedReasonId(selectedReason && selectedReason.id);
    }

    onSubmitForm() {
        if (this.removeForm.$invalid) {
            return;
        }

        if (this.awaitingAsyncOperation) {
            LogHelper.warn('Remove coupon redemption code request submitted while awaiting pre-existing async op');
            return;
        }

        if (this.state.selectedReasonId) {
            this.awaitingAsyncOperation = true;
            let couponRedemptionCodeUpdated = false;
            const couponRedemptionCodeId = this.state.selectedCouponRedemptionCode.RedemptionCode.Id;
            const reasonId = parseInt(this.state.selectedReasonId, 10);

            this.actions.removeCouponRedemptionCode(couponRedemptionCodeId, reasonId).then(() => {
                couponRedemptionCodeUpdated = true;
                this.uiNotificationService.success(i18n.translate(this.localeKeys.COUPONS.REDEMPTION_CODE_DETAILS.LABELS.REMOVE_SUCCESS_MESSAGE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                this.handleClose(couponRedemptionCodeUpdated);
            }).catch((error) => {
                this.handleClose(couponRedemptionCodeUpdated, error);
            }).finally(() => {
                this.awaitingAsyncOperation = false;
            });
        }
    }

    handleClose(paymentMethodUpdated, error) {
        // Call the onClose callback passed to the component
        this.onClose()(paymentMethodUpdated, error);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        onClose: '&',
    },
    template: require('./remove.redemption.code.modal.html'),
    controller: RemoveCouponRedemptionCodeModalController,
};
