import {
    i18n
} from 'invision-core';
import {
    equals
} from 'ramda';
import LocaleKeys from '../../../../../locales/keys';
import {
    SUBSCRIPTION_TYPE
} from '../../../../shared/constants/subscriptions.constants';

import {
    setEditRenewal,
    updateSubscription
} from '../../../../../reducers/actions/customer.subscriptions.actions';

import {
    CurrentCustomerIdSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    CurrentSubscriptionSelector,
    IsUpdatingSubscriptionSelector,
    ModifyRenewalSubscriptionItemSelector,
    RenewalUnderEditSelector,
    SubscriptionFormErrorSelector
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';

const DEFAULT_RENEW_FORM_VIEW_MODEL = {
    isRenewTypeConfigurable: false,
    renew: false,
    terminationReason: 0,
    type: SUBSCRIPTION_TYPE.MANUAL
};

class RenewModalController {
    constructor($ngRedux, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.localeKeys = LocaleKeys;
        this.notification = uiNotificationService;

        this.formLocaleKeys = {
            renewalType: this.localeKeys.SUBSCRIPTIONS.RENEWAL_TYPE,
            terminationReason: this.localeKeys.SUBSCRIPTIONS.TERMINATION_REASON
        };

        this.renewalDescriptor = {
            idField: (field) => {
                return (field || Number.isInteger(field)) && field.toString();
            }
        };
    }

    $onInit() {

        const controllerActions = {
            setEditRenewal,
            updateSubscription
        };

        const mapStateToTarget = (store) => {
            return {
                customerId: CurrentCustomerIdSelector(store),
                isUpdatingSubscription: IsUpdatingSubscriptionSelector(store),
                modifyRenewErrors: SubscriptionFormErrorSelector(store),
                renewFormViewModel: RenewalUnderEditSelector(store),
                subscription: CurrentSubscriptionSelector(store),
                modifyRenewalSubscriptionItem: ModifyRenewalSubscriptionItemSelector(store)
            };
        };

        this.renewFormViewModel = DEFAULT_RENEW_FORM_VIEW_MODEL;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.setRecoverableViewModel();
    }

    $onDestroy() {
        this.actions.setEditRenewal(DEFAULT_RENEW_FORM_VIEW_MODEL);
        this.disconnectRedux();
    }

    handleClose() {
        this.onClose()();
    }

    handleFormSubmit() {
        if (this.renewalForm.$valid) {
            const updatedSubscription = {
                Id: this.state.subscription.Id,
                RenewalType: this.renewFormViewModel.type,
                TerminationReason: this.renewFormViewModel.terminationReason
            };
            if (!this.state.modifyRenewalSubscriptionItem) {
                // Top Level Subscription's renewal is changed
                updatedSubscription.Renew = this.renewFormViewModel.renew;
            }
            if (this.state.subscription.Items.length > 1) {
                // Aligned Subscription's renewal or Individual Item of an Aligned Subscription is changed.
                if (this.renewFormViewModel.renew) {
                    updatedSubscription.RenewItemIds = this.affectedSubscriptions.map(item => {
                        return item.Id;
                    });
                } else {
                    updatedSubscription.RemoveItemIds = this.affectedSubscriptions.map(item => {
                        return item.Id;
                    });
                }
            }

            this.actions.updateSubscription(this.state.customerId, updatedSubscription).then((response) => {
                const onSuccessCb = this.onSuccess();
                this.renewalForm.$setUntouched();
                this.notification.transientSuccess(i18n.translate(this.localeKeys.SUBSCRIPTIONS.UPDATE_SUCCESS));
                if (onSuccessCb) {
                    onSuccessCb(response.Subscription);
                }
            });
        }
    }

    onRenewalToggle() {
        // we set the form to untouched on checkbox toggle to give the user the ability to fill in
        // the renewal options without errors displaying.
        this.renewFormViewModel.renew = !this.renewFormViewModel.renew;
        this.renewalForm.$setUntouched();
        this.saveRecoverableViewModel();
    }

    onRenewalChange(option) {
        this.renewFormViewModel.type = option && option.id || null;
        this.saveRecoverableViewModel();
    }

    onTerminationChange(option) {
        this.renewFormViewModel.terminationReason = option && option.id || null;
        this.saveRecoverableViewModel();
    }

    saveRecoverableViewModel() {
        this.actions.setEditRenewal(this.renewFormViewModel);
    }

    setRecoverableViewModel() {
        const newViewModel = {
            isRenewTypeConfigurable:  this.state.subscription.Policy &&
                this.state.subscription.Policy.RenewTypeConfigurable,
            renew: this.state.subscription.Renew,
            terminationReason: this.state.subscription.TerminationReason,
            type: this.state.subscription.Type
        };
        if (this.state.modifyRenewalSubscriptionItem) {
            newViewModel.renew = equals('Yes', this.state.modifyRenewalSubscriptionItem.Renew);
        }
        this.actions.setEditRenewal(newViewModel);
        this.renewFormViewModel = newViewModel;
    }

    get canChangeRenewalType() {
        return this.renewFormViewModel &&
            this.renewFormViewModel.isRenewTypeConfigurable &&
            !this.requiresTerminationReason;
    }

    get isLoading() {
        return this.state.isUpdatingSubscription;
    }

    get requiresTerminationReason() {
        return this.renewFormViewModel &&
            !this.renewFormViewModel.renew;
    }

    get affectedSubscriptions() {
        if (!this.state.modifyRenewalSubscriptionItem) {
            // All subscription Items are affected
            return this.state.subscription.Items;
        } else {
            return this.state.subscription.Items.filter(item => {
                return equals(item.Id, this.state.modifyRenewalSubscriptionItem.Id);
            });
        }
    }

}

export default {
    controller: RenewModalController,
    template: require('./renew.modal.html'),
    bindings: {
        onClose: '&',
        onSuccess: '&'
    }
};
