import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
// import isNil from 'ramda/src/isNil';
// import pathOr from 'ramda/src/pathOr';
// import reject from 'ramda/src/reject';
import moment from 'moment';
// import isEmpty from 'lodash/isEmpty';
// import i18n from 'invision-core/src/components/i18n/i18n';
// import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';
import {
    MetadataCodeLoadedSelector,
    // MetadataCodeTypeSelector,
    // MetadataOptionsForCodeValuesSelector,
    // SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    // SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
// import {
//     CurrentBusinessUnitCurrencyCodeSelector,
//     IsDbss
// } from 'invision-core/src/components/session/businessunit.selectors';
import {
    // fetchAddressCountryTypes,
    // fetchAddressStateTypes,
    // fetchCreditCardTypes,
    fetchCodeTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerCareKeys from '../../../../../../locales/keys';
// import cloneDeep from 'lodash/cloneDeep';
// import {
//     BILLING_EFFECTIVE_DATE_ORDER_OPTIONS,
//     DOWN_PAYMENT_TYPE,
//     ORDER_SCENARIOS,
//     INVOICE_TIMING_CONSTANTS,
//     SCHEDULED_ORDER_OPTIONS
// } from '../../../../../../customercare.constants';
import {
    BillingEffectiveDateOptionsSelector,
    BillingEffectiveDateSettingsSelector,
    // IsQuotingOfferSelector,
    // IsSubmittingOrderSelector,
    // OfferingOrderCheckoutPageEditFormSelector,
    // OfferingOrderOffCycleAlignmentSelector,
    SelectedOrderExecutionOptionsSelector
} from '../../../../../../reducers/selectors/offering.order.selectors';
import {
    // CartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector,
    GetMaxDateForFutureDatedOrder,
    // IsFutureDatedOrderAllowedInBunt,
    // SelectedOfferChangesEffectiveSelector,
    // SelectedOfferExternalContractIdSelector,
    // SelectedOfferPurchaseOrderNumberSelector,
    // ShoppingCartSelector
} from '../../../../../../reducers/selectors/selected.offering.order.selectors';
// import {
//     BLANK_BILLING_ADDRESS_NEW,
//     BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY
// } from '../../../../../customer/makePayment/make.payment.constants';
// import {
//     createCardShortName,
//     getCardName
// } from '../../../../../paymentBillingAddressForm/payment.billing.address.form.helper';
// import {
//     addUnsavedPaymentMethod,
//     retrieveAvailablePaymentInstrumentTypes,
//     saveAutoPayFlagStatus,
//     selectPaymentMethod,
//     setRecordPaymentInstrument,
//     supplyPaymentInstrument,
//     updatePaymentAccountsOnSelectedPaymentMethod
// } from '../../../../../../reducers/actions/customer.ewallet.actions';
// import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../../../../customer/ewallet/ewallet.constants';
// import {setFormattedCityState} from '../../../../../../reducers/helpers/customer.helper';
// import {
//     InvisionConfigurationAdditionalPropertiesSelector
// } from '../../../../../../reducers/selectors/configuration.invision.selectors';
// import {
//     DefaultPaymentAutoPayEnable,
//     DefaultPaymentMethodSelector,
//     DefaultPaymentMethodAutoPaySelector,
//     IsRetrievingEwalletDataSelector,
//     PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
//     PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesAutoPayViewModelSelector,
//     PaymentInstrumentTypeValueOptions,
//     PaymentInstrumentTypeValueOptionsWithAutoPay,
//     PaymentMethodWithConvergentBillerAccountsSelector,
//     SelectedPaymentAutoPayEnable,
//     SelectedPaymentForSubmitAtCheckoutSelector,
//     SelectedPaymentMethodSelector,
//     // SupplyPaymentInstrumentSelector
// } from '../../../../../../reducers/selectors/customer.ewallet.selectors';
// import {
//     PostalCodeSelector,
//     UpdateAddressPopupStateRegionProvinceValueOptions

// } from '../../../../../../reducers/selectors/customer.addresses.selectors';
// import {setAddressData} from '../../../../../../reducers/actions/customer.addresses.actions';
// import {
//     BillCycleDetailsSelector,
//     CandidateBillCyclesSelector,
//     IsEvaluatingBillCyclesSelector,
//     IsRetrievingBillCycleDetailsSelector,
//     BillCycleDetailsLastRetrieveErrorSelector
// } from '../../../../../../reducers/selectors/customer.billcycle.selectors';
// import {
//     retrieveBillCycleDetails,
//     retrieveCandidateBillCycles
// } from '../../../../../../reducers/actions/customer.billcycle.actions';
import {
    // CurrentCustomerBillCycleSelector,
    // CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIdSelector,
    // CurrentCustomerSelector,
    // CustomerIsFetchingDataSelector
} from '../../../../../../reducers/selectors/customer.selectors';
// import {
//     CustomerInfoSelector,
//     NewConnectCandidateBillCyclesSelector
// } from '../../../../../../reducers/selectors/new.connect.wizard.selectors';
// TODO - Customer Order wizard componentized steps should not be importing anything from a specific wizards
// import {IsSubmittingCOSOrderSelector} from '../../../../../../reducers/selectors/transition.offer.wizard.selectors';
// import {
//     IsCalculatingQuoteSelector,
//     IsMultiOfferSelector,
//     OffCycleAlignmentSubscriberProductDataSelector,
//     MultiOfferShoppingCartSelector,
//     MultiOfferCartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector,
//     AddOfferOffCycleAlignmentOptionsSelector,
//     AddOfferSelectedOffCycleAlignmentsSelector,
//     AddOfferProductIdsAndPricingPlanIdsOffCycleOfferSelector
// } from '../../../../../../reducers/selectors/add.offer.wizard.selectors';
// import {SelectedContractSelector} from '../../../../../../reducers/selectors/contract.selectors';
import {
    retrieveOrderContext,
    // retrieveOffCycleAlignmentOptions,
    // retrieveTransferFromSubscriber,
    // setChangesEffective,
    setCheckoutDetailsValidation,
    // setDevicePaymentOptions,
    setExecutionOptions,
    // setExternalContractId,
    // setPurchaseOrderNumber,
    // setOffCycleAlignmentSubscriberProductData,
    // updateShippingDetails
} from '../../../../../../reducers/actions/offering.order.actions';
// import {SubscriberExternalGiftCardTypeOptionsWithRegexes} from '../../../../../../reducers/selectors/modify.payment.method.popup.selectors';
// import {retrieveCustomer} from '../../../../../../reducers/actions/customer.actions';
// import {IsAutoPayEnabledSelector} from '../../../../../../reducers/selectors/autopay.configuration.selectors';
// import {SelectedOfferingAssignedPaymentInstrumentSelector} from '../../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {OVERRIDE_BILLING_EFFECTIVE_DATE} from '../../../../../../security.attributes';
// import {stateRequiredHelper} from '../../../../../customer/addresses/address.helper';
import {getInitialExecutionOptionsModel} from '../../../../../shared/presentationalComponents/orders/checkout/futureDatedOrders/future.dated.orders.helpers';
// import {CUSTOMER_CATEGORY} from '../../../../../createCustomerPopup/create.customer.popup.constants';

export class ConfirmController {
    constructor($filter, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            $timeout,
            // addressFormModel: {},
            // availableCredit: null,
            // availableFinanceCredit: null,
            // billCycleOptions: [],
            // billCycleSelectOptionTemplate: require('invision-ui/lib/components/formControls/selectors/singleselect/optionTemplates/text.with.dot.delimited.subtext.html'),
            // callRecalculateQuote: this.callRecalculateQuote.bind(this),
            // cartHasOfferWithBillerRuleCycleLevelOtherThanItem: this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem.bind(this),
            coreLocaleKeys: CoreLocaleKeys,
            customerCareKeys: CustomerCareKeys,
            // customerCategory: CUSTOMER_CATEGORY,
            // devicePaymentEditableItem: this.devicePaymentEditableItem.bind(this),
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            // editedBillCycle: null,
            executionOptionsMinDate: moment(this.getTomorrow()).startOf('day').utc().toISOString(),
            executionOptionsModel: {},
            extraErrorMessages: [],
            // getFormatedCityStateForShippingAddress: this.getFormatedCityStateForShippingAddress.bind(this),
            // handleIsAutoPayActiveChanged: this.handleIsAutoPayActiveChanged.bind(this),
            // handleItemDownPaymentChanged: this.handleItemDownPaymentChanged.bind(this),
            // handlePaymentTypeChange: this.handlePaymentTypeChange.bind(this),
            // INVOICE_TIMING_CONSTANTS,
            // isAlignRenewals: false,
            // isAutoPayActive: false,
            // isAutoPayEdit: false,
            isBillingEffectiveDateShown: this.isBillingEffectiveDateShown.bind(this),
            // isShipping: this.isShipping.bind(this),
            // itemDownPayment: null,
            // onBillCycleSelectOptionSelected: this.onBillCycleSelectOptionSelected.bind(this),
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            onFdoFormValidityChange: this.onFdoFormValidityChange.bind(this),
            onOfferAdditionalPropertyChanged: this.onOfferAdditionalPropertyChanged.bind(this),
            onOfferAdditionalPropertyListUpdated: this.onOfferAdditionalPropertyListUpdated.bind(this),
            onOrderAdditionalPropertyChanged: this.onOrderAdditionalPropertyChanged.bind(this),
            onOrderAdditionalPropertyListUpdated: this.onOrderAdditionalPropertyListUpdated.bind(this),
            // onSubmitEditModal: this.onSubmitEditModal.bind(this),
            // paymentInstrument: {},
            // showSideNavEditModal: this.showSideNavEditModal.bind(this),
            // SCHEDULED_ORDER_OPTIONS,
            // SIDE_NAV_FORMS: {
            //     BILLING_AND_SCHEDULING: 'editModalBillingAndSchedulingForm',
            //     DEVICE_PAYMENT: 'editModalDevicePaymentForm',
            //     SHIPPING: 'editModalShippingForm'
            // },
            // selectPaymentInstrument: this.selectPaymentInstrument.bind(this),
            // selectPaymentInstrumentOnRecord: this.selectPaymentInstrumentOnRecord.bind(this),
            uiNotificationService,
            // CONSTANTS: {
            //     TRUE_VALUE: true,
            //     FALSE_VALUE: false
            // },
            // edit: {
            //     financedItems: []
            // },
            // onAddressFormUpdate: this.onAddressFormUpdate.bind(this),
            // handleCreditBreachValidations: this.handleCreditBreachValidations.bind(this),
            // updateSubscriberProductIds: this.updateSubscriberProductIds.bind(this),
            // updateSelectedDeliveryMethod: this.updateSelectedDeliveryMethod.bind(this)
        });
    }

    $onInit() {
        const codeTables = [
            // CODES.InvisionConfiguration,
            // CODES.PaymentInstrumentType,
            // CODES.PaymentInstrumentConfiguration,
            CODES.FutureDatedOrderConfiguration,
            // CODES.ExternalGiftCardType,
            // CODES.ExternalBillType,
            // CODES.SubscriberSearchConfig
        ];

        const mapStateToTarget = (store) => {
            return {
                // addressCountries: MetadataOptionsForCodeValuesSelector(CODES.AddressCountry, store),
                // addressCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
                // addressRequirements: MetadataCodeTypeSelector(CODES.AddressRequirements, store),
                // addressRequirementsLoaded: MetadataCodeLoadedSelector(CODES.AddressRequirements, store),
                // addressStatesOptions: UpdateAddressPopupStateRegionProvinceValueOptions(store),
                // addressStateTypesLoaded: MetadataCodeLoadedSelector(CODES.AddressStateProvinceRegion, store),
                // billCycleDetails: BillCycleDetailsSelector(store),
                // billCycleDetailsLastRetrieveError: BillCycleDetailsLastRetrieveErrorSelector(store),
                billingEffectiveDateOptions: BillingEffectiveDateOptionsSelector(store),
                billingEffectiveDateSettings: BillingEffectiveDateSettingsSelector(store),
                // candidateBillCycles: CandidateBillCyclesSelector(store),
                // candidateBillCyclesNewConnect: NewConnectCandidateBillCyclesSelector(store),
                // cartContainsOfferWithBillerRuleCycleLevelOtherThanItem: CartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector(store),
                // changesEffective: SelectedOfferChangesEffectiveSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                // creditCardTypes: MetadataCodeTypeSelector(CODES.CreditCardType, store),
                // currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                // currentCustomer: CurrentCustomerSelector(store),
                // currentCustomerBillCycle: CurrentCustomerBillCycleSelector(store),
                // currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                // customerInfoNewConnect: CustomerInfoSelector(store),
                // defaultPaymentAutoPayEnable: DefaultPaymentAutoPayEnable(store),
                // defaultPaymentMethod: DefaultPaymentMethodSelector(store),
                // defaultPaymentMethodWithAutoPay: DefaultPaymentMethodAutoPaySelector(store),
                // editModalSideNavList: OfferingOrderCheckoutPageEditFormSelector(store),
                executionOptions: SelectedOrderExecutionOptionsSelector(store),
                // externalBillTypes: MetadataCodeTypeSelector(CODES.ExternalBillType, store),
                // externalContractId: SelectedOfferExternalContractIdSelector(store),
                // externalGiftCardTypes: SubscriberExternalGiftCardTypeOptionsWithRegexes(store),
                // fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                // fdoConfiguration: MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoMaxDate: GetMaxDateForFutureDatedOrder(store),
                // invisionConfiguration: InvisionConfigurationAdditionalPropertiesSelector(store),
                // isAutoPayEnabled: IsAutoPayEnabledSelector(store),
                // isCalculatingQuote: IsCalculatingQuoteSelector(store),
                // isDbss: IsDbss(store),
                // isEvaluatingBillCycles: IsEvaluatingBillCyclesSelector(store),
                // isFetchingCustomerData: CustomerIsFetchingDataSelector(store),
                // isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                // isMultioffer: IsMultiOfferSelector(store),
                // isQuotingOffer: IsQuotingOfferSelector(store),
                // isRetrievingBillCycleDetails: IsRetrievingBillCycleDetailsSelector(store),
                // isSubmittingCOSOrder: IsSubmittingCOSOrderSelector(store),
                // isSubmittingOrder: IsSubmittingOrderSelector(store),
                // multiOfferCartContainsOfferWithBillerRuleCycleLevelOtherThanItem: MultiOfferCartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector(store),
                // multiOfferShoppingCart: MultiOfferShoppingCartSelector(store),
                // offCycleAlignments: OfferingOrderOffCycleAlignmentSelector(store),
                // offCycleAlignmentOptions: AddOfferOffCycleAlignmentOptionsSelector(store),
                // offCycleAlignmentSubscriberProductData: OffCycleAlignmentSubscriberProductDataSelector(store),
                // paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                // paymentInstrumentsAutoPay: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesAutoPayViewModelSelector(store),
                // paymentInstrumentsWithAutoPay : PaymentInstrumentTypeValueOptionsWithAutoPay(store),
                // paymentInstrumentTypeValueOptions: PaymentInstrumentTypeValueOptions(store),
                // paymentMethodWithConvergentAccount: PaymentMethodWithConvergentBillerAccountsSelector(store),
                // postalCode: PostalCodeSelector(store),
                // purchaseOrderNumber: SelectedOfferPurchaseOrderNumberSelector(store),
                // productIdsAndPricingPlanIdsOffCycleOffers: AddOfferProductIdsAndPricingPlanIdsOffCycleOfferSelector(store),
                // selectedContract: SelectedContractSelector(store),
                // selectedOffCycleAlignments: AddOfferSelectedOffCycleAlignmentsSelector(store),
                // selectedOfferingAssignedPaymentInstrument: SelectedOfferingAssignedPaymentInstrumentSelector(store),
                // selectedPaymentClean: SelectedPaymentForSubmitAtCheckoutSelector(store),
                // selectedPaymentMethod: SelectedPaymentMethodSelector(store),
                // selectedPaymentMethodAutoPay: SelectedPaymentAutoPayEnable(store),
                // shoppingCart: ShoppingCartSelector(store),
                // subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                // subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                // supplyPaymentInstrument: SupplyPaymentInstrumentSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            // addUnsavedPaymentMethod,
            // fetchAddressCountryTypes,
            // fetchAddressStateTypes,
            fetchCodeTypes,
            // fetchCreditCardTypes,
            // retrieveAvailablePaymentInstrumentTypes,
            // retrieveBillCycleDetails,
            // retrieveCandidateBillCycles,
            // retrieveCustomer,
            // retrieveOffCycleAlignmentOptions,
            retrieveOrderContext,
            // retrieveTransferFromSubscriber,
            // saveAutoPayFlagStatus,
            // selectPaymentMethod,
            // setAddressData,
            // setChangesEffective,
            setCheckoutDetailsValidation,
            // setDevicePaymentOptions,
            setExecutionOptions,
            // setExternalContractId,
            // setPurchaseOrderNumber,
            // setRecordPaymentInstrument,
            // setOffCycleAlignmentSubscriberProductData,
            // supplyPaymentInstrument,
            // updatePaymentAccountsOnSelectedPaymentMethod,
            // updateShippingDetails
        };

        // const initialExecutionOptionsModelRelatedPromises = [];

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        // this.onCheckoutStepSubmit({
        //     handleCheckoutStepSubmit: this.handleCreditBreachValidations
        // });

        // this.PaymentTypes = SUPPORTED_PAYMENT_INSTRUMENT_TYPES;

        // if (this.serviceAddress) {
        //     const cityState = {
        //         city: this.serviceAddress.City,
        //         stateRegionProvince: this.serviceAddress.State
        //     };
        //     this.formattedCityState = setFormattedCityState(cityState);
        // }

        // this.isAutoPayEdit = this.state.defaultPaymentAutoPayEnable || this.state.selectedPaymentMethodAutoPay ?
        //     clone(this.state.defaultPaymentAutoPayEnable || this.state.selectedPaymentMethodAutoPay) :
        //     false;

        if (this.isBillingEffectiveDateShown()) {
            this.actions.retrieveOrderContext(null, this.state.currentCustomerId, true).then(() => {
                const getInitialExecutionOptionsModelArgs = {
                    executionOptionsSavedCopy: this.state.executionOptions,
                    showImmediateOption: true,  // !this.transferFromSubscriberId,
                    showBillCycleOption: false, // this.hasInvoiceItems && !this.transferFromSubscriberId && this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem(),
                    showDatePickerOption: true, // !this.transferFromSubscriberId,
                    isBillingEffectiveDateShown: true,  // this.isBillingEffectiveDateShown(),
                    billingEffectiveDateOptions: this.state.billingEffectiveDateOptions
                };

                this.executionOptionsModel = getInitialExecutionOptionsModel(getInitialExecutionOptionsModelArgs);
                this.actions.setExecutionOptions(this.executionOptionsModel);
                this.executionOptionsModel = {};
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }

        // this.getShoppingCartItemsForRetrievePaymentInstrumentTypes = () => {
        //     if (this.state.multiOfferShoppingCart && this.state.multiOfferShoppingCart.Items) {
        //         return this.state.multiOfferShoppingCart.AddItems?.length || this.state.multiOfferShoppingCart.ModifyItems?.length ?
        //             [...this.state.multiOfferShoppingCart.AddItems, ...this.state.multiOfferShoppingCart.ModifyItems] :
        //             this.state.multiOfferShoppingCart.Items;
        //     } else if (this.state.shoppingCart && this.state.shoppingCart.Items) {
        //         return this.state.shoppingCart.AddItems?.length || this.state.shoppingCart.ModifyItems?.length ?
        //             [...this.state.shoppingCart.AddItems, ...this.state.shoppingCart.ModifyItems] :
        //             this.state.shoppingCart.Items;
        //     }
        //     return [];
        // };

        // this.actions.retrieveAvailablePaymentInstrumentTypes({
        //     Currency: this.state.currentCustomerCurrencyCode || this.state.currentBusinessUnitCurrencyCode,
        //     ShoppingCart: {
        //         Items: this.getShoppingCartItemsForRetrievePaymentInstrumentTypes()
        //     }
        // }).then(() => {
        //     if (!this.paymentInfo && !this.isAutoPayActive) {
        //         this.supplyPaymentInstrument = false;
        //         this.serviceAddressAsBillingAddress = true;
        //         this.paymentInstrument = Object.assign(cloneDeep(BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY), {
        //             Type: this.state.paymentInstrumentTypeValueOptions.length
        //                 ? this.state.paymentInstrumentTypeValueOptions[0].paymentInstrumentType.Value
        //                 : BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY.Type
        //         });
        //         this.billingAddress = cloneDeep(BLANK_BILLING_ADDRESS_NEW);
        //     } else {
        //         this.supplyPaymentInstrument = true;
        //         this.serviceAddressAsBillingAddress = this.paymentInfo.BillingAddressIsServiceAddress;
        //         this.paymentInstrument = {
        //             CreditCard: Object.assign({}, this.paymentInfo.CreditCard),
        //             ConvergentBillerPaymentInstrumentAccounts: Object.assign({}, this.paymentInfo.ConvergentBillerPaymentInstrumentAccounts),
        //             ECheck: Object.assign({}, this.paymentInfo.ECheck),
        //             Type: this.paymentInfo.Type
        //         };
        //     }
        // }).catch((error) => {
        //     this.uiNotificationService.transientError(error.translatedMessage);
        // });

        // if (this.paymentInfo) {
        //     this.billingAddress = Object.assign({}, this.paymentInfo.BillingAddress);
        //     this.addressFormModel.addressLine1 = this.billingAddress.LineOne;
        //     this.addressFormModel.addressLine2 = this.billingAddress.LineTwo;
        //     this.addressFormModel.city = this.billingAddress.City;
        //     this.addressFormModel.country = this.billingAddress.Country;
        //     this.addressFormModel.stateRegionProvince = this.billingAddress.State;
        //     this.addressFormModel.postalCode = this.billingAddress.PostalCode;
        //     this.setCountryData();
        // }


        // this.newPaymentInstrument = this.state.paymentInstruments.length === 0;
        // if (!this.newPaymentInstrument) {
        //     if (this.state.selectedPaymentMethod && this.state.supplyPaymentInstrument) {
        //         this.supplyPaymentInstrument = true;
        //     } else {
        //         const defaultSelectedPaymentInstrument = this.state.selectedOfferingAssignedPaymentInstrument || this.state.defaultPaymentMethodWithAutoPay;
        //         this.selectPaymentInstrument(defaultSelectedPaymentInstrument, true);
        //     }
        // }

        // if (this.state.creditCardTypes.length === 0) {
        //     this.actions.fetchCreditCardTypes();
        // }
        // if (!this.state.addressCountriesLoaded) {
        //     this.actions.fetchAddressCountryTypes();
        // }
        // if (!this.state.addressStateTypesLoaded) {
        //     this.actions.fetchAddressStateTypes();
        // }
        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).then(() => {
                    // if (codeType === CODES.SubscriberSearchConfig) {
                    //     this.initializeIsAutopayActive();
                    // }
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
            // else {
            //     this.initializeIsAutopayActive();
            // }
        });

        // if (this.state.isDbss) {
        //     Promise.resolve().then(() => {
        //         if (this.state.currentCustomerId) {
        //             return this.actions.retrieveCustomer(this.state.currentCustomerId).then(() => {
        //                 return this.setInitialBillCycleSelection();
        //             });
        //         } else if (this.state.candidateBillCycles.length > 0) {
        //             this.setDefaultBillCycle(true);
        //         }
        //     }).then(() => {
        //         this.billCycleOptions = this.getBillCycleOptions();
        //     });
        // }

        // this.purchaseOrderNumber = this.state.purchaseOrderNumber ? clone(this.state.purchaseOrderNumber) : null;
        // this.externalContractId = this.state.externalContractId ? clone(this.state.externalContractId) : null;
        this.additionalPropertyFileds = clone(this.additionalPropertiesCollection);
        this.offerAdditionalPropertyFileds = clone(this.offerAdditionalPropertiesCollection);
        // const EMPTY_RENEWAL_DATE_OBJ = {};

        // if (this.isEditFlow()) {
        //     if (this.state.purchaseOrderNumber) {
        //         this.purchaseOrderNumberReadOnly = true;
        //     }

        //     if (this.state.externalContractId) {
        //         this.externalContractIdReadOnly = true;
        //     }
        // }

        // this.contractTermsConditionsPopupConfig = {
        //     onRegisterApi: ({api}) => {
        //         this.contractTermsConditionsPopupApi = api;
        //     }
        // };

        // this.openContractTermsConditionsPopup = () => {
        //     this.contractTermsConditionsPopupOpened = true;
        //     this.$timeout(() => {
        //         this.contractTermsConditionsPopupApi.open();
        //     });
        // };

        // this.handleCloseContractTermsConditionsPopup = () => {
        //     this.contractTermsConditionsPopupApi.close();
        //     this.contractTermsConditionsPopupOpened = false;
        // };

        // this.downPaymentsSchedulingAndBillingPopupConfig = {
        //     onRegisterApi: ({api}) => {
        //         this.devicePaymentsSchedulingAndBillingPopupApi = api;
        //     }
        // };
        // this.editCheckoutPageInformationPopupConfig = {
        //     onRegisterApi: ({api}) => {
        //         this.editCheckoutPageInformationPopupApi = api;
        //     }
        // };

        // this.openEditCheckoutPageInformationPopup = (editFormName) => {
        //     this.isAlignRenewals = false;
        //     this.renewalData = clone(this.state.offCycleAlignmentSubscriberProductData);
        //     this.showErrorSummary = false;
        //     this.selectedItemFormName = editFormName;
        //     this.actions.setCheckoutDetailsValidation({
        //         formName: editFormName,
        //         isActive: true
        //     });
        //     // this.edit.financedItems = clone(this.financedItems);
        //     // this.billCycleOptions = this.getBillCycleOptions();
        //     this.editedBillCycle = this.selectedBillCycle;
        //     this.editCheckoutPageInformationPopupOpened = true;

        //     this.$timeout(() => {
        //         this.editCheckoutPageInformationPopupApi.open();
        //     });
        // };

        // this.closeEditCheckoutPageInformationPopup = () => {
        //     this.editCheckoutPageInformationPopupApi.close();
        //     this.editCheckoutPageInformationPopupOpened = false;
        // };

        // this.closeDownPaymentsSchedulingAndBillingPopup = () => {
        //     this.executionOptionsModel = {};
        //     this.devicePaymentsSchedulingAndBillingPopupApi.close();
        // };

        // if (!this.state.addressRequirementsLoaded) {
        //     this.actions.fetchCodeTypes(CODES.AddressRequirements);
        // }

        // this.isStateRegionProvinceRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);

        // this.changesEffectiveSelection = this.state.changesEffective ?
        //     `${this.state.changesEffective}` :
        //     `${INVOICE_TIMING_CONSTANTS.INVOICE_IMMEDIATELY}`;

        // this.handleCreditBreachValidations();
        // Promise.all(initialExecutionOptionsModelRelatedPromises).then(() => {
        //     const getInitialExecutionOptionsModelArgs = {
        //         executionOptionsSavedCopy: this.state.executionOptions,
        //         showImmediateOption: !this.transferFromSubscriberId,
        //         showBillCycleOption: this.hasInvoiceItems && !this.transferFromSubscriberId && this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem(),
        //         showDatePickerOption: !this.transferFromSubscriberId,
        //         isBillingEffectiveDateShown: this.isBillingEffectiveDateShown(),
        //         billingEffectiveDateOptions: this.state.billingEffectiveDateOptions
        //     };
        //     this.executionOptionsModel = getInitialExecutionOptionsModel(getInitialExecutionOptionsModelArgs);
        //     this.actions.setExecutionOptions(this.executionOptionsModel);
        //     this.executionOptionsModel = {};
        // });
        // if (this.showAlignRenewals && this.state.productIdsAndPricingPlanIdsOffCycleOffers.length) {
        //     this.actions.retrieveOffCycleAlignmentOptions(this.state.productIdsAndPricingPlanIdsOffCycleOffers, this.state.currentCustomerId);
        // }
        // if (!this.state.isMultioffer) {
        //     this.state.offCycleAlignmentOptions.forEach((offer) => {
        //         EMPTY_RENEWAL_DATE_OBJ[offer.offeringInstanceId] = {};
        //         offer.pricingPlans.map((pricingPlan) => {
        //             EMPTY_RENEWAL_DATE_OBJ[offer.offeringInstanceId][pricingPlan.id] ={
        //                 'pricingPlanId': null,
        //                 'productId': null,
        //                 'nextRenewalDate': null,
        //                 'subscriberProductId': null
        //             };
        //         });
        //     });
        //     this.renewalData = clone(EMPTY_RENEWAL_DATE_OBJ);
        //     this.actions.setOffCycleAlignmentSubscriberProductData(this.renewalData);
        // }
    }

    $onChanges(changesObj) {
        // if (this.state &&
        //     (pathOr(false, ['availableCredit', 'currentValue'], changesObj) ||
        //      pathOr(false, ['availableFinanceCredit', 'currentValue'], changesObj))) {
        //     this.handleCreditBreachValidations();
        // }
    }

    $onDestroy() {
        this.actions.setExecutionOptions({});
        this.disconnectRedux();
    }

    // isShipping() {
    //     return this.shippingAddress;
    // }

    // showSideNavEditModal() {
    //     return this.state.editModalSideNavList.length && this.canShowFdoAndBed() && !this.devicePaymentEditableItem();
    // }

    // devicePaymentEditableItem() {
    //     return this.financedItems.length === 1 && this.financedItems.find((item) => {
    //         return item.downPaymentType === DOWN_PAYMENT_TYPE.DOWN_PAYMENT_EQUAL_TO_FIRST_INSTALLMENT ? false : (!!item.downPaymentType && item.isFullUpfrontPayment);
    //     });
    // }

    // getFormatedCityStateForShippingAddress() {
    //     if (this.shippingAddress) {
    //         const cityState = {
    //             city: this.shippingAddress.City,
    //             stateRegionProvince: this.shippingAddress.State
    //         };
    //         return setFormattedCityState(cityState);
    //     }
    // }

    // initializeIsAutopayActive() {
    //     this.isAutoPayActive = this.paymentInfo
    //     && (this.paymentInfo.ConvergentBillerPaymentInstrumentAccounts
    //         && this.paymentInfo.ConvergentBillerPaymentInstrumentAccounts.length) &&
    //     (this.isOrderPostPaid && this.state.isAutoPayEnabled && this.state.isDbss) ?
    //         this.paymentInfo.ConvergentBillerPaymentInstrumentAccounts[0].AutoPay :
    //         false;
    // }

    // getPaymentInstrumentObject(paymentInfoObj) {
    //     if (paymentInfoObj.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD ||
    //         !pathOr(0, ['Type', 'length'], paymentInfoObj)) {
    //         return paymentInfoObj.CreditCard;
    //     } else if (paymentInfoObj.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK) {
    //         return paymentInfoObj.ECheck;
    //     } else if (paymentInfoObj.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD) {
    //         return paymentInfoObj.ExternalGiftCard;
    //     } else if (paymentInfoObj.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD) {
    //         return paymentInfoObj.GiftCard;
    //     } else if (paymentInfoObj.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL) {
    //         return paymentInfoObj.ExternalBill;
    //     }
    // }

    getTomorrow() {
        const today = moment();
        return moment(today).add(1, 'days');
    }

    // buildPaymentInstrumentObject() {
    //     const defaultAutopayObject = [{
    //         AutoPay: this.isAutoPayActive
    //     }];

    //     if (!this.isPaymentInstrumentRequired &&
    //         !this.hasDownPaymentItemsChanges() &&
    //         !this.supplyPaymentInstrument &&
    //         !(this.isOrderPostPaid &&
    //             this.isAutoPayActive &&
    //             this.state.isAutoPayEnabled &&
    //             this.state.isDbss)) {
    //         // don't fill the redux store with unneeded cc data and don't pass to api
    //         return null;
    //     }

    //     if (!this.serviceAddressAsBillingAddress) {
    //         this.billingAddress.Name = this.billingAddress.LineOne; // Name is required by the API and needs to be unique.
    //     }

    //     if (this.newPaymentInstrument) {
    //         delete this.paymentInstrument.Id;
    //     }

    //     const paymentInstrumentTypes = this.isAutoPayActive ? this.state.paymentInstrumentsWithAutoPay : this.state.paymentInstrumentTypeValueOptions;
    //     const paymentInstrumentObject = this.getPaymentInstrumentObject(this.paymentInstrument);

    //     return Object.assign(reject(isEmpty, this.paymentInstrument), {
    //         BillingAddressIsServiceAddress: this.serviceAddressAsBillingAddress,
    //         BillingAddress: !this.serviceAddressAsBillingAddress ? this.billingAddress : this.serviceAddress,
    //         ConvergentBillerPaymentInstrumentAccounts: this.isOrderPostPaid ? defaultAutopayObject : undefined,
    //         Name: createCardShortName(paymentInstrumentObject,
    //             getCardName(paymentInstrumentObject.Type, this.paymentInstrument, this.state.creditCardTypes, undefined, paymentInstrumentTypes))
    //     });
    // }

    // hasDownPaymentItemsChanges() {
    //     return this.financedItems && this.financedItems.length && this.financedItems.filter((item) => {
    //         return item.downPayment || item.isFullPriceDownPaymentSelected || item.isDownPaymentEqualToFirstInstallmentSelected;
    //     }).length;
    // }

    shouldShowFdoExecutionOptionsError() {
        return this.wizardForm.$submitted && this.errorsExistOnForm;
    }

    // shouldShowCreditLimitBreached() {
    //     this.breachedCreditLimit = this.isCreditLimitBreached ? i18n.translate(CustomerCareKeys.CREDIT_LIMIT_BREACHED, {
    //         breachAmount: this.$filter('invCurrency')(this.availableCredit, this.state.currentCustomer.SubscriberCurrency)
    //     }) : null;
    //     return this.isCreditLimitBreached && this.availableCredit < 0;
    // }

    // shouldShowFinanceLimitBreached() {
    //     this.breachedFinanceLimit = this.availableFinanceCredit > 0 ? i18n.translate(CustomerCareKeys.FINANCE_LIMIT_BREACHED, {
    //         breachAmount: this.$filter('invCurrency')(this.availableFinanceCredit, this.state.currentCustomer.SubscriberCurrency)
    //     }) : null;
    //     return this.availableFinanceCredit && !(this.availableFinanceCredit < 0);
    // }

    // shouldShowCreditAndFinanceLimitBreaches() {
    //     return this.shouldShowCreditLimitBreached() || this.shouldShowFinanceLimitBreached();
    // }

    // fetchQuote(newConnect) {
    //     // TODO: CustomerOrder: The checkout component should have no concept of which it's calling.
    //     // This breaks the wizard design and should be handled by the parent consuming component through a binding.
    //     this.actions.setOffCycleAlignmentSubscriberProductData(this.renewalData);
    //     return newConnect ? this.fetchQuoteNewConnect()(
    //         this.state.customerInfoNewConnect,
    //         this.state.shoppingCart,
    //         (this.selectedBillCycle || {}).name):
    //         this.fetchOrderQuote()(
    //             this.state.currentCustomer,
    //             this.state.isMultioffer? this.state.multiOfferShoppingCart: this.state.shoppingCart,
    //             (this.selectedBillCycle || {}).name,
    //             this.state.isDbss);
    // }

    // isFirstOrder() {
    //     return !this.state.currentCustomerBillCycle;
    // }

    //TODO ASC-103364. this is temporary fix to get errors from address component. Reference story should add error handling in the component and remove this workaround.
    // getControlFromAddressComponent(controlName) {
    //     return this.confirmGroupOrderForm['addressWrapupController.singleAddressFormFormApi']
    //         && this.confirmGroupOrderForm['addressWrapupController.singleAddressFormFormApi']['$ctrl.formApi'] ?
    //         this.confirmGroupOrderForm['addressWrapupController.singleAddressFormFormApi']['$ctrl.formApi'].$$controls.find((control) => {
    //             return control.$name.includes(controlName);
    //         }) : undefined;
    // }



    // setInitialBillCycleSelection() {
    //     if (this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem()) {
    //         if (this.isFirstOrder() && !this.transferFromSubscriberId) {
    //             return this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, this.isOrderPostPaid ?
    //                 subscriberOrderTypeEnum.postpaid.value : subscriberOrderTypeEnum.prepaid.value).then(() => {
    //                 if (this.state.candidateBillCycles.length > 0) {
    //                     this.setDefaultBillCycle(false);
    //                 }
    //             });
    //         } else if (this.isFirstOrder() && this.transferFromSubscriberId) {
    //             // If transferring, and the To Subscriber does not have a bill cycle, it inherits the From's, otherwise it keeps it's current.
    //             return this.actions.retrieveTransferFromSubscriber(this.transferFromSubscriberId)
    //                 .then((response) => {
    //                     return this.actions.retrieveBillCycleDetails(response.Subscriber.SubscriberTypeDetails.BillCycle)
    //                         .then((detailsResponse) => {
    //                             this.selectedBillCycle = {
    //                                 billDate: this.$filter('localShortDate')(detailsResponse.BillCycleDetails.BillDate),
    //                                 id: detailsResponse.BillCycleDetails.Id,
    //                                 name: detailsResponse.BillCycleDetails.Name,
    //                                 paymentDueDate: this.$filter('localShortDate')(detailsResponse.BillCycleDetails.PaymentDueDate),
    //                                 repeatInterval: detailsResponse.BillCycleDetails.RepeatInterval,
    //                                 repeatType: detailsResponse.BillCycleDetails.RepeatType
    //                             };
    //                             this._updateBillCycle(this.selectedBillCycle.name);
    //                         });
    //                 }).catch((error) => {
    //                     this.uiNotificationService.transientError(error.translatedMessage);
    //                 });
    //         } else {
    //             return this.actions.retrieveBillCycleDetails(this.state.currentCustomerBillCycle).catch(() => {
    //                 this.uiNotificationService.error(
    //                     i18n.translate(CustomerCareKeys.CHECKOUT.RETRIEVE_BILL_CYCLE_DETAILS_ERROR, {
    //                         id: this.state.currentCustomerBillCycle
    //                     }), this.state.billCycleDetailsLastRetrieveError.translatedMessage);
    //                 this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, this.isOrderPostPaid ?
    //                     subscriberOrderTypeEnum.postpaid.value : subscriberOrderTypeEnum.prepaid.value);
    //             }).then(() => {
    //                 this.selectedBillCycle = this.state.billCycleDetails;
    //                 this._updateBillCycle(this.selectedBillCycle.name);
    //             });
    //         }
    //     } else {
    //         return Promise.resolve();
    //     }
    // }

    // cartHasOfferWithBillerRuleCycleLevelOtherThanItem() {
    //     return (this.orderScenario === ORDER_SCENARIOS.ADD_OFFER && this.state.multiOfferCartContainsOfferWithBillerRuleCycleLevelOtherThanItem) ||
    //         (this.orderScenario !== ORDER_SCENARIOS.ADD_OFFER && this.state.cartContainsOfferWithBillerRuleCycleLevelOtherThanItem);
    // }

    // setDefaultBillCycle(newConnect) {
    //     this.selectedBillCycle = newConnect ?
    //         this.state.candidateBillCyclesNewConnect[0] :
    //         this.state.candidateBillCycles[0];
    //     this._updateBillCycle(this.selectedBillCycle.name);
    // }

    // getCandidateBillCycles() {
    //     return this.state.currentCustomerId ?
    //         this.state.candidateBillCycles : this.state.candidateBillCyclesNewConnect;
    // }

    // buildWalletRequest() {
    //     return {
    //         customerId: this.customer.Id
    //     };
    // }

    isLoading() {
        // return this.isCreatingOrUpdatingCustomer ||
        //     this.isFetchingData ||
        //     this.state.isCalculatingQuote ||
        //     this.state.isEvaluatingBillCycles ||
        //     this.state.isFetchingCustomerData ||
        //     this.state.isFetchingEwalletData ||
        //     this.state.isQuotingOffer ||
        //     this.state.isRetrievingBillCycleDetails ||
        //     this.state.isSubmittingCOSOrder;

        return this.isFetchingData;
    }

    // selectPaymentInstrument(paymentInstrument, isInit = false) {
    //     this.actions.saveAutoPayFlagStatus(this.isAutoPayEdit);
    //     this.actions.selectPaymentMethod(paymentInstrument);
    //     if (this.isOrderPostPaid) {
    //         this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
    //             ConvergentBillerPaymentInstrumentAccounts: this.state.paymentMethodWithConvergentAccount.ConvergentBillerPaymentInstrumentAccounts
    //         });
    //     }
    //     this.actions.supplyPaymentInstrument(true);
    //     if (!(this.state.isDbss || isInit)) {
    //         // Quote does not need to be recalled after init in DBSS, since it is based on Service Address,
    //         // OTT does need this as it is based on Billing Address.
    //         this.callRecalculateQuote(paymentInstrument);
    //     }
    // }

    // selectPaymentInstrumentOnRecord(paymentInstrument) {
    //     this.actions.saveAutoPayFlagStatus(this.isAutoPayEdit);
    //     if (!pathOr(true, ['recordPayment'], paymentInstrument)) {
    //         this.actions.saveAutoPayFlagStatus(this.isAutoPayEdit = false);
    //         this.actions.addUnsavedPaymentMethod(paymentInstrument);
    //     }
    //     this.actions.selectPaymentMethod(paymentInstrument);
    //     if (this.isOrderPostPaid) {
    //         this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
    //             ConvergentBillerPaymentInstrumentAccounts: this.state.paymentMethodWithConvergentAccount.ConvergentBillerPaymentInstrumentAccounts
    //         });
    //     }
    //     this.actions.supplyPaymentInstrument(true);
    //     if (!this.state.isDbss) {
    //         this.callRecalculateQuote(paymentInstrument.recordPayment ? paymentInstrument : this.state.selectedPaymentClean);
    //     }
    // }

    // disableAutoPayCheck() {
    //     return (this.state.selectedPaymentMethod && pathOr(true, ['recordPayment'], this.state.selectedPaymentMethod));
    // }

    // updateSelectPaymentInstrument() {
    //     this.actions.saveAutoPayFlagStatus(this.isAutoPayEdit);
    //     this.actions.selectPaymentMethod(this.state.paymentMethodWithConvergentAccount);
    //     if (this.isOrderPostPaid) {
    //         this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
    //             ConvergentBillerPaymentInstrumentAccounts: this.state.paymentMethodWithConvergentAccount.ConvergentBillerPaymentInstrumentAccounts
    //         });
    //     }
    // }

    // addressText() {
    //     return i18n.translate(this.serviceAddress
    //         ? this.customerCareKeys.ADDRESSES.SERVICE_ADDRESS
    //         : this.customerCareKeys.ADDRESS);
    // }

    // isEditFlow() {
    //     return this.orderScenario === ORDER_SCENARIOS.CHANGE_ORDER;
    // }

    // supportsInvoiceOptions() {
    //     return this.isEditFlow() && !this.state.isDbss;
    // }

    // setCountryData() {
    //     if (this.billingAddress.Country) {
    //         const payload = {
    //             Country: this.billingAddress.Country
    //         };
    //         this.actions.setAddressData(payload);
    //     } else {
    //         // Have to apply this here due to the previous step in New Connect not needing to reset the redux store yet
    //         this.actions.setAddressData(null);
    //     }
    // }

    // handleItemDownPaymentChanged(itemIndex, item) {
    //     const isDownPaymentOptionChanged = (this.financedItems[itemIndex].isFullPriceDownPaymentSelected !==
    //         item.isFullPriceDownPaymentSelected);
    //     if (isDownPaymentOptionChanged && isNil(item.downPayment)) {
    //         item.downPayment = item.minimumDownPayment;
    //     }
    //     if ((!isNil(item.downPayment) && this.financedItems[itemIndex].downPayment !== item.downPayment) ||
    //         isDownPaymentOptionChanged) {
    //         this.isFinancedItemsModified = true;

    //         this.itemDownPayment = item;
    //     }
    // }

    // handlePaymentTypeChange() {
    //     this.paymentInstrument = Object.assign(cloneDeep(BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY), {
    //         Type: this.paymentInstrument.Type
    //     });

    //     const newPaymentInstrumentObject = this.buildPaymentInstrumentObject();

    //     this.updatePaymentInfo()(newPaymentInstrumentObject);
    // }

    // updateSubscriberProductIds(pricingPlanId, productId, offeringInstanceId, options) {
    //     this.renewalData[offeringInstanceId][pricingPlanId]['pricingPlanId'] = pricingPlanId;
    //     this.renewalData[offeringInstanceId][pricingPlanId]['productId'] = productId;
    //     const matchingOption = options.find((option) => {
    //         return option.SubscriberProductId === this.renewalData[offeringInstanceId][pricingPlanId]['subscriberProductId'];
    //     });
    //     if (matchingOption) {
    //         this.renewalData[offeringInstanceId][pricingPlanId]['nextRenewalDate'] = matchingOption.NextRenewalDate;
    //     } else {
    //         this.renewalData[offeringInstanceId][pricingPlanId]['nextRenewalDate'] = undefined;
    //         this.renewalData[offeringInstanceId][pricingPlanId]['subscriberProductId'] = undefined;
    //     }
    // }

    // updateSelectedDeliveryMethod(deliveryMethod) {
    //     return this.deliveryDecisionWithCost = this.deliveryMethodOptions.find((item) => {
    //         return item.value === deliveryMethod;
    //     });
    // }

    // handleIsAutoPayActiveChanged() {
    //     this.paymentInstrument.Type = null;
    //     this.handlePaymentInstrumentChanged();
    // }

    // validatePaymentObject(paymentInstrument) {
    //     if (paymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD) {
    //         const cardObject = paymentInstrument.CreditCard;
    //         return cardObject.NameOnCard && cardObject.Type && cardObject.AccountNumber
    //             && cardObject.ExpirationMonth && cardObject.ExpirationYear;
    //     }
    //     return true;
    // }

    // handlePaymentInstrumentChanged() {
    //     this.setCountryData();
    //     if (this.newPaymentInstrument) {
    //         if (this.isAutoPayActive && !pathOr(0, ['Type', 'length'], this.paymentInstrument) && this.state.paymentInstrumentsWithAutoPay.length) {
    //             this.savePaymentInstrument = true;
    //             this.actions.setRecordPaymentInstrument(this.savePaymentInstrument);
    //             this.paymentInstrument.Type = this.state.paymentInstrumentsWithAutoPay[0].paymentInstrumentType.Value;
    //         } else if (!this.isAutoPayActive
    //             && !pathOr(0, ['Type', 'length'], this.paymentInstrument)
    //             && this.state.paymentInstrumentTypeValueOptions.length) {
    //             this.savePaymentInstrument = true;
    //             this.actions.setRecordPaymentInstrument(this.savePaymentInstrument);
    //             this.paymentInstrument.Type = this.state.paymentInstrumentTypeValueOptions[0].paymentInstrumentType.Value;
    //         } else if (!this.isAutoPayActive && !this.isPaymentInstrumentRequired && !this.supplyPaymentInstrument &&
    //             !this.hasDownPaymentItemsChanges()) {
    //             this.supplyPaymentInstrument = false;
    //             this.serviceAddressAsBillingAddress = false;
    //             this.paymentInstrument = Object.assign(cloneDeep(BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY), {
    //                 Type: this.state.paymentInstrumentTypeValueOptions.length
    //                     ? this.state.paymentInstrumentTypeValueOptions[0].paymentInstrumentType.Value
    //                     : BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY.Type
    //             });
    //             this.billingAddress = cloneDeep(BLANK_BILLING_ADDRESS_NEW);
    //         }
    //         const creditCardForm = this.confirmGroupOrderForm['creditCardController.creditCardForm'];
    //         const shouldRecalculate = this.newPaymentInstrument &&
    //             creditCardForm && creditCardForm.$valid &&
    //             !this.state.isDbss &&
    //             (this.serviceAddressAsBillingAddress ||
    //                 (!isEmpty(this.addressFormModel) && (!isEmpty(this.addressFormModel['country']) &&
    //                         !isEmpty(this.addressFormModel['addressLine1']) &&
    //                             !isEmpty(this.addressFormModel['postalCode'])))
    //             );
    //         const newPaymentInstrumentObject = this.buildPaymentInstrumentObject();

    //         this.updatePaymentInfo()(newPaymentInstrumentObject);
    //         if (shouldRecalculate && this.validatePaymentObject(newPaymentInstrumentObject)) {
    //             this.callRecalculateQuote(newPaymentInstrumentObject);
    //         }
    //     }

    //     this.actions.supplyPaymentInstrument(this.supplyPaymentInstrument || this.isAutoPayActive);
    // }

    // handleSavePaymentInstrumentChanged() {
    //     this.actions.setRecordPaymentInstrument(this.savePaymentInstrument);
    //     if (!this.savePaymentInstrument) {
    //         this.isAutoPayActive = false;
    //         this.handlePaymentInstrumentChanged();
    //     }
    // }

    // onAddressFormUpdate(addressFormModel) {
    //     this.billingAddress.LineOne = addressFormModel.addressLine1;
    //     this.billingAddress.LineTwo = addressFormModel.addressLine2;
    //     this.billingAddress.City = addressFormModel.city;
    //     this.billingAddress.Country = addressFormModel.country;
    //     this.billingAddress.State = addressFormModel.stateRegionProvince;
    //     this.billingAddress.PostalCode = addressFormModel.postalCode;

    //     this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
    //     this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
    //     this.addressFormModel.city = addressFormModel.city;
    //     this.addressFormModel.country = addressFormModel.country;
    //     this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
    //     this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
    //     this.handlePaymentInstrumentChanged();
    // }

    // hasAddress() {
    //     const billingAddress = this.billingAddress && !!this.billingAddress.LineOne;
    //     const customerAddress = this.customer && !!this.customer.addressLine1;

    //     return this.serviceAddress || billingAddress || customerAddress;
    // }

    // callRecalculateQuote(paymentInstrument = []) {
    //     if (this.recalculateQuote) {
    //         this.recalculateQuote()(paymentInstrument);
    //     }
    // }

    // updatePurchaseOrderNumber() {
    //     this.actions.setPurchaseOrderNumber(this.purchaseOrderNumber);
    // }

    // changesEffectiveChanged() {
    //     this.actions.setChangesEffective(this.changesEffectiveSelection);
    //     this.callRecalculateQuote();
    // }

    // updateExternalContractId() {
    //     this.actions.setExternalContractId(this.externalContractId);
    // }

    onExecutionOptionChange(executionOptionsModel) {
        this.executionOptionsModel = executionOptionsModel;
        this.actions.setExecutionOptions(this.executionOptionsModel);
    }

    onFdoFormValidityChange(message) {
        this.fdoErrorMessageFromComponent = message;
        return message;
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
        return isFormValid;
    }

    // editPurchaseOrderNumber() {
    //     this.purchaseOrderNumberReadOnly = false;
    // }

    // removePurchaseOrderNumber() {
    //     this.purchaseOrderNumberReadOnly = false;
    //     this.purchaseOrderNumber = null;
    //     this.updatePurchaseOrderNumber();
    // }

    // editExternalContractId() {
    //     this.externalContractIdReadOnly = false;
    // }

    // removeExternalContractId() {
    //     this.externalContractIdReadOnly = false;
    //     this.externalContractId = null;
    //     this.updateExternalContractId();
    // }

    // _updateBillCycle(billCycle) {
    //     this.updateBillCycle()(billCycle);
    // }

    // showDownPaymentsSchedulingAndBilling() {
    //     return (this.financedItems.length && this.displaySummaryTab && !this.isDevicePaymentsHidden) ||
    //         (this.state.isDbss && this.isOrderPostPaid && this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem()) ||
    //         (this.canShowFdoAndBed() && (this.showFDO() || this.isBillingEffectiveDateShown()));
    // }

    // showEditDownPayments() {
    //     return  (this.financedItems.length && !this.isDevicePaymentsHidden);
    // }

    // showEditSchedulingAndBilling() {
    //     return (this.isFirstOrder() && !this.isBillCycleEditDisabled) ||
    //         (this.canShowFdoAndBed() && (this.showFDO() || this.isBillingEffectiveDateShown()));
    // }

    // canShowFdoAndBed() {
    //     // return this.state.isDbss && this.isOrderPostPaid && this.state.fdoAllowedInBunt &&
    //     //     !this.isReconnectOffer && this.displaySummaryTab;
    // }

    // showFDO() {
    //     return (this.hasInvoiceItems && !this.transferFromSubscriberId && this.cartHasOfferWithBillerRuleCycleLevelOtherThanItem()) ||
    //         !this.transferFromSubscriberId;
    // }

    // showPaymentInfoSection() {
    //     return this.isOrderPostPaid && (!this.newPaymentInstrument && this.hasOffCycleChargeRenewal && this.state.selectedPaymentMethod &&
    //         !this.state.selectedPaymentMethod.Default) || !this.newPaymentInstrument;
    // }

    // getBillCycleOptions() {
    //     return (this.getCandidateBillCycles() || []).map((billCycle) => {
    //         return Object.assign(cloneDeep(billCycle), {
    //             text: billCycle.name,
    //             selected: this.selectedBillCycle && this.selectedBillCycle.id === billCycle.id,
    //             subtextList: [i18n.translate(CustomerCareKeys.BILL_CYCLE.NEXT_BILL_DATE, {
    //                 next_bill_date: this.$filter('localShortDate')(billCycle.billDate)
    //             }), i18n.translate(CustomerCareKeys.BILL_CYCLE.PAYMENT_DUE_DATE, {
    //                 payment_due_date: this.$filter('localShortDate')(billCycle.paymentDueDate)
    //             })]
    //         });
    //     });
    // }

    // onBillCycleSelectOptionSelected(selectedOption) {
    //     this.billCycleOptions.forEach((billCycleOption) => {
    //         billCycleOption.selected = false;
    //     });
    //     selectedOption.selected = true;
    //     this.editedBillCycle = selectedOption;
    // }

    // getSelectedFdoDisplayText() {
    //     const executionOptions = this.state.executionOptions || {};
    //     switch (+executionOptions.effective) {
    //         case SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY:
    //             return i18n.translate(this.customerCareKeys.SCHEDULING_OPTIONS.EFFECTIVE_IMMEDIATELY);
    //         case SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE:
    //             return i18n.translate(this.customerCareKeys.SCHEDULING_OPTIONS.ON_NEXT_BILL_CYCLE);
    //         case SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE:
    //             return executionOptions.specifiedDateFromPicker || executionOptions.specifiedDate ?
    //                 this.$filter('localShortDate')(executionOptions.specifiedDateFromPicker || executionOptions.specifiedDate) : '';
    //         default:
    //             return '';
    //     }
    // }

    // getSelectedBedDisplayText() {
    //     const executionOptions = this.state.executionOptions || {};
    //     switch (+executionOptions.billingEffectiveIntention) {
    //         case BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_ORDER_COMPLETION:
    //             return i18n.translate(this.customerCareKeys.SCHEDULING_OPTIONS.BILLING_EFFECTIVE_DATE.BILLING_EFFECTIVE_DATE_1);
    //         case BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_LAST_ORDER:
    //             return i18n.translate(this.customerCareKeys.SCHEDULING_OPTIONS.BILLING_EFFECTIVE_DATE.BILLING_EFFECTIVE_DATE_2);
    //         case BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_SPECIFIED_DATE:
    //             return executionOptions.billingEffectiveDate ? this.$filter('shortWithSeconds')(executionOptions.billingEffectiveDate) : '';
    //         case BILLING_EFFECTIVE_DATE_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE:
    //             return i18n.translate(this.customerCareKeys.SCHEDULING_OPTIONS.BILLING_EFFECTIVE_DATE.BILLING_EFFECTIVE_DATE_4);
    //         default:
    //             return '';
    //     }
    // }

    // onSubmitEditModal(executionOptionsModel) {
    //     this.showErrorSummary = true;
    //     this.selectedBillCycle = this.editedBillCycle;
    //     this.executionOptionsModel = executionOptionsModel;
    //     this.actions.setExecutionOptions(this.executionOptionsModel);
    //     if (this.isFinancedItemsModified) {
    //         this.actions.setDevicePaymentOptions(this.edit.financedItems);
    //         if (this.onItemDownpaymentChanged && this.itemDownPayment) {
    //             this.onItemDownpaymentChanged()(this.itemDownPayment);
    //         }
    //     }
    //     if (this.selectedBillCycle) {
    //         this._updateBillCycle(this.selectedBillCycle.name);
    //     }
    //     if (this.shippingAddress) {
    //         const shippingDetails = {
    //             methodId: this.deliveryDecisionWithCost.value,
    //             addressId: this.shippingAddress.Id,
    //             shipTo: this.formattedName
    //         };
    //         this.actions.updateShippingDetails(shippingDetails);
    //     }

    //     if (this.orderScenario === ORDER_SCENARIOS.ADD_OFFER || this.orderScenario === ORDER_SCENARIOS.NEW_CONNECT) {
    //         this.fetchQuote(!this.state.currentCustomerId, this.shippingDetails);
    //     } else {
    //         this.callRecalculateQuote([]);
    //     }
    //     this.closeEditCheckoutPageInformationPopup();
    // }

    // shouldShowBillingAddress() {
    //     return this.newPaymentInstrument &&
    //         (this.isPaymentInstrumentRequired || this.supplyPaymentInstrument ||
    //             (this.isOrderPostPaid && this.isAutoPayActive)) || this.hasDownPaymentItemsChanges();
    // }

    // shouldShowPaymentMethod() {
    //     return (!this.isPaymentInstrumentRequired && !this.isExternallyManagedArCustomer) &&
    //         !(this.isOrderPostPaid && this.state.isAutoPayEnabled && this.state.isDbss) &&
    //         this.hasNoPaymentInstruments();
    // }

    // shouldShowPaymentMethodAutoPay() {
    //     return this.state.isAutoPayEnabled && this.isOrderPostPaid && this.state.isDbss &&
    //         this.hasNoPaymentInstruments();
    // }

    // shouldShowPaymentMethodAutoPayAdd() {
    //     return this.state.isAutoPayEnabled && this.isOrderPostPaid && this.state.isDbss &&
    //         !this.hasNoPaymentInstruments();
    // }

    // shouldShowSaveForFutureUseField() {
    //     return (this.newPaymentInstrument && this.isPaymentInstrumentRequired)
    //         || this.supplyPaymentInstrument || this.isAutoPayActive || this.hasDownPaymentItemsChanges();
    // }

    // shouldShowAutopayPaymentTypeField() {
    //     return (this.newPaymentInstrument && this.isPaymentInstrumentRequired)
    //         || this.supplyPaymentInstrument || (this.isOrderPostPaid && this.isAutoPayActive) || this.hasDownPaymentItemsChanges();
    // }

    // shouldShowAutopayNoPaymentInstruments() {
    //     return this.isAutoPayActive && this.shouldShowPaymentMethod();
    // }

    // shouldShowPaymentInstrumentForm() {
    //     return (this.newPaymentInstrument && this.isPaymentInstrumentRequired)
    //         || this.supplyPaymentInstrument || (this.isOrderPostPaid && this.isAutoPayActive) || this.hasDownPaymentItemsChanges();
    // }

    // shouldShowPaymentMethodSection() {
    //     return this.newPaymentInstrument && (this.shouldShowPaymentMethodAutoPay() ||
    //         this.shouldShowSaveForFutureUseField() || this.shouldShowAutopayPaymentTypeField()
    //         || this.shouldShowAutopayNoPaymentInstruments() || this.shouldShowPaymentInstrumentForm() || this.hasDownPaymentItemsChanges());
    // }

    // hasNoPaymentInstruments() {
    //     return !this.state.selectedPaymentMethod;
    // }

    onOrderAdditionalPropertyListUpdated() {
        this.onAdditionalPropertyListUpdated()();
    }

    onOrderAdditionalPropertyChanged(id, newValue) {
        this.onAdditionalPropertyChanged()(id, newValue);
    }

    onOfferAdditionalPropertyListUpdated() {
        this.onAdditionalPropertyListUpdated()(true);
    }

    onOfferAdditionalPropertyChanged(id, newValue) {
        this.onAdditionalPropertyChanged()(id, newValue, true);
    }

    isBillingEffectiveDateShown() {
        return hasAccess(this.state.userSecurityAttributes, OVERRIDE_BILLING_EFFECTIVE_DATE);   // && !this.isNewConnect;
    }

    // handleCreditBreachValidations() {
    //     this.extraErrorMessages = [];
    //     if (this.shouldShowCreditAndFinanceLimitBreaches()) {
    //         this.extraErrorMessages.push(i18n.translate(this.customerCareKeys.FINANCE_AND_CREDIT_LIMIT_BREACHED_NOTICE));
    //     }
    //     if (this.shouldShowCreditLimitBreached()) {
    //         this.extraErrorMessages.push(this.breachedCreditLimit);
    //     }
    //     if (this.shouldShowFinanceLimitBreached()) {
    //         this.extraErrorMessages.push(this.breachedFinanceLimit);
    //     }
    // }
}

export default {
    template: require('./confirm.html'),
    controller: ConfirmController,
    bindings: {
        additionalPropertiesCollection: '<',
        additionalPropertyValueOptions: '<',
        // addressStates: '<',
        // availableCredit: '<?',
        // availableFinanceCredit: '<?',
        // billingEffectiveDate: '<?',
        // customer: '<',
        // displaySummaryTab: '<?',
        // deliveryMethodOptions: '<?',
        // fetchOrderQuote: '&?',
        // fetchQuoteNewConnect: '&?',
        // financedItems: '<?',
        // formattedCityState: '<',
        // formattedName: '<',
        // hasInvoiceItems: '<',
        // hasOffCycleChargeRenewal: '<?',
        // isCreatingOrUpdatingCustomer: '<',
        // isBillCycleEditDisabled: '<?',
        // isDevicePaymentsHidden: '<?',
        // isDevicePaymentsDisabled: '<?',
        // isCreditLimitBreached: '<?',
        // isExternallyManagedArCustomer: '<',
        isFetchingData: '<',
        // isModifyingPurchaseOrderNumberDisabled: '<?',
        // isNewConnect: '<?',
        // isOrderPostPaid: '<',
        // isPaymentInstrumentRequired: '<',
        // isReconnectOffer: '<?',
        onAdditionalPropertyListUpdated: '&',
        onAdditionalPropertyChanged: '&',
        offerAdditionalPropertiesCollection: '<?',
        // onItemDownpaymentChanged: '&?',
        // orderId: '<?',
        orderScenario: '<',
        // paymentInfo: '<',
        // recalculateQuote: '&?',
        // savePaymentInstrument: '<',
        // serviceAddress: '<',
        // shippingAddress: '<?',
        // showAlignRenewals: '<?',
        // transferFromSubscriberId: '<?',
        // updateBillCycle: '&',
        // updatePaymentInfo: '&',
        // onCheckoutStepSubmit: '&'
    },
    require: {
        wizardForm: '^form'
    }
};
