import i18n from 'invision-core/src/components/i18n/i18n';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {NewWindow} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    FUTURE_INVOICE_CALCULATION_COMPLETION_STATUS,
    FUTURE_INVOICE_COLUMNDEFS,
    FUTURE_INVOICE_DETAILS_URL,
    FUTURE_INVOICE_REFRESH_TIMEOUT,
    FUTURE_INVOICE_START_CALCULATION_ACTIONS
} from '../billing.payments.constants';
import {retrieveFutureInvoices} from '../../../reducers/actions/customer.billing.actions';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    FutureInvoicesCalculationSessionIdSelector,
    FutureInvoicesCalculationStatusSelector,
    FutureInvoicesDataSelector,
    IsFutureInvoicesCalcultaionStartedSelector
} from '../../../reducers/selectors/customer.billing.futureStatements.selectors';

class BillingFutureInvoicesController {
    constructor($ngRedux, $state, $timeout, uiNotificationService, $interval) {
        Object.assign(this, {
            $interval,
            $ngRedux,
            $state,
            $timeout,
            fetchFutureInvoices: this.fetchFutureInvoices.bind(this),
            FUTURE_INVOICE_CALCULATION_COMPLETION_STATUS,
            FUTURE_INVOICE_COLUMNDEFS,
            FUTURE_INVOICE_START_CALCULATION_ACTIONS,
            LocaleKeys,
            openInvoiceInNewWindow: this.openInvoiceInNewWindow.bind(this),
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                futureInvoicesCalculationSessionId: FutureInvoicesCalculationSessionIdSelector(store),
                futureInvoicesCalculationStatus: FutureInvoicesCalculationStatusSelector(store),
                futureInvoicesData: FutureInvoicesDataSelector(store),
                isFutureInvoicesCalcultaionStartedselector: IsFutureInvoicesCalcultaionStartedSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            retrieveFutureInvoices
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.fetchFutureInvoices();
    }

    fetchFutureInvoices(isGenerateNewInvoices) {
        const FUTURE_STATEMENTS_REQUEST_PAYLOAD = {};
        FUTURE_STATEMENTS_REQUEST_PAYLOAD.Action = !isGenerateNewInvoices ? this.FUTURE_INVOICE_START_CALCULATION_ACTIONS.CHECK_STATUS_OR_START :
            this.FUTURE_INVOICE_START_CALCULATION_ACTIONS.START_CALCULATION;
        this.futureInvoicesAreReady = false;
        if (this.state.futureInvoicesCalculationSessionId && !isGenerateNewInvoices) {
            FUTURE_STATEMENTS_REQUEST_PAYLOAD.CalculationSessionId = this.state.futureInvoicesCalculationSessionId;
        }
        if (this.intervalObj) {
            this.$interval.cancel(this.intervalObj);
        }
        this.actions.retrieveFutureInvoices(FUTURE_STATEMENTS_REQUEST_PAYLOAD, this.state.currentCustomerId)
            .then(() => {
                if (this.state.futureInvoicesCalculationStatus !== this.FUTURE_INVOICE_CALCULATION_COMPLETION_STATUS.READY) {
                    this.triggerRefreshTimer();
                } else {
                    this.futureInvoicesAreReady = true;
                }
            })
            .catch((error) => {
                this.triggerRefreshTimer();
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    triggerRefreshTimer() {
        this.remainingSecond = FUTURE_INVOICE_REFRESH_TIMEOUT;
        this.leftOverSecond = `${this.remainingSecond} ${i18n.translate(this.LocaleKeys.SECONDS)}`;
        this.intervalObj = this.$interval(() => {
            this.remainingSecond = this.remainingSecond - 1;
            this.leftOverSecond= `${this.remainingSecond} ${i18n.translate(this.LocaleKeys.SECONDS)}`;
            if (!this.remainingSecond) {
                this.fetchFutureInvoices();
            }
        }, 1000);
    }

    $onDestroy() {
        this.disconnectRedux();
        this.$interval.cancel(this.intervalObj);
    }


    openInvoiceInNewWindow(invoiceId) {
        NewWindow.open(FUTURE_INVOICE_DETAILS_URL, {
            invoiceId: invoiceId
        });
    }

}
export default {
    controller: BillingFutureInvoicesController,
    controllerAs: 'BillingFutureInvoicesController',
    template: require('./billing.future.invoices.template.html')
};