import angular from 'angular';
import 'angular-hotkeys';

import MemberListComponent from './member.list.component';
import MemberListItemComponent from './member.list.item.component';

export default angular.module('invision.customercare.customer.household.memberList', [
    'cfp.hotkeys'
])
    .component('memberListItem', MemberListItemComponent)
    .component('memberList', MemberListComponent)
    .name;
