import {
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../locales/keys';

export const CUSTOMERCARE_PAGE_APP_ID = 'care';

export default {
    CUSTOMERCARE_PAGE_APP_ID: CUSTOMERCARE_PAGE_APP_ID
};

export const SEARCH_NAV_ITEM_ID = 'search';
export const RECENT_CUSTOMERS_NAV_ITEM_ID = 'recentCustomers';

export const LoadNavigatorItems = () => {
    return [{
        id: SEARCH_NAV_ITEM_ID,
        isActive: false,
        glyph: 'search',
        caption: i18n.translate(CustomerCareLocaleKeys.NAVIGATOR_CAPTION.SEARCH)
    }, {
        id: RECENT_CUSTOMERS_NAV_ITEM_ID,
        isActive: false,
        glyph: 'past',
        caption: i18n.translate(CustomerCareLocaleKeys.RECENT_CUSTOMERS)
    }];
};

export const NavigatorFlyoutCaption = () => {
    return i18n.translate(CustomerCareLocaleKeys.NAVIGATOR_CAPTION.FLYOUT);
};

export const NavigatorItemIndex = {
    SEARCH: 0,
    RECENT: 1
};

export const customerCareRoute = 'index.customercare';
