import {map} from 'ramda';
import {
    setSearchType
} from '../../../reducers/actions/search.actions';
import {
    SEARCH_DROPDOWN_CONSTANTS
} from '../search.constants';
import {
    SearchTypeSelector,
    SearchTypeTabsSelector
} from '../../../reducers/selectors/search.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';

class SearchTypeTabsController {

    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;

        this.syncTabData = this.syncTabData.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.evaluateTabState = this.evaluateTabState.bind(this);
    }

    $onInit() {
        const controllerActions = {
            setSearchType
        };

        const mapStateToTarget = (store) => {
            return {
                searchType: SearchTypeSelector(store),
                tabData: SearchTypeTabsSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.searchType) {
            this.actions.setSearchType(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL);
        }
        this.syncTabData();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    tabSelected(selectedTab) {
        if (selectedTab.id !== this.state.searchType) {
            this.actions.setSearchType(selectedTab.id);
            this.syncTabData();
            this.searchTabChanged()(selectedTab);
        }
    }

    evaluateTabState(tab) {
        tab.active = this.state.searchType === tab.id;
    }

    syncTabData() {
        map(this.evaluateTabState, this.state.tabData);
    }
}

export default {
    template: require('./search.type.tabs.html'),
    controller: SearchTypeTabsController,
    bindings: {
        searchTabChanged: '&'
    }
};
