import LocaleKeys from '../../../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    INSTANCE_PROPERTY_TYPES,
    GIFT_CARD_INSTANCE_PROPERTY_NAMES
} from '../../../../../../../reducers/constants/products.wizard.constants';

import {REGEXES} from '../../../../../../../customercare.constants';

class EditInstancePropertiesComponentController {
    /**************************************************************
    * VIEW MODEL DEFINITION ('product' binding)
    * {
    *      -- The product object containing InstanceProperties
    * }
    **************************************************************/

    constructor() {
        Object.assign(this, {
            _instanceProperties: {},
            EMAIL_REGEX_PATTERN: REGEXES.EMAIL,
            GIFT_CARD_INSTANCE_PROPERTY_NAMES: GIFT_CARD_INSTANCE_PROPERTY_NAMES,
            INSTANCE_PROPERTY_TYPES: INSTANCE_PROPERTY_TYPES,
            LocaleKeys
        });
    }

    $onInit() {
        this.modelCtrl.$render = () => {
            this.onRender();
        };
    }

    onRender() {
        this._instanceProperties = this.modelCtrl.$viewValue || {};
    }

    onValueChanged() {
        //Set the view value for ngModel
        this.modelCtrl.$setViewValue(this._instanceProperties);
        this.onChanged() && this.onChanged()();
    }

    onTextValueChanged(instanceProperty) {
        // don't allow empty strings for text-input instance properties
        if (this._instanceProperties[instanceProperty.Id] === '') {
            delete this._instanceProperties[instanceProperty.Id];
        }

        this.onValueChanged();
    }

    getPlaceholder(instanceProperty, isSelectOption) {
        let result = '';

        if (isSelectOption && !this.hideLabel) {
            result = i18n.translate(this.LocaleKeys.SELECT);
        } else {
            if (this.hideLabel) {
                result = instanceProperty.Name;

                //Double checking hideLabel here as this could be a select input and don't want to modify existing display outside of hideLabel usage.
                if (!instanceProperty.Required && this.hideLabel) {
                    const optionalLabel = i18n.translate(this.LocaleKeys.OPTIONAL_LABEL);
                    result = `${result} (${optionalLabel})`;
                }

                if (this.hideLabel && isSelectOption) {
                    const selectLabel = i18n.translate(this.LocaleKeys.SELECT);
                    result = `${result}: ${selectLabel}`;
                }
            }
        }

        return result;
    }
}

export default {
    template: require('./edit.instance.properties.html'),
    controller: EditInstancePropertiesComponentController,
    require: {
        parentForm: '^^form',
        modelCtrl: 'ngModel'
    },
    bindings: {
        active: '<',
        hideLabel: '<?',
        onChanged: '&',
        product: '<',
        viewOnly: '<'
    }
};
