import {pathOr} from 'ramda';
import {StatusConstants, i18n} from 'invision-core';
import CustomerCareLocaleKeys from './../../../../locales/keys';
import {SUBSCRIPTION_TABS, SUBSCRIPTION_STATUSES} from '../../../shared/constants/subscriptions.constants';

class SubscriptionItemController {
    constructor($timeout, $filter) {
        this.onMoreOptionClickedForItem = this.onMoreOptionClickedForItem.bind(this);
        this.removeDiscount = this.removeDiscount.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.$timeout = $timeout;
        this.$filter = $filter;
    }

    $onInit() {
        this.localeKeys = CustomerCareLocaleKeys;
        this.DEFAULT_TABS = [
            {
                id: SUBSCRIPTION_TABS.INFO,
                active: true,
                glyph: 'info-circle'
            },
            {
                id: SUBSCRIPTION_TABS.DISCOUNT,
                active: false,
                glyph: 'percent'
            },
            {
                id: SUBSCRIPTION_TABS.PRICING_HISTORY,
                active: false,
                glyph: 'history'
            },
            {
                id: SUBSCRIPTION_TABS.ADDITIONAL_INFO,
                active: false,
                glyph: 'plus'
            }
        ];
        this.tabIds = SUBSCRIPTION_TABS;
        this.tabs = this.DEFAULT_TABS;

        this.redrawTrigger = false;
        this.selectedTabId = SUBSCRIPTION_TABS.INFO;
        this.updateTabs();
        if (this.item) {
            this.offerItemStatus = this.getOfferItemStatus(this.item.Status);
            this.effectiveDateTranslation = this.getEffectiveDateTranslation(this.item.EffectiveDate);
        }
    }

    $onChanges(changesObj) {
        if (changesObj.item) {
            this.updateTabs();
            if (this.localeKeys) { //translation can't be executed if this runs before onInit
                const subscriptionItem = changesObj.item.currentValue || {};
                this.offerItemStatus = this.getOfferItemStatus(subscriptionItem.Status);
                this.effectiveDateTranslation = this.getEffectiveDateTranslation(subscriptionItem.EffectiveDate);
            }
        }
    }

    get discounts() {
        return pathOr([], ['Discounts'], this.item);
    }

    hasDiscounts() {
        return this.discounts.length > 0;
    }

    onMoreOptionClickedForItem(moreOption) {
        this.onMoreOptionClicked()()(moreOption, this.item);
    }

    removeDiscount(discount) {
        this.onRemoveDiscount()()(discount);
    }

    get periods() {
        return pathOr([], ['PricingPeriods'], this.item);
    }

    hasPricingPeriods() {
        return this.periods.length > 0;
    }

    hasInstanceProperties() {
        return pathOr(false, ['InstanceProperties'], this.item);
    }

    updateTabs() {
        if (!this.tabs) {
            return;
        }

        this.tabs = this.DEFAULT_TABS.filter((tab) => {
            if (tab.id === SUBSCRIPTION_TABS.DISCOUNT) {
                return this.hasDiscounts();
            } else if (tab.id === SUBSCRIPTION_TABS.PRICING_HISTORY) {
                return this.hasPricingPeriods();
            } else if (tab.id === SUBSCRIPTION_TABS.ADDITIONAL_INFO) {
                return this.hasInstanceProperties();
            } else {
                return true;
            }
        }).map((tab) => {
            if (tab.id === SUBSCRIPTION_TABS.DISCOUNT) {
                tab.notificationCount = this.discounts.length;
            }

            if (tab.id === SUBSCRIPTION_TABS.PRICING_HISTORY) {
                tab.notificationCount = this.periods.length;
            }

            tab.active = this.tabs.some((currentTab) => {
                return currentTab && currentTab.active && currentTab.id === tab.id;
            });

            return tab;
        });

        this.setDefaultTab();
    }

    setDefaultTab() {
        const hasActiveTab = this.tabs.some((tab) => {
            return tab.active;
        });

        if (!hasActiveTab) {
            this.tabSelected(this.DEFAULT_TABS[0]);
        }
    }

    tabSelected(item) {
        this.selectedTabId = item.id;
        this.tabs = this.tabs.map((tab) => {
            tab.active = tab.id === this.selectedTabId;
            return tab;
        });
        this.$timeout(() => {
            this.redrawTrigger = !this.redrawTrigger;
        });

    }

    getOfferItemStatus(subscriptionStatus) {
        let offerItemStatus = undefined;
        if (subscriptionStatus === SUBSCRIPTION_STATUSES.PENDING_ACTIVE) {
            offerItemStatus = StatusConstants.OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE;
        } else if (subscriptionStatus === SUBSCRIPTION_STATUSES.PENDING_REMOVED) {
            offerItemStatus = StatusConstants.OFFER_STATUS_INDICATOR_STATUS.PENDING_REMOVED;
        }

        return offerItemStatus;
    }

    getEffectiveDateTranslation(date) {
        if (!date) {
            return null;
        }

        return i18n.translate(this.localeKeys.SUBSCRIPTIONS.ITEM.EFFECTIVE_ON, {
            effectiveDate: this.$filter('localShortDate')(date)
        });
    }
}

export default {
    controller: SubscriptionItemController,
    template: require('./subscription.item.html'),
    bindings: {
        item: '<',
        moreOptions: '<',
        userCanRemove: '<',

        onMoreOptionClicked: '&',
        onRemoveDiscount: '&',
        subscription: '<',
    }
};
