import localeKeys from '../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {BulkChargeTypes} from '../../../../wizards/newConnectWizard/new.connect.wizard.constants';
class DisconnectItemController {
    constructor() {
        Object.assign(this, {
            localeKeys
        });
    }

    getBulkTooltip(bulkChargeTypeCode) {
        return i18n.translate(convertStringToNumber(bulkChargeTypeCode) === BulkChargeTypes.FLAT ?
            this.localeKeys.DECISIONS.FLAT_PRICING :
            this.localeKeys.DECISIONS.UNIT_PRICING
        );
    }

    getBulkIcon(bulkChargeTypeCode) {
        return convertStringToNumber(bulkChargeTypeCode) === BulkChargeTypes.FLAT ?
            'filled-circle-f' :
            'filled-circle-u';
    }
}

export default {
    template: require('./disconnect.item.html'),
    bindings: {
        amount: '<',
        bulkChargeTypeCodes: '<',
        connectDate: '<',
        currencyCode: '<',
        itemName: '<',
        quantity: '<',
        showIcon: '<?'
    },
    controller: DisconnectItemController,
    controllerAs: 'DisconnectItemController'
};
