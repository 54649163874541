import CustomerCareLocaleKeys from '../../../../locales/keys';
import Immutable from 'seamless-immutable';

class RenewalStatementsController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
        this.handleCloseDialog = () => {
            const close = this.onClose();
            close && close();
        };
    }

    $onInit() {
        if (this.subscriptionQuotes) {
            this.subscriptionQuotes = Immutable.asMutable(this.subscriptionQuotes, {
                deep: true
            });
        }
    }
}

export default {
    template: require('./renewal.statements.dialog.html'),
    controller: RenewalStatementsController,
    bindings: {
        onClose: '&',
        subscriptionQuotes: '<'
    }
};
