import angular from 'angular';

import 'invision-core';

import CreatePromiseToPayPopupComponent from './create.promise.to.pay.popup.component';

export default angular.module('inv.customer.treatmentDetails.createPromiseToPayPopup', [
    'invision.core.components'
])
    .component('createPromiseToPayPopup', CreatePromiseToPayPopupComponent)
    .name;
