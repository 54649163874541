import Immutable from 'seamless-immutable';
import {FormHelper,
    i18n} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {CurrentHouseholdSelector,
    CurrentHouseholdMemberSelector,
    EditHouseholdDataSelector,
    HouseholdErrorSelector,
    IsCreatingHouseholdSelector,
    IsUpdatingHouseholdSelector,
    SelectedHouseholdMemberIdSelector,
    SelectedHouseholdMemberSelector} from '../../../../reducers/selectors/customer.household.selectors';
import {createHousehold,
    setEditHouseholdData,
    updateHousehold} from '../../../../reducers/actions/customer.household.actions';
import {CurrentCustomerSelector} from '../../../../reducers/selectors/customer.selectors';


function EditHouseholdPopupController($ngRedux, $timeout) {
    let disconnectRedux;
    let popupApi = null;

    const createSuccessMessage = i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.CREATE_HOUSEHOLD_SUCCESS);
    const updateSuccessMessage = i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.UPDATE_HOUSEHOLD_SUCCESS);

    const mapStateToTarget = (store) => {
        return {
            currentCustomer: CurrentCustomerSelector(store),
            currentHousehold: CurrentHouseholdSelector(store),
            currentMember: CurrentHouseholdMemberSelector(store),
            editHouseholdData: EditHouseholdDataSelector(store),
            isCreatingHousehold: IsCreatingHouseholdSelector(store),
            isUpdatingHousehold: IsUpdatingHouseholdSelector(store),
            lastAttemptError: HouseholdErrorSelector(store),
            selectedMember: SelectedHouseholdMemberSelector(store),
            selectedMemberId: SelectedHouseholdMemberIdSelector(store)
        };
    };
    const controllerActions = {
        createHousehold,
        setEditHouseholdData,
        updateHousehold
    };

    this.$onInit = () => {
        this.showForm = false;

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.resetForm();
        this.customerCareKeys = CustomerCareLocaleKeys;
        this.errorLookup = this.getErrorLookupObject();

        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.resetForm();

                    if (this.household.Id) {
                        this.popupTitle = i18n.translate(this.customerCareKeys.HOUSEHOLD.EDIT_HOUSEHOLD);
                    } else {
                        this.popupTitle = i18n.translate(this.customerCareKeys.HOUSEHOLD.NEW_HOUSEHOLD);
                        this.household.Name = this.getDefaultHouseholdName();
                    }

                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };
    };

    this.getErrorLookupObject = () => {
        return {
            householdName: {
                required: i18n.translate(this.customerCareKeys.HOUSEHOLD.ERRORS.HOUSEHOLD_NAME_REQUIRED),
                maxlength: i18n.translate(this.customerCareKeys.HOUSEHOLD.ERRORS.HOUSEHOLD_NAME_100_CHAR)
            }
        };
    };

    this.checkForErrors = ngFormError => {
        const errorModelList = FormHelper.formHasErrors(ngFormError, this.errorLookup);

        this.formErrors = errorModelList;
        return errorModelList.length > 0;
    };

    this.handleSubmitForm = (editHouseholdForm) => {
        if (!this.checkForErrors(editHouseholdForm.$error)) {
            if (this.isUpdating()) {
                this.actions.updateHousehold(this.customerId, this.household)
                    .then(this.successCallback(updateSuccessMessage), this.errorCallback());
            } else {
                this.actions.createHousehold(this.customerId, this.household)
                    .then(this.successCallback(createSuccessMessage), this.errorCallback());
            }
        }
    };

    this.successCallback = (message) => {
        return () => {
            this.closePopup();
            const onHouseholdEdited = this.onHouseholdEdited();
            onHouseholdEdited && onHouseholdEdited(message, this.state.selectedMemberId || this.state.currentCustomer.Id);
        };
    };

    this.errorCallback = () => {
        return () => {
            this.formErrors = [this.state.lastAttemptError.message];
        };
    };

    this.closePopup = () => {
        if (!this.state.isCreatingHousehold) {
            popupApi.close();
            this.showForm = false;
        }
    };

    this.resetForm = () => {
        this.setEditData(this.state.currentHousehold || {});
        this.household = Immutable.asMutable(this.state.editHouseholdData, {
            deep: true
        });
        this.formErrors = [];
    };

    this.getDefaultHouseholdName = () => {
        const substitutions = {
            lastName: this.state.currentCustomer.LastName
        };
        return i18n.translate(this.customerCareKeys.HOUSEHOLD.DEFAULT_HOUSEHOLD_NAME, substitutions);
    };

    this.handleFormChange = () => {
        this.actions.setEditHouseholdData(this.household);
    };

    this.isUpdating = () => {
        return this.household.Id;
    };

    this.setEditData = (household = {}) => {
        this.actions.setEditHouseholdData(household);
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };
}

export default {
    template: require('./edit.household.popup.html'),
    bindings: {
        customerId: '<',
        config: '<',
        onHouseholdEdited: '&'
    },
    controllerAs: 'EditHouseholdPopup',
    controller: EditHouseholdPopupController
};
