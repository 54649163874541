import CustomerCareLocaleKeys from '../../../../../locales/keys';

class RokuDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
    }
}

export const RokuDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: RokuDetailController,
    template: require('./roku.detail.html')
};
