import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';
import {
    ADJUSTMENT_CATEGORIES,
    DEBIT_NOTE,
    INVOICE,
    MAX_DESCR_LENGTH,
    MIN_NOTE_AMOUNT,
    NOTE_TYPES
} from './createNote.modal.constants';
import {constraintErrorMessages} from 'invision-core/src/utilities/angular.form.utilities';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {CurrentBusinessUnitCurrencyCodeSelector} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    createConvergentBillerAdjustment,
    retrieveInvoicesForDropdown
} from '../../../reducers/actions/customer.billing.actions';
import * as BillingPaymentsConstants from '../../../components/billingPayments/billing.payments.constants';
import {
    AdjustmentConfigSelector,
    AdjustmentReasonCodeValueOptions,
    HierarchyInvoiceNodeSubscriberIdSelector,
    InvoiceListForDropDownSelector,
    InvoiceSummariesSelector,
    IsFetchingInvoiceListSelector,
} from '../../../reducers/selectors/customer.billing.selectors';
import {IsCreatingConvergentBillerAdjustmentSelector} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {CurrentCustomerSelector} from '../../../reducers/selectors/customer.selectors';
import LocaleKeys from '../../../locales/keys';
import moment from 'moment';
import {REPORTING_LEVELS} from '../../../components/customer/accountHierarchy/account.hierarchy.constants';

class CreateNoteModalController {
    constructor($ngRedux, $filter, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            formattedInvoiceList: [],
            formattedInvoiceAndDebitNoteList : [],
            invoicesOptions: [],
            isStatement : this.isStatement.bind(this),
            MAX_DESCR_LENGTH,
            MIN_NOTE_AMOUNT,
            NOTE_TYPES,
            selectedInvoiceOrDebitNote: null,
            selectedReasonCode: null,
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentConfig: AdjustmentConfigSelector(store),
                adjustmentReasonCodes: AdjustmentReasonCodeValueOptions(store),
                adjustmentReasonCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdjustmentReason, store),
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                hierarchyInvoiceNodeSubscriberId: HierarchyInvoiceNodeSubscriberIdSelector(store),
                invoiceAndDebitNoteList: InvoiceListForDropDownSelector(store),
                invoiceSummaries: InvoiceSummariesSelector(store),
                isCreatingConvergentBillerAdjustment: IsCreatingConvergentBillerAdjustmentSelector(store),
                isFetchingInvoiceAndDebitNoteList: IsFetchingInvoiceListSelector(store)
            };
        };
        const controllerActions = {
            createConvergentBillerAdjustment,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveInvoicesForDropdown
        };

        this.localeKeys = LocaleKeys;
        this.noteType = NOTE_TYPES.CREDIT;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.adjustmentReasonCodesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentReason);
        }

        if (this.state.invoiceSummaries.length) {
            this.currencyCode = this.state.invoiceSummaries[0].CurrencyCode;
        } else {
            this.currencyCode = this.state.currentBusinessUnitCurrencyCode;
        }

        this.enableGenerateStatements = this.state.adjustmentConfig[BillingPaymentsConstants.ENABLE_GENERATE_STATEMENT] === BillingPaymentsConstants.ADJUSTMENT_CONFIG_ENABLED ? true : false;
        this.getInvoiceAndDebitNotesList();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getInvoiceAndDebitNotesList() {
        const getInvoiceAndDebitNotesListRequest = {
            customerId: this.state.currentCustomer.Id,
            request: {
                Filter: BillingPaymentsConstants.INVOICES | BillingPaymentsConstants.DEBIT_NOTES
            }
        };
        this.actions.retrieveInvoicesForDropdown(getInvoiceAndDebitNotesListRequest)
            .then(() => {
                this.formatInvoiceAndDebitNotesList();
                this.setInvoicesOptions();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    get adjustmentReasonCodes() {
        const adjustmentReasonCodes = pathOr([], ['adjustmentReasonCodes'], this.state);
        return adjustmentReasonCodes.filter((reasonCode) => {
            return (
                (
                    reasonCode.adjustment_category === ADJUSTMENT_CATEGORIES.OTHER ||
                    isEmpty(reasonCode.adjustment_category)
                ) && (
                    this.noteType === reasonCode.adjustment_payment_type ||
                    isEmpty(reasonCode.adjustment_payment_type)
                )
            );
        });
    }

    closeModal() {
        this.onClose();
    }

    formatInvoiceAndDebitNotesList() {

        function isFutureDatedInvoice(invoiceDueDate) {
            return invoiceDueDate && (new Date(invoiceDueDate)).getTime() > Date.now();
        }

        this.state.invoiceAndDebitNoteList.asMutable({
            deep: true
        })
            .forEach((item) => {
                if (item.Invoice && item.Invoice.CurrentDue > 0 && !item.Invoice.IsStatement && isFutureDatedInvoice(item.Invoice.DueDate)) {
                    this.formattedInvoiceList.push({
                        amount: item.Invoice.CurrentDue,
                        id: item.Invoice.InvoiceId,
                        text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_PREFIX)}${item.Invoice.InvoiceNumber} - ${this.$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)} - ${this.$filter('localShortDate')(item.Invoice.DueDate)}`,
                        type: INVOICE
                    });
                }
                if (item.Invoice && item.Invoice.CurrentDue > 0 && !item.Invoice.IsStatement) {
                    this.formattedInvoiceAndDebitNoteList.push({
                        amount: item.Invoice.CurrentDue,
                        id: item.Invoice.InvoiceId,
                        text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_PREFIX)}${item.Invoice.InvoiceNumber} - ${this.$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)}`,
                        type: INVOICE
                    });
                }
                if (item.DebitNote && item.DebitNote.RemainingBalance > 0) {
                    this.formattedInvoiceAndDebitNoteList.push({
                        amount: item.DebitNote.RemainingBalance,
                        id: item.DebitNote.Id,
                        text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.DEBIT_NOTE_PREFIX)}${item.DebitNote.ReferenceId} - ${this.$filter('invCurrency')(item.DebitNote.RemainingBalance, item.DebitNote.CurrencyCode)}`,
                        type: DEBIT_NOTE
                    });
                }
            });
    }

    validateForm() {
        this.maxNoteAmount = (this.noteType === NOTE_TYPES.DEBIT) ? null : (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.amount) || null ;
        this.formErrorMessages = constraintErrorMessages(undefined, this.createNoteForm, {
            noteAmount: LocaleKeys.CREDIT_DEBIT_NOTE.NOTE_AMOUNT,
            noteDescription: LocaleKeys.ADJUSTMENTS.DESCRIPTIONS,
            reasonCode: LocaleKeys.ADJUSTMENTS.REASON_CODE
        }, {
            maxlength: {
                noteDescription: this.MAX_DESCR_LENGTH
            },
            max: {
                noteAmount: this.$filter('invCurrency')(this.maxNoteAmount, this.currencyCode)
            },
            min: {
                noteAmount: this.$filter('invCurrency')(this.MIN_NOTE_AMOUNT, this.currencyCode)
            }
        });
    }

    setInvoicesOptions() {
        this.invoicesOptions = this.noteType === NOTE_TYPES.DEBIT ? this.formattedInvoiceList : this.formattedInvoiceAndDebitNoteList;
    }

    createNote() {
        if (this.createNoteForm.$valid) {
            const request = {
                customerId: this.state.currentCustomer.Id,
                request: {
                    AccountNumber: this.accountNumber,
                    AdjustmentTypeCode: this.noteType,
                    Amount: parseFloat(this.noteAmount).toFixed(2),
                    Description: this.noteDescription,
                    GenerateStatement: this.enableGenerateStatements,
                    DebitNoteId: (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.type === DEBIT_NOTE) ? this.selectedInvoiceOrDebitNote.id : null,
                    InvoiceId: (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.type === INVOICE) ? this.selectedInvoiceOrDebitNote.id : null,
                    PostDate: new Date(),
                    ReasonCode: this.selectedReasonCode
                }
            };
            this.actions.createConvergentBillerAdjustment(request).then(() => {
                const successMessage = this.noteType === NOTE_TYPES.CREDIT ? i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.CREDIT_NOTE_APPLIED) : i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.DEBIT_NOTE_APPLIED);
                this.uiNotificationService.transientSuccess(successMessage);
                this.onSuccess();
            }).catch((error) => {
                if (this.formErrorMessages) {
                    this.formErrorMessages.length = 0;
                }
                this.formErrorMessages.push(error.translatedMessage);
            });
        } else {
            this.validateForm();
        }
    }

    onNoteTypeChange() {
        if (this.noteType === NOTE_TYPES.DEBIT) {
            this.selectedInvoiceOrDebitNote = null;
        }
        this.setInvoicesOptions();
        this.validateForm();
    }

    isStatement() {
        return this.state.currentCustomer?.HierarchyReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT;
    }
}

export default {
    controller: CreateNoteModalController,
    controllerAs: 'CreateNoteModalController',
    template: require('./createNote.modal.html'),
    bindings: {
        accountNumber: '<?',
        config: '<',
        onClose: '&',
        onSuccess: '&'
    }
};
