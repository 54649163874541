import {
    CustomerCareCouponsWindowTitleSelector,
    CustomerCareGrantCouponsWindowTitleSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    emptyCouponsList,
    removeCouponsFilter
} from '../../../reducers/actions/customer.coupons.actions';

export const GRANTED_COUPON_ROUTE = 'index.customercare.customer.coupons.grantedCoupons';
export const COUPON_DETAILS_ROUTE = 'index.customercare.customer.coupons.details';

export function routing($stateProvider) {
    $stateProvider
        .state('index.customercare.customer.coupons', {
            url: '/coupons',
            redirectTo: GRANTED_COUPON_ROUTE,
            template: '<ui-view class="u-flexKeepHeight"></ui-view>',
            onExit: ($ngRedux) => {
                $ngRedux.dispatch(emptyCouponsList());
                $ngRedux.dispatch(removeCouponsFilter());
            }
        })
        .state(GRANTED_COUPON_ROUTE, {
            url: '/grantedCoupons',
            template: '<customer-coupons></customer-coupons>',
            data: {
                windowTitle: CustomerCareCouponsWindowTitleSelector
            }
        })
        .state('index.customercare.customer.coupons.grantCoupons', {
            url: '/grantCoupons',
            template: '<grant-coupons></grant-coupons>',
            data: {
                windowTitle: CustomerCareGrantCouponsWindowTitleSelector
            }
        })
        .state(COUPON_DETAILS_ROUTE, {
            url: '/details/:couponRedemptionCodeId',
            template: '<div class="c-content-container"><redemption-code-details class="u-flexKeepHeight"></redemption-code-details></div>'
        });
}
