import {i18n} from 'invision-core';
import * as DeviceConstants from '../devices.constants';
import {stateGo} from 'redux-ui-router';
import {DEVICE_DETAIL_STATE_OR_NAME as CONTENT_ACTIVITY_STATE} from '../../contentActivity/content.activity.config';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    RouteParams,
    RouteState
} from '../../../../reducers/selectors/customer.selectors';

class CustomerDevicesDetailController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.handleMoreOptionsSelected = this.handleMoreOptionsSelected.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                routeParams: RouteParams(store),
                routeState: RouteState(store)
            };
        };

        const controllerActions = {
            stateGo
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.localeKeys = CustomerCareLocaleKeys;

        this.moreOptionsMenuItems = [
            {
                id: DeviceConstants.REMOVE_DEVICE_OPTION_ID,
                title: i18n.translate(this.localeKeys.REMOVE)
            },
            {
                id: DeviceConstants.VIEW_CONTENT_ACTIVITY,
                title: i18n.translate(this.localeKeys.VIEW_CONTENT_ACTIVITY)
            }
        ];
    }

    handleMoreOptionsSelected({item}) {
        switch (item.id) {
            case DeviceConstants.REMOVE_DEVICE_OPTION_ID:
                this.onRemoveDevice()();
                break;
            case DeviceConstants.VIEW_CONTENT_ACTIVITY:
                this.navigateToContentActivity();
                break;
            default:
                break;
        }
    }

    navigateToContentActivity() {
        this.actions.stateGo(CONTENT_ACTIVITY_STATE, {
            deviceId: this.device.Id,
            previousRoute: {
                name: this.state.routeState.name,
                params: this.state.routeParams
            },
            deviceName: this.device.Name
        });
    }

    openUpdateDeviceModal() {
        this.onUpdateDevice()();
    }

    get isDeviceActive() {
        return this.device.Active;
    }

    get canEditDevice() {
        return this.isDeviceActive && this.userCanEdit;
    }
}

export default {
    bindings: {
        currentCustomerId: '<',
        device: '<',
        userCanEdit: '<',

        onRemoveDevice: '&',
        onUpdateDevice: '&'
    },
    template: require('./devices.detail.html'),
    controller: CustomerDevicesDetailController
};
