import i18n from 'invision-core/src/components/i18n/i18n';
import customerCareKeys from '../../../../locales/keys';

const REMOVED_MEMBER = 2;
class MemberListItemController {
    constructor() {
        this.customerCareKeys = customerCareKeys;
    }
    selectItem() {
        this.onItemSelected()(this.item);
    }
    get isRemovedMember() {
        return this.item.Status === REMOVED_MEMBER;
    }
    get isHeadOfHousehold() {
        return this.item.SubscriberId === this.headOfHouseholdId;
    }

    get memberPrivilegesInfo() {
        return this.item.Privileges.MemberManagementEnabled ?
            `${i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.FULL_ACCESS_PRIVILEGES)})` :
            (this.item.Privileges.ManageServicesEnabled ?
                `${i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.SERVICE_LEVEL_PRIVILEGES)})` :
                i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER));
    }
}

export default {
    template: require('./member.list.item.html'),
    bindings: {
        headOfHouseholdId: '<',
        item: '<',
        isSelectedItem: '<',
        onItemSelected: '&'
    },
    controller: MemberListItemController
};
