import i18n from 'invision-core/src/components/i18n/i18n';
import localeKeys from '../../../../locales/keys';

class InventoryStoreHeaderController {
    constructor() {
        Object.assign(this, {
            localeKeys
        });
    }

    getStoreResultMessage() {
        return i18n.translate(
            this.storesCount > 1 ?
                this.localeKeys.INVENTORY.MODAL.STORE_RESULT_PLURAL
                : this.localeKeys.INVENTORY.MODAL.STORE_RESULT,
            {
                count: this.storesCount
            });
    }
}

export default {
    bindings: {
        onRegionFilterChange: '&',
        regions: '<',
        selectedRegion: '<',
        storesCount: '<'
    },
    template: require('./inventory.store.header.template.html'),
    controller: InventoryStoreHeaderController
};