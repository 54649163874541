import {i18n} from 'invision-core';
import {pathOr} from 'ramda';
import CustomerCareLocaleKeys from '../../locales/keys';

const TAX_EXEMPTION_IDS = {
    COUNTY: 1,
    FEDERAL: 2,
    LIFELINE: 3,
    LOCAL: 4,
    STATE: 5,
    UNINCORPORATED: 6
};

const TAX_EXEMPTION_API_PROPERTIES = {
    COUNTY: 'CountyExempt',
    FEDERAL: 'FederalExempt',
    LIFELINE: 'Lifeline',
    LOCAL: 'LocalExempt',
    STATE: 'StateExempt',
    UNINCORPORATED: 'Unincorporated',
    WHOLESALE: 'WholesaleFlag'
};

export const createTaxExemptionFromOptions = (optionsSelected, customerModel) => {
    const modelObject = {
        Id: pathOr(undefined, ['TaxExemption', 'Id'], customerModel)
    };

    const options = optionsSelected || [];

    modelObject[TAX_EXEMPTION_API_PROPERTIES.COUNTY] = options.includes(TAX_EXEMPTION_API_PROPERTIES.COUNTY);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.FEDERAL] = options.includes(TAX_EXEMPTION_API_PROPERTIES.FEDERAL);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.LIFELINE] = options.includes(TAX_EXEMPTION_API_PROPERTIES.LIFELINE);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.LOCAL] = options.includes(TAX_EXEMPTION_API_PROPERTIES.LOCAL);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.STATE] = options.includes(TAX_EXEMPTION_API_PROPERTIES.STATE);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.UNINCORPORATED] = options.includes(TAX_EXEMPTION_API_PROPERTIES.UNINCORPORATED);
    modelObject[TAX_EXEMPTION_API_PROPERTIES.WHOLESALE] = pathOr(false, ['TaxExemption', TAX_EXEMPTION_API_PROPERTIES.WHOLESALE], customerModel);

    return modelObject;
};

export const taxLevelExemptions = () => {
    return [
        {
            id: TAX_EXEMPTION_IDS.COUNTY,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.COUNTY),
            value: TAX_EXEMPTION_API_PROPERTIES.COUNTY
        }, {
            id: TAX_EXEMPTION_IDS.FEDERAL,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.FEDERAL),
            value: TAX_EXEMPTION_API_PROPERTIES.FEDERAL
        }, {
            id: TAX_EXEMPTION_IDS.LIFELINE,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.LIFELINE),
            value: TAX_EXEMPTION_API_PROPERTIES.LIFELINE
        }, {
            id: TAX_EXEMPTION_IDS.LOCAL,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.LOCAL),
            value: TAX_EXEMPTION_API_PROPERTIES.LOCAL
        }, {
            id: TAX_EXEMPTION_IDS.STATE,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.STATE),
            value: TAX_EXEMPTION_API_PROPERTIES.STATE
        }, {
            id: TAX_EXEMPTION_IDS.UNINCORPORATED,
            text: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.LEVELS.UNINCORPORATED),
            value: TAX_EXEMPTION_API_PROPERTIES.UNINCORPORATED
        }
    ];
};

const ChargeTypesForDetailedTaxation = {
    Recurring: 1,
    OneTime: 2
};

export const getChargeTypesForDetailedTaxation = () => {
    return [
        {
            id: ChargeTypesForDetailedTaxation.OneTime,
            text: i18n.translate(CustomerCareLocaleKeys.BILLER_RULE_INSTANCE_TYPES.ONETIME),
            value: ChargeTypesForDetailedTaxation.OneTime
        },
        {
            id: ChargeTypesForDetailedTaxation.Recurring,
            text: i18n.translate(CustomerCareLocaleKeys.BILLER_RULE_INSTANCE_TYPES.RECURRING),
            value: ChargeTypesForDetailedTaxation.Recurring
        }
    ];
};
