import {i18n} from 'invision-core';
import CustomerLocaleKeys from '../../../locales/keys';

export const contentTypes = {
    CATCHUP: 2,
    LIVE: 0,
    PRODUCT: 'product',
    REPLAY: 3,
    START_OVER: 1
};

export function getContentType(contentActivity) {
    let contentType = contentActivity.ChannelViewType !== undefined ? contentActivity.ChannelViewType : null;

    if (!contentType && contentActivity.Product) {
        contentType = contentTypes.PRODUCT;
    }

    let contentIdentifier = {
        isCatchup: false,
        isLive: false,
        isProduct: false,
        isReplay: false,
        isStartOver: false
    };

    switch (contentType) {
        case contentTypes.LIVE:
            contentIdentifier = {
                isLive: true,
                name: i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.LIVE),
                value: 0
            };
            break;
        case contentTypes.START_OVER:
            contentIdentifier = {
                isStartOver: true,
                name: i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.START_OVER),
                value: 1
            };
            break;
        case contentTypes.CATCHUP:
            contentIdentifier = {
                isCatchup: true,
                name: i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.CATCHUP),
                value: 2
            };
            break;
        case contentTypes.REPLAY:
            contentIdentifier = {
                isReplay: true,
                name: i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.REPLAY),
                value: contentTypes.REPLAY
            };
            break;
        case contentTypes.PRODUCT:
            contentIdentifier = {
                isProduct: true,
                name: i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.PRODUCT.PRODUCT),
                value: 'product'
            };
            break;
        default:
            break;
    }

    return contentIdentifier;
}

export function getFriendlyDisplayName(contentActivity) {
    const contentIdentifier = getContentType(contentActivity);

    if (contentActivity.Product) {
        return contentActivity.Product.Name;
    }

    if (contentIdentifier && (contentIdentifier.isLive || contentIdentifier.isReplay) && contentActivity.Channel) {
        return contentActivity.Channel.Name;
    }


    if (contentActivity.Channel && !contentIdentifier.name) {
        return contentActivity.Channel.Name;
    }

    if (contentIdentifier && (contentIdentifier.isCatchup || contentIdentifier.isStartOver)) {
        if (contentActivity.Program) {
            return i18n.translate(CustomerLocaleKeys.CONTENT_ACTIVITY.FRIENDLY_PROGRAM_NAME, {
                episodeNumber: contentActivity.Program.EpisodeNumber,
                episodeTitle: contentActivity.Program.EpisodeTitle,
                seasonNumber: contentActivity.Program.SeasonNumber,

            });
        }
    }

    return null;
}
