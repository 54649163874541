import __ from 'ramda/src/__';
import {stateGo} from 'redux-ui-router';
import {
    RouteParams,
    RouteCurrentStateSelector
} from 'invision-core/src/components/router/router.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CustomerLocaleKeys from '../../../../locales/keys';
import {ServicesAndUsagesTabsSelector} from '../../../../reducers/selectors/customer.services.and.usage.selectors';
import {
    ActiveServicesSelector,
    BulkServicesPageSizeSelector,
    BulkServicesSearchStringSelector,
    HasOffCycleChargesSelector,
    IncludeStatusFilterSelector,
    IncludeTypeOfServiceFilterSelector,
    ResumeServiceReasonCodesSelector,
    SERVICE_ACTION_OPTIONS,
    ServicesSuspendResumeMenuOptionsSelector,
    SuspendServiceReasonCodesSelector
} from '../../../../reducers/selectors/services.selectors';
import {USAGE_CAPS_URL} from '../../servicesAndUsage/usageCaps/usage.caps.constants';
import {
    NON_BULK_SERVICE_CATEGORIZATION
} from './services/services.list.constants';
import {SERVICES_AND_SHARED_ENTITLEMENTS_TAB} from '../services.and.usage.config';
import {ORDER_HISTORY_DETAILS_STATE_NAME} from '../../orderHistory/order.history.config';
import {
    removeBulkServicesStatusFilter,
    searchServices,
    setSelectAccountLevel,
    suspendOrResumeServices,
} from '../../../../reducers/actions/services.actions';
import {resetSharedEntitlementTabData} from '../../../../reducers/actions/shared.entitlements.actions';
import {
    CurrentCustomerIdSelector,
    SelectedCustomerActiveProductsSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    retrieveConvergentBillerAccountDetails,
    retrieveConvergentBillerSubscriberSummary
} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    CurrentAccountServicesSelector,
    CombinedEntitlementTypesSelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {TRANSFER_STATE_NAME} from '../../orders/transfer/transfer.wizard.config';
import {retrieveSubscriberFinanceDetails} from '../../../../reducers/actions/customer.devices.actions';
import {FeatureToggleConstants} from 'invision-core';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import {clearSearchEntitlementFiltersRecoverableUIState} from '../../../../reducers/actions/usage.details.actions';
import {RESUME_SERVICES_REDIRECT, SUSPEND_SERVICES_REDIRECT} from '../../../../reactRoutes';

class ServicesAndUsageController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            handleServiceMenuOptions: this.handleServiceMenuOptions.bind(this),
            onTabSelected: this.onTabSelected.bind(this),
            openSuspendOrResumeServiceModal: this.openSuspendOrResumeServiceModal.bind(this),
            SERVICES_AND_SHARED_ENTITLEMENTS_TAB,
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.FeatureToggleConfig,
            CODES.ReasonCodes,
            CODES.ServiceAttribute,
            CODES.UsageCapMenuTitle
        ];
        const controllerActions = {
            clearSearchEntitlementFiltersRecoverableUIState,
            fetchCodeTypes,
            removeBulkServicesStatusFilter,
            resetSharedEntitlementTabData,
            retrieveConvergentBillerAccountDetails,
            retrieveConvergentBillerSubscriberSummary,
            retrieveSubscriberFinanceDetails,
            searchServices,
            setSelectAccountLevel,
            stateGo,
            suspendOrResumeServices
        };

        const mapStateToTarget = (store) => {
            return {
                activeServices: ActiveServicesSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                combinedEntitlementTypes: CombinedEntitlementTypesSelector(store),
                currentAccountServices: CurrentAccountServicesSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                customerActiveProducts: SelectedCustomerActiveProductsSelector(store),
                features: FeatureTogglesSelector(store),
                hasOffCycleCharges: HasOffCycleChargesSelector(store),
                pageSize: BulkServicesPageSizeSelector(store),
                resumeServiceReasonCodes: ResumeServiceReasonCodesSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteCurrentStateSelector(store),
                searchString: BulkServicesSearchStringSelector(store),
                selectedStatusFilter: IncludeStatusFilterSelector(store),
                selectedTypeOfServiceFilter: IncludeTypeOfServiceFilterSelector(store),
                servicesSuspendResumeMenuOptions: ServicesSuspendResumeMenuOptionsSelector(store),
                suspendServiceReasonCodes: SuspendServiceReasonCodesSelector(store),
                tabs: ServicesAndUsagesTabsSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.optionalParams = {
            customerId: this.state.currentCustomerId,
            orderId: this.state.routeParams.orderId
        };

        if (this.state.routeParams.orderId) {
            this.orderHistoryState = {
                name: ORDER_HISTORY_DETAILS_STATE_NAME,
                params: {
                    orderId: this.state.routeParams.orderId
                }
            };
        }

        //When we navigate from a React page, make sure that FeatureToggleConfig code table is loaded before making RetrieveSubscriberFinanceDetails call.
        Promise.resolve(this.actions.fetchCodeTypes(CODES.FeatureToggleConfig)).then(() => {
            if (!this.state.features[FeatureToggleConstants.OFFCYCLE_BYPASS_ARM]) {
                this.actions.retrieveSubscriberFinanceDetails(this.state.currentCustomerId).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onTabSelected(selected) {
        this.actions.stateGo(selected.state, selected.params);
    }

    handleServiceMenuOptions(option) {
        if (SERVICE_ACTION_OPTIONS.TRANSFER === option.item.id) {
            this.actions.stateGo(TRANSFER_STATE_NAME);
        } else if (SERVICE_ACTION_OPTIONS.USAGE_CAPS === option.item.id) {
            this.navigateToUsageCapsPage();
        } else {
            this.openSuspendOrResumeServiceModal(option);
        }
    }


    openSuspendOrResumeServiceModal(options) {
        if (options.EllipsisMenuOption === NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK
            || options.EllipsisMenuOption === NON_BULK_SERVICE_CATEGORIZATION.ACTIVE_NON_BULK_WITH_MANAGED_FEATURES) {
            this.actions.stateGo(SUSPEND_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: options?.ServiceIdentifier?.Value ?? null
            });
        } else if (options.EllipsisMenuOption === NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK
            || options.EllipsisMenuOption === NON_BULK_SERVICE_CATEGORIZATION.SUSPENDED_NON_BULK_WITH_MANAGED_FEATURES) {
            this.actions.stateGo(RESUME_SERVICES_REDIRECT, {
                customerId: this.state.currentCustomerId,
                serviceIdentifier: options?.ServiceIdentifier?.Value ?? null
            });
        } else if (options.item) {
            if (options.item.id === SERVICE_ACTION_OPTIONS.SUSPEND) {
                this.actions.stateGo(SUSPEND_SERVICES_REDIRECT, {
                    customerId: this.state.currentCustomerId
                });
            } else if (options.item.id === SERVICE_ACTION_OPTIONS.RESUME) {
                this.actions.stateGo(RESUME_SERVICES_REDIRECT, {
                    customerId: this.state.currentCustomerId
                });
            }
        }
    }

    navigateToUsageCapsPage() {
        this.actions.setSelectAccountLevel(true);
        this.actions.stateGo(USAGE_CAPS_URL);
    }

    $onDestroy() {
        this.actions.resetSharedEntitlementTabData();
        this.actions.removeBulkServicesStatusFilter();
        this.actions.clearSearchEntitlementFiltersRecoverableUIState();
        this.disconnectRedux();
    }
}

export default {
    controller: ServicesAndUsageController,
    template: require('./services.and.shared.entitlements.template.html')
};
