import i18n from 'invision-core/src/components/i18n/i18n';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import __ from 'ramda/src/__';
import chain from 'ramda/src/chain';
import path from 'ramda/src/path';
import prop from 'ramda/src/prop';
import {stateGo} from 'redux-ui-router';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {CurrentMemberHasCreatePaymentInstrumentPermissionsSelector} from '../../../../../reducers/selectors/customer.household.selectors';
import {retrieveOfferingRemovalContext} from '../../../../../reducers/actions/offering.disconnect.actions';
import {
    calculateChangeOfServiceQuote,
    submitChangeOfServiceOrderRemoval,
} from '../../../../../reducers/actions/offering.remove.actions';
import {
    retrieveWallet,
    selectPaymentMethod
} from '../../../../../reducers/actions/customer.ewallet.actions';
import {
    retrieveSubscription,
    searchSubscriptions
} from '../../../../../reducers/actions/customer.subscriptions.actions';

import LocaleKeys from '../../../../../locales/keys';
import {
    IsCalculatingQuoteSelector,
    IsRetrievingContextSelector,
    RemoveOfferItemsIdsSelector
} from '../../../../../reducers/selectors/offering.disconnect.selectors';
import * as EWalletSelectors from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    BriefSubscriptionsSelector,
    CurrentSubscriptionSelector,
    IsFetchingDataSelector,
    UserCanIssueCreditToAlternatePaymentInstrument
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    IsSubmittingOrderSelector,
    QuoteOfferSelector,
    QuoteOfferRemainingAmountSelector
} from '../../../../../reducers/selectors/offering.remove.selectors';
import {
    IsUnderContractForDisconnectOffers,
    OfferingsSelector,
    SelectedCustomerOfferingsCurrency
} from '../../../../../reducers/selectors/disconnect.offers.selectors';
import {NOTIFICATION_TIME_LENGTH} from '../../../../../customercare.constants';
import * as SubscriptionConstants from '../../../../shared/constants/subscriptions.constants';
import {DASHBOARD_ROUTE} from '../../../../../reducers/constants/dashboard.constants';
import {setReloadSubscriberStateFlag} from '../../../../../reducers/actions/customer.actions';

const SUBSCRIPTIONS_SEARCH_CRITERIA = {
    IncludePaymentInstrumentInfo: true,
    IncludeRemoved: false,
    PageSize: 5,
    SortDirection: 2
};

class DisconnectOffersITVController {
    constructor($ngRedux, $state, $q, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $q,
            LocaleKeys,
            uiNotificationService,
            $timeout,
            isDisconnecting: false
        });

        this.confirmationPopupConfig = {
            onRegisterApi: (event) => {
                this.confirmationPopupApi = event.api;
            }
        };

        this.removal = {
            changeImmediately: true,
            creditAmount: 0,
            creditAmountType: 'max',
            creditReason: null,
            customAmount: 0,
            sendNotification: true,
            terminationReason: null
        };

        this.metadataCodes = [
            'SubscriptionTerminationReason',
            'CreditReasons',
            'WaiveFeeReason'
        ];

        this.onConfirmation = this.onConfirmation.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                canIssueCreditToAlternatePaymentMethod: UserCanIssueCreditToAlternatePaymentInstrument(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                isContractEndDate: IsUnderContractForDisconnectOffers(store),
                creditReasons: MetadataOptionsForCodeValuesSelector(CODES.CreditReasons, store),
                currency: SelectedCustomerOfferingsCurrency(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currentRemainingAmount: QuoteOfferRemainingAmountSelector(store),
                currentRemoval: QuoteOfferSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                eWalletError: EWalletSelectors.EwalletErrorSelector(store),
                isCalculatingQuote: IsCalculatingQuoteSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isFetchingEwalletData: EWalletSelectors.IsRetrievingEwalletDataSelector(store),
                isRetrievingContext: IsRetrievingContextSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                offerings: OfferingsSelector(store),
                paymentInstruments: EWalletSelectors.PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                removeOfferItemsIds: RemoveOfferItemsIdsSelector(store),
                selectedPaymentMethod: EWalletSelectors.SelectedPaymentMethodSelector(store),
                subscriptionTerminationReasons: MetadataOptionsForCodeValuesSelector(CODES.SubscriptionTerminationReason, store),
                subscriptions: BriefSubscriptionsSelector(store),
                userHasEWalletAdminAccess: EWalletSelectors.UserHasEWalletAdminAccess(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                waiveFeeReasons: MetadataOptionsForCodeValuesSelector(CODES.WaiveFeeReason, store),
            };
        };
        const controllerActions = {
            calculateChangeOfServiceQuote,
            fetchCodeType: fetchCodeTypes,
            retrieveOfferingRemovalContext,
            retrieveSubscription,
            retrieveWallet,
            searchSubscriptions,
            selectPaymentMethod,
            setReloadSubscriberStateFlag,
            stateGo,
            submitChangeOfServiceOrderRemoval
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            Object.assign(this, {
                state,
                actions
            });
        });

        //Load all needed metadata codes
        this.metadataCodes.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(CODES[codeType])) {
                this.actions.fetchCodeType(CODES[codeType]);
            }
        });

        this.actions.searchSubscriptions(this.state.currentCustomerId, SUBSCRIPTIONS_SEARCH_CRITERIA).then(() => {
            return this.$q.all([
                this.actions.retrieveSubscription(this.state.currentCustomerId, {
                    //for now just grabbing the first subscription might want to grab everything else
                    Id: this.state.subscriptions[0].id
                }),
                this.actions.retrieveOfferingRemovalContext(this.state.removeOfferItemsIds, this.state.currentCustomerId),
                this.actions.retrieveWallet({
                    customerId: this.state.currentCustomerId
                })
            ]);
        }).then(() => {
            return this.actions.selectPaymentMethod(this.state.currentSubscription.PaymentInstrument);
        }).then(() => {
            return this.actions.calculateChangeOfServiceQuote(this.createRequest(), this.state.currentCustomerId);
        }).then(() => {
            this.removal.creditAmount = this.state.currentRemainingAmount;
        }).catch(() => {
            const message = path(['eWalletError', 'message'], this.state) || path(['subscriptionError', 'message'], this.state);
            if (message) {
                this.uiNotificationService.error(message, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }
        });

    }

    createRequest() {
        const removeItemsArray = chain(prop('removeItems'), this.state.offerings);

        const removeOffersRequest = {
            ChangeImmediately: this.removal.changeImmediately,
            CreditAmount: this.removal.creditAmount,
            CreditPaymentInstrument: this.state.selectedPaymentMethod,
            CreditPaymentInstrumentId: this.state.selectedPaymentMethod.Id,
            CreditReason: this.removal.creditReason,
            RemoveItems: removeItemsArray,
            RemoveReasonCode: this.removal.terminationReason,
            SendNotification: this.removal.sendNotification
            //according to Muhamet we do not need OfferingId or SubscriptionId
        };
        return removeOffersRequest;
    }

    get canCreatePaymentInstruments() {
        return this.state.currentMemberCanCreatePaymentInstrument && this.state.userHasEWalletAdminAccess;
    }

    get isLoading() {
        return this.state.isFetchingData || this.state.isFetchingEwalletData || this.state.isRetrievingContext ||
            this.state.isCalculatingQuote || this.state.isSubmittingOrder;
    }

    headingText() {
        return i18n.translate(this.state.removeOfferItemsIds
            ? this.LocaleKeys.DISCONNECT_OFFERS.REMOVE_OFFER
            : this.LocaleKeys.DISCONNECT_OFFERS.DISCONNECT_CUSTOMER);
    }

    isCurrentPaymentMethodRemoved() {
        return this.state.selectedPaymentMethod && this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.REMOVED;
    }

    confirmRemoval() {
        if (this.disconnectOffersForm.$valid) {
            this.disconnectOffersForm.$setPristine();
            this.confirmationPopupApi.open();
        }
    }

    onConfirmation(confirmed) {
        if (confirmed) {
            this.removeOffers();
        }

        this.confirmationPopupApi.close();
    }
    removeOffers() {
        this.actions.submitChangeOfServiceOrderRemoval(this.createRequest(), this.state.currentCustomerId)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(this.LocaleKeys.DISCONNECT_OFFERS.REMOVE_SUCCESSFUL), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                /*
                * TODO:
                * PD-411357
                * Need to identify when the subscriber has no more offers left to trigger (Switches to disconnect state) below action
                */
                this.actions.setReloadSubscriberStateFlag(true);
                this.actions.stateGo(DASHBOARD_ROUTE, {
                    'customerId': this.state.currentCustomerId
                });
            })
            .catch(() => {
                this.uiNotificationService.error(i18n.translate(this.LocaleKeys.DISCONNECT_OFFERS.REMOVE_UNSUCCESSFUL), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }

    handleSetEffectiveType() {
        this.calculateChangeOfService();
        this.removal.creditAmount = 0;
        this.removal.creditAmountType = 'max';
        this.removal.creditReason =  null;
        this.removal.customAmount = 0;
    }

    calculateChangeOfService() {
        this.actions.calculateChangeOfServiceQuote(this.createRequest(), this.state.currentCustomerId).then(() => {
            this.removal.creditAmount = this.state.currentRemainingAmount;
        });
    }
}

export default {
    template: require('./disconnect.offers.itv.html'),
    bindings: {},
    controller: DisconnectOffersITVController,
    controllerAs: 'DisconnectOffers'
};
