import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import {
    SubscriberAdditionalPropertiesInputs,
    MetadataSelectors,
    MetadataConstants,
    MetadataHelpers,
    AdditionalPropValueType
} from 'invision-core';
import {
    IsEditingProfileSelector,
    SelectedCustomerAdditionalPropertiesSelector,
    CreateEditCustomerSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    CurrentStepIndexSelector as NewConnectCurrentStepIndexSelector,
    CurrentStepSelector as NewConnectCurrentStepSelector,
    EditCustomerInfoSelector
} from '../../../reducers/selectors/new.connect.wizard.selectors';
import {CreateChildModelSelector} from '../../../reducers/selectors/customer.account.hierarchy.selectors';
import {
    CreditCheckAdditionalPropertiesSelector,
    CreditCheckConfigurationSelector,
    IsCreditCheckSkippedSelector
} from '../../../reducers/selectors/credit.check.selectors';
import CustomerCareKeys from '../../../locales/keys';
import {ADDITIONAL_PROPERTIES_SORT_PROP_DATA} from '../create.customer.constants';

export const SubscriberAdditionalPropFilteredByCustomerTypeSelector = createSelector(
    [
        SubscriberAdditionalPropertiesInputs,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriberType),
        CreateEditCustomerSelector,
        EditCustomerInfoSelector,
        CreateChildModelSelector
    ],
    (subscriberAdditionalPropData, subscriberTypeCodes, customerModel, newConnectCustomerModel, childModel) => {
        let subscriberType = '';
        if (customerModel.SubscriberTypeCode || newConnectCustomerModel.SubscriberTypeCode || childModel.Customer.SubscriberTypeCode) {
            const subscriberTypeCode = subscriberTypeCodes.find((code) => {
                return code.Value === (customerModel.SubscriberTypeCode || newConnectCustomerModel.SubscriberTypeCode || childModel.Customer.SubscriberTypeCode);
            });
            if (subscriberTypeCode) {
                subscriberType = subscriberTypeCode.Name;
            }
        }

        subscriberAdditionalPropData.map((propData) => {
            const propDataId = ADDITIONAL_PROPERTIES_SORT_PROP_DATA.find((apSortData) => {
                return apSortData.propId === propData.id;
            });

            if (propDataId && propData.options) {
                propData.options.sort((a, b) => {
                    return a.name.localeCompare(b.name);
                });
            }
            return propData;
        });

        return subscriberAdditionalPropData.filter((additionalProperty) => {
            return additionalProperty.subEntityType === subscriberType ||
                additionalProperty.subEntityType === '';
        });
    }
);

export const SubscriberAdditionalPropertiesSelector = createSelector(
    [
        CreateEditCustomerSelector,
        CreditCheckConfigurationSelector,
        CreditCheckAdditionalPropertiesSelector,
        IsCreditCheckSkippedSelector,
        IsEditingProfileSelector,
        NewConnectCurrentStepIndexSelector,
        NewConnectCurrentStepSelector,
        SelectedCustomerAdditionalPropertiesSelector,
        SubscriberAdditionalPropFilteredByCustomerTypeSelector
    ],
    (
        createCustomerModel,
        creditCheckConfiguration, creditCheckAdditionalProperties, isCreditCheckSkipped,
        isEditingProfile,
        newConnectCurrentStepIndex, newConnectCurrentStep,
        selectedCustomerAdditionalProperties, subscriberAdditionalPropertyFields
    ) => {
        let subscriberAdditionalProperties = [];
        const editedAdditionalProps = pathOr([], ['setEditAdditionalProp'], createCustomerModel);
        if (isEditingProfile || creditCheckConfiguration.creditCheckModalStatus ||
            CustomerCareKeys.WIZARD.STEPS.CUSTOMER === newConnectCurrentStep) {
            if (creditCheckConfiguration.creditCheckModalStatus) {
                subscriberAdditionalPropertyFields.forEach((item) => {
                    item.requiredForCreditCheck = creditCheckAdditionalProperties.includes(item.id.toString());
                    return item;
                });
            }

            if (isEditingProfile) {
                subscriberAdditionalProperties = MetadataHelpers.codes.additionalPropertiesMergeValuesIntoTemplate(
                    subscriberAdditionalPropertyFields,
                    editedAdditionalProps.length ? editedAdditionalProps : selectedCustomerAdditionalProperties
                );
            } else {
                subscriberAdditionalProperties = MetadataHelpers.codes.additionalPropertiesMergeValuesIntoTemplate(
                    subscriberAdditionalPropertyFields,
                    editedAdditionalProps
                );
            }

            if (creditCheckConfiguration.creditCheckModalStatus) {
                if (!isCreditCheckSkipped && creditCheckAdditionalProperties) {
                    subscriberAdditionalProperties.forEach((item) => {
                        item.required = item.required || creditCheckAdditionalProperties.includes(item.id);
                        if (item.type === AdditionalPropValueType.MULTIPLE_TEXT && item.required && item.Values.length === 0) {
                            item.Values.push('');
                        }
                        return item;
                    });
                }
            }
        } else {
            subscriberAdditionalProperties = MetadataHelpers.codes.additionalPropertiesMergeValuesIntoTemplate(
                subscriberAdditionalPropertyFields,
                editedAdditionalProps
            );
        }
        return subscriberAdditionalProperties;
    }
);
