export const ACCOUNT_HIERARCHY_MORE_MENU_ITEMS = {
    ADD_EXISTING_CUSTOMER: 'ADD_EXISTING_CUSTOMER',
    CHANGE_BILLING_TYPE: 'CHANGE_BILLING_TYPE',
    CREATE_COMMERCIAL_CHILD: 'CREATE_COMMERCIAL_CHILD',
    CREATE_RESIDENTIAL_CHILD: 'CREATE_RESIDENTIAL_CHILD',
    MOVE_IN_HIERARCHY: 'MOVE_IN_HIERARCHY',
    REDIRECT_CHARGES: 'REDIRECT_CHARGES',
    REMOVE: 'REMOVE',
    UPDATE_RESPONSIBLE_PARTY: 'UPDATE_RESPONSIBLE_PARTY'
};
export const REPORTING_LEVELS = Object.freeze({
    REPORTING_LEVEL_INVOICE: 1,
    REPORTING_LEVEL_STATEMENT: 2,
    REPORTING_LEVEL_NONE: 3
});

export const SUBSCRIBER_TYPES = Object.freeze({
    COMMERCIAL: 4,
    RESIDENTIAL: 5
});

export const CHARGE_REDIRECT_INSTANCE_STATUS = Object.freeze({
    ACTIVE: 1,
    INACTIVE: 2
});
