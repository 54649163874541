import __ from 'ramda/src/__';
import {stateGo} from 'redux-ui-router';
import {
    RouteParams,
    RouteCurrentStateSelector
} from 'invision-core/src/components/router/router.selectors';
import {LastRouteSelector} from 'invision-core/src/components/session/session.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import CustomerLocaleKeys from '../../../locales/keys';
import {
    IsFetchingOfferingsSelector,
    SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector,
    SelectedSubscriberOfferingInstanceIdSelector,
    SubsriberOfferingsFilterDataSelector,
    SubsriberOfferingsPaginationDataSelector,
} from '../../../reducers/selectors/search.subscriber.offerings.selectors';
import {
    searchSubscriberOfferings,
    setOfferingFilterData,
    setOfferingPaginationData
} from '../../../reducers/actions/offering.order.actions';
import {setSelectedOfferingInstanceId} from '../../../reducers/actions/customer.actions';
import {
    DEFAULT_MAXIMUM_SLOTS,
    DEFAULT_PAGE_NUMBER,
    offeringSearchByFilters,
    offeringStatusFilters,
    OFFERING_STATUS_TYPES,
    SEARCH_BY_TYPES
} from './offerings.constants';
import {DASHBOARD_ROUTE} from '../../../reducers/constants/dashboard.constants';


class OfferingsController {
    constructor($ngRedux, $state, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            CustomerLocaleKeys,
            CoreLocaleKeys,
            DEFAULT_MAXIMUM_SLOTS,
            DEFAULT_PAGE_NUMBER,
            goBack: this.goBack.bind(this),
            offerStatusFilters: offeringStatusFilters(),
            onFilterOptionSelected: this.onFilterOptionSelected.bind(this),
            onOfferingSelected: this.onOfferingSelected.bind(this),
            onPageSelected: this.onPageSelected.bind(this),
            onPageSizeOptionSelected: this.onPageSizeOptionSelected.bind(this),
            onSearchByOptionSelected: this.onSearchByOptionSelected.bind(this),
            onSearchSubmitted: this.onSearchSubmitted.bind(this),
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            fetchCodeTypes,
            searchSubscriberOfferings,
            setOfferingFilterData,
            setOfferingPaginationData,
            setSelectedOfferingInstanceId,
            stateGo
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                filterData: SubsriberOfferingsFilterDataSelector(store),
                isFetchingOfferings: IsFetchingOfferingsSelector(store),
                lastRoute: LastRouteSelector(store),
                offerings: SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector(store),
                paginationData: SubsriberOfferingsPaginationDataSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteCurrentStateSelector(store),
                selectedOfferingInstanceId: SelectedSubscriberOfferingInstanceIdSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        const codeTables = [
            CODES.ServiceAttribute,
            CODES.RegularExpression,
            CODES.ServiceLifeCycleStatus
        ];

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.optionalParams = {
            customerId: this.state.currentCustomerId,
            offeringInstanceId: this.state.routeParams.offeringInstanceId
        };

        this.actions.setSelectedOfferingInstanceId(null);

        if (this.state.routeParams.offeringInstanceId) {
            const offeringStatusTypes = Object.values(OFFERING_STATUS_TYPES);
            this.setSelectedOfferStatus(offeringStatusTypes.map((status) => {
                return {
                    id: status
                };
            }));
            this.actions.setOfferingFilterData(SEARCH_BY_TYPES.OFFERING_INSTANCE_ID, this.state.routeParams.offeringInstanceId, offeringStatusTypes);
            this.searchByFilters = offeringSearchByFilters(SEARCH_BY_TYPES.OFFERING_INSTANCE_ID);
        } else {
            this.actions.setOfferingFilterData(SEARCH_BY_TYPES.OFFERING_NAME, null, [OFFERING_STATUS_TYPES.ACTIVE]);
            this.searchByFilters = offeringSearchByFilters(SEARCH_BY_TYPES.OFFERING_NAME);
        }

        this.fetchOfferings();
    }

    fetchOfferings() {

        const params =  Object.assign({}, this.state.routeParams, {
            pageNumber: this.state.paginationData.pageNumber,
            pageSize: this.state.paginationData.pageSize,
            status: this.state.filterData.filterBy
        });

        if (this.state.filterData.searchTerm) {
            if (this.state.filterData.searchBy === SEARCH_BY_TYPES.OFFERING_INSTANCE_ID) {
                params.offeringInstanceIds = [this.state.filterData.searchTerm];
            } else {
                params.offerName = this.state.filterData.searchTerm;
            }
        }

        this.actions.searchSubscriberOfferings(params).then(() => {
            if (this.state.offerings.length) {
                this.actions.setSelectedOfferingInstanceId(this.state.offerings[0].offerDetails.OfferingInstanceId);
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onSearchByOptionSelected(searchBy) {
        this.actions.setOfferingFilterData(searchBy.id, this.state.filterData.searchTerm, this.state.filterData.filterBy);
        this.actions.setOfferingPaginationData(DEFAULT_PAGE_NUMBER, this.state.paginationData.pageSize);
    }

    onSearchSubmitted(searchTerm) {
        this.actions.setOfferingFilterData(this.state.filterData.searchBy, searchTerm, this.state.filterData.filterBy);
        this.actions.setOfferingPaginationData(DEFAULT_PAGE_NUMBER, this.state.paginationData.pageSize);
        this.fetchOfferings();
    }

    onFilterOptionSelected(filterBy = []) {
        this.setSelectedOfferStatus(filterBy);
        this.actions.setOfferingFilterData(this.state.filterData.searchBy, this.state.filterData.searchTerm, filterBy.map((filter) => {
            return filter.id;
        }));
        this.actions.setOfferingPaginationData(DEFAULT_PAGE_NUMBER, this.state.paginationData.pageSize);
        this.fetchOfferings();
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setOfferingPaginationData(DEFAULT_PAGE_NUMBER, pageSize);
        this.fetchOfferings();
    }

    onPageSelected(pageNumber) {
        this.actions.setOfferingPaginationData(pageNumber, this.state.paginationData.pageSize);
        this.fetchOfferings();
    }

    setSelectedOfferStatus(selectedOfferStatus = []) {
        this.offerStatusFilters = this.offerStatusFilters.map((item) => {
            return Object.assign(item, {
                selected: selectedOfferStatus.some((filter) => {
                    return filter.id === item.id;
                })
            });
        });
    }

    onOfferingSelected(offeringInstanceId) {
        this.actions.setSelectedOfferingInstanceId(offeringInstanceId);
    }

    goBack() {
        const route = this.state.lastRoute.name || DASHBOARD_ROUTE;
        const params = this.state.lastRoute.params || {};
        this.$state.go(route, params);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: OfferingsController,
    template: require('./offerings.template.html')
};
