import pathOr from 'ramda/src/pathOr';
import {
    getProductPrice,
    getProductPriceLabel
} from './product.helper';
import {
    CartTabViewModel,
    CatalogSubscriptionsFilterSelector,
    CustomerInfoSelector,
    SubscriptionsOnlySelector
} from '../../create.products.order.wizard.selectors';
import {
    ProductCategoriesSelector,
    ProductFacetsSelector
} from './products.selectors';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {getCategories} from 'invision-core/src/components/metadata/categories/categories.actions';
import {
    IsOfferFeatureEnabled,
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {RouteParams} from '../../../../../../../reducers/selectors/customer.selectors';
import {CategoriesIsLoadedSelector} from 'invision-core/src/components/metadata/categories/categories.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {
    InvalidItemsSelector,
    IsFetchingSelector,
    NumberOfPagesSelector,
    PageNumberSelector,
    ProductOrderDataListSelector,
    ProductsSortOptionsFormattedSelector,
    RecordCountSelector,
    SearchCatalogParamsSelector,
    SelectedProductIdSelector,
    SelectedProductsSortOption,
    ShoppingCartClassification
} from '../../../../../../../reducers/selectors/products.order.selectors';
import {
    clearProductsMetadata,
    setPageNumber,
    setSelectedProductsSortOption,
    updateSearchCatalogParams
} from '../../../../../../../reducers/actions/products.order.actions';
import CustomerCareKeys from '../../../../../../../locales/keys';
import {PreviousStepIndexSelector} from '../../../../../../../reducers/selectors/products.wizard.selectors';
import {STRUCTURE_TYPES} from '../../../../../../../reducers/constants/structure.type.constants';
import {ALL_STEPS_KEYS} from '../../../../../../../reducers/constants/products.wizard.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';

const PAGER_MAX_SLOTS = 5;

class ProductsController {
    constructor($filter, $ngRedux, $timeout, $anchorScroll, uiNotificationService) {
        Object.assign(this, {
            $anchorScroll,
            $filter,
            $ngRedux,
            $timeout,
            couponCode: '',
            customerCareKeys: CustomerCareKeys,
            handleSearchCatalogChanged: this.handleSearchCatalogChanged.bind(this),
            pageSelected: this.pageSelected.bind(this),
            productClassifications: PRODUCT_CLASSIFICATIONS,
            shouldShowCategoriesPanel: false,
            shouldShowError: this.shouldShowError.bind(this),
            sortChanged: this.sortChanged.bind(this),
            structureTypes: STRUCTURE_TYPES,
            toggleCategoriesPanel: this.toggleCategoriesPanel.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                cartClassification: ShoppingCartClassification(store),
                cartTab: CartTabViewModel(store),
                catalogSubscriptionsFilter: CatalogSubscriptionsFilterSelector(store),
                categories: ProductCategoriesSelector(store),
                categoriesLoaded: CategoriesIsLoadedSelector(store),
                customerInfo: CustomerInfoSelector(store),
                data: ProductOrderDataListSelector(store),
                facets: ProductFacetsSelector(store),
                invalidItems: InvalidItemsSelector(store),
                isDbss: IsDbss(store),
                isFetchingData: IsFetchingSelector(store),
                isItvOffersEnabled: IsOfferFeatureEnabled(store),
                isSubscription: SubscriptionsOnlySelector(store),
                maximumSlots: PAGER_MAX_SLOTS,
                numberOfPages: NumberOfPagesSelector(store),
                pageNumber: PageNumberSelector(store),
                paginationDataContext: {
                    filters: pathOr([], ['ProductFilter', 'Facets'], SearchCatalogParamsSelector(store))
                        .concat(pathOr([], ['ProductFilter', 'Categories'], SearchCatalogParamsSelector(store)))
                },
                previousStepIndex: PreviousStepIndexSelector(store),
                productFacetCategoriesLoaded: MetadataCodeLoadedSelector(CODES.ProductFacetCategory, store),
                productFacetsLoaded: MetadataCodeLoadedSelector(CODES.ProductFacet, store),
                productsSortOptions: ProductsSortOptionsFormattedSelector(store),
                recordCount: RecordCountSelector(store),
                routeParams: RouteParams(store),
                searchCatalogParams: SearchCatalogParamsSelector(store),
                selectedProductId: SelectedProductIdSelector(store),
                selectedProductsSortOption: SelectedProductsSortOption(store),
                structureTypes: MetadataCodeTypeSelector(CODES.StructureType, store),
                structureTypesLoaded: MetadataCodeLoadedSelector(CODES.StructureType, store),
                userPageSizePreference: PageSizePreferenceSelector(store)
            };
        };
        const controllerActions = {
            clearProductsMetadata,
            fetchCodeTypes,
            getCategories,
            setPageNumber,
            setSelectedProductsSortOption: setSelectedProductsSortOption,
            updateSearchCatalogParams
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        // TODO - Customer Order we should not be providing a mutable copy for this, but rather dispatch actions to directly update client reducer store
        // where selections should be stored and the selector should provide an immutable object for presentational filter component to bind to
        // then debounce the search catalog call if not done already so that click happy user isn't causing extra calls.
        this.searchCatalogParams = this.state.searchCatalogParams; //mutable copy for view model search filters
        this.shouldPersistSearchOptions = ALL_STEPS_KEYS[this.state.previousStepIndex] === CustomerCareKeys.WIZARD.STEPS.CHECKOUT;

        if (!this.shouldPersistSearchOptions) {
            this.searchCatalogParams.ProductFilter.Subscription = this.state.catalogSubscriptionsFilter;
        }

        this.recurrenceOptions = {
            subscriptionFilter: this.state.catalogSubscriptionsFilter,
            productFilter: !this.state.catalogSubscriptionsFilter
        };

        if (!this.state.structureTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.StructureType);
        }

        if (!this.state.productFacetsLoaded) {
            this.actions.fetchCodeTypes(CODES.ProductFacet);
        }

        if (!this.state.productFacetCategoriesLoaded) {
            this.actions.fetchCodeTypes(CODES.ProductFacetCategory);
        }

        if (!this.state.categoriesLoaded) {
            this.actions.getCategories();
        }

        this.getProductPriceLabel = (product) => {
            return getProductPriceLabel(product);
        };

        this.getProductPrice = (product) => {
            return getProductPrice(this.$filter, product);
        };

        if (this.shouldPersistSearchOptions) {
            this.keyword = this.searchCatalogParams.SearchString;
        } else {
            this.keyword = '';
        }

        this.pageSelected(1);
    }

    toggleCategoriesPanel() {
        this.shouldShowCategoriesPanel = !this.shouldShowCategoriesPanel;
    }

    shouldShowError() {
        return this.continueClicked && !this.cartHasItems;
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    $onChanges(changesObj) {
        if (changesObj.continueClicked && this.shouldShowError()) {
            this.$anchorScroll('topProductList');
        }
    }

    updateSearchCatalogParams(searchCatalogParams) {
        if (!searchCatalogParams.PageSize) {
            searchCatalogParams.PageSize = this.state.userPageSizePreference;
        }

        if (!searchCatalogParams.Headers.CustomerId) {
            searchCatalogParams.Headers.CustomerId = this.state.customerInfo.Id;
        }

        if (this.isServiceFeatureOrder) {
            searchCatalogParams.ProductFilter.ProductClassification = PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE;
            searchCatalogParams.ProductFilter.ServiceId = convertStringToNumber(this.state.routeParams.serviceTypeId);
        }

        this.actions.updateSearchCatalogParams(searchCatalogParams);
    }

    updateSearchCatalogParamsResetPage(searchCatalogParams) {
        const initialPageNumber = 1;
        searchCatalogParams.PageNumber = initialPageNumber;
        this.actions.setPageNumber(initialPageNumber);
        this.updateSearchCatalogParams(searchCatalogParams);
    }

    pageSelected(pageNum) {
        this.searchCatalogParams.PageNumber = pageNum;

        this.actions.setPageNumber(pageNum);
        this.updateSearchCatalogParams(this.searchCatalogParams);
    }

    keywordGo() {
        this.searchCatalogParams.SearchString = this.keyword;

        this.updateSearchCatalogParamsResetPage(this.searchCatalogParams);
    }

    clearSearchText() {
        this.searchCatalogParams.SearchString = undefined;
        this.keyword = undefined;
        this.pageSelected(1);
    }

    sortChanged(sortOption) {
        this.state.productsSortOptions.forEach((productSortOption) => {
            productSortOption.selected = false;
        });
        sortOption.selected = true;
        this.searchCatalogParams.ProductSort.SortBy = sortOption.value;
        this.searchCatalogParams.ProductSort.SortDirection = sortOption.direction;

        this.updateSearchCatalogParamsResetPage(this.searchCatalogParams);
        this.actions.setSelectedProductsSortOption(sortOption);
    }

    handleSearchCatalogChanged(searchCatalogParams) {
        this.updateSearchCatalogParamsResetPage(searchCatalogParams);
    }

    onCouponCodeChanged() {
        this.actions.clearProductsMetadata();
        this.searchCatalogParams.ProductFilter.CouponCode = this.couponCode;
        this.updateSearchCatalogParamsResetPage(this.searchCatalogParams);
    }

    removeCouponCode() {
        this.actions.clearProductsMetadata();
        this.couponCode = '';
        this.onCouponCodeChanged();
    }

    onPageSizeOptionSelected(pageSize) {
        this.searchCatalogParams.PageSize = pageSize;
        this.updateSearchCatalogParamsResetPage(this.searchCatalogParams);
    }
}

export default {
    template: require('./products.html'),
    controller: ProductsController,
    controllerAs: 'productsController',
    bindings: {
        cartHasItems: '<',
        continueClicked: '<',
        isServiceFeatureOrder: '<?',
        onOptionSelect: '&',
        onQuickView: '&',
    }
};
