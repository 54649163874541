import concat from 'ramda/src/concat';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import groupBy from 'ramda/src/groupBy';
import uniqBy from 'ramda/src/uniqBy';
import mergeDeepWith from 'ramda/src/mergeDeepWith';
import pathOr from 'ramda/src/pathOr';

import {
    BILLER_RULE_INSTANCE_TYPE,
    BILLER_RULE_INSTANCE_TYPE_COMBINATIONS
} from '../../../../../customercare.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {OFFERING_OPTION_STATUSES} from '../../../../../components/shared/constants/offering.option.status.constants';
import {BRI_ACTIVATION_STATUS} from 'invision-core/src/constants/pricing.plan.constants';

const getOptionBillerItemBulkPricesByType = (option) => {
    return groupBy((billerItemBulkPrice) => {
        return billerItemBulkPrice.Type;
    }, option.BillerRulePrices || []);
};

export function filterBulkOfferOptions(offer) {
    return pathOr([], ['Options'], offer).filter((option) => {
        return option.BillerRulePrices && option.BillerRulePrices.length > 0 &&
            option.OfferingId === offer.OfferingId;
    });
}

export function hasBulkOfferOptions(offer) {
    const bulkOfferOptions = filterBulkOfferOptions(offer);
    return (bulkOfferOptions.length > 0) ? true : false;
}

const getOptionBillerTypeAmountsByType = (option) => {
    return groupBy((billerTypeAmount) => {
        return billerTypeAmount.Type;
    }, option.BillerTypeAmounts || []);
};

export const getOfferOptionsWithInactiveBrisRemoved = (offer, type) => {
    const serviceFeatures = pathOr([], ['ServiceFeatures'], offer);
    const path = (type === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE) ?
        serviceFeatures :
        [...pathOr([], ['Options'], offer), ...serviceFeatures];
    return path.map((option) => {
        const activeBillerTypeAmounts = pathOr([], ['BillerTypeAmounts'], option).filter((billerTypeAmount) => {
            return (billerTypeAmount.Status === BRI_ACTIVATION_STATUS.ACTIVE &&
                (billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING ||
                    billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT ||
                    billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME)) ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.USAGE ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.TRIGGER ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.EARLY_TERMINATION_FEE ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.CUSTOM ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION;
        });

        const billerItemBulkPricesForActiveBillerTypeAmounts =  pathOr([], ['BillerRulePrices'], option).filter((billerItemBulkPrice) => {
            return !!(activeBillerTypeAmounts.find((activeBillerTypeAmount) => {
                return activeBillerTypeAmount.BillerRuleConfigurationId === billerItemBulkPrice.BillerRuleConfigurationId;
            }));
        });

        return Object.assign({}, option, {
            BillerTypeAmounts: activeBillerTypeAmounts,
            BillerRulePrices: billerItemBulkPricesForActiveBillerTypeAmounts
        });
    });
};

export const getOptionBillerRulesGroupedByType = (option) => {
    return mergeDeepWith(
        concat,
        getOptionBillerItemBulkPricesByType(option),
        getOptionBillerTypeAmountsByType(option)
    );
};

// NOTE: EBRIs may also have a related TBRI, but currently those can be effectively ignored for these scenarios
export const getOptionHasEntitlementBillerRuleOnly = (billerRulesGroupedByType) => {
    return !!(
        billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.CUSTOM] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.ONETIME] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.RECURRING] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.USAGE]
    );
};

export const getOptionHasUsageBillerRuleOnly = (billerRulesGroupedByType) => {
    return !!(
        billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.USAGE] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.CUSTOM] &&
        //      !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.ONETIME] &&
        !billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.RECURRING]
    );
};

export function getOptionsByActiveBriType(offer, type) {
    const optionsByBriType = initializeBriOptionsObject();

    getOfferOptionsWithInactiveBrisRemoved(offer, type).forEach((option) => {
        if (option.Status !== OFFERING_OPTION_STATUSES.REMOVED) {
            const billerRulesGroupedByType = getOptionBillerRulesGroupedByType(option);
            const optionHasStandaloneUsageBillerRule = getOptionHasUsageBillerRuleOnly(billerRulesGroupedByType);

            forEachObjIndexed((billerRules, billerRuleType) => {
                optionsByBriType[billerRuleType].push(
                    Object.assign({}, option, {
                        showUsageIcon: !!(
                            !optionHasStandaloneUsageBillerRule &&
                            billerRulesGroupedByType[BILLER_RULE_INSTANCE_TYPE.USAGE]
                        )
                    })
                );
            }, billerRulesGroupedByType);

            if (optionHasStandaloneUsageBillerRule) {
                option.BillerTypeAmounts = uniqBy((BillerTypeAmounts) => {
                    BillerTypeAmounts.type;
                }, option.BillerTypeAmounts);
                optionsByBriType[BILLER_RULE_INSTANCE_TYPE_COMBINATIONS.STANDALONE_USAGE].push(option);
            }
        }
    });

    return optionsByBriType;
}

const initializeBriOptionsObject = () => {
    const optionsByBriType = Object.keys(BILLER_RULE_INSTANCE_TYPE).reduce(
        (accumulator, type) => {
            return Object.assign(accumulator, {
                [BILLER_RULE_INSTANCE_TYPE[type]]: []
            });
        },
        {}
    );

    optionsByBriType[BILLER_RULE_INSTANCE_TYPE_COMBINATIONS.STANDALONE_USAGE] = [];

    return optionsByBriType;
};

export function hasPendingChange(offer) {
    if (!offer || !offer.Options) {
        return false;
    }

    return offer.Options.some((e) => {
        return e.Status === OFFERING_OPTION_STATUSES.PENDING_ACTIVE || e.Status === OFFERING_OPTION_STATUSES.PENDING_REMOVED;
    });
}

const bothPending = (statuses) => {
    const active = statuses.some((status) => {
        return status === OFFERING_OPTION_STATUSES.PENDING_ACTIVE;
    });
    const remove = statuses.some((status) => {
        return status === OFFERING_OPTION_STATUSES.PENDING_REMOVED;
    });
    return active && remove;
};

export function offerOptionsStatus(offer) {
    if (!offer || !offer.Options) {
        return false;
    }

    const statuses = offer.Options.map((e) => {
        return e.Status;
    });

    return (statuses.length > 1 && bothPending(statuses)) ? OFFERING_OPTION_STATUSES.PENDING_ACTIVE : Math.max.apply(Math, statuses);
}

export function isPaymentInstrumentChangeable(offer) {
    if (!offer || !offer.Options) {
        return false;
    }

    return offer.Options.some((o) => {
        return o.OffCycleDetail || false;
    });
}
