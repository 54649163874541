import CustomerCareKeys from '../../../../../locales/keys';

class ReturnDeviceModalComponent {

    constructor($filter, $ngRedux) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            AMOUNT_TYPE: {
                MAX: 'max',
                CUSTOM: 'custom'
            },
            creditLocaleKeys: CustomerCareKeys.ORDER_DETAILS.CREDIT,
            CustomerCareKeys,
            extraErrors: [],
            MAXIMUM_DESCRIPTION_LENGTH: 100,
            model : {
                ReasonCode: null,
                Description: null
            }
        });
    }

    $onInit() {
        this.maximumRefundableAmount = this.returnDetails[0].MaximumRefundableAmount || 0;
    }

    onSubmitForm() {
        if (this.ReturnDeviceForm.$valid) {
            const {Description, ReasonCode} = this.model;
            const deviceRefundDetails = Object.assign({
                Description,
                ReasonCode,
                SubscriberDeviceRefundDetails: [{
                    InventoryTypeId: this.inventoryTypeId,
                    RefundAmount: this.maximumRefundableAmount,
                    SubscriberProductId: this.subscriberProductId
                }]
            });
            this.onSubmit()(deviceRefundDetails);
        }
    }
}

export default {
    bindings: {
        creditReasons: '<',
        currencyCode: '<',
        isFetchingDeviceReturnDetails: '<',
        inventoryTypeId: '<',
        onClose: '&',
        onSubmit: '&',
        returnDetails: '<',
        returnDeviceTitle: '@',
        subscriberProductId: '<'
    },
    template: require('./return.device.modal.html'),
    controller: ReturnDeviceModalComponent,
    controllerAs: 'ReturnDevice'
};
