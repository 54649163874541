import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {
    EMAIL_ADDRESSES,
    NUMBERS_ONLY_OR_BLANK
} from 'invision-core/src/constants/validation.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../../../locales/keys';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CustomerSearchResultsViewModelSelector} from '../../../../../reducers/selectors/search.other.customers.selectors';
import {CurrentCustomerSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    resetSearchOtherCustomersStore,
    searchExistingCustomers,
    searchHouseholdMembers
} from '../../../../../reducers/actions/search.other.customers.actions';
import {SearchIsFetchingResultsSelector} from '../../../../../reducers/selectors/search.selectors';

const CUSTOMER_SEARCH_TYPE = {
    EXISTING_CUSTOMER: 1,
    HOUSEHOLD_MEMBER: 2
};

class customerSearchDialogController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CoreLocaleKeys,
            CUSTOMER_SEARCH_TYPE,
            CustomerCareKeys,
            customErrorMessages: [],
            customerIdNumericErrorMessage: {
                pattern: i18n.translate(CustomerCareKeys.SEARCH_ERRORS_CUSTOMER.SUBSCRIBERID_FORMAT_ERROR)
            },
            customerSearchModel: {},
            customerSearchTypeOptions: [{
                id: CUSTOMER_SEARCH_TYPE.EXISTING_CUSTOMER,
                text: i18n.translate(CustomerCareKeys.SEARCH_OTHER_CUSTOMER.EXISTING_CUSTOMER),
                selected: true
            }, {
                id: CUSTOMER_SEARCH_TYPE.HOUSEHOLD_MEMBER,
                text: i18n.translate(CustomerCareKeys.SEARCH_OTHER_CUSTOMER.HOUSEHOLD_MEMBER),
                selected: false
            }],
            EMAIL_ADDRESSES,
            firstNameRequiredErrorMessage: {
                required: i18n.translate(CustomerCareKeys.SEARCH_OTHER_CUSTOMER.DEPENDENT_REQUIRED_ERROR_MESSAGE, {
                    subjectField: i18n.translate(CustomerCareKeys.SEARCH_FIELDS_CUSTOMER.FIRST_NAME),
                    dependentField: i18n.translate(CustomerCareKeys.SEARCH_FIELDS_CUSTOMER.LAST_NAME),
                    prefixDependentField: i18n.translate(CustomerCareKeys.PREFIX)
                })
            },
            lastNameRequiredErrorMessage: {
                required: i18n.translate(CustomerCareKeys.SEARCH_OTHER_CUSTOMER.DEPENDENT_REQUIRED_ERROR_MESSAGE, {
                    subjectField: i18n.translate(CustomerCareKeys.SEARCH_FIELDS_CUSTOMER.LAST_NAME),
                    dependentField: i18n.translate(CustomerCareKeys.SEARCH_FIELDS_CUSTOMER.FIRST_NAME),
                    prefixDependentField: i18n.translate(CustomerCareKeys.PREFIX)
                })
            },
            NUMBERS_ONLY_OR_BLANK,
            onCustomerSearchTypeOptionSelected: this.onCustomerSearchTypeOptionSelected.bind(this),
            selectedCustomer: null,
            selectedCustomerSearchType: CUSTOMER_SEARCH_TYPE.EXISTING_CUSTOMER,
            showResults: false,
            uiNotificationService
        });
    }

    $onInit() {
        const controllerActions = {
            resetSearchOtherCustomersStore,
            searchExistingCustomers,
            searchHouseholdMembers
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                customerSearchResultsViewModel: CustomerSearchResultsViewModelSelector(store),
                isFetchingSearchResults: SearchIsFetchingResultsSelector(store),
                personTitleOptions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.PersonTitle, store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.resetSearchResultsAndSelectionData();
        this.disconnectRedux();
    }

    onCustomerSearchTypeOptionSelected(option) {
        this.customerSearchTypeOptions.forEach((option) => {
            option.selected = false;
        });
        option.selected = true;
        this.selectedCustomerSearchType = option.id;
        if (this.selectedCustomerSearchType === CUSTOMER_SEARCH_TYPE.EXISTING_CUSTOMER) {
            this.customerSearchModel = {};
            this.showResults = false;
        } else {
            this.actions.searchHouseholdMembers(this.state.currentCustomer.Id).then(() => {
                this.showResults = true;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    showCustomerSearchTypeFilter() {
        return this.selectedCustomerSearchType !== CUSTOMER_SEARCH_TYPE.EXISTING_CUSTOMER || !this.showResults;
    }

    areAllSearchFieldsEmpty() {
        return !this.customerSearchModel.title && !this.customerSearchModel.firstName && !this.customerSearchModel.lastName && !this.customerSearchModel.companyName && !this.customerSearchModel.email &&
            !this.customerSearchModel.customerId && !this.customerSearchModel.phoneNumber;
    }

    customErrorsExist() {
        this.customErrorMessages = [];
        if (this.areAllSearchFieldsEmpty()) {
            this.customErrorMessages.push(i18n.translate(this.CustomerCareKeys.SEARCH_ERRORS_CUSTOMER.EMPTY_FORM_ERROR));
        }
        return !!this.customErrorMessages.length;
    }

    onSearchClicked() {
        this.customerSearchTypeForm.$submitted = true;
        if (this.customerSearchTypeForm.$valid && !this.customErrorsExist()) {
            this.actions.searchExistingCustomers(this.customerSearchModel).then(() => {
                this.showResults = true;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    backToSearchForm() {
        this.showResults = false;
        this.resetSearchResultsAndSelectionData();
    }

    resetSearchResultsAndSelectionData() {
        this.selectedCustomer = null;
        this.actions.resetSearchOtherCustomersStore();
    }

    handleSearchResultItemClicked(customer) {
        if (!customer.isDisabled) {
            this.selectedCustomer = customer;
        }
    }
}

export default {
    template: require('./search.other.customer.dialog.html'),
    bindings: {
        additionalText: '<?',
        dialogTitle: '<',
        isLoading: '<',
        onClose: '&',
        onCustomerSelected: '&',
        searchTypeDropdownLabel: '<?',
        viewModel: '<?',
    },
    controller: customerSearchDialogController
};
