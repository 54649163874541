import clone from 'ramda/src/clone';
import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import CustomerLocaleKeys from '../../../../../../../locales/keys';
import {UNIT_OF_MEASURES} from '../../../../../../../customercare.constants';
import {
    entitlementBalanceUpdate,
    retrieveEntitlementsToUpdate,
    setSelectedEntitlement
} from '../..../../../../../../../../reducers/actions/entitlement.balance.update.modal.actions';
import {ConvergentBillerCurrencyCodeSelector} from '../../../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveServiceDetails} from '../../../../../../../reducers/actions/services.actions';
import {
    IsLoadingEntitlementBalancesSelector,
    ServiceEntitlementsSelector,
    SharedEntitlementsSelector,
    UnitOfMeasuresSelector
}  from '../../../../../../../reducers/selectors/entitlement.balance.update.modal.selectors';
export class EditEntitlementModalController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            uiNotificationService
        });
    }

    $onInit() {
        this.isLoading = false;
        this.disableConfirm = true;
        this.entitlementsToUpdate = [];
        this.selectedEntitlement = undefined;
        this.adjustRemainingBalanceBy = [];

        const controllerActions = {
            entitlementBalanceUpdate,
            retrieveEntitlementsToUpdate,
            retrieveServiceDetails,
            setSelectedEntitlement
        };

        const mapStateToProps = (store) => {
            return {
                currencyCode: ConvergentBillerCurrencyCodeSelector(store),
                isLoadingEntitlementBalances: IsLoadingEntitlementBalancesSelector(store),
                serviceEntitlementItems: ServiceEntitlementsSelector(store),
                sharedEntitlementItems: SharedEntitlementsSelector(store),
                unitOfMeasures: UnitOfMeasuresSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.retrieveEntitlementBalancesForEdit();
    }

    updateEntitlement() {
        this.isLoading = true;
        const updateEntitlementRequest = {
            Amount: this.adjustRemainingBalanceBy[0].value,
            BalanceUnitCode:  this.adjustRemainingBalanceBy[0].type,
            EntitlementIdentifier: this.selectedEntitlement.EntitlementIdentifier,
            ExpirationDate: this.selectedEntitlement.ExpirationDate,
            Reason: this.reasonDescription
        };
        this.actions.entitlementBalanceUpdate(updateEntitlementRequest, this.currentCustomerId)
            .then(() => {
                const serviceIdentifiers = [{
                    Value: this.serviceIdentifier.Value
                }];
                this.isLoading = false;
                this.uiNotificationService.success(
                    i18n.translate(this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.ENITITLEMENT_UPDATE_SUCCESS));
                this.actions.retrieveServiceDetails(this.currentCustomerId, serviceIdentifiers);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.cancelEditEntitlementModal();
            });
    }

    handleFormChanged() {
        this.disableConfirm = !(this.adjustRemainingBalanceBy && this.reasonDescription);
    }

    cancelEditEntitlementModal() {
        this.actions.setSelectedEntitlement({});
        this.onClose();
    }

    onEntitlementChange() {
        this.editEntitlementForm.$setPristine();
        if (this.selectedEntitlement) {
            this.adjustRemainingBalanceBy = [{
                type: +this.selectedEntitlement.BalanceUnitCode,
                value: null
            }];
            this.reasonDescription = '';
            this.disableConfirm = true;
        }
        this.actions.setSelectedEntitlement(this.selectedEntitlement);
        this.uomDropdownOptions = clone(this.state.unitOfMeasures);
    }

    retrieveEntitlementBalancesForEdit() {
        this.actions.retrieveEntitlementsToUpdate(
            this.currentCustomerId,
            this.serviceIdentifier
        ).then(() => {
            const entitlementsToUpdate = this.isShared ? this.state.sharedEntitlementItems : this.state.serviceEntitlementItems;
            this.dropdownEntitlementItems = entitlementsToUpdate.filter((entitlement) => {
                return entitlement.BalanceUnitCode !== UNIT_OF_MEASURES.CURRENCY && entitlement.OwningSubscriberId === convertStringToNumber(this.currentCustomerId);
            });
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: EditEntitlementModalController,
    controllerAs: 'EditEntitlementModalController',
    template: require('./edit.entitlement.html'),
    bindings: {
        currentCustomerId: '<',
        isShared: '<',
        onClose: '&',
        serviceIdentifier: '<'
    }
};