export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.configureAutopay', {
        url: '/configureautopay/:accountNumber',
        params: {
            accountNumber: ''
        },
        template: '<customer-configure-autopay></customer-configure-autopay>',
        params: {
            accountNumber: {
                value: null,
                squash: true
            }
        }
    });
}
