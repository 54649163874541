import CustomerCareLocaleKeys from '../../../../../locales/keys';

class ExternalBillDetailController {

    constructor() {
        this._isInitialized = false;
        this.viewModel = {
            EDIT_MODE_ACC_NO_LENGTH: 30,
            localeKeys: CustomerCareLocaleKeys,
            detailData: {}
        };
    }

    //--------------------------------------------------------------------------
    //
    //  Lifecycle Methods
    //
    //--------------------------------------------------------------------------

    $onInit() {
        this._isInitialized = true;
        this.updateViewModel();
        this.updateAccountNumberLength();
    }

    $onChanges() {
        this.updateViewModel();
        this.updateAccountNumberLength();
    }

    //--------------------------------------------------------------------------
    //
    //  View Model methods
    //
    //--------------------------------------------------------------------------

    updateViewModel() {
        if (!this._isInitialized) {
            return;
        }

        this.viewModel.detailData = this.detailData;
        if (this.detailData) {
            this.viewModel.expiration = {
                fullYear: this.detailData.ExpirationYear,
                month: this.detailData.ExpirationMonth
            };
        } else {
            this.viewModel.expiration = undefined;
        }
    }

    updateAccountNumberLength() {
        if (this.viewModel.detailData && this.viewModel.detailData.AccountNumber) {
            const accountNumberLength = this.viewModel.detailData.AccountNumber.length;
            this.viewModel.detailData.AccountNumber = (accountNumberLength > 30) ?
                this.viewModel.detailData.AccountNumber.substring(accountNumberLength -
                    this.viewModel.EDIT_MODE_ACC_NO_LENGTH, accountNumberLength) :
                this.viewModel.detailData.AccountNumber;
        }
    }
}

export const ExternalBillDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: ExternalBillDetailController,
    template: require('./external.bill.detail.html')
};
