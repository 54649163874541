import {i18n} from 'invision-core';
import CustomerLocaleKeys from '../../../locales/keys';

export const transactionTypeNameIdentifier = (detail) => {
    //Typo in the property name for sva transaction
    if (detail.LoyaltyCard) {
        return i18n.translate(CustomerLocaleKeys.TRANSACTION_DETAILS.SVA_DETAIL_LABELS.LOYALTY_CARD_TRANSACTION);
    }

    return detail.TypeName;
};
