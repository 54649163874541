import {__, path, values} from 'ramda';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    EwalletErrorSelector,
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
    SelectedPaymentMethodSelector,
    UserHasEWalletAdminAccess
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {CurrentMemberHasCreatePaymentInstrumentPermissionsSelector} from './../../../../../reducers/selectors/customer.household.selectors';
import {
    CurrencySelector,
    CurrentRemovalSelector,
    CurrentSubscriptionIdSelector,
    RemoveSubscriptionNameSelector,
    CurrentSubscriptionSelector,
    IsFetchingDataSelector,
    IsRemovingSubscriptionSelector,
    RemoveChildSubscriptionSelector,
    SubscriptionErrorSelector,
    UserCanIssueCreditToAlternatePaymentInstrument
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    SubscriptionWithDetailedItemsSelector
} from '../../../../../reducers/selectors/customer.subscriptions.products.selectors';
import {
    calculateRemoveOrderQuote,
    removeSubscription,
    retrieveSubscription,
    setIncludedFilter
} from '../../../../../reducers/actions/customer.subscriptions.actions';
import {
    retrieveWallet,
    selectPaymentMethod
} from '../../../../../reducers/actions/customer.ewallet.actions';

import * as SubscriptionConstants from '../../../../shared/constants/subscriptions.constants';
import {SUBSCRIPTION_LIST_ROUTE} from '../../../../../reducers/constants/dashboard.constants';

class RemoveSubscriptionController {

    constructor($ngRedux, $state, $q, $filter, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $q,
            $filter,
            uiNotificationService,
            CustomerCareLocaleKeys,
        });

        this.metadataCodes = [
            MetadataConstants.codes.SubscriptionTerminationReason,
            MetadataConstants.codes.CreditReasons,
            MetadataConstants.codes.WaiveFeeReason
        ];

        this.confirmationPopup = {
            onRegisterApi: ({api}) => {
                this.confirmationPopup.api = api;
            }
        };

        this.removal = {
            sendNotification: true,
            terminationReason: null,
            creditReason: null,
            waiveFeeReason: null,
            itemCredits: []
        };

        this.terminationFeeTooltip = require('./remove.subscription.tooltip.html');

        // Necessary for child component callback
        this.onConfirmation = this.onConfirmation.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            const selectOptions = MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(__, store);
            return {
                canIssueCreditToAlternatePaymentMethod: UserCanIssueCreditToAlternatePaymentInstrument(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currency: CurrencySelector(store),
                currentRemoval: CurrentRemovalSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                removeSubscription: RemoveChildSubscriptionSelector(store),
                currentSubscriptionItems: SubscriptionWithDetailedItemsSelector(store),
                eWalletError: EwalletErrorSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                isRemovingSubscription: IsRemovingSubscriptionSelector(store),
                lastRoute: SessionSelectors.LastRouteSelector(store),
                subscriptionId: CurrentSubscriptionIdSelector(store),
                subscriptionName: RemoveSubscriptionNameSelector(store),
                subscriptionError: SubscriptionErrorSelector(store),
                codeTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(__, store),
                creditReasons: selectOptions(MetadataConstants.codes.CreditReasons),
                paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                selectedPaymentMethod: SelectedPaymentMethodSelector(store),
                subscriptionTerminationReasons: selectOptions(MetadataConstants.codes.SubscriptionTerminationReason),
                userHasEWalletAdminAccess: UserHasEWalletAdminAccess(store),
                waiveFeeReasons: selectOptions(MetadataConstants.codes.WaiveFeeReason)
            };
        };

        const controllerActions = {
            calculateRemoveOrderQuote,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            removeSubscription,
            retrieveSubscription,
            retrieveWallet,
            setIncludedFilter,
            selectPaymentMethod
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.metadataCodes.forEach((code) => {
            if (!this.state.codeTypeLoaded(code)) {
                this.actions.fetchCodeType(code);
            }
        });

        this.$q.all([
            this.actions.retrieveSubscription(this.state.currentCustomerId, {
                Id: this.state.subscriptionId
            }),
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            })
        ]).then(() => {
            const removalQuoteRequest = {
                subscriptionId: this.state.subscriptionId,
                immediateRemove: this.state.currentSubscription.Policy.ImmediateRemoveAllowed
            };

            if (this.state.removeSubscription) {
                removalQuoteRequest.removeItemIds = [
                    this.state.removeSubscription.Id
                ];
            }

            return this.actions.calculateRemoveOrderQuote(this.state.currentCustomerId, removalQuoteRequest);
        }).then(() => {
            return this.actions.selectPaymentMethod(this.state.currentSubscription.PaymentInstrument);
        }).catch(() => {
            const message = path(['eWalletError', 'message'], this.state) || path(['subscriptionError', 'message'], this.state);
            if (message) {
                this.uiNotificationService.transientError(message);
            }
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    removeSubscription() {
        const creditItems = [];
        const removalKeys = Object.keys(this.removal.itemCredits);
        let itemCreditTotal = 0;

        removalKeys.forEach((itemKey) => {
            const creditAmount = this.removal.itemCredits[itemKey];
            if (creditAmount > 0) {
                itemCreditTotal += creditAmount;
                creditItems.push({
                    amount: creditAmount,
                    subscriptionItemId: itemKey
                });
            }
        });

        let hasCredit = false;
        if (this.removal.applyTotalCredit) {
            if (this.appliedCredit > 0) {
                hasCredit = true;
            }
        } else {
            if (itemCreditTotal > 0) {
                hasCredit = true;
            }
        }

        const removeSubscriptionRequest = {
            sendNotification: this.removal.sendNotification,
            removeReasonCode: this.removal.terminationReason,
            waiveTerminationFee: this.removal.waiveTerminationFee,
            waiveTerminationFeeReason: this.removal.waiveFeeReason,
            creditAmount: this.removal.applyTotalCredit ? this.appliedCredit : 0,
            creditItems: !this.removal.applyTotalCredit ? creditItems : null,
            creditReason: this.removal.creditReason
        };

        if (hasCredit && (!this.state.selectedPaymentMethod || this.state.selectedPaymentMethod.Status !== SubscriptionConstants.PAYMENT_METHOD_STATUSES.ACTIVE)) {
            this.uiNotificationService.error(i18n.translate(CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.PAYMENT_REQUIRED), null);
            return;
        }

        if (this.state.selectedPaymentMethod && this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.ACTIVE) {
            removeSubscriptionRequest.paymentInstrumentIds = this.state.selectedPaymentMethod ? [this.state.selectedPaymentMethod.Id] : null;
        }

        if (hasCredit) {
            removeSubscriptionRequest.creditPaymentInstrumentId = this.state.selectedPaymentMethod ? this.state.selectedPaymentMethod.Id : null;
        }

        if (this.state.removeSubscription) {
            removeSubscriptionRequest.removeItemIds = [
                this.state.removeSubscription.Id
            ];
        } else {
            removeSubscriptionRequest.subscriptionId = this.state.subscriptionId;
        }

        this.actions.removeSubscription(this.state.currentCustomerId, removeSubscriptionRequest)
            .then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.SUCCESS));
                this.removeSubscriptionForm.$setPristine();
                this.actions.setIncludedFilter(true);

                this.$state.go(SUBSCRIPTION_LIST_ROUTE);
            })
            .catch(() => {
                if (this.state.subscriptionError) {
                    this.uiNotificationService.transientError(this.state.subscriptionError.message);
                }
            });
    }

    isCurrentPaymentMethodRemoved() {
        if (!this.state.selectedPaymentMethod) {
            return false;
        }
        return this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.REMOVED;
    }

    confirmRemoval() {
        if (this.removeSubscriptionForm.$valid) {
            this.confirmationPopup.api.open();
        }
    }

    onConfirmation(confirmed) {
        if (confirmed) {
            this.removeSubscription();
        }

        this.confirmationPopup.api.close();
    }

    get appliedCredit() {
        return this.removal.applyTotalCredit
            ? this.state.currentRemoval.totalRemainingAmount
            : values(this.removal.itemCredits).reduce((total, value) => {
                return total += value || 0;
            }, 0);
    }

    get appliedTerminationFee() {
        return this.removal.waiveTerminationFee || !this.state.currentRemoval.quote
            ? 0
            : this.state.currentRemoval.quote.totals.totalAmount;
    }

    get canCreatePaymentInstruments() {
        return this.state.currentMemberCanCreatePaymentInstrument && this.state.userHasEWalletAdminAccess;
    }

    get isLoading() {
        return this.state.isFetchingData || this.state.isFetchingEwalletData || this.state.isRemovingSubscription || this.metadataCodes.some((code) => {
            return (!this.state.codeTypeLoaded(code));
        });
    }

    get formattedRemainingAmount() {
        return this.$filter('invCurrency')(this.state.currentRemoval.totalRemainingAmount, this.state.currency);
    }
}


export default {
    bindings: {},
    template: require('./remove.subscription.html'),
    controller: RemoveSubscriptionController,
    controllerAs: 'removeSubscriptionController'
};
