import CustomerCareLocaleKeys from '../../../../../locales/keys';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../../../../customer/addresses/addresses.constants';

class AddressWrapupController {
    constructor() {
        Object.assign(this, {
            CoreLocaleKeys,
            countryName: '',
            CustomerCareLocaleKeys,
            formMaxLengthValidation: ADDRESS_FORM_FIELD_MAX_LENGTH
        });
    }

    $onChanges(changesObj) {
        if ((changesObj.addressWrapupModel &&
            changesObj.addressWrapupModel.currentValue !== changesObj.addressWrapupModel.previousValue) ||
            (changesObj.addressCountries &&
                changesObj.addressCountries.currentValue !== changesObj.addressCountries.previousValue)) {
            this.setCountryName();
        }
    }

    onChange() {
        this.onModelChange()(this.addressWrapupModel);
    }

    setCountryName() {
        if (this.isCountryFieldReadonly && this.addressWrapupModel && this.addressCountries) {
            const selectedCountry = this.addressCountries.find((country) => {
                return country.value === this.addressWrapupModel.country;
            });
            this.countryName = selectedCountry ? selectedCountry.label : this.addressWrapupModel.country;
        }
    }
}

export default {
    bindings: {
        addressCountries: '<',
        addressStates: '<',
        addressWrapupModel: '<',
        formMaxLengthValidation: '<',
        isAutocompleteOff: '<?',
        isCountryFieldReadonly: '<?',
        isDisabled: '<?',
        isRequired: '<?',
        isShipToFieldVisible: '<?',
        onModelChange: '&',
        initialFormValues: '<?',
        postalCodeSettings: '<',
        requiredFields: '<'
    },
    template: require('./address.wrapup.form.html'),
    controller: AddressWrapupController
};
