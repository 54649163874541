import localeKeys from '../../../../../locales/keys';

class TransactionDetailsExternalBillerController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.external.biller.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsExternalBillerController
};
