import CustomerLocaleKeys from '../../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {POLICY_COUNTERS_EDIT_ACCESS} from '../../../../../../security.attributes';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {CurrentCustomerIdSelector} from '../../../../../../reducers/selectors/customer.selectors';

class PolicyCountersController {
    constructor($ngRedux, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerLocaleKeys,
            handleMoreOptionsSelected: this.handleMoreOptionsSelected.bind(this),
            onCollapse: this.onCollapse.bind(this)
        });
    }

    $onInit() {
        const controllerActions = {};
        const mapStateToProps = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        this.moreMenuItems = [{
            id: CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_POLICY_COUNTER_MODAL.TITLE,
            title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_POLICY_COUNTER_MODAL.TITLE)
        }];
        this.editPolicyCounterModalConfig = {
            onRegisterApi: (event) => {
                this.handleEditPolicyCounterModalConfig = event.api;
            }
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    hasPolicyCountersEditAccess() {
        return hasAccess(this.state.userSecurityAttributes, POLICY_COUNTERS_EDIT_ACCESS);
    }

    handleMoreOptionsSelected({item}) {
        switch (item.id) {
            case CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_WIDGET.EDIT_POLICY_COUNTER_MODAL.TITLE:
                this.openEditPolicyCounterModal();
                break;
            default:
                break;
        }
    }

    openEditPolicyCounterModal() {
        this.isEditPolicyCounterModalOpen = true;
        this.$timeout(() => {
            this.handleEditPolicyCounterModalConfig.open();
        });
    }

    closeEditPolicyCounterModal() {
        this.isEditPolicyCounterModalOpen = false;
        this.handleEditPolicyCounterModalConfig.close();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onCollapse() {
        return () => {};
    }
}

export default {
    bindings: {
        onClose: '&',
        policyCounters:'<?',
        serviceIdentifier: '<'
    },
    controller: PolicyCountersController,
    controllerAs: 'PolicyCountersController',
    template: require('./policy.counters.template.html')
};