import {stateGo} from 'redux-ui-router';
import __ from 'ramda/src/__';
import {
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    SelectedCustomerActiveProductsSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    CombinedEntitlementTypesSelector,
    ConvergentBillerAccountDetailsSelector,
    IsFetchingAccountDetailsSelector,
    IsFetchingSubscriberSummarySelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import localeKeys from '../../../../../locales/keys';
import {
    HasSuspendResumePermissionSelector,
    ResumeServiceReasonCodesSelector,
    SuspendServiceReasonCodesSelector
} from '../../../../../reducers/selectors/services.selectors';

class ServicesController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            localeKeys,
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.RegularExpression,
            CODES.ReasonCodes,
            CODES.ServiceAttribute,
            CODES.UnitsOfMeasure
        ];
        const controllerActions = {
            fetchCodeTypesThunk,
            stateGo
        };
        const mapStateToProps = (store) => {
            return {
                accountDetails: ConvergentBillerAccountDetailsSelector(store),
                combinedEntitlementTypes: CombinedEntitlementTypesSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                customerActiveProductsSelector: SelectedCustomerActiveProductsSelector(store),
                hasSuspendResumeAccess: HasSuspendResumePermissionSelector(store),
                isCodeTableLoaded: MetadataCodeLoadedSelector(__, store),
                isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
                isFetchingAccountSummary: IsFetchingSubscriberSummarySelector(store),
                isServiceReasonCodeLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                isUnitsOfMeasureLoaded: MetadataCodeLoadedSelector(CODES.UnitsOfMeasure, store),
                resumeServiceReasonCodes: ResumeServiceReasonCodesSelector(store),
                suspendServiceReasonCodes: SuspendServiceReasonCodesSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((code) => {
            if (!this.state.isCodeTableLoaded(code)) {
                this.actions.fetchCodeTypesThunk(code).catch(this.onApiError);
            }
        });
    }
    onApiError(error) {
        this.uiNotificationService.transientError(error.translatedMessage);
    }
    $onDestroy() {
        this.disconnectRedux();
    }

    onRefreshServices() {
        this.refreshServices && this.refreshServices();
    }
}

export default {
    template: require('./services.deprecated.html'),
    bindings: {
        hasAdminAccess: '<',
        refreshServices: '&?'
    },
    controller: ServicesController,
    controllerAs: 'Services'
};
