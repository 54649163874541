export const OFFERING_OPTION_STATUSES = {
    ACTIVE: 1,
    PENDING_ACTIVE: 3,
    PENDING_REMOVED: 4,
    PENDING_SUSPENSION: 8,
    REMOVED: 2,
    SUSPENDED: 6
};

export const INVENTORY_LOOKUP_TYPES = {
    TELEPHONE_NUMBER_LOCATION: 1
};
