export const determineClassForComponent = (showHideFullCartColumns = {}) => {
    let assignedClass = '';
    if (showHideFullCartColumns.showDueToday &&
        showHideFullCartColumns.showDueRecurring &&
        showHideFullCartColumns.showDueNextInvoice) {
        assignedClass = 'c-fullCart-column--label';
    } else if ((showHideFullCartColumns.showDueToday &&
                showHideFullCartColumns.showDueRecurring) ||
            (showHideFullCartColumns.showDueToday &&
                showHideFullCartColumns.showDueNextInvoice) ||
            (showHideFullCartColumns.showDueNextInvoice &&
            showHideFullCartColumns.showDueRecurring) ||
            (showHideFullCartColumns.showDueToday &&
                showHideFullCartColumns.showDueOnFirstUse)) {
        assignedClass = 'c-fullCart-column--labelThreeColumnsLayout';
    }  else if (!showHideFullCartColumns.showDueToday &&
            !showHideFullCartColumns.showDueRecurring &&
        !showHideFullCartColumns.showDueNextInvoice &&
            !showHideFullCartColumns.showDueOnFirstUse) {
        assignedClass = 'c-fullCart-column--labelNoColumnsLayout';
    } else if ((!showHideFullCartColumns.showDueToday &&
            !showHideFullCartColumns.showDueRecurring &&
            showHideFullCartColumns.showDueNextInvoice) ||
                (showHideFullCartColumns.showDueToday &&
                    !showHideFullCartColumns.showDueRecurring &&
                    !showHideFullCartColumns.showDueNextInvoice) ||
                (!showHideFullCartColumns.showDueToday &&
                    showHideFullCartColumns.showDueRecurring &&
                    !showHideFullCartColumns.showDueNextInvoice)) {
        assignedClass = 'c-fullCart-column--labelOneColumnsLayout';
    } else {
        assignedClass = 'c-fullCart-column--label';
    }
    return assignedClass;
};