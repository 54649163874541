import LocaleKeys from '../../locales/keys';

class AddressSwitcherController {
    constructor($timeout) {
        this.$timeout = $timeout;

        this.showChangeAddressModal = false;

        this.closeChangeAddress = this.closeChangeAddress.bind(this);
        this.onChangeAddress = this.onChangeAddress.bind(this);
        this.onAddedAddress = this.onAddedAddress.bind(this);
        this.onUpdatedAddress = this.onUpdatedAddress.bind(this);
    }

    $onInit() {
        this.LocaleKeys = LocaleKeys;
        this.changeAddressDialogConfig = {
            onRegisterApi: (event) => {
                this.changeAddressDialogApi = event.api;
            }
        };
        this.createAddressPopupConfig = {
            onRegisterApi: (event) => {
                this.createAddressPopupApi = event.api;
            }
        };
        this.updateAddressPopupConfig = {
            onRegisterApi: (event) => {
                this.updateAddressPopupApi = event.api;
            }
        };
    }

    hasMultipleAddresses() {
        return this.addresses && this.addresses.length > 1;
    }

    openChangeAddress() {
        this.showChangeAddressModal = true;

        this.$timeout(this.changeAddressDialogApi.open);
    }

    closeChangeAddress() {
        this.changeAddressDialogApi.close();
        this.showChangeAddressModal = false;
    }

    openNewAddressPopup() {
        this.$timeout(this.createAddressPopupApi.open);
    }

    openUpdateAddressPopup() {
        this.$timeout(this.updateAddressPopupApi.open);
    }

    onChangeAddress(selectedAddress) {
        this.onChange()(selectedAddress);
        this.closeChangeAddress();
    }

    onAddedAddress(addedAddress) {
        this.onAdded()(addedAddress);
    }

    onUpdatedAddress(updatedAddress) {
        this.onUpdated()(updatedAddress);
    }

    showChange() {
        return !this.isReadOnly && this.hasMultipleAddresses();
    }

    showEdit() {
        return !this.isReadOnly && this.currentAddress && this.currentAddress.Id;
    }
}

export default {
    template: require('./address.switcher.html'),
    bindings: {
        addresses: '<',
        currentAddress: '<',
        currentCustomerId: '<',
        isReadOnly: '<',
        onAdded: '&',
        onChange: '&',
        onUpdated: '&',
        shipToNameRequired: '<',
        showName: '<'
    },
    controller: AddressSwitcherController
};
