import {pathOr} from 'ramda';
import {i18n} from 'invision-core';
import LocaleKeys from '../../../locales/keys';

class RedemptionCodeDetailsGeneralController {

    constructor($timeout) {
        this.$timeout = $timeout;
        this.customerCareLocaleKeys = LocaleKeys;
        this.couponStatusTypes = {
            //inventory status : api value
            1: '1', //active,
            2: '2', //removed
            3: '4', //pending,
            4: '3' //suspended
        };
        this.discountTypes = {
            1: i18n.translate(this.customerCareLocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.LABELS.DISCOUNT_TYPES.PERCENTAGE_OFF),
            3: i18n.translate(this.customerCareLocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.LABELS.DISCOUNT_TYPES.AMOUNT_OFF)
        };
        this.showModifyExpirationDateModal = false;
    }

    $onInit() {
        this.modifyExpirationDateDialogConfig = {
            onRegisterApi: (event) => {
                this.modifyExpirationDateDialogApi = event.api;
            }
        };
    }

    openModifyExpirationDateModal() {
        this.showModifyExpirationDateModal = true;
        this.$timeout(this.modifyExpirationDateDialogApi.open);
    }

    closeModifyExpirationDateModal() {
        this.showModifyExpirationDateModal = false;
        this.modifyExpirationDateDialogApi.close();
    }

    expirationDateSubmitted(date) {
        this.updateExpirationDate()(date);
        this.closeModifyExpirationDateModal();
    }

    $onChanges(changesObj) {
        if (changesObj.redemptionDetails) {
            const couponStatusType = pathOr('', ['Coupon', 'Status'], changesObj.redemptionDetails.currentValue);
            this.couponStatusLabel = this.couponStatusTypes[couponStatusType];

            const discountType = pathOr('', ['Discount', 'Type'], changesObj.redemptionDetails.currentValue);
            this.discountTypeLabel = this.discountTypes[discountType];
            this.isPercentDiscount = discountType === 1;
            this.percentDiscountAmount =`${(changesObj.redemptionDetails.currentValue.Discount.Amount * 100)}${'%'}`;
            this.isRedeemable = changesObj.redemptionDetails.currentValue.RedemptionCode.IsRedeemable ?
                i18n.translate(this.customerCareLocaleKeys.YES) :
                i18n.translate(this.customerCareLocaleKeys.NO);
        }
    }

    $onDestroy() {
        this.closeModifyExpirationDateModal();
    }
}

export default {
    bindings: {
        isGlobal: '<',
        redemptionDetails: '<',
        updateExpirationDate: '&'
    },
    template: require('./redemption.code.details.general.html'),
    controller: RedemptionCodeDetailsGeneralController,
};
