import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {
    ContractsSearchCriteriaSelector,
    ContractsSearchResultsViewModelSelector,
    IsFetchingContractsSelector
} from '../../../../../reducers/selectors/contracts.list.selectors';
import {IsFetchingActiveContractSelector} from '../../../../../reducers/selectors/offering.order.selectors';
import {
    resetSearchCriteria,
    setSearchCriteriaAndSearchContracts
} from '../../../../../reducers/actions/contracts.list.actions';

const mapStateToTarget = (store) => {
    return {
        contracts: ContractsSearchResultsViewModelSelector(store),
        isFetchingActiveContract: IsFetchingActiveContractSelector(store),
        isFetchingContracts: IsFetchingContractsSelector(store),
        searchCriteria: ContractsSearchCriteriaSelector(store),
    };
};

const controllerActions = {
    resetSearchCriteria,
    setSearchCriteriaAndSearchContracts
};

class SelectContractPopupController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.disconnectRedux = null;
    }
    $onInit() {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.coreLocaleKeys = CoreLocaleKeys;
        this.selectedContractId = this.contractId || null;
        this.formErrors = [];
        this.pageNumber = 1;
        this.searchText = '';
        this.handleClose = () => {
            this.onClose()();
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isFetchingContracts) {
            this.actions.resetSearchCriteria();
            this.searchCriteria = this.cloneContractSearchCriteria();
            this.actions.setSearchCriteriaAndSearchContracts(this.searchCriteria);
        }

        this.search = () => {
            this.searchCriteria.PageNumber = 1;
            this.searchCriteria.SearchFor = this.searchText;
            this.actions.setSearchCriteriaAndSearchContracts(this.searchCriteria);
        };

        this.onUpdateSelectedContract = () => {
            this.formErrors = null;
            this.onSuccess()(this.state.contracts.find((x) => {
                return x.Id === this.selectedContractId;
            }));
        };

        this.pageSelected = (pageNumber) => {
            this.pageNumber = pageNumber;
            this.onSubmitSearch();
        };
    }

    cloneContractSearchCriteria() {
        const searchCriteria = this.state.searchCriteria;
        searchCriteria.CurrencyCode = this.currencyCode;
        searchCriteria.SubtenantIds = this.subTenants && !!this.subTenants.Id ?
            [parseInt(this.subTenants.Id.Value, 10)] : this.subTenants && !this.subTenants.Id ?
                this.subTenants.map((subTenant) => {
                    return parseInt(subTenant.Value, 10);
                }) : [];

        return searchCriteria;
    }

    selectContract(contract) {
        this.selectedContractId = contract.Id;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./select.contract.popup.html'),
    controller: SelectContractPopupController,
    bindings: {
        contractId: '<',
        currencyCode: '<?',
        onClose: '&',
        onSuccess: '&',
        subTenants: '<?'
    }
};
