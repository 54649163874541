import CustomerCareKeys from '../../../../../../locales/keys';
import {determineClassForComponent} from '../cart.helper';
class FullCartChargeComponent {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            assignedClass: null
        });
    }
    $onInit() {
        this.assignedClass = determineClassForComponent(this.showHideFullCartColumns);
    }
}

export default {
    bindings: {
        currency: '<',
        name: '<',
        dueOnFirstUse: '<',
        dueOnActivation: '<',
        dueRecurring: '<',
        dueToday: '<',
        dueNextInvoice: '<',
        isDiscount: '<',
        periodStartDate: '<?',
        periodEndDate: '<?',
        showHideFullCartColumns: '<'
    },
    template: require('./full.cart.charge.template.html'),
    controller: FullCartChargeComponent
};
