import CustomerCareLocaleKeys from '../../../../locales/keys';

import {getColumnDefinitions} from './promise.to.pay.schedule.table.constants';

class PromiseToPaySchedulePopupController {
    constructor($timeout) {
        this.$timeout = $timeout;
    }
    $onInit() {
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.shouldShow = false;
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            this.popupApi = Object.assign({}, api, {
                open: () => {
                    this.shouldShow = true;
                    this.$timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };
        this.tableColDefs = getColumnDefinitions();
    }
    closePopup() {
        this.shouldShow = false;
        this.popupApi.close();
    }
}

export default {
    template: require('./promise.to.pay.schedule.popup.html'),
    bindings: {
        config: '<',
        promiseToPaySchedule: '<'
    },
    controllerAs: 'PromiseToPaySchedulePopup',
    controller: PromiseToPaySchedulePopupController
};
