import append from 'ramda/src/append';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import reject from 'ramda/src/reject';
import {stateGo} from 'redux-ui-router';
import Immutable from 'seamless-immutable';
import {
    AngularFormControllerErrorAdapter,
    LogHelper,
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors,
    UnsavedChangesPromptActions
} from 'invision-core';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {CASE_STATUS, CASE_CATEGORIES} from '../cases/list/case.constants';
import {
    createCase,
    fetchCaseTypes,
    removeCaseSelectedCategoryId,
    retrieveCase,
    setCaseEditMode,
    updateCase,
    updateCaseInventoryItem,
    updateCaseSelectedCategoryId
} from '../../../reducers/actions/customer.cases.actions';
import {
    transformCaseForService,
    shouldHaveResolutionText
} from './transform.case.helper';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    CaseTypesSelector,
    CaseFormIsInvalidSelector,
    CasesErrorSelector,
    CustomerInvoicesSelector,
    CustomFieldsFromCurrentCategorySelector,
    FormattedCaseStatusCodeSelector,
    FormattedInvoicesSelector,
    IsCreatingCaseSelector,
    IsUpdatingCaseSelector
} from '../../../reducers/selectors/customer.cases.selectors';
import {
    CaseInventoryOptionsSelector,
    SelectedCaseInventorySelector
} from '../../../reducers/selectors/customer.inventory.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    CaseCategoryOptionsSelector,
    CasePriorityOptionsSelector,
    CaseSeverityOptionsSelector,
    DisputeInvoiceCaseTypesOptionsSelector
} from './case.modal.selectors';
import {CASE_ACCESS} from '../../../security.attributes';
import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import {CASE_DETAILS_STATE_NAME} from '../cases/detail/detail.config';

function getLabelByValue(predicate, collection) {
    const foundItem = find(propEq(predicate, 'value'))(collection);
    return foundItem ? foundItem.text : '';
}
class CaseModalController {
    constructor($ngRedux, $timeout, uiNotificationService, unsavedChangesPrompt, $locale) {
        Object.assign(this, {
            $locale,
            $ngRedux,
            $timeout,
            editCase: {},
            formFieldLocaleKeyMapping: {
                priority: CustomerCareLocaleKeys.PRIORITY,
                description: CustomerCareLocaleKeys.DESCRIPTION,
                category: CustomerCareLocaleKeys.CASE_TYPE,
                caseFormStatus: CustomerCareLocaleKeys.STATUS,
                // NOTE: these are only necessary if the case category is 'INVOICE_DISPUTE'
                disputed_amount: CustomerCareLocaleKeys.DISPUTED_AMOUNT,
                invoice_number: CustomerCareLocaleKeys.INVOICE_NUMBER,
                resolutionText: CustomerCareLocaleKeys.RESOLUTION
            },
            invoiceNumber: undefined,
            uiNotificationService,
            unsavedChangesPrompt,
            unsavedChangesPromptPopupApi: null,
            validateNumbers
        });

        this.formFieldLocaleKeyMapping ={
            category: CustomerCareLocaleKeys.CASE_TYPE,
            summary: CustomerCareLocaleKeys.CASE_MODAL.SUMMARY,
            caseFormStatus: CustomerCareLocaleKeys.STATUS,
            resolutionText: CustomerCareLocaleKeys.RESOLUTION,
            disputed_amount: CustomerCareLocaleKeys.DISPUTED_AMOUNT,
            invoice_number: CustomerCareLocaleKeys.INVOICE_NUMBER,
            approved_amount: CustomerCareLocaleKeys.APPROVED_AMOUNT,
            priority: CustomerCareLocaleKeys.PRIORITY,
            severity: CustomerCareLocaleKeys.CASE_MODAL.SEVERITY,
            description: CustomerCareLocaleKeys.DESCRIPTION
        };
        this.formFieldConstraintParameters = {
            maxlength: {
                summary: 100,
                description: 8000
            }
        };
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                caseTypes: CaseTypesSelector(store),
                caseCategoryCodes: CaseCategoryOptionsSelector(store),
                customFields: CustomFieldsFromCurrentCategorySelector(store),
                disputeInvoiceCaseTypesOptionsSelector: DisputeInvoiceCaseTypesOptionsSelector(store),
                priorities: CasePriorityOptionsSelector(store),
                prioritiesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CasePriority, store),
                severities: CaseSeverityOptionsSelector(store),
                severitiesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CaseSeverity, store),
                formattedCaseStatusCodes: FormattedCaseStatusCodeSelector(store),
                regularExpressionValidationsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RegularExpression, store),
                formattedInvoices: FormattedInvoicesSelector(store),
                invoices: CustomerInvoicesSelector(store),
                isCreatingCase: IsCreatingCaseSelector(store),
                isInvalid: CaseFormIsInvalidSelector(store),
                isUpdatingCase: IsUpdatingCaseSelector(store),
                lastAttemptError: CasesErrorSelector(store),
                inventory: CaseInventoryOptionsSelector(store),
                selectedInventory: SelectedCaseInventorySelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            createCase,
            fetchCaseTypes,
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            fetchCodeTypesThunk: MetadataActions.codes.fetchCodeTypesThunk,
            registerUnsavedChanges: UnsavedChangesPromptActions.registerUnsavedChanges,
            removeCaseSelectedCategoryId,
            retrieveCase,
            setCaseEditMode,
            stateGo,
            updateCase,
            updateCaseSelectedCategoryId,
            updateCaseInventoryItem,
            unregisterUnsavedChanges: UnsavedChangesPromptActions.unregisterUnsavedChanges
        };

        this.showForm = false;
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.caseCategories = CASE_CATEGORIES;

        this.MIN_INVOICE_DISPUTED_AMOUNT = 1 / Math.pow(10, this.$locale.NUMBER_FORMATS.PATTERNS[1].maxFrac); //Minimal amount value depending on the currency (0.01 in the case of dollars)

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.deregisterUnsavedChanges = () => {
            this.actions.unregisterUnsavedChanges('caseModal');
        };

        this.closePopup = () => {
            if (this.isCreatingCase || this.state.isUpdatingCase) {
                // app is going to crash; log a User-friendly message for the developer
                LogHelper.debug('closing popup while awaiting async operation');
            }

            this.deregisterUnsavedChanges();
            this.popupApi.close();
            this.actions.removeCaseSelectedCategoryId();
            this.showForm = false;
        };
        this.handleClose = () => {
            if (this.unsavedChangesPrompt.hasUnsavedChanges()) {
                this.unsavedChangesPromptPopupApi.open();
            } else {
                this.closePopup();
            }
        };
        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.unsavedChangesPromptPopupApi = api;
            }
        };
        this.handleResultUnsavedChangesPromptPopup = (confirmed) => {
            if (confirmed) {
                this.closePopup();
            }

            this.unsavedChangesPromptPopupApi.close();
        };

        this.handleCloseUnsavedChangesPromptPopup = () => {
            this.unsavedChangesPromptPopupApi.close();
        };

        this.initializeData();
        this.initializePopup();
    }
    $onDestroy() {
        this.disconnectRedux();
    }
    initializeData() {
        if (!this.state.regularExpressionValidationsLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.RegularExpression);
        }
        if (!this.state.caseTypes.length && PermissionService.hasAccess(this.state.userSecurityAttributes, CASE_ACCESS)) {
            this.actions.fetchCaseTypes(this.state.currentCustomerId);
        }
        if (!this.state.prioritiesLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.CasePriority);
        }
        if (!this.state.severitiesLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.CaseSeverity);
        }
    }
    initializePopup() {
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi  = ({api}) => {
            this.popupApi = Object.assign({}, api, {
                open: (isCloseCase, invoiceNumber) => {
                    this.resetForm();
                    this.showForm = true;
                    this.isCloseCase = isCloseCase;
                    if (this.isCloseCase) {
                        const closeCaseText = i18n.translate(CustomerCareLocaleKeys.CASE.CLOSE);
                        this.modalTitle = closeCaseText;
                        this.saveButtonText = closeCaseText;
                        this.editCase.Status = CASE_STATUS.CLOSED;
                    }
                    this.invoiceNumber = invoiceNumber;
                    if (this.invoiceNumber) {
                        this.editCase.Details = {
                            ...pathOr(undefined, ['Details'], this.editCase),
                            InvoiceDisputeDetails: {
                                ...pathOr(undefined, ['Details', 'InvoiceDisputeDetails'], this.editCase),
                                InvoiceNumber: this.invoiceNumber
                            }
                        };
                    }

                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    this.$timeout(() => {
                        api.open();
                        this.actions.registerUnsavedChanges('caseModal', {
                            isDirty: () => {
                                return this.formController ? this.formController.$dirty : false;
                            },
                            setPristine: () => {
                                this.formController && this.formController.$setPristine();
                            }
                        });
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };
    }
    setCase(caseObj) {
        this.editCase = caseObj;
        this.inEditMode = !!pathOr(false, ['case', 'Id', 'Value'], this);
        this.actions.setCaseEditMode(this.inEditMode);
        this.handleCategoryChange();
        this.selectInvoiceAmounts();
    }
    get caseCategoryLabel() {
        return getLabelByValue(this.case && this.case.Category || this.caseType, this.state.caseCategoryCodes);
    }
    get caseStatusLabel() {
        return getLabelByValue(this.editCase.Status, this.state.formattedCaseStatusCodes);
    }

    get inventoryItemName() {
        const inventory = find(propEq(this.editCase.InventoryId, 'Id'), this.state.inventory);
        return inventory ? inventory.text : null;
    }

    get caseTypeOptions() {
        return this.filterCaseTypesByDisputeInvoice ? this.state.disputeInvoiceCaseTypesOptionsSelector : this.state.caseCategoryCodes;
    }

    onSaveSuccess() {
        this.closePopup();
        // NOTE: this should be the responsibility of the component that manages the popup instance
        const successMessageKey = this.inEditMode ? CustomerCareLocaleKeys.CASE.UPDATED_SUCCESSFULLY : CustomerCareLocaleKeys.CASE.CREATED_SUCCESSFULLY;
        this.uiNotificationService.success(i18n.translate(successMessageKey), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
    }
    shouldShowFieldError(fieldName) {
        const fieldObject = this.formController[fieldName] || {};
        const {$touched, $modelValue, $error={}} = fieldObject;
        const {pattern, required} = $error;
        return !!((($touched || this.formController.$submitted) && required) || ($modelValue && pattern));
    }
    handleCategoryChange() {
        if (this.editCase.Category) {
            this.categoryDetails = {};
            this.selectedInvoice = {};
            this.editCase.InventoryId = this.inEditMode ? this.editCase.InventoryId : null;
            this.editCase.CaseTemplateId = pathOr(null, ['CaseTemplateId'], this.state.caseTypes.find(x => {
                return x.Id === this.editCase.Category;
            }));
            this.updateCaseInventoryItem(this.editCase.InventoryId);
            this.actions.updateCaseSelectedCategoryId(this.editCase.Category);
            this._popupLayoutNeedsValidation = true;
            this.validatePopupLayout();
            this.state.customFields.forEach((customField) => {
                this.formFieldLocaleKeyMapping[customField.name] = false;
            });
        } else {
            this.actions.removeCaseSelectedCategoryId();
        }
    }
    onPropertyChange(id, value) {
        this.editCase.AdditionalPropertyValues = filter((property) => {
            return property.Value !== '' && property.Value !== null && property.Value !== undefined;
        }, append({
            Id: id,
            Value: value
        }, reject(propEq(id, 'Id'), pathOr([], ['AdditionalPropertyValues'], this.editCase))));
    }
    validatePopupLayout() {
        if (this._popupLayoutNeedsValidation) {
            this.$timeout(this.popupApi.center, 0, false); //Use false to make sure it does not trigger another digest cycle
            this._popupLayoutNeedsValidation = false;
        }
    }
    get shouldShowResolutionText() {
        return shouldHaveResolutionText(this.editCase);
    }

    validateForm() {
        // NOTE: apply the validation in the next cycle, so that
        // ng-model-options' {updateOn: 'blur'} is applied first (so we actually
        // adapt the errors, instead of not having any yet)
        this.$timeout(() => {
            this.state.customFields.forEach((customField) => {
                this.formFieldLocaleKeyMapping[customField.name] = false;
            });
            this.formErrors = this.formController ? AngularFormControllerErrorAdapter(this.formController, this.formFieldLocaleKeyMapping) : [];
        });
    }
    handleSubmitForm() {
        if (this.formController.$valid) {
            const action = this.inEditMode ? this.actions.updateCase : this.actions.createCase;
            const transformedCase = transformCaseForService(this.inEditMode, this.editCase, this.categoryDetails);

            if (this.state.caseTypes.find((caseType) => {
                return caseType.Id === transformedCase.Category && caseType.IsGlobal;
            })) {
                delete transformedCase.InventoryId;
            }

            return action(this.state.currentCustomerId, transformedCase)
                .then((payload) => {
                    this.onSaveSuccess();
                    this.$timeout(() => {
                        this.actions.stateGo(CASE_DETAILS_STATE_NAME, {
                            caseId: payload.Case.Id.Value
                        });
                    });
                }, () => {
                    if (this.state.lastAttemptError) {
                        this.formErrors = [this.state.lastAttemptError.translatedMessage];
                        this.uiNotificationService.error(this.state.lastAttemptError.translatedMessage, null, {
                            timeOut: NOTIFICATION_TIME_LENGTH
                        });
                    }

                    if (this.inEditMode) {
                        this.actions.retrieveCase(this.state.currentCustomerId, pathOr(false, ['case', 'Id', 'Value'], this)).catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                    }
                });
        } else {
            this.validateForm();
        }
    }
    resetForm() {
        if (this.case) {
            this.setCase(Immutable(this.case).asMutable({
                deep: true
            }));
            if (this.case.Details && this.case.Details.TroubleCallDetails) {
                this.selectInventoryBySerialNumber(this.case.Details.TroubleCallDetails.SerialNumber);
            }
        } else {
            if (this.caseType) {
                this.setCase({
                    Category: this.caseType
                });
            } else {
                this.setCase({});
            }

            if (this.caseDetails) {
                this.updateCaseInventoryItem(pathOr(null, ['Id'], this.caseDetails.inventoryItem));
            } else {
                this.updateCaseInventoryItem(null);
            }
        }

        this.formErrors = [];
        if (this.inEditMode) {
            this.modalTitle = i18n.translate(CustomerCareLocaleKeys.CASE.EDIT_CASE);
            this.saveButtonText = i18n.translate(CustomerCareLocaleKeys.CASE.EDIT_CASE);
        } else {
            this.modalTitle = i18n.translate(CustomerCareLocaleKeys.NEW_CASE);
            this.saveButtonText = i18n.translate(CustomerCareLocaleKeys.CREATE_CASE);
        }
    }

    handleInventoryChange() {
        this.updateCaseInventoryItem(this.editCase.InventoryId);
    }

    updateCaseInventoryItem(inventoryItemId) {
        this.actions.updateCaseInventoryItem(inventoryItemId);

        if (this.editCase.Category === CASE_CATEGORIES.TROUBLE_CALL || this.editCase.CaseTemplateId === CASE_CATEGORIES.TROUBLE_CALL) {
            this.categoryDetails = {
                SerialNumber: pathOr(null, ['SerialNumber'], this.state.selectedInventory)
            };
        }
        this.editCase.InventoryId = inventoryItemId;
    }

    selectInventoryBySerialNumber(serialNumber) {
        const item = this.state.inventory.find((inventoryItem) => {
            return inventoryItem.serialNumber === serialNumber;
        });
        this.editCase.InventoryId = item ? item.Id : null;
        this.updateCaseInventoryItem(item ? item.Id : null);
    }

    selectInvoiceAmounts() {
        const invoiceNumber = path(['editCase', 'Details', 'InvoiceDisputeDetails', 'InvoiceNumber'], this);

        if (invoiceNumber) {
            const invoice = this.state.invoices.find((invoiceItem) => {
                return invoiceItem.InvoiceNumber === invoiceNumber;
            });
            this.invoiceAmount = invoice.Balance;
            this.validatePopupLayout();
        }
    }
}

export default {
    bindings: {
        case: '<?',
        caseDetails: '<?',
        caseType: '<?',
        config: '<',
        filterCaseTypesByDisputeInvoice: '<?',
        isInventorySet: '<?'
    },
    template: require('./case.modal.html'),
    controller: CaseModalController,
    controllerAs: 'caseModalController'
};
