import clone from 'ramda/src/clone';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import reject from 'ramda/src/reject';
import {
    FeatureToggleConstants,
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors,
    UnsavedChangesPromptActions
} from 'invision-core';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import LocaleKeys from '../../../locales/keys';
import {
    chargePaymentInstrument,
    clearSelectedPaymentMethod,
    resetIsMakingPayment,
    retrieveAvailablePaymentInstrumentTypes,
    editPaymentInstrument,
    selectPaymentMethod,
    updatePaymentAccountsOnSelectedPaymentMethod,
    retrieveWallet
} from '../../../reducers/actions/customer.ewallet.actions';
import {
    DefaultPaymentMethodSelector,
    EwalletErrorSelector,
    PaymentInstrumentSummariesSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
    PaymentIsCreatingOrEditingDataSelector,
    PaymentIsUpdatingAutoPaySelector,
    IsMakingPaymentSelector,
    IsRetrievingEwalletDataSelector,
    SelectedPaymentMethodConvergentBillerPaymentInstrumentAccountsSelector,
    SelectedPaymentMethodSelector,
    SelectedPaymentForSubmitAtCheckoutSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {DefaultBillingAddressSelector} from '../../../reducers/selectors/customer.addresses.selectors';
import {
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerIdSelector,
    RouteParams
} from '../../../reducers/selectors/customer.selectors';
import {
    ACCOUNTING_METHODS_NAMES,
    BILLPAY_OPTIONS,
    MAKE_PAYMENT_ROUTE,
    MAKE_PAYMENT_TYPES
} from './make.payment.constants';
import {
    retrieveConvergentBillerSubscriberSummary,
    retrieveConvergentBillerTreatmentDetails
} from '../../../reducers/actions/customer.convergent.biller.actions';
import {
    ConvergentBillerIsInPromiseToPay,
    ConvergentBillerIsInTreatment,
    ConvergentBillerCurrentPromiseToPayInstallment,
    ConvergentBillerSubscriberSummarySelector,
    ConvergentBillerSubscriberSummaryAccountsSelector,
    ConvergentBillerSubscriberSummaryPostPaidAccountSelector,
    ConvergentBillerSubscriberSummaryPrePaidAccountSelector,
    ConvergentBillerTreatmentDetailsSelector,
    CurrentAccountSummarySelector,
    CurrentPostPaidAccountSummarySelector,
    CurrentPostPaidAccountSummaryTotalSelector,
    IsFetchingSubscriberSummarySelector,
    IsARCSubscriptionMgmtFeatureToggleSelector
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {TREATMENT_STATUS} from '../../../reducers/constants/convergent.biller.constants';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {stateGo} from 'redux-ui-router';
import {
    retrieveCustomerHouseholds
} from '../../../reducers/actions/customer.household.actions';
import {
    CurrentHouseholdSelector,
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector
} from '../../../reducers/selectors/customer.household.selectors';
import {
    DeviceFinancingNameSelector,
    SubscriberDeviceFinancingDetailsSelector,
    SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector
} from '../../../reducers/selectors/customer.devices.selectors';
import {
    retrieveSubscriberFinanceDetails,
    setDeviceFinancingWidgetSelectedDevice
} from '../../../reducers/actions/customer.devices.actions';
import {deviceFinancingFriendlyName} from '../dashboard/dbssDashboard/deviceFinancing/device.financing.helper';
import {InvoiceSummariesSelector} from '../../../reducers/selectors/customer.billing.selectors';
import {INVOICES} from '../../billingPayments/billing.payments.constants';
import {retrieveLedgerSummary} from '../../../reducers/actions/customer.billing.actions';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

function CustomerMakePaymentController($ngRedux, uiNotificationService, $timeout, $state) {
    let disconnectRedux;
    let previouslySelectedAccount = {};
    let previousAutoPayPaymentInstrument = {};
    this.hasDeviceFinancingLoaded = false;
    this.deviceFinancingAccountDisplayName = '';

    const mapStateToTarget = (store) => {
        return {
            billingAddress: DefaultBillingAddressSelector(store),
            featureTogglesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
            currentAccountSummary: CurrentAccountSummarySelector(store),
            currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentHousehold: CurrentHouseholdSelector(store),
            currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
            currentPostPaidAccountSummary: CurrentPostPaidAccountSummarySelector(store),
            defaultPaymentMethod: DefaultPaymentMethodSelector(store),
            deviceFinancingName: DeviceFinancingNameSelector(store),
            eWalletError: EwalletErrorSelector(store),
            features: FeatureTogglesSelector(store),
            hasPromiseToPay: ConvergentBillerIsInPromiseToPay(store),
            invoiceList: InvoiceSummariesSelector(store),
            isARCSubscriptionMgmtFeatureLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.MicroserviceConfiguration, store),
            isARCSubscriptionMgmtFeatureToggle:IsARCSubscriptionMgmtFeatureToggleSelector(store),
            isCreatingOrEditingPaymentMethodData: PaymentIsCreatingOrEditingDataSelector(store),
            isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
            isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
            isInTreatment: ConvergentBillerIsInTreatment(store),
            isMakingPayment: IsMakingPaymentSelector(store),
            isUpdatingAutoPayData: PaymentIsUpdatingAutoPaySelector(store),
            paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
            paymentInstrumentSummaries: PaymentInstrumentSummariesSelector(store),
            periodTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PeriodType, store),
            postPaidSubscriberSummaryAccounts: ConvergentBillerSubscriberSummaryPostPaidAccountSelector(store),
            prepaidSubscriberSummaryAccounts: ConvergentBillerSubscriberSummaryPrePaidAccountSelector(store),
            promiseToPayDetails: ConvergentBillerCurrentPromiseToPayInstallment(store),
            routeParams: RouteParams(store),
            selectedDeviceFinancing: SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector(store),
            selectedPaymentMethodPaymentAccountsSelector: SelectedPaymentMethodConvergentBillerPaymentInstrumentAccountsSelector(store),
            selectedPaymentMethod: SelectedPaymentMethodSelector(store),
            selectedPaymentClean: SelectedPaymentForSubmitAtCheckoutSelector(store),
            subscriberFinancingDevices: SubscriberDeviceFinancingDetailsSelector(store),
            subscriberSummary: ConvergentBillerSubscriberSummarySelector(store),
            subscriberSummaryAccounts: ConvergentBillerSubscriberSummaryAccountsSelector(store),
            totalBalance: CurrentPostPaidAccountSummaryTotalSelector(store),
            treatmentDetails: ConvergentBillerTreatmentDetailsSelector(store),
            lastRoute: SessionSelectors.LastRouteSelector(store)
        };
    };

    const controllerActions = {
        chargePaymentInstrument,
        clearSelectedPaymentMethod,
        editPaymentInstrument,
        fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
        fetchPeriodTypes: MetadataActions.codes.fetchPeriodTypes,
        resetIsMakingPayment,
        retrieveAvailablePaymentInstrumentTypes,
        retrieveConvergentBillerSubscriberSummary,
        retrieveConvergentBillerTreatmentDetails,
        retrieveCustomerHouseholds,
        retrieveLedgerSummary,
        retrieveSubscriberFinanceDetails,
        retrieveWallet,
        selectPaymentMethod,
        setDeviceFinancingWidgetSelectedDevice,
        stateGo,
        updatePaymentAccountsOnSelectedPaymentMethod,
        unregisterUnsavedChanges: UnsavedChangesPromptActions.unregisterUnsavedChanges,
    };

    this.$onInit = () => {
        this.LocaleKeys = LocaleKeys;
        this.billPayOptions = BILLPAY_OPTIONS;
        this.treatmentStatusTypes = TREATMENT_STATUS;
        this.DEFAULT_BACK_ROUTE = 'index.customercare.customer.dashboard';
        this.submitText = this.isAutoPay ? LocaleKeys.AUTO_PAY.UPDATE : LocaleKeys.MAKE_PAYMENT.MAKE_PAYMENT;
        this.isBalanceForward = false;
        this.filter = getFilterService();
        this.formattedInvoiceList = [];
        this.selectedInvoiceNumber = null;
        this.statementBalance = null;
        this.statementBalanceDueDate = null;

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.featureTogglesLoaded) {
            this.actions.fetchCodeTypes(CODES.FeatureToggleConfig);
        }

        if (this.deviceFinancingAccount) {
            this.hasDeviceFinancingLoaded = !!this.state.subscriberFinancingDevices.length;
            if (!this.hasDeviceFinancingLoaded && !this.state.features[FeatureToggleConstants.OFFCYCLE_BYPASS_ARM]) {
                this.actions.retrieveSubscriberFinanceDetails(this.state.currentCustomerId)
                    .then(() => {
                        this.hasDeviceFinancingLoaded = true;
                        this.getDeviceFinancingDetails();
                    })
                    .catch((error) => {
                        this.hasDeviceFinancingLoaded = false;
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
            } else {
                this.hasDeviceFinancingLoaded = true;
                this.getDeviceFinancingDetails();
            }
        }

        if (!this.state.periodTypesLoaded) {
            this.actions.fetchPeriodTypes();
        }

        if (!this.state.isARCSubscriptionMgmtFeatureLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.MicroserviceConfiguration);
        }

        fetchRequiredData();

        this.makePaymentStateOrName = MAKE_PAYMENT_ROUTE;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this.mainFormServiceError = '';

        this.changeAutoPayAccountNumberPopUp = {
            onRegisterApi: (evt) => {
                this.changeAutoPayAccountNumberPopUp = evt.api;
            }
        };

        this.actions.retrieveAvailablePaymentInstrumentTypes().catch((error) => {
            uiNotificationService.transientError(error.translatedMessage);
        });

        if (ACCOUNTING_METHODS_NAMES.BALANCE_FORWARD === this.state.currentCustomerAccountingMethod) {
            this.isBalanceForward = true;
        }

        this.retrieveAllInvoices();
    };

    this.$onDestroy = () => {
        this.actions.clearSelectedPaymentMethod();
        this.actions.resetIsMakingPayment();
        disconnectRedux();
    };

    const fetchRequiredData = () => {

        if (!this.state.treatmentDetails) {
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.currentCustomerId);
        }

        this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId).then(() => {
            if (this.isAutoPay) {
                this.selectedAccount = this.state.currentAccountSummary;
            } else if (this.state.isARCSubscriptionMgmtFeatureToggle) {
                this.selectedAccount = this.state.currentAccountSummary;
                this.paymentAmountOption = this.getDefaultPaymentAmountOption();
            } else {
                this.selectedAccount = this.state.currentPostPaidAccountSummary;
                this.paymentAmountOption = this.getDefaultPaymentAmountOption();
            }

            this.actions.retrieveWallet(this.buildWalletRequest()).then(() => {
                if (this.isAutoPay) {
                    this.initializeAutoPayFormFields();
                } else {
                    this.setCurrentlySelectedPaymentInstrument(this.state.defaultPaymentMethod);
                }
            });
        });

        if (!this.state.currentHousehold) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false);
        }
    };

    // Determine which payment option is selected based on various criteria
    this.getDefaultPaymentAmountOption = () => {
        let paymentAmountOption;

        if (this.shouldShowTreatmentAmounts()) {
            paymentAmountOption = this.billPayOptions.INSTALLMENT_TOTAL;
        } else if (this.shouldShowStatementBalance() && this.getPaymentAmount(this.billPayOptions.STATEMENT_BALANCE) > 0) {
            paymentAmountOption = this.billPayOptions.STATEMENT_BALANCE;
        } else if (this.showAccountTotal()) {
            paymentAmountOption = this.billPayOptions.ACCOUNT_TOTAL;
        } else {
            paymentAmountOption = this.billPayOptions.OTHER_AMOUNT;
        }

        return paymentAmountOption;
    };

    this.shouldShowTreatmentAmounts = () => {
        if (this.state.subscriberSummary && this.state.subscriberSummary.TreatmentStatus) {
            return (this.state.subscriberSummary.TreatmentStatus === this.treatmentStatusTypes.IN_TREATMENT_WITH_P2P);
        }
        return false;
    };

    this.shouldShowStatementBalance = () => {
        if (this.state.currentPostPaidAccountSummary
            && this.state.currentPostPaidAccountSummary.PostpaidDetails
            && !this.deviceFinancingAccount) {
            return (!!this.state.currentPostPaidAccountSummary.PostpaidDetails);
        }
        return false;
    };

    this.showAccountTotal = () => {
        return this.state.isInTreatment && !this.deviceFinancingAccount;
    };

    this.continueSettingAccountNumber = (shouldSetAccountNumber) => {
        if (shouldSetAccountNumber) {
            this.actions.unregisterUnsavedChanges('CustomerMakePaymentController.formApi');
            this.actions.stateGo('index.customercare.customer.autoPay', {
                accountNumber: this.selectedAccount.AccountNumber
            }, {
                reload: false,
                notify: false,
                location: 'replace'
            });
            this.initializeAutoPayFormFields();
        } else {
            this.selectedAccount = previouslySelectedAccount;
        }
        this.changeAutoPayAccountNumberPopUp.close();
    };

    this.shouldShowErrorForMainForm = () => {
        return this.formApi.$submitted && (!this.state.selectedPaymentMethod || (!this.selectedAccount && !this.deviceFinancingAccount) || this.balanceIsInvalid() || this.hasNoPaymentInstruments());
    };

    this.shouldShowErrorForMainFormField = (fieldName) => {
        return this.formApi[fieldName] && (this.formApi[fieldName].$touched || this.formApi.$submitted) && this.formApi[fieldName].$invalid;
    };

    this.shouldShowBalanceError = () => {
        return this.paymentAmountOption === BILLPAY_OPTIONS.OTHER_AMOUNT && this.balanceIsInvalid() &&
            (this.formApi.$submitted || this.formApi.customPaymentAmount.$touched);
    };

    this.onAccountNumberChange = (newAccount, previousAccount) => {
        if (this.isAutoPay) {
            previouslySelectedAccount = previousAccount;

            if (this.isAutoPayChangedForAccount()) {
                this.changeAutoPayAccountNumberPopUp.open();
            } else {
                this.continueSettingAccountNumber(true);
            }
        }
    };

    this.initializeAutoPayFormFields = () => {
        if (this.selectedAccount && this.selectedAccount.AutoPayConfigured) {
            previousAutoPayPaymentInstrument = this.state.paymentInstruments.find((pi) => {
                if (pi.ConvergentBillerPaymentInstrumentAccounts && pi.ConvergentBillerPaymentInstrumentAccounts.length) {
                    return pi.ConvergentBillerPaymentInstrumentAccounts.find((account) => {
                        return account.AutoPay && (account.AccountNumber === this.selectedAccount.AccountNumber);
                    });
                }
            });
        } else {
            previousAutoPayPaymentInstrument = null;
        }

        this.isAutoPayActivated = !!previousAutoPayPaymentInstrument;
        if (this.isAutoPayActivated) {
            this.setCurrentlySelectedPaymentInstrument(previousAutoPayPaymentInstrument);
        } else {
            this.setCurrentlySelectedPaymentInstrument(this.state.defaultPaymentMethod);
        }
    };

    this.getUpdatedPaymentAccountsForSelectedPaymentMethod = () => {
        if (this.selectedAccount && this.state.selectedPaymentMethod) {
            const paymentInstrumentPaymentAccounts = reject((currAccount) => {
                return currAccount.AccountNumber === this.selectedAccount.AccountNumber;
            }, this.state.selectedPaymentMethodPaymentAccountsSelector);

            if (this.isAutoPayActivated) {
                paymentInstrumentPaymentAccounts.push({
                    AutoPay: this.isAutoPayActivated,
                    AccountNumber: this.selectedAccount.AccountNumber
                });
            }
            return paymentInstrumentPaymentAccounts;
        }
        return [];
    };

    this.editAutoPayOnPaymentMethod = () => {
        if (this.isAutoPay) {
            this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
                ConvergentBillerPaymentInstrumentAccounts: this.getUpdatedPaymentAccountsForSelectedPaymentMethod()
            });
        }
    };

    this.isAutoPayChangedForAccount = () => {
        if (!previouslySelectedAccount) {
            return false;
        }

        if (previousAutoPayPaymentInstrument && this.state.selectedPaymentMethod) {
            return !this.isAutoPayActivated || previousAutoPayPaymentInstrument.Id !== this.state.selectedPaymentMethod.Id;
        } else {
            return !!this.isAutoPayActivated;
        }
    };

    this.setCurrentlySelectedPaymentInstrument = (paymentInstrument) => {
        this.currentlySelectedPaymentInstrument = paymentInstrument;
        if (!isNil(paymentInstrument)) {
            this.selectPaymentMethod();
        } else {
            this.actions.clearSelectedPaymentMethod();
        }
    };

    this.buildWalletRequest = () => {
        return {
            customerId: this.state.currentCustomerId
        };
    };

    this.selectPaymentMethod = () => {
        this.actions.selectPaymentMethod(this.currentlySelectedPaymentInstrument);

        this.editAutoPayOnPaymentMethod();
    };

    this.getPaymentAmount = (billPaymentOption) => {
        let paymentAmount = null;
        switch (billPaymentOption) {
            case BILLPAY_OPTIONS.INSTALLMENT_TOTAL:
                paymentAmount = this.state.promiseToPayDetails.InstallmentTotal;
                break;
            case BILLPAY_OPTIONS.TREATMENT_BALANCE:
                paymentAmount = this.state.promiseToPayDetails.PromiseAmountRemaining;
                break;
            case BILLPAY_OPTIONS.STATEMENT_BALANCE:
                paymentAmount = this.statementBalance;
                break;
            case BILLPAY_OPTIONS.ACCOUNT_TOTAL:
                paymentAmount = this.state.currentPostPaidAccountSummary.PostpaidDetails.Total;
                break;
            case BILLPAY_OPTIONS.OTHER_AMOUNT:
                paymentAmount = parseFloat(this.customPaymentAmount);
                break;
            case BILLPAY_OPTIONS.DEVICE_FINANCING:
                paymentAmount = pathOr(null, ['selectedDeviceFinancing', 'FinanceBalance'], this.state);
                break;
            default:
                break;
        }

        return paymentAmount;
    };

    this.getPaymentCurrencyCode = (billPaymentOption) => {
        let currencyCode = null;
        if (billPaymentOption === BILLPAY_OPTIONS.INSTALLMENT_TOTAL || billPaymentOption === BILLPAY_OPTIONS.TREATMENT_BALANCE) {
            currencyCode = this.state.promiseToPayDetails.CurrencyCode;
        } else if (billPaymentOption === BILLPAY_OPTIONS.STATEMENT_BALANCE || billPaymentOption === BILLPAY_OPTIONS.ACCOUNT_TOTAL) {
            currencyCode = this.state.currentPostPaidAccountSummary.PostpaidDetails.CurrencyCode;
        } else {
            currencyCode = pathOr('USD', ['state', 'currentPostPaidAccountSummary', 'PostpaidDetails', 'CurrencyCode'], this);
        }
        return currencyCode;
    };

    this.handleServiceError = (errorMessageKey) => {
        const serviceErrorAlert = i18n.translate(errorMessageKey);
        uiNotificationService.error(serviceErrorAlert, null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });

        if (this.state.eWalletError && !this.state.isCreatingOrEditingPaymentMethodData) {
            this.mainFormServiceError = serviceErrorAlert;
        }
    };

    this.makePayment = () => {
        if (this.isAutoPay ||
            (!this.selectedAccount && !this.deviceFinancingAccount) ||
            !this.state.selectedPaymentMethod ||
            this.balanceIsInvalid()) {
            return;
        }

        //Determine if it's a device financing account
        const accountNumber = this.deviceFinancingAccount
            ? this.state.selectedDeviceFinancing.DeviceFinanceAccountNumber
            : this.selectedAccount.AccountNumber;

        const request = {
            customerId: this.state.currentCustomerId,
            request: {
                AccountNumber: accountNumber,
                InvoiceId: this.selectedInvoiceId,
                Amount: this.getPaymentAmount(this.paymentAmountOption)
            }
        };
        if (!request.request.InvoiceId) {
            delete request.request.InvoiceId;
        }
        if (!pathOr(true, ['recordPayment'], this.state.selectedPaymentMethod)) {
            request.request.PaymentInstruments = [this.state.selectedPaymentClean];
        } else {
            request.request.PaymentInstrumentIds = [this.state.selectedPaymentMethod.Id];
        }


        this.mainFormServiceError = '';

        return this.actions.chargePaymentInstrument(request).then(() => {
            this.formApi.$setPristine();
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.currentCustomerId).then(() => {
                this.goBack();
            });
        }).catch(() => {
            this.handleServiceError(LocaleKeys.MAKE_PAYMENT.PAYMENT_FAILURE);
            this.actions.resetIsMakingPayment();
        } );
    };

    this.balanceIsInvalid = () => {
        return this.paymentAmountOption === this.billPayOptions.OTHER_AMOUNT && !this.isValidPaymentAmount(this.customPaymentAmount);
    };

    // Check to see if an amount is valid and greater than zero
    this.isValidPaymentAmount = (amount) => {
        return String(amount).trim().length > 0 && (!isNaN(amount)) && parseFloat(amount) > 0;
    };

    this.updateAutoPay = () => {
        if (!this.isAutoPay ||
            !this.selectedAccount ||
            !this.state.selectedPaymentMethod ||
            this.hasNoPaymentInstruments()) {
            return;
        }
        const request = {
            updateAutoPay: true,
            customerId: this.state.currentCustomerId,
            request: {
                PaymentInstrument: this.state.selectedPaymentMethod
            }
        };
        this.mainFormServiceError = '';

        return this.actions.editPaymentInstrument(request).then(() => {
            uiNotificationService.success(i18n.translate(LocaleKeys.AUTO_PAY.SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });

            this.formApi.$setPristine();
            this.goBack();
        }, () => {
            this.handleServiceError(LocaleKeys.AUTO_PAY.FAILURE);
        });
    };

    this.goBack = () => {
        const route = this.state.lastRoute.name || this.DEFAULT_BACK_ROUTE;
        const params = this.state.lastRoute.params || {};
        $state.go(route, params);
    };

    this.hasNoPaymentInstruments = () => {
        return this.state.paymentInstruments.length === 0 && !this.state.selectedPaymentMethod;
    };

    this.showPageLoader = () => {
        return this.state.isFetchingEwalletData ||
            this.state.isMakingPayment ||
            this.state.isFetchingSubscriberSummary ||
            this.state.isUpdatingAutoPayData;
    };

    this.disableCustomPayment = () => {
        return this.paymentAmountOption !== this.billPayOptions.OTHER_AMOUNT;
    };

    this.getTitleKey = () => {
        return this.isAutoPay ? LocaleKeys.AUTO_PAY.CONFIGURE_AUTO_PAY : LocaleKeys.MAKE_A_PAYMENT;
    };

    this.shouldDisableCurrentBalance = () => {
        return this.state.totalBalance <= 0;
    };

    // If the amount to pay if zero, disable the option
    this.disablePaymentAmountOption = (billPaymentOption) => {
        if (!this.isBalanceForward && (billPaymentOption === BILLPAY_OPTIONS.ACCOUNT_TOTAL)) {
            return true;
        }
        return this.getPaymentAmount(billPaymentOption) <= 0;
    };

    this.canCreatePaymentInstruments = () => {
        if (!this.state.currentHousehold) {
            return true;
        }

        return this.state.currentMemberCanCreatePaymentInstrument;
    };

    this.onSubmit = () => {
        if (this.isAutoPay) {
            this.updateAutoPay();
        } else {
            this.makePayment();
        }
    };

    this.findDeviceFinancingAccount = () => {
        const findDeviceIndex = (device) => {
            return device.DeviceFinanceAccountNumber === this.deviceFinancingAccount;
        };

        return this.state.subscriberFinancingDevices.findIndex(findDeviceIndex);
    };

    this.getDeviceFinancingDetails = () => {
        const deviceIndex = this.findDeviceFinancingAccount();
        if (deviceIndex >= 0) {
            this.actions.setDeviceFinancingWidgetSelectedDevice(deviceIndex);
            const deviceFinancingName = i18n.translate(
                this.LocaleKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING_FOR, {
                    deviceFinancingName: this.state.deviceFinancingName
                }
            );
            this.deviceFinancingAccountDisplayName = `${deviceFinancingName} ${deviceFinancingFriendlyName(this.state.subscriberFinancingDevices[deviceIndex])}`;
        }
    };

    this.retrieveAllInvoices = () => {
        const request = {
            customerId: this.state.currentCustomerId,
            request:{
                Filter: INVOICES
            }
        };
        this.actions.retrieveLedgerSummary(request)
            .then(() => {
                this.formatInvoiceListForSelection();
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    };

    this.formatInvoiceListForSelection = () => {
        const clonedInvoiceList = clone(this.state.invoiceList);
        let latestStatementsIndex = 0;
        let latestStatementsTime = null;
        this.selectedOpenInvoice = null;
        clonedInvoiceList.forEach((item) => {
            if ((this.state.routeParams.paymentType === MAKE_PAYMENT_TYPES.INVOICE)) {
                this.selectedOpenInvoice = (item.InvoiceId === this.state.routeParams.invoiceNumber) ? item : this.selectedOpenInvoice;
            } else {
                if (item.CurrentDue > 0) {
                    latestStatementsTime = latestStatementsTime || item.IssueDate;
                    if (latestStatementsTime < item.IssueDate) {
                        latestStatementsIndex ++;
                        latestStatementsTime = item.IssueDate;
                    }
                    this.formattedInvoiceList.push({
                        currentDue: item.CurrentDue,
                        dueDate: item.DueDate,
                        invoiceId: item.InvoiceId,
                        text: `${item.InvoiceNumber} - ${this.filter('invCurrency')(item.CurrentDue, item.CurrencyCode)}`
                    });
                }
            }

        });
        if (this.state.routeParams.paymentType !== MAKE_PAYMENT_TYPES.INVOICE) {
            if (this.isBalanceForward) {
                this.formattedInvoiceList.unshift({
                    text: i18n.translate(this.LocaleKeys.SEARCH_FIELDS_CUSTOMER.SINGLE_SELECT_PLACEHOLDER),
                    invoiceId: null
                });
                this.selectedInvoice = this.formattedInvoiceList[0];
            }
            if (!this.isBalanceForward && this.formattedInvoiceList.length) {
                this.selectedInvoice = this.formattedInvoiceList[latestStatementsIndex];
                this.getStatementBalanceFromCurrentInvoice(this.formattedInvoiceList[latestStatementsIndex]);
            } else {
                this.statementBalance = this.state.currentPostPaidAccountSummary.PostpaidDetails.CurrentDue;
                this.statementBalanceDueDate = this.state.currentAccountSummary.PostpaidDetails.DueDate;
            }
        } else {
            this.checkIfPaymentForOpenInvoiceDashboard();
        }

        this.paymentAmountOption = this.getDefaultPaymentAmountOption();
    };

    this.onInvoiceNumberSelected = (selectedInvoiceNumber) => {
        if (selectedInvoiceNumber.invoiceId) {
            this.getStatementBalanceFromCurrentInvoice(selectedInvoiceNumber);
        }
    };

    this.getStatementBalanceFromCurrentInvoice = (invoice) => {
        this.selectedInvoiceId = convertStringToNumber(invoice.invoiceId);
        this.statementBalance = invoice.currentDue;
        this.statementBalanceDueDate = invoice.dueDate;
        this.paymentAmountOption = this.getDefaultPaymentAmountOption();
    };

    this.checkIfPaymentForOpenInvoiceDashboard = () => {
        this.selectedInvoiceId = convertStringToNumber(this.selectedOpenInvoice.InvoiceId);
        this.selectedOpenInvoiceNumber = this.selectedOpenInvoice.InvoiceNumber;
        this.statementBalance = this.selectedOpenInvoice.CurrentDue;
        this.statementBalanceDueDate = this.selectedOpenInvoice.DueDate;
    };
}

export default {
    bindings: {
        isAutoPay: '<',
        deviceFinancingAccount : '<?'
    },
    template: require('./make.payment.html'),
    controller: CustomerMakePaymentController,
    controllerAs: 'CustomerMakePaymentController'
};
