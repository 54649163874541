import {ORDERING_ACCESS} from '../../../security.attributes';
import {
    emptyProductsList,
    removeProductsFilter
} from '../../../reducers/actions/customer.products.actions';

export const DETAIL_STATE_OR_NAME = 'index.customercare.customer.customerProducts.detail';

export default ($stateProvider) => {
    $stateProvider.state('index.customercare.customer.customerProducts', {
        url: '/products',
        redirectTo: 'index.customercare.customer.customerProducts.list',
        template: '<ui-view class="u-flexKeepHeight"></ui-view>',
        securityAttributes: [ORDERING_ACCESS],
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(removeProductsFilter());
            $ngRedux.dispatch(emptyProductsList());
        }
    }).state('index.customercare.customer.customerProducts.list', {
        url: '/list',
        template: '<customer-products-list></customer-products-list>',
    }).state(DETAIL_STATE_OR_NAME, {
        url: '/:lockerItemId',
        template: '<products-detail></products-detail>',
    });
};
