export const stateRequiredHelper = (addressRequirements = [], addressStatesOptions = []) => {

    const businessUnitAddressRequirement = addressRequirements.find((addressRequirement) => {
        return !addressRequirement.Global;
    });

    const addressRequirementGlobal = addressRequirements.find((addressRequirement) => {
        return addressRequirement.Global;
    });

    const addressRequirement = businessUnitAddressRequirement ? businessUnitAddressRequirement: addressRequirementGlobal;

    const isStateRequiredProperty = addressRequirement && addressRequirement.AdditionalProperties.find((configObject) => {
        return configObject.Key === 'is_state_required';
    });

    const isStateRequired =  addressStatesOptions && addressStatesOptions.length && isStateRequiredProperty ? isStateRequiredProperty.Value || false : false;

    return isStateRequired === 'False' ? false : isStateRequired === 'True' ? true : isStateRequired;

};