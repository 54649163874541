import {
    EndDateSelector,
    InvoiceSummariesSelector,
    StartDateSelector,
    TypeSelector
} from '../../../reducers/selectors/customer.billing.selectors';
import {retrieveLedgerSummaryPromise} from '../../../reducers/actions/customer.billing.actions';

export function routing($stateProvider) {
    $stateProvider
        .state('index.customercare.customer.billingpayments.detail', {
            url: '/detail/:invoiceId',
            template: '<billing-payments-detail></billing-payments-detail>',
            resolve: {
                invoiceIdIsValid: ($q, $stateParams, $ngRedux) => {
                    const invoiceId = $stateParams.invoiceId;
                    if (invoiceId !== undefined && invoiceId.trim().length && !isNaN(invoiceId)) {
                        if (!InvoiceSummariesSelector($ngRedux.getState()).length) {
                            const requestObj = {
                                customerId: $stateParams.customerId,
                                request: {
                                    EndDate: EndDateSelector($ngRedux.getState()),
                                    Filter: TypeSelector($ngRedux.getState()),
                                    StartDate: StartDateSelector($ngRedux.getState())
                                }
                            };
                            return retrieveLedgerSummaryPromise($ngRedux.dispatch, requestObj)
                                .then(() => {
                                    return $q.resolve();
                                })
                                .catch(() => {
                                    return $q.reject();
                                });
                        } else {
                            return $q.resolve();
                        }
                    } else {
                        return $q.reject({
                            redirectTo: 'index.customercare.customer.billingpayments',
                            toParams: {
                                customerId: $stateParams.customerId
                            }
                        });
                    }
                }
            }
        });
}
