import CustomerCareLocaleKeys from '../../../../../locales/keys';

class AdditionalPropertiesModalController {
    constructor() {
        Object.assign(this, {
            CustomerCareLocaleKeys
        });
    }
    close() {
        this.onClose()();
    }
}
export default {
    controller: AdditionalPropertiesModalController,
    controllerAs: 'AdditionalPropertiesModalController',
    template: require('./additionalProperties.modal.html'),
    bindings: {
        contentTitle: '<',
        content: '<',
        onClose: '&',
        dialogTitle: '<'
    }
};
