import LocaleKeys from '../../../../locales/keys';
import BWConstants from './blocklist.allowlist.constants';
import {
    i18n,
    LogHelper,
} from 'invision-core';
import {
    pathOr
} from 'ramda';
import {
    SelectedReasonIdSelector
} from '../../../../reducers/selectors/blocklist.allowlist.selectors';
import {
    addPaymentInstrumentToBlocklist,
    addPaymentInstrumentToAllowlist,
    removePaymentInstrumentFromBlocklist,
    removePaymentInstrumentFromAllowlist,
    setSelectedReasonId
} from '../../../../reducers/actions/blocklist.allowlist.actions';
import {
    CurrentCustomerIdSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    SelectedPaymentModelBasedOnRecoverableUI
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';

const mapStateToTarget = (store) => {
    return {
        currentCustomerId: CurrentCustomerIdSelector(store),
        selectedPaymentMethod: SelectedPaymentModelBasedOnRecoverableUI(store),
        selectedReasonId: SelectedReasonIdSelector(store)
    };
};

const AVAILABLE_METADATA = {
    [BWConstants.STATE_ADD_TO_BLOCKLIST]: {
        metaDataKey: 'BlocklistAddReason',
    },
    [BWConstants.STATE_ADD_TO_ALLOWLIST]: {
        metaDataKey: 'AllowlistAddReason',
    },
    [BWConstants.STATE_REMOVE_FROM_BLOCKLIST]: {
        metaDataKey: 'BlocklistRemoveReason',
    },
    [BWConstants.STATE_REMOVE_FROM_ALLOWLIST]: {
        metaDataKey: 'AllowlistRemoveReason',
    }
};

const AVAILABLE_CONTROLLER_ACTIONS = {
    [BWConstants.STATE_ADD_TO_BLOCKLIST]: {
        serviceCall: addPaymentInstrumentToBlocklist,
        setSelectedReasonId: setSelectedReasonId
    },
    [BWConstants.STATE_ADD_TO_ALLOWLIST]: {
        serviceCall: addPaymentInstrumentToAllowlist,
        setSelectedReasonId: setSelectedReasonId
    },
    [BWConstants.STATE_REMOVE_FROM_BLOCKLIST]: {
        serviceCall: removePaymentInstrumentFromBlocklist,
        setSelectedReasonId: setSelectedReasonId
    },
    [BWConstants.STATE_REMOVE_FROM_ALLOWLIST]: {
        serviceCall: removePaymentInstrumentFromAllowlist,
        setSelectedReasonId: setSelectedReasonId
    }
};

class BlockAllowModalComponentController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            uiNotificationService
        });
        this.localeKeys = LocaleKeys;
        this.commentText = '';
        this.MODAL_TEXT_ENTRIES = {
            [BWConstants.STATE_ADD_TO_BLOCKLIST]: {
                title: i18n.translate(LocaleKeys.E_WALLET.MODALS.ADD_TO_BLOCKLIST_MENU_TEXT),
                successMessage: i18n.translate(this.localeKeys.E_WALLET.MODALS.ADD_TO_BLOCKLIST_SUCCESS_MESSAGE)
            },
            [BWConstants.STATE_ADD_TO_ALLOWLIST]: {
                title: i18n.translate(LocaleKeys.E_WALLET.MODALS.ADD_TO_ALLOWLIST_MENU_TEXT),
                successMessage: i18n.translate(this.localeKeys.E_WALLET.MODALS.ADD_TO_ALLOWLIST_SUCCESS_MESSAGE)
            },
            [BWConstants.STATE_REMOVE_FROM_BLOCKLIST]: {
                title: i18n.translate(LocaleKeys.E_WALLET.MODALS.REMOVE_FROM_BLOCKLIST_MENU_TEXT),
                successMessage: i18n.translate(this.localeKeys.E_WALLET.MODALS.REMOVE_FROM_BLOCKLIST_SUCCESS_MESSAGE)
            },
            [BWConstants.STATE_REMOVE_FROM_ALLOWLIST]: {
                title: i18n.translate(LocaleKeys.E_WALLET.MODALS.REMOVE_FROM_ALLOWLIST_MENU_TEXT),
                successMessage: i18n.translate(this.localeKeys.E_WALLET.MODALS.REMOVE_FROM_ALLOWLIST_SUCCESS_MESSAGE)
            }
        };
        this.errorsObject = {
            [BWConstants.STATE_ADD_TO_BLOCKLIST]: LocaleKeys.E_WALLET.MODALS.ERRORS.BLOCKLIST_ADD_REASON_REQUIRED,
            [BWConstants.STATE_ADD_TO_ALLOWLIST]: LocaleKeys.E_WALLET.MODALS.ERRORS.ALLOWLIST_ADD_REASON_REQUIRED,
            [BWConstants.STATE_REMOVE_FROM_BLOCKLIST]: LocaleKeys.E_WALLET.MODALS.ERRORS.BLOCKLIST_REMOVE_REASON_REQUIRED,
            [BWConstants.STATE_REMOVE_FROM_ALLOWLIST]: LocaleKeys.E_WALLET.MODALS.ERRORS.ALLOWLIST_REMOVE_REASON_REQUIRED
        };

        this.onReasonSelected = this.onReasonSelected.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resetForm = this.resetForm.bind(this);
    }

    $onInit() {
        const controllerActions = AVAILABLE_CONTROLLER_ACTIONS[this.modalState];

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.metaDataKey = AVAILABLE_METADATA[this.modalState].metaDataKey;

        this.formFieldLocaleKeyMapping = {
            reason: this.errorsObject[this.modalState]
        };

        this.resetForm();
    }

    onSubmitForm() {
        if (this.bwForm.$invalid) {
            return;
        }

        if (this.awaitingAsyncOperation) {
            LogHelper.warn('blocklist/allowlist request submitted while awaiting pre-existing async op');
            return;
        }

        if (this.state.selectedReasonId) {
            let paymentMethodUpdated = false;
            const custId = this.state.currentCustomerId;
            const paymentMethodId = this.state.selectedPaymentMethod.Id;
            const reasonId = parseInt(this.state.selectedReasonId, 10);

            this.actions.serviceCall(custId, paymentMethodId, reasonId, this.commentText)
                .then(() => {
                    paymentMethodUpdated = true;
                    this.actions.setSelectedReasonId(null);
                    this.uiNotificationService.success(i18n.translate(
                        this.MODAL_TEXT_ENTRIES[this.modalState].successMessage),
                    null,
                    {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    }
                    );
                    this.handleClose(paymentMethodUpdated);
                }).catch((error) => {
                    this.handleClose(paymentMethodUpdated, error);
                });
        }
    }

    handleClose(paymentMethodUpdated, error) {
        // Call the onClose callback passed to the component
        this.onClose()(paymentMethodUpdated, error);
    }

    onReasonSelected(selectedReason) {
        this.actions.setSelectedReasonId(selectedReason && selectedReason.id);
    }

    resetForm() {
        this.actions.setSelectedReasonId(null);
    }

    $onChanges() {
    }

    // Uses recoverableUI area of Redux to persist changes made to the mutable form model
    onChangeForm() {
        // this.actions.editEntityChanged(entity);
    }

    getTitle() {
        return pathOr('', ['title'], this.MODAL_TEXT_ENTRIES[this.modalState]);
    }

    $onDestroy() {
        // Clear out the recoverableUI area of Redux
        // this.actions.clearEditEntity();
        this.disconnectRedux();
    }
}

export default {
    template: require('./blocklist.allowlist.modal.html'),
    controller: BlockAllowModalComponentController,
    bindings: {
        config: '<',
        onClose: '&',
        onSuccess: '&',
        entity: '<',
        modalState: '<'
    }
};
