import {HOUSEHOLD_ADMIN_ACCESS} from '../../../../security.attributes';
import {HOUSEHOLD_STATE_NAME} from '../household.constants';

export function config($stateProvider) {
    $stateProvider.state(`${HOUSEHOLD_STATE_NAME}.memberprivileges`, {
        url: '/memberPrivileges/:subscriberId',
        template: '<member-privileges class="u-sizeFull"></member-privileges>',
        adminSecurityAttributes: [HOUSEHOLD_ADMIN_ACCESS]
    });
}
