import {AdditionalPropertyValueType} from 'invision-core/src/components/metadata/codes/codes.additional.properties.constants';
import {getNameForCode} from 'invision-core/src/components/metadata/codes/codes.helpers';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import pathOr from 'ramda/src/pathOr';
import LocaleKeys from '../../../../../../locales/keys';
import {
    ADDRESS_FORM_FIELD_MAX_LENGTH
} from '../../../../../customer/addresses/addresses.constants';
import {
    mobileNumberPortabilitySetEditAdditionalProperty,
    mobileNumberPortabilitySetEditAdditionalPropertyOnChange,
    updateMobileNumberPortabilityAddressModel,
    updateMobileNumberPortabilityModel
} from '../../../../../../reducers/actions/mobile.number.portability.actions';
import {
    MnpAdditionalPropertiesSelector,
    MobileNumberPortabilityAddressModelSelector,
    MobileNumberPortabilityModelSelector,
    PostalCodeRegexSelector,
    StateOptionsSelector
} from '../../../../../../reducers/selectors/mobile.number.portability.selectors';
import clone from 'ramda/src/clone';
import propEq from 'ramda/src/propEq';
import {CurrentAddressesSelector} from '../../../../../../reducers/selectors/customer.addresses.selectors';
import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {UnvalidatedAddressesSelector, ValidatedAddressesSelector} from '../../../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../../../reducers/actions/address.component.actions';
import {EditCustomerInfoSelector} from '../../../../../../reducers/selectors/new.connect.wizard.selectors';
class MobileNumberPortabilityPopupController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            addressFormModel: {},
            AddressHelper,
            CoreLocaleKeys,
            formMaxLengthValidation: ADDRESS_FORM_FIELD_MAX_LENGTH,
            handleAddressChange: this.handleAddressChange.bind(this),
            handleFormChanged: this.handleFormChanged.bind(this),
            LocaleKeys,
            onClose: this.onClose.bind(this),
            onListUpdated: this.onListUpdated.bind(this),
            onMobileNumberPortabilityAdditionalPropertyChange: this.onMobileNumberPortabilityAdditionalPropertyChange.bind(this),
            onPopupSubmit: this.onPopupSubmit.bind(this),
            onAddressFormUpdate: this.onAddressFormUpdate.bind(this),
            uiNotificationService: uiNotificationService,
        });
    }

    $onInit() {
        this.disconnectRedux = this.connectRedux();
        this.existingNumberRegex = this.attribute.regularExpression ? new RegExp(this.attribute.regularExpression) : null;

        this.currentAddresses = clone(this.state.currentAddresses);
        this.mnpAdditionalProperties = clone(this.state.mnpAdditionalProperties);
        if (this.state.currentAddresses.length) {
            this.selectedAddress = this.currentAddresses[0];
            this.handleAddressChange();
        } else {
            this.onAddressFormUpdate({
                addressLine1:  this.state.editCustomerInfo.addressLine1 || this.state.addressData.LineOne,
                addressLine2: this.state.editCustomerInfo.addressLine2 || this.state.addressData.LineTwo,
                country: this.state.addressData.Country || this.state.editCustomerInfo.country,
                city:  this.state.editCustomerInfo.city || this.state.addressData.City,
                stateRegionProvince: this.state.editCustomerInfo.stateRegionProvince || this.state.addressData.State,
                postalCode: this.state.editCustomerInfo.postalCode || this.state.addressData.PostalCode
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    connectRedux() {
        const mapStateToTarget = (store) => {
            return {
                additionalPropertyLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdditionalProperty, store),
                additionalPropertyValueLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdditionalPropertyValue, store),
                additionalPropValueSelector: MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(MetadataConstants.codes.AdditionalPropertyValue, store),
                addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.AddressCountry, store),
                addressData: MobileNumberPortabilityAddressModelSelector(store).asMutable(),
                addressStatesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                currentAddresses: CurrentAddressesSelector(store),
                currentBusinessUnit: CurrentBusinessUnitSelector(store),
                editCustomerInfo: EditCustomerInfoSelector(store),
                mnpAdditionalProperties : MnpAdditionalPropertiesSelector(store),
                portabilityData: MobileNumberPortabilityModelSelector(store).asMutable(),
                postalCodeRegex: PostalCodeRegexSelector(store),
                providerAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.MnpServiceProviderConfiguration, store),
                providerLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.MnpServiceProviderConfiguration, store),
                providerOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.MnpServiceProviderConfiguration, store),
                regularExpressionLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RegularExpression, store),
                stateOptions: StateOptionsSelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
            };
        };

        const controllerActions = {
            mobileNumberPortabilitySetEditAdditionalProperty,
            mobileNumberPortabilitySetEditAdditionalPropertyOnChange,
            setForceShowEditFormAddress,
            updateMobileNumberPortabilityAddressModel,
            updateMobileNumberPortabilityModel,
            validateAddress
        };

        return this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    showHeaderErrorMessages() {
        return !this.portabilityForm.$submitted;
    }

    onAddressFormUpdate(addressFormModel) {
        const payload = {
            LineOne: addressFormModel.addressLine1,
            LineTwo: addressFormModel.addressLine2,
            City: addressFormModel.city,
            Country: addressFormModel.country,
            State: addressFormModel.stateRegionProvince,
            PostalCode: addressFormModel.postalCode
        };

        this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
        this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
        this.addressFormModel.city = addressFormModel.city;
        this.addressFormModel.country = addressFormModel.country;
        this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;

        this.actions.updateMobileNumberPortabilityAddressModel(payload);
    }

    updatePortabilityModel(model = {}) {
        Object.assign(this.state.portabilityData, model);
        this.actions.updateMobileNumberPortabilityModel(this.state.portabilityData);
    }

    setVars() {
        this.postalCodeRegex = {
            regEx: '',
            mask: ''
        };
    }

    resetForm() {
        this.state.portabilityData = {
            existingNumber: null,
            provider: null,
        };
        this.state.addressData = {
            LineOne: null,
            LineTwo: null,
            Country: null,
            City: null,
            State: null,
            PostalCode: null,
        };
        this.formErrors = [];
        if (this.portabilityForm) {
            this.portabilityForm.$setPristine();
            this.portabilityForm.$setUntouched();
        }
    }

    onPopupSubmit() {
        this.portabilityForm.$setSubmitted();
        this.validateAddressWithAPI()
            .then(() => {
                const addressKey = this.getAddressKey(),
                    validatedAddress = this.state.validatedAddresses[addressKey];
                if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress)) || this.bypassValidation) {
                    const providerItem = this.state.providerAll.find((item) => {
                        return item.Value === this.state.portabilityData.provider;
                    });
                    this.state.portabilityData.providerLabel = providerItem.Name;
                    this.onContinue()(clone(this.state.addressData),
                        clone(this.state.portabilityData));
                    this.resetForm();
                } else {
                    this.actions.setForceShowEditFormAddress(false);
                    this.uiNotificationService.transientError(i18n.translate(this.LocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                }
            })
            .catch((err) => {
                this.uiNotificationService.transientError(err.translatedMessage);
            });
    }

    onMobileNumberPortabilityAdditionalPropertyChange(id, newValue) {
        const findType = this.state.mnpAdditionalProperties.find(propEq(id, 'id'));
        switch (findType.type) {
            case AdditionalPropertyValueType.SINGLE_TEXT:
                this.actions.mobileNumberPortabilitySetEditAdditionalPropertyOnChange(id, [newValue]);
                break;
            case AdditionalPropertyValueType.MULTIPLE_TEXT:
                this.actions.mobileNumberPortabilitySetEditAdditionalPropertyOnChange(id, newValue);
                break;
            case AdditionalPropertyValueType.SELECT:
            case AdditionalPropertyValueType.BOOLEAN:
                newValue = newValue ? [getNameForCode(this.state.additionalPropValueSelector, newValue)] : [];
                this.actions.mobileNumberPortabilitySetEditAdditionalPropertyOnChange(id, newValue);
                break;
            default:
                break;
        }
    }

    onListUpdated() {
        this.actions.mobileNumberPortabilitySetEditAdditionalProperty(this.mnpAdditionalProperties);
    }

    handleAddressChange() {
        const selectedAddress = this.selectedAddress || {};
        this.onAddressFormUpdate({
            addressLine1: selectedAddress.LineOne || null,
            addressLine2: selectedAddress.LineTwo || null,
            country: selectedAddress.Country || this.addressFormModel.country,
            city: selectedAddress.City || null,
            stateRegionProvince: selectedAddress.State || null,
            postalCode: selectedAddress.PostalCode || null,
        });
    }

    getAddressKey() {
        return pathOr(undefined, ['portabilityForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['portabilityForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };
        this.portabilityForm['addressWrapupController.singleAddressFormFormApi'].submitted = true;

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else if (!unvalidatedAddress.addressLine1 ||
                !unvalidatedAddress.addressLine2 ||
                !unvalidatedAddress.country ||
                !unvalidatedAddress.postalCode ||
                !unvalidatedAddress.city ||
                !unvalidatedAddress.stateRegionProvince) {
            this.bypassValidation = true;
            return Promise.resolve(200);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    handleFormChanged() {
        this.onFormChanged()(this.state.portabilityData);
    }

    onClose() {
        this.resetForm();
        this.onCancel();
    }
}

export default {
    template: require('./mobile.number.portability.popup.template.html'),
    controller: MobileNumberPortabilityPopupController,
    bindings: {
        attribute: '<',
        onCancel: '&',
        onContinue: '&'
    }
};
