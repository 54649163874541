import angular from 'angular';

import {routing} from './billing.payments.config';
import AdjustmentModal from './../adjustment/adjustment.modal.module';
import AdjustmentPopout from './adjustment.detail.popout/adjustment.detail.popout.module';
import ApplyCreditNoteModal from './applyCreditNote/apply.credit.note.modal.module';
import BillingFutureInvoices from './futureInvoices/billing.future.invoices.module';
import CreateNoteModal from './createNote/createNote.modal.module';
import ApplyTaxCertificateModal from './applyTaxCertificate/applyTaxCertificate.modal.module';
import CreditNoteDetailsModule from './creditNote/credit.note.details.module';
import OpenInvoicesListComponent from './list/tabs/openInvoicesList/open.invoices.component';
import DetailModule from './detail/detail.module';
import ListModule from './list/list.module';

export default angular.module('invision.customercare.customer.billingpayments', [
    AdjustmentModal,
    AdjustmentPopout,
    ApplyCreditNoteModal,
    ApplyTaxCertificateModal,
    BillingFutureInvoices,
    CreateNoteModal,
    CreditNoteDetailsModule,
    DetailModule,
    ListModule
]
)
    .config(routing)
    .component('openInvoicesList', OpenInvoicesListComponent)
    .name;
