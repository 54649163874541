import __ from 'ramda/src/__';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';

import customerCareKeys from '../../../../locales/keys';
import {UNIT_OF_MEASURES} from '../../../../customercare.constants';

class EntitlementBalanceController {
    constructor(uiNotificationService, $ngRedux) {
        Object.assign(this, {
            $ngRedux,
            customerCareKeys,
            emptyCellEmDash: '—',
            isCurrencyBalanceUnitCode: false,
            isUnitBalanceUnitCode: false,
            showUnit: true,
            uiNotificationService,
            unitOfMeasure: undefined,
            UNIT_OF_MEASURES
        });
    }
    $onInit() {
        const codeTables = [
            CODES.UnitsOfMeasure
        ];

        const controllerActions = {
            fetchCodeTypes
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                unitOfMeasures: MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure, store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        Promise.all(codeTables.map((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                return this.actions.fetchCodeTypes(codeType);
            }
        })).then(() => {
            this.getUnitOfMeasure();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
        this.isCurrencyBalanceUnitCode = `${this.balanceUnitCode}` === this.UNIT_OF_MEASURES.CURRENCY;
        this.isUnitBalanceUnitCode = `${this.balanceUnitCode}` === this.UNIT_OF_MEASURES.UNITS;
    }

    $onChanges() {
        this.getUnitOfMeasure();
    }

    getUnitOfMeasure() {
        if (this.unitDisplayName) {
            this.unitOfMeasure = this.unitDisplayName;
        } else {
            const unitCode = this.state && this.state.unitOfMeasures[this.balanceUnitCode];
            this.unitOfMeasure = unitCode && (this.shortUnitName && unitCode.AdditionalProperties.short_name || unitCode.Name);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        balance: '<',
        balanceUnitCode: '<',
        currencyCode: '<?',
        isUnlimited: '<?',
        showUnit: '<?',
        shortUnitName: '<?',
        toFixedValue: '<?',
        unitDisplayName: '<?'
    },
    controller: EntitlementBalanceController,
    template: require('./entitlement.balance.template.html')
};