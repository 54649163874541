import Immutable from 'seamless-immutable';

// This is different from Subscriber Status
export const CUSTOMER_STATE = Immutable({
    PROSPECT: 0,
    ACTIVE: 1,
    DISCONNECTED: 2,
    ANONYMIZED: 3,
    BLOCKLISTED: 4,
    PROSPECT_LITE: 5
});

export const GLYPH_DICTIONARY = {
    active: 'complete',
    anonymized: 'removed',
    prospect: 'ellipses',
    prospect_lite: 'ellipses'
};

export const STATE_TRANSLATION_DICTIONARY = {
    0: 'PROSPECT',
    1: 'ACTIVE',
    2: 'DISCONNECTED',
    3: 'ANONYMIZED',
    4: 'BLOCKLISTED',
    5: 'PROSPECT_LITE'
};

export const STORE_ORDER_TYPE = {
    NONE: 0,
    ADD: 1,
    MODIFY: 2,
    TRANSITION: 3,
    REMOVE: 4,
    TRANSITION_REQUEST: 5
};

export const CUSTOMER_WRAPUP_COMMENTS_LENGTH = 1000;
export const CUSTOMER_WRAPUP_COMMENTS_DEBOUNCE = 500;

export const PROSPECT_LITE_CUSTOMER = 5;

export const VALIDATE_PIN_RESULT = {
    SUCCESS: 1,
    FAILURE: 2
};

export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const DEFAULT_TAX_SPLIT = 0.5;