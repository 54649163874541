import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {
    clone,
    path
} from 'ramda';
import {
    REGEXES
} from '../../../../../customercare.constants';

/*
    NOTE: This is a "dumb" component. Most logic can be found in order.details.component.js
*/

class ResendGiftNotificationModal {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
        this.currentDate = new Date();
        this.emailPattern = REGEXES.EMAIL;
        this.viewModel = {
            giftOrderInfo: {
                giftMessage: null,
                orderId: null,
                recipientEmail: null,
                recipientName: null
            },
            errors: [],
            isSubmitting: false
        };
    }

    $onChanges(changes) {
        if (changes.giftOrderInfo) {
            this.viewModel.giftOrderInfo = clone(changes.giftOrderInfo.currentValue);
        }

        if (changes.lastAttemptError) {
            this.viewModel.errors =  this._getLastAttemptErrorArray(changes.lastAttemptError.currentValue);
        }

        if (changes.isSubmitting) {
            this.viewModel.isSubmitting = changes.isSubmitting.currentValue;
        }
    }

    _getLastAttemptErrorArray(lastAttemptError) {
        const errorMessage = path(['message'], lastAttemptError);
        return errorMessage ? [errorMessage] : [];
    }

    edit() {
        const onEdit = this.onEdit;
        onEdit && onEdit()(this.viewModel.giftOrderInfo);
    }

    submit() {
        if (this.giftOrderInfoForm.$valid) {
            this.giftOrderInfoForm.$setPristine();

            const onSubmit = this.onSubmit;
            onSubmit && onSubmit()(this.viewModel.giftOrderInfo);
        }
    }

    close() {
        this.giftOrderInfoForm.$setPristine();

        const onClose = this.onClose;
        onClose && onClose()();
    }

}

export default {
    controller: ResendGiftNotificationModal,
    template: require('./resend.gift.notification.modal.html'),
    bindings: {
        onClose: '&',
        onSubmit: '&',
        onEdit: '&',
        isSubmitting: '<',
        giftOrderInfo: '<',
        lastAttemptError: '<'
    }
};
