import LocaleKeys from '../../../locales/keys';

import {
    i18n
} from 'invision-core';

export default function AdjustmentsDetailsPopout(uiTetherProvider) {
    return {
        restrict: 'AE',
        scope: {
            popupAttachment: '@',
            popupPosition: '@',
            uiPopoutCreditInvoiceNumber: '@',
            uiPopoutCreditNoteNumber:  '@',
            uiPopoutDescription: '@',
            uiPopoutInvoiceNumber: '@',
            uiPopoutOriginalPayment: '@',
            uiPopoutPaymentTransactionId: '@',
            uiPopoutReasoncode: '@',
            uiPopoutTransactionId: '@',
            uiPopoutUser: '@'
        },
        link: (scope, element) => {
            scope.$watch('uiPopoutDescription', function(newValue) {
                scope.description = newValue;
            });
            scope.additionalInformation = !!scope.uiPopoutCreditInvoiceNumber && i18n.translate(LocaleKeys.ADJUSTMENTS.CREDIT_INVOICE_DETAIL, {
                creditInvoiceNumber: scope.uiPopoutCreditInvoiceNumber
            });
            scope.creditNoteNumber = scope.uiPopoutCreditNoteNumber;
            scope.invoiceNumber = scope.uiPopoutInvoiceNumber;
            scope.originalPayment = scope.uiPopoutOriginalPayment;
            scope.paymentTransactionId = scope.uiPopoutPaymentTransactionId;
            scope.reasonCode = scope.uiPopoutReasoncode;
            scope.transactionId = scope.uiPopoutTransactionId;
            scope.user = scope.uiPopoutUser;

            scope.ADDITIONAL_INFORMATION = i18n.translate(LocaleKeys.ADDITIONAL_INFORMATION);
            scope.CREDIT_NOTE_NUMBER =  i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.CREDIT_NOTE_NUMBER);
            scope.DESCRIPTION =  i18n.translate(LocaleKeys.DESCRIPTION);
            scope.INVOICE_NUMBER = i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_NUMBER);
            scope.ORIGINAL_PAYMENT =  i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ORIGINAL_PAYMENT_AMOUNT);
            scope.REASON_CODE =  i18n.translate(LocaleKeys.REASON_CODE);
            scope.TRANSACTION_ID = i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.TRANSACTION_ID);
            scope.UNKNOWN_USER =  i18n.translate(LocaleKeys.UNKNOWN_USER);
            scope.USER =  i18n.translate(LocaleKeys.USER);
            uiTetherProvider({
                target: element,
                template: require('./adjustment.detail.popout.html'),
                scope: scope,
                position: scope.popupPosition ? scope.popupPosition : 'bottom center',
                openOn: 'click',
                tetherOptions: {
                    attachment: scope.popupAttachment ? scope.popupAttachment : 'top center'
                }
            });
        }
    };
}
