import pathOr from 'ramda/src/pathOr';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import isEmpty from 'ramda/src/isEmpty';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

import {validateNumbers} from 'invision-core/src/utilities/input.helper';

import LocaleKeys from '../../../locales/keys';
import {
    createDiscretionaryGridColumnDefs,
    createRegularGridColumnDefs,
    discountTypes
} from './decision.override.discount.dialog.helper';

class DecisionOverrideDiscountController {
    constructor(uiGridConstants, uiGridFilterBySearch) {
        Object.assign(this, {
            areOnlyGlobalDiscountsSelectedOnRegularTab: null,
            clientSearch: this.clientSearch.bind(this),
            clearSelectedDiscretionaryDiscounts: this.clearSelectedDiscretionaryDiscounts.bind(this),
            clearSelectedRegularDiscounts: this.clearSelectedRegularDiscounts.bind(this),
            CoreLocaleKeys,
            countryName: '',
            defaultDiscountsForSelectedBri: [],
            discountTypes,
            discretionaryDiscountTableData: [],
            discretionaryGrid: {},
            filterData: this.filterData.bind(this),
            filterText: '',
            getSavings: this.getSavings.bind(this),
            getMaxRegularDiscountSelectionText: this.getMaxRegularDiscountSelectionText.bind(this),
            hasZeroDiscount: false,
            initializeDiscretionaryTableDataSelections: this.initializeDiscretionaryTableDataSelections.bind(this),
            initializeRegularTableDataSelections: this.initializeRegularTableDataSelections.bind(this),
            isFilteredResultEmpty: false,
            isReasonCodeSelectionDisabled: this.isReasonCodeSelectionDisabled.bind(this),
            LocaleKeys,
            lookForDefault: this.lookForDefault.bind(this),
            onDiscretionaryGridRegisterApi: this.onDiscretionaryGridRegisterApi.bind(this),
            onDiscountOverrideTabSelected: this.onDiscountOverrideTabSelected.bind(this),
            regularDiscountTableData: [],
            regularGrid: {},
            selectedDiscretionaryDiscounts: {},
            selectedRegularDiscounts: {},
            selectedDiscretionaryReasonCode: null,
            showDiscretionaryTabContent: this.showDiscretionaryTabContent.bind(this),
            showRegularTabContent: this.showRegularTabContent.bind(this),
            totalDiscounts: 0,
            uiGridConstants,
            uiGridFilterBySearch,
            updateSelectedDiscretionaryDiscounts: this.updateSelectedDiscretionaryDiscounts.bind(this),
            updateOverriddenDiscountAmount: this.updateOverriddenDiscountAmount.bind(this),
            updateSelectedRegularDiscounts: this.updateSelectedRegularDiscounts.bind(this),
            validateNumbers
        });
    }

    $onInit() {
        this.discretionaryGrid = {
            enableSorting: true,
            appScopeProvider: this,
            onRegisterApi: this.onDiscretionaryGridRegisterApi,
            columnDefs: createDiscretionaryGridColumnDefs(),
            data: this.discretionaryTableData,
            enableHorizontalScrollbar: this.uiGridConstants.scrollbars.NEVER,
            enableFullRowSelection: true,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            rowTemplate: require('./rowTemplates/row.template.html')
        };

        this.regularGrid = {
            enableSorting: true,
            appScopeProvider: this,
            columnDefs: createRegularGridColumnDefs(),
            data: this.regularTableData,
            enableHorizontalScrollbar: this.uiGridConstants.scrollbars.NEVER,
            enableFullRowSelection: true,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            rowTemplate: require('./rowTemplates/row.template.html')
        };
        this.lookForDefault();
    }

    $onChanges(changesObj) {
        const currentDiscretionaryTableData = pathOr(undefined, ['discretionaryTableData', 'currentValue'], changesObj);
        if (this.discretionaryGrid && currentDiscretionaryTableData) {
            this.discretionaryGrid.data = this.initializeDiscretionaryTableDataSelections(currentDiscretionaryTableData);
        }
        const currentRegularTableData = pathOr(undefined, ['regularTableData', 'currentValue'], changesObj);
        if (this.regularGrid && currentRegularTableData) {
            this.regularGrid.data = this.initializeRegularTableDataSelections(currentRegularTableData);
        }
        this.defaultDiscountsForSelectedBri = pathOr([], ['option', 'DefaultSelectedDiscounts'], this.configuration).filter((selectedDiscounts) => {
            return selectedDiscounts.BillerRuleConfigurationId === this.selectedBri.billerRuleConfigurationId;
        });
    }

    initializeDiscretionaryTableDataSelections(discretionaryTableData) {
        let areAnyDiscretionaryDiscountsSelected = false;
        const updatedTableData = [];
        discretionaryTableData.forEach((tableData) => {
            const matchingSelectedDiscretionaryDiscountInfo = this.selectedDiscountsInfo.find((discountInfo) => {
                return (`${discountInfo.DiscountId}` === tableData.discountId) && discountInfo.DiscretionaryDiscountReason;
            });
            tableData.isSelected = !!matchingSelectedDiscretionaryDiscountInfo;
            if (tableData.isSelected) {
                areAnyDiscretionaryDiscountsSelected = true;
                this.selectedDiscretionaryReasonCode = matchingSelectedDiscretionaryDiscountInfo.DiscretionaryDiscountReason;
                this.updateSelectedDiscretionaryDiscounts(tableData);
            }
            updatedTableData.push(tableData);
        });
        return updatedTableData;
    }

    initializeRegularTableDataSelections(regularTableData) {
        let areAnyRegularDiscountsSelected = false;
        const updatedTableData = [];
        regularTableData.forEach((tableData) => {
            const matchingSelectedRegularDiscountInfo = this.selectedDiscountsInfo.find((discountInfo) => {
                return (`${discountInfo.DiscountId}` === tableData.discountId) &&
                    (discountInfo.DiscretionaryDiscountReason === undefined || discountInfo.DiscretionaryDiscountReason === null);
            });
            tableData.isSelected = !!matchingSelectedRegularDiscountInfo;
            if (tableData.isSelected) {
                areAnyRegularDiscountsSelected = true;
                this.updateSelectedRegularDiscounts(tableData);
            }
            updatedTableData.push(tableData);
        });
        return updatedTableData;
    }

    onDiscretionaryGridRegisterApi(gridApi) {
        this.discretionaryGridApi = gridApi;
        gridApi.grid.api.grid.registerRowsProcessor(this.clientSearch);
    }

    clientSearch(rows, columnDefs) {
        const filteredResult = this.uiGridFilterBySearch(rows, columnDefs, this.filterText);
        this.isFilteredResultEmpty = !filteredResult.some((item) => {
            return item.visible;
        });
        return filteredResult;
    }

    filterData(searchTerm) {
        this.filterText = searchTerm;
        this.discretionaryGridApi.grid.refresh(true);
    }

    getSavings(rowEntity) {
        return rowEntity.discountAmountType === this.discountTypes.percentageOff.value  ?
            +(this.selectedBri.chargeAmount * rowEntity.amount / 100) : +rowEntity.amount;
    }

    validateDiscounts() {
        if (this.totalDiscounts > this.selectedBri.chargeAmount) {
            this.warningMessage = i18n.translate(this.LocaleKeys.CART.DISCOUNT_MODAL.DISCOUNT_WARNING);
        } else {
            this.warningMessage = null;
        }

        const allDiscounts = [...Object.values(this.selectedRegularDiscounts), ...Object.values(this.selectedDiscretionaryDiscounts)];
        this.hasZeroDiscount = !!allDiscounts.find((discount) => {
            return discount.savings === 0;
        });
        if (this.hasZeroDiscount) {
            this.warningMessage = i18n.translate(this.LocaleKeys.CART.DISCOUNT_MODAL.AMOUNT_ZERO_WARNING);
        }
    }

    updateTotalDiscounts() {
        const totalRegularDiscounts = (Object.values(this.selectedRegularDiscounts) || []).reduce((accumulator, object) => {
            return object.isSelected ? accumulator + object.savings : accumulator;
        }, 0);
        const totalDiscretionaryDiscounts = (Object.values(this.selectedDiscretionaryDiscounts) || []).reduce((accumulator, object) => {
            return object.isSelected ? accumulator + object.savings : accumulator;
        }, 0);
        this.totalDiscounts = totalRegularDiscounts + totalDiscretionaryDiscounts;
        this.validateDiscounts();
    }

    updateSelectedDiscretionaryDiscounts(rowEntity) {
        if (rowEntity.isSelected) {
            this.selectedDiscretionaryDiscounts[rowEntity.discountId] = rowEntity;
        } else {
            delete this.selectedDiscretionaryDiscounts[rowEntity.discountId];
            this.discretionaryGrid.data = this.discretionaryGrid.data.map((item) => {
                return item.discountId === rowEntity.discountId ? {
                    ...item,
                    amount: item.defaultAmount,
                    savings: this.getSavings({
                        ...item,
                        amount: item.defaultAmount
                    }),
                    isOverridden: false
                } : item;
            });
        }
        this.updateTotalDiscounts();
    }

    updateSelectedRegularDiscounts(rowEntity) {
        if (rowEntity.isSelected) {
            this.selectedRegularDiscounts[rowEntity.discountId] = rowEntity;
        } else {
            delete this.selectedRegularDiscounts[rowEntity.discountId];
            this.regularGrid.data = this.regularGrid.data.map((item) => {
                return item.discountId === rowEntity.discountId ? {
                    ...item,
                    amount: item.defaultAmount,
                    savings: this.getSavings({
                        ...item,
                        amount: item.defaultAmount
                    }),
                    isOverridden: false
                } : item;
            });
        }
        this.updateTotalDiscounts();
        this.areOnlyGlobalDiscountsSelectedOnRegularTab = Object.values(this.selectedRegularDiscounts).every((selectedDiscount) => {
            return selectedDiscount.isGlobalDiscount;
        });
    }

    updateOverriddenDiscountAmount(rowEntity) {
        const savings = this.getSavings(rowEntity);

        if (this.showRegularTabContent()) {
            this.selectedRegularDiscounts[rowEntity.discountId] = {
                ...rowEntity,
                isOverridden: true,
                savings: savings
            };
        } else {
            this.selectedDiscretionaryDiscounts[rowEntity.discountId] = {
                ...rowEntity,
                isOverridden: true,
                savings: savings
            };
        }
        this.updateTotalDiscounts();
    }

    getMaxRegularDiscountSelectionText() {
        const selectionLimit = this.defaultDiscountsForSelectedBri.length || 1;
        return i18n.translate(
            this.defaultDiscountsForSelectedBri.length > 1 ?
                this.LocaleKeys.CART.DISCOUNT_MODAL.MAXIMUM_REGULAR_DISCOUNT_SELECTIONS :
                this.LocaleKeys.CART.DISCOUNT_MODAL.MAXIMUM_REGULAR_DISCOUNT_SELECTION, {
                max: selectionLimit
            });
    }

    clearSelectedDiscretionaryDiscounts() {
        this.discretionaryGrid.data.forEach((item) => {
            item.isSelected = false;
            item.amount = item.defaultAmount;
        });
        this.selectedDiscretionaryDiscounts = {};
        this.selectedDiscretionaryReasonCode = null;
        this.updateTotalDiscounts();
    }

    clearSelectedRegularDiscounts() {
        Object.keys(this.selectedRegularDiscounts).forEach((discountId) => {
            if (!this.selectedRegularDiscounts[discountId].isGlobalDiscount) {
                delete this.selectedRegularDiscounts[discountId];
            }
        });
        this.regularGrid.data.forEach((item) => {
            if (!item.isGlobalDiscount) {
                item.isSelected = false;
                item.amount = item.defaultAmount;
            }
        });
        this.areOnlyGlobalDiscountsSelectedOnRegularTab = this.areRegularDiscountsSelected();
        this.updateTotalDiscounts();
    }

    onDiscountOverrideTabSelected(selectedTab) {
        this.configuration.tabs.forEach((tab) => {
            tab.active = tab.name === selectedTab.name;
        });
        this.configuration.selectedTab = selectedTab;
    }

    areDiscretionaryDiscountsSelected() {
        return !isEmpty(this.selectedDiscretionaryDiscounts);
    }

    areRegularDiscountsSelected() {
        return !isEmpty(this.selectedRegularDiscounts);
    }

    showDiscretionaryTabContent() {
        return this.configuration.selectedTab.name === i18n.translate(this.LocaleKeys.CART.DISCOUNT_MODAL.DISCRETIONARY) && this.discretionaryTableData.length;
    }

    showRegularTabContent() {
        return this.configuration.selectedTab.name === i18n.translate(this.LocaleKeys.CART.DISCOUNT_MODAL.REGULAR) && this.regularTableData.length;
    }

    isReasonCodeSelectionDisabled() {
        return this.selectedDiscretionaryReasonCode &&
            pathOr(null, ['option', 'OfferingDecisionOptionInstances', 0, 'SubscriberProductId'], this.configuration) ||
            pathOr(null, ['option', 'OfferingDecisionOptionInstances', 0, 'TransferSubscriberProductId'], this.configuration);
    }

    onCloseHandler() {
        this.configuration.onCloseModal();
    }

    handleFormSubmit() {
        this.discountForm.$setSubmitted();
        this.validateDiscounts();
        if (!this.warningMessage) {
            if (this.discountForm.$valid) {
                this.warningMessage = null;
                const selectedDiscretionaryDiscountsWithReasonCode =
                    this.getSelectedDiscretionaryDiscountsWithReasonCode(this.selectedDiscretionaryDiscounts, this.selectedDiscretionaryReasonCode);
                const allSelectedDiscounts = Object.assign({}, selectedDiscretionaryDiscountsWithReasonCode, this.selectedRegularDiscounts);
                const selectedDiscountsInfo = Object.values(allSelectedDiscounts).map((discount) => {
                    const discountInfo = {
                        BillerRuleConfigurationId: this.selectedBri.billerRuleConfigurationId,
                        DiscountId: discount.discountId,
                        DiscountAmount: discount.savings,
                        ...(discount.isOverridden ? {
                            DiscountOverrideAmount:
                            discount.discountAmountType === this.discountTypes.percentageOff.value  ? discount.amount/100 : discount.amount
                        } : {}),
                        SubscriberProductDiscountId: (this.selectedDiscountsInfo.find((discountInfo) => {
                            return (discountInfo.BillerRuleConfigurationId === this.selectedBri.billerRuleConfigurationId) &&
                                discountInfo.DiscountId === convertStringToNumber(discount.discountId);
                        }) || {}).SubscriberProductDiscountId
                    };
                    if (discount.discretionaryDiscountReason) {
                        discountInfo.DiscretionaryDiscountReason = discount.discretionaryDiscountReason;
                    }
                    return discountInfo;
                });
                this.configuration.onSubmit(this.configuration.decision.Id, this.configuration.option.Id, this.selectedBri.billerRuleConfigurationId,  selectedDiscountsInfo);
            } else {
                this.onDiscountOverrideTabSelected({
                    name: i18n.translate(this.LocaleKeys.CART.DISCOUNT_MODAL.DISCRETIONARY)
                });
            }
        }
    }

    getSelectedDiscretionaryDiscountsWithReasonCode(selectedDiscretionaryDiscounts, reasonCode) {
        const selectedDiscretionaryDiscountsWithReasonCode = {};
        Object.keys(selectedDiscretionaryDiscounts).forEach((discountId) => {
            selectedDiscretionaryDiscountsWithReasonCode[`${discountId}`] = Object.assign({}, selectedDiscretionaryDiscounts[`${discountId}`], {
                discretionaryDiscountReason: reasonCode
            }) ;
        });
        return selectedDiscretionaryDiscountsWithReasonCode;
    }

    handleRemoveDiscount() {
        this.configuration.onRemoveDiscount(this.configuration.decision.Id, this.configuration.option.Id);
    }

    lookForDefault() {
        const bri = pathOr({}, ['PricingPlanBillerRuleInstances', 'RecurringBillerRuleInstances', 0], this.configuration.selectedOption);
        const discount = pathOr([], ['BillerRuleInstanceDiscounts'], bri).find((dis) => {
            return !!dis.IsDefault;
        });
        const defaultDiscount = discount ? discount.DiscountId : null;
        if ( defaultDiscount ) {
            this.selectedDiscount = `${discount.DiscountId}_${bri.Id}`;
        }
    }
}

export default {
    bindings: {
        configuration: '<',
        discretionaryTableData: '<',
        hasMultipleDiscountAccess: '<',
        isLoading: '<',
        regularTableData: '<',
        selectedBri: '<',
        selectedDiscountsInfo: '<',
        warningMessage: '<'
    },
    template: require('./decision.override.discount.dialog.template.html'),
    controller: DecisionOverrideDiscountController
};
