export const MAKE_PAYMENT_TYPES = {
    DASHBOARD: 0,
    BILLING_PAYMENTS: 1,
    INVOICE: 2
};

export const BLANK_PAYMENT_INSTRUMENT = {
    Id: 0,
    CreditCard: {},
    ECheck: {},
    ExternalBill: {},
    ExternalGiftCard: {},
    GiftCard: {},
    BillingAddress: {
        Id: 0,
        Country: null,
        State: null
    },
    Type: {}
};

export const BLANK_PAYMENT_INSTRUMENT_CHECKOUT_AUTO_PAY = {
    Id: 0,
    CreditCard: {},
    ECheck: {},
    ExternalBill: {},
    ExternalGiftCard: {},
    GiftCard: {},
    BillingAddress: {
        Id: 0,
        Country: null,
        State: null
    },
    Type: '0'
};

export const BLANK_BILLING_ADDRESS_NEW = {
    DefaultBilling: true,
    City: null,
    Country: null,
    LineOne: null,
    LineTwo: null,
    Name: null,
    PostalCode: null,
    ShipToName: null,
    State: null
};

export default {
    MAKE_PAYMENT_TYPES:MAKE_PAYMENT_TYPES
};

export const BILLPAY_OPTIONS = {
    INSTALLMENT_TOTAL: 'installmentTotal',
    TREATMENT_BALANCE: 'treatmentBalance',
    STATEMENT_BALANCE: 'statementBalance',
    ACCOUNT_TOTAL: 'accountTotal',
    OTHER_AMOUNT: 'otherAmount',
    DEVICE_FINANCING: 'deviceFinancing'
};

export const NO_FORM_ERRORS = [];

export const MAKE_PAYMENT_ROUTE = 'index.customercare.customer.makePayment';

export const ACCOUNTING_METHODS_NAMES = {
    BALANCE_FORWARD: 1,
    OPEN_INVOICE: 2
};