import {
    includes as contains,
    values
} from 'ramda';
import moment from 'moment';
import {
    AddressHelper,
    i18n,
    LogHelper,
    MetadataConstants,
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    ADD_TO_BLOCKLIST_MENU_ID,
    ADD_TO_ALLOWLIST_MENU_ID,
    PAYMENT_METHOD_STATUSES,
    REACTIVATE_PAYMENT_METHOD_OPTION_ID,
    OPT_OUT_REACTIVATE_PAYMENT_INSTRUMENT_TYPES,
    REMOVE_FROM_BLOCKLIST_MENU_ID,
    REMOVE_FROM_ALLOWLIST_MENU_ID,
    REMOVE_PAYMENT_METHOD_OPTION_ID,
    ANONYMIZE_PAYMENT_METHOD_OPTION_ID,
    SET_AS_DEFAULT_PAYMENT_METHOD_OPTION_ID,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES,
    VIEW_TRANSACTION_HISTORY_MENU_ID
} from '../ewallet.constants';
import {paymentTypeNameIdentifier} from '../ewallet.helpers';
import BWConstants from '../modals/blocklist.allowlist.constants';
import {
    paymentInstrumentCanBeSetAsDefault,
    showAddToBlocklist,
    showRemoveFromBlocklist,
    showAddToAllowlist,
    showRemoveFromAllowlist
} from './more.menu.helpers';

let removePaymentMethodConfPopupApi = null;
let removePaymentMethodErrorPopupApi = null;
let anonymizePaymentMethodConfPopupApi = null;
let anonymizePaymentMethodErrorPopupApi = null;
let updateSubscriptionsConfPopupApi = null;
let activatePaymentMethodErrorPopupApi = null;
let setDefaultPaymentMethodErrorPopupApi = null;
let bwModalPopupApi = null;
let bwModalErrorPopupApi = null;

class EwalletDetailController {
    constructor(uiNotificationService, $timeout) {
        Object.assign(this, {
            $timeout,
            uiNotificationService,
            localeKeys: CustomerCareLocaleKeys,
            paymentTypeNameIdentifier,
            showBWModal: false,
            paymentInstrumentTypes: SUPPORTED_PAYMENT_INSTRUMENT_TYPES
        });
    }

    $onInit() {
        this.awaitingAsyncRemovePaymentMethod = false;
        this.awaitingAsyncAnonymizePaymentMethod = false;

        this.buildMoreMenuOptions();

        this.removePaymentMethodConfPopupConfig = {
            onRegisterApi: (event) => {
                removePaymentMethodConfPopupApi = event.api;
            }
        };

        this.removePaymentMethodErrorPopupConfig = {
            onRegisterApi: (event) => {
                removePaymentMethodErrorPopupApi = event.api;
            }
        };

        this.anonymizePaymentMethodConfPopupConfig = {
            onRegisterApi: (event) => {
                anonymizePaymentMethodConfPopupApi = event.api;
            }
        };

        this.anonymizePaymentMethodErrorPopupConfig = {
            onRegisterApi: (event) => {
                anonymizePaymentMethodErrorPopupApi = event.api;
            }
        };

        this.updateSubscriptionsConfPopupConfig = {
            onRegisterApi: (event) => {
                updateSubscriptionsConfPopupApi = event.api;
            }
        };

        this.activatePaymentMethodErrorPopupConfig = {
            onRegisterApi: (event) => {
                activatePaymentMethodErrorPopupApi = event.api;
            }
        };

        this.setDefaultPaymentMethodErrorPopupConfig = {
            onRegisterApi: (event) => {
                setDefaultPaymentMethodErrorPopupApi = event.api;
            }
        };

        this.bwModalPopupConfig = {
            onRegisterApi: (event) => {
                bwModalPopupApi = event.api;
            }
        };

        this.bwModalErrorPopupConfig = {
            onRegisterApi: (event) => {
                bwModalErrorPopupApi = event.api;
            }
        };

        this.handleResultRemovePaymentMethodConfirmationPopup = (isConfirmed) => {
            if (this.awaitingAsyncRemovePaymentMethod) {
                LogHelper('remove submitted while awaiting async op: funk awaits');
            }
            if (isConfirmed) {
                this.awaitingAsyncRemovePaymentMethod = true;
                removePaymentMethodConfPopupApi.close();
                // NOTE: inv-popup and inv-confirmation-dialog don't interop;
                // encapsulate the "close on action" concern here, via promises
                const initialPromise = this.paymentMethodRemove()(this.currentCustomerId, this.paymentMethod.Id);
                const endAsyncRemovPaymentMethod = () => {
                    this.awaitingAsyncRemovePaymentMethod = false;
                    return initialPromise;
                };
                // actual fulfill/reject handler for inv-confirmation-dialog action
                return initialPromise.then(endAsyncRemovPaymentMethod, endAsyncRemovPaymentMethod).then(() => {
                    this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.E_WALLET.REMOVE_SUCCESS_MESSAGE));
                    const onPaymentMethodRemoved = this.onPaymentMethodRemoved();
                    onPaymentMethodRemoved && onPaymentMethodRemoved(this.paymentMethod);
                }, () => {
                    removePaymentMethodErrorPopupApi.open();
                });
            } else {
                // user clicked cancel
                removePaymentMethodConfPopupApi.close();
            }
        };

        this.handleResultAnonymizePaymentMethodConfirmationPopup = (isConfirmed) => {
            if (this.awaitingAsyncAnonymizePaymentMethod) {
                LogHelper('anonymize submitted while awaiting async op');
            }
            if (isConfirmed) {
                this.awaitingAsyncAnonymizePaymentMethod = true;
                anonymizePaymentMethodConfPopupApi.close();
                // NOTE: inv-popup and inv-confirmation-dialog don't interop;
                // encapsulate the "close on action" concern here, via promises
                const initialPromise = this.paymentMethodAnonymize()(this.currentCustomerId, this.paymentMethod.Id);
                const endAsyncAnonymizePaymentMethod = () => {
                    this.awaitingAsyncAnonymizePaymentMethod = false;
                    return initialPromise;
                };
                // actual fulfill/reject handler for inv-confirmation-dialog action
                return initialPromise.then(endAsyncAnonymizePaymentMethod, endAsyncAnonymizePaymentMethod).then(() => {
                    this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.E_WALLET.ANONYMIZE_SUCCESS_MESSAGE));
                    const onPaymentMethodAnonymized = this.onPaymentMethodAnonymized();
                    onPaymentMethodAnonymized && onPaymentMethodAnonymized(this.paymentMethod);
                }, () => {
                    anonymizePaymentMethodErrorPopupApi.open();
                });
            } else {
                // user clicked cancel
                anonymizePaymentMethodConfPopupApi.close();
            }
        };

        this.activatePaymentMethod = () => {

            const initialPromise = this.activatePaymentInstrument()(this.currentCustomerId, this.paymentMethod);
            const endAsyncActivatePaymentMethod = () => {
                return initialPromise;
            };
            // actual fulfill/reject handler for inv-confirmation-dialog action
            return initialPromise.then(endAsyncActivatePaymentMethod, endAsyncActivatePaymentMethod).then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.E_WALLET.ACTIVATE_SUCCESS_MESSAGE));
            }, () => {
                activatePaymentMethodErrorPopupApi.open();
            });
        };

        this.handleResultUpdateSubscriptionsConfirmationPopup = (isConfirmed) => {
            this.setPaymentMethodAsDefault(isConfirmed);
            updateSubscriptionsConfPopupApi.close();
        };

        this.handleCloseBWModalPopup = (shouldUpdatePaymentMethod, error) => {
            this.$timeout(() => {
                bwModalPopupApi.close();
                if (error) {
                    bwModalErrorPopupApi.open();
                }
            });
            if (shouldUpdatePaymentMethod) {
                this.onPaymentMethodUpdated()();
            }
            this.showBWModal = false;
        };

        this.handleResultRemovePaymentMethodConfirmationPopup.bind(this);
        this.handleResultAnonymizePaymentMethodConfirmationPopup.bind(this);
        this.handleMoreOptionsMenuSelected.bind(this);
        this.activatePaymentMethod.bind(this);

        if (!this.braintreeMethodTypesLoaded) {
            this.fetchCodeType()(MetadataConstants.codes.BraintreeMethodType);
        }

        if (!this.brandableCurrenciesLoaded) {
            this.fetchCodeType()(MetadataConstants.codes.BrandableCurrency);
        }
    }

    $onChanges() {
        this.buildMoreMenuOptions();
    }

    $onDestroy() {
        bwModalPopupApi.close();
    }

    get formattedCreditCardExpiration() {
        if (!this.paymentMethod) {
            return '';
        }
        const {ExpirationMonth, ExpirationYear} = this.paymentMethod.CreditCard;
        return `${ExpirationMonth}/${ExpirationYear.slice(-2)}`;
    }

    get isStripeCreditCardExpired() {
        return moment(this.paymentInstrument.StripeAccount.CardExpirationDate, 'MM/YYYY').isBefore(moment(), 'month');
    }

    get formattedBillingCityState() {
        if (!this.paymentMethod) {
            return '';
        }
        const {City = '', State = '', Country = '', PostalCode = ''} = this.paymentMethod.BillingAddress || {};
        const countryAndPostalCode = AddressHelper.formatCountryAndPostalCode(Country, PostalCode).countryAndPostalCode;
        return `${City ? `${City}, `: ''}${State ? `${State}, `: ''}${countryAndPostalCode}`;
    }

    get removePaymentMethodMessage() {
        return !this.autopayEnableForPaymentMethod ? this.localeKeys.E_WALLET.CONFIRM_REMOVE_MESSAGE : this.localeKeys.E_WALLET.CONFIRM_REMOVE_AUTO_PAY_MESSAGE;
    }

    get isCreditCard() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD && this.paymentMethod.CreditCard;
    }
    get isGiftCard() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD && this.paymentMethod.GiftCard;
    }
    get isExternalGiftCard() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD && this.paymentMethod.ExternalGiftCard;
    }
    get isPayPal() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.PAY_PAL && this.paymentMethod.PayPalAccount;
    }
    get isItunes() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ITUNES && this.paymentMethod.ITunesAccount;
    }

    get isStripeAccount() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STRIPE_ACCOUNT && this.paymentMethod.StripeAccount;
    }

    get isExpired() {
        return moment(this.formattedCreditCardExpiration, 'MM/YY').isBefore(moment(), 'month');
    }

    get hasAdditionalStatus() {
        return this.paymentMethod.Default || this.paymentMethod.Blocklisted || this.paymentMethod.Allowlisted;
    }

    get isLocked() {
        return Boolean(this.paymentMethod.Locked);
    }

    get canEdit() {
        const baseEditPermission = this.userHasAdminPermission() && this.currentSubHasEditPermission && this.paymentMethod.actionables.canEdit && this.isEwalletEditable;

        if (String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD) {
            return baseEditPermission && this.paymentMethod.requiresBillingAddress;
        } else {
            return baseEditPermission;
        }
    }

    get canRemove() {
        return this.userHasAdminPermission() && this.currentSubHasEditPermission && !this.isLocked;
    }

    get canReactivate() {
        const isPaymentTypeOptedOut = contains(String(this.paymentMethod.Type), values(OPT_OUT_REACTIVATE_PAYMENT_INSTRUMENT_TYPES));
        return !isPaymentTypeOptedOut && this.userHasAdminPermission() && this.currentSubHasEditPermission &&
            !this.paymentMethod.Blocklisted && !this.isCreditCardWithEmptyAccountNumber;
    }

    get canBlocklist() {
        return this.userHasAdminPermission() && this.currentSubHasEditPermission;
    }

    get isActivePaymentMethod() {
        return this.paymentMethod.Status === PAYMENT_METHOD_STATUSES.ACTIVE;
    }

    get canSetDefaultPaymentMethod() {
        return this.isActivePaymentMethod && !this.paymentMethod.Default && this.userHasAdminPermission() && paymentInstrumentCanBeSetAsDefault(this.paymentMethod);
    }

    get hasMoreMenuOptions() {
        return this.moreOptionsMenuItems.length > 0;
    }

    get isCreditCardWithEmptyAccountNumber() {
        return String(this.paymentMethod.Type) === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD &&
            !this.paymentMethod.CreditCard.AccountNumber;
    }

    handleEditPaymentMethodClicked() {
        this.editPaymentMethod(this.paymentMethod);
    }

    setPaymentMethodAsDefault(applyToSubscriptions) {
        const initialPromise = this.setPaymentInstrumentDefault()(this.currentCustomerId, this.paymentMethod, applyToSubscriptions);
        const endAsyncSetDefaultPaymentMethod = () => {
            return initialPromise;
        };
        // actual fulfill/reject handler for inv-confirmation-dialog action
        return initialPromise.then(endAsyncSetDefaultPaymentMethod, endAsyncSetDefaultPaymentMethod).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareLocaleKeys.E_WALLET.SET_DEFAULT_SUCCESS_MESSAGE));
        }, () => {
            setDefaultPaymentMethodErrorPopupApi.open();
        });
    }

    handleSetPaymentMethodAsDefault() {
        if (this.defaultPaymentMethodHasSubscriptions &&
            !this.defaultPaymentMethodIsReadOnly &&
            this.currentPaymentMethodIsDirectSource) {
            updateSubscriptionsConfPopupApi.open();
        } else {
            this.setPaymentMethodAsDefault(false);
        }
    }

    handleMoreOptionsMenuSelected(item) {
        const selectedPaymentMethod = {
            id: this.paymentMethod.Id,
            value: this.paymentMethod.Id,
            text: this.paymentMethod.Name
        };

        switch (item.item.id) {
            case VIEW_TRANSACTION_HISTORY_MENU_ID:
                this.setPaymentInstrumentFilter()(selectedPaymentMethod);
                this.stateGo()('index.customercare.customer.transactions.list', {
                    selectedPaymentId: this.paymentMethod.Id
                });
                break;
            case ADD_TO_BLOCKLIST_MENU_ID:
                this.modalState = BWConstants.STATE_ADD_TO_BLOCKLIST;
                this.showBWModal = true;
                this.$timeout(() => {
                    bwModalPopupApi.open();
                });
                break;
            case ADD_TO_ALLOWLIST_MENU_ID:
                this.modalState = BWConstants.STATE_ADD_TO_ALLOWLIST;
                this.showBWModal = true;
                this.$timeout(() => {
                    bwModalPopupApi.open();
                });
                break;
            case REMOVE_FROM_BLOCKLIST_MENU_ID:
                this.modalState = BWConstants.STATE_REMOVE_FROM_BLOCKLIST;
                this.showBWModal = true;
                this.$timeout(() => {
                    bwModalPopupApi.open();
                });
                break;
            case REMOVE_FROM_ALLOWLIST_MENU_ID:
                this.modalState = BWConstants.STATE_REMOVE_FROM_ALLOWLIST;
                this.showBWModal = true;
                this.$timeout(() => {
                    bwModalPopupApi.open();
                });
                break;
            case REMOVE_PAYMENT_METHOD_OPTION_ID:
                removePaymentMethodConfPopupApi.open();
                break;
            case ANONYMIZE_PAYMENT_METHOD_OPTION_ID:
                anonymizePaymentMethodConfPopupApi.open();
                break;
            case REACTIVATE_PAYMENT_METHOD_OPTION_ID:
                this.activatePaymentMethod();
                break;
            case SET_AS_DEFAULT_PAYMENT_METHOD_OPTION_ID:
                this.handleSetPaymentMethodAsDefault();
                break;
            default:
                break;
        }
    }

    buildMoreMenuOptions() {
        this.moreOptionsMenuItems = [];

        if (this.canSetDefaultPaymentMethod && !this.isExternallyManagedArCustomer && !this.isLocked) {
            this.moreOptionsMenuItems.push({
                id: SET_AS_DEFAULT_PAYMENT_METHOD_OPTION_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.SET_AS_DEFAULT_PAYMENT_METHOD)
            });
        }

        this.moreOptionsMenuItems.push({
            id: 'VIEW_TRANSACTION_HISTORY',
            title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.VIEW_PAYMENT_HISTORY)
        });

        if (this.isActivePaymentMethod && this.canRemove && this.paymentMethod.Type !== parseInt(this.paymentInstrumentTypes.CURRENCY_ENTITLEMENT)) {
            this.moreOptionsMenuItems.push({
                id: REMOVE_PAYMENT_METHOD_OPTION_ID,
                title: i18n.translate(CustomerCareLocaleKeys.REMOVE)
            });
        }

        if (this.canRemove && this.canAnonymizePaymentMethod && (this.isCreditCard || this.isStripeAccount)) {
            this.moreOptionsMenuItems.push({
                id: ANONYMIZE_PAYMENT_METHOD_OPTION_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.ANONYMIZE_PAYMENT_METHOD_MENU_OPTION)
            });
        }

        if (this.paymentMethod.Status === PAYMENT_METHOD_STATUSES.REMOVED && this.canReactivate) {
            this.moreOptionsMenuItems.push({
                id: REACTIVATE_PAYMENT_METHOD_OPTION_ID,
                title: i18n.translate(CustomerCareLocaleKeys.REACTIVATE)
            });
        }

        if (this.canBlocklist && showAddToBlocklist(this.paymentMethod)) {
            this.moreOptionsMenuItems.push({
                id: ADD_TO_BLOCKLIST_MENU_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.MODALS.ADD_TO_BLOCKLIST_MENU_TEXT)
            });
        }

        if (this.canBlocklist && showRemoveFromBlocklist(this.paymentMethod)) {
            this.moreOptionsMenuItems.push({
                id: REMOVE_FROM_BLOCKLIST_MENU_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.MODALS.REMOVE_FROM_BLOCKLIST_MENU_TEXT)
            });
        }

        if (this.canBlocklist && showAddToAllowlist(this.paymentMethod)) {
            this.moreOptionsMenuItems.push({
                id: ADD_TO_ALLOWLIST_MENU_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.MODALS.ADD_TO_ALLOWLIST_MENU_TEXT)
            });
        }

        if (this.canBlocklist && showRemoveFromAllowlist(this.paymentMethod)) {
            this.moreOptionsMenuItems.push({
                id: REMOVE_FROM_ALLOWLIST_MENU_ID,
                title: i18n.translate(CustomerCareLocaleKeys.E_WALLET.MODALS.REMOVE_FROM_ALLOWLIST_MENU_TEXT)
            });
        }
    }

    handleCloseUpdateSubscriptionsConfirmationPopup() {
        updateSubscriptionsConfPopupApi.close();
    }

    handleResultActivatePaymentMethodErrorPopup() {
        activatePaymentMethodErrorPopupApi.close();
    }

    handleCloseActivatePaymentMethodErrorPopup() {
        activatePaymentMethodErrorPopupApi.close();
    }

    handleCloseRemovePaymentMethodConfirmationPopup() {
        removePaymentMethodConfPopupApi.close();
    }

    handleResultRemovePaymentMethodErrorPopup() {
        removePaymentMethodErrorPopupApi.close();
    }

    handleCloseRemovePaymentMethodErrorPopup() {
        removePaymentMethodErrorPopupApi.close();
    }

    handleCloseAnonymizePaymentMethodConfirmationPopup() {
        anonymizePaymentMethodConfPopupApi.close();
    }

    handleResultAnonymizePaymentMethodErrorPopup() {
        anonymizePaymentMethodErrorPopupApi.close();
    }

    handleCloseAnonymizePaymentMethodErrorPopup() {
        anonymizePaymentMethodErrorPopupApi.close();
    }

    handleResultSetDefaultPaymentMethodErrorPopup() {
        setDefaultPaymentMethodErrorPopupApi.close();
    }

    handleCloseSetDefaultPaymentMethodErrorPopup() {
        setDefaultPaymentMethodErrorPopupApi.close();
    }

    handleCloseBwModalPopupConfigErrorPopup() {
        bwModalErrorPopupApi.close();
    }
}

export default {
    bindings: {
        activatePaymentInstrument: '&',
        braintreeMethodTypesLoaded: '<',
        brandableCurrenciesLoaded: '<',
        currentCustomerId: '<',
        currentPaymentMethodIsDirectSource: '<',
        currentSubHasEditPermission: '<',
        defaultPaymentMethodHasSubscriptions: '<',
        defaultPaymentMethodIsReadOnly: '<',
        editPaymentMethod: '<',
        fetchCodeType: '&',
        autopayEnableForPaymentMethod: '<',
        isEwalletEditable: '<',
        isExternallyManagedArCustomer: '<',
        lastAttemptError: '<',
        onPaymentMethodRemoved: '&',
        onPaymentMethodUpdated: '&',
        paymentMethod: '<',
        paymentMethodDetails: '<',
        paymentMethodRemove: '&',
        paymentMethodAnonymize: '&',
        onPaymentMethodAnonymized: '&',
        paymentMethodType: '<',
        setPaymentInstrumentDefault: '&',
        setPaymentInstrumentFilter: '&',
        stateGo: '&',
        userHasAdminPermission: '<',
        canAnonymizePaymentMethod: '<'
    },
    template: require('./ewallet.detail.html'),
    controller: EwalletDetailController,
    require: {
        parent: '^customer-ewallet'
    }
};
