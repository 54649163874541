import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../../../../../locales/keys';
import {SERVICE_FEATURE_ORDER_WIZARD_MODE} from '../../../../../../../reducers/constants/products.wizard.constants';

export const isServiceFeatureOrder = (routeParams) => {
    return (routeParams.service || routeParams.serviceId) &&
        routeParams.mode === SERVICE_FEATURE_ORDER_WIZARD_MODE.MANAGE;
};

export const setProductListItemSelected = (shoppingCart, products) => {
    if (shoppingCart.Items && shoppingCart.Items.length) {
        shoppingCart.Items.forEach((item) => {
            const selectedProduct = products.find((product) => {
                return product.Id === item.ProductId;
            });
            if (selectedProduct) {
                //null check needed due to search clearing out product that was already selected
                selectedProduct.isSelected = true;
            }
        });
    }
};

export const getButtonCount = (shoppingCart, products) => {
    if (shoppingCart.Items && shoppingCart.Items.length) {
        shoppingCart.Items.forEach((item) => {
            const selectedProduct = products.find((product) => {
                return product.Id === item.ProductId;
            });
            if (selectedProduct) {
                //null check needed due to search clearing out product that was already selected
                selectedProduct.buttonCount += item.Quantity;
                selectedProduct.selectedText = i18n.translate(CustomerCareKeys.EDIT_OPTIONS, {
                    count: selectedProduct.buttonCount
                });
            }
        });
    }
};

export const setPricingPlanQuantities = (shoppingCart, products) => {
    if (shoppingCart.Items && shoppingCart.Items.length) {
        shoppingCart.Items.forEach((item) => {
            const selectedProduct = products.find((product) => {
                return product.Id === item.ProductId;
            });
            const selectedPricingPlan = selectedProduct && selectedProduct.ProductContext &&
                selectedProduct.ProductContext.OrderablePricingPlans && selectedProduct.ProductContext.OrderablePricingPlans.length ?
                selectedProduct.ProductContext.OrderablePricingPlans.find((pricingPlan) => {
                    return pricingPlan.Id === item.PricingPlanId;
                }) : null;

            if (selectedPricingPlan) {
                selectedPricingPlan.cartQuantity = item.Quantity;
            }
        });
    }
};
