import {ORDER_ACCESS} from '../../../security.attributes';

export const ORDERS_STATE_NAME = 'index.customercare.customer.orders';

export default ($stateProvider) => {
    $stateProvider.state(ORDERS_STATE_NAME, {
        url: '/orders',
        template: '<orders ui-view></orders>',
        securityAttributes: [
            ORDER_ACCESS
        ]
    });
};
