import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {IsDbss, CurrentBusinessUnitTimeZoneSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {DateTimeHelper} from 'invision-core';
import {
    fetchCodeTypesThunk,
    fetchNetworkEventTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import LocaleKeys from '../../../locales/keys';
import {BILL_ADJUSTMENT} from '../../adjustment/adjustment.modal.constants';
import * as BillingPaymentsConstants from '../billing.payments.constants';
import {CASE_CATEGORIES} from '../../customer/cases/list/case.constants';
import {
    createConvergentBillerAdjustment,
    retrieveAndOpenConvergentBillerLedgerInvoice,
    retrieveLedgerInvoiceDetails,
    retrieveLedgerSummary,
    setPageSizePreference,
    updatePageNumber
} from '../../../reducers/actions/customer.billing.actions';
import {
    CurrentCustomerAccountingMethodSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerNameSelector,
    IsPIIDataAccessEnabledSelector,
    RouteParams
} from '../../../reducers/selectors/customer.selectors';
import {
    AdjustmentConfigSelector,
    CombinedBillingCurrencyCodeSelector,
    InvoiceDetailsPageCountSelector,
    InvoiceDetailsRecordCountSelector,
    InvoiceDetailsSummarySelector,
    InvoiceItemsTableDataSelector,
    InvoiceSummaryByIdSelector,
    IsFetchingInvoiceContentSelector,
    IsFetchingInvoiceDetailsSelector,
    PageNumberSelector,
    PageSizeSelector,
    SelectedPageSizePreference,
} from '../../../reducers/selectors/customer.billing.selectors';
import {
    IsCurrentAccountPostpaidSelector
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    ALLOW_CREDIT_DEBIT_NOTE,
    ONE_TIME_MAKE_PAYMENT,
    PAYMENT_ADJUSTMENT_ACCESS
} from '../../../security.attributes';
import * as DETAILS_CONSTANTS from './detail.constants';
import MakePaymentConstants, {MAKE_PAYMENT_ROUTE} from '../../customer/makePayment/make.payment.constants';
import {ACCOUNTING_METHODS} from '../../createCustomer/billingInformationSection/billing.information.section.constants';
import {ACCOUNTING_METHOD_TYPES} from '../../../customercare.constants';
import * as SessionSelectors from 'invision-core/src/components/session/session.selectors';
import {FUTURE_INVOICE_URL} from '../billing.payments.constants';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';

class BillingPaymentsInvoiceController {
    constructor($ngRedux, $state, $stateParams, $filter, $scope, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $stateParams,
            $filter,
            $scope,
            $timeout,
            ACCOUNTING_METHODS: ACCOUNTING_METHODS,
            LocaleKeys: LocaleKeys,
            ADJUSTMENT_TYPE: BillingPaymentsConstants.OVERALL,
            DUE_DATE: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.DUE_DATE),
            futureInvoiceHasError: false,
            goToMakePayment: this.goToMakePayment.bind(this),
            hasMakeAPaymentAccess: this.hasMakeAPaymentAccess.bind(this),
            handleMoreMenuOptions: this.handleMoreMenuOptions.bind(this),
            handleConvertToCreditModalClose: this.handleConvertToCreditModalClose.bind(this),
            onConvertToCreditFormSubmit: this.onConvertToCreditFormSubmit.bind(this),
            goBack: this.goBack.bind(this),
            moreMenuOptions: [],
            isInvoiceAmountNegative: false,
            isConvertToCreditModalOpen: false,
            isOpenInvoice: false,
            INVOICE_BILL_DATES: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_BILL_DATES),
            INVOICE_DETAILS: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_DETAILS),
            INVOICE_NUMBER: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_NUMBER),
            INVOICE_SUMMARY: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_SUMMARY),
            LOADING: i18n.translate(LocaleKeys.LOADING),
            MAKE_A_PAYMENT: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.MAKE_A_PAYMENT),
            MORE: i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.MORE),
            INVOICE_TYPE: MakePaymentConstants.MAKE_PAYMENT_TYPES.INVOICE,
            stateOrName: 'index.customercare.customer.billingpayments.detail',
            searchTerm: '',
            tableData: {
                data: [],
                columnDefs: []
            },
            onPageSizeOptionSelected: this.onPageSizeOptionSelected.bind(this),
            onSearchCleared: this.onSearchCleared.bind(this),
            onSearchInvoiceItem: this.onSearchInvoiceItem.bind(this),
            openApplyTaxCertificateModal: this.openApplyTaxCertificateModal.bind(this),
            onApplyTaxCertificateModalClose: this.onApplyTaxCertificateModalClose.bind(this),
            updatePage: this.updatePage.bind(this),
            withheldTaxesApplicable: false,
            adjustmentModalConfig: {
                onRegisterApi: ({api}) => {
                    this.adjustmentModalApi= api;
                }
            },
            uiNotificationService: uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentConfig: AdjustmentConfigSelector(store),
                currentBUTimeZone: CurrentBusinessUnitTimeZoneSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currencyCode: CombinedBillingCurrencyCodeSelector(store),
                currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
                currentCustomerName: CurrentCustomerNameSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                invoiceSummary: InvoiceSummaryByIdSelector(store),
                featureTogglesLoaded: MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                isCurrentAccountPostpaid: IsCurrentAccountPostpaidSelector(store),
                isDbss: IsDbss(store),
                isFetchingInvoiceDetails: IsFetchingInvoiceDetailsSelector(store),
                isFetchingInvoiceContent: IsFetchingInvoiceContentSelector(store),
                isInvoiceItemTypeLoaded: MetadataCodeLoadedSelector(CODES.InvoiceItemType, store),
                networkEventTypeCodesLoaded: MetadataCodeLoadedSelector(CODES.NetworkEventType, store),
                routeParams: RouteParams(store),
                serviceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                tableData: InvoiceItemsTableDataSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                invoiceDetailsSummary: InvoiceDetailsSummarySelector(store),
                pageCount: InvoiceDetailsPageCountSelector(store),
                recordCount: InvoiceDetailsRecordCountSelector(store),
                selectedPageSizePreference: SelectedPageSizePreference(store),
                userPageSizePreference: PageSizeSelector(store),
                pageNumber: PageNumberSelector(store),
                lastRoute: SessionSelectors.LastRouteSelector(store)
            };
        };
        const controllerActions = {
            createConvergentBillerAdjustment,
            fetchCodeType: fetchCodeTypesThunk,
            fetchNetworkEventTypes: fetchNetworkEventTypes,
            retrieveInvoiceAndOpenInNewTab: retrieveAndOpenConvergentBillerLedgerInvoice,
            retrieveLedgerInvoiceDetails,
            retrieveLedgerSummary,
            setPageSizePreference,
            stateGo,
            updatePageNumber
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.defaultBackRoute = FUTURE_INVOICE_URL;
        this.INVOICE_HEADER = this.state.routeParams.isFutureInvoice ? i18n.translate(LocaleKeys.UPCOMING_INVOICE) : i18n.translate(LocaleKeys.INVOICE);
        this.AMOUNT_DUE = this.state.routeParams.isFutureInvoice ? i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ESTIMATED_AMOUNT_DUE) :
            i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.AMOUNT_DUE);
        this.INVOICE_AMOUNT = this.state.routeParams.isFutureInvoice ? i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.ESTIMATED_INVOICE_AMOUNT) :
            i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.INVOICE_AMOUNT);
        this.invoiceId = this.state.routeParams.invoiceId;
        if (this.ACCOUNTING_METHODS[this.state.currentCustomerAccountingMethod].value === ACCOUNTING_METHOD_TYPES.OPEN_INVOICE ) {
            this.isOpenInvoice = true;
        }
        if (!this.state.networkEventTypeCodesLoaded) {
            this.actions.fetchNetworkEventTypes();
        }
        if (!this.state.serviceAttributesLoaded) {
            this.actions.fetchCodeType(CODES.ServiceAttribute);
        }
        if (!this.state.featureTogglesLoaded) {
            this.actions.fetchCodeType(CODES.FeatureToggleConfig);
        }
        if (!this.state.isInvoiceItemTypeLoaded) {
            this.actions.fetchCodeType(CODES.InvoiceItemType);
        }
        this.actions.fetchCodeType(CODES.AdjustmentConfig).then(() => {
            this.updateInvoiceSummary();
            this.setMoreMenuOptions();
        });

        this.optionalParams = {
            customerId: this.state.currentCustomerId,
            invoiceId: this.state.routeParams.invoiceId
        };

        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        if (!this.state.routeParams.isFutureInvoice) {
            this.retrieveLedgerInvoiceDetails().then(() => {
                this.updateInvoiceSummary();

            });
        } else {
            this.retrieveLedgerInvoiceDetails();
        }

        this.applyTaxCertificateModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyTaxCertificateModalApi = api;
            }
        };
        this.openDialog = (itemId, itemText, totalCharge, accountNumber) => {
            this.adjustmentType = BILL_ADJUSTMENT;
            this.adjustmentModal = {
                accountNumber: accountNumber,
                invoiceId: this.state.routeParams.invoiceId,
                itemId: itemId,
                itemText: itemText,
                maxAmount: totalCharge,
                referenceId: this.state.invoiceDetailsSummary.InvoiceNumber
            };
            this.showAdjustmentModal = true;
            this.$timeout(() => {
                this.adjustmentModalApi.open();
            });
        };

        this.caseModalProps = {
            isOpen: false,
            onClose: () => {
                this.caseModalProps.isOpen = false;
            },
            onSubmit: () => {
                this.caseModalProps.isOpen = false;
                this.uiNotificationService.success(i18n.translate(LocaleKeys.CASE.CREATED_SUCCESSFULLY));
                this.retrieveLedgerInvoiceDetails();
                this.actions.retrieveLedgerSummary({
                    customerId: this.state.currentCustomerId,
                    request: {
                        Filter: BillingPaymentsConstants.INVOICES
                    }
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            },
            openCaseModal: (invoiceItemId, invoiceItemBalance) => {
                this.caseModalProps.disputeDetails.selectedInvoiceId = this.state.invoiceDetailsSummary.InvoiceId;
                this.caseModalProps.disputeDetails.selectedInvoiceNumber = this.state.invoiceDetailsSummary.InvoiceNumber;
                this.caseModalProps.disputeDetails.selectedInvoiceItemId = invoiceItemId;
                this.caseModalProps.disputeDetails.selectedInvoiceItemBalance = invoiceItemBalance;
                this.caseModalProps.isOpen = true;
            },
            disputeDetails: {
                selectedInvoiceId: '',
                selectedInvoiceNumber: '',
                selectedInvoiceItemId: '',
                selectedInvoiceItemBalance: ''
            },
            caseTemplateId: CASE_CATEGORIES.LINE_ITEM_DISPUTE,
            routeParams: {
                customerId: this.state.currentCustomerId
            }
        };

        this.$scope.LINE_ITEM_HAS_DISPUTE = i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.LINE_ITEM_HAS_DISPUTE);
        this.$scope.handleMoreOptionSelected = (item, action) => {
            if (action.item.id === 'DISPUTE_LINE_ITEM') {
                this.caseModalProps.openCaseModal(item.invoiceItemId, item.invoiceItemBalance);
            } else if (action.item.id === 'APPLY_ITEM_ADJUSTMENT') {
                this.openDialog(item.itemId, item.subscriberItem, item.totalChargeAmount, item.accountNumber);
            } else {
                const STATE_OR_NAME_NEW = 'index.customercare.customer.cases.detailsRedirect';
                this.actions.stateGo(STATE_OR_NAME_NEW, {
                    caseId: item.caseId
                });
            }
        };
    }

    hasApplyTaxCertificateAccess() {
        return this.state.isDbss && (this.state.adjustmentConfig[BillingPaymentsConstants.ENABLE_CREATE_NOTE] === BillingPaymentsConstants.ADJUSTMENT_CONFIG_ENABLED) && hasAccess(this.state.userSecurityAttributes, ALLOW_CREDIT_DEBIT_NOTE);
    }

    setMoreMenuOptions() {
        this.moreMenuOptions = [];
        if (!this.state.invoiceSummary.IsStatement && !this.state.routeParams.isFutureInvoice && (this.state.isCurrentAccountPostpaid &&
            !this.state.currentCustomerIsExternallyManagedAR &&
            this.hasMakeAPaymentAccess() && this.invoiceSummary.CurrentDue >= 0)) {
            this.moreMenuOptions.push({
                title: i18n.translate(this.LocaleKeys.MAKE_A_PAYMENT),
                id: DETAILS_CONSTANTS.MAKE_A_PAYMENT
            });
        }
        if (this.hasApplyTaxCertificateAccess() && this.state.invoiceSummary?.WithheldTaxDue > 0) {
            this.moreMenuOptions.push({
                title: i18n.translate(this.LocaleKeys.APPLY_TAX_CERTIFICATE.APPLY_TAX_CERTIFICATE),
                id: DETAILS_CONSTANTS.APPLY_TAX_CERTIFICATE
            });
        };
        if (this.invoiceSummary.CurrentDue < 0) {
            this.moreMenuOptions.push({
                title: i18n.translate(LocaleKeys.CONVERT_TO_CREDIT),
                id: DETAILS_CONSTANTS.CONVERT_TO_CREDIT
            });
        }
    }

    openApplyTaxCertificateModal() {
        this.showApplyTaxCertificateModal = true;
        this.$timeout(() => {
            this.applyTaxCertificateModalApi.open();
        });
    }

    onApplyTaxCertificateModalClose() {
        this.updatePage(1);
        this.applyTaxCertificateModalApi.close();
        this.showApplyTaxCertificateModal = false;
    }

    openConvertToCreditModal() {
        this.convertToCreditInitialValues = {
            AccountNumber: this.invoiceSummary.AccountNumber,
            noteType: BillingPaymentsConstants.CREDIT_NOTE_TYPE,
            InvoiceId: this.invoiceSummary.InvoiceId,
            InvoiceNumber: this.invoiceSummary.InvoiceNumber,
            Amount: Math.abs(this.invoiceSummary.CurrentDue),
            GenerateStatement: true
        };

        this.isConvertToCreditModalOpen = true;
    }

    handleMoreMenuOptions(option) {
        switch (option.item.id) {
            case DETAILS_CONSTANTS.MAKE_A_PAYMENT:
                this.goToMakePayment();
                break;
            case DETAILS_CONSTANTS.APPLY_TAX_CERTIFICATE:
                this.openApplyTaxCertificateModal();
                break;
            case DETAILS_CONSTANTS.CONVERT_TO_CREDIT:
                this.openConvertToCreditModal();
                break;
            default:
                break;
        }
    }

    onConvertToCreditFormSubmit(formValues) {
        const {noteType, InvoiceNumber, ...convertToCreditFormValues} = formValues;
        const currentDateInBillingTimezone = DateTimeHelper.offsetTimezone(new Date(), this.state.currentBUTimeZone);

        const request = {
            customerId: this.state.currentCustomerId,
            request: {
                AdjustmentTypeCode: BillingPaymentsConstants.CONVERT_TO_CREDIT,
                PostDate: currentDateInBillingTimezone,
                ...convertToCreditFormValues
            }
        };

        this.actions.createConvergentBillerAdjustment(request).then(() => {
            this.isConvertToCreditModalOpen = false;
            this.uiNotificationService.transientSuccess(i18n.translate(this.LocaleKeys.CONVERT_TO_CREDIT_SUCCESS));
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    handleConvertToCreditModalClose() {
        this.isConvertToCreditModalOpen = false;
    }

    onSearchCleared() {
        this.searchTerm = '';
        this.retrieveLedgerInvoiceDetails();
    }

    onSearchInvoiceItem(invoiceNumber) {
        this.searchTerm = invoiceNumber;
        this.retrieveLedgerInvoiceDetails();
    }

    retrieveLedgerInvoiceDetails() {
        this.futureInvoiceHasError = false;
        this.invoiceDetailsRequest = {
            customerId: this.state.currentCustomerId,
            request: {
                InvoiceId: this.state.routeParams.invoiceId,
                PageNumber: this.state.pageNumber,
                PageSize: this.state.selectedPageSizePreference,
                InvoiceItemId: this.searchTerm || undefined
            }
        };
        return this.actions.retrieveLedgerInvoiceDetails(this.invoiceDetailsRequest)
            .then(() => {
                this.updateInvoiceSummary();
                this.formatTableData();
                this.INVOICE_HEADER = this.state.routeParams.isFutureInvoice ? `${i18n.translate(LocaleKeys.UPCOMING_INVOICE)} ${this.invoiceBillDates}` :
                    `${i18n.translate(LocaleKeys.INVOICE)}: ${this.invoiceSummary.InvoiceNumber}`;
            })
            .catch((error) => {
                if (this.state.routeParams.isFutureInvoice) {
                    this.futureInvoiceHasError = true;
                }
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    updateInvoiceSummary() {
        this.invoiceSummary = (this.state.invoiceSummary &&
                this.state.invoiceSummary !== {} &&
                !this.state.routeParams.isFutureInvoice) ?
            this.state.invoiceSummary :
            this.state.invoiceDetailsSummary;
        this.withheldTaxesApplicable = (this.state.invoiceSummary?.TotalWithheldTax > 0
            || this.state.invoiceSummary?.TotalCashReceivable > 0);
        this.amountDue = this.buildAmountDue();
        this.invoiceAmount = this.buildInvoiceAmount() || this.buildEstimatedAmount();
        this.invoiceBillDates = this.buildInvoiceBillDates();
    }

    onAdjustmentClose() {
        this.adjustmentModalApi.close();
        this.showAdjustmentModal = false;
    }

    //Invoice adjustment has been removed from invoice.detail.page.html temporarily.
    //Below openInvoiceAdjustmentModal method is retained here, so that it can be easily reenabled in future when invoice adjustment is supported
    openInvoiceAdjustmentModal() {
        this.adjustmentType = BILL_ADJUSTMENT;
        this.adjustmentModal = {
            accountNumber: this.invoiceSummary.AccountNumber,
            maxAmount: this.invoiceSummary ? this.invoiceSummary.Amount : null,
            invoiceId: this.state.routeParams.invoiceId,
            referenceId: this.invoiceSummary.ReferenceId
        };
        this.showAdjustmentModal = true;
        this.$timeout(() => {
            this.adjustmentModalApi.open();
        });
    }

    retrieveInvoiceContent() {
        if (this.state.routeParams.invoiceId) {
            this.actions.retrieveInvoiceAndOpenInNewTab(this.buildInvoiceContentRequest());
        }
    }

    buildAmountDue() {
        this.isInvoiceAmountDueNegative = this.invoiceSummary.CurrentDue < 0;
        return (this.invoiceSummary.CurrentDue !== null
        && this.invoiceSummary.CurrencyCode)
            ? this.$filter('invCurrency')(this.invoiceSummary.CurrentDue, this.invoiceSummary.CurrencyCode)
            : null;
    }

    buildInvoiceAmount() {
        return (this.invoiceSummary.Balance !== null
            && this.invoiceSummary.CurrencyCode)
            ? this.$filter('invCurrency')(this.invoiceSummary.Balance, this.invoiceSummary.CurrencyCode)
            : null;
    }

    buildEstimatedAmount() {
        return (this.invoiceSummary.EstimatedAmount !== null
            && this.invoiceSummary.EstimatedAmount)
            ? this.$filter('invCurrency')(this.invoiceSummary.EstimatedAmount, this.invoiceSummary.CurrencyCode)
            : null;
    }

    buildBillingAndPaymentsRequest() {
        return {
            customerId: this.state.currentCustomerId,
            request: {
                Filter: this.filter
            }
        };
    }

    buildInvoiceContentRequest() {
        return {
            customerId: this.state.currentCustomerId,
            customerName: this.state.currentCustomerName,
            invoiceNumber: this.invoiceSummary.InvoiceNumber,
            request: {
                InvoiceNumber: this.invoiceSummary.InvoiceNumber,
                InvoiceId: this.state.routeParams.invoiceId,
                IssueDate: this.invoiceSummary.IssueDate
            }
        };
    }

    buildInvoiceBillDates() {
        let retVal = i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.NA);
        this.dueDate = this.invoiceSummary.DueDate ? this.$filter('localShortDate')(this.invoiceSummary.DueDate) : i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.NA);
        const sDate = this.invoiceSummary.StartDate ? this.$filter('localShortDate')(this.invoiceSummary.StartDate) : i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.NA);
        const eDate = this.invoiceSummary.EndDate ? this.$filter('localShortDate')(this.invoiceSummary.EndDate) : i18n.translate(LocaleKeys.BILLING_AND_PAYMENT_INVOICE.NA);

        retVal = `${sDate} - ${eDate}`;

        return retVal;
    }

    hasPaymentAdjustmentAccess() {
        return hasAccess(this.state.userSecurityAttributes, PAYMENT_ADJUSTMENT_ACCESS);
    }

    hasMakeAPaymentAccess() {
        return hasAccess(this.state.userSecurityAttributes, ONE_TIME_MAKE_PAYMENT);
    }

    updatePage(page) {
        this.actions.updatePageNumber(page);
        this.retrieveLedgerInvoiceDetails();
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);
        this.updatePage(1);
    }

    $onDestroy() {
        this.disconnectRedux();
        this.adjustmentModalApi.close();
    }

    formatTableData() {
        const colDefs = [...this.state.tableData.columnDefs];
        this.state.invoiceDetailsSummary.FutureInvoice ? colDefs.pop() : null;
        this.tableData = {
            data: this.state.tableData.data,
            columnDefs: colDefs
        };
    }

    goToMakePayment() {
        this.actions.stateGo(MAKE_PAYMENT_ROUTE, {
            invoiceNumber: this.state.routeParams.invoiceId,
            paymentType: this.INVOICE_TYPE
        });
    };

    goBack() {
        const route = this.state.routeParams.isFutureInvoice ? this.defaultBackRoute : this.state.lastRoute.name;
        const params = this.state.lastRoute.params || {};
        this.$state.go(route, params);
    }
}

export default {
    template: require('./views/invoice.detail.page.html'),
    controller: BillingPaymentsInvoiceController,
    controllerAs: 'billingPaymentsDetail'
};
