import {
    CanMakeHouseholdChangesSelector,
    CurrentHouseholdSelector,
    CurrentMemberIsHeadOfHouseholdSelector,
    EnableHouseholdAccessPrivilegesSelector,
    EnableHouseholdUsagePrivilegesSelector,
    HeadOfHouseholdIsSelectedSelector,
    HouseholdErrorSelector,
    HouseholdMemberMoreOptionsSelector,
    IsRemovingHouseholdMemberSelector,
    RatingsByCategorySelector,
    SelectedHouseholdMemberSelector,
    SelectedHouseholdMemberServicesSelector,
    SelectedMemberPaymentPrivilegesViewModelsSelector,
    SelectedMemberPrivilegesSelector,
    SelectedMemberIsCurrentMemberSelector
} from '../../../../reducers/selectors/customer.household.selectors';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {findLastSelectedRating} from '../../household/household.helpers';
import {removeHouseholdMembers,
    retrieveCustomerHouseholds,
    updateHouseholdMember} from '../../../../reducers/actions/customer.household.actions';
import {
    i18n,
    BusinessUnitSelectors,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {CustomerActionsTemplateSelector} from '../../../../reducers/selectors/customer.actions.template.selectors';
import {
    ACTIVATE_MEMBER_OPTION_ID,
    EDIT_PRIVILEGES_ID,
    REMOVE_MEMBER_OPTION_ID
} from '../household.constants';
import {retrieveWallet} from '../../../../reducers/actions/customer.ewallet.actions';
import {
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsWithTypeNamesViewModel
} from '../../../../reducers/selectors/customer.ewallet.selectors';
import {retrieveConvergentBillerSubscriberSummary} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    ConvergentBillerSubscriberSummaryServicesSelector,
    IsFetchingSubscriberSummarySelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';

function MemberDetailsController($ngRedux, uiNotificationService, $state) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        const selectedMemberServices = SelectedHouseholdMemberServicesSelector(store);
        return {
            canMakeChanges: CanMakeHouseholdChangesSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentHousehold: CurrentHouseholdSelector(store),
            currentMemberIsHeadOfHousehold: CurrentMemberIsHeadOfHouseholdSelector(store),
            customerActionsTemplate: CustomerActionsTemplateSelector(store),
            enableHouseholdAccessPrivileges: EnableHouseholdAccessPrivilegesSelector(store),
            enableHouseholdUsagePrivileges: EnableHouseholdUsagePrivilegesSelector(store),
            hasLoadedHouseholdPrivileges: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.HouseholdPrivileges, store),
            hasLoadedRatings: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.Rating, store),
            hasLoadedRatingCategories: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RatingCategory, store),
            hasLoadedTimezones: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TimeZone, store),
            headOfHouseholdSelected: HeadOfHouseholdIsSelectedSelector(store),
            householdMemberMoreOptions: HouseholdMemberMoreOptionsSelector(store),
            isDbss: BusinessUnitSelectors.IsDbss(store),
            isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
            isRemovingHouseholdMember: IsRemovingHouseholdMemberSelector(store),
            isRetrievingWallet: IsRetrievingEwalletDataSelector(store),
            lastAttemptError: HouseholdErrorSelector(store),
            paymentInstruments: PaymentInstrumentsWithTypeNamesViewModel(store),
            privileges: SelectedMemberPrivilegesSelector(store),
            ratingsByCategory: RatingsByCategorySelector(store),
            selectedMemberPaymentInstruments: SelectedMemberPaymentPrivilegesViewModelsSelector(store),
            selectedMember: SelectedHouseholdMemberSelector(store),
            selectedMemberServices,
            hasServicesForMember: selectedMemberServices && selectedMemberServices.length,
            selectedMemberIsCurrentMember: SelectedMemberIsCurrentMemberSelector(store),
            subscriberServices: ConvergentBillerSubscriberSummaryServicesSelector(store)
        };
    };

    const controllerActions = {
        fetchCodeType: MetadataActions.codes.fetchCodeTypes,
        removeHouseholdMembers,
        retrieveCustomerHouseholds,
        retrieveConvergentBillerSubscriberSummary,
        retrieveWallet,
        updateHouseholdMember
    };

    this.$onInit = () => {
        this.customerCareKeys = CustomerCareLocaleKeys;
        this.valueLocaleKeys = {
            allowed: i18n.translate(this.customerCareKeys.ALLOWED),
            headOfHousehold: i18n.translate(this.customerCareKeys.HOUSEHOLD.HEAD_OF_HOUSEHOLD),
            member: i18n.translate(this.customerCareKeys.HOUSEHOLD.MEMBER),
            no: i18n.translate(this.customerCareKeys.NO),
            notAllowed: i18n.translate(this.customerCareKeys.NOT_ALLOWED),
            yes: i18n.translate(this.customerCareKeys.YES),
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = 'index.customercare.customer';

        this.$state = $state;

        if (!this.state.hasLoadedRatings) {
            this.actions.fetchCodeType(MetadataConstants.codes.Rating);
        }

        if (!this.state.hasLoadedRatingCategories) {
            this.actions.fetchCodeType(MetadataConstants.codes.RatingCategory);
        }

        if (!this.state.hasLoadedTimezones) {
            this.actions.fetchCodeType(MetadataConstants.codes.TimeZone);
        }

        if (!this.state.hasLoadedHouseholdPrivileges) {
            this.actions.fetchCodeType(MetadataConstants.codes.HouseholdPrivileges);
        }

        if (!this.state.subscriberServices && this.state.isDbss) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId);
        }

        if (!this.state.paymentInstruments.length) {
            this.actions.retrieveWallet({
                customerId: this.state.currentHousehold.HeadOfHouseholdSubscriberId
            });
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.handleMoreOptionsMenuSelected = (item) => {
        switch (item.item.id) {
            case ACTIVATE_MEMBER_OPTION_ID:
                this.activateHouseholdMember();
                break;
            case REMOVE_MEMBER_OPTION_ID:
                this.removeMemberConfirmation.open();
                break;
            case EDIT_PRIVILEGES_ID:
                this.$state.go('index.customercare.customer.household.memberprivileges', {
                    subscriberId: this.state.selectedMember.SubscriberId
                });
                break;
            default:
                break;
        }
    };

    this.showMoreButton = () => {
        return this.state.canMakeChanges && !this.state.headOfHouseholdSelected && this.state.householdMemberMoreOptions.length;
    };


    this.removeMemberConfirmation = {
        onRegisterApi: (event) => {
            Object.assign(this.removeMemberConfirmation, event.api);
        },
        onResult: (proceed) => {
            this.removeMemberConfirmation.close();

            if (proceed) {
                removeCurrentHouseholdMember();
            }
        },
        onClose: () => {
            this.removeMemberConfirmation.close();
        }
    };

    this.householdErrorPopupConfig = {
        onRegisterApi: (event) => {
            Object.assign(this.householdErrorPopupConfig, event.api);
        },
        onResult: () => {
            this.householdErrorPopupConfig.close();
        },
        onClose: () => {
            this.householdErrorPopupConfig.close();
        }
    };

    const removeCurrentHouseholdMember = () => {
        this.actions.removeHouseholdMembers(
            this.state.currentCustomerId,
            this.state.currentHousehold.Id,
            [this.state.selectedMember]
        ).then(() => {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, true);

            uiNotificationService.success(
                i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.REMOVE_HOUSEHOLD_MEMBER_SUCCESS_MESSAGE), null,
                {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
        })
            .catch(() => {
                this.householdErrorPopupConfig.open();
            });
    };

    this.activateHouseholdMember = () => {
        const includeRemoved = true;

        this.actions.updateHouseholdMember(
            this.state.currentCustomerId,
            this.state.currentHousehold.Id,
            this.state.selectedMember
        ).then(() => {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, includeRemoved);

            uiNotificationService.success(
                i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.REACTIVATE_HOUSEHOLD_MEMBER_SUCCESS_MESSAGE), null,
                {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
        })
            .catch(() => {
                uiNotificationService.success(this.state.lastAttemptError, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    };

    this.getYesNo = (value) => {
        return this.valueLocaleKeys[value ? 'yes' : 'no'];
    };

    this.getAllowedNotAllowed = (value) => {
        return this.valueLocaleKeys[value ? 'allowed' : 'notAllowed'];
    };

    this.getRole = (headOfHouseholdSelected) => {
        return this.valueLocaleKeys[headOfHouseholdSelected ? 'headOfHousehold' : 'member'];
    };

    this.getSpendingLimitEveryFrequencyString = (numberOfDays, daysUnitTxt) => {
        return numberOfDays && daysUnitTxt ? ` / ${numberOfDays} ${daysUnitTxt}` : '';
    };

    this.isLoading = () => {
        return this.state.isRemovingHouseholdMember || this.state.isFetchingSubscriberSummary || this.state.isRetrievingWallet;
    };

    this.getRoleWithAccessPrivilegeInformation = () => {
        return this.state.privileges.MemberManagementEnabled ?
            `${this.getRole(this.state.headOfHouseholdSelected)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.FULL_ACCESS_PRIVILEGES)})` :
            (this.state.privileges.ManageServicesEnabled ?
                `${this.getRole(this.state.headOfHouseholdSelected)} (${i18n.translate(this.customerCareKeys.HOUSEHOLD.SERVICE_LEVEL_PRIVILEGES)})` :
                this.getRole(this.state.headOfHouseholdSelected));
    };

    this.isCurrentMemberSelectedOrHeadOfHousehold = () => {
        return this.state.currentMemberIsHeadOfHousehold || this.state.selectedMemberIsCurrentMember;
    };

    this.canViewBalanceDetails = () => {
        return this.isCurrentMemberSelectedOrHeadOfHousehold() && this.state.privileges.EnforcePaymentPrivileges && this.state.privileges.LimitSpending;
    };

    this.canViewSharedPaymentMethods = () => {
        return this.isCurrentMemberSelectedOrHeadOfHousehold() && this.state.privileges.EnforcePaymentPrivileges && this.state.selectedMemberPaymentInstruments.length > 0;
    };

    this.hasAccessPrivilegesEnforced = () => {
        return this.isCurrentMemberSelectedOrHeadOfHousehold() && this.state.privileges.EnforceAccessPrivileges;
    };

    this.hasAccessPrivilegesSet = () => {
        if (this.state.privileges.RatingPrivileges) {
            return this.state.privileges.RatingPrivileges.length > 0;
        }
    };

    this.hasUsagePrivilegesEnforced = () => {
        return this.isCurrentMemberSelectedOrHeadOfHousehold() && this.state.privileges.EnforceUsagePrivileges;
    };

    this.hasUsagePrivilegesSet = () => {
        return this.state.privileges.DayAndTimePrivileges.length > 0;
    };

    this.canViewServiceIDs = () => {
        return this.isCurrentMemberSelectedOrHeadOfHousehold() && this.state.isDbss;
    };

    this.findLastSelectedRatingName = (ratings, memberRatings) => {
        const lastRating = findLastSelectedRating(ratings, memberRatings);

        return lastRating ? lastRating.Name : null;
    };
}

export default {
    template: require('./member.details.html'),
    controllerAs: 'MemberDetails',
    controller: MemberDetailsController
};
