import __ from 'ramda/src/__';
import {
    MetadataCodeLoadedSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {BillingConfigurationLoadedSelector} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.selectors';
import {BILLING_CONFIGURATION_TYPES} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.action.types';
import BillingConfigurationActions from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.actions';
import {
    DestinationCustomerAccountInfoViewModelSelector,
    DestinationCustomerAdditionalPropertiesSortedByDisplayOrderSelector,
    DestinationCustomerInformationViewModelSelector,
    IsFetchingDestinationCustomerDataSelector,
    SelectedDestinationCustomerSelector,
} from '../../../../../reducers/selectors/transfer.wizard.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {loadSubscriberRequirements} from '../../../../../reducers/helpers/subscriber.requirements.actions.helper';


class DestinationCustomerInfoController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            uiNotificationService,
            isLoadingData: false,
            promises: [],
            requirements: {}
        });
    }

    $onInit() {
        const codeTables = [
            CODES.ConvergentBillerConfigurationDetail,
            CODES.SubscriberCategoryRequirements,
            CODES.SubscriberRequirements,
            CODES.Currency,
            CODES.AddressCountry,
            CODES.Language,
            CODES.SubscriberType,
            CODES.InvoiceDetailLevel,
            CODES.AdditionalProperty,
            CODES.AdditionalPropertyValue,
            CODES.RegularExpression
        ];

        const controllerActions = {
            fetchCodeTypes,
            getInvoiceDisplay: BillingConfigurationActions.getInvoiceDisplay,
            loadSubscriberRequirements
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                commercialRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                destinationCustomerAccountInfoViewModel: DestinationCustomerAccountInfoViewModelSelector(store),
                destinationCustomerAdditionalProperties: DestinationCustomerAdditionalPropertiesSortedByDisplayOrderSelector(store),
                destinationCustomerInformationViewModel: DestinationCustomerInformationViewModelSelector(store),
                isDbss: IsDbss(store),
                isFetchingDestinationCustomerData: IsFetchingDestinationCustomerDataSelector(store),
                isInvoiceDisplayLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.INVOICE_DISPLAY_CODE, store),
                residentialRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
                selectedDestinationCustomer: SelectedDestinationCustomerSelector(store),
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.promises.push(this.actions.fetchCodeTypes(codeType));
            }
        });

        if (this.state.isDbss) {
            if (!this.state.isInvoiceDisplayLoaded) {
                this.promises.push(this.actions.getInvoiceDisplay());
            }
        }

        this.isLoadingData = true;
        Promise.all(this.promises).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
            this.isLoadingData = false;
        }).finally(() => {
            this.isLoadingData = false;
        });
    }
}

export default {
    template: require('./destination.customer.info.html'),
    controller: DestinationCustomerInfoController,
    bindings: {}
};
