import i18n from 'invision-core/src/components/i18n/i18n';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import CustomerCareKeys from '../../../../../../../../locales/keys';
import {GetProductStructureTypes} from '../../../../../../../../reducers/constants/structure.type.constants';

const CATEGORY_VALUE = 'Id';
const FACET_VALUE = 'Value';
const STRUCTURE_TYPE_VALUE = 'id';

const FACET_LIST_PROPERTY = 'subFacets';
const CATEGORY_LIST_PROPERTY = 'Children';

class CategoryFiltersController {
    constructor() {
        Object.assign(this, {
            customerCareKeys: CustomerCareKeys,
            onRecurrenceChanged: this.onRecurrenceChanged.bind(this),
            onClearStructureType: this.onClearStructureType.bind(this),
            onStructureTypeChanged: this.onStructureTypeChanged.bind(this),
            onFacetChanged: this.onFacetChanged.bind(this),
            onClearFacet: this.onClearFacet.bind(this),
            onSubFacetChanged: this.onSubFacetChanged.bind(this),
            onClearCategory: this.onClearCategory.bind(this),
            onCategoryChanged: this.onCategoryChanged.bind(this),
            onSubCategoryChanged: this.onSubCategoryChanged.bind(this),
            onClearFacetsCategories: this.onClearFacetsCategories.bind(this),
            onClassChanged: this.onClassChanged.bind(this),
            structureTypes: GetProductStructureTypes(),
            productClassification: PRODUCT_CLASSIFICATIONS.NONE, //Setting default to All
            productClassifications: PRODUCT_CLASSIFICATIONS,
            productClassificationFilters: [{
                id: PRODUCT_CLASSIFICATIONS.NONE,
                label: i18n.translate(CustomerCareKeys.ALL)
            }, {
                id: PRODUCT_CLASSIFICATIONS.PRODUCT,
                label: i18n.translate(CustomerCareKeys.PRODUCT)
            }, {
                id: PRODUCT_CLASSIFICATIONS.AD_HOC,
                label: i18n.translate(CustomerCareKeys.AD_HOC)
            }],
            selectedStructureTypes: [],
            selectedFacets: [],
            selectedCategories: []
        });
    }

    $onInit() {
        this.initPersistence();
        this.setSubscriptionValue();
    }

    initPersistence() {
        if (this.usePersistence) {
            this.initRecurrence(this.searchCatalogParams);
            this.initStructureTypes();
        } else {
            this.clearCategories(this.categories);
            this.clearFacets(this.facets);
        }
    }

    clearCategories(categories) {
        categories && categories.forEach((category) => {
            this._clearCategory(category);
        });
    }

    clearFacets(facets) {
        facets && facets.forEach((facet) => {
            this._clearFacet(facet);
        });
    }

    initRecurrence() {
        if (this.searchCatalogParams.ProductFilter.Subscription === null) {
            this.recurrenceOptions.productFilter = false;
            this.recurrenceOptions.subscriptionFilter = false;
        } else if (this.searchCatalogParams.ProductFilter.Subscription === false) {
            this.recurrenceOptions.productFilter = true;
            this.recurrenceOptions.subscriptionFilter = false;
        } else if (this.searchCatalogParams.ProductFilter.Subscription === true) {
            this.recurrenceOptions.productFilter = false;
            this.recurrenceOptions.subscriptionFilter = true;
        }
    }

    initStructureTypes() {
        this.structureTypes.forEach((structureType) => {
            structureType.selected = !!this.searchCatalogParams.ProductFilter.StructureTypes.find((id) => {
                return id === structureType.id;
            });
            if (structureType.selected) {
                this.selectedStructureTypes.push(structureType.id);
            }
        });
    }

    setSubscriptionValue() {
        if (this.hideRecurrence) {
            this.subscription = false;
        } else if ((this.recurrenceOptions.productFilter && this.recurrenceOptions.subscriptionFilter) ||
            (!this.recurrenceOptions.productFilter && !this.recurrenceOptions.subscriptionFilter)) {
            this.subscription = null;
        } else {
            this.subscription = this.recurrenceOptions.subscriptionFilter ? this.recurrenceOptions.subscriptionFilter : !this.recurrenceOptions.productFilter;
        }
    }

    onRecurrenceChanged() {
        this.setSubscriptionValue();
        this._updateSearchCatalogParams();
    }

    onClearStructureType() {
        this._clearStructureTypes();
        this._updateSearchCatalogParams();
    }

    onClearFacetsCategories() {
        if (this.selectedFacets) {
            this.clearFacets(this.facets);
        }
        if (this.selectedCategories) {
            this.clearCategories(this.categories);
        }
        this._updateSearchCatalogParams();
    }

    onClassChanged() {
        this._updateSearchCatalogParams();
    }

    onStructureTypeChanged(structureType) {
        this._setSelectedItems(this.selectedStructureTypes, structureType, STRUCTURE_TYPE_VALUE);
        this._updateSearchCatalogParams();
    }

    onFacetChanged(facet) {
        this._updateFacet(facet);
        this._updateSearchCatalogParams();
    }

    onClearFacet(facet) {
        facet.selected = false;
        this._updateFacet(facet);
        this._updateSearchCatalogParams();
    }

    onSubFacetChanged(facet, subFacet) {
        this._updateSubFacet(facet, subFacet);
        this._updateSearchCatalogParams();
    }

    onClearCategory(category) {
        category.selected = false;
        this._updateCategory(category);
        this._updateSearchCatalogParams();
    }

    onCategoryChanged(category) {
        this._updateCategory(category);
        this._updateSearchCatalogParams();
    }

    onSubCategoryChanged(category, subCategory) {
        this._updateSubCategory(category, subCategory);
        this._updateSearchCatalogParams();
    }

    _setShouldShowClearItem(item, property) {
        const selectedItemCount = item[property].filter((subItem) => {
            return subItem.selected;
        }).length;
        item.shouldShowClear = selectedItemCount > 1;
    }

    _setSelectedItems(list, item, property) {
        const foundIndex = list.indexOf(item[property]);
        if (foundIndex === -1 && item.selected) {
            list.push(item[property]);
        } else if (foundIndex > -1 && !item.selected) {
            list.splice(foundIndex, 1);
        }
    }

    _clearStructureTypes() {
        this.structureTypes.forEach((structureType) => {
            structureType.selected = false;
            this._setSelectedItems(this.selectedStructureTypes, structureType, STRUCTURE_TYPE_VALUE);
        });
    }

    _updateFacet(facet) {
        facet.subFacets.forEach((subFacet) => {
            subFacet.selected = facet.selected;
            this._setSelectedItems(this.selectedFacets, subFacet, FACET_VALUE);
        });
        facet.indeterminate = false;

        this._setShouldShowClearItem(facet, FACET_LIST_PROPERTY);
    }

    _clearFacet(facet) {
        facet.selected = false;
        this._updateFacet(facet);
    }

    _updateSubFacet(facet, subFacet) {
        const selectedCount = facet[FACET_LIST_PROPERTY].filter((subItem) => {
            return subItem.selected;
        }).length;

        facet.indeterminate = this._isIndeterminate(selectedCount, facet[FACET_LIST_PROPERTY].length);
        facet.selected = this._shouldParentBeSelected(selectedCount, facet[FACET_LIST_PROPERTY].length);

        this._setShouldShowClearItem(facet, FACET_LIST_PROPERTY);
        this._setSelectedItems(this.selectedFacets, subFacet, FACET_VALUE);
    }

    _clearCategory(category) {
        category.selected = false;
        this._updateCategory(category);
    }

    _updateCategory(category) {
        category.indeterminate = false;
        if (category.Children) {
            category.Children.forEach((subCategory) => {
                subCategory.selected = category.selected;
                this._setSelectedItems(this.selectedCategories, subCategory, CATEGORY_VALUE);
            });
            this._setShouldShowClearItem(category, CATEGORY_LIST_PROPERTY);
        } else {
            this._setSelectedItems(this.selectedCategories, category, CATEGORY_VALUE);
        }
    }

    _updateSubCategory(category, subCategory) {
        const selectedCount = category[CATEGORY_LIST_PROPERTY].filter((subItem) => {
            return subItem.selected;
        }).length;

        category.indeterminate = this._isIndeterminate(selectedCount, category[CATEGORY_LIST_PROPERTY].length);
        category.selected = this._shouldParentBeSelected(selectedCount, category[CATEGORY_LIST_PROPERTY].length);

        this._setShouldShowClearItem(category, CATEGORY_LIST_PROPERTY);
        this._setSelectedItems(this.selectedCategories, subCategory, CATEGORY_VALUE);
    }

    _updateSearchCatalogParams() {
        if (this.onSearchCatalogParamsUpdated) {
            this.searchCatalogParams.ProductFilter.ProductClassification = this.productClassification === PRODUCT_CLASSIFICATIONS.NONE ?
                null :
                this.productClassification;
            this.searchCatalogParams.ProductFilter.Subscription = this.subscription;
            this.searchCatalogParams.ProductFilter.Categories = this.selectedCategories;
            this.searchCatalogParams.ProductFilter.Facets = this.selectedFacets;
            this.searchCatalogParams.ProductFilter.StructureTypes = this.selectedStructureTypes;
            this.onSearchCatalogParamsUpdated()(this.searchCatalogParams);
        }
    }

    _shouldParentBeSelected(selectedCount, totalChildrenCount) {
        return selectedCount > 0 && selectedCount === totalChildrenCount;
    }

    _isIndeterminate(selectedCount, totalChildrenCount) {
        return selectedCount > 0 && selectedCount !== totalChildrenCount;
    }

    $onChanges(changesObj) {
        // TODO: Customer Order: This component should be a true visual component. This logic should be handled by
        // the parent and passed in. This was done like this due to the component handling it's own view model internally
        if (changesObj.cartClassification &&
            (!changesObj.cartClassification.isFirstChange() &&
                changesObj.cartClassification.currentValue !== changesObj.cartClassification.previousValue)) {
            this.productClassification = !changesObj.cartClassification.currentValue ?
                PRODUCT_CLASSIFICATIONS.NONE :
                changesObj.cartClassification.currentValue;
            this.onClassChanged();
        }
    }
}

export default {
    bindings: {
        cartClassification: '<',
        categories: '<',
        disableClassifications: '<',
        facets: '<',
        hideRecurrence: '<',
        onSearchCatalogParamsUpdated: '&',
        productClass: '<',
        recurrenceOptions: '<',
        searchCatalogParams: '<',
        showClassificationFilter: '<',
        usePersistence: '<'
    },
    template: require('./advanced.search.filter.bar.html'),
    controller: CategoryFiltersController
};
