import {
    getContentType,
    getFriendlyDisplayName
} from '../content.activity.helpers';

class ContentActivityListItemController {
    $onChanges(change) {
        if (change.contentActivity) {
            this.getContentType(change.contentActivity.currentValue);
        }
    }

    getContentType(contentActivity) {
        this.contentIdentifier = getContentType(contentActivity);
        this.friendlyName = getFriendlyDisplayName(contentActivity);
    }

    handleClick() {
        this.onItemSelected()(this.contentActivity);
    }
}

export default {
    template: require('./list.item.html'),
    bindings: {
        contentActivity: '<',
        isActiveSelected: '<',
        isSelectedItem: '<',
        onItemSelected: '&'
    },
    controller: ContentActivityListItemController,
    controllerAs: 'ContentActivityListItemController'
};
