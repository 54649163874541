import CustomerLocaleKeys from '../../../locales/keys';
import {
    ConvergentBillerTreatmentDetailsSelector,
    ConvergentBillerIsInPromiseToPay,
    ConvergentBillerCurrentPromiseToPayInstallment,
    ConvergentBillerIsInTreatment,
    ConvergentBillerSubscriberSummarySelector,
    IsFetchingTreatmentDetailsSelector,
    IsFetchingSubscriberSummarySelector,
    ConvergentBillerCurrentPromiseToPaySchedule
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerConvergentBillerIdSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    retrieveConvergentBillerTreatmentDetails,
    retrieveConvergentBillerSubscriberSummary
} from '../../../reducers/actions/customer.convergent.biller.actions';
import {IsARMInMaintenanceModeSelector} from 'invision-core/src/components/warnings/warnings.selectors';
import {
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';

class TreatmentDetailsController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                armInMaintenanceMode: IsARMInMaintenanceModeSelector(store),
                customerId: CurrentCustomerIdSelector(store),
                customerConvergentBillerId: CurrentCustomerConvergentBillerIdSelector(store),
                hasPromiseToPay: ConvergentBillerIsInPromiseToPay(store),
                isFetchingTreatmentDetails: IsFetchingTreatmentDetailsSelector(store),
                isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
                isInTreatment: ConvergentBillerIsInTreatment(store),
                periodTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PeriodType, store),
                promiseToPayDetails: ConvergentBillerCurrentPromiseToPayInstallment(store),
                subscriberSummary: ConvergentBillerSubscriberSummarySelector(store),
                treatmentDetails: ConvergentBillerTreatmentDetailsSelector(store),
                promiseToPaySchedule: ConvergentBillerCurrentPromiseToPaySchedule(store)
            };
        };
        const controllerActions = {
            retrieveConvergentBillerSubscriberSummary,
            retrieveConvergentBillerTreatmentDetails,
            fetchPeriodTypes: MetadataActions.codes.fetchPeriodTypes
        };
        this.CustomerLocaleKeys = CustomerLocaleKeys;
        this.isChangeStepEscalationCalled = false;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        if (this.state.customerConvergentBillerId && !this.state.isFetchingTreatmentDetails) {
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.customerId);
        }
        if (!this.state.periodTypesLoaded) {
            this.actions.fetchPeriodTypes();
        }
        if (this.state.customerConvergentBillerId && !this.state.isFetchingSubscriberSummary) {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.customerId);
        }

        this.createPromiseToPayConfig = {
            onRegisterApi: ({api: {open}}) => {
                this.openCreatePromiseToPayPopup = open;
            }
        };

        this.changeStepConfig = {
            onRegisterApi: ({api: {open}}) => {
                this.openChangeStepPopup = open;
            }
        };

        this.promiseToPayScheduleConfig = {
            onRegisterApi: ({api: {open}}) => {
                this.openPromiseToPaySchedulePopup = open;
            }
        };

        this.refreshData = () => {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.customerId);
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.customerId);
        };

        this.changeStepSuccess = (isEscalation) => {
            if (isEscalation) {
                this.isChangeStepEscalationCalled = true;
            } else {
                this.refreshTreatmentDetails();
            }
        };

        this.refreshTreatmentDetails = () => {
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.customerId);
            this.isChangeStepEscalationCalled = false;
        };
    }
    $onDestroy() {
        this.disconnectRedux();
    }
    getPromiseToPayStatusKey({PromiseStatus} = {}) {
        let subKey;
        switch (PromiseStatus) {
            case 0:
                subKey = 'OPEN';
                break;
            case 1:
                subKey = 'COMPLETED';
                break;
            case 2:
                subKey = 'BROKEN';
                break;
            default:
                subKey = 'NA';
                break;
        }
        return CustomerLocaleKeys.TREATMENT_DETAILS.PROMISE_TO_PAY_STATUS[subKey];
    }
    get shouldShowChangeStep() {
        return this.state && this.state.treatmentDetails.NextStepNumber !== 0 && !this.isChangeStepEscalationCalled;
    }
    get isContentLoading() {
        return this.state && (this.state.isFetchingTreatmentDetails || this.state.isFetchingSubscriberSummary || this.isNavigatingToOverview);
    }
}

export default {
    template: require('./treatment.details.html'),
    controller: TreatmentDetailsController,
    controllerAs: 'TreatmentDetails'
};
