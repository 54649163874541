import {CurrentBusinessUnitCurrencyCodeSelector} from 'invision-core/src/components/session/businessunit.selectors';
import clone from 'ramda/src/clone';
import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';

import {stateGo} from 'redux-ui-router';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    IsPIIDataAccessEnabledSelector,
    RoutePreviousState
} from '../../../reducers/selectors/customer.selectors';
import {
    activatePaymentInstrument,
    anonymizePaymentInstrument,
    clearEWalletError,
    clearSelectedItemId,
    clearSelectedPaymentMethod,
    editPaymentInstrument,
    removePaymentInstrument,
    retrieveAvailablePaymentInstrumentTypes,
    retrieveWallet,
    selectPaymentMethod,
    setIncludeRemovedFilter,
    setPaymentInstrumentDefault,
    setSelectedItemId
} from '../../../reducers/actions/customer.ewallet.actions';
import {setPaymentInstrumentFilter} from '../../../reducers/actions/customer.transactions.actions';
import {
    searchSubscriptions
} from '../../../reducers/actions/customer.subscriptions.actions';
import {
    PageNumberSelector,
    RecordCountSelector,
    SubscriptionsPaymentInstruments,
    TotalPagesSelector,
    SubscriptionErrorSelector
} from '../../../reducers/selectors/customer.subscriptions.selectors';
import {
    CurrentDefaultPaymentModel,
    DefaultPaymentMethodAutoPaySelector,
    DefaultPaymentMethodSelector,
    EwalletErrorSelector,
    EwalletRetrievalErrorSelector,
    IncludeRemovedSelector,
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsWithTypeNamesViewModel,
    SelectedPaymentAutoPayEnable,
    SelectedPaymentIsDirectSource,
    SelectedPaymentModelBasedOnRecoverableUI,
    SelectedItemIdSelector,
    SelectedTypeIdSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {
    isReadOnlyException
} from '../../../reducers/helpers/ewallet.reducer.helper';
import * as ComponentViewModelSelectors from '../ewallet/detail/ewallet.detail.component.selector';
import {
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    E_WALLET_ADMIN_ACCESS,
    ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS,
    SUBSCRIBER_ANONYMIZATION_ACCESS
} from '../../../security.attributes';
import {NOTIFICATION_TIME_LENGTH} from '../../../customercare.constants';
import {BLANK_PAYMENT_INSTRUMENT} from '../makePayment/make.payment.constants';
import {retrieveCustomerHouseholds} from '../../../reducers/actions/customer.household.actions';
import {
    CurrentHouseholdSelector,
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector
} from '../../../reducers/selectors/customer.household.selectors';
import {GIFT_CARD_DETAILS} from '../../giftCard/gift.card.config';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {RouteParams} from 'invision-core/src/components/router/router.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';


function CustomerEwalletController($ngRedux, $timeout, $anchorScroll, uiNotificationService) {
    let disconnectRedux;
    const mapStateToTarget = (store) => {
        return {
            convergentBillerAccounts: DefaultPaymentMethodAutoPaySelector(store),
            currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
            currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
            currentHousehold: CurrentHouseholdSelector(store),
            currentSubHasEditPermission: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
            creditCardTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CreditCardType, store),
            defaultPaymentMethod: DefaultPaymentMethodSelector(store),
            defaultPaymentMethodModel: CurrentDefaultPaymentModel(store),
            externalBillTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalBillType, store),
            externalGiftCardTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalGiftCardType, store),
            braintreeMethodTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.BraintreeMethodType, store),
            brandableCurrenciesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.BrandableCurrency, store),
            includeRemoved: IncludeRemovedSelector(store),
            isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
            isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
            lastAttemptError: EwalletErrorSelector(store),
            paymentInstruments: PaymentInstrumentsWithTypeNamesViewModel(store),
            routePreviousState: RoutePreviousState(store),
            retrieveEwalletError: EwalletRetrievalErrorSelector(store),
            selectedPaymentMethod: SelectedPaymentModelBasedOnRecoverableUI(store),
            selectedPaymentMethodAutoPayEnable: SelectedPaymentAutoPayEnable(store),
            selectedPaymentMethodIsDirectSource: SelectedPaymentIsDirectSource(store),
            selectedItemId: SelectedItemIdSelector(store),
            selectedTypeId: SelectedTypeIdSelector(store),
            subscriberListError: SubscriptionErrorSelector(store),
            subscriberListNumberOfPages: TotalPagesSelector(store),
            subscriberListPageNumber: PageNumberSelector(store),
            subscriberListPaymentInstruments: SubscriptionsPaymentInstruments(store),
            subscriberListRecordCount: RecordCountSelector(store),
            templatePaymentDetail: ComponentViewModelSelectors.TemplatePaymentDetailSelector(store),
            timeZonesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TimeZone, store),
            userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
            routeParams: RouteParams(store)
        };
    };
    const controllerActions = {
        activatePaymentInstrument,
        anonymizePaymentInstrument,
        clearEWalletError,
        clearSelectedItemId,
        clearSelectedPaymentMethod,
        editPaymentInstrument,
        fetchCodeType: MetadataActions.codes.fetchCodeTypes,
        removePaymentInstrument,
        retrieveCustomerHouseholds,
        retrieveAvailablePaymentInstrumentTypes,
        retrieveWallet,
        selectPaymentMethod,
        searchSubscriptions,
        setIncludeRemovedFilter,
        setPaymentInstrumentFilter,
        setPaymentInstrumentDefault,
        setSelectedItemId,
        stateGo
    };
    const FIRST_SUBSCRIPTION_PAGE = 1;
    const findPaymentMethodById = (id) => {
        return find(propEq(id, 'Id'));
    };

    this.$onInit = () => {
        this.$timeout = $timeout;
        this.$anchorScroll = $anchorScroll;
        this.localeKeys = CustomerCareLocaleKeys;
        this.showPaymentInstrumentForm = false;
        this.subscriptionPageNumber = FIRST_SUBSCRIPTION_PAGE;

        this.newEditPaymentMethodPopupConfig = {
            onRegisterApi: (event) => {
                this.newEditPaymentMethodPopupApi = event.api;
            }
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.loadHouseholdData();
        this.retrieveWalletAndUpdateSelectedItem();

        this.actions.retrieveAvailablePaymentInstrumentTypes({
            Currency: this.state.currentCustomerCurrencyCode || this.state.currentBusinessUnitCurrencyCode
        }).catch((error) => {
            uiNotificationService.transientError(error.translatedMessage);
        });

        fetchSubscriptions(this.subscriptionPageNumber);

        if (!this.state.creditCardTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.CreditCardType);
        }
        if (!this.state.externalBillTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalBillType);
        }
        if (!this.state.externalGiftCardTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalGiftCardType);
        }

        this.stateOrName = 'index.customercare.customer.ewallet';
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.shouldShowBackBanner = this.state.routePreviousState.name === GIFT_CARD_DETAILS;

        if (!this.state.timeZonesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.TimeZone);
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    const fetchSubscriptions = (pageNumber) => {
        const data = {
            IncludePaymentInstrumentInfo: true,
            IncludeRemoved: false,
            PageNumber: pageNumber,
            PageSize: 100,
            SortDirection: 2
        };

        return this.actions.searchSubscriptions(this.state.currentCustomerId, data).catch(() => {
            if (this.state.subscriberListError) {
                this.uiNotificationService.error(this.state.subscriberListError.message, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }
        });
    };

    this.eWalletEditable = () => {
        return this.state.isPIIDataAccessEnabled;
    };

    this.currentDefaultPaymentInstrumentHasSubscriptions = () => {
        return this.state.subscriberListPaymentInstruments.find((name) => {
            return name === this.state.defaultPaymentMethod.Name;
        });
    };

    this.currentDefaultPaymentIsReadOnly = () => {
        const currentDefaultPaymentMethodType = pathOr('', ['Type'], this.state.defaultPaymentMethodModel);
        const readOnlyException = isReadOnlyException(currentDefaultPaymentMethodType);

        if (readOnlyException) {
            return false;
        } else {
            return !this.state.defaultPaymentMethodModel || !this.state.defaultPaymentMethodModel.actionables.canOrder;
        }
    };

    this.openAddPaymentMethodPopup = () => {
        this.modalPaymentMethod = clone(BLANK_PAYMENT_INSTRUMENT);

        this.showPaymentInstrumentForm = true;

        this.$timeout(() => {
            this.newEditPaymentMethodPopupApi.open();
        });
    };

    this.openEditPaymentMethodPopup = (paymentMethodToEdit) => {
        this.modalPaymentMethod = clone(paymentMethodToEdit);

        this.showPaymentInstrumentForm = true;
        this.isEditingPaymentInstrument = true;

        this.$timeout(() => {
            this.newEditPaymentMethodPopupApi.open();
        });
    };

    this.handleSelectedPaymentMethod = (paymentInstrument) => {
        this.setSelectedPaymentMethod(paymentInstrument);
        this.scrollToSelectedItem();
    };

    this.setSelectedPaymentMethod = (paymentInstrument) => {
        let itemId = null;

        if (this.state.selectedItemId !== paymentInstrument.Id) {
            itemId = paymentInstrument.Id;
        } else if (this.state.selectedItemId) {
            itemId = this.state.selectedItemId;
        }

        this.actions.setSelectedItemId(itemId);

        const paymentMethod = findPaymentMethodById(itemId)(this.state.paymentInstruments);

        this.actions.selectPaymentMethod(paymentMethod);
    };

    this.scrollToSelectedItem = () => {
        if (!this.isInView(`ewalletItem-${this.state.selectedItemId}`, 'customer-ewallet-list')) {
            this.$anchorScroll(`ewalletItem-${this.state.selectedItemId}`);
        }
    };

    this.isInView = (element, parentElement) => {
        const elementObj = angular.element(`#${element}`)[0];
        if (elementObj) {
            const elementRect = elementObj.getBoundingClientRect();
            const parentElementRect = angular.element(parentElement)[0].getBoundingClientRect();
            const elementTopBorder = elementRect.top + elementRect.height;
            const elementBottomBorder = elementRect.bottom - elementRect.height;

            return !(elementTopBorder > parentElementRect.bottom || elementBottomBorder < parentElementRect.top);
        } else {
            return true;
        }
    };

    this.includeRemovedAndSelectPaymentType = () => {
        this.actions.setIncludeRemovedFilter(true);
        this.retrieveWallet().then(() => {
            const paymentInstrument = this.state.paymentInstruments.find(propEq(this.state.selectedTypeId, 'Type'));

            this.setSelectedPaymentMethod(paymentInstrument);
        });
    };

    this.includeRemovedAndSelectPaymentInstrument = () => {
        this.actions.setIncludeRemovedFilter(true);
        this.retrieveWallet().then(() => {
            const paymentInstrument = this.state.paymentInstruments.find(propEq(this.state.selectedItemId, 'Id'));

            this.setSelectedPaymentMethod(paymentInstrument);
        });
    };

    this.handleIncludeRemovedClick = () => {
        this.actions.setIncludeRemovedFilter(!this.state.includeRemoved);
        this.retrieveWallet().then(() => {
            if (!this.selectedPaymentPersists()) {
                this.handleSelectFirstPaymentMethod();
            }
        });
    };

    this.loadHouseholdData = () => {
        if (!this.state.currentHousehold) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false);
        }
    };

    this.retrieveWallet = () => {
        return this.actions.retrieveWallet({
            customerId: this.state.currentCustomerId,
            request: {
                IncludeRemoved: this.state.includeRemoved
            }
        }).catch((payload) => {
            uiNotificationService.error(payload.translatedMessage, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    };

    this.currentCustomerHasPaymentMethods = () => {
        return this.state.paymentInstruments && this.state.paymentInstruments.length > 0;
    };

    this.currentCustomerHasSelectedPaymentMethod = () => {
        return this.state.selectedPaymentMethod && this.state.selectedPaymentMethod.Id;
    };

    this.closeNewEditPaymentMethodPopup = () => {
        this.newEditPaymentMethodPopupApi.close();
        this.actions.clearEWalletError();
        this.isEditingPaymentInstrument = false;
        this.showPaymentInstrumentForm = false;
    };

    this.onCreateOrEditPaymentMethodComplete = (paymentInstrument) => {
        this.closeNewEditPaymentMethodPopup();
        this.setSelectedPaymentMethod(paymentInstrument);
    };

    this.paymentMethodType = () => {
        const paymentMethod = findPaymentMethodById(this.state.selectedItemId)(this.state.paymentInstruments);

        return String(paymentMethod.Type);
    };

    this.paymentMethodRemove = (currentCustomerId, paymentMethodId) => {
        return this.actions.removePaymentInstrument(currentCustomerId, paymentMethodId);
    };

    this.paymentMethodAnonymize = (currentCustomerId, paymentMethodId) => {
        return this.actions.anonymizePaymentInstrument(currentCustomerId, paymentMethodId);
    };

    this.setPaymentInstrumentFilter = (selectedPaymentMethod) => {
        return this.actions.setPaymentInstrumentFilter(selectedPaymentMethod);
    };

    this.activatePaymentInstrument = (currentCustomerId, paymentMethod) => {
        return this.actions.activatePaymentInstrument(currentCustomerId, paymentMethod);
    };

    this.setPaymentInstrumentDefault = (currentCustomerId, paymentMethod, applyToSubscriptions) => {
        return this.actions.setPaymentInstrumentDefault(currentCustomerId, paymentMethod, applyToSubscriptions).then(() => {
            if (!paymentMethod.ConvergentBillerPaymentInstrumentAccounts.length && this.state.convergentBillerAccounts.ConvergentBillerPaymentInstrumentAccounts && this.state.convergentBillerAccounts.ConvergentBillerPaymentInstrumentAccounts.length) {
                paymentMethod.ConvergentBillerPaymentInstrumentAccounts = clone(this.state.convergentBillerAccounts.ConvergentBillerPaymentInstrumentAccounts);
                paymentMethod.Default = true;
                const request = {
                    updateAutoPay: true,
                    customerId: currentCustomerId,
                    request: {
                        PaymentInstrument: paymentMethod
                    }
                };

                this.actions.editPaymentInstrument(request);
            }
            fetchSubscriptions(this.subscriptionPageNumber);
        });
    };

    this.stateGo = (location, state) => {
        return this.actions.stateGo(location, state);
    };

    this.fetchCodeType = (code) => {
        return this.actions.fetchCodeType(code);
    };

    this.handlePaymentMethodRemoved = () => {
        this.retrieveWallet().then(() => {
            if (!this.state.includeRemoved) {
                this.handleSelectFirstPaymentMethod();
            }
        });
    };

    this.handlePaymentMethodAnonymized = () => {
        this.retrieveWallet().then(() => {
            if (!this.state.includeRemoved) {
                this.handleSelectFirstPaymentMethod();
            }
        });
    };

    this.handleSelectFirstPaymentMethod = () => {
        if (this.state.paymentInstruments.length > 0) {
            this.setSelectedPaymentMethod(this.state.paymentInstruments[0]);
        } else {
            this.actions.clearSelectedPaymentMethod();
        }
    };

    this.selectedPaymentPersists = () => {
        return !!find(propEq(this.state.selectedItemId, 'Id'))(this.state.paymentInstruments);
    };

    this.retrieveWalletAndUpdateSelectedItem = () => {
        this.retrieveWallet().then(() => {
            if (this.state.selectedItemId) {
                const paymentInstrument = this.state.paymentInstruments.find(propEq(this.state.selectedItemId, 'Id'));

                if (paymentInstrument) {
                    this.setSelectedPaymentMethod(paymentInstrument);
                } else {
                    this.includeRemovedAndSelectPaymentInstrument();
                }
            } else {
                if (this.state.selectedTypeId) {
                    const paymentInstrument = this.state.paymentInstruments.find(propEq(this.state.selectedTypeId, 'Type'));

                    if (paymentInstrument) {
                        this.setSelectedPaymentMethod(paymentInstrument);
                    } else {
                        this.includeRemovedAndSelectPaymentType();
                    }
                } else {
                    this.handleSelectFirstPaymentMethod();
                }
            }
        });
    };

    this.handlePaymentMethodUpdated = () => {
        this.retrieveWalletAndUpdateSelectedItem();
    };

    this.userHasAdminPermissions = () => {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, E_WALLET_ADMIN_ACCESS);
    };

    this.canAdd = () => {
        return this.userHasAdminPermissions() && this.state.currentSubHasEditPermission && this.state.timeZonesLoaded && this.state.isPIIDataAccessEnabled;
    };

    this.onBannerClose = () => {
        this.shouldShowBackBanner = false;
    };

    this.canAnonymizePaymentMethod = () => {
        return this.userHasAdminPermissions() && PermissionService.hasAccess(this.state.userSecurityAttributes, ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS) && PermissionService.hasAccess(this.state.userSecurityAttributes, SUBSCRIBER_ANONYMIZATION_ACCESS);
    };
}

export default {
    bindings: {},
    template: require('./ewallet.html'),
    controller: CustomerEwalletController,
    controllerAs: 'ewalletController'
};
