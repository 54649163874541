import angular from 'angular';
import DefaultLandingPageComponent from './default.landing.page.component';
import LandingPageComponent from './landing.page.component';
import LandingPageContextualComponent from './landing.page.contextual';
import LandingPageCustomWidgetComponent from './custom/custom.widget.component';

export default angular.module('invision.customercare.landingPage', [])
    .component('defaultLandingPage', DefaultLandingPageComponent)
    .component('landingPage', LandingPageComponent)
    .component('landingPageCtx', LandingPageContextualComponent)
    .component('landingPageCustomWidget', LandingPageCustomWidgetComponent)
    .name;
