import propEq from 'ramda/src/propEq';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {OfferingMetadataByIdSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {
    fetchOfferingFacetCategoryTypes,
    fetchOfferingFacetTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PageSizePreferenceSelector} from 'invision-core/src/components/session/session.selectors';
import {
    ContextOfferingAction,
    DEVICE_TYPE_OFFER,
    OPTION_OFFERING_CHARGE_TYPE
} from '../../../../reducers/constants/wizard.constants';
import {ORDER_SCENARIOS} from '../../../../customercare.constants';
import {IsLoadedSelector} from './offerings.selectors';
import {
    IsFetchingSelector,
    NumberOfPagesSelector,
    PageNumberSelector,
    RecordCountSelector,
    SelectedPageSizePreference
} from '../../../../reducers/selectors/offering.order.selectors';
import {SelectedContractSelector} from '../../../../reducers/selectors/contract.selectors';
import {
    keywordSearch,
    keywordSearchWithCustomerId,
    setSelectedOfferName,
    setPageNumber,
    setPageSizePreference
} from '../../../../reducers/actions/offering.order.actions';
import {setSelectedContract} from '../../../../reducers/actions/contract.details.actions';
// TODO - Customer Order wizard componentized steps should not be importing anything from a specific wizards
import {EditCustomerInfoSelector} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {PagesIsFetchingDataSelector} from '../../../../reducers/selectors/selected.offering.order.selectors';
import CustomerCareKeys from '../../../../locales/keys';
import {ChangeOfferingInstanceIdSelector} from '../../../../reducers/selectors/add.offer.wizard.selectors';
import {
    IsFetchingCurrentOfferSelector,
    TransitionOfferingIdSelector
} from '../../../../reducers/selectors/transition.offer.wizard.selectors';

function OfferingsController($timeout, $ngRedux, $anchorScroll, uiNotificationService) {
    let disconnectRedux;
    const MAX_SLOTS_FOR_PAGER = 5;

    const mapStateToTarget = (store) => {
        return {
            changeOfferingInstanceId: ChangeOfferingInstanceIdSelector(store),
            facetCategoriesLoaded: MetadataCodeLoadedSelector(CODES.OfferingFacetCategory, store),
            facetsLoaded: MetadataCodeLoadedSelector(CODES.OfferingFacet, store),
            isFetchingAvailableOffers: IsFetchingSelector(store),
            isFetchingCurrentOffer: IsFetchingCurrentOfferSelector(store),
            isFetchingDecisionPages: PagesIsFetchingDataSelector(store),
            isLoaded: IsLoadedSelector(store),
            maximumSlots: MAX_SLOTS_FOR_PAGER,
            newConnectCustomer: EditCustomerInfoSelector(store),
            numberOfPages: NumberOfPagesSelector(store),
            offeringMetadata: OfferingMetadataByIdSelector(store),
            pageNumber: PageNumberSelector(store),
            recordCount: RecordCountSelector(store),
            selectedContract: SelectedContractSelector(store),
            selectedPageSizePreference: SelectedPageSizePreference(store),
            transitionOfferingId: TransitionOfferingIdSelector(store),
            userPageSizePreference: PageSizePreferenceSelector(store)
        };
    };

    this.$onInit = () => {
        this.TRANSITION = ContextOfferingAction.TRANSITION;
        const controllerActions = {
            fetchOfferingFacetCategoryTypes,
            fetchOfferingFacetTypes,
            keywordSearch,
            keywordSearchWithCustomerId,
            retrieveOfferingsMetadata,
            setPageNumber,
            setPageSizePreference,
            setSelectedContract,
            setSelectedOfferName
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareKeys = CustomerCareKeys;

        if (!this.state.facetCategoriesLoaded) {
            this.actions.fetchOfferingFacetCategoryTypes();
        }

        if (!this.state.facetsLoaded) {
            this.actions.fetchOfferingFacetTypes();
        }

        this.keyword = '';
        this.offerGrayoutFilter = this.offerGrayoutFilter || this.defaultFilter;
        this.currentOfferType = null;

        if (this.state.transitionOfferingId) {
            this.actions.retrieveOfferingsMetadata([this.state.transitionOfferingId]).then(() => {
                this.currentOfferType = this.state.offeringMetadata[this.state.transitionOfferingId].OfferType;
                this.searchForPage(1);
            }).catch((error) => {
                uiNotificationService.transientError(error.translatedMessage);
            });
        } else {
            this.searchForPage(1);
        }
        this.setShowContractSection();
    };

    this.$onChanges = (changesObj) => {
        if (changesObj.selectedOfferId) {
            if (changesObj.selectedOfferId.isFirstChange() && !changesObj.selectedOfferId.currentValue) {
                this.setCurrentStepIsInvalid()(true);
            } else {
                this.setSelectedOfferEditCopy()(this.selectedOfferId);
            }
        }
        if (changesObj.contracts && !changesObj.contracts.isFirstChange()) {
            this.setShowContractSection();
        }
        if (changesObj.continueClicked && this.continueClicked) {
            $anchorScroll('top');
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.defaultFilter = () => {
        return () => {
            return true;
        };
    };

    this.setShowContractSection = () => {
        this.showContractSection = (this.orderScenario === ORDER_SCENARIOS.NEW_CONNECT || this.orderScenario === ORDER_SCENARIOS.ADD_OFFER) &&
            this.contracts.length &&
            (!this.isMultiOffer || this.state && this.state.selectedContract);
    };

    this.performSearch = () => {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        const additionalOptions = {
            // This currency code implementation is a temporary solution, should use the one on the subscriber
            // if it exists which it should on add flow
            currencyCode: this.currencyCode,
            chargeTypeIdsSelector:  this.offeringChargeTypeIdSelector(),
            facetIdsSelector: this.selectedFacetIdsSelector(),
            keyword: this.keyword,
            nonBulkOnly: this.nonBulkOnly,
            offerType: (this.currentOfferType >= 0) ? this.currentOfferType : null,
            orderScenario: this.orderScenario,
            pageNumber:  this.state.pageNumber,
            pageSize: this.state.selectedPageSizePreference
        };

        if (this.customerId) {
            this.state.changeOfferingInstanceId ? this.actions.keywordSearchWithCustomerId(this.customerId,
                additionalOptions, this.contextOfferingAction, DEVICE_TYPE_OFFER) : this.actions.keywordSearchWithCustomerId(this.customerId,
                additionalOptions, this.contextOfferingAction);
        } else {
            additionalOptions['subtenants'] = (this.state.newConnectCustomer.SubtenantId)? [this.state.newConnectCustomer.SubtenantId] : [];
            this.actions.keywordSearch(this.postalCode,
                this.state.newConnectCustomer.Category,
                this.state.newConnectCustomer.SubscriberTypeCode,
                this.state.newConnectCustomer.Language,
                additionalOptions, this.contextOfferingAction);
        }
    };

    this.onOfferingSelect = (offerId) => {
        this.onOfferingSelected()(offerId);
        const offerName = this.offerOptionViewModel.find(propEq(offerId, 'Id')).DisplayName;
        this.actions.setSelectedOfferName(offerName);
    };

    this.onOfferingUnselect = () => {
        if (this.onOfferingDeselected()) {
            this.onOfferingDeselected()();
        }
        this.setSelectedOfferEditCopy()(null);
        this.setSelectedOffer()(null); // The user shouldn't have to continue or go back for this deselection to take effect
        this.setCurrentStepIsInvalid()(true);
    };

    this.clearSelectedContract = () => {
        this.actions.setSelectedContract(null);
        this.searchForPage(1);
    };

    this.openSelectContractPopup = () => {
        this.contractDialogOpened = true;
        $timeout(this._contractPopupApi.open);
    };

    this.successContractPopup = (selectedContract) => {
        this._contractPopupApi.close();
        this.contractDialogOpened = false;
        this.actions.setSelectedContract(selectedContract);
        this.searchForPage(1);
    };


    this.closeContractPopup = () => {
        this._contractPopupApi.close();
        this.contractDialogOpened = false;
    };

    this.openContractTermsConditionsPopup = () => {
        this.contractTermsConditionsPopupOpened = true;
        $timeout(() => {
            this.contractTermsConditionsPopupApi.open();
        });
    };

    this.handleCloseContractTermsConditionsPopup = () => {
        this.contractTermsConditionsPopupApi.close();
        this.contractTermsConditionsPopupOpened = false;
    };

    this.title = i18n.translate(CustomerCareKeys.NARROW_RESULTS);
    this.noAvailableOfferingsMessage = i18n.translate(CustomerCareKeys.NO_OFFERINGS_AVAILABLE);

    this.filtersDescriptor = {
        group: {
            name: 'groupName',
            filters: 'filters'
        },
        filter: {
            name: 'name',
            checked: 'checked',
            disabled: 'disabled'
        }
    };

    this.filterSelected = (filterGroup, selectedFilter, isChecked) => {
        switch (selectedFilter.type) {
            case OPTION_OFFERING_CHARGE_TYPE:
                this.setSelectedOfferingChargeTypesEditCopy()(selectedFilter.id, !isChecked);
                this.searchForPage(1);
                break;
            default:
                this.setSelectedFacetsEditCopy()(selectedFilter.id, !isChecked);
                this.searchForPage(1);
        }
    };

    this.contractPopupConfig = {
        onRegisterApi: (event) => {
            this._contractPopupApi = event.api;
        }
    };

    this.quickViewPopupConfig = {
        onRegisterApi: (event) => {
            this._quickViewPopupApi = event.api;
        }
    };

    this.contractTermsConditionsPopupConfig = {
        onRegisterApi: (event) => {
            this.contractTermsConditionsPopupApi = event.api;
        }
    };

    this.clearSearchText = () => {
        this.keyword = undefined;
        this.searchForPage(1);
    };

    this.keywordGo = (event) => {
        event.preventDefault();
        this.searchForPage(1);
    };

    this.onQuickView = (id) => {
        this.quickViewOffer = this.offerOptionViewModel.find(propEq(id, 'Id'));
        this.showQuickViewDialog = true;
        $timeout(this._quickViewPopupApi.open);
    };
    this.handleSelectOfferQuickViewDialog = (id) => {
        this.onOfferingSelected()(id);
        this._quickViewPopupApi.close();
        this.showQuickViewDialog = false;
    };

    this.handleCloseOfferQuickViewDialog = () => {
        this._quickViewPopupApi.close();
        this.showQuickViewDialog = false;
    };

    this.searchForPage = (pageNum) => {
        this.actions.setPageNumber(pageNum);
        this.performSearch();
    };

    this.onPageSizeOptionSelected = (pageSize) => {
        this.actions.setPageSizePreference(pageSize);
        this.searchForPage(1);
    };
    this.shouldShowError = () => {
        return this.stepIsInvalid && this.continueClicked;
    };
}

export default {
    template: require('./offerings.html'),
    controller: OfferingsController,
    controllerAs: 'offeringsController',
    bindings: {
        contextOfferingAction: '<',
        continueClicked: '<',
        contracts: '<?',
        currencyCode: '<',
        customerId: '<',
        goToPreviousStep: '&',
        groupedCheckboxFilterOptions: '<',
        isMultiOffer: '<?',
        nonBulkOnly: '<?',
        offerGrayoutFilter: '&?',
        offeringChargeTypeIdSelector: '&',
        offerOptionViewModel: '<',
        onOfferingDeselected: '&',
        onOfferingSelected: '&',
        orderScenario: '<',
        postalCode: '<',
        selectedFacetIdsSelector: '&',
        selectedOfferId: '<',
        setCurrentStepIsInvalid: '&',
        setSelectedFacetsEditCopy: '&',
        setSelectedOffer: '&',
        setSelectedOfferEditCopy: '&',
        setSelectedOfferingChargeTypesEditCopy: '&',
        stepIsInvalid: '<',
        subtenants: '<'
    }
};
