import {forEachObjIndexed} from 'ramda';

import {IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES} from '../order.details.constants';

const DEFAULT_FORMATTED_FILE_IMPORT_RESULT = {
    [IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.FAILURE]: {
        classes: 'is-error',
        glyph: 'status-pending-filled',
        message: null,
        status: IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.FAILURE
    },
    [IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.PROGRESS]: {
        classes: 'is-warning',
        glyph: 'status-progress-filled',
        message: null,
        status: IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.PROGRESS
    },
    [IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.SUCCESS]: {
        classes: 'is-success',
        glyph: 'status-complete-filled',
        message: null,
        status: IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES.SUCCESS
    }
};

export const generateFormattedFileResult = (statusType, message) => {
    return Object.assign({},
        DEFAULT_FORMATTED_FILE_IMPORT_RESULT[statusType],
        {
            message: message
        });
};

export const returnExpiredImportBulkServiceAttributesJobsPendingUpdatedStatus = (orderItems, bulkServiceAttributesJobsPendingUpdatedStatus) => {
    const jobsToBeRemoved = [];

    forEachObjIndexed((jobDetails, jobPricingPlanId) => {
        const jobPricingPlanIdAsInt = Number(jobPricingPlanId);

        const orderItem = orderItems[jobDetails.offeringId].find((item) => {
            return item.pricingPlanId === jobPricingPlanIdAsInt;
        });

        if (orderItem.BulkExtractItemDetail.Modified > jobDetails.dateLastModified) {
            jobsToBeRemoved.push({
                offeringId: jobDetails.offeringId,
                pricingPlanId: jobPricingPlanIdAsInt
            });
        }
    }, bulkServiceAttributesJobsPendingUpdatedStatus);

    return jobsToBeRemoved;
};
