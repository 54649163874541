import {cloneUserSession} from 'invision-core/src/components/session/authentication.actions';
import {EnvironmentSettingNameSelector} from 'invision-core/src/components/environment/environment.selectors';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';

import {CustomWidgetHostedUrlBaseSelector} from 'invision-core/src/components/customWidget/custom.widget.selectors';
import {STATUSES as CUSTOM_WIDGET_STATUSES} from 'invision-core/src/components/customWidget/custom.widget.constants';
import {CustomWidgetContainer} from 'invision-core/src/components/customWidget/custom.widget.container';
import {isApiEvent as isCustomWidgetApiEvent} from 'invision-core/src/components/customWidget/custom.widget.helpers';
import {UserSecurityContextSelector} from 'invision-core/src/components/session/session.selectors';

import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector
} from '../../../../reducers/selectors/customer.selectors';
import {retrieveCustomer} from '../../../../reducers/actions/customer.actions';
import {searchServices} from '../../../../reducers/actions/services.actions';

import {
    CustomWidgetHasApiIntegrationSelector,
    CustomWidgetIsErroredSelector,
    CustomWidgetIsExpandedSelector,
    CustomWidgetIsLoadingSelector,
    CustomWidgetSelector
} from '../../../../reducers/selectors/custom.widgets.selectors';
import {
    initCustomWidget,
    setCustomWidgetHeight,
    setCustomWidgetIsExpanded,
    setCustomWidgetStatus
} from '../../../../reducers/actions/custom.widgets.actions';

import LocaleKeys from '../../../../locales/keys';

const WIDGET_ELEMENT_ID_PREFIX = 'dashboard-custom-widget';

class CustomWidgetController {
    constructor($ngRedux, $sce, $state, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $sce,
            $state,
            $timeout,
            LocaleKeys,
            widget: null
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentBusinessUnit: CurrentBusinessUnitSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentUserContext: UserSecurityContextSelector(store),
                customWidget: CustomWidgetSelector(this.widgetData.Id)(store),
                customWidgetHasApiIntegration: CustomWidgetHasApiIntegrationSelector(this.widgetData.Id)(store),
                customWidgetHostedUrlBase: CustomWidgetHostedUrlBaseSelector(store),
                customWidgetIsErrored: CustomWidgetIsErroredSelector(this.widgetData.Id)(store),
                customWidgetIsExpanded: CustomWidgetIsExpandedSelector(this.widgetData.Id)(store),
                customWidgetIsLoading: CustomWidgetIsLoadingSelector(this.widgetData.Id)(store),
                environmentName: EnvironmentSettingNameSelector(store)
            };
        };

        const controllerActions = {
            cloneUserSession,
            initCustomWidget,
            retrieveCustomer,
            searchServices,
            setCustomWidgetHeight,
            setCustomWidgetIsExpanded,
            setCustomWidgetStatus
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.actions = actions;
            this.state = state;
        });

        this.actions.initCustomWidget({
            height: this.widgetData.Height,
            id: this.widgetData.Id,
            integrationTypeId: this.widgetData.IntegrationTypeId,
            isExpanded: this.widgetData.Expanded,
            statusId: CUSTOM_WIDGET_STATUSES.processing
        });

        if (this.state.customWidgetHasApiIntegration && this.state.customWidgetIsExpanded) {
            this.$timeout(() => {
                this.initCustomWidgetWithApiIntegration();
            });
        } else if (!this.state.customWidgetHasApiIntegration) {
            this.iframeUrl = this.$sce.trustAsResourceUrl(this.widgetData.URL.startsWith('http') ?
                this.widgetData.URL :
                `https://${this.widgetData.URL}`
            );

            // events for when a message is received from the widget
            this.receiveWidgetDataEvent = (evt) => {
                if (!isCustomWidgetApiEvent(evt) && this.iframeUrl) {
                    const urlElement = new URL(this.iframeUrl);

                    if (evt.origin === urlElement.origin) {
                        let data = null;

                        try {
                            data = JSON.parse(evt.data);
                        } catch (e) {
                            data = evt.data;
                        }

                        // start parsing data with url as a required to differentiate between listeners
                        if (typeof(data) === 'object' && (data.url === urlElement.href || data.url === this.widgetData.URL)) {
                            if (!isNaN(data.height)) {
                                this.actions.setCustomWidgetHeight({
                                    id: this.widgetData.Id,
                                    height: data.height
                                });
                            }
                        }
                    }
                }
            };

            // listen for messages from the widget
            if (window.addEventListener) {
                window.addEventListener('message', this.receiveWidgetDataEvent, false);
            } else { // support for IE8
                window.attachEvent('onmessage', this.receiveWidgetDataEvent);
            }

            this.sendConfiguredWidgetData = JSON.stringify({
                SubscriberId: this.widgetData.SendSubscriberId ? this.state.currentCustomer.Id : undefined,
                SubscriberExternalReferenceId: this.widgetData.SendSubscriberExternalReferenceId ? this.state.currentCustomer.ExternalReference: undefined,
                AgentId: this.widgetData.SendAgentId ? this.state.currentUserContext.Id : undefined,
                AgentRole: this.widgetData.SendAgentRole ? this.state.currentUserContext.Roles : undefined
            });
        }
    }

    $onDestroy() {
        if (this.receiveWidgetDataEvent) {
            window.removeEventListener('message', this.receiveWidgetDataEvent, false);
        }

        if (this.widget && this.widget.disconnect) {
            this.widget.disconnect();
        }

        this.disconnect();
    }

    handleExpansion() {
        const isExpanded = !this.state.customWidgetIsExpanded;

        this.actions.setCustomWidgetIsExpanded({
            id: this.widgetData.Id,
            isExpanded: isExpanded
        });

        if (isExpanded) {
            this.reloadIframe();
        }
    }

    iframeOnLoadCallback() {
        this.actions.setCustomWidgetStatus({
            id: this.widgetData.Id,
            statusId: CUSTOM_WIDGET_STATUSES.idle
        });
    }

    iframeOnFailCallback() {
        this.actions.setCustomWidgetStatus({
            id: this.widgetData.Id,
            statusId: CUSTOM_WIDGET_STATUSES.errored
        });
    }

    initCustomWidgetWithApiIntegration() {
        return CustomWidgetContainer.call(this, {
            actions: {
                boundCloneUserSession: {
                    enumerable: false,
                    value: this.actions.cloneUserSession
                },
                boundRetrieveCustomer: {
                    enumerable: false,
                    value: this.actions.retrieveCustomer
                },
                boundSearchServices: {
                    enumerable: false,
                    value: this.actions.searchServices
                },
                boundSetCustomWidgetStatus: {
                    enumerable: false,
                    value: this.actions.setCustomWidgetStatus
                }
            },
            container: angular.element(`#${WIDGET_ELEMENT_ID_PREFIX}-${this.widgetData.Id}`)[0],
            data: {
                businessUnit: {
                    id: this.state.currentBusinessUnit.id,
                    name: this.state.currentBusinessUnit.name,
                    systemId: this.state.currentBusinessUnit.systemId
                },
                customerId: this.state.currentCustomerId,
                environment: this.state.environmentName,
                user: {
                    id: this.state.currentUserContext.Id,
                    roles: this.state.currentUserContext.Roles
                },
                widgetId: this.widgetData.Id
            },
            methods: {

                /**
                 * Disconnect the container and widget.
                 * @returns {void}
                 */
                disconnect() {

                    // Remove the iFrame element and destroy any `message` event listeners
                    if (this.widget && this.widget.disconnect) {
                        this.widget.disconnect();
                    }
                },

                /**
                 * Handle an encountered error.
                 * @param {Error} error - Error encountered.
                 *
                 * @returns {Promise.<Error>} Promise object that _resolves_ to the provided error to support chaining.
                 */
                handleError(error) {
                    return new Promise((resolve) => {
                        this.actions.setCustomWidgetStatus({
                            id: this.widgetData.Id,
                            statusId: CUSTOM_WIDGET_STATUSES.errored
                        });

                        resolve(error);
                    });
                }
            },
            state: this.$state,
            url: `${this.state.customWidgetHostedUrlBase}/${this.widgetData.Id}/index.html`
        })
            .then((instance) => {
                this.widget = instance;

                if (this.widget && this.widget.api && this.widget.api.log) {
                    this.widget.api.log('Message sent from widget container using the example widget\'s custom `log` method...');
                }

                return Promise.resolve(instance);
            })
            .catch((error) => {
                this.actions.setCustomWidgetStatus({
                    id: this.widgetData.Id,
                    statusId: CUSTOM_WIDGET_STATUSES.errored
                });

                return Promise.reject(error);
            });
    }

    reloadIframe() {
        const container = angular.element(`#${WIDGET_ELEMENT_ID_PREFIX}-${this.widgetData.Id}`)[0];
        if (container && container.hasChildNodes()) {
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
        }

        this.actions.setCustomWidgetStatus({
            id: this.widgetData.Id,
            statusId: CUSTOM_WIDGET_STATUSES.processing
        });

        if (this.state.customWidgetHasApiIntegration) {
            this.$timeout(() => {
                this.initCustomWidgetWithApiIntegration();
            });
        }
    }
}

export default {
    template: require('./custom.widget.html'),
    bindings: {
        widgetData: '<'
    },
    controller: CustomWidgetController,
    controllerAs: 'ctrl'
};

