import {createSelector} from 'reselect';
import {
    i18n,
} from 'invision-core';

import {
    FormattedCaseCategoryCodeSelector,
    FormattedCasePriorityCodeSelector,
    FormattedCaseSeverityCodeSelector,
    FormattedDisputeInvoiceCaseTypesSelector
} from '../../../reducers/selectors/customer.cases.selectors';

import LocaleKeys from '../../../locales/keys';

export const CasePriorityOptionsSelector = createSelector(
    [FormattedCasePriorityCodeSelector],
    (priorities) => {
        const selectOption = {
            text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.SELECT),
            value: undefined // must be undefined for required validation to work
        };
        return [selectOption].concat(priorities);
    }
);

export const CaseSeverityOptionsSelector = createSelector(
    [FormattedCaseSeverityCodeSelector],
    (severities) => {
        const selectOption = {
            text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.SELECT),
            value: undefined // must be undefined for required validation to work
        };
        return [selectOption].concat(severities);
    }
);

export const CaseCategoryOptionsSelector = createSelector(
    [FormattedCaseCategoryCodeSelector],
    (categories) => {
        const selectOption = {
            text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.SELECT),
            value: undefined // must be undefined for required validation to work
        };
        return [selectOption].concat(categories);
    }
);

export const DisputeInvoiceCaseTypesOptionsSelector = createSelector(
    [FormattedDisputeInvoiceCaseTypesSelector],
    (categories) => {
        const selectOption = {
            text: i18n.translate(LocaleKeys.ORDER_DETAILS.CREDIT.SELECT),
            value: undefined // must be undefined for required validation to work
        };
        return [selectOption].concat(categories);
    }
);
