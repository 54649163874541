export const ADJUSTMENT_CATEGORIES = {
    FINANCE: 'FINANCE',
    OTHER: 'OTHER'
};

export const NOTE_TYPES = {
    CREDIT: '1006',
    DEBIT: '1007'
};

export const MIN_NOTE_AMOUNT = 0.01;
export const MAX_DESCR_LENGTH = 200;
export const INVOICE_START_DATE_DURATION = 6;
export const DEBIT_NOTE = 'Debit Note';
export const INVOICE = 'Invoice';

