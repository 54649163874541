import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    CoreLocaleKeys,
    DateTimeHelper,
    i18n,
    InjectorHelper
} from 'invision-core';
import {
    indexOf,
    find,
    forEach,
    map,
    propEq
} from 'ramda';

const defaultCurrency = 'USD';
export function getCodeName(value, Codes) {
    const val = find(propEq(String(value), 'Value'))(Codes);
    return val ? val.Name : value;
}

export function getPricingPlanName(depositTypeId, orders, storedOrdersWithDeposits) {
    let pricingPlanName = '';
    forEach((order) => {
        forEach((orderItem) => {
            const depositFound = find((deposit) => {
                return (depositTypeId === deposit.DepositTypeId);
            }, orderItem.PricingPlan.Deposits);

            if (!pricingPlanName && depositFound && !isOrderItemIdTaken(orderItem.Id, depositTypeId, storedOrdersWithDeposits)) {
                pricingPlanName = orderItem.PricingPlan.Name;
                storeDepositInOrder(depositTypeId, orderItem.Id, storedOrdersWithDeposits);
            }
        }, order.OrderItems);
    }, orders);

    return pricingPlanName;
}

export function getCaseTypeName(caseTypeId, caseTypes) {
    const caseType = find(propEq(caseTypeId, 'Id'))(caseTypes || []);
    return caseType ? caseType.DisplayName : caseTypeId;
}

function isOrderItemIdTaken(orderId, depositTypeId, storedOrdersWithDeposits) {
    return ~indexOf(depositTypeId, storedOrdersWithDeposits[orderId] || []);
}

function storeDepositInOrder(depositTypeId, orderId, storedOrdersWithDeposits) {
    if (!storedOrdersWithDeposits[orderId]) {
        storedOrdersWithDeposits[orderId] = [];
        storedOrdersWithDeposits[orderId].push(depositTypeId);
    } else {
        storedOrdersWithDeposits[orderId].push(depositTypeId);
    }
}

export function createSelectOptionsForCodes(codes) {
    return codes ? codes.map((code) => {
        return {
            text: code.Name,
            value: code.Value
        };
    }) : [];
}

export function createSelectOptionsForCaseActivities(activities) {
    let options = [];
    if (activities) {
        const addAdditionalProperties = (additionalProperty) => {
            return Object.assign({}, additionalProperty, {
                Value: ''
            });
        };
        const addToOptions = (activity) => {
            return {
                text: activity.Name,
                additionalProperties: map(addAdditionalProperties, activity.AdditionalProperties),
                value: activity.Id
            };
        };
        options = map(addToOptions, activities);
    }
    return options;
}

export function createSelectOptionsForInvoices(invoices) {
    const $filter = InjectorHelper.getFilterService();

    if (!invoices || invoices.length === 0) {
        return [];
    }
    const addToOptions = (invoice) => {
        return {
            text: `${$filter('localShortDate')(invoice.StartDate)} - ${$filter('localShortDate')(invoice.EndDate)} - ${invoice.InvoiceNumber}`,
            value: invoice.InvoiceNumber
        };
    };
    return map(addToOptions, invoices);
}

export function createTableViewModel(views, statusCodes, categoryCodes, priorityCodes, severityCodes) {
    const tableData = {
        columnDefs: [{
            field: 'caseNumber',
            displayName: i18n.translate(CustomerCareLocaleKeys.CASE_NUMBER),
            cellTemplate: require('./cellTemplates/caseNumber.template.html'),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            field: 'caseType',
            displayName: i18n.translate(CustomerCareLocaleKeys.CASE_TYPE),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            cellTemplate: require('./cellTemplates/status.template.html'),
            field: 'status',
            displayName: i18n.translate(CoreLocaleKeys.STATUS),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            cellTemplate: require('./cellTemplates/summary.template.html'),
            field: 'summary',
            displayName: i18n.translate(CoreLocaleKeys.SUMMARY),
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            field: 'priority',
            displayName: i18n.translate(CustomerCareLocaleKeys.PRIORITY),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            field: 'severity',
            displayName: i18n.translate(CustomerCareLocaleKeys.CASE.SEVERITY),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false
        }, {
            cellTemplate: require('./cellTemplates/created.date.template.html'),
            field: 'created',
            displayName: i18n.translate(CoreLocaleKeys.CREATED),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false,
            sortingAlgorithm: DateTimeHelper.dateTimeComparator

        }, {
            field: 'modified',
            displayName: i18n.translate(CoreLocaleKeys.MODIFIED),
            cellTemplate: require('./cellTemplates/modified.date.template.html'),
            width: '*',
            minWidth: 70,
            resizable: true,
            enableSorting: false,
            enableColumnMoving: false,
            sortingAlgorithm: DateTimeHelper.dateTimeComparator
        }]
    };

    const formatView = (view) => {
        return {
            caseNumber: view.CaseNumber,
            caseType: getCaseTypeName(view.Category, categoryCodes),
            id: view.Id.Value,
            status: getCodeName(view.Status, statusCodes),
            summary: view.Summary,
            priority: getCodeName(view.PriorityCode, priorityCodes),
            severity: getCodeName(view.SeverityCode, severityCodes),
            created: view.Added,
            modified: view.Modified
        };
    };

    if (views) {
        tableData.data = map(formatView, views);
    }

    return tableData;
}

function getDespositsStatus() {
    return [{
        Name: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.DEPOSIT_STATUS.ACTIVE),
        Value: '1'
    }, {
        Name: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.DEPOSIT_STATUS.RETURNED),
        Value: '2'
    }, {
        Name: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.DEPOSIT_STATUS.FORFEITED),
        Value: '3'
    }, {
        Name: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.DEPOSIT_STATUS.MATURED),
        Value: '4'
    }, {
        Name: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.DEPOSIT_STATUS.ENTERED),
        Value: '5'
    }
    ];
}

export function createTableDepositsHeaders() {
    return [{
        field: 'name',
        displayName: i18n.translate(CoreLocaleKeys.NAME),
        width: '*',
        minWidth: 100,
        resizable: true,
    }, {
        field: 'status',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.STATUS),
        width: '*',
        minWidth: 80,
        maxWidth: 129,
        resizable: true,
    }, {
        field: 'pricingPlan',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.PRICING_PLAN),
        width: '*',
        minWidth: 180,
        resizable: true,
    }, {
        field: 'collectionDate',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.COLLECTION_DATE),
        width: '*',
        minWidth: 140,
        maxWidth: 160,
        resizable: true,
    }, {
        field: 'maturityDate',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.MATURITY_DATE),
        width: '*',
        minWidth: 140,
        maxWidth: 160,
        resizable: true,
    }, {
        field: 'paymentAmount',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.PAYMENT_AMOUNT),
        width: '*',
        minWidth: 180,
        maxWidth: 200,
        resizable: true,
    }, {
        field: 'totalAmount',
        displayName: i18n.translate(CustomerCareLocaleKeys.DEPOSITS.TOTAL_AMOUNT),
        width: '*',
        minWidth: 140,
        maxWidth: 160,
        resizable: true,
    }];
}

export function createDepositsTableViewModel(views, depositTypeCodes, accountSummary, orderHistory) {
    const $filter = InjectorHelper.getFilterService();
    const storedOrdersWithDeposits = {};
    const formatView = (view) => {
        if (!view) {
            return {};
        } else {
            return {
                name: getCodeName(view.DepositTypeCode, depositTypeCodes),
                collectionDate: (view.CollectionDate) ? $filter('localShort')(view.CollectionDate) : null,
                status: getCodeName(view.DepositStatus, getDespositsStatus()),
                paymentAmount: $filter('invCurrency')(view.PaymentAmount, accountSummary.currencyCode || defaultCurrency),
                pricingPlan: getPricingPlanName(view.DepositTypeCode, orderHistory, storedOrdersWithDeposits),
                totalAmount: $filter('invCurrency')(view.TotalAmount, accountSummary.currencyCode || defaultCurrency),
                maturityDate: (view.MaturityDate) ? $filter('localShort')(view.MaturityDate) : null
            };
        }
    };
    views = map(formatView, views);
    return views;
}
