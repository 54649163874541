import localeKeys from '../../../../../locales/keys';
import {REGEXES} from '../../../../../customercare.constants';

class GrantCouponPopupController {
    constructor() {
        this.localeKeys = localeKeys;
        this.viewModel = {
            errorMessages: [],
            emailRegex: REGEXES.EMAIL,
            isGrantingCoupon: false,
            selectedCoupon: null
        };
        this._isInitialized = false;
    }
    $onInit() {
        this.viewModel.grantCouponModel = {
            SendNotification: true,
            GrantCouponTimes: 1,
            GrantCouponEmail: this.customer.Email
        };
        this._isInitialized = true;
        this.updateViewModel();
    }

    $onChanges() {
        this.updateViewModel();
    }

    updateViewModel() {
        if (!this._isInitialized) {
            return;
        }
        this.viewModel.isGrantingCoupon = this.isGrantingCoupon;
        this.viewModel.selectedCoupon = this.selectedCoupon;
        if (this.errorMessage) {
            this.viewModel.errorMessages = [this.errorMessage];
        } else {
            this.viewModel.errorMessages = [];
        }
    }

    handleClose() {
        this.onClose()();
    }
    handleSubmitForm() {
        if (this.grantCouponPopupForm.$invalid) {
            return;
        }
        const actionData = {
            CouponId: this.selectedCoupon.Id,
            RecipientEmailAddress: this.viewModel.grantCouponModel.GrantCouponEmail,
            RedemptionCodeCount: this.viewModel.grantCouponModel.GrantCouponTimes,
            SendNotification: this.viewModel.grantCouponModel.SendNotification
        };
        this.onSubmit()(actionData);
    }

    handleKeydown(event) {
        //This function is specially designed to prevent anything from happening when the user hits the "enter key"
        const ENTER_KEYCODE = 13;
        const keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;

        if (keyCode === ENTER_KEYCODE) {
            event.cancelBubble = true;
            event.returnValue = false;

            if (event.stopPropagation) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
    }
}

export default {
    template: require('./grant.coupon.popup.html'),
    bindings: {
        customer: '<',
        errorMessage: '<',
        isGrantingCoupon: '<',
        onClose: '&',
        onSubmit: '&',
        selectedCoupon: '<',
        selectedCouponAssociationCardsData: '<'
    },
    controller: GrantCouponPopupController,
    controllerAs: 'GrantCouponPopup'
};
