import LocaleKeys from '../../../../locales/keys';


class ModifyExpirationDateController {
    constructor() {
        Object.assign(this, {
            formFieldLocaleKeyMapping: {},
            LocaleKeys: LocaleKeys,
            minimumDate: new Date()
        });
    }

    clearDateTime() {
        this.expirationDate = null;
    }

    closeDialog() {
        this.onClose()();
    }

    onExpirationDateChanged(date) {
        this.expirationDate = date;
    }

    onSubmitDateChange() {
        if (this.modifyExpirationDateForm.$invalid) {
            return;
        }
        this.onSubmit()(this.expirationDate);
    }
}

export default {
    template: require('./modify.expiration.date.modal.html'),
    bindings: {
        onClose: '&',
        onSubmit: '&',
        expirationDate: '<?'
    },
    controller: ModifyExpirationDateController
};
