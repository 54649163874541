import CustomerCareLocaleKeys from '../../../locales/keys';

class ContactMethodsSectionController {
    constructor() {
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.contactPreferencesModel = {};
        this.contactEventTypeToContactMethods = [];
    }

    $onInit() {
        this.contactPreferencesModel = this.contactPreferencesDictionary.asMutable({
            deep:true
        });
        this.initializeContactEventTypeToContactModel();
    }

    handleFormChanged() {
        this.updateCustomerModel(this.customerModel, this.contactPreferencesModel);
        this.onFormChanged()(this.customerModel);
    }

    getContactPreferences(contactPreferences) {
        const updatedContactPreferences = [];
        const cpKeys = Object.keys(contactPreferences);
        //Iterate through the event types (keys)
        cpKeys.forEach((eventTypeId) => {
            //Iterate through the contact methods
            contactPreferences[eventTypeId] && contactPreferences[eventTypeId].forEach((contactMethod) => {
                updatedContactPreferences.push({
                    ContactEventType: eventTypeId,
                    ContactMethod: contactMethod,
                    OptIn: true
                });
            });
        });

        return updatedContactPreferences;
    }

    updateCustomerModel(customerModel, contactPreferences) {
        customerModel.ContactPreferences = this.getContactPreferences(contactPreferences);
    }

    initializeContactEventTypeToContactModel() {
        //Iterate through all contact event types, and determine what contact methods should be associated to each one
        this.contactEventTypes.forEach((contactEventType) => {
            //Get the mappings for the specific contact event type
            const contactEventTypeToContactMethodMappings = this.contactMapping.filter((contactMapping) => {
                const contactEventTypeCode = contactMapping.AdditionalProperties.find((additionalProperty)  => {
                    return additionalProperty.Key === 'contact_event_type_code';
                });
                return contactEventTypeCode.Value === contactEventType.Value;
            });
            //If mappings are found, we should not set all contact methods as possible choices in the dropdown
            if (contactEventTypeToContactMethodMappings && contactEventTypeToContactMethodMappings.length) {
                const contactMethodOptions = [];
                //Interate through the found mappings for the contact event, and save the contact method label and value
                contactEventTypeToContactMethodMappings.forEach((contactEventTypeToContactMethodMapping) => {
                    const contactMethodFromMapping = contactEventTypeToContactMethodMapping.AdditionalProperties.find((additionalProperty) => {
                        return additionalProperty.Key === 'contact_method_code';
                    });
                    const contactMethod = this.contactMethodOptions.find((contactMethodOption) => {
                        return contactMethodOption.value === contactMethodFromMapping.Value;
                    });
                    //Add the contact method lable and value to the contact method options array
                    contactMethodOptions.push({
                        label: contactMethod.label,
                        value: contactMethod.value
                    });
                });
                //Add the contact event name and value, and the associated contact methods
                this.contactEventTypeToContactMethods.push({
                    ContactEventTypeValue: contactEventType.Value,
                    ContactEventTypeName: contactEventType.Name,
                    ContactMethods: contactMethodOptions
                });
            } else { //If mappings are not found, set all possible contact methods as choices in the dropdown
                this.contactEventTypeToContactMethods.push({
                    ContactEventTypeValue: contactEventType.Value,
                    ContactEventTypeName: contactEventType.Name,
                    ContactMethods: this.contactMethodOptions
                });
            }
        });
    }
}

export default {
    template: require('./contact.methods.section.html'),
    bindings: {
        contactEventTypes: '<',
        contactMapping: '<',
        contactMethodOptions: '<',
        customerModel:'<',
        contactPreferencesDictionary: '<',
        isFormSubmitted: '<',
        onFormChanged: '&'
    },
    controller: ContactMethodsSectionController
};
