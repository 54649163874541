import CustomerCareKeys from '../../../../../../locales/keys';

class SubscriptionFeesController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            waiveFee: false,
            waiveFeeReason: null,
            waiveLateFee: false,
            waiveLateFeeReason: null
        });
    }
}

export default {
    template: require('./subscription.fees.html'),
    bindings: {
        currency: '<',
        lateFee: '<',
        onWaiveFeeReasonSelected: '&',
        onWaiveFeeSelected: '&',
        onWaiveLateFeeReasonSelected: '&',
        onWaiveLateFeeSelected: '&',
        subscriptionHasLateFee: '<',
        waiveFeeReasonOptions: '<'
    },
    controller: SubscriptionFeesController
};