import angular from 'angular';
import 'angular-ui-grid';
import {AmountSummaryQuickInfoMountPoint} from '@invision/customercare2/src/shared/components/contextualComponents/AccountSummaryQuickInfo/AccountSummaryQuickInfo.mountPoint';
import {react2angular} from 'react2angular';
import {routing as RoutingConfig} from './list.config';
import BillingPaymentsList from './list.component';

export default angular.module('invision.customercare.customer.billingpayments.list', [
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.moveColumns'
])
    .config(RoutingConfig)
    .component('billingPaymentsList', BillingPaymentsList)
    .component('amountSummaryQuickInfo', react2angular(AmountSummaryQuickInfoMountPoint, ['data', 'isOpen', 'onClose']))
    .name;
