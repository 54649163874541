import angular from 'angular';

import CustomPageComponent from './custom.page.component';
import CustomPageContextualComponent from './custom.page.contextual';
import {routing as RoutingConfig} from './custom.page.config';

export default angular.module('invision.customercare.customPage', [])
    .component('customPage', CustomPageComponent)
    .component('customPageContextual', CustomPageContextualComponent)
    .config(RoutingConfig)
    .name;
