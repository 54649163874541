import LocaleKeys from '../../../locales/keys';

class ChangeAddressController {
    constructor() {
        this.LocaleKeys = LocaleKeys;
    }

    $onChanges(changesObj) {
        if (changesObj.currentAddress && Array.isArray(this.addresses)) {
            this.selectedAddress = this.addresses.find((address) => {
                return this.currentAddress && this.currentAddress.Id === address.Id;
            });
        }
    }

    changeSelectedAddress(addressId, addressShipToName) {
        this.selectedAddress = this.addresses.find((address) => {
            return (address.Id === addressId) && (address.ShipToName === addressShipToName);
        });
    }

    closeDialog() {
        this.onClose()();
    }

    submitChange() {
        this.onSubmit()(this.selectedAddress);
    }
}

export default {
    template: require('./change.address.modal.html'),
    bindings: {
        addresses: '<',
        currentAddress: '<',
        onClose: '&',
        onSubmit: '&'
    },
    controller: ChangeAddressController
};
