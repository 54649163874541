export const ADJUSTMENT_CATEGORIES = {
    FINANCE: 'FINANCE',
    OTHER: 'OTHER'
};

export const NOTE_TYPES = {
    CREDIT: '1006'
};

export const MIN_NOTE_AMOUNT = 0.01;
export const MAX_DESCR_LENGTH = 200;
export const INVOICE_START_DATE_DURATION = 6;
export const INVOICE = 'Invoice';

export const WITHHELD_TAX_EXTERNAL_REF = {
    Key: 'external_reference',
    Value: 'WITHHOLDING_TAX_CERTIFICATE'
};