import CustomerCareLocaleKeys from '../../../../locales/keys';

class MandatoryConsentsPopupController {
    $onInit() {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;

        this.handleClose = () => {
            if (this.onClose()) {
                this.onClose()();
            }
        };
    }
}

export default {
    template: require('./mandatory.consents.popup.html'),
    controller: MandatoryConsentsPopupController,
    controllerAs: 'MandatoryConsentsPopup',
    bindings: {
        consents: '<',
        onClose: '&'
    }
};
