import {
    compose,
    isEmpty,
    isNil,
    pathOr,
    props,
    reject
} from 'ramda';

/**
 * Determines whether the Payment Instrument has positive balance.
 * Checks the prepaid balance for "Gift Card, External Gift Card, Stored Value Account". For others it always returns *true*.
 * @param instrumentSummary
 * @returns {boolean} true if the Instrument Summary has positive balance.
 * @see hasPrepaidBalance
 *
 * Added the >=0 as for PD-461269 requested to show all cards even if they have no balance
 */
export const hasPrepaidBalance = (instrumentSummary) => {
    if (instrumentSummary.GiftCard) {
        return pathOr(0, ['GiftCard', 'Balance'], instrumentSummary) >= 0;
    } else if (instrumentSummary.ExternalGiftCard) {
        return pathOr(0, ['ExternalGiftCard', 'Balance'], instrumentSummary) >= 0;
    } else if (instrumentSummary.StoredValueAccount) {
        return pathOr(0, ['StoredValueAccount', 'Balance'], instrumentSummary) >= 0;
    } else {
        return true;
    }
};

export const isValidPaymentInstrument = (paymentInfo) => {
    let isValid = false;
    if (paymentInfo) {
        if (paymentInfo.CreditCard) {
            isValid = compose(reject(isEmpty), reject(isNil), props(['AccountNumber', 'ExpirationMonth', 'ExpirationYear', 'NameOnCard', 'Type']))(paymentInfo.CreditCard).length === 5;
        } else if (paymentInfo.ECheck) {
            isValid = compose(reject(isEmpty), reject(isNil), props(['AccountNumber', 'RoutingNumber', 'Type']))(paymentInfo.ECheck).length === 3;
        } else if (paymentInfo.GiftCard) {
            isValid = compose(reject(isEmpty), reject(isNil), props(['AccountNumber', 'Pin', 'Balance', 'Type']))(paymentInfo.GiftCard).length === 4;
        } else if (paymentInfo.ExternalGiftCard) {
            isValid = compose(reject(isEmpty), reject(isNil), props(['AccountNumber', 'AuthorizationKey', 'Balance', 'Type']))(paymentInfo.ExternalGiftCard).length === 4;
        }
    }

    return isValid;
};

export const isValidBillingInformation = (paymentInfo) => {
    let isValid = false;

    if (paymentInfo) {
        if (paymentInfo.BillingAddress) {
            isValid = compose(reject(isEmpty), reject(isNil), props(['Country', 'LineOne', 'City', 'State', 'PostalCode']))(paymentInfo.BillingAddress).length === 5;
        }
    }

    return isValid;
};
