import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    PAYMENT_METHOD_STATUSES,
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../ewallet.constants';
import {
    getFormattedExpirationForCard,
    paymentTypeNameIdentifier
} from '../ewallet.helpers';

function EwalletListItemController() {
    this.$onInit = () => {
        this.localeKeys = CustomerCareLocaleKeys;
        this.getFormattedExpirationForCard = getFormattedExpirationForCard;
        this.paymentTypeNameIdentifier = paymentTypeNameIdentifier;
    };

    this.handleClick = () => {
        this.onItemSelected()(this.paymentMethod);
    };

    this.getLineOneTextForItem = () => {
        const paymentMethod = this.paymentMethod;

        if (this.isCreditCard()) {
            return paymentMethod.Name || `${paymentMethod.CreditCard.typeName} - ${paymentMethod.CreditCard.AccountNumber}`;
        }
        if (this.isExternalGiftCard()) {
            return paymentMethod.Name || paymentMethod.ExternalGiftCard.typeName;
        }
        if (this.isGooglePlay()) {
            return paymentMethod.TypeName;
        }
        if (this.isPayPal() && paymentMethod.PayPalAccount && paymentMethod.PayPalAccount.UserName) {
            return paymentMethod.PayPalAccount.UserName;
        }
        if (this.isItunes() && paymentMethod.ITunesAccount && paymentMethod.ITunesAccount.UserName) {
            return paymentMethod.ITunesAccount.UserName;
        }

        if (paymentMethod.ExternalBill) {
            return `${paymentMethod.TypeName} ${paymentMethod.Name}`;
        }

        return paymentMethod.Name || paymentMethod.TypeName;
    };

    this.isCreditCard = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD;
    };
    this.isCurrencyEntitlement = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CURRENCY_ENTITLEMENT;
    };
    this.isInternalGiftCard = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD;
    };
    this.isExternalGiftCard = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD;
    };
    this.isPayPal = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.PAY_PAL;
    };
    this.isItunes = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ITUNES;
    };
    this.isGooglePlay = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.GOOGLE_PLAY;
    };
    this.isStoredValueAccount = () => {
        return this.paymentMethod.Type.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STORED_VALUE_ACCOUNT;
    };

    this.isRemovedOrSuspended = () => {
        return this.paymentMethod.Status === PAYMENT_METHOD_STATUSES.REMOVED || this.paymentMethod.Status === PAYMENT_METHOD_STATUSES.SUSPENDED;
    };
}

export default {
    template: require('./ewallet.list.item.html'),
    bindings: {
        paymentMethod: '<',
        isSelectedItem: '<',
        onItemSelected: '&'
    },
    controller: EwalletListItemController,
    controllerAs: 'EwalletListItemController'
};
