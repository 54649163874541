import {
    i18n
} from 'invision-core';
import {
    EXTENSION_STATUS_INDICATOR_STATUS
} from '../../../../../../../components/shared/constants/subscriptions.constants';
import LocaleKeys from '../../../../../../../locales/keys';

export class SubscriptionExtensionRemoveListPopupController {
    $onInit() {
        this.LocaleKeys = LocaleKeys;
        this.ExtensionStatus = EXTENSION_STATUS_INDICATOR_STATUS;

        const columnDefs = [
            {
                field: 'Radio',
                displayName: '',
                width: '50',
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false,
                cellTemplate: require('./cellTemplates/extension.template.html')
            },
            {
                field: 'PricingPlanName',
                displayName: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.EXTENSION),
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false,
                width: '*'
            },
            {
                field: 'Status',
                displayName: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.STATUS),
                width: '120',
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false,
                cellTemplate: require('./cellTemplates/status.template.html')
            },
            {
                field: 'StartDate',
                displayName: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.START_DATE),
                width: '120',
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false,
                cellTemplate: require('./cellTemplates/start.date.template.html')
            },
            {
                field: 'EndDate',
                displayName: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.END_DATE),
                width: '120',
                resizable: false,
                enableSorting: false,
                enableColumnMoving: false,
                cellTemplate: require('./cellTemplates/end.date.template.html')
            }
        ];

        this.dataTable = {
            data: [],
            appScopeProvider: this,
            onRegisterApi: (event) => {
                this.gridApi = event.grid.api;
            },
            columnDefs: columnDefs
        };

        this.closeExtensionToRemovePopup = () => {
            this.onClose()();
        };

        this.handleKeypressForm = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.submitExtensionToRemove();
            }
        };

        this.submitExtensionToRemove = () => {
            this.onSuccess()(this.extensionToRemove);
        };
    }

    $onChanges(changesObj) {
        if (changesObj.extensions) {
            if (this.extensions && this.extensions.length && this.dataTable) {
                this.dataTable.data = this.extensions;
                this.extensionToRemove = this.extensions[0].Id;
            }
        }
    }
}

export default {
    template: require('./subscription.extension.remove.list.popup.html'),
    controller: SubscriptionExtensionRemoveListPopupController,
    bindings: {
        extensions: '<',
        onClose: '&',
        onSuccess: '&',
    }
};