import CustomerCareLocaleKeys from '../../../locales/keys';
import {getOptOutOptions} from '../create.customer.constants';

class NotificationPreferencesSectionController {
    constructor() {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.notificationPreferencesModal = {};
        this.optOutOptions = getOptOutOptions();
    }

    $onInit() {
        this.initializeNotificationPreferencesModal();
    }

    handleFormChanged() {
        this.updateCustomerModel(this.customerModel, this.notificationPreferencesModal);
        this.onFormChanged()(this.customerModel);
    }

    updateCustomerModel(customerModel, notificationPreferences) {
        customerModel.NotificationCategoryPreferences = this.getNotificationPreferences(notificationPreferences);
    }

    getNotificationPreferences(notificationPreferences) {
        return Object.keys(notificationPreferences).map((notificationCategoryId) => {
            return {
                CustomerNotificationCategoryId: +notificationCategoryId,
                OptOutOptions: notificationPreferences[notificationCategoryId] || []
            };
        });
    }

    initializeNotificationPreferencesModal() {
        if (this.notificationCategoryPreferences.length) {
            this.notificationPreferencesModal = {
                ...this.notificationCategoryPreferences.reduce((acc, category) => {
                    const optOutOptions = category.optOutOptions
                        .filter((option) => {
                            return option.value;
                        })
                        .map((option) => {
                            return option.value;
                        });

                    acc[category.customerNotificationCategoryId] = optOutOptions;
                    return acc;
                }, {})
            };
        }
    }
}

export default {
    template: require('./notification.preferences.section.html'),
    bindings: {
        customerModel: '<',
        notificationCategoryPreferences: '<',
        onFormChanged: '&'
    },
    controller: NotificationPreferencesSectionController
};
