import LocaleKeys from '../../../../../locales/keys';
import {ORDER_ITEM_DISCOUNT_ACTIONS} from '../../../../../customercare.constants';


class DiscountDetailsDialogComponent {
    constructor() {
        Object.assign(this, {
            LocaleKeys,
            ORDER_ITEM_DISCOUNT_ACTIONS
        });
    }
}

export default {
    controller: DiscountDetailsDialogComponent,
    template: require('./discount.details.dialog.template.html'),
    bindings: {
        discounts: '<',
        onClose: '&',
    }
};
