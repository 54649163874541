import {stateGo} from 'redux-ui-router';
import __ from 'ramda/src/__';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    DateRangeId,
    getRelativeShortListShortcuts,
    getShortListShortcuts
} from 'invision-core/src/components/helpers/daterange.shortcut.helper';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import {
    fetchCodeTypesThunk,
    fetchNetworkEventSubTypes,
    fetchNetworkEventTypes,
    fetchUnitOfMeasureTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    CurrentCustomerIdSelector,
    RouteParams
} from '../../../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerAccountUsageDetailsByServiceIdSelector,
    ConvergentBillerCurrencyCodeSelector,
    ConvergentBillerSubscriberSummaryServicesAvailableSelector,
    CurrentAccountCurrencyCodeSelector,
    IsFetchingAccountUsageDetailsSelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveConvergentBillerAccountUsageDetails} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    clearUsageDetailsRecoverableUIState,
    retrieveUsageDetailsGroupedEntitlements,
    retrieveUsageDetailsServiceEntitlements,
    retrieveUsageDetailsSharedEntitlements,
    searchUsageDetailsEntitlementName,
    setSelectedCallCategories,
    updateNetworkEventTypeFilter,
    updateNetworkSubEventTypeFilter,
    updateUsageDetailsCurrencyCode,
    updateUsageDetailsDateFilter,
    updateUsageDetailsPaginationData,
    updateUsageDetailsSearchEntitlementsDatePicker,
    updateUsageDetailsSearchEntitlementsIncludeExpired,
    updateUsageDetailsSelectedEntitlementDetails,
    updateUsageDetailsSort,
    updateUsageDetailsTotalAmount
} from '../../../../reducers/actions/usage.details.actions';
import {
    ENTITLEMENT_ACTIVATION_STATUS,
    getColumnDefinitions,
    USAGE_SORT_DIRECTION,
    USAGE_SORT_FIELD,
    USAGES_LIMIT_WARNING_CODE
} from './usage.details.table.constants';
import {
    AccountUsageDetailsEntitlementsSelector,
    AccountUsageDetailsTableDataSelector,
    FormattedServiceAttributeValueSelector,
    IsLoadingEntitlementsSelector,
    IsSharedEntintitlementUsageSelector,
    SelectedEntitlementDetailsSelector,
    SelectedEntitlementSelector,
    SelectedNetworkEventSubTypeFiltersSelector,
    SelectedNetworkEventTypeFiltersFormatSelector,
    SelectedNetworkEventTypeFiltersSelector,
    UsageDetailsPageTitleSelector,
    UsageDetailsPaginationDataSelector,
    UsageDetailsSearchEntitlementsDatePickerSelector,
    UsageDetailsSearchEntitlementsIncludeExpiredSelector,
    UsageDetailsSearchedEntitlementNameSelector,
    UsageDetailsSelectedCallCategoriesSelector,
    UsageDetailsSelectedEndDateSelector,
    UsageDetailsSelectedStartDateSelector,
    UsageDetailsSortParamsSelector,
    UsageDetailsTotalAmountSelector
} from '../../../../reducers/selectors/usage.details.selectors';
import {SelectedServiceDetailsSelector} from '../../../../reducers/selectors/selected.service.details.selectors';
import {
    LOCATION_OVERRIDES_DISPLAY_MAX,
    USAGE_CAPS_URL
} from '../usageCaps/usage.caps.constants';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from './usage.details.config';
import {getSuspensionTypeByCode} from '../../../../reducers/selectors/services.list.selectors.helper';

class UsageDetailsController {
    constructor($ngRedux, $scope, $timeout, uiGridConstants, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $scope,
            $timeout,
            callCategories: [],
            entitlementName: undefined,
            isUsageCapsButtonVisible: false,
            NON_BULK_SERVICE_STATUSES,
            onCallCategoryChanged: this.onCallCategoryChanged.bind(this),
            onGridRegisterApi: this.onGridRegisterApi.bind(this),
            onPageSelected: this.onPageSelected.bind(this),
            onPageSizeOptionSelected: this.onPageSizeOptionSelected.bind(this),
            onSearchCleared: this.onSearchCleared.bind(this),
            searchEntitlementName: this.searchEntitlementName.bind(this),
            searchEntitlementsDatePicker: {
                shortcut: null,
                startDate: null,
                endDate: null
            },
            showAll: false,
            uiGridConstants,
            uiNotificationService
        });
    }

    $onInit() {
        const codeTables = [
            CODES.CallCategory,
            CODES.RegularExpression,
            CODES.ServiceAttribute,
            CODES.UsageUnitConversion
        ];

        const mapStateToTarget = (store) => {
            return {
                accountUsageTableData: AccountUsageDetailsTableDataSelector(store),
                callCategories: UsageDetailsSelectedCallCategoriesSelector(store),
                callCategoryCodeTableValues: MetadataCodeTypeDictionarySelector(CODES.CallCategory, store),
                currentAccountCurrencyCode: CurrentAccountCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                endDate: UsageDetailsSelectedEndDateSelector(store),
                entitlementCurrencyCode: ConvergentBillerCurrencyCodeSelector(store),
                entitlementName: UsageDetailsSearchedEntitlementNameSelector(store),
                entitlements: AccountUsageDetailsEntitlementsSelector(store),
                formattedServiceAttributeValue: FormattedServiceAttributeValueSelector(store),
                includeExpiredEntitlements: UsageDetailsSearchEntitlementsIncludeExpiredSelector(store),
                isCodeTableLoaded: MetadataCodeLoadedSelector(__, store),
                isLoadingEntitlements: IsLoadingEntitlementsSelector(store),
                isLoadingUsageDetails: IsFetchingAccountUsageDetailsSelector(store),
                isSharedEntitlementUsage: IsSharedEntintitlementUsageSelector(store),
                networkEventSubTypesLoaded: MetadataCodeLoadedSelector(CODES.NetworkEventSubType, store),
                networkEventTypesLoaded: MetadataCodeLoadedSelector(CODES.NetworkEventType, store),
                paginationData: UsageDetailsPaginationDataSelector(store),
                routeParams: RouteParams(store),
                searchEntitlementsDatePicker: UsageDetailsSearchEntitlementsDatePickerSelector(store),
                selectedEntitlement: SelectedEntitlementSelector(store),
                selectedEntitlementDetails: SelectedEntitlementDetailsSelector(store),
                selectedNetworkEventSubTypeFilters: SelectedNetworkEventSubTypeFiltersSelector(store),
                selectedNetworkEventTypeFiltersFormat: SelectedNetworkEventTypeFiltersFormatSelector(store),
                selectedNetworkEventTypes: SelectedNetworkEventTypeFiltersSelector(store),
                serviceDetails: SelectedServiceDetailsSelector(store),
                servicesAvailable: ConvergentBillerSubscriberSummaryServicesAvailableSelector(store),
                sortParams: UsageDetailsSortParamsSelector(store),
                startDate: UsageDetailsSelectedStartDateSelector(store),
                totalAmount: UsageDetailsTotalAmountSelector(store),
                unitsOfMeasureLoaded: MetadataCodeLoadedSelector(CODES.UnitsOfMeasure, store),
                usageDetails: ConvergentBillerAccountUsageDetailsByServiceIdSelector(store),
                usageDetailsPageTitle: UsageDetailsPageTitleSelector(store)
            };
        };
        const controllerActions = {
            clearUsageDetailsRecoverableUIState,
            fetchCodeTypesThunk,
            fetchNetworkEventSubTypes,
            fetchNetworkEventTypes,
            fetchUnitOfMeasureTypes,
            retrieveConvergentBillerAccountUsageDetails,
            retrieveUsageDetailsGroupedEntitlements,
            retrieveUsageDetailsServiceEntitlements,
            retrieveUsageDetailsSharedEntitlements,
            searchUsageDetailsEntitlementName,
            setSelectedCallCategories,
            stateGo,
            updateNetworkEventTypeFilter,
            updateNetworkSubEventTypeFilter,
            updateUsageDetailsCurrencyCode,
            updateUsageDetailsDateFilter,
            updateUsageDetailsPaginationData,
            updateUsageDetailsSearchEntitlementsDatePicker,
            updateUsageDetailsSearchEntitlementsIncludeExpired,
            updateUsageDetailsSelectedEntitlementDetails,
            updateUsageDetailsSort,
            updateUsageDetailsTotalAmount
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((code) => {
            if (!this.state.isCodeTableLoaded(code)) {
                this.actions.fetchCodeTypesThunk(code).catch(this.onApiError);
            }
        });

        this.stateOrName = SERVICE_USAGE_DETAILS_STATE_NAME;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.$filter = getFilterService();

        this.entitlementColumn = {
            field: 'entitlementName',
            displayName: i18n.translate(CustomerCareLocaleKeys.ENTITLEMENT),
            visible: this.isEntitlementColumnVisible()
        };

        this.tableColDefs = [this.entitlementColumn, ...getColumnDefinitions(this.uiGridConstants, this.$filter)];
        this.dateShortcuts = [getShortListShortcuts(), getRelativeShortListShortcuts()];
        this.showFilters = false;
        this.tooltipListTemplate = require('./fieldTemplates/tooltip.list.template.html');
        this.tableConfig = {
            columnDefs: this.tableColDefs,
            data: this.state.accountUsageTableData,
            onRegisterApi: this.onGridRegisterApi,
            useExternalSorting: true
        };

        if (!this.state.networkEventSubTypesLoaded) {
            this.actions.fetchNetworkEventSubTypes();
        }
        if (!this.state.networkEventTypesLoaded) {
            this.actions.fetchNetworkEventTypes();
        }
        if (!this.state.unitsOfMeasureLoaded) {
            this.actions.fetchUnitOfMeasureTypes();
        }

        this.updateSelectedService = (service) => {
            this.actions.stateGo(SERVICE_USAGE_DETAILS_STATE_NAME, {
                serviceIdentifierValue: service.ServiceIdentifier.Value,
                serviceIdentifierName: service.ServiceIdentifier.ServiceIdentifierName
            });
        };

        this.onDateRangeChanged = (startDate, endDate) => {
            this.selectedShortcut = this.getDateRangeShortCutFromStartAndEndDates(
                startDate,
                endDate
            );
            this.actions.updateUsageDetailsDateFilter({
                startDate: startDate,
                endDate: endDate
            });
            this._resetPagination();
            this._retrieveConvergentBillerAccountUsageDetails();
        };

        this.initializeSearchEntitlementsDatePicker = () => {
            if (this.state.searchEntitlementsDatePicker.startDate && this.state.searchEntitlementsDatePicker.endDate) {
                this.searchEntitlementsDatePicker.shortcut = this.getDateRangeShortCutFromStartAndEndDates(
                    this.state.searchEntitlementsDatePicker.startDate,
                    this.state.searchEntitlementsDatePicker.endDate
                );
            } else {
                this.searchEntitlementsDatePicker.shortcut = getShortListShortcuts().find((sc) => {
                    return sc.id === DateRangeId.last30Days;
                });
            }
            if (this.searchEntitlementsDatePicker.shortcut) {
                this.searchEntitlementsDatePicker.startDate = this.searchEntitlementsDatePicker.shortcut.start;
                this.searchEntitlementsDatePicker.endDate = this.searchEntitlementsDatePicker.shortcut.end;
            } else {
                this.searchEntitlementsDatePicker.startDate = this.state.searchEntitlementsDatePicker.startDate;
                this.searchEntitlementsDatePicker.endDate = this.state.searchEntitlementsDatePicker.endDate;
            }
            this.actions.updateUsageDetailsSearchEntitlementsDatePicker(this.searchEntitlementsDatePicker);
            if (this.state.searchEntitlementsDatePicker.startDate && this.state.searchEntitlementsDatePicker.endDate) {
                this.syncUsageAndEntitlementFilters();
            }
        };

        this.onSearchEntitlementsDateRangeChange = (startDate, endDate) => {
            this.searchEntitlementsDatePicker.startDate = startDate;
            this.searchEntitlementsDatePicker.endDate = endDate;
            this.actions.updateUsageDetailsSearchEntitlementsDatePicker(this.searchEntitlementsDatePicker);
            if (this.state.includeExpiredEntitlements && this.searchEntitlementsDatePicker.startDate && this.searchEntitlementsDatePicker.endDate) {
                this.retrieveEntitlements(true);
            }
        };

        this.onIncludeRemovedChange = () => {
            this.actions.updateUsageDetailsSearchEntitlementsIncludeExpired(!this.state.includeExpiredEntitlements);
            if (!this.state.includeExpiredEntitlements) {
                this._setDateRangeToDefault();
                this.setSearchEntitlementsDatePickerToDefaults();
            }
            if (this.searchEntitlementsDatePicker.startDate && this.searchEntitlementsDatePicker.endDate) {
                this.retrieveEntitlements(true);
            }
        };

        this._initDateRangeAndRetreiveUsageDetails = () => {
            this._setDateRangeToDefault();
            this._resetPagination();
            this._retrieveConvergentBillerAccountUsageDetails();
        };

        this.showFilters = true;

        this.retrieveEntitlements().then(() => {
            if (this.state.searchEntitlementsDatePicker.startDate && this.state.searchEntitlementsDatePicker.endDate) {
                this.syncUsageAndEntitlementFilters();
                this._resetPagination();
                this._retrieveConvergentBillerAccountUsageDetails();
            } else {
                if (!this.state.startDate || !this.state.endDate) {
                    this._initDateRangeAndRetreiveUsageDetails();
                } else {
                    this.selectedShortcut = this.getDateRangeShortCutFromStartAndEndDates(this.state.startDate, this.state.endDate);
                    if (this.selectedShortcut) {
                        this.onDateRangeChanged(this.selectedShortcut.start, this.selectedShortcut.end);
                    } else {
                        this.onDateRangeChanged(this.state.startDate, this.state.endDate);
                    }
                }
            }
            this.initializeSearchEntitlementsDatePicker();
        });

        this.syncUsageAndEntitlementFilters = () => {
            this.selectedShortcut = this.getDateRangeShortCutFromStartAndEndDates(
                this.state.searchEntitlementsDatePicker.startDate,
                this.state.searchEntitlementsDatePicker.endDate
            );
            this.actions.updateUsageDetailsDateFilter({
                startDate: this.state.searchEntitlementsDatePicker.startDate,
                endDate: this.state.searchEntitlementsDatePicker.endDate
            });
        };

        this.getDateRangeShortCutFromStartAndEndDates = (startDate, endDate) => {
            let matchingShortcut = null;
            if (this.dateShortcuts[0] && this.dateShortcuts[1]) {
                matchingShortcut = [...this.dateShortcuts[0], ...this.dateShortcuts[1]].find((shortcut) => {
                    let dateCheckStart = new Date(shortcut.start);
                    let dateCheckEnd = new Date(shortcut.end);
                    let ourDateStart = new Date(startDate);
                    let ourDateEnd = new Date(endDate);
                    dateCheckStart.setMinutes(0, 0);
                    dateCheckEnd.setMinutes(0, 0);
                    ourDateStart.setMinutes(0, 0);
                    ourDateEnd.setMinutes(0, 0);
                    dateCheckStart = dateCheckStart.toUTCString();
                    dateCheckEnd = dateCheckEnd.toUTCString();
                    ourDateStart = ourDateStart.toUTCString();
                    ourDateEnd = ourDateEnd.toUTCString();
                    return dateCheckStart === ourDateStart && dateCheckEnd === ourDateEnd;
                });
            }
            return matchingShortcut;
        };

        this.additionalUsageDetailsPopupConfig = {
            onRegisterApi: (evt) => {
                this.additionalUsageDetailsPopupConfig = evt.api;
            }
        };
    }

    onCallCategoryChanged(callCategories) {
        this.actions.setSelectedCallCategories((callCategories || []).map((callCategory) => {
            return callCategory.id;
        }));
        this._resetPagination();
        this._retrieveConvergentBillerAccountUsageDetails();
    };

    searchEntitlementName(entitlementName) {
        this.actions.searchUsageDetailsEntitlementName(entitlementName);
        this._resetPagination();
        this._retrieveConvergentBillerAccountUsageDetails();
    }

    onSearchCleared() {
        this.actions.searchUsageDetailsEntitlementName(null);
        this._resetPagination();
        this._retrieveConvergentBillerAccountUsageDetails();
    }

    isDataLoading() {
        return this.state.isLoadingUsageDetails || !this.state.unitsOfMeasureLoaded || this.state.isLoadingEntitlements;
    }

    retrieveEntitlements(isFilterChange = false) {
        if (this.state.isSharedEntitlementUsage && this.state.routeParams.groupCode) {
            return this.actions.retrieveUsageDetailsGroupedEntitlements(this.state.routeParams.groupCode, {
                ServiceAttributeId: this.state.routeParams.serviceAttributeId,
                Value: this.state.routeParams.serviceIdentifierValue
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        } else if (this.state.isSharedEntitlementUsage) {
            return this.actions.retrieveUsageDetailsSharedEntitlements(this.state.currentCustomerId, this.state.routeParams.serviceIdentifierValue).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        } else {
            const searchByDateFilterAndIncludeAll = this.state.includeExpiredEntitlements &&
                this.state.searchEntitlementsDatePicker.startDate && this.state.searchEntitlementsDatePicker.endDate;
            return this.actions.retrieveUsageDetailsServiceEntitlements(
                this.state.currentCustomerId,
                this.state.routeParams.serviceIdentifierValue,
                this.state.includeExpiredEntitlements || undefined,
                searchByDateFilterAndIncludeAll ? this.state.searchEntitlementsDatePicker.startDate : undefined,
                searchByDateFilterAndIncludeAll ? this.state.searchEntitlementsDatePicker.endDate : undefined)
                .then((data) => {
                    if (isFilterChange) {
                        this.resetEntitlementSelectionOnFilter(data?.ServiceEntitlementBalances);
                        this.actions.setSelectedCallCategories([]);
                        this.syncUsageAndEntitlementFilters();
                        this._resetPagination();
                        this._retrieveConvergentBillerAccountUsageDetails();
                        this.callCategories = [];
                    }
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    resetEntitlementSelectionOnFilter(serviceEntitlementBalances) {
        if (serviceEntitlementBalances.length) {
            const entitlementToSelect = serviceEntitlementBalances[0];
            this.selectEntitlement(
                0,
                entitlementToSelect.EntitlementIdentifier.EntitlementId
                || entitlementToSelect.EntitlementIdentifier.GroupCode,
                entitlementToSelect.EntitlementIdentifier.BalanceIterationId
            );
        }
    }

    _retrieveConvergentBillerAccountUsageDetails() {
        return this.actions.retrieveConvergentBillerAccountUsageDetails(this._buildRetreiveAccountUsageDetailsRequest())
            .then((response) => {
                this.actions.updateUsageDetailsTotalAmount(response.TotalAmount);
                this.actions.updateUsageDetailsCurrencyCode(response.CurrencyCode);
                this.determineUsageCapsButtonVisibility();
                this.tableConfig.data = this.state.accountUsageTableData;
                this.usagesLimitExceededWarning = (response.Warnings || []).find(Obj => {
                    return Obj.Code === USAGES_LIMIT_WARNING_CODE;
                }) ? i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.USAGES_LIMIT_EXCEEDED_WARNING) : null;
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    _setDateRangeToDefault() {
        this.selectedShortcut = getShortListShortcuts().find((sc) => {
            return sc.id === DateRangeId.last30Days;
        });
        this.actions.updateUsageDetailsDateFilter({
            startDate: this.selectedShortcut.start,
            endDate: this.selectedShortcut.end
        });
    }

    setSearchEntitlementsDatePickerToDefaults() {
        this.searchEntitlementsDatePicker.shortcut = getShortListShortcuts().find((sc) => {
            return sc.id === DateRangeId.last30Days;
        });
        this.searchEntitlementsDatePicker.startDate = this.searchEntitlementsDatePicker.shortcut.start;
        this.searchEntitlementsDatePicker.endDate = this.searchEntitlementsDatePicker.shortcut.end;
        this.actions.updateUsageDetailsSearchEntitlementsDatePicker(this.searchEntitlementsDatePicker);
    }

    getCallCategoryNamesFromIds() {
        if ((this.state.callCategories || []).length && Object.values(this.state.callCategoryCodeTableValues || {}).length) {
            return this.state.callCategories.map((callCategoryId) => {
                return this.state.callCategoryCodeTableValues[callCategoryId].Name;
            });
        }
        return [];
    }

    _buildRetreiveAccountUsageDetailsRequest() {
        return {
            customerId: this.state.currentCustomerId,
            request: {
                CallCategories: this.getCallCategoryNamesFromIds(),
                EndDate: this.state.endDate,
                EntitlementName: this.state.entitlementName,
                NetworkEventSubTypeCodes: this.state.selectedNetworkEventSubTypeFilters.map((eventType) => {
                    return eventType.value;
                }),
                NetworkEventTypeCodes: this.state.selectedNetworkEventTypeFiltersFormat,
                PageNumber: this.state.paginationData.pageNumber,
                PageSize: this.state.paginationData.pageSize,
                ServiceIdentifier: {
                    ServiceIdentifierName: this.state.routeParams.serviceIdentifierName,
                    Value: this.state.routeParams.serviceIdentifierValue
                },
                EntitlementIdentifier: this.state.isSharedEntitlementUsage ? {
                    EntitlementId: this.state.routeParams.entitlementId,
                    BalanceIterationId: this.state.routeParams.balanceIterationId,
                } : this.state.selectedEntitlement.EntitlementIdentifier,
                SortByField: this.state.sortParams.sortBy,
                SortDirection: this.state.sortParams.sortDirection,
                StartDate: this.state.startDate
            }
        };
    }
    navigateToUsageCapsPage() {
        this.actions.stateGo(USAGE_CAPS_URL, {
            serviceId: this.state.routeParams.serviceIdentifierValue
        });
    }
    determineUsageCapsButtonVisibility() {
        this.isUsageCapsButtonVisible = !this.state.isSharedEntitlementUsage
                                            && !this.state.usageDetails.IsBulkWithServiceType;
    }
    onGridRegisterApi(event) {
        this.gridApi = event.grid.api;

        this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
            this.actions.updateUsageDetailsSort({
                sortBy: USAGE_SORT_FIELD[sortColumns[0].field],
                sortDirection: sortColumns[0].sort.direction === this.uiGridConstants.ASC
                    ? USAGE_SORT_DIRECTION.ASCENDING
                    : USAGE_SORT_DIRECTION.DESCENDING
            });
            this._resetPagination();
            this._retrieveConvergentBillerAccountUsageDetails();
        });
    }

    _resetPagination() {
        this.actions.updateUsageDetailsPaginationData({
            pageNumber: 1,
            pageSize: this.state.paginationData.pageSize
        });
    }

    onPageSelected(page) {
        this.actions.updateUsageDetailsPaginationData({
            pageNumber: page,
            pageSize: this.state.paginationData.pageSize
        });
        this._retrieveConvergentBillerAccountUsageDetails();
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.updateUsageDetailsPaginationData({
            pageNumber: this.state.paginationData.pageNumber,
            pageSize: pageSize
        });
        this._retrieveConvergentBillerAccountUsageDetails();
    }

    selectEntitlement(entitlementIndex, entitlementId, balanceIterationId) {
        if (entitlementIndex === this.state.selectedEntitlementDetails.entitlementIndex) {
            return;
        }
        if (!entitlementId) {
            this.showAll = true;
        } else {
            this.showAll = false;
        }
        this.actions.stateGo(SERVICE_USAGE_DETAILS_STATE_NAME, Object.assign({}, this.state.routeParams, {
            entitlementId: entitlementId,
            entitlementIndex: entitlementIndex,
            balanceIterationId: balanceIterationId
        }), {
            location: 'replace',
            notify: false
        });

        this.actions.updateUsageDetailsSelectedEntitlementDetails({
            entitlementId: entitlementId,
            entitlementIndex,
            balanceIterationId
        });
        this._resetPagination();

        this.entitlementColumn.visible = this.isEntitlementColumnVisible();
        this.gridApi.core.refresh();
        this._retrieveConvergentBillerAccountUsageDetails();
    }

    isEntitlementColumnVisible() {
        return !!(this.state.selectedEntitlement &&
            this.state.selectedEntitlement.EntitlementIdentifier &&
            this.state.selectedEntitlement.EntitlementIdentifier.GroupCode);
    }

    isEntitlementActive(entitlement) {
        return entitlement?.EntitlementIdentifier?.EntitlementId ?
            ((+entitlement.EntitlementIdentifier.EntitlementId === +this.state.selectedEntitlementDetails?.entitlementId
                && entitlement?.EntitlementIdentifier?.BalanceIterationId === this.state.selectedEntitlementDetails?.balanceIterationId) ||
                entitlement.index === this.state.selectedEntitlementDetails?.entitlementIndex) :
            entitlement?.EntitlementIdentifier?.GroupCode === +this.state.selectedEntitlementDetails?.entitlementId ||
                (entitlement.selectAll && this.state.routeParams.entitlementIndex && !this.state.routeParams.entitlementId);
    }

    openAdditionalUsageDetailsPopup(popupData) {
        this.additionalUsageDetailsPopupData = popupData;
        this.$timeout(() => {
            this.additionalUsageDetailsPopupConfig.open();
        });
    }

    closeAdditionalUsageDetailsPopup() {
        this.additionalUsageDetailsPopupConfig.close();
    }

    isOverrideContainerCollapsed() {
        const allLocationOverrides =(this.state.selectedEntitlement.PricingVectorOverrides || []).reduce((acc, override) => {
            acc.push(...(override.OverrideValues || []));
            return acc;
        }, []);
        return allLocationOverrides.length > LOCATION_OVERRIDES_DISPLAY_MAX;
    }

    onApiError(error) {
        this.uiNotificationService.transientError(error.translatedMessage);
    }

    displayExpirationDate(entitlement) {
        return ![
            ENTITLEMENT_ACTIVATION_STATUS.AWAITING_ENTITLEMENT_FIRST_USE,
            ENTITLEMENT_ACTIVATION_STATUS.AWAITING_SERVICE_FIRST_USE,
            ENTITLEMENT_ACTIVATION_STATUS.AWAITING_PAYMENT_CONFIRMATION,
            ENTITLEMENT_ACTIVATION_STATUS.AWAITING_ENTITLEMENT_FIRST_USE_WITH_PRICING_PLAN
        ].includes(entitlement.ActivationStatus);
    }

    get suspensionDate() {
        return getFilterService()('localShortDate')(this.state.serviceDetails.SuspendedDate);
    }

    get suspensionTypeByCode() {
        return getSuspensionTypeByCode(this.state.serviceDetails.SuspendTypeCode);
    }

    $onDestroy() {
        this.actions.clearUsageDetailsRecoverableUIState();
        this.disconnect();
    }
}

export default {
    template: require('./usage.details.html'),
    bindings: {
        fromStateObject: '<?'
    },
    controller: UsageDetailsController
};
