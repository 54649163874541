import CustomerCareKeys from '../../../../../locales/keys';

class CustomerPinModalComponent {
    constructor($filter, $ngRedux) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            CustomerCareKeys
        });
    }

    onSubmitForm() {
        if (this.SubscriberPinForm.$valid) {
            this.onSubmit()({
                'SubscriberPIN': this.pin
            });
        }
    }
}

export default {
    bindings: {
        canUserUpdatePin: '<',
        customerHasPin: '<?',
        dialogSubtitle: '<',
        dialogTitle: '<',
        dialogLabel: '<',
        onClose: '&',
        onSubmit: '&',
        pinRegex: '<'
    },
    template: require('./customer.pin.modal.html'),
    controller: CustomerPinModalComponent,
    controllerAs: 'CustomerPin'
};
