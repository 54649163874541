import moment from 'moment';
import localeKeys from '../../../../../locales/keys';

class BulkDetailsController {
    $onInit() {
        this.localeKeys = localeKeys;
        this.handleClose = this.onClose();
    }
    getDateDifference(end) {
        return moment(end).diff(new Date(), 'days');
    }
}

export default {
    template: require('./bulk.details.modal.html'),
    controller: BulkDetailsController,
    bindings: {
        entity: '<',
        onClose: '&'
    }
};
