import {stateGo} from 'redux-ui-router';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import LocaleKeys from '../../../../locales/keys';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    fetchCodeTypes,
    fetchCodeTypesThunk
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    BusinessUnitSelectors,
    DateRangeShortcutHelper,
    i18n,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    IsCurrentCustomerBlocklisted
} from '../../../../reducers/selectors/customer.selectors';
import {
    EndRecordSelector,
    GroupOrderFeatureVisibilitySelector,
    IsFetchingOrdersSelector,
    RecordCountSelector,
    StartRecordSelector,
    TableDataSelector,
    TotalPagesSelector
} from '../../../../reducers/selectors/order.history.selectors';
import {
    autoOrdersFilterSelector,
    endDateSelector,
    migratedOrdersFilterSelector,
    orderKindsFiltersSelector,
    pageNumberSelector,
    SelectedPageSizePreferenceSelector,
    selectedStatusFilterSelector,
    selectedTypeFilterSelector,
    startDateSelector
} from '../../../../reducers/selectors/order.history.filter.selectors';
import * as OrderHistoryActions from '../../../../reducers/actions/order.history.actions';
import * as OrderHistoryFilterActions from '../../../../reducers/actions/order.history.filter.actions';
import {STATE_OR_NAME as ADD_OFFER_STATE} from '../../../../reducers/constants/add.offer.wizard.constants';
import {CREATE_PRODUCTS_ORDER_STATE_NAME as NEW_PRODUCT_ORDER_STATE} from '../../../../reducers/constants/products.wizard.constants';
import {ORDERING_ACCESS} from '../../../../security.attributes';

class OrderHistoryListController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        const columnDefs = [
            {
                field: 'OrderNumber',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.ORDER),
                width: 140,
                cellTemplate: require('../cellTemplates/order.number.template.html')
            },
            {
                field: 'OrderName',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.ORDER_DESCRIPTION),
                minWidth: 150
            },
            {
                field: 'OrderStatus',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.ORDER_STATUS_NAME),
                width: 120,
                cellTemplate: require('../cellTemplates/order.status.template.html')
            },
            {
                field: 'OrderTypeName',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.ORDER_TYPE),
                width: 140
            },
            {
                field: 'OrderReasonValue',
                displayName: i18n.translate(LocaleKeys.ORDER_DETAILS.ORDER_REASON),
                width: 140,
                cellTemplate: require('../cellTemplates/order.reason.template.html')
            },
            {
                field: 'Ordered',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.ORDER_DATE),
                width: 150,
                cellTemplate: require('../cellTemplates/order.date.template.html')
            },
            {
                field: 'TotalAmount',
                displayName: i18n.translate(LocaleKeys.ORDER_HISTORY.COLUMNS.TOTAL_AMOUNT),
                headerCellClass: 'u-pr++',
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE,
                width: 140,
                cellTemplate: require('../cellTemplates/total.amount.template.html')
            }
        ];

        const mapStateToTarget = (store) => {
            return {
                autoOrdersFilter: autoOrdersFilterSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                endDate: endDateSelector(store),
                endRecord: EndRecordSelector(store),
                isCurrentCustomerBlocklisted: IsCurrentCustomerBlocklisted(store),
                isDbss: BusinessUnitSelectors.IsDbss(store),
                isFetchingOrders: IsFetchingOrdersSelector(store),
                isServiceReasonCodeLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                maximumSlots: 5,
                migratedOrdersFilter: migratedOrdersFilterSelector(store),
                numberOfPages: TotalPagesSelector(store),
                orderKindsFilterOptions: orderKindsFiltersSelector(store),
                pageNumber: pageNumberSelector(store),
                recordCount: RecordCountSelector(store),
                selectedPageSizePreference: SelectedPageSizePreferenceSelector(store),
                selectedOrderStatuses: selectedStatusFilterSelector(store),
                selectedOrderTypes: selectedTypeFilterSelector(store),
                startDate: startDateSelector(store),
                startRecord: StartRecordSelector(store),
                tableData: {
                    enableSorting: false,
                    columnDefs : columnDefs,
                    data : TableDataSelector(store)
                },
                userPageSizePreference: SessionSelectors.PageSizePreferenceSelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
                isFeatureToggleCodesLoaded: MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
                isGroupOrderEnabled: GroupOrderFeatureVisibilitySelector(store),
            };
        };
        const controllerActions = {
            fetchDisconnectReasons: () => {
                return fetchCodeTypes(CODES.ReasonCodes);
            },
            fetchCodeTypesThunk,
            searchOrders: OrderHistoryActions.searchOrders,
            setPageSizePreference: OrderHistoryFilterActions.updatePageSizePreference,
            stateGo,
            updateDateRange: OrderHistoryFilterActions.updateDateRange,
            updateKindFilter: OrderHistoryFilterActions.updateOrderKindFilter,
            updatePageNumber: OrderHistoryFilterActions.updatePageNumber,
            updatePageSizePreference: OrderHistoryFilterActions.updatePageSizePreference,
            updateStatusFilter: OrderHistoryFilterActions.updateOrderStatusFilter,
            updateTypeFilter: OrderHistoryFilterActions.updateOrderTypeFilter
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isServiceReasonCodeLoaded) {
            this.actions.fetchDisconnectReasons();
        }
        if (!this.state.isFeatureToggleCodesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.FeatureToggleConfig);
        }
        this.updatePage = (page) => {
            this.actions.updatePageNumber(page);
            this.searchOrders();
        };

        this.updateTypeFilter = (selected) => {
            this.actions.updatePageNumber(1);
            this.actions.updateTypeFilter(selected);

            this.searchOrders();
        };

        this.updateIncludeKindFilter = (selected) => {
            this.actions.updatePageNumber(1);
            this.actions.updateKindFilter(selected);
            this.searchOrders();
        };

        this.onDateRangeChanged = (start, end) => {
            this.actions.updatePageNumber(1);
            this.actions.updateDateRange({
                startDate : start,
                endDate : end
            });
            this.searchOrders();
        };

        this.showDisabledNewOrderButton = () => {
            return (this.state.isDbss
                && !this.state.currentCustomer.ConvergentBillerId)
                || this.state.isCurrentCustomerBlocklisted;
        };

        this.handleClickCreateOrder = () => {
            if (!this.showDisabledNewOrderButton()) {
                this.actions.stateGo(this.state.isDbss ? ADD_OFFER_STATE : NEW_PRODUCT_ORDER_STATE);
            }
        };

        this.hasOrderingAccess = () => {
            return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
        };

        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
        this.dateShortcuts = [[entireHistoryDateShortcut].concat(DateRangeShortcutHelper.getShortListShortcuts()), DateRangeShortcutHelper.getRelativeShortListShortcuts()];
        this.selectedDateShortcut = entireHistoryDateShortcut;
        this.CustomerLocaleKeys = LocaleKeys;
        this.stateOrName = 'index.customercare.customer.orderHistory';
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this.searchOrders();
    }

    get disabledNewOrderButtonTooltip() {
        if (this.state.isCurrentCustomerBlocklisted) {
            return this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT_STATUS_BLOCKLISTED_LOCKED_ORDERING;
        } else {
            return this.CustomerLocaleKeys.CUSTOMER_DASHBOARD.CONVERGENT_BILLER_ERROR;
        }
    }

    searchOrders() {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setPageSizePreference(this.state.userPageSizePreference);
        }

        const filters = {
            pageSize : this.state.selectedPageSizePreference,
            pageNumber : this.state.pageNumber,
            orderStatus : this.state.selectedOrderStatuses,
            orderType : this.state.selectedOrderTypes,
            returnAutoOrders: this.state.autoOrdersFilter && this.state.autoOrdersFilter.selected,
            returnMigratedOrders: this.state.migratedOrdersFilter && this.state.migratedOrdersFilter.selected,
            startDate : this.state.startDate,
            endDate : this.state.endDate
        };
        this.actions.searchOrders(this.state.currentCustomerId, filters);
    }

    updateStatusFilter(selected) {
        this.actions.updatePageNumber(1);
        this.actions.updateStatusFilter(selected);

        this.searchOrders();
    }

    onPageSizeOptionsSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);

        this.updatePage(1);
    }
}

export default {
    bindings: {},
    controller: OrderHistoryListController,
    controllerAs: 'OrderHistory',
    template: require('./order.history.list.html')
};
