import angular from 'angular';
import 'invision-core';

import OverviewComponent from './overview.component';

export default angular.module('invision.customercare.customer.household.overview', [
    'invision.ui.components.openNewWindow'
])
    .component('overview', OverviewComponent)
    .name;
