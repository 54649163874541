import CustomerCareKeys from '../../../../../../locales/keys';
import {FREQUENCY_TYPES} from 'invision-core/src/constants/frequency.types.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import pluck from 'ramda/src/pluck';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
class FullCartRenewalScheduleComponent {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareKeys
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                offeringMetadataById: OfferingMetadataByIdSelector(store),
                offeringMetadataPricingPlans: OfferingMetadataPricingPlansSelector(store),
            };
        };
        const controllerActions = {
            retrieveOfferingsMetadata
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.fetchOrderDetails = () => {
            const orderIdList = pluck('OfferingId', this.renewalSchedule.OffCycleNextRenewalScheduleDetails);
            this.actions.retrieveOfferingsMetadata(orderIdList);
        };
        this.fetchOrderDetails();
        this.getBillingPeriod = (pricingPlanId) => {
            const pricingPlanDetails = this.state.offeringMetadataPricingPlans[pricingPlanId];
            switch (pricingPlanDetails.DurationPeriodType) {
                case FREQUENCY_TYPES.DAILY:
                    return i18n.translate(CustomerCareKeys.FULL_CART.DAILY);
                case FREQUENCY_TYPES.HOURLY:
                    return i18n.translate(CustomerCareKeys.FULL_CART.HOURLY);
                case FREQUENCY_TYPES.MONTHLY:
                    return i18n.translate(CustomerCareKeys.FULL_CART.MONTHLY);
                case FREQUENCY_TYPES.WEEKLY:
                    return i18n.translate(CustomerCareKeys.FULL_CART.WEEKLY);
                case FREQUENCY_TYPES.YEARLY:
                    return i18n.translate(CustomerCareKeys.FULL_CART.YEARLY);
                default:
                    return '';
            }
        };
    }
    onExpandToggleItems() {
        this.onExpandToggle();
    }
}

export default {
    bindings: {
        currency: '<',
        isEdit: '<',
        isExpanded: '<',
        renewalSchedule: '<',
        onExpandToggle: '&'
    },
    template: require('./full.cart.renewal.schedule.html'),
    controller: FullCartRenewalScheduleComponent,
    controllerAs: 'FullCartRenewalScheduleController'
};
