import CustomerCareKeys from '../../../../../../locales/keys';

class ShippingMethodsController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys
        });
    }
}

export default {
    template: require('./shipping.methods.html'),
    bindings: {
        isShippingMethodsAvailable: '<',
        shippingMethodId: '<',
        shippingMethods: '<',
        shippingMethodSelected: '&'
    },
    controller: ShippingMethodsController
};