import {SearchInventoryAvailabilityErrorSelector} from '../../../reducers/selectors/offering.order.selectors';

export class OfferWizard {
    constructor() {
        Object.assign(this, {
            searchInventoryAvailability: this.searchInventoryAvailability.bind(this),
            onSearchInventoryAvailabilityFailure: this.onSearchInventoryAvailabilityFailure.bind(this)
        });
    }

    connectToStore(selectorsMap, controllerActions) {
        const mapStateToTarget = (store) => {
            return Object.assign(selectorsMap(store), {
                searchInventoryAvailabilityError: SearchInventoryAvailabilityErrorSelector(store)
            });
        };

        return this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    searchInventoryAvailability() {
        return this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
            .catch(this.onSearchInventoryAvailabilityFailure);
    }

    onSearchInventoryAvailabilityFailure(error) {
        error.translatedMessage && this.uiNotificationService.transientError(error.translatedMessage);
        return Promise.reject(error);
    }
}