import {USAGE_CAPS_URL} from './usage.caps.constants';

export function config($stateProvider) {
    $stateProvider.state(USAGE_CAPS_URL, {
        url: '/usageCaps/:serviceId',
        params: {
            serviceId: ''
        },
        template: '<usage-caps></usage-caps>'
    });
}
