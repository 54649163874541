import pluck from 'ramda/src/pluck';
import {stateGo} from 'redux-ui-router';
import {
    LanguageHeaderSelector,
    LastRouteSelector
} from 'invision-core/src/components/session/session.selectors';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    RouteParams,
    RouteCurrentStateSelector
} from 'invision-core/src/components/router/router.selectors';
import customerLocaleKeys from '../../../../locales/keys';
import {SERVICES_USAGE_STATE_NAME} from '../../servicesAndUsage/services.and.usage.config';
import {RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE} from '../../../../customercare.constants';
import {DASHBOARD_ROUTE} from '../../../../reducers/constants/dashboard.constants';
import {
    getOfferingMetadata,
    resetTransfer,
    retrieveCustomer,
    retrieveTransferableOfferings,
    retrieveTransferOfferingContext,
    selectOfferingForTransfer,
    setOfferingsMetadata,
    unselectOfferingForTransfer,
    updateShoppingCart,
    validateSubscriberForServiceTransfer
} from '../../../../reducers/actions/transfer.wizard.actions';
import {
    CurrentCustomerNameSelector,
    CurrentCustomerSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    IsFetchingTransferableOfferingsSelector,
    IsValidatingSubscriberForTransferSelector,
    OfferingInstancesSelectedForTransferSelector,
    SelectedDestinationCustomerSelector,
    TransferableOfferingsSelector,
    TransferableOfferingsViewModelSelector
} from '../../../../reducers/selectors/transfer.wizard.selectors';
import {setTransferFromName} from '../../../../reducers/actions/add.offer.wizard.actions';
import {STATE_OR_NAME as ADD_OFFER_STATE} from '../../../../reducers/constants/add.offer.wizard.constants';

class TransferWizardController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            closeCustomerSearchDialogPopup: this.closeCustomerSearchDialogPopup.bind(this),
            customerLocaleKeys,
            isOffersLoading: false,
            isWizardLoading: false,
            onCustomerSelected: this.onCustomerSelected.bind(this),
            openCustomerSearchDialogPopup: this.openCustomerSearchDialogPopup.bind(this),
            resumeOrderWarning: RESUME_ORDER_INSUFFICIENT_ACCESS_WARNING_CODE,
            selectOffering: this.selectOffering.bind(this),
            servicesAndUsageUrl: SERVICES_USAGE_STATE_NAME,
            showCustomerSearchDialog: false,
            startTransferClicked: false,
            uiNotificationService,
            unselectOffering: this.unselectOffering.bind(this)
        });
    }

    _connectRedux() {
        const controllerActions = {
            fetchCodeTypes,
            getOfferingMetadata,
            resetTransfer,
            retrieveCustomer,
            retrieveTransferableOfferings,
            retrieveTransferOfferingContext,
            selectOfferingForTransfer,
            setOfferingsMetadata,
            setTransferFromName,
            stateGo,
            unselectOfferingForTransfer,
            updateShoppingCart,
            validateSubscriberForServiceTransfer
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerName: CurrentCustomerNameSelector(store),
                currentUserLanguage: LanguageHeaderSelector(store),
                isFetchingTransferableOfferings: IsFetchingTransferableOfferingsSelector(store),
                isRegularExpressionCodeTableLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                isServiceAttributeCodeTableLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                isValidatingSubscriberForTransfer: IsValidatingSubscriberForTransferSelector(store),
                lastRoute: LastRouteSelector(store),
                offeringInstancesSelectedForTransfer: OfferingInstancesSelectedForTransferSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteCurrentStateSelector(store),
                selectedDestinationCustomer: SelectedDestinationCustomerSelector(store),
                transferableOfferings: TransferableOfferingsSelector(store),
                transferableOfferingsViewModel: TransferableOfferingsViewModelSelector(store)
            };
        };

        this._disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onInit() {
        this._connectRedux();
        this.optionalParams = {
            customerId: this.state.currentCustomer.Id
        };

        if (!this.state.isRegularExpressionCodeTableLoaded) {
            this.actions.fetchCodeTypes(CODES.RegularExpression);
        }

        if (!this.state.isServiceAttributeCodeTableLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceAttribute);
        }

        this.actions.retrieveTransferableOfferings(this.state.currentCustomer.Id)
            .then((response) => {
                if (response.Offerings && response.Offerings.length) {
                    this.isOffersLoading = true;

                    const offeringIds = Array.from(new Set(pluck('OrderedOfferingId', response.Offerings)));
                    Promise.all(offeringIds.map((id) => {
                        return this.actions.getOfferingMetadata(id, this.state.currentCustomer.Language);
                    })).then((response) => {
                        this.actions.setOfferingsMetadata(response);
                    }).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    }).finally(() => {
                        this.isOffersLoading = false;
                    });
                }
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });

        this.customerSearchDialogPopupConfig = {
            onRegisterApi: ({api}) => {
                this.customerSearchDialogPopupApi = api;
            }
        };
        this.insufficientPermissionsWarningPopup = {
            onRegisterApi: (event) => {
                this.insufficientPermissionsWarningPopup = event.api;
            }
        };

        this.openInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.open();
        };

        this.closeInsufficientPermissionsWarningPopup = () => {
            this.insufficientPermissionsWarningPopup.close();
            this.goBackToDashboard();
        };

        this.goBackToDashboard = () => {
            const route = this.servicesAndUsageUrl;
            const params = this.state.lastRoute.params || {};
            this.$state.go(route, params);
        };
    }

    openCustomerSearchDialogPopup() {
        this.showCustomerSearchDialog = true;
        this.$timeout(() => {
            this.customerSearchDialogPopupApi.open();
        });
    }

    closeCustomerSearchDialogPopup() {
        this.showCustomerSearchDialog = false;
        this.customerSearchDialogPopupApi.close();
    }

    onCustomerSelected(selectedCustomerId) {
        this.actions.validateSubscriberForServiceTransfer(selectedCustomerId, this.state.currentCustomer.Id).then(() => {
            this.actions.retrieveCustomer(selectedCustomerId, this.state.currentUserLanguage).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
            this.closeCustomerSearchDialogPopup();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    startTransfer() {
        this.startTransferClicked = true;
        if (this.state.offeringInstancesSelectedForTransfer.length && this.state.selectedDestinationCustomer.Id) {
            this.isWizardLoading = true;
            const transferPayload = this.state.offeringInstancesSelectedForTransfer.map((offeringInstanceId) => {
                return {
                    OfferingId: this.state.transferableOfferings.find((offering) => {
                        return offeringInstanceId === offering.OfferingInstanceId;
                    }).OrderedOfferingId,
                    OfferingInstanceId: offeringInstanceId
                };
            });

            this.actions.retrieveTransferOfferingContext(this.state.currentCustomer.Id, transferPayload)
                .then((response) => {
                    const shoppingCart = Object.assign({}, response.ShoppingCart, {
                        TransferFromSubscriberId: this.state.currentCustomer.Id
                    });
                    if (response.Warnings && !!(response.Warnings.find((warning) => {
                        return warning.Code === this.resumeOrderWarning;
                    }))) {
                        this.openInsufficientPermissionsWarningPopup();
                        return Promise.reject();
                    }
                    return this.actions.updateShoppingCart(this.state.selectedDestinationCustomer.Id, shoppingCart);
                }).then(() => {
                    this.actions.setTransferFromName(this.state.currentCustomerName);
                    this.actions.stateGo(ADD_OFFER_STATE, {
                        customerId: this.state.selectedDestinationCustomer.Id
                    });
                }).catch((error) => {
                    // only handling isWizardLoading false on catch due to the success taking you to another url
                    this.isWizardLoading = false;
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        } else if (this.state.offeringInstancesSelectedForTransfer.length && !this.state.selectedDestinationCustomer.Id) {
            this.openCustomerSearchDialogPopup();
        }
    }

    selectOffering(offeringInstanceId) {
        this.actions.selectOfferingForTransfer(offeringInstanceId);

        if (this.showNoServicesSelectedError) {
            this.showNoServicesSelectedError = false;
        }
    }

    unselectOffering(offeringInstanceId) {
        this.actions.unselectOfferingForTransfer(offeringInstanceId);
    }

    $onDestroy() {
        this.actions.resetTransfer();
        this._disconnectRedux();
    }
}

export default {
    controller: TransferWizardController,
    template: require('./transfer.wizard.html')
};
