import {path} from 'ramda';
import {IsPanelVisibleSelector,
    RecentCustomersSelector} from '../../reducers/selectors/recent.customers.selectors';
import {routeToCustomer} from '../../reducers/actions/search.customer.actions';
import {hideRecentCustomersPanel} from '../../reducers/actions/recent.customers.actions';
import CustomerCareLocaleKeys from '../../locales/keys';
import PanelAutoClose from 'invision-core/src/utilities/panel.auto.close.handler';

export class RecentCustomersController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        const controllerActions = {
            hideRecentCustomersPanel,
            routeToCustomer
        };

        const mapStateToTarget = (store) => {
            return {
                customers: RecentCustomersSelector(store),
                isRecentCustomersVisible: IsPanelVisibleSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerClicked = (customer) => {
            this.actions.routeToCustomer(customer);
            this.actions.hideRecentCustomersPanel();
        };

        this.customerCareLocaleKeys = CustomerCareLocaleKeys;

        this.isRecentCustomersHidden = () => {
            return !path(['state', 'isRecentCustomersVisible'], this);
        };

        this.recentCustomersEvent = PanelAutoClose.autoCloseRegistration({
            element: 'recent-customers',
            toggleButtonElement: '#navigator-item-recentCustomers',
            isHidden: this.isRecentCustomersHidden,
            callback: this.actions.hideRecentCustomersPanel
        });
    }

    $onDestroy() {
        this.disconnectRedux();
        PanelAutoClose.unregister(this.recentCustomersEvent);
    }
}

export default {
    template: require('./recent.customers.html'),
    controller: RecentCustomersController,
    controllerAs: 'RecentCustomers',
    bindings: {
        isVisible: '<'
    }
};
