import * as R from 'ramda';
import localeKeys from './../../../locales/keys';
import {
    FormHelper,
    i18n,
    UnsavedChangesPromptActions
} from 'invision-core';
import memoize from 'lodash/memoize';
import {BLANK_PAYMENT_INSTRUMENT} from '../makePayment/make.payment.constants';
import {
    CurrentCustomerIdSelector,
    CurrentServiceIdSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    CustomerTopUpsIsFetchingTopUpsSelector,
    CustomerTopUpsIsSubmittingTopUpSelector,
    CustomerTopUpsServiceOptionsSelector,
    CustomerTopUpsSelectorByServiceIdentifier,
    LastAttemptErrorSelector
} from '../../../reducers/selectors/customer.top.ups.selectors';
import {
    DefaultPaymentMethodSelector,
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector,
    SelectedPaymentMethodSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {
    getAvailableTopUps,
    submitTopUp
} from '../../../reducers/actions/customer.top.ups.actions';
import {
    selectPaymentMethod,
    retrieveWalletAndSelectDefaultPaymentMethod
} from '../../../reducers/actions/customer.ewallet.actions';

function TopUpsController($ngRedux, $state, $stateParams, $window, $scope, $timeout, invPhoneFilter) {
    let disconnectRedux, errorPopupApi = null;

    const mapStateToTarget = (store) => {
        return {
            currentCustomerId: CurrentCustomerIdSelector(store),
            defaultPaymentMethod: DefaultPaymentMethodSelector(store),
            isFetchingTopUps: CustomerTopUpsIsFetchingTopUpsSelector(store),
            isRetrievingPaymentInstruments: IsRetrievingEwalletDataSelector(store),
            isSubmittingTopUp: CustomerTopUpsIsSubmittingTopUpSelector(store),
            lastAttemptError: LastAttemptErrorSelector(store),
            paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
            selectedPaymentInstrument: SelectedPaymentMethodSelector(store),
            serviceId: CurrentServiceIdSelector(store),
            topUpServices: CustomerTopUpsServiceOptionsSelector(store),
            topUpsByServiceId: CustomerTopUpsSelectorByServiceIdentifier(store)
        };
    };

    this.$onInit = () => {
        this.LocaleKeys = localeKeys;

        const controllerActions = {
            getAvailableTopUps,
            retrieveWalletAndSelectDefaultPaymentMethod,
            submitTopUp,
            selectPaymentMethod,
            unregisterUnsavedChanges: UnsavedChangesPromptActions.unregisterUnsavedChanges
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.actions.getAvailableTopUps(this.state.currentCustomerId);
        this.actions.retrieveWalletAndSelectDefaultPaymentMethod({
            customerId: this.state.currentCustomerId
        });
    };

    this.submitTopUpOrder = () => {
        if (!this.validate()) {
            return false;
        }

        const submissionData = {
            serviceIdentifier: this.state.serviceId,
            pricingPlanId: this.selectedTopUpId,
            paymentInstrumentId: this.state.selectedPaymentInstrument.Id
        };

        this.actions.unregisterUnsavedChanges('TopUps.topUpsForm');

        this.actions
            .submitTopUp(this.state.currentCustomerId, submissionData)
            .catch(() => {
                errorPopupApi.open();
            });
    };

    this.validate = () => {
        const errorLookup = {
            selectedTopUpId: {
                required: i18n.translate(this.LocaleKeys.TOP_UPS.TOP_UP_REQUIRED)
            }
        };

        this.errors = FormHelper.formHasErrors(this.topUpsForm.$error, errorLookup)
            .filter((error, index, filtered) => {
                return filtered.find(existing => {
                    return existing.description === error.description;
                }) === error;
            });

        return this.errors.length === 0;
    };

    this.handleFormChange = () => {
        $timeout(this.validate);
    };

    this.isShowingLoadingIndicator = () => {
        return this.state.isFetchingTopUps || this.state.isSubmittingTopUp || this.state.isRetrievingPaymentInstruments;
    };

    this.errorPopupConfig = {
        onRegisterApi: (event) => {
            errorPopupApi = event.api;
        }
    };

    this.handleCloseErrorPopup = () => {
        errorPopupApi.close();
    };

    this.formatBillingAddressCity = () => {
        const billingAddress = R.path(['selectedPaymentInstrument', 'BillingAddress'], this.state);

        if (!billingAddress) {
            return '';
        }

        const substitutions = {
            city: billingAddress.City,
            state: billingAddress.State,
            zip: billingAddress.PostalCode
        };

        return i18n.translate(this.LocaleKeys.CITY_STATE_ZIP, substitutions);
    };

    this.formatSelectedExpirationDate = () => {
        const card = R.path(['selectedPaymentInstrument', 'CreditCard'], this.state);

        if (!card || !card.ExpirationMonth || !card.ExpirationYear) {
            return '';
        }

        return `${card.ExpirationMonth}/${card.ExpirationYear}`;
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.onServiceSelect = (service) => {
        $state.go('.', {
            serviceId: service.id
        });
    };

    this.changePaymentInstrument = () => {
        this.actions.unregisterUnsavedChanges('TopUps.topUpsForm');
        $state.go('.paymentinstrument');
    };

    this.goBack = () => {
        $state.go('^.dashboard');
    };

    this.topUpsAvailable = () => {
        return this.topUpPlans().length;
    };

    this.topUpPlans = () => {
        return this.state.topUpsByServiceId
            ? this.state.topUpsByServiceId[this.state.serviceId].TopUpPricingPlans
            : [];
    };

    this.selectedTopUp = () => {
        return this.selectedTopUpId
            ? this.topUpPlans().find(topup => {
                return topup.Id === this.selectedTopUpId;
            })
            : null;
    };

    this.serviceListOptions = memoize((services, selected) => {
        const topUps = [];
        for (const topUp in services) {
            topUps.push(services[topUp]);
        }
        const topUpOptions = topUps.map(item => {
            return ({
                text: invPhoneFilter(item.FriendlyName ? item.FriendlyName : item.ServiceIdentifier),
                selected: item.ServiceIdentifier === selected,
                id: item.ServiceIdentifier
            });
        });
        return topUpOptions;
    });

    this.toggleDescription = topup => {
        this.selectedDescription = (this.selectedDescription === topup.Id)
            ? null
            : topup.Id;
    };


    // New Payment Instrument related controller methods and helper functions.
    this.instrumentPopup = {
        config:{
            onRegisterApi: (event) => {
                this.instrumentPopup.api = event.api;
            }
        },
        onClose: () => {
            this.instrumentPopup.api.close();
            this.instrumentPopup.show = false;
        },
        open: () => {
            this.instrumentPopup.paymentInstrument = BLANK_PAYMENT_INSTRUMENT;
            this.instrumentPopup.show = true;

            $timeout(this.instrumentPopup.api.open);
        },
        isEditing: false,
        show: false,
        paymentInstrument: null
    };
}

export default {
    template: require('./top.ups.html'),
    bindings: {},
    controller: TopUpsController,
    controllerAs: 'TopUps'
};
