import {CurrentCustomerSelector} from '../../../../reducers/selectors/customer.selectors';
import CustomerCareKeys from '../../../../locales/keys';
import {
    OfferingInstancesSelectedForTransferSelector,
    SelectedDestinationCustomerSelector
} from '../../../../reducers/selectors/transfer.wizard.selectors';

class TransferConfigurationController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CustomerCareKeys,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                offeringInstancesSelectedForTransfer: OfferingInstancesSelectedForTransferSelector(store),
                selectedDestinationCustomer: SelectedDestinationCustomerSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, null)((state) => {
            this.state = state;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./transfer.configuration.html'),
    controller: TransferConfigurationController,
    controllerAs: 'configStep',
    bindings: {
        isLoading: '<?',
        offerings: '<',
        openCustomerSearchModal: '&',
        selectOffering: '&',
        startTransferClicked: '<',
        unselectOffering: '&'
    }
};
