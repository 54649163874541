import LocaleKeys from '../../../../../locales/keys';
import {
    i18n
} from 'invision-core';

class PricingPlanHistoryTabController {
    constructor($filter) {
        this.localeKeys = LocaleKeys;
        this.$filter = $filter;
    }

    buildStartEndDates(item) {
        const sDate = this.$filter('localShort')(item.Start);
        let retVal = `${sDate}`;
        let eDate = i18n.translate(this.localeKeys.CURRENT);

        if (item.End) {
            eDate = this.$filter('localShort')(item.End);
        }

        retVal = `${sDate} - ${eDate}`;
        return retVal;
    }
}

export default {
    controller: PricingPlanHistoryTabController,
    template: require('./pricing.plan.history.tab.html'),
    bindings: {
        periods: '<'
    }
};