import contains from 'ramda/src/includes';
import curry from 'ramda/src/curry';
import last from 'ramda/src/last';
import map from 'ramda/src/map';
import pipe from 'ramda/src/pipe';
import sort from 'ramda/src/sort';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../../locales/keys';
import {QuantityPricingTypeCodes} from './decisions.constants';

const getPriceTypeOptions = curry((priceType, option, billerConfigId, pricingPlansMetadata) => {
    if (pricingPlansMetadata) {
        const pricingPlanBillerRuleInstances = pricingPlansMetadata[option.PricingPlanId] &&
            pricingPlansMetadata[option.PricingPlanId].PricingPlanBillerRuleInstances;
        if (pricingPlanBillerRuleInstances) {
            const filteredPricingPlanBillerRuleInstances = [
                ...( pricingPlanBillerRuleInstances.RecurringBillerRuleInstances || []),
                ...( pricingPlanBillerRuleInstances.OneTimeBillerRuleInstances || [])];

            const pricingPlanBillerRuleInstance = filteredPricingPlanBillerRuleInstances.find((bri) => {
                return bri.BillerRuleConfigurationId === billerConfigId;
            });

            if (pricingPlanBillerRuleInstance && pricingPlanBillerRuleInstance.QuantityPricingTypeCode === priceType) {
                return pipe(
                    sort(ascending),
                    map(createQuantityPriceObject),
                    updateLastQuantity
                )(pricingPlanBillerRuleInstance.BillerRuleInstanceCharges);
            }
        }
    }

    return [];
});

const ascending = (a, b) => {
    return a.StartQuantity - b.StartQuantity;
};

const createQuantityPriceObject = (item) => {
    let qty = `${item.StartQuantity}`;

    if (isQuantityARange(item)) {
        qty = `${item.StartQuantity}-${item.EndQuantity}`;
    }

    return {
        quantity:  qty,
        price: item.ChargeAmount
    };
};

const updateLastQuantity = (tiers) => {
    const lastTier = last(tiers);
    if (lastTier && !contains('-', lastTier.quantity)) {
        lastTier.quantity += '+';
    }

    return tiers;
};

const isQuantityARange = (item) => {
    return item.EndQuantity && item.StartQuantity !== item.EndQuantity;
};

export default class PricingTypeDecisions {
    constructor(pagesWithDecisions, isDecisionGroupOptionItem) {
        this.pagesWithDecisions = pagesWithDecisions;
        this.getTieredItems = getPriceTypeOptions(QuantityPricingTypeCodes.TIERED) || [];

        this.getTaperedItems = getPriceTypeOptions(QuantityPricingTypeCodes.TAPERED) || [];
        this.findPricingTypeOptions = getPriceTypeOptions;

        this.createThumbnailTier = curry((context, option, priceType, pricingPlanMetadata, isDgoi, bri) => {
            const _isDgoi = isDgoi || isDecisionGroupOptionItem;

            context[_isDgoi || isDecisionGroupOptionItem ? option.DecisionGroupOptionItemId : option.Id][bri.BillerRuleConfigurationId] = {
                currencyCode: option.CurrencyCode || option.BillerRuleInstanceThumbnails[0].CurrencyCode,
                description: priceType === QuantityPricingTypeCodes.TIERED ?
                    i18n.translate(CustomerCareKeys.DECISIONS.TIERED_DESCRIPTION) :
                    i18n.translate(CustomerCareKeys.DECISIONS.TAPERED_DESCRIPTION),
                headerItems: [
                    i18n.translate(CustomerCareKeys.DECISIONS.QUANTITY),
                    i18n.translate(CustomerCareKeys.DECISIONS.PRICE)
                ],
                icon: priceType === QuantityPricingTypeCodes.TIERED ? 'tiered' : 'tapered',
                tableItems: priceType === QuantityPricingTypeCodes.TIERED ?
                    this.getTieredItems(option, bri.BillerRuleConfigurationId, pricingPlanMetadata) :
                    this.getTaperedItems(option, bri.BillerRuleConfigurationId, pricingPlanMetadata),
                title: priceType === QuantityPricingTypeCodes.TIERED ?
                    i18n.translate(CustomerCareKeys.DECISIONS.TIERED_PRICING) :
                    i18n.translate(CustomerCareKeys.DECISIONS.TAPERED_PRICING)
            };
        });
    }

    createTaperedData(pricingPlansMetadata, isDgoi) {
        return this.createDataFromThumbnails(QuantityPricingTypeCodes.TAPERED, pricingPlansMetadata, isDgoi);
    }

    createTieredData(pricingPlansMetadata, isDgoi) {
        return this.createDataFromThumbnails(QuantityPricingTypeCodes.TIERED, pricingPlansMetadata, isDgoi);
    }

    createDataFromThumbnails(priceType, pricingPlansMetadata, isDgoi) {
        const context = {};

        this.pagesWithDecisions.map((page) => {
            page.decisions.map((decision) => {
                decision.Options.map((option) => {
                    if (isDgoi) {
                        option.Items.map((item) => {
                            context[item.DecisionGroupOptionItemId] = {};
                            item.BillerRuleInstanceThumbnails.map(this.createThumbnailTier(context, item, priceType, pricingPlansMetadata, true));
                        });
                    } else {
                        context[option.Id] = {};
                        option.BillerRuleInstanceThumbnails.map(this.createThumbnailTier(context, option, priceType, pricingPlansMetadata, false));
                    }
                });
            });
        });

        return context;
    }
}
