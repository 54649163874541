import {
    isNil,
    reject
} from 'ramda';
import {
    i18n,
    MetadataActions,
    SessionSelectors,
    UnsavedChangesPromptActions
} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    chargePaymentInstrument,
    clearSelectedPaymentMethod,
    editPaymentInstrument,
    retrieveAvailablePaymentInstrumentTypes,
    selectPaymentMethod,
    setPaymentInstrumentDefault,
    updatePaymentAccountsOnSelectedPaymentMethod,
    retrieveWallet
} from '../../../reducers/actions/customer.ewallet.actions';
import {
    DefaultPaymentMethodAutoPaySelector,
    EwalletErrorSelector,
    PaymentInstrumentSummariesSelector,
    PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesAutoPayViewModelSelector,
    PaymentIsCreatingOrEditingDataSelector,
    PaymentIsUpdatingAutoPaySelector,
    IsRetrievingEwalletDataSelector,
    SelectedPaymentMethodConvergentBillerPaymentInstrumentAccountsSelector,
    SelectedPaymentMethodSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {DefaultBillingAddressSelector} from '../../../reducers/selectors/customer.addresses.selectors';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    retrieveConvergentBillerSubscriberSummary
} from '../../../reducers/actions/customer.convergent.biller.actions';
import {
    ConvergentBillerSubscriberSummarySelector,
    ConvergentBillerSubscriberSummaryAccountsSelector,
    ConvergentBillerSubscriberSummaryPostPaidAccountSelector,
    ConvergentBillerSubscriberSummaryPrePaidAccountSelector,
    CurrentAccountSummarySelector,
    CurrentPostPaidAccountSummarySelector,
    IsFetchingSubscriberSummarySelector,
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {
    DASHBOARD_ROUTE
} from '../../../reducers/constants/dashboard.constants';
import {stateGo} from 'redux-ui-router';
import {setCustomerAutoPayBannerFlag} from '../../../reducers/actions/customer.account.status.actions';
import {
    retrieveCustomerHouseholds
} from '../../../reducers/actions/customer.household.actions';
import {
    CurrentHouseholdSelector,
    CurrentMemberHasCreatePaymentInstrumentPermissionsSelector
} from '../../../reducers/selectors/customer.household.selectors';

const SUSPENSION_TYPE = {
    INDEFINITE: 'INDEFINITE',
    UNTIL: 'UNTIL'
};

function CustomerConfigureAutopayController($ngRedux, uiNotificationService, $timeout, $state) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            billingAddress: DefaultBillingAddressSelector(store),
            currentAccountSummary: CurrentAccountSummarySelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentHousehold: CurrentHouseholdSelector(store),
            currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
            currentPostPaidAccountSummary: CurrentPostPaidAccountSummarySelector(store),
            defaultPaymentMethod: DefaultPaymentMethodAutoPaySelector(store),
            eWalletError: EwalletErrorSelector(store),
            isCreatingOrEditingPaymentMethodData: PaymentIsCreatingOrEditingDataSelector(store),
            isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
            isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
            isUpdatingAutoPayData: PaymentIsUpdatingAutoPaySelector(store),
            paymentInstruments: PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesAutoPayViewModelSelector(store),
            paymentInstrumentSummaries: PaymentInstrumentSummariesSelector(store),
            postPaidSubscriberSummaryAccounts: ConvergentBillerSubscriberSummaryPostPaidAccountSelector(store),
            prepaidSubscriberSummaryAccounts: ConvergentBillerSubscriberSummaryPrePaidAccountSelector(store),
            selectedPaymentMethodPaymentAccountsSelector: SelectedPaymentMethodConvergentBillerPaymentInstrumentAccountsSelector(store),
            selectedPaymentMethod: SelectedPaymentMethodSelector(store),
            subscriberSummary: ConvergentBillerSubscriberSummarySelector(store),
            subscriberSummaryAccounts: ConvergentBillerSubscriberSummaryAccountsSelector(store),
            lastRoute: SessionSelectors.LastRouteSelector(store)
        };
    };

    const controllerActions = {
        chargePaymentInstrument,
        clearSelectedPaymentMethod,
        editPaymentInstrument,
        fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
        retrieveAvailablePaymentInstrumentTypes,
        retrieveConvergentBillerSubscriberSummary,
        retrieveWallet,
        retrieveCustomerHouseholds,
        selectPaymentMethod,
        setCustomerAutoPayBannerFlag,
        setPaymentInstrumentDefault,
        stateGo,
        updatePaymentAccountsOnSelectedPaymentMethod,
        unregisterUnsavedChanges: UnsavedChangesPromptActions.unregisterUnsavedChanges,
    };

    this.$onInit = () => {
        this.LocaleKeys = LocaleKeys;
        this.DEFAULT_BACK_ROUTE = DASHBOARD_ROUTE;
        this.submitText = LocaleKeys.AUTO_PAY.UPDATE;
        this.confirmDeactivation = false;
        this.showFields = false;
        this.suspendType = SUSPENSION_TYPE.INDEFINITE;
        this.suspendTypes = SUSPENSION_TYPE;
        this.minDate = new Date().setDate( new Date().getDate()+1);

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        fetchRequiredData();

        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this.mainFormServiceError = '';

        this.changeAutoPayAccountActivationPopUp = {
            onRegisterApi: (evt) => {
                this.changeAutoPayAccountActivationPopUp = evt.api;
            }
        };

        this.actions.retrieveAvailablePaymentInstrumentTypes().catch((error) => {
            uiNotificationService.transientError(error.translatedMessage);
        });

    };


    const fetchRequiredData = () => {

        this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId).then(() => {
            this.selectedAccount = this.state.currentAccountSummary;

            this.actions.retrieveWallet(this.buildWalletRequest()).then(() => {
                this.setCurrentlySelectedPaymentInstrument(this.state.defaultPaymentMethod);
            });

            this.accountSummaryEditCopy = this.state.currentAccountSummary.asMutable({
                deep: true
            });
            this.showFields = this.accountSummaryEditCopy.AutoPayConfigured;
            this.setSelectedSuspension();
        });

        if (!this.state.currentHousehold) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false);
        }
    };

    this.setSelectedSuspension = () => {
        if (this.accountSummaryEditCopy.AutoPayConfigured) {
            if (this.accountSummaryEditCopy.AutoPaySuspensionEndDate && this.accountSummaryEditCopy.AutoPaySuspended) {
                this.suspendType = SUSPENSION_TYPE.UNTIL;
            } else if (!!this.accountSummaryEditCopy.AutoPaySuspensionEndDate && this.accountSummaryEditCopy.AutoPaySuspended) {
                this.suspendType = SUSPENSION_TYPE.INDEFINITE;
            }
        }
    };

    this.continueDeactivatingAutopay = (shouldDeactivateAutopay) => {
        if (shouldDeactivateAutopay) {
            this.confirmDeactivation = true;
            this.showFields = this.accountSummaryEditCopy.AutoPayConfigured;
            this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
                ConvergentBillerPaymentInstrumentAccounts: this.getUpdatedPaymentAccountsForSelectedPaymentMethod()
            });
        } else {
            this.accountSummaryEditCopy.AutoPayConfigured = true;
        }
        this.changeAutoPayAccountActivationPopUp.close();
    };

    this.$onDestroy = () => {
        this.actions.clearSelectedPaymentMethod();
        disconnectRedux();
    };

    this.shouldShowErrorForMainForm = () => {
        return this.formApi.$submitted && (!this.state.selectedPaymentMethod || this.hasNoPaymentInstruments() || this.isSuspensionDateRequired());
    };

    this.shouldShowErrorForMainFormField = (fieldName) => {
        return this.formApi[fieldName] && (this.formApi[fieldName].$touched || this.formApi.$submitted) && this.formApi[fieldName].$invalid;
    };

    this.getUpdatedPaymentAccountsForSelectedPaymentMethod = (dateSelected, autoPayStatus) => {
        if (this.selectedAccount && this.state.selectedPaymentMethod) {
            const paymentInstrumentPaymentAccounts = reject((currAccount) => {
                return currAccount.AccountNumber === this.selectedAccount.AccountNumber;
            }, this.state.selectedPaymentMethodPaymentAccountsSelector);

            if (this.accountSummaryEditCopy.AutoPayConfigured && this.accountSummaryEditCopy.AutoPaySuspended && this.suspendType === SUSPENSION_TYPE.UNTIL && (dateSelected || this.accountSummaryEditCopy.AutoPaySuspensionEndDate)) {
                this.accountSummaryEditCopy.AutoPaySuspensionEndDate = dateSelected || this.accountSummaryEditCopy.AutoPaySuspensionEndDate;
            } else {
                this.accountSummaryEditCopy.AutoPaySuspensionEndDate = undefined;
            }

            paymentInstrumentPaymentAccounts.push({
                AutoPay: autoPayStatus ? autoPayStatus : this.accountSummaryEditCopy.AutoPayConfigured,
                AccountNumber: this.selectedAccount.AccountNumber,
                AutoPaySuspended: this.accountSummaryEditCopy.AutoPayConfigured && this.accountSummaryEditCopy.AutoPaySuspended ? this.accountSummaryEditCopy.AutoPaySuspended : null,
                AutoPaySuspensionEndDate: this.accountSummaryEditCopy.AutoPaySuspensionEndDate,
            });
            return paymentInstrumentPaymentAccounts;
        }
        return [];
    };

    this.handleSetSuspensionDate = () => {
        if (this.suspendType === SUSPENSION_TYPE.INDEFINITE) {
            this.accountSummaryEditCopy.AutoPaySuspensionEndDate = undefined;
        }
        this.editAutoPayOnPaymentMethod();
    };

    this.editAutoPayOnPaymentMethod = (dateSelected) => {
        this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
            ConvergentBillerPaymentInstrumentAccounts: this.getUpdatedPaymentAccountsForSelectedPaymentMethod(dateSelected)
        });

    };

    this.editAutoPayActivation = (autoPayStatus) => {
        if (this.accountSummaryEditCopy.AutoPayConfigured && !this.confirmDeactivation && this.state.currentAccountSummary.AutoPayConfigured) {
            this.changeAutoPayAccountActivationPopUp.open();
        } else {
            this.showFields = !this.accountSummaryEditCopy.AutoPayConfigured;
            this.actions.updatePaymentAccountsOnSelectedPaymentMethod({
                ConvergentBillerPaymentInstrumentAccounts: this.getUpdatedPaymentAccountsForSelectedPaymentMethod(null, autoPayStatus)
            });
        }
    };

    this.setCurrentlySelectedPaymentInstrument = (paymentInstrument) => {
        this.currentlySelectedPaymentInstrument = paymentInstrument;
        if (!isNil(paymentInstrument)) {
            this.selectPaymentMethod();
        } else {
            this.actions.clearSelectedPaymentMethod();
        }
    };

    this.buildWalletRequest = () => {
        return {
            customerId: this.state.currentCustomerId
        };
    };

    this.selectPaymentMethod = () => {
        this.actions.selectPaymentMethod(this.currentlySelectedPaymentInstrument);

        this.editAutoPayOnPaymentMethod();
    };

    this.handleServiceError = (errorMessageKey) => {
        const serviceErrorAlert = i18n.translate(errorMessageKey);
        uiNotificationService.error(serviceErrorAlert, null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });

        if (this.state.eWalletError && !this.state.isCreatingOrEditingPaymentMethodData) {
            this.mainFormServiceError = serviceErrorAlert;
        }
    };

    this.updateAutoPay = () => {
        if (!this.selectedAccount ||
            !this.state.selectedPaymentMethod ||
            this.hasNoPaymentInstruments()) {
            return;
        }
        const request = {
            updateAutoPay: true,
            customerId: this.state.currentCustomerId,
            request: {
                PaymentInstrument: this.state.selectedPaymentMethod
            }
        };

        this.mainFormServiceError = '';

        return this.actions.editPaymentInstrument(request).then(() => {
            this.actions.setCustomerAutoPayBannerFlag(this.accountSummaryEditCopy.AutoPayConfigured
                && !this.state.currentAccountSummary.AutoPayConfigured
                && !this.accountSummaryEditCopy.AutoPaySuspended);
            uiNotificationService.success(i18n.translate(LocaleKeys.AUTO_PAY.SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
            this.formApi.$setPristine();
            $state.go('index.customercare.customer.accountDashboard', {
                'customerId': parseInt(this.state.currentCustomerId),
                'showAutoPayBanner': {
                    enabled: this.accountSummaryEditCopy.AutoPayConfigured && !this.state.currentAccountSummary.AutoPayConfigured && !this.accountSummaryEditCopy.AutoPaySuspended,
                    customerId: parseInt(this.state.currentCustomerId)
                }
            });
        }, () => {
            this.handleServiceError(LocaleKeys.AUTO_PAY.FAILURE);
        });
    };

    this.isSuspensionDateRequired = () => {
        return this.accountSummaryEditCopy.AutoPaySuspended && this.accountSummaryEditCopy.AutoPayConfigured &&
            this.suspendType === SUSPENSION_TYPE.UNTIL &&
            this.formApi.$submitted &&
            !this.accountSummaryEditCopy.AutoPaySuspensionEndDate;
    };

    this.goBack = () => {
        const route = this.state.lastRoute.name || this.DEFAULT_BACK_ROUTE;
        const params = this.state.lastRoute.params || {};
        $state.go(route, params);
    };

    this.hasNoPaymentInstruments = () => {
        return this.state.paymentInstruments.length === 0;
    };

    this.showPageLoader = () => {
        return this.state.isFetchingEwalletData ||
            this.state.isMakingPayment ||
            this.state.isFetchingSubscriberSummary ||
            this.state.isUpdatingAutoPayData;
    };

    this.getTitleKey = () => {
        return i18n.translate(LocaleKeys.AUTO_PAY.CONFIGURE_AUTO_PAY_HEADER, {
            account_number: this.state.currentAccountSummary ? this.state.currentAccountSummary.AccountNumber : ''
        });
    };

    this.canCreatePaymentInstruments = () => {
        if (!this.state.currentHousehold) {
            return true;
        }

        return this.state.currentMemberCanCreatePaymentInstrument;
    };

    this.onSubmit = () => {
        this.formApi.$setSubmitted();
        if (!this.isSuspensionDateRequired() && this.state.selectedPaymentMethod) {
            this.updateAutoPay();
        }
    };
}

export default {
    template: require('./configure.autopay.html'),
    controller: CustomerConfigureAutopayController,
    controllerAs: 'CustomerConfigureAutopayController'
};
