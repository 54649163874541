import {CustomerCareOrderHistoryWindowTitleSelector} from '../../../../reducers/selectors/customer.selectors';
// import {
//     GroupOrderDetailsRoute,
//     GroupOrderHistoryList,
//     OrderHistoryMountPoint,
//     OrderHistoryRouteConstants,
//     OrderHistoryTab
// } from '@invision/customercare2/src/customer/orderHistory';
// import React from 'react';
import {
    ORDER_HISTORY_LIST_STATE_NAME,
    ORDER_HISTORY_LIST_WRAPPER_STATE_NAME,
    STATE_OR_NAME
} from '../order.history.config';

export const GROUP_ORDER_HISTORY_LIST_STATE_NAME = `${ORDER_HISTORY_LIST_WRAPPER_STATE_NAME}.groupOrderList`;
export const GROUP_ORDER_HISTORY_DETAILS_STATE_NAME = `${STATE_OR_NAME}.groupOrderDetails`;

export  function routing($stateProvider) {
    $stateProvider.state(ORDER_HISTORY_LIST_STATE_NAME, {
        url: 'list',
        data: {
            windowTitle: CustomerCareOrderHistoryWindowTitleSelector,
        },
        template: '<order-history-list class="u-sizeFullHeight"></order-history-list>'
    });
    // $stateProvider.state(GROUP_ORDER_HISTORY_DETAILS_STATE_NAME, {
    //     url: OrderHistoryRouteConstants.GROUP_ORDER_DETAILS_PAGE.pathSegment,
    //     component: ({transition}) => {
    //         const {customerId, groupOrderId} = transition.params();
    //         return (
    //             <div className="u-sizeFullHeight">
    //                 <OrderHistoryMountPoint>
    //                     <GroupOrderDetailsRoute routeParams={{
    //                         customerId,
    //                         groupOrderId
    //                     }} />
    //                 </OrderHistoryMountPoint>
    //             </div>
    //         );
    //     }
    // });
    // $stateProvider.state({
    //     url: OrderHistoryTab.GroupOrderListPage,
    //     name: GROUP_ORDER_HISTORY_LIST_STATE_NAME,
    //     component: ({transition}) => {
    //         const {customerId} = transition.params();
    //         return (
    //             <div className="u-sizeFullHeight">
    //                 <OrderHistoryMountPoint>
    //                     <GroupOrderHistoryList routeParams={{
    //                         customerId
    //                     }}/>
    //                 </OrderHistoryMountPoint>
    //             </div>
    //         );
    //     }
    // });

}