import {
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {retrieveBulkServicesTemplate} from '../../../../../reducers/actions/order.details.actions';
import {
    OrderDetailsExportingBulkServiceAttributesErrorSelector,
    OrderDetailsIsExportingBulkServiceAttributesSelector
} from '../../../../../reducers/selectors/order.details.selectors';

class ExportBulkAttributesModalController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareLocaleKeys,
            i18n
        });
    }

    $onInit() {
        const controllerActions = {
            retrieveBulkServicesTemplate
        };
        const mapStateToTarget = (store) => {
            return {
                isExportingBulkServiceAttributes: OrderDetailsIsExportingBulkServiceAttributesSelector(store),
                isExportingBulkServiceAttributesError: OrderDetailsExportingBulkServiceAttributesErrorSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.actions.retrieveBulkServicesTemplate(this.orderDetails).then((response) => {
            this.serviceTemplateFilename = response.Filename;
            this.templateUrl = response.Url;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    close() {
        this.onClose()();
    }
}

export default {
    bindings: {
        onClose: '&',
        orderDetails: '<'
    },
    controller: ExportBulkAttributesModalController,
    controllerAs: 'ExportBulkAttributesModal',
    template: require('./export.bulk.attributes.modal.html')
};
