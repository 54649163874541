import __ from 'ramda/src/__';
import compose from 'ramda/src/compose';
import reduce from 'ramda/src/reduce';

import ApiFaultCodes from './fault.code.keys';
import {
    handleGlobalFault,
    handleInvalidRequestFault
} from 'invision-core/src/reducers/actions/fault/fault.actions';

/**
 * Registered faults will be intercepted by the api middleware/thunk helper, and dispatched to the configured faultHandler.
 * Fault code behaviors support the following:
 *
 * @param faultHandler - fault handler function that is passed an options object
 * @param faultHandler.options - defines fault attributes
 * @param faultHandler.options.fault - translated fault instance
 * @param faultHandler.options.originState - router state of the originating fault
 * @param faultHandler.options.clearSession - option to clear the session
 * @param faultHandler.options.redirectToLogin - option to navigate to the login
 * @param faultHandler.options.hideBackLink - option to suppress navigation link
 * @param config - optional settings passed into the faultHandler
 */

// Anything in this collection is suspected to also be handled in form related
// feedback through a reducer. Most invalid form request/response errors
// should be within this collection.
const invalidRequestCodes = [
    ApiFaultCodes.ACCOUNT_ITEM_LIMIT_REACHED,
    ApiFaultCodes.ADD_HIERARCHY_NODE_FAILURE,
    ApiFaultCodes.ADDRESS_VALIDATION_FAILED,
    ApiFaultCodes.ADDRESS_VALIDATOR_NOT_ACCESSIBLE,
    ApiFaultCodes.ADJUSTMENT_INVALID_OVER_CHARGE,
    ApiFaultCodes.ADJUSTMENT_LIMIT_PER_DAY_VIOLATED,
    ApiFaultCodes.ADJUSTMENT_LIMIT_PER_INSTANCE_VIOLATED,
    ApiFaultCodes.ADJUSTMENT_NOT_ALLOWED,
    ApiFaultCodes.AUTOPAY_NOT_SUPPORTED,
    ApiFaultCodes.BULK_SERVICE_ATTRIBUTES_JOB_EXTRACT_FILE_ALREADY_PROCESSED,
    ApiFaultCodes.BULK_SERVICE_ATTRIBUTES_JOB_MISSING_EXTRACT_FILE,
    ApiFaultCodes.BULK_SERVICE_ATTRIBUTES_JOB_MISSING_PRICING_PLAN,
    ApiFaultCodes.BULK_SERVICE_ATTRIBUTES_JOB_NOT_ASSOCIATED_WITH_ORDER,
    ApiFaultCodes.BULK_SERVICE_ATTRIBUTES_JOB_NOT_FOUND,
    ApiFaultCodes.CONVERGENT_BILLER_ISSUE,
    ApiFaultCodes.CREDIT_CARD_TYPE,
    ApiFaultCodes.CREDIT_CHECK_CONFIGURATION_NEEDED,
    ApiFaultCodes.CREDIT_DEBIT_ADJUSTMENT_REASON_CODE_NOT_CONFIGURED,
    ApiFaultCodes.CREDIT_NOTE_LIMIT_PER_DAY_VIOLATED,
    ApiFaultCodes.CREDIT_NOTE_LIMIT_PER_INSTANCE_VIOLATED,
    ApiFaultCodes.DATA_EXTRACT_NOT_FOUND,
    ApiFaultCodes.DEPENDENT_PRICING_PLAN,
    ApiFaultCodes.DEVICE_NOT_FOUND,
    ApiFaultCodes.DUPLICATE_SERVICE_IDENTIFIER,
    ApiFaultCodes.DUPLICATE_SERVICE_IDENTIFIER_IN_ARM,
    ApiFaultCodes.EZTAX_ADDRESS_NOT_FOUND,
    ApiFaultCodes.EZTAX_AUTHORIZATION_FAILED,
    ApiFaultCodes.EZTAX_BILL_TO_LOCATION_NOT_SPECIFIED,
    ApiFaultCodes.EZTAX_BUSINESS_CLASS_NOT_VALID,
    ApiFaultCodes.EZTAX_COMMIT_TRANSACTION_ALREADY_EXECUTED,
    ApiFaultCodes.EZTAX_COUNTRY_ISO_NOT_VALID,
    ApiFaultCodes.EZTAX_COUNTY_STATE_ZIP_NOT_FOUND,
    ApiFaultCodes.EZTAX_MISSING_INVALID_REQUEST_BODY,
    ApiFaultCodes.EZTAX_TABLE_INDICES_NOT_MATCHING,
    ApiFaultCodes.EZTAX_TAX_TRANSACTIONS_ALREADY_COMMITTED,
    ApiFaultCodes.EZTAX_TRANSACTION_SERVICE_PAIR_NOT_VALID,
    ApiFaultCodes.EZTAX_TRANSACTIONS_FOR_DOCUMENT_CODE_NOT_FOUND,
    ApiFaultCodes.EZTAX_UNABLE_TO_REACH_CONFIGURED_URL,
    ApiFaultCodes.EZTAX_UNSUPPORTED_TAX_CODE,
    ApiFaultCodes.FDO_DISABLED_ON_BUNT,
    ApiFaultCodes.FDO_MAX_DATE_SURPASSED,
    ApiFaultCodes.FDO_ORDER_CONTAINS_DEPOSITS,
    ApiFaultCodes.FDO_ORDER_CONTAINS_INVALID_PRODUCTS,
    ApiFaultCodes.FDO_ORDER_CONTAINS_LOGICAL_INVENTORY,
    ApiFaultCodes.FDO_ORDER_CONTAINS_PHYSICAL_INVENTORY,
    ApiFaultCodes.FDO_PREPAID_ORDERS_NOT_ALLOWED,
    ApiFaultCodes.FDO_PRICING_PLAN_OVERLAP,
    ApiFaultCodes.FULL_SUITE_ADDRESS_VALIDATION_FAILED,
    ApiFaultCodes.FULL_SUITE_OTHER_ADDRESS_VALIDATION_FAILED,
    ApiFaultCodes.GIFT_CARD_ACTIVATION_FAILURE,
    ApiFaultCodes.HIERARCHY_NOT_FOUND,
    ApiFaultCodes.HOUSEHOLD_ACCESS_DENIED_INSUFFICIENT_SUBSCRIBER_ACCESS,
    ApiFaultCodes.INVALID_CHARGE_REQUEST,
    ApiFaultCodes.INVALID_FDO_IN_PROGRESS,
    ApiFaultCodes.INVALID_FDO_NOT_FDO,
    ApiFaultCodes.INVALID_PAYMENT_INSTRUMENT,
    ApiFaultCodes.INVALID_PAYMENT_REQUEST,
    ApiFaultCodes.INVENTORY_INVALID_URL,
    ApiFaultCodes.INVENTORY_ITEM_NO_LONGER_AVAILABLE,
    ApiFaultCodes.INVENTORY_MULTIPLE_PROVIDER,
    ApiFaultCodes.INVENTORY_PROVIDER_PROCESSING_ERROR,
    ApiFaultCodes.INVENTORY_PROVIDER_UNAVAILABLE_ERROR,
    ApiFaultCodes.INVENTORY_QUANTITY_SHOULD_BE_GREATER_THAN_ZERO,
    ApiFaultCodes.INVENTORY_STORE_REGION_ERROR,
    ApiFaultCodes.INVENTORY_TYPE_ATTRIBUTE_NOT_MATCHED,
    ApiFaultCodes.INVENTORY_TYPE_MAKE_NOT_MATCHED,
    ApiFaultCodes.INVENTORY_TYPE_NOT_FOUND,
    ApiFaultCodes.INVENTORY_TYPE_PROVIDER_COMMUNICATION_NOT_FOUND,
    ApiFaultCodes.INVENTORY_TYPE_PROVIDER_NOT_FOUND,
    ApiFaultCodes.INVENTORY_TYPE_PROVIDER_NOT_VALID,
    ApiFaultCodes.INVENTORY_SKU,
    ApiFaultCodes.INVOICE_AND_DEBIT_NOTE_ID_NOT_ALLOWED,
    ApiFaultCodes.NO_ATTRIBUTES_FOR_PRICING_PLAN,
    ApiFaultCodes.NO_BILL_CYCLES_AVAILABLE,
    ApiFaultCodes.NO_INVENTORY_AVAILABLE,
    ApiFaultCodes.OFFERING_NOT_FOUND,
    ApiFaultCodes.ORDER_COULD_NOT_BE_CANCELED,
    ApiFaultCodes.OVERLAPPING_OR_INVALID_INDEX,
    ApiFaultCodes.PAYMENT_INSTRUMENT_AUTHORIZATION_FAILED,
    ApiFaultCodes.PAYMENT_INSTRUMENT_NOT_FOUND,
    ApiFaultCodes.PAYMENT_INSTRUMENT_TYPE_NOT_SUPPORTED,
    ApiFaultCodes.PAYMENT_SOURCE_NOT_VALID,
    ApiFaultCodes.PAYPAL_PROCESSING_FAILURE,
    ApiFaultCodes.PHYSICAL_INVENTORY_TYPE_MODEL,
    ApiFaultCodes.PRICING_PLAN_NOT_AVAILABLE_FOR_TOP_UP,
    ApiFaultCodes.PRICING_PLAN_NOT_FOUND,
    ApiFaultCodes.PROCESS_PAYMENT_FAILED,
    ApiFaultCodes.PROMISE_TO_PAY_ALREADY_EXISTS,
    ApiFaultCodes.REORDER_WHILE_PENDING_ERROR,
    ApiFaultCodes.REVENUE_CONFIGURATION_NOT_FOUND,
    ApiFaultCodes.REVENUE_MANAGEMENT_PAYMENT_INSTRUMENT_CONVERSION_FAILURE,
    ApiFaultCodes.SERVICE_ATTRIBUTE_VALUE_MISSING_ERROR,
    ApiFaultCodes.SERVICE_FEATURE_CANNOT_BE_ASSOCIATED,
    ApiFaultCodes.SERVICE_IDENTIFIER_NOT_FOUND,
    ApiFaultCodes.SHOPPING_CART_AD_HOC_EMPTY_OVERRIDE,
    ApiFaultCodes.SHOPPING_CART_AD_HOC_OVERRIDE_DETAIL_NOT_ALLOWED,
    ApiFaultCodes.SHOPPING_CART_AD_HOC_OVERRIDE_INVALID_AMOUNT,
    ApiFaultCodes.SHOPPING_CART_EXCEEDED,
    ApiFaultCodes.SHOPPING_CART_HAS_NO_ITEMS,
    ApiFaultCodes.SHOPPING_CART_INVALID_AD_HOC_OVERRIDE_DETAIL,
    ApiFaultCodes.SHOPPING_CART_INVALID_AD_HOC_PRODUCTS_IN_CART,
    ApiFaultCodes.SITE_ADDRESS_NOT_SPECIFIED,
    ApiFaultCodes.SUBSCRIBER_IN_CONVERGENT_BILLER_NOT_FOUND,
    ApiFaultCodes.SUBSCRIBER_NOT_FOUND,
    ApiFaultCodes.SUBSCRIBER_OFFER_LIMIT_VIOLATION,
    ApiFaultCodes.SUBSCRIBER_PRODUCT_NOT_FOUND,
    ApiFaultCodes.SUBSCRIBER_REQUIRED,
    ApiFaultCodes.TEXT_SCRUBBER_FAILED,
    ApiFaultCodes.USAGE_CAPS_INVALID_ELECTION,
    ApiFaultCodes.USER_INVENTORY_CHANGES_NOT_ALLOWED
];

// Anything in this collection will be registered with the global handlers.
// This is best for things that require severe restriction to the user, which
// at the time of this comment was to bounce the user to an outside-the-app
// error screen.
const globalCodes = [
    ApiFaultCodes.ACCESS_DENIED_EXPIRED_TEMPORARY_PASSWORD,
    ApiFaultCodes.ACCESS_DENIED_INSUFFICIENT_SUBSCRIBER_ACCESS,
    ApiFaultCodes.CASE_ACTIVITY_NOT_FOUND,
    ApiFaultCodes.CASE_NOT_FOUND,
    ApiFaultCodes.INTERACTION_NOT_FOUND,
    ApiFaultCodes.MISSING_OR_INVALID_VALUES,
    ApiFaultCodes.PAYMENT_INSTRUMENT_DETAIL_NOT_FOUND,
    ApiFaultCodes.PRODUCT_BUNDLE_NOT_AVAILABLE,
    ApiFaultCodes.PRODUCT_DOES_NOT_EXIST,
    ApiFaultCodes.PRODUCT_PRICING_PLAN_NOT_FOUND,
    ApiFaultCodes.SPENDING_LIMIT_VIOLATION,
    ApiFaultCodes.SUBSCRIPTION_NOT_FOUND
];

const customFaults = {};

const externalProviderFaults = [
    ApiFaultCodes.INVALID_PORT_IN_REQUEST_LAMBDA_ERROR
];

// If you are interested in functional programming and reduce, below will be cool.
// otherwise, it could be a bit of a head scratcher.

export const registerDefaultGlobalHandlers = reduce((faultObj, faultCodeConstant) => {
    faultObj[faultCodeConstant] = {
        faultHandler: handleGlobalFault,
        config: {
            hideBackLink: true
        }
    };
    return faultObj;
});

export const registerInvalidRequestHandlers = reduce((faultObj, faultCodeConstant) => {
    faultObj[faultCodeConstant] = {
        faultHandler: handleInvalidRequestFault
    };
    return faultObj;
});

const registerExternalProviderFaults = reduce((faultObj, faultCodeConstant) => {
    faultObj[faultCodeConstant] = {
        faultHandler: handleGlobalFault,
        config: {
            isDetailedFault: true
        }
    };
    return faultObj;
});


// we set up the accumulater to be an object passed from one handler
// setup function to the next, filling in the accumulator with the
// fault handlers of each type.
const globalHandlers = registerDefaultGlobalHandlers(__, globalCodes);
const invalidRequestHandlers = registerInvalidRequestHandlers(__, invalidRequestCodes);
const externalProviderFaultHandlers = registerExternalProviderFaults(__, externalProviderFaults);

export default compose(globalHandlers, invalidRequestHandlers, externalProviderFaultHandlers)(customFaults);
