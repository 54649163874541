import LocaleKeys from '../../../../../locales/keys';

class RemoveDiscountsController {
    constructor() {
        this.localeKeys = LocaleKeys;
        this.formLookup = {
            removeReason: LocaleKeys.SUBSCRIPTIONS.DISCOUNTS.REMOVE_REASON
        };
    }

    closeDialog() {
        this.onClose()();
    }

    onFormChange() {
        this.onEntityChange()(this.discount);
    }

    submitDialog() {
        if (this.removeDiscountForm.$valid) {
            this.onSubmit()();
        }
    }
}

export default {
    bindings: {
        discount: '<',
        discountRemoveOptions: '<',
        isLoading: '<',
        showDiscountRemove: '<',

        onClose: '&',
        onEntityChange: '&',
        onSubmit: '&'
    },
    template: require('./remove.discounts.modal.html'),
    controller: RemoveDiscountsController
};