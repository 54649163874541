import i18n from 'invision-core/src/components/i18n/i18n';
import moment from 'moment';
import pathOr from 'ramda/src/pathOr';
import {
    fetchCodeTypes,
    fetchProductRemovalReasons
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';
import CustomerLocaleKeys from '../../../../locales/keys';
import {
    BILLER_RULE_INSTANCE_TYPE,
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {CurrentCustomerIdSelector,
    CurrentCustomerSelector} from '../../../../reducers/selectors/customer.selectors';
import {
    removeProducts,
    setRemoveProductExecutionOptions
} from '../../../../reducers/actions/customer.products.actions';
import {
    CurrentProductDetailSelector
} from '../../../../reducers/selectors/customer.product.detail.selectors';
import {CandidateBillCyclesSelector} from '../../../../reducers/selectors/customer.billcycle.selectors';
import {
    GetMaxDateForFutureDatedOrder,
    IsFutureDatedOrderAllowedInBunt
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {RemoveProductExecutionOptionsSelector} from '../../../../reducers/selectors/customer.products.selectors';
import {retrieveCandidateBillCycles} from '../../../../reducers/actions/customer.billcycle.actions';
import {setReloadSubscriberStateFlag} from '../../../../reducers/actions/customer.actions';

class RemoveProductsController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            CustomerLocaleKeys,
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            executionOptionsMinDate: moment(new Date()).startOf('day').utc().toISOString(),
            $ngRedux,
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            onFdoFormValidityChange: this.onFdoFormValidityChange.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                candidateBillCycles: CandidateBillCyclesSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                executionOptions: RemoveProductExecutionOptionsSelector(store),
                fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                fdoConfiguration: MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoConfigurationLoaded: MetadataCodeLoadedSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoMaxDate: GetMaxDateForFutureDatedOrder(store),
                isDbss: IsDbss(store),
                oneTimeProductRemovalReason: MetadataOptionsForCodeValuesSelector(CODES.OneTimeProductRemovalReason, store),
                oneTimeProductRemovalReasonLoaded: MetadataCodeLoadedSelector(CODES.OneTimeProductRemovalReason, store),
                orderReason: MetadataOptionsForCodeValuesSelector(CODES.ReasonCodes, store),
                orderReasonLoaded: MetadataCodeLoadedSelector(CODES.ReasonCodes, store),
                productDetails: CurrentProductDetailSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeTypes,
            fetchProductRemovalReasons: fetchProductRemovalReasons,
            removeProducts,
            retrieveCandidateBillCycles,
            setReloadSubscriberStateFlag,
            setRemoveProductExecutionOptions
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        const promiseList = [];
        this.isLoadingCodeTypes = true;
        if (!this.state.oneTimeProductRemovalReasonLoaded) {
            promiseList.push(this.actions.fetchProductRemovalReasons());
        }
        if (!this.state.orderReasonLoaded) {
            promiseList.push(this.actions.fetchCodeTypes(CODES.ReasonCodes));
        }

        Promise.all(promiseList).then(() => {
            this.isLoadingCodeTypes = false;
            this.removeReasonOptions = this.isAdHocProduct() ? this.state.orderReason: this.state.oneTimeProductRemovalReason;
        });

        if (!this.state.fdoConfigurationLoaded) {
            this.actions.fetchCodeTypes(CODES.FutureDatedOrderConfiguration);
        }

        if (this.state.isDbss) {
            this.actions.retrieveCandidateBillCycles(this.state.currentCustomerId, subscriberOrderTypeEnum.postpaid.value).then(() => {
                this.setDefaultBillCycle();
            });
        }

        this.confirmationDialogConfig = {
            onRegisterApi: ({api}) => {
                this.confirmationDialogConfig = api;
            }
        };

        this.model = {
            ItemsToRemove: this.isAdHocProduct() ?
                this.itemsToRemove.map((item) => {
                    return {
                        ProductId: item.Product.Id,
                        LockerItemId: item.LockerItemId || item.Id,
                        PricingPlanId: item.PricingPlanId || item.PricingPlan.Id,
                        IsStandalone: item.Product.Standalone
                    };
                }) : this.itemsToRemove.map((item) => {
                    return item.LockerItemId || item.Id;
                }),
            ReasonCode: null,
            SendNotification: true
        };

        this.productToRemoveIsOneTimeBill = this.isProductToRemoveOneTimeBill();

    }

    onConfirmationDialogResponse(confirmed) {
        this.closeConfirmationDialogPopup();

        if (confirmed) {
            return this.actions.removeProducts(this.state.currentCustomerId, this.model, this.isAdHocProduct(), this.state.executionOptions.specifiedDate)
                .then(() => {
                    this.onSuccess()();
                    this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.REMOVE_PRODUCTS.SUCCESS));
                    /*
                    * TODO:
                    * PD-411357
                    * Need to identify when the subscriber has no more products left (Switches to disconnect state) to trigger below action
                    */
                    this.actions.setReloadSubscriberStateFlag(true);
                })
                .catch(() => {
                    this.uiNotificationService.error(i18n.translate(this.CustomerLocaleKeys.REMOVE_PRODUCTS.ERROR), null, {
                        timeout: NOTIFICATION_TIME_LENGTH
                    });
                });
        }
    }

    isProductToRemoveOneTimeBill() {
        if (pathOr(0, ['length'], this.itemsToRemove) < 1) {
            return false;
        }
        const bris = [];
        this.itemsToRemove.forEach((item) => {
            let brisItem =  pathOr([], ['PricingPlan', 'BillerRuleInstances'], item);
            if (brisItem && !brisItem.length) {
                brisItem = pathOr([], ['PricingPlan', 'BillerRuleInstanceThumbnails'], item);
            }
            bris.push(...brisItem);
        });

        return bris.every((item) => {
            return item.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME;
        });
    }

    isAdHocProduct() {
        return this.itemsToRemove.every((item) => {
            return item.Product.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC;
        });
    }

    closeConfirmationDialogPopup() {
        this.handleClose();
        this.confirmationDialogConfig.close();
    }

    openConfirmationDialogPopup() {
        this.confirmationDialogConfig.open();
    }

    confirmRemoval() {
        if (this.RemoveProductsForm.$valid) {
            this.hideRemoveProductReasonModal = true;
            this.openConfirmationDialogPopup();
        }
    }

    handleClose() {
        this.onClose()();
    }

    setDefaultBillCycle() {
        this.selectedBillCycle = this.state.candidateBillCycles[0];
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
    }

    onExecutionOptionChange(executionOptionsModel) {
        this.actions.setRemoveProductExecutionOptions(executionOptionsModel);
    }

    onFdoFormValidityChange(message) {
        this.fdoErrorMessageFromComponent = message;
        return message;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./remove.products.popup.html'),
    controller: RemoveProductsController,
    controllerAs: 'RemoveProducts',
    bindings: {
        itemsToRemove: '<',
        onClose: '&',
        onSuccess: '&'
    }
};
