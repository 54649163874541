import {SUBSCRIBER_ACCESS} from '../../security.attributes';

export function routing($stateProvider) {
    $stateProvider
        .state('index.customercare.welcome', {
            url: '/welcome',
            securityAttributes: [SUBSCRIBER_ACCESS],
            template: '<welcome></welcome>'
        });
}
