import {stateGo} from 'redux-ui-router';
import CustomerLocaleKeys from '../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    retrieveServiceDetails,
    searchServices,
    setSelectedServiceIdentifier,
} from '../../../../../reducers/actions/services.actions';
import {searchSubscriberOfferings} from '../../../../../reducers/actions/offering.order.actions';
import {OFFERING_STATUS_TYPES} from '../../../offerings/offerings.constants';
import {
    retrieveSubscriberOfferingDetail,
    setSelectedOfferingInstanceId,
    setServiceAndOfferWidgetSelectedTab
} from '../../../../../reducers/actions/customer.actions';
import {
    DashboardOfferingsWidgetUIFeatureToggleSelector,
    DashboardServicesWidgetUIFeatureToggleSelector,
    DashboardSharedUsageWidgetUIFeatureToggleSelector
} from '../../../../../reducers/selectors/customer.actions.template.selectors';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIdSelector,
    RouteParams,
    SelectedTabServicesAndOffersSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    BulkServicesRecordCountSelector,
    ServicesWithFormattedServiceAttributeSelector
} from '../../../../../reducers/selectors/services.selectors';
import {
    SelectedServiceIdentifierSelector,
    SelectedServiceThumbnailSelector
} from '../../../../../reducers/selectors/selected.service.details.selectors';
import {
    SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector,
    SelectedOfferingAndOptionsSelector,
    SelectedSubscriberOfferingInstanceIdSelector,
    SelectedSubscriberOfferingWithFormattedServiceSelector,
    SubsriberOfferingsPaginationDataSelector
} from '../../../../../reducers/selectors/search.subscriber.offerings.selectors';
import {
    NUMBER_OF_CAROUSEL_ITEMS_TO_DISPLAY,
    NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH,
    NUMBER_OF_ENTITLEMENTS_TO_DISPLAY,
    SERVICES_AND_OFFERS_TABS
} from './services.and.offers.contants';
import {SERVICES_USAGE_STATE_NAME} from '../../../servicesAndUsage/services.and.usage.config';
import {OFFERINGS_STATE_NAME} from '../../../offerings/offerings.config';
import {TYPE_OF_SERVICE} from '../../../servicesAndUsage/servicesAndSharedEntitlements/services/services.list.constants';
import {formattedEntitlementsSelector} from '../../../../../reducers/selectors/shared.entitlements.selectors';
import {
    retrieveSharedEntitlements,
    setSelectedSharedEntitlementIndex
} from '../../../../../reducers/actions/shared.entitlements.actions';
import {retrieveChangeOfferEligibilityRules} from '../../../../../reducers/actions/customer.offering.actions';
import {IsChangeOfferEligibilityRulesLoaded} from '../../../../../reducers/selectors/customer.offerings.selectors';
import {OfferEligibilityIdsSelector} from '../../../../../reducers/selectors/dashboard.selectors';
class ServicesAndOffersController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            fetchOffers: this.fetchOffers.bind(this),
            fetchServices: this.fetchServices.bind(this),
            isExpanded: true,
            NUMBER_OF_CAROUSEL_ITEMS_TO_DISPLAY,
            NUMBER_OF_ENTITLEMENTS_TO_DISPLAY,
            OFFERINGS_STATE_NAME,
            onTabSelected: this.onTabSelected.bind(this),
            SERVICES_AND_OFFERS_TABS,
            SERVICES_USAGE_STATE_NAME,
            tabs: [],
            uiNotificationService
        });
    }

    $onInit() {

        const controllerActions = {
            fetchCodeTypes,
            retrieveChangeOfferEligibilityRules,
            retrieveServiceDetails,
            retrieveSharedEntitlements,
            retrieveSubscriberOfferingDetail,
            searchServices,
            searchSubscriberOfferings,
            setSelectedOfferingInstanceId,
            setSelectedServiceIdentifier,
            setSelectedSharedEntitlementIndex,
            setServiceAndOfferWidgetSelectedTab,
            stateGo
        };

        const mapStateToTarget = (store) => {
            return {
                allServices: ServicesWithFormattedServiceAttributeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                isChangeOfferEligibilityRulesLoaded: IsChangeOfferEligibilityRulesLoaded(store),
                isServiceLifeCycleStatusLoaded: MetadataCodeLoadedSelector(CODES.ServiceLifeCycleStatus, store),
                isDashboardOfferingsWidgetUIFeatureEnabled: DashboardOfferingsWidgetUIFeatureToggleSelector(store),
                isDashboardServicesWidgetUIFeatureEnabled: DashboardServicesWidgetUIFeatureToggleSelector(store),
                isDashboardSharedUsageWidgetUIFeatureEnabled: DashboardSharedUsageWidgetUIFeatureToggleSelector(store),
                offeringsPaginationData: SubsriberOfferingsPaginationDataSelector(store),
                offersWithFormatedServices: SearchSubscriberOfferingsWithFormattedServiceIdentifierSelector(store),
                routeParams: RouteParams(store),
                selectedOffering: SelectedOfferingAndOptionsSelector(store),
                selectedOfferingInstanceId: SelectedSubscriberOfferingInstanceIdSelector(store),
                selectedServiceIdentifier: SelectedServiceIdentifierSelector(store),
                selectedServiceThumbnail: SelectedServiceThumbnailSelector(store),
                selectedSubscriberOfferingWithFormattedService: SelectedSubscriberOfferingWithFormattedServiceSelector(store),
                selectedTab: SelectedTabServicesAndOffersSelector(store),
                servicesRecordCount: BulkServicesRecordCountSelector(store),
                offerEligibilityIds: OfferEligibilityIdsSelector(store),
                sharedEntitlements: formattedEntitlementsSelector(store),
                subscriberCurrencyCode: CurrentCustomerCurrencyCodeSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        if (this.state.selectedTab) {
            this.tabs.map((tab) => {
                tab.active = this.state.selectedTab === tab.id;
                return tab;
            });
        }
        if (!this.state.isServiceLifeCycleStatusLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceLifeCycleStatus);
        }
        Promise.all([this.fetchServices(), this.fetchOffers(), this.fetchEntitlementBalances()]).then(() => {
            if (this.state.allServices.serviceThumbnails.length) {
                this.tabs.push({
                    id: SERVICES_AND_OFFERS_TABS.WIDGET_SERVICES,
                    name: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_AND_OFFERS_WIDGET.TABS.SERVICES),
                    active: false
                });
            }
            if (this.state.offersWithFormatedServices.length) {
                this.tabs.push({
                    id: SERVICES_AND_OFFERS_TABS.WIDGET_OFFERS,
                    name: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_AND_OFFERS_WIDGET.TABS.OFFERS),
                    active: false
                });
            }
            if (this.state.sharedEntitlements.length) {
                this.tabs.push({
                    id: SERVICES_AND_OFFERS_TABS.WIDGET_SHARED_USAGE,
                    name: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.SERVICES_AND_OFFERS_WIDGET.TABS.SHARED_USAGE),
                    active: false
                });
            }
            if (this.tabs.length) {
                this.tabs[0].active = true;
                this.onTabSelected(this.tabs[0]);
            }
        });

        if (!this.state.isChangeOfferEligibilityRulesLoaded && this.state.offerEligibilityIds && this.state.offerEligibilityIds.length) {
            this.actions.retrieveChangeOfferEligibilityRules(this.state.currentCustomerId, this.state.offerEligibilityIds);
        }

    }

    onTabSelected(selectedTab) {
        this.actions.setServiceAndOfferWidgetSelectedTab(selectedTab.id);
        this.tabs = this.tabs.map((tab) => {
            tab.active = selectedTab.id === tab.id;
            return tab;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    fetchServices() {
        const params = Object.assign({}, this.state.routeParams, {
            serviceType: TYPE_OF_SERVICE.NON_BULK,
            pageNumber: 1,
            pageSize: NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH
        });

        return this.actions.searchServices(params).catch((error) => {
            return this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    fetchOffers() {
        const params = Object.assign({}, this.state.routeParams, {
            includeOfferingTotals: true,
            pageNumber: 1,
            pageSize: NUMBER_OF_CAROUSEL_ITEMS_TO_FETCH,
            status: [OFFERING_STATUS_TYPES.ACTIVE,
                OFFERING_STATUS_TYPES.PENDING_ACTIVE,
                OFFERING_STATUS_TYPES.PENDING_REMOVED,
                OFFERING_STATUS_TYPES.SUSPENDED,
                OFFERING_STATUS_TYPES.SUSPENSION_SCHEDULED]
        });

        return this.actions.searchSubscriberOfferings(params).then(() => {
            if (this.state.offersWithFormatedServices.length) {
                return this.actions.setSelectedOfferingInstanceId(this.state.offersWithFormatedServices[0].offerDetails.OfferingInstanceId);
            }
        }).catch((error) => {
            return this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    fetchEntitlementBalances() {
        return this.actions.retrieveSharedEntitlements(this.state.currentCustomerId).catch((error) => {
            return this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    fetchServiceDetails() {
        if (this.state.selectedServiceIdentifier) {
            const serviceIdentifiers = [{
                Value: this.state.selectedServiceIdentifier
            }];
            this.actions.retrieveServiceDetails(this.state.currentCustomerId, serviceIdentifiers).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    refreshServices() {
        this.fetchServices();
        this.fetchServiceDetails();
    }

    handleServiceSelected(service) {
        this.actions.setSelectedServiceIdentifier(service.ServiceIdentifier);
    }

    handleOfferingSelected(offer) {
        this.actions.setSelectedOfferingInstanceId(offer.offerDetails.OfferingInstanceId);
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    onRefreshConvergentBillerDetails() {
        this.refreshConvergentBillerDetails && this.refreshConvergentBillerDetails();
    }
}

export default {
    bindings: {
        displayOfferingsWidget: '<?',
        displayServicesAndOfferingsWidget: '<?',
        displayServicesWidget: '<?',
        refreshConvergentBillerDetails: '&?'
    },
    template: require('./services.and.offers.html'),
    controller: ServicesAndOffersController
};
