import {
    BusinessUnitSelectors,
    CoreLocaleKeys,
    LogHelper,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    i18n
} from 'invision-core';
import pathOr from 'ramda/src/pathOr';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    IsUpdatingAddressSelector,
    PostalCodeSelector,
    PreventOverrideFlagSelector,
    PreventRemovalOfDefaultTypesSelector,
    UpdateAddressErrorSelector,
    UpdateAddressPopupStateRegionProvinceValueOptions
} from '../../../../reducers/selectors/customer.addresses.selectors';
import {
    setAddressData,
    updateAddress,
    updateFormAddress,
    updateOverrideFlag
} from '../../../../reducers/actions/customer.addresses.actions';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../addresses.constants';
import {isAddressValidFromAddressResponse} from '../../../shared/contextualComponents/address/address.validator.helper';
import {
    IsValidatingStatusSelector,
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../reducers/selectors/address.component.selectors';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../reducers/actions/address.component.actions';
import {invalidateCreditClassification} from '../../../../reducers/actions/customer.actions';
import {performCreditCheck} from '../../../../reducers/actions/credit.check.actions';
import {stateRequiredHelper} from './../address.helper';

function UpdateAddressPopupController($ngRedux, $timeout, uiNotificationService) {
    let popupApi = null;
    const mapStateToTarget = (store) => {
        return {
            addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
            addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
            addressRequirements: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressRequirements, store),
            addressRequirementsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressRequirements, store),
            addressStatesOptions: UpdateAddressPopupStateRegionProvinceValueOptions(store),
            isAddressBeingValidatedFromApi: IsValidatingStatusSelector(store),
            isDbss: BusinessUnitSelectors.IsDbss(store),
            isUpdatingAddress: IsUpdatingAddressSelector(store),
            lastAttemptError: UpdateAddressErrorSelector(store),
            postalCode: PostalCodeSelector(store),
            preventOverrideFlag: PreventOverrideFlagSelector(store),
            preventRemovalOfDefaultTypes: PreventRemovalOfDefaultTypesSelector(store),
            unvalidatedAddresses: UnvalidatedAddressesSelector(store),
            validatedAddresses: ValidatedAddressesSelector(store)
        };
    };
    const controllerActions = {
        fetchAddressCountryTypes: MetadataActions.codes.fetchAddressCountryTypes,
        fetchAddressStateTypes: MetadataActions.codes.fetchAddressStateTypes,
        fetchCodeType: MetadataActions.codes.fetchCodeTypes,
        invalidateCreditClassification,
        performCreditCheck,
        setAddressData,
        setForceShowEditFormAddress,
        updateAddress,
        updateFormAddress,
        updateOverrideFlag,
        validateAddress
    };
    this.$onInit = () => {
        this.resetForm();
        this.showForm = false;

        this.disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchAddressStateTypes();
        }

        this.formMaxLengthValidation = ADDRESS_FORM_FIELD_MAX_LENGTH;

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.onAddressFormChanged = this.onAddressFormChanged.bind(this);

        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.resetForm();
                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };
        if (!this.state.addressRequirementsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressRequirements);
        }
    };
    this.handleCloseDialog = () => {
        this.closePopup();
    };
    this.handleKeypressForm = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmitForm();
        }
    };
    this.handleSubmitForm = () => {
        this.formController.$submitted = true;
        this.formController.highlightErrorMessages();

        if (this.formController.$valid) {

            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        this.actions.updateAddress(
                            this.customerId,
                            this.formAddress,
                            this.textScrubberOverride,
                            this.state.preventOverrideFlag
                        ).then((result) => {
                            this.closePopup();
                            const onAddressUpdated = this.onAddressUpdated();
                            onAddressUpdated && onAddressUpdated(result.Address);
                            this.actions.performCreditCheck(null, this.customerId);
                            this.actions.invalidateCreditClassification();
                            this.actions.updateOverrideFlag(null);
                        });
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        uiNotificationService.transientError(
                            i18n.translate(this.CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                        );
                    }
                }).catch((err) => {
                    uiNotificationService.transientError(err.translatedMessage);
                });
        }
    };
    this.handleClickCancelButton = () => {
        this.closePopup();
    };
    this.handleChangeFormCountry = () => {
        this.updateStateField();
    };
    this.closePopup = () => {
        if (this.isUpdatingAddress) {
            // app is going to crash; log a User-friendly message for the developer
            LogHelper.debug('closing popup while awaiting async operation');
        }
        popupApi.close();
        this.showForm = false;
    };
    this.resetForm = () => {
        this.formAddress = Object.assign({}, this.address);
        this.addressFormModel = {
            shipToName: this.address ? this.address.ShipToName : null,
            addressLine1: this.address ? this.address.LineOne : null,
            addressLine2: this.address ? this.address.LineTwo : null,
            city: this.address ? this.address.City : null,
            country: this.address ? this.address.Country : null,
            stateRegionProvince: this.address ? this.address.State : null,
            postalCode: this.address ? this.address.PostalCode : null
        };
        this.textScrubberOverride = undefined;
        // called before $ngRedux.connect, must guard
        this.actions && this.state && this.updateStateField();
    };
    this.updateStateField = () => {
        this.actions.setAddressData(this.formAddress, this.textScrubberOverride);
        this.actions.updateFormAddress(this.formAddress);

        this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);

        if (this.state.addressStatesOptions && !this.state.addressStatesOptions.find((stateOption) => {
            return stateOption.value === this.formAddress.State;
        })) {
            this.formAddress.State = null;
            this.addressFormModel.stateRegionProvince = this.formAddress.State;
        }
    };
    this.validateAddressDefaults = () => {
        this.actions.updateFormAddress(this.formAddress);
    };
    this.showDescription = () => {
        const prevent = this.state.preventRemovalOfDefaultTypes;
        return prevent.service || prevent.home || prevent.postal || prevent.work;
    };
    this.onAddressFormChanged = (addressFormModel) => {
        /*
            Check if the addressForm country field changed if
            then trigger handleChangeForm route
        */
        this.formAddress.LineOne = addressFormModel.addressLine1;
        this.formAddress.LineTwo = addressFormModel.addressLine2;
        this.formAddress.City = addressFormModel.city;
        this.formAddress.State = addressFormModel.stateRegionProvince;
        this.formAddress.PostalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
        this.formAddress.ShipToName = addressFormModel.shipToName;

        Object.assign(this.addressFormModel, addressFormModel);
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;

        if (addressFormModel.country !== this.formAddress.Country) {
            this.formAddress.Country = addressFormModel.country;
            this.handleChangeFormCountry();
        }
    };
    this.$onDestroy = () => {
        this.disconnectRedux();
    };

    this.getAddressKey = () => {
        return pathOr(undefined, ['formController', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    };

    this.getAddressKeyForAddressValidatedThroughAPI = () => {
        return pathOr(undefined, ['formController', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    };

    this.getJobToken = () => {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    };

    this.validateAddressWithAPI = () => {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey];

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            const addressTypes = {
                DefaultBilling: this.formAddress.DefaultBilling,
                DefaultHome: this.formAddress.DefaultHome,
                DefaultPostal: this.formAddress.DefaultPostal,
                DefaultService: this.formAddress.DefaultService,
                DefaultShipping: this.formAddress.DefaultShipping,
                DefaultWork: this.formAddress.DefaultWork
            };
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    };
}

export default {
    template: require('./update.address.popup.html'),
    bindings: {
        address: '<',
        config: '<',
        customerId: '<',
        onAddressUpdated: '&',
        shipToNameRequired: '<'
    },
    controllerAs: 'UpdateAddressPopupController',
    controller: UpdateAddressPopupController
};
