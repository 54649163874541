import {DASHBOARD_ROUTE} from '../../../reducers/constants/dashboard.constants';
import {setCustomerAutoPayBannerFlag} from '../../../reducers/actions/customer.account.status.actions';

export function routing($stateProvider) {
    $stateProvider.state(DASHBOARD_ROUTE, {
        url: '/dashboard',
        template: '<dashboard></dashboard>',
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(setCustomerAutoPayBannerFlag(false));
        }
    }).state('index.customercare.customer.accountDashboard', {
        url: '/dashboard/:accountNumber',
        template: '<dashboard></dashboard>',
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(setCustomerAutoPayBannerFlag(false));
        }
    });
}
