import {NUMBERS_ONLY_OR_BLANK} from 'invision-core/src/constants/validation.constants';

export function validateNumbersOnly(e) {
    const allowedKeys = [9, 40, 38, 37, 39, 8, 46];

    const reg = NUMBERS_ONLY_OR_BLANK;
    if (!reg.test(e.key)) {
        if (allowedKeys.indexOf(e.keyCode) < 0) {
            e.preventDefault();
        }
    }
}
