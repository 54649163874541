import {FormHelper,
    i18n,
    UnsavedChangesPromptActions} from 'invision-core';
import {stateReload} from 'redux-ui-router';
import pathOr from 'ramda/src/pathOr';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    CurrentHouseholdSelector,
    HouseholdErrorSelector,
    IsCreatingHouseholdMemberSelector,
    SelectedHouseholdMemberIdSelector,
    SelectedHouseholdMemberSelector
} from '../../../../reducers/selectors/customer.household.selectors';
import {
    CreateCustomerStateRegionProvinceValueOptions,
    CreateChildCustomerSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerAccountingMethodSelector,
    EnableProspectLiteForHouseholdSelector,
    SelectedCustomerSubtenantSelector
} from '../../../../reducers/selectors/customer.selectors';
import {getErrorLookupObject} from '../../../createCustomer/create.customer.constants';
import {
    clearCreateEditCustomerForm,
    createEditCustomerFormChanged
} from '../../../../reducers/actions/customer.actions';
import {createHouseholdMember} from '../../../../reducers/actions/customer.household.actions';
import {apFormHasErrors} from '../../../main/customercare.page.helper';
import {isAddressValidFromAddressResponse} from '../../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../reducers/actions/address.component.actions';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../reducers/selectors/address.component.selectors';
import {FormattedEditCustomerSubscriberIdentitiesSelector} from '../../../../reducers/selectors/customer.information.selectors';

function CreateMemberPopupController($ngRedux, $timeout) {
    let disconnectRedux;
    let popupApi = null;

    const mapStateToTarget = (store) => {
        return {
            customerId: CurrentCustomerIdSelector(store),
            currentCustomerAccountingMethod: CurrentCustomerAccountingMethodSelector(store),
            driversLicenseStateOptions: CreateCustomerStateRegionProvinceValueOptions(store),
            enableProspectLiteForHousehold: EnableProspectLiteForHouseholdSelector(store),
            household: CurrentHouseholdSelector(store),
            formattedEditCustomerSubscriberIdentities: FormattedEditCustomerSubscriberIdentitiesSelector(store),
            isCreatingHouseholdMember: IsCreatingHouseholdMemberSelector(store),
            lastAttemptError: HouseholdErrorSelector(store),
            newMember: CreateChildCustomerSelector(store),
            selectedCustomerSubtenant: SelectedCustomerSubtenantSelector(store),
            selectedMember: SelectedHouseholdMemberSelector(store),
            selectedMemberId: SelectedHouseholdMemberIdSelector(store),
            unvalidatedAddresses: UnvalidatedAddressesSelector(store),
            validatedAddresses: ValidatedAddressesSelector(store)
        };
    };

    const controllerActions = {
        clearCreateEditCustomerForm,
        createEditCustomerFormChanged,
        createHouseholdMember,
        registerUnsavedChanges: UnsavedChangesPromptActions.registerUnsavedChanges,
        unregisterUnsavedChanges: UnsavedChangesPromptActions.unregisterUnsavedChanges,
        setForceShowEditFormAddress,
        stateReload: stateReload,
        validateAddress
    };

    this.$onInit = () => {
        this.showForm = false;
        this.formErrors = [];
        this.apErrors = [];

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareKeys = CustomerCareLocaleKeys;
        this.createSuccessMessage = i18n.translate(this.customerCareKeys.HOUSEHOLD.CREATE_MEMBER_SUCCESS);

        //Clear the form on init
        this.actions.clearCreateEditCustomerForm();
        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.showForm = true;
                    const newMemberForInitialization = this.state.newMember;
                    newMemberForInitialization.SubscriberTypeDetails.AccountingMethod = this.state.currentCustomerAccountingMethod;
                    this.actions.createEditCustomerFormChanged(newMemberForInitialization);
                    this.actions.registerUnsavedChanges('createCustomerForm', {
                        isDirty: () => {
                            return this.createMemberFormWrapper && this.createMemberFormWrapper.createCustomerForm ?
                                this.createMemberFormWrapper.createCustomerForm.$dirty : false;
                        },
                        setPristine: () => {
                            if (this.createMemberFormWrapper && this.createMemberFormWrapper.createCustomerForm) {
                                this.createMemberFormWrapper.createCustomerForm.$setPristine();
                            }
                        }
                    });

                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };
    };

    this.onFormChanged = (newMember) => {
        this.actions.createEditCustomerFormChanged(newMember);
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.onSubmit = (form) => {
        if (!this.checkForErrors(form.$error)) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        this.actions.createHouseholdMember(this.state.newMember, {
                            HouseholdId : this.state.household.Id
                        })
                            .then(() => {
                                this.successCallback(this.createSuccessMessage);
                            }).then(() => {
                                this.actions.stateReload();
                            })
                            .catch(this.errorCallback);
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        this.formErrors = [{
                        //translate and also get guidance from the doc writer
                            translatedMessage: i18n.translate(this.CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                        }];
                    }
                }).catch(this.errorCallback);
        }
    };

    this.errorCallback = () => {
        this.formErrors = [this.state.lastAttemptError.message];
    };

    this.successCallback = (message) => {
        this.closePopup();
        this.actions.unregisterUnsavedChanges('createCustomerForm');

        const onMemberCreated = this.onMemberCreated;
        onMemberCreated && onMemberCreated()(message, this.state.selectedMemberId);

        this.createMemberFormWrapper.$setPristine();
        this.createMemberFormWrapper.createCustomerForm.$setPristine();
    };

    this.checkForErrors = ngFormError => {
        this.handleApErrors();
        const errorModelList = FormHelper.formHasErrors(ngFormError, getErrorLookupObject());
        this.formErrors = errorModelList.concat(this.apErrors);
        return this.formErrors.length > 0;
    };

    this.handleApErrors = () => {
        const apErrors = this.createMemberFormWrapper.createCustomerForm.addlApi ? this.createMemberFormWrapper.createCustomerForm.addlApi.$error : null;
        this.apErrors = apFormHasErrors(apErrors);
    };

    this.closePopup = () => {
        if (!this.state.isCreatingHouseholdMember) {
            this.actions.clearCreateEditCustomerForm();
            this.formErrors = [];
            popupApi.close();
            this.showForm = false;
        }
    };

    this.getAddressKey = () => {
        return pathOr(undefined, ['createMemberFormWrapper', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    };

    this.getAddressKeyForAddressValidatedThroughAPI = () => {
        return pathOr(undefined, ['createMemberFormWrapper', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    };

    this.getJobToken = () => {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    };

    this.validateAddressWithAPI = () => {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    };
}

export default {
    template: require('./create.member.popup.html'),
    bindings: {
        config: '<',
        customerId: '<',
        onMemberCreated: '&',
        overrideValidations: '<?'
    },
    controllerAs: 'CreateMemberPopup',
    controller: CreateMemberPopupController
};
