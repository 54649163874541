import {i18n} from 'invision-core';
import LocaleKeys from '../../../locales/keys';

class GiftCardActivationListController {

    constructor() {
        this.CustomerCareLocaleKeys = LocaleKeys;
        this.viewModel = {
            shouldShowActivationList: false
        };
    }

    $onInit() {
        const columnDefs = [
            {
                field: 'Notified',
                displayName: i18n.translate(LocaleKeys.GIFT_CARD_DETAILS.SEND_DATE),
                width: 150,
                cellFilter: 'date:"short"'
            },
            {
                field: 'SenderEmail',
                displayName: i18n.translate(LocaleKeys.GIFT_CARD_DETAILS.SENDER_EMAIL),
                minWidth: 150
            },
            {
                field: 'RecipientName',
                displayName: i18n.translate(LocaleKeys.GIFT_CARD_DETAILS.RECIPIENT_NAME),
                minWidth: 150
            },
            {
                field: 'RecipientEmail',
                displayName: i18n.translate(LocaleKeys.GIFT_CARD_DETAILS.RECIPIENT_EMAIL),
                minWidth: 150
            }
        ];
        this.tableData = {
            columnDefs: columnDefs,
            data: this.activationList
        };
    }

    $onChanges(changes) {
        if (changes.activationList) {
            this.viewModel.shouldShowActivationList = this.activationList && this.activationList.length > 0;
        }
    }
}

export default {
    bindings: {
        activationList: '<'
    },
    template: require('./gift.card.activation.list.html'),
    controller: GiftCardActivationListController
};
