import clone from 'ramda/src/clone';
import find from 'ramda/src/find';
import isNil from 'ramda/src/isNil';
import map from 'ramda/src/map';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import propEq from 'ramda/src/propEq';
import propOr from 'ramda/src/propOr';
import reject from 'ramda/src/reject';
import LocaleKeys from '../../../locales/keys';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../customer/ewallet/ewallet.constants';
import {
    CoreLocaleKeys,
    CreditCardFieldNames,
    i18n,
    FormHelper,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {
    createAddress,
    retrieveCustomerAddresses,
    setAddressData,
    updateAddress
} from '../../../reducers/actions/customer.addresses.actions';
import {PostalCodeSelector} from '../../../reducers/selectors/customer.addresses.selectors';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    IsAccountNumberEnabledSelector,
    PaymentInstrumentTypeValueOptions,
    PaymentInstrumentTypeErrorsSelector,
    SelectedPaymentTypeRequiresAddress,
    SubscriberExternalGiftCardTypeOptionsWithRegexes
} from '../../../reducers/selectors/modify.payment.method.popup.selectors';
import {
    EditAddressPopupStateRegionProvinceValueOptions,
    MakePaymentAddressSelector,
    MakePaymentAddressesSelector
} from '../../customer/makePayment/make.payment.selectors';
import {
    setMakePaymentInstrumentBillingAddress,
    setMakePaymentAddressStateValue,
    setMakePaymentInstrument
} from '../../../reducers/actions/make.payment.actions';
import {
    createPaymentInstrument,
    editPaymentInstrument,
    redeemGiftCard,
    setRecordPaymentInstrument
} from '../../../reducers/actions/customer.ewallet.actions';
import {
    fetchCodeTypesFromConfiguration
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    EwalletErrorsSelector,
    NewPaymentMethodWithTypeNamesAndMetadataViewModelSelector,
    PaymentInstrumentTypeValueOptionsWithAutoPay,
    PaymentIsCreatingOrEditingDataSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {
    BLANK_PAYMENT_INSTRUMENT,
    NO_FORM_ERRORS
} from '../../customer/makePayment/make.payment.constants';
import {
    addressStateRegionProvinceValueOptionsForCountry
} from '../../../reducers/helpers/customer.selectors.helpers';
import {buildPaymentInstrumentRequest} from '../../paymentBillingAddressForm/payment.billing.address.form.helper';
import {
    parseAdditionalProperties,
    PaymentInstrumentConfigurationAdditionalProperties,
    paymentInstrumentConfigurationAdditionalPropertyDescriptors
} from '../../../reducers/helpers/payment.instrument.additional.property.parser';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../../customer/addresses/addresses.constants';
import {
    InvisionConfigurationAdditionalPropertiesSelector
} from '../../../reducers/selectors/configuration.invision.selectors';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../reducers/actions/address.component.actions';
import {stateRequiredHelper} from '../../customer/addresses/address.helper';

const BILLING_ADDRESS_ERRORS_KEY = 'BILLING_ADDRESS_ERRORS_KEY';
const ENTER_KEY = 13;

export class ModifyPaymentMethodPopupController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            addressFormModel: {},
            uiNotificationService,
            editPaymentInstrument: clone(BLANK_PAYMENT_INSTRUMENT),
            formErrors: NO_FORM_ERRORS,
            savePaymentInstrument: true,
            formNameMap: new Map([
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, 'creditCardController.creditCardForm'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD, 'giftCardController.giftCardForm'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD, 'externalGiftCardController.externalGiftCardForm'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL, '$ctrl.externalBillForm'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK, 'eCheckController.echeckForm']
            ]),
            CHANGE_CARD_TYPE: 'cardType',
            objectAttributeMap: new Map([
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD, 'CreditCard'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD, 'ExternalGiftCard'],
                [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK, 'ECheck']
            ]),
            paymentInstrumentAttributes: [
                'BillingAddress',
                'BillingAddressId',
                'Id',
                'Name',
                'Type'
            ],
            instrumentAttributesToKeep: [],
            errorsMap: new Map([
                [
                    SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD,
                    {
                        [CreditCardFieldNames.CC_NUMBER]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.CARD_NUMBER_REQUIRED)
                        },
                        [CreditCardFieldNames.CC_TYPE]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.CARD_TYPE_REQUIRED)
                        },
                        [CreditCardFieldNames.CC_CVV]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.CVV_REQUIRED)
                        },
                        [CreditCardFieldNames.CC_EXP_MONTH]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXPIRATION_MONTH_REQUIRED)
                        },
                        [CreditCardFieldNames.CC_EXP_YEAR]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXPIRATION_YEAR_REQUIRED)
                        },
                        [CreditCardFieldNames.CC_NAME]: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.NAME_ON_CARD_REQUIRED)
                        }
                    }
                ], [
                    SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD,
                    {
                        cardPin: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.GIFT_CARD_PIN_REQUIRED)
                        }
                    }
                ], [
                    SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD,
                    {
                        accountNumber: {
                            pattern: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_GIFT_CARD.ACCOUNT_NUMBER_INVALID_PATTERN),
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_GIFT_CARD.ACCOUNT_NUMBER_REQUIRED)
                        },
                        authKey: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_GIFT_CARD.AUTH_KEY_REQUIRED)
                        },
                        cardType: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_GIFT_CARD.CARD_TYPE_REQUIRED)
                        }
                    }
                ], [
                    SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL,
                    {
                        accountNumber: {
                            pattern: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.ACCOUNT_NUMBER_INVALID_PATTERN),
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.ACCOUNT_NUMBER_REQUIRED)
                        },
                        authorizationChallenge: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.AUTHORIZATION_CHALLENGE_REQUIRED)
                        },
                        nameOnAccount: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.NAME_ON_ACCOUNT_REQUIRED)
                        },
                        phoneNumber: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.PHONE_NUMBER_REQUIRED)
                        },
                        expiration: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.EXTERNAL_BILL.EXPIRATION_INVALID_DATE)
                        }
                    }
                ], [
                    BILLING_ADDRESS_ERRORS_KEY,
                    {
                        address_line_1: {
                            required: i18n.translate(LocaleKeys.ADDRESS_LINE_1_REQUIRED),
                        },
                        city: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.CITY_REQUIRED)
                        },
                        country: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.COUNTRY_REQUIRED)
                        },
                        postal_code: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.POSTAL_CODE_REQUIRED)
                        },
                        state_region_province: {
                            required: i18n.translate(LocaleKeys.MAKE_PAYMENT.ERRORS.STATE_REGION_PROVINCE_REQUIRED)
                        }
                    }
                ]
            ]),
            onAddressFormChanged: this.onAddressFormChanged.bind(this)
        });


    }

    $onInit() {
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.LocaleKeys = LocaleKeys;
        this.PaymentTypes = SUPPORTED_PAYMENT_INSTRUMENT_TYPES;
        this.resetStateFlag = true;
        this.loadingIndicator = false;
        this.savePaymentInstrument = true;

        const mapStateToTarget = (store) => {
            return {
                addressCountriesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
                addressCountriesOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.AddressCountry, store),
                addressRequirements: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressRequirements, store),
                addressRequirementsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressRequirements, store),
                addressStatesAll: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                addressStatesOptions: EditAddressPopupStateRegionProvinceValueOptions(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                creditCardTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.CreditCardType, store),
                eWalletError: EwalletErrorsSelector(store),
                externalBillTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ExternalBillType, store),
                externalBillTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalBillType, store),
                externalGiftCardTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ExternalGiftCardType, store),
                externalGiftCardTypes: SubscriberExternalGiftCardTypeOptionsWithRegexes(store),
                giftCardConfigurationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.GiftCardConfiguration, store),
                isAccountNumberEnabled: IsAccountNumberEnabledSelector(store),
                isCreatingOrEditingData: PaymentIsCreatingOrEditingDataSelector(store),
                invisionConfiguration: InvisionConfigurationAdditionalPropertiesSelector(store),
                invisionConfigurationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.InvisionConfiguration, store),
                makePaymentAddress: MakePaymentAddressSelector(store),
                makePaymentAddresses: MakePaymentAddressesSelector(store),
                newPaymentMethodWithMetadata: NewPaymentMethodWithTypeNamesAndMetadataViewModelSelector(store),
                paymentInstrumentConfiguration: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentConfigurationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentTypes: PaymentInstrumentTypeValueOptions(store),
                paymentInstrumentTypesErrors: PaymentInstrumentTypeErrorsSelector(store),
                paymentInstrumentAutoPayTypes: PaymentInstrumentTypeValueOptionsWithAutoPay(store),
                paymentInstrumentTypeLoaded: MetadataSelectors.codes.PaymentInstrumentTypeLoadedSelector(store),
                paymentTypeRequiresAddress: SelectedPaymentTypeRequiresAddress(store),
                postalCode: PostalCodeSelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
            };
        };

        const controllerActions = {
            createAddress,
            createPaymentInstrument,
            editPaymentInstrument,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            fetchCodeTypesFromConfiguration,
            redeemGiftCard,
            retrieveCustomerAddresses,
            setAddressData,
            setForceShowEditFormAddress,
            setMakePaymentAddressStateValue,
            setMakePaymentInstrumentBillingAddress,
            setMakePaymentInstrument,
            setRecordPaymentInstrument,
            updateAddress,
            validateAddress
        };

        this.formMaxLengthValidation = ADDRESS_FORM_FIELD_MAX_LENGTH;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressCountry);
        }

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressStateProvinceRegion);
        }

        if (!this.state.creditCardTypes.length) {
            this.actions.fetchCodeType(MetadataConstants.codes.CreditCardType);
        }

        if (!this.state.paymentInstrumentTypeLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentType);
        }

        if (!this.state.addressRequirementsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressRequirements);
        }

        if (!this.state.invisionConfigurationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.InvisionConfiguration);
        }

        if (!this.state.externalBillTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalBillType);
        }

        if (!this.state.externalGiftCardTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.ExternalGiftCardType);
        }

        if (!this.state.paymentInstrumentConfigurationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentConfiguration);
        }

        if (!this.state.giftCardConfigurationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.GiftCardConfiguration);
        }

        this.retrieveCustomerAddresses = () => {
            this.actions.retrieveCustomerAddresses(this.state.currentCustomerId, true);
        };

        this.retrieveCustomerAddresses();

        this.getErrorLookupObject = (typeCode) => {
            return this.errorsMap.get(typeCode) || {};
        };

        const setUpDialogKeys = () => {
            this.dialogTextKeys = {
                edit: {
                    title: this.LocaleKeys.MAKE_PAYMENT.EDIT_PAYMENT_TITLE,
                    callToAction: this.LocaleKeys.MAKE_PAYMENT.EDIT_PAYMENT_BUTTON

                },
                new: {
                    title: this.LocaleKeys.MAKE_PAYMENT.ADD_NEW_PAYMENT_METHOD,
                    callToAction: this.LocaleKeys.MAKE_PAYMENT.ADD_METHOD
                }
            };
        };

        this.errorLookup = this.getErrorLookupObject(this.editPaymentInstrument.Type);

        setUpDialogKeys();

        this.executeNewEditPaymentMethod = () => {
            this.paymentInstrumentForm.$submitted = true;
            this.paymentInstrumentForm.highlightErrorMessages();

            if (this.paymentInstrumentForm.$valid) {
                const addressKey = this.getAddressKey();
                this.validateAddressWithAPI(addressKey)
                    .then(() => {
                        const validatedAddress = this.state.validatedAddresses[addressKey];
                        if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                            this.formErrors = NO_FORM_ERRORS;
                            const requestData = {
                                paymentTypeRequiresAddress: this.state.paymentTypeRequiresAddress,
                                editPaymentInstrument: this.editPaymentInstrument,
                                makePaymentAddress: this.state.makePaymentAddress,
                                creditCardTypes: this.state.creditCardTypes,
                                externalBillTypes: this.state.externalBillTypes,
                                externalGiftCardTypes: this.state.externalGiftCardTypes,
                                paymentInstrumentTypes: this.state.paymentInstrumentTypes,
                                currentCustomerId: this.state.currentCustomerId
                            };
                            this.isEditingPaymentInstrument ? this.editPaymentMethod(requestData) : this.addPaymentMethod(requestData);
                        } else {
                            this.actions.setForceShowEditFormAddress(false);
                            this.uiNotificationService.transientError(i18n.translate(this.LocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                        }
                    })
                    .catch((err) => {
                        this.uiNotificationService.transientError(err.translatedMessage);
                    });
            } else {
                this.checkForErrors();
            }
        };

        this.submitIfEnter = (event) => {
            if (event.which === ENTER_KEY) {
                event.preventDefault();
                this.executeNewEditPaymentMethod();
            }
        };

        this.addPaymentMethod = (requestData) => {
            if (this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD) {
                this.actions.redeemGiftCard(this.editPaymentInstrument.GiftCard.pin, this.state.currentCustomerId, this.editPaymentInstrument.GiftCard.accountNumber).then((redeemedGiftCard) => {
                    this.onSuccessfulSubmit(i18n.translate(LocaleKeys.E_WALLET.REDEEM_GIFT_CARD_SUCCESS), redeemedGiftCard.GiftCard);
                });

            } else if (this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD ||
                this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK ||
                this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL ||
                this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD) {
                if (!this.savePaymentInstrument) {
                    return this.onSuccessfulSubmit(i18n.translate(LocaleKeys.MAKE_PAYMENT.CREATE_INSTRUMENT_SUCCESS),
                        buildPaymentInstrumentRequest(true, requestData, this.savePaymentInstrument).request.PaymentInstrument, false);
                } else {
                    return this.actions.createPaymentInstrument(buildPaymentInstrumentRequest(true, requestData))
                        .then((createdPaymentInstrument) => {
                            this.onSuccessfulSubmit(i18n.translate(LocaleKeys.MAKE_PAYMENT.CREATE_INSTRUMENT_SUCCESS),
                                createdPaymentInstrument.PaymentInstrument);
                        })
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }

            }
        };

        this.handleSavePaymentInstrumentChanged = () => {
            this.actions.setRecordPaymentInstrument(this.savePaymentInstrument);
        };

        this.editPaymentMethod = (requestData) => {
            return this.actions.editPaymentInstrument(buildPaymentInstrumentRequest(false, requestData)).then((editedPaymentInstrument) => {
                this.onSuccessfulSubmit(i18n.translate(LocaleKeys.MAKE_PAYMENT.EDIT_INSTRUMENT_SUCCESS), editedPaymentInstrument.PaymentInstrument);
            });
        };

        this.onSuccessfulSubmit = (successMessageToToast, rawPaymentInstrumentForCallback, showToast = true) => {
            this.retrieveCustomerAddresses();
            if (showToast) {
                this.uiNotificationService.success(successMessageToToast, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }

            if (rawPaymentInstrumentForCallback.CreditCard && this.savePaymentInstrument) {
                delete rawPaymentInstrumentForCallback.CreditCard.Cvv;
            }

            this.resetForm();
            this.onSuccess()(rawPaymentInstrumentForCallback);
        };

        this.checkForErrors = () => {
            this.gatherAndSetFormErrors();
            return this.formErrors.length > 0;
        };

        this.resetForm = () => {
            this.editPaymentInstrument = clone(BLANK_PAYMENT_INSTRUMENT);
            this.addressFormModel = {};

            if (this.paymentInstrumentForm) {
                this.paymentInstrumentForm.$setPristine();
                this.paymentInstrumentForm.$setUntouched();
            }

            this.formErrors = NO_FORM_ERRORS;
        };

        this.gatherAndSetFormErrors = () => {
            //the payment method for errors have to be gathered by accessing the sub ng-forms
            const formName = this.formNameMap.get(this.editPaymentInstrument.Type);
            const formCardErrors = FormHelper.formHasErrors(this.paymentInstrumentForm[formName].$error,
                this.getErrorLookupObject(this.editPaymentInstrument.Type));

            const paymentInstrumentFormClone = clone(this.paymentInstrumentForm.$error);

            const billingErrors = FormHelper.formHasErrors(paymentInstrumentFormClone,
                this.getErrorLookupObject(BILLING_ADDRESS_ERRORS_KEY));

            //we need to filter out descriptions that are blank from the error list FormHelper returns
            this.formErrors = formCardErrors.concat(billingErrors).filter((message) => {
                return !!message.description;
            });
        };

        this.getErrorMessageForBillingAddressField = (formField) => {
            const errorsForField = [];
            const formFieldErrorLookup = this.getErrorLookupObject(BILLING_ADDRESS_ERRORS_KEY)[formField.$name];

            Object.keys(formField.$error).forEach((errorKey) => {
                errorsForField.push(formFieldErrorLookup[errorKey]);
            });

            return errorsForField;
        };

        this.shouldShowNewEditLoading = () => {
            return (this.state.isCreatingOrEditingData || this.loadingIndicator);
        };

        this.showHeaderErrorMessages = () => {
            return !this.paymentInstrumentForm.$submitted && this.state.paymentInstrumentTypesErrors && this.state.paymentInstrumentTypesErrors.length > 0;
        };

        this.transformAdditionalProperties = (additionalProperty) => {
            const baseObject = new PaymentInstrumentConfigurationAdditionalProperties();
            return parseAdditionalProperties(baseObject, additionalProperty.AdditionalProperties, paymentInstrumentConfigurationAdditionalPropertyDescriptors);
        };

        this.shouldRequirePhoneNumber = (instrumentType) => {
            return pipe(
                reject(propEq(true, 'Global')),
                map(this.transformAdditionalProperties),
                find(propEq(instrumentType, 'paymentInstrumentTypeCode')),
                propOr(false, 'requirePhoneNumber')
            )(this.state.paymentInstrumentConfiguration);
        };

        this.handleChangeDropDownAddress = () => {
            this.loadingIndicator = true;
            if (!addressStateRegionProvinceValueOptionsForCountry(this.state.makePaymentAddress.Country, this.state.addressCountriesAll, this.state.addressStatesAll)) {
                this.resetStateFlag = false;
            }
            this.actions.setMakePaymentInstrumentBillingAddress(clone(this.state.makePaymentAddress));
            this.editPaymentInstrument.BillingAddress = clone(this.state.makePaymentAddress);
            this.actions.setMakePaymentInstrument(this.editPaymentInstrument);
            this.updateStateField();
            this.updateAddressFormModel();
            this.loadingIndicator = false;
        };

        this.handlePaymentTypeChange = (selectedPaymentTypeOption) => {
            this.savePaymentInstrument = true;
            this.renderAddress = false;
            this.$timeout(() => {
                this.renderAddress = true;
            });
            this.resetForm();
            if (selectedPaymentTypeOption && selectedPaymentTypeOption.paymentInstrumentType) {
                this.editPaymentInstrument.Type = selectedPaymentTypeOption.paymentInstrumentType.Value;
            }

            this.updatePaymentInstrumentEditCopy();
        };

        //This function is called by the child select-or-text-input component as a callback.
        //If the resetStateFlag is set as false by the handleChangeDropDownAddress function, then we need to set it back to true (as resetting the state should be the default), but then return false to the child controller.
        //Because we normally want to reset the state on the change of a country, to a country with a dropdown, this pattern was needed when preventing that state reset on a change of the overall address dropdown.
        //This pattern is also designed to avoid race conditions or the need of a $timeout function
        this.shouldResetState = () => {
            if (!this.resetStateFlag) {
                this.resetStateFlag = true;
                return false;
            } else {
                return true;
            }
        };

        this.updateStateField = () => {
            this.actions.setAddressData(this.editPaymentInstrument.BillingAddress);
            this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);

            // if Country has states, reset State if it's not one of them
            if (this.state.addressStatesOptions && !this.state.addressStatesOptions.find((stateOption) => {
                return stateOption.value === this.editPaymentInstrument.BillingAddress.State;
            })) {
                this.editPaymentInstrument.BillingAddress.State = null;
                this.actions.setMakePaymentAddressStateValue(null);
                this.addressFormModel.stateRegionProvince = this.editPaymentInstrument.BillingAddress.State;
            }

            this.canEditAddress();
        };

        this.canEditAddress = () => {
            this.isAddressLocked = this.state.makePaymentAddress && this.state.makePaymentAddress.Locked;
        };

        this.handleChangeFormCountry = () => {
            this.updateStateField();
        };

        this.closeNewEditPaymentMethodPopup = () => {
            this.renderAddress = false;
            this.resetForm();
            this.onClose()();
        };

        this.getDialogTextKeys = () => {
            return this.dialogTextKeys[this.isEditingPaymentInstrument ? 'edit' : 'new'];
        };

        this.showRecordPaymentInstrumentBox = () => {
            return !this.isEditingPaymentInstrument && this.showSavePaymentInstrument &&
                (this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CREDIT_CARD ||
                    this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK ||
                    this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL ||
                    this.editPaymentInstrument.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_GIFT_CARD);
        };

        this.updatePaymentInstrumentEditCopy = (changes) => {
            if (isNil(changes) || changes === this.CHANGE_CARD_TYPE) {
                this.actions.setMakePaymentInstrument(clone(this.editPaymentInstrument));
            }

            this.editPaymentInstrument = this.state.newPaymentMethodWithMetadata;
            this.updateAddressFormModel();
        };

        this.updatePaymentInstrumentOnBlur = () => {
            this.updatePaymentInstrumentEditCopy();
        };

        this.initialized = true;

        if (this.paymentInstrument || this.showPaymentInstrumentForm) {
            this.editPaymentInstrument = clone(this.paymentInstrument);
            this.updatePaymentInstrument(this.editPaymentInstrument);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    $onChanges(changes) {
        // Refresh the form if the selected payment instrument changes or if the modal is opened.
        if (this.initialized && (changes.paymentInstrument || (changes.showPaymentInstrumentForm && changes.showPaymentInstrumentForm.currentValue))) {
            this.editPaymentInstrument = changes.paymentInstrument ? clone(changes.paymentInstrument.currentValue) : clone(this.paymentInstrument);
            this.updatePaymentInstrument(this.editPaymentInstrument);
        }
    }

    updatePaymentInstrument(editPaymentInstrument) {
        if (isNil(editPaymentInstrument) || isNil(editPaymentInstrument.Type) || !this.isEditingPaymentInstrument) {
            this.handlePaymentTypeChange(this.state.paymentInstrumentTypes[0]);
        } else {
            editPaymentInstrument.Type = editPaymentInstrument.Type.toString();
            this.actions.setMakePaymentInstrument(editPaymentInstrument);
        }
        this.updateStateField();
        this.updateAddressFormModel();
        this.renderAddress = true;
    }

    onAddressFormChanged(addressFormModel) {
        this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
        this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
        this.addressFormModel.city = addressFormModel.city;
        this.addressFormModel.country = addressFormModel.country;
        this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
        this.addressFormModel.shipToName = addressFormModel.shipToName;

        this.editPaymentInstrument.BillingAddress.ShipToName= this.addressFormModel.shipToName;
        this.editPaymentInstrument.BillingAddress.LineOne= this.addressFormModel.addressLine1;
        this.editPaymentInstrument.BillingAddress.LineTwo= this.addressFormModel.addressLine2;
        this.editPaymentInstrument.BillingAddress.City= this.addressFormModel.city;
        this.editPaymentInstrument.BillingAddress.State= this.addressFormModel.stateRegionProvince;
        this.editPaymentInstrument.BillingAddress.PostalCode= this.addressFormModel.postalCode ? this.addressFormModel.postalCode : null;

        if (addressFormModel.country !== this.editPaymentInstrument.BillingAddress.Country) {
            this.editPaymentInstrument.BillingAddress.Country = addressFormModel.country;
            this.handleChangeFormCountry();
        } else {
            this.updatePaymentInstrumentEditCopy();
        }

    }

    updateAddressFormModel() {
        if (!this.editPaymentInstrument ||!this.editPaymentInstrument.BillingAddress) {
            return;
        }
        this.addressFormModel = Object.assign({}, this.addressFormModel, {
            addressLine1: this.editPaymentInstrument.BillingAddress.LineOne,
            addressLine2: this.editPaymentInstrument.BillingAddress.LineTwo,
            city: this.editPaymentInstrument.BillingAddress.City,
            country: this.editPaymentInstrument.BillingAddress.Country,
            postalCode: this.editPaymentInstrument.BillingAddress.PostalCode,
            shipToName: this.editPaymentInstrument.BillingAddress.ShipToName,
            stateRegionProvince: this.editPaymentInstrument.BillingAddress.State
        });
    }

    //check if this works
    getAddressKey() {
        return pathOr(undefined, ['paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI(addressKey) {
        //TO-DO verify the appropriate address type below
        const unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }
}

export default {
    template: require('./modify.payment.method.popup.html'),
    controller: ModifyPaymentMethodPopupController,
    controllerAs: 'modifyPaymentMethodPopup',
    bindings: {
        filterAutopay: '<?',
        isEditingPaymentInstrument: '<',
        onClose: '&',
        onSuccess: '&',
        paymentInstrument: '<',
        showPaymentInstrumentForm: '<',
        showSavePaymentInstrument: '<?'
    }
};
