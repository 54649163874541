import Immutable from 'seamless-immutable';
import {Configurable} from 'invision-core';
import {ORDER_ACCESS, SUBSCRIBER_CREATE} from '../../security.attributes';

/*
    Global Action Data Structure:
        id - Required: This ID should be generated in invision-core to ensure it remains the same across the app
        securityAttributes - Optional: The security attributes are run through the PermissionsService.hasAccess function,
            if any of the attributes are not met, then the action is not added to the collection
        adminAttributes - Optional: The security attributes are run through PermissionsService.hasAdminAccess function
*/

const {CUSTOMER_GLOBAL_ACTIONS_IDS} = Configurable.CustomerCare;
export const CREATE_COMMERCIAL_CUSTOMER_GLOBAL_ACTION = {
    id: CUSTOMER_GLOBAL_ACTIONS_IDS.CREATE_COMMERCIAL_CUSTOMER,
    glyph: 'building',
    securityAttributes: [SUBSCRIBER_CREATE]
};

export const CREATE_CUSTOMER_GLOBAL_ACTION = {
    id: CUSTOMER_GLOBAL_ACTIONS_IDS.CREATE_CUSTOMER,
    glyph: 'user',
    securityAttributes: [SUBSCRIBER_CREATE]
};

export const CREATE_RESIDENTIAL_CUSTOMER_GLOBAL_ACTION = {
    id: CUSTOMER_GLOBAL_ACTIONS_IDS.CREATE_RESIDENTIAL_CUSTOMER,
    glyph: 'user',
    securityAttributes: [SUBSCRIBER_CREATE]
};

export const NEW_CONNECT_GLOBAL_ACTION = {
    id: CUSTOMER_GLOBAL_ACTIONS_IDS.NEW_CONNECT,
    glyph: 'shopping-cart',
    securityAttributes: [ORDER_ACCESS]
};

export const GLOBAL_ACTIONS_MAP = Immutable({
    [CREATE_COMMERCIAL_CUSTOMER_GLOBAL_ACTION.id]: CREATE_COMMERCIAL_CUSTOMER_GLOBAL_ACTION,
    [CREATE_CUSTOMER_GLOBAL_ACTION.id]: CREATE_CUSTOMER_GLOBAL_ACTION,
    [CREATE_RESIDENTIAL_CUSTOMER_GLOBAL_ACTION.id]: CREATE_RESIDENTIAL_CUSTOMER_GLOBAL_ACTION,
    [NEW_CONNECT_GLOBAL_ACTION.id]: NEW_CONNECT_GLOBAL_ACTION
});