import CustomerCareLocaleKeys from '../../../../locales/keys';
import {StoredValueAcccountRechargeTypeEnum, toLabel} from '../../../../reducers/constants/stored.value.account.recharge.type.constants';
import {createSelector} from 'reselect';
import {
    i18n,
    EnumHelper,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';

import {
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../ewallet.constants';

import {
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    PaymentInstrumentsWithTypeNamesViewModel
} from '../../../../reducers/selectors/customer.ewallet.selectors';

const paymentMethodTypeSelector = createSelector(
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (selectedPaymentMethod) => {
        return selectedPaymentMethod ? selectedPaymentMethod.Type : null;
    }
);

export const IsDirectDebitSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.DIRECT_DEBIT.toString());
    }
);

export const DirectDebitDetailSelector = createSelector(
    IsDirectDebitSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        isDirectDebit,
        paymentMethod
    ) => {
        return (isDirectDebit && paymentMethod) ? paymentMethod.DirectDebit : null;
    }
);

export const IsAcpSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ACP.toString());
    }
);

export const AcpDetailSelector = createSelector(
    IsAcpSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        isAcp,
        paymentMethod
    ) => {
        return (isAcp && paymentMethod) ? paymentMethod.AcpBill : null;
    }
);

export const IsBraintreeSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.BRAINTREE.toString());
    }
);

export const BraintreeMethodTypeLookupSelector = createSelector(
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.BraintreeMethodType),
    (braintreeMethodTypes) => {
        if (braintreeMethodTypes && braintreeMethodTypes.length) {
            return braintreeMethodTypes.reduce(
                (lookup, methodType) => {
                    const value = methodType.Value;
                    lookup[value] = methodType;
                    return lookup;
                },
                {}
            );
        }

        return {};
    }
);


export const BraintreeDetailSelector = createSelector(
    IsBraintreeSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    BraintreeMethodTypeLookupSelector,
    (
        isBraintree,
        paymentMethod,
        brainTreeMethodByType
    ) => {
        if (isBraintree && paymentMethod && paymentMethod.BraintreeMethod) {
            const braintree = paymentMethod.BraintreeMethod;
            const typeId = braintree.Type;
            const typeObj = brainTreeMethodByType[typeId.toString()];
            return Object.assign(
                {},
                braintree,
                {
                    methodType: typeObj
                }
            );
        }
        return null;
    }
);

export const IsECheckSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK.toString());
    }
);

export const ECheckDetailSelector = createSelector(
    IsECheckSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        isECheck,
        paymentMethod
    ) => {
        return (isECheck && paymentMethod) ? paymentMethod.ECheck : null;
    }
);

export const IsExternalBillSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL.toString());
    }
);

export const ExternalBillDetailSelector = createSelector(
    IsExternalBillSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        isExternalBill,
        paymentMethod
    ) => {
        return (isExternalBill && paymentMethod) ? paymentMethod.ExternalBill : null;
    }
);


export const IsRokuSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ROKU.toString());
    }
);

export const RokuDetailSelector = createSelector(
    IsRokuSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        isRoku,
        paymentMethod
    ) => {
        return (isRoku && paymentMethod) ? paymentMethod.RokuAccount : null;
    }
);

export const IsStoredValueAccountSelector = createSelector(
    paymentMethodTypeSelector,
    (paymentType) => {
        return (paymentType !== null) &&
            (paymentType.toString() === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STORED_VALUE_ACCOUNT.toString());
    }
);

export const BrandableCurrencyLookupSelector = createSelector(
    MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.BrandableCurrency),
    (brandableCurrencies) => {
        if (brandableCurrencies && brandableCurrencies.length) {
            return brandableCurrencies.reduce(
                (lookup, brandableCurrency) => {
                    const value = brandableCurrency.Value;
                    lookup[value] = brandableCurrency;
                    return lookup;
                },
                {}
            );
        }

        return {};
    }
);


export const StoredValueAccountDetailSelector = createSelector(
    IsStoredValueAccountSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    PaymentInstrumentsWithTypeNamesViewModel,
    BrandableCurrencyLookupSelector,
    (
        isStoredValueAccount,
        selectedPaymentMethod,
        allPaymentMethods,
        brandableCurrenciesLookup
    ) => {
        if (isStoredValueAccount && selectedPaymentMethod) {
            const sva = selectedPaymentMethod.StoredValueAccount;
            const chargePaymentInstrumentId = sva.ChargePaymentInstrumentId;
            const chargePaymentInstrument = allPaymentMethods.find((pi) => {
                return pi.Id === chargePaymentInstrumentId;
            });

            let brandableCurrency;
            if (sva.LoyaltyCard && sva.BrandableCurrency) {
                const brandableCurrencyId = sva.BrandableCurrency.toString();
                brandableCurrency = brandableCurrenciesLookup[brandableCurrencyId];
            }

            return Object.assign({},
                sva,
                {
                    chargePaymentInstrument: chargePaymentInstrument,
                    brandableCurrency: brandableCurrency,
                    frequency: getFrequency(sva)
                }
            );
        }

        return null;
    }
);

export const CurrentStateSelector = (state) => {
    return state;
};

const paymentInstrumentMap = {
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.EXTERNAL_BILL] : ExternalBillDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.DIRECT_DEBIT] : DirectDebitDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ACP] : AcpDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.BRAINTREE] : BraintreeDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.ROKU] : RokuDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.E_CHECK] : ECheckDetailSelector,
    [SUPPORTED_PAYMENT_INSTRUMENT_TYPES.STORED_VALUE_ACCOUNT] : StoredValueAccountDetailSelector
};

export const TemplatePaymentDetailSelector = createSelector(
    CurrentStateSelector,
    SelectedPaymentMethodWithTypeNamesViewModelSelector,
    (
        state,
        selectedPayment
    ) => {
        if (selectedPayment && selectedPayment.Type && paymentInstrumentMap[selectedPayment.Type]) {
            const instrumentType = paymentInstrumentMap[selectedPayment.Type];

            return instrumentType(state);
        }

        return null;
    }
);

const getFrequency = (sva) => {
    const frequencyId = EnumHelper.getEnumOrDefault(sva.RechargePolicy, StoredValueAcccountRechargeTypeEnum, null);
    const frequencyQuantity = parseInt(sva.RechargeDays);

    let frequency = '';

    if (frequencyId !== null) {
        if (frequencyId === StoredValueAcccountRechargeTypeEnum.frequencyByDay && frequencyQuantity > 0) {
            frequency = getFrequencyByDayText(frequencyQuantity);
        } else {
            frequency = toLabel(frequencyId);
        }
    }

    return frequency;
};

const getFrequencyByDayText = (frequencyQuantity) => {
    switch (frequencyQuantity) {
        case 1:
            return i18n.translate(CustomerCareLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.FREQUENCY_BY_DAY.ONE);
        case 2:
            return i18n.translate(CustomerCareLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.FREQUENCY_BY_DAY.TWO);
        default:
            return i18n.translate(CustomerCareLocaleKeys.E_WALLET.STORED_VALUE_ACCOUNT.RECHARGE_TYPES.FREQUENCY_BY_DAY.MORE, {
                quantity: frequencyQuantity
            });
    }
};
