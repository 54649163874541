import {
    isNil
} from 'ramda';
import {
    i18n,
    PermissionService,
    SessionSelectors,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import LocaleKeys from '../../../../../locales/keys';
import {
    ORDERING_ACCESS
} from '../../../../../security.attributes';
import {
    goToCheckoutForRestoreSubscription
} from '../../../../../reducers/actions/products.order.actions';
import {
    CurrentCustomerIdSelector,
    RouteParams,
    RouteState
} from '../../../../../reducers/selectors/customer.selectors';
import {
    BillingCycleSelector,
    BrandableCurrencyNameSelector,
    BriefSubscriptionsSelector,
    CurrencySelector,
    CurrentPricingPlanSelector,
    CurrentSubscriptionPricingPlanSelector,
    CurrentSubscriptionProductSelector,
    CurrentSubscriptionSelector,
    IsASubscriptionExtensionActive,
    IsFetchingSubscriptionDetailsSelector,
    OriginalPricingPlanSelector,
    RecordCountSelector,
    subscriptionActionListSelector,
    SubscriberHasAnyAlignedSubscriptionsSelector,
    UserCanRemoveExtensionSelector,
    UserCanRemoveSubscriptionSelector,
    UserCanRenewSelector,
    UserCanRestoreSubscriptionSelector,
    UserCanUpdateSubscriptionSelector
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    SubscriptionItemsExtensionSelectors
} from '../../../../../reducers/selectors/customer.subscriptions.products.selectors';
import {
    retrieveSubscription,
    searchSubscriptions,
    viewSubscriptionTransactions
} from '../../../../../reducers/actions/customer.subscriptions.actions';

import {
    SUBSCRIPTION_DETAILS_ROUTE
} from '../../../subscriptions/subscriptions.config';

const SUBSCRIPTIONS_SEARCH_CRITERIA = {
    IncludePaymentInstrumentInfo: true,
    IncludeRemoved: false,
    PageSize: 5,
    SortDirection: 2
};

const SUBSCRIPTIONS_VIEW_TO = 'index.customercare.customer.subscriptions';
const SUBSCRIPTION_REMOVE_TO = 'index.customercare.customer.subscriptions.detail.remove';

export class SubscriptionsWidgetController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        Object.assign(this, {
            $timeout,
            $ngRedux,
            localeKeys: LocaleKeys,
            uiNotificationService: uiNotificationService
        });

        this.manualRenewal = i18n.translate(this.localeKeys.SUBSCRIPTIONS.MANUAL_RENEWAL);
        this.autoRenewal = i18n.translate(this.localeKeys.SUBSCRIPTIONS.AUTOMATIC_RENEWAL);


        this._actionItemDictionary = {
            View: [
                {
                    title: i18n.translate(this.localeKeys.VIEW_DETAILS),
                    selected: false,
                    shouldShow: () => {
                        return true;
                    }
                },
                {
                    title: i18n.translate(this.localeKeys.CUSTOMER_DASHBOARD.TRANSACTIONS),
                    selected: false,
                    shouldShow: () => {
                        return true;
                    }
                }
            ],
            Restore: [
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.RESTORE_SUBSCRIPTION),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanRestoreSubscription;
                    }

                }
            ],
            Modify: [
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.MODIFY_RENEWAL),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanRenew;
                    }

                },
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.MODIFY),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanUpdate && !this.currentSubscriptionIsAligned();
                    }

                }
            ],
            Remove: [
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanRemove && !this.currentSubscriptionIsAligned();
                    }

                },
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE_ALL),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanRemove && this.currentSubscriptionIsAligned();
                    }
                },
                /*
                    TO-DO
                    -The shouldShow logic is also dependent on the existence of an extension which will be
                    added in later PR
                */
                {
                    title: i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.REMOVE_EXTENSION),
                    selected: false,
                    shouldShow: () => {
                        return this.state.userCanRemoveExtension;
                    }
                }
            ]
        };

        this.showAllSubscriptionItems = false;
        this.shouldShowExpandCollapseLink = false;
        this.currentSubscriptionList = [];
    }

    $onInit() {
        const controllerActions = {
            goToCheckoutForRestoreSubscription,
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            retrieveSubscription,
            searchSubscriptions,
            stateGo: stateGo,
            viewSubscriptionTransactions
        };

        const mapStateToTarget = (store) => {
            return {
                actionList: subscriptionActionListSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPricingPlan: CurrentPricingPlanSelector(store),
                isFetchingSubscriptionDetails: IsFetchingSubscriptionDetailsSelector(store),
                renewalPricingPlan: OriginalPricingPlanSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteState(store),
                selectedSubscription: CurrentSubscriptionSelector(store),
                selectedSubscriptionBillingCycleName: BillingCycleSelector(store),
                selectedSubscriptionBrandableCurrencyName: BrandableCurrencyNameSelector(store),
                selectedSubscriptionCurrency: CurrencySelector(store),
                selectedSubscriptionPricingPlan: CurrentSubscriptionPricingPlanSelector(store),
                selectedSubscriptionProduct: CurrentSubscriptionProductSelector(store),
                showAsAligned: SubscriberHasAnyAlignedSubscriptionsSelector(store),
                showRenewalPricingPlan: IsASubscriptionExtensionActive(store),
                subscriptions: BriefSubscriptionsSelector(store),
                subscriptionsCount: RecordCountSelector(store),
                subscriptionItemsExtensions: SubscriptionItemsExtensionSelectors(store),
                subscriptionStateIndicatorsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.SubscriptionStateIndicator, store),
                userCanRemove: UserCanRemoveSubscriptionSelector(store),
                userCanRemoveExtension: UserCanRemoveExtensionSelector(store),
                userCanRenew: UserCanRenewSelector(store),
                userCanRestoreSubscription: UserCanRestoreSubscriptionSelector(store),
                userCanUpdate: UserCanUpdateSubscriptionSelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.subscriptionStateIndicatorsLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.SubscriptionStateIndicator);
        }

        this.toggleModifyRenewalModal = false;

        this.modifyRenewalModal = {
            onRegisterApi: (evt) => {
                this.modifyRenewalModal = evt.api;
            }
        };

        if (this.state.currentCustomerId && this.hasWidgetAccess()) {
            this.searchSubscriptions();
        }

        this.toggleModifyModal = false;

        this.removeExtensionModalConfig = {
            onRegisterApi: (evt) => {
                this.removeExtensionModalApi = evt.api;
            }
        };

        this.onSubscriptionExtensionRemoveListComplete = (extensionId) => {
            this.removeExtensionModalApi.close();
            this.actions.stateGo('index.customercare.customer.subscriptions.detail.extension', {
                subscriptionId: this.state.selectedSubscription.Id,
                extensionId: extensionId,
                currency: this.state.selectedSubscriptionCurrency
            });
        };

        this.closeSubscriptionExtensionRemoveListPopup = () => {
            this.removeExtensionModalApi.close();
        };
    }

    openRenewalModal() {
        this.toggleModifyRenewalModal = true;
        this.$timeout(this.modifyRenewalModal.open);
    }

    closeRenewalModal() {
        this.modifyRenewalModal.close();
        this.toggleModifyRenewalModal = false;
    }

    onRenewModalSuccess() {
        this.closeRenewalModal();
        this.searchSubscriptions(this.state.selectedSubscription.Id);
    }

    searchSubscriptions(activeSubscriptionId) {
        this.actions.searchSubscriptions(this.state.currentCustomerId, SUBSCRIPTIONS_SEARCH_CRITERIA).then(() => {
            this.viewAllText = i18n.translate(this.localeKeys.CUSTOMER_DASHBOARD.VIEW_ALL_SUBSCRIPTIONS, {
                count: this.state.subscriptionsCount
            });
            if (this.state.subscriptions.length) {
                this.retrieveSubscription(activeSubscriptionId || this.state.subscriptions[0].id);
            }
        });
    }

    $onChanges(changesObj) {
        if (changesObj.customerId
            && !isNil(this.state.currentCustomerId)
            && !changesObj.customerId.isFirstChange()
            && this.actions
            && this.hasWidgetAccess()) {
            this.actions.searchSubscriptions(this.state.currentCustomerId, SUBSCRIPTIONS_SEARCH_CRITERIA);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    get actionsList() {
        return this._actionsList;
    }

    onOptionSelect(option) {
        switch (option.item.title) {
            case i18n.translate(this.localeKeys.VIEW_DETAILS):
                this.viewSubscriptionDetails();
                break;
            case i18n.translate(this.localeKeys.CUSTOMER_DASHBOARD.TRANSACTIONS):
                this.viewSubscriptionTransactions();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.MODIFY_RENEWAL):
                this.openRenewalModal();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.MODIFY):
                this.openModifyModal();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.RESTORE_SUBSCRIPTION):
                this.restoreSubscription();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE):
                this.viewRemoveSubscription();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE_ALL):
                this.viewRemoveSubscription();
                break;
            case i18n.translate(this.localeKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.REMOVE_EXTENSION):
                this.removeExtensionModalApi.open();
                break;
            default:
                break;
        }
    }

    currentSubscriptionIsAligned() {
        return this.state.selectedSubscription && this.state.selectedSubscription.Items && this.state.selectedSubscription.Items.length > 1;
    }

    setCurrentSubscriptionItemsList() {
        if (this.state.selectedSubscription && this.state.selectedSubscription.Items) {
            const firstHasBundleChildren = this.state.selectedSubscription.Items[0].Children &&
                this.state.selectedSubscription.Items[0].Children.length;
            const numberOfCollapsedItemsToShow = firstHasBundleChildren ? 1 : 3;

            this.shouldShowExpandCollapseLink = this.state.selectedSubscription.Items.length > numberOfCollapsedItemsToShow;

            if (!this.showAllSubscriptionItems && this.shouldShowExpandCollapseLink) {
                this.currentSubscriptionList = this.state.selectedSubscription.Items.slice(0, numberOfCollapsedItemsToShow);
            } else {
                this.currentSubscriptionList = this.state.selectedSubscription.Items;
            }
        }
    }

    toggleShowMoreSubscriptionList() {
        this.showAllSubscriptionItems = !this.showAllSubscriptionItems;
        this.setCurrentSubscriptionItemsList();
    }

    hasWidgetAccess() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
    }

    onSelectSubscription(subscription) {
        this.retrieveSubscription(subscription.id);
    }

    retrieveSubscription(subscriptionId) {
        this.actions.retrieveSubscription(this.state.currentCustomerId, {
            Id: subscriptionId,
            IncludeChangeOptions: true
        }).then(() => {
            this.setActionItemsList();
            this.setCurrentSubscriptionItemsList();
        });
    }

    setActionItemsList() {
        this.state.actionList.forEach((action) => {
            action.filteredActions = this._actionItemDictionary[action.category].filter((item) => {
                return item.shouldShow();
            });
        });
    }

    viewRemoveExtension() {
        /*
         TO-DO: When remove subscription-extension API is ready.
        */
    }

    restoreSubscription() {
        this.actions.stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
            subscriptionId: this.state.selectedSubscription.Id
        }).then(() => {
            this.actions.goToCheckoutForRestoreSubscription(this.state.selectedSubscription.Id);
        });
    }

    viewRemoveSubscription() {
        this.actions.stateGo(SUBSCRIPTION_REMOVE_TO, {
            subscriptionId: this.state.selectedSubscription.Id
        });
    }

    viewSubscriptionDetails() {
        this.actions.stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
            subscriptionId: this.state.selectedSubscription.Id
        });
    }

    viewSubscriptionList() {
        this.actions.stateGo(SUBSCRIPTIONS_VIEW_TO);
    }

    viewSubscriptionTransactions() {
        this.actions.viewSubscriptionTransactions({
            name: this.state.routeState.name,
            params: this.state.routeParams
        }, this.state.selectedSubscription);
    }

    openModifyModal() {
        this.toggleModifyModal = true;
    }

    closeModifyModal() {
        this.toggleModifyModal = false;
    }
}

export default {
    template: require('./subscriptions.html'),
    controller: SubscriptionsWidgetController,
    controllerAs: 'Subscriptions'
};
