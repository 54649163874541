import c3 from 'c3/c3';
import UsageChartHelper from './usage.chart.helper';

export default function($document, $element, $timeout) {
    this.$onChanges = (change) => {
        this._unused = this.unlimited ? 0 : Math.max(this.unused, 0);

        if (this.initial && this.used === '0') {
            this._used = Math.max((this.initial - this._unused), 0);
        } else {
            this._used = this.unlimited ? 100 : Math.max(this.used, 0);
        }

        this._unlimited = this.unlimited || null;
        this.size = this.size || 'small';

        if (!this._unused && !this._used) {
            this._unused = 1;
        }

        this.hexValue = UsageChartHelper.getHexValueByUsage(this._used, this._unused, this._unlimited);

        if (this.chart && (change.used || change.unused)) {
            this.loadNewData();
        } else {
            this.generatePromise = $timeout(() => {
                this.generateChart();
            });
        }
    };

    this.$onDestroy = () => {
        this.generatePromise && $timeout.cancel(this.generatePromise);
    };

    this.getSharedProperties = () => {
        return {
            columns: [
                ['used', this._used],
                ['reserved', Math.max(this.reserved, 0)],
                ['unused', this._unused]
            ],
            colors: {
                reserved: UsageChartHelper.C3_HEX_VALUES.RESERVED,
                unused: UsageChartHelper.C3_HEX_VALUES.UNUSED,
                used: this.hexValue
            },
            order: null
        };
    };
    this.loadNewData = () => {
        this.chart.load(Object.assign({
            unload: ['used', 'unused', 'reserved']
        }, this.getSharedProperties()));
    };
    this.generateChart = () => {
        this.chart = c3.generate({
            bindto: $element[0],
            interaction: {
                enabled: false
            },
            data: Object.assign({
                labels: false,
                type: 'donut'
            }, this.getSharedProperties()),
            donut: {
                label: {
                    show: false
                },
                width: this.size === 'large' ? 20 : this.size === 'medium' ? 10 : 7
            },
            tooltip: {
                show: false
            },
            legend: {
                show: false
            },
            size: {
                height: this.size === 'large' ? 240 : this.size === 'medium' ? 120 : undefined
            },
            transition: {
                duration: 200
            }
        });
    };

    $document.ready(() => {
        $timeout(() => {
            this.chart && this.chart.resize();
        }, 2000);
    });
}
