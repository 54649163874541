import angular from 'angular';
import StatusButtonComponent from './statusButton/status.button.component';
import StatusIconComponent from './statusIcon/status.icon.component';
import CreditCheckPopupComponent from './creditCheckModal/credit.check.modal.component';

export default angular
    .module('invision.customercare.customer.creditCheck', [])
    .component('creditCheckStatusButton', StatusButtonComponent)
    .component('creditCheckStatusIcon', StatusIconComponent)
    .component('creditCheckPopup', CreditCheckPopupComponent)
    .name;
