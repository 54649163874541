import localeKeys from '../../../../locales/keys';
import {
    REGEXES,
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {
    i18n
} from 'invision-core';
import {
    resendRedemptionCode
} from '../../../../reducers/actions/customer.coupons.actions';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';

class ResendRedemptionPopupController {
    constructor($ngRedux, uiNotificationService) {
        this.localeKeys = localeKeys;
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.vm = {
            errorMessages: [],
            emailRegex: REGEXES.EMAIL,
            isLoading: false,
            isResendingCode: false
        };
    }

    handleClose() {
        this.onClose()();
    }

    handleSubmitForm() {
        if (this.resendRedemptionPopupForm.$invalid) {
            return;
        }

        this.vm.isLoading = true;
        this.actions.resendRedemptionCode(this.state.currentCustomerId, this.redemptionDetails.RedemptionCode.RedemptionCode,
            this.vm.resendRedemptionCodeModel.ResendCouponCodeEmail, this.vm.resendRedemptionCodeModel.ResendCouponCodeRecipient)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(this.localeKeys.COUPONS.REDEMPTION_CODE_DETAILS.LABELS.RESEND_SUCCESS_MESSAGE), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                this.onClose()(true);
            }).catch((error) => {
                this.onClose()(false, error);
            }).finally(() => {
                this.vm.isLoading = false;
            });
    }


    $onInit() {
        const controllerActions = {
            resendRedemptionCode
        };

        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store)
            };
        };


        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.vm.resendRedemptionCodeModel = {
            // TODO Email does not get returned from the API.  Speak with Sanjay about this.
            ResendCouponCodeEmail: this.redemptionDetails.RedemptionCode.SubscriberEmail,
            ResendCouponCodeRecipient: this.redemptionDetails.RedemptionCode.SubscriberName
        };
    }

    $onChanges(changesObj) {
        if (changesObj.redemptionDetails.currentValue) {
            this.vm.dialogHeading = i18n.translate(this.localeKeys.COUPONS.RESEND_REDEMPTION_CODE.DIALOG_HEADING, {
                couponName: changesObj.redemptionDetails.currentValue.Coupon.Name
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./resend.redemption.code.popup.html'),
    bindings: {
        redemptionDetails: '<',
        onClose: '&'
    },
    controller: ResendRedemptionPopupController
};
