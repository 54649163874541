import angular from 'angular';

import {routing} from './add.offer.wizard.config';
import AddOfferWizardComponent from './add.offer.wizard.component';

export const ADD_OFFER_ORDER_URL = 'index.customercare.customer.orders.addofferorder';

export default angular.module(ADD_OFFER_ORDER_URL, [])
    .component('addOffer', AddOfferWizardComponent)
    .config(routing)
    .name;
