import {GENERAL_STATUSES} from '../../shared/constants/general.status.constants';

// PaymentInstrumentTypes, metadata code 34
export const DIRECT_DEBIT = '8';
export const DD_SUB_TYPE_NETHERLANDS = '3';

const ACP = '14';
const BRAINTREE = '17';
const CHECK_REFUND = '19';
const CONVERGENT_BILLER_INVOICE = '15';
const CREDIT_CARD = '0';
const CURRENCY_ENTITLEMENT = '21';
const E_CHECK = '1';
const EXTERNAL_BILL = '4';  //potentially these constants may be duplicates of src/components/search/search.constants.js
const EXTERNAL_GIFT_CARD = '10';
const GOOGLE_PLAY = '12';
const INTERNAL_GIFT_CARD = '3';
const ITUNES = '6';
const PAY_PAL = '5';
const ROKU = '11';
const STORED_VALUE_ACCOUNT = '2';
const UWP = '16';
const XBOX = '9';
const STRIPE_ACCOUNT = '22';

// only these payment instrument types are supported in Invision at this time
export const SUPPORTED_PAYMENT_INSTRUMENT_TYPES = {
    ACP: ACP,
    BRAINTREE: BRAINTREE,
    CREDIT_CARD: CREDIT_CARD,
    CURRENCY_ENTITLEMENT: CURRENCY_ENTITLEMENT,
    DIRECT_DEBIT: DIRECT_DEBIT,
    E_CHECK: E_CHECK,
    EXTERNAL_BILL: EXTERNAL_BILL,
    EXTERNAL_GIFT_CARD: EXTERNAL_GIFT_CARD,
    GOOGLE_PLAY: GOOGLE_PLAY,
    INTERNAL_GIFT_CARD: INTERNAL_GIFT_CARD,
    ITUNES: ITUNES,
    PAY_PAL: PAY_PAL,
    ROKU: ROKU,
    STORED_VALUE_ACCOUNT: STORED_VALUE_ACCOUNT,
    STRIPE_ACCOUNT: STRIPE_ACCOUNT,
    CHECK_REFUND: CHECK_REFUND
};

export const SUPPORTED_OTT_PAYMENT_INSTRUMENT_TYPES = Object.assign({}, {
    CONVERGENT_BILLER_INVOICE: CONVERGENT_BILLER_INVOICE
}, SUPPORTED_PAYMENT_INSTRUMENT_TYPES);


// Ideally the values here should be returned from the API
export const PAYMENT_INSTRUMENT_ALWAYS_REQUIRED_ADDRESS = {
    CREDIT_CARD: CREDIT_CARD,
    E_CHECK
};

// Ideally the values here should be returned from the API
export const PAYMENT_INSTRUMENT_NEVER_REQUIRED_ADDRESS = {
    INTERNAL_GIFT_CARD: INTERNAL_GIFT_CARD
};

export const DEFAULT_UNKNOWN_PAYMENT_METHOD_TYPE_SORT_ORDER = 999;
/**
 * @see https://jira.csgicorp.com/browse/ASC-50330
 */
export const PAYMENT_METHOD_TYPE_SORT_ORDER = [
    CREDIT_CARD,
    DIRECT_DEBIT,
    INTERNAL_GIFT_CARD,
    EXTERNAL_GIFT_CARD,
    EXTERNAL_BILL,
    ACP,
    E_CHECK,
    STORED_VALUE_ACCOUNT,
    PAY_PAL,
    BRAINTREE,
    ITUNES,
    GOOGLE_PLAY,
    // FACEBOOK,
    ROKU,
    XBOX,
    UWP
];

// only these payment instrument types can be created in Invision at this time
export const CREATABLE_PAYMENT_INSTRUMENT_TYPES = {
    CREDIT_CARD: CREDIT_CARD,
    EXTERNAL_BILL: EXTERNAL_BILL,
    EXTERNAL_GIFT_CARD: EXTERNAL_GIFT_CARD,
    INTERNAL_GIFT_CARD: INTERNAL_GIFT_CARD,
    E_CHECK: E_CHECK
};

// all payment instrument types (except the opt'd ones) can be edited in Invision at this time
export const EDITABLE_PAYMENT_INSTRUMENT_TYPES = {
    CREDIT_CARD: CREDIT_CARD,
    EXTERNAL_BILL: EXTERNAL_BILL,
    EXTERNAL_GIFT_CARD: EXTERNAL_GIFT_CARD,
    E_CHECK: E_CHECK
};

export const OPT_OUT_PROCESS_CREDIT_PAYMENT_SELECTION = {
    DIRECT_DEBIT: DIRECT_DEBIT,
    BRAINTREE: BRAINTREE
};

export const ALWAYS_ABLE_TO_SET_AS_DEFAULT_TYPES = {
    DIRECT_DEBIT: DIRECT_DEBIT
};

export const READ_ONLY_PAYMENT_INSTRUMENT_TYPES_EXCEPTIONS = {
    DIRECT_DEBIT: DIRECT_DEBIT
};

// opt-out UI support for reactivation
export const OPT_OUT_REACTIVATE_PAYMENT_INSTRUMENT_TYPES = {
    BRAINTREE,
    PAY_PAL
};

export const AUTO_PAY_SUPPORTED_PAYMENT_INSTRUMENT_TYPES = {
    CREDIT_CARD: CREDIT_CARD,
    E_CHECK: E_CHECK
};

export const PAYMENT_METHOD_STATUSES = GENERAL_STATUSES;

export const REMOVE_PAYMENT_METHOD_OPTION_ID = 'REMOVE_PAYMENT_METHOD_OPTION_ID';
export const ANONYMIZE_PAYMENT_METHOD_OPTION_ID = 'ANONYMIZE_PAYMENT_METHOD_OPTION_ID';
export const REACTIVATE_PAYMENT_METHOD_OPTION_ID = 'REACTIVATE_PAYMENT_METHOD_OPTION_ID';
export const SET_AS_DEFAULT_PAYMENT_METHOD_OPTION_ID = 'SET_AS_DEFAULT_PAYMENT_METHOD_OPTION_ID';

export const ADD_TO_BLOCKLIST_MENU_ID = 'ADD_TO_BLOCKLIST';
export const ADD_TO_ALLOWLIST_MENU_ID = 'ADD_TO_ALLOWLIST';
export const REMOVE_FROM_BLOCKLIST_MENU_ID = 'REMOVE_FROM_BLOCKLIST';
export const REMOVE_FROM_ALLOWLIST_MENU_ID = 'REMOVE_FROM_ALLOWLIST';
export const VIEW_TRANSACTION_HISTORY_MENU_ID = 'VIEW_TRANSACTION_HISTORY';

export const ENABLE_ACCOUNT_NUMBER_KEY = 'enable_account_number';
