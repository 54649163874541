import {
    differenceWith,
    flip,
    isEmpty,
    map,
    pathOr,
    pipe,
    pluck
} from 'ramda';
import {
    convertMapToKeyValueObjectArray
} from '../../../../../../reducers/helpers/instance.property.helper';

const compareIdToProductId = (existing, cart) => {
    return parseInt(existing.Product.Id, 10) === parseInt(cart.ProductId, 10);
};

const compareProductIdToId = (cart, existing) => {
    return parseInt(cart.ProductId, 10) === parseInt(pathOr(0, ['Product', 'Id'], existing), 10);
};

const flippedParseInt = flip(parseInt);
const parseBase10 = flippedParseInt(10);

export const getRemoveItems = (currentSelections, existingChildren) => {
    return pipe(
        differenceWith(compareIdToProductId, existingChildren),
        pluck('Id'),
        map(parseBase10)
    )(currentSelections || []);
};

export const getAddItems = (currentSelections, existingChildren) => {
    return pipe(
        differenceWith(compareProductIdToId, currentSelections),
        map((currentItemSelection) => {
            return {
                BundleId: currentItemSelection.BundleId,
                InstanceProperties: convertMapToKeyValueObjectArray(currentItemSelection.InstanceProperties),
                ProductId: parseInt(currentItemSelection.ProductId, 10),
                PricingPlanId: currentItemSelection.PricingPlanId
            };
        })
    )(existingChildren);
};

const isNewPricingPlan = (currentPricingPlanId, existingSubscriptionItem) => {
    if (existingSubscriptionItem?.ChangePricingPlan) {
        return currentPricingPlanId && currentPricingPlanId !==
            pathOr(null, ['ChangePricingPlan', 'Id'], existingSubscriptionItem);
    }
    return currentPricingPlanId && currentPricingPlanId !==
        pathOr(null, ['PricingPlan', 'Id'], existingSubscriptionItem);
};

export const getChangeItems = (currentPricingPlanId, existingSubscriptionItem) => {
    if (!isNewPricingPlan(currentPricingPlanId, existingSubscriptionItem)) {
        return [];
    }
    return [{
        PricingPlanId: currentPricingPlanId,
        SubscriptionItemId: existingSubscriptionItem.Id
    }];

};

export const getReplaceItems = (updatedSubscriptionItem, currentSubscriptionItemToReplaceId) => {
    if (!updatedSubscriptionItem || isEmpty(updatedSubscriptionItem) || !currentSubscriptionItemToReplaceId) {
        return [];
    }
    return [Object.assign({}, updatedSubscriptionItem, {
        SubscriptionItemId: currentSubscriptionItemToReplaceId
    })];
};

export const getEditItems = (updatedSubscriptionItem, existingChildren, editSubscriptionItem) => {
    let addItems;

    const changeItems = getChangeItems(updatedSubscriptionItem.PricingPlanId, editSubscriptionItem);
    const removeItems = getRemoveItems(updatedSubscriptionItem.ChildItems, existingChildren);

    if (changeItems.length) {
        addItems = getAddItems(updatedSubscriptionItem.ChildItems, []);
    } else {
        addItems = getAddItems(updatedSubscriptionItem.ChildItems, existingChildren);
    }

    return {
        addItems,
        changeItems,
        removeItems
    };
};

export const noItemsModified = (editItems) => {
    return editItems.changeItems.length === 0 && editItems.removeItems.length === 0 && editItems.addItems.length === 0;
};
