import pathOr from 'ramda/src/pathOr';
import {createSelector} from 'reselect';
import {CurrentCustomerSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    CompletedStepsSelector,
    CurrentStepSelector,
    CurrentStepIndexSelector,
    EditAttributeGroupsSelector,
    EditPhysicalAttributeGroupsSelector,
    IsDecisionsStepSelector,
    OfferQuoteSelector,
    PostQuoteDueTotalsSelector,
    PreQuoteDueSubTotalsSelector,
    SkippedStepSelector,
    TotalDownPaymentSelector
} from '../../../../../reducers/selectors/edit.offer.wizard.selectors';
import {ALL_STEPS_KEYS} from '../../../../../reducers/constants/edit.offer.wizard.constants';
import {
    AllAttributesAreValidHelper,
    CurrentActiveAttributeNameHelper,
    FilteredFormAttributeGroupsHelper,
    FilteredPhysicalAttributeGroupsHelper,
    FormAttributeGroupsHelper,
    FormAttributeGroupsNavigationHelper,
    FormPhysicalAttributeGroupsHelper,
    GetFinancedProductGroupInCartSelector,
    GetSummaryTabFinancedGroupSelector,
    HasRequiredAndUnavailableInventory,
    ShowNextButtonHelper,
    ShowPreviousButtonHelper,
    SummaryTab,
    UnavailablePhysicalInventory
} from '../../../../../reducers/helpers/offer.ordering.wizard.selector.helper';
import {
    BackButtonText,
    ForwardButtonText,
    FormattedName,
    FormattedCityState,
    StepProgressBarViewModel,
} from '../../../../../reducers/helpers/wizard.selector.helper';
import {
    AttributeFormSubmittedSelector,
    CurrentAttributeFormName,
    CurrentAttributesValidationStatuses,
    InventoryAvailabilitySelector,
    IsUpdatingCartSelector,
    PagesIsFetchingDataSelector,
    ShoppingCartSelector,
    SubmittedOrderErrorSelector
} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    QuoteCalculatedSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector,
    TotalRemainingAmountSelector
} from '../../../../../reducers/selectors/offering.order.selectors';
import {SelectedContractSelector} from '../../../../../reducers/selectors/contract.selectors';
import {MetadataCodeTypeDictionarySelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
const DEFAULT_CUSTOMER = {};
export const CustomerInfoSelector = createSelector(
    [CurrentCustomerSelector],
    (customer) => {
        if (customer) {
            return customer;
        } else {
            return DEFAULT_CUSTOMER;
        }
    }
);

export const FormattedNameSelector = FormattedName([CustomerInfoSelector]);

export const FormattedCityStateSelector = FormattedCityState([CustomerInfoSelector]);

export const UnavailablePhysicalInventorySelector = UnavailablePhysicalInventory([EditPhysicalAttributeGroupsSelector, InventoryAvailabilitySelector]);

export const StepProgressBarViewModelSelector = StepProgressBarViewModel(
    [
        CurrentStepSelector,
        CompletedStepsSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS);

export const BackButtonTextSelector = BackButtonText(
    [
        CurrentStepIndexSelector,
        CompletedStepsSelector
    ], ALL_STEPS_KEYS);

export const ForwardButtonTextSelector = ForwardButtonText(
    [
        CurrentStepIndexSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS);

//Passing serviceAttribute for Edit and Change offer
//https://jira.csgicorp.com/browse/ASCBR-1369
export const FormAttributeGroups = FormAttributeGroupsHelper([EditAttributeGroupsSelector, MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute)]);
export const FormPhysicalAttributeGroups = FormPhysicalAttributeGroupsHelper([EditPhysicalAttributeGroupsSelector]);
export const FilteredPhysicalAttributeGroups = FilteredPhysicalAttributeGroupsHelper([FormAttributeGroups, FormPhysicalAttributeGroups, CurrentAttributeFormName]);
export const AttributeServiceSelector = FormAttributeGroupsNavigationHelper([
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    CurrentAttributeFormName,
    AttributeFormSubmittedSelector,
    CurrentAttributesValidationStatuses,
    SubmittedOrderErrorSelector
]);
export const CurrentActiveAttributeNameSelector = CurrentActiveAttributeNameHelper([AttributeServiceSelector]);
export const ShowPreviousButtonSelector = ShowPreviousButtonHelper([AttributeServiceSelector]);
export const ShowNextButtonSelector = ShowNextButtonHelper([AttributeServiceSelector]);
export const FilteredFormAttributeGroups = FilteredFormAttributeGroupsHelper([FormAttributeGroups, AttributeServiceSelector]);
export const AllAttributesAreValidSelector = AllAttributesAreValidHelper([AttributeServiceSelector, CurrentAttributesValidationStatuses]);

export const HasRequiredAndUnavailableInventorySelector = HasRequiredAndUnavailableInventory([FormAttributeGroups]);

export const SummaryTabViewModel = SummaryTab([
    ShoppingCartSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector,
    QuoteCalculatedSelector,
    SelectedContractSelector,
    TotalRemainingAmountSelector,
    () => {
        return undefined;
    },
    TotalDownPaymentSelector,
    PostQuoteDueTotalsSelector,
    PreQuoteDueSubTotalsSelector
]);

const SummaryTabFinancedGroupSelector = GetSummaryTabFinancedGroupSelector([SummaryTabViewModel]);

export const FinancedItemsForDownpaymentSelector = createSelector(
    [SummaryTabFinancedGroupSelector],
    (financedProductGroup) => {
        return pathOr([], ['products'], financedProductGroup).filter((product) => {
            return product.isAdded;
        });
    }
);

export const FinancedProductGroupInCartSelector = GetFinancedProductGroupInCartSelector([
    FinancedItemsForDownpaymentSelector,
    OfferQuoteSelector
]);

export const GoToPreviousStepIsValidSelector = createSelector(
    [IsDecisionsStepSelector, CompletedStepsSelector, BackButtonTextSelector],
    (isDecisionsStep, completedSteps, backButtonText) => {
        return !isDecisionsStep && Object.keys(completedSteps).length > 0 && !!(backButtonText);
    }
);

export const IsFetchingAnyDataSelector = createSelector(
    [PagesIsFetchingDataSelector, IsUpdatingCartSelector],
    (isLoading, isUpdatingCart) => {
        return isLoading || isUpdatingCart;
    }
);
