import {stateGo} from 'redux-ui-router';
import clone from 'ramda/src/clone';
import pathOr from 'ramda/src/pathOr';
import CustomerCareKeys from '../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    fetchAdjustmentReasons,
    fetchCodeTypes
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CreditReasonsLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    DEVICE_FINANCE_STATUS,
    NON_BULK_SERVICE_STATUSES
} from 'invision-core/src/constants/status.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    DeviceFinancingNameSelector,
    IsFetchingDeviceReturnDetailsSelector,
    SubscriberDeviceFinancingCurrencySelector,
    SubscriberDeviceFinancingDetailsDropdownSelector,
    SubscriberDeviceFinancingDetailsSelector,
    SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector,
    SubscriberDeviceFinancingWidgetActiveDiscountDetailsSelector,
    SubscriberDeviceReturnDetailsSelector
} from '../../../../../reducers/selectors/customer.devices.selectors';
import {
    CanUserPerformFinanceAdjustmentSelector,
    CurrentCustomerIdSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {IsCoolingPeriodEnabledSelector} from '../../../../../reducers/selectors/customer.offerings.selectors';
import {createDeviceAdjustment,
    retrieveDeviceRefundAmount,
    setDeviceFinancingWidgetSelectedDevice} from '../../../../../reducers/actions/customer.devices.actions';
import {
    deviceFinancingFriendlyName,
    deviceFinancingImage,
    isDeviceFinanceCoolingOffStatus
} from './device.financing.helper';
import {DOWN_PAYMENT_TYPE} from '../../../../../customercare.constants';
import {DEPOSIT_ADJUSTMENT} from '../../../../adjustment/adjustment.modal.constants';
import {MAKE_PAYMENT_ROUTE} from '../../../makePayment/make.payment.constants';
import {INVENTORY_STATE_NAME} from '../../../inventory/inventory.config';
import {CanReturnDeviceSelector} from '../../../../../reducers/selectors/apply.credit.modal.selectors';
import {ONE_TIME_MAKE_PAYMENT} from '../../../../../security.attributes';
class DeviceFinancingController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            availableDevicesOptions: [],
            CustomerCareKeys,
            DEVICE_FINANCE_STATUS,
            deviceFinancingFriendlyName,
            deviceFinancingImage,
            DOWN_PAYMENT_TYPE,
            isDeviceFinanceCoolingOffStatus,
            isExpanded: true,
            NON_BULK_SERVICE_STATUSES,
            onCloseReturnDeviceModal: this.onCloseReturnDeviceModal.bind(this),
            onMoreActionsItemSelection: this.onMoreActionsItemSelection.bind(this),
            onSubmitReturnDeviceModal: this.onSubmitReturnDeviceModal.bind(this),
            thumbnailHeight: 95,
            thumbnailWidth: 65,
            toolTipTemplate:  require('./device.finance.tooltip.template.info.html'),
            uiNotificationService
        });
    }

    $onInit() {
        const controllerActions = {
            createDeviceAdjustment,
            fetchAdjustmentReasons,
            fetchCodeType: fetchCodeTypes,
            retrieveDeviceRefundAmount,
            setDeviceFinancingWidgetSelectedDevice,
            stateGo
        };

        const mapStateToTarget = (store) => {
            const moreMenuItems = this.getMoreMenuItems();
            return {
                adjustmentReasonsLoaded: MetadataCodeLoadedSelector(CODES.BalanceAdjustmentReason, store),
                adjustmentReasonOptions: MetadataOptionsForCodeValuesSelector(CODES.BalanceAdjustmentReason, store),
                availableDevices: SubscriberDeviceFinancingDetailsDropdownSelector(store),
                canReturnDevice: CanReturnDeviceSelector(store),
                canUserPerformFinanceAdjustment: CanUserPerformFinanceAdjustmentSelector(store),
                coolingPeriodLoaded:  MetadataCodeLoadedSelector(CODES.CoolingPeriod, store),
                creditReasonsLoaded: CreditReasonsLoadedSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                deviceFinancingCurrencyCode: SubscriberDeviceFinancingCurrencySelector(store),
                deviceFinancingDetails: SubscriberDeviceFinancingDetailsSelector(store),
                deviceFinancingName: DeviceFinancingNameSelector(store),
                deviceReturnDetails: SubscriberDeviceReturnDetailsSelector(store),
                isCoolingPeriodEnabledForBU: IsCoolingPeriodEnabledSelector(store),
                isDbss: IsDbss(store),
                isFetchingDeviceReturnDetails: IsFetchingDeviceReturnDetailsSelector(store),
                moreMenuItems,
                reasonOptions: MetadataOptionsForCodeValuesSelector(CODES.CreditReasons, store),
                selectedDevice: SubscriberDeviceFinancingWidgetActiveDeviceDetailsSelector(store),
                selectedDeviceFinanceDiscounts: SubscriberDeviceFinancingWidgetActiveDiscountDetailsSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.updateOptions();

        this.adjustmentModalConfig = {
            onRegisterApi: ({api}) => {
                this.adjustmentModalApi = api;
            }
        };

        this.returnDeviceModalConfig = {
            onRegisterApi: ({api}) => {
                this.returnDeviceModalConfigApi = api;
            }
        };

        this.discountToolTip = require('../../../../shared/tooltipTemplates/discount.tooltip.html');

        this.fetchCodeTypes().then(() => {
            if (isDeviceFinanceCoolingOffStatus(this.state.selectedDevice) && this.state.isCoolingPeriodEnabledForBU) {
                this.isCoolingOffEnabled = true;
                this.tooltipContext = {
                    title: i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING_COOLING_PERIOD)
                };
            } else {
                this.isCoolingOffEnabled = false;
            }
        });
    }

    fetchCodeTypes() {
        const promises = [];
        if (!this.state.creditReasonsLoaded) {
            promises.push(this.actions.fetchCodeType(CODES.CreditReasons));
        }

        if (!this.state.coolingPeriodLoaded) {
            promises.push(this.actions.fetchCodeType(CODES.CoolingPeriod));
        }

        if (!this.state.adjustmentReasonsLoaded) {
            this.actions.fetchAdjustmentReasons();
        }

        return Promise.all(promises);
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    discountTooltipText() {
        if (this.state.selectedDeviceFinanceDiscounts.length > 0) {
            return `${this.state.selectedDeviceFinanceDiscounts.length} ${i18n.translate(this.state.selectedDeviceFinanceDiscounts.length === 1
                ? CustomerCareKeys.DISCOUNT : CustomerCareKeys.DISCOUNTS_LABEL)}`;
        }
    }

    updateSelectedDevice(option) {
        this.actions.setDeviceFinancingWidgetSelectedDevice(option.index);
        this.updateOptions();
    }

    onAdjustmentClose() {
        this.adjustmentModalApi.close();
        this.showAdjustmentModal = false;
    }

    onSubmitAdjustmentModal() {
        this.retrieveConvergentBillerAndDeviceFinancingDetails && this.retrieveConvergentBillerAndDeviceFinancingDetails();
        this.refreshServices && this.refreshServices();
        this.onAdjustmentClose();
    }

    onMoreActionsItemSelection(option) {
        switch (option.item.id) {
            case this.CustomerCareKeys.VIEW_DETAILS:
                this.actions.stateGo(INVENTORY_STATE_NAME, {
                    customerId: this.state.currentCustomerId,
                    inventoryId: pathOr(null, ['state', 'selectedDevice', 'SubscriberInventory', 'Id'], this)
                });
                break;
            case this.CustomerCareKeys.MAKE_A_PAYMENT:
                this.actions.stateGo(MAKE_PAYMENT_ROUTE, {
                    account: this.state.selectedDevice.DeviceFinanceAccountNumber
                });
                break;
            case this.CustomerCareKeys.ADJUSTMENTS.APPLY_ADJUSTMENT:
                option.item.action();
                break;
            case this.CustomerCareKeys.INVENTORY.RETURN_DEVICE:
                option.item.action();
                break;
            default:
                break;
        }
    }

    getMoreMenuItems() {
        let moreMenuItems = [];

        if (this.state && this.state.selectedDevice && this.state.selectedDevice.DeviceFinanceAccountNumber
            && this.state.canUserPerformFinanceAdjustment) {
            moreMenuItems = [{
                id: this.CustomerCareKeys.VIEW_DETAILS,
                title: i18n.translate(this.CustomerCareKeys.VIEW_DETAILS)
            }, {
                id: this.CustomerCareKeys.ADJUSTMENTS.APPLY_ADJUSTMENT,
                title: i18n.translate(this.CustomerCareKeys.ADJUSTMENTS.APPLY_ADJUSTMENT),
                action: () => {
                    this.adjustmentType = DEPOSIT_ADJUSTMENT;
                    this.showAdjustmentModal = true;
                    this.$timeout(() => {
                        this.adjustmentModalApi.open();
                    });
                }
            }];
        } else {
            moreMenuItems = [{
                id: this.CustomerCareKeys.VIEW_DETAILS,
                title: i18n.translate(this.CustomerCareKeys.VIEW_DETAILS)
            }];
        }
        if (this.state && hasAccess(this.state.userSecurityAttributes, ONE_TIME_MAKE_PAYMENT)) {
            moreMenuItems.push({
                id: this.CustomerCareKeys.MAKE_A_PAYMENT,
                title: i18n.translate(this.CustomerCareKeys.MAKE_A_PAYMENT)
            });
        }

        if (this.state && this.state.canReturnDevice) {
            moreMenuItems.push({
                id: this.CustomerCareKeys.INVENTORY.RETURN_DEVICE,
                title: i18n.translate(this.CustomerCareKeys.INVENTORY.RETURN_DEVICE),
                action: () => {
                    this.actions.retrieveDeviceRefundAmount(this.state.selectedDevice.SubscriberInventory && this.state.selectedDevice.SubscriberInventory.SubscriberProductId, this.state.currentCustomerId)
                        .then(() => {
                            this.showReturnDeviceModal = true;
                            this.$timeout(() => {
                                this.returnDeviceModalConfigApi.open();
                            });
                        })
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
            });
        }
        return moreMenuItems;
    }

    onCloseReturnDeviceModal() {
        this.showReturnDeviceModal = false;
        this.returnDeviceModalConfigApi.close();
    }

    onSubmitReturnDeviceModal(deviceDetails) {
        this.actions.createDeviceAdjustment(deviceDetails, this.state.currentCustomerId).then(() => {
            this.retrieveConvergentBillerAndDeviceFinancingDetails && this.retrieveConvergentBillerAndDeviceFinancingDetails();
            this.refreshServices && this.refreshServices();

            this.uiNotificationService.success(i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.DEVICE_RETURN_SUCCESS));
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        }).finally(() => {
            this.onCloseReturnDeviceModal();
        });
    }

    getDeviceFinancingMarketingNameForHeading() {
        return i18n.translate(
            this.CustomerCareKeys.CUSTOMER_DASHBOARD.DEVICE_FINANCING_FOR, {
                deviceFinancingName: this.state.deviceFinancingName
            });
    }

    updateOptions() {
        this.availableDevicesOptions = clone(this.state.availableDevices);
    }

}

export default {
    template: require('./device.financing.html'),
    controller: DeviceFinancingController,
    bindings: {
        refreshServices: '&?',
        retrieveConvergentBillerAndDeviceFinancingDetails: '&?'
    },
};
