import LocaleKeys from '../../locales/keys';
import {
    CurrentRedemptionDetailsSelector,
} from '../../reducers/selectors/coupon.redemption.code.selectors';


class CouponRedemptionCodeController {

    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        this.CustomerLocaleKeys = LocaleKeys;

        this.mapStateToTarget = (store) => {
            return {
                currentRedemptionDetails: CurrentRedemptionDetailsSelector(store),
            };
        };

        this.controllerActions = {};

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {},
    template: require('./coupon.redemption.code.html'),
    controller: CouponRedemptionCodeController,
};
