import angular from 'angular';

import GiftCardComponent from './gift.card.component';
import GiftCardDetailsComponent from './detail/gift.card.detail.component';
import GiftCardActivationListComponent from './list/gift.card.activation.list.component';
import ResendDialogComponent from './resendDialog/resend.gift.card.dialog.component';

import {routing as RoutingConfig} from './gift.card.config';

export default angular.module('invision.customercare.gift.card', [])
    .config(RoutingConfig)
    .component('giftCard', GiftCardComponent)
    .component('giftCardDetails', GiftCardDetailsComponent)
    .component('giftCardActivationList', GiftCardActivationListComponent)
    .component('resendDialog', ResendDialogComponent)
    .name;
