import i18n from 'invision-core/src/components/i18n/i18n';
import {INTERNAL_STATUS} from 'invision-ui/lib/components/messaging/statusIndicator/inventoryInternalStatus/inventory.internal.status.types';

import LocaleKeys from '../../../locales/keys';

export const DEFAULT_MAXIMUM_SLOTS = 5;
export const DEFAULT_PAGE_NUMBER = 1;

export const SEARCH_BY_TYPES =  {
    SERVICE_ID: 1,
    INVENTORY_NAME: 2,
    SERIAL_NUMBER: 3,
    INVENTORY_ID: 4
};

export const inventorySearchByFilters = (selectedType) => {
    return [{
        id: SEARCH_BY_TYPES.SERVICE_ID,
        text: i18n.translate(LocaleKeys.INVENTORY.SERVICE_ID),
        selected: selectedType === SEARCH_BY_TYPES.SERVICE_ID
    }, {
        id: SEARCH_BY_TYPES.INVENTORY_NAME,
        text: i18n.translate(LocaleKeys.INVENTORY.INVENTORY_NAME),
        selected: selectedType === SEARCH_BY_TYPES.INVENTORY_NAME
    }, {
        id: SEARCH_BY_TYPES.SERIAL_NUMBER,
        text: i18n.translate(LocaleKeys.INVENTORY.SERIAL_NUMBER),
        selected: selectedType === SEARCH_BY_TYPES.SERIAL_NUMBER
    }, {
        id: SEARCH_BY_TYPES.INVENTORY_ID,
        text: i18n.translate(LocaleKeys.INVENTORY.INVENTORY_ID),
        selected: selectedType === SEARCH_BY_TYPES.INVENTORY_ID
    }];
};

export const INVENTORY_CATEGORIES =  {
    LOGICAL: 1,
    PHYSICAL: 2
};

export const internalInventoryStatusFilters = () => {
    return [{
        id: INTERNAL_STATUS.ACTIVE,
        text: i18n.translate(LocaleKeys.ACTIVE),
        selected: true
    }, {
        id: INTERNAL_STATUS.RETURNED,
        text: i18n.translate(LocaleKeys.RETURNED)
    }, {
        id: INTERNAL_STATUS.REMOVED,
        text: i18n.translate(LocaleKeys.REMOVED)
    }];
};

export const TAB_IDS = {
    OVERVIEW: 1,
    FINANCING_DETAILS: 2
};

export const inventoryTabs = (hasFinanceBris) => {
    const tabs = [{
        id: TAB_IDS.OVERVIEW,
        name: i18n.translate(LocaleKeys.INVENTORY.OVERVIEW),
        active: true
    }];

    if (hasFinanceBris) {
        tabs.push({
            id: TAB_IDS.FINANCING_DETAILS,
            name: i18n.translate(LocaleKeys.INVENTORY.FINANCING_DETAILS),
            active: false
        });
    }

    return tabs;
};

export const PAYMENT_TYPE = {
    ACPBILL: 23,
    AMAZON_ACCOUNT: 13,
    BATCH: 22,
    BRAIN_TREE: 26,
    CASH: 99,
    CHECK_REFUND: 19,
    CHECK: 98,
    CONVERGENTBILLER_INVOICE: 15,
    CONVERSION: 14,
    CREDIT_CARD: 0,
    CURRENCY_ENTITLEMENT: 21,
    DIRECT_DEBIT: 8,
    E_CHECK: 1,
    EXTERNAL_BILL: 4,
    EXTERNAL_GIFT_CARD: 10,
    FACEBOOK_ACCOUNT: 7,
    GIFT_CARD: 3,
    GOOGLEPLAY_ACCOUNT: 12,
    ITUNES_ACCOUNT: 6,
    LOCKBOX_PAYMENT: 27,
    PAYPAL_ACCOUNT: 5,
    POINT_OF_SALE: 20,
    ROKU_ACCOUNT: 11,
    STORED_VALUE_ACCOUNT: 2,
    UWP_ACCOUNT: 25,
    XBOX_ACCOUNT: 9
};
