export const PROFILE_OPTION_REACTIVATE_ID = 'PROFILE_OPTION_REACTIVATE_ID';
export const PROFILE_OPTION_SCHEDULE_ANONYMIZATION_ID = 'PROFILE_OPTION_SCHEDULE_ANONYMIZATION_ID';
export const PROFILE_OPTION_ANONYMIZE_IMMEDIATELY_ID = 'PROFILE_OPTION_ANONYMIZE_IMMEDIATELY_ID';
export const PROFILE_ACTIVE_STATUS = '1';
export const PROFILE_REMOVED_STATUS = '2';
export const PROFILE_ANONYMIZED_STATUS = '3';
export const PROFILE_EVENTS = {
    OPEN_EDIT_CUSTOMER_MODAL: 'OPEN_EDIT_CUSTOMER_MODAL'
};

export const EMAIL_VERIFICATION_STATUS = {
    SUSPENDED: 1,
    PENDING_APPROVAL: 2
};