import LocaleKeys from '../../../../../../locales/keys';

class MobileNumberPortabilityConfirmationPopupController {
    constructor() {
        Object.assign(this, {
            LocaleKeys,
            getTitle: this.getTitle.bind(this),
        });
    }

    getTitle() {
        if (this.isApproved === undefined) {
            return undefined;
        }
        return this.isApproved ? LocaleKeys.MOBILE_NUMBER_PORTABILITY.CONFIRM_TITLE : LocaleKeys.MOBILE_NUMBER_PORTABILITY.ERROR_TITLE;
    }
}

export default {
    template: require('./mobile.number.portability.confirmation.popup.template.html'),
    controller: MobileNumberPortabilityConfirmationPopupController,
    bindings: {
        number: '<',
        provider: '<',
        isApproved: '<',
        onEdit: '&',
        onCancel: '&',
        onAccept: '&',
        errorMessage: '<'
    }
};
