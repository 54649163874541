import LocaleKeys from '../../../locales/keys';

class GiftCardDetailsController {

    constructor() {
        this.CustomerCareLocaleKeys = LocaleKeys;
    }

    $onInit() {
        if (this.giftCard.Pin) {
            const lastFourIdx = this.giftCard.Pin.length - 4;
            this.giftCard.maskedPin = `**** **** **** ${this.giftCard.Pin.substring(lastFourIdx)}`;
        }
    }
}

export default {
    bindings: {
        giftCard: '<',
        subscriberClick: '&'
    },
    template: require('./gift.card.detail.html'),
    controller: GiftCardDetailsController
};
