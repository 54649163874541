import LocaleKeys from '../../../../../locales/keys';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
import {formValidated} from '../../../../../reducers/actions/mobile.number.portability.actions';

class MobileNumberPortabilityController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CoreLocaleKeys,
            LocaleKeys,
            onContinue: this.onContinue.bind(this),
            onEdit: this.onEdit.bind(this),
            showConfirmation: this.showConfirmation.bind(this),
            uiNotificationService,
        });
    }

    $onInit() {
        this.disconnectRedux = this.connectRedux();
        this.setVars();
        this.fetchDataCodes();
        this.setConfig();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    connectRedux() {
        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(__, store),
            };
        };

        const controllerActions = {
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            formValidated
        };

        return this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    fetchDataCodes() {
        const metadataCodes = [
            MetadataConstants.codes.AddressCountry,
            MetadataConstants.codes.AddressStateProvinceRegion,
            MetadataConstants.codes.MnpServiceProviderConfiguration
        ];
        metadataCodes.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType);
            }
        });
    }

    setVars() {
        this.number = '';
        this.provider = '';
        this.isApproved = false;
        this.inventoryTypeId = '';
        this.selectedAttribute = {};

        this.formPopupConfig = {
            onRegisterApi: ({api}) => {
                this.formPopupApi = api;
            }
        };
        this.confirmPopupConfig = {
            onRegisterApi: ({api}) => {
                this.confirmPopupApi = api;
            }
        };
    }

    onEdit(attribute) {
        if (attribute) {
            this.inventoryTypeId  = attribute.inventoryTypeId;
            this.selectedAttribute = attribute;
        }
        this.closeConfirmation();
        this.openForm();
    }

    onCancel() {
        this.closeForm();
        this.closeConfirmation();
    }

    onContinue(addressData, portabilityData) {
        this.addressData = addressData;
        this.portabilityData = portabilityData;
        this.mnpVerificationCallBack()(addressData, portabilityData, this.inventoryTypeId);
        this.closeForm();
    }

    showConfirmation(token, errorMessage) {
        this.isApproved = !!token;
        this.number = this.portabilityData.existingNumber;
        this.provider = this.portabilityData.providerLabel;
        this.mnpVerificationErrorMessage = errorMessage;
        this.closeForm();
        this.openConfirmation();
    }

    openConfirmation() {
        this.confirmationOpen = true;
        this.$timeout(() => {
            this.confirmPopupApi.open();
        });
    }

    closeConfirmation() {
        this.confirmPopupApi.close();
        this.confirmationOpen = false;
    }

    openForm() {
        this.formOpen = true;
        this.$timeout(() => {
            this.formPopupApi.open();
        });
    }

    closeForm() {
        this.formPopupApi.close();
        this.formOpen = false;
    }

    onAccept() {
        this.closeConfirmation();
        this.actions.formValidated();
        this.onVerificationComplete()(clone(this.addressData), clone(this.portabilityData));
    }

    setConfig() {
        this.popupConfig.onRegisterApi({
            api: {
                open: this.onEdit,
                showConfirmation: this.showConfirmation
            }
        });
    }
}

export default {
    template: require('./mobile.number.portability.template.html'),
    controller: MobileNumberPortabilityController,
    bindings: {
        popupConfig: '<',
        onVerificationComplete: '&',
        mnpVerificationCallBack: '&'
    }
};
