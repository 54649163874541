import CustomerCareLocaleKeys from '../../../../../locales/keys';

class StoredValueAccountDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
    }

    $onInit() {
        this._isInitialized = true;
    }
}

export const StoredValueAccountDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: StoredValueAccountDetailController,
    template: require('./stored.value.account.detail.html')
};
