import localeKeys from '../../../../../locales/keys';

class TransactionDetailsSvaController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.sva.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsSvaController
};
