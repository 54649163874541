import LocaleKeys from '../../../locales/keys';
import {
    formattedCreditCardExpiration,
    isExpired
} from '../../../utilities/credit.card.helper';

class ChangePaymentPopupController {

    $onChanges(changesObj) {
        if (changesObj.instrumentSummaries) {
            this.paymentInstrumentViewModel = {
                paymentInstruments: changesObj.instrumentSummaries.currentValue.map((summary) => {
                    return {
                        id: summary.Id,
                        name: summary.Name,
                        isCreditCard: !!summary.CreditCard,
                        isCurrencyEntitlement: !!summary.CurrencyEntitlement,
                        isStripeAccount: !!summary.StripeAccount,
                        expirationDate: formattedCreditCardExpiration(summary),
                        flagged: summary.Flagged,
                        currentBalance: summary.CurrencyEntitlement && summary.CurrencyEntitlement.CurrentBalance,
                        currency: summary.CurrencyEntitlement && summary.CurrencyEntitlement.Currency,
                        isExpired: isExpired(summary),
                        stripePaymentSummary: `${summary?.StripeAccount?.PaymentMethodType} ${summary?.StripeAccount?.PaymentMethodSubType ? `- ${summary?.StripeAccount?.PaymentMethodSubType}` : ''}`,
                        typeName: summary.TypeName
                    };
                })
            };
        }
    }

    $onInit() {
        this.LocaleKeys = LocaleKeys;
        this.currentPaymentInstrument = this.paymentInstrument;
    }

    closePopup() {
        this.onClose()();
    }

    changeSelectedPayment(selectedPaymentId) {
        if (selectedPaymentId !== (this.paymentInstrument && this.paymentInstrument.Id)) {
            this.paymentInstrument = this.instrumentSummaries.find((paymentInstrument) => {
                return paymentInstrument.Id === selectedPaymentId;
            });
        }
    }

    submitPopup() {
        this.onSuccess()(this.paymentInstrument);
    }
}

export default {
    template: require('./change.payment.popup.html'),
    bindings: {
        config: '<',
        onClose: '&',
        onSuccess: '&',
        paymentInstrument: '<',
        instrumentSummaries: '<'
    },
    controllerAs: 'ChangePaymentPopupController',
    controller: ChangePaymentPopupController,
};
