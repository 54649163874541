import {CustomerCareRedeemGiftOrderWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
import {ORDER_ACCESS, ORDERING_ACCESS} from '../../../security.attributes';
export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.redeemGiftOrder', {
        url: '/redeemGiftOrder',
        template: '<redeem-gift-order></redeem-gift-order>',
        data: {
            windowTitle: CustomerCareRedeemGiftOrderWindowTitleSelector,
        },
        securityAttributes: [ORDER_ACCESS],
        adminSecurityAttributes: [ORDERING_ACCESS]
    });
}