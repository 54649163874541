import angular from 'angular';
import 'invision-core';

import {routing as RoutingConfig} from './redeem.gift.order.config';
import RedeemGiftOrder from './redeem.gift.order.component';

export default angular.module('invision.customercare.customer.redeem.gift.order', [
    'invision.core.components'
])
    .component('redeemGiftOrder', RedeemGiftOrder)
    .config(RoutingConfig)
    .name;
