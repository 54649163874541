import equals from 'ramda/src/equals';
import CustomerCareLocaleKeys from '../../locales/keys';
import {INITIAL_STATE as CREATE_CUSTOMER_INITIAL_STATE} from '../../reducers/create.customer.reducer';
import {
    AvailableContactPreferencesDictionarySelector,
    CreateCustomerConsentsMandatorySelector,
    CreateEditCustomerConsentsOptionalSelector,
    EditCustomerConsentsMandatorySelector,
    IsGdprCompliantSelector,
    NewConnectCustomerConsentsSelector,
    SelectedCustomerHomeCountrySelector,
    SelectedCustomerConsentsMandatoryUnacceptedSelector,
    CustomerPreferredTaxCurrenciesSelector
} from '../../reducers/selectors/customer.selectors';
import {IsCustomerStepSelector} from '../../reducers/selectors/new.connect.wizard.selectors';
import {
    CustomerTaxCategoryExemptionsSelector,
    NotificationPreferencesSelector,
    TaxCategoryExemptionsSelector,
    TaxLevelExemptionsSelector
} from '../createCustomerPopup/customer.form.dropdown.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {BillingConfigurationLoadedSelector} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.selectors';
import {SubscriberAdditionalPropertiesSelector as SubscriberAdditionalPropertiesInputs} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {BILLING_CONFIGURATION_TYPES} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.action.types';
import {
    fetchConsents,
    fetchCodeTypesThunk,
    fetchMinimumAgeCountry,
    fetchTaxLevels
} from 'invision-core/src/components/metadata/codes/codes.actions';
import BillingActions from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.actions';
import {getErrorLookupObject} from './create.customer.constants';
import {CONSENT_TYPES} from '../createCustomerPopup/create.customer.popup.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

const mapStateToTarget = (store) => {
    return {
        contactEventTypesLoaded: MetadataCodeLoadedSelector(CODES.ContactEvent, store),
        contactEventTypes: MetadataCodeTypeSelector(CODES.ContactEvent, store),
        contactMapping: MetadataCodeTypeSelector(CODES.ContactMapping, store),
        contactMappingLoaded: MetadataCodeLoadedSelector(CODES.ContactMapping, store),
        contactMethodOptionsLoaded: MetadataCodeLoadedSelector(CODES.ContactMethod, store),
        contactMethodOptions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.ContactMethod, store),
        createCustomerMandatoryConsents: CreateCustomerConsentsMandatorySelector(store),
        customerPreferredTaxCurrencies: CustomerPreferredTaxCurrenciesSelector(store),
        customerTaxCategoryExemptions: CustomerTaxCategoryExemptionsSelector(store),
        contactPreferencesDictionary: AvailableContactPreferencesDictionarySelector(store),
        defaultPreferredReportingTaxValues: MetadataCodeTypeSelector(CODES.DefaultPreferredTaxReportingCurrencyConfig, store),
        editCustomerMandatoryConsents: EditCustomerConsentsMandatorySelector(store),
        isConsentsLoaded: MetadataCodeLoadedSelector(CODES.Consents, store),
        isCustomerStep: IsCustomerStepSelector(store),
        isDbss: IsDbss(store),
        isGdprCompliant: IsGdprCompliantSelector(store),
        isMinimumAgeCountryLoaded: MetadataCodeLoadedSelector(CODES.MinimumAgeCountry, store),
        isTaxCategoryCodesLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_CATEGORY_CODE, store),
        isTaxLevelCodesLoaded: MetadataCodeLoadedSelector(CODES.TaxLevel, store),
        isTaxTypeCodesLoaded: BillingConfigurationLoadedSelector(BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_TYPE_CODE, store),
        minimumAgeCountries: MetadataCodeTypeSelector(CODES.MinimumAgeCountry, store),
        newConnectCustomerConsents: NewConnectCustomerConsentsSelector(store),
        notificationCategoryPreference: NotificationPreferencesSelector(store),
        optionalConsents: CreateEditCustomerConsentsOptionalSelector(store),
        taxCategoryExemptions: TaxCategoryExemptionsSelector(store),
        taxLevelExemptions: TaxLevelExemptionsSelector(store),
        subscriberAdditionalPropData: SubscriberAdditionalPropertiesInputs(store),
        subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
        selectedCustomerHomeCountry: SelectedCustomerHomeCountrySelector(store),
        selectedCustomerUnacceptedConsents: SelectedCustomerConsentsMandatoryUnacceptedSelector(store)
    };
};

class CreateCustomerController {

    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.ConsentTypes = CONSENT_TYPES;
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
    }

    $onInit() {
        const controllerActions = {
            fetchCodeTypesThunk,
            getConsents: fetchConsents,
            getMinimumAgeCountry: fetchMinimumAgeCountry,
            getTaxCategoryCodes: BillingActions.getTaxExemptionCategories,
            getTaxLevelCodes: fetchTaxLevels,
            getTaxExemptionTypes: BillingActions.getTaxExemptionTypes
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.isDbss) {
            if (!this.state.isTaxCategoryCodesLoaded) {
                this.actions.getTaxCategoryCodes();
            }
            if (!this.state.isTaxTypeCodesLoaded) {
                this.actions.getTaxExemptionTypes();
            }
            if (!this.state.isTaxLevelCodesLoaded) {
                this.actions.getTaxLevelCodes();
            }
        }

        if (!this.state.isConsentsLoaded) {
            this.actions.getConsents();
        }
        if (!this.state.contactEventTypesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ContactEvent);
        }
        if (!this.state.contactMappingLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ContactMapping);
        }
        if (!this.state.contactMethodOptionsLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.ContactMethod);
        }
        if (!this.state.isMinimumAgeCountryLoaded) {
            this.actions.getMinimumAgeCountry();
        }
        if (!this.state.notificationCategoryPreference.length) {
            this.actions.fetchCodeTypesThunk(CODES.NotificationCategories);
        }

        this.errorLookup = getErrorLookupObject({
            birthDate: {
                outOfRange: {
                    maxAge: this.state.subscriberRequirements.maximum_age,
                    minAge: this.state.subscriberRequirements.minimum_age
                }
            }
        });

        this.handleFormChanged = (updatedCustomerModel) => {
            Object.assign(this.customerModel, updatedCustomerModel);
            this.onFormChanged()(this.customerModel);
        };
    }

    $onChanges() {
        /* if the one way bindings change from the parent, then we should set the form to pristine to remove any
         and all validation messages. */
        if (this.formErrors.length === 0 && this.createCustomerForm && equals(this.customerModel, CREATE_CUSTOMER_INITIAL_STATE)) {
            this.createCustomerForm.$setPristine();
            this.createCustomerForm.$setUntouched();
            if (this.createCustomerForm.addlApi) {
                this.createCustomerForm.addlApi.$setPristine();
                this.createCustomerForm.addlApi.$setUntouched();
            }
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    get isMandatoryConsentsToBeAccepted() {
        return this.mandatoryConsents.length > 0 && !this.customerModel.ConsentAcceptingMandatoryTerms.IsAccepted;
    }

    get mandatoryConsents() {
        if (this.isNewCustomer && this.state.isCustomerStep) {
            return this.state.newConnectCustomerConsents;
        } else if (this.isNewCustomer) {
            return this.state.createCustomerMandatoryConsents;
        }  else {
            if (this.customerModel.HomeCountry === this.state.selectedCustomerHomeCountry) {
                return this.state.selectedCustomerUnacceptedConsents;
            } else {
                return this.state.editCustomerMandatoryConsents;
            }
        }
    }

    get optionalConsents() {
        if (this.isNewCustomer) {
            return this.state.createCustomerOptionalConsents;
        } else {
            return this.state.editCustomerOptionalConsents;
        }
    }
}

export default {
    template: require('./create.customer.html'),
    bindings: {
        addressStateOptions: '<',
        customerModel: '<',
        driversLicenseStateOptions: '<',
        formErrors: '<',
        formattedSubscriberIdentities: '<',
        invoiceDisplayOptions: '<',
        isAccountingMethodReadonly: '<?',
        isCountryDisabled: '<',
        isCreditCheckPopup: '<?',
        isCurrencyDisabled: '<',
        isCustomerTypeReadOnly: '<?',
        isNewConnect: '<?',
        isNewCustomer: '<',
        isLiteCustomerEnabledHousehold: '<',
        isParentNodeDisabled: '<?',
        isSubmitted: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?',
        onFormChanged: '&',
        onFormSubmit: '&',
        onPropertyChange: '&',
        overrideValidations: '<?',
        removeMultiOfferValidations: '<?',
        selectedCustomerSubtenant: '<',
        showAddressSection: '<',
        showCreditCheckAddressSection: '<?'
    },
    controller: CreateCustomerController,
    controllerAs: 'CreateCustomer'
};
