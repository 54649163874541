import moment from 'moment';
import head from 'ramda/src/head';
import isNil from 'ramda/src/isNil';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import reject from 'ramda/src/reject';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    fetchCodeTypes,
    fetchProductLineOfBusinessDeliveryCapability,
    fetchProductLineOfBusinessDeliveryCapabilityConfigurationAction
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    hasAccess,
    hasAdminAccess
} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerLocaleKeys from '../../../../../locales/keys';
import {NOTIFICATION_TIME_LENGTH} from '../../../../../customercare.constants';
import {
    GRANT_ADDITIONAL_RIGHTS,
    ORDERING_ACCESS
} from '../../../../../security.attributes';
import {
    CurrentCustomerIdSelector,
    RouteParams,
    RouteState
} from '../../../../../reducers/selectors/customer.selectors';
import {
    ActiveProductSelector,
    CanViewContentActivitySelector,
    CustomerProductsCountSelector,
    CustomerRecentProductsCollectionSelector,
    IsRemovingProductSelector
} from '../../../../../reducers/selectors/customer.products.selectors';
import {
    retrieveProducts,
    setActiveProduct
} from '../../../../../reducers/actions/customer.products.actions';
import {RECENT_PRODUCTS_TO_DISPLAY} from '../../../../../reducers/constants/dashboard.constants';
import {CREATE_PRODUCTS_ORDER_STATE_NAME as ADD_PRODUCT_STATE} from '../../../../../reducers/constants/products.wizard.constants';
import {DETAIL_STATE_OR_NAME as CONTENT_ACTIVITY_STATE} from '../../../contentActivity/content.activity.config';
import {DETAIL_STATE_OR_NAME as PRODUCT_STATE} from '../../../products/products.config';

const ACTIVE_PRODUCT_IMAGE_WIDTH = 180;
const PRODUCT_IMAGE_WIDTH = 120;

class ProductsController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            customerCareKeys: CustomerLocaleKeys,
            uiNotificationService
        });

        this.closeRemoveProductsPopup = this.closeRemoveProductsPopup.bind(this);
        this.submitRemoveProductsPopup = this.submitRemoveProductsPopup.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                activeProduct: ActiveProductSelector(store),
                canViewContentActivity: CanViewContentActivitySelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                deliveryCapabilities: MetadataCodeSelector(CODES.ProductLineOfBusinessDeliveryCapability, store),
                deliveryCapabilitiesLoaded: MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapability, store),
                deliveryCapabilityConfigurationLoaded: MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapabilityConfigurationAction, store),
                isDbss: IsDbss(store),
                isRemovingProduct: IsRemovingProductSelector(store),
                productsCount: CustomerProductsCountSelector(store),
                recentProducts: CustomerRecentProductsCollectionSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteState(store),
                structureTypes: MetadataCodeTypeSelector(CODES.StructureType, store),
                structureTypesLoaded: MetadataCodeLoadedSelector(CODES.StructureType, store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeType: fetchCodeTypes,
            fetchProductLineOfBusinessDeliveryCapability: fetchProductLineOfBusinessDeliveryCapability,
            fetchDeliveryCapabilityConfiguration: fetchProductLineOfBusinessDeliveryCapabilityConfigurationAction,
            retrieveProducts,
            setActiveProduct,
            stateGo
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.activeProductImageWidth = ACTIVE_PRODUCT_IMAGE_WIDTH;
        this.productImageWidth = PRODUCT_IMAGE_WIDTH;
        this.currentDateTime = new Date();

        this.addProductAction = {
            id: 'ADD_PRODUCT',
            title: i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.ADD_PRODUCT),
            callback: () => {
                this.goToAddProductWizard();
            },
            shouldShow: () => {
                return this.hasOrderingAccess();
            }
        };

        this.actionsList = [
            {
                id: 'PRODUCT_DETAILS',
                title: i18n.translate(CustomerLocaleKeys.VIEW_DETAILS),
                callback: () => {
                    this.goToProductDetails();
                },
                shouldShow: () => {
                    return hasAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
                }
            },
            {
                id: 'VIEWING_ACTIVITY',
                title: i18n.translate(CustomerLocaleKeys.VIEW_CONTENT_ACTIVITY),
                callback: () => {
                    this.goToProductContentActivity();
                },
                shouldShow: () => {
                    return this.state.canViewContentActivity;
                }
            },
            {
                id: 'REMOVE_PRODUCTS',
                title: i18n.translate(CustomerLocaleKeys.REMOVE_PRODUCTS.TITLE),
                callback: () => {
                    this.openRemoveProductsPopup();
                },
                shouldShow: () => {
                    return this.hasOrderingAccess() &&
                        !(pathOr(false, ['Product', 'Standalone'], this.state.activeProduct) &&
                            this.state.isDbss);
                }
            },
            {
                id: 'GRANT_ACCESS',
                title: i18n.translate(CustomerLocaleKeys.GRANT_ACCESS.GRANT_ACCESS),
                callback: () => {
                    this.openGrantAccessRightsPopup();
                },
                shouldShow: () => {
                    const expired = pathOr(null, ['activeProduct', 'Expired'], this.state);
                    return hasAccess(this.state.userSecurityAttributes, GRANT_ADDITIONAL_RIGHTS) && expired;
                }
            }
        ];
        if (!this.isContentActivityEnabled) {
            const updatedActionsList = this.actionsList.filter((item) => {
                return item.id !== 'VIEWING_ACTIVITY';
            });
            this.actionsList = updatedActionsList;
        }

        if (this.hasWidgetAccess()) {
            if (!this.state.structureTypesLoaded) {
                this.actions.fetchCodeType(CODES.StructureType);
            }

            if (!this.state.deliveryCapabilitiesLoaded) {
                this.actions.fetchProductLineOfBusinessDeliveryCapability();
            }

            if (!this.state.deliveryCapabilityConfigurationLoaded) {
                this.actions.fetchDeliveryCapabilityConfiguration();
            }

            if (!isNil(this.state.currentCustomerId)) {
                this.getRecentProductsAndUpdate();
            }

        }

        this.grantAccessRightsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.grantAccessRightsPopupConfig = api;
            }
        };

        this.showGrantAccessModal = false;
        this.removeProductsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeProductsPopupConfig = api;
            }
        };
    }

    $onChanges(changes) {
        if (changes.customerId &&
            !isNil(this.state.currentCustomerId) &&
            !changes.customerId.isFirstChange() &&
            this.state &&
            this.hasWidgetAccess()) {
            this.getRecentProductsAndUpdate();
        }

        if (this.state) {
            this.moreOptionsMenu = [
                this.addProductAction
            ].filter((menuItem) => {
                return menuItem.shouldShow();
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    submitGrantAccessRightsPopup() {
        this.closeGrantAccessRightsPopup();
        this.getRecentProductsAndUpdate();

        this.uiNotificationService.success(i18n.translate(this.customerCareKeys.GRANT_ACCESS.SUCCESSFULLY_UPDATED), null, {
            timeout: NOTIFICATION_TIME_LENGTH
        });
    }

    closeGrantAccessRightsPopup() {
        this.grantAccessRightsPopupConfig.close();
        this.showGrantAccessModal = false;
    }

    openGrantAccessRightsPopup() {
        this.showGrantAccessModal = true;

        this.$timeout(() => {
            this.grantAccessRightsPopupConfig.open();
        });
    }

    getExpirationDate(policies) {
        if (policies) {
            //this finds the expiration date from a list of purchase policies on a locker item
            const expirationDate = head(reject(isNil, pluck('ExpirationDate')(policies)));
            this.isExpired = moment(expirationDate).isBefore(moment());

            return expirationDate;
        }
    }

    getAssociatedCapability(deliveryCapability) {
        const associatedCapability = this.state.deliveryCapabilities.items.find((capability) => {
            return deliveryCapability && deliveryCapability.toString() === capability.Value;
        });

        return associatedCapability ? associatedCapability.Name : '';
    }

    getRecentProductsAndUpdate() {
        this.actions.retrieveProducts(this.state.currentCustomerId, {
            IncludeEntitlementContext: true,
            IncludeExpired: true,
            PageSize: RECENT_PRODUCTS_TO_DISPLAY,
        })
            .then(() => {
                this.updateCountAndActive();
            });
    }

    goToAddProductWizard() {
        this.actions.stateGo(ADD_PRODUCT_STATE, {
            productsOnly: true
        });
    }

    goToProductContentActivity() {
        this.actions.stateGo(CONTENT_ACTIVITY_STATE, {
            productId: this.state.activeProduct.Product.Id,
            previousRoute: {
                name: this.state.routeState.name,
                params: this.state.routeParams
            }
        }, {
            reload: true
        });
    }

    goToProductDetails() {
        this.actions.stateGo(PRODUCT_STATE, {
            lockerItemId: this.state.activeProduct.Id
        });
    }

    hasOrderingAccess() {
        return hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
    }

    submitRemoveProductsPopup() {
        this.closeRemoveProductsPopup();

        this.getRecentProductsAndUpdate();
    }

    showAvailabilityDate() {
        return new Date(pathOr(null, ['PricingPlan', 'AvailabilityStart'], this.state.activeProduct)) > this.currentDateTime;
    }

    closeRemoveProductsPopup() {
        this.removeProductsPopupConfig.close();
        this.showRemoveProductsPopup = false;
    }

    hasWidgetAccess() {
        return hasAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
    }

    openRemoveProductsPopup() {
        this.showRemoveProductsPopup = true;

        this.$timeout(() => {
            this.removeProductsPopupConfig.open();
        });
    }

    onMenuItemSelected(selected) {
        return selected.item.callback();
    }

    setActiveProduct(product) {
        this.actions.setActiveProduct(product);
    }

    updateCountAndActive() {
        this.state.activeProduct = this.state.recentProducts[0];
        this.setActiveProduct(this.state.activeProduct);

        this.viewAllText = i18n.translate(CustomerLocaleKeys.CUSTOMER_DASHBOARD.VIEW_ALL_PRODUCTS, {
            count: this.state.productsCount
        });
    }
}

export default {
    template: require('./products.html'),
    controller: ProductsController,
    controllerAs: 'Products',
    bindings: {
        isContentActivityEnabled: '<?'
    }
};
