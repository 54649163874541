import angular from 'angular';

import {routing as RoutingConfig} from './coupon.redemption.code.config';
import CouponRedemptionCode from './coupon.redemption.code.component';
import CouponRedemptionCodeDetails from './details/redemption.code.details.component';
import CouponRedemptionCodeDetailsAssociations from './details/redemption.code.details.associations.component';
import CouponRedemptionCodeDetailsGeneral from './details/redemption.code.details.general.component';
import CouponRedemptionCodeDetailsRedemptions from './details/redemption.code.details.redemptions.component';
import CouponRedemptionCodeDetailsHeader from './header/redemption.code.details.header.component';
import ModifyExpirationDateModal from './details/modifyExpirationDateModal/modify.expiration.date.modal.component';
import RemoveCouponRedemptionCodeModal from './details/removeModal/remove.redemption.code.modal.component';

export default angular.module('invision.customercare.coupon', [])
    .config(RoutingConfig)
    .component('couponRedemptionCode', CouponRedemptionCode)
    .component('redemptionCodeDetails', CouponRedemptionCodeDetails)
    .component('redemptionCodeDetailsAssociations', CouponRedemptionCodeDetailsAssociations)
    .component('redemptionCodeDetailsGeneral', CouponRedemptionCodeDetailsGeneral)
    .component('redemptionCodeDetailsRedemptions', CouponRedemptionCodeDetailsRedemptions)
    .component('redemptionCodeDetailsHeader', CouponRedemptionCodeDetailsHeader)
    .component('modifyExpirationDateController', ModifyExpirationDateModal)
    .component('removeRedemptionCodeModal', RemoveCouponRedemptionCodeModal)
    .name;
