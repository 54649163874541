import CustomerCareLocaleKeys from '../../../../../locales/keys';

class ECheckDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
    }
}

export const ECheckDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: ECheckDetailController,
    template: require('./echeck.detail.html')
};
