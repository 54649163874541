import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../../../locales/keys';
import {REPORTING_LEVELS} from '../../../customer/accountHierarchy/account.hierarchy.constants';
class MoveHierarchyNodeController {
    constructor($timeout) {
        Object.assign(this, {
            LocaleKeys,
            $timeout,
            labelText: i18n.translate(LocaleKeys.ACCOUNT_HIERARCHY.SELECT_THE_NEW_PARENT_FOR),
            nodeBillingTypeIconGlyph: this.nodeBillingTypeIconGlyph.bind(this),
            nodeBillingTypeLabel: this.nodeBillingTypeLabel.bind(this),
            onConfirm: this.onConfirm.bind(this),
            onClose: this.onClose.bind(this)
        });
    }

    $onChanges(changesObj) {
        if (changesObj.newParentNode.currentValue) {
            this.labelText = `${this.newParentNode.NodeName} ${i18n.translate(LocaleKeys.ACCOUNT_HIERARCHY.WILL_BE_THE_NEW_PARENT_FOR)}`;
        }
    }

    nodeBillingTypeIconGlyph() {
        let billingTypeIconGlyph = 'dollar-circle';

        if (this.nodeToMove.ReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT||
            this.nodeToMove.ReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_NONE) {
            billingTypeIconGlyph = 'filled-circled-up-arrow';
        }

        return billingTypeIconGlyph;
    }

    nodeBillingTypeLabel() {
        let billingTypeLocaleKey = LocaleKeys.BILLING;
        let billingTypeLocaleParameters = {};

        if (this.nodeToMove.ReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT ||
            this.nodeToMove.ReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_NONE) {
            billingTypeLocaleKey = LocaleKeys.PASS_THROUGH_BILLING;
            // pass-through billing nodes pass-through to their parent
            billingTypeLocaleParameters = {
                receiver: `${this.nodeToMove.ParentSubscriber.FirstName} ${this.nodeToMove.ParentSubscriber.LastName}`
            };
        }

        return i18n.translate(billingTypeLocaleKey, billingTypeLocaleParameters);
    }

    onClose() {
        this.closeContainer()();
    }

    onConfirm() {
        this.nodeToMove.ParentSubscriberId = this.newParentNode.SubscriberId;
        this.onConfirmation()(this.nodeToMove);
    }

}

export default {
    template: require('./move.hierarchy.node.template.html'),
    controller: MoveHierarchyNodeController,
    controllerAs: 'MoveHierarchyNodeController',
    bindings: {
        nodeToMove: '<',
        newParentNode: '<?',
        closeContainer: '&',
        onConfirmation: '&'
    }
};
