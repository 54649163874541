import clamp from 'ramda/src/clamp';
import curry from 'ramda/src/curry';
import flatten from 'ramda/src/flatten';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import filter from 'ramda/src/filter';
import __ from 'ramda/src/__';

import i18n from 'invision-core/src/components/i18n/i18n';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {ROCWarningTranslatedMessageSelector} from 'invision-core/src/components/warnings/warnings.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataOptionsForCodeIntegerValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {DiscretionaryDiscountsByCurrencySelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';

import debounce from 'lodash/debounce';
import {
    BILLER_RULE_INSTANCE_TYPE,
    DEBOUNCE_TIME_MS
} from '../../../../customercare.constants';
import {
    PRICE_OVERRIDE_ACCESS,
    DISCOUNT_OVERRIDE_ACCESS,
    ISSUE_DISCRETIONARY_DISCOUNT,
    MULTIPLE_DISCOUNT_ACCESS
} from '../../../../security.attributes';
import {ALLOWABLE_ACTIONS} from '../../../shared/constants/general.status.constants';

import {
    ActiveLifeCycleSelector,
    BackDecisionPageTextSelector,
    CompletedDecisionsSelector,
    DecisionsHasErrorSelector,
    DecisionsLifeCycleStepsForOffersSelector,
    DecisionsRequireAffectedServicesResolutionSelector,
    DecisionsRequiringAffectedServicesResolutionSelector,
    DiscretionaryDiscountTableDataSelector,
    IsFetchingBillerRuleInstanceDiscountContextSelector,
    IsFirstDecisionPageSelector,
    IsLastDecisionPageSelector,
    IsUpdatingCartSelector,
    LifeCycleDetailsForPricingPlanSelector,
    LifeCycleStepsPricingPlanSelector,
    LifeCyclesForProductsSelector,
    NextDecisionPageTextSelector,
    PageDisplayOrderSelector,
    PagesIsFetchingDataSelector,
    PagesSelector,
    PagesWithDecisionsSelector,
    PricingPlanChargeDetailsSelector,
    RegularDiscountTableDataSelector,
    SelectedPageHasErrorSelector,
    SelectedPageIdSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {SelectedOfferType} from '../../../../reducers/selectors/customer.convergent.biller.selectors';

// TODO - Customer Order wizard componentized steps should not be importing anything from a specific wizards
import {
    retrieveDecisionOptionServiceIds,
    setDecisionsRequiringAffectedServicesResolution
} from '../../../../reducers/actions/edit.offer.wizard.actions';
import {EditCustomerInfoSelector} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {DecisionPagesViewModel} from './decisions.selectors';
import {
    clearDiscountOverride,
    clearSummaryQuote,
    decisionOptionItemQuantityChanged,
    decisionOptionQuantityChanged,
    decisionOptionSelected,
    decisionOptionServiceIdsForRemovalSelected,
    discountOverrideChanged,
    nonDecisionGroupOptionQuantityChanged,
    retrieveBillerRuleInstanceDiscountContext,
    setDecisionsSelectedPageId,
    setSavedLifeCycleId,
    setSelectedBriSelectedDiscounts
} from '../../../../reducers/actions/offering.order.actions';
import CustomerCareKeys from '../../../../locales/keys';
import {
    BRI_STATUS,
    DecisionTypes,
    DISALLOW_EDIT_REASONS,
    EditDecisionOptionQualificationTypes,
    MINIMUM_DECISIONS,
    OfferTypes,
} from './decisions.constants';
// TODO - Customer Order wizard componentized steps should not be importing anything from a specific wizards
import {BulkChargeTypes} from '../../newConnectWizard/new.connect.wizard.constants';
import PricingTypeDecisions from './pricing.type.decisions';
// TODO - Customer Order wizard componentized steps should not be importing anything from a specific wizards
import {TransitionContextSelector} from '../../../../reducers/selectors/transition.offer.wizard.selectors';
import {SummaryTabViewModel} from '../../../customer/orders/create/offer/add.offer.wizard.selectors';
import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {DependentPricingPlansIdsSelector} from '../../../../reducers/selectors/offering.order.selectors';
import {CHARGE_TIMING} from '../../../../reducers/constants/wizard.constants';
import {getMatchingOneTimeRecurringOrFinanceBriFromBrcId} from '../../../../reducers/selected.offering.order.reducer.helper';
import {buildSegmentationContext} from '../../../../reducers/helpers/offer.ordering.wizard.helper';

const DISCOUNT_TYPE_PERCENTAGE_OFF = '1';

function DecisionsController($ngRedux, $location, $anchorScroll, $timeout, uiNotificationService) {
    let disconnectRedux;
    let editOptionPopupApi;
    let financeBriOverridePopupApi;

    const mapStateToTarget = (store) => {
        return {
            activeLifeCycle: ActiveLifeCycleSelector(store),
            backPageText: BackDecisionPageTextSelector(store),
            cartSummary: SummaryTabViewModel(store),
            codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
            completedDecisions: CompletedDecisionsSelector(store),
            currentCustomerId: CurrentCustomerIdSelector(store),
            customerInfoEdit: EditCustomerInfoSelector(store),
            decisionPagesViewModel: DecisionPagesViewModel(store),
            decisionsRequireAffectedServicesResolution: DecisionsRequireAffectedServicesResolutionSelector(store),
            decisionsRequiringAffectedServicesResolution: DecisionsRequiringAffectedServicesResolutionSelector(store),
            discretionaryDiscountTableData: DiscretionaryDiscountTableDataSelector(store),
            dependentPricingPlansIds: DependentPricingPlansIdsSelector(store),
            discretionaryDiscountReasonCodeOptions: MetadataOptionsForCodeIntegerValuesSelector(CODES.DiscretionaryDiscountReason, store),
            discretionaryDiscountsByCurrency: DiscretionaryDiscountsByCurrencySelector(store),
            hasValidationError: DecisionsHasErrorSelector(store),
            isConvergentBiller: IsDbss(store),
            isFetchingBillerRuleInstanceDiscountContext: IsFetchingBillerRuleInstanceDiscountContextSelector(store),
            isFirstPage: IsFirstDecisionPageSelector(store),
            isLastPage: IsLastDecisionPageSelector(store),
            isLoading: PagesIsFetchingDataSelector(store),
            isUpdatingCart:IsUpdatingCartSelector(store),
            lifeCycleChargeDetails: PricingPlanChargeDetailsSelector(store),
            lifeCyclesForProducts: LifeCyclesForProductsSelector(store),
            lifeCycleDetailsForPricingPlan: LifeCycleDetailsForPricingPlanSelector(store),
            lifeCyclePricingPlans: LifeCycleStepsPricingPlanSelector(store),
            lifeCycleSteps: DecisionsLifeCycleStepsForOffersSelector(store),
            nextPageText: NextDecisionPageTextSelector(store),
            offeringMetadata: OfferingMetadataByIdSelector(store),
            offerType: SelectedOfferType(store),
            pageOrder: PageDisplayOrderSelector(store),
            pagesMap: PagesSelector(store),
            pagesWithDecisions: PagesWithDecisionsSelector(store),
            pricingPlanMetadata: OfferingMetadataPricingPlansSelector(store),
            regularDiscountTableData: RegularDiscountTableDataSelector(store),
            rocWarningTranslatedMessage: ROCWarningTranslatedMessageSelector(store),
            selectedPageHasErrors: SelectedPageHasErrorSelector(store),
            selectedPageId: SelectedPageIdSelector(store),
            transitionContext: TransitionContextSelector(store),
            userSecurityAttributes: UserSecurityAttributesSelector(store)
        };
    };

    this.$onInit = () => {
        const codeTables = [
            CODES.DiscretionaryDiscountReason
        ];

        const controllerActions = {
            clearDiscountOverride,
            clearSummaryQuote,
            decisionOptionItemQuantityChanged,
            decisionOptionQuantityChanged,
            decisionOptionSelected,
            decisionOptionServiceIdsForRemovalSelected,
            discountOverrideChanged,
            fetchCodeTypes,
            nonDecisionGroupOptionQuantityChanged,
            retrieveBillerRuleInstanceDiscountContext,
            retrieveDecisionOptionServiceIds,
            retrieveOfferingsMetadata,
            setDecisionsRequiringAffectedServicesResolution,
            setDecisionsSelectedPageId,
            setSavedLifeCycleId,
            setSelectedBriSelectedDiscounts
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.showDiscountOverridePopup = false;
        this.MINIMUM_DECISIONS = MINIMUM_DECISIONS;
        this.editDecisionQualTypes = EditDecisionOptionQualificationTypes;
        this.BRI_STATUS = BRI_STATUS;
        this.DISALLOW_EDIT_REASONS = DISALLOW_EDIT_REASONS;

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        if (this.selectedOfferId) {
            this.actions.retrieveOfferingsMetadata([this.selectedOfferId]);
        }

        if (this.state.selectedPageId === null && this.state.pagesWithDecisions && this.state.pagesWithDecisions.length) {
            this.actions.setDecisionsSelectedPageId(this.state.pagesWithDecisions[0].Id);
        }

        this.decisionPages = this.state.decisionPagesViewModel;

        this.customerCareKeys = CustomerCareKeys;
        this.decisionTypes = DecisionTypes;
        this.briTypes = BILLER_RULE_INSTANCE_TYPE;
        this.tieredTemplate = require('./pricing.table.html');
        this.triggerToolTip = require('./trigger.tooltip.html');
        this.discountToolTip = require('../../../shared/tooltipTemplates/discount.tooltip.html');
        this.renewalPeriodsTooltip = require('./renewal.periods.html');

        this.selectAffectedServicesPopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectAffectedServicesPopupApi = api;
            }
        };

        this.discountOverridePopupConfig = {
            onRegisterApi: ({api}) => {
                this.discountOverridePopupPopupApi = api;
            }
        };

        this.lifeCycleDetailsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.lifeCycleDetailsPopupConfigApi = api;
            }
        };

        this.editOptionPopupConfig = {
            onRegisterApi: (event) => {
                editOptionPopupApi = event.api;
            }
        };

        this.financeBriOverridePopupConfig = {
            onRegisterApi: (event) => {
                financeBriOverridePopupApi = event.api;
            }
        };

        this.configurationDiscountOverridePopup = {
            appliedDiscountSelected: null,
            onCloseModal: null,
            option: {},
            tableData: []
        };
        this.regularDiscountTableData = [];
        this.discretionaryDiscountTableData = [];

        this.pricingTypeDecision = new PricingTypeDecisions(this.state.pagesWithDecisions);
        this.tieredContext = this.pricingTypeDecision.createTieredData(this.state.pricingPlanMetadata);
        this.taperedContext = this.pricingTypeDecision.createTaperedData(this.state.pricingPlanMetadata);
        this.pricingTypeDgoiDecision = new PricingTypeDecisions(this.state.pagesWithDecisions, true);
        this.tieredContextDgoi = this.pricingTypeDecision.createTieredData(this.state.pricingPlanMetadata, true);
        this.taperedContextDgoi = this.pricingTypeDecision.createTaperedData(this.state.pricingPlanMetadata, true);

        this.hasPriceOverrideAccess = hasAccess(this.state.userSecurityAttributes, PRICE_OVERRIDE_ACCESS);
        this.hasDiscretionaryDiscountAccess = hasAccess(this.state.userSecurityAttributes, ISSUE_DISCRETIONARY_DISCOUNT);
        this.hasDiscountOverrideAccess = hasAccess(this.state.userSecurityAttributes, DISCOUNT_OVERRIDE_ACCESS);
        this.hasMultipleDiscountAccess = hasAccess(this.state.userSecurityAttributes, MULTIPLE_DISCOUNT_ACCESS) && this.hasDiscountOverrideAccess;
        this.ALLOWABLE_ACTIONS = ALLOWABLE_ACTIONS;
        this.setDecisionOptionShowingEditButtonExists();

        // TODO: remove this when DD-45915 is complete, and implement a 'Use Bulk' checkbox on the decision
        if (this.state.pagesWithDecisions.length) {
            const decisions = flatten(pluck('decisions', this.state.pagesWithDecisions));
            const options = flatten(pluck('Options', decisions));
            const hasBulkItems = options.some((option) => {
                return option.Bulk === true;
            });
            if (hasBulkItems) {
                this.callUpdateCart();
            }
        }

        this.originalOptionQuantities = {};
        if (this.isModify) {
            const decisionsModify = flatten(pluck('decisions', this.state.pagesWithDecisions));
            const optionsModify = flatten(pluck('Options', decisionsModify));

            optionsModify.forEach((option) => {
                const numberOfAddedProducts = [];
                if (this.state.cartSummary.productGroups) {
                    this.state.cartSummary.productGroups.some((productGroup) => {
                        if (productGroup.products) {
                            productGroup.products.forEach((product) => {
                                if (product.isAdded && product.offeringOptionPriceId === option.Id) {
                                    numberOfAddedProducts.push(product);
                                }
                            });
                        }
                        return !!numberOfAddedProducts.length;
                    });
                }
                this.originalOptionQuantities[option.Id] = option.Quantity - numberOfAddedProducts.length;
            });
        }

        this.retrieveServicesIds = (option, decision, quantity) => {

            const isDeviceType = this.state.offerType === OfferTypes.DEVICE;
            const affectedDecisions = this.state.decisionsRequiringAffectedServicesResolution.length;

            if (isDeviceType && affectedDecisions) {

                this.actions.retrieveDecisionOptionServiceIds(this.state.currentCustomerId, [option.Id], this.selectedOfferingInstanceId)
                    .then((response) => {

                        // eslint-disable-next-line no-prototype-builtins
                        if (response && response.hasOwnProperty('DecisionServiceIdentifiers') && response.DecisionServiceIdentifiers.length === 1) {
                            const serviceIdentifier = response.DecisionServiceIdentifiers[0].ServiceIdentifiers[0];
                            this.applyDecisionAffectedServicesResolutions([{
                                decisionId: decision.Id,
                                optionId: option.Id,
                                serviceIdentifiers: [{
                                    attributeId: serviceIdentifier.ServiceAttributeId,
                                    Id: serviceIdentifier.Value,
                                    Name: serviceIdentifier.Value
                                }],
                                updatedQuantity: quantity
                            }]);
                        }
                    });
            }
        };
    };

    // NOTE: Avoid this at all costs... This is necessary because the parent component (i.e. the CoS wizard) can
    // dictate whether to show the popup or proceed to the intended page
    this.$onChanges = (changes) => {
        if (undefined !== changes.showSelectAffectedServicesPopup &&
            changes.showSelectAffectedServicesPopup.currentValue !== changes.showSelectAffectedServicesPopup.previousValue) {
            if (changes.showSelectAffectedServicesPopup.currentValue) {
                this.openSelectAffectedServicesPopup();
            } else if (!changes.showSelectAffectedServicesPopup.isFirstChange()) {
                this.closeSelectAffectedServicesPopup();
            }
        }

        if (changes.proceedToPage &&
            changes.proceedToPage.currentValue !== changes.proceedToPage.previousValue &&
            !changes.proceedToPage.isFirstChange()) {
            this.pageSelected(changes.proceedToPage.currentValue);
        }

        if (this.actions && changes.selectedOfferId &&
            changes.selectedOfferId.currentValue !== changes.selectedOfferId.previousValue) {
            this.actions.retrieveOfferingsMetadata([changes.selectedOfferId.currentValue]);
        }
    };

    this.$onDestroy = () => {
        this.cancelResolveDecisionAffectedServicesCallback();

        disconnectRedux();
    };

    this.navigatorTitle = i18n.translate(CustomerCareKeys.OFFER_DECISIONS);

    this.tierNavigatorDescriptor = {
        navigationItem: {
            active: 'selected',
            name: 'Name'
        },
        navSets: {
            name: 'name',
            navigationItems: 'decisions'
        }
    };

    this.pageSelected = (page, childPage) => {
        if (this.state.decisionsRequireAffectedServicesResolution) {
            this.beginResolveDecisionAffectedServicesCallback()(page);
        } else {
            const old = $location.hash();
            if (!this.state.selectedPageHasErrors) {
                this.actions.setDecisionsSelectedPageId(page.Id);
                this.decisionPages = this.state.decisionPagesViewModel;
                this.setDecisionOptionShowingEditButtonExists();
                $timeout(function() {
                    let scrollTarget = 'topOfDecisionsPage';
                    if (childPage) {
                        scrollTarget = childPage.item.Id;
                    }
                    $location.hash(scrollTarget);
                    $anchorScroll();
                    $location.hash(old);
                });
            } else {
                $location.hash('quantityDecisionErrorField');
                $anchorScroll();
                $location.hash(old);
            }
        }
    };

    this.shouldShowDecisionPager = () => {
        return !(this.state.isLastPage && this.state.isFirstPage);
    };

    this.nextPage = () => {
        if (!this.state.isLastPage) {
            const nextPage = this.state.pagesMap[this.state.pageOrder[(this.state.pageOrder.indexOf(this.state.selectedPageId)) + 1]];
            this.pageSelected(nextPage);
        }
    };

    this.previousPage = () => {
        if (!this.state.isFirstPage) {
            const prevPage = this.state.pagesMap[this.state.pageOrder[(this.state.pageOrder.indexOf(this.state.selectedPageId)) - 1]];
            this.pageSelected(prevPage);
        }
    };

    this.callUpdateCart = () => {
        if (!this.state.hasValidationError && !this.state.decisionsRequireAffectedServicesResolution) {
            this.updateCart()(
                this.selectedOfferId,
                this.selectedOfferingInstanceId,
                this.state.completedDecisions,
                this.isModify,
                this.customerId,
                undefined,
                this.contextOfferingAction,
                this.state.transitionContext,
                this.state.isConvergentBiller,
                this.customerLanguage
            );
        }
    };

    this.editDecisionUpdateCart = () => {
        return (callback) => {
            this.updateCart()(
                this.selectedOfferId,
                this.selectedOfferingInstanceId,
                this.state.completedDecisions,
                this.isModify,
                this.customerId,
                callback,
                this.contextOfferingAction,
                this.state.transitionContext,
                this.state.isConvergentBiller,
                this.customerLanguage
            );
        };
    };

    this.saveLifeCycle = (lifeCycleId, isEditable) => {
        this.actions.setSavedLifeCycleId(lifeCycleId, this.selectedOption.Id, this.selectedDecision.Id, isEditable);
        this.closeLifeCycleDetailsPopup();
    };

    this.getBritChargeAmount = (brit, option) => {
        return option.BillerRuleInstanceThumbnails.find(
            propEq(brit.BillerRuleConfigurationId, 'BillerRuleConfigurationId'))
            ?.Amount;
    };

    this.debouncedUpdateCart = debounce(this.callUpdateCart, DEBOUNCE_TIME_MS);

    this.selectButtonChanged = curry((decisionId, optionId, optionItemId, quantity) => {
        if (optionItemId) {
            this.actions.decisionOptionItemQuantityChanged(optionItemId, quantity, decisionId, optionId);
        } else {
            this.actions.decisionOptionQuantityChanged(quantity, decisionId, optionId, true);
            this.actions.decisionOptionSelected(decisionId, optionId);
        }
        if (this.isQuoteCalculated) {
            this.actions.clearSummaryQuote();
        }
        this.debouncedUpdateCart();
    });

    this.nonDecisionGroupOptionSelectButtonChanged = curry((decisionId, optionId, quantity) => {
        this.actions.nonDecisionGroupOptionQuantityChanged(quantity, decisionId, optionId);
        if (this.isQuoteCalculated) {
            this.actions.clearSummaryQuote();
        }
        this.debouncedUpdateCart();
    });

    this.stepperChanged = curry((decision, option, optionItemId, quantity) => {
        let changedDecisionIsResolved = true;

        if (this.isQuoteCalculated) {
            this.actions.clearSummaryQuote();
        }

        if (this.isModify && !this.isReconnect && option.HasServiceIdentifier && !option.Bulk) {
            const changedDecisionRequiresNumAffectedServicesResolution = this.decisionRequiresNumAffectedServicesResolution(decision.Id, option.Id, quantity);

            changedDecisionIsResolved = 0 >= changedDecisionRequiresNumAffectedServicesResolution;

            if (changedDecisionIsResolved) {
                this.actions.decisionOptionServiceIdsForRemovalSelected(decision.Id, option.Id, undefined);
            }

            if (!optionItemId) {
                this.actions.setDecisionsRequiringAffectedServicesResolution([{
                    decisionId: decision.Id,
                    numRequiredSelections: clamp(0, option.MaximumQuantity, changedDecisionRequiresNumAffectedServicesResolution),
                    optionId: option.Id,
                    resolved: changedDecisionIsResolved,
                    updatedQuantity: quantity
                }]);
                this.retrieveServicesIds(option, decision, quantity);
            }
        }

        if (optionItemId) {
            this.actions.decisionOptionItemQuantityChanged(optionItemId, quantity, decision.Id, option.Id);
        } else {
            this.actions.decisionOptionQuantityChanged(quantity, decision.Id, option.Id, false);
        }

        if (this.state.hasValidationError) {
            this.setCurrentStepIsInvalid()(true);
        } else {
            this.setCurrentStepIsInvalid()(false);

            if (changedDecisionIsResolved && !this.state.decisionsRequireAffectedServicesResolution) {
                this.debouncedUpdateCart();
            }
        }
    });

    this.decisionRequiresNumAffectedServicesResolution = (decisionId, optionId, updatedQuantity) => {

        // TODO: Confirm the current page index is always "0"
        const originalDecisionParentValues = this.decisionPages[0].decisions.find(propEq(true, 'selected')) || {};
        const originalDecisionValues = originalDecisionParentValues.decisions.find(propEq(decisionId, 'Id')) || {};
        const originalDecisionOptionValue = originalDecisionValues.Options.find(propEq(optionId, 'Id')) || {};

        return this.originalOptionQuantities[originalDecisionOptionValue.Id] - updatedQuantity;
    };

    this.hasSecurityAttributesToEditDiscounts = () => {
        return ((this.hasDiscountOverrideAccess && this.hasMultipleDiscountAccess) || this.hasDiscountOverrideAccess ||
            this.hasDiscretionaryDiscountAccess);
    };

    this.showDiscountSection = (brit, decision) => {
        return this.isQuantityDecision(decision) &&
            ((this.state.discretionaryDiscountsByCurrency[brit.CurrencyCode] || brit.hasAvailableDiscounts) &&
                brit.Type !== this.briTypes.FINANCE && brit.Amount > 0) ||
            (brit.Type === this.briTypes.FINANCE && (brit.selectedDiscountsInfo || []).length);
    };

    this.showDiscountEdit = (briAmounts) => {
        return briAmounts.Type !== this.briTypes.FINANCE && this.hasSecurityAttributesToEditDiscounts() &&
            briAmounts.Amount > 0 &&
            (briAmounts.hasAvailableDiscounts || this.state.discretionaryDiscountsByCurrency[briAmounts.CurrencyCode]);
    };

    this.isBulkDecision = (decision, option) => {
        const isQuantityDecision = decision.DecisionType === this.decisionTypes.QUANTITY;
        const isStandaloneDecision = decision.DecisionType === this.decisionTypes.STANDALONE;
        const hasBulkOption = option ? option.Bulk : decision.Options.some((option) => {
            return option.Bulk;
        });
        return (isQuantityDecision || isStandaloneDecision) && hasBulkOption;
    };

    this.getOptionQuantity = (option) => {
        if (option.Bulk && option.Quantity) {
            return option.BillerRuleDetails?.[0]?.Quantity;
        }
        return option.Quantity;
    };

    this.getBulkTooltip = (billerRuleInstanceThumbnail) => {
        return billerRuleInstanceThumbnail.BulkChargeTypeCode === BulkChargeTypes.FLAT ?
            i18n.translate(CustomerCareKeys.DECISIONS.FLAT_PRICING) :
            i18n.translate(CustomerCareKeys.DECISIONS.UNIT_PRICING);
    };

    this.discountTooltipText = (discounts) => {
        return `${discounts.length} ${i18n.translate(discounts.length === 1 ? CustomerCareKeys.DISCOUNT : CustomerCareKeys.DISCOUNTS_LABEL)}`;
    };

    this.getBulkIcon = (billerRuleInstanceThumbnail) => {
        return billerRuleInstanceThumbnail.BulkChargeTypeCode === BulkChargeTypes.FLAT ?
            'filled-circle-f' :
            'filled-circle-u';
    };

    this.isNonBulkQuantityDecision = (decision) => {
        const isQuantityDecision = decision.DecisionType === this.decisionTypes.QUANTITY;
        const noBulkOptions = decision.Options.every((option) => {
            return !option.Bulk;
        });
        return isQuantityDecision && noBulkOptions;
    };

    this.launchEditOption = (option) => {
        if (this.showDecisionOptionEditButton(option)) {
            this.setEditOption()(option);
            $timeout(() => {
                editOptionPopupApi.open();
            });
        }
    };

    this.launchFinanceBriOverrideModal = (option) => {
        if (this.showFinanceBriChargeOverrideButton(option)) {
            this.setEditOption()(option);
            $timeout(() => {
                financeBriOverridePopupApi.open();
            });
        }
    };

    this.isOptionEdited = (option) => {
        if (!option.Bulk || !this.editOptions) {
            return false;
        }
        const editedOptionIds = pluck('Id')(this.editOptions);
        if (!editedOptionIds.includes(option.Id)) {
            return false;
        } else {
            const editedOption = this.editOptions.find(propEq(option.Id, 'Id'));
            const isEdited = option.BillerRuleInstanceThumbnails.reduce((isEdited, brit) => {
                const editedBri = editedOption.defaultAmounts.find(propEq(brit.BillerRuleConfigurationId, 'BillerRuleConfigurationId'));
                const priceChanged = editedBri.Amount !== brit.Amount;
                return isEdited || priceChanged;
            }, false);

            return isEdited;
        }
    };

    this.isQuantityDecision = (decision) => {
        return decision.DecisionType === this.decisionTypes.QUANTITY;
    };

    this.isDecisionGroup = (decision) => {
        return decision.DecisionType === this.decisionTypes.GROUP;
    };

    this.isQuantityDecisionGroup = (decision) => {
        return decision.DecisionType === this.decisionTypes.GROUP && (decision.MaximumQuantity > 1 || decision.MaximumQuantity > decision.MinimumQuantity);
    };

    this.isDefaultMaximumAndMinimumQuantityEqual = (decision) => {
        return (decision.MaximumQuantity === decision.DefaultQuantity && decision.MaximumQuantity === decision.MinimumQuantity);
    };

    this.isOptionSelected = (option) => {
        return option.Selected || option.Quantity > 0;
    };

    this.isOptionItemSelected = (item, option) => {
        return item.Quantity > 0 && this.isOptionSelected(option);
    };

    this.showStandardOptionPrice = (option, billerConfigId) => {
        //Added extra validation, to prevent un-existing array index from causing issues with display
        if (this.tieredContext[option][billerConfigId] || this.taperedContext[option][billerConfigId]) {
            return !this.tieredContext[option][billerConfigId].tableItems.length &&
                !this.taperedContext[option][billerConfigId].tableItems.length;
        } else {
            return true;
        }
    };

    this.showStandardOptionDgoiPrice = (option, billerConfigId) => {
        //Added extra validation, to prevent un-existing array index from causing issues with display
        if (this.tieredContextDgoi[option][billerConfigId] || this.taperedContextDgoi[option][billerConfigId]) {
            return !this.tieredContextDgoi[option][billerConfigId].tableItems.length &&
                !this.taperedContextDgoi[option][billerConfigId].tableItems.length;
        } else {
            return true;
        }
    };

    this.applyDecisionAffectedServicesResolutions = (decisionAffectedServicesSelections = []) => {
        const resolvedDecisions = this.state.decisionsRequiringAffectedServicesResolution.map((decision) => {
            const decisionOptionResolution = decisionAffectedServicesSelections.find((resolution) => {
                return resolution.decisionId === decision.decisionId &&
                    resolution.optionId === decision.optionId;
            });

            return decision.set('resolved', !!(decisionOptionResolution &&
                decisionOptionResolution.serviceIdentifiers.length === decision.numRequiredSelections));
        });

        this.actions.setDecisionsRequiringAffectedServicesResolution(resolvedDecisions);

        decisionAffectedServicesSelections.forEach((decision) => {
            const formattedServiceIds = !decision.serviceIdentifiers.length ?
                undefined :
                decision.serviceIdentifiers.map((serviceId) => {
                    return {
                        ServiceAttributeId: serviceId.attributeId,
                        Value: serviceId.Id
                    };
                });

            this.actions.decisionOptionServiceIdsForRemovalSelected(decision.decisionId, decision.optionId, formattedServiceIds);
            this.actions.decisionOptionQuantityChanged(decision.updatedQuantity, decision.decisionId, decision.optionId);
        });

        this.closeSelectAffectedServicesPopup();

        this.callUpdateCart();

        this.applyDecisionAffectedServicesResolutionsCallback()();
    };

    this.cancelResolveDecisionAffectedServices = () => {
        this.closeSelectAffectedServicesPopup();

        this.cancelResolveDecisionAffectedServicesCallback()(true);
    };

    this.closeSelectAffectedServicesPopup = () => {
        this.selectAffectedServicesPopupApi.close();
    };

    this.closeDiscountOverridePopup = () => {
        this.showDiscountOverridePopup = false;
        this.discountOverridePopupPopupApi.close();
    };

    this.closeLifeCycleDetailsPopup = () => {
        this.lifeCycleDialogOpened = false;
        this.lifeCycleDetailsPopupConfigApi.close();
    };

    this.openSelectAffectedServicesPopup = () => {
        $timeout(() => {
            this.selectAffectedServicesPopupApi.open();
        });
    };

    this.submitDiscountOverride = (decisionId, optionId, billerRuleConfigurationId, selectedDiscountsInfo) => {
        this.actions.discountOverrideChanged(decisionId, optionId, billerRuleConfigurationId, selectedDiscountsInfo);
        $timeout(() => {
            this.debouncedUpdateCart();
            this.closeDiscountOverridePopup();
        });

    };

    this.submitRemoveDiscountOverride = (decisionId, optionId) => {
        this.actions.clearDiscountOverride(decisionId, optionId);
        $timeout(() => {
            this.debouncedUpdateCart();
            this.closeDiscountOverridePopup();
        });
    };

    this.getDiscountOverridePopupTabs = () => {
        const tabs = [];
        if (this.state.regularDiscountTableData.length && (this.hasDiscountOverrideAccess || (this.hasDiscountOverrideAccess && this.hasMultipleDiscountAccess))) {
            tabs.push({
                name: i18n.translate(this.customerCareKeys.CART.DISCOUNT_MODAL.REGULAR),
                active: false
            });
        }
        if (this.state.discretionaryDiscountTableData.length && this.hasDiscretionaryDiscountAccess) {
            tabs.push({
                name: i18n.translate(this.customerCareKeys.CART.DISCOUNT_MODAL.DISCRETIONARY),
                active: false
            });
        }
        tabs[0].active = true;
        return tabs;
    };

    this.getFormattedDiscountsData = (tableData, selectedBri, selectedDiscountsInfo) => {
        return tableData.map((item) => {
            const matchingSelectedDiscount = (selectedDiscountsInfo || []).find((discountInfo) => {
                return `${discountInfo.DiscountId}` === item.discountId;
            });
            const discountAmount = matchingSelectedDiscount ? matchingSelectedDiscount.DiscountAmount : item.savings;
            if (matchingSelectedDiscount?.DiscountOverrideAmount) {
                return {
                    ...item,
                    amount: item.discountAmountType === DISCOUNT_TYPE_PERCENTAGE_OFF  ?
                        matchingSelectedDiscount.DiscountOverrideAmount * 100 : matchingSelectedDiscount.DiscountOverrideAmount,
                    isOverridden: true,
                    defaultAmount: item.discountAmountType === DISCOUNT_TYPE_PERCENTAGE_OFF  ?
                        item.savings * 100 / selectedBri.chargeAmount : item.savings,
                    savings: item.discountAmountType === DISCOUNT_TYPE_PERCENTAGE_OFF  ?
                        matchingSelectedDiscount.DiscountOverrideAmount * selectedBri.chargeAmount : matchingSelectedDiscount.DiscountOverrideAmount
                };
            }
            return {
                ...item,
                amount: item.discountAmountType === DISCOUNT_TYPE_PERCENTAGE_OFF  ?
                    discountAmount * 100 / selectedBri.chargeAmount : item.amount,
                defaultAmount: item.discountAmountType === DISCOUNT_TYPE_PERCENTAGE_OFF  ?
                    item.savings * 100 / selectedBri.chargeAmount : item.savings,
                savings: discountAmount
            };
        });
    };

    this.openDiscountOverridePopup = (_selectedOption, _decision, billerRuleInstanceThumbnail, showBanner) => {
        const bri = getMatchingOneTimeRecurringOrFinanceBriFromBrcId(_selectedOption, billerRuleInstanceThumbnail.BillerRuleConfigurationId) || {};

        const overriddenCharge = this.getBritChargeAmount(billerRuleInstanceThumbnail, _selectedOption);

        this.selectedBri = {
            name: this.getBritName(_selectedOption, billerRuleInstanceThumbnail),
            billerRuleConfigurationId: billerRuleInstanceThumbnail.BillerRuleConfigurationId,
            id: bri.Id,
            chargeAmount: overriddenCharge
        };

        this.warningMessage = showBanner ? this.state.rocWarningTranslatedMessage : undefined;

        this.selectedDiscountsInfo = billerRuleInstanceThumbnail.selectedDiscountsInfo;
        this.actions.setSelectedBriSelectedDiscounts(this.selectedDiscountsInfo);

        const nonSelectedGlobalDiscounts = _selectedOption && _selectedOption.DefaultSelectedDiscounts ? filter(propEq(false, 'IsGlobal'))(_selectedOption.DefaultSelectedDiscounts) : [];

        const defaultSelectedDiscounts = nonSelectedGlobalDiscounts ? nonSelectedGlobalDiscounts.map((item) => {
            return item.DiscountId;
        }) : null;

        this.actions.retrieveBillerRuleInstanceDiscountContext(
            billerRuleInstanceThumbnail.BillerRuleConfigurationId,
            overriddenCharge,
            this.customerId,
            _selectedOption.PricingPlanBillerRuleInstances.PricingPlanId,
            _decision.ProductId,
            this.customerId ? undefined : buildSegmentationContext(this.state.customerInfoEdit),
            _selectedOption.OrderScenario || null,
            defaultSelectedDiscounts
        ).then(() => {
            const tabs = this.getDiscountOverridePopupTabs();
            this.configurationDiscountOverridePopup = {
                appliedDiscountSelected: _selectedOption.OverrideDiscount,
                currency: billerRuleInstanceThumbnail.CurrencyCode,
                decision: _decision,
                onCloseModal: this.closeDiscountOverridePopup,
                onRemoveDiscount: this.submitRemoveDiscountOverride,
                onSubmit: this.submitDiscountOverride,
                option: _selectedOption,
                reasonCodes: this.state.discretionaryDiscountReasonCodeOptions,
                selectedOption: _selectedOption,
                selectedTab: tabs[0],
                tabs
            };

            this.regularDiscountTableData = this.getFormattedDiscountsData(this.state.regularDiscountTableData, this.selectedBri, this.selectedDiscountsInfo);
            this.discretionaryDiscountTableData = this.getFormattedDiscountsData(this.state.discretionaryDiscountTableData, this.selectedBri, this.selectedDiscountsInfo);

            this.showDiscountOverridePopup = true;
            $timeout(() => {
                this.discountOverridePopupPopupApi.open();
            });
        }).catch((error) => {
            uiNotificationService.transientError(error.translatedMessage);
        });
    };

    this.openLifeCycleDetailsPopup = (decision, isDisabled, option) => {
        this.selectedOption = option;
        this.selectedDecision = decision;
        const editOfferLifeCyclesInProductEditable = this.lifeCyclesInProductEditable(option);
        this.currentActiveLifeCycleStep = (option.Quantity > 0) ? option.PricingPlanBillerRuleInstances.PricingPlanId : '';
        let isEditable = true;
        if (option.OfferingDecisionOptionInstances && option.OfferingDecisionOptionInstances[0].SelectedLifeCycle) {
            this.defaultLifeCycleId = option.OfferingDecisionOptionInstances[0].SelectedLifeCycle.LifeCycleId;
            isEditable = option.OfferingDecisionOptionInstances[0].SelectedLifeCycle.IsEditable ? option.OfferingDecisionOptionInstances[0].SelectedLifeCycle.IsEditable : (this.editOfferLifeCycle ? '' : true);
        }
        const lifeCyclesForProduct = this.state.lifeCyclesForProducts.find((lifeCycle) => {
            return lifeCycle.productId === decision.ProductId;
        });
        const lifeCycleInPricingPlanThumbnail = Object.assign([], this.state.lifeCycleChargeDetails);
        const filteredPricingPlans = lifeCycleInPricingPlanThumbnail.filter((pricingPlan) => {
            return Object.values(pricingPlan)[0].pricingPlanInfo.ProductId === decision.ProductId;
        });
        this.lifeCycleDetailsPricingPlanThumbnail = Object.assign({}, ...filteredPricingPlans);
        const lifeCyclePricingPlansInProduct = Object.assign([], this.state.lifeCyclePricingPlans);
        this.lifeCycleChargeDetails = Object.entries(this.lifeCycleDetailsPricingPlanThumbnail).length ?
            lifeCyclePricingPlansInProduct.filter((lifeCycle) => {
                return Object.values(this.lifeCycleDetailsPricingPlanThumbnail).find((pricingPlan) => {
                    return pricingPlan.pricingPlanInfo.Id === lifeCycle.Id;
                });
            }): [];
        const lifeCycleDetailsForProducts = [];
        lifeCyclesForProduct.lifeCycles ? lifeCyclesForProduct.lifeCycles.forEach((lifeCycle) => {
            const pricingPlanIds = pluck('PricingPlanId', lifeCycle.Steps);
            let pricingPlanInfo = {};
            const pricingPlanInfoForLifeCycleStep = [];
            pricingPlanIds.length ? pricingPlanIds.forEach((pricingPlanId) => {
                pricingPlanInfo = {
                    ...pricingPlanInfo,
                    ...{
                        [pricingPlanId]: this.lifeCycleDetailsPricingPlanThumbnail[pricingPlanId]
                    }
                };
            }) : [];
            lifeCycle.Steps.forEach((step) => {
                this.lifeCycleChargeDetails.find((pricingPlan) => {
                    if (pricingPlan.Id === step.PricingPlanId) {
                        pricingPlanInfoForLifeCycleStep.push({
                            ...pricingPlan,
                            sortIndex: step.StepNumber
                        });
                    }
                });
            });
            lifeCycleDetailsForProducts.push({
                lifeCycleDetails: lifeCycle,
                pricingPlanDetails: pricingPlanInfo,
                pricingPlanLifeCycleStep: pricingPlanInfoForLifeCycleStep.sort((a, b) => {
                    return a.sortIndex - b.sortIndex;
                }),
                isEditable: isEditable
            });
        }): [];
        this.lifeCycleDetailsForProductPlans = lifeCycleDetailsForProducts.filter((lifeCycle) => {
            return lifeCycle.pricingPlanLifeCycleStep.find((pricingPlan) => {
                return pricingPlan.Id === option.PricingPlanBillerRuleInstances.PricingPlanId;
            });
        });

        if (this.editOfferLifeCycle) {
            this.disableLifeCyclesEditOfferModal = pluck('isEditable', this.lifeCycleDetailsForProductPlans).every((item) => {
                return item === false;
            });
        }

        if (this.disableLifeCyclesEditOfferModal) {
            this.lifeCycleDetailsForProductPlans = lifeCycleDetailsForProducts.filter((lifeCycle) => {
                return lifeCycle.lifeCycleDetails.Id === this.defaultLifeCycleId;
            });
        }

        this.lifeCycleDialogOpened = true;
        this.disableLifeCycles = !editOfferLifeCyclesInProductEditable || isDisabled;

        $timeout(() => {
            this.lifeCycleDetailsPopupConfigApi.open();
        });
    };

    this.isPricingPlanInLifeCycle = (option) => {
        return this.state.lifeCyclePricingPlans.find((lifeCycle) => {
            return lifeCycle.Id === option.PricingPlanBillerRuleInstances.PricingPlanId;
        });
    };

    this.showDecisionOptionEditButton = (option) => {
        return this.hasPriceOverrideAccess && (
            option.Bulk ||
            isBulkWithServicesOption(option) ||
            isChargeOverridableOption(option)
        );
    };

    this.showFinanceBriChargeOverrideButton = (option) => {
        return !option.SubscriberProductId && this.hasPriceOverrideAccess && isFinanceBriChargeOverridableOption(option);
    };

    this.setDecisionOptionShowingEditButtonExists = () => {
        this.decisionOptionShowingEditButtonExists = false;

        if (this.state.pagesWithDecisions && this.state.pagesWithDecisions.length) {
            const currentPage = this.state.pagesWithDecisions.find((page) => {
                return page.Id === this.state.selectedPageId;
            });

            if (currentPage) {
                if (this.hasPriceOverrideAccess) {
                    this.decisionOptionShowingEditButtonExists = currentPage.decisions.some((decision) => {
                        return this.isBulkDecision(decision) || hasAnyChargeOverridableOptions(decision);
                    });
                } else {
                    this.decisionOptionShowingEditButtonExists = currentPage.decisions.some((decision) => {
                        return decision.Options.some((option) => {
                            return isBulkWithServicesOption(option);
                        });
                    });
                }
            }
        }
    };

    const hasAnyChargeOverridableOptions = (decision) => {
        return decision.Options.some((option) => {
            return isChargeOverridableOption(option);
        });
    };

    const isChargeOverridableOption = (option) => {
        const filteredBris = option.PricingPlanBillerRuleInstances ? [
            ...option.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
            ...option.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances
        ] : [];

        return filteredBris.some((bri) => {
            return bri.AllowChargeOverride;
        });
    };

    const isFinanceBriChargeOverridableOption = (option) => {
        return (option.PricingPlanBillerRuleInstances.FinanceBillerRuleInstances || []).some((bri) => {
            return bri.AllowChargeOverride;
        });
    };

    const isBulkWithServicesOption = (option) => {
        return option.Bulk && option.HasServiceIdentifier;
    };

    this.getName = (option, brit) => {
        return this.state.pricingPlanMetadata[option.PricingPlanId].BillerRuleInstanceThumbnails.find((MetaDataBrit) => {
            return MetaDataBrit.Id === brit.Id;
        }).Name;
    };

    this.getBritName = (option, brit) => {

        let briName =  this.getName(option, brit);

        if (brit.Type === this.briTypes.SUBSCRIPTION) {
            briName = `${i18n.translate(this.customerCareKeys.BILLER_RULE_INSTANCE_TYPES.RECURRING)} ${this.state.cartSummary.productsByType[CHARGE_TIMING.DUE_TODAY][0].billCycleName}`;
        } else if (brit.Type === this.briTypes.FINANCE) {
            // A finance pricing plan can have only 1 BRIT.
            if (this.isFullUpfrontPayment(option.PricingPlanId)) {
                briName = i18n.translate(this.customerCareKeys.DECISIONS.DUE_TODAY);
            } else if (brit.RemainingBalance) {
                briName = i18n.translate(this.customerCareKeys.REMAINING_BALANCE);
            } else {
                briName = i18n.translate(this.customerCareKeys.DECISIONS.FULL_PRICE);
            }
        }
        return briName;
    };

    this.isFullUpfrontPayment = (pricingPlanId) => {
        return pathOr(null, [pricingPlanId, 'PricingPlanBillerRuleInstances', 'FinanceBillerRuleInstances', 0, 'FullUpfrontPayment'], this.state.pricingPlanMetadata);
    };

    this.lifeCyclesInProductEditable = (option) => {
        const lifeCyclesForProduct = this.state.lifeCyclesForProducts.find((lifeCycle) => {
            return lifeCycle.productId === option.PricingPlanBillerRuleInstances.ProductId;
        });
        if (lifeCyclesForProduct) {
            return lifeCyclesForProduct.lifeCycles.length > 1;
        }
    };

    this.isLifeCycleEditable = (option) => {
        return option.OfferingDecisionOptionInstances[0] && option.OfferingDecisionOptionInstances[0].SelectedLifeCycle ? option.OfferingDecisionOptionInstances[0].SelectedLifeCycle.IsEditable : false;
    };

    this.getDefaultLifeCycleName = (lifeCycleObj) => {
        let lifeCycleName = '';

        if (lifeCycleObj.SelectedLifeCycle) {
            this.state.lifeCyclesForProducts.find((lifeCycleDetails) => {
                lifeCycleDetails.lifeCycles.find((lifeCycle) => {
                    if (lifeCycle.Id === lifeCycleObj.SelectedLifeCycle.LifeCycleId) {
                        lifeCycleName = lifeCycle.Name;
                    }
                });
            });
        }
        return lifeCycleName;
    };

    this.enabledOption = (option) => {
        let pricingPlanId = null;
        Object.values(this.state.offeringMetadata).forEach((offeringMap) => {
            if (offeringMap.Options) {
                offeringMap.Options.forEach((optionMap) => {
                    if (optionMap.OptionPrices) {
                        optionMap.OptionPrices.forEach((optionPrice) => {
                            if (option.Id === optionPrice.Id) {
                                pricingPlanId = optionPrice.PricingPlanId;
                            }
                        });
                    }
                });
            }
        });
        if (pricingPlanId && this.state.dependentPricingPlansIds) {
            return this.state.dependentPricingPlansIds.includes(pricingPlanId);
        } else {
            return false;
        }

    };

    this.getTermLength = (option) => {
        return option?.BillerRuleDetails?.[0]?.TermLength ||
        pathOr(null, [option.PricingPlanId, 'PricingPlanBillerRuleInstances', 'FinanceBillerRuleInstances', 0, 'TermLength'], this.state.pricingPlanMetadata);
    };

    this.getLastInvoicedBulkQuantity = (option, brit) => {
        const lastInvoicedBulkQuantity = option?.BillerRuleInstanceThumbnails.find((briThumbnail) => {
            return briThumbnail.BillerRuleConfigurationId === brit.BillerRuleConfigurationId;
        })?.LastInvoicedBulkQuantity;
        return lastInvoicedBulkQuantity;
    };
}

export default {
    template: require('./decisions.html'),
    controller: DecisionsController,
    controllerAs: 'decisionsController',
    bindings: {
        applyDecisionAffectedServicesResolutionsCallback: '&',
        beginResolveDecisionAffectedServicesCallback: '&',
        cancelResolveDecisionAffectedServicesCallback: '&',
        contextOfferingAction: '<',
        customerId: '<',
        customerLanguage: '<',
        editOption: '<',
        editOptions: '<',
        editOfferLifeCycle: '<?',
        enableDiscountOverride: '<?',
        isModify: '<',
        isReconnect: '<?', // To be used in conjunction with isModify flag.
        isQuoteCalculated: '<',
        isResumingOrder: '<',
        proceedToPage: '<',
        restoreOptionDefaultPricing: '&',
        saveOptionEdit: '&',
        saveBulkPricing: '&',
        selectedOfferId: '<',
        selectedOfferingInstanceId: '<?',
        setCurrentStepIsInvalid: '&',
        setEditOption: '&',
        showSelectAffectedServicesPopup: '<',
        updateCart: '&',
        updateEditOptionPrice: '&'
    }
};
