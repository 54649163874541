import CustomerCareKeys from '../../../../../../locales/keys';
import {
    CurrentCustomerIdSelector
} from '../../../../../../reducers/selectors/customer.selectors';
import {
    DisplayTaxAndGlLocationsSelector,
    FormattedCustomersAddressesListSelector,
    InventoryRegionFilterSelector,
    InventoryStoresTableDataSelector,
    SelectedCustomerAddressSelector,
    SelectedInventoryStoreDetailsSelector,
    SelectedInventoryStoreNameSelector,
    SelectedServiceIdentifierSelector,
    ServiceIdentifiersListSelector,
    ShoppingCartItemDetailsLocationCodesSelector,
    ShoppingCartSelector
} from '../../../../../../reducers/selectors/products.order.selectors';
import {
    setSelectedItemId
} from '../../../../../../reducers/actions/customer.addresses.actions';
import {
    fetchProductOrderCustomerInventory,
    retrieveCustomerAddresses,
    setSelectedCustomerAddress,
    setInventoryRegionFilter,
    setSelectedInventoryStores,
    setSelectedServiceIdentifier
} from '../../../../../../reducers/actions/products.order.actions';
import {
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {loadCodeTables} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {INVENTORY_CATEGORY_CODE} from '../../../../../../customercare.constants';
import clone from 'ramda/src/clone';
import {calculateOrderQuote} from '../../../../../../reducers/actions/products.wizard.actions';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';

const TAX_LOCATION_CONSTANTS = {
    CUSTOMER_ADDRESS: 2,
    DEFAULT: 1,
    NPA_NXX: 3,
    STORE_LOCATION: 4,
};

class TaxAndGeneralLedgerController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            callCalculateOrderQuote: true,
            closeSelectCustomerAddressAndServiceIdentifierDialog: this.closeSelectCustomerAddressAndServiceIdentifierDialog.bind(this),
            closeSelectCustomerAddressDialog: this.closeSelectCustomerAddressDialog.bind(this),
            closeSelectServiceIdentifierDialog: this.closeSelectServiceIdentifierDialog.bind(this),
            closeSelectStorePopupDialog: this.closeSelectStorePopupDialog.bind(this),
            CustomerCareKeys,
            onInventoryRegionFilterChanged: this.onInventoryRegionFilterChanged.bind(this),
            openSelectCustomerAddressAndServiceIdentifierDialog: this.openSelectCustomerAddressAndServiceIdentifierDialog.bind(this),
            openSelectCustomerAddressDialog: this.openSelectCustomerAddressDialog.bind(this),
            openSelectServiceIdentifierDialog: this.openSelectServiceIdentifierDialog.bind(this),
            retrieveAddresses: this.retrieveAddresses.bind(this),
            saveSelectCustomerAddressAndServiceIdentifierDialog: this.saveSelectCustomerAddressAndServiceIdentifierDialog.bind(this),
            saveSelectCustomerAddressDialog: this.saveSelectCustomerAddressDialog.bind(this),
            selectedCustomerAddress: null,
            selectedServiceIdentifier: null,
            showCustomerAddressAndServiceIdentifierPicker: false,
            showCustomerAddressPicker: false,
            showServiceIdentifierPicker: false,
            showStoreLocationPicker: false,
            uiNotificationService
        });
        Promise.all(loadCodeTables(this.$ngRedux.getState(), this.$ngRedux.dispatch, [
            CODES.InventoryRegion,
            CODES.Stores
        ])).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                displayTaxAndGlLocations: DisplayTaxAndGlLocationsSelector(store),
                formattedCustomerAddressesList: FormattedCustomersAddressesListSelector(store),
                inventoryRegions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.InventoryRegion, store),
                locationCodes: ShoppingCartItemDetailsLocationCodesSelector(store),
                selectedCustomerAddress: SelectedCustomerAddressSelector(store),
                selectedInventoryRegion: InventoryRegionFilterSelector(store),
                selectedInventoryStore: SelectedInventoryStoreDetailsSelector(store),
                selectedInventoryStoreName: SelectedInventoryStoreNameSelector(store),
                selectedServiceIdentifier: SelectedServiceIdentifierSelector(store),
                serviceIdentifiersList: ServiceIdentifiersListSelector(store),
                shoppingCart: ShoppingCartSelector(store),
                storesTableData: InventoryStoresTableDataSelector(store)
            };
        };

        const controllerActions = {
            calculateOrderQuote,
            fetchProductOrderCustomerInventory,
            retrieveCustomerAddresses,
            setInventoryRegionFilter,
            setSelectedCustomerAddress,
            setSelectedInventoryStores,
            setSelectedItemId,
            setSelectedServiceIdentifier
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.selectCustomerAddressPopupConfig = {
            onRegisterApi: (event) => {
                this.selectCustomerAddressPopupApi = event.api;
            }
        };

        this.selectStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectStorePopupApi = api;
            }
        };

        this.selectServiceIdentifierPopupConfig = {
            onRegisterApi: (event) => {
                this.selectServiceIdentifierPopupApi = event.api;
            }
        };

        this.selectCustomerAddressAndServiceIdentifierPopupConfig = {
            onRegisterApi: (event) => {
                this.selectCustomerAddressAndServiceIdentifierPopupApi = event.api;
            }
        };

        this.retrieveAddresses();
        this.retrieveServiceIdentifiers();
    }

    openSelectCustomerAddressDialog() {
        this.customerAddresses = clone(this.state.formattedCustomerAddressesList);
        if (this.customerAddresses.length === TAX_LOCATION_CONSTANTS.DEFAULT) {
            this.selectedCustomerAddress = this.customerAddresses[0];
        } else if ((this.customerAddresses.length > TAX_LOCATION_CONSTANTS.DEFAULT) && this.selectedCustomerAddress === null) {
            this.selectedCustomerAddress = this.state.selectedCustomerAddress;
        }
        this.$timeout(() => {
            this.selectCustomerAddressPopupApi.open();
        });
    }

    closeSelectCustomerAddressDialog() {
        this.$timeout(() => {
            this.selectCustomerAddressPopupApi.close();
        });
    }

    saveSelectCustomerAddressDialog() {
        this.actions.setSelectedCustomerAddress(this.selectedCustomerAddress);
        this.calculateOrderQuoteWithTaxAndGL();
        this.closeSelectCustomerAddressDialog();
    }

    openSelectStoreDialog() {
        this.showSelectStorePopup = true;
        this.$timeout(() => {
            this.selectStorePopupApi.open();
        });
    }

    closeSelectStorePopupDialog(isApplyChanges, selectedStore) {
        this.showSelectStorePopup = false;
        if (isApplyChanges) {
            this.actions.setSelectedInventoryStores(selectedStore);
            this.calculateOrderQuoteWithTaxAndGL();
        }
        this.selectStorePopupApi.close();
    }

    openSelectServiceIdentifierDialog() {
        this.serviceIdentifiersListData = clone(this.state.serviceIdentifiersList);
        if (this.serviceIdentifiersListData.length === TAX_LOCATION_CONSTANTS.DEFAULT) {
            this.selectedServiceIdentifier = this.serviceIdentifiersListData[0];
        } else if ((this.serviceIdentifiersListData.length > TAX_LOCATION_CONSTANTS.DEFAULT) && this.selectedServiceIdentifier === null) {
            this.selectedServiceIdentifier = this.state.selectedServiceIdentifier;
        }
        this.$timeout(() => {
            this.selectServiceIdentifierPopupApi.open();
        });
    }

    closeSelectServiceIdentifierDialog() {
        this.$timeout(() => {
            this.selectServiceIdentifierPopupApi.close();
        });
    }

    saveSelectedServiceIdentifier() {
        this.actions.setSelectedServiceIdentifier(this.selectedServiceIdentifier);
        this.calculateOrderQuoteWithTaxAndGL();
        this.closeSelectServiceIdentifierDialog();
    }

    onInventoryRegionFilterChanged(region) {
        this.actions.setInventoryRegionFilter(region);
    }

    openSelectCustomerAddressAndServiceIdentifierDialog() {
        this.customerAddresses = clone(this.state.formattedCustomerAddressesList);
        this.serviceIdentifiersListData = clone(this.state.serviceIdentifiersList);
        if (this.customerAddresses.length === TAX_LOCATION_CONSTANTS.DEFAULT) {
            this.selectedCustomerAddress = this.customerAddresses[0];
        } else if ((this.customerAddresses.length > TAX_LOCATION_CONSTANTS.DEFAULT) && this.selectedCustomerAddress === null) {
            this.selectedCustomerAddress = this.state.selectedCustomerAddress;
        }
        if (this.serviceIdentifiersListData.length === TAX_LOCATION_CONSTANTS.DEFAULT) {
            this.selectedServiceIdentifier = this.serviceIdentifiersListData[0];
        } else if ((this.serviceIdentifiersListData.length > TAX_LOCATION_CONSTANTS.DEFAULT) && this.selectedServiceIdentifier === null) {
            this.selectedServiceIdentifier = this.state.selectedServiceIdentifier;
        }
        this.$timeout(() => {
            this.selectCustomerAddressAndServiceIdentifierPopupApi.open();
        });
    }

    closeSelectCustomerAddressAndServiceIdentifierDialog() {
        this.$timeout(() => {
            this.selectCustomerAddressAndServiceIdentifierPopupApi.close();
        });
    }

    saveSelectCustomerAddressAndServiceIdentifierDialog() {
        this.actions.setSelectedCustomerAddress(this.selectedCustomerAddress);
        this.actions.setSelectedServiceIdentifier(this.selectedServiceIdentifier);
        this.calculateOrderQuoteWithTaxAndGL();
        this.closeSelectCustomerAddressAndServiceIdentifierDialog();
    }

    retrieveAddresses() {
        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId, false)
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    retrieveServiceIdentifiers() {
        this.actions.fetchProductOrderCustomerInventory(this.state.currentCustomerId, INVENTORY_CATEGORY_CODE.LOGICAL);
    }

    calculateOrderQuoteWithTaxAndGL() {
        if (this.state.displayTaxAndGlLocations) {
            this.showCustomerAddressPicker = this.state.displayTaxAndGlLocations.showCustomerAddressPicker;
            this.showServiceIdentifierPicker = this.state.displayTaxAndGlLocations.showServiceIdentifierPicker;
            this.showStoreLocationPicker = this.state.displayTaxAndGlLocations.showStoreLocationPicker;
            this.showCustomerAddressAndServiceIdentifierPicker = this.state.displayTaxAndGlLocations.showCustomerAddressAndServiceIdentifierPicker;
        }

        const taxAndGlPayload = Object.assign({}, (this.showCustomerAddressPicker && this.selectedCustomerAddress) && {
            AddressId: this.selectedCustomerAddress.addressId
        }, (this.showServiceIdentifierPicker && this.selectedServiceIdentifier) && {
            ServiceIdentifierValue: this.selectedServiceIdentifier.SerialNumber
        }, (this.showStoreLocationPicker && this.state.selectedInventoryStore) && {
            StoreId: convertStringToNumber(this.state.selectedInventoryStore.Value)
        }, (this.showCustomerAddressAndServiceIdentifierPicker && this.selectedCustomerAddress && this.selectedServiceIdentifier) && {
            AddressId: this.selectedCustomerAddress.addressId,
            ServiceIdentifierValue: this.selectedServiceIdentifier.SerialNumber
        });

        const order = {
            customerId: this.state.currentCustomerId,
            shoppingCart: this.state.shoppingCart,
            orderTaxLocation: taxAndGlPayload
        };
        if (this.uiNotificationService.numActiveToasts()) {
            this.uiNotificationService.clear();
        }

        if (this.state.locationCodes.length === Object.keys(taxAndGlPayload).length) {
            this.actions.calculateOrderQuote(order);
        }
    }
}

export default {
    template: require('./tax.and.general.ledger.location.html'),
    bindings: {},
    controller: TaxAndGeneralLedgerController
};