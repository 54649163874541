import angular from 'angular';

import CreatePromiseToPayPopupModule from './promiseToPayPopup/create.promise.to.pay.popup.module';

import ChangeStepPopupModule from './changeStepPopup/change.step.popup.module';

import PromiseToPayScheduleModule from './promiseToPaySchedulePopup/promise.to.pay.schedule.popup.module';

import treatmentDetailsConfig from './treatment.details.config';
import treatmentDetailsComponent from './treatment.details.component';

export default angular.module('inv.customer.treatmentDetails', [
    CreatePromiseToPayPopupModule,
    ChangeStepPopupModule,
    PromiseToPayScheduleModule
])
    .config(treatmentDetailsConfig)
    .component('treatmentDetails', treatmentDetailsComponent)
    .name;
