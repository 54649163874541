import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import LocaleKeys from '../../../../../../locales/keys';
import {validateNumbersOnly} from '../../../../../../utilities/inputs.helper';
import values from 'ramda/src/values';
import uniq from 'ramda/src/uniq';
import {getFormattedServiceAttributeValue} from '../../../../../../reducers/selectors/services.list.selectors.helper';

class TelephoneSelectionController {
    constructor() {
        Object.assign(this, {
            CoreLocaleKeys,
            LocaleKeys,
            contains: '',
            previousSearch: null,
            maxLength: 4,
            minLength: 0,
            placeholder: '####',
            validateNumbersOnly
        });
    }

    searchTelephones(previousSearch) {
        this.telephoneSelectionForm.$submitted = true;
        if (this.telephoneSelectionForm.$valid || previousSearch) {
            this.telephoneNumberSelected = null;
            const patterns = this.previousSearch = previousSearch || this.createSearchPatterns();
            this.search()(patterns);
        }
    }

    createSearchPatterns() {
        const patterns = [];
        const contains = this.getContainsList(this.contains);
        values(this.region.codesNpa).forEach((rCode) => {
            rCode.nxx.forEach((rCodeNxx) => {
                contains.forEach((contain) => {
                    patterns.push(`${rCode.Id}${rCodeNxx}${contain}`);
                });
            });
        });
        return uniq(patterns);
    }

    getContainsList(wildcard) {
        const contains = [];
        const wildcardLength = wildcard.toString().length;
        if (wildcard !== undefined && wildcard !== null) {
            if (wildcardLength === 4) {
                contains.push(wildcard);
            } else if (wildcardLength === 3) {
                contains.push(`${wildcard}%`);
                contains.push(`%${wildcard}`);
            } else if (wildcardLength < 3 && wildcardLength > 0) {
                contains.push(`${wildcard}%`);
                contains.push(`%${wildcard}`);
                contains.push(`%${wildcard}%`);
            } else if (wildcardLength === 0) {
                contains.push('%');
            } else {
                contains.push('');
            }
        }
        return contains;
    }

    getContainsTooltip() {
        return this.attribute.enableWildCardSearch ? this.LocaleKeys.ATTRIBUTES.TELEPHONE_SELECTION.CONTAINS_TOOLTIP :
            this.LocaleKeys.ATTRIBUTES.TELEPHONE_SELECTION.CONTAINS_TOOLTIP_WS;
    }

    getFormattedNumber(option) {
        return getFormattedServiceAttributeValue(
            this.attribute.serviceAttributeId,
            option.SerialNumber,
            this.serviceAttributes,
            this.regularExpressions
        );
    }

    moreOptions() {
        this.telephoneNumberSelected = null;
        this.onPhoneNumberSelection(this.telephoneNumberSelected); //Report un-selection
        this.searchTelephones(this.previousSearch);
    }

    onPhoneNumberSelection(phone) {
        this.onPhoneSelection()(phone);
    }
}

export default {
    template: require('./telephone.selection.template.html'),
    controller: TelephoneSelectionController,
    controllerAs: 'TelephoneSelectionController',
    bindings: {
        attribute: '<', //need attribute to get the attribute type id and pull inventory options
        onPhoneSelection: '&',
        regularExpressions: '<',
        search: '&',
        serviceAttributes: '<',
        stateLocations: '<',
        telephoneList: '<'
    }
};
