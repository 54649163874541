import localeKeys from '../../../../../locales/keys';

class TransactionDetailsItunesController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.itunes.html'),
    bindings: {
        details: '<',
    },
    controller: TransactionDetailsItunesController
};
