import angular from 'angular';

import {routing as RoutingConfig} from './offers.config';
import DisconnectContractItemComponent from './disconnect/contract/disconnect.contract.item.component';
import DisconnectItemComponent from './disconnect/item/disconnect.item.component';

export default angular.module('invision.customercare.customer.offers', [])
    .config(RoutingConfig)
    .component('disconnectContract', DisconnectContractItemComponent)
    .component('disconnectItem', DisconnectItemComponent)
    .name;
