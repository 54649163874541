import {InjectorHelper} from 'invision-core';

export const generateTitle = (giftCard) => {
    if (giftCard && giftCard.Amount) {
        const $filter = InjectorHelper.getFilterService();
        const displayAmount = $filter('invCurrency')(giftCard.Amount, giftCard.Currency);
        let id = null;
        if (giftCard.RecipientEmail) {
            id = giftCard.RecipientEmail;
        } else if (giftCard.ProviderCode && giftCard.AccountNumber) {
            //The existence of ProviderCode tells us we have a physical gift card
            id = giftCard.AccountNumber;
        }
        return `${id} - ${displayAmount}`;
    }
    return '';
};

export const getCreationDate = (giftCard) => {
    if (giftCard) {
        const notifications = giftCard.SentNotifications;
        if (notifications && notifications.length > 0) {
            return notifications[0].Notified;
        }
    }
    return null;
};
