import {
    find,
    propEq
} from 'ramda';
import {i18n} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {SERVICE_ATTRIBUTE_DISPLAY_TYPES} from '../../../../../customercare.constants';

export const getRegexValidation = (attribute, attributeConfiguration, regexCodes) => {
    const serviceAttribute = find(propEq(String(attribute.ServiceAttributeId), 'Value'))(attributeConfiguration);
    let val = null;
    if (serviceAttribute && serviceAttribute.AdditionalProperties) {
        serviceAttribute.AdditionalProperties.forEach((prop) => {
            if (prop.Key === 'regex_code' && typeof prop.Value === 'string' && prop.Value.length > 0) {
                const regexCodeObject = find(propEq(String(prop.Value), 'Value'))(regexCodes);

                val = regexCodeObject.AdditionalProperties[0].Value;
            }
        });
    }
    return val;
};

export const getPossibleValues = (attribute, attributeConfiguration) => {
    const serviceAttribute = find(propEq(String(attribute.ServiceAttributeId), 'Value'))(attributeConfiguration);
    let values = null;
    if (serviceAttribute) {
        serviceAttribute.AdditionalProperties.forEach((prop) => {
            if (prop.Key === 'possible_values') {
                if (prop.Value.includes(',')) {
                    values = formatMultipleValues(prop.Value.split(','));
                } else if (prop.Value && prop.Value !== '') {
                    values = formatMultipleValues([prop.Value]);
                }
            }
        });
    }
    return values;
};

export const getIsRequired = (attribute, attributeConfiguration) => {
    const serviceAttribute = find(propEq(String(attribute.ServiceAttributeId), 'Value'))(attributeConfiguration);
    let isRequired = false;
    if (serviceAttribute) {
        serviceAttribute.AdditionalProperties.forEach((prop) => {
            if (prop.Key === 'is_required') {
                isRequired = prop.Value === 'True';
            }
        });
    }
    return isRequired;
};

export const getDependentAttributes = (attributes, attributeConfiguration) => {
    const dependentAttributes = [];
    attributes.forEach((attr) => {
        const attrConf = getCodeConfiguration(attr.ServiceAttributeId, attributeConfiguration);
        const attrConfDependentService = getCodeProperty(attrConf, 'dependent_service_attribute_id');
        if (attrConfDependentService !== '') {
            dependentAttributes.push(attr);
        }
    });
    return dependentAttributes;
};

export const showHideAttributes = (dependentAttributes, attributes, attributeConfiguration) => {
    dependentAttributes.forEach((attr) => {
        const attrConf = getCodeConfiguration(attr.ServiceAttributeId, attributeConfiguration);
        const attrConfDependentService = getCodeProperty(attrConf, 'dependent_service_attribute_id');
        const otherAttributeConf = getCodeConfiguration(attrConfDependentService, attributeConfiguration);
        const otherAttribute = otherAttributeConf ? find(propEq(parseInt(otherAttributeConf.Value, 10), 'ServiceAttributeId'))(attributes) : null;
        const attrConfDependentValue = getCodeProperty(attrConf, 'dependent_values');
        if (otherAttribute) {
            if (attrConfDependentValue.includes(',')) {
                attr.isHidden = !attrConfDependentValue.includes(otherAttribute.Value) || otherAttribute.isHidden ? true : false;
            } else {
                attr.isHidden = (otherAttribute.Value !== attrConfDependentValue) || otherAttribute.isHidden ? true : false;
            }
        } else {
            attr.isHidden = false;
        }
        if (attr.isHidden && attr.Editable) {
            attr.Value = null;
        }
    });
};

export const getCodeProperty = (item, propertyName) => {
    let values = null;
    if (item) {
        item.AdditionalProperties.forEach((prop) => {
            if (prop.Key === propertyName) {
                values = prop.Value;
            }
        });
    }
    return values;
};

export const getCodeConfiguration = (codeNum, attributeConfiguration) => {
    return find(propEq(String(codeNum), 'Value'))(attributeConfiguration);
};

export const formatMultipleValues = (value) => {
    const itemsArray = [
        {
            label: i18n.translate(CustomerCareLocaleKeys.TAX_LABELS.SELECT),
            value: null
        }
    ];
    value.forEach((val) => {
        itemsArray.push({
            label: val.trim(),
            value: val.trim()
        });
    });
    return itemsArray;
};

export const getCodeTablePropertyValueByName = (attribute, attributeConfiguration, propertyName) => {
    const serviceAttribute = find(propEq(String(attribute.ServiceAttributeId), 'Value'))(attributeConfiguration);
    return serviceAttribute && serviceAttribute.AdditionalProperties.find((prop) => {
        return prop.Key === propertyName;
    }).Value || undefined;
};