import CustomerCareLocaleKeys from '../../../locales/keys';
import  {
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {CUSTOMER_CATEGORY} from '../../createCustomerPopup/create.customer.popup.constants';
import {SelectedCustomerCategorySelector} from '../../../reducers/selectors/customer.selectors';
import {REGEXES} from '../../../customercare.constants';

const PHONE_NUMBER_FIELD_MAX_LENGTH = 20;

class ContactInformationSectionController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                contactMethodTypes: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.ContactMethod, store),
                customerCategory: SelectedCustomerCategorySelector(store),
                subscriberCategoryRequirements: MetadataSelectors.codes.SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: MetadataSelectors.codes.SubscriberRequirementsForCurrentBusinessUnitOrDefault(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });

        this.phoneNumberRegex = REGEXES.PHONE_NUMBER;

        this.phoneNumberFieldMaxLength = PHONE_NUMBER_FIELD_MAX_LENGTH;

        this.requirements = this.state.customerCategory === CUSTOMER_CATEGORY.COMMERCIAL
            ? this.state.subscriberCategoryRequirements
            : this.state.subscriberRequirements;
    }

    handleFormChanged() {
        this.onFormChanged()(this.customerModel);
    }

    isShowHeader() {
        return !!(this.state.subscriberRequirements.display_business_phone ||
            this.state.subscriberRequirements.display_home_phone ||
            this.state.subscriberRequirements.display_mobile_phone);
    }

    isError(elementName, fields = []) {
        const formElement = this.parentForm[elementName];
        let isError = false;
        if (formElement && (formElement.$touched || this.isFormSubmitted)) {
            if (fields.length === 0 && Object.keys(formElement.$error).length > 0) {
                isError = true;
            }

            fields.forEach(field => {
                if (formElement.$error[field]) {
                    isError = true;
                }
            });
        }
        return isError;
    }

    get isRequired() {
        return this.removeMultiOfferValidations !== undefined ? !this.removeMultiOfferValidations : !this.overrideValidations;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./contact.information.section.html'),
    bindings: {
        customerModel: '<',
        errorLookup: '<',
        onFormChanged: '&',
        isFormSubmitted: '<',
        overrideValidations: '<?',
        removeMultiOfferValidations: '<?'
    },
    require: {
        parentForm: '^form'
    },
    controller: ContactInformationSectionController,
    controllerAs: 'ContactInformationSection'
};
