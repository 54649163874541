import Immutable from 'seamless-immutable';

export const ORDER_TYPES = {
    CHANGE_OF_SERVICE: 8,
    GIFT: 6,
    GIFT_REDEMPTION: 7,
    IN_PROCESS: 1,
    RESTORE: 5,
    SUSPEND: 4
};

export const ORDER_STATUS = {
    CANCELED: 3,
    COMPLETE: 2,
    OPEN: 1,
    PENDING: 0
};

export const PAYMENT_STATUS = {
    PENDING: 2
};

export const GIFT_REDEMPTION_STATUS = Immutable({
    AVAILABLE: 1
});

export const ACTIVITY_COMPLETE = 6;

export const ACTIONS_ITEMS = {
    VIEW_SERVICES_ID: 'VIEW_SERVICES_ID',
    VIEW_SERVICES_ATTRIBUTES_ID: 'VIEW_SERVICES_ATTRIBUTES_ID',
    IMPORT_SERVICE_ATTRIBUTES_ID: 'IMPORT_SERVICE_ATTRIBUTES_ID',
    EXPORT_SERVICES_TEMPLATE_ID: 'EXPORT_SERVICES_TEMPLATE_ID',
    VIEW_PRICING_SCHEDULE_ID: 'VIEW_PRICING_SCHEDULE_ID'
};

export const IMPORT_BULK_SERVICE_ATTRIBUTES_ALLOWED_FILE_TYPE_NAME = 'csv';

export const IMPORT_BULK_SERVICE_ATTRIBUTES_UPLOAD_STATUSES = {
    FAILURE: 'failure',
    PROGRESS: 'progress',
    SUCCESS: 'success'
};

export const IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES = {
    FILE_SENT_TO_SINGLE_VIEW_WITH_NO_ERRORS: 8,
    FILE_SENT_TO_SINGLE_VIEW_FAILED: 9,
    FINISHED: 2,
    FINISHED_WITH_ERRORS: 3,
    NOT_STARTED: 0,
    PROCESSING: 1,
    PROCESS_BULK_SERVICES_COMPLETE_FAILED: 11,
    PROCESS_BULK_SERVICES_COMPLETE_WITH_NO_ERRORS: 10,
    UPLOAD_FAILED: 5,
    UPLOADED: 4,
    VALIDATED_WITH_ERRORS: 7,
    VALIDATED_WITHOUT_ERRORS: 6
};

export const IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_ERROR_STATUSES = [
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.VALIDATED_WITH_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FILE_SENT_TO_SINGLE_VIEW_FAILED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.PROCESS_BULK_SERVICES_COMPLETE_FAILED
];

export const IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_PENDING_STATUSES = [
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FILE_SENT_TO_SINGLE_VIEW_WITH_NO_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.PROCESSING,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.UPLOADED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.VALIDATED_WITHOUT_ERRORS
];

export const IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_MODIFIABLE_STATUSES = [
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.NOT_STARTED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.UPLOAD_FAILED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.VALIDATED_WITH_ERRORS
];

export const IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES_WITH_RESULTS_SUMMARY = [
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FILE_SENT_TO_SINGLE_VIEW_FAILED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.PROCESS_BULK_SERVICES_COMPLETE_FAILED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.PROCESS_BULK_SERVICES_COMPLETE_WITH_NO_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.FINISHED_WITH_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.VALIDATED_WITH_ERRORS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.VALIDATED_WITHOUT_ERRORS
];

export const MAX_NUM_IMPORT_UPLOADABLE_FILES = 1;
