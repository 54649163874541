import template from './usage.bar.chart.html';
import UsageChartHelper from './usage.chart.helper';
import {i18n} from 'invision-core';
import LocaleKeys from '../../../../locales/keys';

class UsageBarChartController {
    $onChanges() {
        this.maximumBalance = this.maximumBalance === undefined || this.maximumBalance === null ? 0 : this.maximumBalance;
        this.usageWidth = this.calculatePercentage(this.maximumBalance);
        this.color = UsageChartHelper.getStyleByUsage(this.usageWidth, this.unlimited);
        this.totalUsagePosition = this.positionTotalUsageMarker(this.maximumBalance);

        //TO Be CONSUMED: When we have the ability to cap individual accounts by API.
        //const maximumBalance = this.shared? this.individualInitialBalance() : this.maximumBalance;
        //const ratio = this.calculatePercentage(maximumBalance);
    }

    positionTotalUsageMarker(value) {
        if (!this.unlimited) {
            let ratio = value > 0 && this.sharedBalanceConsumed ? (this.sharedBalanceConsumed / value) * 100 : 0;
            ratio = Math.min(Math.max(ratio, 0), 100);
            return `${ratio}%`;
        } else {
            return '100%';
        }
    }

    calculatePercentage(value) {
        if (!this.unlimited && value > 0) {
            return Math.min(Math.max(this.subscriberUsage / value * 100, 0), 100);
        } else {
            return 100;
        }
    }

    /* //TO Be CONSUMED: When we have the ability to cap individual accounts by API.
    this.individualInitialBalance = () => {
        return this.shared.length > 0 ? this.initialBalance / this.shared.length : 0;
    };*/

    displayUsageDetails() {
        this.unitOfMeasure = this.unitOfMeasure.toUpperCase();
        if (!this.unlimited) {
            return `${this.subscriberUsage} ${this.unitOfMeasure} / ${this.maximumBalance} ${this.unitOfMeasure}`;
        } else {
            return `${this.subscriberUsage} ${this.unitOfMeasure} / ${i18n.translate(LocaleKeys.UNLIMITED)}`;
        }
    }
}

export default {
    controller: UsageBarChartController,
    controllerAs: 'UsageBarChartController',
    bindings: {
        maximumBalance: '<',
        name: '<',
        subscriberUsage: '<',
        sharedBalanceConsumed: '<',
        shared: '<',
        unitOfMeasure: '<',
        unlimited: '<'
    },
    template
};
