import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    MetadataCodeTypeSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {BillCycleDetailsSelector} from '../../../reducers/selectors/customer.billcycle.selectors';
import {retrieveBillCycleDetails} from '../../../reducers/actions/customer.billcycle.actions';
import {
    ADJUSTMENT_DUE_DAYS,
    CUSTOMER_CATEGORY,
    PAYMENT_DUE_DAYS,
    SUBSCRIBER_ADJUSTMENT_DUE_DAYS_LIMITS,
    SUBSCRIBER_PAYMENT_DUE_DAYS_LIMITS
} from '../../createCustomerPopup/create.customer.popup.constants';
import {
    CanUserUpdateBillingDetailsSelector,
    SelectedCustomerCategorySelector
} from '../../../reducers/selectors/customer.selectors';
import {findAccountingMethodBasedOnBu} from './billing.information.section.helper';
import {
    ACCOUNTING_METHODS,
    DEFAULT_ACCOUTNING_METHOD_CODE
} from './billing.information.section.constants';
export class BillingInformationSectionController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            ACCOUNTING_METHODS,
            CoreLocaleKeys,
            CustomerCareLocaleKeys,
            handleAdjustmentDueDaysChange: this.handleAdjustmentDueDaysChange.bind(this),
            handlePaymentDueDaysChange: this.handlePaymentDueDaysChange.bind(this),
            setDefaultAccountingMethod: this.setDefaultAccountingMethod.bind(this),
            subscriberAdjustmentDueDaysLimits: SUBSCRIBER_ADJUSTMENT_DUE_DAYS_LIMITS,
            subscriberPaymentDueDaysLimits: SUBSCRIBER_PAYMENT_DUE_DAYS_LIMITS
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                accountingMethod: MetadataCodeTypeSelector(CODES.Accounting, store),
                billCycleDetails: BillCycleDetailsSelector(store),
                canUserUpdateBillingDetails: CanUserUpdateBillingDetailsSelector(store),
                convergentBillerBehaviorConfig: MetadataCodeTypeSelector(CODES.ConvergentBillerBehaviorConfig, store),
                currentBusinessUnit: CurrentBusinessUnitSelector(store),
                customerCategory: SelectedCustomerCategorySelector(store),
                subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
                subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store)
            };
        };
        const controllerActions = {
            fetchCodeTypesThunk,
            retrieveBillCycleDetails
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.setSubscriberPaymentDueDays();
        this.defaultAccountingMethod = findAccountingMethodBasedOnBu(this.state.accountingMethod);
        this.selectedDefaultAccountingMethod = this.customerModel.SubscriberTypeDetails.AccountingMethod ?
            this.ACCOUNTING_METHODS[this.customerModel.SubscriberTypeDetails.AccountingMethod] :
            this.ACCOUNTING_METHODS[this.extractDefaultAccountingMethodValue()];
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    extractDefaultAccountingMethodValue() {
        return this.defaultAccountingMethod.find((accountingMethod) => {
            return accountingMethod.Key === DEFAULT_ACCOUTNING_METHOD_CODE;
        }).Value;
    }

    handleAdjustmentDueDaysChange(updatedAdjustmentDueDays) {
        this.customerModel.SubscriberTypeDetails.AdjustmentDueDays = updatedAdjustmentDueDays;
        this.onFormChanged()(this.customerModel);
    }

    handlePaymentDueDaysChange(updatedPaymentDueDays) {
        this.customerModel.SubscriberTypeDetails.PaymentDueDays = updatedPaymentDueDays;
        this.onFormChanged()(this.customerModel);
    }

    get requirements() {
        if (this.state) {
            return this.state.customerCategory === CUSTOMER_CATEGORY.COMMERCIAL ? this.state.subscriberCategoryRequirements : this.state.subscriberRequirements;
        } else {
            return {};
        }
    }

    setSubscriberPaymentDueDays() {
        const convergentBillerDefaultConfig = this.state.convergentBillerBehaviorConfig?.length &&
        (this.state.convergentBillerBehaviorConfig.find((config) => {
            return !config.Global;
        }) || this.state.convergentBillerBehaviorConfig.find((config) => {
            return config.Global;
        }));
        const convergentBillerBehaviorConfigPaymentDueDays = convergentBillerDefaultConfig?.AdditionalProperties.find((additionalProperties) => {
            return additionalProperties.Key === PAYMENT_DUE_DAYS;
        })?.Value;
        const convergentBillerBehaviorConfigAdjustmentDueDays = convergentBillerDefaultConfig?.AdditionalProperties.find((additionalProperties) => {
            return additionalProperties.Key === ADJUSTMENT_DUE_DAYS;
        })?.Value;
        const defaultPaymentDueDays = this.requirements && this.requirements.default_payment_due_days.length ? this.requirements.default_payment_due_days : convergentBillerBehaviorConfigPaymentDueDays;
        const defaultAdjustmentDueDays = this.requirements && this.requirements.default_adjustment_due_days.length ? this.requirements.default_adjustment_due_days : convergentBillerBehaviorConfigAdjustmentDueDays;
        if (this.customerModel && this.customerModel.SubscriberTypeDetails) {
            this.paymentDueDays = this.customerModel.SubscriberTypeDetails.PaymentDueDays !== null ? this.customerModel.SubscriberTypeDetails.PaymentDueDays : convertStringToNumber(defaultPaymentDueDays);
            this.adjustmentDueDays = this.customerModel.SubscriberTypeDetails.AdjustmentDueDays !== null ? this.customerModel.SubscriberTypeDetails.AdjustmentDueDays : convertStringToNumber(defaultAdjustmentDueDays);
        }
    }

    setDefaultAccountingMethod(updatedAccountingMethod) {
        this.selectedDefaultAccountingMethod = this.customerModel.SubscriberTypeDetails.AccountingMethod;
        this.customerModel.SubscriberTypeDetails.AccountingMethod = updatedAccountingMethod.value;
        this.onFormChanged()(this.customerModel);
    }
}

export default {
    template: require('./billing.information.section.html'),
    bindings: {
        customerModel: '<',
        isAccountingMethodReadonly: '<?',
        isCreditCheckPopup: '<?',
        isFormSubmitted: '<',
        isNewConnect: '<',
        isNewCustomer: '<',
        onFormChanged: '&'
    },
    require: {
        parentForm: '^form'
    },
    controller: BillingInformationSectionController,
    controllerAs: 'BillingInformationSection'
};
