import angular from 'angular';
import 'invision-core';

import {routing as RoutingConfig} from './make.payment.config';
import CustomerMakePaymentController from './make.payment.component';

export default angular.module('invision.customercare.customer.makePayment', [
    'invision.core.components'
])
    .config(RoutingConfig)
    .component('customerMakePayment', CustomerMakePaymentController)
    .name;

