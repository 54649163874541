import clone from 'ramda/src/clone';
import equals from 'ramda/src/equals';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import memoize from 'lodash/memoize';
import merge from 'ramda/src/mergeWith';
import propEq from 'ramda/src/propEq';

import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../../../../../../locales/keys';

class SelectableProductItemsController {
    /**************************************************************
    * VIEW MODEL DEFINITION ('configuration' binding)
    * {
    *      items: [],
    *      mappedPricingPlanId: number, -- Pricing plan to show / allow (expects item.pricingPlans)
    *      showInstruction: boolean, -- Show selection instructions
    *      minQuantity: number, -- Min quantity allowed (0 for unvalidated / unrestricted)
    *      maxQuantity: number, -- Max quantity allowed (0 for unvalidated / unrestricted)
    *      usePlanSelection: boolean, -- Should show dropdown for plan selection
    *      showPurchasedPlans: boolean, -- Expects item.purchasedPlans
    *      usePerItemQuantity: boolean -- Use per-item pricing plan to define min/max quantities and allow quantity entry
    * }
    **************************************************************/

    constructor($filter) {
        Object.assign(this, {
            $filter,
            LocaleKeys,
            viewModel: {
                instanceProperties: {},
                items: []
            },
            configurationItemsWithSingleSelectOptions: []
        });
    }

    $onInit() {
        this.modelCtrl.$validators['invalidSelectableItems'] = (modelValue, viewValue) => {
            return this._validateSelection(modelValue, viewValue);
        };

        this._initializeViewModel();
        this._initializeConfigurationItemsWithSingleSelectOptions();
        this._mergeInstanceProperties();
        this._updateViewValueFromItemsCollection();
        this.modelCtrl.$commitViewValue();
    }

    _initializeViewModel() {
        if (!isNil(this.ngModel)) {
            this.viewModel = merge(this.viewModel, this.ngModel);
        } else {
            this.viewModel.items = clone(this.configuration.items.filter(propEq(true, 'isSelected')));
        }
    }

    _initializeConfigurationItemsWithSingleSelectOptions() {
        let pp;
        this.configurationItemsWithSingleSelectOptions = this.configuration.items.map((item) => {
            item.singleSelectOptions = Object.keys(item.pricingPlans).map((pricingPlanId) => {
                pp = item.pricingPlans[pricingPlanId];
                return {
                    isDiscounted: this._pricingPlanIsDiscounted(pp),
                    pricingPlan: pp,
                    pricingPlanId: pricingPlanId,
                    selectableItem: item,
                    selected: item.isSelected && equals(item.selectedPlanId, pricingPlanId) || false,
                    text: ''
                };
            });

            if (item.singleSelectOptions && item.singleSelectOptions.length) {
                item.singleSelectOptions.unshift({
                    text: i18n.translate(LocaleKeys.SELECT_WITH_ELLIPSIS)
                });
            }

            return item;
        });
    }

    _pricingPlanIsDiscounted(pricingPlan) {
        return (pricingPlan.discountedAmount || pricingPlan.discountedAmount === 0)
            && pricingPlan.discountedAmount !== pricingPlan.ChargeAmount;
    }

    _mergeInstanceProperties() {
        if (this.viewModel.items && this.viewModel.items.length > 0) {
            this.viewModel.items.forEach((viewModelItem) => {
                const match = this.configuration.items.find((inputItem) => {
                    return inputItem.product.Id === viewModelItem.product.Id;
                });

                if (!isNil(match) && !isNil(match.instanceProperties) && !isEmpty(match.instanceProperties)) {
                    const currentProps = this.viewModel.instanceProperties[viewModelItem.product.Id] || {};
                    this.viewModel.instanceProperties[viewModelItem.product.Id] = merge(match.instanceProperties, currentProps);
                }
            });
        }
    }

    _applyCurrencyFilter(amount, currency) {
        return this.$filter('invCurrency')(amount, currency);
    }

    $onChanges(changesObj) {
        if (changesObj.configuration && !changesObj.configuration.isFirstChange()) {
            this._initializeConfigurationItemsWithSingleSelectOptions();
        }

        if (isNil(this.configuration.minQuantity)) {
            this.configuration.minQuantity = 0;
        }

        if (isNil(this.configuration.maxQuantity)) {
            this.configuration.maxQuantity = 0;
        }

        if (isNil(this.configuration.showInstruction)) {
            this.configuration.showInstruction = false;
        }

        if (isNil(this.configuration.items)) {
            this.configuration.items = [];
        }

        this.setInstructionalMessage();

        this._mergeInstanceProperties();

        //The configuration items change when "Show More" is clicked, need to resync with view model
        if (this.ngModel && this.ngModel.items && this.ngModel.items.length) {
            this.ngModel.items.forEach((item) => {
                if (item.isSelected) {
                    const match = this.configuration.items.find((targetItem) => {
                        return targetItem.product.Id === item.product.Id;
                    });

                    if (match) {
                        match.selectedPlanId = item.selectedPlanId;
                        match.isSelected = true;
                    }
                }
            });
        }

        this.modelCtrl.$validate();
    }

    setInstructionalMessage() {
        if (!this.configuration.showInstruction) {
            this.instructionalMessage = null;
            return;
        }

        //Create the memoized message function if it doesn't exist
        if (isNil(this._buildInstructionalMessage)) {
            this._buildInstructionalMessage = memoize((min, max) => {

                if (this.configuration.items && this.configuration.items.length > 0) {
                    const messageParams = {
                        min: min,
                        max: max
                    };

                    if (min === 0 && max === 0) { //No min or max
                        this.instructionalMessage = i18n.translate(LocaleKeys.SELECTABLE_ITEMS.FROM);
                    } else if ((min > max)) { //No max
                        this.instructionalMessage = i18n.translate(LocaleKeys.SELECTABLE_ITEMS.AT_LEAST, messageParams);
                    } else if (min === 0 && max > min) { //No min
                        this.instructionalMessage = i18n.translate(LocaleKeys.SELECTABLE_ITEMS.AT_MOST, messageParams);
                    } else if (min === max) { //Min and max are the same (exact number)
                        this.instructionalMessage = i18n.translate(LocaleKeys.SELECTABLE_ITEMS.EXACTLY, messageParams);
                    } else { //Both min and max
                        this.instructionalMessage = i18n.translate(LocaleKeys.SELECTABLE_ITEMS.BETWEEN, messageParams);
                    }
                } else {
                    this.instructionalMessage = null;
                }
            });
        }

        this._buildInstructionalMessage(this.configuration.minQuantity, this.configuration.maxQuantity);
    }

    onItemSelection() {
        this._updateViewValueFromItemsCollection();
    }

    onSingleSelectPlanChange(singleSelectOptions, selectedPricingPlan) {

        singleSelectOptions.forEach((pricingPlan) => {
            if (pricingPlan.selectableItem && pricingPlan.selectableItem.isSelected) {
                pricingPlan.selectableItem.isSelected = false;
                pricingPlan.selectableItem.selectedPlanId = null;
            }
        });

        if (selectedPricingPlan.selectableItem) {
            selectedPricingPlan.selectableItem.isSelected = true;
            selectedPricingPlan.selectableItem.selectedPlanId = selectedPricingPlan.pricingPlanId;
        }

        this._updateViewValueFromItemsCollection();
    }

    onSingleSelectPlanRemove(deselectedPricingPlan) {
        deselectedPricingPlan.selectableItem.isSelected = false;
        deselectedPricingPlan.selectableItem.selectedPlanId = '';
        this._updateViewValueFromItemsCollection();
    }

    onQuantityChange(selectableItem) {
        if (selectableItem.quantity === '' || selectableItem.quantity === null) {
            selectableItem.quantity = 0;
            selectableItem.isSelected = false;
        } else {
            const qty = Number(selectableItem.quantity);
            selectableItem.isSelected = qty > 0;
        }

        this._updateViewValueFromItemsCollection();
    }

    _updateViewValueFromItemsCollection() {
        //One liner but called from multiple places.  Abstracted in case logic changes
        this.viewModel.items = clone(this.configuration.items.filter(propEq(true, 'isSelected')));
        this.modelCtrl.$setViewValue(this.viewModel);
        this.modelCtrl.$validate();
    }

    _validateSelection(modelValue, viewValue) {
        const value = modelValue || viewValue;
        let isValid = true;

        if (!isNil(value) && this.configuration.items && this.configuration.items.length > 0) {
            const min = this.configuration.minQuantity;
            const max = this.configuration.maxQuantity;

            if (min === 0 && max === 0) {
                isValid = true;
            } else {
                const selectedCount = value.items ? value.items.length : 0;

                if (min > 0 && selectedCount < min) {
                    isValid = false;
                } else if (max > 0 && selectedCount > max) {
                    isValid = false;
                } else {
                    isValid = true;
                }
            }
        }

        return isValid;
    }
}

export default {
    template: require('./product.selectable.items.html'),
    controller: SelectableProductItemsController,
    require: {
        parentForm: '^^form',
        modelCtrl: 'ngModel'
    },
    bindings: {
        configuration: '<',
        disableCurrentInstanceProperties: '<?',
        ngModel: '<'
    }
};
