import __ from 'ramda/src/__';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {NA} from '../../../../reducers/constants/billing.payments.constants';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import CustomerLocaleKeys from '../../../../locales/keys';
import {
    ENTITLEMENT_CONTEXT_TYPES,
    ENTITLEMENT_SHARE_STATUS,
    SERVICE_ENTITLEMENTS_ACTIVATION_STATUS
} from '../../../../customercare.constants';

class EntitlementThumbnailController {
    constructor(uiNotificationService, $ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys,
            ENTITLEMENT_CONTEXT_TYPES,
            ENTITLEMENT_SHARE_STATUS,
            NA,
            SERVICE_ENTITLEMENTS_ACTIVATION_STATUS,
            uiNotificationService,
            unitOfMeasure: undefined
        });
    }

    $onInit() {
        const codeTables = [CODES.EntitlementRecurringPeriodType];

        this.entitlementTooltip = require('../../../../components/shared/tooltipTemplates/entitlement.tooltip.html');

        const controllerActions = {
            fetchCodeTypes
        };

        const mapStateToTarget = (store) => {
            return {
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                entitlementPeriodTypes: MetadataCodeTypeDictionarySelector(
                    CODES.EntitlementRecurringPeriodType,
                    store
                )
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(
            mapStateToTarget,
            controllerActions
        )((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(
                        error.translatedMessage
                    );
                });
            }
        });

        this.setActivationEntitlementStatus();
    }

    $onChanges(changesObj) {
        if (changesObj.entitlement) {
            this.setActivationEntitlementStatus();
        }
    }

    setActivationEntitlementStatus() {
        this.isEntitlementFirstUse =
            this.entitlement.ActivationStatus ===
            SERVICE_ENTITLEMENTS_ACTIVATION_STATUS.AWAITING_ENTITLEMENT_FIRST_USE;
        this.isEntitlementFirstUseWithPricingPlan =
            this.entitlement.ActivationStatus ===
            SERVICE_ENTITLEMENTS_ACTIVATION_STATUS.AWAITING_ENTITLEMENT_FIRST_USE_WITH_PRICING_PLAN;
    }

    canShareEntitlement() {
        return (
            !!this.entitlement.EntitlementIdentifier.EntitlementId &&
            !this.entitlement.EntitlementIdentifier
                .RefundEntitlementConfigurationId &&
            this.entitlement.EntitlementContextTypeCode ===
                this.ENTITLEMENT_CONTEXT_TYPES.ACCOUNT
        );
    }
}

export default {
    bindings: {
        currencyCode: '<?',
        entitlement: '<',
        isSharedUsageTab: '<?',
        serviceIdentifier: '<?',
        onShareActionClicked: '&?',
        showTotalUsed: '<?'
    },
    controller: EntitlementThumbnailController,
    template: require('./entitlement.thumbnail.template.html')
};
