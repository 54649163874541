import {
    CurrentCouponRedemptionCodeSelector,
    CurrentCouponRedemptionCodeValueSelector
} from '../../reducers/selectors/coupon.redemption.code.selectors';
import {
    getCouponSearchByIdPromise
} from '../../reducers/actions/search.coupon.actions';
import {COUPON_ACCESS} from '../../security.attributes';
export const COUPON_DETAILS_GLOBAL_ROUTE = 'index.customercare.coupon';

export function routing($stateProvider) {
    $stateProvider.state(COUPON_DETAILS_GLOBAL_ROUTE, {
        url: '/coupon/:couponRedemptionCodeId',
        template: '<coupon-redemption-code></coupon-redemption-code>',
        data: {
            windowTitle: CurrentCouponRedemptionCodeValueSelector,
        },
        securityAttributes: [COUPON_ACCESS],
        resolve: {
            couponRedemptionCodeIdIsValid: ($q, $stateParams) => {
                const couponRedemptionCodeId = $stateParams.couponRedemptionCodeId;

                if (couponRedemptionCodeId !== undefined && couponRedemptionCodeId.trim().length
                    && !isNaN(couponRedemptionCodeId)) {
                    return $q.resolve();
                } else {
                    return $q.reject({
                        redirectTo: 'index.customercare'
                    });
                }
            },
            coupon: ($ngRedux, $stateParams, $q, couponRedemptionCodeIdIsValid) => { // eslint-disable-line no-unused-vars
                const currentCouponRedemptionCode= CurrentCouponRedemptionCodeSelector($ngRedux.getState());
                const couponRedemptionCodeId = $stateParams.couponRedemptionCodeId;

                if (currentCouponRedemptionCode && couponRedemptionCodeId === currentCouponRedemptionCode.RedemptionCode.Id) {
                    return $q.resolve();
                } else {
                    return getCouponSearchByIdPromise($ngRedux.dispatch, couponRedemptionCodeId);
                }
            }
        }
    });
}
