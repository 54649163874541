import customerCareKeys from '../../../../../locales/keys';
import {
    CurrentAccountSummarySelector,
    ConvergentBillerCurrentPromiseToPayInstallment,
    ConvergentBillerIsInPromiseToPay,
    IsFetchingSubscriberSummarySelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveConvergentBillerTreatmentDetails} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector
} from '../../../../../reducers/selectors/customer.selectors';

class TreatmentController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            customerCareKeys
        });
    }

    $onInit() {
        const controllerActions = {
            retrieveConvergentBillerTreatmentDetails
        };
        const mapStateToTarget = (store) => {
            return {
                accountSummary: CurrentAccountSummarySelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPromiseToPayInstallment: ConvergentBillerCurrentPromiseToPayInstallment(store),
                hasPromiseToPay: ConvergentBillerIsInPromiseToPay(store),
                isFetchingAccountSummary: IsFetchingSubscriberSummarySelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.hasPromiseToPay) {
            this.actions.retrieveConvergentBillerTreatmentDetails(this.state.currentCustomerId);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./treatment.html'),
    controller: TreatmentController,
    controllerAs: 'Treatment'
};
