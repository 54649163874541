class LandingPageController {
}

export default {
    template: require('./landing.page.html'),
    controller: LandingPageController,
    bindings: {
        userContext: '<',
        widgets: '<'
    }
};