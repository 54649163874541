import concat from 'ramda/src/concat';
import find from 'ramda/src/find';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {FeatureToggleConstants} from 'invision-core';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {
    OFFER_STATUS_INDICATOR_STATUS,
    NON_BULK_SERVICE_STATUSES
} from 'invision-core/src/constants/status.constants';
import customerCareKeys from '../../../../../locales/keys';
import {getFriendlyName} from '../../../../../reducers/selectors/billing.payments.invoice.selectors.helper';
import {NA} from '../../../../../reducers/constants/billing.payments.constants';
import {MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME} from '../../../../../reducers/constants/products.wizard.constants';
import {
    BILLER_RULE_INSTANCE_TYPE_COMBINATIONS,
    BILLER_RULE_INSTANCE_TYPE,
    ENTITLEMENT_CONTEXT_TYPES,
    ENTITLEMENT_SHARE_STATUS
} from '../../../../../customercare.constants';
import {getOptionsByActiveBriType} from '../offering/offering.component.helper';
import {USAGE_CAPS_URL} from '../../../servicesAndUsage/usageCaps/usage.caps.constants';
import {ORDER_STATUS} from '../../../orderHistory/orderDetails/order.details.constants';
import {DASHBOARD_ROUTE} from '../../../../../reducers/constants/dashboard.constants';
import {suspendOrResumeServices} from '../../../../../reducers/actions/services.actions';
import {IsSuspendingOrResumingServicesSelector} from '../../../../../reducers/selectors/services.selectors';
import {SERVICE_USAGE_DETAILS_STATE_NAME} from '../../../servicesAndUsage/usageDetails/usage.details.config';
import {
    retrieveConvergentBillerAccountDetails,
    retrieveConvergentBillerSubscriberSummary,
    updateDashboardEntitlementShareStatus
} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {
    SERVICES_AND_SHARED_ENTITLEMENTS,
    SERVICES_AND_SHARED_ENTITLEMENTS_TAB
} from '../../../servicesAndUsage/services.and.usage.config';
import {
    ConvergentBillerCurrencyCodeSelector,
    IsUpdatingDashboardEntitlementStatusSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {setSelectedSharedEntitlementIndex} from '../../../../../reducers/actions/shared.entitlements.actions';
import {retrieveSubscriberFinanceDetails} from '../../../../../reducers/actions/customer.devices.actions';

class ServicesContentsController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            customerCareKeys,
            coreLocaleKeys: CoreLocaleKeys,
            closeSuspendResumeServiceModal: this.closeSuspendResumeServiceModal.bind(this),
            ENTITLEMENT_CONTEXT_TYPES,
            goToUsageDetails: this.goToUsageDetails.bind(this),
            NA,
            NON_BULK_SERVICE_STATUSES,
            onApiError: this.onApiError.bind(this),
            onCloseUpdateFriendlyNameModal: this.onCloseUpdateFriendlyNameModal.bind(this),
            onSubmitUpdateFriendlyNameModal: this.onSubmitUpdateFriendlyNameModal.bind(this),
            suspendResumeServices: this.suspendResumeServices.bind(this),
            uiNotificationService,
            updateSelectedService: this.updateSelectedService.bind(this)
        });
    }

    $onInit() {
        const controllerActions = {
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            retrieveConvergentBillerAccountDetails,
            retrieveConvergentBillerSubscriberSummary,
            retrieveSubscriberFinanceDetails,
            setSelectedSharedEntitlementIndex,
            suspendOrResumeServices,
            updateDashboardEntitlementShareStatus
        };
        const mapStateToProps = (store) => {
            return {
                currencyCode: ConvergentBillerCurrencyCodeSelector(store),
                features: FeatureTogglesSelector(store),
                featureTogglesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
                isSuspendingOrResuming: IsSuspendingOrResumingServicesSelector(store),
                isUpdatingDashboardEntitlementStatus: IsUpdatingDashboardEntitlementStatusSelector(store)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.featureTogglesLoaded) {
            this.actions.fetchCodeTypes(CODES.FeatureToggleConfig);
        }

        this.updateFriendlyNameModalConfig = {
            onRegisterApi: ({api}) => {
                this.updateFriendlyNameModalConfigApi = api;
            }
        };
        this.servicesSuspendResumeModalConfig = {
            onRegisterApi: (event) => {
                this.servicesSuspendResumeModalAPI = event.api;
            }
        };
        this.isExpanded = true;
    }

    $onChanges(changes) {
        if (changes.services && changes.services.currentValue && changes.services.currentValue.length > 0) {
            const getSelectedService = () => {
                return this.servicesAvailable.find((service) => {
                    return service.selected;
                });
            };

            const prevSelectedService = this.servicesAvailable
                ? getSelectedService()
                : null;
            const serviceIdentifiersMatch = (service) => {
                const previousService = pathOr(i18n.translate(customerCareKeys.HOUSEHOLD.SHARED_ENTITLEMENTS), ['service', 'ServiceIdentifier', 'Value'], prevSelectedService);
                const currentService = pathOr(i18n.translate(customerCareKeys.HOUSEHOLD.SHARED_ENTITLEMENTS), ['ServiceIdentifier', 'Value'], service);
                return previousService === currentService;
            };
            const isSelected = (service, index) => {
                if (prevExistsInCurrent) {
                    return serviceIdentifiersMatch(service);
                } else if (sharedEntitlement) {
                    return !service.ServiceIdentifier;
                } else {
                    return index === 0;
                }
            };
            const prevExistsInCurrent = prevSelectedService
                ? changes.services.currentValue.some((service) => {
                    return serviceIdentifiersMatch(service);
                })
                : false;
            const isSharedEntitlement = (service) => {
                return !service.ServiceIdentifier && service.EntitlementBalances.length > 0;
            };
            const sharedEntitlement = this.services.find(isSharedEntitlement);
            this.servicesAvailable = this.services
                .asMutable({
                    deep: true
                })
                .filter((service) => {
                    const hasIndividualEntitlements = service.ServiceIdentifier && getFriendlyName(service.ServiceIdentifier) !== NA;
                    const hasSharedEntitlements = isSharedEntitlement(service);
                    return hasIndividualEntitlements || hasSharedEntitlements;
                })
                .map((service, index) => {
                    service.isSharedEntitlement = isSharedEntitlement(service);
                    return {
                        text: this.getServiceWidgetTitle(service.ServiceIdentifier),
                        selected: isSelected(service, index),
                        service,
                        index
                    };
                }).sort((service) => {
                    //Make the selected service first
                    return service.selected ? 0 : 1;
                });
            this.updateSelectedService(getSelectedService());
        }
    }

    sortOfferOptionsByBillerTypeTotal(optionsToBeSorted, billerType) {
        return optionsToBeSorted.sort((a, b) => {
            const firstItem = find(propEq(billerType, 'Type'))(concat(a.BillerRulePrices || [], a.BillerTypeAmounts || []));
            const secondItem = find(propEq(billerType, 'Type'))(concat(b.BillerRulePrices || [], b.BillerTypeAmounts || []));
            return (firstItem && secondItem) ? secondItem.TotalAmount - firstItem.TotalAmount : true;
        });
    }

    updateSelectedService(service) {
        if (service) {
            this.selectedService = service.service;
            this.servicesAvailable.forEach((availableService) => {
                availableService.selected = service.index === availableService.index;
            });
            const briOptions = getOptionsByActiveBriType(this.selectedService, PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE);

            this.sortedOneTimeOptions = this.sortOfferOptionsByBillerTypeTotal(briOptions[BILLER_RULE_INSTANCE_TYPE.ONETIME], BILLER_RULE_INSTANCE_TYPE.ONETIME);
            this.sortedRecurringOptions = this.sortOfferOptionsByBillerTypeTotal(briOptions[BILLER_RULE_INSTANCE_TYPE.RECURRING], BILLER_RULE_INSTANCE_TYPE.RECURRING);
            this.usageOnlyOptions = briOptions[BILLER_RULE_INSTANCE_TYPE_COMBINATIONS.STANDALONE_USAGE];

            this.hasOneTimeOptions = this.sortedOneTimeOptions.length > 0;
            this.hasRecurringOptions = this.sortedRecurringOptions.length > 0;
            this.hasUsageOnlyOptions = this.usageOnlyOptions.length > 0;
            this.hasPendingChange = this.getHasPendingChange();
            this.serviceFeatureStatus = this.getServiceFeatureStatus();
            this.serviceWidgetTitle = this.getServiceWidgetTitle(service.service.ServiceIdentifier);
            this.ellipsisMenuItems = this.getMoreMenuItems();
        }
    }

    getHasPendingChange() {
        if (!this.selectedService || !this.selectedService.ServiceFeatures) {
            return false;
        }

        return this.selectedService.ServiceFeatures.some((e) => {
            return e.Status === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE || e.Status === OFFER_STATUS_INDICATOR_STATUS.PENDING_REMOVED;
        });
    }

    getServiceFeatureStatus() {
        if (!this.selectedService || !this.selectedService.ServiceFeatures) {
            return false;
        }

        const statuses = this.selectedService.ServiceFeatures.map((e) => {
            return e.Status;
        });
        const bothPending = () => {
            const active = statuses.some((status) => {
                return status === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE;
            });
            const remove = statuses.some((status) => {
                return status === OFFER_STATUS_INDICATOR_STATUS.PENDING_REMOVED;
            });
            return active && remove;
        };

        return (statuses.length > 1 && bothPending()) ? OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE : Math.max.apply(Math, statuses);
    }

    goToUsageDetails(entitlementIndex, entitlementId) {
        if (this.selectedService.isSharedEntitlement) {
            this.actions.setSelectedSharedEntitlementIndex(entitlementIndex);
            return this.stateGo()(SERVICES_AND_SHARED_ENTITLEMENTS, {
                tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SHARED_ENTITLEMENTS
            });
        } else {
            return this.stateGo()(SERVICE_USAGE_DETAILS_STATE_NAME, {
                entitlementId,
                entitlementIndex,
                serviceIdentifierValue: this.selectedService.ServiceIdentifier.Value,
                serviceIdentifierName: this.selectedService.ServiceIdentifier.ServiceIdentifierName,
                serviceAttributeId: this.selectedService.ServiceIdentifier.ServiceAttributeId,
                serviceIdentifierFriendlyName: this.selectedService.ServiceIdentifier.FriendlyName,
                fromStateObject: {
                    name: DASHBOARD_ROUTE,
                    params: {}
                }
            });
        }
    }

    handleEllipsisMenuItemSelection({item: itemProps}) {
        itemProps.action(itemProps);
    }

    onCloseUpdateFriendlyNameModal() {
        this.showUpdateFriendlyNameModal = false;
        this.updateFriendlyNameModalConfigApi.close();
    }

    onSubmitUpdateFriendlyNameModal() {
        this.refreshServices && this.refreshServices();

        this.showUpdateFriendlyNameModal = false;
        this.updateFriendlyNameModalConfigApi.close();
    }

    goToManageFeatures() {
        return this.stateGo()(MANAGE_SERVICE_FEATURES_ORDER_STATE_NAME, {
            service: this.selectedService.ServiceIdentifier,
            serviceId: this.selectedService.ServiceIdentifier.Value,
            serviceTypeId: this.selectedService.ServiceIdentifier.ServiceId,
            serviceInstanceId: this.selectedService.ServiceIdentifier.ServiceInstanceId
        });
    }

    getMoreMenuItems() {
        const ellipsisMenuItems = [];
        if (this.selectedService && !this.selectedService.isSharedEntitlement) {
            const isSelectedServiceSuspended = this.selectedService.Status === NON_BULK_SERVICE_STATUSES.SUSPENDED;
            const serviceSuspended = this.availableProducts.find((product) => {
                if (product.OrderStatus === ORDER_STATUS.OPEN || product.OrderStatus === ORDER_STATUS.PENDING) {
                    return product.Items.find((item) => {
                        return item.LockerItemId === pathOr(null, ['ServiceIdentifier', 'SubscriberProductId'], this.selectedService);
                    });
                } else {
                    return false;
                }
            });
            if (this.selectedService.usageCharges) {
                ellipsisMenuItems.push({
                    title: i18n.translate(this.customerCareKeys.VIEW_USAGE_DETAILS),
                    action: () => {
                        return this.goToUsageDetails();
                    }
                });
            }
            if (this.selectedService) {
                ellipsisMenuItems.push({
                    title: i18n.translate(this.customerCareKeys.MANAGE_ADD_ONS),
                    action: (item) => {
                        if (!item.isLocked) {
                            return this.goToManageFeatures();
                        }
                    },
                    isLocked: isSelectedServiceSuspended,
                    disabled: isSelectedServiceSuspended
                });
            }
            ellipsisMenuItems.push({
                title: i18n.translate(customerCareKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE),
                action: () => {
                    this.showUpdateFriendlyNameModal = true;
                    this.$timeout(() => {
                        this.updateFriendlyNameModalConfigApi.open();
                    });
                }
            });
            if (this.selectedService.TopUpAvailable) {
                ellipsisMenuItems.push({
                    title: i18n.translate(this.customerCareKeys.TOP_UPS.APPLY_TOP_UP),
                    action: () => {
                        return this.stateGo()('index.customercare.customer.topups', {
                            serviceId: this.selectedService.ServiceIdentifier.Value
                        });
                    }
                });
            }
            //Usage Caps
            ellipsisMenuItems.push({
                title:  i18n.translate(this.customerCareKeys.USAGE_CAP_SETTINGS),
                action: () => {
                    return this.stateGo()(USAGE_CAPS_URL, {
                        serviceId: this.selectedService.ServiceIdentifier.Value
                    });
                }
            });
            if (this.hasSuspendAccess) {
                if (serviceSuspended) {
                    ellipsisMenuItems.push({
                        title: i18n.translate(this.customerCareKeys.SUSPEND_SERVICE),
                        action: () => {},
                        disabled: true,
                        isLocked: true,
                        hasTooltip: true,
                        tooltip: {
                            context: {
                                description: i18n.translate(this.customerCareKeys.SUSPENDED_SERVICE_ERROR)
                            },
                            template: require('./suspend.service.tooltip.html'),
                            icon: 'lock'
                        }
                    });
                } else if (this.selectedService.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
                    ellipsisMenuItems.push({
                        title: i18n.translate(this.customerCareKeys.SUSPEND_SERVICE),
                        action: () => {
                            return this.openSuspendOrResumeServiceModal();
                        }
                    });
                }
                if (this.selectedService.Status === NON_BULK_SERVICE_STATUSES.SUSPENDED) {
                    ellipsisMenuItems.push({
                        title: i18n.translate(this.customerCareKeys.RESUME_SERVICE),
                        action: () => {
                            return this.openSuspendOrResumeServiceModal();
                        }
                    });
                }
            }
        }
        return ellipsisMenuItems;
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    launchOptionQuickEdit() {
        return this.goToManageFeatures();
    }

    serviceFeatureIsActive(feature) {
        return OFFER_STATUS_INDICATOR_STATUS.ACTIVE === feature.Status && !this.hasPendingChange;
    }

    enableServiceFeatureEdit(feature) {
        return this.serviceFeatureIsActive(feature) && feature.AllowEdit;
    }

    closeSuspendResumeServiceModal() {
        this.servicesSuspendResumeModalAPI.close();
        this.showServicesSuspendResumeModal = false;
    }

    openSuspendOrResumeServiceModal() {
        this.services = [];
        if (this.selectedService.Status === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            this.isActive = true;
            this.reasonCodes = this.suspendServiceReasonCodes;
        } else {
            this.isActive = false;
            this.reasonCodes = this.resumeServiceReasonCodes;
        }
        this.showServicesSuspendResumeModal = true;
        this.services.push(this.selectedService);
        this.$timeout(this.servicesSuspendResumeModalAPI.open);
    }

    suspendResumeServices(serviceDetails) {
        this.actions.suspendOrResumeServices(this.customerId, serviceDetails, this.isActive).then(() => {
            this.refreshServices && this.refreshServices();
            const promises = [
                this.actions.retrieveConvergentBillerSubscriberSummary(this.customerId),
                this.actions.retrieveConvergentBillerAccountDetails(this.customerId, true)
            ];
            if (this.servicesAvailable.find(({service}) => {
                return !!service.deviceFinance;
            }) && !this.state.features[FeatureToggleConstants.OFFCYCLE_BYPASS_ARM]) {
                promises.push(this.actions.retrieveSubscriberFinanceDetails(this.customerId));
            }
            Promise.all(promises).catch(this.onApiError);

            this.uiNotificationService.transientSuccess(i18n.translate(this.isActive ?
                customerCareKeys.SUSPEND_SERVICES_SUCCESS : customerCareKeys.RESUME_SERVICES_SUCCESS));
        }).catch(() => {
            this.uiNotificationService.transientError(i18n.translate(this.isActive ?
                customerCareKeys.SUSPEND_SERVICES_FAILURE : customerCareKeys.RESUME_SERVICES_FAILURE));
        }).finally(() => {
            this.closeSuspendResumeServiceModal();
        });
    }

    getServiceWidgetTitle(serviceIdentifier) {
        return serviceIdentifier ?
            `${serviceIdentifier.FormattedValue} ${serviceIdentifier.FriendlyName || ''}`
            : i18n.translate(customerCareKeys.HOUSEHOLD.SHARED_ENTITLEMENTS);
    }

    updateEntitlementShareStatus(entitlement) {
        const newStatus = entitlement.SharingStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
            ? ENTITLEMENT_SHARE_STATUS.DISABLED
            : ENTITLEMENT_SHARE_STATUS.ENABLED;
        this.actions.updateDashboardEntitlementShareStatus(this.customerId, entitlement.EntitlementIdentifier, newStatus).then(() => {
            return this.actions.retrieveConvergentBillerSubscriberSummary(this.customerId);
        }).then(() => {
            this.uiNotificationService.transientSuccess(i18n.translate(
                newStatus === ENTITLEMENT_SHARE_STATUS.ENABLED
                    ? customerCareKeys.SHARING_ACTIONS.ENABLE_SUCCESS
                    : customerCareKeys.SHARING_ACTIONS.DISABLE_SUCCESS));
        }).catch(this.onApiError);
    }

    onApiError(error) {
        this.uiNotificationService.transientError(error.translatedMessage);
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    get suspensionDate() {
        return getFilterService()('localShortDate')(this.selectedService.SuspendedDate);
    }
}

export default {
    template: require('./services.contents.deprecated.html'),
    bindings: {
        availableProducts: '<',
        currencyCode: '<',
        customerId: '<',
        hasAdminAccess: '<',
        hasSuspendAccess: '<',
        refreshServices: '&?',
        resumeServiceReasonCodes: '<?',
        services: '<',
        stateGo: '&',
        suspendServiceReasonCodes: '<?'
    },
    controller: ServicesContentsController,
    controllerAs: 'ServicesContents'
};
