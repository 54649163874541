import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    clearSearchResults,
    setSearchType
} from '../../../reducers/actions/search.actions';
import {SEARCH_DROPDOWN_CONSTANTS} from '../search.constants';
import {
    CoreLocaleKeys,
    i18n,
    SessionSelectors
} from 'invision-core';
import {
    getGiftCardSearch,
    resetGiftCardSearchRecoverable,
    routeToGiftCard
} from '../../../reducers/actions/search.giftcard.actions';
import {
    GiftCardSearchCurrentPageNumberSelector,
    GiftCardSearchMapSelector,
    GiftCardSearchRecordCountSelector,
    GiftCardSearchTotalPageCountSelector
} from '../../../reducers/selectors/search.giftcard.selectors';

export function SearchGiftCardResultsController($ngRedux) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            giftCardSearchResults: GiftCardSearchMapSelector(store),
            giftCardSearchResultCount: GiftCardSearchRecordCountSelector(store),
            currentPageNumber: GiftCardSearchCurrentPageNumberSelector(store),
            totalPageCount: GiftCardSearchTotalPageCountSelector(store),
            pageSize: SessionSelectors.PageSizePreferenceSelector(store)
        };
    };

    this.$onInit = () => {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;

        const controllerActions = {
            clearSearchResults,
            getGiftCardSearch,
            resetGiftCardSearchRecoverable,
            routeToGiftCard,
            setSearchType
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.getRecordCount = () => {
            const firstRecordNumberOnPage = (this.state.currentPageNumber * this.state.pageSize + 1) - this.state.pageSize;
            const lastRecordNumberOnPage = Math.min((this.state.currentPageNumber * this.state.pageSize), this.state.giftCardSearchResultCount);
            return i18n.translate(CoreLocaleKeys.PAGING_FOOTER, {
                pageRecordFirst: firstRecordNumberOnPage,
                pageRecordLast: lastRecordNumberOnPage,
                totalRecordCount: this.state.giftCardSearchResultCount
            });
        };

        this.clearResults = () => {
            this.actions.clearSearchResults();
            this.actions.setSearchType(SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD);
            this.actions.resetGiftCardSearchRecoverable();
        };

        this.giftCardClicked = (giftCardId) => {
            const giftCard = this.state.giftCardSearchResults[giftCardId];
            this.actions.routeToGiftCard(giftCard);
        };

        this.pageSelected = (pageNum) => {
            this.onPageChange()(pageNum);
            this.actions.getGiftCardSearch(this.giftCardSearchModel, pageNum, this.state.pageSize);
        };
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };
}

export const SearchGiftCardResults = {
    template: require('./search.giftcard.results.html'),
    controller: SearchGiftCardResultsController,
    bindings: {
        giftCardSearchModel: '<',
        pageNumber: '<',
        onPageChange: '&'
    }
};
