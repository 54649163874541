import isEmpty from 'ramda/src/isEmpty';
import CustomerCareKeys from '../../../../../../locales/keys';
import {IsFetchingOrdersSelector} from '../../../../../../reducers/selectors/order.history.selectors';
import {IsFetchingAccountDetailsSelector} from '../../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    IsCalculatingQuoteSelector,
    IsShoppingCartLoadingSelector,
    IsDbssShoppingCart,
    IsServiceFeatureShoppingCart
} from '../../../../../../reducers/selectors/products.order.selectors';
import {IsCreatingModeSelector} from '../create.products.order.wizard.selectors';
import {
    AMOUNT_TYPES,
    getSummaryTotalsLabel
} from '../../../../../../reducers/helpers/summary.tab.helper';
import {getRetrieveOrderQuoteCountdown} from '../../../../../../reducers/helpers/async.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';

class CartItemsController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            AMOUNT_TYPES,
            getSummaryTotalsLabel,
            getRetrieveOrderQuoteCountdown,
            localeKeys: CustomerCareKeys
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                isCalculatingQuote: IsCalculatingQuoteSelector(store),
                isCreatingMode: IsCreatingModeSelector(store),
                isDbss: IsDbss(store),
                isDbssCart: IsDbssShoppingCart(store),
                isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
                isFetchingOrders: IsFetchingOrdersSelector(store),
                isServiceFeatureShoppingCart: IsServiceFeatureShoppingCart(store),
                isShoppingCartLoading: IsShoppingCartLoadingSelector(store),
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, {})((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    hasCartItemsToShow() {
        return this.viewModel.items.length || this.showReplaceCartItem() || this.showModifyCartItem();
    }

    showModifyCartItem() {
        return this.viewModel.modifyItem && !isEmpty(this.viewModel.modifyItem);
    }

    showReplaceCartItem() {
        return this.viewModel.replaceItem && this.isReplacingOrder;
    }

    showCartItems() {
        return (this.isProductsStep && this.hasCartItemsToShow()) ||
            (this.hasCartItemsToShow() && this.isAsyncComplete && this.isCheckoutStep);
    }
}

export default {
    bindings: {
        isAsyncComplete: '<?',
        isCheckoutStep: '<?',
        isProductsStep: '<?',
        isModifyingOrder: '<?',
        isReplacingOrder: '<?',
        onEditCartItem: '&',
        onEditedCartItem: '&',
        onPreviewRenewalStatements: '&',
        onRefreshQuote: '&?',
        onRemoveCartItem: '&',
        retrieveOrderQuoteTimestamp: '<?',
        viewModel: '<' // See CartTab in products.order.selector.helper
    },
    controller: CartItemsController,
    template: require('./create.products.order.cart.html')
};
