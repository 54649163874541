import localeKeys from '../../../../../../locales/keys';

class DiscountTooltipController {
    constructor() {
        this.localeKeys = localeKeys;
        this.tooltipContext = {};
    }

    $onInit() {
        this.tooltipTemplate = require('./basic.list.tooltip.template.html');
    }

    $onChanges(changeObj) {
        if (changeObj.discounts) {
            this.tooltipContext.list = changeObj.discounts.currentValue;
        }
    }
}

export default {
    bindings: {
        discounts: '<'
    },
    controller: DiscountTooltipController,
    template: require('./discounts.tooltip.html')
};
