import partial from 'ramda/src/partial';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import {retrieveProductsMetadata} from 'invision-core/src/components/metadata/products/products.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    IsProductMetadataLoadingSelector,
    ProductMetadataPricingPlansByIdSelector
} from 'invision-core/src/components/metadata/products/products.selectors';
import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {SERVICE_FEATURE_ADD_ON_DESCRIPTION_OPTIMAL_CHAR_COUNT} from '../../../shared/constants/service.constants';
import {
    AttributeGroupsSelector,
    CurrentAttributeFormName,
    CurrentAttributesValidationStatuses,
    PhysicalAttributeGroupsSelector,
    PagesIsFetchingDataSelector,
    ServiceTaxCustomizationHashSelector,
    TelephoneNumberProvinceRegionSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import FaultCodeKeys from '../../../../api/fault.code.keys';
import {
    DbssShippingWarehouseIdSelector,
    IsQuotingOfferSelector,
    IsUpdatingMultiOfferShoppingCart,
    SelectedInventoryStoresSelector,
    ServiceFeaturesViewModelSelector
} from '../../../../reducers/selectors/offering.order.selectors';
import {
    clearProductContextForServiceFeatures,
    getServiceFeatureProduct,
    retrieveProductContextForServiceFeatures,
    searchServiceFeatures,
    searchTelephoneNumberInventory,
    setActiveAttributeFormName,
    setActiveFormValidationStatus,
    setDeliveryDecision,
    setSelectedInventoryStores,
    updateDeliveryDecision
} from '../../../../reducers/actions/offering.order.actions';
import {duplicatedServiceIdentifierAttributes} from '../../../../reducers/helpers/offer.ordering.wizard.helper';
import CareLocaleKeys from '../../../../locales/keys';
import {
    formValidated as setCurrentAddressAsInitialAddress,
    restoreMobileNumberPortabilityAttribute,
    saveMobileNumberPortabilityAttribute,
    setShowPortability,
    updateMobileNumberPortabilityAddressModel,
    validatePortInData,
} from '../../../../reducers/actions/mobile.number.portability.actions';
import {
    IsResumingCartWithMnpData,
    MobileNumberPortabilityAddressModelSelector,
    MobileNumberPortabilityAttributesSelector,
    MobileNumberPortabilityModelSelector,
    MobileNumberPortabilityShowPortabilitySelector
} from '../../../../reducers/selectors/mobile.number.portability.selectors';
import {INVENTORY_LOOKUP_TYPES} from '../../../shared/constants/offering.option.status.constants';
import metadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import {
    DELIVERY_OPTIONS,
    SEARCH_INVENTORY_NO_RESULTS,
    SERVICE_ATTRIBUTE_DISPLAY_TYPES,
    SERVICE_ATTRIBUTE_TYPE
} from '../../../../customercare.constants';
import {
    CreateEditCustomerSelector,
    CurrentCustomerSelector,
} from '../../../../reducers/selectors/customer.selectors';
import {
    CurrentAddressesSelector,
    IsFetchingAddressesSelector
} from '../../../../reducers/selectors/customer.addresses.selectors';
import {retrieveCustomerAddresses} from '../../../../reducers/actions/customer.addresses.actions';
import {SelectedProducIdsOfServiceFeaturesSelectors} from '../../newConnectWizard/new.connect.wizard.selectors';
import {getFormattedServiceAttributeValue} from '../../../../reducers/selectors/services.list.selectors.helper';
import {PRICE_OVERRIDE_ACCESS} from '../../../../security.attributes';

class AttributesController {
    constructor($anchorScroll, $location, $ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $anchorScroll,
            $location,
            $ngRedux,
            $state,
            $timeout,
            AddressHelper,
            activeNavItem: {},
            attributesForm: null, // populated by ng-form
            CareLocaleKeys,
            billerRuleInstanceDetails: [],
            changeServiceAttributeList: this.changeServiceAttributeList.bind(this),
            closeServiceFeaturesPopup: this.closeServiceFeaturesPopup.bind(this),
            DELIVERY_OPTIONS,
            deliveryOptionsByPricingPlanViewModel: {},
            deliveryOptionsFieldName: i18n.translate(CareLocaleKeys.ATTRIBUTES.DELIVERY_OPTIONS),
            deliveryOptionsTooltip: require('../../../../components/shared/tooltipTemplates/deliveryOptions.tooltip.html'),
            goToNextNavItem: this.goToNextNavItem.bind(this),
            goToPreviousNavItem: this.goToPreviousNavItem.bind(this),
            INVENTORY_LOOKUP_TYPES,
            mnpVerificationCallBack: this.mnpVerificationCallBack.bind(this),
            onMobileNumberPortabilityEdit: this.onMobileNumberPortabilityEdit.bind(this),
            onPhoneNumberSelection: this.onPhoneNumberSelection.bind(this),
            onPortabilityNumberChange: this.onPortabilityNumberChange.bind(this),
            onProductPricingPlansRequested: this.onProductPricingPlansRequested.bind(this),
            onDeliveryOptionsChange: this.onDeliveryOptionsChange.bind(this),
            orderScenario: undefined,
            onServiceFeaturePricingPlanSelection: this.onServiceFeaturePricingPlanSelection.bind(this),
            onTelephoneSelection: this.onTelephoneSelection.bind(this),
            billToLocationToolTipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.BILL_TO_LOCATION_TOOLTIP_CONTENT),
            originatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.ORIGINATING_LOCATION_TOOLTIP_CONTENT),
            terminatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.TERMINATING_LOCATION_TOOLTIP_CONTENT),
            productIds: [],
            searchPhones: this.searchPhones.bind(this),
            SERVICE_ATTRIBUTE_TYPE,
            SERVICE_FEATURE_ADD_ON_DESCRIPTION_OPTIMAL_CHAR_COUNT,
            serviceTaxCustom: false,
            setValidationStatus: this.setValidationStatus.bind(this),
            uiNotificationService: uiNotificationService,
            updateMake: this.updateMake.bind(this),
            updateModel: this.updateModel.bind(this),
            updatePhysicalAttribute: this.updatePhysicalAttribute.bind(this),
            updateCustomizedServiceTaxValue: this.updateCustomizedServiceTaxValue.bind(this),
            validateNumbers,
            serviceAttributeDisplayTypeValues: SERVICE_ATTRIBUTE_DISPLAY_TYPES
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                addressStateCodesLoaded: metadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                attributeGroups: AttributeGroupsSelector(store),
                currentAddresses: CurrentAddressesSelector(store),
                currentAttributeFormName: CurrentAttributeFormName(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentValidationStatuses: CurrentAttributesValidationStatuses(store),
                dbssShippingWarehouseId: DbssShippingWarehouseIdSelector(store),
                eligibilityModel: CreateEditCustomerSelector(store),
                isFetchingAddresses: IsFetchingAddressesSelector(store),
                isFetchingData: PagesIsFetchingDataSelector(store),
                isProductMetadataLoading: IsProductMetadataLoadingSelector(store),
                isQuotingOffer: IsQuotingOfferSelector(store),
                isRegularExpressionsLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                isResumingCartWithMnpData: IsResumingCartWithMnpData(store),
                isServiceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                isUpdatingMultiOfferShoppingCart: IsUpdatingMultiOfferShoppingCart(store),
                physicalAttributeGroups: PhysicalAttributeGroupsSelector(store),
                portabilityModel: MobileNumberPortabilityModelSelector(store),
                portabilityModelAddress: MobileNumberPortabilityAddressModelSelector(store),
                productMetadataPricingPlansBy: ProductMetadataPricingPlansByIdSelector(store),
                regularExpressions: MetadataCodeTypeDictionarySelector(CODES.RegularExpression, store),
                savedPortabilityAttributes: MobileNumberPortabilityAttributesSelector(store),
                selectedInventoryStores: SelectedInventoryStoresSelector(store),
                selectedProducIdsOfServiceFeatures: SelectedProducIdsOfServiceFeaturesSelectors(store),
                serviceAttributes: MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute, store),
                serviceFeaturesViewModel: ServiceFeaturesViewModelSelector(store),
                serviceTaxCustomizationHash: ServiceTaxCustomizationHashSelector(store),
                showPortability: !!MobileNumberPortabilityShowPortabilitySelector(store),
                telephoneCodes: TelephoneNumberProvinceRegionSelector(store),
                telephoneCodesLoaded: metadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TelephoneCodes, store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            clearProductContextForServiceFeatures,
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypesThunk,
            fetchTelephoneInventory: searchTelephoneNumberInventory,
            getServiceFeatureProduct,
            restoreMobileNumberPortabilityAttribute,
            retrieveCustomerAddresses,
            retrieveProductContextForServiceFeatures,
            retrieveProductsMetadata,
            saveMobileNumberPortabilityAttribute,
            searchServiceFeatures,
            setActiveAttributeFormName,
            setActiveFormValidationStatus,
            setCurrentAddressAsInitialAddress,
            setDeliveryDecision,
            setSelectedInventoryStores,
            setShowPortability,
            updateDeliveryDecision,
            updateMobileNumberPortabilityAddressModel,
            validatePortInData
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.mobileNumberPortabilityConfig = {
            onRegisterApi: ({api}) => {
                this.mobileNumberPortabilityApi = api;
            }
        };

        this.telephoneSelectionConfig = {
            onRegisterApi: ({api}) => {
                this.telephoneSelectionApi = api;
            }
        };

        this.selectServiceFeaturesPopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectServiceFeaturesPopupApi = api;
            }
        };
        if (this.filteredFormAttributeGroups && this.filteredFormAttributeGroups.length === 0) {
            this.setEditAttributeGroups()(this.state.attributeGroups);
        }
        this.setEditPhysicalAttributeGroups()({
            physicalAttributeGroups: this.state.physicalAttributeGroups,
            formPhysicalAttributeGroups: this.formPhysicalAttributeGroups
        });

        if (this.taxLocationOptionsByPricingPlanViewModel && Object.keys(this.taxLocationOptionsByPricingPlanViewModel).length) {
            this.updateServiceTaxLocationDecision()(this.taxLocationOptionsByPricingPlanViewModel);
        }
        Object.keys(this.state.physicalAttributeGroups).forEach((pricingPlanId) => {
            const pricingPlan = this.state.physicalAttributeGroups[pricingPlanId];
            pricingPlan.forEach((item) => {
                let make, model;
                if (item.makeInformation.length === 1) {
                    make = item.makeInformation[0];
                } else {
                    make = item.makeInformation.length ? item.makeInformation.find((make) => {
                        return make.id === item.selectedMakeFromSavedCart;
                    }) : undefined;
                }

                if (make.possibleValues.length === 1) {
                    model = make.possibleValues[0];
                } else {
                    model = make.possibleValues.length > 1 ? make.possibleValues.find((model) => {
                        return model.id === item.selectedModelFromSavedCart;
                    }) : undefined;
                }

                if ((make.possibleValues.length === 1) ||
                    (make.possibleValues.length > 1 && (make || model))
                ) {
                    const selectedMakeId = make ? make.id : undefined,
                        selectedModelId = model ? model.id : undefined;

                    this.updateMakeAndModel()(pricingPlanId, item.id, selectedMakeId, selectedModelId);
                }

                item.typeAttributes.forEach((typeAttribute) => {
                    if (typeAttribute.possibleValues.length === 1) {
                        this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, item.id, typeAttribute.id, typeAttribute.possibleValues[0]);
                    } else if (typeAttribute.selectedValue) {
                        this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, item.id, typeAttribute.id, typeAttribute.selectedValue);
                    }
                });
            });
        });

        this.careLocaleKeys = CareLocaleKeys;
        this.duplicatedServiceIdentifiers = [];

        this.updateMakeCallback = partial(this.updateMake).bind(this);
        this.updateModelCallback = partial(this.updateModel).bind(this);
        this.updatePhysicalAttributeCallback = partial(this.updatePhysicalAttribute).bind(this);

        this.filteredNavigationItems = this.filterNavigationItems(this.navigationItems);
        this.mnpToken = '';
        this.sendPortInData = false;

        if (!this.state.telephoneCodesLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.TelephoneCodes);
        }
        if (!this.state.addressStateCodesLoaded) {
            this.actions.fetchCodeTypes(MetadataConstants.codes.AddressStateProvinceRegion);
        }
        if (!this.state.isServiceAttributesLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceAttribute);
        }
        if (!this.state.isRegularExpressionsLoaded) {
            this.actions.fetchCodeTypes(CODES.RegularExpression);
        }

        this.getInitialAddressIfAvailable();
        this.activeNavItem = this.getActiveNavItem();
        if (this.state.isResumingCartWithMnpData) {
            this.sendPortInOrItemReservation()(this.findPortableAttributeId(this.activeNavItem), true);
            this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
            this.updatePortInRequest()(this.findPortableAttributeId(this.activeNavItem), this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
        }
        this.$timeout(this.setValidationStatus);
        this.actions.clearProductContextForServiceFeatures();
        this.fetchServiceAddonPricingPlanDetails();
        if (this.deliveryOptionsByPricingPlanViewModel && Object.values(this.deliveryOptionsByPricingPlanViewModel).length) {
            const firstShippablePricingPlanDeliveryDecision = Object.values(this.deliveryOptionsByPricingPlanViewModel)[0];
            const firstInstanceOfFirstShippablePricingPlanDeliveryDecision = firstShippablePricingPlanDeliveryDecision['1'];
            this.onDeliveryOptionsChange(this.selectedDeliveryDecision ? {
                selectedValue: this.selectedDeliveryDecision
            } : firstInstanceOfFirstShippablePricingPlanDeliveryDecision);
        }
        this.hasPriceOverrideAccess = hasAccess(this.state.userSecurityAttributes, PRICE_OVERRIDE_ACCESS);
    }

    updateMake(pricingPlanId, inventoryToUpdate, selectedOption) {
        const selectedMakeId = null !== selectedOption ? selectedOption.value : undefined;
        const selectedMakeModels = pathOr([], ['modelsByMakeId', selectedMakeId], inventoryToUpdate);
        let selectedModelId = undefined;

        if (undefined !== selectedMakeId && 1 === selectedMakeModels.length) {
            selectedModelId = selectedMakeModels[0].value;
        }

        this.updateMakeAndModel()(pricingPlanId, inventoryToUpdate.id, selectedMakeId, selectedModelId);
        this.$timeout(this.setValidationStatus);
    }
    updateModel(pricingPlanId, inventoryToUpdate, makeId, selectedOption) {
        this.updateMakeAndModel()(pricingPlanId, inventoryToUpdate.id, makeId, selectedOption === null ? undefined : selectedOption.value);
        this.$timeout(this.setValidationStatus);
    }
    updatePhysicalAttribute(pricingPlanId, inventoryToUpdate, typeAttributeId, selectedOption) {
        this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, inventoryToUpdate.id, typeAttributeId, selectedOption === null ? undefined : selectedOption.value);
        this.$timeout(this.setValidationStatus);
    }
    filterHidden(attribute) {
        return !attribute.isHidden;
    }
    isSelectAttribute(attribute) {
        return !attribute.selectedValue && attribute.possibleValues && attribute.possibleValues.length;
    }
    setFormValidity(attribute) {
        this.updateAttribute()(attribute.id, attribute.formValue);
        this.$timeout(this.setValidationStatus);
    }
    setValidationStatus() {
        const formErrors = this.getFormErrors();
        const hasErrors = this.hasErrors() || (formErrors && (formErrors.patternErrors.length || formErrors.requiredErrors.length));
        this.activeNavItem = this.getActiveNavItem();
        this.actions.setActiveFormValidationStatus({
            formName: this.activeNavItem.formName,
            isValid: !hasErrors
        });
    }

    onDeliveryOptionsChange(deliveryDecision) {
        this.$timeout(this.setValidationStatus);
        if (deliveryDecision.selectedValue === DELIVERY_OPTIONS.SHIPPED) {
            this.actions.setSelectedInventoryStores([this.state.dbssShippingWarehouseId]);
        } else if ((deliveryDecision.selectedValue === DELIVERY_OPTIONS.STORE_PICKUP) &&
            this.state.selectedInventoryStores.length &&
            this.state.selectedInventoryStores[0] === this.state.dbssShippingWarehouseId) {
            this.actions.setSelectedInventoryStores([]);
        }
        this.actions.setDeliveryDecision(deliveryDecision.selectedValue);
        this.actions.updateDeliveryDecision(deliveryDecision.selectedValue);
    }

    selectChange(attribute) {
        this.setFormValidity(attribute);
    }
    radioChange(attribute, option) {
        this.setFormValidity(attribute);
        this.sendPortInOrItemReservation()(attribute.id, false);
        this.updateInventorySelection()(attribute.id, option, attribute.formValue);
    }
    getFormErrors() {
        this.hasPhysicalAttributeErrors = false;
        const errors = this.formAttributeGroups.map((attributeGroup) => {
            return attributeGroup.formName;
        }).filter((formName) => {
            // On initialization there are no nested forms and the remaining
            // logic is effectively short circuited with this filter
            return !!this.attributesForm[formName];
        }).reduce((errorObj, formName) => {
            const formCtrl = this.attributesForm[formName];
            let badPatternFields = [];
            let requiredFields = [];

            if (formCtrl.$error.pattern) {
                badPatternFields = formCtrl.$error.pattern.map((badPatternField) => {
                    return badPatternField.$name;
                }).filter((badPatternField) => {
                    return formCtrl[badPatternField].$modelValue !== '' || formCtrl[badPatternField].$touched;
                });
            }
            if (formCtrl.$error.required) {
                requiredFields = formCtrl.$error.required.map((requiredField) => {
                    return requiredField.$name;
                });
            }

            return Object.assign({}, errorObj, {
                requiredErrors: errorObj.requiredErrors.concat(requiredFields),
                patternErrors: errorObj.patternErrors.concat(badPatternFields)
            });
        }, {
            requiredErrors: [],
            patternErrors: []
        });
        Object.values(this.detachedPhysicalAttributeForm || {}).forEach((pricingPlanValues) => {
            pricingPlanValues.forEach((value) => {
                if (value.typeAttributes && value.typeAttributes.length) {
                    const requiredPhysicalAttributes = value.typeAttributes.filter((attribute) => {
                        return attribute.required;
                    });
                    requiredPhysicalAttributes.forEach((attribute) => {
                        if (!value.selectedTypeAttributes || !value.selectedTypeAttributes[attribute.id]) {
                            this.hasPhysicalAttributeErrors = true;
                            errors.requiredErrors.push(`${value.displayName}: ${attribute.name}`);
                        }
                    });
                }

                if (!value.selectedMake && value.IsRequired) {
                    errors.requiredErrors.push(i18n.translate(CareLocaleKeys.ATTRIBUTES.MAKE_AND_MODEL_REQUIRED, {
                        item: value.displayName
                    }));
                } else if (!value.selectedModel && value.IsRequired) {
                    errors.requiredErrors.push(i18n.translate(CareLocaleKeys.ATTRIBUTES.MODEL_REQUIRED, {
                        item: value.displayName
                    }));
                }
            });
        });
        if (this.attributesForm.serviceTaxCustomizationForm) {
            if (Object.values(this.attributesForm.serviceTaxCustomizationForm.$error).length > 0) {
                if ((this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'].$valid) ||
                    (this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'].$valid) ||
                    (this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--billToTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--billToTaxLocation'].$valid)) {
                    errors.requiredErrors.push(i18n.translate(this.careLocaleKeys.TAX_LOCATION_RULES.NPA_NXX_ENTRY));
                }
                this.attributesForm.serviceTaxCustomizationForm.$setSubmitted(true);
            }
        }
        return errors;
    }
    changeServiceAttributeList(navItem) {
        this.$timeout(this.setValidationStatus);
        this.activeNavItem = this.getActiveNavItem();
        this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
        this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(navItem));
        this.actions.setActiveAttributeFormName(navItem.formName);
    }
    getInitialAddressIfAvailable() {
        if (this.state.currentCustomer.Id) {
            this.actions.retrieveCustomerAddresses(this.state.currentCustomer.Id, false)
                .then(() => {
                    this.currentAddresses = clone(this.state.currentAddresses);
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        } else {
            this.actions.updateMobileNumberPortabilityAddressModel({
                LineOne: this.state.eligibilityModel.addressLine1,
                LineTwo: this.state.eligibilityModel.addressLine2,
                Country: this.state.eligibilityModel.country,
                City: this.state.eligibilityModel.city,
                State: this.state.eligibilityModel.stateRegionProvince,
                PostalCode: this.state.eligibilityModel.postalCode,
            });
        }
    }
    isAttributeFieldDuplicated(id) {
        return this.attributesDuplicated().includes(id);
    }
    attributesDuplicated() {
        return this.duplicatedServiceIdentifiers.length ?
            this.duplicatedServiceIdentifiers :
            this.attributesDuplicatedFromServer();
    }
    attributesDuplicatedFromServer() {
        return pathOr([], ['faultData'], this.lastAttemptError);
    }
    filterNavigationItems(navigationItems) {
        return navigationItems.length ? navigationItems.filter(({isHidden}) => {
            return !isHidden;
        }) : [];
    }
    getActiveNavItem() {
        return this.filteredNavigationItems.find((navItem) => {
            return navItem.isActive;
        });
    }
    goToPreviousNavItem() {
        this.activeNavItem = this.getActiveNavItem();
        const currentIndex = this.filteredNavigationItems.indexOf(this.activeNavItem);
        const previousNavItem = this.filteredNavigationItems[currentIndex - 1];
        this.$timeout(this.setValidationStatus);
        this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
        this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(previousNavItem));
        this.actions.setActiveAttributeFormName(previousNavItem.formName);
    }
    goToNextNavItem() {
        this.activeNavItem = this.getActiveNavItem();
        const currentIndex = this.filteredNavigationItems.indexOf(this.activeNavItem);
        const nextNavItem = this.filteredNavigationItems[currentIndex + 1];
        this.$timeout(this.setValidationStatus);
        this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
        this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(nextNavItem));
        this.actions.setActiveAttributeFormName(nextNavItem.formName);
    }
    getErrorForField(formName, fieldName) {
        const fieldController = this.attributesForm[formName][fieldName];

        if (fieldController.$error) {
            if (fieldController.$error.required) {
                return this.getRequiredFieldErrorMessage(fieldName);
            } else if (fieldController.$error.pattern) {
                return this.getPatternErrorMessage(fieldName);
            } else if (this.attributesDuplicatedFromServer().length && !this.duplicatedServiceIdentifiers.length) {
                // Server Validation duplicates display a specific message
                return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_ALREADY_EXISTS);
            } else if (this.duplicatedServiceIdentifiers.length) {
                // Local Validation duplicates display a specific message
                return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_UNIQUE_ERROR);
            }
        }
    }
    getRequiredFieldErrorMessage(fieldName) {
        return i18n.translate(CoreLocaleKeys.FIELD_IS_A_REQUIRED_FIELD, {
            field_name: fieldName
        });
    }
    getPatternErrorMessage(fieldName) {
        return i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
            field_name: fieldName
        });
    }
    getServiceIdentifierDuplicatedErrorMessage() {
        return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_DUPLICATES_FOUND, {
            duplicates: this.attributesDuplicated().length
        });
    }
    shouldShowErrors() {
        return !!((this.wizardForm.$submitted &&
            (this.attributesForm.$invalid || this.attributesDuplicated().length || this.hasPhysicalAttributeErrors)
        ) || this.attributesDuplicatedFromServer().length);
    }
    hasErrors() {
        return this.attributesForm.$invalid;
    }
    shouldShowFieldError(formName, fieldName, id) {
        const fieldController = this.attributesForm[formName][fieldName];

        if (this.attributesForm[formName][fieldName]) {
            return (fieldController.$error.required && (fieldController.$touched || this.wizardForm.$submitted)) ||
                (fieldController.$modelValue && fieldController.$error.pattern) ||
                this.isAttributeFieldDuplicated(id);
        }
        return false;
    }
    getMoreOptions(attribute) {
        this.searchAttributeInventory()(attribute);
    }

    onPortabilityNumberChange(attribute) {
        this.activeNavItem = this.getActiveNavItem();
        this.actions.setShowPortability(this.state.showPortability);
        this.updateAttribute()(attribute.id, this.showPortInOrItemRsvp(attribute));
        this.sendPortInOrItemReservation()(attribute.id, this.sendPortInData);
        if (this.sendPortInData && this.state.isResumingCartWithMnpData) {
            this.sendPortInOrItemReservation()(attribute.id, true);
            this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
            this.updatePortInRequest()(attribute.id, this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
        }
    }

    // Please note - because this is an exceptional situation with nested
    // dynamically generated forms its less error prone to keep this form
    // in sync the Angular way than the Redux way.
    // See https://confluence.csgicorp.com/display/Ascendon/Redux#Redux-DynamicAngularforms
    // for more information
    $onChanges(changesObj) {
        const formAttributeGroups = pathOr(null, ['formAttributeGroups', 'currentValue'], changesObj) || this.formAttributeGroups;
        const filteredFormAttributeGroups = pathOr(null, ['filteredFormAttributeGroups', 'currentValue'], changesObj);

        if (filteredFormAttributeGroups && filteredFormAttributeGroups.length) {
            this.duplicatedServiceIdentifiers = duplicatedServiceIdentifierAttributes(formAttributeGroups);
            // Performs a simple/shallow clone
            this.detachedFormObj = this.filteredFormAttributeGroups.map((attributeGroupVM) => {
                return Object.assign({}, attributeGroupVM);
            });
            // There's an assumption that form groups and attributes in those groups
            // will never change order at runtime
            this.detachedFormObj.forEach((attributeGroup, index) => {
                const sourceGroup = this.filteredFormAttributeGroups[index];
                // Rather than blowing away the reference, use the original attribute
                // reference and replace the three values we care about
                attributeGroup.attributes.forEach((attribute, attrIndex) => {
                    const sourceAttribute = sourceGroup.attributes[attrIndex];
                    attribute.formValue = sourceAttribute.formValue;
                    attribute.isHidden = sourceAttribute.isHidden;
                    attribute.inventoryItemReservation = sourceAttribute.inventoryItemReservation;
                });
            });

            if (changesObj.filteredFormAttributeGroups.previousValue.length) {
                this.handleServiceFeaturesIfServiceIdentifierChanged(changesObj.filteredFormAttributeGroups);
            }
        }
        if (changesObj.filteredPhysicalAttributeGroups) {
            this.detachedPhysicalAttributeForm = Object.assign({}, this.filteredPhysicalAttributeGroups);
        }
        if (changesObj.navigationItems) {
            this.filteredNavigationItems = this.filterNavigationItems(this.navigationItems);
        }
        if (changesObj.deliveryOptionsByPricingPlan) {
            this.deliveryOptionsByPricingPlanViewModel = clone(changesObj.deliveryOptionsByPricingPlan.currentValue);
        }
        if (changesObj.taxLocationOptionsByPricingPlan) {
            this.taxLocationOptionsByPricingPlanViewModel = clone(changesObj.taxLocationOptionsByPricingPlan.currentValue);
        }
    }
    $onDestroy() {
        this.actions.setActiveAttributeFormName(this.state.currentAttributeFormName);
        this.disconnectRedux();
    }

    onMobileNumberPortabilityEdit(attribute) {
        this.activeNavItem = this.getActiveNavItem();
        if (this.sendPortInData && this.state.isResumingCartWithMnpData) {
            this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
            this.sendPortInOrItemReservation()(attribute.id, true);
            this.updatePortInRequest()(attribute.id, this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
        }
        this.mobileNumberPortabilityApi.open(attribute);
        this.onMobileNumberPortabilityVerificationComplete = (addressData, portabilityData) => {
            this.sendPortInOrItemReservation()(attribute.id, true);
            this.actions.saveMobileNumberPortabilityAttribute(attribute.id, this.state.showPortability);
            this.updateAttribute()(attribute.id, this.state.portabilityModel.existingNumber);
            this.updatePortInRequest()(attribute.id, portabilityData, this.mnpToken, addressData);
        };
    }

    mnpVerificationCallBack(addressData, portabilityData, inventoryTypeId) {
        this.showMnpLoadingIndicator = true;
        this.actions.validatePortInData(addressData, portabilityData, inventoryTypeId, this.state.currentCustomer.Id).then((response) => {
            if (response.Token) {
                this.mnpToken = response.Token;
                this.showMnpLoadingIndicator = false;
                this.mobileNumberPortabilityApi.showConfirmation(response.Token, null);
            }
        }).catch((error) => {
            this.sendPortInData = false;
            this.showMnpLoadingIndicator = false;
            if (error.faultCode !== FaultCodeKeys.INVALID_PORT_IN_REQUEST_LAMBDA_ERROR) {
                this.mobileNumberPortabilityApi.showConfirmation(false, error.translatedMessage);
            }
        });
    }

    showPortInOrItemRsvp(attribute) {
        const currentForm = this.state.savedPortabilityAttributes[attribute.id];
        if (currentForm && this.state.showPortability) {
            this.sendPortInData = true;
            return currentForm.portabilityModel.existingNumber;
        } else if (!currentForm && this.state.showPortability) {
            this.sendPortInData = true;
            return null;
        } else {
            this.sendPortInData = false;
            return attribute.selectedValue;
        }
    }

    findPortableAttributeId(formName) {
        const portableAttribute = this.formAttributeGroups.filter((item) => {
            return item.formName === formName.id;
        });
        const mnpAttribute = portableAttribute[0].attributes.find((attribute) => {
            return attribute.isPortable;
        });
        if (mnpAttribute) {
            return mnpAttribute.id || null;
        }
    }

    onTelephoneSelectionPopup(attribute) {
        // phoneSelectionAttribute used to reference attribute when opening the modal since .open() does accept parameters
        this.phoneSelectionAttribute = attribute;
        this.isTelephoneSelectionOpen = true;
        this.telephoneNumberSelected = null;
        this.telephoneList = null;
        this.$timeout(() => {
            this.telephoneSelectionApi.open();
        });
    }

    onTelephoneSelection(attribute, phoneNumber) {
        this.isTelephoneSelectionOpen = false;
        this.telephoneSelectionApi.close();
        if (attribute && phoneNumber) {
            attribute.formValue = phoneNumber.SerialNumber;
            this.radioChange(attribute, phoneNumber);
        }
        this.telephoneList = null;
    }

    formatPhoneNumber(attribute) {
        return getFormattedServiceAttributeValue(
            attribute.serviceAttributeId,
            attribute.formValue,
            this.state.serviceAttributes,
            this.state.regularExpressions
        );
    }

    onPhoneNumberSelection(phoneNumber) {
        this.telephoneNumberSelected = phoneNumber;
    }

    searchPhones(searchPatterns) {
        this.actions.fetchTelephoneInventory(this.phoneSelectionAttribute.inventoryTypeId, searchPatterns).then((response) => {
            this.telephoneList = response.InventoryItems;
        }).catch((error) => {
            this.telephoneList = [];
            if (error.faultCode !== SEARCH_INVENTORY_NO_RESULTS) {
                this.uiNotificationService.transientError(error.translatedMessage);
            }
        });
    }

    //Service Features
    closeServiceFeaturesPopup() {
        this.selectServiceFeaturesPopupApi.close();
        this.serviceFeaturesOpen = false;
    }

    getServiceFeatureAttribute(data) {
        return data[0].attributes.find((attribute) => {
            return attribute.isServiceFeatureAvailable;
        }) || {};
    }

    handleServiceFeaturesIfServiceIdentifierChanged({currentValue, previousValue}) {
        const currentService = this.getServiceFeatureAttribute(currentValue);
        const previousService = this.getServiceFeatureAttribute(previousValue);

        if (previousService.id &&
            previousService.serviceFeatures && previousService.serviceFeatures.length &&
            currentService.instanceNumber === previousService.instanceNumber &&
            currentService.pricingPlanId === previousService.pricingPlanId &&
            (currentService.id !== previousService.id)) {

            previousService.serviceFeatures.forEach((serviceFeature) => {
                serviceFeature.ServiceAttributeId = currentService.serviceAttributeId;
            });

            this.updateSelectedServiceFeatures()(currentService.id, previousService.serviceFeatures);
            this.updateSelectedServiceFeatures()(previousService.id, []);
        }
    }

    getMappedServiceFeatures(serviceFeatures, serviceAttribute = {}, skipCheck = false) {
        const modifiedServiceFeature = [];
        let currentServiceFeatures = [];
        this.billerRuleInstanceDetails = [];
        serviceFeatures.forEach((serviceFeature) => {
            if (!skipCheck) {
                currentServiceFeatures = this.detachedFormObj[0].serviceFeatures.filter((existingServiceFeature) => {
                    return serviceFeature.pricingPlanId === existingServiceFeature.pricingPlanId && serviceFeature.productId === existingServiceFeature.productId;
                });
            }
            if (serviceFeature.quantity && serviceFeature.quantity > 1 && !skipCheck) {
                for (let i = 0; i < serviceFeature.quantity; i++) {
                    if (serviceFeature.billerRuleDetails && serviceFeature.billerRuleDetails.length) {
                        serviceFeature.billerRuleDetails.forEach((billerRuleDetail) => {
                            this.billerRuleInstanceDetails.push({
                                BillerRuleConfigurationId: billerRuleDetail.BillerRuleConfigurationId,
                                ServiceFeatureProductId: serviceFeature.productId,
                                ServiceFeaturePricingPlanId: serviceFeature.pricingPlanId,
                                BillerRuleDecisionDetails: [billerRuleDetail]
                            });
                        });
                    }
                    modifiedServiceFeature.push({
                        PricingPlanId: serviceFeature.pricingPlanId,
                        PricingPlanName: serviceFeature.pricingPlanName,
                        ProductId: serviceFeature.productId,
                        ProductName: serviceFeature.productName,
                        ServiceAttributeId: serviceAttribute.serviceAttributeId || serviceFeature.serviceAttributeId,
                        ServiceId: serviceAttribute.serviceId || serviceFeature.serviceId,
                        ServiceInstanceId: serviceAttribute.serviceInstanceId || pathOr(undefined, ['serviceInstanceId'], this.getServiceFeatureAttribute(this.detachedFormObj)), //Service Instance Id  is required to associate the SF to a service if the service exists
                        SubscriberProductId: currentServiceFeatures[i] ?
                            currentServiceFeatures[i].subscriberProductId :
                            undefined,
                        BillerRuleDetails: serviceFeature.billerRuleDetails
                    });
                };
            } else {
                if (serviceFeature.billerRuleDetails && serviceFeature.billerRuleDetails.length) {
                    serviceFeature.billerRuleDetails.forEach((billerRuleDetail) => {
                        this.billerRuleInstanceDetails.push({
                            BillerRuleConfigurationId: billerRuleDetail.BillerRuleConfigurationId,
                            ServiceFeatureProductId: serviceFeature.productId,
                            ServiceFeaturePricingPlanId: serviceFeature.pricingPlanId,
                            BillerRuleDecisionDetails: [billerRuleDetail]
                        });
                    });
                }
                modifiedServiceFeature.push({
                    PricingPlanId: serviceFeature.pricingPlanId,
                    PricingPlanName: serviceFeature.pricingPlanName,
                    ProductId: serviceFeature.productId,
                    ProductName: serviceFeature.productName,
                    ServiceAttributeId: serviceAttribute.serviceAttributeId || serviceFeature.serviceAttributeId,
                    ServiceId: serviceAttribute.serviceId || serviceFeature.serviceId,
                    ServiceInstanceId: serviceAttribute.serviceInstanceId || pathOr(undefined, ['serviceInstanceId'], this.getServiceFeatureAttribute(this.detachedFormObj)), //Service Instance Id  is required to associate the SF to a service if the service exists
                    SubscriberProductId: serviceFeature.subscriberProductId || undefined,
                    BillerRuleDetails: serviceFeature.billerRuleDetails
                });
            }
        });
        return modifiedServiceFeature;
    }

    openServiceFeaturesPopup() {
        this.orderScenario = this.getServiceFeatureAttribute(this.detachedFormObj).parentServicePlanOrderScenario;
        this.serviceFeaturesOpen = true;
        this.actions.searchServiceFeatures(this.detachedFormObj[0].attributes[0].serviceId, this.state.currentCustomer.Id, this.segmentationContext)
            .then(() => {
                this.$timeout(() => {
                    this.selectServiceFeaturesPopupApi.open();
                });
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    onProductPricingPlansRequested(productId) {
        this.actions.retrieveProductContextForServiceFeatures(productId, this.detachedFormObj[0].attributes[0].serviceId, this.state.currentCustomer.Id, this.orderScenario);
        this.actions.getServiceFeatureProduct(productId, this.state.currentCustomer.Language);
    }

    onServiceFeaturePricingPlanSelection(serviceFeatures) {
        this.productIds = [];
        const serviceAttribute = this.getServiceFeatureAttribute(this.detachedFormObj);
        this.updateSelectedServiceFeatures()(
            serviceAttribute.id,
            this.getMappedServiceFeatures(serviceFeatures, serviceAttribute),
            this.billerRuleInstanceDetails
        );
        this.restoreOfferingContext()();
        this.fetchServiceAddonPricingPlanDetails();
        this.closeServiceFeaturesPopup();
    }

    removeServiceFeature(serviceFeature) {
        const selectedServiceFeatures = clone(this.detachedFormObj[0].serviceFeatures);
        const indexOfServiceFeatureToRemove = selectedServiceFeatures.findIndex((selectedServiceFeature) => {
            if (selectedServiceFeature.productId === serviceFeature.productId &&
                selectedServiceFeature.pricingPlanId === serviceFeature.pricingPlanId) {
                return this.addItems && this.addItems.length ? !selectedServiceFeature.subscriberProductId : true;

            };
        });

        selectedServiceFeatures.splice(indexOfServiceFeatureToRemove, 1);
        this.updateSelectedServiceFeatures()(
            serviceFeature.attributeId,
            this.getMappedServiceFeatures(selectedServiceFeatures),
            this.billerRuleInstanceDetails,
            true
        );
        this.restoreOfferingContext()();
    }

    fetchServiceAddonPricingPlanDetails() {
        if (this.state.selectedProducIdsOfServiceFeatures.length) {
            this.actions.retrieveProductsMetadata(this.state.selectedProducIdsOfServiceFeatures)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    updateCustomizedServiceTaxValue(customizedServiceTax) {
        this.updateServiceTaxLocationDecision()(customizedServiceTax);
    }

    setAttributeVisibilityBasedOnServiceAttribute(allowEdit, serviceAttributeDisplayCode) {
        if (allowEdit && !serviceAttributeDisplayCode) {
            return this.serviceAttributeDisplayTypeValues.EDITABLE;
        }
        if (!allowEdit && serviceAttributeDisplayCode && (String(serviceAttributeDisplayCode) !== this.serviceAttributeDisplayTypeValues.HIDDEN)) {
            return this.serviceAttributeDisplayTypeValues.VIEWABLE;
        }
        return serviceAttributeDisplayCode && String(serviceAttributeDisplayCode);
    }
}

export default {
    template: require('./attributes.html'),
    controller: AttributesController,
    controllerAs: 'attributesController',
    bindings: {
        addItems: '<',
        attributeFormSubmitted: '<',
        attributeTitle: '<',
        deliveryOptionsByPricingPlan: '<',
        filteredFormAttributeGroups: '<',
        filteredPhysicalAttributeGroups: '<',
        formAttributeGroups: '<',
        formPhysicalAttributeGroups: '<',
        isFeaturesHidden: '<?',
        showCustomizeTaxOption: '<?',
        lastAttemptError: '<',
        navigationItems: '<',
        restoreOfferingContext: '&?',
        searchAttributeInventory: '&',
        segmentationContext: '<?',
        selectedDeliveryDecision: '<?',
        sendPortInOrItemReservation: '&',
        setEditAttributeGroups: '&',
        setEditPhysicalAttributeGroups: '&',
        showNextButton: '<',
        showPreviousButton: '<',
        taxLocationOptionsByPricingPlan: '<?',
        updateAttribute: '&',
        updateInventorySelection: '&',
        updateMakeAndModel: '&',
        updatePhysicalInventoryTypeAttribute: '&',
        updatePortInRequest: '&',
        updateSelectedServiceFeatures: '&',
        updateServiceTaxLocationDecision: '&?'
    },
    require: {
        wizardForm: '^form'
    }
};
