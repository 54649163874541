import isEmpty from 'ramda/src/isEmpty';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import {
    ADJUSTMENT_CATEGORIES,
    ADJUSTMENT_PAYMENT_TYPES,
    BILL_ADJUSTMENT,
    DEPOSIT_ADJUSTMENT,
    MISC_ADJUSTMENT
} from './adjustment.modal.constants';
import {
    i18n,
    AngularFormUtilities,
    BusinessUnitSelectors,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {
    createConvergentBillerAdjustment,
    retrieveInvoicesForDropdown
} from '../../reducers/actions/customer.billing.actions';
import * as BillingPaymentsConstants from '../../components/billingPayments/billing.payments.constants';
import {
    AdjustmentConfigSelector,
    AdjustmentReasonCodeValueOptions,
    HierarchyInvoiceNodeSubscriberIdSelector,
    InvoiceListForDropDownSelector,
    InvoiceSummariesSelector,
    IsFetchingInvoiceListSelector,
} from './../../reducers/selectors/customer.billing.selectors';
import {IsCreatingConvergentBillerAdjustmentSelector} from '../../reducers/selectors/customer.convergent.biller.selectors';
import {CurrentCustomerSelector} from './../../reducers/selectors/customer.selectors';
import LocaleKeys from '../../locales/keys';
import moment from 'moment';
import {VIEW_INVOICE_DROPDOWN_ACCESS} from './../../security.attributes';
import {REPORTING_LEVELS} from '../../components/customer/accountHierarchy/account.hierarchy.constants';

class AdjustmentModalController {
    constructor($ngRedux, $timeout, $filter, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            $filter,
            ADJUSTMENT_PAYMENT_TYPES,
            BILL_ADJUSTMENT,
            formattedInvoiceList : [],
            formattedInvoiceListforDebitAdjustments: [],
            getTitleByAdjustmentType : this.getTitleByAdjustmentType.bind(this),
            invoicesOptions: [],
            isStatement : this.isStatement.bind(this),
            REPORTING_LEVELS,
            resetFormValues : this.resetFormValues.bind(this),
            MISC_ADJUSTMENT,
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                adjustmentConfig: AdjustmentConfigSelector(store),
                adjustmentReasonCodes: AdjustmentReasonCodeValueOptions(store),
                adjustmentReasonCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdjustmentReason, store),
                currentBusinessUnitCurrencyCode: BusinessUnitSelectors.CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                hierarchyInvoiceNodeSubscriberId: HierarchyInvoiceNodeSubscriberIdSelector(store),
                invoiceList: InvoiceListForDropDownSelector(store),
                invoiceSummaries: InvoiceSummariesSelector(store),
                isCreatingConvergentBillerAdjustment: IsCreatingConvergentBillerAdjustmentSelector(store),
                isFetchingInvoiceList: IsFetchingInvoiceListSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };
        const controllerActions = {
            createAdjustment: createConvergentBillerAdjustment,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveInvoicesForDropdown
        };

        this.localeKeys = LocaleKeys;

        this.adjustmentPaymentType = ADJUSTMENT_PAYMENT_TYPES.CREDIT;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.adjustmentReasonCodesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AdjustmentReason);
        }

        this.getTitleByAdjustmentType(this.adjustmentType);

        if (this.state.invoiceSummaries.length) {
            this.currencyCode = this.state.invoiceSummaries[0].CurrencyCode;
        } else {
            this.currencyCode = this.state.currentBusinessUnitCurrencyCode;
        }

        this.MIN_ADJUSTMENT_AMOUNT = 0.01;
        this.MAX_DESCR_LENGTH = 200;
        this.minPostDate = new Date();
        this.selectedPostDate = new Date();
        this.selectedReasonCode = null;
        this.selectedInvoice = null;
        this.currentDate = new Date();
        if (this.adjustmentType === MISC_ADJUSTMENT && !this.referenceId) {
            this.hasInvoiceDropdownAccess = hasAccess(this.state.userSecurityAttributes, VIEW_INVOICE_DROPDOWN_ACCESS);
            if (this.hasInvoiceDropdownAccess) {
                this.getInvoiceList();
            }
        }
    }

    $onChanges(changesObj) {
        if (changesObj) {
            if (changesObj.adjustmentType && changesObj.adjustmentType.currentValue !== changesObj.adjustmentType.previousValue) {
                this.getTitleByAdjustmentType(changesObj.adjustmentType.currentValue);
            }
            if (changesObj.referenceId && changesObj.referenceId.currentValue !== changesObj.referenceId.previousValue) {
                this.referenceId = changesObj.referenceId.currentValue;
            }
            if (changesObj.maxAdjustmentAmount && changesObj.maxAdjustmentAmount.currentValue !== changesObj.maxAdjustmentAmount.previousValue) {
                this.maxAdjustmentAmount = changesObj.maxAdjustmentAmount.currentValue;
            }
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getTitleByAdjustmentType(adjustmentType) {
        if (adjustmentType === MISC_ADJUSTMENT) {
            this.titleText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_MISC_ADJUSTMENT);
            this.applyButtonText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_MISC_ADJUSTMENT);
            this.enableGenerateStatements = this.state && this.state.adjustmentConfig[BillingPaymentsConstants.ENABLE_GENERATE_STATEMENT] === BillingPaymentsConstants.ADJUSTMENT_CONFIG_ENABLED ? true : false;
        } else if (adjustmentType === BILL_ADJUSTMENT) {
            if (this.itemId) {
                this.enableGenerateStatements = this.state && this.state.adjustmentConfig[BillingPaymentsConstants.ENABLE_GENERATE_STATEMENT] === BillingPaymentsConstants.ADJUSTMENT_CONFIG_ENABLED ? true : false;
                this.titleText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_ITEM_ADJUSTMENT);
                this.applyButtonText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_ITEM_ADJUSTMENT);
            } else {
                this.titleText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_INVOICE_ADJUSTMENT);
                this.applyButtonText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_INVOICE_ADJUSTMENT);
            }
        } else if (adjustmentType === DEPOSIT_ADJUSTMENT) {
            this.titleText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_ADJUSTMENT);
            this.applyButtonText = i18n.translate(LocaleKeys.ADJUSTMENTS.APPLY_ADJUSTMENT);
        }
    }

    isStatement() {
        return this.state.currentCustomer?.HierarchyReportingLevel === REPORTING_LEVELS.REPORTING_LEVEL_STATEMENT;
    }

    getInvoiceList() {
        const getInvoiceListRequest = {
            customerId: this.state.currentCustomer.Id,
            request: {
                Filter: BillingPaymentsConstants.INVOICES
            }
        };
        this.actions.retrieveInvoicesForDropdown(getInvoiceListRequest)
            .then(() => {
                this.formatInvoiceList();
                this.setInvoicesOptions();
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    closeModal() {
        this.resetFormValues();
        this.onClose();
    }

    resetFormValues() {
        this.adjustmentAmount = undefined;
        this.selectedDescription = undefined;
        this.selectedReasonCode = undefined;
        this.adjustmentForm.$setUntouched();
        this.adjustmentForm.$setPristine();
    }

    get adjustmentReasonCodes() {
        const adjustmentReasonCodes = pathOr([], ['adjustmentReasonCodes'], this.state);
        return this.deviceFinanceHeading ? adjustmentReasonCodes.filter((reasonCode) => {
            return (reasonCode.adjustment_category === ADJUSTMENT_CATEGORIES.FINANCE &&
                (
                    this.adjustmentPaymentType === reasonCode.adjustment_payment_type ||
                    isEmpty(reasonCode.adjustment_payment_type)
                )
            );
        }) : adjustmentReasonCodes.filter((reasonCode) => {
            return (
                (
                    reasonCode.adjustment_category === ADJUSTMENT_CATEGORIES.OTHER ||
                    isEmpty(reasonCode.adjustment_category)
                ) && (
                    this.adjustmentPaymentType === reasonCode.adjustment_payment_type ||
                    isEmpty(reasonCode.adjustment_payment_type)
                )
            );
        });
    }

    validMaxAdjustmentAmount() {
        this.setInvoicesOptions();
        if (this.adjustmentType === MISC_ADJUSTMENT) {
            if (this.adjustmentPaymentType === ADJUSTMENT_PAYMENT_TYPES.DEBIT) {
                this.maxAdjustmentAmount = null;
            }

            if (this.adjustmentPaymentType === ADJUSTMENT_PAYMENT_TYPES.CREDIT) {
                this.maxAdjustmentAmount = this.selectedInvoice ? this.selectedInvoice?.currentDue : null;
            }
        }
        return (this.adjustmentPaymentType === ADJUSTMENT_PAYMENT_TYPES.CREDIT) ? (this.maxAdjustmentAmount || this.financeBalance) : null;
    }

    validateForm() {
        this.$timeout(() => {
            this.formErrors = AngularFormUtilities.constraintErrorMessages(undefined, this.adjustmentForm, {
                accountNumber: LocaleKeys.ACCOUNT_NUMBER,
                adjustmentAmount: LocaleKeys.ADJUSTMENTS.ADJUSTMENT_AMOUNT,
                adjustmentDescription: LocaleKeys.ADJUSTMENTS.DESCRIPTIONS,
                reasonCode: LocaleKeys.ADJUSTMENTS.REASON_CODE
            }, {
                max: {
                    adjustmentAmount: this.$filter('invCurrency')(this.maxAdjustmentAmount, this.currencyCode)
                },
                maxlength: {
                    adjustmentDescription: this.MAX_DESCR_LENGTH
                },
                min: {
                    adjustmentAmount: this.$filter('invCurrency')(this.MIN_ADJUSTMENT_AMOUNT, this.currencyCode)
                }
            });

            // AngularFormUtilities does not have support for three-field-date-input. This code hacks it in.
            this.formErrorMessages = this.formErrors.filter((error) => {
                return error !== '';
            });
        });
    }

    setInvoicesOptions() {
        this.invoicesOptions = this.adjustmentPaymentType === ADJUSTMENT_PAYMENT_TYPES.DEBIT ? this.formattedInvoiceListforDebitAdjustments : this.formattedInvoiceList;
    }

    save() {
        if (this.adjustmentForm.$valid) {
            const isPostDateToday = moment().isSame(moment(this.selectedPostDate), 'day');
            const request = {
                customerId: this.state.currentCustomer.Id,
                request: {
                    AccountNumber: this.accountNumber,
                    AdjustmentPaymentType: this.adjustmentPaymentType,
                    AdjustmentTypeCode: this.adjustmentType,
                    Amount: parseFloat(this.adjustmentAmount).toFixed(2),
                    Description: this.selectedDescription,
                    EventId: this.itemId,
                    GenerateStatement: this.enableGenerateStatements,
                    InvoiceId: (this.adjustmentType === MISC_ADJUSTMENT && this.selectedInvoice) ? this.selectedInvoice.invoiceId : this.invoiceId,
                    PostDate: isPostDateToday ? new Date() : this.selectedPostDate,
                    ReasonCode: this.selectedReasonCode
                },
                BillingAndPayments: this.buildBillingAndPaymentsRequest()
            };
            this.actions.createAdjustment(request).then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(LocaleKeys.ADJUSTMENTS.ADJUSTMENT_SUCCESS));
                this.onSuccess();
                this.resetFormValues();
            }).catch((error) => {
                this.formErrorMessages.push(error.translatedMessage);
                this.formErrors.push(error.translatedMessage);
            });
        } else {
            this.validateForm();
        }
    }

    buildBillingAndPaymentsRequest() {
        return {
            customerId: this.state.currentCustomer.Id,
            request: {
                EndDate: this.state.billingAndPaymentsEndDate,
                Filter: BillingPaymentsConstants.ADJUSTMENTS | BillingPaymentsConstants.INVOICES | BillingPaymentsConstants.PAYMENTS,
                StartDate: this.state.billingAndPaymentsStartDate
            }
        };
    }

    formatInvoiceList() {

        function isFutureDatedInvoice(invoiceDueDate) {
            return invoiceDueDate && (new Date(invoiceDueDate)).getTime() > Date.now();
        }

        const clonedInvoiceSummaries = clone(this.state.invoiceList);
        clonedInvoiceSummaries.forEach((item) => {
            if (item.Invoice.CurrentDue > 0) {
                this.formattedInvoiceList.push({
                    currentDue: item.Invoice.CurrentDue,
                    dueDate: item.Invoice.DueDate,
                    invoiceId: item.Invoice.InvoiceId,
                    text: `${item.Invoice.InvoiceNumber} - ${this.$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)}`
                });
            }
            if (item.Invoice.CurrentDue > 0 && isFutureDatedInvoice(item.Invoice.DueDate)) {
                this.formattedInvoiceListforDebitAdjustments.push({
                    currentDue: item.Invoice.CurrentDue,
                    dueDate: item.Invoice.DueDate,
                    invoiceId: item.Invoice.InvoiceId,
                    text: `${item.Invoice.InvoiceNumber} - ${this.$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)} - ${this.$filter('localShortDate')(item.Invoice.DueDate)}`
                });
            }
        });
    }
}

export default {
    bindings: {
        adjustmentType: '<',
        config: '<',
        invoiceId: '<?',
        accountNumber: '<?',
        deviceFinanceHeading: '<?',
        financeBalance: '<?',
        itemId: '<?',
        itemText: '<?',
        isInvoiceAdjustment:'<?',
        maxAdjustmentAmount: '<?',
        onClose: '&',
        onSuccess: '&',
        referenceId: '<?',
        subscriberSummaryAccounts: '<?'
    },
    template: require('./adjustment.modal.html'),
    controller: AdjustmentModalController
};
