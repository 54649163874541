import angular from 'angular';
import 'invision-core';

import OverviewAndMemberDetailsComponent from './details.component';

export default angular.module('invision.customercare.customer.household.details', [
    'invision.ui.components.openNewWindow'
])
    .component('overviewAndMemberDetails', OverviewAndMemberDetailsComponent)
    .name;
