import {
    i18n,
    SessionSelectors
} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    RouteParams,
} from '../../../reducers/selectors/customer.selectors';

import {
    CurrentRedemptionDetailsAssociationCardsDataSelector
} from '../../../reducers/selectors/customer.coupons.selectors';

import {
    CurrentRedemptionDetailsSelector,
    CouponRedemptionCodeErrorSelector,
    IsRedemptionCodeGlobalSelector
} from '../../../reducers/selectors/coupon.redemption.code.selectors';

import {
    retrieveRedemptionCodeDetails
} from '../../../reducers/actions/customer.coupons.actions';

import {
    updateCouponRedemptionCodeExpiration
} from '../../../reducers/actions/coupon.redemption.code.actions';


class RedemptionCodeDetailsController {

    constructor($ngRedux, $timeout, $q, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
        this.uiNotificationService = uiNotificationService;
    }

    $onInit() {
        this.CustomerLocaleKeys = LocaleKeys;

        this.detailsTabs = [];

        if (!this.isGlobal) {
            this.detailsTabs.push({
                id: 'general',
                name: i18n.translate(this.CustomerLocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.TAB_LABELS.GENERAL),
                active: true
            });
        }

        this.detailsTabs.push({
            id: 'associations',
            name: i18n.translate(this.CustomerLocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.TAB_LABELS.ASSOCIATIONS),
            active: this.isGlobal
        });
        this.detailsTabs.push({
            id: 'redemptions',
            name: i18n.translate(this.CustomerLocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.TAB_LABELS.REDEMPTIONS),
            active: false
        });

        this.mapStateToTarget = (store) => {
            return {
                currentRedemptionDetails: CurrentRedemptionDetailsSelector(store),
                currentRedemptionDetailsAssociationCardsData: CurrentRedemptionDetailsAssociationCardsDataSelector(store),
                isRedemptionCodeGlobal: IsRedemptionCodeGlobalSelector(store),
                lastAttemptError: CouponRedemptionCodeErrorSelector(store),
                routeParams: RouteParams(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
            };
        };

        this.controllerActions = {
            retrieveRedemptionCodeDetails: retrieveRedemptionCodeDetails,
            updateCouponRedemptionCodeExpiration: updateCouponRedemptionCodeExpiration
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.shouldRequestRedemptionCodeDetails()) {
            this.actions.retrieveRedemptionCodeDetails(this.state.routeParams.customerId, this.state.routeParams.couponRedemptionCodeId);
        }

        this.selectedTab = this.detailsTabs[0];
    }

    shouldRequestRedemptionCodeDetails() {
        return !this.state.currentRedemptionDetails || this.currentRedemptionCodeDoesNotHaveRedemptionCount;
    }

    get currentRedemptionCodeDoesNotHaveRedemptionCount() {
        return !this.state.currentRedemptionDetails.RedemptionCode.RedemptionsCount;
    }

    tabSelected(tabSelected) {
        this.detailsTabs.forEach((detailTab) => {
            if (detailTab.id === tabSelected.id) {
                detailTab.active = true;
                this.selectedTab = tabSelected;
            } else {
                detailTab.active = false;
            }
        });
    }

    updateExpirationDate(date) {
        this.actions.updateCouponRedemptionCodeExpiration(this.state.routeParams.couponRedemptionCodeId, date, this.state.currentRedemptionDetails.RedemptionCode.Status)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(LocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.LABELS.UPDATE_EXPIRATION_DATE_SUCCESS_MESSAGE));
            });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        isGlobal: '<'
    },
    template: require('./redemption.code.details.html'),
    controller: RedemptionCodeDetailsController,
};
