import LocaleKeys from '../../locales/keys';
import {
    CurrentGiftCardIdSelector,
    CurrentGiftCardSelector,
    IsLoadingDataSelector
} from '../../reducers/selectors/giftcard.result.selectors';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../customer/ewallet/ewallet.constants';
import {stateGo} from 'redux-ui-router';
import {RouteState} from '../../reducers/selectors/customer.selectors';
import {getGiftCardResult} from '../../reducers/actions/giftcard.result.actions';
import {setSelectedTypeId} from '../../reducers/actions/customer.ewallet.actions';

class GiftCardController {

    constructor($ngRedux, $filter, $timeout) {
        this.$ngRedux = $ngRedux;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.CustomerCareLocaleKeys = LocaleKeys;
        this.optionalParams = {};
        this.modalPopupConfig = {
            onRegisterApi: ({api}) => {
                this.modalPopupApi = api;
            }
        };
        this.openModalPopup = this.openModalPopup.bind(this);
        this.closeModalPopup = this.closeModalPopup.bind(this);
        this.onSubmitModalPopup = this.onSubmitModalPopup.bind(this);
    }

    $onInit() {
        const controllerActions = {
            retrieveGiftCard: getGiftCardResult,
            setSelectedTypeId,
            stateGo
        };

        const mapStateToTarget = (store) => {
            return {
                currentGiftCard: CurrentGiftCardSelector(store),
                currentGiftCardId: CurrentGiftCardIdSelector(store),
                isLoadingData: IsLoadingDataSelector(store),
                routeState: RouteState(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = this.state.routeState.name;
        this.retrieveGiftCard();
    }

    $onDestroy() {
        this.disconnectRedux();
        this.modalPopupApi.close();
    }

    retrieveGiftCard() {
        this.actions.retrieveGiftCard(this.state.currentGiftCardId).then(() => {
            this.selectedGiftCard = this.state.currentGiftCard;
        });
    }


    closeModalPopup(hasResent) {
        this.modalPopupApi.close();
        this.showModalPopup = false;
        if (hasResent) {
            this.retrieveGiftCard();
        }
    }

    onSubmitModalPopup() {
        this.closeModalPopup();
    }

    openModalPopup() {
        this.showModalPopup = true;

        // This $timeout is necessary as it allows for a digest cycle to occur, which properly appends the content of the modal component that was ng-if'ed into the DOM
        this.$timeout(() => {
            this.modalPopupApi.open();
        });
    }

    shouldShowResendActivationMessageButton() {
        return this.selectedGiftCard && !this.selectedGiftCard.ProviderCode && !this.selectedGiftCard.Redeemed;
    }

    handleSubscriberClick(subscriberId) {
        this.actions.setSelectedTypeId(Number.parseInt(SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD, 10));
        this.actions.stateGo('index.customercare.customer.ewallet', {
            customerId: subscriberId
        });
    }
}

export default {
    bindings: {},
    template: require('./gift.card.html'),
    controller: GiftCardController
};
