import CustomerCareKeys from '../../../../../../locales/keys';
import {
    MINI_CART_MORE_ACTIONS,
    moreActionMenuItems
} from '../../../../../wizards/tabs/summaryTab/summary.tab.helper';
import {ITEM_QUOTE_STATUS} from '../../../../../../customercare.constants';
import {determineClassForComponent} from '../cart.helper';
class FullCartOfferComponent {

    constructor() {
        Object.assign(this, {
            assignedClass: null,
            CustomerCareKeys,
            ITEM_QUOTE_STATUS,
            handleMoreOptionsMenuSelected: this.handleMoreOptionsMenuSelected.bind(this)
        });
    }

    $onInit() {
        if (this.isEdit || this.isChange || this.isNewConnect) {
            this.menuItems = moreActionMenuItems([MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER]);
        } else {
            this.menuItems = moreActionMenuItems([MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER, MINI_CART_MORE_ACTIONS.REMOVE]);
        }
        this.assignedClass = determineClassForComponent(this.showHideFullCartColumns);
    }

    showMoreMenuActions() {
        if (this.isChange && this.offer.offeringInstanceId) {
            return this.offer.offeringInstanceId === '0';
        } else {
            return !this.offer.isPlaceHolderForExistingItems;
        }
    }

    handleMoreOptionsMenuSelected(option) {
        if (this.isEdit || this.isChange) {
            this.gotoDecisionsPage();
        } else {
            switch (option.item.id) {
                case MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER:
                    this.handleEdit()(this.offer.offeringInstanceId);
                    break;
                case MINI_CART_MORE_ACTIONS.REMOVE:
                    this.handleRemove()(this.offer.offeringInstanceId);
                    break;
                default:
                    break;
            }
        }
    }

    onExpandToggleItems(isExistingItemHeader, onLoad) {
        if (isExistingItemHeader) {
            this.expandExistingHeaderItems();
        }
        if (!onLoad) {
            this.onExpandToggle();
        }
    }
}

export default {
    bindings: {
        currency: '<',
        expandExistingHeaderItems: '&?',
        handleEdit: '&',
        handleRemove: '&',
        hideItem: '<?',
        isChange: '<?',
        isEdit: '<?',
        isExpanded: '<',
        isNewConnect: '<?',
        offer: '<',
        onExpandToggle: '&',
        gotoDecisionsPage: '&',
        showEdit: '<',
        showHideFullCartColumns: '<'
    },
    template: require('./full.cart.offer.html'),
    controller: FullCartOfferComponent
};
