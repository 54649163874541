// import AccountHierarchy from './accountHierarchy/account.hierarchy.module';
// import AddressesModule from './addresses/addresses.module';
import ApplyOrderCreditModal from './applyCreditModal/apply.credit.modal.module';
import AutoPayModule from './autoPay/auto.pay.module';
import AutoPayPostpaidModule from './autoPayPostpaid/auto.pay.postpaid.module';
import CaseModalModule from './caseModal/case.modal.module';
// import CasesModule from './cases/cases.module';
import ConfigureAutoPayModule from './configureAutopay/configure.autopay.module';
import ContentActivity from './contentActivity/content.activity.module';
import CreditCheckModule from './creditCheck/credit.check.module';
import CustomerCouponsModule from './coupons/customer.coupons.module';
import CustomPageModule from './customPage/custom.page.module';
import DashboardModule from './dashboard/dashboard.module';
import DemoActionsModule from './demoActions/demoActions.module';
import DevicesModule from './devices/devices.module';
import DisconnectOffersDbssModule from './offers/disconnect/dbss/disconnect.offers.dbss.module';
import DisconnectOffersItvModule from './offers/disconnect/itv/disconnect.offers.itv.module';
import DiscountsModule from './discountsModal/discounts.modal.module';
import EWalletModule from './ewallet/ewallet.module';
import HouseholdModule from './household/household.module';
// import InteractionsModule from './interactions/interactions.module';
// import InventoryModule from './inventory/inventory.module';
import MakePaymentModule from './makePayment/make.payment.module';
import OfferingsModule from './offerings/offerings.module';
import OffersModule from './offers/offers.module';
import OrderHistoryModule from './orderHistory/order.history.module';
// import OrderingModule from './ordering/ordering.module';
import OrdersModule from './orders/orders.module';
// import pricingPlanEarlyRenewalModule from './pricingPlanEarlyRenewal/pricingPlanEarlyRenewal.module';
import ProductsModule from './products/products.module';
import ProfileModule from './profile/profile.module';
// import RateSheetModule from './rateSheet/rate.sheet.module';
import RedeemGiftOrderModule from './redeemGiftOrder/redeem.gift.order.module';
import RemoveOfferModule from './offers/remove/remove.offer.module';
import ServicesAndUsageModule from './servicesAndUsage/services.and.usage.module';
import SubscriptionsModule from './subscriptions/subscriptions.module';
import TopUpsModule from './topUps/top.ups.module';
import TotalsModule from './totals/totals.module';
import TransactionsModule from './transactions/transactions.module';
import TreatmentDetailsModule from './treatmentDetails/treatment.details.module';
import UsageCapsModule from './servicesAndUsage/usageCaps/usage.caps.module';
import UsageDetailsModule from './servicesAndUsage/usageDetails/usage.details.module';
import {
    OfferingAdditionalPropertiesModalMountPoint
} from '@invision/customercare2/src/shared/components/contextualComponents/OfferingAdditionalPropertiesModal/OfferingAdditionalPropertiesModal.mountPoint';
import CreateUpdateCaseModalMountPoint from '@invision/customercare2/src/customer/cases/CreateUpdateCaseModal/CreateUpdateCaseModal.mountPoint';
import {react2angular} from 'react2angular';

import {routing as RoutingConfig} from './customer.config';
import CustomerComponent from './customer.component';

export default angular.module('invision.customercare.customer', [
    // AccountHierarchy,
    // AddressesModule,
    ApplyOrderCreditModal,
    AutoPayModule,
    AutoPayPostpaidModule,
    CaseModalModule,
    // CasesModule,
    ConfigureAutoPayModule,
    ConfigureAutoPayModule,
    ContentActivity,
    CreditCheckModule,
    CustomerCouponsModule,
    CustomPageModule,
    DashboardModule,
    DemoActionsModule,
    DevicesModule,
    DisconnectOffersDbssModule,
    DisconnectOffersItvModule,
    DiscountsModule,
    EWalletModule,
    HouseholdModule,
    // InteractionsModule,
    // InventoryModule,
    MakePaymentModule,
    OfferingsModule,
    OffersModule,
    OrderHistoryModule,
    // OrderingModule,
    OrdersModule,
    // pricingPlanEarlyRenewalModule,
    ProductsModule,
    ProfileModule,
    // RateSheetModule,
    RedeemGiftOrderModule,
    RemoveOfferModule,
    ServicesAndUsageModule,
    SubscriptionsModule,
    TopUpsModule,
    TotalsModule,
    TransactionsModule,
    TreatmentDetailsModule,
    UsageCapsModule,
    UsageDetailsModule
])
    .config(RoutingConfig)
    .component('customer', CustomerComponent)
    .component('editOfferServiceAttributesModal', react2angular(OfferingAdditionalPropertiesModalMountPoint, [
        'isOpen',
        'onClose',
        'onSubmit',
        'offeringInstanceId',
        'offeringInstanceAdditionalProperties',
        'routeParams'
    ]))
    .component('createUpdateCaseModal', react2angular(CreateUpdateCaseModalMountPoint, [
        'isOpen',
        'onClose',
        'onSubmit',
        'caseTemplateId',
        'disputeDetails',
        'routeParams'
    ]))
    .name;
