import {
    ApiConstants,
    ThunkHelper
} from 'invision-core';
import {retrieveConvergentBillerSubscriberSummaryPromise} from '../../../../reducers/actions/customer.convergent.biller.actions';

export const UPDATE_SERVICE_FRIENDLY_NAME = {
    BEGIN: 'UPDATE_SERVICE_FRIENDLY_NAME.BEGIN',
    SUCCESS: 'UPDATE_SERVICE_FRIENDLY_NAME.SUCCESS',
    FAILURE: 'UPDATE_SERVICE_FRIENDLY_NAME.FAILURE'
};

const UPDATE_SERVICE_FRIENDLY_NAME_CONSTANTS = [
    UPDATE_SERVICE_FRIENDLY_NAME.BEGIN,
    UPDATE_SERVICE_FRIENDLY_NAME.SUCCESS,
    UPDATE_SERVICE_FRIENDLY_NAME.FAILURE
];

export const updateServiceFriendlyNamePromise = (dispatch, customerId, serviceId, friendlyName) => {
    return ThunkHelper(dispatch, UPDATE_SERVICE_FRIENDLY_NAME_CONSTANTS, {
        method: 'post',
        url: 'SubscriberManagement/UpdateSubscriberServiceFriendlyName',
        data: {
            FriendlyName: friendlyName,
            ServiceIdentifier: serviceId
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const updateServiceFriendlyName = (customerId, serviceIdentifier, friendlyName) => {
    return (dispatch) => {
        return updateServiceFriendlyNamePromise(dispatch, customerId, serviceIdentifier, friendlyName).then(() => {
            return retrieveConvergentBillerSubscriberSummaryPromise(dispatch, customerId);
        });
    };
};

export const subscriberProductExpirationRenewOptInOutPromise = (dispatch, customerId, productId, optIn, reasonCode) => {
    return ThunkHelper(dispatch, UPDATE_SERVICE_FRIENDLY_NAME_CONSTANTS, {
        data: !optIn ? {
            SubscriberProductIds: [productId],
            OptOutReasonCode : reasonCode
        } : {
            SubscriberProductIds: [productId]
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        },
        method: 'post',
        url: optIn
            ? 'SubscriberManagement/SubscriberProductExpirationRenewOptIn'
            : 'SubscriberManagement/SubscriberProductExpirationRenewOptOut'
    });
};

export const subscriberProductExpirationRenewOptInOut = (customerId, productId, optIn = true, reasonCode) => {
    return (dispatch) => {
        return subscriberProductExpirationRenewOptInOutPromise(dispatch, customerId, productId, optIn, reasonCode).then(() => {
            return retrieveConvergentBillerSubscriberSummaryPromise(dispatch, customerId);
        });
    };
};
