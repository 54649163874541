import c3 from 'c3/c3';
import LocaleKeys from '../../../../locales/keys';
import {getHexValueByUsage} from './usage.chart.helper';

class PieChartController {
    constructor(uiTetherProvider, $element, $scope) {
        Object.assign(this, {
            $element,
            $scope,
            chart: null,
            uiTetherProvider
        });
        this.$scope.LocaleKeys = LocaleKeys;
    }

    $onChanges() {
        Object.assign(this.$scope, {
            currencyCode: this.currencyCode,
            total: this.total,
            unitCode: this.unitCode,
            used: this.used,
            unitDisplayName: this.unitDisplayName,
            entitlementUsageLimits: this.entitlementUsageLimits,
            perServiceUsageLimits: this.perServiceUsageLimits,
            pricingVectorOverrides: this.pricingVectorOverrides
        });
        this.generateChart();
    }

    $onInit() {
        this.generateChart();
        this.uiTetherProvider({
            target: this.$element,
            scope: this.$scope,
            template: require('./pie.chart.popup.template.html')
        });
    }

    getPieData() {
        const remainingBalance = this.total - this.used; //exception: do not do maths in UI
        return {
            columns: [
                ['used', this.used],
                ['remaining', remainingBalance]
            ],
            colors: {
                remaining: 'transparent',
                used: getHexValueByUsage(this.used, remainingBalance)
            }
        };
    }

    generateChart() {
        if (this.chart) {
            this.chart.load(Object.assign({
                unload: true,
            }, this.getPieData()));
        } else {
            this.cart = c3.generate({
                bindto: this.$element[0],
                data: Object.assign({
                    type: 'pie',
                    labels: false,
                    order: null
                }, this.getPieData()),
                interaction: {
                    enabled: false
                },
                tooltip: {
                    show: false
                },
                legend: {
                    show: false
                },
                pie: {
                    label: {
                        show: false
                    }
                }
            });
        }
    }
}

export default {
    controller: PieChartController,
    bindings: {
        currencyCode: '<',
        total: '<',
        unitCode: '<',
        used: '<',
        unitDisplayName: '<?',
        entitlementUsageLimits: '=',
        perServiceUsageLimits: '=',
        pricingVectorOverrides: '='
    }
};