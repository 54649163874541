import localeKeys from '../../../../locales/keys';
import {CoreLocaleKeys} from 'invision-core';
class CouponAssociationCardController {
    constructor() {
        this.localeKeys = localeKeys;
        this.coreLocaleKeys = CoreLocaleKeys;
    }
}

export default {
    template: require('./coupon.association.card.html'),
    bindings: {
        couponAssociation: '<'
    },
    controller: CouponAssociationCardController,
    controllerAs: 'CouponAssociationCard'
};
