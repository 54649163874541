import angular from 'angular';

import paymentMethodSwitcher from './payment.method.switcher.component';

import changePaymentPopup from './changePaymentPopup/change.payment.popup.module';
import modifyPaymentMethodPopup from './modifyPaymentMethodPopup/modify.payment.method.popup.module';

export default angular.module('invision.paymentMethodSwitcher', [
    changePaymentPopup,
    modifyPaymentMethodPopup
])
    .component('paymentMethodSwitcher', paymentMethodSwitcher)
    .name;
