import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    BusinessUnitSelectors,
    CoreLocaleKeys,
    i18n,
    SessionSelectors
} from 'invision-core';
import {CUSTOMER_CATEGORY, HierarchyNodeType} from '../../createCustomerPopup/create.customer.popup.constants';
import {
    getCustomerSearch,
    routeToCustomer,
} from '../../../reducers/actions/search.customer.actions';
import {
    clearSearchResults,
    setSearchType
} from '../../../reducers/actions/search.actions';
import {
    CustomerSearchCurrentPageNumberSelector,
    CustomerSearchArraySelector,
    CustomerSearchRecordCountSelector,
    CustomerSearchTotalPageCountSelector
} from '../../../reducers/selectors/search.customer.selectors';
import {SearchTypeSelector} from '../../../reducers/selectors/search.selectors';
import {
    MetadataCodeLoadedSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

export function SearchCustomerResultsController($ngRedux) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            currentPageNumber: CustomerSearchCurrentPageNumberSelector(store),
            customerSearchResultCount: CustomerSearchRecordCountSelector(store),
            customerSearchResults: CustomerSearchArraySelector(store),
            isDbss: BusinessUnitSelectors.IsDbss(store),
            pageSize: SessionSelectors.PageSizePreferenceSelector(store),
            searchType: SearchTypeSelector(store),
            subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberStatesLoaded: MetadataCodeLoadedSelector(CODES.SubscriberState, store),
            totalPageCount: CustomerSearchTotalPageCountSelector(store)
        };
    };

    this.$onInit = () => {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.customerCategory = CUSTOMER_CATEGORY;

        const controllerActions = {
            clearSearchResults,
            fetchCodeTypesThunk,
            getCustomerSearch,
            routeToCustomer,
            setSearchType
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.subscriberStatesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.SubscriberState);
        }
        this.getRecordCount = () => {
            const firstRecordNumberOnPage = (this.state.currentPageNumber * this.state.pageSize + 1) - this.state.pageSize;
            const lastRecordNumberOnPage = Math.min((this.state.currentPageNumber * this.state.pageSize), this.state.customerSearchResultCount);
            return i18n.translate(CoreLocaleKeys.PAGING_FOOTER, {
                pageRecordFirst: firstRecordNumberOnPage,
                pageRecordLast: lastRecordNumberOnPage,
                totalRecordCount: this.state.customerSearchResultCount
            });
        };

        this.clearResults = () => {
            const type = this.state.searchType;
            this.actions.clearSearchResults();
            this.actions.setSearchType(type);
        };

        this.customerClicked = (customer) => {
            this.actions.routeToCustomer(customer);
        };

        this.pageSelected = (pageNum) => {
            this.onPageChange()(pageNum);
            this.actions.getCustomerSearch(this.customerSearchModel, pageNum, this.state.pageSize);
        };
    };

    this.getGlyph = (customer) => {
        if (this.state.isDbss) {
            if (!!customer.HierarchyParentSubscriberId && customer.HierarchyNodeType === HierarchyNodeType.INHERITED) {
                return 'gen-hierarchy-lg';
            } else if (customer.Category === CUSTOMER_CATEGORY.COMMERCIAL) {
                return 'building';
            } else {
                return 'user';
            }
        }
        return '';
    };

    this.isServiceInheritedHierarchyNode = (customer) => {
        if (!!customer.HierarchyParentSubscriberId  && customer.HierarchyNodeType === HierarchyNodeType.INHERITED) {
            return true;
        }
        return false;
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };
}

// Definition for the self-contained SearchResults component
export const SearchCustomerResults = {
    template: require('./search.customer.results.html'),
    controller: SearchCustomerResultsController,
    controllerAs: 'SearchResults',
    bindings: {
        customerSearchModel: '<',
        pageNumber: '<',
        onPageChange: '&'
    }
};
