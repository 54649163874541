import {
    head,
    isNil,
    pathOr,
    pluck,
    reject
} from 'ramda';
import moment from 'moment';
import {stateGo} from 'redux-ui-router';

import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {fetchProductLineOfBusinessDeliveryCapabilityConfigurationAction} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    hasAdminAccess,
    hasAccess
} from 'invision-core/src/components/security/permission.service';

import LocaleKeys from './../../../../locales/keys';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {END_DATE_TYPES} from '../../../../reducers/constants/dashboard.constants';
import {
    GRANT_ADDITIONAL_RIGHTS,
    ORDERING_ACCESS
} from '../../../../security.attributes';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    RouteParams,
    RouteState
} from '../../../../reducers/selectors/customer.selectors';
import {DETAIL_STATE_OR_NAME as CONTENT_ACTIVITY_STATE} from '../../contentActivity/content.activity.config';
import {IsRemovingProductSelector} from '../../../../reducers/selectors/customer.products.selectors';
import {
    CanViewContentActivitySelector,
    CurrentLockerItemIdSelector,
    CurrentLockerItemOrderIdSelector,
    CurrentProductDetailSelector,
    CurrentProductDetailsClassificationSelector,
    CurrentProductOrderDetailSelector,
    CurrentProductServiceIdSelector,
    CurrentOfferInContextSelector,
    IsFetchingProductDetailDataSelector
} from '../../../../reducers/selectors/customer.product.detail.selectors';
import {
    retrieveOrderForLockerDetail,
    retrieveProductDetail
} from '../../../../reducers/actions/customer.product.detail.actions';
import {IsFetchingAccountDetailsSelector} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveConvergentBillerAccountDetails} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {isProductEditable} from '../products.component.helpers';

const MORE_OPTIONS_IDENTIFIER = {
    REMOVE: 0
};

class ProductsDetailController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            isProductLoadComplete: false,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                canViewContentActivity: CanViewContentActivitySelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                deliveryCapabilityConfigurationLoaded: MetadataCodeLoadedSelector(CODES.ProductLineOfBusinessDeliveryCapabilityConfigurationAction, store),
                currentLockerItemId: CurrentLockerItemIdSelector(store),
                currentLockerItemOrderId: CurrentLockerItemOrderIdSelector(store),
                currentProductServiceId: CurrentProductServiceIdSelector(store),
                currentOfferInContext: CurrentOfferInContextSelector(store),
                currentOrderDetail: CurrentProductOrderDetailSelector(store),
                isDbss: IsDbss(store),
                isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
                isFetchingProductDetail: IsFetchingProductDetailDataSelector(store),
                isRemovingProduct: IsRemovingProductSelector(store),
                productDetail: CurrentProductDetailSelector(store),
                productClassification: CurrentProductDetailsClassificationSelector(store),
                routeParams: RouteParams(store),
                routeState: RouteState(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            fetchDeliveryCapabilityConfiguration: fetchProductLineOfBusinessDeliveryCapabilityConfigurationAction,
            retrieveConvergentBillerAccountDetails,
            retrieveOrderForLockerDetail,
            retrieveProductDetail,
            stateGo
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.offerEndDateTypes = END_DATE_TYPES;
        this.CustomerLocaleKeys = LocaleKeys;
        this.stateOrName = 'index.customercare.customer.products.detail';

        this.actions.retrieveProductDetail(this.state.currentCustomerId, this.state.currentLockerItemId).then((response) => {
            this.isProductLoadComplete = true;
            if (this.state.isDbss) {
                this.actions.retrieveConvergentBillerAccountDetails(this.state.currentCustomerId).then(() => {
                    if (pathOr(null, ['LockerItem', 'Product', 'ProductClassification'], response) === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE) {
                        this.setServiceName();
                    }
                });
            }

            this.actions.retrieveOrderForLockerDetail(this.state.currentCustomerId, this.state.currentLockerItemOrderId);

            this.moreOptions = this.buildMoreOptions();
        });

        if (!this.state.deliveryCapabilityConfigurationLoaded) {
            this.actions.fetchDeliveryCapabilityConfiguration();
        }

        this.hasGrantAdditionalRightsAccess = this.canGrantAdditionalRights();

        this.grantAccessRightsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.grantAccessRightsPopupConfig = api;
            }
        };

        this.removeProductsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeProductsPopupConfig = api;
            }
        };

        this.currentDateTime = new Date();
    }

    $onChanges() {
        if (this.state) {
            this.moreOptions = this.buildMoreOptions();
        }
    }

    setServiceName() {
        const service = this.state.productDetail.Product.Services.find((service) => {
            return service.Id === this.state.currentProductServiceId;
        });
        this.ServiceName = service ? service.Name : null;
    }

    buildMoreOptions() {
        const hasOrderingAdminAccess = hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS);
        const moreOptions = [];

        if (hasOrderingAdminAccess && isProductEditable(this.state.productDetail, this.state.isDbss)) {
            moreOptions.push({
                title: i18n.translate(this.CustomerLocaleKeys.REMOVE),
                id: MORE_OPTIONS_IDENTIFIER.REMOVE
            });
        }

        return moreOptions;
    }

    handleOptionSelected({item: {id}}) {
        switch (id) {
            case MORE_OPTIONS_IDENTIFIER.REMOVE:
                this.openRemoveProductsPopup();
                break;
            default:
                break;
        }
    }

    submitGrantAccessRightsPopup() {
        this.closeGrantAccessRightsPopup();
        this.actions.retrieveProductDetail(this.state.currentCustomerId, this.state.currentLockerItemId);

        this.uiNotificationService.success(i18n.translate(this.CustomerLocaleKeys.GRANT_ACCESS.SUCCESSFULLY_UPDATED), null, {
            timeout: NOTIFICATION_TIME_LENGTH
        });
    }

    closeGrantAccessRightsPopup() {
        this.grantAccessRightsPopupConfig.close();
        this.showGrantAccessModal = false;
    }

    openGrantAccessRightsPopup(policy) {
        this.selectedPolicy = policy;
        this.showGrantAccessModal = true;

        this.$timeout(() => {
            this.grantAccessRightsPopupConfig.open();
        });
    }

    submitRemoveProductsPopup() {
        this.closeRemoveProductsPopup();

        this.actions.retrieveProductDetail(this.state.currentCustomerId, this.state.currentLockerItemId);
    }

    closeRemoveProductsPopup() {
        this.removeProductsPopupConfig.close();
        this.showRemoveProductsPopup = false;
    }

    openRemoveProductsPopup() {
        this.showRemoveProductsPopup = true;

        this.$timeout(() => {
            this.removeProductsPopupConfig.open();
        });
    }

    navigateToOrderDetails() {
        this.actions.stateGo('index.customercare.customer.orderHistory.details', {
            orderId: this.state.productDetail.OrderId
        });
    }

    navigateToContentActivity() {
        this.actions.stateGo(CONTENT_ACTIVITY_STATE, {
            productId: this.state.productDetail.Product.Id,
            previousRoute: {
                name: this.state.routeState.name,
                params: this.state.routeParams
            }
        }, {
            reload: true
        });
    }

    calculateTimeRemaining(expirationDate) {
        if (expirationDate) {
            const difference = moment(expirationDate).diff(moment());

            const duration = difference > 0 ? moment.duration(difference) : moment.duration(new Date());

            return i18n.translate(this.CustomerLocaleKeys.PRODUCTS_PAGE.TIME_REMAINING_DISPLAY, {
                Day: duration.days(),
                Hour: duration.hours(),
                Minute: duration.minutes()
            });
        } else {
            return null;
        }
    }

    getExpirationDate(policies) {
        if (policies) {
            //this finds the expiration date from a list of purchase policies on a locker item
            const expirationDate = head(reject(isNil, pluck('ExpirationDate')(policies)));
            this.isExpired = moment(expirationDate).isBefore(moment());

            return expirationDate;
        }
    }

    hasAccessOrLicenseCount(policies) {
        if (policies) {
            const accessCount = head(reject(isNil, pluck('AccessRequestsRemaining')(policies)));
            const licenseCount = head(reject(isNil, pluck('LicenseRequestsRemaining')(policies)));

            return accessCount || licenseCount;
        }
    }

    hasPolicyValue(policies) {
        return this.getExpirationDate(policies) || this.hasAccessOrLicenseCount(policies);
    }

    displayZeroWhenEmpty(property) {
        return property || i18n.translate(this.CustomerLocaleKeys.PRODUCTS_PAGE.ZERO);
    }

    canGrantAdditionalRights() {
        return hasAccess(this.state.userSecurityAttributes, GRANT_ADDITIONAL_RIGHTS);
    }

    showAvailabilityDate() {
        return new Date(pathOr(null, ['PricingPlan', 'AvailabilityStart'], this.state.productDetail)) > this.currentDateTime;
    }

    $onDestroy() {
        this.disconnect();
    }
}

export default {
    template: require('./products.detail.html'),
    controller: ProductsDetailController,
    controllerAs: 'ProductsDetail'
};
