import __ from 'ramda/src/__';
import isEmpty from 'ramda/src/isEmpty';
import pick from 'ramda/src/pick';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    CreateAddressPopupStateRegionProvinceValueOptions,
    PostalCodeSelector
} from '../../../reducers/selectors/customer.addresses.selectors';
import {setAddressData} from '../../../reducers/actions/customer.addresses.actions';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../../customer/addresses/addresses.constants';
import {IsAddressMandatoryInCreditCheckSelector} from '../../../reducers/selectors/credit.check.selectors';
import {stateRequiredHelper} from '../../customer/addresses/address.helper';

class AddressInformationSectionController {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            addressFormModel: {},
            CustomerCareLocaleKeys,
            CoreLocaleKeys,
            formMaxLengthValidation: ADDRESS_FORM_FIELD_MAX_LENGTH,
            isCountryDisabled: this.isCountryDisabled || false,
            onAddressFormUpdate: this.onAddressFormUpdate.bind(this),
            pickAddressFormDataFrom: this.pickAddressFormDataFrom.bind(this),
            stateRequired: null,
            uiNotificationService
        });
    }

    $onInit() {
        const promises = [];
        const codeTables = [
            CODES.AddressCountry,
            CODES.AddressRequirements,
            CODES.AddressStateProvinceRegion
        ];

        const mapStateToTarget = (store) => {
            return {
                addressRequirements: MetadataCodeTypeSelector(CODES.AddressRequirements, store),
                addressStatesOptions: CreateAddressPopupStateRegionProvinceValueOptions(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                isAddressMandatoryInCreditCheck: IsAddressMandatoryInCreditCheckSelector(store),
                postalCode: PostalCodeSelector(store)
            };
        };
        const controllerActions = {
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypes,
            setAddressData
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                promises.push(this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }));
            }
        });

        Promise.all(promises).then(() => {
            this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        Object.assign(this.addressFormModel, this.pickAddressFormDataFrom(this.customerModel));
        this.handleFormChanged();
    }

    $onChanges(changesObj) {
        if (!isEmpty(this.addressFormModel) && (changesObj.customerModel && changesObj.customerModel.currentValue)) {
            Object.assign(this.addressFormModel, this.pickAddressFormDataFrom(changesObj.customerModel.currentValue));
        }
    }

    onAddressFormUpdate(addressFormModel) {
        Object.assign(this.customerModel, addressFormModel);
        Object.assign(this.addressFormModel, addressFormModel);
        this.onFormChanged()(this.customerModel);
    }

    //This is a helper function for the address wrapup contextual modal
    pickAddressFormDataFrom(model) {
        return pick(['addressLine1', 'addressLine2', 'city', 'country', 'stateRegionProvince', 'postalCode'], model);
    }

    handleFormChanged() {
        this.setAddressData();
        this.onFormChanged()(this.customerModel);
    }

    setAddressData() {
        const payload = {};
        if (this.customerModel.country) {
            payload.Country = this.customerModel.country;
        }
        this.actions.setAddressData(payload);
    }

    isError(formElement) {
        return (formElement.$error.pattern || formElement.$error.required || formElement.$error.maxlength) && (formElement.$touched || this.isFormSubmitted);
    }

    isDisabled() {
        return this.state.isAddressMandatoryInCreditCheck && this.markMandatoryCreditCheckFieldsDisabled;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./address.section.html'),
    bindings: {
        addressStateOptions: '<',
        customerModel: '<',
        isCountryDisabled: '<',
        errorLookup: '<',
        onFormChanged: '&',
        isFormSubmitted: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?'
    },
    controller: AddressInformationSectionController,
    controllerAs: 'AddressInformationSection'
};
