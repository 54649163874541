import isEmpty from 'ramda/src/isEmpty';
import pathSatisfies from 'ramda/src/pathSatisfies';
import i18n from 'invision-core/src/components/i18n/i18n';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';

import CustomerCareKeys from '../../../../../../../locales/keys';

export const getProductPriceLabel = (product) => {
    return hasPriceInfo(product) ? `${i18n.translate(CustomerCareKeys.FEATURED_PRICE)}` : '';
};

export const getProductPrice = ($filter, product) => {
    if (hasBrandableCurrencyInfo(product)) {
        return `${product.ProductContext.FeaturedOrderablePricingPlan.Amount || 0} ${product.ProductContext.FeaturedOrderablePricingPlan.BrandableCurrencyName}`;
    }

    if (hasPriceInfo(product)) {
        return `${$filter('invCurrency')(product.ProductContext.FeaturedOrderablePricingPlan.Amount || 0, product.ProductContext.FeaturedOrderablePricingPlan.Currency)}`;
    }

    return '';
};

export const hasPriceInfo = (product) => {
    return product.ProductClassification === PRODUCT_CLASSIFICATIONS.AD_HOC ||
        product.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE ? false :
        pathSatisfies((featuredPlan) => {
            return featuredPlan !== undefined && !isEmpty(featuredPlan);
        }, ['ProductContext', 'FeaturedOrderablePricingPlan'], product);
};

const hasBrandableCurrencyInfo = (product) => {
    return pathSatisfies((featuredPlan) => {
        return featuredPlan !== undefined && !isEmpty(featuredPlan);
    }, ['ProductContext', 'FeaturedOrderablePricingPlan', 'BrandableCurrency'], product);
};