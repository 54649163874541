import pathOr from 'ramda/src/pathOr';
import {createSelector} from 'reselect';
import {
    AllAttributesAreValidHelper,
    CurrentActiveAttributeNameHelper,
    FilteredFormAttributeGroupsHelper,
    FilteredPhysicalAttributeGroupsHelper,
    FormAttributeGroupsNavigationHelper,
    GetFinancedProductGroupInCartSelector,
    GetSummaryTabFinancedGroupSelector,
    HasRequiredAndUnavailableInventory,
    ShowNextButtonHelper,
    ShowPreviousButtonHelper
} from '../../../../reducers/helpers/offer.ordering.wizard.selector.helper';
import {
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    OfferQuoteSelector,
    SummaryTabViewModelSelector
} from '../../../../reducers/selectors/transition.offer.wizard.selectors';
import {
    AttributeFormSubmittedSelector,
    CurrentAttributeFormName,
    CurrentAttributesValidationStatuses,
    SubmittedOrderErrorSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';

export const FilteredPhysicalAttributeGroups = FilteredPhysicalAttributeGroupsHelper([FormAttributeGroups, FormPhysicalAttributeGroups, CurrentAttributeFormName]);
export const AttributeServiceSelector = FormAttributeGroupsNavigationHelper([
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    CurrentAttributeFormName,
    AttributeFormSubmittedSelector,
    CurrentAttributesValidationStatuses,
    SubmittedOrderErrorSelector
]);
export const CurrentActiveAttributeNameSelector = CurrentActiveAttributeNameHelper([AttributeServiceSelector]);
export const ShowPreviousButtonSelector = ShowPreviousButtonHelper([AttributeServiceSelector]);
export const ShowNextButtonSelector = ShowNextButtonHelper([AttributeServiceSelector]);
export const FilteredFormAttributeGroups = FilteredFormAttributeGroupsHelper([FormAttributeGroups, AttributeServiceSelector]);
export const AllAttributesAreValidSelector = AllAttributesAreValidHelper([AttributeServiceSelector, CurrentAttributesValidationStatuses]);

const SummaryTabFinancedGroupSelector = GetSummaryTabFinancedGroupSelector([SummaryTabViewModelSelector]);

export const HasRequiredAndUnavailableInventorySelector = HasRequiredAndUnavailableInventory([FormAttributeGroups]);

export const FinancedItemsForDownpaymentSelector = createSelector(
    [SummaryTabFinancedGroupSelector],
    (financedProductGroup) => {
        return pathOr([], ['products'], financedProductGroup).filter((product) => {
            return product.isAdded;
        });
    }
);

export const FinancedProductGroupInCartSelector = GetFinancedProductGroupInCartSelector([
    FinancedItemsForDownpaymentSelector,
    OfferQuoteSelector
]);