import angular from 'angular';

import 'invision-core';

import EditHouseholdPopupComponent from './edit.household.popup.component';

export default angular.module('invision.customercare.customer.household.editHouseholdPopup', [
    'invision.core.components'
])
    .component('editHouseholdPopup', EditHouseholdPopupComponent)
    .name;
