import CustomerLocaleKeys from '../../../../locales/keys';

class SubscriberServiceDetails {
    constructor() {
        Object.assign(this, {
            CustomerLocaleKeys
        });
    }
}

export default {
    bindings: {
        addOnTitle: '<?',
        currencyCode: '<?',
        serviceFeatures: '<?',
    },
    template: require('./subscriber.service.details.html'),
    controller: SubscriberServiceDetails
};
