import {stateGo} from 'redux-ui-router';
import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsConvergentBillerSelector} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

import localeKeys from './../../../locales/keys';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    EnableProspectLiteForHouseholdSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    CanMakeHouseholdChangesSelector,
    CurrentHouseholdSelector,
    CurrentHouseholdMemberSelector,
    CurrentMemberIsHeadOfHouseholdSelector,
    CsrHasHouseholdAdminAccessSelector,
    HeadOfHouseholdSelector,
    HouseholdErrorSelector,
    HouseholdMembersListSelector,
    HouseholdMoreOptionsSelector,
    IncludeRemovedSelector,
    IsFetchingHouseholdSelector,
    IsRemovingHouseholdSelector,
    SelectedHouseholdMemberSelector,
    ShowHouseholdSelector
} from '../../../reducers/selectors/customer.household.selectors';
import {
    removeHousehold,
    retrieveCustomerHouseholds,
    setIncludedRemovedFilter,
    setSelectedHouseholdMember,
    updateHouseholdAndStatus
} from '../../../reducers/actions/customer.household.actions';
import {NOTIFICATION_TIME_LENGTH} from '../../../customercare.constants';
import {getMemberBySubscriberId} from './household.helpers';
import {
    HOUSEHOLD_OPTION_ACTIVATE_ID,
    HOUSEHOLD_OPTION_EDIT_ID,
    HOUSEHOLD_OPTION_REMOVE_ID,
    HOUSEHOLD_STATE_NAME
} from './household.constants';

const ACTIVE = 1;
class CustomerHouseholdController {
    constructor($ngRedux, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
    }

    $onInit() {
        this.customerCareKeys = localeKeys;

        const mapStateToTarget = (store) => {
            return {
                canMakeChanges: CanMakeHouseholdChangesSelector(store),
                csrHasAdminAccess: CsrHasHouseholdAdminAccessSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentHousehold: CurrentHouseholdSelector(store),
                currentMember: CurrentHouseholdMemberSelector(store),
                currentMemberIsHeadOfHousehold: CurrentMemberIsHeadOfHouseholdSelector(store),
                enableProspectLiteForHousehold: EnableProspectLiteForHouseholdSelector(store),
                headOfHousehold: HeadOfHouseholdSelector(store),
                householdError: HouseholdErrorSelector(store),
                householdOptions: HouseholdMoreOptionsSelector(store),
                members: HouseholdMembersListSelector(store),
                includeRemoved: IncludeRemovedSelector(store),
                isConvergentBiller: IsConvergentBillerSelector(store),
                isFetchingHouseholds: IsFetchingHouseholdSelector(store),
                isRemovingHousehold: IsRemovingHouseholdSelector(store),
                selectedMember: SelectedHouseholdMemberSelector(store),
                serviceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                showHousehold: ShowHouseholdSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeType: fetchCodeTypesThunk,
            removeHousehold,
            retrieveCustomerHouseholds,
            setIncludedRemovedFilter,
            setSelectedHouseholdMember,
            stateGo,
            updateHouseholdAndStatus
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = HOUSEHOLD_STATE_NAME;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.editHouseholdPopupConfig = {
            onRegisterApi: (event) => {
                this.editHouseholdPopupApi = event.api;
            }
        };

        this.removeHouseholdPopupConfig = {
            onRegisterApi: (event) => {
                this.removeHouseholdPopupApi = event.api;
            }
        };

        this.createMemberPopupConfig = {
            onRegisterApi: (event) => {
                this.createMemberPopupApi = event.api;
            }
        };

        if (!this.state.currentHousehold) {
            this.retrieveHouseholds();
        }

        if (!this.state.serviceAttributesLoaded) {
            this.actions.fetchCodeType(CODES.ServiceAttribute);
        }
    }

    handleClickCreateHousehold() {
        this.editHouseholdPopupApi.open();
    }

    handleClickRemoveHousehold() {
        this.removeHouseholdPopupApi.open();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    handleHouseholdAction(message, memberId) {
        this.uiNotificationService.success(message, null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.retrieveHouseholds()
            .then(() => {
                this.setSelectedMember(getMemberBySubscriberId(this.state.currentHousehold.Members, memberId));
            });
    }

    handleClickNewMember() {
        this.createMemberPopupApi.open();
    }

    retrieveHouseholds() {
        const INCLUDE_REMOVED = true;
        return this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, INCLUDE_REMOVED);
    }

    setSelectedMember(member) {
        this.actions.setSelectedHouseholdMember((!member || member.isOverview) ? null : member.SubscriberId);
    }

    handleMoreOptionsMenuSelected(item) {
        switch (item.item.id) {
            case HOUSEHOLD_OPTION_ACTIVATE_ID:
                this.activateHousehold();
                break;
            case HOUSEHOLD_OPTION_EDIT_ID:
                this.editHouseholdPopupApi.open();
                break;
            case HOUSEHOLD_OPTION_REMOVE_ID:
                this.removeHouseholdPopupApi.open();
                break;
            default:
                break;
        }
    }

    shouldShowAssociateServices() {
        const associatedServices = pathOr([], ['currentHousehold', 'MemberToServiceAssociations'], this.state);
        const unassociatedServices = pathOr([], ['headOfHousehold', 'services'], this.state);

        return this.state.isConvergentBiller && this.state.currentMemberIsHeadOfHousehold && (associatedServices.length || unassociatedServices.length) ? true : false;
    }

    handleIncludeRemovedClick() {
        this.actions.setIncludedRemovedFilter(!this.state.includeRemoved);
        this.retrieveHouseholds();
    }

    closeDialog(dialogApi) {
        dialogApi.close();
    }

    activateHousehold() {
        this.actions.updateHouseholdAndStatus(this.state.currentCustomerId, this.state.currentHousehold, ACTIVE).then(() => {
            this.uiNotificationService.success(i18n.translate(localeKeys.HOUSEHOLD.REACTIVATE_HOUSEHOLD_SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });

            return this.retrieveHouseholds();
        }).catch(() => {
            this.uiNotificationService.error(this.state.householdError.translatedMessage, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        });
    }

    removeHouseholdDialogResult(isRemoveHousehold) {
        if (isRemoveHousehold) {
            this.actions.removeHousehold(this.state.currentCustomerId, this.state.currentHousehold.Id).then(() => {
                this.uiNotificationService.success(i18n.translate(localeKeys.HOUSEHOLD.DIALOG.REMOVE_HOUSEHOLD.SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });

                return this.retrieveHouseholds();
            }, () => {
                this.uiNotificationService.error(this.state.householdError.translatedMessage, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }).then(() => {
                this.setSelectedMember();
            });
        }

        this.closeDialog(this.removeHouseholdPopupApi);
    }

    get showLoadingIndicator() {
        return this.state.isFetchingHouseholds || this.state.isRemoveHousehold;
    }
}

export default {
    template: require('./household.html'),
    bindings: {},
    controller: CustomerHouseholdController,
    controllerAs: 'CustomerHousehold'
};
