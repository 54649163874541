export const MENU_ITEMS = {
    ADD_ANOTHER_OFFER: 'ADD_ANOTHER_OFFER_MENU_ITEM',
    ADD_PAYMENT_INSTRUMENT: 'ADD_PAYMENT_INSTRUMENT_MENU_ITEM',
    CHANGE_OFFER: 'CHANGE_OFFER_MENU_ITEM',
    CHANGE_PAYMENT_INSTRUMENT: 'CHANGE_PAYMENT_INSTRUMENT_MENU_ITEM',
    DISCONNECT_OFFER: 'DISCONNECT_OFFER_MENU_ITEM',
    DISABLE_AUTO_RENEW: 'DISABLE_AUTO_RENEW',
    EDIT_ADDITIONAL_PROPERTIES: 'EDIT_ADDITIONAL_PROPERTIES',
    EDIT_OFFER_ADDITIONAL_PROPERTIES: 'EDIT_OFFER_ADDITIONAL_PROPERTIES',
    EDIT_OFFER: 'EDIT_OFFER_MENU_ITEM',
    EDIT_PAYMENT_INSTRUMENT: 'EDIT_PAYMENT_INSTRUMENT_MENU_ITEM',
    ENABLE_AUTO_RENEW: 'ENABLE_AUTO_RENEW',
    MAKE_EARLY_RENEWAL_PAYMENT: 'MAKE_EARLY_RENEWAL_PAYMENT',
    MANAGE_PLAN_SERVICES: 'MANAGE_PLAN_SERVICES_MENU_ITEM',
    RECONNECT_OFFER: 'RECONNECT_OFFER_MENU_ITEM',
    REMOVE_OFFER: 'REMOVE_OFFER_MENU_ITEM',
    RESUME_OFFER: 'RESUME_OFFER_MENU_ITEM',
    RESUME_SUBSCRIBER_PRODUCT: 'RESUME_SUBSCRIBER_PRODUCT',
    RETRY_PAYMENT: 'RETRY_PAYMENT_MENU_ITEM',
    SUSPEND_OFFER: 'SUSPEND_OFFER_MENU_ITEM',
    SUSPEND_SUBSCRIBER_PRODUCT: 'SUSPEND_SUBSCRIBER_PRODUCT',
    VIEW_OFFER_DETAILS: 'VIEW_OFFER_DETAILS_MENU_ITEM'
};