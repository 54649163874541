import angular from 'angular';
import 'angular-ui-grid';

import OrderDetailsComponent from './orderDetails/order.details.component';
import AdditionalPropertiesComponent from './orderDetails/additionalPropertiesModal/additionalProperties.component';

import AttributeModalModule from './orderDetails/attributeModal/attribute.modal.module';
import ExportBulkAttributesModal from './orderDetails/exportBulkAttributesModal/export.bulk.attributes.modal.module';
import BulkDetailsModal from './orderDetails/bulkDetailsModal/bulk.details.modal.module';
import DiscountDetailsDialogModule from './orderDetails/discountDetailsDialog/discount.details.dialog.module';
import GiftNotificationHistory from './orderDetails/giftNotificationHistory/gift.notification.history.module';
import ImportBulkAttributesModal from './orderDetails/importBulkAttributesModal/import.bulk.attributes.modal.module';
import ResendGiftNotificationModal from './orderDetails/resendGiftNotificationModal/resend.gift.notification.modal.module';
import ChangeFdoDateModal from './orderDetails/changeFdoDateModal/change.fdo.date.modal.module';
import orderHistoryListWrapperModule from './orderHistoryListWrapper/order.history.list.wrapper.module';

import OrderHistoryListWrapperComponent from './orderHistoryListWrapper/order.history.list.wrapper.component';
import {routing as OrderHistoryConfig} from './order.history.config';
import RevokeGiftOrderDialogModule from './orderDetails/revokeGiftOrderDialog/revoke.gift.order.module';

export default angular.module('inv.customer.orderHistory', [
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.moveColumns',
    AttributeModalModule,
    BulkDetailsModal,
    ChangeFdoDateModal,
    DiscountDetailsDialogModule,
    ExportBulkAttributesModal,
    GiftNotificationHistory,
    ImportBulkAttributesModal,
    ResendGiftNotificationModal,
    RevokeGiftOrderDialogModule,
    orderHistoryListWrapperModule
])
    .component('orderDetails', OrderDetailsComponent)
    .component('additionalPropertiesModal', AdditionalPropertiesComponent)
    .component('orderHistoryListWrapper', OrderHistoryListWrapperComponent)
    .config(OrderHistoryConfig)
    .name;
