import i18n from 'invision-core/src/components/i18n/i18n';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import clone from 'ramda/src/clone';
import moment from 'moment';
import {LastRouteSelector} from 'invision-core/src/components/session/session.selectors';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    BackButtonTextSelector,
    CartHasItemsSelector,
    CartTabViewModel,
    FormattedCityStateSelector,
    FormattedNameSelector,
    ForwardButtonTextSelector,
    HasCustomerInfoSelector,
    IsCreatingModeSelector,
    IsModifyingModeSelector,
    IsPreviousRouteDashboardSelector,
    IsReplacingModeSelector,
    IsRestoringModeSelector,
    PreviousRouteSelector,
    QuoteTabViewModel,
    WizardTitleCustomerOrServiceSelector,
    WizardTitleLocaleSelector
} from './create.products.order.wizard.selectors';
import {
    ChildSubscriptionIdSelector,
    CurrentSubscriptionIdSelector,
    CurrentSubscriptionSelector,
    EditSubscriptionItemSelector,
    SubscriptionErrorSelector,
} from '../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    SelectedInventoryNameSelector
} from '../../../../../reducers/selectors/customer.inventory.selectors';
import {
    clearCurrentSubscription,
    retrieveSubscription
} from '../../../../../reducers/actions/customer.subscriptions.actions';
import {
    initializeReplaceOrder,
    retrieveOrderQuote,
    setReplaceItem,
    setSelectedProductId
} from '../../../../../reducers/actions/products.order.actions';
import FaultCodeKeys from '../../../../../api/fault.code.keys';
import {
    addMultipleAndUpdateShoppingCart,
    calculateChangeOfServiceOrderQuote,
    calculateModifyOrderQuote,
    calculateOrderQuote,
    calculateRenewOrderQuote,
    cancelOrder,
    clearOrderQuote,
    editMultipleAndUpdateShoppingCart,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    reinitializeProductOrder,
    removeFromAndUpdateShoppingCart,
    setCurrentStepIsInvalid,
    setEditOrderAdditionalProperty,
    setEditOrderAdditionalPropertyOnChange,
    setSelectedShippingMethodId,
    setSelectedTab,
    setShoppingCartItemIndex,
    submitGiftOrder,
    submitModifyOrder,
    submitOrder,
    submitRestoreOrder,
    submitServiceFeatureOrder,
    updateServiceFeatureQuantityPromise,
    updateShoppingCartWithLatest
} from '../../../../../reducers/actions/products.wizard.actions';
import {
    ChangeImmediateSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    FeatureOrderProductPricingPlanQuantitySelector,
    IsCheckoutStepSelector,
    IsProductsStepSelector,
    IsProductWizardRestoreOrderWaivingFeeSelector,
    IsProductWizardRestoreOrderWaivingLateFeeSelector,
    OrderAdditionalPropertiesSelector,
    OrderAdditionalPropertyListMapSelector,
    OttExecutionOptionsSelector,
    PaymentInstrumentIdsSelector,
    PaymentInstrumentsSelector,
    PaymentInstrumentsCleanSelector,
    ProductOrderShippingAddressIdSelector,
    ProductOrderShippingMethodIdSelector,
    ProductWizardGiftOrderInfoSelector,
    ProductWizardIsGiftOrderSelector,
    ProductWizardOrderDetailsSelector,
    ProductWizardRestoreOrderWaiveFeeReasonSelector,
    ProductWizardRestoreOrderWaiveLateFeeReasonSelector,
    ProductWizardTemporaryShipToNameSelector,
    SelectedTabIndexSelector,
    ShowPreviousButtonSelector,
    TabPanelViewModelSelector
} from '../../../../../reducers/selectors/products.wizard.selectors';
import {
    CreatedOrderSelector,
    DiscretionaryDiscountsIdSelector,
    DiscretionaryDiscountsReasonSelector,
    HasPrepaidPricingPlanSelector,
    InvalidCouponRedemptionsSelector,
    InvalidItemsSelector,
    IsAsyncCompleteSelector,
    IsCalculatingQuoteSelector,
    IsDbssShoppingCart,
    IsFetchingSelector,
    IsServiceFeatureShoppingCart,
    IsSubmittingOrderSelector,
    ModifiedSubscriptionItemSelector,
    ModifySubscriptionRequestDataSelector,
    OrderRequiresShippingSelector,
    OrderSubscriptionQuotesAsMutableSelector,
    ProductOfferAsyncIdSelector,
    ProductOrderDataListSelector,
    RemovedOrderSelector,
    ReplaceCartItemSelector,
    ReplacementCartItemSelector,
    SearchCouponCodeSelector,
    SelectedCustomerAddressSelector,
    SelectedInventoryStoreDetailsSelector,
    SelectedProductIdSelector,
    SelectedServiceIdentifierSelector,
    ShippingMethodsViewModel,
    ShoppingCartIsDirtySelector,
    ShoppingCartItemDetailsLocationCodesSelector,
    ShoppingCartSelector,
    SkipToCheckoutSelector,
    SubmitChangeOfServiceForServiceFeatureRequestSelector,
    SubmitChangeOfServiceForServiceFeatureWithoutQuantitiesRequestSelector,
    UnfilteredCouponCodesSelector,
    ViewLastAttemptErrorSelector
} from '../../../../../reducers/selectors/products.order.selectors';
import {INVENTORY_CATEGORIES} from '../../../inventory/inventory.constants';
import {
    CurrentCustomerSelector,
    RouteParams,
    RouteState
} from '../../../../../reducers/selectors/customer.selectors';
import {
    getExistingQuantities,
    processServiceFeatures
} from './steps/products/productDialog/product.dialog.helpers';
import {
    CREATE_PRODUCTS_ORDER_STATE_NAME,
    DBSS_UPDATE_CART_ADDITIONAL_OPTIONS
} from '../../../../../reducers/constants/products.wizard.constants';
import {RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION} from '../../../../../../src/customercare.constants';
import * as SubscriptionHelper from '../../../subscriptions/subscription.helper';
import CustomerCareKeys from '../../../../../locales/keys';
import SubscriptionOrder from './subscriptionHelpers/subscription.order';
import {SUBSCRIPTION_DETAILS_ROUTE} from '../../../subscriptions/subscriptions.config';
import {isServiceFeatureOrder} from '../productsOrder/steps/products/products.wizard.helper';
import {
    IsFetchingOrdersSelector,
    OrdersSelector
} from '../../../../../reducers/selectors/order.history.selectors';
import {
    IsFetchingAccountDetailsSelector,
    CurrentConvergentBillerSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {retrieveConvergentBillerAccountDetails} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {searchOrders} from '../../../../../reducers/actions/order.history.actions';
import {asyncRepeatInterval} from '../../../../../reducers/helpers/async.helper';
import {
    PaymentIsFetchingDataSelector,
    RecordPaymentInformationSelector
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {retrieveCandidateBillCycles} from '../../../../../reducers/actions/customer.billcycle.actions';
import {setReloadSubscriberStateFlag} from '../../../../../reducers/actions/customer.actions';
import {CUSTOMER_CATEGORY} from '../../../../createCustomerPopup/create.customer.popup.constants';

const clearStore = (actions) => {
    actions.reinitializeProductOrder();
    actions.clearCurrentSubscription();
};

const TAX_LOCATION_CONSTANTS = {
    CUSTOMER_ADDRESS: 2,
    DEFAULT: 1,
    NPA_NXX: 3,
    STORE_LOCATION: 4,
};

function ProductsWizardController($ngRedux, $log, $state, $timeout, unsavedChangesPrompt, uiNotificationService) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            additionalPropValueOptions: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
            asyncId: ProductOfferAsyncIdSelector(store),
            backButtonText: BackButtonTextSelector(store),
            cartHasItems: CartHasItemsSelector(store),
            cartTab: CartTabViewModel(store),
            changeImmediately: ChangeImmediateSelector(store),
            convergentBiller: CurrentConvergentBillerSelector(store),
            couponCodes: UnfilteredCouponCodesSelector(store),
            createdOrder: CreatedOrderSelector(store),
            currentCustomer: CurrentCustomerSelector(store),
            currentStep: CurrentStepSelector(store),
            currentStepIsInvalid: CurrentStepIsInvalidSelector(store),
            currentSubscription: CurrentSubscriptionSelector(store),
            currentSubscriptionChildItemId: ChildSubscriptionIdSelector(store),
            currentSubscriptionId: CurrentSubscriptionIdSelector(store),
            defaultRoute: PreviousRouteSelector(store),
            discretionaryDiscountIds: DiscretionaryDiscountsIdSelector(store),
            discretionaryDiscountReason: DiscretionaryDiscountsReasonSelector(store),
            editSubscriptionItem: EditSubscriptionItemSelector(store),
            executionOptions: OttExecutionOptionsSelector(store),
            featureOrderQuantities: FeatureOrderProductPricingPlanQuantitySelector(store),
            formattedCityState: FormattedCityStateSelector(store),
            formattedName: FormattedNameSelector(store),
            forwardButtonText: ForwardButtonTextSelector(store),
            giftOrderInfo: ProductWizardGiftOrderInfoSelector(store),
            hasCustomerInfo: HasCustomerInfoSelector(store),
            hasPrepaid: HasPrepaidPricingPlanSelector(store),
            // used w/ SubscriptionOrder (via Submit(Gift|Modify|Restore)Order flow)
            invalidCouponRedemptions: InvalidCouponRedemptionsSelector(store),
            invalidItems: InvalidItemsSelector(store),
            isAsyncComplete: IsAsyncCompleteSelector(store),
            isCalculatingQuote: IsCalculatingQuoteSelector(store),
            isCheckoutStep: IsCheckoutStepSelector(store),
            isCreatingMode: IsCreatingModeSelector(store),
            isDbssBU: IsDbss(store),
            isDbssShoppingCart: IsDbssShoppingCart(store),
            isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
            isFetchingData: IsFetchingSelector(store),
            isFetchingOrders: IsFetchingOrdersSelector(store),
            isFetchingWalletData: PaymentIsFetchingDataSelector(store),
            isGiftOrder: ProductWizardIsGiftOrderSelector(store),
            isModifyingMode: IsModifyingModeSelector(store),
            isPreviousRouteDashboard: IsPreviousRouteDashboardSelector(store),
            isProductsStep: IsProductsStepSelector(store),
            isReplacingMode: IsReplacingModeSelector(store),
            isRestoringMode: IsRestoringModeSelector(store),
            isServiceFeatureShoppingCart: IsServiceFeatureShoppingCart(store),
            isSubmittingOrder: IsSubmittingOrderSelector(store),
            lastAttemptError: ViewLastAttemptErrorSelector(store),
            lastRoute: LastRouteSelector(store),
            locationCodes: ShoppingCartItemDetailsLocationCodesSelector(store),
            modifiedItem: ModifiedSubscriptionItemSelector(store),
            modifyItemRequestData: ModifySubscriptionRequestDataSelector(store),
            orderAdditionalProperties: OrderAdditionalPropertiesSelector(store),
            orderAdditionalPropertyListMap: OrderAdditionalPropertyListMapSelector(store),
            orderDetails: ProductWizardOrderDetailsSelector(store),
            orderRequiresShipping: OrderRequiresShippingSelector(store),
            orders: OrdersSelector(store),
            paymentInstrumentIds: PaymentInstrumentIdsSelector(store),
            paymentInstruments: PaymentInstrumentsSelector(store),
            paymentInstrumentsClean: PaymentInstrumentsCleanSelector(store),
            productsList: ProductOrderDataListSelector(store),
            quoteTab: QuoteTabViewModel(store),
            recordPaymentInfo: RecordPaymentInformationSelector(store),
            removedOrder: RemovedOrderSelector(store),
            replaceItem: ReplaceCartItemSelector(store),
            replacementItem: ReplacementCartItemSelector(store),
            routeParams: RouteParams(store),
            routeState: RouteState(store),
            searchCouponCode: SearchCouponCodeSelector(store),
            selectedCustomerAddress: SelectedCustomerAddressSelector(store),
            selectedInventoryStore: SelectedInventoryStoreDetailsSelector(store),
            selectedInventoryName: SelectedInventoryNameSelector(store),
            selectedProductId: SelectedProductIdSelector(store),
            selectedServiceIdentifier: SelectedServiceIdentifierSelector(store),
            selectedTabIndex: SelectedTabIndexSelector(store),
            shippingAddressId: ProductOrderShippingAddressIdSelector(store),
            shippingMethodId: ProductOrderShippingMethodIdSelector(store),
            shippingMethods: ShippingMethodsViewModel(store),
            shipToName: ProductWizardTemporaryShipToNameSelector(store),
            shoppingCart: ShoppingCartSelector(store),
            shoppingCartIsDirty: ShoppingCartIsDirtySelector(store),
            showPreviousButton: ShowPreviousButtonSelector(store),
            skipToCheckout: SkipToCheckoutSelector(store),
            structureTypesLoaded: MetadataCodeLoadedSelector(CODES.StructureType, store),
            submitChangeOfServiceForServiceFeatureRequest: SubmitChangeOfServiceForServiceFeatureRequestSelector(store),
            submitChangeOfServiceForServiceFeatureWithoutQuantitiesRequest: SubmitChangeOfServiceForServiceFeatureWithoutQuantitiesRequestSelector(store),
            subscriptionError: SubscriptionErrorSelector(store),
            subscriptionQuotes: OrderSubscriptionQuotesAsMutableSelector(store),
            subscriberCategoryRequirements: SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault(store),
            subscriberRequirements: SubscriberRequirementsForCurrentBusinessUnitOrDefault(store),
            tabs: TabPanelViewModelSelector(store),
            waiveFee: IsProductWizardRestoreOrderWaivingFeeSelector(store),
            waiveFeeReason: ProductWizardRestoreOrderWaiveFeeReasonSelector(store),
            waiveLateFee: IsProductWizardRestoreOrderWaivingLateFeeSelector(store),
            waiveLateFeeReason: ProductWizardRestoreOrderWaiveLateFeeReasonSelector(store),
            wizardTitleCustomerOrService: WizardTitleCustomerOrServiceSelector(store),
            wizardTitleLocale: WizardTitleLocaleSelector(store)
        };
    };

    this.$onInit = () => {
        const controllerActions = {
            addMultipleAndUpdateShoppingCart,
            calculateChangeOfServiceOrderQuote,
            calculateModifyOrderQuote,
            calculateOrderQuote,
            calculateRenewOrderQuote,
            cancelOrder,
            clearCurrentSubscription,
            clearOrderQuote,
            editMultipleAndUpdateShoppingCart,
            goToNextStep,
            goToPreviousStep,
            goToStep,
            initializeReplaceOrder,
            registerUnsavedChanges,
            reinitializeProductOrder,
            removeFromAndUpdateShoppingCart,
            retrieveCandidateBillCycles,
            retrieveConvergentBillerAccountDetails,
            retrieveOrderQuote,
            retrieveSubscription,
            searchOrders,
            setCurrentStepIsInvalid,
            setEditOrderAdditionalProperty,
            setEditOrderAdditionalPropertyOnChange,
            setReloadSubscriberStateFlag,
            setReplaceItem,
            setSelectedProductId,
            setSelectedShippingMethodId,
            setSelectedTab,
            setShoppingCartItemIndex,
            submitGiftOrder,
            submitModifyOrder,
            submitOrder,
            submitRestoreOrder,
            submitServiceFeatureOrder,
            unregisterUnsavedChanges,
            updateServiceFeatureQuantityPromise,
            updateShoppingCartWithLatest
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.retrieveOrderQuoteTimestamp = null;

        this.customerCategory = CUSTOMER_CATEGORY,
        this.customerCareKeys = CustomerCareKeys;
        this.isServiceFeatureOrder = isServiceFeatureOrder(this.state.routeParams);
        this.wizardContinueClicked = false;

        this.selectOptionsPopupApi = null;
        this.taxAndGlValuesRequired = false;
        this.INVENTORY_CATEGORIES = INVENTORY_CATEGORIES;
        this.selectOptionsPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.selectOptionsPopupApi = api;
            }
        };

        this.editProfileInformationPopupConfig = {
            onRegisterApi: ({api: api}) => {
                this.editProfileInformationPopupApi = api;
            }
        };

        this.closeDialog = (() => {
            this.showOptionsModalPopup = false;
            this.selectOptionsPopupApi.close();
        }).bind(this);

        this.onOptionSelect = (id) => {
            if (this.state.isReplacingMode && this.state.cartTab.items.length) {
                this.openUpdateOrderConfirmation(id);
            } else {
                this.openProductModal(i18n.translate(CustomerCareKeys.PRODUCT_OPTIONS.OPTIONS), id);
            }
        };

        this.onQuickView = (id) => {
            this.openProductModal(i18n.translate(CustomerCareKeys.PRODUCT_OPTIONS.DETAILS), id);
        };

        this.openProductModal = (tabName, id) => {
            this.actions.setSelectedProductId(id);
            this.selectedTab = tabName;
            this.showOptionsModalPopup = true;
            $timeout(() => {
                this.selectOptionsPopupApi.open();
            });
        };

        this.centerSelectOptionsPopup = () => {
            this.selectOptionsPopupApi.center();
        };

        this.productSelected = ((shoppingCart, addedItems, isFailure, serviceFeatureModel) => {
            if (shoppingCart && !this.state.isCreatingMode) {
                const itemsInCart = !this.isServiceFeatureOrder ? addedItems.some((addedItem) => {
                    return shoppingCart.ShoppingCart.Items.some((cartItem) => {
                        return addedItem.pricingPlanId === cartItem.PricingPlanId &&
                               addedItem.productId === cartItem.ProductId && !!(cartItem.Quantity);
                    });
                }) : null;

                if (itemsInCart) {
                    uiNotificationService.warning(i18n.translate(CustomerCareKeys.CART.DUPLICATE_ITEM_WARNING));
                }
            }

            if (this.state.invalidItems.length) {
                this.state.invalidItems.forEach((invalidItem) => {
                    uiNotificationService.error(invalidItem.productName ?
                        i18n.translate(CustomerCareKeys.CART.ITEM_NOT_ADDED_NAMED, {
                            productName: invalidItem.productName
                        }) :
                        i18n.translate(CustomerCareKeys.CART.ITEM_NOT_ADDED_GENERIC));
                });
            }
            this.onProductSelected();

            if (this.isServiceFeatureOrder) {
                if (isFailure) {
                    this.actions.updateServiceFeatureQuantityPromise({
                        AddItems: pathOr([], ['shoppingCart', 'AddItems'],  this.serviceFeatureModel),
                        RemoveItems:  pathOr([], ['shoppingCart', 'RemoveItems'],  this.serviceFeatureModel)
                    });
                } else {
                    this.serviceFeatureModel = serviceFeatureModel;
                }
            }


        }).bind(this);

        if (!this.state.skipToCheckout) {
            clearStore(this.actions);
        }

        if (this.isServiceFeatureOrder) {
            this.actions.searchOrders(this.state.currentCustomer.Id);
            this.actions.retrieveConvergentBillerAccountDetails(this.state.currentCustomer.Id).then(() => {
                if (this.state.featureOrderQuantities && this.state.featureOrderQuantities.length && !this.state.shoppingCartIsDirty) {
                    const model = {
                        products: this.state.featureOrderQuantities,
                        customerId: this.state.currentCustomer.Id,
                        shoppingCart: this.state.shoppingCart,
                        redemptionCodes: []
                    };
                    this.actions.addMultipleAndUpdateShoppingCart(model, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS);
                }
            });
        }

        if (this.state.isReplacingMode) {
            this.initializeReplaceMode();
        } else if (this.state.isModifyingMode) {
            const cartItem = SubscriptionHelper.createSubscriptionCartItem(this.state.currentSubscription);
            this.actions.setReplaceItem(cartItem);
        } else if (this.state.isRestoringMode) {
            const order = new SubscriptionOrder(this.state).createOrder({
                useDefaults: false,
                calculateTaxes: false
            });
            this.actions.calculateRenewOrderQuote(order);
        }

        this.onEditCartItem = (cartItem) => {
            this.actions.setShoppingCartItemIndex(cartItem.index);
            this.openProductModal(i18n.translate(CustomerCareKeys.PRODUCT_OPTIONS.OPTIONS), cartItem.productId);
        };

        this.onEditedCartItem = (cartItem) => {
            if (!this.isServiceFeatureOrder && cartItem && this.state.shoppingCart && (this.state.shoppingCart.Items || []).find((item) => {
                return cartItem.pricingPlanId === item.PricingPlanId && cartItem.productId === item.ProductId && !!(item.Quantity);
            })) {
                uiNotificationService.warning(i18n.translate(CustomerCareKeys.CART.DUPLICATE_ITEM_WARNING));
            }

            this.actions.setSelectedProductId(null);
            this.calculateOrderQuote();
        };

        this.actions.registerUnsavedChanges('productsOrderWizard', {
            isDirty: () => {
                return this.state.cartHasItems;
            },
            setPristine: () => {
                this.handleCancel();
            }
        });

        this.stateOrName = CREATE_PRODUCTS_ORDER_STATE_NAME;

        // Submit Order Success Confirmation Popup
        this.orderConfirmationPopup = {
            onRegisterApi: (evt) => {
                this.orderConfirmationPopup = evt.api;
            }
        };

        // Submit Order Failure Error Popup
        this.submitOrderErrorPopup = {
            onRegisterApi: (evt) => {
                this.submitOrderErrorPopup = evt.api;
            }
        };

        this.repeatRetrieveQuote = (() => {
            asyncRepeatInterval(
                this.onRefreshQuote,
                () => {
                    return !this.state.isAsyncComplete && this.state.isCheckoutStep;
                }
            );
        }).bind(this);

        this.onRefreshQuote = (() => {
            return this.actions.retrieveOrderQuote(this.state.currentCustomer.Id, this.state.asyncId)
                .then((response) => {
                    this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                    return response;
                }).catch((error) => {
                    return this.uiNotificationService.transientError(error.translatedMessage);
                });
        }).bind(this);

        this.attemptNextStep = () => {
            this.wizardContinueClicked = true;

            if (this.wizardForm) {
                this.wizardForm.highlightErrorMessages();
            }

            if (this.state.currentStepIsInvalid || !this.state.cartHasItems || this.wizardForm.$invalid) {
                this.wizardForm.$setSubmitted();
                $log.warn('The current step has errors');
            } else if (this.state.isProductsStep) {
                this.wizardForm.$setPristine();
                this.actions.goToNextStep();
            } else if (this.state.isCheckoutStep) {
                // The outer wizard submit button doesn't live inside of a form. The original wizard was built
                // without an outer form but form(s) now wrap individual steps to make it backwards compatible.
                // As a result we have to manually set submitted.
                this.wizardForm.$setSubmitted();

                if (!this.isCheckoutStepInvalid()) {
                    this.submitOrder();
                }
            }
        };

        // Preview Renewal Statements Popup Dialog bits
        this._previewRenewalStatementsPopupApi = null;
        this.showPreviewRenewalStatementsDialog = false;
        this.previewRenewalStatementsPopupConfig = {
            onRegisterApi: (event) => {
                this._previewRenewalStatementsPopupApi = event.api;
            }
        };

        if (this.state.skipToCheckout) {
            this.deregisterUnsavedChanges();
            $timeout(() => {
                this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
            });
        }

        this.onOrderAdditionalPropertyListUpdated = () => {
            this.actions.setEditOrderAdditionalProperty(this.state.orderAdditionalPropertyListMap);
        };

        this.onOrderAdditionalPropertyChanged = (id, newValue) => {
            this.actions.setEditOrderAdditionalPropertyOnChange(id, newValue);
        };
    };

    this.isCheckoutStepInvalid = () => {
        return (this.wizardForm && this.wizardForm.$invalid)
            && (this.wizardForm['$ctrl.fdoExecutionOptionsForm'] && this.wizardForm['$ctrl.fdoExecutionOptionsForm'].$invalid);
    };

    this.calculateOrderQuote = () => {
        if (!this.isServiceFeatureOrder && !this.state.isCheckoutStep || !this.hasDataRequiredForOrderQuote()) {
            this.actions.clearOrderQuote();
            return;
        }
        if (!this.state.cartHasItems && this.state.isCheckoutStep) {
            this.goToPreviousStep();
            return;
        }

        // Skip SubscriptionOrder builder for DBSS, which will only need customer id and shopping cart items
        const order = this.state.isDbssShoppingCart ? {
            customerId: this.state.currentCustomer.Id,
            shoppingCart: this.state.shoppingCart
        } : this.state.isServiceFeatureShoppingCart ? {
            customerId: this.state.currentCustomer.Id,
            addItems: this.state.submitChangeOfServiceForServiceFeatureWithoutQuantitiesRequest.AddItems,
            removeItems: this.state.submitChangeOfServiceForServiceFeatureWithoutQuantitiesRequest.RemoveItems
        } : new SubscriptionOrder(this.state, false).createOrder();

        let calculateQuoteFn;

        if (this.state.locationCodes && this.state.locationCodes.length) {
            this.appendTaxAndGlPayload(order);
        }

        if (this.isServiceFeatureOrder) {
            calculateQuoteFn = this.actions.calculateChangeOfServiceOrderQuote;
            order.paymentInstrumentIds = this.state.paymentInstrumentIds;
        } else {
            if (this.state.isCreatingMode) {
                calculateQuoteFn = this.actions.calculateOrderQuote;
            } else if (this.state.isReplacingMode || this.state.isModifyingMode) {
                calculateQuoteFn = this.actions.calculateModifyOrderQuote;
            } else if (this.state.isRestoringMode) {
                calculateQuoteFn = this.actions.calculateRenewOrderQuote;
            }
        }

        if (calculateQuoteFn) {
            return calculateQuoteFn(order).then(() => {
                if (this.state.orderRequiresShipping) {
                    // COQ uses first payment method by default, we need to reflect this on client
                    if (!this.state.shippingMethodId && this.state.shippingMethods.length > 0) {
                        this.actions.setSelectedShippingMethodId(this.state.shippingMethods[0].Id);
                    }
                }
                if (this.state.asyncId) {
                    this.repeatRetrieveQuote();
                }
            });
        }
    };

    this.removeProductListItemSelected = (cartItem) => {
        const selectedProduct = this.state.productsList ? this.state.productsList.find((product) => {
            return product.Id === cartItem.productId;
        }) : null;

        const inShoppingCart = this.state.shoppingCart.Items && this.state.shoppingCart.Items.length ?
            this.state.shoppingCart.Items.find((item) => {
                return item.ProductId === cartItem.productId;
            }) : null;


        if (!inShoppingCart && selectedProduct) {
            selectedProduct.isSelected = false;
        }
    };

    this.updateShoppingCartWithCouponsAndCalculateOrderQuote = () => {
        if (!this.state.isReplacingMode && !this.state.isModifyingMode && !this.state.isRestoringMode) {
            const couponCodes = pluck('name', this.state.couponCodes);
            return this.actions.updateShoppingCartWithLatest({
                customerId: this.state.currentCustomer.Id,
                discretionaryDiscountIds: this.state.discretionaryDiscountIds,
                discretionaryDiscountReasonCode: pathOr(null, ['discretionaryDiscountReason', 'value'], this.state),
                redemptionCodes: couponCodes,
                shoppingCart: this.state.shoppingCart
            }, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS).then(() => {
                this.calculateOrderQuote();
            });
        } else {
            // we cannot call updateShoppingCart for replace, modify, and restore orders
            this.calculateOrderQuote();
        }
    };

    this.onRemoveCartItem = (cartItem) => {
        if (!this.state.isReplacingMode && !this.state.isModifyingMode && !this.state.isRestoringMode) {
            const couponCodes = pluck('name', this.state.couponCodes);
            if (this.isServiceFeatureOrder) {
                const existingQuantity = getExistingQuantities(cartItem.pricingPlanId, cartItem.productId, this.state.featureOrderQuantities);
                const pricingPlans = [
                    {
                        Id: cartItem.pricingPlanId,
                        cartQuantity: existingQuantity - cartItem.quantity
                    }
                ];
                this.state.shoppingCart.Items.forEach((shoppingCartItem) => {
                    if (cartItem.productId === shoppingCartItem.ProductId && cartItem.pricingPlanId !== shoppingCartItem.PricingPlanId) {
                        const cartItemToModify = {
                            Id: shoppingCartItem.PricingPlanId,
                            cartQuantity: 1
                        };
                        pricingPlans.push(cartItemToModify);
                    };
                });
                const product = this.state.productsList.find((prod) => {
                    return prod.Id === cartItem.productId;
                });
                const shoppingCart = processServiceFeatures(
                    cartItem.productId,
                    product,
                    pricingPlans,
                    this.state.shoppingCart,
                    this.state.featureOrderQuantities,
                    this.state.routeParams,
                    this.state.orders,
                    this.state.submitChangeOfServiceForServiceFeatureRequest
                );
                this.actions.updateServiceFeatureQuantityPromise({
                    AddItems: shoppingCart.AddItems,
                    RemoveItems: shoppingCart.RemoveItems
                });
                const model = {
                    products: [],
                    customerId: this.state.currentCustomer.Id,
                    shoppingCart: shoppingCart,
                    redemptionCodes:[]
                };
                this.serviceFeatureModel = model;
                return this.actions.editMultipleAndUpdateShoppingCart(model, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS).then(() => {
                    if (this.state.isCheckoutStep) {
                        this.calculateOrderQuote();
                    }
                    uiNotificationService.success(i18n.translate(itemInRemoveItems ? CustomerCareKeys.CART.ITEM_ADDED_BACK_TO_CART_SUCCESS : CustomerCareKeys.CART.ITEM_REMOVE_SUCCESS));
                });
            } else {
                return this.actions.removeFromAndUpdateShoppingCart({
                    index: cartItem.index,
                    customerId: this.state.currentCustomer.Id,
                    shoppingCart: this.state.shoppingCart,
                    redemptionCodes: couponCodes || []
                }, this.state.isDbssShoppingCart ? DBSS_UPDATE_CART_ADDITIONAL_OPTIONS : undefined).then(() => {
                    this.removeProductListItemSelected(cartItem);
                    this.calculateOrderQuote();
                });
            }
        }
    };

    this.orderConfirmationRedirect = () => {
        this.orderConfirmationPopup.close();
        this.deregisterUnsavedChanges();
        if (this.state.isModifyingMode && this.state.isPreviousRouteDashboard) {
            $state.go('index.customercare.customer.dashboard', {});
        } else if (this.state.isReplacingMode || this.state.isModifyingMode) {
            $state.go('index.customercare.customer.subscriptions.list');
        } else if (this.state.isRestoringMode) {
            $state.go(SUBSCRIPTION_DETAILS_ROUTE, {
                subscriptionId: this.state.currentSubscription.Id
            }, {
                reload: true
            });
        } else {
            $state.go('index.customercare.customer.dashboard', {});
        }
    };

    this.submitOrder = () => {
        if (uiNotificationService.numActiveToasts()) {
            uiNotificationService.clear();
        }
        const subscriptionOrder = this.state.isGiftOrder ? {
            discretionaryDiscountReasonCode: pathOr(null, ['id'], this.state.discretionaryDiscountReason),
            giftOrderInfo: this.state.giftOrderInfo,
        } : {
            discretionaryDiscountReasonCode: pathOr(null, ['id'], this.state.discretionaryDiscountReason),
        };
        // Skip SubscriptionOrder builder for DBSS, which will only need customer id and shopping cart items
        const order = this.state.isDbssShoppingCart ? {
            customerId: this.state.currentCustomer.Id,
            orderDetails: this.state.orderDetails,
            shoppingCart: this.state.shoppingCart,
            executionOptions: this.state.executionOptions
        } : this.state.isServiceFeatureShoppingCart ? {
            customerId: this.state.currentCustomer.Id,
            addItems: this.state.submitChangeOfServiceForServiceFeatureWithoutQuantitiesRequest.AddItems,
            removeItems: this.state.submitChangeOfServiceForServiceFeatureWithoutQuantitiesRequest.RemoveItems,
            executionOptions: this.state.executionOptions
        } : new SubscriptionOrder(this.state, true).createOrder(subscriptionOrder);

        if (this.state.orderAdditionalProperties.length) {
            order.additionalProperties = this.state.orderAdditionalProperties;
        }

        if (this.state.locationCodes && this.state.locationCodes.length) {
            this.taxAndGlValuesRequired = true;
            this.appendTaxAndGlPayload(order);
        }

        let submitOrderFn;

        if (this.isServiceFeatureOrder) {
            submitOrderFn = this.actions.submitServiceFeatureOrder;
        } else {
            if (this.state.isGiftOrder) {
                submitOrderFn = this.actions.submitGiftOrder;
            } else if (this.state.isCreatingMode) {
                submitOrderFn = this.actions.submitOrder;
            } else if (this.state.isRestoringMode) {
                submitOrderFn = this.actions.submitRestoreOrder;
            } else if (this.state.isReplacingMode || this.state.isModifyingMode) {
                submitOrderFn = this.actions.submitModifyOrder;
            }
        }

        if (submitOrderFn && !this.taxAndGlValuesRequired) {
            return submitOrderFn(order).then(() => {
                this.deregisterUnsavedChanges();
                this.orderConfirmationPopup.open();
                if (this.state.isCreatingMode) {
                    this.actions.setReloadSubscriberStateFlag(true);
                }
            }).catch((error) => {
                error.Code === convertStringToNumber(FaultCodeKeys.LITE_CUSTOMER_SUBMISSION_ERROR_CODE) ?
                    this.openEditProfileInformationPopup() : this.submitOrderErrorPopup.open();
            });
        } else if (this.taxAndGlValuesRequired) {
            const errorMessage = i18n.translate(CustomerCareKeys.TAX_AND_GL_LOCATION.SELECT_LOCATION_TOAST_MESSAGE);
            uiNotificationService.error(errorMessage, null, {
                timeOut: 0,
                tapToDismiss: true
            });
        }
    };

    this.goToPreviousStep = () => {
        const couponCodes = pluck('name', this.state.couponCodes);
        if (this.state.isCheckoutStep) {
            this.actions.clearOrderQuote();
            if (this.state.couponCodes && this.state.couponCodes.length) {
                if (!this.state.isReplacingMode) {
                    this.actions.updateShoppingCartWithLatest({
                        customerId: this.state.currentCustomer.Id,
                        shoppingCart: this.state.shoppingCart,
                        redemptionCodes: couponCodes || [],
                    }, this.state.isDbssShoppingCart ? DBSS_UPDATE_CART_ADDITIONAL_OPTIONS : undefined);
                }
            }
        }
        this.actions.goToPreviousStep();
        this.wizardContinueClicked = false;
    };

    this.stepSelected = () => {
        // Disabled per Kelly until it is worked in a future story
        // TODO: re-enable
        // this.actions.goToStep(selectedStep.name);
    };

    this.cancelClick = () => {
        if (!unsavedChangesPrompt.hasUnsavedChanges()) {
            this.handleCancel();
        }
    };

    this.tabSelected = (selectedTab) => {
        const selectedIndex = this.state.tabs.findIndex((tab) => {
            return tab === selectedTab;
        });

        this.actions.setSelectedTab(selectedIndex);
    };

    this.handleCancel = () => {
        if (this.state.skipToCheckout) {
            clearStore(this.actions);
        }

        this.actions.cancelOrder();
        this.deregisterUnsavedChanges();
    };

    this.deregisterUnsavedChanges = () => {
        this.actions.unregisterUnsavedChanges('productsOrderWizard');
    };

    this.closeOrderErrorPopup = () => {
        this.submitOrderErrorPopup.close();
    };

    this.shouldShowBackButton = () => {
        return this.state.skipToCheckout ? false : !this.state.isProductsStep;
    };

    this.hasDataRequiredForOrderQuote = () => {
        const hasPaymentInstruments = this.state.paymentInstrumentIds.length > 0 || this.state.paymentInstruments.length > 0;

        const hasShippingAddress = !!this.state.shippingAddressId;

        return (hasPaymentInstruments || !this.state.shoppingCart.PaymentInstrumentRequired) &&
            (hasShippingAddress || !this.state.orderRequiresShipping);
    };

    this.initializeReplaceMode = () => {
        if (!this.state.currentSubscription) {
            return this.actions.retrieveSubscription(this.state.currentCustomer.Id, {
                Id: this.state.currentSubscriptionId
            }).then(() => {
                const cartItem = SubscriptionHelper.createSubscriptionCartItem(this.state.currentSubscription, this.state.currentSubscriptionChildItemId);
                this.actions.initializeReplaceOrder(cartItem);
            }).catch(() => {
                if (this.state.subscriptionError) {
                    uiNotificationService.error(this.state.subscriptionError.message);
                }
            });
        } else {
            const cartItem = SubscriptionHelper.createSubscriptionCartItem(this.state.currentSubscription, this.state.currentSubscriptionChildItemId);
            this.actions.initializeReplaceOrder(cartItem);
        }
    };

    this.$onDestroy = () => {
        clearStore(this.actions);
        disconnectRedux();
    };

    this.handlePreviewRenewalStatements = () => {
        this._openPreviewRenewalStatementsPopup();
    };

    this.handleClosePreviewRenewalStatementsDialog = () => {
        this._closePreviewRenewalStatementsPopup();
    };

    this._openPreviewRenewalStatementsPopup = () => {
        this.showPreviewRenewalStatementsDialog = true;
        $timeout(this._previewRenewalStatementsPopupApi.open);
    };

    this._closePreviewRenewalStatementsPopup = () => {
        this.showPreviewRenewalStatementsDialog = false;
        this._previewRenewalStatementsPopupApi.close();
    };

    this.updateOrderPopupConfig = {
        onRegisterApi: ({api: api}) => {
            this.updateOrderPopupApi = api;
        }
    };

    this.openUpdateOrderConfirmation = (productId) => {
        this.productId = productId;
        $timeout(this.updateOrderPopupApi.open);
    };

    this.closeUpdateOrderPopup = (result) => {
        if (result) {
            this.openProductModal(i18n.translate(CustomerCareKeys.PRODUCT_OPTIONS.OPTIONS), this.productId);
        }
        this.updateOrderPopupApi.close();
    };

    this.onProductSelected = () => {
        if (this.state.isReplacingMode && this.state.isProductsStep) {
            this.attemptNextStep();
        } else if (this.state.isCheckoutStep) {
            this.calculateOrderQuote();
        }

    };

    this.getOrderNumber = () => {
        const createOrderNumber = path(['state', 'createdOrder', 'OrderNumber'], this);

        return createOrderNumber ? createOrderNumber :
            path(['state', 'removedOrder', 'OrderNumber'], this);
    };

    this.openEditProfileInformationPopup = () => {
        this.showCustomerPopup = true;
        $timeout(() => {
            this.editProfileInformationPopupApi.open();
        });
    };

    this.closeEditProfileInformationPopup = () => {
        this.showCustomerPopup = false;
        this.editProfileInformationPopupApi.close();
    };

    this.onSubmitEditCustomerForm = () => {
        this.showLoadingIndicator = true;
        this.closeEditProfileInformationPopup();
        uiNotificationService.transientSuccess(i18n.translate(CustomerCareKeys.PROFILE_PAGE.UPDATE_SUCCESS_MESSAGE));
    };

    this.appendTaxAndGlPayload = (order) => {
        let isCustomerAddressConfigured = false;
        let isStoreLocationConfigured = false;
        let isServiceIdentifierConfigured = false;
        if (this.state.locationCodes.length) {
            isCustomerAddressConfigured = this.state.locationCodes.includes(TAX_LOCATION_CONSTANTS.CUSTOMER_ADDRESS) && (this.state.selectedCustomerAddress !== null);
            isStoreLocationConfigured = this.state.locationCodes.includes(TAX_LOCATION_CONSTANTS.STORE_LOCATION) && (this.state.selectedInventoryStore !== null);
            isServiceIdentifierConfigured = this.state.locationCodes.includes(TAX_LOCATION_CONSTANTS.NPA_NXX) && (this.state.selectedServiceIdentifier !== null);
        }
        if (isCustomerAddressConfigured || isStoreLocationConfigured || isServiceIdentifierConfigured) {
            const taxAndGlPayload = Object.assign({}, isCustomerAddressConfigured && {
                AddressId: this.state.selectedCustomerAddress.addressId
            }, isStoreLocationConfigured && {
                StoreId: convertStringToNumber(this.state.selectedInventoryStore.Value)
            }, isServiceIdentifierConfigured && {
                ServiceIdentifierValue: this.state.selectedServiceIdentifier.SerialNumber
            });
            order.orderTaxLocation = taxAndGlPayload;
            if (this.state.locationCodes.length === Object.keys(taxAndGlPayload).length) {
                this.taxAndGlValuesRequired = false;
            }
        }
        return order;
    };
}

export default {
    template: require('./create.products.order.wizard.html'),
    controller: ProductsWizardController,
    controllerAs: 'productsController'
};
