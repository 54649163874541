import angular from 'angular';
import 'angular-hotkeys';
import 'invision-core';

import CancelRemovalModalComponent from './detail/cancelRemovalModal/cancel.removal.modal.component';
import DiscountsTabComponent from './detail/discounts/discounts.tab.component';
import InstancePropertiesTabComponent from './detail/instanceProperties/instance.properties.tab.component';
import ModifyModalComponent from './detail/modifyModal/modify.modal.component';
import PricingPlanHistoryTabComponent from './detail/pricingPlanHistory/pricing.plan.history.tab.component';
import RemoveDiscountModalComponent from './detail/discounts/remove.discounts.modal.component';
import RemoveExtensionConfirmationComponent from './detail/extensions/remove/confirmation/remove.extension.confirmation.component';
import RemoveSubscriptionComponent from './detail/remove/remove.subscription.component';
import RenewModalComponent from './detail/renewModal/renew.modal.component';
import SubscriptionsListComponent from './list/subscriptions.list.component';
import SubscriptionBundleComponent from './detail/subscription.bundle.component';
import SubscriptionDetailComponent from './detail/subscriptions.detail.component';
import SubscriptionExtensionComponent from './detail/extensions/subscription.extension.component';
import SubscriptionExtensionRemoveListPopupComponent from './detail/extensions/remove/list/subscription.extension.remove.list.popup.component';
import SubscriptionItemComponent from './detail/subscription.item.component';
import {routing as RoutingConfig} from './subscriptions.config';

export default angular.module('invision.customercare.customer.subscriptions', [
    'cfp.hotkeys',
    'invision.core.components'
])
    .component('cancelRemovalModal', CancelRemovalModalComponent)
    .component('discountsTab', DiscountsTabComponent)
    .component('instancePropertiesTab', InstancePropertiesTabComponent)
    .component('modifyModal', ModifyModalComponent)
    .component('pricingPlanHistoryTab', PricingPlanHistoryTabComponent)
    .component('removeDiscount', RemoveDiscountModalComponent)
    .component('removeExtensionConfirmation', RemoveExtensionConfirmationComponent)
    .component('removeSubscription', RemoveSubscriptionComponent)
    .component('renewModal', RenewModalComponent)
    .component('subscriptionsList', SubscriptionsListComponent)
    .component('subscriptionBundle', SubscriptionBundleComponent)
    .component('subscriptionDetail', SubscriptionDetailComponent)
    .component('subscriptionItem', SubscriptionItemComponent)
    .component('subscriptionExtension', SubscriptionExtensionComponent)
    .component('subscriptionExtensionRemoveListPopup', SubscriptionExtensionRemoveListPopupComponent)
    .config(RoutingConfig)
    .name;
