import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    CurrentBusinessUnitCurrencyCodeSelector,
    IsDbss
} from 'invision-core/src/components/session/businessunit.selectors';
import {
    fetchAdjustmentReasons,
    fetchCodeTypes,
    fetchCreditReasons
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {
    IsOfferFeatureEnabled,
    MetadataOptionsForCodeValuesSelector,
    MetadataCodeLoadedSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {IsARMInMaintenanceModeSelector} from 'invision-core/src/components/warnings/warnings.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    fetchInvoices,
    fetchDeposits
} from '../../../../../reducers/actions/customer.cases.actions';
import {
    retrieveChangeOfferEligibilityRules,
} from '../../../../../reducers/actions/customer.offering.actions';
import {
    CustomerDespositsSelector,
    CustomerInvoicesSelector,
    IsFetchingInvoicesSelector
} from '../../../../../reducers/selectors/customer.cases.selectors';
import {
    AutopayConfigurationAdditionalPropertiesSelector,
    IsAutoPayEnabledSelector
} from '../../../../../reducers/selectors/autopay.configuration.selectors';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentCustomerIdSelector,
    CurrentCustomerNameSelector,
    CurrentCustomerSelector,
    IsPIIDataAccessEnabledSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {IsChangeOfferEligibilityRulesLoaded} from '../../../../../reducers/selectors/customer.offerings.selectors';
import {
    ConvergentBillerIsInPromiseToPay,
    ConvergentBillerSubscriberRemainingWriteOffAmountSelector,
    ConvergentBillerSubscriberSummarySelector,
    CreditLimitBreachedSelector,
    CreditLimitSummarySelector,
    CurrentAccountSummarySelector,
    IsCurrentAccountPostpaidSelector,
    IsFetchingDepositsSelector,
    IsFetchingSubscriberSummarySelector,
    MostRecentInvoiceSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {IsFetchingOrdersSelector} from './../../../../../reducers/selectors/order.history.selectors';
import {
    retrieveConvergentBillerSubscriberSummary,
    retrieveConvergentBillerTreatmentDetails
} from '../../../../../reducers/actions/customer.convergent.biller.actions';
import {retrieveAndOpenConvergentBillerLedgerInvoice} from './../../../../../reducers/actions/customer.billing.actions';
import {searchOrders} from './../../../../../reducers/actions/order.history.actions';
import {createTableDepositsHeaders} from './../../../cases/list/list.selectors.helper';
import {CASE_CATEGORIES} from './../../../cases/list/case.constants';
import {CASES_DETAILS_STATE_REDIRECT} from '../../../cases/cases.config';
import MakePaymentConstants from './../../../makePayment/make.payment.constants';
import {IsFetchingInvoiceContentSelector} from '../../../../../reducers/selectors/customer.billing.selectors';
import {OfferEligibilityIdsSelector} from '../../../../../reducers/selectors/dashboard.selectors';
import {
    PaymentInstrumentsWithTypeNamesViewModel
} from '../../../../../reducers/selectors/customer.ewallet.selectors';
import {
    retrieveAvailablePaymentInstrumentTypes
} from '../../../../../reducers/actions/customer.ewallet.actions';
import {fetchCustomerInventory} from '../../../../../reducers/actions/customer.inventory.actions';
import {CanApplyCreditSelector} from '../../../../../reducers/selectors/apply.credit.modal.selectors';
import {
    ONE_TIME_MAKE_PAYMENT,
    DISPUTE_INVOICE
} from '../../../../../security.attributes';

class AccountBalanceController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            closeCreditRefundModal: this.closeCreditRefundModal.bind(this),
            hasMakePaymentAccess: this.hasMakePaymentAccess.bind(this),
            hasLatePayment: this.hasLatePayment.bind(this),
            onMoreActionsItemSelection: this.onMoreActionsItemSelection.bind(this),
            onSuccessfulCreditSubmission: this.onSuccessfulCreditSubmission.bind(this),
            uiNotificationService,
            zeroValueAmount: 0
        });
    }

    $onInit() {
        const controllerActions = {
            fetchAdjustmentReasons,
            fetchCodeType: fetchCodeTypes,
            fetchDeposits,
            fetchCreditReasons: fetchCreditReasons,
            fetchCustomerInventory,
            fetchInvoices,
            retrieveAndOpenConvergentBillerLedgerInvoice,
            retrieveChangeOfferEligibilityRules,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveConvergentBillerSubscriberSummary,
            retrieveConvergentBillerTreatmentDetails,
            searchOrders
        };
        const mapStateToTarget = (store) => {
            const accountSummary = CurrentAccountSummarySelector(store);
            const remainingWriteOffAmount = ConvergentBillerSubscriberRemainingWriteOffAmountSelector(store);
            const mostRecentInvoice = MostRecentInvoiceSelector(store);
            const userSecurityAttributes = UserSecurityAttributesSelector(store);
            const isAutoPayConfigured = accountSummary && accountSummary.AutoPayConfigured;
            const hasAutoPayFailed = accountSummary && accountSummary.HasAutoPayFailed;
            const moreMenuItems = this.getMoreMenuItems(mostRecentInvoice, accountSummary, userSecurityAttributes);
            return {
                adjustmentReasonsLoaded: MetadataCodeLoadedSelector(CODES.BalanceAdjustmentReason, store),
                adjustmentReasonOptions: MetadataOptionsForCodeValuesSelector(CODES.BalanceAdjustmentReason, store),
                accountSummary,
                autopayConfigurationsLoaded: MetadataCodeLoadedSelector(CODES.AutopayConfig, store),
                autopayConfigurations: AutopayConfigurationAdditionalPropertiesSelector(store),
                canApplyCredit: CanApplyCreditSelector(store),
                creditReasonsLoaded: MetadataCodeLoadedSelector(CODES.CreditReasons, store),
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentCustomerDeposits: CustomerDespositsSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomerName: CurrentCustomerNameSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                ewalletPaymentInstruments: PaymentInstrumentsWithTypeNamesViewModel(store),
                creditLimitSummary: CreditLimitSummarySelector(store),
                featureConfigurationsLoaded: MetadataCodeLoadedSelector(CODES.FeatureConfiguration, store),
                hasAutoPayFailed,
                hasPromiseToPay: ConvergentBillerIsInPromiseToPay(store),
                isAutoPayConfigured,
                isAutoPayEnabled: IsAutoPayEnabledSelector(store),
                isARMInMaintenanceModeEnabled: IsARMInMaintenanceModeSelector(store),
                isChangeOfferEligibilityRulesLoaded: IsChangeOfferEligibilityRulesLoaded(store),
                isDbss: IsDbss(store),
                isCreditLimitBreached: CreditLimitBreachedSelector(store),
                isCurrentAccountPostpaid: IsCurrentAccountPostpaidSelector(store),
                isFetchingInvoices: IsFetchingInvoicesSelector(store),
                isFetchingDeposits: IsFetchingDepositsSelector(store),
                isFetchingInvoiceContent: IsFetchingInvoiceContentSelector(store),
                isFetchingOrders: IsFetchingOrdersSelector(store),
                isFetchingAccountSummary: IsFetchingSubscriberSummarySelector(store),
                isItvOffersEnabled: IsOfferFeatureEnabled(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                invoices: CustomerInvoicesSelector(store),
                moreMenuItems,
                mostRecentInvoice,
                offerEligibilityIds: OfferEligibilityIdsSelector(store),
                periodTypesLoaded: MetadataCodeLoadedSelector(CODES.PeriodType, store),
                reasonOptions: MetadataOptionsForCodeValuesSelector(CODES.CreditReasons, store),
                remainingWriteOffAmount,
                subscriberSummary: ConvergentBillerSubscriberSummarySelector(store),
                userSecurityAttributes
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if ((!this.state.invoices || !this.state.invoices.length) && this.state.currentCustomer.ConvergentBillerId && !this.state.isFetchingInvoices) {
            this.actions.fetchInvoices(this.state.currentCustomerId);
        }
        if (this.state.currentCustomer.ConvergentBillerId) {
            if (this.state.hasPromiseToPay) {
                this.actions.retrieveConvergentBillerTreatmentDetails(this.state.currentCustomerId);
            }
            if (!this.state.isChangeOfferEligibilityRulesLoaded && this.state.offerEligibilityIds && this.state.offerEligibilityIds.length) {
                this.actions.retrieveChangeOfferEligibilityRules(this.state.currentCustomerId, this.state.offerEligibilityIds);
            }
        }

        if (!this.state.autopayConfigurationsLoaded) {
            this.actions.fetchCodeType(CODES.AutopayConfig);
        }

        if (!this.state.creditReasonsLoaded) {
            this.actions.fetchCreditReasons();
        }

        if (!this.state.adjustmentReasonsLoaded) {
            this.actions.fetchAdjustmentReasons();
        }

        if (!this.state.periodTypesLoaded) {
            this.actions.fetchCodeType(CODES.PeriodType);
        }

        this.customerCareKeys = CustomerCareKeys;
        this.DASHBOARD = MakePaymentConstants.MAKE_PAYMENT_TYPES.DASHBOARD;
        this.CASE_CATEGORIES = CASE_CATEGORIES;

        this.gridVisible = false;
        this.grid = this.gridSetup();

        this.depositsModalConfig = {
            onRegisterApi: (event) => {
                this.depositsModalConfigApi = event.api;
            }
        };

        this.applyCreditRefundModalConfig = {
            onRegisterApi: ({api}) => {
                this.applyCreditRefundModalApi = api;
            }
        };

        this.actions.retrieveAvailablePaymentInstrumentTypes({
            Currency: this.state.currentCustomerCurrencyCode || this.state.currentBusinessUnitCurrencyCode
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });

        this.caseModalProps = {
            isOpen: false,
            onClose: () => {
                this.caseModalProps.isOpen = false;
            },
            onSubmit: (caseDetails) => {
                this.caseModalProps.isOpen = false;
                this.uiNotificationService.success(i18n.translate(CustomerCareKeys.CASE.CREATED_SUCCESSFULLY));

                this.$state.go(CASES_DETAILS_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    caseId: caseDetails.Id.Value
                });
            },
            openCaseModal: () => {
                this.caseModalProps.disputeDetails.selectedInvoiceNumber = this.state.mostRecentInvoice?.InvoiceNumber;
                this.caseModalProps.isOpen = true;
            },
            disputeDetails: {
                selectedInvoiceNumber: this.state.mostRecentInvoice?.InvoiceNumber
            },
            caseTemplateId: CASE_CATEGORIES.INVOICE_DISPUTE,
            routeParams: {
                customerId: this.state.currentCustomerId
            }
        };
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    hasLatePayment() {
        return this.state?.accountSummary?.PostpaidDetails
            ? this.state.accountSummary.PostpaidDetails.LatePaymentEligible &&
                  this.state.accountSummary.PostpaidDetails
                      .LatePaymentAmountDue > 0 &&
                  this.state.accountSummary.PostpaidDetails.DueDate !== null &&
                  this.state.accountSummary.PostpaidDetails
                      .LatePaymentFeeAmount > 0
            : false;
    }

    gridSetup() {
        return {
            data: [],
            appScopeProvider: this,
            onRegisterApi: (event) => {
                this.gridApi = event.grid.api;
            },
            columnDefs: createTableDepositsHeaders()
        };
    }
    hasLastPayment() {
        return pathOr(false, ['accountSummary', 'PostpaidDetails', 'LastPaymentDate'], this.state);
    }
    viewDeposits() {
        this.depositsModalConfigApi.open();
        this.actions.searchOrders(this.state.currentCustomerId).then(() => {
            return this.actions.fetchDeposits(this.state.currentCustomerId);
        }).then(() => {
            this.grid.data = this.state.currentCustomerDeposits;
            this.gridVisible = true;
        });
    }
    onCloseDepositsPopup() {
        this.gridVisible = false;
        this.depositsModalConfigApi.close();
    }
    retrieveInvoiceContent() {
        if (this.state.mostRecentInvoice) {
            const data = {
                customerId: this.state.currentCustomerId,
                customerName: this.state.currentCustomerName,
                invoiceNumber: this.state.mostRecentInvoice.InvoiceNumber,
                request: {
                    InvoiceId: this.state.mostRecentInvoice.InvoiceId,
                    InvoiceNumber: this.state.mostRecentInvoice.InvoiceNumber,
                    IssueDate: this.state.mostRecentInvoice.IssueDate
                }
            };
            this.actions.retrieveAndOpenConvergentBillerLedgerInvoice(data);
        }
    }
    goToAutoPay() {
        this.$state.go('index.customercare.customer.autoPay', {
            accountNumber: this.$state.params.accountNumber
        });
    }

    goToAutoPayPostpaid() {
        this.$state.go('index.customercare.customer.configureAutopay', {
            accountNumber: this.$state.params.accountNumber
        });
    }

    showAutoPayLabel() {
        return this.state.isAutoPayEnabled && (this.state.isAutoPayConfigured || this.state.accountSummary)  && this.state.isCurrentAccountPostpaid;
    }

    autoPayConfiguredLabel() {
        const $filter = getFilterService();
        let substitutions = {};
        if (this.state.accountSummary.PostpaidDetails) {
            substitutions = {
                amount: this.state.accountSummary.PostpaidDetails.DueDate ?
                    $filter('invCurrency')(this.state.accountSummary.PostpaidDetails.Total, this.state.accountSummary.currencyCode) :
                    i18n.translate(CustomerCareKeys.NOT_APPLICABLE),
                date: $filter('localShortDate')(this.state.accountSummary.NextAutoPayDate)
            };
        }

        const suspended = this.state.accountSummary && this.state.accountSummary.AutoPaySuspended;
        const suspendedMessage = this.state.accountSummary.AutoPaySuspensionEndDate ?
            i18n.translate(CustomerCareKeys.AUTO_PAY.SUSPENDED_UNTIL, {
                date: $filter('localShortDate')(this.state.accountSummary.AutoPaySuspensionEndDate)
            }) :
            i18n.translate(CustomerCareKeys.AUTO_PAY.SUSPENDED);

        return suspended ? suspendedMessage : i18n.translate(CustomerCareKeys.AUTO_PAY.AUTO_PAY_ENABLE, substitutions);
    }

    latePaymentLabel() {
        const $filter = getFilterService();
        const latePayment = {
            dueAmount: $filter('invCurrency')(this.state.accountSummary.PostpaidDetails.LatePaymentAmountDue, this.state.accountSummary.PostpaidDetails.CurrencyCode),
            date: $filter('localShortDate')(this.state.accountSummary.PostpaidDetails.DueDate),
            latePaymentFee : $filter('invCurrency')(this.state.accountSummary.PostpaidDetails.LatePaymentFeeAmount, this.state.accountSummary.PostpaidDetails.CurrencyCode)
        };
        return i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.LATE_PAYMENT_FEE.LATE_PAYMENT_FEE_LABEL, latePayment);
    };

    onMoreActionsItemSelection(option) {
        switch (option.item.id) {
            case CustomerCareKeys.DISPUTE_INVOICE:
                this.caseModalProps.openCaseModal();
                break;
            case CustomerCareKeys.VIEW_DEPOSITS:
                this.viewDeposits();
                break;
            case CustomerCareKeys.TRANSACTION_DETAILS.APPLY_CREDIT:
                this.openCreditRefundModal();
                break;
            default:
                break;
        }
    }
    getMoreMenuItems(mostRecentInvoice, accountSummary, userSecurityAttributes) {
        const moreMenuItems = [];
        const hasDeposits = pathOr(0, ['PostpaidDetails', 'TotalDepositAmount'], accountSummary) > 0;

        if (hasAccess(userSecurityAttributes, DISPUTE_INVOICE) && mostRecentInvoice) {
            moreMenuItems.push({
                id: CustomerCareKeys.DISPUTE_INVOICE,
                title: i18n.translate(CustomerCareKeys.DISPUTE_INVOICE)
            });
        }
        if (hasDeposits) {
            moreMenuItems.push({
                id: CustomerCareKeys.VIEW_DEPOSITS,
                title: i18n.translate(CustomerCareKeys.VIEW_DEPOSITS)
            });
        }
        if (this.state && this.state.isDbss && this.state.canApplyCredit) {
            moreMenuItems.push({
                id: CustomerCareKeys.TRANSACTION_DETAILS.APPLY_CREDIT,
                title: i18n.translate(CustomerCareKeys.TRANSACTION_DETAILS.APPLY_CREDIT)
            });
        }
        return moreMenuItems;
    }

    openCreditRefundModal() {
        this.showCreditRefundModal = true;
        this.additionalApiData = {
            AccountNumber: this.state.accountSummary.AccountNumber
        };
        this.$timeout(this.applyCreditRefundModalApi.open);
    }

    closeCreditRefundModal() {
        this.showCreditRefundModal = false;
        this.applyCreditRefundModalApi.close();
    }

    onSuccessfulCreditSubmission() {
        this.uiNotificationService.transientSuccess(i18n.translate(CustomerCareKeys.TRANSACTION_DETAILS.SUCCESSFUL_REFUND));
        this.closeCreditRefundModal();
        this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId, true);
    }

    hasMakePaymentAccess() {
        return (this.state.isCurrentAccountPostpaid || this.isARCSubscriptionMgmtFeatureToggle) &&
            !this.state.currentCustomerIsExternallyManagedAR &&
            hasAccess(this.state.userSecurityAttributes, ONE_TIME_MAKE_PAYMENT);
    }

}

export default {
    template: require('./account.balance.html'),
    controller: AccountBalanceController,
    bindings: {
        isArcSubscriptionFeatureToggle :'<'
    },
    controllerAs: 'AccountBalance'
};
