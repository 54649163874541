import {createSelector} from 'reselect';
import {MetadataConstants, MetadataSelectors} from 'invision-core';

export const IsLoadedSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.OfferingFacet),
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.OfferingFacetCategory)
    ],
    (facetLoaded, facetCategoryLoaded) => {
        return facetCategoryLoaded && facetLoaded;
    }
);