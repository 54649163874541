import {
    stateGo
} from 'redux-ui-router';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import * as CustomerSelectors from '../../../reducers/selectors/customer.selectors';
import * as CustomerDevicesSelector from '../../../reducers/selectors/customer.devices.selectors';
import CustomerCareLocaleKeys from '../../../locales/keys';
import * as CustomerDevicesActions from '../../../reducers/actions/customer.devices.actions';
import {DEVICE_MANAGEMENT_ACCESS} from '../../../security.attributes';

const mapStateToTarget = (store) => {
    return {
        currentCustomerId: CustomerSelectors.CurrentCustomerIdSelector(store),
        currentDevices: CustomerDevicesSelector.CurrentDevicesSelector(store),
        currentFormattedDevices: CustomerDevicesSelector.FormattedDevicesSelector(store),
        currentParams: CustomerSelectors.RouteParams(store),
        currentRoute: CustomerSelectors.RouteState(store),
        deviceCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.DeviceType, store),
        deviceErrors: CustomerDevicesSelector.DeviceErrorsSelector(store),
        deviceForm: CustomerDevicesSelector.DeviceFormSelector(store),
        formError: CustomerDevicesSelector.DeviceFormErrorSelector(store),
        includeRemoved: CustomerDevicesSelector.IncludeRemovedSelector(store),
        isRegisteringDevice: CustomerDevicesSelector.IsRegisteringDevicesSelector(store),
        isFetchingDevices: CustomerDevicesSelector.IsFetchingDevicesSelector(store),
        isRemovingDevice: CustomerDevicesSelector.IsRemovingDeviceSelector(store),
        isUpdatingDevice: CustomerDevicesSelector.IsUpdatingDevicesSelector(store),
        physicalDeviceCodesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PhysicalDeviceType, store),
        remainingAssociations: CustomerDevicesSelector.RemainingDeviceAssociations(store),
        selectedDevice: CustomerDevicesSelector.FormattedSelectedDeviceSelector(store),
        userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
    };
};

const controllerActions = {
    clearDeviceForm: CustomerDevicesActions.clearDeviceForm,
    retrieveCustomerDevices: CustomerDevicesActions.retrieveCustomerDevices,
    fetchCodeType: MetadataActions.codes.fetchCodeTypes,
    registerDevice: CustomerDevicesActions.registerDevice,
    removeDevice: CustomerDevicesActions.removeDevice,
    setIncludedFilter: CustomerDevicesActions.setIncludedFilter,
    setDeviceForm: CustomerDevicesActions.setDeviceForm,
    setSelectedDevice: CustomerDevicesActions.setSelectedDevice,
    stateGo,
    updateDevice: CustomerDevicesActions.updateDevice
};

class CustomerDevicesController {
    constructor($ngRedux, uiNotificationService, $timeout) {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.localeKeys = CustomerCareLocaleKeys;

        this.labels = {
            registerSuccess: i18n.translate(this.localeKeys.DEVICE.REGISTER_SUCCESS),
            removeSuccess: i18n.translate(this.localeKeys.DEVICE.REMOVE_SUCCESS),
            updateSuccess: i18n.translate(this.localeKeys.DEVICE.UPDATE_SUCCESS)
        };

        this.unlimitedAssociations = i18n.translate(this.localeKeys.DEVICE.UNLIMITED);
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.retrieveCurrentDevices();

        if (!this.state.deviceCodesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.DeviceType);
        }

        if (!this.state.physicalDeviceCodesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PhysicalDeviceType);
        }

        this.registerDevicePopUp = {
            onRegisterApi: (evt) => {
                this.registerDevicePopUp = Object.assign({}, evt.api, {
                    open: () => {
                        this.showRegisterForm = true;

                        this.$timeout(() => {
                            evt.api.open();
                        });
                    },
                    close: () => {
                        this.showRegisterForm = false;
                        evt.api.close();
                    }
                });
            }
        };

        this.removeDevicePopUp = {
            onRegisterApi: (evt) => {
                this.removeDevicePopUp = evt.api;
            }
        };

        this.updateDevicePopUp = {
            onRegisterApi: (evt) => {
                this.updateDevicePopUp = Object.assign({}, evt.api, {
                    open: () => {
                        this.showUpdateForm = true;

                        this.$timeout(() => {
                            evt.api.open();
                        });
                    },
                    close: () => {
                        this.showUpdateForm = false;
                        evt.api.close();
                    }
                });
            }
        };
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    closeRegisterDevicePopUp() {
        this.registerDevicePopUp.close();
        this.actions.clearDeviceForm();
    }

    closeRemoveDevicePopUp() {
        this.removeDevicePopUp.close();
    }

    closeUpdateDevicePopUp() {
        this.updateDevicePopUp.close();
        this.actions.clearDeviceForm();
    }

    get isLoading() {
        return this.state.isFetchingDevices || !this.state.deviceCodesLoaded || !this.state.physicalDeviceCodesLoaded ||
                this.state.isRemovingDevice;
    }

    onDeviceFormChange(deviceForm) {
        this.actions.setDeviceForm(deviceForm);
    }

    openRegisterDevicePopUp() {
        this.registerDevicePopUp.open();
    }

    openUpdateDevicePopUp() {
        this.onDeviceFormChange(this.state.selectedDevice);
        this.updateDevicePopUp.open();
    }

    onRemoveDevice(result) {
        this.closeRemoveDevicePopUp();

        if (result) {
            return this.actions.removeDevice(this.state.currentCustomerId, this.state.selectedDevice.DeviceId).then(() => {
                this.uiNotificationService.success(this.labels.removeSuccess, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });

                this.retrieveCurrentDevices();
            }).catch(() => {
                if (this.state.deviceErrors) {
                    this.uiNotificationService.error(this.state.deviceErrors.message, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                }
            });
        }
    }

    openRemoveDevicePopUp() {
        this.removeDevicePopUp.open();
    }

    registerDevice() {
        return this.actions.registerDevice(this.state.currentCustomerId, this.state.deviceForm).then((response) => {
            this.uiNotificationService.success(this.labels.registerSuccess, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });

            this.closeRegisterDevicePopUp();
            return this.retrieveCurrentDevices().then(() => {
                this.actions.setSelectedDevice(response.PhysicalDevice);
            });
        });
    }

    retrieveCurrentDevices() {
        return this.actions.retrieveCustomerDevices(this.state.currentCustomerId, this.state.includeRemoved)
            .then(() => {
                if (!this.state.selectedDevice) {
                    this.actions.setSelectedDevice(this.state.currentDevices[0]);
                }
            }).catch(() => {
                if (this.state.deviceErrors) {
                    this.uiNotificationService.error(this.state.deviceErrors.message, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                }
            });
    }

    onDeviceSelected(device) {
        this.actions.setSelectedDevice(device);
    }

    remainingAssociations() {
        return Number.isInteger(this.state.remainingAssociations) ?
            this.state.remainingAssociations : this.unlimitedAssociations;
    }

    onIncludeRemovedChange() {
        this.actions.setIncludedFilter(!this.state.includeRemoved);
        this.retrieveCurrentDevices();
    }

    updateDevice() {
        return this.actions.updateDevice(this.state.currentCustomerId, this.state.deviceForm).then(() => {
            this.uiNotificationService.success(this.labels.updateSuccess, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });

            this.closeUpdateDevicePopUp();
            this.retrieveCurrentDevices();
        });
    }

    get userCanEdit() {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, DEVICE_MANAGEMENT_ACCESS);
    }
}

export default {
    template: require('./devices.html'),
    controller: CustomerDevicesController
};
