import clone from 'ramda/src/clone';
import pick from 'ramda/src/pick';

import {stateGo} from 'redux-ui-router';

import i18n from 'invision-core/src/components/i18n/i18n';
import {AngularFormControllerErrorAdapter} from 'invision-core';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';

import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {
    getMemberBySubscriberId,
    sortMemberListByAlpheticalOrder
} from '../../household/household.helpers';

import {retrieveConvergentBillerSubscriberSummary} from '../../../../reducers/actions/customer.convergent.biller.actions';
import {
    retrieveCustomerHouseholds,
    setSelectedHouseholdMember,
    updateHouseholdServicesAssociations
} from '../../../../reducers/actions/customer.household.actions';

import {CurrentCustomerIdSelector} from '../../../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerSubscriberSummaryServicesSelector,
    IsFetchingSubscriberSummarySelector
} from '../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    CanMakeHouseholdChangesSelector,
    CurrentHouseholdSelector,
    HeadOfHouseholdSelector,
    HouseholdErrorSelector,
    HousholdMembersSortedForServiceAssociation,
    HouseholdServicesAssociations,
    IsFetchingHouseholdSelector,
    IsUpdatingHouseholdServicesAssociationsSelector
} from '../../../../reducers/selectors/customer.household.selectors';

import {HOUSEHOLD_STATE_NAME} from '../household.constants';

import CustomerCareLocaleKeys from '../../../../locales/keys';
import {NA} from '../../../../reducers/constants/billing.payments.constants';

class AssociateServicesController {
    constructor($ngRedux, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.associateServicesFormErrors = [];
        this.ASSOCIATE_SERVICES_FIELDNAME_PREFIX = 'service_association';
        this.ASSOCIATE_SERVICES_TRANSLATED_FIELD_LABEL = i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.SERVICE);
        this.currentHouseholdMembers = [];
        this.currentHouseholdServicesAssociations = [];
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.HOUSEHOLD_STATE_NAME = HOUSEHOLD_STATE_NAME;
        this.stateOrName = 'index.customercare.customer.associateservices';
        this.uiNotificationService = uiNotificationService;
        this.NA = NA;
        this.handleHouseholdAction = this.handleHouseholdAction.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                canMakeChanges: CanMakeHouseholdChangesSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentHousehold: CurrentHouseholdSelector(store),
                currentHouseholdMembers: HousholdMembersSortedForServiceAssociation(store),
                currentHouseholdServicesAssociations: HouseholdServicesAssociations(store),
                headOfHousehold: HeadOfHouseholdSelector(store),
                householdError: HouseholdErrorSelector(store),
                isFetchingHouseholds: IsFetchingHouseholdSelector(store),
                isFetchingSubscriberSummary: IsFetchingSubscriberSummarySelector(store),
                isUpdatingHouseholdServicesAssociations: IsUpdatingHouseholdServicesAssociationsSelector(store),
                regularExpressionLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                serviceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                subscriberServices: ConvergentBillerSubscriberSummaryServicesSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeType: fetchCodeTypesThunk,
            retrieveConvergentBillerSubscriberSummary,
            retrieveCustomerHouseholds,
            setSelectedHouseholdMember,
            stateGo,
            updateHouseholdServicesAssociations
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.createMemberPopupConfig = {
            onRegisterApi: (event) => {
                this.createMemberPopupApi = event.api;
            }
        };

        this.notifyUserWithMessage = (message) => {
            this.uiNotificationService.success(message, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        };

        if (this.state.subscriberServices) {
            this.retrieveCustomerHousehold();
        } else {
            this.actions.retrieveConvergentBillerSubscriberSummary(this.state.currentCustomerId)
                .then(() => {
                    this.retrieveCustomerHousehold();
                });
        }

        this.saveHouseholdMemberServiceAssociationsSuccessCallback = () => {
            this.associateServicesForm.$setPristine();

            this.actions.stateGo(HOUSEHOLD_STATE_NAME);

            this.uiNotificationService.success(i18n.translate(this.CustomerCareLocaleKeys.HOUSEHOLD.UPDATE_HOUSEHOLD_SERVICES_ASSOCIATIONS_SUCCESS), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        };

        this.saveHouseholdMemberServiceAssociationsErrorCallback = () => {
            this.uiNotificationService.error(this.state.householdError.message, null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
        };

        if (!this.state.serviceAttributesLoaded) {
            this.actions.fetchCodeType(CODES.ServiceAttribute);
        }

        if (!this.state.regularExpressionLoaded) {
            this.actions.fetchCodeType(CODES.RegularExpression);
        }
    }

    householdNeedsToBeFetched() {
        return !this.state.isFetchingHouseholds && (!this.state.currentHousehold || !this.state.currentHouseholdMembers || !this.state.headOfHousehold);
    }

    showMemberPopup() {
        this.createMemberPopupApi.open();
    }

    retrieveCustomerHousehold() {
        if (this.householdNeedsToBeFetched()) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false)
                .then(() => {
                    this.retrieveCurrentHouseholdMembers();
                    this.retrieveCurrentHouseholdServicesAssociations();
                    this.redirectNoServiceAssociations();
                });
        } else {
            this.retrieveCurrentHouseholdMembers();
            this.retrieveCurrentHouseholdServicesAssociations();
        }
    }

    redirectNoServiceAssociations() {
        if (this.state.currentHouseholdServicesAssociations.length === 0) {
            this.actions.stateGo(this.HOUSEHOLD_STATE_NAME);
        }
    }

    retrieveCurrentHouseholdMembers() {
        this.currentHouseholdMembers = clone(this.state.currentHouseholdMembers);

        this.currentHouseholdMembers = sortMemberListByAlpheticalOrder(this.currentHouseholdMembers, this.state.headOfHousehold, true);
    }

    retrieveCurrentHouseholdServicesAssociations() {
        this.currentHouseholdServicesAssociations = (clone(this.state.currentHouseholdServicesAssociations)).map((currentHouseholdServicesAssociation) => {
            return Object.assign(currentHouseholdServicesAssociation, {
                serviceFieldName: `${this.ASSOCIATE_SERVICES_FIELDNAME_PREFIX}-${currentHouseholdServicesAssociation.ServiceIdentifier}`
            });
        });
    }

    clearForm() {
        this.associateServicesForm.$setPristine();

        this.actions.stateGo(this.HOUSEHOLD_STATE_NAME);
    }

    updateHouseholdMemberServiceAssociations(service) {
        if (!service.SubscriberId) {
            service.SubscriberId = this.state.headOfHousehold.SubscriberId;
        }

        this.currentHouseholdMembers = this.currentHouseholdMembers.map((householdMember) => {
            const associatedServices = this.currentHouseholdServicesAssociations
                .filter((serviceAssociation) => {
                    return serviceAssociation.SubscriberId === householdMember.SubscriberId;
                })
                .map((serviceAssociation) => {
                    return pick(['ServiceFriendlyName', 'ServiceIdentifier'], serviceAssociation);
                });

            return Object.assign(householdMember, {
                services: associatedServices
            });
        });
    }

    handleHouseholdAction(message, memberId) {
        this.uiNotificationService.success(message, null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.retrieveHouseholds()
            .then(() => {
                this.setSelectedMember(getMemberBySubscriberId(this.state.currentHousehold.Members, memberId));
            });
    }

    retrieveHouseholds() {
        const INCLUDE_REMOVED = true;
        return this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, INCLUDE_REMOVED);
    }

    setSelectedMember(member) {
        this.actions.setSelectedHouseholdMember((!member || member.isOverview) ? null : member.SubscriberId);
    }

    buildDynamicFormFieldNames() {
        const dynamicFormFieldNames = {};

        this.currentHouseholdServicesAssociations.forEach((currentHouseholdServicesAssociation) => {
            dynamicFormFieldNames[currentHouseholdServicesAssociation.serviceFieldName] = `${this.ASSOCIATE_SERVICES_TRANSLATED_FIELD_LABEL} "${currentHouseholdServicesAssociation.ServiceIdentifier}"`;
        });

        return dynamicFormFieldNames;
    }

    isLoading() {
        return this.state.isFetchingSubscriberSummary || this.state.isUpdatingHouseholdServicesAssociations;
    }

    saveHouseholdMemberServiceAssociations() {
        if (this.associateServicesForm.$valid) {
            const formattedServicesAssociations = this.currentHouseholdServicesAssociations.map((currentHouseholdServicesAssociation) => {
                return {
                    ServiceIdentifier: {
                        Value: currentHouseholdServicesAssociation.ServiceIdentifier,
                        FriendlyName: currentHouseholdServicesAssociation.ServiceFriendlyName
                    },
                    SubscriberId: currentHouseholdServicesAssociation.SubscriberId
                };
            });
            const data = {
                headOfHouseholdSubscriberId: this.state.currentHousehold.HeadOfHouseholdSubscriberId,
                householdServicesAssociations: formattedServicesAssociations,
                subscriberId: this.state.currentCustomerId
            };

            this.actions.updateHouseholdServicesAssociations(data)
                .then(this.saveHouseholdMemberServiceAssociationsSuccessCallback)
                .catch(this.saveHouseholdMemberServiceAssociationsErrorCallback);
        } else {
            this.associateServicesFormErrors = AngularFormControllerErrorAdapter(this.associateServicesForm, this.buildDynamicFormFieldNames());
        }
    }

    showFormErrors(fieldName) {
        return this.associateServicesForm[fieldName].$invalid;
    }
}

export default {
    template: require('./associate.services.template.html'),
    bindings: {},
    controllerAs: 'AssociateServicesController',
    controller: AssociateServicesController
};
