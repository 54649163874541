import {i18n} from 'invision-core';
import LocaleKeys from '../../locales/keys';

export const ACCEPTING_FOR = {
    CHILD: 'child',
    MYSELF: 'myself'
};

export const CONSENT_TYPES = {
    MANDATORY: 'mandatory',
    OPTIONAL: 'optional'
};

export const CUSTOMER_CATEGORY = {
    RESIDENTIAL : 0,
    COMMERCIAL : 1
};

export const INVOICE_DELIVERY_METHODS = {
    INVOICE: '12'
};

export const CustomerCategories = () => {
    return [{
        id: CUSTOMER_CATEGORY.RESIDENTIAL,
        value: i18n.translate(LocaleKeys.RESIDENTIAL)
    }, {
        id: CUSTOMER_CATEGORY.COMMERCIAL,
        value: i18n.translate(LocaleKeys.COMMERCIAL)
    }];
};

export const NO_SUBTENANTS = '0';

export const HierarchyNodeType = {
    REGULAR: 1,
    INHERITED: 2
};

export const SUBSCRIBER_ADJUSTMENT_DUE_DAYS_LIMITS = {
    min: -1,
    max: 1000
};

export const SUBSCRIBER_PAYMENT_DUE_DAYS_LIMITS = {
    min: 0,
    max: 1000
};

export const ADJUSTMENT_DUE_DAYS = 'adjustment_due_days';
export const PAYMENT_DUE_DAYS = 'payment_due_days';

export const OPT_OUT_OPTIONS = {
    OPT_OUT_EMAIL: 1,
    OPT_OUT_SMS: 2
};
