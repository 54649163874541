import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import {createSelector} from 'reselect';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import Immutable from 'seamless-immutable';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    OfferingFacetCategorySelector,
    OfferingFacetSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {MetadataCodeTypeSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CompletedStepsSelector,
    CurrentStepIndexSelector,
    CurrentStepSelector,
    EditAttributeGroupsSelector,
    EditCustomerInfoSelector,
    EditPhysicalAttributeGroupsSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferIdSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    IsCheckoutStepSelector,
    IsCustomerStepSelector,
    IsFetchingBillCyclesSelector,
    IsFetchingDataSelector,
    IsServiceabilityStepSelector,
    OfferQuoteSelector,
    PostQuoteDueTotalsSelector,
    SkippedStepSelector,
    TotalDownPaymentSelector
} from '../../../reducers/selectors/new.connect.wizard.selectors';
import {
    IsFetchingSelector,
    IsPostpaidEnabledSelector,
    IsPrepaidEnabledSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    OfferingOrderDataSelector,
    OffersSelector,
    QuoteCalculatedSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector
} from '../../../reducers/selectors/offering.order.selectors';
import {
    SelectedContractSelector
} from '../../../reducers/selectors/contract.selectors';
import {
    AttributeFormSubmittedSelector,
    CurrentAttributeFormName,
    CurrentAttributesValidationStatuses,
    HasBulkShoppingCartItem,
    InventoryAvailabilitySelector,
    IsUpdatingCartSelector,
    PagesIsFetchingDataSelector,
    ShoppingCartSelector,
    ShoppingCartHaveOnlyStandaloneItemsSelector,
    SubmittedOrderErrorSelector
} from '../../../reducers/selectors/selected.offering.order.selectors';
import {addressStateRegionProvinceValueOptionsForCountry} from '../../../reducers/helpers/customer.selectors.helpers';
import {ALL_STEPS_KEYS} from '../../../reducers/constants/new.connect.wizard.constants';
import {
    AllAttributesAreValidHelper,
    CurrentActiveAttributeNameHelper,
    FilteredFormAttributeGroupsHelper,
    FilteredPhysicalAttributeGroupsHelper,
    FormAttributeGroupsHelper,
    FormAttributeGroupsNavigationHelper,
    FormPhysicalAttributeGroupsHelper,
    GetFinancedProductGroupInCartSelector,
    GetPreQuoteDueSubTotalsSelector,
    GetSummaryTabFinancedGroupSelector,
    GroupedCheckboxFilterOptions,
    HasRequiredAndUnavailableInventory,
    OfferOptionViewModel,
    ShippingAddressDropdownOptionsWizardSelector,
    ShippingAddressWizardSelector,
    ShowNextButtonHelper,
    ShowPreviousButtonHelper,
    SummaryTab,
    UnavailablePhysicalInventory
} from '../../../reducers/helpers/offer.ordering.wizard.selector.helper';
import {
    BackButtonText,
    ForwardButtonText,
    StepProgressBarViewModel
} from '../../../reducers/helpers/wizard.selector.helper';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';

export const StepProgressBarViewModelSelector = StepProgressBarViewModel(
    [
        CurrentStepSelector,
        CompletedStepsSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const BackButtonTextSelector = BackButtonText(
    [
        CurrentStepIndexSelector,
        CompletedStepsSelector
    ], ALL_STEPS_KEYS
);

export const ForwardButtonTextSelector = ForwardButtonText(
    [
        CurrentStepIndexSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const GroupedCheckboxFilterOptionsSelector = GroupedCheckboxFilterOptions([
    OfferingFacetCategorySelector,
    OfferingFacetSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    IsPostpaidEnabledSelector,
    IsPrepaidEnabledSelector,
    () => {
        return false;
    }
]);

export const OfferOptionViewModelSelector = OfferOptionViewModel([OffersSelector, EditSelectedOfferIdSelector]);

export const FormAttributeGroups = FormAttributeGroupsHelper([EditAttributeGroupsSelector]);
export const FormPhysicalAttributeGroups = FormPhysicalAttributeGroupsHelper([EditPhysicalAttributeGroupsSelector]);

export const FilteredPhysicalAttributeGroups = FilteredPhysicalAttributeGroupsHelper([FormAttributeGroups, FormPhysicalAttributeGroups, CurrentAttributeFormName]);
export const AttributeServiceSelector = FormAttributeGroupsNavigationHelper([
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    CurrentAttributeFormName,
    AttributeFormSubmittedSelector,
    CurrentAttributesValidationStatuses,
    SubmittedOrderErrorSelector
]);
export const CurrentActiveAttributeNameSelector = CurrentActiveAttributeNameHelper([AttributeServiceSelector]);
export const ShowPreviousButtonSelector = ShowPreviousButtonHelper([AttributeServiceSelector]);
export const ShowNextButtonSelector = ShowNextButtonHelper([AttributeServiceSelector]);
export const FilteredFormAttributeGroups = FilteredFormAttributeGroupsHelper([FormAttributeGroups, AttributeServiceSelector]);
export const AllAttributesAreValidSelector = AllAttributesAreValidHelper([AttributeServiceSelector, CurrentAttributesValidationStatuses]);

export const UnavailablePhysicalInventorySelector = UnavailablePhysicalInventory([EditPhysicalAttributeGroupsSelector, InventoryAvailabilitySelector]);
export const HasRequiredAndUnavailableInventorySelector = HasRequiredAndUnavailableInventory([FormAttributeGroups]);

export const StateRegionProvinceValueOptions = createSelector(
    [
        EditCustomerInfoSelector,
        MetadataCodeTypeSelector(CODES.AddressCountry),
        MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
    ],
    (customerInfo, countries, states) => {
        return addressStateRegionProvinceValueOptionsForCountry(customerInfo.HomeCountry, countries, states);
    }
);

export const StatesByCountrySelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.AddressCountry),
        MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
    ],
    (countries, states) => {
        const mappedStates = [];
        countries.forEach((country) => {
            mappedStates[country.Value] = addressStateRegionProvinceValueOptionsForCountry(country.Value, countries, states);
        });

        return mappedStates;
    }
);

export const DriversLicenseStateRegionProvinceValueOptions = createSelector(
    [
        EditCustomerInfoSelector,
        MetadataCodeTypeSelector(CODES.AddressCountry),
        MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
    ],
    (customerInfo, countries, states) => {
        return addressStateRegionProvinceValueOptionsForCountry(customerInfo.HomeCountry, countries, states);
    }
);

export const PreQuoteDueSubTotalsSelector = GetPreQuoteDueSubTotalsSelector([ShoppingCartSelector]);

export const SummaryTabViewModel = SummaryTab([
    ShoppingCartSelector,
    SubTotalAmountSelector,
    TaxAmountSelector,
    TotalAmountSelector,
    QuoteCalculatedSelector,
    SelectedContractSelector,
    () => {
        return undefined;
    },
    () => {
        return undefined;
    },
    TotalDownPaymentSelector,
    PostQuoteDueTotalsSelector,
    PreQuoteDueSubTotalsSelector,
    () => {
        return undefined;
    },
    () => {
        return undefined;
    }
]);

const SummaryTabFinancedGroupSelector = GetSummaryTabFinancedGroupSelector([SummaryTabViewModel]);

export const FinancedItemsForDownpaymentSelector = createSelector(
    [SummaryTabFinancedGroupSelector],
    (financedProductGroup) => {
        return pathOr([], ['products'], financedProductGroup);
    }
);

export const FinancedProductGroupInCartSelector = GetFinancedProductGroupInCartSelector([
    FinancedItemsForDownpaymentSelector,
    OfferQuoteSelector
]);

export const IsSaveOrderButtonDisabledSelector = createSelector(
    [ShoppingCartSelector, ShoppingCartHaveOnlyStandaloneItemsSelector],
    (shoppingCart, shoppingCartHaveOnlyStandaloneItems) => {
        return !!(shoppingCartHaveOnlyStandaloneItems || !(shoppingCart.Items && shoppingCart.Items.length));
    }
);

export const IsFetchingAnyDataSelector = createSelector(
    [
        IsFetchingSelector,
        PagesIsFetchingDataSelector,
        IsQuotingOfferSelector,
        IsUpdatingCartSelector,
        IsFetchingBillCyclesSelector,
        IsFetchingDataSelector
    ],
    (isFetchingOffer, isFetchingDecisionsOrAttributes, isQuotingOffer, isUpdatingCart, isFetchingBillCycles, isFetchingData) => {
        return !!(isFetchingOffer || isFetchingDecisionsOrAttributes || isQuotingOffer || isUpdatingCart || isFetchingBillCycles || isFetchingData);
    }
);


export const IsContinueDisabledSelector = createSelector(
    [IsFetchingAnyDataSelector, IsServiceabilityStepSelector, EditCustomerInfoSelector],
    (isFetchingAnyData, isServiceabilityStep, customerInfoEdit) => {
        return isFetchingAnyData || (isServiceabilityStep && !customerInfoEdit.SubscriberCurrency);
    }
);

export const IsContinueShoppingShownSelector = createSelector(
    [
        HasBulkShoppingCartItem,
        IsCustomerStepSelector,
        IsCheckoutStepSelector,
        IsDbss
    ],
    (hasBulkShoppingCartItem, isCustomer, isCheckout, isDbss) => {
        // More to be added as part of ASCFTR-2830 for validation
        return Immutable(isDbss && !hasBulkShoppingCartItem && (isCustomer || isCheckout));
    }
);

export const IsShippingRequiredSelector = createImmutableSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return pathOr(false, ['ShippingRequired'], shoppingCart);
    }
);

export const ShippingAddressSelector = ShippingAddressWizardSelector([IsQuoteBypassedSelector, OfferingOrderDataSelector]);

export const ShippingAddressDropdownOptionsSelector = ShippingAddressDropdownOptionsWizardSelector([OfferingOrderDataSelector]);

export const SelectedProducIdsOfServiceFeaturesSelectors = createImmutableSelector(
    [FilteredFormAttributeGroups],
    (attributes) => {
        return attributes ? attributes.reduce((productIds, attribute) => {
            return [...productIds, ...pluck('productId', attribute.serviceFeatures)];
        }, []) : EMPTY_ARRAY;
    });