import {createSelector} from 'reselect';
import {MetadataConstants, MetadataSelectors} from 'invision-core';

const CATEGORY_CODE = 0;

export const IsLoadedSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ProductFacet),
        MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ProductFacetCategory)
    ],
    (facetLoaded, facetCategoryLoaded) => {
        return facetCategoryLoaded && facetLoaded;
    }
);

export const ProductFacetsSelector = createSelector(
    [
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ProductFacet),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ProductFacetCategory)
    ],
    (productFacets, facetCategories) => {
        const facets = [];
        facetCategories.forEach((facet) => {
            facets.push({
                name: facet.Name,
                value: facet.Value,
                subFacets: []
            });
        });
        productFacets.forEach((subFacet) => {
            const foundFacet = facets.find((facet) => {
                return facet.value === subFacet.AdditionalProperties[CATEGORY_CODE].Value;
            });
            if (foundFacet) {
                foundFacet.subFacets.push(subFacet);
            }
        });
        return facets;
    }
);

export const ProductCategoriesSelector = createSelector(
    [MetadataSelectors.categories.CategoriesDataSelector],
    (categories) => {
        if (categories) {
            return categories.asMutable({
                deep:true
            });
        }
    }
);
