import clone from 'ramda/src/clone';
import {
    DEBIT_NOTE,
    INVOICE,
    MIN_NOTE_AMOUNT
} from './apply.credit.note.modal.constants';
import {constraintErrorMessages} from 'invision-core/src/utilities/angular.form.utilities';
import {CurrentBusinessUnitCurrencyCodeSelector} from 'invision-core/src/components/session/businessunit.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {
    applyCreditNote,
    retrieveInvoicesForDropdown
} from '../../../reducers/actions/customer.billing.actions';
import * as BillingPaymentsConstants from '../billing.payments.constants';
import {
    InvoiceListForDropDownSelector,
    IsApplyingCreditNoteSelector,
    IsFetchingInvoiceListSelector,
} from '../../../reducers/selectors/customer.billing.selectors';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import LocaleKeys from '../../../locales/keys';
import moment from 'moment';

class ApplyCreditNoteModalController {
    constructor($ngRedux, $filter, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            formattedInvoiceAndDebitNoteList : [],
            localeKeys: LocaleKeys,
            MIN_NOTE_AMOUNT,
            selectedInvoiceOrDebitNote: null,
            uiNotificationService
        });
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentBusinessUnitCurrencyCode: CurrentBusinessUnitCurrencyCodeSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                invoiceAndDebitNoteList: InvoiceListForDropDownSelector(store),
                isApplyingCreditNote: IsApplyingCreditNoteSelector(store),
                isFetchingInvoiceAndDebitNoteList: IsFetchingInvoiceListSelector(store)
            };
        };
        const controllerActions = {
            applyCreditNote,
            retrieveInvoicesForDropdown
        };

        this.modalTitle = `${i18n.translate(this.localeKeys.CREDIT_DEBIT_NOTE.APPLY_CREDIT_NOTE_TITLE)}${this.creditNoteData.referenceId}`;

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.currencyCode = this.creditNoteData.currencyCode || this.state.currentBusinessUnitCurrencyCode;

        this.getInvoiceAndDebitNotesList();
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getInvoiceAndDebitNotesList() {
        const getInvoiceAndDebitNotesListRequest = {
            customerId: this.state.currentCustomerId,
            request: {
                Filter: BillingPaymentsConstants.INVOICES | BillingPaymentsConstants.DEBIT_NOTES
            }
        };
        this.actions.retrieveInvoicesForDropdown(getInvoiceAndDebitNotesListRequest)
            .then(() => {
                this.formatInvoiceAndDebitNotesList();
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    closeModal() {
        this.onClose();
    }

    formatInvoiceAndDebitNotesList() {
        const clonedInvoiceAndDebitNoteList= clone(this.state.invoiceAndDebitNoteList);
        clonedInvoiceAndDebitNoteList.forEach((item) => {
            if (item.Invoice && item.Invoice.CurrentDue > 0) {
                this.formattedInvoiceAndDebitNoteList.push({
                    amount: item.Invoice.CurrentDue,
                    id: item.Invoice.InvoiceId,
                    text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_PREFIX)}${item.Invoice.InvoiceNumber} - ${this.$filter('invCurrency')(item.Invoice.CurrentDue, item.Invoice.CurrencyCode)}`,
                    type: INVOICE
                });
            }
            if (item.DebitNote && item.DebitNote.RemainingBalance > 0) {
                this.formattedInvoiceAndDebitNoteList.push({
                    amount: item.DebitNote.RemainingBalance,
                    id: item.DebitNote.Id,
                    text: `${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.DEBIT_NOTE_PREFIX)}${item.DebitNote.ReferenceId} - ${this.$filter('invCurrency')(item.DebitNote.RemainingBalance, item.DebitNote.CurrencyCode)}`,
                    type: DEBIT_NOTE
                });
            }
        });
    }

    validateForm() {
        const creditNoteBalance = Math.abs(this.creditNoteData.remainingBalance);
        this.showMaxNoteAmountError = false;
        this.maxNoteAmount = (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.amount) ? Math.min((this.selectedInvoiceOrDebitNote.amount), creditNoteBalance) : creditNoteBalance;
        this.formErrorMessages = constraintErrorMessages(undefined, this.applyNoteForm, {
            noteAmount: LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY,
            selectedInvoiceOrDebitNote: LocaleKeys.CREDIT_DEBIT_NOTE.INVOICE_DEBIT_NOTE,
        }, {
            min: {
                noteAmount: this.$filter('invCurrency')(this.MIN_NOTE_AMOUNT, this.currencyCode)
            }
        });
        if (this.noteAmount > this.maxNoteAmount) {
            this.showMaxNoteAmountError = true;
            let message = '';

            if (!this.selectedInvoiceOrDebitNote) {
                message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_EXCEEDS_BALANCE_ERROR);
            } else if (this.selectedInvoiceOrDebitNote) {
                if (this.selectedInvoiceOrDebitNote.amount === creditNoteBalance || (this.noteAmount > this.selectedInvoiceOrDebitNote.amount && this.noteAmount > creditNoteBalance)) {
                    message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_MAX_ERROR_SUMMARY);
                } else if (this.maxNoteAmount === this.selectedInvoiceOrDebitNote.amount) {
                    if (this.noteAmount < this.selectedInvoiceOrDebitNote.amount) {
                        message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_EXCEEDS_BALANCE_ERROR);
                    } else {
                        message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_EXCEEDS_AMOUNT_DUE_ERROR);
                    }
                } else if (this.maxNoteAmount === creditNoteBalance) {
                    if (this.noteAmount < creditNoteBalance) {
                        message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_EXCEEDS_AMOUNT_DUE_ERROR);
                    } else {
                        message  = i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.AMOUNT_TO_APPLY_EXCEEDS_BALANCE_ERROR);
                    }
                }
            }
            if (message) {
                this.formErrorMessages.push(`${message} (${i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.MAX_AMOUNT)}${this.maxNoteAmount})`);
            }
        }
    }

    applyNote() {
        if (this.applyNoteForm.$valid && !this.showMaxNoteAmountError) {
            const request = {
                customerId: this.state.currentCustomerId,
                request: {
                    AmountToApply: parseFloat(this.noteAmount).toFixed(2),
                    CreditNoteId: this.creditNoteData.creditNoteId,
                    DebitNoteId: (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.type === DEBIT_NOTE) ? this.selectedInvoiceOrDebitNote.id : null,
                    InvoiceId: (this.selectedInvoiceOrDebitNote && this.selectedInvoiceOrDebitNote.type === INVOICE) ? this.selectedInvoiceOrDebitNote.id : null
                }
            };
            this.actions.applyCreditNote(request).then(() => {
                this.uiNotificationService.transientSuccess(i18n.translate(LocaleKeys.CREDIT_DEBIT_NOTE.CREDIT_NOTE_APPLIED));
                if (this.formErrorMessages) {
                    this.formErrorMessages.length = 0;
                }
                this.onSuccess();
            }).catch((error) => {
                if (this.formErrorMessages) {
                    this.formErrorMessages.length = 0;
                }
                this.formErrorMessages.push(error.translatedMessage);
            });
        } else {
            this.validateForm();
        }
    }

}

export default {
    controller: ApplyCreditNoteModalController,
    controllerAs: 'ApplyCreditNoteModalController',
    template: require('./apply.credit.note.modal.html'),
    bindings: {
        config: '<',
        creditNoteData: '<?',
        onClose: '&',
        onSuccess: '&'
    }
};
