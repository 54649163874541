import pathOr from 'ramda/src/pathOr';
import {PRODUCT_STATUS} from '../../../customercare.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';

export function isProductEditable(productDetail, isDbss) {
    const classification = pathOr('', ['Product', 'ProductClassification'], productDetail);
    return !productDetail.IsSuspensionFee &&
        productDetail.Status !== PRODUCT_STATUS.SUSPENDED &&
        !productDetail.Removed &&
        (
            !(pathOr(false, ['Product', 'Standalone'], productDetail) && isDbss) ||
            classification === PRODUCT_CLASSIFICATIONS.AD_HOC
        );
}