export const WorkflowStateEnum = {
    //Translation from Metadata Code table (CodeType 72) to ui-guide component binding
    '1': '',            //Name: Open
    '6': 'complete',    //Name: Complete
    '7': 'progress',    //Name: In Process
    '9': 'error',       //Name: Error
    '10': 'jeopardy',   //Name: Jeopardy
    '13': 'canceled'    //Name: Canceled
};

export const WorkflowStates = {
    open: 1,
    complete: 6,
    progress: 7,
    error: 9,
    jeopardy: 10,
    canceled: 13
};