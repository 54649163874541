import Immutable from 'seamless-immutable';

import {GENERAL_STATUSES} from '../../shared/constants/general.status.constants';

export const APPLY_DISCOUNT_OPTION_ID = 'APPLY_DISCOUNT_OPTION_ID';
export const CHANGE_SUBSCRIPTION_OFFER_OPTION_ID = 'CHANGE_SUBSCRIPTION_OFFER_OPTION_ID';
export const MODIFY_RENEWAL_OPTION_ID = 'MODIFY_RENEWAL_OPTION_ID';
export const MODIFY_SUBSCRIPTION = 'MODIFY_SUBSCRIPTION';
export const MODIFY_SUBSCRIPTION_OFFER_OPTION_ID = 'MODIFY_SUBSCRIPTION_OFFER_OPTION_ID';
export const REMOVE_SUBSCRIPTION_EXTENSION_OPTION_ID = 'REMOVE_SUBSCRIPTION_EXTENSION_OPTION_ID';
export const REMOVE_SUBSCRIPTION_OPTION_ID = 'REMOVE_SUBSCRIPTION_OPTION_ID';
export const REMOVE_SUBSCRIPTION_OFFER_OPTION_ID = 'REMOVE_SUBSCRIPTION_OFFER_OPTION_ID';
export const REACTIVATE_SUBSCRIPTION_OPTION_ID = 'REACTIVATE_SUBSCRIPTION_OPTION_ID';
export const REPLACE_SUBSCRIPTION_OPTION_ID = 'REPLACE_SUBSCRIPTION_OPTION_ID';
export const RESTORE_SUBSCRIPTION_OPTION_ID = 'RESTORE_SUBSCRIPTION_OPTION_ID';
export const VIEW_PAYMENT_HISTORY_OPTION_ID = 'VIEW_PAYMENT_HISTORY_OPTION_ID';

export const SUBSCRIPTION_STATUSES = Object.assign({
    PENDING_ACTIVE: 5,
    PENDING_REMOVED: 6
}, GENERAL_STATUSES);

export const PAYMENT_METHOD_STATUSES = GENERAL_STATUSES;
export const SUBSCRIPTION_TABS = {
    INFO: 'info',
    DISCOUNT: 'discount',
    PRICING_HISTORY: 'pricing_history',
    ADDITIONAL_INFO: 'additional_info'
};

export const SUBSCRIPTION_TYPE = {
    MANUAL: 1,
    AUTOMATIC: 2
};

export const EXTENSION_STATUS_INDICATOR_STATUS = {
    ACTIVE: 1,
    UPCOMING: 2
};

export const DISCOUNT_SOURCE = Immutable({
    PRICING_PLAN: 1,
    COUPON: 2,
    GLOBAL: 3,
    DISCRETIONARY: 4,
    PRODUCT_PURCHASE: 5,
    INSTANT: 6,
    QUANTITY: 7,
    EPISODIC_PURCHASE: 8
});
