import {
    SelectedHouseholdMemberSelector
} from '../../../../reducers/selectors/customer.household.selectors';

class OverviewAndMemberDetailsController {
    constructor($ngRedux, $state) {
        this.$ngRedux = $ngRedux;
        this.$state = $state;
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                selectedMember: SelectedHouseholdMemberSelector(store)
            };
        };

        const controllerActions = {};

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./details.html'),
    controllerAs: 'OverviewAndMemberDetails',
    controller: OverviewAndMemberDetailsController
};
