import {stateGo} from 'redux-ui-router';
import {
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
    IsCurrentCustomerBlocklisted
} from './../../../../reducers/selectors/customer.selectors';
import LocaleKeys from './../../../../locales/keys';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../customercare.constants';
import {
    searchSubscriptions,
    setCurrentPage,
    setIncludedFilter,
    setPageSizePreference
} from './../../../../reducers/actions/customer.subscriptions.actions';
import {
    BriefSubscriptionsSelector,
    IncludeRemovedSelector,
    IsFetchingDataSelector,
    PageNumberSelector,
    RecordCountSelector,
    SelectedPageSizePreference,
    SubscriptionErrorSelector,
    TotalPagesSelector
} from './../../../../reducers/selectors/customer.subscriptions.selectors';
import {CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME} from '../../../../reducers/constants/products.wizard.constants';
import {ORDERING_ACCESS} from '../../../../security.attributes';

export const SORT_DESC = 2;

function SubscriptionsController($ngRedux, uiNotificationService) {
    let disconnectRedux;

    const mapStateToTarget = (store) => {
        return {
            currentCustomerId: CurrentCustomerIdSelector(store),
            currentCustomer: CurrentCustomerSelector(store),
            includeRemoved: IncludeRemovedSelector(store),
            isCurrentCustomerBlocklisted: IsCurrentCustomerBlocklisted(store),
            isFetchingData: IsFetchingDataSelector(store),
            pagerCurrentPage: PageNumberSelector(store),
            pagerMaxSlots: 5,
            pagerNumPages: TotalPagesSelector(store),
            pagerPageSize: SelectedPageSizePreference(store) || SessionSelectors.PageSizePreferenceSelector(store),
            pagerRecordCount: RecordCountSelector(store),
            subscriptionError: SubscriptionErrorSelector(store),
            subscriptions: BriefSubscriptionsSelector(store),
            userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
        };
    };

    this.$onInit = () => {
        this.uiNotificationService = uiNotificationService;

        const controllerActions = {
            searchSubscriptions: searchSubscriptions,
            setCurrentPage,
            setIncludedFilter,
            setPageSizePreference,
            stateGo
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.LocaleKeys = LocaleKeys;
        this.stateOrName = 'index.customercare.customer.subscriptions';
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.fetchSubscriptions();
    };

    this.fetchSubscriptions = () => {
        const criteria = {
            IncludeRemoved: this.state.includeRemoved,
            IncludePaymentInstrumentInfo: true,
            PageNumber: this.state.pagerCurrentPage,
            PageSize: this.state.pagerPageSize,
            SortDirection: SORT_DESC
        };

        return this.actions.searchSubscriptions(this.state.currentCustomerId, criteria)
            .catch(() => {
                if (this.state.subscriptionError) {
                    this.uiNotificationService.error(this.state.subscriptionError.message, null, {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    });
                }
            });
    };

    this.handleIncludeRemovedClick = () => {
        this.actions.setIncludedFilter(!this.state.includeRemoved);
        this.actions.setCurrentPage(1);
        this.fetchSubscriptions();
    };

    this.hasOrderingAccess = () => {
        return PermissionService.hasAdminAccess(this.state.userSecurityAttributes, ORDERING_ACCESS)
            && !this.state.isCurrentCustomerBlocklisted;
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.hasSubscriptions = () => {
        return this.state.subscriptions.length > 0;
    };

    this.addSubscription = () => {
        this.actions.stateGo(CREATE_SUBSCRIPTIONS_ORDER_STATE_NAME, {
            subscriptionsOnly: true
        });
    };

    this.updatePage = (pageNum) => {
        this.actions.setCurrentPage(pageNum);
        this.fetchSubscriptions();
    };

    this.updatePageSize = (pageSize) => {
        this.actions.setPageSizePreference(pageSize);
        this.actions.setCurrentPage(1);
        this.fetchSubscriptions();
    };
}

export default {
    bindings: {},
    template: require('./subscriptions.list.html'),
    controller: SubscriptionsController,
    controllerAs: 'controller'
};
