import {createSelector} from 'reselect';
import {
    PagesWithDecisionsSelector,
    SelectedOfferNameSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';

export const DecisionPagesViewModel = createSelector(
    [
        SelectedOfferNameSelector,
        PagesWithDecisionsSelector
    ],
    (offerName, decisions) => {
        return [{
            name: offerName,
            decisions: decisions
        }];
    }
);
