import CreateCustomerComponent from './create.customer.component';
import AccountInformationSectionComponent from './accountInformationSection/account.information.section.component';
import AdditionalPropertiesSectionComponent from './additionalPropertiesSection/additional.properties.section.component';
import AddressInformationSectionComponent from './addressInformationSection/address.section.component';
import BillingInformationSectionComponent from './billingInformationSection/billing.information.section.component';
import ConsentInformationSectionComponent from './consentInformationSection/consent.information.section.component';
import ContactInformationSectionComponent from './contactInformationSection/contact.information.section.component';
import ContactMethodsSectionComponent from './contactMethodsSection/contact.methods.section.component';
import CreditCheckAddressComponent from './creditCheckAddressSection/credit.check.address.section.component';
import CustomerInformationSectionComponent from './customerInformationSection/customer.information.section.component';
import TaxExemptionInformationSectionComponent from './taxExemptionInformationSection/tax.exemption.information.section.component';
import NotificationPreferencesSectionController from './notificationPreferencesSection/notification.preferences.section.component';

export default angular.module('invision.customercare.createCustomer', [])
    .component('createCustomerForm', CreateCustomerComponent)
    .component('accountInformationSection', AccountInformationSectionComponent)
    .component('additionalPropertiesSection', AdditionalPropertiesSectionComponent)
    .component('addressInformationSection', AddressInformationSectionComponent)
    .component('billingInformationSection', BillingInformationSectionComponent)
    .component('consentInformationSection', ConsentInformationSectionComponent)
    .component('contactInformationSection', ContactInformationSectionComponent)
    .component('contactMethodsSection', ContactMethodsSectionComponent)
    .component('creditCheckAddressSection', CreditCheckAddressComponent)
    .component('customerInformationSection', CustomerInformationSectionComponent)
    .component('taxExemptionInformationSection', TaxExemptionInformationSectionComponent)
    .component('notificationPreferencesSection', NotificationPreferencesSectionController)
    .name;
