import clone from 'ramda/src/clone';
import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {hasReadAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {formHasErrors} from 'invision-core/src/components/helpers/form.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerCareKeys from '../../../../locales/keys';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import {
    CREDIT_CHECK_RESULT_SUCCESS_STATUS,
    CREDIT_CHECK_STATUS
} from '../../../../reducers/constants/credit.check.constants';
// Should not be importing files from a specific wizard here, this can be passed in. Will be fixed in ASCINV-319
import {
    DriversLicenseStateRegionProvinceValueOptions,
    StateRegionProvinceValueOptions
} from '../../../wizards/newConnectWizard/new.connect.wizard.selectors';
import {
    EditCartSelector,
    IsCreatingOrUpdatingCustomerSelector
} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {
    updateCustomerInfo,
    updateCustomerInfoEditCopy
} from '../../../../reducers/actions/new.connect.wizard.actions';
import {
    createOrUpdateCustomerAndRunCreditCheck,
    overrideCreditClassification,
    setCreditCheckModalStatus,
    setCreditCheckSkipped
} from '../../../../reducers/actions/credit.check.actions';
import {getErrorLookupObject} from '../../../createCustomer/create.customer.constants';
import {apFormHasErrors} from '../../../main/customercare.page.helper';
import {
    CanUserOverrideCreditCheckSelector,
    CreditCheckClassificationOptionsSelector,
    CreditCheckConfigurationSelector,
    CreditClassificationsSelector,
    RunCreditCheckErrorMessageSelector,
    SubscriberCreditCheckAddressStatesOptions,
    SubscriberCreditCheckDetailsSelector,
    SubscriberCreditCheckStatusSelector,
    SubscriberIsUpdatingCreditClassificationSelector
} from '../../../../reducers/selectors/credit.check.selectors';
import {transformAddressResponseObjectForEditing} from '../../../../reducers/helpers/customer.helper';
import {
    createEditCustomerFormChanged
} from '../../../../reducers/actions/customer.actions';
import {
    CreateEditCustomerSelector,
    SelectedCustomerCreditCheckDetailsSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../reducers/actions/address.component.actions';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../shared/contextualComponents/address/address.validator.helper';
import {CREDIT_CLASS_VIEW_ACCESS} from '../../../../security.attributes';

const mapStateToTarget = (store) => {
    return {
        addressCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
        addressStatesLoaded: MetadataCodeLoadedSelector(CODES.AddressStateProvinceRegion, store),
        addressStatesOptions: StateRegionProvinceValueOptions(store),
        canOverrideCreditCheckClassification: CanUserOverrideCreditCheckSelector(store),
        createEditCustomer: CreateEditCustomerSelector(store),
        creditCheckClassificationOptions: CreditCheckClassificationOptionsSelector(store),
        creditCheckConfiguration: CreditCheckConfigurationSelector(store),
        creditClassifications: CreditClassificationsSelector(store),
        driversLicenseStateOptions: DriversLicenseStateRegionProvinceValueOptions(store),
        editCartSelector: EditCartSelector(store),
        isCreatingCustomer: IsCreatingOrUpdatingCustomerSelector(store),
        isDbss: IsDbss(store),
        isUpdatingCreditClassification: SubscriberIsUpdatingCreditClassificationSelector(store),
        runCreditCheckErrorMessage: RunCreditCheckErrorMessageSelector(store),
        selectedCustomerCreditCheckDetails: SelectedCustomerCreditCheckDetailsSelector(store),
        subscriberAddressStatesOptions: SubscriberCreditCheckAddressStatesOptions(store),
        subscriberCreditCheckDetails: SubscriberCreditCheckDetailsSelector(store),
        subscriberCreditCheckStatus: SubscriberCreditCheckStatusSelector(store),
        unvalidatedAddresses: UnvalidatedAddressesSelector(store),
        userSecurityAttributes: UserSecurityAttributesSelector(store),
        validatedAddresses: ValidatedAddressesSelector(store),
    };
};

const controllerActions = {
    createEditCustomerFormChanged,
    createOrUpdateCustomerAndRunCreditCheck,
    fetchCodeTypes,
    overrideCreditClassification,
    setCreditCheckModalStatus,
    setCreditCheckSkipped,
    setForceShowEditFormAddress,
    updateCustomerInfo,
    updateCustomerInfoEditCopy,
    validateAddress
};

class CreditCheckController {

    constructor($ngRedux, $state, $timeout, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.CREDIT_CHECK_RESULT_SUCCESS_STATUS = CREDIT_CHECK_RESULT_SUCCESS_STATUS;
        this.CREDIT_CHECK_STATUS = CREDIT_CHECK_STATUS;
        this.customerCareKeys = CustomerCareKeys;
        this.customerModelLastSaved = {};
        this.disconnectRedux = null;
        this.flags = {};
        this.formApi = {};
        this.getAddressKey = this.getAddressKey.bind(this);
        this.popupApi = null;
        this.uiNotificationService = uiNotificationService;
        this.staticTranslations = {
            unexpectedError: i18n.translate(this.customerCareKeys.CREDIT_CHECK.UNEXPECTED_ERROR)
        };
        this.validateAddressWithAPI = this.validateAddressWithAPI.bind(this);
    }

    $onInit() {
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.showForm = false; // To show/hide the Customer Form
        this.creditCheckFormErrors = [];
        this.creditCheck = true;
        this.showCreditCheckForm = true; // To show/hide the Override Credit Check section after Credit Check is run or skipped
        this.showCreditClassificationComment = false; // To show/hide the Comment field when the Credit Check is overridden
        this.overrideCreditCheckClassificationID = '';
        this.overrideCreditCheckClassificationComment = '';
        this.creditCheckFailed = false;
        this.creditCheckSuccessFlag = false;

        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            this.popupApi = Object.assign({}, api, {
                open: () => {
                    this.onModalOpen();
                    this.$timeout(() => {
                        api.open();
                        this.formApi.$setPristine();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };

        this.handleFormChanged = (customerModel) => {
            this.actions.createEditCustomerFormChanged(customerModel);
            if (this.isNewConnect) {
                this.actions.updateCustomerInfoEditCopy(customerModel);
            }
        };

        this.handlePropertyChange = () => {
            // Use this placeholder to handle errors if required.
        };

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressCountry);
        }

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressStateProvinceRegion);
        }
        this.hasCreditCardUnmaskViewAccess = () => {
            return hasReadAccess(this.state.userSecurityAttributes, CREDIT_CLASS_VIEW_ACCESS.id);
        };
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    closePopup() {
        if (this.creditCheckSuccessFlag) {
            if (this.isNewConnect) {
                this.actions.updateCustomerInfo(this.customerModelLastSaved);
            }
            if (this.isChangeOfService && this.isCheckoutStep) {
                this.runChangeOfServiceOrderQuote()();
            } else if (this.isCheckoutStep) {
                this.runOrderQuote()(this.state.selectedCustomerCreditCheckDetails.Subscriber);
            } else if (this.state.subscriberCreditCheckStatus === this.CREDIT_CHECK_STATUS.OVERRIDE) {
                this.uiNotificationService.success(i18n.translate(this.customerCareKeys.CREDIT_CHECK.OVERRIDE_CREDIT_CHECK_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            }
            if (this.onSuccess) {
                this.onSuccess();
            }
        } else if (this.creditCheckFailed) {
            if (this.onError) {
                this.onError();
            }
        } else {
            if (this.onClose) {
                this.onClose();
            }
        }
        if (this.isNewConnect) {
            this.actions.updateCustomerInfoEditCopy(this.customerModelLastSaved);
        }
        this.actions.setCreditCheckModalStatus(false);
        this.showCreditClassificationComment = false;
        this.overrideCreditCheckClassificationComment = '';
        this.showForm = false;
        this.showCreditCheckForm = true;
        this.popupApi.close();
    }

    get addressStateOptions() {
        if (this.isNewConnect) {
            return this.state.addressStatesOptions;
        } else {
            return this.state.subscriberAddressStatesOptions;
        }
    }

    get isFormDirty() {
        if (this.formApi && this.formApi.createCustomerForm) {
            return this.formApi.createCustomerForm.$dirty;
        } else {
            return false;
        }
    }

    onCreditCheckOptionChange() {
        this.actions.setCreditCheckSkipped(!this.creditCheck);
    }

    onCreditCheckOverride() {
        if (this.overrideCreditCheckClassificationID && this.overrideCreditCheckClassificationComment) {
            const additionalData = {
                updateSubscriber: false,
                creditClassificationData: this.state.subscriberCreditCheckDetails,
                overrideCreditCheckClassificationID: this.overrideCreditCheckClassificationID,
                overrideCreditCheckClassificationComment: this.overrideCreditCheckClassificationComment,
                isDbss: this.state.isDbss
            };
            this.actions.overrideCreditClassification(this.state.createEditCustomer, this.state.subscriberCreditCheckDetails, additionalData)
                .then(() => {
                    this.overrideCreditCheckClassificationID = '';
                    this.closePopup();
                })
                .catch((err) => {
                    this.onFailure(err);
                });
        } else if (!this.overrideCreditCheckClassificationID) {
            this.closePopup();
        }
    }

    onFailure(err) {
        this.creditCheckFormErrors.push(err.translatedMessage || this.staticTranslations.unexpectedError);
        this.creditCheckFailed = true;
        this.uiNotificationService.transientError(err.translatedMessage || this.staticTranslations.unexpectedError);
    }

    onModalOpen() {
        this.currencyCode = this.customerModel.SubscriberCurrency;
        if (this.state.selectedCustomerCreditCheckDetails.Subscriber) {
            // If Credit Check details already exist for the Customer
            this.currencyCode = this.state.selectedCustomerCreditCheckDetails.Subscriber.SubscriberCurrency;
        } else if (this.state.createEditCustomer) {
            // If New Connect flow
            this.currencyCode = this.state.editCartSelector ? this.state.editCartSelector.customerInfo.SubscriberCurrency : this.customerModel.SubscriberCurrency;
        }
        this.creditCheckFormErrors = [];
        const address = pathOr(null, ['subscriberCreditCheckDetails', 'Address'], this.state);
        let primaryCustomerAddress = null;
        if (address) {
            primaryCustomerAddress = transformAddressResponseObjectForEditing(address);
        } else {
            primaryCustomerAddress = {
                country: this.customerModel.HomeCountry
            };
        }
        const creditCheckAddress = {
            country: this.customerModel.HomeCountry,
            addressLine1: null,
            addressLine2: null,
            city: null,
            stateRegionProvince: null,
            postalCode: null
        };
        if (this.isNewConnect) {
            this.actions.createEditCustomerFormChanged(Object.assign({}, this.customerModel, primaryCustomerAddress, {
                AddressForCreditCheck: creditCheckAddress
            }));
        } else {
            this.actions.createEditCustomerFormChanged(Object.assign({}, this.customerModel, primaryCustomerAddress, {
                AddressForCreditCheck: creditCheckAddress
            }, {
                setEditAdditionalProp: this.customerModel.AdditionalProperties
            }));
        }
        this.customerModelLastSaved = clone(this.customerModel);
        this.showForm = true;
        this.creditCheckSuccessFlag = false;
        this.creditClassificationOptions = this.state.creditCheckClassificationOptions;
        this.actions.setCreditCheckModalStatus(true);
        this.actions.setCreditCheckSkipped(!this.creditCheck);
    }

    changeCreditClassification(item) {
        this.setCreditClassification(item.id);
        this.setCreditLimitForClassification(item.id);
        if (item.id !== this.assignedCreditClassificationId) {
            this.showCreditClassificationComment = true;
            this.overrideCreditCheckClassificationID = item.id;
        } else {
            this.showCreditClassificationComment = false;
            this.overrideCreditCheckClassificationID = '';
        }
    }

    setCreditLimitForClassification(item) {
        const classification = this.state.creditClassifications.find((classification) => {
            return classification.Id === item;
        });
        this.creditLimit = classification ? classification.CreditLimit : i18n.translate(this.customerCareKeys.CREDIT_CHECK.NA);
    }
    rerunCreditCheck() {
        this.showCreditClassificationComment = false;
        this.showCreditCheckForm = true;
        this.overrideCreditCheckClassificationID = '';
        this.$timeout(() => {
            this.formApi.$setPristine();
        });
    }

    setCreditClassification(creditClassificationId) {
        this.creditClassificationOptions.forEach((item) => {
            (item.id === creditClassificationId) ? item.selected = true : item.selected = false;
        });
    }

    handleSubmitForm() {
        if (this.showCreditCheckForm) {
            this.runOrSkipCreditCheck();
        } else {
            this.onCreditCheckOverride();
        }
    }

    getAddressKey() {
        return pathOr(undefined, ['formApi', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['formApi', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        /*
            The address below is being used to populate serviceability address and other areas
            pre-checkout in new connect order flow, which is the reason for the addressTypes
            below
        */
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    handleUpdateCreditClassificationResponse(response) {
        this.assignedCreditClassificationId = response.Subscriber.CreditCheckClassification.CreditCheckClassificationId;
        this.setCreditClassification(this.assignedCreditClassificationId);
        this.setCreditLimitForClassification(this.assignedCreditClassificationId);
        this.showCreditCheckForm = false;
        this.creditCheckSuccessFlag = true;
        this.creditCheckFailed = false;
        this.formApi.$setPristine();
        this.customerModelLastSaved = clone(this.customerModel);
    }

    runOrSkipCreditCheck() {
        const ngFormErrors = this.formApi.createCustomerForm.$error;
        const creditCheckFormNonApErrors = formHasErrors(ngFormErrors, getErrorLookupObject());
        const apFormErrors = this.formApi.createCustomerForm.addlApi ? this.formApi.createCustomerForm.addlApi.$error : null;
        const apErrors = apFormHasErrors(apFormErrors);
        this.creditCheckFormErrors = creditCheckFormNonApErrors.concat(apErrors);

        if (this.creditCheckFormErrors.length === 0 && !this.state.isCreatingCustomer) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        if (!this.state.subscriberCreditCheckDetails.Subscriber) {
                            this.actions.createOrUpdateCustomerAndRunCreditCheck(this.state.createEditCustomer, this.creditCheck, this.state.subscriberCreditCheckDetails)
                                .then((response) => {
                                    this.handleUpdateCreditClassificationResponse(response);
                                })
                                .catch((err) => {
                                    this.onFailure(err);
                                });
                        } else {
                            const additionalData = {
                                updateSubscriber: this.isFormDirty,
                                creditClassificationData: this.state.subscriberCreditCheckDetails,
                                isDbss: this.state.isDbss
                            };
                            this.actions.createOrUpdateCustomerAndRunCreditCheck(this.state.createEditCustomer, this.creditCheck, this.state.subscriberCreditCheckDetails, additionalData)
                                .then((response) => {
                                    this.handleUpdateCreditClassificationResponse(response);
                                })
                                .catch((err) => {
                                    this.onFailure(err);
                                });
                        }
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        this.onFailure({
                        //translate and also get guidance from the doc writer
                            translatedMessage: i18n.translate(this.customerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                        });
                    }
                })
                .catch((err) => {
                    this.onFailure(err);
                });

        }
    }
}

export default {
    template: require('./credit.check.modal.html'),
    bindings: {
        config: '<',
        customerModel: '<',
        formattedSubscriberIdentities:'<?',
        isChangeOfService: '<?',
        isCheckoutStep: '<?',
        isCustomerTypeReadOnly: '<?',
        isNewConnect: '<?',
        isNewCustomer: '<',
        onClose: '&?',
        onError: '&?',
        onSuccess: '&?',
        runChangeOfServiceOrderQuote: '&?',
        runOrderQuote: '&?'
    },
    controllerAs:'CreditCheckController',
    controller: CreditCheckController
};
