import LocaleKeys from '../../../../../locales/keys';

class CancelRemovalModalController {
    constructor() {
        this.localeKeys = LocaleKeys;
    }
}

export default {
    bindings: {
        currentSubscription: '<',
        onClose: '&',
        onSuccess: '&'
    },
    controller: CancelRemovalModalController,
    controllerAs: 'cancelRemovalModal',
    template: require('./cancel.removal.modal.html')
};
