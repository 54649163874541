import {i18n} from 'invision-core';
import moment from 'moment';
import LocaleKeys from '../locales/keys';

export const formatCardName = (cardTypes, cardType, accountNumber) => {
    const substitution = {
        cardType: findCardType(cardTypes, cardType),
        last4Digits: accountNumber ? accountNumber.slice(-4) : ''
    };

    return i18n.translate(LocaleKeys.MAKE_PAYMENT.CARD_TYPE_AND_LAST_FOUR, substitution);
};

export const findCardType = (cardTypes, cardType) => {
    const type = cardType ? cardTypes.find((item) => {
        return String(item.Value) === String(cardType);
    }) : null;

    return type ? type.Name : null;
};

export const formatExpirationDate = (creditCard) => {
    const substitution = {
        expirationMonth: creditCard.expirationMonth,
        expirationYear: creditCard.expirationYear
    };

    return i18n.translate(LocaleKeys.CREDIT_CARD_EXPIRATION_DATE, substitution);
};

export const formattedCreditCardExpiration = (paymentMethod) => {
    if (!paymentMethod.CreditCard) {
        return '';
    }
    const {ExpirationMonth, ExpirationYear} = paymentMethod.CreditCard;
    return `${ExpirationMonth}/${ExpirationYear}`;
};

export const isExpired = (payment) => {
    const formattedExpiration = formattedCreditCardExpiration(payment);
    if (formattedExpiration.length) {
        return moment(formattedExpiration, 'MM/YYYY').isBefore(moment(), 'month');
    }
    return false;
};
