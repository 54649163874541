import {
    i18n,
    SessionSelectors
} from 'invision-core';
import LocaleKeys from '../../../../locales/keys';
import {
    grantCoupon,
    searchGrantableCoupons,
    setCurrentGrantCouponsPage,
    setGrantCouponsNameFilter,
    setSelectedCoupon,
    setGrantedCouponsPageSizePreference
} from '../../../../reducers/actions/customer.coupons.actions';
import {
    CurrentCustomerSelector,
    CurrentCustomerIdSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    CustomerCouponsErrorSelector,
    IsFetchingGrantableCouponsSelector,
    IsGrantingCouponSelector,
    GrantCouponsCurrentPageNumberSelector,
    GrantCouponsNameFilterSelector,
    GrantCouponsRecordCountSelector,
    GrantCouponsSelectedPageSizePreference,
    GrantCouponsShouldShowEmptyStateSelector,
    GrantCouponsShouldShowNoResultsSelector,
    GrantCouponsTableDataSelector,
    GrantCouponsTotalPageNumberSelector,
    LastGrantedCouponDetailsSelector,
    SelectedCouponAssociationCardsDataSelector,
    SelectedCouponSelector,
} from '../../../../reducers/selectors/customer.coupons.selectors';
import {
    GRANTED_COUPON_ROUTE
} from '../customer.coupons.config';

const MAX_SLOTS_FOR_PAGER = 5;

class GrantCouponsController {

    constructor($ngRedux, $timeout, $state) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$state = $state;
        this.CustomerLocaleKeys = LocaleKeys;
        this.searchTerm = undefined;
        this.grantCoupon = this.grantCoupon.bind(this);

        this.columnDefs = [
            {
                field: 'DateAdded',
                displayName: i18n.translate(LocaleKeys.COUPONS.DATE_ADDED),
                width: 120,
                cellFilter: 'date:"shortDate"'
            },
            {
                field: 'Name',
                displayName: i18n.translate(LocaleKeys.NAME),
                cellTemplate: require('./cellTemplates/name.html')
            },
            {
                field: 'Discount',
                displayName: i18n.translate(LocaleKeys.DISCOUNT)
            }
        ];
    }

    $onInit() {
        this.grantCouponPopupConfig = {
            onRegisterApi: ({api}) => {
                this.grantCouponPopupApi = api;
            }
        };

        //hook up the confirmation grant coupon api
        this.grantCouponSuccessPopupConfig = {
            onRegisterApi: ({api}) => {
                this.grantCouponSuccessPopupApi = api;
            }
        };

        this.prepareTableData = (tableData) => {
            return tableData.map((rowData) => {
                return Object.assign({}, rowData, {
                    clickHandler: () => {
                        this.openGrantCouponPopup(rowData);
                    }
                });
            });
        };

        this.mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPageNumber: GrantCouponsCurrentPageNumberSelector(store),
                customerCouponError: CustomerCouponsErrorSelector(store),
                isFetchingGrantableCoupons: IsFetchingGrantableCouponsSelector(store),
                isGrantingCoupon: IsGrantingCouponSelector(store),
                lastGrantedCouponDetails: LastGrantedCouponDetailsSelector(store),
                maximumSlots: MAX_SLOTS_FOR_PAGER,
                nameFilter: GrantCouponsNameFilterSelector(store),
                numberOfPages: GrantCouponsTotalPageNumberSelector(store),
                recordCount: GrantCouponsRecordCountSelector(store),
                selectedCouponAssociationCardsData: SelectedCouponAssociationCardsDataSelector(store),
                selectedCoupon: SelectedCouponSelector(store),
                selectedPageSizePreference: GrantCouponsSelectedPageSizePreference(store),
                shouldShowEmptyState: GrantCouponsShouldShowEmptyStateSelector(store),
                shouldShowNoResults: GrantCouponsShouldShowNoResultsSelector(store),
                tableData: this.prepareTableData(GrantCouponsTableDataSelector(store)),
                userPageSizePreference: SessionSelectors.PageSizePreferenceSelector(store)
            };
        };

        this.controllerActions = {
            grantCoupon,
            searchGrantableCoupons,
            setCurrentGrantCouponsPage,
            setGrantCouponsNameFilter,
            setGrantedCouponsPageSizePreference,
            setSelectedCoupon,
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = 'index.customercare.customer.coupons.grantCoupons';
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };
        this.actions.setGrantCouponsNameFilter('');
        this.actions.setCurrentGrantCouponsPage(1);
        this.searchGrantableCoupons();
    }

    clearSearchText() {
        this.searchTerm = undefined;
        this.actions.setGrantCouponsNameFilter('');
        this.actions.setCurrentGrantCouponsPage(1);
        this.searchGrantableCoupons();
    }

    closeGrantCouponPopup() {
        this.showGrantCouponPopup = false;
        this.grantCouponPopupApi.close();
    }

    grantCoupon(actionData) {
        this.actions.grantCoupon(this.state.currentCustomerId, actionData).then(() => {
            this.closeGrantCouponPopup();
            this.openGrantCouponConfirmationPopup();
        });
    }

    openGrantCouponPopup(grantedCoupon) {
        this.actions.setSelectedCoupon(grantedCoupon.CouponData);
        this.showGrantCouponPopup = true;
        this.$timeout(this.grantCouponPopupApi.open);
    }

    openGrantCouponConfirmationPopup() {
        this.grantCouponSuccessPopupApi.open();
    }

    handleCloseGrantCouponConfirmationPopup() {
        this.grantCouponSuccessPopupApi.close();
        this.$state.go(GRANTED_COUPON_ROUTE, {
            subscriberId: this.state.currentCustomerId
        });
    }

    onPageSelected(page) {
        this.actions.setCurrentGrantCouponsPage(page);
        this.searchGrantableCoupons();
    }

    onSearchTermSubmitted(searchTerm) {
        this.searchTerm = searchTerm;
        this.actions.setGrantCouponsNameFilter(searchTerm);
        this.onPageSelected(1);
    }

    searchGrantableCoupons() {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setGrantedCouponsPageSizePreference(this.state.userPageSizePreference);
        }

        const filterData = {
            couponName: this.state.nameFilter,
            pageSize: this.state.selectedPageSizePreference,
            pageNumber: this.state.currentPageNumber
        };
        this.actions.searchGrantableCoupons(filterData);
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setGrantedCouponsPageSizePreference(pageSize);
        this.onPageSelected(1);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {},
    template: require('./grant.coupons.html'),
    controller: GrantCouponsController,
    controllerAs: 'GrantCouponsController'
};
