import {
    i18n
} from 'invision-core';
import LocaleKeys from '../../../../locales/keys';

function ExternalAuthorizationsController($scope) {

    let removeAuthConfPopupApi = null;
    const DELETE_ROW_OPTION_ID = 'DELETE_ROW_OPTION_ID';

    this.$onInit = () => {
        this.LocaleKeys = LocaleKeys;
        $scope.identifierAbbreviationLabel = i18n.translate(LocaleKeys.IDENTIFIER_ABBREVIATION);
        this.tableData = {
            data: this.externalAuthorizations,
            columnDefs: [{
                field: 'name',
                displayName: i18n.translate(LocaleKeys.NAME),
                width: '*'
            }, {
                field: 'externalSubscriberId',
                displayName: i18n.translate(LocaleKeys.PROFILE_PAGE.EXTERNAL_SUBSCRIBER_ID),
                cellTemplate: require('./subscriberIdTemplate.html'),
                width: '*'
            }, {
                field: 'expirationDate',
                displayName: i18n.translate(LocaleKeys.PROFILE_PAGE.EXPIRATION_DATE),
                cellTemplate: require('./expirationDateTemplate.html'),
                width: '*'
            }, {
                field: 'tokenActive',
                displayName: i18n.translate(LocaleKeys.PROFILE_PAGE.TOKEN_ACTIVE),
                width: '*'
            }, {
                field: 'menu',
                displayName: '',
                cellTemplate: require('./menuCellTemplate.html'),
                width: '50'
            }]
        };

        $scope.datatable = {
            handleListSelected: (entityId) => {
                return this.handleRowMoreCellListSelected.bind(this, entityId);
            },
            moreCellOptions: [{
                id: DELETE_ROW_OPTION_ID,
                title: i18n.translate(LocaleKeys.REMOVE)
            }]
        };

        this.removeAuthConfPopupConfig = {
            onRegisterApi: (event) => {
                removeAuthConfPopupApi = event.api;
            }
        };
    };

    this.$onChanges = (changesObj) => {
        if (changesObj.externalAuthorizations && this.externalAuthorizations && this.tableData) {
            this.tableData.data = changesObj.externalAuthorizations.currentValue;
        }
    };


    this.handleResultRemoveAuthConfirmationPopup = (isConfirmed) => {
        if (isConfirmed) {
            this.onDeleteRow()(this.deletingRowId);
            removeAuthConfPopupApi.close();
        } else {
            removeAuthConfPopupApi.close();
        }
        this.deletingRowId = null;
    };

    this.handleCloseRemoveAuthConfirmationPopup = () => {
        removeAuthConfPopupApi.close();
    };

    this.deleteRow = (rowId) => {
        this.deletingRowId = rowId;
        removeAuthConfPopupApi.open();
    };

    this.handleRowMoreCellListSelected = (entityId, {item: selectedListItem}) => {
        if (selectedListItem.id === DELETE_ROW_OPTION_ID) {
            this.deleteRow(entityId);
        }
    };
}

export default {
    bindings: {
        externalAuthorizations : '<',
        onDeleteRow : '&'
    },
    template: require('./externalAuthorizations.html'),
    controller: ExternalAuthorizationsController,
    controllerAs: 'externalAuthorizationsController'
};
