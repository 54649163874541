import {
    DateRangeShortcutHelper,
    i18n,
    SessionSelectors
} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    searchCouponRedemptions,
    searchRedemptionCodes,
    setCurrentGrantedCouponsPage,
    setGrantedCouponsGrantDateEndFilter,
    setGrantedCouponsGrantDateStartFilter,
    setGrantedCouponsNameOrCodeFilter,
    setGrantedCouponsRedemptionStatusFilter,
    setPageSizePreference,
} from '../../../reducers/actions/customer.coupons.actions';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerSelector,
} from '../../../reducers/selectors/customer.selectors';
import {
    GrantedCouponsCurrentPageNumberSelector,
    GrantedCouponsGrantDateEndFilterSelector,
    GrantedCouponsGrantStartFilterSelector,
    GrantedCouponsNameOrCodeFilterSelector,
    GrantedCouponsRecordCountSelector,
    GrantedCouponsRedemptionStatusFilterSelector,
    GrantedCouponsTableDataSelector,
    IsFetchingRedemptionCodesSelector,
    IsFetchingCouponRedemptionsSelector,
    SelectedPageSizePreference
} from '../../../reducers/selectors/customer.coupons.selectors';
import {LanguageHeaderSelector} from 'invision-core/src/components/session/session.selectors';

const REDEMPTION_STATUS_ALL = 1;
const REDEMPTION_STATUS_NOT_REDEEMED = 2;
const REDEMPTION_STATUS_REDEEMED = 3;
const SORT_DIRECTION_DESCENDING = 2;

class CustomerCouponsController {

    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.CustomerLocaleKeys = LocaleKeys;
    }

    $onInit() {
        const columnDefs = [
            {
                field: 'DateGranted',
                displayName: i18n.translate(LocaleKeys.COUPONS.DATE_GRANTED),
                width: 120,
                cellFilter: 'date:"shortDate"'
            },
            {
                field: 'Status',
                displayName: i18n.translate(LocaleKeys.STATUS),
                width: 140,
                cellTemplate: require('./cellTemplates/status.html')
            },
            {
                field: 'RedemptionCode',
                displayName: i18n.translate(LocaleKeys.COUPONS.REDEMPTION_CODE),
                minWidth: 150,
                cellTemplate: require('./cellTemplates/redemption.code.html')
            },
            {
                field: 'Coupon',
                displayName: i18n.translate(LocaleKeys.NAME),
                minWidth: 150
            },
            {
                field: 'OrderNumber',
                displayName: i18n.translate(LocaleKeys.ORDER_NUMBER),
                width: 130,
                cellTemplate: require('./cellTemplates/order.html')
            },
            {
                field: 'LastRedeemed',
                displayName: i18n.translate(LocaleKeys.COUPONS.LAST_REDEEMED),
                width: 125,
                cellFilter: 'date:"shortDate"'
            },
            {
                field: 'ExpirationDate',
                displayName: i18n.translate(LocaleKeys.COUPONS.EXPIRATION_DATE),
                width: 125,
                cellFilter: 'date:"shortDate"'
            }
        ];

        this.redemptionStatusOptions = [
            {
                id: REDEMPTION_STATUS_ALL,
                text: i18n.translate(LocaleKeys.ALL),
                selected: true
            },
            {
                id: REDEMPTION_STATUS_REDEEMED,
                text: i18n.translate(LocaleKeys.COUPONS.REDEEMED),
                selected: false
            },
            {
                id: REDEMPTION_STATUS_NOT_REDEEMED,
                text: i18n.translate(LocaleKeys.COUPONS.NOT_REDEEMED),
                selected: false
            }
        ];

        this.mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentPageNumber: GrantedCouponsCurrentPageNumberSelector(store),
                grantDateEndFilter: GrantedCouponsGrantDateEndFilterSelector(store),
                grantDateStartFilter: GrantedCouponsGrantStartFilterSelector(store),
                languageHeader: LanguageHeaderSelector(store),
                nameOrCodeFilter: GrantedCouponsNameOrCodeFilterSelector(store),
                redemptionStatusFilter: GrantedCouponsRedemptionStatusFilterSelector(store),
                isFetchingCouponRedemptions: IsFetchingCouponRedemptionsSelector(store),
                isFetchingRedemptionCodes: IsFetchingRedemptionCodesSelector(store),
                recordCount: GrantedCouponsRecordCountSelector(store),
                selectedPageSizePreference: SelectedPageSizePreference(store),
                tableData: {
                    columnDefs: columnDefs,
                    data: GrantedCouponsTableDataSelector(store)
                },
                userPageSizePreference: SessionSelectors.PageSizePreferenceSelector(store)
            };
        };

        this.controllerActions = {
            searchCouponRedemptions,
            searchRedemptionCodes,
            setCurrentGrantedCouponsPage,
            setGrantedCouponsGrantDateEndFilter,
            setGrantedCouponsGrantDateStartFilter,
            setGrantedCouponsNameOrCodeFilter,
            setGrantedCouponsRedemptionStatusFilter,
            setPageSizePreference
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.stateOrName = 'index.customercare.customer.coupons';
        this.searchTerm = this.state.nameOrCodeFilter || undefined;
        this.optionalParams = {
            customerId: this.state.currentCustomerId
        };

        this.initDateRange().then(() => {
            if (!this.state.selectedPageSizePreference) {
                this.actions.setPageSizePreference(this.state.userPageSizePreference);
            }

            this.redemptionStatusOptions.forEach((status) => {
                status.selected = (this.state.redemptionStatusFilter === status.id);
            });
        });
    }

    initDateRange() {
        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
        this.shortcuts = [[entireHistoryDateShortcut].concat(DateRangeShortcutHelper.getRelativeShortListShortcuts())];
        return Promise.resolve(this.onDateRangeChanged(entireHistoryDateShortcut.start, entireHistoryDateShortcut.end, entireHistoryDateShortcut));
    }

    onPageSelected(page) {
        this.actions.setCurrentGrantedCouponsPage(page);
        return this.searchCouponCodesAndRedemptions();
    }

    onDateRangeChanged(start, end, shortcut) {
        this.selectedShortcut = shortcut;
        this.actions.setGrantedCouponsGrantDateStartFilter(start);
        this.actions.setGrantedCouponsGrantDateEndFilter(end);
        return this.onPageSelected(1);
    }

    onSearchTermSubmitted(searchTerm) {
        this.searchTerm = searchTerm;
        this.actions.setGrantedCouponsNameOrCodeFilter(searchTerm);
        this.onPageSelected(1);
    }

    clearSearchText() {
        this.searchTerm = null;
        this.onSearchTermSubmitted();
    }

    onRedemptionStatusOptionSelect(status) {
        if (status) {
            this.actions.setGrantedCouponsRedemptionStatusFilter(status.id);
        }

        this.redemptionStatusOptions.forEach((status) => {
            status.selected = false;

            if (this.state.redemptionStatusFilter === status.id) {
                status.selected = true;
            }
        });

        this.onPageSelected(1);
    }

    generateRedemptionCodeFilter() {
        return {
            couponName: this.state.nameOrCodeFilter,
            grantEnd: this.state.grantDateEndFilter,
            grantStart: this.state.grantDateStartFilter,
            pageSize: this.state.selectedPageSizePreference,
            pageNumber: this.state.currentPageNumber,
            redemptionStatus: this.state.redemptionStatusFilter,
            searchString: this.state.nameOrCodeFilter,
            sortDirection: SORT_DIRECTION_DESCENDING
        };
    }

    searchCouponCodesAndRedemptions() {
        const searchRedemptionCodesFilterData = this.generateRedemptionCodeFilter();
        const searchCouponRedemptionsFilterData = {
            couponName: this.state.nameOrCodeFilter,
            searchString: this.state.nameOrCodeFilter,
            pageSize: this.state.selectedPageSizePreference
        };

        return Promise.all(
            [this.actions.searchCouponRedemptions(this.state.currentCustomerId, searchCouponRedemptionsFilterData, this.state.languageHeader),
                this.actions.searchRedemptionCodes(this.state.currentCustomerId, searchRedemptionCodesFilterData)]
        );
    }

    hasFiltersApplied() {
        return this.state.nameOrCodeFilter
            || this.state.includeSearch
            || this.state.grantDateStartFilter
            || this.state.grantDateEndFilter
            || this.state.redemptionStatusFilter;
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setPageSizePreference(pageSize);
        this.onPageSelected(1);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {},
    template: require('./customer.coupons.html'),
    controller: CustomerCouponsController,
    controllerAs: 'CustomerCouponsController'
};
