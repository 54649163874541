import {STATE_OR_NAME} from '../../../reducers/constants/new.connect.wizard.constants';
import {CustomerCareNewConnectWizardWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';

export function routing($stateProvider) {
    $stateProvider
        .state(STATE_OR_NAME, {
            url: '/newconnect',
            ignoreBack: true,
            template: '<new-connect class="u-sizeFull u-flexKeepHeight"></new-connect>',
            data: {
                windowTitle: CustomerCareNewConnectWizardWindowTitleSelector
            }
        });
}
