import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {
    CUSTOMER_WRAPUP_COMMENTS_LENGTH,
    CUSTOMER_WRAPUP_COMMENTS_DEBOUNCE
} from '../../../constants/customer.constants';

class CustomerWrapupController {
    constructor() {
        Object.assign(this, {
            CUSTOMER_WRAPUP_COMMENTS_LENGTH,
            CUSTOMER_WRAPUP_COMMENTS_DEBOUNCE,
            CustomerCareLocaleKeys,
            customerWrapupCommentsModelOptions: {
                debounce: {
                    default: CUSTOMER_WRAPUP_COMMENTS_DEBOUNCE
                }
            },
            customerWrapupModel: {}
        });
    }

    onChange() {
        this.onModelChange()(this.customerWrapupModel);
    }
}

export default {
    bindings: {
        customerReasons: '<',
        onModelChange: '&'
    },
    template: require('./customer.wrapup.form.html'),
    controller: CustomerWrapupController
};
