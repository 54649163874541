import CustomerCareKeys from '../../../../locales/keys';
import {CREDIT_CHECK_STATUS} from '../../../../reducers/constants/credit.check.constants';

class StatusIconController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            CREDIT_CHECK_STATUS
        });
    }
}

export default {
    template: require('./status.icon.html'),
    controller: StatusIconController,
    bindings: {
        statusCode: '<',
        showLabel: '<'
    },
    controllerAs: 'StatusIcon'
};
