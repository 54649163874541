import angular from 'angular';

import {config} from './billing.future.invoices.config';
import BillingFutureInvoices from './billing.future.invoices.component';
import {FUTURE_INVOICE_URL} from '../billing.payments.constants';

export default angular.module(FUTURE_INVOICE_URL, [])
    .config(config)
    .component('billingFutureInvoices', BillingFutureInvoices)
    .name;
