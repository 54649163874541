import {i18n} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';

const columnDefs = [{
    field: 'installmentNumber',
    displayName: 'INSTALLMENT',
}, {
    field: 'InstallmentStartDate',
    displayName: 'START_DATE',
    cellTemplate: require('./cellTemplates/installmentStartDate.template.html'),
    width: 200
}, {
    field: 'InstallmentDueDate',
    displayName: 'DUE_DATE',
    cellTemplate: require('./cellTemplates/installmentDueDate.template.html'),
    width: 200
}, {
    field: 'isPaid',
    displayName: 'PAID',
    width: 150
}, {
    field: 'InstallmentTotal',
    displayName: 'AMOUNT',
    cellFilter: 'invCurrency:row.entity.CurrencyCode',
    cellClass: 'c-dataType-number u-pr++',
    headerCellClass: 'u-pr++',
    width: 200,
    headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE
}];

const columnDefMapper = (overrides) => {
    return Object.assign({
        resizable: false,
        enableSorting: true,
        enableColumnMoving: false
    }, overrides, {
        displayName: i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.PROMISE_TO_PAY_SCHEDULE.TABLE_HEADERS[overrides.displayName])
    });
};

export const getColumnDefinitions = () => {
    return columnDefs.map(columnDefMapper);
};
