import {
    i18n,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    CouponRedemptionCodeErrorSelector
} from '../../../reducers/selectors/coupon.redemption.code.selectors';
import {
    CustomerCouponsErrorSelector
} from '../../../reducers/selectors/customer.coupons.selectors';
import {
    COUPON_REDEMPTION_CODE_UPDATE_ACCESS,
    COUPON_REDEMPTION_CODE_VIEW_ACCESS
} from '../../../security.attributes';

const RESEND_REDEMPTION_CODE_ID = 'RESEND_REDEMPTION_CODE_ID';
const REMOVE_REDEMPTION_CODE_ID = 'REMOVE_REDEMPTION_CODE_ID';

class RedemptionCodeDetailsHeaderController {

    constructor($ngRedux, $timeout, $q) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$q = $q;
    }

    $onInit() {
        this.CustomerLocaleKeys = LocaleKeys;

        this.resendRedemptionCodePopupConfig = {
            onRegisterApi: ({api}) => {
                this.resendRedemptionCodePopupApi = api;
            }
        };

        this.resendRedemptionCodeErrorPopupConfig = {
            onRegisterApi: (event) => {
                this.resendRedemptionCodeErrorPopupApi = event.api;
            }
        };

        this.removeRedemptionCodePopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeRedemptionCodePopupApi = api;
            }
        };

        this.removeRedemptionCodeErrorPopupConfig = {
            onRegisterApi: (event) => {
                this.removeRedemptionCodeErrorPopupApi = event.api;
            }
        };

        this.mapStateToTarget = (store) => {
            return {
                customerCouponsError: CustomerCouponsErrorSelector(store),
                lastAttemptError: CouponRedemptionCodeErrorSelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
            };
        };

        this.controllerActions = {};

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.moreMenuOptions = this.buildMoreOptionsMenu();
    }

    $onChanges() {
        if (this.state) {
            this.moreMenuOptions = this.buildMoreOptionsMenu();
        }
    }

    buildMoreOptionsMenu() {
        const moreOptionsMenu = [];
        if (this.redemptionCodeDetails.isFetching) {
            return moreOptionsMenu;
        }

        if (this.canResend) {
            moreOptionsMenu.push({
                id: RESEND_REDEMPTION_CODE_ID,
                title: i18n.translate(LocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.MORE_OPTIONS.RESEND_REDEMPTION_CODE)
            });
        }
        if (this.canRemove) {
            moreOptionsMenu.push({
                id: REMOVE_REDEMPTION_CODE_ID,
                title: i18n.translate(LocaleKeys.COUPONS.REDEMPTION_CODE_DETAILS.MORE_OPTIONS.REMOVE_REDEMPTION_CODE)
            });
        }

        return moreOptionsMenu;
    }

    openResendRedemptionCodePopup() {
        this.showResendRedemptionCodePopup = true;
        this.$timeout(this.resendRedemptionCodePopupApi.open);
    }

    closeResendRedemptionCodePopup(redemptionCodeResent, error) {
        this.showResendRedemptionCodePopup = false;
        this.resendRedemptionCodePopupApi.close();
        if (error) {
            this.resendRedemptionCodeErrorPopupApi.open();
        }
    }

    closeResendRedemptionCodeModalErrorPopup() {
        this.resendRedemptionCodeErrorPopupApi.close();
    }

    openRemoveRedemptionCodePopup() {
        this.showRemoveRedemptionCodePopup = true;
        this.$timeout(this.removeRedemptionCodePopupApi.open);
    }

    closeRemoveRedemptionCodePopup(paymentMethodUpdated, error) {
        this.showRemoveRedemptionCodePopup = false;
        this.removeRedemptionCodePopupApi.close();
        if (error) {
            this.removeRedemptionCodeErrorPopupApi.open();
        }
    }

    closeRemoveRedemptionCodeModalErrorPopup() {
        this.removeRedemptionCodeErrorPopupApi.close();
    }

    handleMoreOptionsMenuSelected(option) {
        switch (option.item.id) {
            case RESEND_REDEMPTION_CODE_ID:
                this.openResendRedemptionCodePopup();
                break;
            case REMOVE_REDEMPTION_CODE_ID:
                this.openRemoveRedemptionCodePopup();
                break;
            default:
                break;
        }
    }

    get canRemove() {
        return this.userHasUpdatePermissions && !this.hasBeenRemoved;
    }

    get hasBeenRemoved() {
        return this.redemptionCodeDetails.RedemptionCode.Status === 2;
    }


    get canResend() {
        return this.userHasViewPermissions &&
            this.redemptionCodeDetails.RedemptionCode.IsRedeemable &&
            this.redemptionCodeDetails.RedemptionCode.SubscriberId;
    }

    get userHasUpdatePermissions() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, COUPON_REDEMPTION_CODE_UPDATE_ACCESS);
    }

    get userHasViewPermissions() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, COUPON_REDEMPTION_CODE_VIEW_ACCESS);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        redemptionCodeDetails: '<',
        returnUiSref: '@'
    },
    template: require('./redemption.code.details.header.html'),
    controller: RedemptionCodeDetailsHeaderController,
};
