import angular from 'angular';

import 'invision-core';

import CreateMemberPopupComponent from './create.member.popup.component';
import createCustomerModule from '../../../createCustomer/create.customer.module';

export default angular.module('invision.customercare.customer.household.createMemberPopup', [
    'invision.core.components',
    createCustomerModule
])
    .component('createMemberPopup', CreateMemberPopupComponent)
    .name;
