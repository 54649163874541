import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareLocaleKeys from '../../../../locales/keys';

export const MINI_CART_MORE_ACTIONS = {
    CHANGE: 'change',
    CLEAR_CART: 'clear_cart',
    EDIT: 'edit',
    EDIT_SINGLE_OFFER: 'edit_single',
    REMOVE: 'remove',
};


export const moreActionMenuItems = (actions = []) => {

    const menuItems = [{
        id: MINI_CART_MORE_ACTIONS.CHANGE,
        isDisabled: false,
        title: i18n.translate(CustomerCareLocaleKeys.CHANGE)
    }, {
        id: MINI_CART_MORE_ACTIONS.CLEAR_CART,
        isDisabled: false,
        title: i18n.translate(CustomerCareLocaleKeys.REMOVE)
    }, {
        id: MINI_CART_MORE_ACTIONS.EDIT,
        isDisabled: false,
        title: i18n.translate(CustomerCareLocaleKeys.EDIT)
    }, {
        id: MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER,
        isDisabled: false,
        title: i18n.translate(CustomerCareLocaleKeys.EDIT)
    }, {
        id: MINI_CART_MORE_ACTIONS.REMOVE,
        isDisabled: false,
        title: i18n.translate(CustomerCareLocaleKeys.REMOVE)
    }];

    return menuItems.filter((item) => {
        return actions.includes(item.id);
    });
};