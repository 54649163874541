import LocaleKeys from '../../../../../locales/keys';
import {
    revokeGiftOrder,
    setRevokeGiftOrderModel
} from './revoke.gift.order.actions';
import {
    IsRevokingGiftOrderSelector,
    RevokeGiftOrderErrorMessagesSelector,
    RevokeGiftOrderModelSelector
} from './revoke.gift.order.selectors';

class RevokeGiftOrderDialogController {
    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
        this.LocaleKeys = LocaleKeys;
    }

    $onInit() {
        this._connectRedux();
        this._persistState();
    }

    $onDestroy() {
        this._disconnectRedux();
    }

    closeDialog() {
        this.formController.$setPristine();

        const onClose = this.onClose();
        onClose && onClose();
    }

    selectReasonCode(reasonCode) {
        this.reasonCode = reasonCode;
        this._persistState();
    }

    submitForm() {
        if (this.formController.$valid) {
            this._actions.revokeGiftOrder({
                reasonCode: this.state.revokeGiftOrderModel.reasonCode.id
            }, this.orderId).then(() => {
                this.formController.$setPristine();

                const onSuccess = this.onSuccess();
                onSuccess && onSuccess();
            });
        }
    }

    _connectRedux() {
        const mapStateToTarget = (state) => {
            return {
                isRevokingGiftOrder: IsRevokingGiftOrderSelector(state),
                revokeGiftOrderErrorMessages: RevokeGiftOrderErrorMessagesSelector(state),
                revokeGiftOrderModel: RevokeGiftOrderModelSelector(state)
            };
        };
        const controllerActions = {
            revokeGiftOrder,
            setRevokeGiftOrderModel
        };

        this._disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this._actions = actions;
        });
    }

    _persistState()  {
        this._actions.setRevokeGiftOrderModel({
            reasonCode: this.reasonCode || null
        });
    }
}

export default {
    bindings: {
        orderId: '<',
        reasonCode: '<',
        onClose: '&',
        onSuccess: '&'
    },
    controller: RevokeGiftOrderDialogController,
    template: require('./revoke.gift.order.dialog.html')
};
