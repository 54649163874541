import CustomerLocaleKeys from '../../../../../../locales/keys';

class ServiceLifecycleStatusDetailsController {
    constructor() {
        Object.assign(this, {
            CustomerLocaleKeys
        });
    }
}

export default {
    bindings: {
        serviceLifecycleStatus: '<'
    },
    template: require('./service.lifecycle.status.details.html'),
    controller: ServiceLifecycleStatusDetailsController
};
