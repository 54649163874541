import LocaleKeys from '../../../locales/keys';
import {
    DateRangeShortcutHelper,
    i18n,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    RouteParams
} from '../../../reducers/selectors/customer.selectors';
import {
    CurrentRedemptionCodeTableDataSelector,
    IsFetchingRedemptionCodeRedemptionsSelector,
    RedemptionCodeRedemptionsCurrentPageNumberSelector,
    RedemptionCodeRedemptionsRecordCountSelector,
    RedemptionCodeRedemptionsRedemptionDateEndFilterSelector,
    RedemptionCodeRedemptionsRedemptionDateStartFilterSelector,
    RedemptionCodeRedemptionsSelectedPageSizeSelector,
    RedemptionCodeRedemptionsTotalPageNumberSelector
} from '../../../reducers/selectors/coupon.redemption.code.selectors';
import {
    searchCouponDetailsCouponRedemptions,
    setRedemptionCodeRedemptionsCurrentPage,
    setRedemptionCodeRedemptionsDateEndFilter,
    setRedemptionCodeRedemptionsDateStartFilter,
    setRedemptionCodeRedemptionsPageSizePreference
} from '../../../reducers/actions/coupon.redemption.code.actions';
import {
    CustomerCouponsErrorSelector
} from '../../../reducers/selectors/customer.coupons.selectors';

import {
    COUPON_REDEMPTION_CODE_VIEW_ACCESS
} from '../../../security.attributes';

import {LanguageHeaderSelector} from 'invision-core/src/components/session/session.selectors';

class RedemptionCodeDetailsRedemptionsController {
    constructor($ngRedux, $timeout) {
        this.localeKeys = LocaleKeys;
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
    }

    $onInit() {
        this.columnDefs = [
            {
                field: 'RedemptionDate',
                displayName: i18n.translate(LocaleKeys.COUPONS.REDEMPTION_DATE),
                cellFilter: 'date:"shortDate"',
                width : 165
            }
        ];

        this.resendRedemptionCodePopupConfig = {
            onRegisterApi: ({api}) => {
                this.resendRedemptionCodePopupApi = api;
            }
        };

        this.resendRedemptionCodeErrorPopupConfig = {
            onRegisterApi: (event) => {
                this.resendRedemptionCodeErrorPopupApi = event.api;
            }
        };

        this.mapStateToTarget = (store) => {
            return {
                currentPageNumber: RedemptionCodeRedemptionsCurrentPageNumberSelector(store),
                customerCouponsError: CustomerCouponsErrorSelector(store),
                isFetchingRedemptionCodeRedemptions: IsFetchingRedemptionCodeRedemptionsSelector(store),
                languageHeader: LanguageHeaderSelector(store),
                numberOfPages: RedemptionCodeRedemptionsTotalPageNumberSelector(store),
                recordCount: RedemptionCodeRedemptionsRecordCountSelector(store),
                redemptionDateEndFilter: RedemptionCodeRedemptionsRedemptionDateEndFilterSelector(store),
                redemptionDateStartFilter: RedemptionCodeRedemptionsRedemptionDateStartFilterSelector(store),
                routeParams: RouteParams(store),
                selectedPageSizePreference: RedemptionCodeRedemptionsSelectedPageSizeSelector(store),
                tableData: {
                    columnDefs: this.columnDefs,
                    data: CurrentRedemptionCodeTableDataSelector(store)
                },
                userPageSizePreference: SessionSelectors.PageSizePreferenceSelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store)
            };
        };

        this.controllerActions = {
            searchCouponDetailsCouponRedemptions,
            setRedemptionCodeRedemptionsCurrentPage,
            setRedemptionCodeRedemptionsDateEndFilter,
            setRedemptionCodeRedemptionsDateStartFilter,
            setRedemptionCodeRedemptionsPageSizePreference
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, this.controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.routeParams.customerId) {
            this.columnDefs.push({
                field: 'Redeemer',
                displayName: i18n.translate(LocaleKeys.COUPONS.REDEEMER),
                width: '*'
            });
        }
        this.columnDefs.push(
            {
                field: 'OrderNumber',
                displayName: i18n.translate(LocaleKeys.ORDER_NUMBER),
                cellTemplate: require('./cellTemplates/order.html'),
                width: 150
            },
            {
                field: 'ProductPricingPlan',
                displayName: i18n.translate(LocaleKeys.COUPONS.PRODUCT_PRICING_PLAN),
                width: '*'
            }
        );
        this.shouldShowEmptyState = undefined;

        this.initDateRange();
    }

    initDateRange() {
        const entireHistoryDateShortcut = DateRangeShortcutHelper.getNullableOption(i18n.translate(LocaleKeys.ALL_TIME));
        this.shortcuts = [[entireHistoryDateShortcut].concat(DateRangeShortcutHelper.getRelativeShortListShortcuts())];
        this.onDateRangeChanged(entireHistoryDateShortcut.start, entireHistoryDateShortcut.end, entireHistoryDateShortcut);
    }

    onPageSelected(page) {
        this.actions.setRedemptionCodeRedemptionsCurrentPage(page);
        this.searchCouponDetailsCouponRedemptions().then((response) => {
            this.shouldShowEmptyState = (this.shouldShowEmptyState === undefined)? Boolean(!response.RecordCount) : false;
        });
    }

    onDateRangeChanged(start, end, shortcut) {
        this.selectedShortcut = shortcut;
        this.actions.setRedemptionCodeRedemptionsDateStartFilter(start);
        this.actions.setRedemptionCodeRedemptionsDateEndFilter(end);
        this.onPageSelected(1);
    }

    onPageSizeOptionSelected(pageSize) {
        this.actions.setRedemptionCodeRedemptionsPageSizePreference(pageSize);
        this.onPageSelected(1);
    }

    searchCouponDetailsCouponRedemptions() {
        if (!this.state.selectedPageSizePreference) {
            this.actions.setRedemptionCodeRedemptionsPageSizePreference(this.state.userPageSizePreference);
        }

        const searchFilterData = {
            endDate: this.state.redemptionDateEndFilter,
            startDate: this.state.redemptionDateStartFilter,
            pageNumber: this.state.currentPageNumber,
            pageSize: this.state.selectedPageSizePreference,
            couponRedemptionCodeId: this.state.routeParams.couponRedemptionCodeId
        };
        return this.actions.searchCouponDetailsCouponRedemptions(this.state.routeParams.customerId, searchFilterData, this.state.languageHeader);
    }

    openResendRedemptionCodePopup() {
        this.showResendRedemptionCodePopup = true;
        this.$timeout(this.resendRedemptionCodePopupApi.open);
    }

    closeResendRedemptionCodePopup(redemptionCodeResent, error) {
        this.showResendRedemptionCodePopup = false;
        this.resendRedemptionCodePopupApi.close();
        if (error) {
            this.resendRedemptionCodeErrorPopupApi.open();
        }
    }

    closeResendRedemptionCodeModalErrorPopup() {
        this.resendRedemptionCodeErrorPopupApi.close();
    }

    canResend() {
        return this.userHasViewPermissions &&
            this.redemptionCodeDetails.RedemptionCode.IsRedeemable &&
            this.redemptionCodeDetails.RedemptionCode.SubscriberId;
    }

    get userHasViewPermissions() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, COUPON_REDEMPTION_CODE_VIEW_ACCESS);
    }

    $onDestroy() {
        this.disconnectRedux();
        this.resendRedemptionCodePopupApi.close();
        this.resendRedemptionCodeErrorPopupApi.close();
    }
}

export default {
    bindings: {
        redemptionCodeId: '<',
        redemptionCodeDetails: '<'
    },
    template: require('./redemption.code.details.redemptions.html'),
    controller: RedemptionCodeDetailsRedemptionsController,
};
