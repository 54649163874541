import angular from 'angular';
import 'invision-core';

import {routing as RoutingConfig} from './configure.autopay.config';
import CustomerConfigureAutopayController from './configure.autopay.component';

export default angular.module('invision.customercare.customer.configureAutopay', [
    'invision.core.components'
])
    .config(RoutingConfig)
    .component('customerConfigureAutopay', CustomerConfigureAutopayController)
    .name;

