import clone from 'ramda/src/clone';
import curry from 'ramda/src/curry';
import equals from 'ramda/src/equals';
import innerJoin from 'ramda/src/innerJoin';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import none from 'ramda/src/none';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import propEq from 'ramda/src/propEq';
import values from 'ramda/src/values';

import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {
    LanguageSelector,
    UserSecurityAttributesSelector
} from 'invision-core/src/components/session/session.selectors';

import {
    CurrentCustomerSelector,
    RouteParams
} from '../../../../../../../../reducers/selectors/customer.selectors';
import {
    IsCreatingModeSelector,
    IsModifyingModeSelector,
    IsReplacingModeSelector
} from '../../../create.products.order.wizard.selectors';
import {
    CouponCodesSelector,
    HasSavedSeason,
    HasOtherSavedSeasonSelections,
    HaveSelectedProductMetadata,
    IncludedEpisodesSelector,
    IncludedProductsSelector,
    IndividualEpisodesConfigurationViewModelSelector,
    InvalidItemsSelector,
    IsCalculatingQuoteSelector,
    IsFetchingOrderableProductData,
    IsFetchingSingleProductContext,
    IsSelectedProductAdHoc,
    IsSelectedProductSeriesContainer,
    IsSelectedProductDbss,
    IsSelectedProductServiceFeature,
    OptionalProductsConfigurationViewModelSelector,
    OtherSeasonSelectionCount,
    OtherSeasonsProductsToAdd,
    PicklistProductsConfigurationViewModelSelector,
    PicklistSelectionMessageSelector,
    ProductDialogErrorMessagesSelector,
    ProductsWithMetadataMapSelector,
    RemainingEpisodicProductIdsSelector,
    ReplaceCartItemSelector,
    SavedOtherSeasonSelections,
    SearchCouponCodeSelector,
    SelectedCartItemPricingPlanIdSelector,
    SelectedCartItemQuantitySelector,
    SelectedProductGuidanceRatingsSelector,
    SelectedProductSelector,
    SelectedProductIdSelector,
    SelectedProductDescriptionSelector,
    SelectedSeriesContainerProductId,
    SelectedShoppingCartItemIndexSelector,
    SeriesContainerEpisodicBundlesOptions,
    SeriesContainerIncludedEpisodicBundles,
    ShoppingCartSelector,
    SubmitChangeOfServiceForServiceFeatureRequestSelector,
} from '../../../../../../../../reducers/selectors/products.order.selectors';
import {getPricingPlanAvailabilityDate} from '../../../../../../../../reducers/helpers/products.order.selector.helpers';
import {
    CurrentSubscriptionSelector,
    EditSubscriptionItemSelector,
    InstancePropertySelector,
    PricingPlanOptionsSelector,
    SubscriptionPricingPlanIdSelector
} from '../../../../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    ChangeImmediateSelector,
    CurrentStepSelector,
    FeatureOrderProductPricingPlanQuantitySelector,
    PaymentInstrumentIdsSelector
} from '../../../../../../../../reducers/selectors/products.wizard.selectors';
import {OrdersSelector} from '../../../../../../../../reducers/selectors/order.history.selectors';
import {
    addToAndUpdateShoppingCart,
    addMultipleAndUpdateShoppingCart,
    calculateModifyOrderQuote,
    editAndUpdateShoppingCart,
    editMultipleAndUpdateShoppingCart,
    setShoppingCartItemIndex,
    updateServiceFeatureQuantityPromise
} from '../../../../../../../../reducers/actions/products.wizard.actions';
import LocaleKeys from '../../../../../../../../locales/keys';
import {
    clearSavedSeasonsSelections,
    clearSelectedOptions,
    clearShoppingCartError,
    getOrderablePricingExtended,
    getProductMetadataBatch,
    loadAdditionalEpisodes,
    saveSeriesContainerSeasonSelections,
    setEpisodes,
    setOptionalProducts,
    setPicklistProducts,
    setProductOrderCouponCodes,
    setReplacementItem,
    setSelectedProductId,
    setSelectedSeriesContainerProductId,
    setSelectedSeriesContainerSeasons
} from '../../../../../../../../reducers/actions/products.order.actions';
import {
    DBSS_UPDATE_CART_ADDITIONAL_OPTIONS,
    GIFT_CARD_INSTANCE_PROPERTY_NAMES
} from '../../../../../../../../reducers/constants/products.wizard.constants';
import {pascalCaseKeys} from '../../../../../../../../utilities/object.formatting.helper';
import {
    getEditItems,
    getReplaceItems,
    noItemsModified
} from '../../../subscriptionHelpers/subscription.order.helper';
import {MAX_SERVICE_FEATURE_ITEMS} from '../../../../../../../../customercare.constants';
import {STRUCTURE_TYPES} from '../../../../../../../../reducers/constants/structure.type.constants';
import {
    processServiceFeatures,
    splitIndividualProducts
} from './product.dialog.helpers';
import {
    AD_HOC_OVERRIDE,
    PRICE_OVERRIDE_ACCESS
} from '../../../../../../../../security.attributes';
import {EMPTY_OBJECT} from 'invision-core/src/constants/common.constants';

const DESCRIPTION_LENGTH_LIMIT = 400;
const FAILED_CALL = 'FAILED_CALL';
const TAB_INDEX = {
    DETAILS: 0,
    OPTIONS: 1
};
const ITV_QUANTITY = {
    MIN: 1,
    MAX: 100
};

// TODO - Customer Order refactor this dialog options tab to consume presentational components for option pricing DBSS vs ITV

class ProductDialogController {
    constructor($filter, $ngRedux, $timeout, uiNotificationService, $anchorScroll) {
        Object.assign(this, {
            $anchorScroll,
            $filter,
            $ngRedux,
            $timeout,
            _addedProducts: [],
            coreLocaleKeys: CoreLocaleKeys,
            countOfSelectedPricingPlans: {},
            descriptionLengthLimit: DESCRIPTION_LENGTH_LIMIT,
            formSubmittedFromOptionsTab: false,
            hasIncludedWithInstanceProperties: false,
            hasIncludedWithoutInstanceProperties: false,
            includedProductInstanceProperties: {},
            isExceededCartMaximumQuantity: false,
            ITV_QUANTITY,
            LocaleKeys,
            MAX_SERVICE_FEATURE_ITEMS,
            modalCartItemTotal: 0,
            NOW_DATETIME_ISOSTRING: new Date().toISOString(),
            picklistMessage: null,
            productClassifications: PRODUCT_CLASSIFICATIONS,
            productHasOverrides: false,
            quantity: 1,
            savedServiceFeatureModel: EMPTY_OBJECT,
            selectedIndividualEpisodes: null,
            selectedOptionalItems: null,
            selectedPicklistItems: [],
            selectedPricingPlanId: null,
            selectIndividualEpisodes: false,
            selectedEpisodicBundle: 0,
            shoppingCartForServiceFeatures: [],
            showDescriptionOverflow: false,
            TAB_INDEX,
            tabs: [{
                name: i18n.translate(LocaleKeys.PRODUCT_OPTIONS.DETAILS),
                active: true
            }, {
                name: i18n.translate(LocaleKeys.PRODUCT_OPTIONS.OPTIONS),
                active: false
            }],
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                availableSeasons: SeriesContainerIncludedEpisodicBundles(store),
                changeImmediately: ChangeImmediateSelector(store),
                couponCodes: CouponCodesSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentStep: CurrentStepSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                editSubscriptionItem: EditSubscriptionItemSelector(store),
                featureOrderQuantities: FeatureOrderProductPricingPlanQuantitySelector(store),
                hasSavedSeason: HasSavedSeason(store),
                hasOtherSavedSeasonSelections: HasOtherSavedSeasonSelections(store),
                haveSelectedProductMetadata: HaveSelectedProductMetadata(store),
                includedEpisodes: IncludedEpisodesSelector(store),
                includedProducts: IncludedProductsSelector(store),
                individualEpisodeConfiguration: IndividualEpisodesConfigurationViewModelSelector(store),
                invalidItems: InvalidItemsSelector(store),
                isCalculatingQuote: IsCalculatingQuoteSelector(store),
                isCreatingMode: IsCreatingModeSelector(store),
                isFetchingOrderableProductData: IsFetchingOrderableProductData(store),
                isFetchingSingleProductContext: IsFetchingSingleProductContext(store),
                isModifyingMode: IsModifyingModeSelector(store),
                isReplacingMode: IsReplacingModeSelector(store),
                isSelectedProductAdHoc: IsSelectedProductAdHoc(store),
                isSelectedProductServiceFeature: IsSelectedProductServiceFeature(store),
                isSelectedProductDbss: IsSelectedProductDbss(store),
                isSelectedProductSeriesContainer: IsSelectedProductSeriesContainer(store),
                language: LanguageSelector(store),
                optionalProductConfiguration: OptionalProductsConfigurationViewModelSelector(store),
                orders: OrdersSelector(store),
                otherSeasonSelectionCount: OtherSeasonSelectionCount(store),
                otherSeasonsProductsToAdd: OtherSeasonsProductsToAdd(store),
                paymentInstrumentIds: PaymentInstrumentIdsSelector(store),
                picklistMessage: PicklistSelectionMessageSelector(store),
                picklistProductConfiguration: PicklistProductsConfigurationViewModelSelector(store),
                pricingPlansForModifySubscription: PricingPlanOptionsSelector(store),
                productDialogErrorMessages: ProductDialogErrorMessagesSelector(store),
                productsWithMetadataMap: ProductsWithMetadataMapSelector(store),
                remainingEpisodicProductIds: RemainingEpisodicProductIdsSelector(store),
                replaceItem: ReplaceCartItemSelector(store),
                routeParams: RouteParams(store),
                savedOtherSeasonSelections: SavedOtherSeasonSelections(store),
                searchCouponCode: SearchCouponCodeSelector(store),
                selectedProduct: SelectedProductSelector(store),
                selectedProductId: SelectedProductIdSelector(store),
                selectedProductDescription: SelectedProductDescriptionSelector(store),
                selectedProductGuidanceRatings: SelectedProductGuidanceRatingsSelector(store),
                selectedSeriesContainerProductId: SelectedSeriesContainerProductId(store),
                selectedShoppingCartItemIndex: SelectedShoppingCartItemIndexSelector(store),
                selectedCartItemPricingPlanId: SelectedCartItemPricingPlanIdSelector(store),
                selectedCartItemQuantity: SelectedCartItemQuantitySelector(store),
                seriesEpisodicBundles: SeriesContainerEpisodicBundlesOptions(store),
                shoppingCart: ShoppingCartSelector(store),
                structureTypes: MetadataCodeTypeSelector(CODES.StructureType, store),
                structureTypesLoaded: MetadataCodeLoadedSelector(CODES.StructureType, store),
                submitChangeOfServiceForServiceFeatureRequest: SubmitChangeOfServiceForServiceFeatureRequestSelector(store),
                subscriptionInstanceProperties: InstancePropertySelector(store),
                subscriptionPricingPlanId: SubscriptionPricingPlanIdSelector(store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            addToAndUpdateShoppingCart,
            addMultipleAndUpdateShoppingCart,
            calculateModifyOrderQuote,
            clearSavedSeasonsSelections,
            clearSelectedOptions,
            clearShoppingCartError,
            editAndUpdateShoppingCart,
            editMultipleAndUpdateShoppingCart,
            fetchCodeTypes,
            loadAdditionalEpisodes,
            getOrderablePricingExtended,
            getProductMetadataBatch,
            saveSeriesContainerSeasonSelections,
            setEpisodes,
            setOptionalProducts,
            setPicklistProducts,
            setProductOrderCouponCodes,
            setReplacementItem,
            setSelectedProductId,
            setSelectedSeriesContainerProductId,
            setSelectedSeriesContainerSeasons,
            setShoppingCartItemIndex,
            updateServiceFeatureQuantityPromise
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
            this._initializeOrUpdateComponentData();
        });

        this._instanceProperties = clone(this.state.subscriptionInstanceProperties || this.state.selectedProduct.InstanceProperties) || {};

        this.handleTabSelection = (selectedTab) => {
            this._selectTab(selectedTab);
        };

        this._selectTab(this._getTabByNameOrDefault(this.selectedTab));

        if (!this.state.structureTypesLoaded) {
            this.actions.fetchCodeTypes(CODES.StructureType);
        }
        this.hasAdHocOverrideAccess = hasAccess(this.state.userSecurityAttributes, AD_HOC_OVERRIDE);
        this.hasPriceOverrideAccess = hasAccess(this.state.userSecurityAttributes, PRICE_OVERRIDE_ACCESS);

        if (this.state.selectedShoppingCartItemIndex !== null) {
            // zero value is true for selectedShoppingCartItemIndex
            this.quantity = clone(this.state.selectedCartItemQuantity);
            this.selectedPricingPlanId = clone(this.state.selectedCartItemPricingPlanId);
            this.isEditMode = this.state.shoppingCart && this.itemExistsInCart() ? true : false;
        }

        if (this.state.subscriptionPricingPlanId && this.isModifyMode()) {
            this.selectedPricingPlanId = clone(this.state.subscriptionPricingPlanId);
        }

        if (this.state.editSubscriptionItem?.ChangePricingPlan?.Id && this.isModifyMode()) {
            this.selectedPricingPlanId = this.state.editSubscriptionItem.ChangePricingPlan.Id;
        }

        // Skip RPC and Product metadata call for product that has already been visited, response of these call don't change often
        // On destroy of this component we are reinitializing the product order store which will cause this to remake this call
        // While in the product order flow this will prevent making repeated and unneeded RPC and Metadata calls
        if (!this.state.haveSelectedProductMetadata) {
            this.actions.getOrderablePricingExtended(this.state.selectedProductId,
                this.state.currentCustomer.Id,
                this.state.searchCouponCode,
                this.state.routeParams.serviceTypeId)
                .then(() => {
                    this.initializeData();
                    if (this.pricingPlans) {
                        this.checkPricingPlansInstances();
                    }
                    const dialogPopulatedCallbackFn = this.onDialogPopulated();
                    const childProductIds = this._getBundleProductIds();

                    if (childProductIds.length) {
                        this.actions.getProductMetadataBatch(childProductIds).then(() => {
                            dialogPopulatedCallbackFn && dialogPopulatedCallbackFn();
                        });
                    } else {
                        dialogPopulatedCallbackFn && dialogPopulatedCallbackFn();
                    }
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);

                    const failureCallbackFn = this.onFailure();
                    failureCallbackFn && failureCallbackFn();
                });
        } else {
            this.initializeData();
            if (this.pricingPlans) {
                this.checkPricingPlansInstances();
            }
            const dialogPopulatedCallbackFn = this.onDialogPopulated();
            dialogPopulatedCallbackFn && dialogPopulatedCallbackFn();
        }

        this.pricingPlanQuantityChanged = () => {
            return (quantity, pricingPlanId, pricingPlanName, shoppingCartItemBillerRuleDetails) => {
                this.enableAddToCartButton(quantity, pricingPlanId, pricingPlanName, shoppingCartItemBillerRuleDetails);
            };
        };

        this.pricingPlanQuantityChangedByProductOrder = curry((pricingPlanId, quantity) => {
            this.enableAddToCartButton(quantity, pricingPlanId.Id);
        });
    }

    enableAddToCartButton(quantity, pricingPlanId, pricingPlanName, shoppingCartItemBillerRuleDetails) {
        // TODO (CustomerOrder): OnChange should call an action to update the model
        // Move logic to a selector also and use the MaxQuantity constant that applies to all dbss carts
        this.modalCartItemTotal = 0;
        const product = {
            productId: this.state.selectedProductId,
            pricingPlanId: pricingPlanId,
            childItems: [],
            quantity: quantity
        };
        this.pricingPlans.forEach(currentPricingPlan => {
            if (currentPricingPlan.Id === pricingPlanId) {
                this.countOfSelectedPricingPlans[pricingPlanId] = convertStringToNumber(quantity);
                currentPricingPlan.cartQuantity = convertStringToNumber(quantity);
                currentPricingPlan.ShoppingCartItemBillerRuleDetails = shoppingCartItemBillerRuleDetails;
            }
            this.modalCartItemTotal += currentPricingPlan.cartQuantity;
        });

        this.isExceededCartMaximumQuantity = (this.modalCartItemTotal > this.state.selectedProduct.MaxNonBulkQuantity) ||
                    (this.state.selectedProduct.ProductContext.ServiceFeatureCardinality ?
                        this.modalCartItemTotal > this.state.selectedProduct.ProductContext.ServiceFeatureCardinality.MaximumQuantity :
                        false);
        const itemExistsInCart = this.shoppingCartForServiceFeatures && this.shoppingCartForServiceFeatures.length ?
            this.shoppingCartForServiceFeatures.find((item) => {
                return item.pricingPlanId === pricingPlanId;
            }) : null;

        if (itemExistsInCart) {
            itemExistsInCart.quantity = quantity;
        } else {
            this.shoppingCartForServiceFeatures.push(product);
        }
    }


    checkPricingPlansInstances() {
        this.countOfSelectedPricingPlans = this.pricingPlans
            .reduce((countPrim, selectedPricingPlan) => {
                countPrim[selectedPricingPlan.Id] = selectedPricingPlan.cartQuantity;
                return countPrim;
            }, {});
    }

    // TODO - Customer Order a lot of this needs to be moved into selectors instead of controller logic that is repeatedly called
    // also we should never be modifying state from a selector in controller code like mappedPricingPlan is below
    initializeData() {
        if (this.selectedPricingPlanId) {
            if (!isNil(this.state.optionalProductConfiguration)) {
                this.state.optionalProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
            }

            if (!isNil(this.state.picklistProductConfiguration)) {
                this.state.picklistProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
            }
        }

        if (this.state.isSelectedProductServiceFeature) {
            this.isEditMode = this._isServiceFeatureInEditMode();
        }
        this.removeSelectedPlanIfNotAvailable();
        this.pricingPlans = this.getPricingPlans();
        this._setDefaultValuesForInstanceProperties();

        this.actions.setSelectedSeriesContainerProductId(this.state.selectedProduct.StructureType === STRUCTURE_TYPES.SERIES_CONTAINER
            ? this.state.selectedProduct.Id : null);

        // No Options tab for series container structure type, long term we need to move this into selectors to build the tabs
        if (this.state.selectedProduct.StructureType === STRUCTURE_TYPES.SERIES_CONTAINER) {
            this.tabs.pop();
            this._selectTab(this.tabs[TAB_INDEX.DETAILS]);
            this.actions.setSelectedSeriesContainerSeasons(values(this.state.selectedProduct.ReferencedProducts));
            this.actions.clearSavedSeasonsSelections();
        }
    }

    _isServiceFeatureInEditMode() {
        let isEditMode = null;
        isEditMode = !!((this.state.submitChangeOfServiceForServiceFeatureRequest.AddItems || []).find((item) => {
            return item.ProductId === this.state.selectedProductId;
        }));
        if (!isEditMode) {
            const removeItems =  this.state.submitChangeOfServiceForServiceFeatureRequest.RemoveItems.filter((item) => {
                return item.ProductId === this.state.selectedProductId;
            });
            const numExisting = this.state.featureOrderQuantities && this.state.featureOrderQuantities.length ?
                this.state.featureOrderQuantities.filter((quantity) => {
                    return quantity.productId === this.state.selectedProductId;
                }) : [];
            isEditMode = numExisting.length > removeItems.length;
        }
        return isEditMode;
    }

    _addDbssProductsToCart(products) {
        const model = {
            products: splitIndividualProducts(products),
            customerId: this.state.currentCustomer.Id,
            shoppingCart: this.state.shoppingCart
        };

        // DBSS products need to be split out individually and not combined
        return this.actions.addMultipleAndUpdateShoppingCart(model, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS);
    }

    _prepMultipleItemsForCart(products) {
        // DBSS at the moment does not support coupon code so don't think any code related to coupon codes is needed
        const couponCodes = pluck('name', this.state.couponCodes);
        this._addedProducts = products;
        const model = {
            products: products,
            customerId: this.state.currentCustomer.Id,
            shoppingCart: this.state.shoppingCart,
            redemptionCodes: couponCodes || []
        };
        if (this.state.shoppingCart && this.itemExistsInCart()) {
            this.isEditMode = true;
        }

        if (this.state.isSelectedProductServiceFeature) {
            const shoppingCart = processServiceFeatures(
                this.state.selectedProductId,
                this.state.selectedProduct,
                this.pricingPlans,
                this.state.shoppingCart,
                this.state.featureOrderQuantities,
                this.state.routeParams,
                this.state.orders,
                this.state.submitChangeOfServiceForServiceFeatureRequest
            );
            this.actions.updateServiceFeatureQuantityPromise({
                AddItems: shoppingCart.AddItems,
                RemoveItems: shoppingCart.RemoveItems
            });
            model.shoppingCart = shoppingCart;
            model.products = [];
        }

        return (this.isEditMode ?
            this.actions.editMultipleAndUpdateShoppingCart(model, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS) :
            this.actions.addMultipleAndUpdateShoppingCart(model, DBSS_UPDATE_CART_ADDITIONAL_OPTIONS))
            .then(() => {
                if (this.state.isSelectedProductServiceFeature) {
                    this.savedServiceFeatureModel = model;
                }
            }) ;
    }

    _setDefaultValuesForInstanceProperties() {
        if (this.state.selectedProduct.InstanceProperties) {
            this.state.selectedProduct.InstanceProperties.map((instanceProp) => {
                if (!this._instanceProperties[instanceProp.Id]) {
                    if (equals(GIFT_CARD_INSTANCE_PROPERTY_NAMES.SENDER_NAME, instanceProp.Name)) {
                        this._instanceProperties[instanceProp.Id] = `${this.state.currentCustomer.FirstName} ${this.state.currentCustomer.LastName}`;
                    } else if (equals(GIFT_CARD_INSTANCE_PROPERTY_NAMES.SENDER_EMAIL, instanceProp.Name)) {
                        this._instanceProperties[instanceProp.Id] = this.state.currentCustomer.Email;
                    }
                }
            });
        }
    }

    $onChanges() {
        // TODO (CustomerOrder): Do not bind to state or modify state. This should only be done through actions.
        if (this.selectedPricingPlanId) {
            if (!isNil(this.state) && !isNil(this.state.optionalProductConfiguration)) {
                this.state.optionalProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
            }

            if (!isNil(this.state) && !isNil(this.state.picklistProductConfiguration)) {
                this.state.picklistProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
            }
        }
    }

    _getBundleProductIds() {
        const children = this.state.individualEpisodeConfiguration.items
            .concat(this.state.optionalProductConfiguration.items || [])
            .concat(this.state.includedProducts || []);

        return children.map((child) => {
            return child.product.Id;
        });
    }

    _initializeOrUpdateComponentData() {
        // TODO (CustomerOrder): Do not bind to state or modify state. This should only be done through actions.
        if (this.state.optionalProductConfiguration && this.selectedPricingPlanId) {
            this.state.optionalProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
            this.state.picklistProductConfiguration.mappedPricingPlanId = this.selectedPricingPlanId;
        }

        if (this.state.includedProducts) {
            this.hasIncludedWithInstanceProperties = this.state.includedProducts.some((prod) => {
                return !isNil(prod.product.InstanceProperties) && !isEmpty(prod.product.InstanceProperties);
            });

            this.hasIncludedWithoutInstanceProperties = this.state.includedProducts.some((prod) => {
                return isNil(prod.product.InstanceProperties) || isEmpty(prod.product.InstanceProperties);
            });
        }
    }

    _selectTab(selectedTab) {
        this.tabs.forEach((tab) => {
            tab.active = tab.name === selectedTab.name;
        });
    }

    isModifyMode() {
        // modify mode is not set on state until checkout page, also need to pass in is-modify from modify.modal
        return this.state.isModifyingMode || this.isModify;
    }

    handleChangeEpisodicBundle() {
        // Save selections when leaving a episodic bundle
        if (this.state.selectedProduct.Id !== this.state.selectedSeriesContainerProductId) {
            this.actions.saveSeriesContainerSeasonSelections(this.state.selectedProduct.Id, this.selectedPricingPlanId,
                !this.selectedPricingPlanId && this.selectedIndividualEpisodes && this.selectedIndividualEpisodes.items ?
                    this.selectedIndividualEpisodes.items.map((episode) => {
                        return {
                            productId: episode.product.Id,
                            pricingPlanId: episode.selectedPlanId
                        };
                    }) : null, this.quantity);
        }

        if (!this.selectedEpisodicBundle) {
            // set the selected product id back to the series container
            this.actions.setSelectedProductId(this.state.selectedSeriesContainerProductId);
            this.tabs.pop();
            this._selectTab(this.tabs[TAB_INDEX.DETAILS]);
        } else {
            if (this.tabs.length === 1) {
                this.tabs.push({
                    name: i18n.translate(this.LocaleKeys.PRODUCT_OPTIONS.OPTIONS),
                    active: false
                });
                this._selectTab(this.tabs[TAB_INDEX.OPTIONS]);
            }

            // set the selected product to the episodic bundle
            this.actions.setSelectedProductId(this.selectedEpisodicBundle);

            // episodic bundle has been visited before, no need to retrieve data again
            if (!this.state.productsWithMetadataMap[this.selectedEpisodicBundle]) {
                // get the episode bundle and related episodes data
                this.actions.getOrderablePricingExtended(this.selectedEpisodicBundle,
                    this.state.currentCustomer.Id,
                    this.state.searchCouponCode,
                    this.state.routeParams.serviceTypeId)
                    .then(() => {
                        this.initSeasonData();

                        const childProductIds = this._getBundleProductIds();

                        if (childProductIds.length) {
                            this.actions.getProductMetadataBatch(childProductIds);
                        }

                        this.resetSeasonData();
                    });
            } else {
                this.initSeasonData();
                // check if we have saved this season selections before
                if (this.state.hasSavedSeason) {
                    if ((this.state.savedOtherSeasonSelections[this.selectedEpisodicBundle]).pricingPlanId) {
                        // hydrate season pricing plan selection
                        this.selectedPricingPlanId = clone((this.state.savedOtherSeasonSelections[this.selectedEpisodicBundle]).pricingPlanId);
                        this.selectIndividualEpisodes = false;
                        this.selectedIndividualEpisodes = null;
                    } else {
                        // hydrate season episode selections in selector for episode view model
                        this.selectedPricingPlanId = null;
                        this.selectIndividualEpisodes = true;
                        if (!this.selectedIndividualEpisodes) {
                            this.selectedIndividualEpisodes = {};
                        }
                        this.selectedIndividualEpisodes.items = clone(this.state.individualEpisodeConfiguration.items.filter(propEq(true, 'isSelected')));
                    }
                    this.quantity = clone((this.state.savedOtherSeasonSelections[this.selectedEpisodicBundle]).quantity);
                } else {
                    // no saved season data, reset to no selections
                    this.resetSeasonData();
                }
            }
        }
    }

    initSeasonData() {
        this.removeSelectedPlanIfNotAvailable();
        this.pricingPlans = this.getPricingPlans();
        this._setDefaultValuesForInstanceProperties();
    }

    resetSeasonData() {
        this.quantity = 1;
        this.selectedPricingPlanId = null;
        this.selectIndividualEpisodes = false;
        this.selectedIndividualEpisodes = null;
    }

    getSeriesContainerCurrentSeasonSelectionCount() {
        return this.state.otherSeasonSelectionCount + (this.selectedPricingPlanId ? this.quantity :
            this.selectedIndividualEpisodes && this.selectedIndividualEpisodes.items ?
                this.selectedIndividualEpisodes.items.length * this.quantity : 0);
    }

    getSubmitButtonLabel() {
        return this.tabs[TAB_INDEX.DETAILS].active ? i18n.translate(this.LocaleKeys.SELECT_OPTIONS) :
            (this.submitLabel || i18n.translate(this.state.isReplacingMode ?  this.LocaleKeys.SUBSCRIPTIONS.REPLACE :
                this.isEditMode ? this.LocaleKeys.CART.SUBMIT_UPDATE :
                    this.state.isSelectedProductSeriesContainer && this.getSeriesContainerCurrentSeasonSelectionCount() ?
                        this.LocaleKeys.ADD_TO_CART_WITH_COUNT : this.LocaleKeys.ADD_TO_CART, {
                count: this.getSeriesContainerCurrentSeasonSelectionCount()
            }));
    }

    getPricingPlans() {
        return clone(this.isModifyMode() ?
            this.filterOutUnavailablePlans(this.state.pricingPlansForModifySubscription) :
            this.state.selectedProduct.ProductContext.OrderablePricingPlans).filter((pricingPlan) => {
            if (this.state.isSelectedProductServiceFeature) {
                if (pricingPlan.AssociatedServices && pricingPlan.AssociatedServices.length) {
                    this.associatedServices = pricingPlan.AssociatedServices.filter((item) => {
                        return item.Status === OFFER_STATUS_INDICATOR_STATUS.ACTIVE && item.Id.Value === this.state.routeParams.serviceTypeId;
                    });
                }
                return this.associatedServices && this.associatedServices.length;
            } else {
                return pricingPlan;
            }
        }).map((pricingPlan) => {
            let countOfPricingPlan = 0;
            if (this.state.isSelectedProductDbss) {
                // TODO: (CustomerOrder) Temporary solution. Proper solution will be to move all of this to a selector.
                const pricingPlanRAndOBRIs = pricingPlan && pricingPlan.PricingPlanBillerRuleInstances ?
                    [...pricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                        ...pricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances] : [];
                if (this.state.isSelectedProductServiceFeature) {
                    pricingPlan.billerRuleInstances = pricingPlan.BillerRuleInstanceThumbnails.map((bri) => {
                        bri.chargeAmount = bri.Amount;
                        bri.discountAmount = bri.DiscountAmount;
                        bri.hasDiscount = !!bri.DiscountAmount;
                        bri.name = pricingPlanRAndOBRIs.find(({BillerRuleConfigurationId}) => {
                            return bri.BillerRuleConfigurationId === BillerRuleConfigurationId;
                        }).Name;
                        bri.isOverrideSelected = false;
                        bri.isOverrideable = this.hasAdHocOverrideAccess && pricingPlanRAndOBRIs.some((pricingPlanBRI) => {
                            return pricingPlanBRI.Id === bri.Id && pricingPlanBRI.AllowChargeOverride;
                        });
                        return bri;
                    });
                } else {
                    pricingPlan.billerRuleInstances = pricingPlanRAndOBRIs.map((bri) => {
                        bri.chargeAmount = pathOr(0, ['BillerRuleInstanceCharges', 0, 'ChargeAmount'], bri);
                        bri.discountAmount = pathOr(0, ['BillerRuleInstanceCharges', 0, 'DiscountActualAmount'], bri);
                        bri.hasDiscount = !!bri.discountAmount;
                        bri.nonDiscountChargeAmount = pathOr(0, ['BillerRuleInstanceCharges', 0, 'NonDiscountChargeAmount'], bri);
                        bri.name = pricingPlan.BillerRuleInstances.find(({BillerRuleConfigurationId}) => {
                            return bri.BillerRuleConfigurationId === BillerRuleConfigurationId;
                        }).Name;
                        bri.isOverrideSelected = false;
                        bri.isOverrideable = this.hasAdHocOverrideAccess && pricingPlanRAndOBRIs.some((pricingPlanBRI) => {
                            return pricingPlanBRI.Id === bri.Id && pricingPlanBRI.AllowChargeOverride;
                        });
                        bri.overriddenAmount = bri.chargeAmount >= 0 ? bri.chargeAmount : bri.chargeAmount * -1;
                        return bri;
                    });
                }

                this.productHasOverrides = !this.productHasOverrides ? pricingPlan.billerRuleInstances.some((bri) => {
                    return bri.isOverrideable;
                }) : true;

                if (this.state.isSelectedProductAdHoc) {
                    pricingPlan.cartQuantity = 0;
                } else {
                    if (this.state.shoppingCart.Items) {
                        this.state.shoppingCart.Items.forEach((shoppingCartItem) => {
                            if (shoppingCartItem.PricingPlanId === pricingPlan.Id && shoppingCartItem.ProductId === this.state.selectedProduct.Id) {
                                pricingPlan.ShoppingCartItemBillerRuleDetails = clone(shoppingCartItem.ShoppingCartItemBillerRuleDetails);
                                countOfPricingPlan += shoppingCartItem.Quantity;
                            }
                        }) || {};
                    }
                    pricingPlan.cartQuantity = countOfPricingPlan;
                }
            } else {
                // NonDBSS pricing plans
                pricingPlan.availabilityDate = getPricingPlanAvailabilityDate(pricingPlan.AvailabilityStart);
            }
            return pricingPlan;
        });
    }

    filterOutUnavailablePlans(availablePricingPlanIds) {
        return innerJoin((plan, availableId) => {
            return plan.Id === availableId;
        }, this.state.selectedProduct.ProductContext.OrderablePricingPlans, availablePricingPlanIds);
    }

    itemExistsInCart() {
        return (this.state.shoppingCart.Items || []).find((item) => {
            return item.ProductId === this.state.selectedProductId;
        });
    }

    _getTabByNameOrDefault(name) {
        return name === i18n.translate(this.LocaleKeys.PRODUCT_OPTIONS.OPTIONS) ? this.tabs[TAB_INDEX.OPTIONS] : this.tabs[TAB_INDEX.DETAILS];
    }

    hasSelectableProducts(configuration) {
        if (isNil(configuration) || isNil(this.selectedPricingPlanId) || isNil(configuration.items) || configuration.items.length === 0) {
            return false;
        }

        return configuration.items.some((selectableItem) => {
            if (!selectableItem.pricingPlans) {
                return false;
            }
            return selectableItem.pricingPlans[this.selectedPricingPlanId];
        });
    }

    shouldShowDetails() {
        return this.tabs[TAB_INDEX.DETAILS].active;
    }

    shouldShowLoadingIndicator() {
        return this.state.isFetchingOrderableProductData || this.state.isFetchingSingleProductContext ||
            !this.pricingPlans || this.state.isCalculatingQuote;
    }

    _getFormSubmitAction() {
        if (this.isModifyMode() || this.state.isReplacingMode) {
            return this._calculateModifyOrderQuote.bind(this);
        } else if (this.isEditMode && !this.state.isSelectedProductServiceFeature) {
            return this._editProductInCart.bind(this);
        } else if (this.state.isSelectedProductServiceFeature) {
            return this._prepMultipleItemsForCart.bind(this);
            // TODO Customer Order long term we should have a Shared function for DBSS products as behavior will be the same for now just starting with ad hoc
        } else if (this.state.isSelectedProductAdHoc) {
            return this._addDbssProductsToCart.bind(this);
        } else {
            return this._addProductToCart.bind(this);
        }
    }

    handleSubmitForm() {
        if (this.tabs[TAB_INDEX.DETAILS].active) {
            if (!this.formSubmittedFromOptionsTab) {
                // reset the form if it wasn't previously submitted, so the first visit to the options tab
                // doesn't show validation errors, but any prior errors will remain
                this.formApi.$setPristine();
            }

            this._selectTab(this.tabs[TAB_INDEX.OPTIONS]);
        } else if (this._noItemsModified()) {
            this.uiNotificationService.error(
                i18n.translate(this.LocaleKeys.CART.NO_CHANGED_ITEMS)
            );
        } else if (this.isExceededCartMaximumQuantity) {
            this.$anchorScroll('quantityItemsErrorField');
        } else if (this.formApi.$valid && !this.isExceededCartMaximumQuantity) {
            this.formSubmittedFromOptionsTab = true;

            for (const key in this._instanceProperties) {
                if (this._instanceProperties[key] === '' || isNil(this._instanceProperties[key])) {
                    delete this._instanceProperties[key];
                }
            }
            const submitFn = this._getFormSubmitAction();
            if (this.state.isSelectedProductAdHoc) {
                this.shoppingCartForServiceFeatures = this.shoppingCartForServiceFeatures.map((cartItem) => {
                    const matchingPricingPlan = this.pricingPlans.find((pricingPlan) => {
                        return pricingPlan.Id === cartItem.pricingPlanId;
                    });
                    const localBRIData = matchingPricingPlan.billerRuleInstances.length ? matchingPricingPlan.billerRuleInstances[0] : null;
                    return {
                        adHocOverrideDetails: !localBRIData.isOverrideSelected ?
                            undefined : {
                                // Keep Amounts's polarity the same when submitting to the API
                                Amount: Math.abs(localBRIData.chargeAmount) === Math.abs(localBRIData.overriddenAmount) ?
                                    undefined :
                                    (localBRIData.chargeAmount <= 0 && localBRIData.Credit ?
                                        localBRIData.overriddenAmount * -1 :
                                        convertStringToNumber(localBRIData.overriddenAmount)),
                                InvoiceText: localBRIData.overriddenName
                            },
                        childItems: cartItem.childItems,
                        pricingPlanId: cartItem.pricingPlanId,
                        productId: cartItem.productId,
                        quantity: cartItem.quantity
                    };
                });
            }

            // TODO Customer Order refactor later to handle for DBSS Products as a whole, not individually for SF, Adhoc, & later Standalones
            // TODO Customer Order shoppingCartForServiceFeatures will be Shared for DBSS products with collection of pricing plans with quantities
            return submitFn(this.state.isSelectedProductServiceFeature || this.state.isSelectedProductAdHoc ? this.shoppingCartForServiceFeatures : this.selectedPricingPlanId).then((shoppingCart) => {
                if (this.state.invalidItems.length > 0 || shoppingCart === FAILED_CALL) {
                    const failureCallbackFn = this.onFailure();
                    failureCallbackFn && failureCallbackFn(shoppingCart, this.state.invalidItems);
                } else {
                    const successCallbackFn = this.onSuccess();
                    successCallbackFn && successCallbackFn(shoppingCart, this._addedProducts, false, this.savedServiceFeatureModel);
                }
                this._closeDialog();
            }).catch(() => {
                if (this.state.isSelectedProductServiceFeature) {
                    const failureCallbackFn = this.onFailure();
                    failureCallbackFn && failureCallbackFn(undefined, this.state.invalidItems, true);
                }
            });
        }
    }

    clearSelectedPricingPlan() {
        if (this.selectIndividualEpisodes) {
            this.selectedPricingPlanId = null;
            // TODO (CustomerOrder): Do not bind to state or modify state. This should only be done through actions.
            this.state.optionalProductConfiguration.mappedPricingPlanId = null;
            this.state.picklistProductConfiguration.mappedPricingPlanId = null;
        }
    }

    clearNonPricingPlanSelections(pricingPlanId) {
        this.selectIndividualEpisodes = false;
        // TODO (CustomerOrder): Do not bind to state or modify state. This should only be done through actions.
        this.state.optionalProductConfiguration.mappedPricingPlanId = pricingPlanId;
        this.state.picklistProductConfiguration.mappedPricingPlanId = pricingPlanId;
    }

    _addProductToCart(pricingPlanId) {
        const couponCode = this.state.searchCouponCode;
        if (couponCode) {
            if (!this.state.couponCodes.find(propEq(couponCode, 'name'))) {
                // only apply the coupon if it's unique (not already applied)
                this.actions.setProductOrderCouponCodes([{
                    name: couponCode,
                    description: null
                }].concat(this.state.couponCodes));
            }
        }
        // ToDo: (CutomerOrder): Move to a selector so it's memoized
        const currentCouponCodes = pluck('name', this.state.couponCodes);
        const couponCodes = couponCode ? currentCouponCodes.concat([couponCode]) : currentCouponCodes;

        if (this.selectIndividualEpisodes || this.state.hasOtherSavedSeasonSelections) {
            // Get selection from other seasons if any
            let products = this.state.hasOtherSavedSeasonSelections ? this.state.otherSeasonsProductsToAdd : [];
            // Add current selection of episodes or full season
            if (this.selectIndividualEpisodes && !isNil(this.selectedIndividualEpisodes) && this.selectedIndividualEpisodes.items.length) {
                products = products.concat(this.selectedIndividualEpisodes.items.map((episode) => {
                    const result = {
                        productId: episode.product.Id,
                        pricingPlanId: episode.selectedPlanId,
                        quantity: this.quantity,
                    };

                    const instanceProps = this.selectedIndividualEpisodes.instanceProperties[episode.product.Id];

                    if (!isNil(instanceProps)) {
                        result.instanceProperties = instanceProps;
                    }

                    return result;
                }));
            } else {
                products = products.concat({
                    productId: this.state.isSelectedProductSeriesContainer ? this.state.selectedProduct.Id : this.state.selectedProductId,
                    pricingPlanId: pricingPlanId,
                    quantity: this.quantity,
                });
            }

            this._addedProducts = products;

            const model = {
                products: products,
                customerId: this.state.currentCustomer.Id,
                shoppingCart: this.state.shoppingCart,
                redemptionCodes: couponCodes || []
            };

            return this.actions.addMultipleAndUpdateShoppingCart(model);

        } else {
            const product = {
                childItems: [],
                customerId: this.state.currentCustomer.Id,
                instanceProperties: this._instanceProperties,
                pricingPlanId: pricingPlanId,
                productId: this.state.isSelectedProductSeriesContainer ? this.state.selectedProduct.Id : this.state.selectedProductId,
                quantity: this.quantity,
                redemptionCodes: couponCodes || [],
                shoppingCart: this.state.shoppingCart,
                standalone: this.state.selectedProduct.Standalone || undefined
            };

            this._populateBundleChildren(product, pricingPlanId);

            this._addedProducts = [{
                productId: this.state.selectedProductId,
                pricingPlanId: pricingPlanId
            }];

            return this.actions.addToAndUpdateShoppingCart(product);
        }
    }

    _editProductInCart(updatedPricingPlanId) {
        const product = {
            pricingPlanId: updatedPricingPlanId,
            childItems: []
        };

        this._populateBundleChildren(product, updatedPricingPlanId);

        this._addedProducts = [{
            pricingPlanId: updatedPricingPlanId,
            productId: this.state.selectedProductId
        }];

        const couponCodes = pluck('name', this.state.couponCodes);

        return this.actions.editAndUpdateShoppingCart({
            index: this.state.selectedShoppingCartItemIndex,
            product: product,
            quantity: this.quantity,
            instanceProperties: this._instanceProperties,
            customerId: this.state.currentCustomer.Id,
            shoppingCart: this.state.shoppingCart,
            redemptionCodes: couponCodes || []
        }).then((responseData) => {
            this.actions.setShoppingCartItemIndex(null);
            return responseData;
        });
    }

    _getBaseSubscriptionOrderData(pricingPlanId) {
        let updatedSubscriptionItem = {
            productId: this.state.selectedProductId,
            pricingPlanId: pricingPlanId,
            childItems: [],
            quantity: this.quantity,
            instanceProperties: this._instanceProperties
        };

        this._populateBundleChildren(updatedSubscriptionItem, pricingPlanId);
        updatedSubscriptionItem = pascalCaseKeys(updatedSubscriptionItem);

        return updatedSubscriptionItem;
    }

    _noItemsModified() {
        if (!this.isModifyMode()) {
            return false;
        }

        const updatedSubscriptionItem = this._getBaseSubscriptionOrderData(this.selectedPricingPlanId);
        // TODO - Customer Order move to selectors for memoization and uniting, don't need to run this code repeatedly
        const existingChildren = pathOr([], ['state', 'editSubscriptionItem', 'Children'], this);
        const editItems = getEditItems(updatedSubscriptionItem, existingChildren, this.state.editSubscriptionItem);

        return noItemsModified(editItems);
    }

    _getRequestDataForModifyOrderQuote(pricingPlanId) {
        const updatedSubscriptionItem = this._getBaseSubscriptionOrderData(pricingPlanId);

        let editItems = {};
        let replaceItems = [];

        if (this.state.isReplacingMode) {
            const currentSubscriptionItemToReplaceId = pathOr(null, ['replaceItem', 'id'], this.state);
            this.actions.setReplacementItem(updatedSubscriptionItem);

            replaceItems = getReplaceItems(updatedSubscriptionItem, currentSubscriptionItemToReplaceId);
        } else {
            // TODO - Customer Order move to selectors for memoization and uniting, don't need to run this code repeatedly
            const existingChildren = pathOr([], ['state', 'editSubscriptionItem', 'Children'], this);
            editItems = getEditItems(updatedSubscriptionItem, existingChildren, this.state.editSubscriptionItem);
        }

        return {
            customerId: this.state.currentCustomer.Id,
            calculateShipping: false,
            calculateTaxes: false,
            useDefaults: false,
            modificationObject : {
                ChangeImmediately: this.state.changeImmediately,
                AddItems: editItems.addItems || [],
                ChangeItems: editItems.changeItems || [],
                RemoveItems: editItems.removeItems || [],
                ReplaceItems: replaceItems,
                SubscriptionId: this.state.currentSubscription.Id
            },
            paymentInstrumentIds: this.state.paymentInstrumentIds || [],
            redemptionCodes: this.state.searchCouponCode ? [this.state.searchCouponCode] : []
        };
    }

    _calculateModifyOrderQuote(pricingPlanId) {
        const reqData = this._getRequestDataForModifyOrderQuote(pricingPlanId);

        return this.actions.calculateModifyOrderQuote(reqData).then((respData) => {
            if (respData && respData.faultCode) {
                return FAILED_CALL;
            } else {
                return this.state.shoppingCart;
            }
        });
    }

    _closeDialog() {
        this.removeSelectedPlanIfNotAvailable();
        this.actions.setShoppingCartItemIndex(null);
        this.formApi.$setPristine();
        this.actions.clearSelectedOptions();
        const onClose = this.onClose();
        onClose && onClose();
    }

    hasAdditionalEpisodes() {
        return this.state.remainingEpisodicProductIds.length > 0 && this.state.includedEpisodes.length > 0;
    }

    fetchAdditionalEpisodes() {
        return this.actions.loadAdditionalEpisodes(this.state.currentCustomer.Id, this.state.selectedProductId, {
            EpisodicChildProducts: this.state.remainingEpisodicProductIds.slice(0, 50),
            IncludeOrderablePricingPlans: true
        }).then(() => {
            const episodeIds = this.state.individualEpisodeConfiguration.items.map((item) => {
                return item.product.Id;
            });

            this.actions.getProductMetadataBatch(episodeIds);
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    pricingPlanDoesNotExist(plans) {
        return none((plan) => {
            return plan.Id === this.selectedPricingPlanId;
        }, plans);
    }

    removeSelectedPlanIfNotAvailable() {
        const plans = pathOr([], ['ProductContext', 'OrderablePricingPlans'], this.state.selectedProduct);
        if (this.pricingPlanDoesNotExist(plans)) {
            delete this.selectedPricingPlanId;
        }
    }

    _populateBundleChildren(product, pricingPlanId) {
        this.selectedOptionalItems = this.selectedOptionalItems || {};
        this.selectedPicklistItems = this.selectedPicklistItems || {};

        const optional = this.selectedOptionalItems.items || [];
        const picklist = this.selectedPicklistItems.items || [];

        if (this.state.includedProducts.length) {
            this.state.includedProducts.forEach((includedProduct) => {
                const qty = includedProduct.bundle.MinimumQuantity;
                const childItem = {
                    BundleId: includedProduct.bundle.Id,
                    ProductId: includedProduct.product.Id,
                    PricingPlanId: includedProduct.pricingPlans[pricingPlanId].Id,
                    Quantity: qty > 0 ? qty : 1
                };

                const instanceProps = this.includedProductInstanceProperties[includedProduct.product.Id];

                if (!isNil(instanceProps) && !isEmpty(instanceProps)) {
                    childItem.InstanceProperties = instanceProps;
                }

                product.childItems.push(childItem);
            });
        }

        optional.forEach((child) => {
            const qty = Number(child.quantity);
            if (!isNil(child.pricingPlans[pricingPlanId])) {
                const childItem = {
                    BundleId: child.bundle.Id,
                    ProductId: child.product.Id,
                    PricingPlanId: child.pricingPlans[pricingPlanId].Id,
                    Quantity: isNaN(qty) ? 1 : qty
                };

                const instanceProps = this.selectedOptionalItems.instanceProperties[child.product.Id];

                if (!isNil(instanceProps) && !isEmpty(instanceProps)) {
                    childItem.InstanceProperties = instanceProps;
                }

                product.childItems.push(childItem);
            }
        });

        picklist.forEach((child) => {
            const qty = Number(child.quantity);
            if (!isNil(child.pricingPlans[pricingPlanId])) {
                product.childItems.push({
                    BundleId: child.bundle.Id,
                    ProductId: child.product.Id,
                    PricingPlanId: child.pricingPlans[pricingPlanId].Id,
                    Quantity: isNaN(qty) ? 1 : qty
                });
            }
        });
    }

    get productMaxQuantity() {
        return pathOr(true, ['isSelectedProductServiceFeature'], this.state) ?
            this.state.selectedProduct.ProductContext.ServiceFeatureCardinality.MaximumQuantity || this.MAX_SERVICE_FEATURE_ITEMS :
            pathOr(0, ['selectedProduct', 'MaxNonBulkQuantity'], this.state);
    }

    $onDestroy() {
        if (this.state.productDialogErrorMessages) {
            this.actions.clearShoppingCartError();
        }
        this.disconnectRedux();
    }
}

export default {
    template: require('./product.dialog.html'),
    controller: ProductDialogController,
    bindings: {
        dialogSize:'<?',
        isModify: '<?',
        onClose: '&',
        onDialogPopulated: '&',
        onFailure: '&',
        onSuccess: '&',
        selectedTab: '<',
        submitLabel: '<?'
    }
};
