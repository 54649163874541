import {HotkeyNavListController} from '../../../shared/controllers/hotkey.nav.list.controller';

export default {
    template: require('./member.list.html'),
    bindings: {
        items: '<',
        onItemSelected: '&',
        selectedItem: '<',
        headOfHouseholdId: '<'
    },
    controllerAs: 'MemberList',
    controller: HotkeyNavListController
};
