import {
    LandingPageWidgetsSelector
} from '../../reducers/selectors/landing.page.selectors';
import {UserSecurityContextSelector} from 'invision-core/src/components/session/session.selectors';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';

const mapStateToTarget = (store) => {
    return {
        templateLoaded: MetadataCodeLoadedSelector(CODES.BusinessUnitTemplate, store),
        userContext: UserSecurityContextSelector(store),
        widgets: LandingPageWidgetsSelector(store)
    };
};

const controllerActions = {};

class LandingPageContextualController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux
        });
    }

    $onInit() {
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    showCustomLandingPage() {
        return this.state.templateLoaded && this.state.widgets.length > 0;
    }

    showDefaultLandingPage() {
        return this.state.templateLoaded && this.state.widgets.length === 0;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./landing.page.contextual.html'),
    controller: LandingPageContextualController
};