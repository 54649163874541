import angular from 'angular';
import ContentActivityComponent from './content.activity.component';
import ContentActivityConfig from './content.activity.config';
import ListComponent from './list/list.component';
import ListItemComponent from './listItem/list.item.component';
import DetailComponent from './detail/detail.component';

export default angular.module('inv.customer.contentActivity', [])
    .config(ContentActivityConfig)
    .component('contentActivity', ContentActivityComponent)
    .component('contentActivityDetail', DetailComponent)
    .component('contentActivityList', ListComponent)
    .component('contentActivityListItem', ListItemComponent)
    .name;