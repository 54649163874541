// import partial from 'ramda/src/partial';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import {cloneDeep} from 'lodash';
import {DIRECTION} from 'invision-core/src/constants/common.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
// import {validateNumbers} from 'invision-core/src/utilities/input.helper';
import i18n from 'invision-core/src/components/i18n/i18n';
import {retrieveProductsMetadata} from 'invision-core/src/components/metadata/products/products.actions';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    IsProductMetadataLoadingSelector,
    ProductMetadataPricingPlansByIdSelector
} from 'invision-core/src/components/metadata/products/products.selectors';
// import {addressHelper as AddressHelper} from 'invision-core/src/utilities/address.helper';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {SERVICE_FEATURE_ADD_ON_DESCRIPTION_OPTIMAL_CHAR_COUNT} from '../../../../../shared/constants/service.constants';
import {
    // AttributeGroupsSelector,
    // CurrentAttributeFormName,
    // CurrentAttributesValidationStatuses,
    // PhysicalAttributeGroupsSelector,
    PagesIsFetchingDataSelector,
    // ServiceTaxCustomizationHashSelector,
    // TelephoneNumberProvinceRegionSelector
} from '../../../../../../reducers/selectors/selected.offering.order.selectors';
// import FaultCodeKeys from '../../../../../../api/fault.code.keys';
import {
    // DbssShippingWarehouseIdSelector,
    // IsQuotingOfferSelector,
    // IsUpdatingMultiOfferShoppingCart,
    // SelectedInventoryStoresSelector,
    ServiceFeatureSelector,
    ServiceFeaturesViewModelSelector,
} from '../../../../../../reducers/selectors/offering.order.selectors';
import {
    clearProductContextForServiceFeatures,
    getServiceFeatureProduct,
    retrieveProductContextForServiceFeatures,
    searchServiceFeatures,
    // searchTelephoneNumberInventory,
    // setActiveAttributeFormName,
    //setActiveFormValidationStatus,
    // setDeliveryDecision,
    // setSelectedInventoryStores,
    // updateDeliveryDecision
} from '../../../../../../reducers/actions/offering.order.actions';
// import {duplicatedServiceIdentifierAttributes} from '../../../../../../reducers/helpers/offer.ordering.wizard.helper';
import CareLocaleKeys from '../../../../../../locales/keys';
// import {
//     formValidated as setCurrentAddressAsInitialAddress,
//     restoreMobileNumberPortabilityAttribute,
//     saveMobileNumberPortabilityAttribute,
//     setShowPortability,
//     updateMobileNumberPortabilityAddressModel,
//     validatePortInData,
// } from '../../../../../../reducers/actions/mobile.number.portability.actions';
// import {
//     IsResumingCartWithMnpData,
//     MobileNumberPortabilityAddressModelSelector,
//     MobileNumberPortabilityAttributesSelector,
//     MobileNumberPortabilityModelSelector,
//     MobileNumberPortabilityShowPortabilitySelector
// } from '../../../../../../reducers/selectors/mobile.number.portability.selectors';
// import {INVENTORY_LOOKUP_TYPES} from '../../../../../shared/constants/offering.option.status.constants';
// import metadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
// import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import {
    // DELIVERY_OPTIONS,
    // SEARCH_INVENTORY_NO_RESULTS,
    SERVICE_ATTRIBUTE_TYPE,
    TAX_RULE_OPTIONS
} from '../../../../../../customercare.constants';
import {
    // CreateEditCustomerSelector,
    CurrentCustomerSelector,
} from '../../../../../../reducers/selectors/customer.selectors';
// import {
//     CurrentAddressesSelector,
//     IsFetchingAddressesSelector
// } from '../../../../../../reducers/selectors/customer.addresses.selectors';
// import {retrieveCustomerAddresses} from '../../../../../../reducers/actions/customer.addresses.actions';
import {SelectedProducIdsOfServiceFeaturesSelectors} from '../../../../../wizards/newConnectWizard/new.connect.wizard.selectors';
import {getFormattedServiceAttributeValue} from '../../../../../../reducers/selectors/services.list.selectors.helper';
import {PRICE_OVERRIDE_ACCESS} from '../../../../../../security.attributes';
import {
    setActiveFormValidationStatus,
    setSelectedServiceId,
    addNewServiceTemplate,
    removeServiceTemplates,
    updateServiceTemplate,
    updateGroupTemplate,
    setAttributeFormSubmitted,
    setGroupTemplateConfigured,
    overrideGroupTemplate,
} from '../../../../../../reducers/actions/add.services.wizard.actions';
import uuid from 'uuid/v4';
import {GROUP_TEMPLATE_ID} from '../../../../../../reducers/constants/add.services.wizard.constants';
import {
    AllServiceAttributeFieldsSelector,
    AllServiceTemplatesSelector,
    DefaultServiceTaxDecisionsSelector,
    DuplicateServiceIdentifiersSelector,
    FormValidationStatusesSelector,
    GroupTemplateSelector,
    HasServiceFeaturesAvailableSelector,
    IsGroupTemplateConfiguredSelector,
    IsGroupTemplateSelectedSelector,
    IsSelectedServiceTemplateServiceIdentifierValueDuplicateSelector,
    SelectedServiceFeaturesSelector,
    SelectedServiceTemplateDetailsSelector,
    SelectedServiceTemplateFieldsSelector,
    ServiceIdentifierDependantAttributeSelector,
    ServiceTemplatesSelector,
    TaxLocationByPricingPlanSelector
} from '../../../../../../reducers/selectors/add.services.wizard.selectors';
import {CurrentCustomerNodeSelector} from '../../../../../../reducers/selectors/customer.account.hierarchy.selectors';
import {DEFAULT_TAX_SPLIT} from '../../../../../shared/constants/customer.constants';

class AttributesController {
    constructor($ngRedux, $state, $timeout, uiNotificationService) { // $anchorScroll, $location,
        Object.assign(this, {
            // $anchorScroll,
            // $location,
            $ngRedux,
            $state,
            $timeout,
            // AddressHelper,
            // activeNavItem: {},
            attributesForm: null, // populated by ng-form
            CareLocaleKeys,
            closeSearchTelephoneNumberModal: this.closeSearchTelephoneNumberModal.bind(this),
            CoreLocaleKeys,
            billerRuleInstanceDetails: [],
            // changeServiceAttributeList: this.changeServiceAttributeList.bind(this),
            closeServiceFeaturesPopup: this.closeServiceFeaturesPopup.bind(this),
            defaultTaxSplit: DEFAULT_TAX_SPLIT,
            DIRECTION,
            getMappedServiceFeatures: this.getMappedServiceFeatures.bind(this),
            isSearchTelephoneNumberModalOpen: false,
            // DELIVERY_OPTIONS,
            // deliveryOptionsByPricingPlanViewModel: {},
            // deliveryOptionsFieldName: i18n.translate(CareLocaleKeys.ATTRIBUTES.DELIVERY_OPTIONS),
            // deliveryOptionsTooltip: require('../../../../../shared/tooltipTemplates/deliveryOptions.tooltip.html'),
            // goToNextNavItem: this.goToNextNavItem.bind(this),
            // goToPreviousNavItem: this.goToPreviousNavItem.bind(this),
            // INVENTORY_LOOKUP_TYPES,
            // mnpVerificationCallBack: this.mnpVerificationCallBack.bind(this),
            // onMobileNumberPortabilityEdit: this.onMobileNumberPortabilityEdit.bind(this),
            // onPhoneNumberSelection: this.onPhoneNumberSelection.bind(this),
            // onPortabilityNumberChange: this.onPortabilityNumberChange.bind(this),
            onProductPricingPlansRequested: this.onProductPricingPlansRequested.bind(this),
            // onDeliveryOptionsChange: this.onDeliveryOptionsChange.bind(this),
            orderScenario: undefined,
            onServiceFeaturePricingPlanSelection: this.onServiceFeaturePricingPlanSelection.bind(this),
            onTelephoneNumberSelected: this.onTelephoneNumberSelected.bind(this),
            // onTelephoneSelection: this.onTelephoneSelection.bind(this),
            // billToLocationToolTipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.BILL_TO_LOCATION_TOOLTIP_CONTENT),
            // originatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.ORIGINATING_LOCATION_TOOLTIP_CONTENT),
            // terminatingLocationTooltipContent: i18n.translate(CareLocaleKeys.TAX_LOCATION_RULES.TERMINATING_LOCATION_TOOLTIP_CONTENT),
            productIds: [],
            // searchPhones: this.searchPhones.bind(this),
            SERVICE_ATTRIBUTE_TYPE,
            SERVICE_FEATURE_ADD_ON_DESCRIPTION_OPTIMAL_CHAR_COUNT,
            // serviceTaxCustom: false,
            setValidationStatus: this.setValidationStatus.bind(this),
            uiNotificationService: uiNotificationService,
            // updateMake: this.updateMake.bind(this),
            // updateModel: this.updateModel.bind(this),
            // updatePhysicalAttribute: this.updatePhysicalAttribute.bind(this),
            updateCustomizedServiceTaxValue: this.updateCustomizedServiceTaxValue.bind(this),
            // validateNumbers,
            onAddNewService: this.onAddNewService.bind(this),
            onSelectService: this.onSelectService.bind(this),
            onBulkRemoveServices: this.onBulkRemoveServices.bind(this),
            closeBulkRemoveServices: this.closeBulkRemoveServices.bind(this),
            handleBulkRemoveServices: this.handleBulkRemoveServices.bind(this),
            onConfirmOrderSettings: this.onConfirmOrderSettings.bind(this),
            attributesEditStatus: {},
            isEditingAttribute: this.isEditingAttribute.bind(this),
            onEditAttribute: this.onEditAttribute.bind(this),
            onApplyAttributeChange: this.onApplyAttributeChange.bind(this),
            onCancelAttributeChange: this.onCancelAttributeChange.bind(this),
            onResetAttributeChange: this.onResetAttributeChange.bind(this),
            attributeToOverride: {},
            onApplyAttributeOverride: this.onApplyAttributeOverride.bind(this),
            closeAttributeOverride: this.closeAttributeOverride.bind(this),
            isGlobalTemplate: this.isGlobalTemplate.bind(this)
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                // addressStateCodesLoaded: metadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
                // attributeGroups: AttributeGroupsSelector(store),
                // currentAddresses: CurrentAddressesSelector(store),
                // currentAttributeFormName: CurrentAttributeFormName(store),
                allServiceAttributeFields: AllServiceAttributeFieldsSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                defaultTaxLocationDecision: DefaultServiceTaxDecisionsSelector(store),
                formValidationStatuses: FormValidationStatusesSelector(store),
                // currentValidationStatuses: CurrentAttributesValidationStatuses(store),
                // dbssShippingWarehouseId: DbssShippingWarehouseIdSelector(store),
                // eligibilityModel: CreateEditCustomerSelector(store),
                // isFetchingAddresses: IsFetchingAddressesSelector(store),
                isFetchingData: PagesIsFetchingDataSelector(store),
                isProductMetadataLoading: IsProductMetadataLoadingSelector(store),
                // isQuotingOffer: IsQuotingOfferSelector(store),
                isRegularExpressionsLoaded: MetadataCodeLoadedSelector(CODES.RegularExpression, store),
                // isResumingCartWithMnpData: IsResumingCartWithMnpData(store),
                isServiceAttributesLoaded: MetadataCodeLoadedSelector(CODES.ServiceAttribute, store),
                isGroupTemplateSelected: IsGroupTemplateSelectedSelector(store),
                // isUpdatingMultiOfferShoppingCart: IsUpdatingMultiOfferShoppingCart(store),
                // physicalAttributeGroups: PhysicalAttributeGroupsSelector(store),
                // portabilityModel: MobileNumberPortabilityModelSelector(store),
                // portabilityModelAddress: MobileNumberPortabilityAddressModelSelector(store),
                metadataProductsAndPricingPlan: ServiceFeatureSelector(store),
                productMetadataPricingPlansBy: ProductMetadataPricingPlansByIdSelector(store),
                regularExpressions: MetadataCodeTypeDictionarySelector(CODES.RegularExpression, store),
                // savedPortabilityAttributes: MobileNumberPortabilityAttributesSelector(store),
                // selectedInventoryStores: SelectedInventoryStoresSelector(store),
                selectedProducIdsOfServiceFeatures: SelectedProducIdsOfServiceFeaturesSelectors(store),
                selectedServiceFeatures: SelectedServiceFeaturesSelector(store),
                serviceAttributes: MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute, store),
                serviceFeaturesViewModel: ServiceFeaturesViewModelSelector(store),
                // serviceTaxCustomizationHash: ServiceTaxCustomizationHashSelector(store),
                // showPortability: !!MobileNumberPortabilityShowPortabilitySelector(store),
                // telephoneCodes: TelephoneNumberProvinceRegionSelector(store),
                // telephoneCodesLoaded: metadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.TelephoneCodes, store),
                userSecurityAttributes: UserSecurityAttributesSelector(store),
                allServiceTemplates: AllServiceTemplatesSelector(store),
                serviceTemplates: ServiceTemplatesSelector(store),
                selectedServiceTemplateDetails: SelectedServiceTemplateDetailsSelector(store),
                selectedServiceTemplateFields: SelectedServiceTemplateFieldsSelector(store),
                isGroupTemplateConfigured: IsGroupTemplateConfiguredSelector(store),
                isDuplicate: IsSelectedServiceTemplateServiceIdentifierValueDuplicateSelector(store),
                hasServiceFeaturesAvailable: HasServiceFeaturesAvailableSelector(store),
                groupTemplate: GroupTemplateSelector(store),
                hasServiceFeaturesAvailable: HasServiceFeaturesAvailableSelector(store),
                duplicates: DuplicateServiceIdentifiersSelector(store),
                taxLocationByPricingPlanMap: TaxLocationByPricingPlanSelector(store),
                serviceIdentifierDependantAttribute: ServiceIdentifierDependantAttributeSelector(store),
                currentCustomerNode: CurrentCustomerNodeSelector(store)
            };
        };

        const controllerActions = {
            clearProductContextForServiceFeatures,
            fetchCodeTypes: MetadataActions.codes.fetchCodeTypesThunk,
            // fetchTelephoneInventory: searchTelephoneNumberInventory,
            getServiceFeatureProduct,
            // restoreMobileNumberPortabilityAttribute,
            // retrieveCustomerAddresses,
            retrieveProductContextForServiceFeatures,
            retrieveProductsMetadata,
            // saveMobileNumberPortabilityAttribute,
            searchServiceFeatures,
            // setActiveAttributeFormName,
            setActiveFormValidationStatus,
            setAttributeFormSubmitted,
            // setCurrentAddressAsInitialAddress,
            // setDeliveryDecision,
            // setSelectedInventoryStores,
            // setShowPortability,
            // updateDeliveryDecision,
            // updateMobileNumberPortabilityAddressModel,
            // validatePortInData,
            setSelectedServiceId,
            addNewServiceTemplate,
            removeServiceTemplates,
            updateServiceTemplate,
            updateGroupTemplate,
            setGroupTemplateConfigured,
            overrideGroupTemplate,
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        // this.mobileNumberPortabilityConfig = {
        //     onRegisterApi: ({api}) => {
        //         this.mobileNumberPortabilityApi = api;
        //     }
        // };

        // this.telephoneSelectionConfig = {
        //     onRegisterApi: ({api}) => {
        //         this.telephoneSelectionApi = api;
        //     }
        // };

        this.bulkRemoveServicesWarningPopupConfig = {
            onRegisterApi: ({api}) => {
                this.bulkRemoveServicesWarningPopupApi = api;
            }
        };

        this.attributeOverrideWarningPopupConfig = {
            onRegisterApi: ({api}) => {
                this.attributeOverrideWarningPopupApi = api;
            }
        };

        this.selectServiceFeaturesPopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectServiceFeaturesPopupApi = api;
            }
        };

        // if (this.filteredFormAttributeGroups && this.filteredFormAttributeGroups.length === 0) {
        //     this.setEditAttributeGroups()(this.state.attributeGroups);
        // }

        // this.setEditPhysicalAttributeGroups()({
        //     physicalAttributeGroups: this.state.physicalAttributeGroups,
        //     formPhysicalAttributeGroups: this.formPhysicalAttributeGroups
        // });

        // Object.keys(this.state.physicalAttributeGroups).forEach((pricingPlanId) => {
        //     const pricingPlan = this.state.physicalAttributeGroups[pricingPlanId];
        //     pricingPlan.forEach((item) => {
        //         let make, model;
        //         if (item.makeInformation.length === 1) {
        //             make = item.makeInformation[0];
        //         } else {
        //             make = item.makeInformation.length ? item.makeInformation.find((make) => {
        //                 return make.id === item.selectedMakeFromSavedCart;
        //             }) : undefined;
        //         }

        //         if (make.possibleValues.length === 1) {
        //             model = make.possibleValues[0];
        //         } else {
        //             model = make.possibleValues.length > 1 ? make.possibleValues.find((model) => {
        //                 return model.id === item.selectedModelFromSavedCart;
        //             }) : undefined;
        //         }

        //         if ((make.possibleValues.length === 1) ||
        //             (make.possibleValues.length > 1 && (make || model))
        //         ) {
        //             const selectedMakeId = make ? make.id : undefined,
        //                 selectedModelId = model ? model.id : undefined;

        //             this.updateMakeAndModel()(pricingPlanId, item.id, selectedMakeId, selectedModelId);
        //         }

        //         item.typeAttributes.forEach((typeAttribute) => {
        //             if (typeAttribute.possibleValues.length === 1) {
        //                 this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, item.id, typeAttribute.id, typeAttribute.possibleValues[0]);
        //             } else if (typeAttribute.selectedValue) {
        //                 this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, item.id, typeAttribute.id, typeAttribute.selectedValue);
        //             }
        //         });
        //     });
        // });

        // this.duplicatedServiceIdentifiers = [];

        // this.updateMakeCallback = partial(this.updateMake).bind(this);
        // this.updateModelCallback = partial(this.updateModel).bind(this);
        // this.updatePhysicalAttributeCallback = partial(this.updatePhysicalAttribute).bind(this);

        // this.filteredNavigationItems = this.filterNavigationItems(this.navigationItems);
        // this.mnpToken = '';
        // this.sendPortInData = false;

        // if (!this.state.telephoneCodesLoaded) {
        //     this.actions.fetchCodeTypes(MetadataConstants.codes.TelephoneCodes);
        // }
        // if (!this.state.addressStateCodesLoaded) {
        //     this.actions.fetchCodeTypes(MetadataConstants.codes.AddressStateProvinceRegion);
        // }

        if (!this.state.isServiceAttributesLoaded) {
            this.actions.fetchCodeTypes(CODES.ServiceAttribute);
        }

        if (!this.state.isRegularExpressionsLoaded) {
            this.actions.fetchCodeTypes(CODES.RegularExpression);
        }

        // this.getInitialAddressIfAvailable();
        // this.activeNavItem = this.getActiveNavItem();
        // if (this.state.isResumingCartWithMnpData) {
        //     this.sendPortInOrItemReservation()(this.findPortableAttributeId(this.activeNavItem), true);
        //     this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
        //     this.updatePortInRequest()(this.findPortableAttributeId(this.activeNavItem), this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
        // }
        this.actions.clearProductContextForServiceFeatures();
        this.fetchServiceAddonPricingPlanDetails();

        // if (this.deliveryOptionsByPricingPlanViewModel && Object.values(this.deliveryOptionsByPricingPlanViewModel).length) {
        //     const firstShippablePricingPlanDeliveryDecision = Object.values(this.deliveryOptionsByPricingPlanViewModel)[0];
        //     const firstInstanceOfFirstShippablePricingPlanDeliveryDecision = firstShippablePricingPlanDeliveryDecision['1'];
        //     this.onDeliveryOptionsChange(this.selectedDeliveryDecision ? {
        //         selectedValue: this.selectedDeliveryDecision
        //     } : firstInstanceOfFirstShippablePricingPlanDeliveryDecision);
        // }

        this.hasPriceOverrideAccess = hasAccess(this.state.userSecurityAttributes, PRICE_OVERRIDE_ACCESS);
        this.setValidationStatus();
        this.setServicesViewModel();
    }

    setServicesViewModel() {
        if (this.state) {
            this.allItems = clone(this.state.allServiceTemplates);
            this.viewModel = clone(this.state.selectedServiceTemplateDetails.attributes);
        }
    }

    isGlobalTemplate() {
        return GROUP_TEMPLATE_ID === this.state.selectedServiceTemplateDetails.Id;
    }

    onAddNewService() {
        if (this.state.isGroupTemplateConfigured && !this.state.isDuplicate && !this.attributesForm.$invalid) {
            const newServiceId = uuid();

            this.actions.addNewServiceTemplate(newServiceId);
            this.onSelectService({
                Id: newServiceId
            });
        } else if (!this.state.isDuplicate && !this.attributesForm.$invalid) {
            if (this.uiNotificationService.numActiveToasts()) {
                this.uiNotificationService.clear();
            }
            this.uiNotificationService.transientError(i18n.translate(CareLocaleKeys.ATTRIBUTES.CONFIRM_ORDER_SETTINGS_WARNING));
        }
        if (!this.attributesForm.$valid) {
            this.actions.setAttributeFormSubmitted(true);
        } else {
            this.actions.setAttributeFormSubmitted(false);
        }
        this.attributesForm.$setUntouched();
        this.setValidationStatus();
    }

    onSelectService(service) {
        if (!this.attributesForm.$valid) {
            this.actions.setAttributeFormSubmitted(true);
        } else {
            this.attributesEditStatus = {};
            this.actions.setSelectedServiceId(service.Id);
        }
    }

    onBulkRemoveServices(services) {
        this.selectedServiceIds = services.map((service) => {
            return service.Id;
        });

        this.bulkRemoveServicesWarningPopupApi.open();
    }

    closeBulkRemoveServices() {
        this.selectedServiceIds = [];
        this.bulkRemoveServicesWarningPopupApi.close();
    }

    handleBulkRemoveServices(confirmed) {
        if (confirmed) {
            this.actions.removeServiceTemplates(this.selectedServiceIds);
            this.setServicesViewModel();
        }

        this.closeBulkRemoveServices();
    }

    onConfirmOrderSettings() {
        if (!this.attributesForm.$valid) {
            this.attributesForm.$setSubmitted(true);
            this.actions.setAttributeFormSubmitted(true);
        }
        this.setValidationStatus();
        if (this.attributesForm.$valid) {
            this.actions.setGroupTemplateConfigured(true);
        }
    }

    isEditingAttribute(attribute) {
        return this.attributesEditStatus[attribute.ServiceAttributeId];
    }

    onEditAttribute(attribute) {
        this.viewModel[attribute.ServiceAttributeId] = this.viewModel[attribute.ServiceAttributeId] ||  this.state.groupTemplate.attributes[attribute.ServiceAttributeId];

        this.attributesEditStatus[attribute.ServiceAttributeId] = true;
    }

    onApplyAttributeChange(attribute) {
        if (this.attributesForm.attributeForm[attribute.Name].$valid) {
            if (this.isGlobalTemplate()) {
                this.attributeToOverride = attribute;
                this.attributeOverrideWarningPopupApi.open();
            } else {
                this.onAttributeChange(attribute);
                this.attributesEditStatus[attribute.ServiceAttributeId] = false;
            }
        }
    }

    onCancelAttributeChange(attribute) {
        this.viewModel[attribute.ServiceAttributeId] = this.state.selectedServiceTemplateDetails.attributes[attribute.ServiceAttributeId];

        this.attributesEditStatus[attribute.ServiceAttributeId] = false;
    }

    onResetAttributeChange(attribute) {
        this.viewModel[attribute.ServiceAttributeId] = this.state.groupTemplate.attributes[attribute.ServiceAttributeId];

        this.onApplyAttributeChange(attribute);
    }

    onApplyAttributeOverride(confirmed) {
        if (confirmed) {
            this.onAttributeChange(this.attributeToOverride);
            this.attributesEditStatus[this.attributeToOverride.ServiceAttributeId] = false;
        }

        this.closeAttributeOverride();
    }

    closeAttributeOverride() {
        this.onCancelAttributeChange(this.attributeToOverride);

        this.attributeToOverride = {};
        this.attributeOverrideWarningPopupApi.close();
    }

    // updateMake(pricingPlanId, inventoryToUpdate, selectedOption) {
    //     const selectedMakeId = null !== selectedOption ? selectedOption.value : undefined;
    //     const selectedMakeModels = pathOr([], ['modelsByMakeId', selectedMakeId], inventoryToUpdate);
    //     let selectedModelId = undefined;

    //     if (undefined !== selectedMakeId && 1 === selectedMakeModels.length) {
    //         selectedModelId = selectedMakeModels[0].value;
    //     }

    //     this.updateMakeAndModel()(pricingPlanId, inventoryToUpdate.id, selectedMakeId, selectedModelId);
    //     this.$timeout(this.setValidationStatus);
    // }
    // updateModel(pricingPlanId, inventoryToUpdate, makeId, selectedOption) {
    //     this.updateMakeAndModel()(pricingPlanId, inventoryToUpdate.id, makeId, selectedOption === null ? undefined : selectedOption.value);
    //     this.$timeout(this.setValidationStatus);
    // }
    // updatePhysicalAttribute(pricingPlanId, inventoryToUpdate, typeAttributeId, selectedOption) {
    //     this.updatePhysicalInventoryTypeAttribute()(pricingPlanId, inventoryToUpdate.id, typeAttributeId, selectedOption === null ? undefined : selectedOption.value);
    //     this.$timeout(this.setValidationStatus);
    // }

    // filterHidden(attribute) {
    //     return !attribute.isHidden;
    // }

    isSelectField(attribute) {
        return attribute.PossibleValues && attribute.PossibleValues.length;
    }

    isGroupTemplateConfigured(attribute) {
        return !this.state.isGroupTemplateConfigured ||
        (this.state.isGroupTemplateConfigured &&
            (attribute.ServiceIdentifierFlag || attribute.ServiceAttributeId === this.state.serviceIdentifierDependantAttribute?.ServiceAttributeId)
        );
    }

    onAttributeChange(attribute) {
        if (this.isGlobalTemplate()) {
            if (this.state.isGroupTemplateConfigured) {
                this.actions.overrideGroupTemplate({
                    serviceAttributeId: attribute.ServiceAttributeId,
                    serviceAttributeValue: this.viewModel[attribute.ServiceAttributeId]
                });
            } else {
                this.actions.updateGroupTemplate({
                    serviceAttributeId: attribute.ServiceAttributeId,
                    serviceAttributeValue: this.viewModel[attribute.ServiceAttributeId]
                });
            }
        } else {
            this.actions.updateServiceTemplate({
                serviceTemplateId: this.state.selectedServiceTemplateDetails.Id,
                serviceAttributeId: attribute.ServiceAttributeId,
                serviceAttributeValue: this.viewModel[attribute.ServiceAttributeId]
            });
            this.state.selectedServiceTemplateFields.forEach((templateField) => {
                if (templateField.DependentServiceAttributeId === attribute.Id) {
                    this.actions.updateServiceTemplate({
                        serviceTemplateId: this.state.selectedServiceTemplateDetails.Id,
                        serviceAttributeId: templateField.ServiceAttributeId,
                        serviceAttributeValue: undefined
                    });
                }
            });
        }

        // this.updateAttribute()(attribute.id, attribute.formValue);
        this.setValidationStatus();
    }

    formatPhoneNumber(serviceAttributeId) {
        return getFormattedServiceAttributeValue(
            serviceAttributeId,
            this.viewModel[serviceAttributeId],
            this.state.serviceAttributes,
            this.state.regularExpressions
        );
    }

    openSearchTelephoneNumberModal(attribute) {
        this.isSearchTelephoneNumberModalOpen = true;
        this.selectedAttributeForPhoneNumberSearch = attribute;
        this.reservedNumbers = this.state.serviceTemplates.reduce((accumulator, serviceTemplate) => {
            if (serviceTemplate.attributes[attribute.ServiceAttributeId]) {
                accumulator.push(serviceTemplate.attributes[attribute.ServiceAttributeId]);
            }
            return accumulator;
        }, []);
    }

    closeSearchTelephoneNumberModal() {
        this.isSearchTelephoneNumberModalOpen = false;
        this.selectedAttributeForPhoneNumberSearch = undefined;
    }

    onTelephoneNumberSelected(value) {
        this.viewModel[this.selectedAttributeForPhoneNumberSearch.ServiceAttributeId] = value;
        this.onAttributeChange(this.selectedAttributeForPhoneNumberSearch);
        this.closeSearchTelephoneNumberModal();
    }

    setValidationStatus() {
        this.$timeout(() => {
            const formErrors = this.getFormErrors();
            const hasErrors = this.hasErrors() || (formErrors && (formErrors.patternErrors.length || formErrors.requiredErrors.length));

            this.actions.setActiveFormValidationStatus({
                formName: this.state.selectedServiceTemplateDetails.Id,
                isValid: !hasErrors
            });
            this.setServicesViewModel();
        });
    }

    // onDeliveryOptionsChange(deliveryDecision) {
    //     this.$timeout(this.setValidationStatus);
    //     if (deliveryDecision.selectedValue === DELIVERY_OPTIONS.SHIPPED) {
    //         this.actions.setSelectedInventoryStores([this.state.dbssShippingWarehouseId]);
    //     } else if ((deliveryDecision.selectedValue === DELIVERY_OPTIONS.STORE_PICKUP) &&
    //         this.state.selectedInventoryStores.length &&
    //         this.state.selectedInventoryStores[0] === this.state.dbssShippingWarehouseId) {
    //         this.actions.setSelectedInventoryStores([]);
    //     }
    //     this.actions.setDeliveryDecision(deliveryDecision.selectedValue);
    //     this.actions.updateDeliveryDecision(deliveryDecision.selectedValue);
    // }

    // radioChange(attribute, option) {
    //     this.onAttributeChange(attribute);
    //     this.sendPortInOrItemReservation()(attribute.id, false);
    //     this.updateInventorySelection()(attribute.id, option, attribute.formValue);
    // }

    getFormErrors() {
        // this.hasPhysicalAttributeErrors = false;

        const errors = [...this.state.selectedServiceTemplateFields].map((attributeGroup) => {
            return attributeGroup.Name;
        }).reduce((errorObj, formName) => {
            const formCtrl = this.attributesForm.attributeForm[formName];
            const badPatternFields = [];
            const requiredFields = [];
            const minLengthErrors = [];

            if (formCtrl) {
                if (formCtrl.$error.pattern) {
                    if (formCtrl.$modelValue !== '' || formCtrl.$touched) {
                        badPatternFields.push(formCtrl.$name);
                    }
                }
                if (formCtrl.$error.required) {
                    requiredFields.push(formCtrl.$name);
                }
                if (formCtrl.$error.minLength) {
                    minLengthErrors.push(formCtrl.$name);
                }
            }

            return Object.assign({}, errorObj, {
                requiredErrors: errorObj.requiredErrors.concat(requiredFields),
                minLengthErrors: errorObj.minLengthErrors.concat(minLengthErrors),
                patternErrors: errorObj.patternErrors.concat(badPatternFields)
            });
        }, {
            requiredErrors: [],
            patternErrors: [],
            minLengthErrors: []
        });

        // Object.values(this.detachedPhysicalAttributeForm || {}).forEach((pricingPlanValues) => {
        //     pricingPlanValues.forEach((value) => {
        //         if (value.typeAttributes && value.typeAttributes.length) {
        //             const requiredPhysicalAttributes = value.typeAttributes.filter((attribute) => {
        //                 return attribute.required;
        //             });
        //             requiredPhysicalAttributes.forEach((attribute) => {
        //                 if (!value.selectedTypeAttributes || !value.selectedTypeAttributes[attribute.id]) {
        //                     this.hasPhysicalAttributeErrors = true;
        //                     errors.requiredErrors.push(`${value.displayName}: ${attribute.name}`);
        //                 }
        //             });
        //         }

        //         if (!value.selectedMake && value.IsRequired) {
        //             errors.requiredErrors.push(i18n.translate(CareLocaleKeys.ATTRIBUTES.MAKE_AND_MODEL_REQUIRED, {
        //                 item: value.displayName
        //             }));
        //         } else if (!value.selectedModel && value.IsRequired) {
        //             errors.requiredErrors.push(i18n.translate(CareLocaleKeys.ATTRIBUTES.MODEL_REQUIRED, {
        //                 item: value.displayName
        //             }));
        //         }
        //     });
        // });
        if (this.attributesForm.serviceTaxCustomizationForm) {
            if (this.attributesForm.serviceTaxCustomizationForm.$error?.required?.length) {
                if ((this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'].$valid) ||
                    (this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'].$valid)) {
                    errors.requiredErrors.push(i18n.translate(this.CareLocaleKeys.TAX_LOCATION_RULES.NPA_NXX_ENTRY));
                }
                this.attributesForm.serviceTaxCustomizationForm.$setSubmitted(true);
            }

            if (this.attributesForm.serviceTaxCustomizationForm.$error?.minlength?.length) {
                if ((this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--originatingTaxLocation'].$valid) ||
                    (this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'] &&
                        !this.attributesForm.serviceTaxCustomizationForm['attribute-npaNxxEntry--terminatingTaxLocation'].$valid)) {
                    errors.minLengthErrors.push(i18n.translate(this.CareLocaleKeys.TAX_LOCATION_RULES.NPA_NXX_ENTRY));
                }
                this.attributesForm.serviceTaxCustomizationForm.$setSubmitted(true);
            }
        }

        return errors;
    }

    // changeServiceAttributeList(navItem) {
    //     this.$timeout(this.setValidationStatus);
    //     this.activeNavItem = this.getActiveNavItem();
    //     this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
    //     this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(navItem));
    //     this.actions.setActiveAttributeFormName(navItem.formName);
    // }
    // getInitialAddressIfAvailable() {
    //     if (this.state.currentCustomer.Id) {
    //         this.actions.retrieveCustomerAddresses(this.state.currentCustomer.Id, false)
    //             .then(() => {
    //                 this.currentAddresses = clone(this.state.currentAddresses);
    //             })
    //             .catch((error) => {
    //                 this.uiNotificationService.transientError(error.translatedMessage);
    //             });
    //     } else {
    //         this.actions.updateMobileNumberPortabilityAddressModel({
    //             LineOne: this.state.eligibilityModel.addressLine1,
    //             LineTwo: this.state.eligibilityModel.addressLine2,
    //             Country: this.state.eligibilityModel.country,
    //             City: this.state.eligibilityModel.city,
    //             State: this.state.eligibilityModel.stateRegionProvince,
    //             PostalCode: this.state.eligibilityModel.postalCode,
    //         });
    //     }
    // }
    // isAttributeFieldDuplicated(id) {
    //     return this.attributesDuplicated().includes(id);
    // }
    // attributesDuplicated() {
    //     return this.duplicatedServiceIdentifiers.length ?
    //         this.duplicatedServiceIdentifiers :
    //         this.attributesDuplicatedFromServer();
    // }
    // attributesDuplicatedFromServer() {
    //     return pathOr([], ['faultData'], this.lastAttemptError);
    // }
    // filterNavigationItems(navigationItems) {
    //     return navigationItems.length ? navigationItems.filter(({isHidden}) => {
    //         return !isHidden;
    //     }) : [];
    // }
    // getActiveNavItem() {
    //     return this.filteredNavigationItems.find((navItem) => {
    //         return navItem.isActive;
    //     });
    // }
    // goToPreviousNavItem() {
    //     this.activeNavItem = this.getActiveNavItem();
    //     const currentIndex = this.filteredNavigationItems.indexOf(this.activeNavItem);
    //     const previousNavItem = this.filteredNavigationItems[currentIndex - 1];
    //     this.$timeout(this.setValidationStatus);
    //     this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
    //     this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(previousNavItem));
    //     this.actions.setActiveAttributeFormName(previousNavItem.formName);
    // }
    // goToNextNavItem() {
    //     this.activeNavItem = this.getActiveNavItem();
    //     const currentIndex = this.filteredNavigationItems.indexOf(this.activeNavItem);
    //     const nextNavItem = this.filteredNavigationItems[currentIndex + 1];
    //     this.$timeout(this.setValidationStatus);
    //     this.actions.saveMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem), this.state.showPortability);
    //     this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(nextNavItem));
    //     this.actions.setActiveAttributeFormName(nextNavItem.formName);
    // }
    // getErrorForField(formName, fieldName) {
    //     const fieldController = this.attributesForm[formName][fieldName];

    //     if (fieldController.$error) {
    //         if (fieldController.$error.required) {
    //             return this.getRequiredFieldErrorMessage(fieldName);
    //         } else if (fieldController.$error.pattern) {
    //             return this.getPatternErrorMessage(fieldName);
    //         } else if (this.attributesDuplicatedFromServer().length && !this.duplicatedServiceIdentifiers.length) {
    //             // Server Validation duplicates display a specific message
    //             return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_ALREADY_EXISTS);
    //         } else if (this.duplicatedServiceIdentifiers.length) {
    //             // Local Validation duplicates display a specific message
    //             return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_UNIQUE_ERROR);
    //         }
    //     }
    // }

    getRequiredFieldErrorMessage(fieldName) {
        return i18n.translate(CoreLocaleKeys.FIELD_IS_A_REQUIRED_FIELD, {
            field_name: fieldName
        });
    }

    getPatternErrorMessage(fieldName) {
        return i18n.translate(CoreLocaleKeys.FIELD_IS_INVALID, {
            field_name: fieldName
        });
    }

    getFieldIsTooShortErrorMessage(fieldName, minlength) {
        return i18n.translate(CoreLocaleKeys.FIELD_IS_TOO_SHORT, {
            field_name: fieldName,
            minlength
        });
    }

    getServiceIdentifierDuplicatedErrorMessage() {
        return i18n.translate(CareLocaleKeys.SERVICE_IDENTIFIER_DUPLICATES_FOUND, {
            duplicates: this.state.duplicates.join(',')
        });
    }

    shouldShowErrors() {
        // return !!((this.wizardForm.$submitted &&
        //     (this.attributesForm.$invalid || this.attributesDuplicated().length || this.hasPhysicalAttributeErrors)
        // ) || this.attributesDuplicatedFromServer().length);
        return (this.attributeFormSubmitted && this.attributesForm.$invalid) ||
            this.state.duplicates.length ||
            (this.attributeFormSubmitted && !this.state.serviceTemplates.length);
    }

    hasErrors() {
        return this.attributesForm.$invalid;
    }

    shouldShowFieldError(formName, fieldName, id) {
        if (formName && fieldName) {
            const fieldController = this.attributesForm[formName][fieldName];

            if (this.attributesForm[formName][fieldName]) {
                return (fieldController.$error.required && (fieldController.$touched || this.wizardForm.$submitted)) ||
                    (fieldController.$modelValue && fieldController.$error.pattern) || this.state.isDuplicate;// || this.isAttributeFieldDuplicated(id);
            }
        }
        return false;
    }

    // getMoreOptions(attribute) {
    //     this.searchAttributeInventory()(attribute);
    // }

    // onPortabilityNumberChange(attribute) {
    //     this.activeNavItem = this.getActiveNavItem();
    //     this.actions.setShowPortability(this.state.showPortability);
    //     this.updateAttribute()(attribute.id, this.showPortInOrItemRsvp(attribute));
    //     this.sendPortInOrItemReservation()(attribute.id, this.sendPortInData);
    //     if (this.sendPortInData && this.state.isResumingCartWithMnpData) {
    //         this.sendPortInOrItemReservation()(attribute.id, true);
    //         this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
    //         this.updatePortInRequest()(attribute.id, this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
    //     }
    // }

    // Please note - because this is an exceptional situation with nested
    // dynamically generated forms its less error prone to keep this form
    // in sync the Angular way than the Redux way.
    // See https://confluence.csgicorp.com/display/Ascendon/Redux#Redux-DynamicAngularforms
    // for more information
    $onChanges(changesObj) {
        // const formAttributeGroups = pathOr(null, ['formAttributeGroups', 'currentValue'], changesObj) || this.formAttributeGroups;
        const filteredFormAttributeGroups = pathOr(null, ['filteredFormAttributeGroups', 'currentValue'], changesObj);

        if (filteredFormAttributeGroups && filteredFormAttributeGroups.length) {
            // this.duplicatedServiceIdentifiers = duplicatedServiceIdentifierAttributes(formAttributeGroups);
            // Performs a simple/shallow clone
            this.detachedFormObj = this.filteredFormAttributeGroups.map((attributeGroupVM) => {
                return Object.assign({}, attributeGroupVM);
            });
            // There's an assumption that form groups and attributes in those groups
            // will never change order at runtime
            this.detachedFormObj.forEach((attributeGroup, index) => {
                const sourceGroup = this.filteredFormAttributeGroups[index];
                // Rather than blowing away the reference, use the original attribute
                // reference and replace the three values we care about
                attributeGroup.attributes.forEach((attribute, attrIndex) => {
                    const sourceAttribute = sourceGroup.attributes[attrIndex];
                    attribute.formValue = sourceAttribute.formValue;
                    attribute.isHidden = sourceAttribute.isHidden;
                    attribute.inventoryItemReservation = sourceAttribute.inventoryItemReservation;
                });
            });

            if (changesObj.filteredFormAttributeGroups.previousValue.length) {
                this.handleServiceFeaturesIfServiceIdentifierChanged(changesObj.filteredFormAttributeGroups);
            }
        }

        if (changesObj.attributeFormSubmitted) {
            this.setServicesViewModel();
        }

        if (changesObj.selectedServiceId) {
            this.setServicesViewModel();
            this.setValidationStatus();
        }

        // if (changesObj.filteredPhysicalAttributeGroups) {
        //     this.detachedPhysicalAttributeForm = Object.assign({}, this.filteredPhysicalAttributeGroups);
        // }
        // if (changesObj.navigationItems) {
        //     this.filteredNavigationItems = this.filterNavigationItems(this.navigationItems);
        // }
        // if (changesObj.deliveryOptionsByPricingPlan) {
        //     this.deliveryOptionsByPricingPlanViewModel = clone(changesObj.deliveryOptionsByPricingPlan.currentValue);
        // }
        // if (changesObj.taxLocationOptionsByPricingPlan) {
        //     this.taxLocationOptionsByPricingPlanViewModel = clone(changesObj.taxLocationOptionsByPricingPlan.currentValue);
        // }
    }

    $onDestroy() {
        // this.actions.setActiveAttributeFormName(this.state.currentAttributeFormName);
        this.disconnectRedux();
    }

    // onMobileNumberPortabilityEdit(attribute) {
    //     this.activeNavItem = this.getActiveNavItem();
    //     if (this.sendPortInData && this.state.isResumingCartWithMnpData) {
    //         this.actions.restoreMobileNumberPortabilityAttribute(this.findPortableAttributeId(this.activeNavItem));
    //         this.sendPortInOrItemReservation()(attribute.id, true);
    //         this.updatePortInRequest()(attribute.id, this.state.portabilityModel, this.state.portabilityModel.token, this.state.portabilityModelAddress);
    //     }
    //     this.mobileNumberPortabilityApi.open(attribute);
    //     this.onMobileNumberPortabilityVerificationComplete = (addressData, portabilityData) => {
    //         this.sendPortInOrItemReservation()(attribute.id, true);
    //         this.actions.saveMobileNumberPortabilityAttribute(attribute.id, this.state.showPortability);
    //         this.updateAttribute()(attribute.id, this.state.portabilityModel.existingNumber);
    //         this.updatePortInRequest()(attribute.id, portabilityData, this.mnpToken, addressData);
    //     };
    // }

    // mnpVerificationCallBack(addressData, portabilityData, inventoryTypeId) {
    //     this.showMnpLoadingIndicator = true;
    //     this.actions.validatePortInData(addressData, portabilityData, inventoryTypeId, this.state.currentCustomer.Id).then((response) => {
    //         if (response.Token) {
    //             this.mnpToken = response.Token;
    //             this.showMnpLoadingIndicator = false;
    //             this.mobileNumberPortabilityApi.showConfirmation(response.Token, null);
    //         }
    //     }).catch((error) => {
    //         this.sendPortInData = false;
    //         this.showMnpLoadingIndicator = false;
    //         if (error.faultCode !== FaultCodeKeys.INVALID_PORT_IN_REQUEST_LAMBDA_ERROR) {
    //             this.mobileNumberPortabilityApi.showConfirmation(false, error.translatedMessage);
    //         }
    //     });
    // }

    // showPortInOrItemRsvp(attribute) {
    //     const currentForm = this.state.savedPortabilityAttributes[attribute.id];
    //     if (currentForm && this.state.showPortability) {
    //         this.sendPortInData = true;
    //         return currentForm.portabilityModel.existingNumber;
    //     } else if (!currentForm && this.state.showPortability) {
    //         this.sendPortInData = true;
    //         return null;
    //     } else {
    //         this.sendPortInData = false;
    //         return attribute.selectedValue;
    //     }
    // }

    // findPortableAttributeId(formName) {
    //     const portableAttribute = this.formAttributeGroups.filter((item) => {
    //         return item.formName === formName.id;
    //     });
    //     const mnpAttribute = portableAttribute[0].attributes.find((attribute) => {
    //         return attribute.isPortable;
    //     });
    //     if (mnpAttribute) {
    //         return mnpAttribute.id || null;
    //     }
    // }

    // onTelephoneSelectionPopup(attribute) {
    //     this.phoneSelectionAttribute = attribute;
    //     this.isTelephoneSelectionOpen = true;
    //     this.telephoneNumberSelected = null;
    //     this.telephoneList = null;
    //     this.$timeout(() => {
    //         this.telephoneSelectionApi.open();
    //     });
    // }

    // onTelephoneSelection(attribute, phoneNumber) {
    //     this.isTelephoneSelectionOpen = false;
    //     this.telephoneSelectionApi.close();
    //     if (attribute && phoneNumber) {
    //         attribute.formValue = phoneNumber.SerialNumber;
    //         this.radioChange(attribute, phoneNumber);
    //     }
    //     this.telephoneList = null;

    //     this.formattedPhoneNumber = getFormattedServiceAttributeValue(
    //         attribute.serviceAttributeId,
    //         phoneNumber.SerialNumber,
    //         this.state.serviceAttributes,
    //         this.state.regularExpressions
    //     );
    // }

    // onPhoneNumberSelection(phoneNumber) {
    //     this.telephoneNumberSelected = phoneNumber;
    // }

    // searchPhones(searchPatterns) {
    //     this.actions.fetchTelephoneInventory(this.phoneSelectionAttribute.inventoryTypeId, searchPatterns).then((response) => {
    //         this.telephoneList = response.InventoryItems;
    //     }).catch((error) => {
    //         this.telephoneList = [];
    //         if (error.faultCode !== SEARCH_INVENTORY_NO_RESULTS) {
    //             this.uiNotificationService.transientError(error.translatedMessage);
    //         }
    //     });
    // }

    //Service Features
    closeServiceFeaturesPopup() {
        this.selectServiceFeaturesPopupApi.close();
        this.serviceFeaturesOpen = false;
    }

    getServiceFeatureAttribute(data) {
        return data[0]?.IsServiceFeatureAvailable ? data[0] : {};
    }

    handleServiceFeaturesIfServiceIdentifierChanged({currentValue, previousValue}) {
        const currentService = this.getServiceFeatureAttribute(currentValue);
        const previousService = this.getServiceFeatureAttribute(previousValue);

        if (previousService.id &&
            previousService.serviceFeatures && previousService.serviceFeatures.length &&
            currentService.instanceNumber === previousService.instanceNumber &&
            currentService.pricingPlanId === previousService.pricingPlanId &&
            (currentService.id !== previousService.id)) {

            previousService.serviceFeatures.forEach((serviceFeature) => {
                serviceFeature.ServiceAttributeId = currentService.serviceAttributeId;
            });

            this.updateSelectedServiceFeatures()(currentService.id, previousService.serviceFeatures);
            this.updateSelectedServiceFeatures()(previousService.id, []);
        }
    }

    getMappedServiceFeatures(serviceFeatures, serviceAttribute = {}, skipCheck = false) {
        const modifiedServiceFeature = [];
        let currentServiceFeatures = [];
        this.billerRuleInstanceDetails = [];
        serviceFeatures.forEach((serviceFeature) => {
            if (!skipCheck) {
                currentServiceFeatures = this.state.selectedServiceFeatures && this.state.selectedServiceFeatures.length ? this.state.selectedServiceFeatures.filter((existingServiceFeature) => {
                    return serviceFeature.pricingPlanId === existingServiceFeature.pricingPlanId && serviceFeature.productId === existingServiceFeature.productId;
                }) : [];
            }
            if (serviceFeature.quantity && serviceFeature.quantity > 1 && !skipCheck) {
                for (let i = 0; i < serviceFeature.quantity; i++) {
                    if (serviceFeature.billerRuleDetails && serviceFeature.billerRuleDetails.length) {
                        this.billerRuleInstanceDetails.push({
                            BillerRuleConfigurationId: serviceFeature.billerRuleDetails[0].BillerRuleConfigurationId,
                            ServiceFeatureProductId: serviceFeature.productId,
                            ServiceFeaturePricingPlanId: serviceFeature.pricingPlanId,
                            BillerRuleDecisionDetails: serviceFeature.billerRuleDetails
                        });
                    }
                    modifiedServiceFeature.push({
                        PricingPlanId: serviceFeature.pricingPlanId,
                        PricingPlanName: serviceFeature.pricingPlanName,
                        ProductId: serviceFeature.productId,
                        ProductName: serviceFeature.productName,
                        ServiceAttributeId: serviceAttribute.serviceAttributeId || serviceFeature.serviceAttributeId,
                        ServiceId: serviceAttribute.serviceId || serviceFeature.serviceId,
                        ServiceInstanceId: serviceAttribute.serviceInstanceId || pathOr(undefined, ['serviceInstanceId'], this.getServiceFeatureAttribute(this.state.selectedServiceTemplateFields)), //Service Instance Id  is required to associate the SF to a service if the service exists
                        SubscriberProductId: currentServiceFeatures[i] ?
                            currentServiceFeatures[i].subscriberProductId :
                            undefined,
                        BillerRuleDetails: serviceFeature.billerRuleDetails
                    });
                };
            } else {
                if (serviceFeature.billerRuleDetails && serviceFeature.billerRuleDetails.length) {
                    this.billerRuleInstanceDetails.push({
                        BillerRuleConfigurationId: serviceFeature.billerRuleDetails[0].BillerRuleConfigurationId,
                        ServiceFeatureProductId: serviceFeature.productId,
                        ServiceFeaturePricingPlanId: serviceFeature.pricingPlanId,
                        BillerRuleDecisionDetails: serviceFeature.billerRuleDetails
                    });
                }
                modifiedServiceFeature.push({
                    PricingPlanId: serviceFeature.pricingPlanId,
                    PricingPlanName: serviceFeature.pricingPlanName,
                    ProductId: serviceFeature.productId,
                    ProductName: serviceFeature.productName,
                    ServiceAttributeId: serviceAttribute.serviceAttributeId || serviceFeature.serviceAttributeId,
                    ServiceId: serviceAttribute.serviceId || serviceFeature.serviceId,
                    ServiceInstanceId: serviceAttribute.serviceInstanceId || pathOr(undefined, ['serviceInstanceId'], this.getServiceFeatureAttribute(this.state.selectedServiceTemplateFields)), //Service Instance Id  is required to associate the SF to a service if the service exists
                    SubscriberProductId: serviceFeature.subscriberProductId || undefined,
                    BillerRuleDetails: serviceFeature.billerRuleDetails
                });
            }
        });
        return modifiedServiceFeature;
    }

    openServiceFeaturesPopup() {
        this.orderScenario = this.getServiceFeatureAttribute(this.state.selectedServiceTemplateFields).ParentServicePlanOrderScenario;
        this.serviceFeaturesOpen = true;
        this.serviceFeatures = cloneDeep(this.state.selectedServiceFeatures);
        const serviceAttributeWithServiceId = this.state.allServiceAttributeFields.find((attribute) => {
            return !!attribute.ServiceId;
        });
        this.actions.searchServiceFeatures(serviceAttributeWithServiceId.ServiceId, this.state.currentCustomer.Id, this.segmentationContext)
            .then(() => {
                this.$timeout(() => {
                    this.selectServiceFeaturesPopupApi.open();
                });
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    onProductPricingPlansRequested(productId) {
        const serviceAttributeWithServiceId = this.state.allServiceAttributeFields.find((attribute) => {
            return !!attribute.ServiceId;
        });
        this.actions.retrieveProductContextForServiceFeatures(productId, serviceAttributeWithServiceId.ServiceId, this.state.currentCustomer.Id, this.orderScenario);
        this.actions.getServiceFeatureProduct(productId, this.state.currentCustomer.Language);
    }

    onServiceFeaturePricingPlanSelection(serviceFeatures) {
        this.productIds = [];
        const serviceAttribute = this.getServiceFeatureAttribute(this.state.selectedServiceTemplateFields);
        this.updateSelectedServiceFeatures()(
            this.getServiceFeatures(
                this.getMappedServiceFeatures(serviceFeatures, serviceAttribute)
            )
        );
        // this.restoreOfferingContext()();
        this.fetchServiceAddonPricingPlanDetails();
        this.closeServiceFeaturesPopup();
    }

    getServiceFeatures(selectedServiceFeatures) {
        return selectedServiceFeatures.map((feature) => {
            const metadataProduct = this.state.metadataProductsAndPricingPlan.productMetadataByProductId[feature.ProductId];
            const metadataPricingPlan = this.state.metadataProductsAndPricingPlan.productMetadataByProductId[feature.ProductId].PricingPlans.find((plan) => {
                return plan.Id === feature.PricingPlanId;
            });
            return Object.assign({}, feature, {
                billerRuleDetails: feature.BillerRuleDetails,
                displayName: `${metadataProduct.Name}: ${metadataPricingPlan.Name}`,
                pricingPlanId: feature.PricingPlanId,
                pricingPlanName: feature.PricingPlanName,
                productId: feature.ProductId,
                productName: feature.ProductName,
                quantity: feature.Quantity
            });
        });
    }

    // removeServiceFeature(serviceFeature) {
    //     const selectedServiceFeatures = clone(this.detachedFormObj[0].serviceFeatures);
    //     const indexOfServiceFeatureToRemove = selectedServiceFeatures.findIndex((selectedServiceFeature) => {
    //         if (selectedServiceFeature.productId === serviceFeature.productId &&
    //             selectedServiceFeature.pricingPlanId === serviceFeature.pricingPlanId) {
    //             return this.addItems && this.addItems.length ? !selectedServiceFeature.subscriberProductId : true;
    //         };
    //     });
    //
    //     selectedServiceFeatures.splice(indexOfServiceFeatureToRemove, 1);
    //     this.updateSelectedServiceFeatures()(
    //         serviceFeature.attributeId,
    //         this.getMappedServiceFeatures(selectedServiceFeatures),
    //         this.billerRuleInstanceDetails,
    //         true
    //     );
    //     // this.restoreOfferingContext()();
    // }

    fetchServiceAddonPricingPlanDetails() {
        if (this.state.selectedProducIdsOfServiceFeatures.length) {
            this.actions.retrieveProductsMetadata(this.state.selectedProducIdsOfServiceFeatures)
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    updateCustomizedServiceTaxValue(customizedServiceTax) {
        const newTaxLocation = customizedServiceTax[this.state.defaultTaxLocationDecision.PricingPlanId][this.state.selectedServiceTemplateDetails.Id];
        this.actions.updateServiceTemplate({
            serviceTemplateId: this.state.selectedServiceTemplateDetails.Id,
            taxLocation: newTaxLocation.taxOption === TAX_RULE_OPTIONS.CUSTOM ? newTaxLocation.ServiceTaxCustomization : null
        });
    }
}

export default {
    template: require('./attributes.html'),
    controller: AttributesController,
    controllerAs: 'attributesController',
    bindings: {
        addItems: '<',
        attributeFormSubmitted: '<',
        // attributeTitle: '<',
        deliveryOptionsByPricingPlan: '<',
        // filteredFormAttributeGroups: '<',
        // filteredPhysicalAttributeGroups: '<',
        // formAttributeGroups: '<',
        // formPhysicalAttributeGroups: '<',
        isFeaturesHidden: '<?',
        // showCustomizeTaxOption: '<?',
        lastAttemptError: '<',
        // navigationItems: '<',
        // restoreOfferingContext: '&?',
        // searchAttributeInventory: '&',
        segmentationContext: '<?',
        selectedServiceId: '<?',
        // selectedDeliveryDecision: '<?',
        // sendPortInOrItemReservation: '&',
        // setEditAttributeGroups: '&',
        // setEditPhysicalAttributeGroups: '&',
        // showNextButton: '<',
        // showPreviousButton: '<',
        // taxLocationOptionsByPricingPlan: '<?',
        // updateAttribute: '&',
        // updateInventorySelection: '&',
        // updateMakeAndModel: '&',
        // updatePhysicalInventoryTypeAttribute: '&',
        // updatePortInRequest: '&',
        updateSelectedServiceFeatures: '&',
        // updateServiceTaxLocationDecision: '&?'
    },
    require: {
        wizardForm: '^form'
    }
};
