import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import clone from 'ramda/src/clone';

export const processServiceFeatures = (productId, product, pricingPlans, cart, featureOrderQuantities, routeParams, orders, changeOfServiceRequest, pricingPlanToExclude) => {
    const shoppingCart = clone(cart);
    const existingMutableCart = clone(cart);
    const unmodifiedAddItems = changeOfServiceRequest.AddItems && changeOfServiceRequest.AddItems.length ? getUnmodifiedItems(productId, changeOfServiceRequest.AddItems, pricingPlanToExclude) : [];
    const unmodifiedRemoveItems = changeOfServiceRequest.RemoveItems && changeOfServiceRequest.RemoveItems.length ? getUnmodifiedItems(productId, changeOfServiceRequest.RemoveItems, pricingPlanToExclude) : [];
    shoppingCart.AddItems = !shoppingCart.AddItems ? [] : shoppingCart.AddItems;
    shoppingCart.AddItems = shoppingCart.AddItems.concat(unmodifiedAddItems);
    shoppingCart.RemoveItems = !shoppingCart.RemoveItems ? [] : shoppingCart.RemoveItems;
    shoppingCart.RemoveItems = shoppingCart.RemoveItems.concat(unmodifiedRemoveItems);
    const additionalCartItems = [];
    if (pricingPlans && pricingPlans.length) {
        pricingPlans.forEach((pricingPlan) => {
            const existingQuantities = featureOrderQuantities.filter((feature) => {
                return feature.productId === productId && feature.pricingPlanId === pricingPlan.Id;
            });
            const cartItem = {
                Details: {
                    PricingPlan: pricingPlan,
                    Product: product
                },
                PricingPlanId: pricingPlan.Id,
                ProductId: productId,
                Quantity: 0,
                ServiceFeatureProductAssociation: {
                    ServiceId: routeParams.serviceTypeId,
                    ServiceIdentifierValue: routeParams.serviceId,
                    ServiceInstanceId: routeParams.serviceInstanceId
                },
                ShoppingCartItemBillerRuleDetails: pricingPlan.ShoppingCartItemBillerRuleDetails
            };
            let countDiff = 0;
            const isAdd = pricingPlan.cartQuantity > existingQuantities.length;
            let isModify = false;
            const isRemove = pricingPlan.cartQuantity < existingQuantities.length;
            if (isAdd || isRemove) {
                countDiff = Math.abs(pricingPlan.cartQuantity - existingQuantities.length);
            } else if (existingQuantities.length && pricingPlan.cartQuantity === existingQuantities.length) {
                if (
                    (
                        pricingPlan.ShoppingCartItemBillerRuleDetails &&
                        existingQuantities[0].shoppingCartItemBillerRuleDetails &&
                        pricingPlan.ShoppingCartItemBillerRuleDetails.length !== existingQuantities[0].shoppingCartItemBillerRuleDetails.length
                    ) ||
                    (
                        pricingPlan.ShoppingCartItemBillerRuleDetails
                        && pricingPlan.ShoppingCartItemBillerRuleDetails.length
                        && (pricingPlan.ShoppingCartItemBillerRuleDetails[0].Amount !== existingQuantities[0].shoppingCartItemBillerRuleDetails[0].Amount)
                    )
                ) {
                    isModify = true;
                    countDiff = pricingPlan.cartQuantity;
                };
            }
            for (let quant = 0; quant < pricingPlan.cartQuantity; quant++) {
                cartItem.Quantity = 1;
                additionalCartItems.push(cartItem);
            }
            while ( countDiff > 0) {
                if (isAdd || isModify) {
                    shoppingCart.AddItems.push(cartItem);
                }
                if (isRemove || isModify) {
                    buildRemoveItems(
                        isRemove || isModify,
                        pricingPlan,
                        featureOrderQuantities,
                        shoppingCart,
                        cartItem,
                        productId,
                        orders,
                        countDiff-1);
                }
                countDiff--;
            }
        });


    };
    shoppingCart.Items = [...additionalCartItems, ...getUnModifiedCartItems(existingMutableCart, routeParams, featureOrderQuantities, productId)];
    return shoppingCart;
};

//build remove items
const buildRemoveItems = (
    quantityChanged,
    pricingPlan,
    featureOrderQuantities,
    shoppingCart,
    cartItem,
    productId,
    orders,
    countDiff) => {
    if (quantityChanged) {
        const subscriberProductQuantities = featureOrderQuantities.filter((quantity) => {
            return quantity.productId === productId && quantity.pricingPlanId === pricingPlan.Id;
        });
        const subscriberProductsIds = subscriberProductQuantities && subscriberProductQuantities.length ? pluck('subscriberProductId', subscriberProductQuantities) : [];
        const foundOrderItem = getOrderItem(orders, productId, pricingPlan.Id, subscriberProductsIds[countDiff]);
        const removeItem = clone(cartItem);
        removeItem.Quantity = 1;
        removeItem.LockerItemId = subscriberProductsIds[countDiff];
        removeItem.ServiceFeatureProductAssociation = Object.assign({}, removeItem.ServiceFeatureProductAssociation, {
            OrderItemId: foundOrderItem ? foundOrderItem.Id : null
        });
        if (foundOrderItem) {
            shoppingCart.RemoveItems.push(removeItem);
        }
    }
};

export const getUnmodifiedItems = (productId, items, pricingPlanToExclude) => {
    let unmodifiedItems = [];
    if (!pricingPlanToExclude) {
        unmodifiedItems = unmodifiedItems.concat(items.filter((item) => {
            return item.ProductId !== productId;
        }));
    } else {
        unmodifiedItems = unmodifiedItems.concat(items.filter((item) => {
            return item.ProductId === productId && item.PricingPlanId !== pricingPlanToExclude;
        }).concat(items.filter((item) => {
            return item.ProductId !== productId;
        })));
    }
    return unmodifiedItems;
};

export const getOrderItem = (orders, productId, pricingPlanId, lockerItemId) => {
    let foundOrderItem = null;
    if (orders) {
        orders.forEach((order) => {
            order.OrderItems.forEach((orderItem) => {
                if (orderItem.Product.Id === productId && orderItem.PricingPlan.Id === pricingPlanId && orderItem.LockerItemId === lockerItemId) {
                    foundOrderItem = orderItem;
                }
            });
        });
    }
    return foundOrderItem;
};



export const getUnModifiedCartItems = (shoppingCart, routeParams, featureOrderQuantities, productId) => {
    const unmodifiedItems = [];
    if (shoppingCart.Items && shoppingCart.Items.length) {
        shoppingCart.Items.forEach((item) => {
            if (productId !== item.ProductId) {
                item.ServiceFeatureProductAssociation = {
                    AssociatedServiceSubscriberProductId: routeParams.subscriberProductId,
                    ServiceId: routeParams.serviceTypeId,
                    ServiceIdentifierValue: routeParams.serviceId,
                    ServiceInstanceId: routeParams.serviceInstanceId
                };
                unmodifiedItems.push(item);
            }
        });
    }
    return unmodifiedItems;
};

export const getExistingQuantities = (pricingPlanId, productId, featureOrderQuantities) => {
    const existingQuantities = featureOrderQuantities.filter((feature) => {
        return feature.productId === productId && feature.pricingPlanId === pricingPlanId;
    });
    return pathOr(0, ['length'], existingQuantities);
};

export const splitIndividualProducts = (products) => {
    const splitProducts = [];

    products.forEach((product) => {
        for (let counter = 0; counter < product.quantity; counter++) {
            splitProducts.push(Object.assign({}, product, {
                quantity: 1,
                standalone: true
            }));
        }
    });

    return splitProducts;
};
