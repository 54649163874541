import i18n from 'invision-core/src/components/i18n/i18n';
import flatten from 'ramda/src/flatten';
import pathOr from 'ramda/src/pathOr';
import pluck from 'ramda/src/pluck';
import CustomerCareKeys from '../../../../locales/keys';
import {
    getTransitionOfferingMetadata,
    retrieveTransitionContext,
    setTransitionOfferingsMetadata
} from '../../../../reducers/actions/transition.offer.wizard.actions';
import {
    TransitionContextSelector,
    TransitionCurrentOfferingsSelector,
    TransitionCurrentOffersSavingsSelector,
    TransitionOfferingsSelector,
    TransitionOffersSavingsSelector,
    TransitionParsedOutcomesSelector
} from '../../../../reducers/selectors/transition.offer.wizard.selectors';
import {OfferingMetadataPricingPlansSelector} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {PagesIsFetchingDataSelector} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {
    CurrentCustomerCurrencyCodeSelector,
    CurrentCustomerSelector,
    StoreOrderSelector
} from '../../../../reducers/selectors/customer.selectors';
import {PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE} from '../../../../customercare.constants';
import {TransitionStates} from '../../../../reducers/constants/transition.offer.wizard.constants';

class CompareStepComponent {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            uiNotificationService,
            CustomerCareKeys,
            outcomeFilter: this.outcomeFilter.bind(this),
            providesPlanRemovalWarning: '',
            toggleOutcomeFilter: this.toggleOutcomeFilter.bind(this)
        });
    }

    $onInit() {
        this.isLoading = true;
        this.transitionError = false;
        this.parsedOutcomes = [];
        this.showOnlyChanges = false;

        const mapStateToTarget = (store) => {
            return {
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerCurrencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentOfferings: TransitionCurrentOfferingsSelector(store),
                currentOfferingSavings: TransitionCurrentOffersSavingsSelector(store),
                isFetchingData: PagesIsFetchingDataSelector(store),
                offeringMetadataPricingPlans: OfferingMetadataPricingPlansSelector(store),
                outcomes: TransitionParsedOutcomesSelector(store),
                storeOrder: StoreOrderSelector(store),
                transitionContext: TransitionContextSelector(store),
                transitionOfferings: TransitionOfferingsSelector(store),
                transitionOfferingSavings: TransitionOffersSavingsSelector(store)
            };
        };

        const controllerActions = {
            getTransitionOfferingMetadata,
            retrieveTransitionContext,
            setTransitionOfferingsMetadata
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.currencyCode = this.state.currentCustomerCurrencyCode;

        const offeringId = pathOr(null, ['Context', 'OfferingIds', '0'], this.state.storeOrder);
        this.actions.retrieveTransitionContext(this.customerId, this.selectedOfferId ? this.selectedOfferId : offeringId, this.fromOfferId, this.offeringInstanceId).then((response) => {
            this.transitionError = false;
            this.isLoading = false;
            const offeringsIds = Array.from(new Set(pluck('Id', response.TransitionContext.CurrentOfferings).concat(pluck('Id', response.TransitionContext.TransitionOfferings))));
            this.setChangeProvidesPlanWarning();
            Promise.all(offeringsIds.map((id) => {
                return this.actions.getTransitionOfferingMetadata(id, this.state.currentCustomer.Language);
            })).then((response) => {
                this.actions.setTransitionOfferingsMetadata(response);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }, () => {
            this.uiNotificationService.transientError(i18n.translate(this.CustomerCareKeys.CHANGE_OFFER.CHANGE_CONTEXT_FAILURE));
            this.transitionError = true;
            this.isLoading = false;
        });

    }

    $onDestroy() {
        this.disconnectRedux();
    }

    outcomeFilter(item) {
        return !this.showOnlyChanges ||
        item.isRemoval ||
        item.isChange ||
        item.isAddition;
    }

    toggleOutcomeFilter() {
        this.showOnlyChanges = !this.showOnlyChanges;
    }

    getExistingOfferTitle() {
        return this.state.currentOfferings.length === 1 ? i18n.translate(this.CustomerCareKeys.CHANGE_OFFER.EXISTING_OFFER, {
            offeringName: this.state.currentOfferings[0].DisplayName || this.state.currentOfferings[0].Name
        }) : i18n.translate(this.CustomerCareKeys.CHANGE_OFFER.EXISTING_OFFERS);
    }

    getTransitionOfferTitle() {
        return i18n.translate(this.CustomerCareKeys.CHANGE_OFFER.NEW_OFFER, {
            offeringName: this.state.transitionOfferings[0].DisplayName || this.state.transitionOfferings[0].Name
        });
    }

    setChangeProvidesPlanWarning() {
        const offeringPlans = flatten(this.state.currentOfferings.map((item) => {
            return item.Items;
        }));
        const removedPlans = offeringPlans.filter((plan) => {
            return plan.TransitionState === TransitionStates.REMOVED
                && this.state.offeringMetadataPricingPlans[plan.PricingPlanId].ServiceRelationshipType === PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.PROVIDES;
        });
        if (removedPlans.length) {
            this.providesPlanRemovalWarning = removedPlans.length > 1 ? i18n.translate(CustomerCareKeys.DECISIONS.REMOVE_PLAN_ASSOCIATIONS_GENERIC)
                : i18n.translate(CustomerCareKeys.DECISIONS.REMOVE_PLAN_ASSOCIATIONS_SINGLE, {
                    pricingPlanName: this.state.offeringMetadataPricingPlans[removedPlans[0].PricingPlanId].PricingPlanName
                });
        }
    }
}

export default {
    template: require('./compare.html'),
    bindings: {
        customerId: '<',
        selectedOfferId: '<',
        fromOfferId: '<',
        offeringInstanceId: '<'
    },
    controller: CompareStepComponent,
    controllerAs: 'CompareStepController'
};
