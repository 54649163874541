import {CustomerServicesWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';

export const SERVICES_AND_SHARED_ENTITLEMENTS_TAB = {
    SHARED_ENTITLEMENTS: 'sharedEntitlements',
    SERVICES: 'services'
};

export const SERVICES_USAGE_STATE_NAME = 'index.customercare.customer.servicesAndUsage';

export const SERVICES_AND_SHARED_ENTITLEMENTS = `${SERVICES_USAGE_STATE_NAME}.servicesAndSharedEntitlements`;

export const SERVICE_DETAILS_STATE_NAME = `${SERVICES_USAGE_STATE_NAME}.serviceDetails`;

export default function Routing($stateProvider) {
    $stateProvider.state(SERVICES_USAGE_STATE_NAME, {
        redirectTo: SERVICES_AND_SHARED_ENTITLEMENTS,
        url: '/servicesAndUsage',
        template: '<ui-view class="c-content-container c-content-containHeight"></ui-view>'
    }).state(SERVICE_DETAILS_STATE_NAME, {
        url: '/serviceDetails/:serviceIdentifier',
        template: '<service-details-page class="u-flexKeepHeight"></service-details-page>'
    }).state(SERVICES_AND_SHARED_ENTITLEMENTS, {
        url: '/:tabName?entitlementIndex',
        template: '<services-and-usage class="c-content-containHeight"></services-and-usage>',
        params: {
            tabName: SERVICES_AND_SHARED_ENTITLEMENTS_TAB.SERVICES,
            subscriberProductId: null,
            orderItemId: null,
            orderId: null
        },
        data: {
            windowTitle: CustomerServicesWindowTitleSelector
        }
    });
}
