import CustomerCareLocaleKeys from '../../../locales/keys';
import {find,
    propEq} from 'ramda';
import {
    AdditionalPropValueType,
    MetadataConstants,
    MetadataHelpers,
    MetadataSelectors,
    CoreLocaleKeys
} from 'invision-core';
import {setEditAdditionalProperty,
    setEditApOnChange} from '../../../reducers/actions/customer.actions';
import {
    SubscriberAdditionalPropertiesSelector
} from './additional.properties.filtered.selector';

const mapStateToTarget = (store) => {
    return {
        additionalPropertyLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdditionalProperty, store),
        additionalPropertyValueLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AdditionalPropertyValue, store),
        subscriberAdditionalPropData: SubscriberAdditionalPropertiesSelector(store),
        regularExpressionLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.RegularExpression, store),
        additionalPropValueSelector: MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(MetadataConstants.codes.AdditionalPropertyValue, store)
    };
};

class AdditionalPropertiesSectionController {

    constructor($ngRedux) {
        this.$ngRedux = $ngRedux;
    }

    $onInit() {
        const controllerActions = {
            setEditAdditionalProperty,
            setEditApOnChange
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state= state;
            this.actions = actions;
        });
        this.actions.setEditAdditionalProperty(this.state.subscriberAdditionalPropData);

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.CoreLocaleKeys = CoreLocaleKeys;
    }

    handleFormChanged() {
        this.onFormChanged()(this.customerModel);
    }

    onListUpdated() {
        this.actions.setEditAdditionalProperty(this.state.subscriberAdditionalPropData);
    }

    onAddlPropertyChange(id, newValue) {
        const findType = find(propEq(id, 'id'))(this.state.subscriberAdditionalPropData);
        switch (findType.type) {
            case AdditionalPropValueType.SINGLE_TEXT:
                this.actions.setEditApOnChange(id, [newValue]);
                this.onPropertyChange()(this.customerModel);
                break;
            case AdditionalPropValueType.MULTIPLE_TEXT:
                this.actions.setEditApOnChange(id, newValue);
                this.onPropertyChange()(this.customerModel);
                break;
            case AdditionalPropValueType.SELECT:
            case AdditionalPropValueType.BOOLEAN:
                newValue = newValue ? [MetadataHelpers.codes.getNameForCode(this.state.additionalPropValueSelector, newValue)] : [];
                this.actions.setEditApOnChange(id, newValue);
                this.onPropertyChange()(this.customerModel);
                break;
            default:
                break;
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./additional.properties.section.html'),
    bindings:{
        customerModel:'<',
        onFormChanged:'&',
        onPropertyChange:'&',
        errorLookup: '<',
        isFormSubmitted: '<',
        markMandatoryCreditCheckFieldsDisabled: '<?',
        overrideValidations: '<?',
        removeMultiOfferValidations: '<?'
    },
    controller: AdditionalPropertiesSectionController,
    controllerAs: 'AdditionalPropertiesSection'
};
