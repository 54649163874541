import i18n from 'invision-core/src/components/i18n/i18n';
import CareLocaleKeys from '../../../../../../locales/keys';
import {CurrentAddressesSelector} from '../../../../../../reducers/selectors/customer.addresses.selectors';
import {updateCustomizedServiceTax} from '../../../../../../reducers/actions/services.actions';
import {CurrentCustomerIdSelector} from '../../../../../../reducers/selectors/customer.selectors';
import {IsUpdatingServiceTaxSelector} from '../../../../../../reducers/selectors/services.selectors';
class ServiceTaxCustomizationDetailsController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CareLocaleKeys,
            closeEditServiceTaxCustomization: this.closeEditServiceTaxCustomization.bind(this),
            moreMenuItems: [{
                title: i18n.translate(CareLocaleKeys.EDIT),
                action: () => {
                    this.openEditServiceTaxCustomization();
                }
            }],
            onMoreMenuItemSelected: this.onMoreMenuItemSelected.bind(this),
            onSave: this.onSave.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const controllerActions = {
            updateCustomizedServiceTax
        };
        const mapStateToProps = (state) => {
            return {
                currentAddresses: CurrentAddressesSelector(state),
                currentCustomerId: CurrentCustomerIdSelector(state),
                isUpdatingServiceTax: IsUpdatingServiceTaxSelector(state)
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToProps, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        this.editServiceTaxPopUpConfig = {
            onRegisterApi: (event) => {
                this.editServiceTaxPopupApi = event.api;
            }
        };
    }

    onMoreMenuItemSelected({item: itemAction}) {
        itemAction.action();
    }

    openEditServiceTaxCustomization() {
        this.showEditServiceTaxPopup = true;
        this.$timeout(this.editServiceTaxPopupApi.open);
    }

    closeEditServiceTaxCustomization() {
        this.showEditServiceTaxPopup = false;
        this.editServiceTaxPopupApi.close();
    }

    onSave(customTax) {
        this.actions.updateCustomizedServiceTax(customTax, this.state.currentCustomerId)
            .then(() => {
                this.closeEditServiceTaxCustomization();
                return this.fetchService()();
            })
            .catch((error) => {
                this.closeEditServiceTaxCustomization();
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

}

export default {
    bindings: {
        serviceTaxDetails: '<',
        openEditServiceTaxView: '&?',
        fetchService: '&'
    },
    template: require('./service.tax.customization.details.template.html'),
    controller: ServiceTaxCustomizationDetailsController,
    controllerAs: 'ServiceTaxCustomizationDetailsController'
};