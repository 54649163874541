import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    GiftOrderPreviewAsMutableSelector,
    GiftOrderRedemptionCodeSelector,
    GiftOrderRequiresPaymentInstrumentSelector,
    GiftOrderSelectedShippingAddressSelector,
    GiftOrderShipToNameSelector,
    GiftOrderSubmitSelector,
    GiftOrderSubscriptionQuotesAsMutableSelector,
    IsFetchingPreviewGiftOrderSelector,
    IsSubmittingGiftOrderRedemptionSelector,
    SelectedShippingMethodSelector,
    ShippingAddressesAvailableForGiftOrder,
    SubscriptionRenewalChargeAndCurrencySelector
} from '../../../reducers/selectors/redeem.gift.order.selectors';
import {
    getDefaultPaymentMethodFromPaymentInstruments
} from '../../../reducers/helpers/ewallet.reducer.helper';
import {
    IsRetrievingEwalletDataSelector,
    PaymentInstrumentsWithTypeNamesViewModel
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerStateSelector
} from './../../../reducers/selectors/customer.selectors';
import {
    AddressesErrorSelector,
    IsFetchingAddressesSelector
} from './../../../reducers/selectors/customer.addresses.selectors';
import {
    retrieveAvailablePaymentInstrumentTypes
} from './../../../reducers/actions/customer.ewallet.actions';
import {
    retrieveCustomerAddresses
} from './../../../reducers/actions/customer.addresses.actions';
import {
    fetchWallet
} from '../../../reducers/actions/edit.offer.wizard.actions';
import {
    fetchGiftOrderPreview,
    resetRedeemGiftOrder,
    setSelectedShippingMethod,
    submitRedeemGiftOrder,
    updateGiftOrderRedemptionCode,
    updateGiftOrderSelectedShippingAddress,
    updateGiftOrderShipToName,
    updatePreviewWithSubscriptionsQuote
} from '../../../reducers/actions/redeem.gift.order.actions';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors
} from 'invision-core';
import {stateGo} from 'redux-ui-router';
import {
    retrieveCustomerHouseholds
} from '../../../reducers/actions/customer.household.actions';
import {
    any,
    pathOr
} from 'ramda';
import {retrieveCustomer} from '../../../reducers/actions/customer.actions';
import {CUSTOMER_STATE} from '../../shared/constants/customer.constants';

const PRODUCT_IMAGE_WIDTH = 200;

class RedeemGiftOrderController {
    constructor($ngRedux, $timeout, $filter, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.uiNotificationService = uiNotificationService;

        this.addressFormFieldLocaleKeyMapping = {
            shipToName: CustomerCareLocaleKeys.ADDRESSES.SHIP_TO_NAME
        };

        this.onAddressSwitcherChangeCallback = this.onAddressSwitcherChangeCallback.bind(this);
        this.onAddressSwitcherCreateOrEditCallback = this.onAddressSwitcherCreateOrEditCallback.bind(this);
        this.onChangePaymentInstrumentCallback = this.onChangePaymentInstrumentCallback.bind(this);
        this.getAdditionalInformationHeading = this.getAdditionalInformationHeading.bind(this);
        this.selectedShippingMethod = null;
    }

    $onInit() {
        this.localeKeys = CustomerCareLocaleKeys;
        this.stateOrName = 'index.customercare.customer.redeemGiftOrder';
        this.product_image_width = PRODUCT_IMAGE_WIDTH;
        this.currentPaymentInstrument;
        this.showPaymentInformation = false;
        this.showAddressInformation = false;
        this.paymentMethodErrorMessages = [];

        const mapStateToTarget = (store) => {
            return {
                addressesServiceError: AddressesErrorSelector(store),
                availableAddresses: ShippingAddressesAvailableForGiftOrder(store),
                currentCustomerState: CurrentCustomerStateSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                giftOrderPreview: GiftOrderPreviewAsMutableSelector(store),
                giftOrderRequiresPaymentInstrument: GiftOrderRequiresPaymentInstrumentSelector(store),
                giftOrderSubmit: GiftOrderSubmitSelector(store),
                isFetchingAddresses: IsFetchingAddressesSelector(store),
                isFetchingEwalletData: IsRetrievingEwalletDataSelector(store),
                isFetchingPreviewGiftOrder: IsFetchingPreviewGiftOrderSelector(store),
                isSubmittingGiftOrderRedemption: IsSubmittingGiftOrderRedemptionSelector(store),
                redemptionCode: GiftOrderRedemptionCodeSelector(store),
                selectablePaymentInstruments: PaymentInstrumentsWithTypeNamesViewModel(store),
                selectedShippingAddress: GiftOrderSelectedShippingAddressSelector(store),
                selectedShippingMethod: SelectedShippingMethodSelector(store),
                shippingMethods: MetadataSelectors.shippingMethods.ShippingMethodsSelector(store),
                shippingMethodsIsLoading: MetadataSelectors.shippingMethods.ShippingMethodsMetadataIsLoadingSelector(store),
                shipToName: GiftOrderShipToNameSelector(store),
                structureTypesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.StructureType, store),
                structureTypes: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.StructureType, store),
                subscriptionQuotes: GiftOrderSubscriptionQuotesAsMutableSelector(store),
                subscriptionRenewalChargeAndCurreny: SubscriptionRenewalChargeAndCurrencySelector(store)
            };
        };

        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            fetchGiftOrderPreview,
            fetchWallet,
            getShippingMethodsByCountryCode: MetadataActions.shippingMethods.getShippingMethodsByCountryCode,
            resetRedeemGiftOrder,
            retrieveCustomer,
            retrieveAvailablePaymentInstrumentTypes,
            retrieveCustomerAddresses,
            retrieveCustomerHouseholds,
            setSelectedShippingMethod,
            stateGo,
            submitRedeemGiftOrder,
            updateGiftOrderRedemptionCode,
            updateGiftOrderSelectedShippingAddress,
            updateGiftOrderShipToName,
            updatePreviewWithSubscriptionsQuote
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.redemptionCode = this.state.redemptionCode;

        if (!this.state.structureTypesLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.StructureType);
        }

        this.submitRedeemGiftOrderSuccessConfig = {
            onRegisterApi: (evt) => {
                this.submitRedeemGiftOrderSuccessPopup = evt.api;
            }
        };

        this.submitRedeemGiftOrderErrorConfig = {
            onRegisterApi: (evt) => {
                this.submitRedeemGiftOrderErrorPopup = evt.api;
            }
        };

        this.submitRedeemGiftOrderSuccessRedirect = () => {
            this.submitRedeemGiftOrderSuccessPopup.close();
            if (this.state.currentCustomerState === CUSTOMER_STATE.PROSPECT) {
                this.actions.retrieveCustomer(this.state.currentCustomerId);
            }
            this.actions.stateGo('index.customercare.customer.dashboard', {
                customerId: this.state.currentCustomerId
            });
        };

        this.closeSubmitRedeemGiftOrderErrorPopup = () => {
            this.submitRedeemGiftOrderErrorPopup.close();
        };

        // Preview Renewal Statements Popup Dialog bits
        this._previewRenewalStatementsPopupApi = null;
        this.showPreviewRenewalStatementsDialog = false;
        this.previewRenewalStatementsPopupConfig = {
            onRegisterApi: (event) => {
                this._previewRenewalStatementsPopupApi = event.api;
            }
        };

        this.handleRedemptionCodeInputKeydown = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.handleGiftOrderPreviewClick();
            }
        };

        this.loadHouseholdData();

    }

    getAdditionalInformationHeading() {
        let headingProperty = '';

        if (this.showAddressInformation && this.showPaymentInformation) {
            headingProperty = this.localeKeys.REDEEM_GIFT_ORDER.PAYMENT_AND_SHIPPING_TITLE;
        } else if (this.showAddressInformation) {
            headingProperty = this.localeKeys.SHIPPING_INFORMATION;
        } else if (this.showPaymentInformation) {
            headingProperty = this.localeKeys.PAYMENT_INFORMATION;
        }

        return i18n.translate(headingProperty);
    }

    loadHouseholdData() {
        if (!this.state.currentHousehold) {
            this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId, false).finally(() => {
                this.initializedHouseholdData = true;
            });
        } else {
            this.initializedHouseholdData = true;
        }
    }

    isLoading() {
        return this.state.isFetchingPreviewGiftOrder
                || this.state.isSubmittingGiftOrderRedemption
                || this.state.isFetchingEwalletData
                || !this.initializedHouseholdData
                || this.state.shippingMethodsIsLoading;
    }

    _handleAddressesForGiftOrderPreviewOfShippableGift() {
        this._updateAddressOnGift(this.state.giftOrderPreview.shippingAddress);

        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId).catch(() => {
            if (this.state.addressesServiceError) {
                this.uiNotificationService.error(this.state.addressesServiceError.translatedMessage);
            }
        });
    }

    _handlePaymentMethodsForGiftOrderPreviewOfSubscriptionGift() {
        this.actions.fetchWallet(this.state.currentCustomerId).then(() => {
            this.currentPaymentInstrument = getDefaultPaymentMethodFromPaymentInstruments(this.state.selectablePaymentInstruments);
            if (this.currentPaymentInstrument) {
                this.actions.updatePreviewWithSubscriptionsQuote(this.redemptionCode, this.currentPaymentInstrument.Id, this.state.currentCustomerId).catch((error) => {
                    this.uiNotificationService.error(error.translatedMessage);
                });
            }
        }).catch(() => {
            if (this.state.retrieveEwalletError) {
                this.uiNotificationService.error(this.state.retrieveEwalletError.message);
            }
        });
        this.actions.retrieveCustomerHouseholds(this.state.currentCustomerId);
    }

    handleGiftOrderPreviewClick() {
        this.showAddressInformation = false;
        this.showPaymentInformation = false;
        this.actions.retrieveAvailablePaymentInstrumentTypes();
        this.actions.fetchGiftOrderPreview(this.redemptionCode).then(() => {
            if (this.state.giftOrderRequiresPaymentInstrument) {
                this.showPaymentInformation = true;
                this._handlePaymentMethodsForGiftOrderPreviewOfSubscriptionGift();
            }

            if (this.state.giftOrderPreview.shippingAddress) {
                this.showAddressInformation = true;
                this._handleAddressesForGiftOrderPreviewOfShippableGift();
                this._updateShippingMethodOnChange();
            }
        });
        this.actions.updateGiftOrderRedemptionCode(this.redemptionCode);
    }

    onChangePaymentInstrumentCallback(paymentInstrument) {
        //Set the current active payment instrument
        this.currentPaymentInstrument = paymentInstrument;
        this.actions.updatePreviewWithSubscriptionsQuote(this.redemptionCode, this.currentPaymentInstrument.Id, this.state.currentCustomerId).catch((error) => {
            this.uiNotificationService.error(error.translatedMessage);
        });

        if (paymentInstrument) {
            this.paymentMethodErrorMessages = [];
        }
    }

    handlePreviewRenewalStatements() {
        this.showPreviewRenewalStatementsDialog = true;
        this.$timeout(this._previewRenewalStatementsPopupApi.open);
    }

    handleClosePreviewRenewalStatementsDialog() {
        this.showPreviewRenewalStatementsDialog = false;
        this._previewRenewalStatementsPopupApi.close();
    }

    handleSubmitGiftOrder() {
        const hasRequiredPaymentInstrument = !this.showPaymentInformation || (this.showPaymentInformation && pathOr(false, ['currentPaymentInstrument', 'Id'], this));
        if (!hasRequiredPaymentInstrument) {
            this.paymentMethodErrorMessages = [i18n.translate(CustomerCareLocaleKeys.REDEEM_GIFT_ORDER.PAYMENT_REQUIRED_ERROR)];
        }

        if (this.redemptionForm && this.redemptionForm.$valid && hasRequiredPaymentInstrument) {
            this.actions.updateGiftOrderRedemptionCode(this.redemptionCode);

            this.actions.submitRedeemGiftOrder({
                redemptionCode: this.redemptionCode,
                shippingAddress: this.state.giftOrderPreview.shippingAddress ? this.state.selectedShippingAddress : null,
                shippingMethodId: this.state.giftOrderPreview.shippingMethod ? pathOr(null, ['state', 'selectedShippingMethod', 'Id'], this) : null,
                shipToName: this.state.giftOrderPreview.shippingAddress ? this.state.shipToName : null,
                customerId: this.state.currentCustomerId,
                paymentInstrumentId: this.state.giftOrderRequiresPaymentInstrument ? pathOr(null, ['currentPaymentInstrument', 'Id'], this) : null
            }).then(() => {
                this.submitRedeemGiftOrderSuccessPopup.open();
            }, () => {
                this.submitRedeemGiftOrderErrorPopup.open();
            });
        }
    }

    giftPreviewHasProductsOrSubscriptions() {
        return this.state.giftOrderPreview.products.length !== 0 || this.state.giftOrderPreview.subscriptions.length !== 0;
    }

    onAddressSwitcherChangeCallback(newlySelectedAddress) {
        this._updateAddressOnGift(newlySelectedAddress);
    }

    onAddressSwitcherCreateOrEditCallback(newOrUpdatedAddress) {
        this.actions.retrieveCustomerAddresses(this.state.currentCustomerId).catch(() => {
            if (this.state.addressesServiceError) {
                this.uiNotificationService.error(this.state.addressesServiceError.translatedMessage);
            }
        });
        this._updateAddressOnGift(newOrUpdatedAddress);
    }

    onShipToNameChanged() {
        this.actions.updateGiftOrderShipToName(this.selectedShipToName);
    }

    _updateAddressOnGift(updatedAddress) {
        this.actions.updateGiftOrderSelectedShippingAddress(updatedAddress);

        this.selectedShipToName = updatedAddress.ShipToName;
        this.actions.updateGiftOrderShipToName(updatedAddress.ShipToName);
        this._updateShippingMethodOnChange();
    }

    _updateShippingMethodOnChange() {
        this.actions.getShippingMethodsByCountryCode(this.state.selectedShippingAddress.Country).then(() => {
            this.actions.setSelectedShippingMethod(this._getShippingMethodOnAddressChange());
            this.selectedShippingMethod = this.state.selectedShippingMethod;
            this.redemptionForm.selectedShippingMethod.$setTouched(); //need to call this because a bad value can be set without ever touching the control
        });
    }

    onChangeSelectedShippingMethod() {
        if (this.selectedShippingMethod) {
            this.actions.setSelectedShippingMethod(this.selectedShippingMethod);
        }
    }

    getShippingMethodDisplayName(shippingMethod) {
        const shippingCost = this.$filter('invCurrency')(shippingMethod.ShippingCost, shippingMethod.Currency);
        return `${shippingCost} - ${shippingMethod.Name}`;
    }

    _getShippingMethodOnAddressChange() {
        //find if we can select the currently selected shipping method again
        if (this.state.selectedShippingMethod) {
            const isSelectedShippingMethodAvailable = any((shippingMethod) =>  {
                return shippingMethod.Id === pathOr(null, ['Id'], this.state.selectedShippingMethod);
            }, this.state.shippingMethods);

            if (isSelectedShippingMethodAvailable) {
                return this.state.selectedShippingMethod;
            }
        }

        // if not lets try for the shipping method that was chosen on the order originally
        const isOrderedShippingMethodAvailable = any((shippingMethod) =>  {
            return shippingMethod.Id === pathOr(null, ['shippingMethod', 'Id'], this.state.giftOrderPreview);
        }, this.state.shippingMethods);

        if (isOrderedShippingMethodAvailable) {
            return  this.state.giftOrderPreview.shippingMethod;
        }

        //All else fails, just default to an empty/error state
        return null;
    }

    $onDestroy() {
        this.actions.resetRedeemGiftOrder();
        this.disconnectRedux();
    }
}

export default {
    template: require('./redeem.gift.order.html'),
    bindings: {},
    controller: RedeemGiftOrderController
};
