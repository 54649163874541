import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {debug} from 'invision-core/src/components/helpers/log.helper';
import pathOr from 'ramda/src/pathOr';
import CustomerCareLocaleKeys from '../../../../locales/keys';
import {
    CurrentCustomerSelector,
    HasConvergentBillerIdSelector
} from '../../../../reducers/selectors/customer.selectors';
import {
    AddressesErrorSelector,
    CreateAddressPopupStateRegionProvinceValueOptions,
    IsCreatingAddressSelector,
    PostalCodeSelector,
    PreventOverrideFlagSelector
} from '../../../../reducers/selectors/customer.addresses.selectors';
import {
    createAddress,
    setAddressData,
    updateOverrideFlag
} from '../../../../reducers/actions/customer.addresses.actions';
import {ADDRESS_FORM_FIELD_MAX_LENGTH} from '../addresses.constants';
import {
    IsValidatingStatusSelector,
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../reducers/selectors/address.component.selectors';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../reducers/actions/address.component.actions';
import {isAddressValidFromAddressResponse} from '../../../shared/contextualComponents/address/address.validator.helper';
import {performCreditCheck} from '../../../../reducers/actions/credit.check.actions';
import {stateRequiredHelper} from '../address.helper';

function CreateAddressPopupController($ngRedux, $timeout, uiNotificationService) {
    let disconnectRedux;
    const mapStateToTarget = (store) => {
        return {
            addressCountriesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressCountry, store),
            addressRequirements: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.AddressRequirements, store),
            addressRequirementsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressRequirements, store),
            addressStatesLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.AddressStateProvinceRegion, store),
            addressStatesOptions: CreateAddressPopupStateRegionProvinceValueOptions(store),
            currentCustomer: CurrentCustomerSelector(store),
            hasConvergentBiller: HasConvergentBillerIdSelector(store),
            isCreatingAddress: IsCreatingAddressSelector(store),
            isDbss: IsDbss(store),
            isAddressBeingValidatedFromApi: IsValidatingStatusSelector(store),
            lastAttemptError: AddressesErrorSelector(store),
            postalCode: PostalCodeSelector(store),
            preventOverrideFlag: PreventOverrideFlagSelector(store),
            unvalidatedAddresses: UnvalidatedAddressesSelector(store),
            validatedAddresses: ValidatedAddressesSelector(store)
        };
    };
    const controllerActions = {
        createAddress,
        fetchAddressCountryTypes: MetadataActions.codes.fetchAddressCountryTypes,
        fetchAddressStateTypes: MetadataActions.codes.fetchAddressStateTypes,
        fetchCodeType: MetadataActions.codes.fetchCodeTypes,
        performCreditCheck,
        setAddressData,
        setForceShowEditFormAddress,
        updateOverrideFlag,
        validateAddress
    };

    let popupApi = null;

    const formFieldLocaleKeyMapping = {
        shipToName: CustomerCareLocaleKeys.ADDRESSES.SHIP_TO_NAME,
        textScrubber: CustomerCareLocaleKeys.ADDRESSES.TEXT_SCRUBBER,
        addressType: CustomerCareLocaleKeys.ADDRESSES.ADDRESS_TYPE
    };

    this.$onInit = () => {
        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.resetForm = () => {
            this.address = {
                Country: this.state.currentCustomer.HomeCountry || null,
                State: null,
                DefaultPostal: this.state.isDbss && !this.state.hasConvergentBiller ? true: undefined,
                DefaultService: this.state.isDbss && !this.state.hasConvergentBiller ? true: undefined,
            };
            this.addressFormModel = {
                country: this.address.Country,
                stateRegionProvince: this.address.State
            };
            this.textScrubberOverride = undefined;
            this.formErrors = [];
            // called before $ngRedux.connect, must guard
            this.actions && this.state && this.updateStateField();
        };

        this.formMaxLengthValidation = ADDRESS_FORM_FIELD_MAX_LENGTH;

        this.resetForm();
        this.showForm = false;

        if (this.state.postalCode.mask || this.state.postalCode.regEx) {
            this.actions.setAddressData(null);
        }

        if (!this.state.addressStatesLoaded) {
            this.actions.fetchAddressStateTypes();
        }

        if (!this.state.addressRequirementsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.AddressRequirements);
        }

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.formFieldLocaleKeyMapping = formFieldLocaleKeyMapping;
        this.onAddressFormChanged = this.onAddressFormChanged.bind(this);

        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.resetForm();
                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };
    };

    this.handleCloseDialog = () => {
        this.closePopup();
    };

    this.handleKeypressForm = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.handleSubmitForm();
        }
    };

    this.handleSubmitForm = () => {
        this.formController.$submitted = true;
        this.formController.highlightErrorMessages();

        if (this.formController.$valid) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        this.actions.createAddress(
                            this.customerId,
                            validatedAddress.ValidatedAddress.Status ? validatedAddress.ValidatedAddress : this.address,
                            this.textScrubberOverride,
                            this.state.preventOverrideFlag
                        ).then((result) => {
                            this.closePopup();
                            const onAddressCreated = this.onAddressCreated();
                            onAddressCreated && onAddressCreated(result.Address);
                            this.actions.performCreditCheck(null, this.customerId);
                            this.actions.updateOverrideFlag(null);
                        }, () => {
                            this.apiFaults = [this.state.lastAttemptError.translatedMessage];
                        });
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        uiNotificationService.transientError(
                            i18n.translate(this.CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                        );
                    }
                }).catch((err) => {
                    uiNotificationService.transientError(err.translatedMessage);
                });
        }
    };

    this.handleClickCancelButton = () => {
        this.closePopup();
    };

    this.handleChangeFormCountry = () => {
        this.updateStateField();
    };

    this.closePopup = () => {
        if (this.state.isCreatingAddress) {
            // app is going to crash; log a User-friendly message for the developer
            debug('closing popup while awaiting async operation');
        }
        popupApi.close();
        this.showForm = false;
    };

    this.updateStateField = () => {
        this.actions.setAddressData(this.address, this.textScrubberOverride);
        this.stateRequired = stateRequiredHelper(this.state.addressRequirements, this.state.addressStatesOptions);

        // if Country has states, reset State if it's not one of them
        if (this.state.addressStatesOptions && !this.state.addressStatesOptions.find((stateOption) => {
            return stateOption.value === this.address.State;
        })) {
            this.address.State = null;
            this.addressFormModel.stateRegionProvince = this.address.State;
        }
    };

    this.onAddressFormChanged = (addressFormModel) => {
        this.address.LineOne = addressFormModel.addressLine1;
        this.address.LineTwo = addressFormModel.addressLine2;
        this.address.City = addressFormModel.city;
        this.address.State = addressFormModel.stateRegionProvince;
        this.address.PostalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;
        this.address.ShipToName = addressFormModel.shipToName;

        this.addressFormModel.addressLine1 = addressFormModel.addressLine1;
        this.addressFormModel.addressLine2 = addressFormModel.addressLine2;
        this.addressFormModel.city = addressFormModel.city;
        this.addressFormModel.country = addressFormModel.country;
        this.addressFormModel.stateRegionProvince = addressFormModel.stateRegionProvince;
        this.addressFormModel.postalCode = addressFormModel.postalCode ? addressFormModel.postalCode : null;

        if (addressFormModel.country !== this.address.country) {
            this.address.Country = addressFormModel.country;
            this.handleChangeFormCountry();
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.getAddressKey = () => {
        return pathOr(undefined, ['formController', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    };

    this.getAddressKeyForAddressValidatedThroughAPI = () => {
        return pathOr(undefined, ['formController', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    };

    this.getJobToken = () => {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    };

    this.validateAddressWithAPI = () => {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey];

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            const addressTypes = {
                DefaultBilling: this.address.DefaultBilling,
                DefaultHome: this.address.DefaultHome,
                DefaultPostal: this.address.DefaultPostal,
                DefaultService: this.address.DefaultService,
                DefaultShipping: this.address.DefaultShipping,
                DefaultWork: this.address.DefaultWork
            };

            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    };
}

export default {
    template: require('./create.address.popup.html'),
    bindings: {
        config: '<',
        customerId: '<',
        onAddressCreated: '&',
        shipToNameRequired: '<'
    },
    controllerAs: 'CreateAddressPopupController',
    controller: CreateAddressPopupController
};
