import CustomerCareKeys from '../../../../../locales/keys';
import {CUSTOMER_CATEGORY} from '../../../../createCustomerPopup/create.customer.popup.constants';

class CustomerContactInfoController {

    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            requirements: {}
        });
    }

    $onInit() {
        this.requirements = this.customer.Category === CUSTOMER_CATEGORY.RESIDENTIAL ? this.residentialRequirements : this.commercialRequirements;
    }
}


export default {
    template: require('./customer.contact.info.html'),
    controller: CustomerContactInfoController,
    bindings: {
        customer: '<',
        residentialRequirements: '<',
        commercialRequirements: '<'
    }
};
