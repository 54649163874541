import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from './../../../../locales/keys';
import {validateCustomerPin} from '../../../../reducers/actions/customer.actions';
import {VALIDATE_PIN_RESULT} from '../../constants/customer.constants';

class ValidatePinModalComponent {
    constructor($ngRedux, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareKeys,
            displayValidationFailedMessage: false,
            isLoading: false,
            pin: null,
            formErrors: [
                i18n.translate(CustomerCareKeys.PROFILE_PAGE.PIN_VALIDATION_ERROR)
            ],
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = () => {
            return {};
        };

        const controllerActions = {
            validateCustomerPin
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onDestory() {
        this.disconnectRedux();
    }

    validate() {
        if (this.pin) {
            this.isLoading = true;
            this.displayValidationFailedMessage = false;

            this.actions.validateCustomerPin(this.subscriberId, this.pin).then((response) => {
                if (response.Status === VALIDATE_PIN_RESULT.SUCCESS) {
                    this.onClose()(true);
                } else {
                    this.displayValidationFailedMessage = true;
                }
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.isLoading = false;
            });
        }
    }
}

export default {
    bindings: {
        subscriberId: '<',
        onClose: '&'
    },
    template: require('./validate.pin.modal.template.html'),
    controller: ValidatePinModalComponent,
    controllerAs: 'pinModal'
};
