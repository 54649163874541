import CustomerLocaleKeys from '../../../locales/keys';

import {CurrentCustomerIdSelector,
    CurrentCustomerSelector} from '../../../reducers/selectors/customer.selectors';
import {CustomerTotalsCollectionSelector,
    IsFetchingTotalsDataSelector,
    TotalsCountSelector} from '../../../reducers/selectors/customer.totals.selectors';
import {retrieveTransactionTotals} from '../../../reducers/actions/customer.totals.actions';

import {i18n} from 'invision-core';

class TotalsController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerLocaleKeys
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                isFetchingTotals: IsFetchingTotalsDataSelector(store),
                totals: CustomerTotalsCollectionSelector(store),
                totalsCount: TotalsCountSelector(store),
            };
        };

        const controllerActions = {
            retrieveTransactionTotals
        };

        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.columnDefs = [
            {
                field: 'Currency',
                displayName: i18n.translate(CustomerLocaleKeys.TOTALS.CURRENCY),
                cellTemplate: require('./cellTemplates/currency.type.html')
            },
            {
                field: 'GrossAmount',
                displayName: i18n.translate(CustomerLocaleKeys.TOTALS.ORIGINAL_AMOUNT),
                cellTemplate: require('./cellTemplates/original.amount.html')
            },
            {
                field: 'CreditAmount',
                displayName: i18n.translate(CustomerLocaleKeys.TOTALS.CREDITS),
                cellTemplate: require('./cellTemplates/credit.amount.html')
            },
            {
                field: 'WriteOffAmount',
                displayName: i18n.translate(CustomerLocaleKeys.TOTALS.WRITE_OFFS),
                cellTemplate: require('./cellTemplates/write.off.html')
            },
            {
                field: 'NetAmount',
                displayName: i18n.translate(CustomerLocaleKeys.TOTALS.NET_AMOUNT),
                cellTemplate: require('./cellTemplates/net.amount.html')
            }
        ];

        this.stateOrName = 'index.customercare.customer.totals';

        if (!this.state.totals) {
            this.actions.retrieveTransactionTotals(this.state.currentCustomerId);
        }
    }
}

export default {
    template: require('./totals.html'),
    controller: TotalsController,
    controllerAs: 'Totals'
};
