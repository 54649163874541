import LocaleKeys from '../../../../../../../locales/keys';

class IncludedItemController {
    constructor() {
        Object.assign(this, {
            localeKeys: LocaleKeys,
            instanceProperties: {}
        });
    }

    $onInit() {
        this.instanceProperties = this.ngModel || this.productItem.instanceProperties || {};

        if (this.modelCtrl) {
            this.modelCtrl.$setViewValue(this.instanceProperties);
            this.modelCtrl.$validate();
        }
    }

    onInstancePropertiesChanged() {
        this.modelCtrl.$setViewValue(this.instanceProperties);
        if (this.modelCtrl) {
            this.modelCtrl.$validate();
        }
    }
}

export default {
    template: require('./product.included.item.html'),
    controller: IncludedItemController,
    require: {
        modelCtrl: '?ngModel'
    },
    bindings: {
        disableInstanceProperties: '<',
        ngModel: '<?',
        pricingPlan: '<',
        productItem: '<',
        showInstanceProperties: '<',
        showPurchasedStatus: '<?'
    }
};
