import { CustomerCareEWalletWindowTitleSelector } from '../../../reducers/selectors/customer.selectors';
import { E_WALLET_ACCESS } from '../../../security.attributes';
import {
    clearSelectedItemId,
    clearSelectedPaymentMethod,
    clearSelectedTypeId,
    emptyEWalletList,
    removeEWalletFilter
} from '../../../reducers/actions/customer.ewallet.actions';
import { LIST_STATE_OR_NAME as TRANSACTION_ROUTE } from '../transactions/transactions.config';

export const E_WALLET_STATE_REDIRECT =
    'index.customercare.customer.eWalletRedirect';
export const STATE_OR_NAME = 'index.customercare.customer.ewallet';

export default ($stateProvider) => {
    $stateProvider.state(STATE_OR_NAME, {
        url: '/ewallet',
        template: '<customer-ewallet></customer-ewallet>',
        data: {
            windowTitle: CustomerCareEWalletWindowTitleSelector
        },
        securityAttributes: [E_WALLET_ACCESS],
        onExit: ($ngRedux, $state) => {
            const NEXT_ROUTE = $state.next.name;
            if (TRANSACTION_ROUTE !== NEXT_ROUTE) {
                $ngRedux.dispatch(removeEWalletFilter());
                $ngRedux.dispatch(emptyEWalletList());
                $ngRedux.dispatch(clearSelectedPaymentMethod());
                $ngRedux.dispatch(clearSelectedItemId());
                $ngRedux.dispatch(clearSelectedTypeId());
            }
        }
    });
};
