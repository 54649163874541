import CustomerCareKeys from '../../../../../locales/keys';
import {createConvergentBillerAdjustment, retrieveLedgerSummary} from '../../../../../reducers/actions/customer.billing.actions';
import {fetchCustomerInventory} from '../../../../../reducers/actions/customer.inventory.actions';
import {
    CurrentCustomerIdSelector,
    CurrentCustomerCurrencyCodeSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {DateTimeHelper} from 'invision-core';
import {OPEN_INVOICES, CREDIT_NOTE_TYPE, CONVERT_TO_CREDIT} from '../../../../billingPayments/billing.payments.constants';
import {MISC_ADJUSTMENT} from '../../../../adjustment/adjustment.modal.constants';
import {
    BillingPaymentsOpenInvoicesSelector,
    InvoiceListForOpenInvoiceWidgetSelector,
    IsFetchingOpenInvoicesSelector,
    OpenInvoicesRecordCountSelector,
    TotalAccountBalanceSelector,
    TotalCashReceivableSelector,
    TotalWithheldTaxSelector
} from '../../../../../reducers/selectors/customer.billing.selectors';
import {IsDbss, CurrentBusinessUnitTimeZoneSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {stateGo} from 'redux-ui-router';
import i18n from 'invision-core/src/components/i18n/i18n';
import {MAKE_PAYMENT_ROUTE} from '../../../makePayment/make.payment.constants';
import {CASE_CATEGORIES} from '../../../../customer/cases/list/case.constants';
import {CASES_DETAILS_STATE_REDIRECT} from '../../../../customer/cases/cases.config';
import {CurrentAccountSummarySelector} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';

class OpenInvoicesWidgetController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            CASE_CATEGORIES,
            currentCase: {},
            disputeInvoice: this.disputeInvoice.bind(this),
            uiNotificationService,
            goToInvoiceDetails: this.goToInvoiceDetails.bind(this),
            handleConvertToCreditModalClose: this.handleConvertToCreditModalClose.bind(this),
            makeAdjustment: this.makeAdjustment.bind(this),
            onConvertToCreditFormSubmit: this.onConvertToCreditFormSubmit.bind(this),
            openConvertToCredit: this.openConvertToCredit.bind(this),
            openAdjustmentModal: this.openAdjustmentModal.bind(this),
            goToMakePayment: this.goToMakePayment.bind(this),
            hasLatePayment: this.hasLatePayment.bind(this),
            infoToolTipTemplate: require('../../../../shared/tooltipTemplates/open.invoices.tooltip.template.html'),
            CustomerCareKeys,
            isConvertToCreditModalOpen: false,
            isExpanded: false
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currencyCode: CurrentCustomerCurrencyCodeSelector(store),
                currentAccountSummary: CurrentAccountSummarySelector(store),
                currentBUTimeZone: CurrentBusinessUnitTimeZoneSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                invoiceData: InvoiceListForOpenInvoiceWidgetSelector(store),
                invoiceList: BillingPaymentsOpenInvoicesSelector(store),
                isDbss: IsDbss(store),
                isFetchingOpenInvoices: IsFetchingOpenInvoicesSelector(store),
                recordCountOfLedger: OpenInvoicesRecordCountSelector(store),
                totalAccountBalance: TotalAccountBalanceSelector(store),
                totalCashReceivable: TotalCashReceivableSelector(store),
                totalWithheldTax: TotalWithheldTaxSelector(store)
            };
        };

        const controllerActions = {
            createConvergentBillerAdjustment,
            fetchCustomerInventory,
            retrieveLedgerSummary,
            stateGo: stateGo
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.retrieveLedgerSummary();

        this.adjustmentModalConfig = {
            onRegisterApi: ({api}) => {
                this.adjustmentModalApi = api;
            }
        };

        this.caseModalProps = {
            isOpen: false,
            onClose: () => {
                this.caseModalProps.isOpen = false;
            },
            onSubmit: (caseDetails) => {
                this.caseModalProps.isOpen = false;
                this.uiNotificationService.success(i18n.translate(this.CustomerCareKeys.CASE.CREATED_SUCCESSFULLY));

                this.actions.stateGo(CASES_DETAILS_STATE_REDIRECT, {
                    customerId: this.state.currentCustomerId,
                    caseId: caseDetails.Id.Value
                });
            },
            openCaseModal: (invoiceNumber) => {
                this.caseModalProps.disputeDetails.selectedInvoiceNumber = invoiceNumber;
                this.caseModalProps.isOpen = true;
            },
            disputeDetails: {
                selectedInvoiceNumber: ''
            },
            caseTemplateId: CASE_CATEGORIES.INVOICE_DISPUTE,
            routeParams: {
                customerId: this.state.currentCustomerId
            }
        };
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    goToInvoiceDetails(id) {
        this.actions.stateGo('index.customercare.customer.billingpayments.detail', {
            invoiceId: id
        });
    };

    retrieveLedgerSummary() {
        const request = {
            customerId: this.state.currentCustomerId,
            request: {
                Filter: OPEN_INVOICES,
                IncludeAccountBalance: true,
                PageNumber: 1,
                PageSize: 12
            }
        };

        this.actions.retrieveLedgerSummary(request, true)
            .then(() => {
                this.isExpanded = true;
                this.invoiceDataForWidget = this.state.invoiceData;
                this.invoiceDataForCollapsedWidget = this.state.invoiceData.slice(0, 4);
                this.viewAllText = i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.VIEW_ALL_OPEN_INVOICES, {
                    count: this.state.recordCountOfLedger
                });
                this.showExpandCollapseIcon = this.state.invoiceData.length >  4;
                if (this.closeAdjustmentModal) {
                    this.onAdjustmentClose();
                    this.closeAdjustmentModal  = false;
                }

                if (this.isConvertToCreditModalOpen) {
                    this.handleConvertToCreditModalClose();
                }
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    hasLatePayment() {
        return (this.state?.currentAccountSummary?.PostpaidDetails && this.state.currentAccountSummary.PostpaidDetails.LatePaymentEligible) ?
            (this.state.currentAccountSummary.PostpaidDetails.LatePaymentAmountDue > 0 &&
                this.state.currentAccountSummary.PostpaidDetails.DueDate !== null &&
                this.state.currentAccountSummary.PostpaidDetails.LatePaymentFeeAmount > 0) : false;
    }

    latePaymentLabel() {
        const $filter = getFilterService();
        const latePayment = {
            dueAmount: $filter('invCurrency')(this.state.currentAccountSummary.PostpaidDetails.LatePaymentAmountDue, this.state.currentAccountSummary.PostpaidDetails.CurrencyCode),
            date: $filter('localShortDate')(this.state.currentAccountSummary.PostpaidDetails.DueDate),
            latePaymentFee : $filter('invCurrency')(this.state.currentAccountSummary.PostpaidDetails.LatePaymentFeeAmount, this.state.currentAccountSummary.PostpaidDetails.CurrencyCode)
        };
        return i18n.translate(this.CustomerCareKeys.CUSTOMER_DASHBOARD.LATE_PAYMENT_FEE.LATE_PAYMENT_FEE_LABEL, latePayment);
    };

    makeAdjustment(actionItem) {
        this.openAdjustmentModal(actionItem);
    }

    onAdjustmentClose() {
        this.adjustmentModalApi.close();
        this.invoiceDetails = {};
    }

    onFetchLedgerSummaryAdjustmentClose() {
        this.closeAdjustmentModal  = true;
        this.retrieveLedgerSummary();
    }

    disputeInvoice(actionItem) {
        this.caseModalProps.openCaseModal(actionItem.invoiceNumber);
    }

    openAdjustmentModal(actionItem) {
        this.adjustmentType = MISC_ADJUSTMENT;
        this.$timeout(() => {
            this.adjustmentModalApi.open();
        });
        const invoiceItem = this.state.invoiceList.invoices.find((invoice) => {
            return invoice.Invoice.InvoiceId === actionItem.id;
        });
        this.invoiceDetails = {
            accountNumber: invoiceItem.Invoice.AccountNumber,
            invoiceNumber: invoiceItem.Invoice.InvoiceNumber,
            invoiceId: invoiceItem.Invoice.InvoiceId,
            maxAmount: invoiceItem.Invoice.CurrentDue
        };
    }

    openConvertToCredit(actionItem) {
        const invoiceItem = this.state.invoiceList.invoices.find((invoice) => {
            return invoice.Invoice.InvoiceId === actionItem.id;
        });

        this.convertToCreditInitialValues = {
            AccountNumber: invoiceItem.Invoice.AccountNumber,
            noteType: CREDIT_NOTE_TYPE,
            InvoiceId: invoiceItem.Invoice.InvoiceId,
            InvoiceNumber: invoiceItem.Invoice.InvoiceNumber,
            Amount: Math.abs(invoiceItem.Invoice.CurrentDue),
            GenerateStatement: true
        };

        this.isConvertToCreditModalOpen = true;
    }

    onConvertToCreditFormSubmit(formValues) {
        const {noteType, InvoiceNumber, ...convertToCreditFormValues} = formValues;
        const currentDateInBillingTimezone = DateTimeHelper.offsetTimezone(new Date(), this.state.currentBUTimeZone);

        const request = {
            customerId: this.state.currentCustomerId,
            request: {
                AdjustmentTypeCode: CONVERT_TO_CREDIT,
                PostDate: currentDateInBillingTimezone,
                ...convertToCreditFormValues
            }
        };

        this.actions.createConvergentBillerAdjustment(request)
            .then(() => {
                this.isConvertToCreditModalOpen = false;
                this.retrieveLedgerSummary();
                this.uiNotificationService.transientSuccess(i18n.translate(this.CustomerCareKeys.CONVERT_TO_CREDIT_SUCCESS));
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    handleConvertToCreditModalClose() {
        this.isConvertToCreditModalOpen = false;
    }

    goToMakePayment(invoiceDetails) {
        this.actions.stateGo(MAKE_PAYMENT_ROUTE, {
            invoiceNumber: invoiceDetails.invoiceNumber,
            paymentType: invoiceDetails.actionId
        });
    };

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./open.invoices.html'),
    controller: OpenInvoicesWidgetController,
    controllerAs: 'OpenInvoicesWidget'
};
