import __ from 'ramda/src/__';
import CustomerCareKeys from '../../../../../locales/keys';
import {
    CoreLocaleKeys,
    i18n,
    SessionSelectors
} from 'invision-core';

function OverrideFinanceBriPopupController($ngRedux, $timeout) {
    let disconnectRedux;
    const mapStateToTarget = (store) => {
        return {
            language: SessionSelectors.LanguageSelector(store),
        };
    };
    const controllerActions = {};

    let popupApi = null;

    this.$onInit = () => {
        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.coreLocaleKeys = CoreLocaleKeys;
        this.customerCareKeys = CustomerCareKeys;

        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    $timeout(() => {
                        this.resetForm();
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };
    };


    this.cancelEditPricing = () => {
        this.closePopup();
    };

    this.closePopup = () => {
        popupApi.close();
    };

    this.onPriceChange = () => {
        const params = [];
        if (this.editOption && this.editOption.BillerRuleInstanceThumbnails.length) {
            const britObj = {
                optionId: this.editOption.Id,
                brcId : this.financeBriToEdit.BillerRuleConfigurationId,
                financeOverrides: {
                    Amount: this.billerRuleDecisionDetail.amount,
                    ...(!this.isFullUpfrontPayment && {
                        TermLength: this.billerRuleDecisionDetail.termLength
                    }),
                    /*
                    * For finance BRI, quantity will be 1.
                    */
                    Quantity:1
                }
            };
            params.push(britObj);
        }
        this.updateEditOptionPrice()(params);
    };

    this.resetForm = () => {
        this.popupTitle = `${i18n.translate(this.customerCareKeys.EDIT_BULK_PLAN.EDIT_BULK_PRICING)} ${this.editOption.Name}`;
        this.financeBriToEdit = this.editOption?.BillerRuleInstanceThumbnails?.[0];
        this.isFullUpfrontPayment = this.editOption.PricingPlanBillerRuleInstances?.FinanceBillerRuleInstances?.[0]?.FullUpfrontPayment || false;
        this.billerRuleDecisionDetail= {
            amount: this.editOption.BillerRuleInstanceThumbnails?.[0]?.Amount ||
                this.editOption.PricingPlanBillerRuleInstances?.FinanceBillerRuleInstances?.[0]?.ChargeAmount,
            termLength: this.editOption?.BillerRuleDetails?.[0]?.TermLength || this.editOption.PricingPlanBillerRuleInstances?.FinanceBillerRuleInstances?.[0]?.TermLength
        };
        this.isPlanOrServiceChange = this.editOption.isPlanOrServiceSwap;
    };

    this.restoreDefaults = (e) => {
        e.preventDefault();
        this.billerRuleDecisionDetail= {
            amount: this.financeBriToEdit.CatalogAmount,
            termLength: this.editOption.PricingPlanBillerRuleInstances?.FinanceBillerRuleInstances?.[0]?.TermLength
        };
        this.onPriceChange();
    };

    this.savePricing = () => {
        if (this.formApi.$valid) {
            if (this.isModify) {
                this.editOption = Object.assign({}, this.editOption, {
                    isPlanOrServiceSwap: this.isPlanOrServiceChange
                });
                this.saveOptionEdit()(this.editOption);
                this.setEditOption()(this.editOption);
            }
            this.saveBulkPricing()(this.editOption);
            this.updateCart()(() => {
                this.closePopup();
            });
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

}

export default {
    template: require('./override.finance.bri.popup.html'),
    bindings: {
        config: '<',
        editOption: '<',
        isModify: '<',
        restoreOptionDefaultPricing: '&',
        saveOptionEdit: '&',
        saveBulkPricing: '&',
        setEditOption: '&',
        updateCart: '&',
        updateEditOptionPrice: '&'
    },
    controllerAs: 'OverrideFinanceBriPopupController',
    controller: OverrideFinanceBriPopupController
};
