import {
    AngularFormControllerErrorAdapter,
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import pathOr from 'ramda/src/pathOr';
import {
    ApplyCreditErrorSelector,
    IsApplyingCreditSelector
} from '../../../reducers/selectors/customer.convergent.biller.selectors';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    applyOrderCredit,
    clearApplyCreditErrors
} from '../../../reducers/actions/order.actions';
import {
    createPaymentInstrument,
    redeemGiftCard,
    retrieveWallet
} from '../../../reducers/actions/customer.ewallet.actions';
import {
    EwalletErrorSelector,
    PaymentIsFetchingDataSelector
} from '../../../reducers/selectors/customer.ewallet.selectors';
import {
    CanAddPaymentInstruments,
    CanChangePaymentInstrument,
    CurrentPaymentInstrumentsTypeSelector,
    MutablePaymentInstrumentsTypeFilterOptionsSelector,
} from '../../../reducers/selectors/apply.credit.modal.selectors';
import {
    NEW_PAYMENT_INSTRUMENT,
    ORIGINAL_PAYMENT
} from './apply.credit.modal.constants';
import {
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT,
    ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT
} from '../../../security.attributes';
import {
    SUPPORTED_PAYMENT_INSTRUMENT_TYPES
} from '../ewallet/ewallet.constants';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../customercare.constants';
import {retrieveCustomerHouseholds} from '../../../reducers/actions/customer.household.actions';
import {FLOAT} from '../../shared/constants/regex.constants';
import {isPaymentSelectable} from '../../customer/ewallet/ewallet.helpers';
import CustomerCareLocaleKeys from '../../../locales/keys';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../reducers/actions/address.component.actions';

export class ApplyCreditModalController {
    constructor($filter, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            $timeout,
            uiNotificationService
        });
        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;
        this.creditLocaleKeys = CustomerCareLocaleKeys.ORDER_DETAILS.CREDIT;
        this.currencyRegex = FLOAT;
        this.allowPaymentSelection = true;
        this.isEditingPaymentInstrument = false;
        this.availableCards = [];

        this.NEW_PAYMENT_INSTRUMENT = NEW_PAYMENT_INSTRUMENT;
        this.ORIGINAL_PAYMENT = ORIGINAL_PAYMENT;
        this.MAX_DESCR_LENGTH = 100;
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                areCreditReasonsLoaded: MetadataSelectors.codes.CreditReasonsLoadedSelector(store),
                applyCreditError: ApplyCreditErrorSelector(store),
                canAddPaymentInstruments: CanAddPaymentInstruments(store),
                canChangePaymentInstrument: CanChangePaymentInstrument(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentPaymentInstrumentTypes: CurrentPaymentInstrumentsTypeSelector(store),
                eWalletError: EwalletErrorSelector(store),
                isApplyingCredit: IsApplyingCreditSelector(store),
                isFetchingWallet: PaymentIsFetchingDataSelector(store),
                language: SessionSelectors.LanguageSelector(store),
                reasonsOptions: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.CreditReasons, store),
                paymentInstrumentConfiguration: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentConfigurationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.PaymentInstrumentConfiguration, store),
                paymentInstrumentsTypeFilterOptions: MutablePaymentInstrumentsTypeFilterOptionsSelector(store),
                paymentInstrumentTypes: CurrentPaymentInstrumentsTypeSelector(store),
                userSecurityAttributes: SessionSelectors.UserSecurityAttributesSelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store),
            };
        };
        const controllerActions = {
            fetchCreditReasons: MetadataActions.codes.fetchCreditReasons,
            applyOrderCredit,
            clearApplyCreditErrors,
            createPaymentInstrument,
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            redeemGiftCard,
            retrieveCustomerHouseholds,
            retrieveWallet,
            setForceShowEditFormAddress,
            validateAddress
        };
        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.areCreditReasonsLoaded) {
            this.actions.fetchCreditReasons();
        }
        if (!this.state.paymentInstrumentConfigurationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.PaymentInstrumentConfiguration);
        }
        if (this.canChangePaymentInstrument) {
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            }).then(() => {
                this.updateAvailablePaymentInstruments();
            });
        }

        this.selectedCard = null;

        this.model = {
            ReasonCode: null,
            Description: null,
            Amount: this.maxCreditAmount,
            AmountType: 'max',
            SendNotification: false
        };

        this.paymentInstrumentRequestObject = {};
        this.updateAvailablePaymentInstruments();
    }
    $onDestroy() {
        this.disconnect();
    }
    $onChanges({paymentInstruments}) {
        if (paymentInstruments) {
            this.updateAvailablePaymentInstruments();
        }
    }
    get isAmountValid() {
        const value = parseFloat(this.model.Amount);
        return value > 0 && value <= this.maxCreditAmount;
    }
    updateAvailablePaymentInstruments() {
        if (this.state && this.state.canChangePaymentInstrument) {
            this.availableCards = Array.from(this.state.paymentInstrumentsTypeFilterOptions);
        } else {
            this.availableCards = [];
        }

        this.availableCards.unshift({
            value: this.ORIGINAL_PAYMENT,
            text: i18n.translate(this.creditLocaleKeys.ORIGINAL_PAYMENT_METHOD)
        });
        this.selectedCard = this.ORIGINAL_PAYMENT;
        this.allowPaymentSelection = true;
        this.updateAllowablePaymentSelection();
    }
    get canChangePaymentInstrument() {
        return PermissionService.hasAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT);
    }
    get maxCreditAmount() {
        return Math.min(this.maxAmount, PermissionService.getUpperLimitAccess(this.state.userSecurityAttributes, ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT.id));
    }
    get isOriginalPaymentOnly() {
        return this.selectedCard === this.ORIGINAL_PAYMENT
            && this.availableCards.length === 1
            && this.availableCards[0].value === this.ORIGINAL_PAYMENT;
    }

    handleSetAmountType() {
        if (this.model.AmountType === 'max') {
            this.model.CustomAmount = undefined;
            this.model.Amount = this.maxCreditAmount;
        } else if (this.model.AmountType === 'custom') {
            this.model.Amount = this.model.CustomAmount || 0;
            this.formController.applyCreditAmount.$setUntouched();
        }
    }
    updateCustomAmount() {
        if (this.model.AmountType === 'custom') {
            this.model.Amount = this.model.CustomAmount;
        }
    }
    updateAllowablePaymentSelection() {
        this.paymentInstruments.forEach((paymentInstrument) => {
            const shouldAllow = isPaymentSelectable(paymentInstrument);
            if (!shouldAllow) {
                this.allowPaymentSelection = false;
            }
        });
    }
    validateForm() {
        this.$timeout(() => {
            this.formErrors = AngularFormControllerErrorAdapter(this.formController, {
                applyCreditReason: this.creditLocaleKeys.REASON,
                applyCreditDescription: this.creditLocaleKeys.DESCRIPTION,
                applyCreditAmount: this.creditLocaleKeys.AMOUNT
            }, {
                maxlength: {
                    applyCreditDescription: this.MAX_DESCR_LENGTH
                }
            });
            if (!this.isAmountValid) {
                this.formErrors.push(i18n.translate(CustomerCareLocaleKeys.WRITE_OFF.AMOUNT_VALUE_INVALID, {
                    minAmount: !this.brandableCurrencyName ? this.$filter('invCurrency')(0, this.currencyCode) :
                        this.$filter('invBrandableCurrency')(0, this.brandableCurrencyName),
                    maxAmount: !this.brandableCurrencyName ? this.$filter('invCurrency')(this.maxCreditAmount, this.currencyCode) :
                        this.$filter('invBrandableCurrency')(this.maxCreditAmount, this.brandableCurrencyName)
                }));
            }
        });
    }
    applyOrderCredit(customerId, details) {
        this.actions.applyOrderCredit(customerId, details)
            .then(() => {
                this.onSubmit()();
            });
    }
    submit() {
        this.formErrors = [];
        if (this.formController.$valid && this.isAmountValid) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                        const {Description, ReasonCode, Amount, SendNotification} = this.model;
                        const creditDetails = Object.assign({
                            Description,
                            ReasonCode,
                            Amount,
                            SendNotification
                        }, this.additionalApiData);

                        if (this.allowPaymentSelection) {
                            if (this.selectedCard === this.NEW_PAYMENT_INSTRUMENT) {
                                if (this.paymentInstrumentRequestObject.Type === SUPPORTED_PAYMENT_INSTRUMENT_TYPES.INTERNAL_GIFT_CARD) {
                                    this.actions.redeemGiftCard(this.paymentInstrumentRequestObject.Pin, this.paymentInstrumentRequestObject.CustomerId).then((redeemedGiftCard) => {
                                        this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.E_WALLET.REDEEM_GIFT_CARD_SUCCESS), null, {
                                            timeOut: NOTIFICATION_TIME_LENGTH
                                        });
                                        creditDetails.PaymentInstrument = {
                                            Id: redeemedGiftCard.Id
                                        };
                                        this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                                    });
                                } else {
                                    this.actions.createPaymentInstrument(this.paymentInstrumentRequestObject).then((createdPaymentInstrument) => {
                                        this.uiNotificationService.success(i18n.translate(CustomerCareLocaleKeys.MAKE_PAYMENT.CREATE_INSTRUMENT_SUCCESS), null, {
                                            timeOut: NOTIFICATION_TIME_LENGTH
                                        });
                                        creditDetails.PaymentInstrument = {
                                            Id: createdPaymentInstrument.PaymentInstrument.Id
                                        };
                                        this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                                    });
                                }
                            } else {
                                creditDetails.PaymentInstrument = {
                                    Id: this.selectedCard
                                };
                            }
                        }

                        if (this.selectedCard === this.ORIGINAL_PAYMENT) {
                            delete creditDetails.PaymentInstrument;
                        }

                        if (creditDetails.PaymentInstrument && creditDetails.PaymentInstrument.Id ||
                        this.selectedCard === this.ORIGINAL_PAYMENT) {
                            this.applyOrderCredit(this.state.currentCustomerId, creditDetails);
                        }
                    } else {
                        this.actions.setForceShowEditFormAddress(false);
                        this.uiNotificationService.transientError(
                            i18n.translate(this.CustomerCareLocaleKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                        );
                    }
                })
                .catch((err) => {
                    this.uiNotificationService.transientError(err.translatedMessage);
                });
        } else {
            this.validateForm();
        }
    }
    close() {
        this.onClose()();
    }

    //check if this works
    getAddressKey() {
        return pathOr(undefined, ['formController', 'paymentBillingAddressForm.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        return pathOr(undefined, ['formController', 'paymentBillingAddressForm.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        //TO-DO verify the appropriate addressType for below
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }
}

export default {
    template: require('./apply.credit.modal.html'),
    controller: ApplyCreditModalController,
    controllerAs: 'ApplyCreditModal',
    bindings: {
        additionalApiData: '<',
        brandableCurrencyName: '<',
        currencyCode: '@',
        maxAmount: '<',
        paymentInstruments: '<',
        onClose: '&',
        onSubmit: '&',
        title: '@'
    }
};
