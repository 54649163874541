import {CustomerCareHouseholdWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
import {HOUSEHOLD_STATE_NAME} from './household.constants';

export function routing($stateProvider) {
    $stateProvider.state(HOUSEHOLD_STATE_NAME, {
        url: '/household',
        data: {
            windowTitle: CustomerCareHouseholdWindowTitleSelector,
        },
        template: '<ui-view class="u-sizeFull"></ui-view>',
        redirectTo: `${HOUSEHOLD_STATE_NAME}.list`
    })
        .state(`${HOUSEHOLD_STATE_NAME}.list`, {
            url: '',
            template: '<customer-household></customer-household>'
        });
}
