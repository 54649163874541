import angular from 'angular';

import 'invision-core';

import PromiseToPaySchedulePopupComponent from './promise.to.pay.schedule.popup.component';

export default angular.module('inv.customer.treatmentDetails.promiseToPaySchedulePopup', [
    'invision.core.components'
]).component('promiseToPaySchedulePopup', PromiseToPaySchedulePopupComponent)
    .name;
