import CustomerCareKeys from '../../../../../locales/keys';

class CustomerAccountInfoController {

    constructor() {
        Object.assign(this, {
            CustomerCareKeys
        });
    }

}

export default {
    template: require('./customer.account.info.html'),
    controller: CustomerAccountInfoController,
    bindings: {
        isDbss: '<',
        viewModel: '<'
    }
};
