import {
    i18n
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../locales/keys';
import {clearRemarkBeingEdited,
    createCustomerRemark,
    setRemarkBeingEdited,
    updateCustomerRemark} from '../../../../../reducers/actions/customer.remarks.actions';
import {IsCreatingRemarkSelector,
    IsUpdatingRemarkSelector,
    RemarksLastAttemptErrorSelector,
    RemarkToEditSelector} from '../../../../../reducers/selectors/customer.remarks.selectors';
import {
    NOTIFICATION_TIME_LENGTH
} from '../../../../../customercare.constants';
import moment from 'moment';
const MAX_BODY_LENGTH = 8000;
const REMARK_PRIORITY = {
    LOW: 3,
    MEDIUM: 2,
    HIGH: 1
};
class CreateRemarkPopupCtrl {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            uiNotificationService,
            MAX_BODY_LENGTH,
            popupApi: null,
            REMARK_PRIORITY
        });
    }
    $onInit() {
        this.resetForm();
        this.showForm = false;
        this.minExpirationDate = new Date();
        this.remarkCreationCallback = this.onRemarkCreation() || (() => {});

        const mapStateToTarget = (store) => {
            return {
                remarkCreationLastAttemptError: RemarksLastAttemptErrorSelector(store),
                isCreatingRemark: IsCreatingRemarkSelector(store),
                isUpdatingRemark: IsUpdatingRemarkSelector(store),
                remark: RemarkToEditSelector(store)
            };
        };
        const controllerActions = {
            createCustomerRemark,
            updateCustomerRemark,
            setRemarkBeingEdited,
            clearRemarkBeingEdited
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.CustomerCareLocaleKeys = CustomerCareLocaleKeys;

        // hook the popupApi
        const {onRegisterApi} = this.config;
        this.config.onRegisterApi = ({api: api}) => {
            this.popupApi = Object.assign({}, api, {
                open: () => {
                    this.resetForm();
                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    this.$timeout(() => {
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: this.popupApi
            });
        };
    }
    $onDestroy() {
        this.disconnectRedux();
        this.closePopup();
        this.actions.clearRemarkBeingEdited();
    }
    updateRemark() {
        this.actions.setRemarkBeingEdited(this.state.remark);
    }
    handleClickCreateRemarkButton() {
        if (this.createRemarkForm.$valid) {
            const request = Object.assign({}, {
                remark: this.state.remark,
                customerId: this.customerId
            });
            let promise;
            if (this.isEditingRemark) {
                promise = this.actions.updateCustomerRemark(request)
                    .then(() => {
                        this.uiNotificationService.success(
                            i18n.translate(CustomerCareLocaleKeys.CREATE_REMARK.UPDATE_SUCCESS),
                            null, {
                                timeOut: NOTIFICATION_TIME_LENGTH
                            });
                    });
            } else {
                promise = this.actions.createCustomerRemark(request)
                    .then(() => {
                        this.uiNotificationService.success(
                            i18n.translate(CustomerCareLocaleKeys.CREATE_REMARK.SUCCESS),
                            null, {
                                timeOut: NOTIFICATION_TIME_LENGTH
                            });
                    });
            }
            promise.then(() => {
                this.remarkCreationCallback();
                this.closePopup();
            });
        }
    }
    closePopup() {
        this.popupApi.close();
        this.actions.clearRemarkBeingEdited();
        this.showForm = false;
    }
    onExpirationDateChanged(expirationDate = new Date()) {
        this.state.remark.Expiration = moment(expirationDate).endOf('day').toDate();
        this.updateRemark();
    }
    resetForm() {
        // called before $ngRedux.connect, must guard
        this.actions && !this.state.remark && this.actions.setRemarkBeingEdited({
            Priority: 3
        });
        if (this.createRemarkForm) {
            this.createRemarkForm.$setUntouched(true);
            this.createRemarkForm.$setPristine(true);
        }
    }

    get titleKey() {
        return this.isEditingRemark ? CustomerCareLocaleKeys.CREATE_REMARK.EDIT_REMARK : CustomerCareLocaleKeys.CREATE_REMARK.NEW_REMARK;
    }
    get isEditingRemark() {
        return this.state.remark && this.state.remark.Id;
    }
}

export default {
    template: require('./create.remark.popup.html'),
    bindings: {
        config: '<',
        onRemarkCreation: '&',
        customerId: '@'
    },
    controllerAs: 'CreateRemarkPopup',
    controller: CreateRemarkPopupCtrl
};
