import moment from 'moment';
import clone from 'ramda/src/clone';
import {SCHEDULED_ORDER_OPTIONS} from '../../../../../../customercare.constants';

const defaultExecutionOptions = {
    effective: `${SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY}`
};

const getExecutionDate = (executionOptionsModel) => {
    switch (executionOptionsModel.effective) {
        case `${SCHEDULED_ORDER_OPTIONS.EFFECTIVE_IMMEDIATELY}`:
            return null;
        case `${SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE}`:
            return moment(this.billCycleDate).toISOString();
        case `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`:
            return executionOptionsModel.specifiedDateFromPicker;
        default:
            return null;
    }
};

export const getInitialExecutionOptionsModel = ({executionOptionsSavedCopy, showImmediateOption, showBillCycleOption, showDatePickerOption,
    isBillingEffectiveDateShown, billingEffectiveDateOptions}) => {
    let executionOptionsModel = {};
    executionOptionsModel = executionOptionsSavedCopy && executionOptionsSavedCopy.effective ?
        clone(executionOptionsSavedCopy) : defaultExecutionOptions;
    if (!showImmediateOption && showBillCycleOption && !executionOptionsSavedCopy.effective) {
        executionOptionsModel.effective = `${SCHEDULED_ORDER_OPTIONS.ON_NEXT_BILL_CYCLE}`;
    }

    if (!showImmediateOption && !showBillCycleOption && showDatePickerOption) {
        executionOptionsModel.effective = `${SCHEDULED_ORDER_OPTIONS.ON_SPECIFIED_DATE}`;
    }

    executionOptionsModel.specifiedDate = executionOptionsSavedCopy && executionOptionsSavedCopy.specifiedDate || getExecutionDate(executionOptionsModel);
    executionOptionsModel.applyBalanceToInvoice = true;

    if (isBillingEffectiveDateShown && billingEffectiveDateOptions && billingEffectiveDateOptions.length) {
        const filteredBillingEffectiveDateOptions = {};

        const savedBillingEffectiveIntention = +executionOptionsModel.billingEffectiveIntention;

        billingEffectiveDateOptions.forEach((option) => {
            if (option.Valid) {
                const isDisabled = option.OrderExecutionDateIntention !== +executionOptionsModel.effective;
                const isShown = !isDisabled || !filteredBillingEffectiveDateOptions[option.BillingEffectiveDateIntention];
                const isSelected = !isDisabled && savedBillingEffectiveIntention === option.BillingEffectiveDateIntention;

                if (isShown) {
                    filteredBillingEffectiveDateOptions[option.BillingEffectiveDateIntention] = Object.assign({}, option, {
                        isDisabled,
                        isSelected
                    });
                }

                if (option.Default) {
                    executionOptionsModel.billingEffectiveIntention = `${option.BillingEffectiveDateIntention}`;
                }
            }
        });

        if (filteredBillingEffectiveDateOptions[savedBillingEffectiveIntention] && !filteredBillingEffectiveDateOptions[savedBillingEffectiveIntention].isDisabled) {
            executionOptionsModel.billingEffectiveIntention = `${savedBillingEffectiveIntention}`;
        }
    }

    return executionOptionsModel;
};