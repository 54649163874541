import {DEFAULT_TAX_SPLIT} from '../../../shared/constants/customer.constants';
import {
    BILLING_EFFECTIVE_DATE_INTENTION,
    ORDER_EXECUTION_DATE_INTENTION
} from '../../../../reducers/constants/wizard.constants';

function getServiceAttributes(attributes, pricingPlanId, serviceAttributes = []) {

    // Get the filtered service attributes, which belongs to the current pricingPlanId
    const filteredAttributes = pricingPlanId ? Object.entries(attributes).filter(([ServiceAttributeId]) => {
        return serviceAttributes.find((attribute) => {
            return +ServiceAttributeId === attribute.ServiceAttributeId && pricingPlanId === attribute.PricingPlanId;
        });
    }) : Object.entries(attributes);

    return filteredAttributes.map(([ServiceAttributeId, Value]) => {
        return {
            ServiceAttributeId,
            Value
        };
    });
}

export function getFormattedGroupOrderItems(state) {
    const serviceFeatures = [];
    if (state.selectedServiceFeatures && state.selectedServiceFeatures.length) {
        state.selectedServiceFeatures.forEach((feature) => {
            serviceFeatures.push({
                PricingPlanId: feature.pricingPlanId,
                ProductId: feature.productId,
                ShoppingCartItemBillerRuleDetails: feature.billerRuleDetails,
                ServiceAttributes: []
            });
        });
    }
    const groupOrderItems = (state.shoppingCart.Items || []).reduce((acc,
        {OfferingId, ProductId, PricingPlanId, ShoppingCartItemBillerRuleDetails, Details: {PricingPlan: {IsServiceIndependent}}}
    ) => {
        if (IsServiceIndependent) {
            acc.serviceIndependentItems.push({
                OfferingId,
                ProductId,
                PricingPlanId,
                ShoppingCartItemBillerRuleDetails
            });
        } else {
            if (!acc.serviceTemplates[OfferingId]) {
                acc.serviceTemplates[OfferingId] = {
                    OfferingId,
                    TemplateShoppingCartItems: serviceFeatures,
                    OrderedOfferingAdditionalProperties: state.offerAdditionalProperties,
                    Services: []
                };
            }

            // Shopping cart items with group template values for the current PricingPlan
            acc.serviceTemplates[OfferingId].TemplateShoppingCartItems.push({
                ProductId,
                PricingPlanId,
                ServiceAttributes: getServiceAttributes(state.groupTemplate.attributes, PricingPlanId, state.serviceAttributeFields),
                ShoppingCartItemBillerRuleDetails
            });

            // All services, with the overridden attributes values
            if (!acc.serviceTemplates[OfferingId].Services.length) {
                acc.serviceTemplates[OfferingId].Services = state.serviceTemplates.map((serviceTemplate) => {
                    return {
                        ...(serviceTemplate.taxLocation ? {
                            GroupOrderServiceTaxCustomization: {
                                OriginatingNpaNxx: serviceTemplate.taxLocation.OriginatingTaxServiceLocation.NpaNxx,
                                OriginatingPercentage: serviceTemplate.taxLocation.OriginatingPercentage || DEFAULT_TAX_SPLIT,
                                TerminatingNpaNxx: serviceTemplate.taxLocation.TerminatingTaxServiceLocation.NpaNxx
                            }
                        } : {}),
                        ServiceAttributes: getServiceAttributes(serviceTemplate.attributes)
                    };
                });
            }
        }

        return acc;
    }, {
        serviceIndependentItems: [],
        serviceTemplates: {}
    });

    return {
        AdditionalProperties: state.orderAdditionalProperties,
        OrderedOfferingAdditionalProperties: !!groupOrderItems.serviceIndependentItems.length ? state.offerAdditionalProperties : undefined,
        BillingEffectiveDate: state.executionOptions.billingEffectiveIntention === BILLING_EFFECTIVE_DATE_INTENTION.OnSpecificDate && state.executionOptions.billingEffectiveDate || undefined,
        BillingEffectiveDateIntention: state.executionOptions.billingEffectiveIntention || undefined,
        ExecutionDate: state.executionOptions.effective === ORDER_EXECUTION_DATE_INTENTION.OnSpecificDate && state.executionOptions.specifiedDate || undefined,
        ExecutionDateIntention: state.executionOptions.effective || undefined,
        ServiceIndependentItems: groupOrderItems.serviceIndependentItems,
        ServiceTemplates: Object.values(groupOrderItems.serviceTemplates)
    };
}
