import {CustomerCareLedgerWindowTitleSelector} from '../../reducers/selectors/customer.selectors';
import {
    PAYMENT_ACCESS,
    PAYMENT_ACTIVITY_VIEW_ACCESS
} from './../../security.attributes';

export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.billingpayments', {
        redirectTo: 'index.customercare.customer.billingpayments.list',
        url: '/billingPayments',
        template: '<ui-view class="u-flexKeepHeight"></ui-view>',
        data: {
            windowTitle: CustomerCareLedgerWindowTitleSelector,
        },
        securityAttributes: [PAYMENT_ACCESS, PAYMENT_ACTIVITY_VIEW_ACCESS]
    });
}
