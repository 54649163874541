import CustomerCareKeys from '../../../../../../../locales/keys';

class ApplyChangesFieldController {
    constructor() {
        this.localeKeys = CustomerCareKeys;
    }

    onValueChanged(value) {
        this.onChange()(value);
    }
}

export default {
    template: require('./apply.changes.field.html'),
    controller: ApplyChangesFieldController,
    bindings: {
        onChange: '&',
        value: '<'
    }
};