import path from 'ramda/src/path';
import {RIGHT_ALIGNED_HEADER_TEMPLATE} from 'invision-ui/lib/components/collections/datatable/cellTemplates/cellTemplates';
import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../../../locales/keys';
import {ORDER_STATUS} from '../../../../customer/orderHistory/orderDetails/order.details.constants';
import {SERVICE_SUSPENSION_TYPES} from '../../../constants/service.constants';
import {
    clearServiceSuspendResumeModal,
    retrieveServiceSuspensionQuote,
    setSelectedServiceIdentifierValues
} from '../../../../../reducers/actions/services.suspend.resume.modal.actions';
import {
    IsBillCycleOptionEnabledSelector,
    IsloadingServiceSuspensionQuote,
    ServicesSuspensionQuoteFormattedSelector
} from '../../../../../reducers/selectors/services.suspend.resume.modal.selectors';
import {
    HasFinanceInstallmentSuspendResumeAccessSelector,
    HasSubscriberBillingAndNetworkSuspensionAccessSelector
} from '../../../../../reducers/selectors/permissions.selectors';
import {DEVICE_FINANCE_STATUS} from 'invision-core/src/constants/status.constants';
import {BillCycleDetailsSelector} from '../../../../../reducers/selectors/customer.billcycle.selectors';
import {retrieveBillCycleDetails} from '../../../../../reducers/actions/customer.billcycle.actions';
import {CurrentCustomerBillCycleSelector} from '../../../../../reducers/selectors/customer.selectors';

class ServicesSuspendResumeController {
    constructor($filter, $ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $filter,
            $ngRedux,
            $timeout,
            CustomerCareKeys,
            DEVICE_FINANCE_STATUS,
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            EMPTY_STRING: '',
            formErrorMessages: [],
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            onRowSelectionChanged: this.onRowSelectionChanged.bind(this),
            onSubmitForm: this.onSubmitForm.bind(this),
            onSuspensionTypeChange: this.onSuspensionTypeChange.bind(this),
            MAX_DESCR_LENGTH: 200,
            model: {
                comment: null,
                serviceIdentifiers: [],
                selectedReasonCode: null,
                deviceFinancesDictionary: {},
                specifiedDate: null,
                suspensionType: null,
            },
            onSubmissionPage: false,
            selectedItems: [],
            SERVICE_SUSPENSION_TYPES,
            showDeviceFinancingNotApplicableLabel: this.showDeviceFinancingNotApplicableLabel.bind(this),
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                billCycleDetails: BillCycleDetailsSelector(store),
                currentCustomerBillCycle: CurrentCustomerBillCycleSelector(store),
                hasBillingSuspensionAccess: HasSubscriberBillingAndNetworkSuspensionAccessSelector(store),
                hasFinanceSuspendResumeAccess: HasFinanceInstallmentSuspendResumeAccessSelector(store),
                isBillCycleOptionEnabled: IsBillCycleOptionEnabledSelector(store),
                isLoadingQuote: IsloadingServiceSuspensionQuote(store),
                servicesSuspensionQuotes: ServicesSuspensionQuoteFormattedSelector(store)
            };
        };

        const controllerActions = {
            clearServiceSuspendResumeModal,
            retrieveBillCycleDetails,
            retrieveServiceSuspensionQuote,
            setSelectedServiceIdentifierValues
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.suspensionTypes = [{
            label: i18n.translate(this.CustomerCareKeys.SERVICE_SUSPENSION_TYPE.NETWORK_ONLY),
            value: SERVICE_SUSPENSION_TYPES.NETWORK
        }, {
            label: i18n.translate(this.CustomerCareKeys.SERVICE_SUSPENSION_TYPE.NETWORK_AND_BILLING),
            value: SERVICE_SUSPENSION_TYPES.NETWORK_AND_BILLING
        }];
        this.model.suspensionType = this.suspensionTypes[0];
        if (this.services.length === 1) {
            this.model.serviceIdentifiers.push(this.services[0].ServiceIdentifier.Value);
            this.actions.setSelectedServiceIdentifierValues(this.model.serviceIdentifiers);
        }
        this.actions.retrieveBillCycleDetails(this.state.currentCustomerBillCycle);
        this.gridSetup();
        this.setSuspensionFees();
    }

    $onChanges(changesObj) {
        if (changesObj.availableProducts && changesObj.availableProducts.currentValue && changesObj.services && changesObj.services.currentValue && changesObj.services.currentValue.length > 1) {
            this.services.forEach((service) => {
                this.availableProducts.find((product) => {
                    return product.Items.find((item) => {
                        return item.LockerItemId === service.ServiceIdentifier.SubscriberProductId
                        && (product.OrderStatus === ORDER_STATUS.OPEN ||
                        product.OrderStatus === ORDER_STATUS.PENDING);
                    });
                }) ? service.isServiceSuspendable = false : service.isServiceSuspendable = true;
            });
        }
    }

    gridSetup() {
        if (this.isActive) {
            this.columnDefs = [{
                field: 'ServiceIdentifier.FormattedValue',
                displayName: i18n.translate(CustomerCareKeys.SERVICE),
                maxWidth: 200,
                minWidth: 150
            }, {
                field: 'ServiceName',
                displayName: i18n.translate(CustomerCareKeys.FRIENDLY_NAME),
                minWidth: 120
            }];
            this.columnDefs.push({
                field: 'oneTimeFee',
                cellTemplate: require('./cellTemplates/fee.cell.template.html'),
                displayName: i18n.translate(CustomerCareKeys.SERVICE_SUSPENSION_FEE.ONE_TIME),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE
            }, {
                field: 'recurringFee',
                cellTemplate: require('./cellTemplates/fee.cell.template.html'),
                displayName: i18n.translate(CustomerCareKeys.SERVICE_SUSPENSION_FEE.RECURRING),
                headerCellTemplate: RIGHT_ALIGNED_HEADER_TEMPLATE
            });
            this.columnDefs.push({
                field: 'DeviceFinancing',
                cellTemplate: require('./cellTemplates/device.finance.cell.template.html'),
                displayName: i18n.translate(CustomerCareKeys.DEVICE_FINANCE_CHARGES),
                minWidth: 220
            });
        }
        if (!this.isActive) {
            this.columnDefs = [{
                headerCellTemplate: '<div></div>',
                field: 'ServiceIdentifier.FormattedValue',
                displayName: i18n.translate(CustomerCareKeys.SERVICE),
                maxWidth: 200,
                minWidth: 150
            }, {
                headerCellTemplate: '<div></div>',
                field: 'ServiceName',
                displayName: i18n.translate(CustomerCareKeys.FRIENDLY_NAME),
                minWidth: 120
            }];
            this.columnDefs.push({
                field: 'SuspendType',
                displayName: i18n.translate(CustomerCareKeys.SUSPENSION_TYPE),
                minWidth: 80
            });
        }

        this.gridData = {
            showHeader: this.isActive,
            enableSelectAll: this.isActive,
            appScopeProvider: this,
            columnDefs: this.columnDefs,
            data: this.services,
            enableSorting: false,
            minRowsToShow: this.services.length + 1,
            isRowSelectable: (row) => {
                if (!this.isActive) {
                    if (!this.state.hasBillingSuspensionAccess && row.entity.SuspendTypeCode === SERVICE_SUSPENSION_TYPES.NETWORK_AND_BILLING) {
                        return false;
                    }
                    if (!this.state.hasFinanceSuspendResumeAccess && row.entity.deviceFinance && row.entity.deviceFinance.status === DEVICE_FINANCE_STATUS.SUSPENDED) {
                        return false;
                    }
                }
                return row.entity.isServiceSuspendable;
            },
            onRegisterApi: (event) => {
                this.gridApi = event.grid.api;
                this.gridApi.selection.clearSelectedRows();
                this.gridApi.selection.on.rowSelectionChanged(null, this.onRowSelectionChanged);
                this.gridApi.selection.on.rowSelectionChangedBatch(null, this.onRowSelectionChanged);
            },
            rowTemplate: require('./cellTemplates/checkbox.row.template.html')
        };
    }
    onRowSelectionChanged() {
        this.selectedItems = this.gridApi.selection.getSelectedRows();

        this.actions.setSelectedServiceIdentifierValues(this.selectedItems.map((selectedItem) => {
            return selectedItem.ServiceIdentifier.Value;
        }));

        Object.keys(this.model.deviceFinancesDictionary).forEach((key) => {
            if (!this.selectedItems.find((item) => {
                return path(['deviceFinance', 'serviceIdentifier'], item) === key;
            })) {
                this.model.deviceFinancesDictionary[key] = false;
            }
        });
    }

    onExecutionOptionChange(model) {
        this.model.specifiedDate = model.specifiedDate;
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
    }

    getServiceIdentfiers() {
        if (!(this.services.length === 1)) {
            this.model.serviceIdentifiers = this.selectedItems.map((selectedItem) => {
                return selectedItem.ServiceIdentifier.Value;
            });
        }
    }

    customValidations() {
        this.formErrorMessages = (this.model.serviceIdentifiers.length) ? [] : [i18n.translate(CustomerCareKeys.SERVICE_REQUIRED)];
    }

    setSubmissionPageFlag() {
        this.confirmationGridData = {
            showHeader: this.isActive,
            appScopeProvider: this,
            columnDefs: this.columnDefs,
            data: this.selectedItems
        };
        this.servicesSuspendResumeForm.$submitted = true;
        this.getServiceIdentfiers();
        this.customValidations();
        this.model.selectedReason = this.reasonCodes.filter((reasonCode) => {
            return reasonCode.Value === this.model.selectedReasonCode;
        });
        if (this.servicesSuspendResumeForm.$valid && !this.formErrorMessages.length) {
            this.onSubmissionPage = true;
        }
    }

    onSubmitForm() {
        const serviceObj = {};
        serviceObj.ServiceIdentifiers = this.model.serviceIdentifiers
            //add device finances to suspend
            .concat(Object.keys(this.model.deviceFinancesDictionary).reduce((deviceFinances, deviceFinanceServiceIdentifierValue) => {
                if (this.model.deviceFinancesDictionary[deviceFinanceServiceIdentifierValue]) {
                    deviceFinances.push(deviceFinanceServiceIdentifierValue);
                }
                return deviceFinances;
            }, []))
            //add device finances to resume;
            .concat(this.model.serviceIdentifiers.reduce((deviceFinancesToResume, serviceIdentifierValue) => {
                const service = this.services.find((item) => {
                    return item.ServiceIdentifier.Value === serviceIdentifierValue;
                });
                const deviceFinance = path(['deviceFinance'], service);

                if (deviceFinance && deviceFinance.status === DEVICE_FINANCE_STATUS.SUSPENDED) {
                    deviceFinancesToResume.push(deviceFinance.serviceIdentifier);
                }
                return deviceFinancesToResume;
            }, []));
        serviceObj.Comment = this.model.comment;
        if (this.isActive) {
            serviceObj.SuspensionType = this.model.suspensionType.value;
            serviceObj.SuspendReasonCodeValue = {
                Value: this.model.selectedReasonCode
            };
            serviceObj.SuspensionStartDate = this.model.specifiedDate;
        } else {
            serviceObj.RestoreReasonCodeValue = {
                Value: this.model.selectedReasonCode
            };
            serviceObj.RestorationStartDate = this.model.specifiedDate;
        }
        this.onSubmit()(serviceObj);
    }

    get title() {

        if (this.onSubmissionPage && this.services.length > 1) {
            return i18n.translate(this.isActive ? CustomerCareKeys.SERVICE_SUSPEND_SERVICE_COUNT : CustomerCareKeys.SERVICE_RESUME_SERVICE_COUNT, {
                selectedServices: this.selectedItems.length,
                totalServices: this.services.length
            });
        } else {
            return i18n.translate(this.isActive ? CustomerCareKeys.SUSPEND_SERVICE : CustomerCareKeys.RESUME_SERVICE);
        }
    }

    get confirmationHeading() {
        return `${this.services[0].ServiceIdentifier.FormattedValue} ${this.services[0].ServiceName || this.services[0].ServiceIdentifier.FriendlyName || this.EMPTY_STRING}`;
    }

    onSuspensionTypeChange() {
        this.model.deviceFinancesDictionary = {};
        this.actions.clearServiceSuspendResumeModal();
        this.setSuspensionFees();
        if (this.model.suspensionType.value === SERVICE_SUSPENSION_TYPES.NETWORK_AND_BILLING) {
            this.onReasonCodeChange();
        }
    }

    onReasonCodeChange() {
        if (this.isActive) {
            if (!this.model.selectedReasonCode) {
                this.actions.clearServiceSuspendResumeModal();
                this.setSuspensionFees();
            } else if (this.model.suspensionType.value === SERVICE_SUSPENSION_TYPES.NETWORK_AND_BILLING) {
                this.actions.retrieveServiceSuspensionQuote(this.model.selectedReasonCode, this.services.map((service) => {
                    return service.ServiceIdentifier.Value;
                })).then(() => {
                    this.setSuspensionFees();
                }).catch(this.onApiError);
            }
        }
    }

    setSuspensionFees() {
        this.services.forEach((service) => {
            const suspensionFees = this.state.servicesSuspensionQuotes[service.ServiceIdentifier.Value];
            service.oneTimeFee = suspensionFees
                && suspensionFees.oneTimeCharge
                && suspensionFees.oneTimeCharge.Amount
                && this.$filter('invCurrency')(suspensionFees.oneTimeCharge.Amount, suspensionFees.oneTimeCharge.CurrencyCode);
            service.recurringFee = suspensionFees
                && suspensionFees.recurringCharge
                && suspensionFees.recurringCharge.Amount
                && this.$filter('invCurrency')(suspensionFees.recurringCharge.Amount, suspensionFees.recurringCharge.CurrencyCode);
        });
    }

    onApiError(error) {
        this.uiNotificationService.transientError(error.translatedMessage);
    }

    showDeviceFinancingNotApplicableLabel(service) {
        if (!service.deviceFinance) {
            return true;
        }
        if (!this.isActive && service.deviceFinance && service.deviceFinance.status !== DEVICE_FINANCE_STATUS.SUSPENDED) {
            return true;
        }
        return false;
    }

    $onDestroy() {
        this.actions.clearServiceSuspendResumeModal();
        this.disconnectRedux();
    }
}

export default {
    controller: ServicesSuspendResumeController,
    controllerAs: 'ServicesSuspendResume',
    bindings: {
        availableProducts: '<',
        hideFdoOptions: '<?',
        isActive: '<',
        isSuspendingOrResuming: '<',
        onClose: '&',
        onSubmit: '&',
        reasonCodes: '<',
        services: '<'
    },
    template: require('./services.suspend.resume.modal.html')
};
