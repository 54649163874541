import angular from 'angular';
import 'invision-core';

import {routing as RoutingConfig} from './auto.pay.config';

export default angular.module('invision.customercare.customer.autoPay', [
    'invision.core.components'
])
    .config(RoutingConfig)
    .name;

