import {i18n} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {CurrentCustomerIdSelector} from '../../../reducers/selectors/customer.selectors';
import {
    CustomerAccountStatusSelector,
    IsFetchingAccountStatusDataSelector
} from '../../../reducers/selectors/customer.account.status.selectors';
import {retrieveCustomerAccountStatus} from '../../../reducers/actions/customer.account.status.actions';

export class DiscountsModalController {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux
        });
        this.CustomerCareLocaleKeys = LocaleKeys;
    }
    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                currentCustomerId: CurrentCustomerIdSelector(store),
                accountStatus: CustomerAccountStatusSelector(store),
                isFetchingData: IsFetchingAccountStatusDataSelector(store)
            };
        };
        const controllerActions = {
            retrieveCustomerAccountStatus
        };
        this.disconnect = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.columnDefs = [
            {
                field: 'Discount.Name',
                displayName: i18n.translate(LocaleKeys.NAME),
                width: 225
            },
            {
                field: 'Created',
                displayName: i18n.translate(LocaleKeys.ACTIVE_DISCRETIONARY_DISCOUNTS.ACTIVE_DATE),
                cellTemplate: require('./discounts.modal.cell.active.html'),
                width: 100
            },
            {
                field: 'ExpirationDate',
                displayName: i18n.translate(LocaleKeys.ACTIVE_DISCRETIONARY_DISCOUNTS.EXPIRATION_DATE),
                cellTemplate: require('./discounts.modal.cell.expiration.html'),
                width: 85
            },
            {
                field: 'ProductName',
                displayName: i18n.translate(LocaleKeys.PRODUCT),
                width: 198
            }
        ];
    }
    $onDestroy() {
        this.disconnect();
    }
    $onChanges({openToggle}) {
        if (openToggle.currentValue && this.state && !this.state.accountStatus) {
            this.actions.retrieveCustomerAccountStatus(this.state.currentCustomerId);
        }
    }
    close() {
        this.onClose();
    }
}

export default {
    template: require('./discounts.modal.html'),
    controller: DiscountsModalController,
    bindings: {
        onClose: '&',
        openToggle: '<'
    }
};
