const THRESHOLD = {
    LOW: 89.99,
    WARNING: 99.99,
    DANGER: 100
};

export const C3_HEX_VALUES = {
    RESERVED: '#C1C6CB',
    UNUSED: 'transparent',
    USED: '#25AC60',
    USED_COMPLETELY: '#F64F3D',
    USED_MAJORITY: '#EEC154'
};

export function getHexValueByUsage(used, unused, unlimited) {
    const value = Math.min(Math.max(used / (used + unused) * 100, 0), 100);

    if (value <= THRESHOLD.LOW || unlimited) {
        return C3_HEX_VALUES.USED;
    } else if (value > THRESHOLD.LOW && value < THRESHOLD.WARNING) {
        return C3_HEX_VALUES.USED_MAJORITY;
    } else if (value <= THRESHOLD.DANGER) {
        return C3_HEX_VALUES.USED_COMPLETELY;
    } else {
        return 'none';
    }
}

export function getStyleByUsage(value, unlimited) {
    if (value <= THRESHOLD.LOW || unlimited) {
        return 'low';
    } else if (value > THRESHOLD.LOW && value < THRESHOLD.WARNING) {
        return 'warning';
    } else if (value <= THRESHOLD.DANGER) {
        return 'danger';
    } else {
        return 'none';
    }
}

export default {
    C3_HEX_VALUES: C3_HEX_VALUES,
    getHexValueByUsage: getHexValueByUsage,
    getStyleByUsage: getStyleByUsage,
};


