import localeKeys from '../../../../../locales/keys';
import {find, propEq} from 'ramda';

class TransactionDetailsPosController {
    constructor() {
        this.localeKeys = localeKeys;
    }

    get paymentTypeTranslated() {
        const paymentType = find(propEq(this.paymentInstrument.PointOfSale.SubType.toString(), 'Value'), this.posPaymentTypes);

        return paymentType ? paymentType.Name : undefined;
    }
}

export default {
    template: require('./transaction.details.pos.html'),
    bindings: {
        details: '<',
        paymentInstrument: '<',
        posPaymentTypes: '<'
    },
    controller: TransactionDetailsPosController
};
