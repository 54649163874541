import localeKeys from '../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import clone from 'ramda/src/clone';
import {STORE_GRID_ACTIONS} from './store.inventory.search.constants';
import {IsFetchingSelector} from '../../../../reducers/selectors/offering.order.selectors';
class StoreInventorySearchController {
    constructor($scope, $ngRedux, $timeout, uiGridConstants) {

        // NOTE: Seek UX approval for custom modal dimensions
        const MODAL_CUSTOM_DIMENSIONS = {
            HEIGHT: '65vh',
            WIDTH: '960px'
        };

        /*
            Can just use normal index
            https://stackoverflow.com/questions/29637188/how-to-get-row-index-in-angular-ui-grid-3-0
        */

        Object.assign(this, {
            $ngRedux,
            CoreLocaleKeys,
            addLastColumn: this.addLastColumn.bind(this),
            cannotGetNextBatchOfStores: this.cannotGetNextBatchOfStores.bind(this),
            cannotGetPreviousBatchOfStores: this.cannotGetPreviousBatchOfStores.bind(this),
            getNextBatchOfStores: this.getNextBatchOfStores.bind(this),
            getPreviousBatchOfStores: this.getPreviousBatchOfStores.bind(this),
            changeColumnDefs: this.changeColumnDefs.bind(this),
            MODAL_CUSTOM_DIMENSIONS,
            selectStore: this.selectStore.bind(this),
            setAssociatedTableData: this.setAssociatedTableData.bind(this),
            onRegionChanged: this.onRegionChanged.bind(this),
            updateStoreGridColumns: this.updateStoreGridColumns.bind(this)
        });

        const onRegisterApi = (event) => {
            this.gridApi = event.grid.api;
        };

        const gridOptions = {
            //          selectionRowHeaderWidth: 20,
            columnDefs: [{
                cellClass: 'first-column c-inventoryDetails',
                cellTemplate: require('./customCellTemplates/availability.cell.template.html'),
                field: 'physicalInventoryItem',
                firstColumn: true,
                headerCellTemplate: require('./customCellTemplates/header.cell.template.html'),
                width: '25%'
            }],
            data: [],
            cannotGetNextBatchOfStores: this.cannotGetNextBatchOfStores,
            cannotGetPreviousBatchOfStores: this.cannotGetPreviousBatchOfStores,
            getPreviousBatchOfStores: this.getPreviousBatchOfStores,
            getNextBatchOfStores: this.getNextBatchOfStores,
            selectStore: this.selectStore,
            textFields: {
                selectStore: i18n.translate(localeKeys.STORE_INVENTORY_SEARCH_MODAL.SELECT_STORE),
                selected: i18n.translate(localeKeys.STORE_INVENTORY_SEARCH_MODAL.SELECTED)
            },
            onRegisterApi
        };
        this.$scope = $scope;
        this.currentSelectedIndex = 1;


        Object.assign(this, {
            localeKeys,
            gridOptions,
            storeGridActionConstants: STORE_GRID_ACTIONS,
            uiGridConstants
        });
    }

    $onChanges(changes) {
        if ((
            changes.inventoryItems &&
                changes.inventoryItems.previousValue &&
                changes.inventoryItems.currentValue
        ) || (
            changes.stores &&
               changes.stores.previousValue &&
               changes.stores.currentValue
        )
        ) {
            this.gridOptions.columnDefs = [{
                cellClass: 'first-column c-inventoryDetails',
                cellTemplate: require('./customCellTemplates/availability.cell.template.html'),
                field: 'physicalInventoryItem',
                firstColumn: true,
                headerCellTemplate: require('./customCellTemplates/header.cell.template.html'),
                width: '25%'
            }];
            this.gridOptions.data = [];

            this.setAssociatedTableData();
        }
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                isLoading: IsFetchingSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget)((state) => {
            this.state = state;
        });
    }

    changeColumnDefs(direction) {
        if (direction === this.storeGridActionConstants.NEXT_BATCH_OF_STORES && this.cannotGetNextBatchOfStores()) {
            return;
        }

        if (direction === this.storeGridActionConstants.PREVIOUS_BATCH_OF_STORES && this.cannotGetPreviousBatchOfStores()) {
            return;
        }

        this.gridApi.grid.columns.forEach((columnToHide, index) => {
            if (index === this.gridApi.grid.columns.length -1 || index === 0) {
                return;
            }

            columnToHide.hideColumn();
        });

        if (direction === this.storeGridActionConstants.NEXT_BATCH_OF_STORES) {
            this.currentSelectedIndex += 3;
        }

        if (direction === this.storeGridActionConstants.PREVIOUS_BATCH_OF_STORES) {
            this.currentSelectedIndex -= 3;
        }

        this.gridApi.grid.columns.forEach((columnToHide, index) => {
            if (this.currentSelectedIndex <= index && index <= this.currentSelectedIndex + 2) {
                columnToHide.showColumn();
            }
        });

        this.gridOptions.currentSelectedIndex = this.currentSelectedIndex;
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
    }

    onRegionChanged(region) {
        this.selectedRegion = region;
        this.selectedStore = null;

        this.onRegionFilterChange()(this.selectedRegion);
    }

    cannotGetNextBatchOfStores() {
        return (this.gridOptions.columnDefs.length - 1 <=  this.currentSelectedIndex + 3);
    }

    cannotGetPreviousBatchOfStores() {
        return this.currentSelectedIndex <= 1;
    }

    getPreviousBatchOfStores() {
        this.changeColumnDefs(this.storeGridActionConstants.PREVIOUS_BATCH_OF_STORES);
    }

    getNextBatchOfStores() {
        this.changeColumnDefs(this.storeGridActionConstants.NEXT_BATCH_OF_STORES);
    }

    selectStore(store) {
        this.setSelectedStore()(store.field);
    }

    setAssociatedTableData() {
        if (!this.inventoryItems || !this.stores) {
            return;
        }

        const storeHasAllItems = {};

        this.inventoryItems.forEach((invItem) => {
            const formattedInvItem = clone(invItem);
            const formattedInvItemAvailability = {};

            formattedInvItem.stores.forEach((store) => {
                formattedInvItemAvailability[store.storeId] = store.MeetsRequestedQuantity;

                if (storeHasAllItems[store.storeId] === undefined) {
                    storeHasAllItems[store.storeId] = store.MeetsRequestedQuantity;
                } else {
                    storeHasAllItems[store.storeId] = storeHasAllItems[store.storeId] && store.MeetsRequestedQuantity ? true : false;
                }
            });

            this.gridOptions.data.push(Object.assign(formattedInvItem, {
                targetAvail: formattedInvItemAvailability
            }));
        });

        this.stores.forEach((store, index) => {
            const cellClasses = ['c-inventoryStoreAvailability'];
            const formattedStore = clone(store);
            const selectedStore = this.selectedStore;

            const isStoreSelected = selectedStore && selectedStore.Value === store.storeId ? true : false;

            if (isStoreSelected) {
                cellClasses.push('is-store-selected');
            }

            this.gridOptions.columnDefs.push(Object.assign(formattedStore, {
                cellClass: cellClasses.join(' '),
                cellTemplate: require('./customCellTemplates/availability.cell.template.html'),
                field: store.storeId,
                hasAllItems: storeHasAllItems[store.storeId],
                headerCellTemplate: require('./customCellTemplates/header.cell.template.html'),
                isStoreSelected: isStoreSelected,
                visible: index < 3 ? true : false,
                width: '20%'
            }));

        });

        this.addLastColumn();
        this.updateStoreGridColumns();
    }

    addLastColumn() {
        this.gridOptions.columnDefs.push({
            cellClass: 'last-column',
            cellTemplate: require('./customCellTemplates/availability.cell.template.html'),
            field: 'pseudoColumn',
            headerCellTemplate: require('./customCellTemplates/header.cell.template.html'),
            lastColumn: true,
            width: '*'
        });
    }

    updateStoreGridColumns() {
        if (!this.isInitialized && this.gridApi) {
            this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.COLUMN);
        } else if (this.gridApi) {
            this.isInitialized = true;
        }

    }
    $onDestroy() {
        this.isInitialized = false;
    }
}

export default {
    bindings: {
        hasError: '<',
        onClose: '&',
        onRegionFilterChange: '&',
        onRetry: '&',
        setSelectedStore: '&',
        inventoryItems: '<',
        isLoading: '<',
        regions: '<',
        selectedRegion: '<?',
        selectedStore: '<?',
        stores: '<'
    },
    template: require('./store.inventory.search.template.html'),
    controller: StoreInventorySearchController,
    controllerAs: 'StoreInventorySearch'
};
