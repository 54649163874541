import localeKeys from '../../../../../locales/keys';
import {transactionTypeNameIdentifier} from '../../transactions.helpers';

class TransactionTypeController {
    constructor() {
        Object.assign(this, {
            localeKeys,
            transactionTypeNameIdentifier
        });
    }

    handleClose() {
        this.onClose()();
    }

    get isCurrency() {
        return !!this.transaction.Currency;
    }

    get isBrandableCurrency() {
        return !!this.transaction.BrandableCurrency;
    }
}

export default {
    template: require('./transaction.type.popup.html'),
    bindings: {
        transaction: '<',
        paymentInstrument: '<',
        paypalPaymentTypes: '<',
        posPaymentTypes: '<',
        onClose: '&'
    },
    controller: TransactionTypeController,
    controllerAs: 'TransactionType'
};
