import {CurrentApplicationSelector} from 'invision-core/src/components/application/application.selectors';
import {setApplicationVersion} from 'invision-core/src/components/application/application.actions';
import * as Version from '../../version';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {FaultJustHandledSelector} from 'invision-core/src/components/fault/fault.selectors';
import {info} from 'invision-core/src/components/helpers/log.helper';
import {IsLoadingModuleSelector} from 'invision-core/src/components/session/session.selectors';
import {isInIframeOrWebView} from 'invision-core/src/utilities/message.helpers';

const mapStateToTarget = (store) => {
    return {
        currentApplication: CurrentApplicationSelector(store),
        faultJustHandled: FaultJustHandledSelector(store),
        isLoadingModule: IsLoadingModuleSelector(store)
    };
};

const controllerActions = {
    setApplicationVersion: setApplicationVersion
};

class MainController {
    constructor($ngRedux, SessionService) {
        this.$ngRedux = $ngRedux;
        this.CoreLocaleKeys = CoreLocaleKeys;
        this.SessionService = SessionService;
        this.disableSessionTimeout = isInIframeOrWebView();
    }

    $onInit() {
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.actions.setApplicationVersion(Version);
        this.SessionService.load();
        info('Invision application initialized successfully.');
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./main.html'),
    controller: MainController
};