import _ from 'lodash';
import {i18n} from 'invision-core';
import CustomerCareKeys from '../../../../locales/keys';
import {BulkChargeTypes} from '../../newConnectWizard/new.connect.wizard.constants';

export const briNameAppend = () => {
    const invertedChargeTypes = _.invert(BulkChargeTypes);

    return (briName, bulkChargeTypeCode) => {
        const translationKey = CustomerCareKeys.DECISIONS.BULK_CHARGE_TYPE[invertedChargeTypes[bulkChargeTypeCode]];
        if (briName && translationKey) {
            return `${briName} (${i18n.translate(translationKey)})`;
        }

        return briName;
    };
};
