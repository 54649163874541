import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareKeys from '../../../locales/keys';

export const getDiscountTooltipModel = (discount, currency, discountsMetadataMap, billerConfigurationId, futureDiscountIndex, pastDiscountIndex,
    futureDiscountsLength, pastDiscountsLength) => {
    return {
        billerConfigurationId: billerConfigurationId,
        Currency: currency,
        Name: discountsMetadataMap[discount.DiscountId]?.StorefrontText || discountsMetadataMap[discount.DiscountId]?.Name,
        savings: discount.Amount || discount.DiscountAmount,
        startDate: discount.StartDate,
        startDateText: i18n.translate(CustomerCareKeys.STARTS_ON),
        futureDiscountText:i18n.translate(CustomerCareKeys.FUTURE_DISCOUNTS),
        pastDiscountIndex: pastDiscountIndex,
        futureDiscountIndex: futureDiscountIndex,
        futureDiscountsLength: futureDiscountsLength,
        pastDiscountsLength: pastDiscountsLength,
        expirationDate: discount.DiscountExpirationDate,
        expirationDateText: i18n.translate(CustomerCareKeys.EXPIRES_DATE)
    };
};
