import angular from 'angular';

import {config} from './usage.caps.config';
import {USAGE_CAPS_URL} from './usage.caps.constants';
import UsageCapsComponent from './usage.caps.component';
import UsageCapIndicator from './usageCapIndicator/usage.cap.indicator.component';


export default angular.module(USAGE_CAPS_URL, [])
    .config(config)
    .component('usageCapsIndicator', UsageCapIndicator)
    .component('usageCaps', UsageCapsComponent)
    .name;
