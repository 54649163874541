export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.autoPay', {
        url: '/autoPay/:accountNumber',
        params: {
            accountNumber: ''
        },
        template: '<customer-make-payment is-auto-pay="true"></customer-make-payment>',
        params: {
            accountNumber: {
                value: null,
                squash: true
            }
        }
    });
}
