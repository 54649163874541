import LocaleKeys from '../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';

class PricingPlanRenewalPeriodsTooltipController {
    constructor() {
        Object.assign(this, {
            LocaleKeys,
            tooltipTemplate: require('./tooltip.template.html')
        });
    }

    $onInit() {
        this.showTooltip = this.pricingPlan.MinimumPeriodsCount || this.pricingPlan.MaximumPeriodsCount;
        const minRenewalPeriod = this.pricingPlan.MinimumPeriodsCount ?
            i18n.translate(this.LocaleKeys.DECISIONS.RENEWAL_PERIOD_MIN, {
                period: this.pricingPlan.MinimumPeriodsCount
            }) : null;
        const maxRenewalPeriod = this.pricingPlan.MaximumPeriodsCount ?
            i18n.translate(this.LocaleKeys.DECISIONS.RENEWAL_PERIOD_MAX, {
                period: this.pricingPlan.MaximumPeriodsCount
            }) : null;
        this.tooltipContext = {
            maxRenewalPeriod,
            minRenewalPeriod
        };
    }

}

export default {
    template: require('./pricingplan.renewalperiods.tooltip.template.html'),
    controller: PricingPlanRenewalPeriodsTooltipController,
    bindings: {
        pricingPlan: '<'
    }
};
