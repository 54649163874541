import {path} from 'ramda';
import {stateGo} from 'redux-ui-router';
import {
    i18n,
    MetadataActions,
    MetadataConstants,
    MetadataSelectors,
    SessionSelectors
} from 'invision-core';
import CustomerCareLocaleKeys from '../../../../../../../locales/keys';
import {CurrentCustomerIdSelector} from '../../../../../../../reducers/selectors/customer.selectors';
import * as EwalletSelector from '../../../../../../../reducers/selectors/customer.ewallet.selectors';
import {CurrentMemberHasCreatePaymentInstrumentPermissionsSelector} from '../../../../../../../reducers/selectors/customer.household.selectors';
import {
    CurrentRemovalSelector,
    CurrentSubscriptionIdSelector,
    RemoveSubscriptionNameSelector,
    CurrentSubscriptionSelector,
    IsFetchingDataSelector,
    UserCanIssueCreditToAlternatePaymentInstrument
} from '../../../../../../../reducers/selectors/customer.subscriptions.selectors';
import {
    CurrentSubscriptionItemWithExtensionSelectors,
    CurrentSubscriptionItemExtensionSelectors,
    CurrentSubscriptionItemExtensionPricingPlanSelectors
} from '../../../../../../../reducers/selectors/customer.subscriptions.products.selectors';
import {
    CurrencySelector,
    IsSubmittingOrderSelector,
    LastAttemptErrorSelector
} from '../../../../../../../reducers/selectors/offering.remove.selectors';
import {
    retrieveSubscription
} from '../../../../../../../reducers/actions/customer.subscriptions.actions';
import {
    retrieveWallet,
    selectPaymentMethod
} from '../../../../../../../reducers/actions/customer.ewallet.actions';
import {
    submitChangeOfServiceOrderRemoval
} from '../../../../../../../reducers/actions/offering.remove.actions';
import * as SubscriptionConstants from '../../../../../../shared/constants/subscriptions.constants';
import {NOTIFICATION_TIME_LENGTH} from '../../../../../../../customercare.constants';
import {SUBSCRIPTION_DETAILS_ROUTE} from '../../../../subscriptions.config';
import {DASHBOARD_ROUTE} from '../../../../../../../reducers/constants/dashboard.constants';

const CREDIT_AMOUNT_TYPE = {
    MAX: 'MAX',
    CUSTOM: 'CUSTOM'
};

class RemoveExtensionConfirmationController {
    constructor($ngRedux, $q, $filter, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $q,
            $filter,
            CreditAmountType: CREDIT_AMOUNT_TYPE,
            CustomerCareLocaleKeys,
            uiNotificationService
        });

        this.confirmationPopup = {
            onRegisterApi: ({api}) => {
                this.confirmationPopup.api = api;
            }
        };

        this.removal = {
            creditAmount: 0,
            creditAmountType: CREDIT_AMOUNT_TYPE.MAX,
            creditReason: null,
            customAmount: 0,
            errors: [],
            sendNotification: true,
            terminationReason: null
        };

        this.onConfirmation = this.onConfirmation.bind(this);
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                canIssueCreditToAlternatePaymentMethod: UserCanIssueCreditToAlternatePaymentInstrument(store),
                creditReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.CreditReasons, store),
                creditReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.CreditReasons, store),
                currency: CurrencySelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                currentMemberCanCreatePaymentInstrument: CurrentMemberHasCreatePaymentInstrumentPermissionsSelector(store),
                currentRemoval: CurrentRemovalSelector(store),
                currentSubscription: CurrentSubscriptionSelector(store),
                currentSubscriptionItem: CurrentSubscriptionItemWithExtensionSelectors(store),
                currentSubscriptionItemExtension: CurrentSubscriptionItemExtensionSelectors(store),
                currentSubscriptionItemExtensionPricingPlan: CurrentSubscriptionItemExtensionPricingPlanSelectors(store),
                eWalletError: EwalletSelector.EwalletErrorSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isFetchingEwalletData: EwalletSelector.IsRetrievingEwalletDataSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                language: SessionSelectors.LanguageSelector(store),
                lastAttemptError: LastAttemptErrorSelector(store),
                lastRoute: SessionSelectors.LastRouteSelector(store),
                subscriptionId: CurrentSubscriptionIdSelector(store),
                paymentInstruments: EwalletSelector.PaymentInstrumentsCanBeUsedForOrderingWithTypeNamesViewModelSelector(store),
                subscriptionName: RemoveSubscriptionNameSelector(store),
                subscriptionTerminationReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.SubscriptionTerminationReason, store),
                subscriptionTerminationLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.SubscriptionTerminationReason, store),
                selectedPaymentMethod: EwalletSelector.SelectedPaymentMethodSelector(store),
                userHasEWalletAdminAccess: EwalletSelector.UserHasEWalletAdminAccess(store),
                waiveFeeReasons: MetadataSelectors.codes.MetadataOptionsForCodeValuesSelector(MetadataConstants.codes.WaiveFeeReason, store),
                waiveFeeReasonsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.WaiveFeeReason, store)
            };
        };

        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            retrieveSubscription,
            retrieveWallet,
            selectPaymentMethod,
            submitChangeOfServiceOrderRemoval,
            stateGo
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.subscriptionTerminationLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.SubscriptionTerminationReason);
        }

        if (!this.state.creditReasonsLoaded) {
            this.actions.fetchCodeType(MetadataConstants.codes.CreditReasons);
        }

        this.$q.all([
            this.actions.retrieveSubscription(this.state.currentCustomerId, {
                Id: this.state.subscriptionId,
                IncludeChangeOptions: true
            }),
            this.actions.retrieveWallet({
                customerId: this.state.currentCustomerId
            })
        ]).then(() => {
            return this.actions.selectPaymentMethod(this.state.currentSubscription.PaymentInstrument);
        }).then(() => {
            this.removal.creditAmount = this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount;
        }).catch(() => {
            const message = path(['eWalletError', 'message'], this.state) || path(['subscriptionError', 'message'], this.state);
            if (message) {
                this.uiNotificationService.transientError(message);
            }
        });
    }

    handleSetEffectiveType() {
        this.removal.creditAmount = 0;
        this.removal.creditAmountType = CREDIT_AMOUNT_TYPE.MAX;
        this.removal.customAmount = 0;
    }

    isCurrentPaymentMethodRemoved() {
        if (!this.state.selectedPaymentMethod) {
            return false;
        }
        return this.state.selectedPaymentMethod.Status === SubscriptionConstants.PAYMENT_METHOD_STATUSES.REMOVED;
    }

    confirmRemoval() {
        if (this.removeExtensionForm.$valid && this.isAmountValid) {
            this.confirmationPopup.api.open();
        }
    }

    closeConfirmation() {
        this.confirmationPopup.api.close();
    }

    onConfirmation(confirmed) {
        if (confirmed) {
            this.removeExtensionForm.$setPristine();
            this.removeExtension();
        }

        this.confirmationPopup.api.close();
    }

    showCreditOptions() {
        return this.state.currentSubscriptionItemExtensionPricingPlan ? !!this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount : false;
    }

    createRequest() {
        const removeExtensionRequest = {
            CreditAmount: this.removal.creditAmount,
            CreditPaymentInstrument: this.state.selectedPaymentMethod,
            CreditPaymentInstrumentId: this.state.selectedPaymentMethod.Id,
            CreditReason: this.removal.creditReason,
            RemoveReasonCode: this.removal.terminationReason,
            RemoveItems: [{
                LockerItemId: parseInt(this.state.currentSubscriptionItem.Id),
                PricingPlanId: this.state.currentSubscriptionItemExtension.PricingPlanId,
                ProductId: this.state.currentSubscriptionItemExtensionPricingPlan ? this.state.currentSubscriptionItemExtensionPricingPlan.ProductId : null,
                SubscriptionExtensionId: parseInt(this.state.currentSubscriptionItemExtension.Id),
            }],
            SendNotification: this.removal.sendNotification,
            SubscriptionId: this.state.subscriptionId,
            UseDefaults: true
        };
        return removeExtensionRequest;
    }

    removeExtension() {
        this.actions.submitChangeOfServiceOrderRemoval(this.createRequest(), this.state.currentCustomerId)
            .then(() => {
                this.uiNotificationService.success(i18n.translate(this.CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.REMOVE_SUCCESS), null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
                if (this.state.lastRoute.name === SUBSCRIPTION_DETAILS_ROUTE) {
                    this.actions.stateGo(SUBSCRIPTION_DETAILS_ROUTE, {
                        'subscriptionId': this.state.currentSubscription.Id
                    });
                } else {
                    this.actions.stateGo(DASHBOARD_ROUTE, {
                        'customerId': this.state.currentCustomerId
                    });
                }
            })
            .catch(() => {
                this.uiNotificationService.error(this.state.lastAttemptError.message, null, {
                    timeOut: NOTIFICATION_TIME_LENGTH
                });
            });
    }
    get isAmountValid() {
        const value = parseFloat(this.removal.creditAmount);
        const max = this.state.currentSubscriptionItemExtensionPricingPlan ?
            this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount : value;
        return value >= 0 && value <= max;
    }

    handleSetCreditAmount() {
        if (this.removal.creditAmountType === CREDIT_AMOUNT_TYPE.MAX) {
            this.removal.customAmount = 0;
            this.removal.creditAmount = this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount;
            this.removeExtensionForm.creditCustomAmount.$setUntouched();
            this.removeExtensionForm.creditCustomAmount.$validate();
            if (!this.removeExtensionForm.creditCustomAmount.$invalid) {
                this.removal.errors = [];
            }
        } else if (this.removal.creditAmountType === CREDIT_AMOUNT_TYPE.CUSTOM) {
            this.removal.creditAmount = this.removal.customAmount || 0;
            this.removeExtensionForm.creditCustomAmount.$validate();
            if (this.removeExtensionForm.creditCustomAmount.$invalid) {
                this.removal.errors = [this.creditMinMaxAmount];
            }
        }
    }
    updateCustomAmount() {
        this.removal.creditAmount = this.removal.customAmount || 0;
        if (this.removeExtensionForm.creditCustomAmount.$invalid) {
            this.removal.errors = [this.creditMinMaxAmount];
        } else {
            this.removal.errors = [];
        }
    }

    get amountValueInvalid() {
        return i18n.translate(this.CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.MAX_AMOUNT, {
            maxAmount: this.$filter('invCurrency')(this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount, this.state.currency)
        });
    }

    get canCreatePaymentInstruments() {
        return this.state.currentMemberCanCreatePaymentInstrument && this.state.userHasEWalletAdminAccess;
    }

    get creditMinMaxAmount() {
        const max = this.state.currentSubscriptionItemExtensionPricingPlan ? this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount : null;
        return i18n.translate(this.CustomerCareLocaleKeys.REMOVE_OFFER.AMOUNT_VALUE_INVALID, {
            minAmount: this.$filter('invCurrency')(0, this.state.currency),
            maxAmount: this.$filter('invCurrency')(max, this.state.currency)
        });
    }

    get customAmountIsError() {
        return (this.removeExtensionForm.creditCustomAmount.$touched || this.removeExtensionForm.$submitted) &&
            (!this.isAmountValid && this.removal.creditAmountType === this.CreditAmountType.CUSTOM);
    }

    get isLoading() {
        return this.state.isFetchingData || this.state.isFetchingEwalletData || this.state.isSubmittingOrder;
    }

    get maxAmount() {
        const max = this.state.currentSubscriptionItemExtensionPricingPlan ? this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount : null;
        return i18n.translate(this.CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.MAX_AMOUNT, {
            maxAmount: this.$filter('invCurrency')(max, this.state.currency)
        });
    }

    get pricingPlanTitle() {
        return i18n.translate(this.CustomerCareLocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.REMOVE_PRICING_PLAN, {
            pricingPlanName: this.state.currentSubscriptionItemExtensionPricingPlan ? this.state.currentSubscriptionItemExtensionPricingPlan.Name : null
        });
    }

    $postLink() {
        this.removeExtensionForm.creditCustomAmount.$validators.isCreditAmountValid = (modelValue, viewValue) => {
            const value = parseFloat(modelValue || viewValue);
            const max = this.state.currentSubscriptionItemExtensionPricingPlan ?
                this.state.currentSubscriptionItemExtensionPricingPlan.ChargeAmount : value;

            return value >= 0 && value <= max || this.removal.creditAmountType === CREDIT_AMOUNT_TYPE.MAX;
        };
    }
}

export default {
    template: require('./remove.extension.confirmation.template.html'),
    controller: RemoveExtensionConfirmationController
};
