export const CASE_CATEGORIES =  {
    TROUBLE_CALL: '1',
    COMPLAINT: '2',
    BILLING_DISPUTE: '3',
    INVOICE_DISPUTE: '4',
    PORT_IN: '5',
    LINE_ITEM_DISPUTE: '6'
};
export const CASE_STATUS =  {
    NEW: '1',
    OPEN: '2',
    CLOSED: '3',
    CANCELED: '4',
    PROMOTED_TO_MASTER: '5'
};
export const CASE_DETAILS_TABS = {
    ACTIVITY: 'ACTIVITY',
    DETAILS: 'CASE_DETAILS'
};