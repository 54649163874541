import {
    ApiConstants,
    InjectorHelper,
    SessionSelectors,
    ThunkHelper
} from 'invision-core';
import values from 'ramda/src/values';

const ACP_ACCOUNT_ID = 5000;

const RETRIEVE_ACSR_CUSTOMER = {
    BEGIN: 'RETRIEVE_CUSTOMER_BEGIN',
    SUCCESS: 'RETRIEVE_CUSTOMER_SUCCESS',
    FAILURE: 'RETRIEVE_CUSTOMER_FAILURE'
};

const retrieveCustomerByExternalReferencePromise = (dispatch, externalReference) => {
    const $ngRedux = InjectorHelper.getNgReduxService();
    const state = $ngRedux.getState();

    return ThunkHelper(dispatch, values(RETRIEVE_ACSR_CUSTOMER), {
        method: 'post',
        url: 'SubscriberManagement/RetrieveSubscriber',
        headers: {
            [ApiConstants.SUBSCRIBER_EXTERNAL_REFERENCE_HEADER]: externalReference,
            [ApiConstants.SUBSCRIBER_EXTERNAL_REFERENCE_TYPE_HEADER]: ACP_ACCOUNT_ID,
            [ApiConstants.CUSTOM_SUBSCRIBER_LANGUAGE_HEADER]: SessionSelectors.LanguageHeaderSelector(state)
        },
        data: {}
    });
};

export const retrieveCustomerByExternalReference = (externalReference) => {
    return (dispatch) => {
        return retrieveCustomerByExternalReferencePromise(dispatch, externalReference);
    };
};