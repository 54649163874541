import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import CustomerCareKeys from '../../../locales/keys';
import {retrieveContract} from '../../../reducers/actions/contract.details.actions';
import {
    ContractPricingPlanTableDataSelector,
    IsFetchingSelectedContractSelector,
    SelectedContractPricingPlanEtfInstanceSelector,
    SelectedContractPricingPlanEtfScheduleSelector
} from '../../../reducers/selectors/contract.selectors';
import {SelectedContractDefinitionSelector} from '../../../reducers/selectors/offering.order.selectors';

import {EARLY_TERMINATION_FEE_CHARGE_TYPES} from '../constants/etf.charge.type.contants';

export class ContractTermsConditionsDialogController {
    constructor($ngRedux, $timeout, $scope) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            $scope,
            customerCareKeys: CustomerCareKeys,
            coreLocaleKeys: CoreLocaleKeys
        });
    }
    $onInit() {
        this.etfChargeType = EARLY_TERMINATION_FEE_CHARGE_TYPES;

        const mapStateToTarget = (store) => {
            return {
                etfChargeTypes: MetadataCodeTypeSelector(CODES.EarlyTerminationFeeChargeType, store),
                isEtfChargeTypesLoaded: MetadataCodeLoadedSelector(CODES.EarlyTerminationFeeChargeType, store),
                isFetchingContract: IsFetchingSelectedContractSelector(store),
                selectedContractDetails: SelectedContractDefinitionSelector(store),
                selectedPricingPlanEtfInstance: SelectedContractPricingPlanEtfInstanceSelector(store),
                selectedPricingPlanEtfSchedule: SelectedContractPricingPlanEtfScheduleSelector(store),
                tableData: ContractPricingPlanTableDataSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeTypesThunk,
            retrieveContract
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (!this.state.isEtfChargeTypesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.EarlyTerminationFeeChargeType);
        }

        this.actions.retrieveContract(this.selectedContractId);
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    bindings: {
        dialogTitle: '<',
        onClose: '&',
        selectedContractId: '<'
    },
    controller: ContractTermsConditionsDialogController,
    template: require('./contract.terms.conditions.dialog.template.html')
};
