import CustomerCareLocaleKeys from '../../../../../locales/keys';

class AcpDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
    }
}

export const AcpDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: AcpDetailController,
    template: require('./acp.detail.html')
};
