import i18n from 'invision-core/src/components/i18n/i18n';
import LocaleKeys from '../../../../locales/keys';

export const getColumnDefinitions = () => {

    return [{
        field: 'ServiceIdentifierValue',
        displayName: i18n.translate(LocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.SERVICE),
        cellTemplate: require('./cellTemplates/service.identifier.template.html'),
        width: '12%',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'ServiceFriendlyName',
        displayName: i18n.translate(LocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.FRIENDLY_NAME),
        width: '12%',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'serviceAttributeName',
        displayName: i18n.translate(LocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.NETWORK_IDENTIFIER),
        width: '18%',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'PricingPlanName',
        displayName: i18n.translate(LocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.PRICING_PLAN_NAME),
        cellTemplate: require('./cellTemplates/pricing.plan.name.template.html'),
        width: '29%',
        resizable: true,
        enableSorting: false,
        enableColumnMoving: false
    },
    {
        field: 'more',
        displayName: i18n.translate(LocaleKeys.PRODUCT_SERVICE_RELATIONSHIP.CONSUME_SERVICE_FROM),
        cellTemplate: require('./cellTemplates/provides.plan.select.template.html'),
        width: '29%',
        resizable: false,
        enableSorting: false,
        enableColumnMoving: false
    }];
};