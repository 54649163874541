import CustomerCareLocaleKeys from '../../../../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import {thresholdDisplayName} from '../../../../../reducers/selectors/usage.caps.selectors.helper';
import {
    USAGE_CAP_BEHAVIOR_TYPES,
    USAGE_CAPS_INDICATOR_NORMAL_ICON,
    USAGE_CAPS_INDICATOR_BREACHED_ICON
} from '../usage.caps.constants';

export class UsageCapIndicatorController {
    constructor($ngRedux) {
        Object.assign(this, {
            capBehaviorTypes: USAGE_CAP_BEHAVIOR_TYPES,
            CustomerCareLocaleKeys,
            hasBreachedCaps: false,
            indicatorIcon: USAGE_CAPS_INDICATOR_NORMAL_ICON,
            indicatorLabel: '',
            tooltipContext: {}
        });

        this.$ngRedux = $ngRedux;
        if (!this.usageCapsArray) {
            this.usageCapsArray = [];
        }
    }

    $onInit() {
        const mapStateToTarget = () => {
            return {};
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, {})((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.toolTipTemplate = require('./usage.cap.indicator.tooltip.template.html');
        this.indicatorLabel = this.getUsageCapLabel(this.usageCapsArray);
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    $onChanges(changesObj) {
        if (changesObj.usageCapsArray && changesObj.usageCapsArray.currentValue && Object.keys(changesObj.usageCapsArray.currentValue).length) {
            this.tooltipContext.formattedCapsObject = this.formatUsageCaps(changesObj.usageCapsArray.currentValue);
            this.indicatorIcon = this.determineIndicatorIcon();
            this.indicatorLabel = this.getUsageCapLabel(changesObj.usageCapsArray.currentValue);
        }
    }

    getUsageCapLabel(capsArray) {
        if (capsArray && capsArray.length) {
            let usageCapLabel = '';
            if (capsArray.length > 1) {
                usageCapLabel = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.MULTIPLE, {
                    numberOfCaps: capsArray.length
                });
            } else {
                usageCapLabel = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.SINGLE);
            }
            return usageCapLabel;
        }
    }

    determineIndicatorIcon() {
        if (this.hasBreachedCaps) {
            return USAGE_CAPS_INDICATOR_BREACHED_ICON;
        }
        return USAGE_CAPS_INDICATOR_NORMAL_ICON;
    }

    getBrechedCapMessage(cap) {
        if (cap.UsageCapBehavior) {
            return cap.UsageCapBehavior === USAGE_CAP_BEHAVIOR_TYPES.DiscountToZero ?
                i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.USAGE_ALLOWED_DISCOUNT_ZERO) :
                i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.USAGE_RESTRICTED);
        }
        return i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.BREACHED);
    }

    formatUsageCaps(usageCapsArray) {
        this.hasBreachedCaps = false;
        const getUsageCapsForTooltip = (cap) => {
            cap.Breached && !this.hasBreachedCaps && cap.UsageCapBehavior !== USAGE_CAP_BEHAVIOR_TYPES.DiscountToZero && (this.hasBreachedCaps = true);
            return {
                breached: !!cap.Breached,
                breachedCapMessage: this.getBrechedCapMessage(cap),
                isDiscountToZero: cap.UsageCapBehavior === USAGE_CAP_BEHAVIOR_TYPES.DiscountToZero,
                id: cap.Id,
                name: cap.Name,
                threshold: thresholdDisplayName(cap.ElectedThreshold, cap.ThresholdType, this.businessUnitCurrencyCode)
            };
        };

        return usageCapsArray && usageCapsArray.map(getUsageCapsForTooltip);
    }
}

export default {
    template: require('./usage.cap.indicator.html'),
    controller: UsageCapIndicatorController,
    bindings: {
        usageCapsArray: '<',
        businessUnitCurrencyCode: '<',
        alwaysVisible: '<?'
    },
    controllerAs: 'UsageCapIndicatorController'
};
