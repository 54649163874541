import curry from 'ramda/src/curry';

class DevicePaymentItemsController {
    constructor() {
        Object.assign(this, {
            handleItemDownPaymentChanged: curry(this._handleItemDownPaymentChanged.bind(this))
        });
    }

    _handleItemDownPaymentChanged(itemIndex, item) {
        if (this.onItemDownpaymentChanged) {
            this.onItemDownpaymentChanged()(itemIndex, item);
        }
    }
}

export default {
    template: require('./device.payment.items.html'),
    controller: DevicePaymentItemsController,
    bindings: {
        items: '<',
        isEditable: '<?',
        onItemDownpaymentChanged: '&?'
    }
};
