import angular from 'angular';

import UsageChart from './usage.chart.component';
import UsageBarChart from './usage.bar.chart.component';
import UsagePieChart from './usage.pie.chart.component';

export default angular.module('invision.customercare.customer.usageChart', [])
    .component('usageChart', UsageChart)
    .component('usageBarChart', UsageBarChart)
    .component('usagePieChart', UsagePieChart)
    .name;
