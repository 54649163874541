import CustomerCareLocaleKeys from '../../../../../locales/keys';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {
    ADDRESS_FORM_FIELD_MAX_LENGTH,
    ADDRESS_STATUS_CONSTANTS
} from '../../../../customer/addresses/addresses.constants';
import {
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector,
    MetadataCodeLoadedSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    COMMA,
    EMPTY_STRING
} from '../../../../../reducers/constants/common.constants';
import {
    fetchCodeTypes,
} from 'invision-core/src/components/metadata/codes/codes.actions';
import {updateOverrideFlag} from '../../../../../reducers/actions/customer.addresses.actions';
import clone from 'ramda/src/clone';
import {
    setAddress,
    setAddressKeyForAddressBeingValidatedThroughApi,
    setAddressKeyForAddressPreviouslyValidatedThroughAPI,
    setAddressToValidatedAddress,
    setEditAddressBackToInvalid,
    setForceShowEditFormAddress,
    setSuggestedAddressToValidatedAddress,
} from '../../../../../reducers/actions/address.component.actions';
import {generateKeyFromAddress} from '../../../../../reducers/helpers/address.helper';
import {
    AddressBeingValidatedSelector,
    IsForceShowEditFormStatusSelector,
    IsInvalidAddressOverridableScenarioSelector,
    IsThereSuggestedAddressesSelector,
    MduRangeSelector,
    OVERRIDE_ADDRESS_VALUE,
    PreviouslyValidatedAddressSelector,
    SuggestedAddressesSelector,
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../../reducers/selectors/address.component.selectors';
import {COUNTRY_CANADA} from './address.wrapup.constants';

const addressDefaultSettings = {
    addressStates: [],
    addressModel: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '', //This is country code
        postalCode: '',
        shipToName: '',
        stateRegionProvince: ''
    },
    isCountryFieldReadonly: false,
    isDisabled: false,
    isShipToFieldVisible: false,
    isValidating: false,
    postalCodeSettings: {},
    requiredFields: {
        /*
            this specifies which address form input fields
            are required (validation-wise and display-wise)
        */
        country: false,
        addressLine1: false,
        addressLine2: false,
        city: false,
        stateRegionProvince: false,
        postalCode: false
    }
};

class AddressWrapupController {
    constructor($ngRedux, $anchorScroll, $location, $timeout) {
        Object.assign(this,
            clone(addressDefaultSettings),
            {
                $anchorScroll,
                $location,
                $ngRedux,
                $timeout,
                ADDRESS_STATUS_CONSTANTS,
                CoreLocaleKeys,
                CustomerCareLocaleKeys,
                singleAddressFormFormApi: {}, //using this to hook into AngularJS Forms
                formMaxLengthValidation: ADDRESS_FORM_FIELD_MAX_LENGTH,
                onModelChange: this.onModelChange.bind(this),
                setAddress: this.setAddress.bind(this),
                setAddressToSuggestedAddress: this.setAddressToSuggestedAddress.bind(this),
                setSuggestedAddresses: this.setSuggestedAddresses.bind(this),
                previousAddressKey: null,
                canScrollToSuggested: false
            }
        );
    }

    $onInit() {
        const controllerActions = {
            fetchCodeTypes,
            setAddress,
            setAddressKeyForAddressBeingValidatedThroughApi,
            setAddressKeyForAddressPreviouslyValidatedThroughAPI,
            setAddressToValidatedAddress,
            setEditAddressBackToInvalid,
            setSuggestedAddressToValidatedAddress,
            setForceShowEditFormAddress,
            updateOverrideFlag
        };

        this.disconnectRedux = this.$ngRedux.connect(this.mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
            this.setSuggestedAddresses();
            this.updateAddressToValidatedAddressFromAPI();
        });

        if (!this.state.addressCountriesLoaded) {
            this.actions.fetchCodeTypes(CODES.AddressCountry);
        }

        if (this.requiredFields) {
            this.isAnyFieldRequired = Object.keys(this.requiredFields).length > 0;
        }

        const addressKey = generateKeyFromAddress(this.addressWrapupModel);

        this.singleAddressFormFormApi.addressKey = addressKey;
        this.isInvalidAddressSetAsValid = false;
    }

    $onChanges() {
        Object.assign(this.addressModel, this.addressWrapupModel);
        if (this.canUpdateAddressReduxState() && (generateKeyFromAddress(this.addressModel) !== this.singleAddressFormFormApi.addressKey) ) {
            this.setAddress(this.addressModel);
        }
    }

    mapStateToTarget(store) {
        return {
            addressBeingValidated: AddressBeingValidatedSelector(store),
            addressCountries: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.AddressCountry, store),
            addressCountriesLoaded: MetadataCodeLoadedSelector(CODES.AddressCountry, store),
            isInvalidAddressOverridableScenario: IsInvalidAddressOverridableScenarioSelector(store),
            isSuggestedAddressesPresent: IsThereSuggestedAddressesSelector(store),
            initialValidatedAddress: PreviouslyValidatedAddressSelector(store),
            isForceShowEditForm: IsForceShowEditFormStatusSelector(store),
            mduRange: MduRangeSelector(store),
            suggestedAddresses: SuggestedAddressesSelector(store),
            unvalidatedAddresses: UnvalidatedAddressesSelector(store),
            validatedAddresses: ValidatedAddressesSelector(store)
        };

    }

    $onDestroy() {
        if (this.showSuggestedForm() && this.isInvalidAddressSetAsValid) {
            this.actions.setEditAddressBackToInvalid(this.originalEnteredAddress, this.lastValidatedAddressForEnteredAddress);
        }
        this.actions.setAddressKeyForAddressBeingValidatedThroughApi(null);
        this.actions.setAddressKeyForAddressPreviouslyValidatedThroughAPI(null);
        this.originalAddressOverwrittenWithValidatedAddress = false;
        this.isOverridden = false;
        this.disconnectRedux();
    }

    setSuggestedAddresses() {
        if (!this.isOverridden && !this.state.isForceShowEditForm) {
            this.suggestedAddresses = clone(this.state.suggestedAddresses);
            if (this.suggestedAddresses.length) {
                this.selectedSuggestedAddress = this.suggestedAddresses[0].value;
                this.$timeout(() => {
                    this.setAddressToSuggestedAddress();
                });
            }
        }
    }

    showEditForm() {
        this.actions.setForceShowEditFormAddress(true);
        this.isOverridden = false;
        this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI = null;

        if (this.isInvalidAddressSetAsValid) {
            this.actions.setEditAddressBackToInvalid(this.originalEnteredAddress, this.lastValidatedAddressForEnteredAddress);
            this.isInvalidAddressSetAsValid = false;
            this.lastValidatedAddressForEnteredAddress = null;
        }
        this.addressModel = clone(this.originalEnteredAddress);
        this.onModelChange(this.originalEnteredAddress);
    }

    onModelChange(addressWrapupModel) {
        this.onAddressChange()(addressWrapupModel);
        this.setAddress(addressWrapupModel);
    }

    //TO-DO for security attribute use this function
    setAddressToSuggestedAddress() {
        const selectedSuggestedAddress = this.selectedSuggestedAddress,
            shipToName = this.addressModel.shipToName,
            addressResponseFromApi = this.state.validatedAddresses[this.singleAddressFormFormApi.addressKey];
        let formattedAddress;

        this.isOverridden = true;

        if (this.selectedSuggestedAddress !== OVERRIDE_ADDRESS_VALUE) {
            if (!this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI) {
                this.actions.setAddressKeyForAddressPreviouslyValidatedThroughAPI(this.singleAddressFormFormApi.addressKey, addressResponseFromApi.JobToken);
            }

            if (this.isInvalidAddressSetAsValid) {
                this.actions.setEditAddressBackToInvalid(this.originalEnteredAddress, this.lastValidatedAddressForEnteredAddress);
                this.isInvalidAddressSetAsValid = false;
            }

            formattedAddress = {
                addressLine1: selectedSuggestedAddress.LineOne,
                addressLine2: selectedSuggestedAddress.LineTwo,
                city: selectedSuggestedAddress.City,
                country: selectedSuggestedAddress.Country, //This is country code
                postalCode: selectedSuggestedAddress.PostalCode,
                shipToName,
                stateRegionProvince: selectedSuggestedAddress.State
            };

            const newAddressKey = generateKeyFromAddress(formattedAddress);
            this.actions.setAddressKeyForAddressBeingValidatedThroughApi(newAddressKey);
            this.actions.setSuggestedAddressToValidatedAddress(formattedAddress, addressResponseFromApi.JobToken);
            this.onModelChange(formattedAddress);
            this.actions.updateOverrideFlag(null);
        } else if (this.originalEnteredAddress) {
            this.isInvalidAddressSetAsValid = true;
            if (!this.lastValidatedAddressForEnteredAddress) {
                this.lastValidatedAddressForEnteredAddress = addressResponseFromApi;
            }
            if (this.selectedSuggestedAddress === OVERRIDE_ADDRESS_VALUE) {
                this.actions.updateOverrideFlag(true);
            }
            this.actions.setAddressToValidatedAddress(this.originalEnteredAddress, addressResponseFromApi.JobToken);
            this.onModelChange(this.originalEnteredAddress);
        }
    }

    setAddress(addressWrapupModel) {
        /*
            Before using API to validate, we want to make sure that
            the address passes ui validation requirements.
            Also we should only be validating if the fields are required.
        */
        if ( this.canUpdateAddressReduxState()) {
            const addressKey = generateKeyFromAddress(addressWrapupModel);

            if (this.state.isForceShowEditForm) {
                //At this point they started editing the form from suggestion so reset everything
                this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI= null;
            }

            this.actions.setAddress(addressWrapupModel);
            this.actions.setAddressKeyForAddressBeingValidatedThroughApi(addressKey);
            //This will be used by the parentComponent for address validation
            this.singleAddressFormFormApi.addressKey = addressKey;

            if (this.state.isForceShowEditForm || !this.showSuggestedForm()) {
                this.originalEnteredAddress = clone(this.addressModel);
            }
        }
    }

    canUpdateAddressReduxState() {
        return (   //we do not want to use AngularJS form $valid when radio buttons showing
            (this.singleAddressFormFormApi && this.singleAddressFormFormApi.$valid) ||
                    this.showSuggestedForm()
        )
                && this.isAnyFieldRequired;
    }

    enteredAddress() {
        return this.originalEnteredAddress ?
            `${this.originalEnteredAddress.addressLine1}, ${this.originalEnteredAddress.addressLine2 ? this.originalEnteredAddress.addressLine2 + COMMA: EMPTY_STRING} ${this.originalEnteredAddress.city}, ${this.originalEnteredAddress.stateRegionProvince} ${this.originalEnteredAddress.postalCode}, ${this.originalEnteredAddress.country}` :
            null;
    }

    showSuggestedForm() {
        if (this.singleAddressFormFormApi && (this.singleAddressFormFormApi.$submitted || this.singleAddressFormFormApi.submitted)) {
            const addressKey = generateKeyFromAddress(this.addressModel);

            if (this.state.isForceShowEditForm) {
                return false;
            }

            if (this.isOverridden) {
                return this.suggestedAddresses.length;
            }
            if (this.state.validatedAddresses[addressKey] && !this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI) {
                //initial setting of address validatedThroughAPI
                this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI = addressKey;
                this.canScrollToSuggested = true;
            }

            const shouldShowSuggestedForm = this.singleAddressFormFormApi.keyForAddressValidatedThroughAPI;

            //TO-DO make sure scrolling works
            if (shouldShowSuggestedForm && this.canScrollToSuggested) {
                const old = this.$location.hash();
                this.$location.hash('addressFormSuggestedAddresses');
                this.$anchorScroll();
                this.$location.hash(old);
                this.canScrollToSuggested = false;
            }

            if (this.state.isInvalidAddressOverridableScenario) {
                //TO-DO we should be referring to this.state.suggestedAddresses
                return this.state.suggestedAddresses;
            }

            return shouldShowSuggestedForm && this.state.validatedAddresses[shouldShowSuggestedForm] && (this.state.validatedAddresses[shouldShowSuggestedForm].SuggestedAddresses || this.state.validatedAddresses[shouldShowSuggestedForm].AddressValidationStatus === ADDRESS_STATUS_CONSTANTS.MDU_VALIDATION_ERROR);
        }

        return false;
    }

    updateAddressToValidatedAddressFromAPI() {
        if (this.singleAddressFormFormApi && this.singleAddressFormFormApi.$submitted) {
            const addressKey = generateKeyFromAddress(this.addressModel),
                validatedAddress = this.state.validatedAddresses[addressKey] && this.state.validatedAddresses[addressKey].ValidatedAddress,
                shipToName = this.addressModel.shipToName,
                addressValidationStatus = this.state.validatedAddresses[addressKey] && this.state.validatedAddresses[addressKey].AddressValidationStatus;

            this.addressValidationStatus = addressValidationStatus;
            this.isMissingUnitNumber = false;
            if (addressValidationStatus === ADDRESS_STATUS_CONSTANTS.MDU_VALIDATION_ERROR) {
                this.isMissingUnitNumber = true;
                this.mduRange = validatedAddress && validatedAddress.MduRange;
            }
            if (validatedAddress && !this.originalAddressOverwrittenWithValidatedAddress) {
                //Original address set through API
                const formattedAddress = {
                    addressLine1: validatedAddress.LineOne || validatedAddress.addressLine1,
                    addressLine2: validatedAddress.LineTwo || validatedAddress.addressLine2,
                    city: validatedAddress.City || validatedAddress.city,
                    country: validatedAddress.Country || validatedAddress.country, //This is country code
                    postalCode: validatedAddress.PostalCode || validatedAddress.postalCode,
                    shipToName,
                    stateRegionProvince: validatedAddress.State || validatedAddress.stateRegionProvince
                };

                //situation where the API returns a validated address for an address that ALMOST matches
                this.originalAddressOverwrittenWithValidatedAddress = true;

                this.actions.setAddressToValidatedAddress(formattedAddress, validatedAddress.JobToken, addressValidationStatus);
                this.onModelChange(formattedAddress);
            }
        }
    }

    addUnitNumberToAddress() {
        if (this.originalEnteredAddress.country === COUNTRY_CANADA) {
            this.originalEnteredAddress.addressLine1 = this.unitNumber.concat(' ', this.originalEnteredAddress.addressLine1);
        } else {
            this.originalEnteredAddress.addressLine1 = this.originalEnteredAddress.addressLine1.concat(' ', this.unitNumber);
        }
        this.onModelChange(this.originalEnteredAddress);
        this.isMissingUnitNumber = false;
        this.unitNumber = '';
    }
}
//is errors even necessary anymore to pass down?
export default {
    bindings: {
        /*
            why am I passing in stateOptions but not countries?
            Currently in the locations the contextual component is being used,
            the state options selectors have a dependency on the parent element info.
            E.g. stateOptions in customer components are dependent on customerInfo
            stateOptions in modify mobile Number are dependent on mobileInfo.
            Passing it down for now until we resolve this.
        */
        addressStates: '<',
        addressWrapupModel: '<',
        isAddressApiValidationOn: '<?',
        isCountryFieldReadonly: '<?',
        isDisabled: '<?',
        isNewConnectServiceabilityStep: '<?',
        isShipToFieldVisible: '<?',
        onAddressChange: '&',
        postalCodeSettings: '<',
        requiredFields: '<?'
    },
    template: require('./address.wrapup.html'),
    controller: AddressWrapupController,
    controllerAs: 'addressWrapupController'
};
