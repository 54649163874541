import CustomerCareLocaleKeys from '../../../../../locales/keys';

class BraintreeDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;
    }
}

export const BraintreeDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: BraintreeDetailController,
    template: require('./braintree.detail.html')
};
