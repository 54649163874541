import CustomerCareLocaleKeys from '../../locales/keys';
import {path} from 'ramda';
import {CustomerSearchModelSelector,
    CustomerSearchRecordCountSelector} from '../../reducers/selectors/search.customer.selectors';
import {setCustomerSearchModel} from '../../reducers/actions/search.customer.actions';
import {setGiftCardSearchModel} from '../../reducers/actions/search.giftcard.actions';
import {setCouponSearchModel} from '../../reducers/actions/search.coupon.actions';
import {
    hideSearchPanel,
    setSearchType
} from '../../reducers/actions/search.actions';
import {
    IsSearchPanelVisibleSelector,
    SearchIsFetchingResultsSelector,
    SearchTypeSelector
} from '../../reducers/selectors/search.selectors';
import {
    GiftCardSearchRecordCountSelector,
    GiftCardSearchModelSelector
} from '../../reducers/selectors/search.giftcard.selectors';
import {
    CouponSearchRecordCountSelector,
    CouponSearchModelSelector
} from '../../reducers/selectors/search.coupon.selectors';
import PanelAutoClose from 'invision-core/src/utilities/panel.auto.close.handler';
import {SEARCH_DROPDOWN_CONSTANTS} from './search.constants';


export function SearchController($element, $ngRedux, $timeout) {
    let disconnectRedux;
    const mapStateToTarget = (store) => {
        return {
            couponSearchModel: CouponSearchModelSelector(store),
            couponSearchResultCount: CouponSearchRecordCountSelector(store),
            customerSearchModel: CustomerSearchModelSelector(store),
            customerSearchResultCount: CustomerSearchRecordCountSelector(store),
            giftCardSearchModel: GiftCardSearchModelSelector(store),
            giftCardSearchResultCount: GiftCardSearchRecordCountSelector(store),
            isFetchingResults: SearchIsFetchingResultsSelector(store),
            isSearchPanelVisible: IsSearchPanelVisibleSelector(store),
            searchType: SearchTypeSelector(store)
        };
    };

    this.$onInit = () => {
        this.customerCareLocaleKeys = CustomerCareLocaleKeys;
        this.isVisible = false;
        this.pageNumber = 1;
        const controllerActions = {
            hideSearchPanel,
            setCustomerSearchModel,
            setGiftCardSearchModel,
            setCouponSearchModel,
            setSearchType
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        // This is used to call a function in the child component (searchFields) to clear the data given on
        // search results and search type is Individual or Company due to the component not being re-instantiated.
        this.searchFieldsApiFactory = (api) => {
            this.searchFieldsApi = api;
        };

        this.changePageNumber = (pageNum) => {
            this.pageNumber = pageNum;
        };

        this.pushCustomerSearchModel = (updatedModel) => {
            this.actions.setCustomerSearchModel(updatedModel);
        };

        this.pushGiftCardSearchModel = (updatedModel) => {
            this.actions.setGiftCardSearchModel(updatedModel);
        };

        this.pushCouponSearchModel = (updatedModel) => {
            this.actions.setCouponSearchModel(updatedModel);
        };

        this.isEmpty = () => {
            return this.state.customerSearchResultCount <= 1 &&
                this.state.giftCardSearchResultCount <= 1;
        };

        this.searchPanelEvent = PanelAutoClose.autoCloseRegistration({
            element: 'search',
            toggleButtonElement: '#navigator-item-search',
            isHidden: this.isSearchPanelHidden,
            callback: this.actions.hideSearchPanel
        });
    };

    this.$onChanges = (changesObj) => {
        if (changesObj.isVisible && changesObj.isVisible.currentValue) {
            if (!this.state.customerSearchResultCount &&
            this.state.searchType !== SEARCH_DROPDOWN_CONSTANTS.GIFT_CARD &&
            this.state.searchType !== SEARCH_DROPDOWN_CONSTANTS.COUPON &&
            this.state.searchType !== SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL) {
                this.actions.setSearchType(SEARCH_DROPDOWN_CONSTANTS.INDIVIDUAL);
            }
            this.clearSearch();

            $timeout(() => {
                $element.find('input').first().focus();
            });
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
        PanelAutoClose.unregister(this.searchPanelEvent);
    };

    this.isSearchPanelHidden = () => {
        return !path(['state', 'isSearchPanelVisible'], this);
    };

    this.clearSearch = () => {
        if (this.searchFieldsApi && 'function' === typeof this.searchFieldsApi.clearSearch) {
            this.searchFieldsApi.clearSearch();
        }
    };
}

// Definition for the self-contained SearchPanel component
export const Search = {
    template: require('./search.html'),
    controller: SearchController,
    controllerAs: 'Search',
    bindings: {
        isVisible: '<'
    }
};
