import localeKeys from '../../../../../../locales/keys';

class ShippingTooltipController {
    constructor() {
        this.localeKeys = localeKeys;
        this.tooltipContext = {};
    }

    $onInit() {
        this.tooltipTemplate = require('./prices.description.tooltip.template.html');
    }

    $onChanges(changeObj) {
        if (changeObj.items) {
            this.tooltipContext.items = changeObj.items.currentValue;
        }
        if (changeObj.currencyCode) {
            this.tooltipContext.currencyCode = changeObj.currencyCode.currentValue;
        }
        if (changeObj.brandableCurrencyName) {
            this.tooltipContext.brandableCurrencyName = changeObj.brandableCurrencyName.currentValue;
        }
    }
}

export default {
    bindings: {
        brandableCurrencyName: '<',
        currencyCode: '<',
        items: '<' // See CartTab in products.order.selector.helper.js
    },
    controller: ShippingTooltipController,
    template: require('./shipping.tooltip.html')
};
