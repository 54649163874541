import has from 'ramda/src/has';
import pathOr from 'ramda/src/pathOr';

import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';

import CustomerCareLocaleKeys from '../../../../../../locales/keys';
import {
    BILLER_RULE_INSTANCE_TYPE,
    PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE
} from '../../../../../../customercare.constants';
import {OfferingOptionBulkTypes} from '../../../../../../reducers/constants/convergent.biller.constants';
import {END_DATE_TYPES} from '../../../../../../reducers/constants/dashboard.constants';

import {updateLifeCycleDetails} from '../../../../../../reducers/actions/customer.actions';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';

import {
    OfferingMetadataByIdSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';
import {
    DashBoardLifeCycleStepsForOffersSelector,
    LifeCyclePricingPlanDetailsSelector,
    PricingPlanInLifeCycleForOffersSelector
} from '../../../../../../reducers/selectors/selected.offering.order.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {getDiscountTooltipModel} from '../../../../../shared/tooltipTemplates/discount.tooltip.helper';
import {DiscountsMetadataMapSelector} from 'invision-core/src/components/metadata/discounts/discounts.selectors';

export default class OfferingOptionCtrl {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            customerCareKeys: CustomerCareLocaleKeys,
            discountToolTipTemplate: require('../../../../../shared/tooltipTemplates/discount.tooltip.html'),
            END_DATE_TYPES,
            isOffCyclePaymentFailed: false,
            offeringMetadataLoaded: false,
            OfferingOptionBulkTypes,
            PRODUCT_CLASSIFICATIONS,
            PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                discountsMetadataMap: DiscountsMetadataMapSelector(store),
                offeringMetadataById: OfferingMetadataByIdSelector(store),
                offeringMetadataPricingPlans: OfferingMetadataPricingPlansSelector(store),
                pricingPlanInLifeCycleForOffers: PricingPlanInLifeCycleForOffersSelector(store),
                lifeCyclePricingPlanDetails: LifeCyclePricingPlanDetailsSelector(store),
                lifeCycleStepsForOffers: DashBoardLifeCycleStepsForOffersSelector(store)
            };
        };

        const controllerActions = {
            retrieveOfferingsMetadata,
            updateLifeCycleDetails
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.isOffCyclePaymentFailed = this.option.OffCycleDetail &&
            this.option.OffCycleDetail.GracePeriodExpiryDate &&
            this.option.OffCycleDetail.CurrentRenewalFailedAttempts;

        this.hasBillerItemBulkPrices = pathOr(0, ['BillerRulePrices', 'length'], this.option) !== 0;

        this.billerItemBulkPricesOrBillerTypeAmounts = this.hasBillerItemBulkPrices ?
            this.option.BillerRulePrices : this.option.BillerTypeAmounts || [];

        this.entitlementBris = this.findAllBillerItemFromType(BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT);
        this.oneTimeBris = this.findAllBillerItemFromType(BILLER_RULE_INSTANCE_TYPE.ONETIME);
        this.recurringBris = this.findAllBillerItemFromType(BILLER_RULE_INSTANCE_TYPE.RECURRING);
        this.subscriptionBris = this.findAllBillerItemFromType(BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION);
        this.usageBris = this.findAllBillerItemFromType(BILLER_RULE_INSTANCE_TYPE.USAGE);

        this.usageOrEntitlementBris = this.usageBris.concat(this.entitlementBris);

        if (this.isUsageOrEntitlement) {
            this.billerRuleInstances = this.usageOrEntitlementBris;
        } else if (this.isOneTimeOption) {
            this.billerRuleInstances = this.getBillerRuleInstancesWithDiscountInfo(this.oneTimeBris, this.option, this.currencyCode);
        } else if (this.recurringBris.length) {
            this.billerRuleInstances = this.getBillerRuleInstancesWithDiscountInfo(this.recurringBris, this.option, this.currencyCode);
        } else {
            this.billerRuleInstances = this.subscriptionBris;
        }

        if (this.option.LifeCycleDetail !== undefined && Object.keys(this.option.LifeCycleDetail).length) {
            this.actions.retrieveOfferingsMetadata([this.option.OfferingId]);
        }
        this.lifeCycleDetailsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.lifeCycleDetailsPopupConfigApi = api;
            }
        };
        if (this.option.SubscriberProductServiceRelationship && this.option.SubscriberProductServiceRelationship.ServiceRelationshipType === PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.CONSUMES
            && this.option.SubscriberProductServiceRelationship.ProviderPricingPlanId) {
            this.actions.retrieveOfferingsMetadata([this.option.SubscriberProductServiceRelationship.ProviderOfferingId.Value]).then(() => {
                this.offeringMetadataLoaded = true;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    closeLifeCycleDetailsPopup() {
        this.lifeCycleDialogOpened = false;
        this.lifeCycleDetailsPopupConfigApi.close();
    }

    openLifeCycleDetailsPopup(option) {
        this.actions.updateLifeCycleDetails({
            offerId: option.OfferingId,
            lifeCycleId: option.LifeCycleDetail.LifeCycleId
        });
        this.pricingPlanInLifeCycleForOffers = this.state.pricingPlanInLifeCycleForOffers;
        this.lifeCyclePricingPlanDetails = Object.assign({}, ...this.state.lifeCyclePricingPlanDetails);
        this.lifeCycleDialogOpened = true;
        this.currentLifeCycle = option.PricingPlanId;
        this.$timeout(() => {
            this.lifeCycleDetailsPopupConfigApi.open();
        });
    }

    getBillerRuleInstancesWithDiscountInfo(bris, option, currencyCode) {
        const brisWithDiscountInfo = [];
        bris.forEach((bri) => {
            const briDiscounts = (option.Discounts || []).filter((discount) => {
                return (discount.BillerRuleConfigurationId === bri.BillerRuleConfigurationId) && discount.IsActive;
            }).map((discount) => {
                return getDiscountTooltipModel(discount, currencyCode, this.state.discountsMetadataMap);
            });
            brisWithDiscountInfo.push(Object.assign({}, bri, {
                discounts: briDiscounts
            }));
        });
        return brisWithDiscountInfo;
    }

    getItemQuantityBulk(bri) {
        // NOTE: `BulkQuantity` on the API resonse also contains the following for the time being:
        // - the non-bulk quantity
        // - the eBRI/uBRI quantity when there's no corresponding oRBI or rBRI
        if (this.hasBillerItemBulkPrices && !this.isUsageOrEntitlement) {
            const billerItemBulkPrice = this.getBillerItemBulkPrice(bri);
            return billerItemBulkPrice.Quantity || 0;
        }

        return this.isUsageOrEntitlement &&
            this.option.BillerRulePrices && this.option.BillerRulePrices.length ?
            this.option.BillerRulePrices[0].Quantity : this.option.BulkQuantity;
    }

    getItemAmountBulk(bri) {
        if (this.hasBillerItemBulkPrices) {
            const bulkAmount = this.getBillerItemBulkPrice(bri);
            return pathOr(0, ['Amount'], bulkAmount);
        }
    }

    getItemTotalAmountBulk() {
        // NOTE: `TotalAmount` will not be present on `BillerRulePrices` until the order is completed, so `Amount` is used instead
        let totalAmount = 0;

        if (this.hasBillerItemBulkPrices) {
            const billerItemBulkPrice = this.option.BillerRulePrices.find((bulkPrice) => {
                return has('TotalAmount', bulkPrice) && this.billerRuleInstances.find((bri) => {
                    return bulkPrice.BillerRuleConfigurationId === bri.BillerRuleConfigurationId;
                });
            });

            if (billerItemBulkPrice) {
                totalAmount = undefined !== billerItemBulkPrice.TotalAmount ?
                    billerItemBulkPrice.TotalAmount : billerItemBulkPrice.Amount || 0;
            }
        }

        return totalAmount;
    }

    getItemBulkType(bri) {
        if (this.hasBillerItemBulkPrices) {
            const price = this.getBillerItemBulkPrice(bri);
            return price ? price.BulkType : 0;
        }
    }

    quickEdit() {
        this.launchQuickEdit();
    }

    quickRemove() {
        if (this.launchQuickRemove) {
            this.launchQuickRemove();
        } else {
            this.launchQuickEdit();
        }
    }

    showHoverActions() {
        return this.hasAdminAccess && this.enableQuickEdit() && !this.offerHasPendingChange;
    }

    getItemNameBulk(bri) {
        return this.getBillerTypeAmount(bri).BillerRuleConfigurationName;
    }

    findAllBillerItemFromType(type) {
        return this.billerItemBulkPricesOrBillerTypeAmounts.filter((billerItemBulkPriceOrBillerTypeAmount) => {
            return billerItemBulkPriceOrBillerTypeAmount.Type === type;
        });
    }

    getBillerTypeAmount(bri) {
        return this.option.BillerTypeAmounts.find((billerTypeAmount) => {
            return billerTypeAmount.BillerRuleConfigurationId === bri.BillerRuleConfigurationId;
        });
    }

    getBillerItemBulkPrice(bri) {
        return this.option.BillerRulePrices.find((bulkPrice) => {
            return bulkPrice.BillerRuleConfigurationId === bri.BillerRuleConfigurationId;
        });
    }

    discountTooltipText(discounts) {
        return `${discounts.length} ${i18n.translate(discounts.length === 1 ? CustomerCareLocaleKeys.DISCOUNT : CustomerCareLocaleKeys.DISCOUNTS_LABEL)}`;
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}
