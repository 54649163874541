import {
    ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS,
    SUBSCRIBER_ANONYMIZATION_ACCESS
} from '../../security.attributes';
import {BILLING_CONFIGURATION_TYPES} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.action.types';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {createSelector} from 'reselect';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import mapKeys from 'lodash/mapKeys';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import LocaleKeys from '../../locales/keys';
import {
    MetadataCodeSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {BillingConfigurationSelector} from 'invision-core/src/components/metadata/billingConfiguration/billing.configuration.selectors';
import pathOr from 'ramda/src/pathOr';
import propEq from 'ramda/src/propEq';
import {taxLevelExemptions as taxLevelExemptionsHelper} from '../createCustomer/tax.model.helper';
import {UserSecurityRolesSelector,
    UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
const WHOLESALE_FLAG = 'WholesaleFlag';
import {
    CUSTOMER_CATEGORY,
    INVOICE_DELIVERY_METHODS,
    OPT_OUT_OPTIONS
} from './create.customer.popup.constants';
import {
    SelectedCustomerSelector,
    SelectedCustomerCategorySelector,
    CurrentCustomerSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    HasConvergentBillerIdSelector
} from '../../reducers/selectors/customer.selectors';
import {
    PROFILE_OPTION_REACTIVATE_ID,
    PROFILE_OPTION_SCHEDULE_ANONYMIZATION_ID,
    PROFILE_OPTION_ANONYMIZE_IMMEDIATELY_ID,
    PROFILE_REMOVED_STATUS
} from '../customer/profile/profile.constants';
import {
    CUSTOMER_STATE,
    PROSPECT_LITE_CUSTOMER
} from '../shared/constants/customer.constants';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {
    findByKey,
    findByValue
} from 'invision-core/src/utilities/ramda.helper';
import {EMPTY_ARRAY} from '../../reducers/constants/common.constants';
import {INVOICE_DETAIL_LEVEL} from '../../customercare.constants';

export const CurrentCustomerProfileMoreMenuItemsSelector = createSelector([
    CurrentCustomerSelector,
    IsDbss,
    UserSecurityAttributesSelector
],
(currentCustomer, isDbss, userSecurityAttributes) => {
    const moreOptionsMenuItems = [];
    const PROFILE_MORE_MENU_SCHEDULE_ANONYMIZATION_OPTION = {
        id: PROFILE_OPTION_SCHEDULE_ANONYMIZATION_ID,
        title: i18n.translate(LocaleKeys.PROFILE_PAGE.SCHEDULE_FOR_ANONYMIZATION)
    };

    const PROFILE_MORE_MENU_ANONYMIZE_IMMEDIATELY_OPTION = {
        id: PROFILE_OPTION_ANONYMIZE_IMMEDIATELY_ID,
        title: i18n.translate(LocaleKeys.PROFILE_PAGE.ANONYMIZE_IMMEDIATELY)
    };

    const PROFILE_MORE_MENU_REACTIVATE_OPTION = {
        id: PROFILE_OPTION_REACTIVATE_ID,
        title: i18n.translate(LocaleKeys.REACTIVATE)
    };

    if (currentCustomer && currentCustomer.Status && userSecurityAttributes) {
        if (!currentCustomer.CompletedAnonymization && shouldDisplayAnonymizeOptions(isDbss, currentCustomer.State)) {
            if (!currentCustomer.ScheduledAnonymization && hasAccess(userSecurityAttributes, SUBSCRIBER_ANONYMIZATION_ACCESS)) {
                moreOptionsMenuItems.push(PROFILE_MORE_MENU_SCHEDULE_ANONYMIZATION_OPTION);
            }

            if (hasAccess(userSecurityAttributes, ANONYMIZE_SUBSCRIBER_IMMEDIATELY_ACCESS)) {
                moreOptionsMenuItems.push(PROFILE_MORE_MENU_ANONYMIZE_IMMEDIATELY_OPTION);
            }
        }

        if (!currentCustomer.CompletedAnonymization && !isDbss && currentCustomer.Status && currentCustomer.Status === PROFILE_REMOVED_STATUS) {
            moreOptionsMenuItems.push(PROFILE_MORE_MENU_REACTIVATE_OPTION);
        }
    }
    return moreOptionsMenuItems;
}
);


export const InvoiceDisplaySelector = createSelector(
    [BillingConfigurationSelector],
    (billingConfiguration) => {
        const selectOption = {
            Name: i18n.translate(CoreLocaleKeys.SELECT),
            Value: undefined // must be undefined for required validation to work
        };
        const billingConfigurationOption = billingConfiguration.asMutable({
            deep: true
        });
        const invoiceDisplays = pathOr([], [BILLING_CONFIGURATION_TYPES.INVOICE_DISPLAY_CODE, 'items'], billingConfigurationOption);

        return [selectOption].concat(invoiceDisplays.map((item) => {
            return {
                Name: item.DisplayName,
                Value: item.Name
            };
        }));
    }
);

export const BillCyclesSelector = createSelector(
    [BillingConfigurationSelector],
    (billingConfiguration) => {
        const selectOption = {
            Name: i18n.translate(CoreLocaleKeys.SELECT),
            Value: undefined // must be undefined for required validation to work
        };
        const billingConfigurationOption = billingConfiguration.asMutable({
            deep: true
        });
        const billCycles = pathOr([], [BILLING_CONFIGURATION_TYPES.BILL_CYCLES_CODE, 'items'], billingConfigurationOption);

        return [selectOption].concat(billCycles.map((item) => {
            return {
                Name: item.DisplayName,
                Value: item.Name
            };
        }));
    }
);

export const TaxCategoryExemptionsSelector = createSelector(
    [BillingConfigurationSelector],
    (billingConfiguration) => {
        const taxCategoryExemptions = pathOr([], [BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_CATEGORY_CODE, 'items'], billingConfiguration);

        return taxCategoryExemptions.map((item) => {
            return {
                id: item.Name,
                selected: false,
                text: item.DisplayName
            };
        });
    }
);

export const TaxExemptionTypeCodesSelector = createSelector(
    [BillingConfigurationSelector],
    (billingConfiguration) => {
        const taxExemptionTypeCodes = pathOr([], [BILLING_CONFIGURATION_TYPES.TAX_EXEMPTION_TYPE_CODE, 'items'], billingConfiguration.asMutable({
            deep: true
        }));

        return taxExemptionTypeCodes.sort((a, b) => {
            return b.DisplayName > a.DisplayName ? -1: 1;
        }).map((item) => {
            return {
                Id: item.Name,
                Value: item.DisplayName
            };
        });

    }
);

export const NotificationPreferencesSelector = createImmutableSelector(
    [
        MetadataCodeTypeSelector(CODES.NotificationCategories),
        SelectedCustomerSelector,
    ],
    (notificationCategoryCodeTable, selectedCustomer) => {
        if (notificationCategoryCodeTable?.length) {
            return notificationCategoryCodeTable.reduce((acc, notificationCategory) => {
                let optOutOptions = [];
                const selectedCustomerOptOutOptions =
                    selectedCustomer?.data.NotificationCategoryPreferences?.find((notificationCategoryType) => {
                        return notificationCategoryType.CustomerNotificationCategoryId === +notificationCategory.Value;
                    })?.OptOutOptions;

                if (selectedCustomerOptOutOptions?.length) {
                    optOutOptions = selectedCustomerOptOutOptions.map((option) => {
                        if (option === OPT_OUT_OPTIONS.OPT_OUT_EMAIL) {
                            return {
                                label: i18n.translate(LocaleKeys.NOTIFICATION_PREFERENCES_TYPE.OPT_OUT_EMAIL),
                                value: OPT_OUT_OPTIONS.OPT_OUT_EMAIL,
                            };
                        }
                        if (option === OPT_OUT_OPTIONS.OPT_OUT_SMS) {
                            return {
                                label: i18n.translate(LocaleKeys.NOTIFICATION_PREFERENCES_TYPE.OPT_OUT_SMS),
                                value: OPT_OUT_OPTIONS.OPT_OUT_SMS,
                            };
                        }
                    });
                } else {
                    optOutOptions = [{
                        label: i18n.translate(LocaleKeys.CREATE_CUSTOMER_FORM.CONTACT_PREFERENCES.CONTACT_METHODS_EMPTY_PLACEHOLDER),
                        value: null
                    }];
                }

                acc.push({
                    customerNotificationCategoryId: notificationCategory.Value,
                    customerNotificationCategoryName: notificationCategory.Name,
                    optOutOptions
                });
                return acc;
            }, []);
        }
        return [];
    }
);

export const TaxAssociationCodesSelector = createImmutableSelector(
    [BillingConfigurationSelector],
    (billingConfiguration) => {
        const taxAssociationCodes = pathOr([], [BILLING_CONFIGURATION_TYPES.TAX_ASSOCICATION_CODE, 'items'], billingConfiguration.asMutable({
            deep: true
        }));
        return taxAssociationCodes.sort((a, b) => {
            return b.DisplayName > a.DisplayName ? -1: 1;
        }).map((item) => {
            return {
                Id: item.Name,
                Value: item.DisplayName
            };
        });

    }
);
export const HomeCountriesStatesSelector = createImmutableSelector(
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion),
    (addressStateProvinceRegion) => {
        return addressStateProvinceRegion.map((item) => {
            return {
                Value: item.Name,
                Id: item.Value
            };
        });
    }
);
export const TaxLevelCodesSelector = createSelector(
    MetadataCodeTypeSelector(CODES.TaxLevel),
    (taxLevelCodes) => {
        return taxLevelCodes.map((item) => {
            return {
                Value: item.Name,
                Id: item.Value
            };
        });
    }
);

const getValueFromAdditionalProperty = (key, object) => {
    return find(propEq(key, 'Key'))(object.AdditionalProperties).Value;
};

const IsNewCustomerSelector = (state, isNewCustomer) => {
    return isNewCustomer;
};

export const SubscriberTypeRoleSelector = createSelector(
    MetadataCodeTypeSelector(CODES.SubscriberTypeSecurityRole),
    (subscriberTypeRole) => {
        return subscriberTypeRole.filter((item) => {
            return getBoolOrDefault(getValueFromAdditionalProperty('is_enabled', item));
        }).map((item) => {
            return {
                subscriberType: getValueFromAdditionalProperty('subscriber_type_code', item),
                role: getValueFromAdditionalProperty('security_role_id', item)
            };
        });
    }
);

export const SubscriberTypeFilterByUserRole  = createSelector(
    [
        MetadataCodeTypeSelector(CODES.SubscriberType),
        SubscriberTypeRoleSelector,
        UserSecurityRolesSelector,
        CurrentCustomerSelector,
        IsNewCustomerSelector
    ],
    (subscriberTypes, subscriberTypeRoles, roles, currentCustomer, isNewCustomer) => {
        if (subscriberTypeRoles.length && roles.length && subscriberTypes.length) {
            let returnArray = subscriberTypes.filter((subscriberType) => {
                return !subscriberTypeRoles.some((subscriberTypeRole) => {
                    return subscriberTypeRole.subscriberType === subscriberType.Value;
                });
            });

            const filteredElements = subscriberTypes.filter((subscriberType) => {
                return subscriberTypeRoles.some((subscriberTypeRole) => {
                    return subscriberTypeRole.subscriberType === subscriberType.Value &&
                        roles.includes(parseInt(subscriberTypeRole.role));
                });
            });
            if (filteredElements && filteredElements.length) {
                returnArray = returnArray.concat(filteredElements);
            }

            if (!isNewCustomer && !returnArray.some((subscriberType) => {
                return subscriberType.Value === currentCustomer.SubscriberTypeCode;
            })) {
                returnArray = returnArray.concat(subscriberTypes.filter((subscriberType) => {
                    return subscriberType.Value === currentCustomer.SubscriberTypeCode;
                }));
            }

            return returnArray;
        } else {
            return subscriberTypes && subscriberTypes.length ? subscriberTypes : [];
        }
    }
);

export const SubscriberTypesWithItemsFilterByUserRole  = createSelector(
    [
        SubscriberTypeFilterByUserRole
    ],
    (subscriberTypes) => {
        return  {
            isLoaded: (!!subscriberTypes && !!subscriberTypes.length),
            items: subscriberTypes
        };
    }
);

export const CustomerTypesByCategoryOptionsSelector = createImmutableSelector(
    [
        SubscriberTypeFilterByUserRole,
        MetadataCodeSelector(CODES.SubscriberCategoryDefaultSubscriberType)
    ],
    (subscriberTypes, subscriberCategoryDefaultSubscriberType) => {
        const defaultSubscriberTypes = pathOr([], ['items'], subscriberCategoryDefaultSubscriberType).map((object) => {
            return {
                subscriberCategory: getValueFromAdditionalProperty('subscriber_category_code', object),
                defaultSubscriberType: getValueFromAdditionalProperty('default_subscriber_type_code', object)
            };
        });

        return subscriberTypes.reduce((typeOptions, type) => {
            const categoryCode = getValueFromAdditionalProperty('subscriber_category_code', type);
            const defaultSubscriberTypeCode = pathOr(null, ['defaultSubscriberType'], defaultSubscriberTypes.find((item) => {
                return item.subscriberCategory === categoryCode;
            }));
            if (!typeOptions[categoryCode]) {
                typeOptions[categoryCode] = [];
            }
            typeOptions[categoryCode].push({
                name: type.Name,
                value: type.Value,
                default: type.Value === defaultSubscriberTypeCode
            });
            return typeOptions;
        }, {});
    }
);

export const CustomerTypeFilteredSelector = createSelector(
    [
        SubscriberTypesWithItemsFilterByUserRole,
        MetadataCodeSelector(CODES.SubscriberCategoryDefaultSubscriberType),
        SelectedCustomerCategorySelector,
        IsNewCustomerSelector,
        CurrentCustomerIsExternallyManagedARSelector,
        HasConvergentBillerIdSelector
    ],
    (subscriberType, subscriberCategoryDefaultSubscriberType, customerCategory, isNewCustomer, currentCustomerIsExternallyManagedAR, hasConvergentBillerId) => {

        let subscriberTypes = [];
        if (!isNewCustomer && hasConvergentBillerId) {
            subscriberTypes = subscriberType.items.filter((item) => {
                if (currentCustomerIsExternallyManagedAR) {
                    return !!(item && item.AdditionalProperties.find(propEq('externally_managed_accounts_receivable', 'Key')).Value === 'True');
                } else {
                    return !!(item && item.AdditionalProperties.find(propEq('externally_managed_accounts_receivable', 'Key')).Value !== 'True');
                }
            });
        } else if (subscriberType) {
            subscriberTypes = subscriberType.items;
        }

        // Defaulting to residential otherwise commercial, and returning the value in a string
        // to match the AdditionalProperty value
        const category = customerCategory === CUSTOMER_CATEGORY.COMMERCIAL ? '1' : '0';

        if (pathOr(false, ['items', 'length'], subscriberCategoryDefaultSubscriberType) > 0) {

            const subscriberCategoryDefaultSubscriberTypeOptions = subscriberCategoryDefaultSubscriberType.items.map((object) => {
                return {
                    subscriberCategory: getValueFromAdditionalProperty('subscriber_category_code', object),
                    defaultSubscriberType: getValueFromAdditionalProperty('default_subscriber_type_code', object)
                };
            });
            const filterCategoryDefault = filter(propEq(category, 'subscriberCategory'), subscriberCategoryDefaultSubscriberTypeOptions);

            const categoryDefault = pathOr(null, ['defaultSubscriberType'], filterCategoryDefault[0]);

            let subscriberTypeOptions;

            if (categoryDefault) {
                subscriberTypeOptions = subscriberTypes.map((object) => {
                    return {
                        subscriberCategory: getValueFromAdditionalProperty('subscriber_category_code', object),
                        name: object.Name,
                        value: object.Value,
                        default: (object.Value === categoryDefault)
                    };
                });
            } else {
                subscriberTypeOptions = [{
                    name: i18n.translate(CoreLocaleKeys.SELECT),
                    value: null,
                    subscriberCategory: category,
                    default: true
                }].concat(subscriberTypes.map((object) => {
                    return {
                        subscriberCategory: getValueFromAdditionalProperty('subscriber_category_code', object),
                        name: object.Name,
                        value: object.Value,
                        default: false
                    };
                }));
            }
            return filter(propEq(category, 'subscriberCategory'), subscriberTypeOptions);
        } else {
            return [{
                name: i18n.translate(CoreLocaleKeys.SELECT),
                value: null,
                default: true
            }].concat(subscriberTypes.filter((subscriberType) => {
                return category === getValueFromAdditionalProperty('subscriber_category_code', subscriberType);
            }).map((object) => {
                return {
                    name: object.Name,
                    value: object.Value,
                    default: false
                };
            }));
        }
    }
);

export const DefaultCustomerTypeSelector = createSelector(
    [CustomerTypeFilteredSelector],
    (customerTypeOptions) => {
        const defaultValue = pathOr(null, ['value'], find(propEq(true, 'default'))(customerTypeOptions));
        return defaultValue ? defaultValue : pathOr(null, ['value'], customerTypeOptions[0]);
    }
);

export const CustomerTaxCategoryExemptionsSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return pathOr([], ['TaxExemptionCategories'], currentCustomer);
    }
);

export const CustomerTaxLevelExemptionsSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return pathOr([], ['TaxExemption'], currentCustomer);
    }
);

export const TaxLevelExemptionsSelector = createSelector(
    [CustomerTaxLevelExemptionsSelector],
    (taxLevels) => {
        const selectedTaxExemptions = [];

        for (const key in taxLevels) {
            // eslint-disable-next-line no-prototype-builtins
            if (taxLevels.hasOwnProperty(key) && taxLevels[key] === true && key !== WHOLESALE_FLAG) {
                selectedTaxExemptions.push(key);
            }
        }

        return selectedTaxExemptions;
    }
);

export const SelectedTaxLevelExemptionsSelector = createSelector(
    [TaxLevelExemptionsSelector],
    (taxLevelExemptions) => {
        return taxLevelExemptionsHelper().filter((exemption) => {
            return taxLevelExemptions.includes(exemption.value);
        });
    }
);

export const ContactMethodOptionsSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.ContactMethod),
        MetadataCodeTypeSelector(CODES.ContactMapping)
    ],
    (contactMethods, eventMethodMap) => {
        const invoiceMethods =  filterByInvoiceEventType(eventMethodMap);
        return contactMethods.filter((item) => {
            return invoiceMethods.includes(item.Value);
        });
    }
);

const SUBSCRIBER_IDENTIFICATIONS_KEYS = {
    IS_COUNTRY_STATE_REQUIRED: 'is_country_and_state_required',
    IS_COUNTRY_STATE_VISIBLE: 'is_country_and_state_visible',
    IS_LUHN: 'is_luhn',
    IS_PRIMARY_IDENTIFICATION: 'is_primary_identification',
    REGEX: 'regex',
    REGEX_CODE: 'regex_code',
    USE_CONFIGURED_COUNTRIES: 'use_configured_countries',
    VALIDATION_TYPE_CODE: 'subscriber_identification_validation_type_code'
};

export const SubscriberIdentificationsSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.SubscriberIdentifications),
        MetadataCodeTypeSelector(CODES.SubscriberValidationTypes),
        MetadataCodeTypeSelector(CODES.RegularExpression),
        MetadataCodeTypeSelector(CODES.AddressCountry),
        MetadataCodeTypeSelector(CODES.AllCountry),
        SelectedCustomerSelector
    ],
    (subscriberIdentifications, validationTypes, regularExpressions, configuredCountries, allCountries, customer) => {
        return subscriberIdentifications.map((subscriberIdentification) => {
            const isPrimary = findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.IS_PRIMARY_IDENTIFICATION, subscriberIdentification.AdditionalProperties);
            const isCountryStateRequired = findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.IS_COUNTRY_STATE_REQUIRED, subscriberIdentification.AdditionalProperties);
            const isCountryStateVisible = getBoolOrDefault(
                (findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.IS_COUNTRY_STATE_VISIBLE, subscriberIdentification.AdditionalProperties) || isCountryStateRequired).Value,
                false
            );
            const useConfiguredCountries = findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.USE_CONFIGURED_COUNTRIES, subscriberIdentification.AdditionalProperties) || true;
            const subscriberValidationType = findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.VALIDATION_TYPE_CODE, subscriberIdentification.AdditionalProperties);

            const validationType = subscriberValidationType ? findByValue(subscriberValidationType.Value, validationTypes) : null;
            const validationTypeLuhn = validationType ? findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.IS_LUHN, validationType.AdditionalProperties) : null;
            const validationTypeRegexCode = validationType ? findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.REGEX_CODE, validationType.AdditionalProperties) : null;
            const regularExpression = validationTypeRegexCode ? findByValue(validationTypeRegexCode.Value, regularExpressions) : null;
            const regularExpressionValue = regularExpression ? findByKey(SUBSCRIBER_IDENTIFICATIONS_KEYS.REGEX, regularExpression.AdditionalProperties) : null;

            const isLuhn = getBoolOrDefault(validationTypeLuhn ? validationTypeLuhn.Value : null, false);

            return {
                Countries: isCountryStateVisible ?
                    subscriberIdentificationCountryHelper(convertStringToNumber(subscriberIdentification.Value), configuredCountries, allCountries, customer) :
                    EMPTY_ARRAY,
                IsPrimary: getBoolOrDefault(isPrimary.Value, false),
                IsCountryStateRequired: getBoolOrDefault(isCountryStateRequired.Value, false),
                IsCountryStateVisible: isCountryStateVisible,
                Name: subscriberIdentification.Name,
                Regex: !isLuhn && regularExpressionValue ? regularExpressionValue.Value : null,
                UseConfiguredCountries: getBoolOrDefault(useConfiguredCountries.Value, false),
                Value: convertStringToNumber(subscriberIdentification.Value)
            };
        });
    }
);

const subscriberIdentificationCountryHelper = (subscriberIdentificationTypeCode, configuredCountries, allCountries, customer) => {
    const subscriberIdentification = (customer.data.SubscriberIdentifications || []).find((identification) => {
        return identification.SubscriberIdentificationTypeCode === subscriberIdentificationTypeCode;
    });
    if (subscriberIdentification && allCountries.length) {
        const selectedCountry = allCountries.find((country) => {
            return country.Value === subscriberIdentification.Country;
        });
        const existingCountryInConfiguredCountries = configuredCountries.find((country) => {
            return country.Value === selectedCountry.Value;
        });

        return mapCountrykeysHelper(existingCountryInConfiguredCountries ? configuredCountries : configuredCountries.concat(selectedCountry));
    } else {
        return mapCountrykeysHelper(configuredCountries);
    }
};

const mapCountrykeysHelper = (countries) => {
    const map = {
        AdditionalProperties : 'AdditionalProperties',
        Global : 'Global',
        Name: 'label',
        Value: 'value'
    };

    return countries.map((country) => {
        return mapKeys(country, (value, key) => {
            return map[key];
        });
    });
};

const filterByInvoiceEventType = (eventMethodMap) => {
    const invoiceContactMethods =  eventMethodMap.filter((option) => {
        const invoiceTypeCode = option.AdditionalProperties.find(propEq('contact_event_type_code', 'Key'));
        return invoiceTypeCode.Value === INVOICE_DELIVERY_METHODS.INVOICE;
    });
    return invoiceContactMethods.map((item) => {
        return item.AdditionalProperties.find(propEq('contact_method_code', 'Key')).Value;
    });
};

const shouldDisplayAnonymizeOptions = (isDbss, customerState) => {
    let displayAnonymizeOptions = true;
    if (isDbss) {
        const coercedState = convertStringToNumber(customerState);
        displayAnonymizeOptions = coercedState === CUSTOMER_STATE.PROSPECT || coercedState === CUSTOMER_STATE.DISCONNECTED || coercedState === PROSPECT_LITE_CUSTOMER;
    }

    return displayAnonymizeOptions;
};
