import CustomerCareKeys from '../../../../../../locales/keys';

class DevicePaymentItemController {
    constructor() {
        Object.assign(this, {
            customerCareKeys: CustomerCareKeys
        });
    }

    handleDownPaymentChanged() {
        if (this.onDownpaymentChanged()) {
            this.onDownpaymentChanged()(this.item);
        }
    }
}

export default {
    template: require('./device.payment.item.html'),
    controller: DevicePaymentItemController,
    bindings: {
        isEditable: '<?',
        item: '<',
        onDownpaymentChanged: '&',
        elementIdPrefix: '<?'
    }
};
