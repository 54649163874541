// !TODO: Merge with constants from Make a Payment page
import {GENERAL_STATUSES} from '../shared/constants/general.status.constants';

export const PAYMENT_METHOD_STATUSES = GENERAL_STATUSES;

export const BLANK_PAYMENT_INSTRUMENT = {
    Id: 0,
    CreditCard: {},
    ExternalGiftCard: {},
    GiftCard: {},
    BillingAddress: {
        Id: 0,
        Country: null,
        State: null
    }
};
