import {i18n} from 'invision-core';
import LocaleKeys from '../../../locales/keys';
import {
    NOTIFICATION_TIME_LENGTH,
    REGEXES
} from '../../../customercare.constants';
import {
    submitResendGiftCardNotification
} from '../../../reducers/actions/giftcard.result.actions';
import {
    CurrentGiftCardSelector,
    GiftCardNotificationErrorSelector,
    IsResendingGiftCardNotificationSelector
} from '../../../reducers/selectors/giftcard.result.selectors';

class ResendDialogComponent {
    constructor($ngRedux, uiNotificationService) {
        this.$ngRedux = $ngRedux;
        this.uiNotificationService = uiNotificationService;
        this.localeKeys = LocaleKeys;
        this.viewModel = {
            emailRegex: REGEXES.EMAIL,
            errorMessages: [],
            resendGiftCardModel: {
                Id : -1,
                RecipientEmail : '',
                RecipientName : '',
                SenderName : '',
                notifySender : true,
                message : ''
            }
        };
    }

    $onInit() {
        const controllerActions = {
            submitResendGiftCardNotification: submitResendGiftCardNotification
        };

        const mapStateToTarget = (store) => {
            return {
                giftCard: CurrentGiftCardSelector(store),
                resendGiftCardnotificationError: GiftCardNotificationErrorSelector(store),
                isResendingGiftCardNotification: IsResendingGiftCardNotificationSelector(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
    }

    $onChanges(changes) {
        if (changes.giftCard) {
            this.viewModel.resendGiftCardModel = Object.assign(this.viewModel.resendGiftCardModel, changes.giftCard.currentValue);
        }
    }

    handleClose(hasResent) {
        this.onClose()(hasResent);
    }

    onSubmitForm() {
        if (!this.giftCardResendForm.$invalid) {
            const promise = this.actions.submitResendGiftCardNotification(this.viewModel.resendGiftCardModel);
            promise.then(() => {
                this.uiNotificationService.success(
                    i18n.translate(this.localeKeys.GIFT_CARD_DETAILS.SUCCESS),
                    null,
                    {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    }
                );
            }).catch((error) => {
                this.uiNotificationService.error(
                    error.translatedMessage,
                    null,
                    {
                        timeOut: NOTIFICATION_TIME_LENGTH
                    }
                );
            }).then(() => {
                this.handleClose(true);
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    template: require('./resend.gift.card.dialog.html'),
    controller: ResendDialogComponent,
    bindings: {
        config: '<',
        onClose: '&',
        onSuccess: '&',
        giftCard: '<'
    }
};
