import {ACCEPTING_FOR} from '../../createCustomerPopup/create.customer.popup.constants';

export function getEditCustomerModel(customer, selectorData) {
    const p = customer.asMutable({
        deep: true
    });
    p.BirthDate = new Date(p.BirthDate);
    p.TermsAndConditionsAccepted = new Date(p.TermsAndConditionsAccepted);

    p.AccessCountry = p.AccessCountry === undefined ? null : p.AccessCountry;
    p.Gender = p.Gender === undefined ? null : p.Gender;
    p.HomeCountry = p.HomeCountry === undefined ? null : p.HomeCountry;
    p.IncomeLevelType = p.IncomeLevelType === undefined ? null : p.IncomeLevelType;
    p.Language = p.Language === undefined ? null : p.Language;
    p.SubscriberTypeCode = p.SubscriberTypeCode === undefined ? null : p.SubscriberTypeCode;
    p.SuffixName = p.SuffixName === undefined ? null : p.SuffixName;
    p.Title = p.Title === undefined ? null : p.Title;

    p.ConsentAcceptingMandatoryTerms = {
        IsAccepted: (selectorData.currentCustomerConsentsMandatoryUnaccepted.length === 0),
        Terms: selectorData.currentCustomerConsentsMandatoryUnaccepted
    };
    p.ConsentAcceptingFor = ACCEPTING_FOR.MYSELF;
    p.ConsentLegalGuardianEmail = p.GuardianEmail;
    p.ConsentAcceptingOptionalTerms = selectorData.currentCustomerConsentsOptional.filter(c => {
        // Only the optional consents intentionally accepted/rejected by the CSR
        return (c.isAccepted !== undefined);
    });

    return p;
}
