import CustomerCareKeys from '../../../../locales/keys';

function CustomerTabController() {
    this.customerCareKeys = CustomerCareKeys;
}

export default {
    template: require('./customer.tab.html'),
    bindings: {
        hasCustomerInfo: '<',
        formattedName: '<',
        addressLine1: '<',
        addressLine2: '<',
        formattedCityState: '<',
        postalCode: '<',
        preferredName: '<?',
        displayPreferredName: '<?',
        prefix: '<?',
        displayPrefix: '<?',
        hasSubTenants: '<',
        subTenant: '<'
    },
    controller: CustomerTabController,
    controllerAs: 'customerTabController'
};
