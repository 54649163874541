import LocaleKeys from '../../../../../locales/keys';
import {DISCOUNT_TYPE} from '../../../../../customercare.constants';

class DiscountsTabController {
    constructor() {
        this.localeKeys = LocaleKeys;
        this.discountType = DISCOUNT_TYPE;
    }

    onItemHover(index) {
        const discount = this.discounts[index];

        if ((discount && !discount.Removable) || !this.userCanRemove) {
            return;
        }

        this.hoverIndex = index;
    }

    removeDiscount(discount) {
        this.onRemoveDiscount()(discount);
    }

    isHoverIndex(index) {
        return index === this.hoverIndex;
    }
}

export default {
    controller: DiscountsTabController,
    template: require('./discounts.tab.html'),
    bindings: {
        discounts: '<',
        userCanRemove: '<',

        onRemoveDiscount: '&'
    }
};