import {i18n} from 'invision-core';
import {pathOr} from 'ramda';
import CustomerCareLocaleKeys from '../../../../../locales/keys';

class DirectDebitDetailController {
    constructor() {
        this.localeKeys = CustomerCareLocaleKeys;

        //https://qa-documentation.ascendon.tv/v17.4/codes.aspx?type=DirectDebitType
        this.directDebitTypes = {
            '1': i18n.translate(this.localeKeys.E_WALLET.DIRECT_DEBIT.TYPE_LABELS.AUSTRIA),
            '2': i18n.translate(this.localeKeys.E_WALLET.DIRECT_DEBIT.TYPE_LABELS.GERMANY),
            '3': i18n.translate(this.localeKeys.E_WALLET.DIRECT_DEBIT.TYPE_LABELS.THE_NETHERLANDS),
            '4': i18n.translate(this.localeKeys.E_WALLET.DIRECT_DEBIT.TYPE_LABELS.SPAIN),
            '5': i18n.translate(this.localeKeys.E_WALLET.DIRECT_DEBIT.TYPE_LABELS.UNITED_KINGDOM)
        };
    }

    $onChanges(changesObj) {
        if (changesObj.detailData) {
            const typeCode = pathOr('', ['Type'], changesObj.detailData.currentValue);
            this.typeLabel = this.directDebitTypes[typeCode];
        }
    }
}

export const DirectDebitDetailComponent = {
    bindings: {
        detailData: '<'
    },
    controller: DirectDebitDetailController,
    template: require('./direct.debit.detail.html')
};
