import LocaleKeys from '../../../../locales/keys';

class UpdateComponentController {
    constructor($timeout) {
        this.$timeout = $timeout;
        this.localeKeys = LocaleKeys;

        this.MIN_CODE_LENGTH = 6;
        this.MAX_CODE_LENGTH = 50;
        this.MAX_NICKNAME_LENGTH = 100;

        this.formLookup = {
            nickname: LocaleKeys.DEVICE.NICKNAME,
            rendezvousCode: LocaleKeys.DEVICE.RENDEZVOUS_CODE
        };

        this.formValidationLookup = {
            minlength: {
                rendezvousCode: this.MIN_CODE_LENGTH
            },
            maxlength: {
                nickname: this.MAX_NICKNAME_LENGTH,
                rendezvousCode: this.MAX_CODE_LENGTH
            }
        };
    }

    handleClose() {
        this.resetForm();
        this.onClose()();
    }

    handleFormSubmit() {
        if (this.updateDevicePopupForm.$valid) {
            this.onSubmit()();
        }
    }

    get isCreating() {
        return this.entity && this.entity.Id === undefined;
    }

    resetForm() {
        this.updateDevicePopupForm.$setPristine();
        this.updateDevicePopupForm.$setUntouched();
    }

    onFormChange() {
        this.onEntityChange()(this.entity);
    }

    $onChanges(changesObj) {
        if (changesObj.showDeviceForm && changesObj.showDeviceForm.currentValue) {
            this.resetForm();
        }
    }
}

export default {
    template: require('./update.device.popup.html'),
    controller: UpdateComponentController,
    bindings: {
        entity: '<',
        formErrors: '<',
        isLoading: '<',
        showDeviceForm: '<',

        onClose: '&',
        onEntityChange: '&',
        onSubmit: '&'
    }
};