import {VIEW_FUTURE_INVOICES_ACCESS} from '../../../security.attributes';
import {
    FUTURE_INVOICE_DETAILS_URL,
    FUTURE_INVOICE_URL
} from '../billing.payments.constants';

export function config($stateProvider) {
    $stateProvider.state(FUTURE_INVOICE_URL, {
        url: '/futureInvoices',
        template: '<billing-future-invoices  class="u-flexKeepHeight"></billing-future-invoices>',
        securityAttributes: [VIEW_FUTURE_INVOICES_ACCESS]
    })
        .state(FUTURE_INVOICE_DETAILS_URL, {
            url: '/futureInvoicesDetails/:invoiceId',
            template: '<billing-payments-detail></billing-payments-detail>',
            params: {
                isFutureInvoice: true
            }
        });
}
