import pathOr from 'ramda/src/pathOr';
import i18n from 'invision-core/src/components/i18n/i18n';
import MetadataActions from 'invision-core/src/components/metadata/metadata.actions';
import MetadataConstants from 'invision-core/src/components/metadata/metadata.constants';
import MetadataSelectors from 'invision-core/src/components/metadata/metadata.selectors';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import CustomerLocaleKeys from '../../../../../locales/keys';
import {
    HasAdminOrderingAccessSelector,
    HasOrderingAccessSelector
} from '../../../../../reducers/selectors/permissions.selectors';
import {
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    IsPIIDataAccessEnabledSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    CustomerActiveProductsDisplayItemsSelector,
    IsFetchingActiveProductsSelector
} from '../../../../../reducers/selectors/customer.products.selectors';
import {CREATE_PRODUCTS_ORDER_STATE_NAME} from '../../../../../reducers/constants/products.wizard.constants';
import {BILLER_RULE_INSTANCE_TYPE} from '../../../../../customercare.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {searchOrders} from '../../../../../reducers/actions/order.history.actions';

import {stateGo} from 'redux-ui-router';

const ACTIVE_PRODUCT_IMAGE_WIDTH = 60;

class ActiveProductsController {

    constructor($ngRedux, $state, $timeout) {
        Object.assign(this, {
            $ngRedux,
            $state,
            $timeout,
            customerCareKeys: CustomerLocaleKeys,
            discountToolTipTemplate: require('../../../../shared/tooltipTemplates/discount.tooltip.html'),
            selectedItems: []
        });
    }

    $onInit() {

        this.productImageWidth = ACTIVE_PRODUCT_IMAGE_WIDTH;

        this.addProductAction = {
            id: 'NEW_PRODUCT_ORDER',
            title: i18n.translate(CustomerLocaleKeys.NEW_PRODUCT_ORDER),
            callback: () => {
                this.goToAddProductWizard();
            },
            shouldShow: () => {
                return this.state.hasAdminOrderingAccess && this.state.isPIIDataAccessEnabled;
            }
        };

        const mapStateToTarget = (store) => {
            return {
                activeProducts: CustomerActiveProductsDisplayItemsSelector(store),
                businessUnitIsDbss: IsDbss(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                hasOrderingAccess: HasOrderingAccessSelector(store),
                hasAdminOrderingAccess: HasAdminOrderingAccessSelector(store),
                isFetchingActiveProducts: IsFetchingActiveProductsSelector(store),
                isPIIDataAccessEnabled: IsPIIDataAccessEnabledSelector(store),
                productClassifications: MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.ProductClassification, store),
                productClassificationsLoaded: MetadataSelectors.codes.MetadataCodeLoadedSelector(MetadataConstants.codes.ProductClassification, store),
                userSecurityAttributes: UserSecurityAttributesSelector(store)
            };
        };

        const controllerActions = {
            fetchCodeType: MetadataActions.codes.fetchCodeTypes,
            searchOrders,
            stateGo
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.hasOrderingAccess) {
            this.moreOptionsMenu = [
                this.addProductAction
            ].filter((menuItem) => {
                return menuItem.shouldShow();
            });

            if (!this.state.productClassificationsLoaded) {
                this.actions.fetchCodeType(MetadataConstants.codes.ProductClassification);
            }
        }

        this.isExpanded = true;

        this.showHoverActions = (item) => {
            return this.hasAdminAccess &&
                    (
                        !(item.Product && item.Product.Standalone && this.state.businessUnitIsDbss) ||
                        pathOr('', ['Product', 'ProductClassification'], item) === PRODUCT_CLASSIFICATIONS.AD_HOC
                    );
        };

        this.removeProductsPopupConfig = {
            onRegisterApi: ({api}) => {
                this.removeProductsPopupConfig = api;
            }
        };

        this.multiClassErrorPopup = {
            onRegisterApi: ({api}) => {
                this.multiClassErrorPopup = api;
            }
        };

        if (this.state.currentCustomerId) {
            this.actions.searchOrders(this.state.currentCustomerId);
        }
    }

    handleExpansion() {
        this.isExpanded = !this.isExpanded;
    }

    goToAddProductWizard() {
        this.actions.stateGo(CREATE_PRODUCTS_ORDER_STATE_NAME, {
            productsOnly: true
        });
    }

    onMenuItemSelected(selected) {
        return selected.item.callback();
    }

    getProductClassification(productClassificationId) {
        return pathOr(null, ['Name'], this.state.productClassifications.find((item) => {
            return parseInt(item.Value, 10) === productClassificationId;
        }));
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    getAdHocOverrideName(activeProduct) {
        const pricingPlanName = pathOr(null, ['PricingPlan', 'Name'], activeProduct);
        return pathOr(pricingPlanName, ['AdHocOverrideDetails', 'InvoiceText'], activeProduct);
    }

    getBriType(bri, productClass) {
        return productClass !== PRODUCT_CLASSIFICATIONS.AD_HOC ?
            bri.Name :
            i18n.translate(bri.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING ?
                this.customerCareKeys.BILLER_RULE_INSTANCE_TYPES.RECURRING :
                this.customerCareKeys.BILLER_RULE_INSTANCE_TYPES.ONETIME
            );
    }

    closeRemoveProductsPopup() {
        this.removeProductsPopupConfig.close();
        this.showRemoveProductsPopup = false;
        this.resetSelectedItems();
    }

    closeMultiClassErrorPopup() {
        this.multiClassErrorPopup.close();
    }

    openRemoveProductsPopup(item) {
        this.selectedItems.push(item);
        this.showRemoveProductsPopup = true;
        this.$timeout(() => {
            this.removeProductsPopupConfig.open();
        });
    }

    submitRemoveProductsPopup() {
        this.closeRemoveProductsPopup();
        this.$state.reload();
        this.resetSelectedItems();
    }

    resetSelectedItems() {
        this.selectedItems = [];
    }

    discountTooltipText(discounts) {
        return `${discounts.length} ${i18n.translate(discounts.length === 1 ? CustomerLocaleKeys.DISCOUNT : CustomerLocaleKeys.DISCOUNTS_LABEL)}`;
    }

}

export default {
    template: require('./active.products.html'),
    bindings: {
        customerId: '<',
        hasAdminAccess: '<'
    },
    controller: ActiveProductsController,
    controllerAs: 'ActiveProductsWidget'
};
