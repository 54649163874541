import __ from 'ramda/src/__';
import clone from 'ramda/src/clone';
import pathOr from 'ramda/src/pathOr';
import moment from 'moment';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import {CUSTOMER_URL} from '../../customer/customer.config';
import {DASHBOARD_ROUTE} from '../../../reducers/constants/dashboard.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {updateOverrideFlag} from '../../../reducers/actions/customer.addresses.actions';
import {formHasErrors} from 'invision-core/src/components/helpers/form.helper';
import {ROCWarningTranslatedMessageSelector} from 'invision-core/src/components/warnings/warnings.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeIntegerSelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesWithoutPlaceholderSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {subscriberOrderTypeEnum} from 'invision-core/src/utilities/subscriber.order.type.enum';
import {
    LastRouteSelector,
    PageSizePreferenceSelector
} from 'invision-core/src/components/session/session.selectors';
import {ActiveSubtenantsSelector} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../reducers/actions/address.component.actions';
import {
    CurrentCustomerSelector,
    EnableProspectLiteSelector
} from '../../../reducers/selectors/customer.selectors';
import {
    registerUnsavedChanges,
    unregisterUnsavedChanges
} from 'invision-core/src/components/unsavedChangesPrompt/actions';
import {getShippingMethodsByCountryCode} from 'invision-core/src/components/metadata/shippingMethods/shipping.methods.actions';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    DELIVERY_OPTIONS,
    DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE,
    INSUFFICIENT_CREDIT_AMOUNT_FAULT_CODE,
    INVENTORY_ITEM_RESERVATION_FAULT_CODE,
    NOTIFICATION_TIME_LENGTH,
    ORDER_SCENARIOS,
    RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION
} from '../../../customercare.constants';
import {
    AllAttributesAreValidSelector,
    AttributeServiceSelector,
    BackButtonTextSelector,
    CurrentActiveAttributeNameSelector,
    FilteredFormAttributeGroups,
    FilteredPhysicalAttributeGroups,
    FinancedProductGroupInCartSelector,
    FormAttributeGroups,
    FormPhysicalAttributeGroups,
    ForwardButtonTextSelector,
    GroupedCheckboxFilterOptionsSelector,
    HasRequiredAndUnavailableInventorySelector,
    IsContinueDisabledSelector,
    IsContinueShoppingShownSelector,
    IsFetchingAnyDataSelector,
    IsSaveOrderButtonDisabledSelector,
    IsShippingRequiredSelector,
    OfferOptionViewModelSelector,
    ShippingAddressDropdownOptionsSelector,
    ShippingAddressSelector,
    ShowNextButtonSelector,
    ShowPreviousButtonSelector,
    StepProgressBarViewModelSelector,
    SummaryTabViewModel,
    UnavailablePhysicalInventorySelector
} from './new.connect.wizard.selectors';
import {
    AllPhysicalInventoryItemsFromSelectedOffering,
    AreAnyInventoryUnavailableSelector,
    AttributeFormSubmittedSelector,
    CartContainsOffcycleOfferSelector,
    CartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector,
    CompletedDecisionsSelector,
    DecisionsHasErrorSelector,
    FilteredStoresOnSelectedRegionForStoresSearchModalSelector,
    HasOffCycleChargeRenewalSelector,
    IsAllSelectedPhysicalInventoryPresentAtSelectedStore,
    InventoryAvailabilitySelector,
    IsSelectedOfferingChangeable,
    IsSendToStoreButtonEnabledSelector,
    IsUpdatingCartSelector,
    PagesIsFetchingDataSelector,
    PaymentInstrumentRequiredSelector,
    SelectedDeliveryDecisionFromRocSelector,
    SelectedOfferingPhysicalInventoryAvailabilitySelector,
    SelectedOfferingServiceIdentifiersSelector,
    SelectedOfferingStoreAvailabilitySelector,
    ShoppingCartHaveOnlyStandaloneItemsSelector,
    ShoppingCartIsPostpaidSelector,
    ShoppingCartSelector,
    SubmittedOrderErrorSelector
} from '../../../reducers/selectors/selected.offering.order.selectors';
import {
    AddressDataSelector,
    PreventOverrideFlagSelector
} from '../../../reducers/selectors/customer.addresses.selectors';
import {
    beginNewConnectOrder,
    cancelOrder,
    clearQuoteAndSetSelectedOffer,
    createCustomer,
    createCustomerAndSaveOrder,
    createCustomerAndSendOrderToStore,
    fetchAttributes,
    fetchAttributesAndNavigate,
    fetchDecisionsAndNavigate,
    goToNextStep,
    goToPreviousStep,
    goToStep,
    navigateToFetchOffers,
    restoreOptionDefaultPricing,
    retrieveCandidateBillCyclesNewConnect,
    saveBulkPricing,
    saveOrder,
    searchAttributeInventory,
    sendOrderToStore,
    sendPortInOrItemReservation,
    setCurrentStepIsInvalid,
    setEditAttributeGroups,
    setEditCartAdditionalProperty,
    setEditCartAdditionalPropertyOnChange,
    setEditOfferAdditionalProperty,
    setEditOfferAdditionalPropertyOnChange,
    setEditOption,
    setEditOrderAdditionalProperty,
    setEditOrderAdditionalPropertyOnChange,
    setEditPhysicalAttributeGroups,
    setPaymentInfo,
    setSelectedFacetsEditCopy,
    setSelectedOfferEditCopy,
    setSelectedOfferingChargeTypesEditCopy,
    setSelectedTab,
    submitOrder,
    updateAttribute,
    updateBillCycle,
    updateCart,
    updateCustomer,
    updateAddress,
    updateCustomerAndSaveOrder,
    updateCustomerAndSendOrderToStore,
    updateCustomerAndSubmitOrder,
    updateCustomerInfo,
    updateCustomerInfoEditCopy,
    updateEditOptionPrice,
    updateInventorySelection,
    updateOfferPhysicalInventoryMakeAndModel,
    updateOfferPhysicalInventoryTypeAttribute,
    updatePaymentInfo,
    updatePortInRequest,
    updateSelectedServiceFeatures
} from '../../../reducers/actions/new.connect.wizard.actions';
import {
    calculateOfferingOrderQuote,
    resetAvailableInventoryInformation,
    retrieveOffCycleNextRenewalSchedules,
    retrieveOfferingOrderQuote,
    retrieveOrderQuote,
    saveInventoryStorePickupDetails,
    searchAvailableOfferings,
    searchInventoriesAvailability,
    setActiveAttributeFormName,
    setAttributeFormSubmitted,
    setDeliveryDecision,
    setDevicePaymentOptions,
    setInventoryRegionFilter,
    setInventoryStoreChangedFlag,
    setSelectedInventoryStores,
    updateServiceTaxLocationDecision
} from '../../../reducers/actions/offering.order.actions';
import {
    initializeAsMultiOfferWizard,
    setChangeOfferingId,
    setChangeOfferingInstanceId
} from '../../../reducers/actions/add.offer.wizard.actions';
import {setCreditCheckModalStatus, resetSubscriberCreditClassification} from '../../../reducers/actions/credit.check.actions';
import {
    AvailableCreditLimitFromQuoteSelector,
    AvailableFinanceCreditFromQuoteSelector,
    BillCycleSelector,
    BillingAddressStateRegionProvinceValueOptionsSelector,
    CartAdditionalPropertiesSelector,
    CartAdditionalPropertyListMapSelector,
    CompletedStepsSelector,
    ConvergentBillerAccountsSelector,
    CreatedCustomerSelector,
    CreatedCustomerCreditLimitSelector,
    CurrentCustomerIsExternallyManagedARSelector,
    CurrentStepIndexSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    CustomerInfoSelector,
    DeliveryOptionsByPricingPlanSelector,
    EditOptionSelector,
    EditOptionsSelector,
    EditCustomerInfoIsCategoryValidSelector,
    EditCustomerInfoSelector,
    EditCustomerInfoSubtenantSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    FormattedAttributesSelector,
    FormattedCityStateSelector,
    FormattedDeliveryDecisionsSelector,
    FormattedNameSelector,
    FormattedPhysicalAttributesSelector,
    GetSubtenantNameSelector,
    HasCustomerInfoSelector,
    InventoryAvailabilityRequestsSelector,
    IsAttributesStepSelector,
    IsCheckoutStepSelector,
    IsCreatingOrUpdatingCustomerSelector,
    IsCreditLimitBreachedFromQuoteSelector,
    IsCustomerStepSelector,
    IsDecisionsStepSelector,
    IsFetchingBillCyclesSelector,
    IsFetchingDataSelector,
    IsOffersStepSelector,
    IsPopoutableSelector,
    IsServiceabilityStepSelector,
    NewConnectCandidateBillCyclesSelector,
    NewConnectOfferDeliveryMethodOptionsSelector,
    OfferAdditionalPropertiesSelector,
    OfferAdditionalPropertyListMapSelector,
    OrderAdditionalPropertiesSelector,
    OrderAdditionalPropertyListMapSelector,
    PaymentInfoEditAutoPaySelector,
    PaymentInfoEditSelector,
    PaymentInfoSelector,
    QuoteHasInvoiceItemsSelector,
    SelectedOfferIdSelector,
    SelectedTabIndexSelector,
    ServiceTaxDecisionsSelector,
    TabPanelViewModelSelector,
    TaxLocationDecisionsByPricingPlanSelector,
    ViewLastAttemptErrorSelector
} from '../../../reducers/selectors/new.connect.wizard.selectors';
import {RecordPaymentInformationSelector} from '../../../reducers/selectors/customer.ewallet.selectors';
import {getPreviousStepIndex} from '../../../reducers/helpers/wizard.helper';
import {
    buildSegmentationContext,
    duplicatedServiceIdentifierAttributes
} from '../../../reducers/helpers/offer.ordering.wizard.helper';
import {searchContracts} from '../../../reducers/actions/contracts.list.actions';
import {
    CreatedOrderCurrencyCodeSelector,
    CreatedOrderSelector,
    DbssShippingEnabledRegionIdSelector,
    FullCartAsyncStatusSelector,
    InventoryStorePickupDetailsSelector,
    InventoryStoresTableDataSelector,
    IsFetchingSelector as IsFetchingOfferSelector,
    IsFullCartAsyncCompleteSelector,
    IsAsyncCompleteSelector,
    IsInventoryStoreChangedSelector,
    IsInventoryStoreConfiguredSelector,
    IsQuoteBypassedSelector,
    IsQuotingOfferSelector,
    IsSendingInventoryToStoreSelector,
    IsSubmittingOrderSelector,
    OffcycleRenewalScheduleSelector,
    OfferingOrderAsyncIdSelector,
    OfferingOrderQuoteSelector,
    QuoteCalculatedSelector,
    SelectedDeliveryDecisionSelector,
    SelectedInventoryRegionSelector,
    SelectedInventoryStoresDetailsSelector,
    SelectedOrderDetailsSelector,
    SelectedOrderExecutionOptionsSelector,
    SelectedShippingAddressAndMethodSelector,
    ViewLastAttemptErrorSelector as OfferingOrderLastAttemptErrorSelector
} from '../../../reducers/selectors/offering.order.selectors';
import {
    SelectedContractSelector
} from '../../../reducers/selectors/contract.selectors';
import {
    CurrentBusinessUnitHasSubtenants
} from '../../../reducers/selectors/subtenant.selectors';
import {
    ALL_STEPS_KEYS,
    STATE_OR_NAME
} from '../../../reducers/constants/new.connect.wizard.constants';
import {ContextOfferingAction} from '../../../reducers/constants/wizard.constants';
import CustomerCareKeys from '../../../locales/keys';
import {getErrorLookupObject} from '../../createCustomer/create.customer.constants';
import {searchSubtenants, updateSubscriberBillCycle} from '../../../reducers/actions/customer.actions';
import {ContractsSearchResultsSelector} from '../../../reducers/selectors/contracts.list.selectors';
import {CustomerCategories} from '../../createCustomerPopup/create.customer.popup.constants';
import {ADD_OFFER_ORDER_URL} from '../../customer/orders/create/offer/add.offer.wizard.module';
import {resetMobileNumberPortabilityModels} from '../../../reducers/actions/mobile.number.portability.actions';
import {CustomerTypesByCategoryOptionsSelector} from '../../createCustomerPopup/customer.form.dropdown.selectors';
import {
    ValidatedAddressesSelector,
    UnvalidatedAddressesSelector
} from '../../../reducers/selectors/address.component.selectors';
import {
    FullCartOfferingOrderQuotesSelector,
    FullCartOfferingOrderQuoteTotalSelector,
    FullCartOfferingOrderFeeQuoteSelector,
    FullCartOfferingOrderTaxQuoteSelector,
    FullCartOfferingOrderShippingQuoteSelector,
    ShowHideFullCartColumnsSelector
} from '../../../reducers/selectors/full.cart.selectors';
import {
    formatValidatedAddress,
    isAddressValidFromAddressResponse
} from '../../shared/contextualComponents/address/address.validator.helper';
import {OfferWizard} from '../../customer/orders/offer.wizard.common';
import {
    MINI_CART_MORE_ACTIONS,
    moreActionMenuItems
} from '../tabs/summaryTab/summary.tab.helper';
import {asyncRepeatInterval} from '../../../reducers/helpers/async.helper';
import {FormattedNewCustomerSubscriberIdentitiesSelector} from '../../../reducers/selectors/customer.information.selectors';

export class NewConnectWizardController extends OfferWizard {
    constructor($scope, $ngRedux, $log, $filter, $state, $location, $anchorScroll, $timeout, $interval, $element, formErrors, uiNotificationService, unsavedChangesPrompt) {
        super();
        Object.assign(this, {
            $anchorScroll,
            $element,
            $filter,
            $interval,
            $location,
            $log,
            $ngRedux,
            $scope,
            $state,
            $timeout,
            contextOfferingAction: ContextOfferingAction.ADD,
            CoreLocaleKeys,
            customerCareKeys: CustomerCareKeys,
            DELIVERY_OPTIONS,
            formErrors,
            isInventoryItemReservationFault: false,
            orderScenario: ORDER_SCENARIOS.NEW_CONNECT,
            overrideBypassQuoteConfiguration: false,
            retrieveOfferingOrderQuoteTimestamp: null,
            retrieveOrderQuoteTimestamp: null,
            showSendToStorePopup: false,
            STATE_OR_NAME,
            uiNotificationService,
            unsavedChangesPrompt,

            attemptNextStep: this.attemptNextStep.bind(this),
            changeOfferClick: this.changeOfferClick.bind(this),
            closeSearchInventoryPopupDialog: this.closeSearchInventoryPopupDialog.bind(this),
            closeSelectStorePopupDialog: this.closeSelectStorePopupDialog.bind(this),
            closeSendToStorePopup: this.closeSendToStorePopup.bind(this),
            closeViewFullCartPopupDialog: this.closeViewFullCartPopupDialog.bind(this),
            editOfferClick: this.editOfferClick.bind(this),
            fetchQuoteNewConnect: this.fetchQuoteNewConnect.bind(this),
            getAddressKey: this.getAddressKey.bind(this),
            goToPreviousStep: this.goToPreviousStep.bind(this),
            handleCloseCurrencyMismatchErrorPopup: this.handleCloseCurrencyMismatchErrorPopup.bind(this),
            handleCloseSaveOrderErrorPopup: this.handleCloseSaveOrderErrorPopup.bind(this),
            handleCloseSubmitOrderErrorPopup: this.handleCloseSubmitOrderErrorPopup.bind(this),
            handleInventoryAvailabilityContinue: this.handleInventoryAvailabilityContinue.bind(this),
            handleOpenSubmitOrderErrorPopup: this.handleOpenSubmitOrderErrorPopup.bind(this),
            handleResultUnsavedChangesPromptPopup: this.handleResultUnsavedChangesPromptPopup.bind(this),
            onCartAdditionalPropertyChanged: this.onCartAdditionalPropertyChanged.bind(this),
            onCartAdditionalPropertyListUpdated: this.onCartAdditionalPropertyListUpdated.bind(this),
            onInventoryRegionFilterChanged: this.onInventoryRegionFilterChanged.bind(this),
            onInventoryRegionFilterChangedForStoreSearch: this.onInventoryRegionFilterChangedForStoreSearch.bind(this),
            onMoreActionSelected: this.onMoreActionSelected.bind(this),
            onOfferSelected: this.onOfferSelected.bind(this),
            onAdditionalPropertyChanged: this.onAdditionalPropertyChanged.bind(this),
            onAdditionalPropertyListUpdated: this.onAdditionalPropertyListUpdated.bind(this),
            onOverrideCalculateQuote: this.onOverrideCalculateQuote.bind(this),
            onRefreshOfferingQuote: this.onRefreshOfferingQuote.bind(this),
            onRefreshQuote: this.onRefreshQuote.bind(this),
            onSelectInventoryStore: this.onSelectInventoryStore.bind(this),
            openStoreSearch: this.openStoreSearch.bind(this),
            recallOfferingContext: this.recallOfferingContext.bind(this),
            repeatRetrieveOfferingQuote: this.repeatRetrieveOfferingQuote.bind(this),
            repeatRetrieveQuote: this.repeatRetrieveQuote.bind(this),
            sendToStore: this.sendToStore.bind(this),
            setSelectedStoreForSearchModal: this.setSelectedStoreForSearchModal.bind(this),
            submitOrderMessageError: null,
            tabSelected: this.tabSelected.bind(this),
            updateCart: this.updateCart.bind(this),
            validateAddressWithAPI: this.validateAddressWithAPI.bind(this)
        });
    }

    $onInit() {
        const codeTables = [
            CODES.InventoryRegion,
            CODES.OneTimeBillerRuleConfiguration,
            CODES.PeriodType,
            CODES.ReasonCodes,
            CODES.RecurringBillerRuleConfiguration,
            CODES.Stores,
            CODES.SubscriberType,
            CODES.SubscriberTypeSecurityRole
        ];

        const mapStateToTarget = (store) => {
            return {
                additionalPropValueOptions: MetadataCodeTypeIntegerSelector(CODES.AdditionalPropertyValue, store),
                addressData: AddressDataSelector(store),
                addressStates: BillingAddressStateRegionProvinceValueOptionsSelector(store),
                allAttributesAreValid: AllAttributesAreValidSelector(store),
                areAnyInventoryUnavailable: AreAnyInventoryUnavailableSelector(store),
                attributeFormSubmitted: AttributeFormSubmittedSelector(store),
                availableCreditLimitFromQuote: AvailableCreditLimitFromQuoteSelector(store),
                availableFinanceCreditFromQuote: AvailableFinanceCreditFromQuoteSelector(store),
                availableInventoryInformation: InventoryAvailabilitySelector(store),
                backButtonText: BackButtonTextSelector(store),
                billCycle: BillCycleSelector(store),
                candidateBillCycles: NewConnectCandidateBillCyclesSelector(store),
                cartAdditionalProperties: CartAdditionalPropertiesSelector(store),
                cartAdditionalPropertyListMap: CartAdditionalPropertyListMapSelector(store),
                cartContainsOfferWithBillerRuleCycleLevelOtherThanItem: CartContainsOfferWithBillerRuleCycleLevelOtherThanItemSelector(store),
                cartContainsOffcycleOffer: CartContainsOffcycleOfferSelector(store),
                codeTypeLoaded: MetadataCodeLoadedSelector(__, store),
                completedDecisions: CompletedDecisionsSelector(store),
                completedSteps: CompletedStepsSelector(store),
                contracts: ContractsSearchResultsSelector(store),
                convergentBillerAccounts: ConvergentBillerAccountsSelector(store),
                createdCustomer: CreatedCustomerSelector(store),
                createdCustomerCreditLimit: CreatedCustomerCreditLimitSelector(store),
                createdOrder: CreatedOrderSelector(store),
                createOrderError: OfferingOrderLastAttemptErrorSelector(store),
                createUpdateCustomerError: ViewLastAttemptErrorSelector(store),
                currencyCode: CreatedOrderCurrencyCodeSelector(store),
                currentAttributeTitle: CurrentActiveAttributeNameSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerIsExternallyManagedAR: CurrentCustomerIsExternallyManagedARSelector(store),
                currentStep: CurrentStepSelector(store),
                currentStepIndex: CurrentStepIndexSelector(store),
                currentStepIsInvalid: CurrentStepIsInvalidSelector(store),
                currentSubtenants: ActiveSubtenantsSelector(store),
                customerInfo: CustomerInfoSelector(store),
                customerInfoEdit: EditCustomerInfoSelector(store),
                customerInfoEditIsCategoryValid: EditCustomerInfoIsCategoryValidSelector(store),
                customerInfoEditSubtenant: EditCustomerInfoSubtenantSelector(store),
                customerTypesByCategory: CustomerTypesByCategoryOptionsSelector(store),
                dbssShippingEnabledRegionId: DbssShippingEnabledRegionIdSelector(store),
                deliveryMethodOptions: NewConnectOfferDeliveryMethodOptionsSelector(store),
                deliveryOptionsByPricingPlan: DeliveryOptionsByPricingPlanSelector(store),
                editOption: EditOptionSelector(store),
                editOptions: EditOptionsSelector(store),
                enableProspectLite: EnableProspectLiteSelector(store),
                executionOptions: SelectedOrderExecutionOptionsSelector(store),
                filteredFormAttributeGroups: FilteredFormAttributeGroups(store),
                filteredPhysicalAttributeGroups: FilteredPhysicalAttributeGroups(store),
                filteredStoresOnSelectedRegionForStoresSearchModal: FilteredStoresOnSelectedRegionForStoresSearchModalSelector(store),
                financedProductGroupInCart: FinancedProductGroupInCartSelector(store),
                formattedAttributes: FormattedAttributesSelector(store),
                formattedCityState: FormattedCityStateSelector(store),
                formattedDeliveryDecisions: FormattedDeliveryDecisionsSelector(store),
                formattedName: FormattedNameSelector(store),
                formattedPhysicalAttributes: FormattedPhysicalAttributesSelector(store),
                formattedPhysicalInventories: SelectedOfferingPhysicalInventoryAvailabilitySelector(store),
                formattedNewCustomerSubscriberIdentities: FormattedNewCustomerSubscriberIdentitiesSelector(store).asMutable({
                    deep: true
                }),
                formAttributeGroups: FormAttributeGroups(store),
                formPhysicalAttributeGroups: FormPhysicalAttributeGroups(store),
                forwardButtonText: ForwardButtonTextSelector(store),
                fullCartAsyncStatus: FullCartAsyncStatusSelector(store),
                fullCartOfferingOrderQuotes: FullCartOfferingOrderQuotesSelector(store),
                fullCartOfferingOrderQuoteTotal: FullCartOfferingOrderQuoteTotalSelector(store),
                fullCartOfferingOrderFeeQuote: FullCartOfferingOrderFeeQuoteSelector(store),
                fullCartOfferingOrderTaxQuote: FullCartOfferingOrderTaxQuoteSelector(store),
                fullCartOfferingOrderShippingQuote: FullCartOfferingOrderShippingQuoteSelector(store),
                getSubtenantName: GetSubtenantNameSelector(store),
                groupedCheckboxFilterOptions: GroupedCheckboxFilterOptionsSelector(store),
                hasCustomerInfo: HasCustomerInfoSelector(store),
                hasDecisionValidationError: DecisionsHasErrorSelector(store),
                hasInvoiceItems: QuoteHasInvoiceItemsSelector(store),
                hasOffCycleChargeRenewal: HasOffCycleChargeRenewalSelector(store),
                hasRequiredAndUnavailableInventory : HasRequiredAndUnavailableInventorySelector(store),
                hasSubtenantsCurrently: CurrentBusinessUnitHasSubtenants(store),
                isDbss: IsDbss(store),
                inventoryAvailabilityRequests: InventoryAvailabilityRequestsSelector(store),
                inventoryRegions: MetadataOptionsForCodeValuesWithoutPlaceholderSelector(CODES.InventoryRegion, store),
                inventoryStorePickupDetails: InventoryStorePickupDetailsSelector(store),
                isAllSelectedPhysicalInventoryAvailable: IsAllSelectedPhysicalInventoryPresentAtSelectedStore(store),
                isAttributesStep: IsAttributesStepSelector(store),
                isCheckoutStep: IsCheckoutStepSelector(store),
                isContinueDisabled: IsContinueDisabledSelector(store),
                isContinueShoppingShown: IsContinueShoppingShownSelector(store),
                isCreatingOrUpdatingCustomer: IsCreatingOrUpdatingCustomerSelector(store),
                isCreditLimitBreached: IsCreditLimitBreachedFromQuoteSelector(store),
                isCustomerStep: IsCustomerStepSelector(store),
                isDecisionsStep: IsDecisionsStepSelector(store),
                isFetchingAnyData: IsFetchingAnyDataSelector(store),
                isFetchingBillCycles: IsFetchingBillCyclesSelector(store),
                isFetchingData: IsFetchingDataSelector(store),
                isFetchingDecisionsOrAttributes: PagesIsFetchingDataSelector(store),
                isFetchingOffer: IsFetchingOfferSelector(store),
                isFullCartAsyncComplete: IsFullCartAsyncCompleteSelector(store),
                isInventoryStoreChanged: IsInventoryStoreChangedSelector(store),
                isInventoryStoreConfigured: IsInventoryStoreConfiguredSelector(store),
                isOffersStep: IsOffersStepSelector(store),
                isPaymentInstrumentRequired: PaymentInstrumentRequiredSelector(store),
                isPopoutable: IsPopoutableSelector(store),
                isQuoteBypassed: IsQuoteBypassedSelector(store),
                isQuoteCalculated: QuoteCalculatedSelector(store),
                isAsyncComplete: IsAsyncCompleteSelector(store),
                isQuotingOffer: IsQuotingOfferSelector(store),
                isSaveOrderButtonDisabled: IsSaveOrderButtonDisabledSelector(store),
                isSelectedOfferingChangeable: IsSelectedOfferingChangeable(store),
                isSendingInventoryToStore: IsSendingInventoryToStoreSelector(store),
                isSendToStoreButtonEnabled: IsSendToStoreButtonEnabledSelector(store),
                isServiceabilityStep: IsServiceabilityStepSelector(store),
                isShippingRequired: IsShippingRequiredSelector(store),
                isSubmittingOrder: IsSubmittingOrderSelector(store),
                isUpdatingCart: IsUpdatingCartSelector(store),
                lastAttemptError: SubmittedOrderErrorSelector(store),
                lastRoute: LastRouteSelector(store),
                offcycleRenewalSchedule: OffcycleRenewalScheduleSelector(store),
                offerAdditionalProperties: OfferAdditionalPropertiesSelector(store),
                offerAdditionalPropertyListMap: OfferAdditionalPropertyListMapSelector(store),
                offeringOrderAsyncId: OfferingOrderAsyncIdSelector(store),
                offeringOrderQuote: OfferingOrderQuoteSelector(store),
                offerOptionViewModel: OfferOptionViewModelSelector(store),
                orderAdditionalProperties: OrderAdditionalPropertiesSelector(store),
                orderAdditionalPropertyListMap: OrderAdditionalPropertyListMapSelector(store),
                orderDetails: SelectedOrderDetailsSelector(store),
                pageSize: PageSizePreferenceSelector(store),
                paymentInfo: PaymentInfoSelector(store),
                paymentInfoEdit: PaymentInfoEditSelector(store),
                paymentInfoEditAutoPay: PaymentInfoEditAutoPaySelector(store),
                periodTypes: MetadataCodeTypeSelector(CODES.PeriodType, store),
                physicalInventories: AllPhysicalInventoryItemsFromSelectedOffering(store),
                preventOverrideFlag: PreventOverrideFlagSelector(store),
                recordPaymentInfo: RecordPaymentInformationSelector(store),
                rocWarningTranslatedMessage: ROCWarningTranslatedMessageSelector(store),
                selectedContract: SelectedContractSelector(store),
                selectedDeliveryDecision: SelectedDeliveryDecisionSelector(store),
                selectedDeliveryDecisionFromRoc: SelectedDeliveryDecisionFromRocSelector(store),
                selectedInventoryRegion: SelectedInventoryRegionSelector(store),
                selectedInventoryStores: SelectedInventoryStoresDetailsSelector(store),
                selectedInventoryStoresItemAvailability: SelectedOfferingStoreAvailabilitySelector(store),
                selectedOfferId: SelectedOfferIdSelector(store),
                selectedOfferingServiceIdentifiers: SelectedOfferingServiceIdentifiersSelector(store),
                selectedShippingAddressAndMethod: SelectedShippingAddressAndMethodSelector(store),
                selectedTabIndex: SelectedTabIndexSelector(store),
                services: AttributeServiceSelector(store),
                serviceTaxDecisions: ServiceTaxDecisionsSelector(store),
                shippingAddress: ShippingAddressSelector(store),
                shippingAddressDropdownOptions: ShippingAddressDropdownOptionsSelector(store),
                shoppingCart: ShoppingCartSelector(store),
                shoppingCartHaveOnlyStandaloneItems: ShoppingCartHaveOnlyStandaloneItemsSelector(store),
                shoppingCartIsPostpaid: ShoppingCartIsPostpaidSelector(store),
                shouldShowNextButton: ShowNextButtonSelector(store),
                shouldShowPreviousButton: ShowPreviousButtonSelector(store),
                showHideFullCartColumns: ShowHideFullCartColumnsSelector(store),
                stepsProgressBarViewModel: StepProgressBarViewModelSelector(store),
                storesTableData: InventoryStoresTableDataSelector(store),
                summaryTabViewModel: SummaryTabViewModel(store),
                tabs: TabPanelViewModelSelector(store),
                taxLocationDecisionsByPricingPlan: TaxLocationDecisionsByPricingPlanSelector(store),
                unavailablePhysicalInventory: UnavailablePhysicalInventorySelector(store),
                unvalidatedAddresses: UnvalidatedAddressesSelector(store),
                validatedAddresses: ValidatedAddressesSelector(store)
            };
        };
        const controllerActions = {
            beginNewConnectOrder,
            calculateOfferingOrderQuote,
            cancelOrder,
            clearQuoteAndSetSelectedOffer,
            createCustomer,
            createCustomerAndSaveOrder,
            createCustomerAndSendOrderToStore,
            fetchAttributes,
            fetchAttributesAndNavigate,
            fetchCodeTypes,
            fetchDecisionsAndNavigate,
            getShippingMethodsByCountryCode,
            goToNextStep,
            goToPreviousStep,
            goToStep,
            initializeAsMultiOfferWizard,
            navigateToFetchOffers,
            registerUnsavedChanges,
            resetAvailableInventoryInformation,
            resetMobileNumberPortabilityModels,
            resetSubscriberCreditClassification,
            restoreOptionDefaultPricing,
            retrieveCandidateBillCyclesNewConnect,
            retrieveOffCycleNextRenewalSchedules,
            retrieveOfferingOrderQuote,
            retrieveOfferingsMetadata,
            retrieveOrderQuote,
            saveBulkPricing,
            saveInventoryStorePickupDetails,
            saveOrder,
            searchAttributeInventory,
            searchAvailableOfferings,
            searchContracts,
            searchInventoriesAvailability,
            searchSubtenants,
            sendOrderToStore,
            sendPortInOrItemReservation,
            setActiveAttributeFormName,
            setAttributeFormSubmitted,
            setChangeOfferingId,
            setChangeOfferingInstanceId,
            setCreditCheckModalStatus,
            setCurrentStepIsInvalid,
            setDeliveryDecision,
            setDevicePaymentOptions,
            setEditAttributeGroups,
            setEditCartAdditionalProperty,
            setEditCartAdditionalPropertyOnChange,
            setEditOfferAdditionalProperty,
            setEditOption,
            setEditOrderAdditionalProperty,
            setEditOrderAdditionalPropertyOnChange,
            setEditPhysicalAttributeGroups,
            setForceShowEditFormAddress,
            setInventoryRegionFilter,
            setInventoryStoreChangedFlag,
            setPaymentInfo,
            setSelectedFacetsEditCopy,
            setSelectedInventoryStores,
            setSelectedOfferEditCopy,
            setSelectedOfferingChargeTypesEditCopy,
            setSelectedTab,
            submitOrder,
            unregisterUnsavedChanges,
            updateAddress,
            updateAttribute,
            setEditOfferAdditionalPropertyOnChange,
            updateBillCycle,
            updateCart,
            updateCustomer,
            updateCustomerAndSaveOrder,
            updateCustomerAndSendOrderToStore,
            updateCustomerAndSubmitOrder,
            updateCustomerInfo,
            updateCustomerInfoEditCopy,
            updateEditOptionPrice,
            updateInventorySelection,
            updateOfferPhysicalInventoryMakeAndModel,
            updateOfferPhysicalInventoryTypeAttribute,
            updateOverrideFlag,
            updatePaymentInfo,
            updatePortInRequest,
            updateSelectedServiceFeatures,
            updateServiceTaxLocationDecision,
            updateSubscriberBillCycle,
            validateAddress
        };

        this.disconnectRedux = this.connectToStore(mapStateToTarget, controllerActions);

        this.EditSelectedFacetIdsSelector = EditSelectedFacetIdsSelector;
        this.EditSelectedOfferingChargeTypeIdSelector = EditSelectedOfferingChargeTypeIdSelector;
        this.customerFormErrors = [];
        this.cartMessages = [
            CustomerCareKeys.CART_SUMMARY.QUOTE_SUMMARY
        ];

        this.actions.beginNewConnectOrder();
        this.actions.resetSubscriberCreditClassification();

        this.customerCategories = CustomerCategories();

        // Used to see if Contracts exist if changed, also change in AddOfferWizard
        this.actions.searchContracts({
            PageNumber: 1,
            PageSize: 1
        });

        codeTables.forEach((codeType) => {
            if (!this.state.codeTypeLoaded(codeType)) {
                this.actions.fetchCodeTypes(codeType).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            }
        });

        this.actions.searchSubtenants().then(() => {})
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });

        this.$scope.orderConfirmationPopup = {
            onRegisterApi: (evt) => {
                this.$scope.orderConfirmationPopup = evt.api;
            }
        };

        // hook the popupApi
        this.additionalPropertiesPopupConfig = {
            onRegisterApi: ({api}) => {
                this.additionalPropertiesPopupApi = api;
            }
        };

        this.currencyMismatchErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.currencyMismatchErrorPopupApi = api;
            }
        };

        this.unsavedChangesPromptPopupConfig = {
            onRegisterApi: ({api}) => {
                this.unsavedChangesPromptPopupApi = api;
            }
        };

        this.saveOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.saveOrderErrorPopupApi = api;
            }
        };

        this.submitOrderErrorPopupConfig = {
            onRegisterApi: ({api}) => {
                this.submitOrderErrorPopupApi = api;
            }
        };

        this.saveOrderPopupConfig = {
            onRegisterApi: ({api}) => {
                this.saveOrderPopupApi = api;
            }
        };

        this.sendToStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.sendToStorePopupApi = api;
            }
        };

        this.creditCheckPopupConfig = {
            onRegisterApi: ({api}) => {
                this.creditCheckPopupApi = api;
            }
        };

        this.selectStorePopupConfig = {
            onRegisterApi: ({api}) => {
                this.selectStorePopupApi = api;
            }
        };

        this.storeSearchInventoryConfig = {
            onRegisterApi: ({api}) => {
                this.storeSearchInventoryApi = api;
            }
        };

        this.openCreditCheckModal = () => {
            this.creditCheckPopupApi.open();
        };

        this.viewFullCartPopupConfig = {
            onRegisterApi: (event) => {
                this.viewFullCartPopupApi = event.api;
            }
        };
    }

    openSendToStorePopup() {
        this.sendToStorePickupDetailsConfig = {
            maxDate: undefined,
            minDate: Date.now()
        };
        this.showSendToStorePopup = true;
        this.$timeout(() => {
            this.sendToStorePopupApi.open();
        });
    }

    //Calls retrieveOrderQuote the first time and then calls it every 30 seconds that the call hasnt returned a response
    repeatRetrieveQuote() {
        asyncRepeatInterval(
            this.onRefreshQuote,
            () => {
                return !this.state.isAsyncComplete && this.state.isCheckoutStep;
            }
        );
    }

    onRefreshQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.createdCustomer.Subscriber.Id, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    repeatRetrieveOfferingQuote() {
        if (this.state.offeringOrderQuote.OfferingQuotes.length > 0 && this.state.isFullCartAsyncComplete) {
            this.openViewFullCartPopupDialog();
        } else {
            asyncRepeatInterval(
                this.onRefreshOfferingQuote,
                () => {
                    return !this.state.isFullCartAsyncComplete && this.state.isCheckoutStep;
                }
            );
        }
    }

    onRefreshOfferingQuote() {
        return this.actions.retrieveOfferingOrderQuote(this.state.createdCustomer.Subscriber.Id, this.state.offeringOrderAsyncId)
            .then((response) => {
                this.retrieveOfferingOrderQuoteTimestamp = moment().add(RETRIEVE_ORDER_QUOTE_COUNTDOWN_DURATION, 'milliseconds');
                return response;
            }).catch((error) => {
                return this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    openViewFullCartPopupDialog() {
        this.showViewFullCartPopup = true;
        this.$timeout(this.viewFullCartPopupApi.open);
    }

    onViewFullCartRequest() {
        if (this.state.offeringOrderAsyncId) {
            this.repeatRetrieveOfferingQuote();
            this.openViewFullCartPopupDialog();
        } else {
            if (!Object.keys(this.state.offeringOrderQuote).length) {
                this.actions.calculateOfferingOrderQuote(
                    this.state.customerInfo,
                    this.state.shoppingCart,
                    this.state.billCycle,
                    this.state.executionOptions,
                    this.state.selectedShippingAddressAndMethod,
                    undefined,
                    this.overrideBypassQuoteConfiguration
                ).then(() => {
                    this.openViewFullCartPopupDialog();
                    if (this.state.cartContainsOffcycleOffer) {
                        this.actions.retrieveOffCycleNextRenewalSchedules(this.state.createdCustomer.Subscriber.Id, this.state.shoppingCart.Items)
                            .catch((error) => {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            });
                    }
                }).catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
            } else {
                this.openViewFullCartPopupDialog();
                if (this.state.cartContainsOffcycleOffer) {
                    this.actions.retrieveOffCycleNextRenewalSchedules(this.state.createdCustomer.Subscriber.Id, this.state.shoppingCart.Items)
                        .catch((error) => {
                            this.uiNotificationService.transientError(error.translatedMessage);
                        });
                }
            }
        }
    }

    closeViewFullCartPopupDialog() {
        this.showViewFullCartPopup = false;
        this.viewFullCartPopupApi.close();
    }

    sendToStore() {
        this.isLoading = true;
        this.actions.updateCustomerInfo(this.state.customerInfoEdit);
        const shoppingCart = this.state.cartAdditionalProperties.length ? Object.assign({}, this.state.shoppingCart, {
            AdditionalProperties: this.state.cartAdditionalProperties
        }) : this.state.shoppingCart;

        const createOrUpdateCustomerAndSendOrderToStorePromise = !this.state.createdCustomer ?
            (this.state.isCheckoutStep ?
                this.actions.sendOrderToStore(this.state.createdCustomer, shoppingCart, this.state.inventoryStorePickupDetails):
                this.actions.createCustomerAndSendOrderToStore(this.state.customerInfo, shoppingCart, this.state.inventoryStorePickupDetails, this.state.preventOverrideFlag)) :
            (this.state.isCheckoutStep ?
                this.actions.sendOrderToStore(this.state.createdCustomer, shoppingCart, this.state.inventoryStorePickupDetails):
                this.actions.updateCustomerAndSendOrderToStore(this.state.createdCustomer, this.state.customerInfo, shoppingCart, this.state.inventoryStorePickupDetails));

        createOrUpdateCustomerAndSendOrderToStorePromise
            .then(() => {
                this.actions.unregisterUnsavedChanges('$ctrl.sendToStoreForm');
                this.actions.updateOverrideFlag(null);
                this.onSaveOrderSuccess(CustomerCareKeys.SEND_TO_STORE.SEND_SUCCESS);
            })
            .catch((error) => {
                if (error.Code === INVENTORY_ITEM_RESERVATION_FAULT_CODE) {
                    this.closeSendToStorePopup();
                    this.isInventoryItemReservationFault = true;
                    this.openStoreSearch(true);
                } else {
                    this.uiNotificationService.transientError(error.translatedMessage);
                }
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    launchSaveOrder() {
        if (this.state.cartAdditionalPropertyListMap.length) {
            this.cartAdditionalPropertyFileds = clone(this.state.cartAdditionalPropertyListMap);
            this.showAdditionalPropertiesPopup = true;
            this.$timeout(() => {
                this.additionalPropertiesPopupApi.open();
            });
        } else {
            this.finishSaveOrder();
        }
    }

    closeAdditionalPropertiesPopup() {
        this.showAdditionalPropertiesPopup = false;
        this.additionalPropertiesPopupApi.close();
    }

    saveAdditionalProperties() {
        if (this.additionalPropertiesPopupForm.$valid) {
            this.showAdditionalPropertiesPopup = false;
            this.additionalPropertiesPopupApi.close();

            this.finishSaveOrder();
        } else {
            const old = this.$location.hash();
            this.$location.hash('additionalPropertiesErrorField');
            this.$anchorScroll();
            this.$location.hash(old);
        }
    }

    onCartAdditionalPropertyListUpdated() {
        this.actions.setEditCartAdditionalProperty(this.state.cartAdditionalPropertyListMap);
    }

    onCartAdditionalPropertyChanged(id, newValue) {
        this.actions.setEditCartAdditionalPropertyOnChange(id, newValue);
    }

    onAdditionalPropertyListUpdated(isOfferAdditionalProperty) {
        if (isOfferAdditionalProperty) {
            this.actions.setEditOfferAdditionalProperty(this.state.offerAdditionalPropertyListMap);
        } else {
            this.actions.setEditOrderAdditionalProperty(this.state.orderAdditionalPropertyListMap);
        }
    }

    onAdditionalPropertyChanged(id, newValue, isOfferAdditionalProperty) {
        if (isOfferAdditionalProperty) {
            this.actions.setEditOfferAdditionalPropertyOnChange(id, newValue);
        } else {
            this.actions.setEditOrderAdditionalPropertyOnChange(id, newValue);
        }
    }

    openOrderConfirmationPopup() {
        this.$scope.orderConfirmationPopup.open();
    }

    redirectToCustomer() {
        this.$scope.orderConfirmationPopup.close();
        this.deregisterUnsavedChanges();
        this.$state.go(CUSTOMER_URL, {
            'customerId': this.state.createdCustomer.Subscriber.Id,
            'showAutoPayBanner': {
                enabled: this.state.paymentInfoEditAutoPay,
                customerId: this.state.createdCustomer.Subscriber.Id
            }
        });
    }

    updateCart(offerId, offeringInstanceId = undefined, completedDecisions, isModify, customerId, callback, contextOfferingAction,
        transitionContext, isConvergentBiller, customerLanguage) {
        this.actions.setDevicePaymentOptions([]);

        /**
        * Updates the cart, also handles the user language
        * @param {string} offerId
        * @param {number} offeringInstanceId
        * @param {[]} completedDecisions
        * @param {boolean} isModify
        * @param {number} customerId
        * @param {number} contextOfferingAction
        * @param {Object} transitionContext
        * @param {boolean} isConvergentBiller
        * @param {string} customerLanguage
        */

        /**Callback function as paramater
         *  @callback callback
       */

        this.actions.updateCart(offerId, offeringInstanceId, completedDecisions, isModify, customerId, callback, contextOfferingAction,
            transitionContext, isConvergentBiller, customerLanguage);
    }

    handleResultUnsavedChangesPromptPopup(confirmed) {
        if (confirmed) {
            this.handleCancel();
        }

        this.unsavedChangesPromptPopupApi.close();
    }

    handleCloseCurrencyMismatchErrorPopup() {
        this.currencyMismatchErrorPopupApi.close();
    }

    handleCloseUnsavedChangesPromptPopup() {
        this.unsavedChangesPromptPopupApi.close();
    }

    handleCloseSaveOrderErrorPopup() {
        this.saveOrderErrorPopupApi.close();
    }

    handleOpenSubmitOrderErrorPopup() {
        const totalQuote = pathOr(null, ['summaryTabViewModel', 'totalQuoteAmount'], this.state);
        const creditLimit = pathOr(null, ['currentCustomer', 'CreditCheckClassification', 'CreditLimit'], this.state);
        const breachedAmount = totalQuote && creditLimit ? creditLimit - totalQuote : null;
        this.submitOrderMessageError = breachedAmount ? i18n.translate(CustomerCareKeys.CREDIT_LIMIT_BREACHED, {
            breachAmount: this.$filter('invCurrency')(breachedAmount, this.state.currentCustomer.SubscriberCurrency)
        }) : i18n.translate(CustomerCareKeys.CREDIT_LIMIT_BREACHED_NO_AMOUNT);
        this.submitOrderErrorPopupApi.open();
    }

    handleCloseSubmitOrderErrorPopup() {
        this.submitOrderErrorPopupApi.close();
    }

    handleCancel() {
        this.actions.cancelOrder();
        this.deregisterUnsavedChanges();

        const route = this.state.lastRoute.name || 'index.customercare';
        const params = this.state.lastRoute.params || {};
        this.$state.go(route, params);
    }

    deregisterUnsavedChanges() {
        this.actions.unregisterUnsavedChanges('serviceabilityController.serviceabilityForm');
        // no "offers" form
        // no "decisions" form
        this.actions.unregisterUnsavedChanges('attributesController.attributesForm');
        this.actions.unregisterUnsavedChanges('customerController.customerForm');
        this.actions.unregisterUnsavedChanges('$ctrl.paymentInstrumentForm');
        this.actions.unregisterUnsavedChanges('newConnectWizard');
        this.actions.resetMobileNumberPortabilityModels();
    }

    previousStepIsOffersStep() {
        return getPreviousStepIndex(ALL_STEPS_KEYS, this.state.completedSteps, this.state.currentStepIndex) === 1;
    }

    goToPreviousStep() {
        if (this.state.isCustomerStep) {
            this.customerFormErrors = [];
            this.actions.updateCustomerInfo(this.state.customerInfoEdit);
        } else if (this.state.isCheckoutStep) {
            this.actions.setPaymentInfo(this.state.paymentInfoEdit);
        }

        if (this.state.isAttributesStep &&
            this.isAttributesFormInvalid()) {
            this.wizardForm.$setSubmitted();
            this.actions.setAttributeFormSubmitted(true);
            return;
        }

        this.actions.goToPreviousStep();
        if (this.state.isDecisionsStep) {
            this.actions.setAttributeFormSubmitted(false);
            this.actions.setActiveAttributeFormName(null);
        }
    }

    cancelClick() {
        if (this.unsavedChangesPrompt.hasUnsavedChanges()) {
            this.unsavedChangesPromptPopupApi.open();
        } else {
            this.handleCancel();
        }
    }

    fetchQuoteNewConnect(customerInfo) {
        this.actions.calculateOfferingOrderQuote(
            customerInfo,
            this.state.shoppingCart,
            this.state.billCycle,
            undefined,
            this.state.selectedShippingAddressAndMethod,
            !!this.state.shippingAddress,
            this.overrideBypassQuoteConfiguration
        ).then(() => {
            if (this.state.offeringOrderAsyncId) {
                this.repeatRetrieveQuote();
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    onSaveOrderSuccess(messageKey = CustomerCareKeys.SAVE_ORDER_FORM.SUCCESS) {
        this.uiNotificationService.success(i18n.translate(messageKey), null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
        this.redirectToCustomer();
    }

    onCreditCheckModalSuccess() {
        this.updateStateValues = !this.updateStateValues;
    }

    onOfferSelected(offerId) {
        this.actions.fetchDecisionsAndNavigate({
            offerId,
            skipAttributesTargetStep: CustomerCareKeys.WIZARD.STEPS.CUSTOMER,
            isConvergentBiller: true,
            segmentationContext: buildSegmentationContext(this.state.customerInfoEdit)
        })
            .then(() => {
                this.wizardForm.$setPristine();
                this.actions.retrieveOfferingsMetadata([offerId]);

                this.miniCartMoreActionMenuItems = moreActionMenuItems(this.state.isSelectedOfferingChangeable ?
                    [MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER, MINI_CART_MORE_ACTIONS.CHANGE] :
                    [MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER]);
            });
    }

    saveOrderButtonTooltipText() {
        let tooltipText = '';
        if (!(this.state.shoppingCart.Items && this.state.shoppingCart.Items.length)) {
            tooltipText = i18n.translate(CustomerCareKeys.SAVE_CART.CART_IS_EMPTY);
        } else if (this.state.shoppingCartHaveOnlyStandaloneItems) {
            tooltipText = i18n.translate(CustomerCareKeys.SAVE_CART.CART_IS_HAVING_ONLY_STANDALONE_PRODUCTS);
        }
        return tooltipText;
    }

    closeSendToStorePopup() {
        this.showSendToStorePopup = false;
        this.sendToStorePopupApi.close();
        this.isLoading = false;
    }

    finishSaveOrder() {
        if (this.state.isCheckoutStep) {
            const paymentInfo = this.state.recordPaymentInfo ? (this.state.paymentInfoEdit && this.state.paymentInfoEdit.asMutable()): null;
            if (this.state.recordPaymentInfo && paymentInfo && !paymentInfo.BillingAddress) {
                paymentInfo.BillingAddress = {
                    Name: this.state.customerInfoEdit.addressLine1,
                    City: this.state.customerInfoEdit.city,
                    Country: this.state.customerInfoEdit.country,
                    LineOne: this.state.customerInfoEdit.addressLine1,
                    LineTwo: this.state.customerInfoEdit.addressLine2,
                    PostalCode: this.state.customerInfoEdit.postalCode,
                    State: this.state.customerInfoEdit.stateRegionProvince,
                };
            }
            const shoppingCart = this.state.cartAdditionalProperties.length ? Object.assign({}, this.state.shoppingCart, {
                AdditionalProperties: this.state.cartAdditionalProperties
            }) : this.state.shoppingCart;


            return this.actions.saveOrder(this.state.createdCustomer, shoppingCart)
                .then(() => {
                    this.onSaveOrderSuccess();
                })
                .catch((err) => {
                    this.saveOrderErrorPopupApi.open(err);

                    return Promise.reject();
                });
        } else if ((this.state.isDecisionsStep || this.state.isServiceabilityStep || this.state.isOffersStep) && this.state.hasDecisionValidationError) {
            this.uiNotificationService.error(i18n.translate(CustomerCareKeys.INVALID_ORDER_SAVE), null, {
                timeOut: NOTIFICATION_TIME_LENGTH
            });
            if (this.state.isDecisionsStep) {
                const old = this.$location.hash();
                this.$location.hash('quantityDecisionErrorField');
                this.$anchorScroll();
                this.$location.hash(old);
            }
        } else {
            this.actions.fetchAttributes({
                offeringId: this.state.selectedOfferId,
                completedDecisions: this.getCompletedDecisions(),
                completedAttributes: this.state.formattedAttributes,
                completedPhysicalAttributes: this.state.formattedPhysicalAttributes
            })
                .then(() => {
                    this.wizardForm.$submitted = true;
                    if (!duplicatedServiceIdentifierAttributes(this.state.formAttributeGroups).length) {
                        this.attemptNextStep(false, false, true);
                    }
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    // temporary until all steps do validation the same
    isContinueDisabled() {
        return this.state.isFetchingAnyData || this.state.isSubmittingOrder ||
            this.state.isCreatingOrUpdatingCustomer ||
            (this.state.isServiceabilityStep && !this.state.customerInfoEdit.SubscriberCurrency) ||
            this.isLoading;
    }

    isServiceabilityFormInvalid() {
        return this.wizardForm['serviceabilityController.serviceabilityForm'] &&
            this.wizardForm['serviceabilityController.serviceabilityForm'].$invalid;
    }

    isOffersStepInvalid() {
        return this.state.currentStepIsInvalid;
    }

    isAttributesFormInvalid() {
        return (this.wizardForm['attributesController.attributesForm'] &&
            this.wizardForm['attributesController.attributesForm'].$invalid) || !this.state.allAttributesAreValid;
    }

    isCheckoutFormInvalid() {
        return (this.wizardForm['$ctrl.paymentInstrumentForm'] &&
            this.wizardForm['$ctrl.paymentInstrumentForm'].$invalid);
    }

    isCustomerFormInvalid() {
        return this.wizardForm['customerController.customerForm'] &&
            this.wizardForm['customerController.customerForm'].$invalid;
    }

    onSubmit(isAddAnotherOffer = false) {
        this.removeMultiOfferValidations = this.state.enableProspectLite && isAddAnotherOffer;
        // time out is added for lite customer validations on customer step
        this.$timeout(() => {
            if (!this.state.isCreatingOrUpdatingCustomer && !this.state.isSubmittingOrder && !this.isLoading) {
                // This is a unique case in which we need a local loading variable to immediately disable the continue button
                // Chained actions in continue to next step exposes small gaps in which each action isLoading selector do not cover
                // Therefore we need this to prevent first prevent that as well as faster disabling of the button as we are seeing
                // delay as the selectors start to toggle their isLoading flags
                this.isLoading = true;
                this.wizardForm.$setSubmitted();

                if (!isAddAnotherOffer) {
                    this.$element.find('button[type="submit"]')[0].focus();
                }

                this.attemptNextStep(isAddAnotherOffer);
            }
        });

    }

    addAnotherOfferOrSaveCart(isSaveCart) {
        if (!this.state.createdCustomer) {
            this.actions.createCustomerAndSaveOrder(this.state.customerInfoEdit, this.state.shoppingCart, null, null, null, this.state.preventOverrideFlag).then(() => {
                this.deregisterUnsavedChanges();
                this.actions.initializeAsMultiOfferWizard(true);
                this.actions.updateOverrideFlag(null);
                this.$state.go(isSaveCart ? DASHBOARD_ROUTE : ADD_OFFER_ORDER_URL, {
                    'customerId': this.state.createdCustomer.Subscriber.Id
                });
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
                // Intentionally not setting isLoading to false on .finally() due to it loading other api calls through the
                // process of transitioning to the add flow wizard
                this.isLoading = false;
            });
        } else {
            this.actions.updateCustomerAndSaveOrder(this.state.createdCustomer, this.state.customerInfo, this.state.shoppingCart).then(() => {
                this.deregisterUnsavedChanges();
                this.actions.initializeAsMultiOfferWizard(true);
                this.$state.go(ADD_OFFER_ORDER_URL, {
                    'customerId': this.state.createdCustomer.Subscriber.Id
                });
            });
        }
    }

    recallOfferingContext() {
        this.actions.fetchAttributes({
            offeringId: this.state.selectedOfferId,
            completedDecisions: this.getCompletedDecisions(),
            completedAttributes: this.state.formattedAttributes,
            completedPhysicalAttributes: this.state.formattedPhysicalAttributes
        })
            .catch((err) => {
                this.uiNotificationService.transientError(err.translatedMessage);
            });
    }

    getCompletedDecisions() {
        return [...this.state.serviceTaxDecisions, ...this.state.formattedDeliveryDecisions, ...this.state.completedDecisions];
    }

    attemptNextStep(isAddAnotherOffer = false, isSendToStore = false, isSaveCart = false) {
        let dirty = true;

        this.removeMultiOfferValidations = this.state.enableProspectLite && (isSendToStore || isAddAnotherOffer || isSaveCart);
        // NOTE: this may register non-existant (extraneous) "unsaved
        // changes" in the case that the user tries to proceed to the next
        // step, but has not dirtied the form
        this.actions.registerUnsavedChanges('newConnectWizard', {
            isDirty: () => {
                return dirty;
            },
            setPristine: () => {
                dirty = false;
            }
        });

        if (this.state.isServiceabilityStep) {
            if (!this.isServiceabilityFormInvalid()) {
                if (this.state.isDbss) {
                    this.actions.getShippingMethodsByCountryCode(this.state.addressData.address.Country).catch((error) => {
                        this.uiNotificationService.transientError(error.translatedMessage);
                    });
                }
                if (this.shouldValidateAddressWithAPIForServiceability()) {
                    this.validateAddressWithAPI()
                        .then(() => {
                            const addressKey = this.getAddressKey(),
                                validatedAddress = this.state.validatedAddresses[addressKey];
                            this.isLoading = false;

                            if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                                this.updateCustomerInfoEditWithValidatedAddress(validatedAddress && validatedAddress.ValidatedAddress);
                                return this.proceedToFetchOffers();
                            } else {
                                this.actions.setForceShowEditFormAddress(false);
                                return Promise.resolve();
                            }
                        })
                        .catch((err) => {
                            this.uiNotificationService.transientError(err.translatedMessage);
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                } else {
                    this.proceedToFetchOffers()
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            } else {
                this.isLoading = false;
            }
        } else if (this.state.isOffersStep) {
            if (!this.isOffersStepInvalid()) {
                this.isLoading = false;
                this.actions.goToNextStep();
                this.wizardForm.$setPristine();
            } else {
                this.isLoading = false;
            }

        } else if (this.state.isAttributesStep && !this.state.hasRequiredAndUnavailableInventory) {
            this.actions.setAttributeFormSubmitted(true);
            if (!this.isAttributesFormInvalid() && !duplicatedServiceIdentifierAttributes(this.state.formAttributeGroups).length) {
                if (this.state.formattedPhysicalAttributes.length) {
                    this.actions.resetAvailableInventoryInformation();
                    this.actions.fetchAttributes({
                        offeringId: this.state.selectedOfferId,
                        completedDecisions: this.getCompletedDecisions(),
                        completedAttributes: this.state.formattedAttributes,
                        completedPhysicalAttributes: this.state.formattedPhysicalAttributes
                    })
                        .then(() => {
                            if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                this.actions.setInventoryRegionFilter(this.state.dbssShippingEnabledRegionId);
                            }
                            if (this.state.selectedInventoryStores.length < 1) {
                                this.openStoreSearch();
                                this.wizardForm.$setPristine();
                                this.isLoading = false;
                            } else {
                                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
                                    .then(() => {
                                        if (!this.state.isAllSelectedPhysicalInventoryAvailable) {
                                            if (this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED) {
                                                this.uiNotificationService.transientError(i18n.translate(CustomerCareKeys.ATTRIBUTES.UNAVAILABLE_SHIPPING_INVENTORY));
                                            } else {
                                                this.openStoreSearch();
                                            }
                                        } else {
                                            this.fetchAttributesAndNavigate();
                                        }
                                    })
                                    .then(() => {
                                        this.wizardForm.$setPristine();
                                    })
                                    .catch((err) => {
                                        this.showStoreInventoryPopup= true;
                                        this.$timeout(() => {
                                            this.storeSearchInventoryApi.open();
                                            this.onSearchInventoryAvailabilityFailure(err);
                                        });
                                    })
                                    .finally(() => {
                                        this.isLoading = false;
                                    });
                            }
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (error.Code !== DUPLICATE_SERVICE_IDENTIFIER_FAULT_CODE) {
                                this.uiNotificationService.transientError(error.translatedMessage);
                            }
                        });
                } else {
                    this.fetchAttributesAndNavigate();
                }
            } else {
                this.isLoading = false;
            }
        } else if (this.state.isCheckoutStep) {
            this.isLoading = true;
            this.disableStepBack = true;
            if (isAddAnotherOffer) {
                this.addAnotherOfferOrSaveCart();
            } else if (isSendToStore) {
                this.openSendToStorePopup();
            } else {
                if (!this.isCheckoutFormInvalid()) {
                    this.validateAddressWithAPI()
                        .then(() => {
                            const addressKey = this.getAddressKey(),
                                validatedAddress = this.state.validatedAddresses[addressKey];
                            this.isLoading = false;
                            if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                                this.updateCustomerInfoEditWithValidatedAddress(validatedAddress && validatedAddress.ValidatedAddress);
                                this.actions.updateCustomerInfo(this.state.customerInfoEdit);
                                this.actions.setPaymentInfo(this.state.paymentInfoEdit);
                                if (this.state.createdCustomer) {
                                // if the customer has already been created, then call updateCustomer
                                    const dto = {
                                        additionalProperties: this.state.orderAdditionalProperties,
                                        billCycleName: this.state.billCycle,
                                        convergentBillerAccounts: this.state.convergentBillerAccounts,
                                        customerId: this.state.createdCustomer.Subscriber.Id,
                                        executionOptions: this.state.executionOptions,
                                        newCustomerData: this.state.customerInfo,
                                        orderDetails: this.state.orderDetails,
                                        oldCustomerData: this.state.createdCustomer,
                                        paymentInfo: this.state.paymentInfo,
                                        recordPaymentInfo: this.state.recordPaymentInfo,
                                        shoppingCart: this.state.shoppingCart,
                                        subtenantId: this.state.customerInfo.SubtenantId,
                                        offerAdditionalProperties: this.state.offerAdditionalProperties,
                                    };

                                    if (this.state.createdCustomerCreditLimit) {
                                        this.checkCreditandFinanceLimitAndSubmitOrder(dto);
                                    } else {
                                        this.submitOrder(dto);
                                    }
                                }
                            } else {
                                this.actions.setForceShowEditFormAddress(false);
                                this.uiNotificationService.transientError(i18n.translate(this.customerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE));
                            }
                        }).catch((err) => {
                            this.uiNotificationService.transientError(err.translatedMessage);
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = false;
                }
            }
        } else if (this.state.isCustomerStep) {
            if (!this.isCustomerFormInvalid()) {
                this.validateAddressWithAPI()
                    .then(() => {
                        const addressKey = this.getAddressKey();
                        const validatedAddress = this.state.validatedAddresses[addressKey];
                        this.isLoading = false;

                        if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {
                            this.updateCustomerInfoEditWithValidatedAddress(validatedAddress && validatedAddress.ValidatedAddress);
                            this.isLoading = true;
                            this.actions.setForceShowEditFormAddress(false);
                            if (isAddAnotherOffer || isSaveCart) {
                                this.addAnotherOfferOrSaveCart(isSaveCart);
                            } else if (isSendToStore) {
                                this.openSendToStorePopup();
                            } else {
                                this.actions.updateCustomerInfo(this.state.customerInfoEdit);
                                this.createOrUpdateCustomerCalculateOrderQuoteAndNavigate();
                            }
                        } else {
                            this.actions.setForceShowEditFormAddress(false);
                            this.customerFormErrors.push({
                                description: i18n.translate(CustomerCareKeys.ADDRESS_COMPONENT.INVALID_ADDRESS_ERROR_MESSAGE)
                            });
                        }
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.uiNotificationService.transientError(err.translatedMessage);
                    });
            } else {
                //Customer step
                // time out is added for lite customer validations on customer step
                this.$timeout(() => {
                    const ngFormErrors = this.wizardForm['customerController.customerForm'].createCustomerForm.$error;
                    const errorLookups = getErrorLookupObject();

                    this.customerFormErrors = formHasErrors(ngFormErrors, errorLookups);

                    //TODO ASC-103364. this is temporary fix to get errors from address component. Reference story should add error handling in the component and remove his workaround.
                    this.formErrors.flattenedErrors(ngFormErrors).reduce((customerFormErrors, {error, constraintType}) => {
                        if (!errorLookups[error.$name]) {
                            const errorMessage = this.formErrors.getErrorMessage(error, constraintType, {});
                            errorMessage && customerFormErrors.push({
                                description: errorMessage
                            });
                        }
                        return customerFormErrors;
                    }, this.customerFormErrors);
                });
                this.isLoading = false;
                this.scrollToErrorSummary();
            }
        } else if (this.state.isDecisionsStep &&
            !this.state.currentStepIsInvalid &&
            !this.state.hasDecisionValidationError) {
            this.actions.fetchAttributesAndNavigate({
                offerId: this.state.selectedOfferId,
                completedDecisions: this.getCompletedDecisions(),
                completedAttributes: this.state.formattedAttributes
            }).then(() => {
                if (this.state.selectedDeliveryDecision && this.state.selectedDeliveryDecision !== this.state.selectedDeliveryDecisionFromRoc) {
                    this.actions.setDeliveryDecision(this.state.selectedDeliveryDecisionFromRoc);
                    this.actions.setSelectedInventoryStores([]);
                }

                this.wizardForm.$setPristine();
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            }).finally(() => {
                this.isLoading = false;
            });
        } else {
            this.$log.warn('The current step has errors');
            this.isLoading = false;
        }
    }

    checkCreditandFinanceLimitAndSubmitOrder(dto) {
        const isFinanceCreditLimitBreached = this.validateFinanceCreditLimitFromQuote();
        if (!this.state.isCreditLimitBreached && !isFinanceCreditLimitBreached && dto) {
            this.updateCustomerSubmitOrder(dto);
        } else {
            this.isLoading = false;
        }
    }

    checkCreditAndFinanceLimit(dto) {
        const isFinanceCreditLimitBreached = this.validateFinanceCreditLimitFromQuote();
        if (!this.state.isCreditLimitBreached && !isFinanceCreditLimitBreached && dto) {
            this.updateCustomer(dto);
        } else {
            this.isLoading = false;
        }
    }

    createOrUpdateCustomerCalculateOrderQuoteAndNavigate() {
        if (!this.state.createdCustomer) {
            // if the customer hasn't been created yet, then call createCustomer
            this.actions.createCustomer(this.state.customerInfo, this.state.preventOverrideFlag)
                .then((data) => {
                    this.calculateOrderQuoteAndCheckCreditAndFinanceLimit(true);
                    this.actions.unregisterUnsavedChanges('newConnectWizard');
                    this.actions.updateOverrideFlag(null);
                    this.actions.updateCustomerInfoEditCopy(Object.assign({}, this.state.customerInfoEdit, {
                        Id: data.Subscriber.Id
                    }));
                }).catch((error) => {
                    this.isLoading = false;
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        } else {
            // if the customer has been created , then call updateCustomer
            this.actions.updateCustomer(this.state.createdCustomer, this.state.customerInfo)
                .then(() => {
                    this.calculateOrderQuoteAndCheckCreditAndFinanceLimit();
                    return this.actions.updateAddress(this.state.createdCustomer, this.state.customerInfo);
                })
                .catch((error) => {
                    this.uiNotificationService.transientError(error.translatedMessage);
                });
        }
    }

    calculateOrderQuoteAndCheckCreditAndFinanceLimit(isCreateCustomer = false) {
        this.calculateQuoteAndNavigate(isCreateCustomer)
            .then(() => {
                this.checkCreditAndFinanceLimit();
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    validateFinanceCreditLimitFromQuote() {
        return this.state.availableFinanceCreditFromQuote > 0;
    }

    submitOrder(dto) {
        this.actions.submitOrder(dto, this.state.selectedShippingAddressAndMethod).then(() => {
            this.actions.unregisterUnsavedChanges('newConnectWizard');
            this.$scope.orderConfirmationPopup.open();
            this.actions.resetMobileNumberPortabilityModels();
        }).catch((error) => {
            this.isLoading = false;
            if ( error.Code && error.Code === INSUFFICIENT_CREDIT_AMOUNT_FAULT_CODE ) {
                this.handleOpenSubmitOrderErrorPopup();
                return;
            }
            this.submitOrderErrorPopupApi.open(error);
        });
    }

    updateCustomerSubmitOrder(dto) {
        this.actions.updateCustomerAndSubmitOrder(dto, this.state.selectedShippingAddressAndMethod).then(() => {
            this.actions.unregisterUnsavedChanges('newConnectWizard');
            this.$scope.orderConfirmationPopup.open();
            this.actions.resetMobileNumberPortabilityModels();
        }).catch((error) => {
            this.isLoading = false;
            if ( error.Code && error.Code === INSUFFICIENT_CREDIT_AMOUNT_FAULT_CODE ) {
                this.handleOpenSubmitOrderErrorPopup();
                return;
            }
            this.submitOrderErrorPopupApi.open(error);
        });
    }

    scrollToErrorSummary() {
        this.$timeout(() => { // needed because of the continue shopping button can not submit the angular form
            const old = this.$location.hash();
            this.$location.hash('createCustomerForm');
            this.$anchorScroll();
            this.$location.hash(old);
        });
    }

    calculateQuoteAndNavigate(isCreateCustomer) {
        return Promise.resolve().then(() => {
            if (this.state.cartContainsOfferWithBillerRuleCycleLevelOtherThanItem) {
                // RetrieveCandidateBillCycles should  only be called in DBSS. New Connect currently is not supported in OTT.
                return this.actions.retrieveCandidateBillCyclesNewConnect(
                    this.state.shoppingCartIsPostpaid ?
                        subscriberOrderTypeEnum.postpaid.value : subscriberOrderTypeEnum.prepaid.value,
                    this.state.customerInfo.Category,
                    this.state.customerInfo.SubscriberCurrency,
                    this.state.customerInfo.SubscriberTypeCode,
                    this.state.customerInfo.country,
                    this.state.customerInfo.stateRegionProvince
                );
            }
        }).then((data) => {
            if (isCreateCustomer) {
                this.actions.updateSubscriberBillCycle(data?.BillCycles[0]?.Id, this.state.createdCustomer.Subscriber.Id);
            }
        }).then(() => {
            this.actions.updateCustomerInfo(this.state.customerInfoEdit);
        }).then(() => {
            if (this.state.customerInfo.SubscriberCurrency !== this.state.shoppingCart.Currency) {
                this.currencyMismatchErrorPopupApi.open();
            } else {
                if (this.state.createdCustomer) {
                    return this.fetchQuote(this.state.createdCustomer.Subscriber);
                } else {
                    return this.fetchQuote(this.state.customerInfo);
                }
            }
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
        });
    }

    fetchQuote(custInfo) {
        return this.actions.calculateOfferingOrderQuote(
            custInfo,
            this.state.shoppingCart,
            undefined,
            undefined,
            undefined,
            this.state.selectedDeliveryDecision === DELIVERY_OPTIONS.SHIPPED,
            this.overrideBypassQuoteConfiguration
        )
            .then(() => {
                if (this.state.offeringOrderAsyncId) {
                    this.repeatRetrieveQuote();
                }
                this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.CHECKOUT);
                this.customerFormErrors = [];
                this.wizardForm.$setPristine();
            })
            .catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
    }

    handleInventoryAvailabilityContinue() {
        /*
            if inventoryavailability not there then show the stores
            slash status icons
        */
        this.wizardForm.$setPristine();
        this.fetchAttributesAndNavigate();
    }

    fetchAttributesAndNavigate() {
        return this.actions.fetchAttributesAndNavigate({
            offerId: this.state.selectedOfferId,
            completedDecisions: this.getCompletedDecisions(),
            completedAttributes: this.state.formattedAttributes,
            completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
            skipAttributesTargetStep: this.state.customerInfoEdit.Language
        }).then(() => {
            this.wizardForm.$setPristine();
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);

            return Promise.reject();
        })
            .finally(() => {
                this.isLoading = false;
            });
    }

    tabSelected(selectedTab) {
        const selectedIndex = this.state.tabs.findIndex((tab) => {
            return tab === selectedTab;
        });

        this.actions.setSelectedTab(selectedIndex);
    }

    onSelectInventoryStore() {
        if (this.state.isCustomerStep || this.state.isCheckoutStep) {
            this.openStoreSearch(true);
        } else {
            if (this.state.selectedInventoryRegion) {
                this.actions.setInventoryRegionFilter(this.state.selectedInventoryRegion);
            }
            this.showSelectStorePopup = true;
            this.$timeout(() => {
                this.selectStorePopupApi.open();
            });
        }
    }

    // Handles only single offer flow.
    _updateShoppingCartOnInventoryStoreChange() {
        if ((this.state.isCustomerStep || this.state.isCheckoutStep) && this.state.isInventoryStoreChanged) {
            const selectedOfferingInstanceId = undefined;
            return this.actions.fetchAttributes({
                offeringId: this.state.selectedOfferId,
                offeringInstanceId: selectedOfferingInstanceId,
                completedDecisions: this.getCompletedDecisions(),
                completedAttributes: this.state.formattedAttributes,
                completedPhysicalAttributes: this.state.formattedPhysicalAttributes,
                isConvergentBiller: this.state.isDbss
            }).then(() => {
                // You can reset the Inventory Store Change flag, because the Shopping Cart Items now has the updated StoreId.
                this.actions.setInventoryStoreChangedFlag(false);
                if (this.state.isCheckoutStep) {
                    return this.actions.calculateOfferingOrderQuote(this.state.customerInfo, this.state.shoppingCart, this.state.billCycle, undefined, undefined, undefined, this.overrideBypassQuoteConfiguration);
                }
                return null;
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);

                return Promise.reject();
            });
        }
    }

    closeSelectStorePopupDialog(isApplyChanges, selectedStore) {
        this.showSelectStorePopup = false;
        if (isApplyChanges) {
            this.actions.setSelectedInventoryStores([selectedStore]);
        }
        this.actions.setInventoryRegionFilter(null);
        this.selectStorePopupApi.close();
    }

    onInventoryRegionFilterChanged(region) {
        this.actions.setInventoryRegionFilter(region);
    }

    onInventoryRegionFilterChangedForStoreSearch(region) {
        this.actions.setInventoryRegionFilter(region);
        this.actions.resetAvailableInventoryInformation();
        const formattedInventories = this.state.physicalInventories,
            formattedPayload = !this.state.formattedInventories ? {
                InventoryTypes: formattedInventories,
                RegionId: region
            } : this.state.formattedPhysicalInventories;

        this.actions.searchInventoriesAvailability(formattedPayload)
            .catch(this.onSearchInventoryAvailabilityFailure);
    }

    closeSearchInventoryPopupDialog() {
        this.showStoreInventoryPopup = false;
        this.actions.setInventoryRegionFilter(null);
        this.storeSearchInventoryApi.close();
        if (this.isInventoryItemReservationFault) {
            this.openSendToStorePopup();
            this.isInventoryItemReservationFault = false;
        }
    }

    setSelectedStoreForSearchModal(selectedStore) {
        this.actions.setSelectedInventoryStores([selectedStore]);
        this.showStoreInventoryPopup = false;
        this.closeSearchInventoryPopupDialog();
        if (this.state.isAttributesStep) {
            this.fetchAttributesAndNavigate();
        } else {
            this._updateShoppingCartOnInventoryStoreChange();
        }
    }

    openStoreSearch(isSearchInventoriesAvailability = false) {
        this.showStoreInventoryPopup= true;
        this.$timeout(() => {
            this.storeSearchInventoryApi.open();
            if (isSearchInventoriesAvailability) {
                this.actions.searchInventoriesAvailability(this.state.formattedPhysicalInventories)
                    .catch(this.onSearchInventoryAvailabilityFailure);
            }
        });
    }

    getAddressKey() {
        if (this.state.isServiceabilityStep) {
            return pathOr(undefined, ['wizardForm', 'serviceabilityController.serviceabilityForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
        } else if (this.state.isCheckoutStep) {
            return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
        } else if (this.state.isCustomerStep) {
            return pathOr(undefined, ['wizardForm', 'customerController.customerForm', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
        }
    }

    getAddressKeyForAddressValidatedThroughAPI() {
        if (this.state.isServiceabilityStep) {
            return pathOr(undefined, ['wizardForm', 'serviceabilityController.serviceabilityForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
        } else if (this.state.isCheckoutStep) {
            return pathOr(undefined, ['wizardForm', '$ctrl.paymentInstrumentForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
        } else if (this.state.isCustomerStep) {
            return pathOr(undefined, ['wizardForm', 'customerController.customerForm', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
        }
    }

    getJobToken() {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    }

    validateAddressWithAPI() {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey];
        let addressTypes;

        if (this.state.isCheckoutStep) {
            //Refer to ./configure.autopay.constantsln 21 to see what the addressType should be
            addressTypes =  {
                DefaultBilling: true
            };
        } else {
            //Refer to new.connect.wizard.actions ln 290 or buildAddressRequestObject fn in ./customer.helper.js
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };
        }

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    }

    updateCustomerInfoEditWithValidatedAddress(validatedAddress = {}) {
        if (validatedAddress.Status) {
            this.actions.updateCustomerInfoEditCopy(Object.assign({}, this.state.customerInfoEdit, formatValidatedAddress(validatedAddress), {
                HomeCountry: validatedAddress.Country
            }));
        }
    }

    shouldValidateAddressWithAPIForServiceability() {
        const customerInfo = this.state.customerInfoEdit;
        return !!customerInfo.addressLine1 &&
            !!customerInfo.city && !!customerInfo.country &&
            !!customerInfo.postalCode && !!customerInfo.stateRegionProvince;
    }

    changeOfferClick() {
        this.isLoading = true;
        const saveOrderAction = this.state.createdCustomer ? this.actions.updateCustomerAndSaveOrder : this.actions.createCustomerAndSaveOrder;

        saveOrderAction(
            this.state.createdCustomer ? this.state.createdCustomer : this.state.customerInfoEdit,
            this.state.createdCustomer ? this.state.customerInfo : this.state.shoppingCart,
            this.state.createdCustomer ? this.state.shoppingCart : undefined
        ).then((response) => {
            const offer = pathOr(null, ['ShoppingCart', 'Items', '0'], response);

            this.actions.setChangeOfferingInstanceId(offer.OfferingInstanceId);
            this.actions.setChangeOfferingId(offer.OfferingId);

            this.deregisterUnsavedChanges();
            this.$state.go(ADD_OFFER_ORDER_URL, {
                'customerId': this.state.createdCustomer.Subscriber.Id
            });
        }).catch((error) => {
            this.uiNotificationService.transientError(error.translatedMessage);
            // Intentionally not setting isLoading to false on .finally() due to it loading other api calls through the
            // process of transitioning to the add flow wizard
            this.isLoading = false;
        });
    }

    editOfferClick() {
        this.actions.setPaymentInfo(this.state.paymentInfoEdit);
        this.actions.goToStep(CustomerCareKeys.WIZARD.STEPS.DECISIONS);
        this.closeViewFullCartPopupDialog();
    }

    onMoreActionSelected(action) {
        switch (action) {
            case MINI_CART_MORE_ACTIONS.EDIT_SINGLE_OFFER:
                this.editOfferClick();
                break;
            case MINI_CART_MORE_ACTIONS.CHANGE:
                this.changeOfferClick();
                break;
            default:
                break;
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    //Below function is a promise
    proceedToFetchOffers() {
        this.isLoading = true;
        this.actions.updateCustomerInfo(this.state.customerInfoEdit);
        return this.actions.navigateToFetchOffers()
            .then(() => {
                this.wizardForm.$setPristine();
                return Promise.resolve();
            });
    }

    onOverrideCalculateQuote() {
        this.overrideBypassQuoteConfiguration = true;
        this.fetchQuote(this.state.customerInfo);
    }
}

export default {
    template: require('./new.connect.wizard.html'),
    controller: NewConnectWizardController,
    controllerAs: 'newConnectController'
};
