import angular from 'angular';

import {routing} from './edit.offer.wizard.config';
import SelectAffectedServicesPopupComponent from '../../../../wizards/steps/decisions/selectAffectedServicesPopup/select.affected.services.popup.component';
import EditOfferWizardComponent from './edit.offer.wizard.component';

export default angular.module('index.customercare.customer.orders.editoffer', [])
    .component('editOffer', EditOfferWizardComponent)
    .component('selectAffectedServicesPopup', SelectAffectedServicesPopupComponent)
    .config(routing)
    .name;
