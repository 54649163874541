import {stateGo} from 'redux-ui-router';
import {
    RouteParams,
    RouteCurrentStateSelector
} from 'invision-core/src/components/router/router.selectors';
import i18n from 'invision-core/src/components/i18n/i18n';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import CustomerLocaleKeys from '../../../../locales/keys';
// import {
//     OrderHistoryTab,
// } from '@invision/customercare2/src/customer/orderHistory';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypesThunk} from 'invision-core/src/components/metadata/codes/codes.actions';
import {MetadataCodeLoadedSelector} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {ORDER_HISTORY_LIST_STATE_NAME} from '../order.history.config';
import {GROUP_ORDER_HISTORY_LIST_STATE_NAME} from './order.history.list.wrapper.config';
import {GroupOrderFeatureVisibilitySelector} from '../../../../reducers/selectors/order.history.selectors';

class OrderHistoryListWrapperController {
    constructor($ngRedux, $timeout, $state, $location) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            $state,
            $location,
            CustomerLocaleKeys,
            onTabSelected: this.onTabSelected.bind(this),
            // OrderHistoryTab
        });
    }

    $onInit() {
        const controllerActions = {
            fetchCodeTypesThunk,
            stateGo
        };
        const mapStateToTarget = (store) => {
            return {
                isDbss: IsDbss(store),
                isFeatureToggleCodesLoaded: MetadataCodeLoadedSelector(CODES.FeatureToggleConfig, store),
                isGroupOrderEnabled: GroupOrderFeatureVisibilitySelector(store),
                routeParams: RouteParams(store),
                routeState: RouteCurrentStateSelector(store),
            };
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });
        if (!this.state.isFeatureToggleCodesLoaded) {
            this.actions.fetchCodeTypesThunk(CODES.FeatureToggleConfig);
        }
        this.resetTabs(this.state.routeState.url);
    }

    resetTabs(currentTab) {
        this.tabs = [{
            name: i18n.translate(CustomerLocaleKeys.ORDER_HISTORY.ORDERS),
            state: ORDER_HISTORY_LIST_STATE_NAME,
            active: true,
            id: 'orders'
        }, {
            name: i18n.translate(CustomerLocaleKeys.ORDER_HISTORY.GROUP_ORDERS),
            state: GROUP_ORDER_HISTORY_LIST_STATE_NAME,
            active: false,
            id: 'group-orders'
        }];
    }

    onTabSelected(selected) {
        this.resetTabs(selected.id);
        this.actions.stateGo(selected.state, {
            ...this.state.routeParams
        });
    }
    $onDestroy() {
        this.disconnectRedux();
    }
}

export default {
    controller: OrderHistoryListWrapperController,
    template: require('./order.history.list.wrapper.template.html')
};
