import {
    FormHelper
} from 'invision-core';
import pathOr from 'ramda/src/pathOr';
import {NOTIFICATION_TIME_LENGTH} from '../../../../customercare.constants';
import CustomerCareKeys from '../../../../locales/keys';
import {DriversLicenseStateRegionProvinceValueOptions,
    StateRegionProvinceValueOptions} from '../new.connect.wizard.selectors';
import {CartAdditionalPropertiesSelector,
    CreatedCustomerSelector,
    EditCustomerInfoSelector,
    IsCreatingOrUpdatingCustomerSelector} from '../../../../reducers/selectors/new.connect.wizard.selectors';
import {PreventOverrideFlagSelector} from '../../../../reducers/selectors/customer.addresses.selectors';
import {
    createCustomerAndSaveOrder,
    updateCustomerAndSaveOrder,
    updateCustomerInfoEditCopy
} from '../../../../reducers/actions/new.connect.wizard.actions';
import {updateOverrideFlag} from '../../../../reducers/actions/customer.addresses.actions';
import {ShoppingCartSelector} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {getErrorLookupObject} from '../../../createCustomer/create.customer.constants';
import {apFormHasErrors} from '../../../main/customercare.page.helper';
import {clone} from 'ramda';
import {
    UnvalidatedAddressesSelector,
    ValidatedAddressesSelector
} from '../../../../reducers/selectors/address.component.selectors';
import {isAddressValidFromAddressResponse} from '../../../shared/contextualComponents/address/address.validator.helper';
import {
    setForceShowEditFormAddress,
    validateAddress
} from '../../../../reducers/actions/address.component.actions';
import {EnableProspectLiteSelector} from '../../../../reducers/selectors/customer.selectors';

function SaveOrderController($ngRedux, $state, $timeout, uiNotificationService) {
    let disconnectRedux;
    let popupApi = null;

    const mapStateToTarget = (store) => {
        return {
            addressStates: StateRegionProvinceValueOptions(store),
            cartAdditionalProperties: CartAdditionalPropertiesSelector(store),
            createdCustomer: CreatedCustomerSelector(store),
            driversLicenseStateOptions: DriversLicenseStateRegionProvinceValueOptions(store),
            editCustomerInfo: EditCustomerInfoSelector(store),
            enableProspectLite: EnableProspectLiteSelector(store),
            isCreatingCustomer: IsCreatingOrUpdatingCustomerSelector(store),
            preventOverrideFlag: PreventOverrideFlagSelector(store),
            shoppingCart: ShoppingCartSelector(store),
            unvalidatedAddresses: UnvalidatedAddressesSelector(store),
            validatedAddresses: ValidatedAddressesSelector(store)
        };
    };

    this.$onInit = () => {
        const controllerActions = {
            createCustomerAndSaveOrder,
            setForceShowEditFormAddress,
            updateCustomerAndSaveOrder,
            updateCustomerInfoEditCopy,
            updateOverrideFlag,
            validateAddress
        };

        disconnectRedux = $ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.customerCareKeys = CustomerCareKeys;
        this.showForm = false;
        this.saveOrderFormErrors = [];

        // hook the popupApi
        const onRegisterApi = this.config.onRegisterApi;
        this.config.onRegisterApi = ({api: api}) => {
            popupApi = Object.assign({}, api, {
                open: () => {
                    this.showForm = true;
                    // NOTE: must wait for the ng-if bound to showForm to operate, for the popup centering logic
                    $timeout(() => {
                        this.editCustomerInfoRevertCopy = clone(this.state.editCustomerInfo);
                        api.open();
                    });
                }
            });
            onRegisterApi && onRegisterApi({
                api: popupApi
            });
        };

        this.handleFormChanged = (customerModel) => {
            this.actions.updateCustomerInfoEditCopy(customerModel);
        };
    };

    this.cancelSaveOrder = () => {
        this.actions.updateCustomerInfoEditCopy(this.editCustomerInfoRevertCopy);
        this.closePopup();
    };

    this.closePopup = () => {
        popupApi.close();
        this.showForm = false;
    };

    this.onFailure = (err) => {
        const errMessage = err.translatedMessage || err.Message;
        this.saveOrderFormErrors.push({
            description: errMessage
        });
        uiNotificationService.error(errMessage, null, {
            timeOut: NOTIFICATION_TIME_LENGTH
        });
    };

    this.onSubmitSaveOrderForm = () => {
        const ngFormErrors = this.formApi.createCustomerForm.$error;
        const saveOrderFormNonApErrors = FormHelper.formHasErrors(ngFormErrors, getErrorLookupObject());

        const apFormErrors = this.formApi.createCustomerForm.addlApi ? this.formApi.createCustomerForm.addlApi.$error : null;
        const apErrors = apFormHasErrors(apFormErrors);
        this.saveOrderFormErrors = saveOrderFormNonApErrors.concat(apErrors);

        if (this.saveOrderFormErrors.length === 0  && !this.state.isCreatingCustomer) {
            this.validateAddressWithAPI()
                .then(() => {
                    const addressKey = this.getAddressKey();
                    const validatedAddress = this.state.validatedAddresses[addressKey];
                    if (!addressKey || (validatedAddress && isAddressValidFromAddressResponse(validatedAddress))) {

                        const shoppingCart = this.state.cartAdditionalProperties.length ? Object.assign({}, this.state.shoppingCart, {
                            AdditionalProperties: this.state.cartAdditionalProperties
                        }) : this.state.shoppingCart;

                        if (!this.state.createdCustomer) {
                            return this.actions.createCustomerAndSaveOrder(this.state.editCustomerInfo, shoppingCart, null, null, null, this.state.preventOverrideFlag)
                                .then(() => {
                                    this.onSuccess();
                                    this.actions.updateOverrideFlag(null);
                                })
                                .catch((err) => {
                                    this.onFailure(err);
                                });
                        } else {
                            return this.actions.updateCustomerAndSaveOrder(this.state.createdCustomer, this.state.editCustomerInfo, shoppingCart)
                                .then(() => {
                                    this.onSuccess();
                                })
                                .catch((err) => {
                                    this.onFailure(err);
                                });
                        }
                    }
                })
                .catch((err) => {
                    this.onFailure(err);
                });
        }
    };

    this.$onDestroy = () => {
        disconnectRedux();
    };

    this.getAddressKey = () => {
        return pathOr(undefined, ['formApi', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'addressKey'], this);
    };

    this.getAddressKeyForAddressValidatedThroughAPI = () => {
        return pathOr(undefined, ['formApi', 'createCustomerForm', 'addressWrapupController.singleAddressFormFormApi', 'keyForAddressValidatedThroughAPI'], this);
    };

    this.getJobToken = () => {
        const addressKey = this.getAddressKey(),
            addressKeyForAddressValidatedThroughAPI = this.getAddressKeyForAddressValidatedThroughAPI(),
            validatedAddressForCurrentAddress = this.state.validatedAddresses[addressKey],
            validatedAddressForAddressValidatedThroughAPI = this.state.validatedAddresses[addressKeyForAddressValidatedThroughAPI];

        if (validatedAddressForCurrentAddress && validatedAddressForCurrentAddress.JobToken) {
            return validatedAddressForCurrentAddress.JobToken;
        } else if (validatedAddressForAddressValidatedThroughAPI && validatedAddressForAddressValidatedThroughAPI.JobToken) {
            return validatedAddressForAddressValidatedThroughAPI.JobToken;
        }

        return null;
    };

    this.validateAddressWithAPI = () => {
        const addressKey = this.getAddressKey(),
            unvalidatedAddress = this.state.unvalidatedAddresses[addressKey],
            validatedAddress = this.state.validatedAddresses[addressKey],
            addressTypes = {
                DefaultBilling: true,
                DefaultHome: true,
                DefaultPostal: true,
                DefaultService: true
            };

        if (validatedAddress || !unvalidatedAddress) {
            return Promise.resolve(100);
        } else {
            return this.actions.validateAddress(addressKey, unvalidatedAddress, this.getJobToken(), addressTypes);
        }
    };

}

export default {
    template: require('./save.order.popup.html'),
    bindings: {
        config: '<',
        onSuccess: '&'
    },
    controllerAs: 'SaveOrderController',
    controller: SaveOrderController
};
