import {
    PAYMENT_ACCESS,
    PAYMENT_ACTIVITY_VIEW_ACCESS
} from './../../../security.attributes';
import {resetBillingLedger} from '../../../reducers/actions/customer.billing.actions';

export function routing($stateProvider) {
    $stateProvider.state('index.customercare.customer.billingpayments.list', {
        url: '/list',
        template: '<billing-payments-list></billing-payments-list>',
        resolve: {
            resetSearchBar: ($ngRedux) => {
                $ngRedux.dispatch(resetBillingLedger());
                return Promise.resolve();
            }
        },
        securityAttributes: [PAYMENT_ACCESS, PAYMENT_ACTIVITY_VIEW_ACCESS]
    });
}
