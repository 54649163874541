import angular from 'angular';

import 'invision-core';

import ModifyPaymentMethodPopup from './modify.payment.method.popup.component';

export default angular.module('invision.paymentMethodSwitcher.modifyPaymentMethodPopup', [
    'invision.core.components'
])
    .component('modifyPaymentMethodPopup', ModifyPaymentMethodPopup)
    .name;
