import CustomerCareLocaleKeys from '../../../../../locales/keys';
import moment from 'moment';
import {CandidateBillCyclesSelector} from '../../../../../reducers/selectors/customer.billcycle.selectors';
import {SCHEDULED_ORDER_OPTIONS} from '../../../../../customercare.constants';
import {CurrentCustomerIdSelector} from '../../../../../reducers/selectors/customer.selectors';
import {
    setExecutionOptions,
    updateFdoExecutionDate
} from '../../../../../reducers/actions/order.details.actions';
import {
    GetMaxDateForFutureDatedOrder,
    IsFutureDatedOrderAllowedInBunt
} from '../../../../../reducers/selectors/selected.offering.order.selectors';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';


class ChangeFdoDateModalComponent {
    constructor($ngRedux) {
        Object.assign(this, {
            $ngRedux,
            CustomerCareLocaleKeys,
            doErrorsExistOnFdoForm: this.doErrorsExistOnFdoForm.bind(this),
            executionOptionsMinDate: moment(new Date()).startOf('day').utc().toISOString(),
            onExecutionOptionChange: this.onExecutionOptionChange.bind(this),
            SCHEDULED_ORDER_OPTIONS
        });

        this.executionOptionsModel = this.defaultExecutionOptions;
    }

    $onInit() {
        const controllerActions = {
            fetchCodeTypes,
            setExecutionOptions,
            updateFdoExecutionDate
        };
        const mapStateToTarget = (store) => {
            return {
                candidateBillCycles: CandidateBillCyclesSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                fdoAllowedInBunt: IsFutureDatedOrderAllowedInBunt(store),
                fdoConfiguration: MetadataCodeTypeSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoConfigurationLoaded: MetadataCodeLoadedSelector(CODES.FutureDatedOrderConfiguration, store),
                fdoMaxDate: GetMaxDateForFutureDatedOrder(store)
            };
        };

        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        this.setDefaultBillCycle();

        if (!this.state.fdoConfigurationLoaded) {
            this.actions.fetchCodeTypes(CODES.FutureDatedOrderConfiguration);
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    close() {
        this.actions.setExecutionOptions({});
        this.onClose()();
    }

    setDefaultBillCycle() {
        this.selectedBillCycle = this.state.candidateBillCycles[0];
    }

    onExecutionOptionChange(executionOptionsModel) {
        this.actions.setExecutionOptions(executionOptionsModel);
    }

    doErrorsExistOnFdoForm(isFormValid) {
        this.errorsExistOnForm = isFormValid;
    }

    submit() {
        this.changeFdoForm.$submitted = true;
        if (!this.errorsExistOnForm) {
            this.onSuccess()();
        }
    }
}
export default {
    controller: ChangeFdoDateModalComponent,
    controllerAs: 'ChangeFdoDateModalController',
    template: require('./change.fdo.date.modal.html'),
    bindings: {
        billingEffectiveDateOptions: '<?',
        billingEffectiveDateSettings: '<?',
        contentTitle: '<',
        dialogTitle: '<',
        executionOptions: '<',
        isBillingEffectiveDateShown: '<?',
        onClose: '&',
        onSuccess: '&'
    }
};
