import angular from 'angular';
import ProfileComponent from './profile.component';
import ExternalAuthorizationsComponent from './externalAuthorizations/externalAuthorizations.component';
import {react2angular} from 'react2angular';
import {ProfileMoreMenuMountPoint} from '@invision/customercare2/src/shared/components/contextualComponents/ProfileMoreMenu/ProfileMoreMenu.mountPoint';
import MandatoryConsentsPopupComponent from './mandatoryConsentsPopup/mandatory.consents.popup.component';
import {CreditCheckModalMountPoint} from '@invision/customercare2/src/shared/components/contextualComponents/CreditCheckModal/CreditCheckModal.MountPoint';
import {routing as RoutingConfig} from './profile.config';

export default angular.module('invision.customercare.customer.profile', [])
    .config(RoutingConfig)
    .component('customerProfile', ProfileComponent)
    .component('externalAuthorizations', ExternalAuthorizationsComponent)
    .component('mandatoryConsentsPopup', MandatoryConsentsPopupComponent)
    .component('profileMoreMenu', react2angular(ProfileMoreMenuMountPoint, ['callRetrieveSubscriber']))
    .component('creditCheckModal', react2angular(CreditCheckModalMountPoint, [
        'isOpen',
        'onClose',
        'customerDetails',
        'saveCreditCheckDetails',
        'susbcriberCreditCheckDetails'
    ]))
    .name;
