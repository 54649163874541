import {CustomerCareInventoryWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';

export const INVENTORY_STATE_NAME = 'index.customercare.customer.inventory';

export default ($stateProvider) => {
    $stateProvider.state(INVENTORY_STATE_NAME, {
        template: '<inventory></inventory>',
        url: '/inventory/:serialNumber/:inventoryId',
        params: {
            inventoryId: '',
            serialNumber: ''
        },
        data: {
            windowTitle: CustomerCareInventoryWindowTitleSelector,
        },
    });
};
