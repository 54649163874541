import pluck from 'ramda/src/pluck';

import CustomerCareKeys from '../../../../locales/keys';
import {BILLER_RULE_INSTANCE_TYPE} from '../../../../customercare.constants';
import {FREQUENCY_TYPES} from 'invision-core/src/constants/frequency.types.constants';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
class LifeCycleStepsDialogController {
    constructor() {
        Object.assign(this, {
            BILLER_RULE_INSTANCE_TYPE,
            CoreLocaleKeys,
            CustomerCareKeys,
            getCurrentStepOfLifeCycle: this.getCurrentStepOfLifeCycle.bind(this),
            getDurationPeriodType: this.getDurationPeriodType.bind(this),
            saveLifeCycleForPricingPlan: this.saveLifeCycleForPricingPlan.bind(this),
            selectLifeCycle: this.selectLifeCycle.bind(this)
        });
    }

    $onInit() {
        this.lifeCyclePricingPlanDetails = this.lifeCycleDetails;
        this.lifeCycleBriDetails = this.lifeCycleChargeDetails;

        if (this.multipleLifeCycles) {
            const selectedLifeCycle = this.multipleLifeCycles.find((lifeCycle) => {
                return lifeCycle.lifeCycleDetails.Id === this.defaultLifeCycleId;
            });
            this.selectedLifeCycleId = selectedLifeCycle ? selectedLifeCycle.lifeCycleDetails.Id : this.multipleLifeCycles[0].lifeCycleDetails.Id;
            this.selectedLifeCycleIsEditable = selectedLifeCycle ? selectedLifeCycle.isEditable : this.multipleLifeCycles[0].isEditable;
            this.showEditLifeCycleMessage = pluck('isEditable', this.multipleLifeCycles).every((item) => {
                return item === true;
            });
        }

    }

    $onChanges(changeObj) {
        if (changeObj.lifeCycleDetails && changeObj.lifeCycleDetails.currentValue) {
            this.lifeCyclePricingPlanDetails = this.lifeCycleDetails;
            this.lifeCycleBriDetails = this.lifeCycleChargeDetails;
        }
    }

    selectLifeCycle(lifeCycleId, isEditable) {
        this.selectedLifeCycleId = lifeCycleId;
        this.selectedLifeCycleIsEditable = isEditable;
    }

    getDialogTitle() {
        if (this.multipleLifeCycles && this.disableLifeCycles) {
            return i18n.translate(CustomerCareKeys.LIFE_CYCLE.VIEW_LIFE_CYCLE);
        } else if (this.multipleLifeCycles) {
            return this.disableLifeCyclesEditOfferModal ? i18n.translate(CustomerCareKeys.LIFE_CYCLE.VIEW_LIFE_CYCLE):
                i18n.translate(CustomerCareKeys.LIFE_CYCLE.EDIT_LIFE_CYCLE_TITLE);
        } else {
            return i18n.translate(CustomerCareKeys.LIFE_CYCLE.LIFE_CYCLE_TITLE);
        }
    }

    saveLifeCycleForPricingPlan() {
        this.saveLifeCycle()(this.selectedLifeCycleId, this.selectedLifeCycleIsEditable);
    }

    getCurrentStepOfLifeCycle(pricingPlan, lifeCycleId) {
        return (pricingPlan.Id === this.activeLifeCyclePlan) && (this.selectedLifeCycleId === lifeCycleId);
    }

    getDurationPeriodType(durationPeriodType) {
        switch (durationPeriodType) {
            case FREQUENCY_TYPES.DAILY:
                return i18n.translate(CoreLocaleKeys.PERIOD.DAYS);
            case FREQUENCY_TYPES.HOURLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.HOURS);
            case FREQUENCY_TYPES.MONTHLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.MONTHS);
            case FREQUENCY_TYPES.WEEKLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.WEEKS);
            case FREQUENCY_TYPES.YEARLY:
                return i18n.translate(CoreLocaleKeys.PERIOD.YEARS);
            default:
                return '';
        }
    }
}

export default {
    template: require('./life.cycle.steps.dialog.html'),
    bindings: {
        activeLifeCyclePlan: '<?',
        currentLifeCyclePlan: '<?',
        defaultLifeCycleId: '<?',
        disableLifeCycles: '<?',
        disableLifeCyclesEditOfferModal: '<?',
        editOfferLifeCyclesInProductEditable: '<?',
        hideHighlightPlan: '<?',
        isLarge: '<?',
        isMeduim: '<?',
        lifeCycleChargeDetails: '<',
        lifeCycleDetails: '<',
        multipleLifeCycles: '<?',
        onClose: '&',
        saveLifeCycle: '&?'
    },
    controller: LifeCycleStepsDialogController
};
