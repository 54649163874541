import {CustomerCareOfferingsWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';

export const OFFERINGS_STATE_NAME = 'index.customercare.customer.offerings';

export default ($stateProvider) => {
    $stateProvider.state(OFFERINGS_STATE_NAME, {
        url: '/offerings/:offeringInstanceId',
        template: '<offerings class="c-content-container c-content-containHeight"></offerings>',
        data: {
            windowTitle: CustomerCareOfferingsWindowTitleSelector
        },
        params: {
            offeringInstanceId: {
                value: null,
                squash : true
            }
        }
    });
};
