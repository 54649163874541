import {retrieveOfferingsMetadata} from 'invision-core/src/components/metadata/offerings/offerings.actions';
import {fetchCodeTypes} from 'invision-core/src/components/metadata/codes/codes.actions';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {
    IsOfferFeatureEnabled,
    MetadataCodeLoadedSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {retrieveSubscriberOfferingDetail} from '../../../../../reducers/actions/customer.actions';
import {AccountOfferingsSelector} from '../../../../../reducers/selectors/dashboard.selectors';
import {
    CurrentCustomerSelector,
    CurrentCustomerIdSelector,
    SelectedTabServicesAndOffersSelector
} from '../../../../../reducers/selectors/customer.selectors';
import {
    ConvergentBillerAccountDetailsSelector,
    IsFetchingAccountDetailsSelector
} from '../../../../../reducers/selectors/customer.convergent.biller.selectors';
import {
    SelectedOfferingAndOptionsSelector,
    SelectedSubscriberOfferingInstanceIdSelector
} from '../../../../../reducers/selectors/search.subscriber.offerings.selectors';
import localeKeys from '../../../../../locales/keys';
import {SERVICES_AND_OFFERS_TABS} from '../servicesAndOffers/services.and.offers.contants';

class OfferingListController {
    constructor($ngRedux, $timeout, uiNotificationService) {
        Object.assign(this, {
            $ngRedux,
            $timeout,
            localeKeys,
            SERVICES_AND_OFFERS_TABS,
            uiNotificationService
        });
    }

    $onInit() {
        const mapStateToTarget = (store) => {
            return {
                accountDetails: ConvergentBillerAccountDetailsSelector(store),
                currentCustomer: CurrentCustomerSelector(store),
                currentCustomerId: CurrentCustomerIdSelector(store),
                featureConfigurationsLoaded: MetadataCodeLoadedSelector(CODES.FeatureConfiguration, store),
                isFetchingAccountDetails: IsFetchingAccountDetailsSelector(store),
                isItvOffersEnabled: IsOfferFeatureEnabled(store),
                newOfferWidgetSelectedTab: SelectedTabServicesAndOffersSelector(store),
                offerings: AccountOfferingsSelector(store),
                selectedOffering: SelectedOfferingAndOptionsSelector(store),
                selectedOfferingInstanceId: SelectedSubscriberOfferingInstanceIdSelector(store),
                subscriptionBillingCyclesLoaded: MetadataCodeLoadedSelector(CODES.SubscriptionBillingCycle, store),
            };
        };
        const controllerActions = {
            fetchCodeTypes,
            retrieveOfferingsMetadata,
            retrieveSubscriberOfferingDetail
        };
        this.disconnectRedux = this.$ngRedux.connect(mapStateToTarget, controllerActions)((state, actions) => {
            this.state = state;
            this.actions = actions;
        });

        if (this.state.isItvOffersEnabled || !this.state.featureConfigurationsLoaded) {
            if (!this.state.subscriptionBillingCyclesLoaded) {
                this.actions.fetchCodeTypes(CODES.SubscriptionBillingCycle);
            }
        }
    }

    fetchOfferingDetails() {
        if (this.state.selectedOfferingInstanceId) {
            this.actions.retrieveSubscriberOfferingDetail(this.state.currentCustomerId, this.state.selectedOfferingInstanceId).then(() => {
                return this.actions.retrieveOfferingsMetadata([this.state.selectedOffering.OfferingId]);
            }).catch((error) => {
                this.uiNotificationService.transientError(error.translatedMessage);
            });
        }
    }

    $onDestroy() {
        this.disconnectRedux();
    }

    onRefreshServices() {
        this.refreshServices && this.refreshServices();
    }
}

export default {
    template: require('./offering.list.deprecated.html'),
    bindings: {
        hasAdminAccess: '<',
        refreshServices: '&?',
        refreshConvergentBillerDetails: '&?'
    },
    controller: OfferingListController,
    controllerAs: 'OfferingList'
};
