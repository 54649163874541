import CustomerCareKeys from '../../../../../../locales/keys';

class DiscretionaryDiscountsController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys
        });
    }
}

export default {
    template: require('./discretionary.discounts.html'),
    bindings: {
        discounts: '<',
        discountsReason: '<',
        onChange: '&',
        onEditDiscountClick: '&',
        onRemoveDiscountClick: '&',
        openDiscountDialog: '&'
    },
    controller: DiscretionaryDiscountsController
};