import {CustomerCareOrderHistoryWindowTitleSelector} from '../../../reducers/selectors/customer.selectors';
import {emptyOrderHistoryList} from '../../../reducers/actions/order.history.actions';
import {removeOrderHistoryFilter} from '../../../reducers/actions/order.history.filter.actions';

export const STATE_OR_NAME = 'index.customercare.customer.orderHistory';
export const ORDER_HISTORY_LIST_WRAPPER_STATE_NAME = `${STATE_OR_NAME}.list`;
export const ORDER_HISTORY_LIST_STATE_NAME = `${ORDER_HISTORY_LIST_WRAPPER_STATE_NAME}.normal`;
export const ORDER_HISTORY_DETAILS_STATE_NAME = `${STATE_OR_NAME}.details`;

export  function routing($stateProvider) {
    $stateProvider.state(STATE_OR_NAME, {
        url: '/orderHistory',
        data: {
            windowTitle: CustomerCareOrderHistoryWindowTitleSelector,
        },
        template: '<ui-view class="c-content-container c-content-containHeight"></ui-view>',
        redirectTo: ORDER_HISTORY_LIST_STATE_NAME,
        onExit: ($ngRedux) => {
            $ngRedux.dispatch(removeOrderHistoryFilter());
            $ngRedux.dispatch(emptyOrderHistoryList());
        }
    }).state(ORDER_HISTORY_LIST_WRAPPER_STATE_NAME, {
        url: '/',
        data: {
            windowTitle: CustomerCareOrderHistoryWindowTitleSelector,
        },
        redirectTo: ORDER_HISTORY_LIST_STATE_NAME,
        template: '<order-history-list-wrapper class="c-content-containHeight"></order-history-list-wrapper>',
    }).state(ORDER_HISTORY_DETAILS_STATE_NAME, {
        url: '/:orderId',
        template: '<order-details></order-details>',
        params: {
            previousRoute: null,
            subscription: null
        }
    });
}
