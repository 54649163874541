// import Immutable from 'seamless-immutable';
import {createSelector} from 'reselect';
// import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {
    OfferingFacetCategorySelector,
    OfferingFacetSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors.deprecated';
import {CurrentCustomerSelector} from '../../../../reducers/selectors/customer.selectors';
import {
    // AddOfferAsyncIdSelector,
    // AddOfferShoppingCartPhysicalInventoriesSelector,
    // ChangeOfferingIdSelector,
    CompletedStepsSelector,
    CurrentStepIndexSelector,
    CurrentStepIsInvalidSelector,
    CurrentStepSelector,
    // EditAttributeGroupsSelector,
    // EditPhysicalAttributeGroupsSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferIdSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    // IsAttributesStepSelector,
    IsCheckoutStepSelector,
    IsDecisionsStepSelector,
    // IsMultiOfferSelector,
    IsOffersStepSelector,
    // MultiOfferShoppingCartSelector,
    // PostQuoteDueTotalsSelector,
    // PreQuoteDueSubTotalsSelector,
    // QuoteCalculatedSelector,
    // SelectedOfferIdSelector,
    SkippedStepSelector,
    // SubTotalSelector,
    // TaxAmountSelector,
    // TotalAmountSelector,
    // TotalDownPaymentSelector
} from '../../../../reducers/selectors/add.services.wizard.selectors';
import {
    ALL_STEPS_KEYS
} from '../../../../reducers/constants/add.services.wizard.constants';
import {
    // AttributeFormSubmittedSelector,
    // CurrentAttributeFormName,
    // CurrentAttributesValidationStatuses,
    // HasBulkShoppingCartItem,
    // InventoryAvailabilitySelector,
    IsUpdatingCartSelector,
    PagesIsFetchingDataSelector,
    // ShoppingCartSelector,
    // SubmittedOrderErrorSelector
} from '../../../../reducers/selectors/selected.offering.order.selectors';
import {
    // AllAttributesAreValidHelper,
    // CurrentActiveAttributeNameHelper,
    // FilteredFormAttributeGroupsHelper,
    // FilteredPhysicalAttributeGroupsHelper,
    // FormAttributeGroupsHelper,
    // FormAttributeGroupsNavigationHelper,
    // FormPhysicalAttributeGroupsHelper,
    GroupedCheckboxFilterOptions,
    // HasRequiredAndUnavailableInventory,
    OfferOptionViewModel,
    // ShowNextButtonHelper,
    // ShowPreviousButtonHelper,
    // SummaryTab,
    // UnavailablePhysicalInventory,
    // IsSendToStoreButtonEnabledSelectorCreator
} from '../../../../reducers/helpers/offer.ordering.wizard.selector.helper';
import {
    BackButtonText,
    ForwardButtonText,
    FormattedName,
    StepProgressBarViewModel
} from '../../../../reducers/helpers/wizard.selector.helper';
import {
    IsPostpaidEnabledSelector,
    IsPrepaidEnabledSelector,
    OffersSelector
} from '../../../../reducers/selectors/offering.order.selectors';
// import {SelectedContractSelector} from '../../../../reducers/selectors/contract.selectors';

const DEFAULT_CUSTOMER = {};
export const CustomerInfoSelector = createSelector(
    [CurrentCustomerSelector],
    (customer) => {
        if (customer) {
            return customer;
        } else {
            return DEFAULT_CUSTOMER;
        }
    }
);

export const FormattedNameSelector = FormattedName([CustomerInfoSelector]);

export const StepProgressBarViewModelSelector = StepProgressBarViewModel(
    [
        CurrentStepSelector,
        CompletedStepsSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const BackButtonTextSelector = BackButtonText(
    [
        CurrentStepIndexSelector,
        CompletedStepsSelector
    ], ALL_STEPS_KEYS
);

export const ForwardButtonTextSelector = ForwardButtonText(
    [
        CurrentStepIndexSelector,
        SkippedStepSelector
    ], ALL_STEPS_KEYS
);

export const GroupedCheckboxFilterOptionsSelector = GroupedCheckboxFilterOptions([
    OfferingFacetCategorySelector,
    OfferingFacetSelector,
    EditSelectedFacetIdsSelector,
    EditSelectedOfferingChargeTypeIdSelector,
    IsPostpaidEnabledSelector,
    IsPrepaidEnabledSelector,
    () => {
        return false;
    }
]);

export const OfferOptionViewModelSelector = OfferOptionViewModel([OffersSelector, EditSelectedOfferIdSelector]); // MultiOfferShoppingCartSelector, ChangeOfferingIdSelector

// export const FormAttributeGroups = FormAttributeGroupsHelper([EditAttributeGroupsSelector]);
// export const FormPhysicalAttributeGroups = FormPhysicalAttributeGroupsHelper([EditPhysicalAttributeGroupsSelector]);
// export const FilteredPhysicalAttributeGroups = FilteredPhysicalAttributeGroupsHelper([FormAttributeGroups, FormPhysicalAttributeGroups, CurrentAttributeFormName]);
// export const AttributeServiceSelector = FormAttributeGroupsNavigationHelper([
//     FormAttributeGroups,
//     // FormPhysicalAttributeGroups,
//     CurrentAttributeFormName,
//     AttributeFormSubmittedSelector,
//     CurrentAttributesValidationStatuses,
//     SubmittedOrderErrorSelector
// ]);
// export const CurrentActiveAttributeNameSelector = CurrentActiveAttributeNameHelper([AttributeServiceSelector]);
// export const ShowPreviousButtonSelector = ShowPreviousButtonHelper([AttributeServiceSelector]);
// export const ShowNextButtonSelector = ShowNextButtonHelper([AttributeServiceSelector]);
// export const FilteredFormAttributeGroups = FilteredFormAttributeGroupsHelper([FormAttributeGroups, AttributeServiceSelector]);
// export const AllAttributesAreValidSelector = AllAttributesAreValidHelper([AttributeServiceSelector, CurrentAttributesValidationStatuses]);


// export const UnavailablePhysicalInventorySelector = UnavailablePhysicalInventory([EditPhysicalAttributeGroupsSelector, InventoryAvailabilitySelector]);
// export const HasRequiredAndUnavailableInventorySelector = HasRequiredAndUnavailableInventory([FormAttributeGroups]);

// export const SummaryTabViewModel = SummaryTab([
//     ShoppingCartSelector,
//     SubTotalSelector,
//     TaxAmountSelector,
//     TotalAmountSelector,
//     () => {
//         return undefined;
//     }, // QuoteCalculatedSelector,
//     SelectedContractSelector,
//     () => {
//         return undefined;
//     },
//     IsCheckoutStepSelector,
//     TotalDownPaymentSelector,
//     PostQuoteDueTotalsSelector,
//     PreQuoteDueSubTotalsSelector,
//     IsMultiOffer,
//     MultiOfferShoppingCartSelector
// ]);


export const IsFetchingAnyDataSelector = createSelector(
    [PagesIsFetchingDataSelector, IsUpdatingCartSelector],
    (isFetchingDecisionsOrAttributes, isUpdatingCart) => {
        return isFetchingDecisionsOrAttributes || isUpdatingCart;
    }
);


// export const IsSendToStoreButtonEnabledSelector = IsSendToStoreButtonEnabledSelectorCreator([
//     AddOfferShoppingCartPhysicalInventoriesSelector,
//     IsAttributesStepSelector,
//     IsCheckoutStepSelector,
//     AddOfferAsyncIdSelector
// ]);

export const IsContinueDisabledSelector = createSelector(
    [
        IsFetchingAnyDataSelector,
        IsDecisionsStepSelector,
        IsOffersStepSelector,
        IsCheckoutStepSelector,
        CurrentStepIsInvalidSelector
    ],
    (isFetchingAnyData, isDecisionsStep, isOffersStep, isCheckoutStep, currentStepIsInvalid) => {
        return isFetchingAnyData ||
            (!isDecisionsStep && !isOffersStep && !isCheckoutStep && currentStepIsInvalid);
    }
);

// export const IsContinueShoppingShownSelector = createSelector(
//     [
//         HasBulkShoppingCartItem,
//         IsAttributesStepSelector,
//         IsCheckoutStepSelector,
//         IsDbss
//     ],
//     (hasBulkShoppingCartItem, isAttributes, isCheckout, isDbss) => {
//         // More to be added as part of ASCFTR-2830 for validation
//         return Immutable(isDbss && !hasBulkShoppingCartItem && (isAttributes || isCheckout));
//     }
// );

// export const IsSaveButtonShownSelector = createSelector(
//     [
//         IsAttributesStepSelector,
//         IsCheckoutStepSelector,
//         SelectedOfferIdSelector
//     ],
//     (isAttributesStep, isCheckoutStep, selectedOfferId) => {
//         return Immutable(
//             (selectedOfferId !== null && isAttributesStep) || isCheckoutStep);

//     }
// );
