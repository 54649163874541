import angular from 'angular';

import DevicesComponent from './devices.component';
import DevicesDetailComponent from './detail/devices.detail.component';
import DevicesListComponent from './list/devices.list.component';
import RoutingConfig from './devices.config';
import UpdateDevicePopUp from './updateDevice/update.device.popup.component';

export default angular.module('invision.customercare.customer.devices', [])
    .config(RoutingConfig)
    .component('customerDevices', DevicesComponent)
    .component('customerDevicesDetail', DevicesDetailComponent)
    .component('customerDevicesList', DevicesListComponent)
    .component('updateDevicePopup', UpdateDevicePopUp)
    .name;
