import CustomerCareKeys from '../../../../../../locales/keys';

class CartItemWithActionsController {
    constructor() {
        Object.assign(this, {
            CustomerCareKeys,
            handleMoreOptionsMenuSelected: this.handleMoreOptionsMenuSelected.bind(this)
        });
    }

    handleMoreOptionsMenuSelected(option) {
        this.onActionSelected()(option.item.id, this.cartItemId, this.cartItemOfferingId);
    }
}

export default {
    template: require('./cart.item.with.actions.html'),
    bindings: {
        cartItemId: '<',
        cartItemName: '<',
        cartItemOfferingId: '<',
        delimitedListData: '<?',
        delimitedListAmount: '<?',
        menuItems: '<',
        onActionSelected: '&'
    },
    controller: CartItemWithActionsController
};
