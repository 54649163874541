import localeKeys from '../../../../locales/keys';

class SelectedInventoryStoreController {
    constructor() {
        Object.assign(this, {
            formattedSelectedStoreString: '',
            localeKeys
        });
    }

    formatSelectedStoreString() {
        const {LineOne, LineTwo, City, StateProvince, Zipcode} = this.selectedStore.AdditionalProperties;
        this.formattedSelectedStoreString
            = `${this.selectedStore.Name}, ${LineOne} ${LineTwo}, ${City}, ${StateProvince}, ${Zipcode}`;
    }

    $onChanges(change) {
        if (change.selectedStore && !!change.selectedStore.currentValue) {
            this.formatSelectedStoreString();
        }
    }
}

export default {
    bindings: {
        selectedStore: '<',
        onSelectStore: '&'
    },
    template: require('./selected.inventory.store.template.html'),
    controller: SelectedInventoryStoreController
};
