export const CUSTOMER_REMOVE_OFFER_URL = 'index.customercare.customer.removeoffer';

export function config($stateProvider) {
    $stateProvider.state(CUSTOMER_REMOVE_OFFER_URL, {
        template: '<remove-offer class="u-flexKeepHeight"></remove-offer>',
        url: '/removeOffer?offeringId&offeringInstanceId&subscriptionId&currency',
        params: {
            offeringId: null,
            offeringInstanceId: null,
            subscriptionId: null,
            currency: null
        }
    });
}
