import CustomerCareLocaleKeys from '../../locales/keys';
import {i18n} from 'invision-core';

export function apFormHasErrors(ngFormError) {
    const errorList = [];

    if (ngFormError) {
        const errorTypes = Object.keys(ngFormError);
        errorTypes.forEach((errorType) => {
            ngFormError[errorType].forEach((errDetail) => {
                const fieldName = errDetail.$name;
                if (errorType === 'required') {
                    errorList.push({
                        description: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.IS_REQUIRED, {
                            field: fieldName
                        })
                    });
                } else {
                    errorList.push({
                        description: i18n.translate(CustomerCareLocaleKeys.CREATE_CUSTOMER_FORM.ERRORS.IS_INVALID, {
                            field: fieldName
                        })
                    });
                }
            });
        });
    }
    return errorList;
}