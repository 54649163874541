import angular from 'angular';
import 'angular-ui-grid';

import {routing} from './main.config';
import {ApplicationHeaderController} from 'invision-core';
import {CustomerCarePageController} from './customercare.page.controller';

import Customer from '../customer/customer.module';
import CreateCustomerPopupModule from '../createCustomerPopup/create.customer.popup.module';

import MainComponent from './main.component';
import AuthenticationModule from 'invision-authentication/src/components/authentication/authentication.module';
import FaultModule from 'invision-authentication/src/components/fault/fault.module';
import LandingPageModule from '../landingPage/landing.page.module';
import {CreateCustomerModalMountPoint} from '@invision/customercare2/src/app/createCustomerModal.mountPoint';
import {react2angular} from 'react2angular';

export default angular.module('invision.customercare.main', [
    Customer,
    CreateCustomerPopupModule,
    FaultModule,
    AuthenticationModule,
    LandingPageModule,
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.moveColumns'
])
    .config(routing)
    .controller('careAppHeaderController', ApplicationHeaderController)
    .component('customerCarePage', {
        template: require('./customercare.page.html'),
        controller: CustomerCarePageController,
        controllerAs: 'customerCarePage'
    })
    .component('main', MainComponent)
    .component('createCustomerModal', react2angular(CreateCustomerModalMountPoint, [
        'onClose',
        'open',
        'isResidential',
        'isCreateCustomer',
        'onError',
        'onSuccess',
        'customerDetails',
        'isNewCustomer'
    ]))
    .name;
