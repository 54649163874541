import UsageChartController from './usage.chart.controller';

export default {
    bindings: {
        initial: '<',
        reserved: '<',
        size: '@',
        unlimited: '<',
        unused: '<',
        used: '<'
    },
    controller: UsageChartController
};
