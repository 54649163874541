import './styles/app.scss';
import angular from 'angular';
import angularDynamicLocale from 'angular-dynamic-locale';
import angularSanitize from 'angular-sanitize';
import invisionUi from 'invision-ui';
import ngRedux from 'ng-redux';
import reduxUiRouter from 'redux-ui-router';
import {UI_ROUTER_REACT_HYBRID} from '@uirouter/react-hybrid';
import '@uirouter/angularjs/lib/legacy/stateEvents';

import {angularLogDecorator} from 'invision-core/src/components/helpers/angular.log.decorator';
import {
    enableCustomLogging,
    setupDefaultLocale,
    setupStateChangeErrorListener,
    setupStateChangeStartDecorator,
    setupStateChangeStartListener,
    setupStateChangeSuccessListener,
    setupStateNotFoundListener
} from 'invision-authentication/src/helpers/app.setup.helpers';

import customerCareModule from './customercare.module';
import authenticationModule from 'invision-authentication/src/components/authentication/authentication.module';
import faultModule from 'invision-authentication/src/components/fault/fault.module';
import downloadModule from 'invision-authentication/src/components/download/download.module';
import {initializeRedux} from './ng.redux.config';

import ConfigurationService from 'invision-core/src/components/configuration/configuration.service';
import EnvironmentService from 'invision-core/src/components/environment/environment.service';
import FaultService from 'invision-authentication/src/components/fault/fault.service';
import SessionService from 'invision-core/src/components/session/session.service';

export default angular.module('invision', [
    ngRedux,
    angularSanitize,
    angularDynamicLocale,
    UI_ROUTER_REACT_HYBRID,
    'ui.router.state.events',
    reduxUiRouter,
    invisionUi,
    authenticationModule,
    customerCareModule,
    faultModule,
    downloadModule
])
    // NOTE: this needs to be the first/only $log decorator to ensure functionality
    .decorator('$log', angularLogDecorator)
    .run(($ngRedux, $log) => {
        enableCustomLogging($ngRedux, $log);
    })
    .config(($locationProvider, $httpProvider, tmhDynamicLocaleProvider, $provide, $ngReduxProvider) => {
        $httpProvider.useApplyAsync(true);
        $locationProvider.html5Mode(true);

        initializeRedux($ngReduxProvider);
        setupDefaultLocale(tmhDynamicLocaleProvider);
        setupStateChangeStartDecorator($provide);
    })
    .factory('ConfigurationService', ConfigurationService)
    .factory('EnvironmentService', EnvironmentService)
    .factory('FaultService', FaultService)
    .factory('SessionService', SessionService)
    .constant('LocaleNamespaces', [
        'core',
        'customercare',
        'ui'
    ])
    .constant('AppBaseState', 'index.customercare')
    .constant('AppSupportedMicroservices', [])
    .run(($rootScope, $state, FaultService, $ngRedux, $popupManager, LocaleNamespaces) => {
        FaultService();

        setupStateChangeStartListener($rootScope, $ngRedux, $state, 'index.customercare', LocaleNamespaces);
        setupStateChangeSuccessListener($rootScope, $ngRedux, $popupManager);
        setupStateChangeErrorListener($rootScope, $state);
        setupStateNotFoundListener($rootScope, $ngRedux, $state, 'index.customercare');
    }).name;
