import angular from 'angular';

import DeviceFinanceDetailsComponent from './deviceFinancing/device.financing.component';
import EditEntitlementModalComponent from './entitlementsGrid/editEntitlement/edit.entitlement.component';
import EditPolicyCounterModalComponent from './policyCounters/editPolicyCounter/edit.policy.counter.component';
import EntitlementsGridComponent from './entitlementsGrid/entitlements.grid.component';
import FinancingAndInventoryComponent from './financingAndInventory/financing.and.inventory.component';
import OfferDetailsComponent from './offerDetails/offer.details.component';
import OfferingThumbnailComponent from './offeringThumbnail/offering.thumbnail.component';
import policyCountersComponent from './policyCounters/policy.counters.component';
import ServiceDetailsComponent from './serviceDetails/service.details.component';
import ServiceFeaturesComponent from './serviceFeatures/service.features.component';
import ServiceLifecycleStatusDetailsComponent from './serviceLifecycleStatusDetails/service.lifecycle.status.details.component';
import ServicesAndOffersWidget from './services.and.offers.component';
import ServiceTaxCustomizationDetailsComponent from './serviceTaxCustomizationDetails/service.tax.customization.details.component';
import ServiceTaxDetailsComponent from './serviceTaxDetails/service.tax.details.component';
import ServiceTaxCustomizationDialogComponent from './serviceTaxCustomizationDialog/service.tax.customize.dialog.component';
import ServiceThumbnailComponent from './serviceThumbnail/service.thumbnail.component';
import SharedUsageTabComponent from './sharedUsageTab/shared.usage.tab.component';
import SubscriberOfferComponent from './subscriberOffer/subscriber.offer.component';
import SubscriberServicesComponent from './subscriberServices/subscriber.services.component';
import UsageChargesAndCapsComponent from './usageChargesAndCaps/usage.charges.and.caps.component';


export default angular.module('invision.customercare.customer.dashboard.servicesAndOfferWidget', [])
    .component('deviceFinanceDetails', DeviceFinanceDetailsComponent)
    .component('editEntitlementModal', EditEntitlementModalComponent)
    .component('editPolicyCounterModal', EditPolicyCounterModalComponent)
    .component('entitlementsGrid', EntitlementsGridComponent)
    .component('financingAndInventory', FinancingAndInventoryComponent)
    .component('offerDetails', OfferDetailsComponent)
    .component('offeringThumbnail', OfferingThumbnailComponent)
    .component('policyCounters', policyCountersComponent)
    .component('serviceDetails', ServiceDetailsComponent)
    .component('serviceFeatures', ServiceFeaturesComponent)
    .component('serviceLifecycleStatusDetails', ServiceLifecycleStatusDetailsComponent)
    .component('servicesAndOffersWidget', ServicesAndOffersWidget)
    .component('serviceTaxCustomizationDetails', ServiceTaxCustomizationDetailsComponent)
    .component('serviceTaxDetails', ServiceTaxDetailsComponent)
    .component('serviceTaxCustomizationDialog', ServiceTaxCustomizationDialogComponent)
    .component('serviceThumbnail', ServiceThumbnailComponent)
    .component('subscriberServices', SubscriberServicesComponent)
    .component('subscriberOffer', SubscriberOfferComponent)
    .component('usageChargesAndCaps', UsageChargesAndCapsComponent)
    .component('sharedUsageTab', SharedUsageTabComponent)
    .name;
