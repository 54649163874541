import localeKeys from '../../../../../locales/keys';

class TransactionDetailsStripeController {
    constructor() {
        this.localeKeys = localeKeys;
    }
}

export default {
    template: require('./transaction.details.stripe.html'),
    bindings: {
        transaction: '<'
    },
    controller: TransactionDetailsStripeController
};
