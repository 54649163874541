import angular from 'angular';
import 'angular-ui-grid';

import {routing as RoutingConfig} from './detail.config';
import BillingPaymentsDetail from './detail.component';

export default angular.module('invision.customercare.customer.billingpayments.detail', [
    'ui.grid',
    'ui.grid.autoResize',
    'ui.grid.moveColumns'
])
    .config(RoutingConfig)
    .component('billingPaymentsDetail', BillingPaymentsDetail)
    .name;
