import i18n from 'invision-core/src/components/i18n/i18n';
import CustomerCareLocaleKeys from '../../../../locales/keys';

export const SORT_FIELD_NAME = {
    CHARGE: 'charge',
    DATE: 'dateTime',
    USAGE: 'usage'
};

export const USAGE_SORT_FIELD = {
    [SORT_FIELD_NAME.DATE]: 0,
    [SORT_FIELD_NAME.USAGE]: 1,
    [SORT_FIELD_NAME.CHARGE]: 2
};

export const USAGE_SORT_DIRECTION = {
    ASCENDING: 1,
    DESCENDING: 2
};

export const USAGES_LIMIT_WARNING_CODE = 1365;

export const ENTITLEMENT_ACTIVATION_STATUS = {
    //ENTITLEMENT_INACTIVE: 0,
    // ENTITLEMENT_ACTIVE:1,
    AWAITING_ENTITLEMENT_FIRST_USE:2,
    AWAITING_SERVICE_FIRST_USE:3,
    AWAITING_PAYMENT_CONFIRMATION:4,
    AWAITING_ENTITLEMENT_FIRST_USE_WITH_PRICING_PLAN:5,
    // ENTITLEMENT_EXPIRED:20,
    //ENTITLEMENT_RENEWED:21,
    //ENTITLEMENT_PRODUCT_REMOVED:22
};

export const getColumnDefinitions = (uiGridConstants) => {
    return [{
        field: SORT_FIELD_NAME.DATE,
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.DATE_TIME),
        cellTemplate: require('./cellTemplates/datetime.template.html'),
        enableSorting: true,
        type: 'numberStr',
        sort: {
            direction: uiGridConstants.DESC
        }
    },
    {
        field: 'processDate',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.PROCESS_DATE),
    },
    {
        field:'callCategory',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.CALL_CATEGORY),
        enableSorting: true
    },
    {
        field:'entitlementName',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.ENTITLEMENT_NAME),
        enableSorting: true
    },
    {
        field: 'direction',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.DIRECTION),
    }, {
        field: 'receiverWithLocation',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.TO),
        cellTemplate: require('./cellTemplates/receiver.with.location.template.html')
    }, {
        field: 'senderWithLocation',
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.FROM),
        cellTemplate: require('./cellTemplates/sender.with.location.template.html')
    }, {
        field: SORT_FIELD_NAME.USAGE,
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.USAGE_ACTUAL),
        cellTemplate: require('./cellTemplates/usage.template.html'),
        enableSorting: true
    }, {
        field: SORT_FIELD_NAME.CHARGE,
        displayName: i18n.translate(CustomerCareLocaleKeys.USAGE_DETAILS.TABLE_HEADERS.CHARGE),
        cellClass: 'c-dataType-number',
        cellTemplate: require('./cellTemplates/charge.template.html'),
        enableSorting: true,
        headerCellClass: 'c-dataType-charge'
    }].map((columnDef) => {
        return Object.assign({
            enableSorting: false,
            enableColumnMoving: false,
            minWidth: 70,
            sortDirectionCycle: [
                uiGridConstants.DESC,
                uiGridConstants.ASC
            ],
            width: '*'
        }, columnDef);
    });
};
