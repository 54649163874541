import {ORDERS_STATE_NAME} from '../orders.config';

export const TRANSFER_STATE_NAME = `${ORDERS_STATE_NAME}.transfer`;

export default ($stateProvider) => {
    $stateProvider.state(TRANSFER_STATE_NAME, {
        url: '/transfer',
        template: '<transfer class="u-flexKeepHeight"></transfer>'
    });
};
