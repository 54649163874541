import coreLocaleKeys from 'invision-core/src/locales/core.locale.keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import localeKeys from '../../../../locales/keys';

class InventoryStoreFinderController {
    constructor($timeout, uiGridConstants) {
        const tableData = {
            appScopeProvider: this,
            data: [],
            enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
            enableRowSelection: true,
            enableSelectAll: false,
            enableSorting: false,
            multiSelect: false,
            selectionRowHeaderWidth: 20,
            columnDefs: [{
                field: 'name',
                displayName: i18n.translate(localeKeys.INVENTORY.MODAL.STORE_NAME),
                cellTemplate: require('./name.cell.template.html')
            }, {
                field: 'description',
                displayName: i18n.translate(localeKeys.DESCRIPTION)
            }, {
                field: 'address',
                displayName: i18n.translate(localeKeys.ADDRESS)
            }, {
                field: 'city',
                displayName: i18n.translate(localeKeys.CITY),
                width: 120
            }, {
                field: 'stateProvince',
                displayName: i18n.translate(localeKeys.STATE_PROVINCE),
                width: 150
            }, {
                field: 'postalCode',
                displayName: i18n.translate(localeKeys.POSTAL_CODE),
                width: 140
            }],
            onRegisterApi: (event) => {
                this.gridApi = event.grid.api;
                this.gridApi.selection.on.rowSelectionChanged(null, (row) => {
                    this.selectedStore === row.entity.storeId && row.setSelected(true);
                    this.selectedStore = row.entity.storeId;
                });
                this.gridApi.selection.clearSelectedRows();
                this.setCurrentlyAssociated();
            }
        };

        Object.assign(this, {
            $timeout,
            coreLocaleKeys,
            localeKeys,
            onRegionChanged: this.onRegionChanged.bind(this),
            selectedStore: null,
            tableData
        });
    }

    setCurrentlyAssociated() {
        this.$timeout(() => {
            this.gridApi.grid.rows.forEach((row) => {
                row.setSelected(row.entity.storeId === this.selectedStore);
            });
        });
    }

    onRegionChanged(region) {
        this.selectedRegion = region;
        this.selectedStore = null;
        this.onRegionFilterChange()(this.selectedRegion);
    }

    $onChanges(changes) {
        if (changes.stores && changes.stores.currentValue) {
            this.tableData.data = changes.stores.currentValue;
            this.gridApi && this.setCurrentlyAssociated();
        }
    }

    $postLink() {
        this.$timeout(() => {
            this.dialogInitialized = true;
        });
    }
}

export default {
    bindings: {
        onClose: '&',
        onRegionFilterChange: '&',
        regions: '<',
        selectedRegion: '<',
        selectedStore: '<',
        stores: '<'
    },
    template: require('./inventory.store.finder.template.html'),
    controller: InventoryStoreFinderController
};