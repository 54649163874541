import angular from 'angular';

import RoutingConfig from './orders.config';
import AddOfferModule from './create/offer/add.offer.wizard.module';
import AddServicesModule from './addServices/add.services.wizard.module';
import CreateProductsOrderModule from './create/productsOrder/create.products.order.wizard.module';
import EditOfferModule from './edit/offer/edit.offer.wizard.module';
import RenewalStatementsDialogModule from './renewalStatementsDialog/renewal.statements.dialog.module';
import TransitionOfferModule from './transition/transition.offer.wizard.module';
import TransferModule from './transfer/transfer.wizard.module';

export default angular.module('invision.customercare.customer.orders', [
    AddOfferModule,
    AddServicesModule,
    CreateProductsOrderModule,
    EditOfferModule,
    RenewalStatementsDialogModule,
    TransitionOfferModule,
    TransferModule
])
    .config(RoutingConfig)
    .name;
